<template>
<div class="partner">
    <StateBar />
    <TitleHeader title="佣金记录" bgcolor="transparent" :back="true"></TitleHeader>
    <van-tabs v-if="!firstLoading" class="" v-model="activeIndex" @change="getTab">
        <van-tab v-for="val in vantTabs" :key="val.id" :title="val.name" :name="val.id" class="drg_tab_list" ></van-tab>
    </van-tabs>
    <AutoView :header="true" :footer="false" class="partner--view l-view" :class="{ androidPosition: isAndroidOne, iosPosition: isIOSOne, ipxPosition: isIPXOne }">
        <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__s">
            <EmptyCard v-if="list.length <= 0 && !isFlag" type="partner"></EmptyCard>
            <template v-else>
                <!-- <van-pull-refresh v-model="dataLoading" @refresh="loadNewData">
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" > -->
                <div class="account">
                    <div class="card" v-for="(item, index) in list" :key="index">
                        <van-image
                            :src="item.avatar"
                            lazy-load
                            round
                            fit="cover"
                            class="photo">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                        <div class="content">
                            <h5 class="name">{{item.userName}}</h5>
                            <p class="desc">{{getTypeText( item.type )}}</p>
                        </div>
                        <span class="amount">+{{ item.amount }} 元</span>
                    </div>
                </div>
                    <!-- </van-list>
                </van-pull-refresh> -->
            </template>
        </van-skeleton>
    </AutoView>
</div>
</template>

<script>
export default {
    name : 'commissionRecord',
    data() {
        return {
            firstLoading: true,

            isAndroidOne: false,
            isIOSOne    : false,
            isIPXOne    : false,

            activeIndex: 1,
            vantTabs: [
                { id: 1, name: "一级佣金" },
                { id: 2, name: "二级佣金" }
            ],
            list: [],
            isFlag: false,
        }
    },
    beforeMount () {
        this.isAndroidOne = window.isClient && window.isAndroid;
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOSOne = window.isIOS;
            this.isIPXOne = window.isIPX;
        }
    },
    methods: {
        loadData () {
            this.isFlag = true;
            this.HTTP.incomeList( this.activeIndex, null, 'before', 50,  ).then( ( res ) => {
                console.log(res)
                this.firstLoading = false;
                this.isFlag = false;
                if(res == null) this.list = [];
                if ( res ) this.list = res.list;
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
            } );
        },
        getTab() {
            this.list = [];
            this.loadData();
        },
        getTypeText ( type ) {
            const map = {
                1 : '选址报告',
                2 : '上上贝',
                3 : '会员',
                4 : '选址尊享服务包',
                5 : '品牌洼地报告',
                6 : '数据导出服务',
                7 : '套餐包',
                8 : '店铺监测',
                9 : '品牌报告',
                10: '数据查询服务',
                11: '周边客群报告',
            };
            return '购买' + ( map[ type ] || map[ 1 ] );
        },

    },
    created() {
        this.loadData();
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.partner{
    height: 100vh;
    overflow: hidden;
    .l-view {
        height: calc(100% - 15.5vw - 12vw) !important;
    }
    .androidPosition{
        height: calc(100% - 15.5vw - 12vw - #{$androidStateBar}) !important;
    }
    .iosPosition{
        height: calc(100% - 15.5vw - 12vw - #{$iOSStateBar}) !important;
    }
    .ipxPosition{
        height: calc(100% - 15.5vw - 12vw - #{$iPXStateBar}) !important;
    }
    ::v-deep .van-tabs__line{width: 9.07vw !important;height: 0.8vw;background: #518CDB;border-radius: 0.4vw;}
    ::v-deep .van-tab--active{ font-size: 4.27vw;font-weight: bold;color: #333333;}
    ::v-deep .van-hairline--top-bottom::after{ border-width: 0px;}
    ::v-deep .van-tabs__content{ height: calc(100% - 12vw);}
    .drg_tab{
        // height: 100%;line-height: normal;
        ::v-deep.van-tabs--line .van-tabs__wrap{ height: 12vw;padding: 0 22.67vw;box-sizing: border-box; }
        ::v-deep .van-tab{
            font-size: 3.74vw;flex: none;padding: 0;
            &:first-child() {
                margin-right: 22.67vw;
            }
        }
        ::v-deep .van-tabs__line{width: 9.07vw !important;height: 0.8vw;background: #518CDB;border-radius: 0.4vw;}
        ::v-deep .van-tab--active{ font-size: 4.27vw;font-weight: bold;color: #333333;}
        ::v-deep .van-hairline--top-bottom::after{ border-width: 0px;}
        ::v-deep .van-tabs__content{ height: calc(100% - 12vw);}
        .drg_tab_list{
            width: 100%;min-height: 100%;padding: 4vw; box-sizing: border-box;
        }
    }
    .account{
        position: relative;
        padding:  2.67vw 5.33vw;
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        border-radius: 1.33vw;
    }
    .card {
        @include flex;
        width: 100%;
        height: 100%;
        padding: 4vw 0;
        border-bottom: 1px solid #EDF1FB;
        .photo {
            width: 9.6vw;
            height: 9.6vw;
        }
        .content {
            flex: 1;
            margin: 0 2.67vw;
            .name {
                @include font( $c-text, $font_347, bold );
                line-height: $font_480;
            }
            .desc {
                @include font( $c-info, 2.94vw, normal );
                line-height: $font_400;
                margin-top: 0.67vw;
            }
        }
        &:last-child{
            border-bottom: none;
        }
        .amount{
            @include font( $c-notice, $font_347, bold );
        }
    }
}
</style>
