<template>
    <div class="brand_tab1">
        <div class="brand_tab1_title">
            <h3>{{companyList.name}}</h3>
            <p>
                <span @click="openCompany">查看更多</span>
                <img @click="openCompany" src="/static/icon/icon_arrow-right.png" alt="">
            </p>
        </div>
        <div class="brand_tab1_list" v-for="(val, index) in companyList.data.slice(0,3)" :key="index">
            <h3>{{val.name}}</h3>
            <p><span>{{val.value}}</span></p>
        </div>

    </div>
</template>

<script>
    import { setLocalStorage } from "@/utils/index";
    export default {
        name: "companyInfo",
        props: {
            companyList: {
                type: Object
            },
            titleName: {
                type: String
            },
            baseList: {
                type: Array
            },
            collectList: {
                type: [Array, String]
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            // 跳转到公司审查概况
            openCompany() {
                setLocalStorage("reviewList", this.baseList);
                setLocalStorage("companyList", this.companyList);
                setLocalStorage("collectList", this.collectList);
                this.swRouter( { 
                    path: '/brand/brand_review', 
                    query: { name: this.titleName, } 
                });
            }, 
        },
    }

</script>

<style lang="scss" scoped>
    @import "@/styles/index.scss";
    .brand_tab1{
        background: white;
        padding: 4.53vw 4vw;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        margin-bottom: 2.67vw;
        .brand_tab1_title{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 6.4vw;
            h3{
                font-size: 4.53vw;
                font-weight: bold;
                color: #1D2233;
                width: auto;
                line-height: normal;
            }
            p{
                flex: 1;
                text-align: right;
                font-size: 0;
                vertical-align: middle;
                span{
                    font-size: 3.2vw;
                    display: inline-block;
                    vertical-align: middle;
                    color: #347FFF;
                    line-height: 4.4vw;
                    margin-right: 1.33vw;
                    line-height: normal;
                }
                img{
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    width: 1.33vw;
                    height: 2.8vw;
                }
            }
        }
        .brand_tab1_list{
            margin-top: 3.47vw;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 4.93vw;
            h3{
                width: auto;
                margin-right: 6.4vw;
                font-size: 3.47vw;
                font-weight: 500;
                color: #1D2233;
            }
            p{
                flex: 1;
                font-size: 3.47vw;
                color: #8E9099;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
        }
    }
</style>