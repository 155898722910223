<template>
    <div class="brand">
        <StateBar bgcolor="white" />
        <TitleHeader title="品牌推荐" :back="true"></TitleHeader>

         <AutoView :header="true" :footer="false" class="brand--view">
            <div class="title_and_view">
                <div class="title">
                    <p>免费发布找品牌需求</p>
                    <h3>填写信息，<span>3</span>秒内找到偏好品牌</h3>
                    <h5>直达品牌方，沟通更多信息</h5>
                </div>

                <div class="view">
                    <div class="item">
                        <label class="s-label">
                            <span><small>*</small>意向开店地区</span>
                            <input ref="lat" type="text" readonly placeholder="请选择" :value="field.city || '请选择城市区域'" class="inp icon icon-geo" :class="field.city == null ? 'pleClass': ''" @click="showRegionPicker = true" />
                        </label>
                    </div>
                    <div class="item">
                        <label class="s-label">
                            <span><small>*</small>意向行业</span>
                            <input ref="categoryCode" type="text" readonly placeholder="请选择" :value="field.category || '请选择意向行业'" :class="field.category == null ? 'pleClass': ''" @click="showIndustryPicker = true" class="inp sel" />
                        </label>
                    </div>
                    <div class="item">
                        <label class="s-label">
                            <span><small>*</small>投资预算</span>
                            <input ref="budget" type="text" readonly placeholder="请选择" :value="field.budget || '请选择您的投资预算'" :class="field.budget == null ? 'pleClass': ''" @click="showBudgetPicker = true" class="inp sel" />
                        </label>
                    </div>
                    <div class="item_p">- 现在提交需求，平台为您快速匹配品牌方 -</div>
                    <div class="item_phone">
                        <span>手机号</span>
                        <!-- <van-field type="text"  v-model.number="field.mobile"  /> -->
                        <van-field type="text" v-if="typeMobile == 1" maxlength="11" @keyup.delete="delectPhone" v-model.number="form.mobile" />
                        <van-field type="Number" ref="mobile" v-else v-model.number="form.mobile" oninput="if(value.length>11) value = value.slice(0,11)" />
                    </div>
                    <van-button class="brand_btn" color="#2D7EEC" :loading="submitting" block @click="onSubmit">快速找品牌</van-button>
                    <div class="reading_text">
                        <input type="checkbox" v-model="field.isChecked" id="join_box">
                        <label for="join_box">我已阅读并同意</label><span @click="openAgreement">《上上参谋品牌服务协议》</span>
                    </div>
                </div>
            </div>
            <div class="list" v-if="hotBrandList && hotBrandList.length > 0">
                <h3>近期大热品牌</h3>
                <BrandList v-for="item in hotBrandList" :item="item" :key="item.code" class="item" />
            </div>
        </AutoView>

        <!-- <SoltFooter>
            <div class="brand--footer">
                <van-button type="info" block :disabled="disabled" :loading="submitting" @click="onSubmit">查看品牌推荐结果</van-button>
            </div>
        </SoltFooter> -->

        <!-- 省市区选择 -->
        <OpenCityPicker type="v2open" v-model="showRegionPicker" @confirm="getCityZone"/>

        <!-- 意向行业 -->
        <van-popup v-model="showIndustryPicker" position="bottom">
            <van-picker show-toolbar title="选择意向行业" :columns="newIndustryTypes" @cancel="showIndustryPicker = false" @confirm="confirmIndustry" />
        </van-popup>

        <!-- 投资预算 -->
        <van-popup v-model="showBudgetPicker" position="bottom">
            <van-picker show-toolbar title="选择投资预算" :columns="reqData" @cancel="showBudgetPicker = false" @confirm="budgetConfirm" />
        </van-popup>


        <!-- 消息提示框 -->
        <pop-info
            ref="chilPop"
            title="已收到您的初步意向"
            popText="我们会立刻为您推荐品牌<br/>专属顾问也将致电您，确认您的具体需求"
            @confirmBtn="confirmBtn"></pop-info>
    </div>
</template>

<script>
import SelectTabs from '@/components/widget/selectTabs';
import OpenCityPicker from '../home/components/openCityPicker';
import BrandList from "./components/brandList.vue";
import { Toast } from "vant";
import popInfo from "./components/newBrandDetail/popInfo"
export default {
    components: {
        SelectTabs,
        OpenCityPicker,
        BrandList,
        popInfo
    },
    data () {
        return {
            loading   : true,
            submitting: false, // 提交状态

            showRegionPicker  : false, // 行政区
            showBudgetPicker  : false, // 预算
            showIndustryPicker: false, // 行业

            reqData: [
                { text: '不限', min: null, max: null },
                { text: '0-10万', min: 0, max: 10 },
                { text: '10-20万', min: 10, max: 20 },
                { text: '20-30万', min: 20, max: 30 },
                { text: '30-50万', min: 30, max: 50 },
                { text: '50-100万', min: 50, max: 100 },
                { text: '100万以上', min: 100, max: null },
            ],
            industryTypes: [ '餐饮', '零售', '教育' ],
            brands       : [ '推荐' ],

            newIndustryTypes: [], //获取行业列表

            field: {
                city    : null,
                category: null,
                budget  : null,
                isChecked   : true,
                mobile: null,
            },
            form: {
                zone     : null,
                code     : null,
                city     : null,
                newCategoryCodeL1: null,
                newCategoryNameL1: null,
                newCategoryCodeL2: null,
                newCategoryNameL2: null,
                mobile      : null,
                type        : 2,
            },

            hotBrandList: [],
            typeMobile: 1,
        };
    },
    methods: {
        // 删除键
        delectPhone() {
            this.form.mobile = null;
            this.typeMobile = 2;
            this.$nextTick(() => {
                this.$refs.mobile.focus();
            })
        },
        openAgreement(){
            this.swRouter("/join/agreement")
        },
        onSubmit () {
            Toast.resetDefaultOptions();
            if (this.field.city == null ||  this.field.city == "") {
                Toast({ message: "请选择城市区域", icon: "warning",className: 'forToast' });
                return false;
            }
            if (this.field.category == null ||  this.field.category == "") {
                Toast({ message: "请选择意向行业", icon: "warning",className: 'forToast' });
                return false;
            }
            if (this.field.budget == null ||  this.field.budget == "") {
                Toast({ message: "请选择投资预算", icon: "warning",className: 'forToast' });
                return false;
            }
            if(!/^1[3-9]\d{9}$/.test(this.form.mobile) && this.typeMobile == 2) {
                Toast({ message: "请输入有效的手机号码", icon: "warning",className: 'forToast' });
                return false;
            }
            if(!this.field.isChecked) {
                Toast({ message: "请勾选我已阅读", icon: "warning",className: 'forToast' });
                return false;
            }
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.createBrand( this.form ).then( ( res ) => {
                this.$refs.chilPop.getParams();
                setTimeout( () => {
                    this.form.newCategoryCodeL1 = null;
                    this.form.newCategoryNameL1 = null;
                    this.form.newCategoryCodeL2 = null;
                    this.form.newCategoryNameL2 = null;
                    this.field.category = null;
                    this.field.budget = null;
                    this.typeMobile = 1;
                    this.form.mobile = this.field.mobile;
                }, 100 );
                this.submitting = false;
            }).catch( ( e ) => {
                // Toast({ message: "网络不稳定，请稍候重试！", icon: "warning",className: 'forToast' });
                setTimeout( () => {
                    this.submitting  = false;
                }, 2000 );
            } );


        },
        confirmBtn() {
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.BRAND.TAP_RECOMMENDL );
            this.swRouter( {
                path : '/brand/recommend',
                query: this.form,
            } );
        },
        getCityZone ( picker, code ) {
            this.field.city = picker.join( '' );
            this.form.zone  = picker.join( '' );
            this.form.city  = picker.join( '-' );
            this.form.code  = code[ 2 ] || code[ 1 ] || code[ 0 ];
        },
        confirmIndustry ( type, index ) {
            this.showIndustryPicker = false;
            this.form.newCategoryNameL1 = type[0];
            this.form.newCategoryNameL2 = type[1];
            this.form.newCategoryCodeL1 = this.newIndustryTypes[index[0]].code;
            this.form.newCategoryCodeL2 = this.newIndustryTypes[index[0]].children[index[1]].code;
            this.field.category = type[0]+"-"+type[1];
        },
        // 投资预算
        budgetConfirm( type, index ) {
            this.showBudgetPicker = false;
            this.field.budget = type.text;
        },
        // viewBrand ( item ) {
        //     this.swRouter( '/brand/details/' + item.brandCode );
        // },
    },
    // watch: {
    //     form: {
    //         deep: true,
    //         handler ( form ) {
    //             for ( const k in form ) {
    //                 if ( [ 'costMin', 'costMax', 'newCategoryCodeL2','categoryCode'].includes( k ) ) continue;
    //                 if ( ! form[ k ] && form[ k ] !== 0) {
    //                     this.disabled = true;
    //                     return;
    //                 }
    //             }
    //             this.disabled = false;
    //         },
    //     },
    // },
    created () {
        // 近期大热卖
        this.HTTP.getBrandInfo().then(res => {
            console.log(res)
            this.hotBrandList = res.data.newBrandList;
        }).catch((e) => {
            this.$toast("网络不稳定，正在重试！");
        });
        // 获取行业列表
        this.HTTP.brandCategory().then( ( res ) => {
            this.newIndustryTypes = res.map(item => {
                return {
                    text: item.name,
                    code: item.code,
                    children: item.children.map(i => {
                        return {
                            text: i.name,
                            code: i.code,
                        }
                    })
                };
            })
        }).catch( ( e ) => {
            this.$toast( '获取行业信息时发生错误！' );
        });
        // 获取定位信息
        if ( window.isClient ) {
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                if ( data ) {
                    this.form.code  = data.adCode;
                    this.form.zone  = data.city + data.district;
                    this.form.city  = [ data.city, data.district ].join( '-' );
                    this.field.city = data.province + data.city + data.district;
                }
            } );
        }
        // 获取用户手机号
        this.HTTP.getUserInfo().then(res => {
            this.form.mobile = res.phone;
            this.field.mobile = res.phone;
        })
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.brand {
    min-height: 100vh;
    background-color: #F8F8F9;

    #{&}--view {
        .title_and_view{
            padding: $padding_4 $padding_4 0;
            background: url(/static/img/brand_bg.jpg) no-repeat;
            background-size: contain;
            .title {
                overflow: hidden;
                height: 24.8vw;
                p {
                    font-size: 3.47vw;
                    color: #FFFFFF;
                    line-height: 6.13vw;
                }
                h3 {
                    font-size: 4.53vw;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 6.13vw;
                    margin-top: 0.8vw;
                    span{
                        font-size: 5.87vw;
                    }
                }
                h5 {
                    margin-top: 1.6vw;
                    font-size: 3.47vw;
                    color: #FFFFFF;
                    line-height: 6.13vw;
                    font-weight: normal;
                }
            }

            .view {
                padding: 0 4.67vw;
                background-color: white;
                box-sizing: border-box;
                border-radius: $radius_xs;
                box-shadow: 0vw 1.07vw 3.6vw 0.4vw rgba(13, 13, 13, 0.06);
                .item {
                    @include flex;
                    height: 13.33vw;
                }
                .item_p{
                    height: 12vw;
                    font-size: 2.93vw;
                    color: #666666;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .item_phone{
                    height: 7.73vw;
                    @include flex;
                    background: #DFEDFF;
                    border-radius: 1.07vw;
                    box-sizing: border-box;
                    line-height: normal;
                    span{
                        font-size: 2.93vw;
                        font-weight: bold;
                        color: #347FFF;
                        line-height: 1;
                        padding: 0 3.2vw 0 3.2vw;
                    }
                    ::v-deep .van-cell{
                        padding: 0;
                        font-size: 2.93vw;
                        font-weight: bold;
                        line-height: normal;
                        flex: 1;
                        border: none;
                        background: transparent;
                    }
                    ::v-deep .van-field__control{
                        color: #347FFF;
                    }
                }
                .brand_btn{
                    margin: 2.67vw 0;
                    height: 13.33vw;
                    border-radius: 1.6vw;
                    font-size: 4.8vw;
                    font-weight: bold;
                    color: #FFFFFF;

                }
                .item:not(:last-child) {
                    box-shadow: inset 0 -1px 0 #F3F3F3;
                }
                .item.nob {
                    box-shadow: none;
                }
                .s-label {
                    @include flex;
                    width: 100%;
                    height: 100%;
                    font-size: 3.73vw;
                    color: #333333;
                    span {
                        width: 29vw;
                        font-weight: bold;
                        small{
                            color: #FF5900;
                            font-size: 3.73vw;
                        }
                    }
                    .inp {
                        width: calc( 100% - 18vw );
                        height: $font_560;
                        padding: 0;
                        margin: 0;
                        border: none;
                        box-sizing: border-box;
                        font-size: $font_373;
                        text-align: right;
                        &::placeholder {
                            color: #C4C4C6;
                        }
                        &.warn::placeholder {
                            color: $c-require;
                        }
                    }
                    .sel.lock {
                        background: none;
                    }
                    .sel, .icon {
                        padding-right: 6vw;
                        background: url(/static/icon/arrow_down_2.png) no-repeat right center;
                        background-size: 3vw;
                    }
                    .icon {
                        background: url(/static/icon/arrow_down_2.png) no-repeat right center;
                        background-size: 4vw 4vw;

                        &.icon-geo {
                            background-image: url(/static/icon/location_line_deep.png);
                            background-size: 5vw;
                        }
                    }
                    .pleClass {
                        color: #999999;
                        font-size: 3.73vw;
                    }
                }
                .reading_text{
                    text-align: center;
                    padding-bottom: 2.67vw;
                    font-size: 0;
                    line-height: 1;
                    input{
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0px 1.33vw 0 0;
                        width: 4vw;
                        height: 4vw;
                    }
                    label{
                        color: #999999;
                        font-size: 2.93vw;
                        line-height: normal;
                        display: inline-block;
                        vertical-align: middle;

                    }
                    span{
                        font-size: 2.93vw;
                        line-height: normal;
                        display: inline-block;
                        vertical-align: middle;
                        color: #347FFF;
                    }
                }
            }
        }

        .list{
            padding: 5.33vw $padding_4 0;
            h3{
                font-size: 4.27vw;
                font-weight: bold;
                color: #333333;
                line-height: 6vw;
                margin-bottom: 2.67vw;
            }
            .item {
                margin-bottom: 4vw;
                border-radius: $radius_xs;
                box-shadow: 0 0 2.13vw 0 rgba(0, 0, 0, 0.09);
            }
        }
    }

    #{&}--footer {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;
        background-color: #F8F8F9;

        ::v-deep .van-button {
            height: 100%;
        }
    }
}
</style>
<style >
.forToast{
    width: 26.67vw;
    min-height: 26.67vw;
    font-size: 3.73vw;
    padding: 2.13vw;
}
.forToast .van-toast__icon{
    font-size: 9.6vw;
}
.forToast .van-toast__text{
    line-height: normal;
}
</style>
