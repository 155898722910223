var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon-warp",style:({
    backgroundImage: 'url(' + _vm.activeBodyImage + ')',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  })},_vm._l((_vm.activeItemResList),function(item){return _c('ul',{key:item.couponId,staticClass:"coupon-card",style:({
      backgroundImage: 'url(' + (item.receiveStatus == 1 ? _vm.couponBgImage[0] : _vm.couponBgImage[1]) + ')',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginBottom: '4.53vw',
    }),on:{"click":function($event){return _vm.getCoupon(item)}}},[_c('li',{staticClass:"li-top"},[_c('i',[_vm._v(_vm._s(item.couponName))])]),_c('li',{staticClass:"li-bottom"},[_vm._v(_vm._s(item.validDateDesc))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }