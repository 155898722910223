<template>
  <div class="guide">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" bgcolor="white" :back="isFrom == 'me'">
      <span slot="right" class="jump" @click="jumpGuide" v-if="isFrom != 'me' && !isOk">跳过</span>
    </TitleHeader>
    <div class="coupon_text" v-if="isCoupon && isFrom != 'me'">跳过则无法领取50元优惠券哦~</div>
    <!-- 进度条 -->
    <ProgressBar :guide1Val="guideBar" :guide2Val="0" v-if="isFrom != 'me'" />

    <StackView ref="view" id="view" class="guide--view">
      <van-skeleton :loading="pageLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <div class="content-wrap">
          <div class="pro-sets">
            <h4>您是从哪里了解上上参谋的？</h4>
            <ul class="from-flex">
              <li v-for="(item, index) in fromType" :key="item" @click="selected(index, 'fromIdx')">
                <p :class="{ isSelect: guideVal.fromIdx == index }">{{ item }}</p>
              </li>
            </ul>
          </div>
          <div class="pro-sets">
            <h4>选择您的身份</h4>
            <ul class="from-flex">
              <li v-for="(item, index) in identityType" :key="item" @click="selected(index, 'identityIdx')">
                <p :class="{ isSelect: guideVal.identityIdx == index }">{{ item }}</p>
              </li>
            </ul>
          </div>

          <template v-if="guideVal.identityIdx != -1">
            <!-- 前五种身份 -->
            <template v-if="guideVal.identityIdx < 5">
              <div class="pro-sets">
                <h4>是否有开店经验？</h4>
                <ul class="from-flex">
                  <li v-for="(item, index) in experienceType" :key="item" @click="selected(index, 'experienceIdx')">
                    <p :class="{ isSelect: guideVal.experienceIdx == index }">{{ item }}</p>
                  </li>
                </ul>
              </div>
              <!-- 开过店 -->
              <template v-if="guideVal.experienceIdx == 0">
                <div class="pro-sets">
                  <h4>您开过几家店？</h4>
                  <div class="select-wrap" @click="showNum = true">
                    <input class="select-input" v-model="guideVal.shopNum" readonly="readonly" placeholder="请选择您开过几家店" />
                    <img class="icon-img" src="/static/icon/input_icon-select.png" alt="" />
                  </div>
                </div>
                <div class="pro-sets" v-if="guideVal.shopNum">
                  <h4>您的经营状况是？</h4>
                  <ul class="from-flex">
                    <li v-for="(item, index) in shopStatusType" :key="item" @click="selected(index, 'shopStatusIdx')">
                      <p :class="{ isSelect: guideVal.shopStatusIdx == index }">{{ item }}</p>
                    </li>
                  </ul>
                </div>
                <div class="pro-sets" v-if="guideVal.shopStatusIdx != -1">
                  <h4>您想通过上上参谋解决什么问题？（多选）</h4>
                  <ul class="from-flex">
                    <li v-for="(item, index) in solveType" :key="item" @click="selectMul(index)">
                      <p :class="{ isSelect: guideVal.solveIdx.includes(index) }">{{ item }}</p>
                    </li>
                  </ul>
                </div>
              </template>
              <!-- 没开过店 -->
              <template v-if="guideVal.experienceIdx == 1">
                <div class="pro-sets">
                  <h4>您的意向开店形式是？</h4>
                  <ul class="from-flex">
                    <li v-for="(item, index) in shopType" :key="item" @click="selected(index, 'shopTypeIdx')">
                      <p :class="{ isSelect: guideVal.shopTypeIdx == index }">{{ item }}</p>
                    </li>
                  </ul>
                </div>
                <div class="pro-sets" v-if="guideVal.shopTypeIdx != -1">
                  <h4>您的投资预算是？</h4>
                  <div class="select-wrap" @click="showBudget = true">
                    <input class="select-input" v-model="guideVal.budget" readonly="readonly" placeholder="请选择您的投资预算" />
                    <img class="icon-img" src="/static/icon/input_icon-select.png" alt="" />
                  </div>
                </div>
                <div class="pro-sets" v-if="guideVal.budget">
                  <h4>您想通过上上参谋解决什么问题？（多选）</h4>
                  <ul class="from-flex">
                    <li v-for="(item, index) in solveType" :key="item" @click="selectMul(index)">
                      <p :class="{ isSelect: guideVal.solveIdx.includes(index) }">{{ item }}</p>
                    </li>
                  </ul>
                </div>
              </template>
            </template>
            <!-- 后四种身份 -->
            <template v-else>
              <div class="pro-sets">
                <h4>是否有合作意向？</h4>
                <ul class="from-flex">
                  <li v-for="(item, index) in experienceType" :key="item" @click="selected(index, 'intentionIdx')">
                    <p :class="{ isSelect: guideVal.intentionIdx == index }">{{ item }}</p>
                  </li>
                </ul>
              </div>
            </template>
          </template>
        </div>
      </van-skeleton>
    </StackView>

    <SoltFooter class="guide--footerBar">
      <div class="confirm-btn">
        <van-button :color="colorVal" block :loading="loading" :disabled="!isOk" @click="onTap">下一步</van-button>
      </div>
    </SoltFooter>

    <!-- 店铺数量弹框 -->
    <van-popup v-model="showNum" position="bottom">
      <van-picker visible-item-count="5" show-toolbar :columns="columnsShop" @confirm="shopConfirm" @cancel="showNum = false" />
    </van-popup>
    <!-- 投资预算弹框 -->
    <van-popup v-model="showBudget" position="bottom">
      <van-picker visible-item-count="5" show-toolbar :columns="columnsBudget" @confirm="budgetConfirm" @cancel="showBudget = false" />
    </van-popup>
  </div>
</template>

<script>
import ProgressBar from "./components/progress_bar";

export default {
  components: { ProgressBar },
  data() {
    return {
      title: "",
      guideBar: 0,
      showNum: false, // 店铺数量弹框
      showBudget: false, // 投资预算弹框
      isOk: false,
      loading: false,
      pageLoading: true,
      isEdit: false, // 编辑状态
      isCoupon: null, //是否显示领取优惠券文案
      guideVal: {
        fromIdx: -1, // 了解来源
        identityIdx: -1, // 身份
        experienceIdx: -1, // 开店经验
        intentionIdx: -1, // 合作意向
        shopNum: "", // 店铺数量
        shopTypeIdx: -1, // 店铺形式
        budget: "", // 投资预算
        shopStatusIdx: -1, // 经营状况
        solveIdx: [] // 解决问题
      },
      fromType: ["抖音", "快手", "视频号", "知乎", "头条", "小红书", "朋友圈广告", "应用商店", "百度贴吧", "朋友推荐", "其他"], // 来源
      identityType: ["餐饮经营者", "零售经营者", "地产经营者", "教育经营者", "娱乐经营者", "广告服务商", "咨询顾问", "数据分析师", "其他"], // 身份
      identityId: [1, 3, 5, 6, 7, 8, 9, 11, 12], // 行业ID
      experienceType: ["是", "否"],
      columnsShop: ["1", "2", "3", "4", "5", "5家以上"],
      shopStatusType: ["良好", "一般", "很差"],
      solveType: ["想选址", "找品牌", "获客经营", "找商机", "竞争分析", "找合作", "其他"],
      shopType: ["加盟", "自营"],
      columnsBudget: ["0-20万", "20-50万", "50-70万", "70-100万", "100万以上"]
    };
  },
  computed: {
    colorVal() {
      return this.isOk ? "#347FFF" : "#D5D3DA";
    },
    isFrom() {
      return this.$route.query.from;
    }
  },
  created() {
    this.HTTP.getQuestionnaireById().then(res => {
      this.loadData(res.data);
    });
  },
  beforeMount() {
    if (!this.isFrom) {
      // 禁止返回操作
      this.JSBridge.callHandler("interceptBack", true);
    }
  },
  methods: {
    jumpGuide() {
      let params = {
        budget: "",
        channelName: "",
        cooperationIntention: "",
        industryId: "",
        industryName: "",
        intention: "",
        openShop: "",
        openShopCount: "",
        operationStatus: "",
        todoList: []
      };
      this.HTTP.updateQuestionnaire(params)
        .then(res => {
          if (res.data) {
            this.swRouter({
              path: "/guideTwo",
              query: {
                form: this.isFrom,
                type: 1,
              }
            });
          }
        })
        .catch(e => {
          this.$toast("网络不稳定，请稍后重试！");
        });
    },
    loadData(data) {
      this.isCoupon = data.couponStatus;
      console.log(data);
      this.pageLoading = false;
      if (data && data.channelName) {
        this.guideVal.fromIdx = this.fromType.indexOf(data.channelName);
        this.guideVal.identityIdx = this.identityType.indexOf(data.industryName);

        this.guideVal.experienceIdx = data.openShop - 1;
        this.guideVal.shopNum = data.openShopCount;
        this.guideVal.shopStatusIdx = this.shopStatusType.indexOf(data.operationStatus);
        this.guideVal.shopTypeIdx = this.shopType.indexOf(data.intention);
        this.guideVal.budget = data.budget;

        this.guideVal.intentionIdx = data.cooperationIntention - 1;

        data.todoList.forEach(ele => {
          let idx = this.solveType.indexOf(ele);
          this.guideVal.solveIdx.push(idx);
        });

        this.guideBar = 100;
        this.isOk = true;
        this.isEdit = true;
      }
    },
    selectMul(idx) {
      if (this.guideVal.solveIdx.length == 0) {
        this.guideBar += 16.66;
      }
      let sub = this.guideVal.solveIdx.indexOf(idx);
      if (sub != -1 && this.guideVal.solveIdx.length > 1) {
        this.guideVal.solveIdx.splice(sub, 1);
      } else {
        this.guideVal.solveIdx.push(idx);
      }

      if (this.guideBar > 98) {
        this.isOk = true;
      }
    },
    selected(idx, key) {
      if (this.guideVal[key] == -1) {
        this.guideBar += 16.66;
      }

      if (key == "intentionIdx") {
        if (this.guideVal.fromIdx == -1) {
          this.guideBar = 83.33;
        } else {
          this.guideBar = 100;
        }
      }

      // 身份切换
      if (key == "identityIdx" && this.guideVal[key] != -1) {
        this.isOk = false;
        if (idx > 4 && this.guideVal[key] < 5) {
          // 前五种身份切换为后四种身份
          if (this.guideVal.fromIdx == -1) {
            this.guideBar = 50;
          } else {
            this.guideBar = 66.66;
          }
          this.guideVal.experienceIdx = -1;
          this.guideVal.shopNum = "";
          this.guideVal.budget = "";
          this.guideVal.shopStatusIdx = -1;
          this.guideVal.shopTypeIdx = -1;
          this.guideVal.solveIdx = [];
        }
        if (idx < 5 && this.guideVal[key] > 4) {
          // 后四种身份切换为前五种身份
          if (this.guideVal.fromIdx == -1) {
            this.guideBar = 16.66;
          } else {
            this.guideBar = 33.33;
          }
          this.guideVal.intentionIdx = -1;
        }
      }

      // 开店经验切换
      if (key == "experienceIdx" && this.guideVal[key] != -1) {
        this.isOk = false;
        this.guideBar = 50;
        if (idx == 0) {
          this.guideVal.budget = "";
          this.guideVal.shopTypeIdx = -1;
          this.guideVal.solveIdx = [];
        }
        if (idx == 1) {
          this.guideVal.shopNum = "";
          this.guideVal.shopStatusIdx = -1;
          this.guideVal.solveIdx = [];
        }
      }

      this.guideVal[key] = idx;
      if (this.guideBar > 98) {
        this.isOk = true;
      }
      // 自动回滚
      if (["experienceIdx", "shopStatusIdx", "shopTypeIdx"].includes(key)) {
        this.gotoBottom();
      }
    },
    shopConfirm(value) {
      this.guideBar += 16.66;
      this.showNum = false;
      this.guideVal.shopNum = value;
      this.gotoBottom();
    },
    budgetConfirm(value) {
      this.guideBar += 16.66;
      this.showBudget = false;
      this.guideVal.budget = value;
      this.gotoBottom();
    },
    gotoBottom() {
      setTimeout(() => {
        var ele = document.getElementById("view");
        //判断元素是否出现了滚动条
        if (ele.scrollHeight > ele.clientHeight) {
          //设置滚动条到最底部
          ele.scrollTop = ele.scrollHeight;
        }
      }, 120);
    },
    onTap() {
      this.loading = true;
      let arr = [];
      for (let item of this.guideVal.solveIdx) {
        arr.push(this.solveType[item]);
      }
      let params = {
        budget: this.guideVal.budget,
        channelName: this.fromType[this.guideVal.fromIdx],
        cooperationIntention: this.guideVal.intentionIdx + 1,
        industryId: this.identityId[this.guideVal.identityIdx],
        industryName: this.identityType[this.guideVal.identityIdx],
        intention: this.shopType[this.guideVal.shopTypeIdx],
        openShop: this.guideVal.experienceIdx + 1,
        openShopCount: this.guideVal.shopNum,
        operationStatus: this.shopStatusType[this.guideVal.shopStatusIdx],
        todoList: arr
      };
      this.HTTP.updateQuestionnaire(params)
        .then(res => {
          this.loading = false;
          if (res.data) {
            this.swRouter("/guideTwo?from=" + this.isFrom);
          }
        })
        .catch(e => {
          this.$toast("网络不稳定，请稍后重试！");
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.guide {
  height: 100vh;
  overflow: hidden;
  background: #f8f8f9;
  .coupon_text{
    width: 100%;
    height: 9.33vw;
    margin-bottom: 4.8vw;
    background: url('/static/img/guide_bg.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 0 6.67vw;
    box-sizing: border-box;
    font-size: 3.73vw;
    font-weight: bold;
    color: #347FFF;
    line-height: normal;
  }
  .jump {
    width: 12.27vw;
    height: 5.87vw;
    line-height: normal;
    font-size: 3.73vw;
    color: #8e9099;
    border-radius: 1.6vw;
    text-align: center;
    border: 1px solid #d9dbe3;
  }
  #{&}--view {
    padding: $padding2 $padding2 54.16vw $padding2;
    .content-wrap {
      width: 100%;
      .pro-sets {
        margin-bottom: 6.67vw;
        h4 {
          font-size: 4.53vw;
          color: #1d2233;
          line-height: 6.4vw;
          font-weight: bold;
          padding-left: 1.6vw;
        }
        .from-flex {
          display: flex;
          flex-flow: row wrap;
          text-align: center;
          li {
            flex: 0 0 33.33%;
            margin-top: 3.2vw;
            p {
              width: 27.2vw;
              height: 10.67vw;
              line-height: 10.67vw;
              font-size: 3.73vw;
              color: #666;
              background: #fff;
              border-radius: 1.33vw;
              border: 1px solid #ebeaee;
              margin: 0 auto;
            }
          }
          .isSelect {
            color: #fff;
            background: #347fff;
          }
        }
        .select-wrap {
          position: relative;
          margin: 2.13vw 1.6vw 0;
          .select-input {
            width: 80vw;
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 3.73vw;
            color: #1d2233;
            background: #fff;
            border-radius: 1.33vw;
            border: 1px solid #ebeaee;
            padding: 0 4.27vw;
            outline: none;
            -webkit-appearance: none;
          }
          .icon-img {
            position: absolute;
            top: 4.8vw;
            right: 4.27vw;
            width: 2.4vw;
            height: 1.6vw;
          }
        }
      }
    }
  }

  #{&}--footerBar {
    .confirm-btn {
      width: 100%;
      padding: 0 4vw;
    }
  }
}
</style>
