<template>
  <div class="college-container">
    <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
    <div v-if="!firstLoading">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" v-if="bannerList && bannerList.length">
        <van-swipe-item v-for="item in bannerList" :key="item.key">
          <img class="banner-img" :src="item.pictureUrl" alt="" @click="goPage(item.link)" />
        </van-swipe-item>
      </van-swipe>
      <div class="big-title">精品课程</div>
      <div class="course-grid" v-if="categoryList.length">
        <div class="course-grid-item" v-for="(item, index) in categoryList" :key="index" @click="goPage(item.link)">
          <div class="course-title">{{ item.title }}</div>
          <div class="course-desc" v-if="item.desc">{{ item.desc }}<img class="arrow-icon" src="/static/discovery/white-arrow-icon.png"></div>
          <img class="course-logo" :src="item.image" />
        </div>
      </div>
      <div class="course-list">
        <div class="course-item" v-for="(item, index) in courseList" :key="index" @click="goPage(item.link)">
          <div class="course-item-left">
            <div class="base-info">
              <span class="course-tag" v-if="item.tag">{{ item.tag }}</span>
              <span class="course-title">{{ item.bigTitle }}</span>
            </div>
            <div class="price-info">
              <div class="free" v-if="item.price <= 0">限时免费</div>
              <div class="price-box" v-else>
                <span class="new-price">{{ item.showPrice }}</span>
                <span class="old-price" v-if="item.salePrice">{{ item.salePrice }}</span>
              </div>
              <div class="course-des" v-if="item.des">
                <img class="hot-icon" src="/static/icon/icon_hot_shop@2x.png" />
                <span>{{ item.des }}</span>
              </div>
            </div>
          </div>
          <div class="course-item-right">
            <div class="video-tag" v-if="item.statusStr">{{ item.statusStr }}</div>
            <img class="course-cover" :src="item.cover" />
          </div>
        </div>
      </div>
      <div class="more" v-if="head" @click="goPage(head.link)">
        <span>{{ head.smallTitle }}</span>
        <img class="arrow-icon" src="/static/icon/icon_arrow-right.png" />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  components: {},
  data () {
    return {
      bannerList: [],
      categoryList: [],
      courseList: [],
      head: null,
      firstLoading: true
    };
  },
  mounted () {
    this.queryData()
  },
  methods: {
    async queryData() {
      const { bannerList, appCourse } = await this.HTTP.queryAppCollegeData()
      console.log('bannerList', bannerList)
      console.log('appCourse', appCourse)
      this.bannerList = bannerList || []
      this.categoryList = appCourse.categoryList || []
      this.courseList = appCourse.courseList || []
      this.head = appCourse.head || null
      this.firstLoading = false
    },
    goPage(link) {
      this.JSBridge.callHandler("onNormalizingCall", link)
    }
  },
};
</script>

<style lang="scss" scoped>
 .college-container {
   padding: vw(30);
   background: #fff;
   .more {
     margin: vw(10) auto vw(20);
     width: vw(132);
     height: vw(44);
     background: #F7F7F7;
     border-radius: vw(22);
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 400;
     color: #518CDB;
     font-size: vw(24);
     .arrow-icon {
       width: vw(10);
       height: vw(21);
       margin-left: vw(3);
     }
   }
   .course-list {
     margin-top: vw(25);
     .course-item {
       border-top: vw(1) solid #E7E7E7;
       display: flex;
       align-items: center;
       justify-content: space-between;
       padding: vw(20) 0;
       .course-item-left {
         flex: 1;
         height: vw(168);
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         margin-right: vw(30);
         .course-des {
           font-weight: 400;
           color: #6D7289;
           font-size: vw(24);
           display: flex;
           align-items: center;
           .hot-icon {
             width: vw(21);
             height: vw(25);
             margin-right: vw(8);
           }
         }
         .price-info {
           display: flex;
           align-items: center;
           justify-content: space-between;
           .price-box {
             display: flex;
             align-items: center;
             .new-price {
               font-weight: 500;
               color: #FF5900;
               //line-height: vw(45);
               font-size: vw(32);
             }
             .old-price {
               font-weight: 400;
               color: #B4B4B4;
               //line-height: vw(28);
               font-size: vw(20);
               margin-left: vw(5);
               text-decoration: line-through;
             }
           }
           .free {
             height: 5.067vw;
             line-height: 5.067vw;
             background: linear-gradient(90deg, #F84947 0%, #FF6F54 100%);
             border-radius: 0.667vw;
             font-weight: 500;
             color: #FFFFFF;
             font-size: 2.933vw;
             padding: 0.533vw 1.067vw;
           }
         }
         .base-info {
           .course-tag {
             background: rgba(252,203,141,0.8);
             border-radius: vw(2) vw(10);
             font-weight: 500;
             color: #8D5D22;
             font-size: vw(20);
             line-height: vw(34);
             padding: 0 vw(10);
             height: vw(34);
             display: inline-block;
             box-sizing: border-box;
           }
           .course-title {
             font-weight: 500;
             color: #333333;
             font-size: vw(28);
             line-height: vw(46);
           }
         }
       }
       .course-item-right {
         position: relative;
         .video-tag {
           font-weight: 400;
           color: #FFFFFF;
           font-size: vw(18);
           background: #FF5900;
           border-radius: 0 vw(8) 0 vw(8);
           line-height: vw(25);
           padding: vw(3) vw(12);
           position: absolute;
           top: 0;
           right: 0;
         }
         .course-cover {
           width: vw(280);
           height: vw(168);
           object-fit: cover;
           border-radius: vw(10);
         }
       }
     }
   }
   .course-grid {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     grid-gap: vw(12);
     margin-top: vw(25);
     .course-grid-item {
       position: relative;
       height: vw(127);
       border-radius: vw(10);
       .course-title {
         color: #ffffff;
         font-size: vw(24);
         z-index: 1;
         position: absolute;
         top: vw(9);
         left: vw(12);
       }
       .course-desc {
         color: #ffffff;
         font-size: vw(20);
         z-index: 1;
         position: absolute;
         left: vw(12);
         bottom: vw(16);
         display: flex;
         align-items: center;
         .arrow-icon {
           width: vw(8);
           height: vw(16);
           margin-left: vw(5);
         }
       }
       .course-logo {
         width: 100%;
         height: 100%;
         object-fit: cover;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 0;
         border-radius: vw(10);
       }
     }
   }
   .big-title {
     font-weight: bold;
     color: #1D2233;
     line-height: vw(48);
     font-size: vw(34);
     margin-top: vw(34);
   }
    .my-swipe {
      width: 100%;
      height: vw(220);
      border-radius: 3px;
      .banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
 }
</style>
