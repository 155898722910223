<template>
    <van-overlay :show="show" class="loading">
        <div v-show="showView" class="s-overlay">
            <van-loading :color="color" />
        </div>
    </van-overlay>
</template>

<script>
export default {
    name : 'loading',
    props: {
        show    : Boolean,
        showView: {
            type   : Boolean,
            default: true,
        },
        color: {
            type   : String,
            default: 'white',
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.loading {
    background-color: rgba(black, 0);
    .s-overlay {
        position: absolute;
        top: calc( 50vh - 12.5vw );
        left: 37.5vw;
        width: 25vw;
        height: 25vw;
        line-height: 25vw;
        background-color: rgba(#101E32, .88);
        border-radius: $radius-s;
        text-align: center;
    }
}
</style>
