<template>
  <div class="assess" v-if="entity">
    <div class="header">
      <StateBar/>
      <TitleHeader title="铺位测评" color="white" :bgcolor="bgcolor" :back="true">
        <!-- <div slot="right" @click="$router.push( `/assess/${id}/history` )" class="btnColor">测评记录</div> -->
      </TitleHeader>
    </div>

    <div class="assess--view bg">
      <img src="/static/img/assess/step.png" class="step" :class="{'mini-step': isMini}"/>

      <div class="view">
        <div class="item">
          <label class="s-label">
            <span>店铺名称</span>
            <input ref="storeName" v-model="form.storeName" type="text" placeholder="请输入店铺名称" maxlength="64"
                   class="inp"/>
          </label>
        </div>
        <div class="item">
          <label class="s-label">
            <span>开店行业</span>
            <input ref="categoryCode" type="text" readonly placeholder="请选择" :value="form.categoryText"
                   @click="showIndustryPicker = true" class="inp sel"/>
          </label>
        </div>
        <div class="item">
          <label class="s-label">
            <span>店铺类型</span>
            <input ref="storeType" type="text" readonly placeholder="请选择" :disabled="lockSel" :class="{ lock: lockSel }"
                   :value="form.storeTypeText" @click="showStorePicker = true" class="inp sel"/>
          </label>
        </div>
        <div class="item">
          <label class="s-label">
            <span>店铺位置</span>
            <input ref="lat" type="text" readonly placeholder="请选择店铺位置" :value="form.address" class="inp icon icon-geo"
                   @click="onTapPOI"/>
          </label>
        </div>
      </div>

      <div class="assess--footerBar">
        <van-button type="info" block @click="swRouter( `/assess/${id}/history` )">
          <img src="/static/img/assess/text/record.png" class="btn-text"/>
        </van-button>
        <van-button type="info" block @click="onSubmit">
          <img v-if="entity && entity.flag==1" src="/static/img/assess/text/goto2.png" class="btn-text"/>
          <img v-else src="/static/img/assess/text/goto.png" class="btn-text"/>
        </van-button>
      </div>
    </div>

    <!-- POI选择组件 -->
    <!--    <van-popup v-model="showPoiPicker" style="overflow-y:hidden">-->
    <!--      <POIPicker-->
    <!--        @selectPOI="selectMyPOI"-->
    <!--        @confirm="selectedLocation"-->
    <!--        @cancel="showPoiPicker = false"/>-->
    <!--    </van-popup>-->

    <!-- 行业选择组件 -->
    <IndustryPicker v-model="showIndustryPicker" @confirm="setIndustry"/>

    <!-- 店铺类型选择 -->
    <van-popup v-model="showStorePicker" position="bottom">
      <van-picker show-toolbar :columns="storeTypes" @cancel="showStorePicker = false" @confirm="setStoreType"/>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// const POIPicker = () => import( '@/components/POIPicker/index' );
const IndustryPicker = () => import( '@/views/home/components/IndustryPicker' );

export default {
  name      : 'assessIndex',
  components: {
    // POIPicker,
    IndustryPicker,
  },
  data () {
    return {
      bgcolor: 'transparent',

      showPoiPicker     : false,
      showIndustryPicker: false,
      showStorePicker   : false,

      disabled: true,
      loading : true,
      lockSel : false,

      storeTypes: ['街边店', '商场店'],

      id    : null,
      entity: null,
      form  : {
        id           : 0,
        stdQuestionId: null,
        storeName    : null,
        lat          : null,
        lng          : null,
        address      : null,
        categoryCode : null,
        categoryText : null,
        storeType    : 1,
        storeTypeText: null,
      },
      isUpdateData: false,
      isMini: false
    };
  },
  methods: {
    loadData () {
      // 埋点上报
      this.JSBridge.report( this.JSBridge.REPORT_EVENT.ASSESS.ENTRY );
      this.loading = true;
      this.id = ~ ~ this.$route.params.id || 10007;
      this.HTTP.getAssessInfo( this.id ).then( ( res ) => {
        this.entity = res;
        console.log( 'this.entity', this.entity );
      } ).catch( ( e ) => {
        this.$toast( '网络连接不稳定，请稍候重试！' );
      } );
    },
    onTapPOI () {
      if (window.isClient) {
        this.JSBridge.callHandler( 'callLocationPicker', {
          component: {
            poi   : true, // 位置选择
            now   : true, // 展示当前位置
            radius: false, // 半径选择
            search: true, // 搜索栏
          },
          value    : {
            lat   : this.form.lat,
            lng   : this.form.lng,
            radius: 1 * 1000,
            search: this.form.address,
          },
        }, ( data ) => {
          // 将数据转换后发送到处理方法
          if (data) {
            if (typeof data === 'string') {
              data = JSON.parse( data ); // 兼容安卓数据解析
            }
            this.selectedLocation( [data.lng, data.lat, data.search], data.radius );
          }
        } );
      } else {
        // this.showPoiPicker = true;
        this.$router.push(`/dataQuery/searchPoi?lng=${this.form.lng||''}&lat=${this.form.lat||''}`)
      }
      // this.$router.push(`/dataQuery/searchPoi?lng=${this.form.lng}&lat=${this.form.lat}`)
    },
    onSubmit () {
      if (this.submitting) return;
      // 必填项校验
      const fields = ['storeName', 'categoryCode', 'storeType', 'lat'];
      let pass = true;
      console.log('this.form',JSON.parse(JSON.stringify(this.form)))
      for (const field of fields) {
        const dom = this.$refs[field];
        if (dom) dom.classList.remove( 'warn' );
        if (!this.form[field]) {
          console.log( field );
          if (dom) dom.classList.add( 'warn' );
          if (pass) this.$toast( '请填写/选择店铺信息！' );
          pass = false;
        }
      }
      if (!pass) return;
      // 提交参数
      this.submitting = true;
      // console.log(this.form)
      this.HTTP.submitAssess( this.form ).then( ( res ) => {
        // console.log(res)
        if (window.isMini) {
          this.$router.push(`/assess/${this.id}/poster/${res.history.id}`)
        } else {
          this.swRouter( `/assess/${this.id}/poster/${res.history.id}` );
        }
        this.entity.flag=1
        this.submitting = false;
      } ).catch( ( e ) => {
        this.$toast( '网络不稳定，请稍候重试！' );
        this.submitting = false;
      } );
    },
    selectMyPOI(e) {
      console.log(e)
      this.showPoiPicker = false;
      this.form.lng = e.longitude;
      this.form.lat = e.latitude;
      this.form.address = e.address;
    },
    selectedLocation ( e, radius ) {
      this.showPoiPicker = false;
      this.form.lng = e[0];
      this.form.lat = e[1];
      this.form.address = e[2];
    },
    setIndustry ( names, codes ) {
      this.form.categoryCode = codes[2] || codes[1] || codes[0];
      this.form.categoryText = names[2] || names[1] || names[0];
    },
    setStoreType ( value, index ) {
      this.form.storeType = index + 1;
      this.form.storeTypeText = value;
      this.showStorePicker = false;
    },
  },
  computed: {
    ...mapGetters(['shopPoiInfo'])
  },
  watch  : {
    '$route.params' ( res ) {
      if (window.isClient) {
        this.loadData();
      }
    },
    entity ( res ) {
      this.loading = false;
      this.disabled = false;
      // 提取参数
      this.form.id = res.id;
      const params = {};
      for (const key in this.form) {
        if (key !== 'storeType') {
          if (res.hasOwnProperty( key )) {
            params[key] = res[key];
          } else {
            params[key] = this.form[key];
          }
        }
        // params[ key ] = res[ key ] || this.form[ key ];
      }
      this.form = params;
      this.form.stdQuestionId = this.id;
      this.form.storeTypeText = this.storeTypes[res.storeType ? res.storeType - 1 : 0];
      // console.log( this.form );
      // 限制条件
      if (this.id !== 10009) {
        this.lockSel = true;
        this.form.storeType = this.id === 10007 ? 1 : 2;
        this.form.storeTypeText = this.storeTypes[this.form.storeType - 1];
      } else {
        this.lockSel = false;
      }
    },
    shopPoiInfo(data) {
      console.log('更新poi数据')
      console.log('v',data)
      this.form.lng = this.shopPoiInfo.lng;
      this.form.lat = this.shopPoiInfo.lat;
      this.form.address = this.shopPoiInfo.address;
    }
  },
  activated () {
    console.log('activated',this.id)
    if (this.entity && window.localStorage.getItem('isSavedShop')) {
      this.HTTP.getAssessInfo( this.id ).then( ( res ) => {
        console.log('res',res,this.form)
        this.entity = res;
        window.localStorage.setItem('isSavedShop', '')
        // console.log( 'this.entity31231', res );
      })
    }
  },
  beforeMount () {
    this.isMini = window.isMini
    this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
    console.log( 'assess appCode:', window._appCode );
    // 调起分享按钮
    if (window.isClient) {
      this.JSBridge.callHandler( 'switchShareBtnV2', {
        code: 'TOOLS',
        id  : this.id,
      } );
      const share = {
        title: '店铺评估助手，大数据结合选址经验量化评估指标，选址不用愁！',
        desc : '店铺智能评估助手',
        cover: '/static/img/report/10009/cover.jpg',
      };
      this.JSBridge.callHandler( 'switchShareBtn', {
        type  : 'assess',
        id    : this.id,
        title : share.title,
        desc  : share.desc,
        cover : document.location.protocol + '//' + document.location.host + share.cover,
        url   : document.location.protocol + '//' + document.location.host + '/#/introduce/' + this.id,
        page  : '/pages/index/index',
        target: 'wxmp_tools',
      } );
    }

    // 加载数据
    this.loadData();
    this.JSBridge.registerHandler( 'onAppResume', ( data ) => {
      console.log( 'app 调用 onAppResume 函数成功' );
      if (this.id) {
        this.HTTP.getAssessInfo( this.id ).then( ( res ) => {
          if ((this.form.id!=0 && res.id==0) || this.form.id==0 && res.id!=0) {
            this.entity = res;
          }
          // console.log( 'this.entity31231', res );
        })
      }
    } );
  },
  beforeDestroy () {
    // 隐藏分享按钮
    this.JSBridge.callHandler( 'switchShareBtn', false );
    this.JSBridge.callHandler( 'switchShareBtnV2', false );
  },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.assess {
  height: 100vh;
  background-color: #3154A7;

  .header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    .btnColor {
      color: white;
    }
  }

  #{&}--view {
    position: absolute;
    top: 0;
    min-height: 100vh;
    z-index: 1;
    background: url(/static/img/assess/bg.jpg) no-repeat left top;
    background-size: 100% auto;

    .step {
      display: block;
      width: 74vw;
      height: 12.26vw;
      margin: 0 auto;
      margin-top: 30vw;
      &.mini-step {
        margin-top: 22vw;
      }
    }

    .view {
      width: 94.4vw;
      height: 132vw;
      background: url(/static/img/assess/view.png) no-repeat left top;
      background-size: 100% auto;
      margin: 8vw 2.8vw 0;
      padding: 45vw $padding * 1.5;
      box-sizing: border-box;

      .item {
        height: 13vw;
        @include flex;
        box-shadow: inset 0 -1px 0 #F3F3F3;

        .hr {
          box-shadow: inset -1px 0 0 #F3F3F3;
        }
      }

      .s-label {
        @include flex;
        width: 100%;
        height: 100%;
        font-size: $font_400;
        color: #171061;

        span {
          width: 18vw;
          font-weight: bold;
        }

        .inp {
          width: calc(100% - 18vw);
          height: $font_560;
          padding: 0;
          margin: 0;
          border: none;
          box-sizing: border-box;
          font-size: $font_400;
          text-align: right;

          &::placeholder {
            color: #C4C4C6;
          }

          &.warn::placeholder {
            color: $c-require;
          }
        }

        .sel.lock {
          background: none;
        }

        .sel, .icon {
          padding-right: 4vw;
          background: url(/static/icon/arrow_down_deep.png) no-repeat right center;
          background-size: 3vw;
        }

        .icon {
          padding-right: 4vw;
          background: url(/static/icon/arrow_down_deep.png) no-repeat right center;
          background-size: 4vw 4vw;

          &.icon-geo {
            background-image: url(/static/icon/location_line_deep.png);
          }
        }
      }
    }
  }

  #{&}--footerBar {
    @include flex;
    width: 100%;
    padding: $padding $padding_3;
    box-sizing: border-box;

    ::v-deep .van-button {
      height: 17vw;
      font-size: 7.5vw;
      font-weight: bold;
      line-height: normal;
      background: url(/static/img/assess/btn_short.png) no-repeat;
      background-size: 100% 100%;
      border: none;
      // -webkit-text-stroke: 1.5px $c-text;

      .van-button__text {
        @include flexColumn;

        .btn-text {
          height: 8vw;
          margin-top: 1.3vw;
        }
      }
    }

    ::v-deep .van-button:nth-child(1) {
      background-image: url(/static/img/assess/btn_light.png);
      width: 38vw;
    }

    ::v-deep .van-button:nth-child(2) {
      width: 53vw;
    }
  }
}
</style>
