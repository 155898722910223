<template>
    <div>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-show="!firstLoading">
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-1.png">
                <div class="nor-btn arrow" @click="deeplink({ type: '6', url: '#/community/goodsList?tag=门店运营', btnId: '80304' })">
                    查看更多门店运营干货
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-2.png">
                <div class="nor-btn arrow" @click="deeplink({ type: '5', url: 'OPEN_SHOP', btnId: '80302' })">
                    下载人力制度和管理表
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-3.png">
                <div class="nor-btn arrow" @click="deeplink({ type: '5', url: 'OPEN_SHOP', btnId: '80303' })">
                    下载经营财务报表
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-4.png">
                <div
                    class="nor-btn arrow"
                    @click="deepDataLink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '5' }, btnId: '80305' })"
                >
                    查询外卖经营和分布数据
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-5.png">
                <div class="nor-btn arrow" @click="deeplink({ type: '6', url: '#/community/goodsList?tag=外卖运营', btnId: '80306' })">
                    查看更多外卖运营干货
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-6.png">
                <div class="nor-btn arrow" @click="deeplink({ type: '6', url: '#/community/goodsList?tag=营销技巧', btnId: '80307' })">
                    查看更多营销干货
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab4-7.png">
                <div
                    class="nor-btn arrow"
                    style="bottom: 16vw"
                    @click="deeplink({ type: '6', url: '#/community/drgGoods?tab=经营管理', btnId: '80301' })"
                >
                    点击查看更多经营管理干货
                </div>
            </VanImage>
        </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
    name: 'OperatingManagement',
    data () {
        return {
            firstLoading: true
        };
    },
    methods: {
        imageLoadDone () {
            picNum ++;
            if ( picNum >= 6 ) {
                this.firstLoading = false;
            }
        },
        deeplink ( { type, url, params, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80300, btnId );
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                params: JSON.stringify( params )
            } );
        },
      // 跳转新的查数据页面
      deepDataLink( { type, url, params, btnId } ) {
        console.log( type, url, params, btnId, this.$route.meta.id );
        this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80100, btnId );
        if (window._appCode && window._appCode >= 300) {
          this.JSBridge.callHandler( 'onNormalizingCall', {
            type: 5,
            url: "CHANGE_MAIN_TAB",
            id: "queryData",
            isClear: true,
          } );
        } else {
          this.JSBridge.callHandler( 'onNormalizingCall', {
            type,
            url,
            params: JSON.stringify( params )
          } );
        }
      },
    }
};
</script>

<style lang="scss" scoped>
.van-image {
    width: 100%;
    margin-bottom: -4px;
    position: relative;
    ::v-deep .nor-btn {
        position: absolute;
        width: 78.66vw;
        height: 12.26vw;
        line-height: 12.26vw;
        border-radius: 1.06vw;
        background: linear-gradient(340deg, #23bf75 0%, #4bd287 100%);
        box-shadow: 0 0.66vw 2.66vw 0 rgba(46, 232, 130, 0.4);
        font-size: 4vw;
        color: #fff;
        bottom: 7.33vw;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: bold;
        &.mul-line {
            line-height: 3.5vw;
            font-size: 3.46vw;
            p {
                font-size: 2.93vw;
                padding-top: 2.13vw;
                padding-bottom: 1.13vw;
                font-weight: normal;
            }
        }
        &.free {
            &::before {
                position: absolute;
                content: '';
                width: 8.53vw;
                height: 4.8vw;
                right: -1.33vw;
                top: -2.66vw;
                background: url('/static/icon/icon_free.png') center / 100% 100% no-repeat;
            }
        }
        &.arrow {
            text-indent: -2.5vw;
            &::after {
                position: absolute;
                content: '';
                width: 4vw;
                height: 4vw;
                transform: translateY(-2vw);
                top: 50%;
                margin-left: 1vw;
                background: url('/static/icon/icon_arrow_right3.png') center / 4vw 4vw no-repeat;
            }
        }
    }
    ::v-deep .btn-group {
        position: absolute;
        bottom: 10.6vw;
        left: 50%;
        transform: translateX(-50%);
        width: 84vw;
        display: flex;
        justify-content: space-between;
        .nor-btn {
            position: unset;
            width: 40.66vw;
            transform: translateX(0%);
        }
        .left {
            box-sizing: border-box;
            background: #fff;
            color: #4262fb;
            border: 0.26vw solid #4262fb;
        }
    }
}
</style>
