<template>
    <div class="card" @click="openDetail(itemList.link)">
        <van-image
            :src="itemList.cover"
            lazy-load
            round
            fit="cover"
            class="photo">
            <template v-slot:loading>
                <van-loading type="spinner" size="20" />
            </template>
        </van-image>

        <div class="content">
            <h5 class="name">
                <span>{{itemList.name}}</span>
            </h5>
            <p class="desc">{{itemList.remark}}</p>
            <div class="btn_and_Pricde">
                <div class="left"><span>¥</span>{{ unitFunction(itemList.price) }}</div>
                <div class="right" v-if="itemList.sharePrice" @click.stop="openShare(itemList.appShareReq)">{{itemList.sharePrice}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'profile',
    props: {
        itemList: {
            type: Object
        }
    },
    methods: {
        unitFunction(obj) {
            return obj.substring(1, obj.length)
        },
        openDetail(url) {
            console.log(url)
            this.JSBridge.callHandler("onNormalizingCall", url);
        },
        // 分享
        openShare(obj) {
            this.JSBridge.callHandler("callShareV2", {
                code: obj.code,
                id: obj.id,
            });
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.card {
    @include flex;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 3.33vw 0;
    border-bottom: 1px solid #EDF1FB;
    .photo {
        width: 18.67vw;
        height: 18.67vw;
        margin-right: 2.67vw;
        border-radius: 1.33vw;
    }
    .content {
        flex: 1;
        height: 100%;
        overflow:hidden;
        .name {
            font-size: 4vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 5.6vw;
            white-space:nowrap;
            text-overflow: ellipsis;
            overflow:hidden;
        }
        .desc {
            font-size: 3.2vw;
            color: #888888;
            line-height: 5.6vw;
            white-space:nowrap;
            text-overflow: ellipsis;
            overflow:hidden;
        }
        .btn_and_Pricde{
            @include flex;
            margin-top: 1.2vw;
            line-height: normal;
            .left{
                font-size: 4.53vw;
                font-weight: bold;
                color: #FF5900;
                line-height: 4vw;
                flex: 1;
                vertical-align: middle;
                span{
                    font-size: 3.47vw;
                }
            }
            .right{
                font-size: 3.2vw;
                font-weight: bold;
                color: #FF5900;
                // line-height: 4.4vw;
                background: #FFFFFF;
                border-radius: 3.73vw;
                border: 0.27vw solid #FFBC98;
                padding: 1.6vw 2.67vw;
                text-align: center;
                box-sizing: border-box;
            }
        }
    }
    &:last-child{
        border-bottom: none;
    }
}
</style>
