<template>
    <div class="wrapper">
        <div class="map-skeleton" v-if="!firstLoading">
            <van-skeleton :loading="!firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
        </div>
        <StateBar></StateBar>
        <TitleHeader title="地图标注-店铺上传至地图" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView :header="true" :footer="true" class="location--view">
            <div id="container">
                <div class="btn back" v-if="!waiting" @click="returnHandle">
                    <img src="/static/icon/icon_back_entity.png" alt="" />
                </div>
                <div class="btn">
                    <img @click="positionHandle" src="/static/element/map_icon_location.png" alt="">
                </div>
                <div class="btn location">
                    <img @click="positionHandle" src="/static/icon/map_icon_needle.png" alt="">
                </div>
            </div>
        </AutoView>
        <SoltFooter>
            <div class="submitFooter">
                <van-button type="info" block class="s-radius__s" @click="clickSubmitHandle">确定</van-button>
            </div>
        </SoltFooter>
        <van-overlay :show="waiting">
            <div class="loading">
                <van-loading></van-loading>
                地图正在加载中
            </div>
        </van-overlay>
    </div>
</template>

<script>
import LoadMap from "@/components/baseMap/loadMap";
export default {
    props: {
        lat: Number,
        lng: Number
    },
    data() {
        return {
            city: "",
            citycode: "",
            lnglat: [],
            gpsInfo: {
                lat: "",
                lng: ""
            },
            waiting: true,
            loading: true, // 限制用户在定位中点击确定按钮
            firstLoading: false,
            map: null
        }
    },
    mounted() {
        this.JSBridge.callHandler( 'switchBackBtn', false );
        LoadMap().then(() => {
            this.waiting = false
            this.getLocation();
        });
    },
    methods: {
        getLocation() {
            let that = this;
            // 初始化地图
            that.map = new AMap.Map("container", {
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 14, //初始化地图层级
                center: [116.397428, 39.90923], //初始化地图中心点
            });
            // 由于浏览器限制，无法获取用户精准定位，所以通过原生获取定位
            if(that.lat && that.lng) {
                // 用户已经选了位置
                that.loading = false
                that.lnglat = [that.lng, that.lat]
                moveMap()
            } else {
                // 获取用户精确定位
                that.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                    // 兼容安卓
                    if ( typeof data === 'string' ) {
                        data = JSON.parse( data );
                    }
                    if(!data) {
                        that.$toast.fail("定位失败，请打开手机定位")
                        return
                    }
                    that.lnglat = [data.longitude, data.latitude]
                    // 进入位置选择，但没有移动，设置当前位置为默认位置
                    that.gpsInfo = {
                        lat: data.latitude,
                        lng: data.longitude
                    }
                    that.loading = false
                    moveMap()
                })
            }
            that.map.on("complete", function(){
                that.firstLoading = true
            })
            // 移动地图方法
            function moveMap() {
                that.map.panTo(that.lnglat)
                logMapinfo()
                that.map.on("moveend", logMapinfo);
                that.map.on("zoomend", logMapinfo);
            }
            function logMapinfo() {
                let center = that.map.getCenter(); //获取当前地图中心位置
                that.gpsInfo = {
                    lat: center.lat,
                    lng: center.lng
                }
            }
        },
        returnHandle() {
            this.$emit("return")
        },
        positionHandle() {
            if(this.loading) {
                this.$toast("正在定位中")
            } else {
                this.map.panTo(this.lnglat)
            }
        },
        clickSubmitHandle() {
            if(this.loading) {
                this.$toast("正在定位中")
            } else {
                this.$emit('goBack', this.gpsInfo)
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
#container {
    width: 100vw;
    height: 100%;
    position: relative;
}
.map-skeleton {
    width: 100vw;
    height: 100vh;
    padding-top: 5vw;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000000;
}
.submitFooter {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    border: 0.133vw solid #ebeaee;
    box-shadow: 0 -0.267vw 0.8vw 0 #ebeaee;
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
.btn {
    position: absolute;
    bottom: 2.667vw;
    right: 2.667vw;
    z-index: 2;
    background-color: #fff;
    width: 9.33vw;
    height: 9.33vw;
    box-shadow: 0 2.667vw 4vw 0 rgba(136, 141, 152, 0.27);
    border-radius: 2.667vw;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 5.067vw;
        height: 5.067vw;
    }
}
.back {
    top: 2.667vw;
    left: 2.667vw;
}
.location {
    top: 50%;
    left: 50%;
    background-color: transparent;
    box-shadow: none;
    transform: translate(-50%, -100%);
    img {
        width: 6.933vw;
        height: 9.6vw;
    }
}
.loading {
    width: 40vw;
    height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    background: #fff;
    border-radius: $radius-xs;
    font-size: $font_400;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
::v-deep .van-loading__spinner {
    width: 15vw;
    height: 15vw;
}
::v-deep .van-loading__text {
    color: #000;
}
</style>
