<template>
  <div class="wrapper">
        <StateBar bgcolor="white" />
        <div class="top">
            <img class="logo" src="/static/icon/jump_icon.png" alt="">
            <div class="title">上上参谋开店选址</div>
            <div class="appletWrapper">
                <img class="applet" src="/static/icon/applet_icon.png" alt="">
                <span>小程序</span>
            </div>
        </div>
        <div class="bottom">
            <div class="btn" @click="gotoWeiXin">前往微信打开</div>
            <div class="tip">无法打开时，可使用默认浏览器打开。<span v-clipboard:copy="url" v-clipboard:success="copySuccess" v-clipboard:error="copyError">复制链接</span></div>
        </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            url: "http://webclient.sscanmou.com/#/channel/smsJumpPage"
        }
    },
    mounted() {
        location.href = 'weixin://dl/business/?t=ronxY231zSd'
    },
    methods: {
        copySuccess() {
            this.$toast.success({
                position: top,
                message: "复制成功"
            })
        },
        copyError() {
            this.$toast.fail(
                {
                position: top,
                message: "复制失败"
            })
        },
        gotoWeiXin() {
            location.href = 'weixin://dl/business/?t=ronxY231zSd'
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    height: 100vh;
    position: relative;
}
.top {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30.4vw;
    .logo {
        width: 18.13vw;
        height: 18.13vw;
        margin-bottom: 3.73vw;
    }
    .title {
        margin-bottom: 3.73vw;
        color: #333333;
        font-size: 4.267vw;
        font-weight: 600;
    }
    .appletWrapper {
        color: #C3C3C3;
        display: flex;
        align-items: center;
        font-size: 3.73vw;
        .applet {
            width: 3.73vw;
            height: 3.73vw;
            margin-left: 1vw;
        }
    }
}
.bottom {
    position: absolute;
    bottom: 31.2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    .btn {
        width: 47.773vw;
        height: 11.466vw;
        background-color: #07C060;
        font-size: 4.267vw;
        text-align: center;
        line-height: 11.466vw;
        color: #fff;
        border-radius: 1.6vw;
        margin-bottom: 5.334vw;
    }
    .tip {
        color: #737373;
        font-size: 3.73vw;
        text-align: center;
        span {
            color: #347FFF;
        }
    }
}
</style>