<template>
    <div class="views">
        <div class="header">
            <template v-if="isClient">
                <StateBar :bgcolor="bgColor" />
                <TitleHeader :title="title" :bgcolor="bgColor" :back="true" class="no-mr">
                    <div class="explain-title" slot="right" @click="showExplain=true">
                        <span class="explain-icon-wrap">
                            <img class="explain-icon" src="/static/icon/icon_explain@2x.png" />
                        </span>
                        <span class="explain-label">订阅说明</span>
                    </div>
                </TitleHeader>
            </template>
        </div>

        <AutoView :footer="false" class="views--view">
            <iframe ref="view" :src="url" frameborder="0" class="innerView" @load="frameLoaded">不支持内嵌窗口</iframe>
        </AutoView>

        <LoadingOverlay :show="loading" />

        <!-- 订阅说明遮罩 -->
        <!-- class-name="explain-container" -->
        <van-overlay :show="showExplain" @click="showExplain = false">
            <div class="explain-wrap">
                <div class="explain-header">订阅说明</div>
                <!-- <div class="explain-content">
                    1. 店铺信息来源于数位线下大数据和公开的互联网数据。将定期为您更新。<br />
                    2. 数据包含团购套餐、代金券的销售额、价格变化、上新和下架频率，以及评论的分布与变化，当订阅的店铺没有动态时，表明该店铺暂无更新或操作。<br />
                    3. 订阅有效期过后，将不再更新店铺动态和经营报告，续订后继续更新店铺动态。<br />
                    4.本产品为虚拟产品，购买后不支持退款、转让、退换，本APP拥有最终解释权。
                </div> -->
                <div class="explain-content">
                    1.信息来源于数位线下大数据及公开的互联网数据，包含团购、代金券、外卖、评价等维度，定期更新<br />
                    2.团购及代金券数据为“过去半年”数据，外卖数据为“过去30天”数据<br />
                    3.每次数据的增减变化=本次数据与上次更新的数据对比（“本日的过去半年数据”与“昨日的过去半年数据”，“本日的过去30天数据”与“昨日的过去30天数据”），因此销量和销售额可能会存在减少的情况<br />
                    4.如果店铺是连锁品牌店铺，团购及代金券数据可能为多店通用数据<br />
                    5.当店铺无动态时，表示店铺暂无数据变化<br />
<!--                    6.订阅有效期过后，将不再更新店铺信息，续订后可恢复<br />-->
                    6.本产品为虚拟产品，购买后不支持退款、转让、退换，本APP拥有最终解释权
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
let _timer = 0;

export default {
    name      : 'views',
    components: {

    },
    data () {
        return {
            loading   : true, // 数据加载
            title     : '正在加载',
            bgColor   : 'transparent',
            isTop     : true, // 滚动条为0
            isReady   : false, // 界面已就绪
            isSended  : false, // 已下发数据
            isClient  : false,
            submitting: false,

            id    : 0, // 数据ID
            stdId : 0,
            first : false,
            host  : '/report/?id=',
            entity: null, // 数据实体
            maps  : null, // 用户权益映射表

            showExplain: false
        };
    },
    methods: {
        onBuyReport () {
            if ( this.submitting ) return;
            this.swRouter( `/pay/${this.id}` );
        },
        onShopSub ( tapType ) {
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.createShopSub( this.entity.shopId, this.entity.openShopId, tapType ).then( ( res ) => {
                if ( tapType === 2 ) {
                    // 续订
                    this.gotoPayment( res );
                } else if ( tapType === 3 ) {
                    this.gotoSuccess();
                }
            } ).catch( ( res ) => {
                this.$toast( '订阅失败，请稍候重试！' );
            } ).finally( () => {
                this.submitting = false;
            } );
        },
        onDataQuery () {
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.createDataQuery().then( ( res ) => {
                this.gotoPayment( res );
            } ).catch( ( res ) => {
                this.$toast( '网络不稳定，请稍候重试！' );
            } ).finally( () => {
                this.submitting = false;
            } );
        },
        switchShare ( st ) {
            // 分享按钮显示
        },
        addListener ( e ) {
            switch ( e.data.type ) {
                case 'ready': {
                    this.isReady = true;
                    console.log('addListener get ready');
                    this.sendData();
                    break;
                }
                case 'switchFullscreen': {
                    this.showMenu = ! e.data.content;
                    this.switchShare( this.showMenu );
                    break;
                }
                case 'switchScrollTop': {
                    if ( this.isTop === e.data.content ) return;
                    this.isTop   = e.data.content;
                    this.bgColor = this.isTop ? 'transparent' : 'white';
                    break;
                }
                case 'tapContinue': {
                    if ( this.stdId === 10012 ) {
                        const tapType = e.data.content || 2;
                        this.onShopSub( tapType );
                    }
                    break;
                }
                case 'changeShop': {
                    if ( this.stdId === 100012 ) {
                        this.gotoShopList();
                    }
                }
            }
        },
        sendData () {
            console.log('enter sendData');
            // 已发送或未就绪或未得到数据将不调用下发
            if ( this.isSended || ! this.isReady || ! this.entity ) return;
            this.isSended = true; // 标记为已下发
            clearTimeout( _timer ); // 清理超时提醒
            setTimeout( () => { this.loading = false; }, 1000 );
            console.log( 'shop post message:', this.url );
            this.viewTarget.postMessage( { type: 'data', content: this.entity }, '*' );
        },
        gotoPayment ( businessId ) {
            this.swRouter( `/pay/${businessId}` );
        },
        gotoSuccess () {
            this.swRouter( '/success?view=subscribe&use=test' );
        },
        gotoShopList () {
            this.swRouter( '/shop' );
        },
        frameLoaded() {
            console.log('frame loaded');
            // this.isReady = true;
        }
    },
    computed: {
        url () {
            if ( ! this.entity ) return 'about:blank';
            return this.host + this.id;
        },
        viewTarget () {
            return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
        },
    },
    created () {
        // 获取报告ID
        const id = this.$route.params.id;
        console.log( 'views id:', id );
        if ( ! id ) {
            this.$toast( '无效报告信息，即将返回首页！' );
            this.$router.push( '/' );
            return;
        }
        this.first = this.$route.query.first || false;
        // 获取报告地址
        this.host  = this.HTTP.getReportUrl();
        const entityHandler = ( res ) => {
            if ( typeof res === 'string' ) {
                res = JSON.parse( res );
            }
            this.title  = '店铺订阅';
            this.id     = res.shopId;
            _timer = setTimeout( () => {
                this.$toast( '无法获取报告，请稍候重试！' );
                this.loading = false;
            }, 30000 );
            this.$nextTick( () => {
                this.switchShare( true );
            } );
            // 数据处理
            if ( ! res.stdQuestionId ) res.stdQuestionId = 10012; // 兼容旧接口
            const stdId = res.stdQuestionId;
            this.stdId  = stdId;
            this.maps   = res.userBenefitMap || null;
            // 扩展字段
            res[ 'config' ] = {
                tokenId   : this.$store.state.token,
                templateId: stdId,
            };
            // 保存修改后的数据实体
            this.entity = res;
            console.log( 'entity:', this.entity );
            // 下发数据
            this.sendData();
        };

        const entity = this.$store.getters.temp;
        if ( entity && id === '' + entity.id ) {
            console.log( 'load from temp' );
            this.$store.commit( 'setTemp', null );
            entityHandler( entity );
        } else {
            // 加载网络数据
            // this.HTTP.getShopData( id ).then( ( res ) => {
            //     entityHandler( res );
            // } ).catch( ( e ) => {
            //     this.$toast( '网络不稳定，请稍候重试！' );
            // } );
            this.HTTP.getShopDetail( id ).then( ( res ) => {
                console.log( 'load from api' );
                entityHandler( res );
            } ).catch( ( e ) => {
                this.$toast( '网络不稳定，请稍候重试！' );
            } );
        }
        // 添加通信监听
        window.addEventListener( 'message', this.addListener );
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        // 设置客户端类型
        this.isClient = window.isClient || true;
    },
    beforeDestroy () {
        // 隐藏分享按钮
        this.JSBridge.callHandler( 'switchShareBtn', false );
    },
    destroyed () {
        // 移除通信监听
        window.removeEventListener( 'message', this.addListener );
        // 移除定时监听
        clearTimeout( _timer );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.views {
    overflow: hidden;
    height: 100vh;
    background-color: $c-pageBg2;

    .explain-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .explain-wrap {
        position: relative;
        border-radius: $radius;
        background-color: rgba(16, 30, 50, 0.88);
        margin-top: 60%;
        margin-left: 10%;
        width: 80%;
        padding: 30px 20px;
        box-sizing: border-box;
        .explain-header {
            color: #ffffff;
            font-size: 4vw;
            line-height: 5.6vw;
            text-align: center;
        }
        .explain-sub-header {
            color: #ffffff;
            font-size: 3.2vw;
            line-height: 4vw;
            text-align: left;
            margin-top: 3.2vw;
        }
        .explain-content {
            color: #ffffff;
            font-size: 2.9vw;
            line-height: 3.8vw;
            text-align: left;
            margin-top: 1.6vw;
        }
    }

    .header {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        ::v-deep {
            .stateBar, .titleHeader {
                @include ani(.25s, background-color);
            }
            .favrior {
                @include flexColumn;
                width: $headerHeight;
                height: $headerHeight;
            }
            .btnColor {
                color: $c-main;
            }
        }
        .explain-title {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 4vw;
            .explain-icon-wrap {
                position: relative;
                .explain-icon {
                    position: relative;
                    width: 3.5vw;
                    height: 3.7vw;
                }
            }
            .explain-label {
                color: #8E9099;
                font-size: 3.5vw;
                line-height: 5vw;
                text-align: left;
                margin-left: 0.8vw;
            }
        }
    }

    ::v-deep .titleHeader {
        &.sub {
            .title {
                padding-bottom: 5.75vw;
            }
        }
    }

    #{&}--view {
        overflow-y: hidden;
        height: 100vh!important;

        .innerView {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
