<template>
	<div id="mask" @click="close">
		<div @click.stop class="player">
      <div id="MaskPlayer"></div>
      <p class="title">{{title}}</p>
    </div>
	</div>
</template>

<script>
	import Player from "xgplayer";
	export default {
		data() {
			return {
				show: true
			}
		},
		props: {
			poster: {
				type: String,
				default: ""
			},
			src: {
				type: String
			},
			autoplay: {
				type: Boolean,
				default: false
			},
      title: {
        type: String,
        default: '',
      }
		},
		mounted() {
			let sscmPlayer = new Player({
				id: "MaskPlayer",
				poster: this.src+'?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600', // 封面图
				url: this.src,
				playsinline: true,
				"x5-video-player-type": "h5",
				"x5-video-player-fullscreen": "false",
				closeVideoClick: true,
				closeVideoTouch: true,
				autoplay: this.autoplay, // 自动播放
				videoInit: true, // 显示首帧
				fluid: true, // 流式布局
				// fitVideoSize: "fixWidth",
				ignores: ["volume"],
			});
		},
		methods:{
			close(){
				this.$emit('close')
			}
		}
	}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

	#mask {
		position: fixed;
		left: 0%;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, 1);
		z-index: 999;

		.player {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 100%;
			height: 53.3vw;
			background: #F2F3F8;
		}

    .title{
      text-align: center;
      color: #fff;
      font-size: vw(24) !important;
      padding: vw(15) !important;
    }
	}
</style>
