<template>
  <div class="page-container">
    <StateBar />
    <TitleHeader title="上上参谋2周年" bgcolor="transparent" :back="true"></TitleHeader>
    <AutoView ref="view">
      <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s">
      </van-skeleton>
      <div v-show="!firstLoading">
        <VanImage @load="imageLoadDone" src="/static/anniversary-activity/1.png">

        </VanImage>
        <VanImage @load="imageLoadDone" src="/static/anniversary-activity/2.png">
          <div>
            <div class="buy-btn buy-btn-1" @click="gotoDetail('10026', '1030201')"></div>
            <div class="buy-btn buy-btn-2" @click="gotoDetail('10001', '1030202')"></div>
            <div class="buy-btn buy-btn-3" @click="gotoDetail('10084', '1030203')"></div>
            <div class="buy-btn buy-btn-4" @click="gotoDetail('10092', '1030204')"></div>
          </div>
        </VanImage>
        <VanImage @load="imageLoadDone" src="/static/anniversary-activity/3.png">
          <div>
            <div class="vip-btn vip-btn-1" @click="goVipPage(1, '1030205')"></div>
            <div class="vip-btn vip-btn-2" @click="goVipPage(2, '1030206')"></div>
            <div class="vip-btn vip-btn-3" @click="goVipPage(3, '1030207')"></div>
          </div>
        </VanImage>
        <VanImage @load="imageLoadDone" src="/static/anniversary-activity/4.png">
          <div>
            <div class="sku-btn sku-btn-1" @click="gotoDetail('10001', '1030208')"></div>
            <div class="sku-btn sku-btn-2" @click="gotoDetail('10092', '1030209')"></div>
            <div class="sku-btn sku-btn-3" @click="gotoDetail('10084', '1030210')"></div>
            <div class="sku-btn sku-btn-4" @click="gotoDetail('10026', '1030211')"></div>
          </div>
        </VanImage>
        <VanImage @load="imageLoadDone" src="/static/anniversary-activity/5.png">
          <div>
            <div class="pck-btn pck-btn-1" @click="goPackDetail('119706909775157', '1030212')"></div>
            <div class="pck-btn pck-btn-2" @click="goPackDetail('231600333210390', '1030213')"></div>
            <div class="pck-btn pck-btn-3" @click="goPackDetail('250359980499785', '1030214')"></div>
            <div class="pck-btn pck-btn-4" @click="goPackDetail('137117003310658', '1030215')"></div>
          </div>
        </VanImage>
      </div>
    </AutoView>
  </div>
</template>

<script>
let picNum = 0
export default {
  name: 'anniversary-activity',
  data() {
    return {
      firstLoading: true,
    }
  },
  methods: {
    imageLoadDone() {
      picNum++
      if (picNum >= 3) {
        this.firstLoading = false
      }
    },
    gotoDetail(id, btnId) {
      this.JSBridge.BTNCLICK(10302, id, '1030200', btnId);
      if (window._appCode >= 300) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: 'BRAND_INTRODUCTION_V3',
          id: id,
          isClear: null,
        });
      } else {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: "BRAND_INTRODUCTION_V2",
          id: id,
          isClear: null,
        });
      }
    },
    goVipPage(id, btnId) {
      this.JSBridge.BTNCLICK(10302, id, '1030200', btnId);
      let url = '/h5/#/member'
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 6,
        url,
        isClear: null,
      });
    },
    goPackDetail(id, btnId) {
      if (process.env.NODE_ENV !== 'production') {
        id = '119704383542072'
      }
      this.JSBridge.BTNCLICK(10302, id, '1030200', btnId);
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 6,
        url: '#/payGoods/' + id,
        isClear: null,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .van-image {
    display: block;
    position: relative;
    .pck-btn {
      position: absolute;
      &.pck-btn-1 {
        width: 84vw;
        height: 22vw;
        left: 8vw;
        top: 90vw;
      }
      &.pck-btn-2 {
        width: 84vw;
        height: 22vw;
        left: 8vw;
        top: 114vw;
      }
      &.pck-btn-3 {
        width: 84vw;
        height: 22vw;
        left: 8vw;
        top: 139vw;
      }
      &.pck-btn-4 {
        width: 84vw;
        height: 22vw;
        left: 8vw;
        top: 163vw;
      }
    }
    .sku-btn {
      position: absolute;
      &.sku-btn-1 {
        width: 88vw;
        height: 43vw;
        left: 6vw;
        top: 23vw;
      }
      &.sku-btn-2 {
        width: 88vw;
        height: 43vw;
        left: 6vw;
        top: 68vw;
      }
      &.sku-btn-3 {
        width: 88vw;
        height: 43vw;
        left: 6vw;
        top: 115vw;
      }
      &.sku-btn-4 {
        width: 88vw;
        height: 43vw;
        left: 6vw;
        top: 160vw;
      }
    }
    .vip-btn {
      position: absolute;
      &.vip-btn-1 {
        width: 84vw;
        height: 57vw;
        left: 8vw;
        top: 23vw;
      }
      &.vip-btn-2 {
        width: 84vw;
        height: 57vw;
        left: 8vw;
        top: 85vw;
      }
      &.vip-btn-3 {
        width: 84vw;
        height: 45vw;
        left: 8vw;
        top: 147vw;
      }
    }
    .buy-btn {
      position: absolute;
      width: 41vw;
      height: 32vw;
      &.buy-btn-1 {
        left: 8vw;
        top: 41vw;
      }
      &.buy-btn-2 {
        left: 51vw;
        top: 41vw;
      }
      &.buy-btn-3 {
        left: 8vw;
        top: 75vw;
      }
      &.buy-btn-4 {
        left: 51vw;
        top: 75vw;
      }
    }
  }
</style>
