<template>
    <div class="assess">
        <div>
            <StateBar :bgcolor="bgColor" />
            <TitleHeader title="铺参谋主页" :color="titleColor" :bgcolor="bgColor" :back="true" />
        </div>
        <AutoView :footer="true" :header="true">
            <div class="brokerIntroduction" ref="top">
                <van-swipe @change="onChange" v-if="detailsObj.introduction">
                    <van-swipe-item v-for="(item, key) in detailsObj.introduction.videoList" :key="key + 'b'"
                        @click="videoOnceClick(item)">
                        <div class="banner-box">
                            <img class="banner" :src="item + '?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600'"
                                alt="" />
                            <img class="pay" src="../../../../static/shopEcology/list-pay.png" alt="" />
                        </div>
                    </van-swipe-item>
                    <van-swipe-item v-for="(item, key) in detailsObj.introduction.imageList" :key="key + 'a'"
                        @click="bannerClick(item)">
                        <img class="banner" :src="item" alt="" />
                    </van-swipe-item>
                    <template #indicator>
                        <div class="custom-indicator">
                            {{ current + 1 }}/{{ detailsObj.introduction.imageList.length +
                                    detailsObj.introduction.videoList.length
                            }}
                        </div>
                    </template>
                </van-swipe>
                <div class="brokerIntroduction-body">
                    <div class="people-box" v-if="detailsObj.introduction">
                        <div class="people-box-title">
                            <div class="name">{{ detailsObj.introduction.name }}</div>
                            <div class="small-title">{{ detailsObj.introduction.level }}</div>
                        </div>
                        <div class="people-content">
                            {{ detailsObj.introduction.introduction }}
                        </div>
                    </div>
                    <div class="number-box" v-if="detailsObj.introduction">
                        <div class="number-item">
                            <div class="number-item-title">选址服务</div>
                            <div class="number-item-content">
                                <div class="number">{{ detailsObj.introduction.recommendTimes }}</div>
                                <div class="type">次</div>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-item-title">商铺转让</div>
                            <div class="number-item-content">
                                <div class="number">{{ detailsObj.introduction.transferTimes }}</div>
                                <div class="type">个</div>
                            </div>
                        </div>
                        <div class="number-item">
                            <div class="number-item-title">评价</div>
                            <div class="number-item-content">
                                <div class="number">{{ detailsObj.introduction.score }}</div>
                                <div class="type">分</div>
                            </div>
                        </div>
                    </div>
                    <div class="address-box" v-if="detailsObj.introduction">
                        <div class="title">服务地区</div>
                        <div class="content">{{ detailsObj.introduction.serviceArea }}</div>
                    </div>
                    <!-- 选址案例 -->
                    <div class="siteSelectionEX-box" v-if="detailsObj.recommend">
                        <div class="siteSelectionEX-title"><img src="../../../../static/shopEcology/agent-index1.png"
                                alt="" />选址案例</div>
                        <div class="siteSelectionEX-content" @click="siteSelectionEXDetails()">
                            <div class="left-box" @click.capture="videoOnceClick1">
                                <!-- <img class="img" v-if="detailsObj.recommend"
									:src="detailsObj.recommend.videoList[0].url+'?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600'"
									alt=""> -->
                                <!-- <img class="pay" src="../../../../static/shopEcology/list-pay.png" alt=""> -->
                                <img class="img" src="../../../../static/shopEcology/ex1.png" alt="" />
                            </div>
                            <div class="right-box">
                                <div class="title ell-1">{{ detailsObj.recommend.title }}</div>
                                <div class="icon-box">
                                    <div class="icon-item"
                                        v-if="detailsObj.recommend && detailsObj.recommend.matchList.length">
                                        <template v-for="(item, key) in detailsObj.recommend.matchList">
                                            <div class="left" :key="key">
                                                {{ item.split(" ")[0] }}
                                            </div>
                                            <div class="right">{{ item.split(" ")[1] }}</div>
                                        </template>
                                    </div>
                                </div>
                                <div class="bottom-content ell-2"><span>结论：</span>{{ detailsObj.recommend.introduction
                                }}</div>
                            </div>
                            <div class="type"></div>
                        </div>
                    </div>
                    <!-- 商铺转让案例 -->
                    <div class="transferOfShops-box" v-if="detailsObj.transfer">
                        <div class="transferOfShops-title"><img src="../../../../static/shopEcology/agent-index2.png"
                                alt="" />商铺转让案例</div>
                        <div class="transferOfShops-content" @click="transferOfShopsDetails()">
                            <div class="left-box" @click="videoOnceClick2">
                                <!-- 	<img class="img" v-if="detailsObj.transfer"
									:src="detailsObj.transfer.videoList[0].url+'?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600'"
									alt="">
								<img class="pay" src="../../../../static/shopEcology/list-pay.png" alt=""> -->
                                <img class="img" src="../../../../static/shopEcology/ex2.png" alt="" />
                            </div>
                            <div class="right-box">
                                <div class="title ell-1">{{ detailsObj.transfer.title }}</div>
                                <div class="small-title">
                                    {{ detailsObj.transfer.area }} |
                                    {{ detailsObj.transfer.location }}
                                </div>
                                <div class="small-title">
                                    成交价 <span class="orange" style="font-weight: 600">{{ detailsObj.transfer.finalPrice
                                    }}</span> | 商铺租金
                                    <span class="orange" style="font-weight: 600">{{ detailsObj.transfer.rent }}</span>
                                </div>
                                <div class="icon-box" v-if="detailsObj.transfer">
                                    <template v-for="(item, key) in detailsObj.transfer.tagList">
                                        <div class="icon-item" :key="key">
                                            <div class="left" :key="key">
                                                {{ item.split(" ")[0] }}
                                            </div>
                                            <div class="right">{{ item.split(" ")[1] }}</div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="type"></div>
                        </div>
                    </div>
                    <!-- 用户评价 -->
                    <div class="user-evaluation-box" v-if="detailsObj.commentList && detailsObj.commentList.length">
                        <div class="user-evaluation-title"><img src="../../../../static/shopEcology/agent-index3.png"
                                alt="" />用户评价</div>
                        <div class="user-evaluation-body">
                            <div class="user-evaluation-item" v-for="(item, key) in detailsObj.commentList" :key="key">
                                <div class="left-box">
                                    <img v-if="item.avatar" :src="item.avatar" alt="" />
                                </div>
                                <div class="right-box">
                                    <div class="name">{{ item.name }}</div>
                                    <div class="content">
                                        {{ item.content }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 服务内容 -->
                    <div class="serviceContent-box" v-if="detailsObj.serviceList && detailsObj.serviceList.length">
                        <div class="serviceContent-title"><img src="../../../../static/shopEcology/agent-index4.png"
                                alt="" />服务内容</div>
                        <div class="siteSelectionEX-content" v-for="(item, key) in detailsObj.serviceList" :key="key">
                            <div class="left-box" @click="videoOnceClick3(item, key)">
                                <img class="img" v-if="item.videoList"
                                    :src="item.videoList[0] + '?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600'"
                                    alt="" />
                                <img class="pay" v-if="item.videoList" src="../../../../static/shopEcology/list-pay.png"
                                    alt="" />
                            </div>
                            <div class="right-box" @click="siteSelectionEXContent(key)">
                                <div class="title">{{ item.title }}</div>
                                <div class="content ell-2"><span>服务内容：</span>{{ item.content }}</div>
                                <div class="bottom" v-if="item.characteristic">{{ item.characteristic }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AutoView>
        <div class="button-bottom-box" ref="footer">
            <div class="left-box" @click="intentionMaskClick"></div>
            <div class="right-box" @click="submitMaskClick"></div>
        </div>
        <!-- 提交选址意向 -->
        <intention-mask v-if="intentionMaskType" @submit="intentionSubmit" @close="intentionMaskType = false">
        </intention-mask>
        <!-- 转让意向 -->
        <transfer-intention-mask v-if="transferIntentionMaskType" @submit="transferIntentionSubmit"
            @close="transferIntentionMaskType = false"></transfer-intention-mask>
        <!-- 视频弹窗 -->
        <video-mask v-if="videoMaskType" @close="videoMaskType = false" :src="videoSrc"></video-mask>
        <!-- 图片弹窗 -->
        <image-mask v-if="imgMaskType" @close="imgMaskType = false" :src="imgSrc"></image-mask>
    </div>
</template>

<script>
import PlayVideo from "@/components/VideoPlay/index";
import intentionMask from "../components/intention-mask.vue";
import transferIntentionMask from "../components/transfer-intention-mask.vue";
import videoMask from "../components/video-mask";
import imageMask from "../components/image-mask";
import { saveSitingIntention, detail } from "@/request/shopEcology";
export default {
    data() {
        return {
            current: 0,
            intentionMaskType: false,
            transferIntentionMaskType: false,
            videoMaskType: false, //视频播放弹窗
            detailsObj: {},
            videoSrc: "",
            imgMaskType: false,
            imgSrc: "",
            isSubmit: false
        };
    },
    mounted() {
        this.getDetails();
    },
    components: {
        PlayVideo,
        intentionMask,
        transferIntentionMask,
        videoMask,
        imageMask,
    },
    methods: {
        getDetails() {
            //获取铺参谋详情
            detail(this.$route.query.id).then((res) => {
                console.log(res);
                this.detailsObj = res;
            });
        },
        intentionSubmit(form) {
            // 选址意向
            if (this.isSubmit) return;
            this.isSubmit = true;
            form.brokerUserId = this.$route.query.id;
            // 按钮点击_提交选址意向成功
            saveSitingIntention(form).then((res) => {
                this.intentionMaskType = false;
                this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134900, 134901);
                this.swRouter({
                    path: "/shopEcology/intentionsubmitSuccessful",
                    query: {
                        id: res,
                    },
                });
            }).finally(e => {
                this.isSubmit = false;
            });
        },
        transferIntentionSubmit(form) {
            //转让意向
            if (this.isSubmit) return;
            this.isSubmit = true;
            form.brokerUserId = this.$route.query.id;
            saveSitingIntention(form).then((res) => {
                this.transferIntentionMaskType = false;
                this.JSBridge.BTNCLICK(this.$route.meta.id, null, 135000, 135001);
                this.swRouter({
                    path: "/shopEcology/intentionsubmitSuccessful",
                    query: {
                        id: res,
                    },
                });
            }).finally(e => {
                this.isSubmit = false;
            });
        },
        onChange(index) {
            this.current = index;
        },
        bannerClick(item) {
            //按钮点击_图片
            this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134200, 134302);
            this.imgSrc = item;
            this.imgMaskType = true;
        },
        videoOnceClick(item) {
            // 按钮点击_视频
            this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134200, 134301);
            this.videoSrc = item;
            this.videoMaskType = true;
        },
        videoOnceClick1(item) {
            // 选址案例 按钮点击_视频
            // this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134400, 134402);
            // this.videoSrc = "https://media.swguancha.com/pc-videos/1.mp4"
            // this.videoMaskType = true
        },
        siteSelectionEXDetails() {
            //选址案例详情
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134400, 134401);
            this.swRouter({
                path: "/shopEcology/transferShops",
            });
        },
        videoOnceClick2(item) {
            // 商铺转让案例 按钮点击_视频
            // this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134500, 134502);
            // this.videoSrc = "https://media.swguancha.com/pc-videos/1.mp4"
            // this.videoMaskType = true
        },
        transferOfShopsDetails() {
            // 商铺转让详情
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134500, 134501);
            this.swRouter({
                path: "/shopEcology/siteSelectionCase",
            });
        },
        intentionMaskClick() {
            // 帮我选址
            if (window._appCode && window._appCode >= 307) {
                this.swRouter({
                    path: "/shopEcology/intentionSubmit",
                    query: {
                        id: this.$route.query.id,
                    },
                });
            } else {
                // 兼容旧版本
                this.intentionMaskType = true;
            }
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134800, 134801);
        },
        submitMaskClick() {
            //帮我转让铺
            this.transferIntentionMaskType = true;
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134600, 134802);
        },
        videoOnceClick3(item) {
            //  按钮点击_视频
            if (key === 1) {
                //转让服务
                this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134800, 134702);
            } else {
                //选址服务
                this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134800, 134602);
            }
            this.videoSrc = item.videoList[0];
            this.videoMaskType = true;
        },
        // videoOnceClick4(item) {
        // 	// 商铺转让 按钮点击_视频
        // 	this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134700, 134702);
        // 	this.videoSrc = "https://media.swguancha.com/pc-videos/1.mp4"
        // 	this.videoMaskType = true
        // },
        siteSelectionEXContent(key) {
            if (key === 1) {
                //转让服务
                this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134600, 134701);
                this.swRouter({
                    path: "/shopEcology/shopTransfer",
                });
            } else {
                //选址服务
                this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134600, 134601);
                this.swRouter({
                    path: "/shopEcology/brokerServices",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
    @return $args/750 * 100+vw;
}

.assess {
    height: 100vh;
}

.brokerIntroduction {
    background-color: #f2f3f8;
    box-sizing: border-box;

    .custom-indicator {
        position: absolute;
        right: vw(20);
        bottom: vw(30);
        width: vw(69);
        height: vw(34);
        line-height: vw(34);
        background: rgba(29, 34, 51, 0.5);
        border-radius: vw(17);
        font-size: vw(22);
        font-weight: 600;
        color: #ffffff;
        text-align: center;
    }

    .brokerIntroduction-body {
        padding: vw(30) vw(20);
        box-sizing: border-box;
        background: #f2f3f8 url("../../../../static/shopEcology/borkerBackground.png") no-repeat;
        background-size: 100% vw(422);
        border-radius: vw(24) vw(24) 0 0;
        margin-top: -vw(20);
        position: relative;
        z-index: 200;
    }

    .banner {
        width: 100%;
        height: vw(400);
    }

    .banner-box {
        position: relative;
        height: vw(400);

        .pay {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: vw(64);
            height: vw(64);
        }
    }

    .people-box {
        .people-box-title {
            display: flex;

            .name {
                height: vw(36);
                font-size: vw(36);
                font-weight: 600;
                color: #242424;
                line-height: vw(36);
            }

            .small-title {
                margin-left: vw(14);
                height: vw(34);
                line-height: vw(34);
                background: linear-gradient(90deg, #ffe9ad 0%, #f2d299 100%);
                box-shadow: inset 0px vw(1) 0px 0px rgba(255, 255, 255, 0.5), inset 0px - vw(2) 0px 0px #000000;
                border-radius: vw(4);
                font-size: vw(22);
                font-weight: 600;
                color: #a74e3d;
                text-shadow: vw(1) vw(1) 0px #ffffff;
                padding: 0 vw(10);
            }
        }

        .people-content {
            margin-top: vw(19);
            font-size: vw(24);
            line-height: vw(33);
            color: #676c84;
        }
    }

    .number-box {
        display: flex;
        margin-top: vw(30);
        height: vw(130);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, #f5f8ff 100%);
        border-radius: vw(10);
        border: vw(2) solid #ffffff;

        .number-item {
            flex: 1;

            .number-item-title {
                margin-top: vw(24);
                font-size: vw(24);
                text-align: center;
                color: #1d2233;
                line-height: vw(33);
            }

            .number-item-content {
                height: vw(20);
                line-height: vw(20);
                display: flex;
                margin-top: vw(20);
                justify-content: center;

                .number {
                    font-size: vw(32);
                    color: #347fff;
                    font-weight: 600;
                }

                .type {
                    font-size: vw(20);
                    color: #1d2233;
                    margin-left: vw(4);
                    line-height: vw(24);
                }
            }
        }
    }

    .address-box {
        display: flex;
        justify-content: space-between;
        margin-top: vw(20);
        height: vw(90);
        line-height: vw(90);
        background: #ffffff;
        border-radius: vw(10);
        padding: 0 vw(30);
        box-sizing: border-box;

        .title {
            font-size: vw(24);
            font-weight: 600;
            color: #242424;
        }

        .content {
            font-size: vw(20);
            color: #878787;
        }
    }

    .siteSelectionEX-box {
        margin-top: vw(20);
        height: vw(280);
        background: #ffffff;
        border-radius: vw(10);
        padding: vw(5) vw(30) vw(30);
        box-sizing: border-box;
        position: relative;

        .siteSelectionEX-title {
            height: vw(80);
            font-size: vw(28);
            font-weight: 600;
            line-height: vw(80);
            color: #1d2233;
            display: flex;
            align-items: center;

            img {
                width: vw(28);
                height: vw(28);
                margin-right: vw(10);
            }
        }

        .siteSelectionEX-content {
            height: vw(180);
            border-radius: vw(4);
            display: flex;
            box-sizing: border-box;

            .left-box {
                width: vw(164);
                height: vw(164);
                background: #ffffff;
                background: #cdcdcd;
                position: relative;
                border-radius: vw(8);

                .img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    border-radius: vw(8);
                }

                .pay {
                    width: vw(64);
                    height: vw(64);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .right-box {
                flex: 1;
                padding-left: vw(20);
                box-sizing: border-box;

                .title {
                    height: vw(40);
                    font-size: vw(28);
                    font-weight: 600;
                    line-height: vw(40);
                    color: #1d2233;
                }

                .icon-box {
                    display: flex;
                    margin-top: vw(10);

                    .icon-item {
                        display: flex;
                        box-sizing: border-box;
                        height: vw(42);
                        text-align: center;
                        background: rgba(255, 128, 71, 0.1);
                        border-radius: vw(4);
                        align-items: center;

                        .left {
                            font-size: vw(22);
                            color: #1d2233;
                            padding: 0 vw(10) 0 vw(14);
                        }

                        .right {
                            font-size: vw(22);
                            padding-right: vw(14);
                            color: #fd793d;
                            font-weight: 600;
                        }
                    }
                }

                .bottom-content {
                    margin-top: vw(14);
                    font-size: vw(22);
                    line-height: vw(30);
                    color: #676c84;

                    span {
                        color: #121622;
                        font-weight: 600;
                    }
                }
            }

            .type {
                width: vw(100);
                height: vw(120);
                position: absolute;
                right: 0;
                top: vw(20);
                background: url("../../../../static/shopEcology/exType.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .transferOfShops-box {
        margin-top: vw(20);
        height: vw(280);
        background: #ffffff;
        border-radius: vw(10);
        padding: vw(5) vw(30) vw(30);
        box-sizing: border-box;
        position: relative;

        .transferOfShops-title {
            height: vw(80);
            font-size: vw(28);
            font-weight: 600;
            line-height: vw(80);
            color: #1d2233;
            display: flex;
            align-items: center;

            img {
                width: vw(28);
                height: vw(28);
                margin-right: vw(10);
            }
        }

        .transferOfShops-content {
            height: vw(180);
            border-radius: vw(4);
            display: flex;
            box-sizing: border-box;

            .left-box {
                width: vw(164);
                height: vw(164);
                background: #ffffff;
                border-radius: vw(8);
                background: #cdcdcd;
                position: relative;

                .img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    border-radius: vw(8);
                }

                .pay {
                    width: vw(64);
                    height: vw(64);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .right-box {
                flex: 1;
                padding-left: vw(20);
                box-sizing: border-box;

                .title {
                    height: vw(40);
                    font-size: vw(28);
                    font-weight: 600;
                    line-height: vw(40);
                    font-weight: 600;
                    color: #1d2233;
                }

                .small-title {
                    height: vw(30);
                    font-size: vw(22);
                    line-height: vw(30);
                    color: #676c84;
                    margin-top: vw(6);
                }

                .orange {
                    color: #a74e3d;
                }

                .icon-box {
                    display: flex;
                    margin-top: vw(10);

                    .icon-item {
                        height: vw(42);
                        border-radius: vw(4);
                        font-size: vw(22);
                        color: #696969;
                        line-height: vw(42);
                        margin-right: vw(10);
                        padding: 0 vw(14);
                        background: #f7f8fb;
                        display: flex;

                        .left {
                            color: #676c84;
                        }

                        .right {
                            color: #347fff;
                            margin-left: vw(10);
                            font-weight: 600;
                        }
                    }
                }
            }

            .type {
                width: vw(100);
                height: vw(120);
                position: absolute;
                right: 0;
                top: vw(20);
                background: url("../../../../static/shopEcology/exType.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .user-evaluation-box {
        background: #ffffff;
        border-radius: vw(10);
        margin-top: vw(20);
        padding: vw(25) vw(30) vw(10);
        box-sizing: border-box;

        .user-evaluation-title {
            height: vw(40);
            font-size: vw(28);
            font-weight: 600;
            line-height: vw(40);
            color: #1d2233;
            display: flex;
            align-items: center;

            img {
                width: vw(28);
                height: vw(28);
                margin-right: vw(10);
            }
        }

        .user-evaluation-body {
            margin-top: vw(30);

            .user-evaluation-item {
                display: flex;
                margin-bottom: vw(30);

                .left-box {
                    width: vw(60);
                    height: vw(60);
                    background: #d8d8d8;
                    border: vw(2) solid #f2f3f8;
                    border-radius: 50%;

                    img {
                        width: vw(60);
                        height: vw(60);
                        background: #d8d8d8;
                        border: vw(2) solid #f2f3f8;
                        border-radius: 50%;
                    }
                }

                .right-box {
                    flex: 1;
                    padding-left: vw(10);
                    box-sizing: border-box;

                    .name {
                        height: vw(33);
                        font-size: vw(24);
                        font-weight: 600;
                        line-height: vw(33);
                        color: #1d2233;
                    }

                    .content {
                        font-size: vw(22);
                        line-height: vw(30);
                        margin-top: vw(10);
                        background: #f7f8fb;
                        border-radius: vw(2) vw(10) vw(10) vw(10);
                        padding: vw(16) vw(18);
                        box-sizing: border-box;
                        color: #676c84;
                    }
                }
            }
        }
    }

    .serviceContent-box {
        background: #ffffff;
        border-radius: vw(10);
        margin-top: vw(20);
        padding: vw(25) vw(30) vw(10);
        box-sizing: border-box;

        .serviceContent-title {
            height: vw(40);
            font-size: vw(28);
            font-weight: 600;
            line-height: vw(40);
            color: #1d2233;
            margin-bottom: -vw(10);
            display: flex;
            align-items: center;

            img {
                width: vw(28);
                height: vw(28);
                margin-right: vw(10);
            }
        }

        .siteSelectionEX-content {
            border-radius: vw(4);
            display: flex;
            padding: vw(30) 0 vw(30);
            border-bottom: vw(1) solid #e4e7ed;

            .left-box {
                width: vw(164);
                height: vw(164);
                background: #ffffff;
                border-radius: vw(8);
                background: #cdcdcd;
                position: relative;

                .img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    border-radius: vw(8);
                }

                .pay {
                    width: vw(64);
                    height: vw(64);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .right-box {
                flex: 1;
                padding-left: vw(20);
                box-sizing: border-box;

                .title {
                    height: vw(40);
                    font-size: vw(28);
                    font-weight: 600;
                    line-height: vw(40);
                    color: #1d2233;
                }

                .content {
                    margin-top: vw(10);
                    height: vw(60);
                    font-size: vw(22);
                    line-height: vw(30);
                    color: #121622;

                    span {
                        color: #121622;
                        font-weight: 600;
                    }
                }

                .bottom {
                    margin-top: vw(12);
                    height: vw(42);
                    font-size: vw(22);
                    font-weight: 600;
                    color: #a74e3d;
                    line-height: vw(40);
                    border-radius: vw(4);
                    border: vw(1) solid #a74e3d;
                    box-sizing: border-box;
                    text-align: center;
                    padding: 0 vw(10);
                    display: inline-block;
                }
            }
        }

        .siteSelectionEX-content:last-child {
            border: none;
        }
    }
}

.button-bottom-box {
    width: 100%;
    padding: vw(16) vw(20) vw(20);
    box-sizing: border-box;
    background: #fff;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);

    .left-box {
        width: 49%;
        height: vw(80);
        background: url("../../../../static/shopEcology/agent-index5.png") no-repeat;
        background-size: 100% 100%;
    }

    .right-box {
        width: 49%;
        height: vw(80);
        background: url("../../../../static/shopEcology/agent-index6.png") no-repeat;
        background-size: 100% 100%;
    }
}
</style>
