<template>
    <div class="stackView">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'stackView',
};
</script>

<style lang="scss" scoped>
.stackView {
    flex: auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
}
</style>
