const conditionInput = () => import('@/views/pointRadar/condition-input/index');
const successful = () => import('@/views/pointRadar/successful/index');
const radarList = () => import('@/views/pointRadar/radar-list/index');
const payOrder = () => import('@/views/pointRadar/pay-order/index');
const pointReport = () => import('@/views/pointRadar/report');
export default [
  {
        path: '/pointRadar/conditionInput',
        name: 'conditionInput', // 点位雷达条件输入页
        component: conditionInput,
        meta: {
            id: 10310
        },
    },
    {
        path: '/pointRadar/payOrder',
        name: 'payOrder', // 点位雷达订单支付页
        component: payOrder,
        meta: {
            id: 10311
        },
    },
    {
        path: '/pointRadar/successful',
        name: 'successful', // 点位雷达支付成功页
        component: successful,
        meta: {
            id: 10312
        },
    },
    {
        path: '/pointRadar/radarList',
        name: 'radarList', // 我的雷达页面
        component: radarList,
        meta: {
            id: 10313
        },
    },
    {
      path: '/pointRadar/report/:id',
      name: 'pointReport', // 点位雷达结果页
      component: pointReport,
      meta: {
        id: 10314
      },
    },
]

