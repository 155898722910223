<template>
    <div id="ipRight" class="page" :class="{ ipx: isIPX }">
        <StateBar />
        <TitleHeader title="知识产权保护指引" bgcolor="transparent" :back="true" />

        <AutoView :header="true" :footer="true" class="page--view">
            <h3 class="title">{{ JSBridge.appName() }}知识产权保护（投诉）指引</h3>
            <p class="text"><strong>1</strong>、下载并按说明填写《品牌修正（删除）信息申请表》。个人用户由本人签字，企业用户请加企业公章。</p>
            <p class="text"><strong>2</strong>、将申请表、营业执照扫描件（个人用户提供身份证扫描件）以及商标注册证等其他相关证明资料一并发送至投诉邮箱<strong>sscm.kefu@nf-3.com</strong>，邮件主题注明，<strong>XXX</strong>公司关于<strong>XXX</strong>修正（删除）申请。</p>
            <br>
            <p class="text"><strong>反馈流程</strong></p>
            <p class="text"><strong>1</strong>、客服收到您的投诉材料，并核实投诉人身份与投诉内容的真实与合法性后，交予相关部门尽快处理，请耐心等待处理结果。</p>
            <p class="text"><strong>2</strong>、在投诉发起后，请注意查收回函，我们会将处理结果在3-7个工作日内发送到您的邮箱。</p>
            <br>
            <p class="text"><strong>以下情况将不会受理申请内容</strong></p>
            <p class="text"><strong>1</strong>、投诉材料不完整、不清晰或者资料涉嫌盗用及虚假的均不被受理</p>
            <p class="text"><strong>2</strong>、投诉内容非我司处理范围的不被受理</p>
            <p class="text"><strong>3</strong>、投诉内容无侵犯其他机构或个人权益的行为不被受理</p>
            <br>
            <p class="label">
                <strong>注：</strong><br>
                <strong>1</strong>、为了保证您的问题能够及时有效的处理，请您在提交材料的时候，务必提交真实有效、完整清晰的材料，以免造成您的问题处理不成功或无法受理。<br>
                <strong>2</strong>、当您的投诉已成功提交后，请耐心等待结果即可。反复投诉或提交相同的多个投诉，均不被受理。
            </p>
        </AutoView>

        <SoltFooter>
            <div class="page--footer">
                <van-button type="info" block class="s-radius__s btn1" @click="onPreview">{{ getText }}</van-button>
                <van-button type="info" block class="s-radius__s btn2" @click="onDownload">下载申请表</van-button>
            </div>
        </SoltFooter>
    </div>
</template>

<script>
import { ImagePreview } from 'vant';

let _instance = null;

export default {
    name: 'page',
    data () {
        return {
            showPreview: false,
            isIPX      : false,
            picture    : '/static/img/ipRight.png',
            fileUrl    : 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/doc/%E5%93%81%E7%89%8C%E4%BF%AE%E6%AD%A3%EF%BC%88%E5%88%A0%E9%99%A4%EF%BC%89%E4%BF%A1%E6%81%AF%E7%94%B3%E8%AF%B7%E8%A1%A8.doc',
        };
    },
    methods: {
        onPreview () {
            if ( this.showPreview && _instance ) {
                this.showPreview = false;
                _instance.close();
            } else {
                this.showPreview = true;
                _instance = ImagePreview( {
                    images      : [ this.picture ],
                    loop        : false,
                    showIndex   : false,
                    closeable   : false,
                    className   : 'viewFloat',
                    getContainer: '#ipRight',
                    onClose     : () => {
                        this.showPreview = false;
                    },
                } );
            }
        },
        onDownload () {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'callDownload', {
                    title: '品牌修正（删除）信息申请表',
                    file : this.fileUrl.substr( this.fileUrl.lastIndexOf( '/' ) + 1 ),
                    size : 34816,
                    mime : 'application/msword',
                    url  : this.fileUrl,
                } );
            } else {
                location.href = this.fileUrl;
            }
        },
    },
    computed: {
        getText () {
            return this.showPreview ? '关闭预览' : '预览申请表';
        },
    },
    beforeMount () {
        if ( window.isClient && ! window.isAndroid ) {
            this.isIPX = window.isIPX;
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.page {
    height: 100vh;

    #{&}--view {
        padding: $margin $padding;

        .title {
            font-size: $font_400;
            line-height: $font_560;
            font-weight: bold;
            text-align: center;
            margin-bottom: $margin;
        }
        .text {
            font-size: $font_400;
            line-height: $font_560;
        }
        .label {
            color: $c-info;
            font-size: $font_400;
            line-height: $font_560;
        }
    }

    #{&}--footer {
        @include flex;
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;

        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }

        .btn1 {
            width: 60vw;
            margin-right: $margin;
            padding: 0;
            background-color: white;
            color: $c-main;
        }
    }

    ::v-deep .viewFloat, ::v-deep .van-image-preview__overlay {
        height: calc( 100vh - #{$footerHeight} );
    }
    &.ipx {
        ::v-deep .viewFloat, ::v-deep .van-image-preview__overlay {
            height: calc( 100vh - #{$footerHeight} - #{$iPXBottomBar} );
        }
    }
}
</style>
