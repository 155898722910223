<template>
    <div class="success">
        <TitleHeader :title="title" bgcolor="#F2F3F8" :back="false" />

        <AutoView :header="true" :footer="false" class="success--view l-view">
            <div class="success--tips l-panel s-radius__s">
                <div class="success--info" v-if="showTitle">
                    <div class="title" v-if="reportId && (type === 'payment' ||type === 'shopDesign' )">
                        您的「 <span>{{ reportName }}</span> 」{{ reportType }}
                    </div>
                    <div class="status">
                        <img src="/static/icon/location_icon_yes.png" alt="" />
                        <div>{{type === 'payment' ? '已提交专家团队' : '已成功购买'}}</div>
                    </div>
                </div>
                <van-image class="icon" fit="cover" :src="icon" />
                <h3>{{ description }}</h3>
                <p class="sub-msg" v-if="subDescription && subDescription.length > 0">{{ subDescription }}</p>
                <p v-html="tips"></p>
                <p v-html="text"></p>

                <template v-if="Array.isArray(button)">
                    <div class="success--btnbar">
                        <template v-for="(btn, index) of button">
                            <van-button :key="index" :type="btn.type || 'info'" :plain="btn.line" @click="onTapCustom(btn)">{{
                                btn.text
                            }}</van-button>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <van-button :type="!platform ? 'info' : 'warning'" @click="onTap">{{type === 'payment' ? '我的报告' : '去查看'}}</van-button>
                </template>
            </div>
            <van-swipe v-if="!platform && isShowAd" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="item in linkList" :key="item.image">
                    <div class="ele_ad" @click="handleClick(item.link, item.id)">
                        <img :src="item.image" alt="" />
                    </div>
                </van-swipe-item>
            </van-swipe>
        </AutoView>
      <!-- 支付等待遮罩 -->
      <van-overlay :show="creating">
        <div class="s-overlay">
          <van-loading color="#518CDB" />
        </div>
      </van-overlay>
    </div>
</template>

<script>
import { createReportV450, getInputById, getInputByIdV450 } from '@/request/order';

export default {
    name: "success",
    data() {
        return {
            type: "payment",
            stdId: 10001,
            // jumping: false,
            view: null,
            isShowAd: false,
            linkList: [],
            reportName: "",
            reportType: "",
            reportId: "",
            map: {
                bill: {
                    title: "开发票",
                    icon: "/static/icon/msg_success_orange.png",
                    message: "电子发票提交成功",
                    text: "发票将发送至您填写的邮箱",
                    button: "查看开票进度",
                    url: "/billHistory?home=1",
                },
                custom: {
                    title: "提交成功",
                    icon: "/static/icon/msg_success_orange.png",
                    message: "我们已收到您的需求，小参会尽快答复您！",
                    button: "返回首页",
                    url: "/",
                },
                default: {
                    title: this.JSBridge.appName(),
                    // icon   : 'icon.png',
                    message: "欢迎使用" + this.JSBridge.appName(),
                    text: "开店选址，参谋一下！",
                    button: "前往下载",
                    url: "/download",
                },
                export: {
                    title: "解锁数据导出",
                    icon: "/static/icon/payment_success.png",
                    message: "解锁成功",
                    text: "请至“我的-我的下载”查看",
                    button: "确定",
                    goto: "my-download_tab1",
                },
                package: {
                    title: "支付结果",
                    icon: "/static/icon/payment_success.png",
                    message: "购买成功",
                    text: "请至“我的购买”查看",
                    button: "去查看",
                    url: "/h5/#/my/packages",
                },
                kaCustom: {
                    title: "支付结果",
                    icon: "/static/icon/payment_success.png",
                    message: "购买成功",
                    text: "请至“我的-我的订单”查看",
                    button: "确定",
                    url: "/h5/#/orders",
                },
                payment: {
                    title: "解锁报告",
                    icon: "/static/icon/payment_success.png",
                    message: "正在为您生成精准报告",
                    text: "24小时内可查看，请您耐心等待",
                    tips: "专家团队马不停蹄为您输出专业报告",
                    button: "确定",
                    url: "/h5/#/my/reports",
                    report: () => {
                        // 埋点上报
                        this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.TAP_OK);
                    },
                },
                paymentNearby: {
                    title: "解锁报告",
                    icon: "/static/icon/payment_success.png",
                    message: "解锁成功",
                    text: "请至“我的-我的报告”查看",
                    tips: "预计24小时内推送（周末、节假日顺延至工作日）",
                    button: "确定",
                    url: "/h5/#/my/reports?index=3",
                    report: () => {
                        // 埋点上报
                        this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.TAP_OK);
                    },
                },
                //店铺设计
                shopDesign: {
                    title: "解锁报告",
                    icon: "/static/icon/payment_success.png",
                    message: "已成功提交",
                    text: "请至“我的-我的报告-店铺设计”查看",
                    tips: "预计1-3分钟内生成成功",
                    button: "去查看",
                    url: "/h5/#/my/reports?index=7",
                },
                // 新增课程
                course: {
                    title: "支付结果",
                    icon: "/static/icon/payment_success.png",
                    message: "购买成功",
                    text: "请至“我的-我的课程”查看",
                    button: "确定",
                    url: "/my/course",
                    // report : () => {
                    //     // 埋点上报
                    //     this.JSBridge.report( this.JSBridge.REPORT_EVENT.PAY.TAP_OK );
                    // },
                },
                brandJoin: {
                    title: "支付结果",
                    icon: "/static/icon/payment_success.png",
                    message: "购买成功",
                    text: "请至“我的-我的订单”查看",
                    button: "确定",
                    url: "/h5/#/orders",
                    report: () => {
                        // 埋点上报
                        this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.TAP_OK);
                    },
                },
                subscribe: {
                    title: "支付结果",
                    icon: "/static/icon/subscribe.png",
                    message: "订阅成功",
                    subMsg: "订阅有效时长：1天",
                    text: "你可以去“我的-我的订阅”查看订阅成功的店铺<br>订阅后我们会在当天为您更新一次数据",
                    button: [
                        {
                            type: "info",
                            line: true,
                            text: "继续订阅",
                            url: "/shop",
                            clear: true,
                        },
                        {
                            type: "info",
                            text: "去我的订阅",
                            url: "/my/shops",
                        },
                    ],
                },
                newPayment: {
                    title: "支付成功",
                    icon: "/static/icon/payment_success.png",
                    message: "支付成功",
                    text: "如选择以后再用，请至我的-我的购买中， 点击“去使用”",
                    button: [
                        {
                            type: "warning",
                            line: true,
                            text: "以后再用",
                            url: "/h5/#/my/packages",
                        },
                        {
                            type: "warning",
                            text: "立即评估报告",
                            versions: 207,
                            appUrl: "PICK_LOCATION",
                            appUrlV1: "CONDITIONS_INPUT",
                        },
                    ],
                },
                hotPayment: {
                    title: "支付成功",
                    icon: "/static/icon/payment_success.png",
                    message: "支付成功",
                    text: "如选择以后再用，请至我的-我的购买中， 点击“去使用”",
                    button: [
                        {
                            type: "warning",
                            line: true,
                            text: "以后再用",
                            url: "/h5/#/my/packages",
                        },
                        {
                            type: "warning",
                            text: "立即评估报告",
                            appUrl: "HEAT_MAP_CUSTOMIZATION_V2",
                        },
                    ],
                },
                sitePayment: {
                    title: "支付成功",
                    icon: "/static/icon/payment_success.png",
                    message: "支付成功",
                    text: "如选择以后再用，请至我的-我的购买中， 点击“去使用”",
                    button: [
                        {
                            type: "warning",
                            line: true,
                            text: "以后再用",
                            url: "/h5/#/my/packages",
                        },
                        {
                            type: "warning",
                            text: "立即评估报告",
                            appUrl: "CONDITIONS_INPUT",
                        },
                    ],
                },
              reportPayment: {
                title: "支付成功",
                icon: "/static/icon/payment_success.png",
                message: "支付成功",
                text: "如购买多次套餐，可至我的-选址套餐中使用",
                button: [
                  {
                    type: "warning",
                    text: "生成报告",
                    createOrder: true
                  },
                ],
              },
            },
            // 生成报告中
            creating: false
        };
    },
    mounted() {
        //   console.log( 'success get stdId:', this.stdId, ', typeof stdId:', typeof this.stdId );
        if(this.$route.query.reportId && (this.type === 'payment'||this.type === 'shopDesign')) {
            this.HTTP.getReportName(this.$route.query.reportId)
            .then((res) => {
                console.log(res, "getReportData");
                if (res.code == 0 && res.data.name) {
                    if (this.$route.query.stdId === "10001") {
                        this.subString(res.data.name, 6);
                    } else if (this.$route.query.stdId === "10026") {
                        this.subString(res.data.name, 8);
                    } else if (this.$route.query.stdId === "10006") {
                        this.subString(res.data.name, 4);
                    } else if (this.$route.query.stdId === "10015") {
                        this.subString(res.data.name, 5);
                    } else if (this.$route.query.stdId === "10084") {
                      this.subString(res.data.name, 7);
                    } else if (this.$route.query.stdId === "10119") {
                        this.reportName = res.data.name;
                        this.reportType = "店铺智能设计评估";
                    } else if (this.$route.query.stdId === "10004") {
                        const brand = res.data.name.split('-')[0].split("】")[0] + "】"
                        const city = res.data.name.split('-')[1]
                        this.reportName = brand + '- ' + city;
                        this.reportType = "加盟找位置报告";
                    } else if (this.$route.query.stdId === "10092") {
                      this.subString(res.data.name, 7);
                    } else {
                      this.subString(res.data.name, 0);
                    }
                }
            })
            .catch((e) => {
                this.$toast("网络不稳定，请稍候重试！");
            });
        }
        this.HTTP.selectAd({
            refId: this.$route.query.stdId,
            pageId: this.$route.meta.id,
        }).then((res) => {
            if (res.data) {
                this.isShowAd = true;
                this.linkList = res.data.appAdRespList;
            }
        });
    },
    methods: {
        onTap() {
            // if (this.jumping) return;
            // this.jumping = true;
            console.log("日志", this.url, this.type);
            if (this.view && typeof this.view.report === "function") {
                // 埋点上报
                this.view.report();
            }
            // WEB跳转
            if (this.url) {
                if (window.isClient && this.url === "/") {
                    this.JSBridge.callHandler("gotoPage", "home");
                } else {
                    if (this.type === "paymentNearby") {
                        // this.$router.go( - 2 );
                        this.swRouter(this.url);
                    } else if (this.type === "payment") {
                        if (["10003", "10004"].includes(this.stdId)) {
                            const newUrl = this.url + "?index=2";
                            this.swRouter(newUrl);
                        } else if (this.stdId === "10010") {
                            const newUrl = this.url + "?index=3";
                            this.swRouter(newUrl);
                        } else if (this.stdId === "10015") {
                            // 找商机
                            const newUrl = this.url + "?index=4";
                            this.swRouter(newUrl);
                        } else if (["10018", "10019"].includes(this.stdId)) {
                            // 热力图
                            const newUrl = this.url + "?index=5";
                            this.swRouter(newUrl);
                        } else {
                            const newUrl = this.url + "?index=1";
                            this.swRouter(newUrl);
                        }
                    } else if(this.type === "package" && this.reportId) {
                        this.swRouter("/h5/#/my/reports?index=1")
                    }
                    else {
                        this.swRouter(this.url);
                    }
                }
                // APP跳转
            } else if (this.goto) {
                if (window.isClient) {
                    this.JSBridge.callHandler("gotoPage", this.goto);
                } else {
                    this.$router.push("/");
                }
            }
        },
        handleClick(link, id) {
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 86000, id);
            this.JSBridge.callHandler("onNormalizingCall", link);
        },
        subString(value, number) {
            this.reportName = value.substr(0, value.length - number);
            this.reportType = value.substr(value.length - number, number);
            console.log(value, number,'value, number');
        },
        createOrder() {
          if (this.creating) return
          console.log(this.linkParams)
          const params = JSON.parse(this.linkParams)
          console.log(params)
          const payParams = params.value
          this.creating = true
          if (!payParams.pkgGoodsId) {
            getInputById(this.id).then(res => {
              console.log('input res',res)
              if (res?.pkgShowResp?.questionId) {
                payParams.userQuestionPkgItemId = res.pkgShowResp.questionId
                this.addOrder(payParams)
              } else {
                this.creating = false
              }
            }).catch(() => {
              this.creating = false
            })
          } else {
            this.addOrder(payParams)
          }
        },
        addOrder(params) {
          createReportV450({
            ...params,
            "reportExchangeType": 1 //1-套餐次数  2-上上贝
          }).then(res => {
            console.log(res)
            if (res) {
              this.swRouter(res.link.url, 'replace');
            }
            this.creating = false
          }).catch(() => {
            this.creating = false
          })
        },
        onTapCustom(btn) {
            if (btn.url) {
                if (window.isClient && btn.url === "/") {
                    this.JSBridge.callHandler("gotoPage", "home");
                } else if (btn.rep) {
                    this.$router.replace(btn.url);
                } else {
                    if (btn.clear) {
                        this.JSBridge.callHandler("clearHistory", "my");
                    }
                    this.swRouter(btn.url);
                }
            } else if (btn.createOrder) {
              this.createOrder()
            } else {
                // 前往APP
                let appUrl = btn.appUrl;
                const versions = btn.versions;
                if (versions && window._appCode >= versions) {
                    appUrl = btn.appUrlV1;
                }
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: appUrl,
                    id: this.id || 10001,
                    params: this.linkParams,
                    isClear: true,
                });
            }
        },
    },
    computed: {
        title() {
            return this.view.title;
        },
        icon() {
            return this.view.icon || "/static/icon/more.png";
        },
        description() {
            return this.view.message;
        },
        subDescription() {
            return this.view.subMsg;
        },
        text() {
            return this.view.text || "　";
        },
        tips() {
            return this.view.tips || "  ";
        },
        button() {
            return this.view.button;
        },
        url() {
            return this.view.url;
        },
        goto() {
            return this.view.goto;
        },
        id() {
            return this.$route.query.id;
        },
        platform() {
            return sessionStorage.getItem("platform");
        },
        showTitle() {
            return this.type === 'package' || this.type === 'payment' ||this.type === 'shopDesign'
        },
        linkParams() {
          return this.$route.query.linkParams || '';
        }
    },
    watch: {
        $route() {
            this.type = this.$route.query.view;
            this.view = this.map[this.type] || this.map["default"];
        },
    },
    created() {
        this.type = this.$route.query.view;
        this.reportId = this.$route.query.reportId
        console.log(this.$route.query.stdId, "this.$route.query.stdId");
        if (this.type === "payment") {
            this.stdId = this.$route.query.stdId;
            if (this.stdId==10142) {
              this.map[this.type].tips = '大数据模型推荐中……'
              this.map[this.type].text = '500+数据强力计算，预计10min内生成'
            }
        }
        this.view = this.map[this.type] || this.map["default"];
        if(this.type === "package" && this.reportId) {
            this.view.text = "请至“我的-我的报告”查看"
        }
        if (this.type === "subscribe") {
            const use = this.$route.query.use;
            if (use !== "test") {
                this.view.subMsg = "";
            }
        }
    },
    beforeMount() {
        this.JSBridge.callHandler("setStatebarTheme", "black");
        this.JSBridge.callHandler("switchNavbar", false);
        // 禁止返回操作
        this.JSBridge.callHandler("interceptBack", true);
    },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.success {
    height: 90vh;
    //   background-color: $c-pageBg2;
    padding-top: 10vw;
    background: #f2f3f8;
    #{&}--view {
        opacity: 1;
        background-color: #f2f3f8;
        .sub-msg {
            color: #8d5d22;
            font-size: $font_293;
            line-height: $font_400;
            text-align: center;
            font-weight: bold;
        }
    }
    #{&}--info {
        padding: 4vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #1d2233;
        background: linear-gradient(rgba(247, 248, 251, 1), rgba(247, 248, 251, 0));
        font-size: 3.2vw;
        .title {
            line-height: 4.4vw;
            span {
                color: #347fff;
            }
        }
        .status {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            line-height: 4.4vw;
            margin-top: 1.33vw;
            img {
                width: 4vw;
                height: 4vw;
                margin-right: 1.33vw;
            }
        }
    }

    #{&}--tips {
        padding-bottom: $padding;
        text-align: center;
        .icon {
            width: $iconSize;
            height: $iconSize;
            margin-top: 2.4vw;
        }
        h3 {
            font-size: $font_373;
            line-height: $font_533;
            padding-top: 7.47vw;
        }
        p {
            font-size: $font_293;
            color: $c-info;
            padding-top: 1vw;
            line-height: $font_400;
        }
        .van-button {
            width: 58vw;
            margin-top: 5.6vw;
        }
        .link {
            font-size: $font_400;
            margin-top: 4.8vw;
        }
    }

    #{&}--btnbar {
        @include flex;
        justify-content: center;
        margin-top: 5.6vw;
        margin-bottom: 14.53vw;

        .van-button {
            width: 34vw;
            margin: 0 1.5vw;
        }
    }
    .ele_ad {
        margin-top: 10vw;
        width: 100%;
        position: relative;
        img {
            width: 100%;
        }
        &::before {
            content: "";
            width: 78.67vw;
            height: 0.267vw;
            position: absolute;
            top: -7vw;
            left: 50%;
            transform: translateX(-50%);
            border-top: 0.267vw dashed #f5a564;
        }
    }
}
</style>
