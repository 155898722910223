<template>
  <div class="coupon-container">
        <StateBar ref="stateBar"></StateBar>
        <TitleHeader title="文章干货" bgcolor="transparent" color="black" :back="true"></TitleHeader>
        <van-tabs v-if="!drgGoodsLoading" v-model="firstId" class="drg_tab" @click="goodDetailTab"  >
            <van-tab :title="val.name" class="drg_tab_list" :name="val.id" v-for="(val, index) in tabList" :key="index">
            </van-tab>
        </van-tabs>
        <AutoView :header="true" :footer="false" class="coupon--view">
            <van-skeleton :loading="drgGoodsLoading" title :animate="false" :row="3" avatar class="s-skeleton s-radius__s">
                <EmptyCard v-if="tabListContent.length <= 0 && !isFlag" type="community"></EmptyCard>
                <template v-else>
                    <div class="drg_swrap_box">
                        <div class="drg_swrap" v-for="(val, index) in tabListContent" :key="index">
                            <div class="swrap_title">
                                <h3>{{val.title}}</h3>
                                <div class="swrap_text" @click="openMoreList(val.title)">
                                    <span>更多 </span>
                                    <!-- <van-icon name="arrow" /> -->
                                    <van-image class="item_list_arrow" lazy-load src="/static/icon/arrow_right_gray.png" />
                                </div>
                            </div>
                            <div>
                                <div class="swrap_item" v-for="(item, key) in val.resourceList" :key="key" @click="openDetai(item.id)">
                                    <div class="item_list">
                                        <van-image class="item_list_img" lazy-load :src="item.image" />
                                        <div class="item_list_content">
                                            <h3>{{item.title}}</h3>
                                            <!-- <p>{{item.author}}</p> -->
                                            <p v-if="item.keywordList">
                                                <span class="tag_item" v-for="(tag, i) in item.keywordList" :key="i">{{ tag }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tip_text">
                                <!-- <van-image class="tip_text_img" lazy-load src="/static/icon/chakan.png" /> -->
                                <span class="span_text first_text">{{val.clickCountFormat}}</span>
                                <span class="span_text">{{val.total}}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </van-skeleton>
        </AutoView>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                isAndroidOne: false,
                isIOSOne    : false,
                isIPXOne    : false,
                drgGoodsLoading: true,

                tabList: [],
                firstId:    null,
                tabListContent: [],
                isFlag: false,
            }
        },
        methods: {
            // 更多
            openMoreList(tag) {
                this.swRouter({
                    path: '/community/goodsList',
                    query: {
                        tag: tag
                    }
                });
            },
            // 详情
            openDetai(id) {
                this.swRouter( '/h5/#/community/article/'+id );
            },
            goodDetailTab(name) {
                this.tabListContent = [];
                this.firstId = name;
                this.getLoadDataContent();
            },
            loadData() {
                this.HTTP.getDrgGoods().then(res=> {
                    this.tabList = res.data.categoryList;
                    const tabName = this.$route.query.tab
                    if (tabName) {
                        const tab = this.tabList.find(el => el.name === tabName)
                        this.firstId = tab ? tab.id : this.tabList[0].id;
                    } else {
                        this.firstId = res.data.categoryList[0].id;
                    }
                    this.getLoadDataContent();
                    // console.log(res.data.categoryList)
                }).catch((e) => {
                    this.$toast("网络连接不稳定，请稍候重试！");
                });
            },
            getLoadDataContent() {
                this.isFlag = true;
                this.HTTP.getDrgGoodsList(this.firstId).then(res => {
                    this.drgGoodsLoading = false;
                    this.tabListContent = res.data;
                    this.isFlag = false;
                }).catch((e) => {
                    this.isFlag = false;
                    this.$toast("网络连接不稳定，请稍候重试！");
                });
            },
        },
        beforeMount () {
            this.isAndroidOne = window.isClient && window.isAndroid;
            if ( window.isClient && ! window.isAndroid ) {
                this.isIOSOne = window.isIOS;
                this.isIPXOne = window.isIPX;
            }
        },
        created() {
            this.loadData();
        }
    }
</script>
<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .coupon-container {
        height: 100vh;
        overflow: hidden;
        .drg_tab{
            // height: 100%;line-height: normal;
            ::v-deep.van-tabs--line .van-tabs__wrap{ height: 12vw; }
            ::v-deep .van-tab{ font-size: 3.74vw;}
            ::v-deep .van-tabs__line{width: 9.07vw !important;height: 0.8vw;background: #518CDB;border-radius: 0.4vw;}
            ::v-deep .van-tab--active{ font-size: 4.27vw;font-weight: bold;color: #333333;}
            ::v-deep .van-hairline--top-bottom::after{ border-width: 0px;}
            ::v-deep .van-tabs__content{ height: calc(100% - 12vw);}
            .drg_tab_list{
                width: 100%;min-height: 100%;padding: 4vw; box-sizing: border-box;
            }
        }
        .coupon--view {
            position: relative;
            background: #F7F7F7;
            height: calc( 100vh - #{$headerHeight} );
            .drg_swrap_box{
                width: 100%;min-height: 100%;padding: 4vw 4vw 16vw; box-sizing: border-box;
            }
            .drg_swrap{
                width: 100%;height: auto;background: #fff;border-radius: 1.33vw;padding: 2.4vw 4vw 3.33vw;box-sizing: border-box;margin-bottom: 2.67vw;
                &:last-child{ margin-bottom: 0vw; }
                .swrap_title{
                    display:flex;align-items:center;justify-content: center;line-height: normal;
                    h3{ font-size: 4vw;font-weight: bold;color: #333333;flex: 1; }
                    .swrap_text{
                        font-size: 3.2vw;color: #999999;vertical-align: middle;display: inherit;
                        span{
                            display: inline-block;vertical-align: middle;line-height: normal;
                        }
                        .item_list_arrow{
                            width: 4.33vw;
                            height: auto;
                            vertical-align: middle;
                            ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                                width: 100%;height: 100%;
                                object-fit: scale-down;
                            }
                        }
                        ::v-deep .van-icon{
                            font-size: 2.67vw;vertical-align: middle;
                        }
                    }
                }
                .swrap_item{
                    width: 100%;height: auto;padding: 3.33vw 0;border-bottom: 1px solid #E7E7E7;
                    &:first-child{ padding-top: 2.4vw; }
                    &:last-child{ border-bottom: none;padding-bottom: 0; }
                    .item_list{
                        display: flex;justify-content: center;align-items: center;
                        .item_list_img{
                            width: 29.6vw;height: 20vw;margin-right: 3.33vw;
                            border-radius: 1.06vw;overflow: hidden;
                            ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                                width: 100%;height: 100%;
                                object-fit: cover;
                                // object-fit: scale-down;
                            }
                        }
                        .item_list_content{
                            flex: 1;
                            overflow: hidden;
                            h3{ height: 10.67vw;font-size: 3.74vw;font-weight: bold;color: #333333;line-height: 5.33vw;word-break: break-all;text-overflow: ellipsis; display: -webkit-box;
                            -webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden; }
                            p{
                                margin-top: 2.93vw;font-size: 3.47vw;color: #666666;line-height: 4.93vw;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                            }
                            .tag_item {
                                display: inline-block;
                                font-size: 2.4vw;
                                color: #518CDB;
                                height: 4.53vw;
                                line-height: 4.53vw;
                                text-align: center;
                                border: 0.13vw solid #518CDB;
                                border-radius: 0.67vw;
                                padding: 0 1.6vw;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
                .tip_text{
                    text-align: right;margin-top: 1.07vw;font-size: 0px;vertical-align: middle;
                    .tip_text_img{
                        width: 3.87vw;height: 2.94vw;margin-right: 1.07vw;display: inline-block;vertical-align: middle;
                        ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                            width: 100%; height: 100%;vertical-align: middle;
                        }
                    }
                    .span_text{
                        font-size: 3.2vw;color: #999999;line-height: 3.2vw; display: inline-block;vertical-align: middle;
                    }
                    .first_text{ margin-right: 3.33vw; }
                }
            }

            // .androidPosition{
            //     ::v-deep.van-tabs--line .van-tabs__wrap{ z-index: 2;position: fixed;top: calc(15.5vw + $androidStateBar);left: 0;width: 100%; }
            //     .drg_tab_list{ padding: 16vw 4vw 4vw; }
            // }
            // .iosPosition{
            //     ::v-deep.van-tabs--line .van-tabs__wrap{ z-index: 2;position: fixed;top: calc(15.5vw + $iOSStateBar);left: 0;width: 100%; }
            //     .drg_tab_list{ padding: 16vw 4vw 4vw; }
            // }
            // .ipxPosition{
            //     ::v-deep.van-tabs--line .van-tabs__wrap{ z-index: 2;position: fixed;top: calc(15.5vw + $iPXStateBar);left: 0;width: 100%; }
            //     .drg_tab_list{ padding: 16vw 4vw 4vw; }
            // }
        }
    }
</style>
