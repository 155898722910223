<template>
    <div>
    <div class="article" v-if="info">
        <div ref="content" class="content" @click="onClick" v-html="info.introduce"></div>
        <!-- 全局加载遮罩 -->
    </div>
      <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import { ImagePreview } from 'vant';

export default {
    name: 'articleView',
    data () {
        return {
            id     : null,
            loading: true,
            info   : null,
        };
    },
    methods: {
        loadData () {
            this.id = this.$route.params.id;
            this.HTTP.getCourseInfo( this.id ).then( ( res ) => {
                this.info       = res;
                this.loading    = false;
            } );
        },
        onClick ( e ) {
            if ( e.target && e.target.nodeName === 'IMG' ) {
                ImagePreview( {
                    images   : [ e.target.src ],
                    loop     : false,
                    showIndex: false,
                    closeable: false,
                    onClose  : () => {
                    },
                } );
            }
        },
    },
    computed: {
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler () {
                this.loadData();
            }
        },
    },
    beforeMount () {

    },
    beforeDestroy () {
        // 隐藏分享按钮
    },
    created(){
        // WEB写入登录信息
        // const token = this.$route.query.token;
        // if ( typeof token === 'string' && token.length === 32 ) {
        //     this.HTTP.setAuth( token );
        // } else {
        //     this.HTTP.setAuth( '5325b47652ff4a92903a6f72187757af' );
        // }
    }
};
</script>

<style lang="scss">
// @import '@/styles/index.scss';

.article {
    height: 100vh;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding: 3vw;
    .content {
        line-height: normal;
        letter-spacing: 0.13vw;
        box-sizing: border-box;
        img {
            max-width:100% !important;
            height: auto!important;
            @extend .s-radius__s;
            border: 0;
            outline: none;
            object-fit: contain;
            cursor: text;
            margin: auto;
        }
        p {
            line-height: normal;
            letter-spacing: 0.13vw;
            white-space: pre-wrap;
            box-sizing: border-box;
            word-break: break-all;
        }
    }
    .share {
        position: fixed;
        top: 0;
        width: 100%;
        height: $headerHeight;
        background: rgba(black, .8) url(/static/icon/logo@96.png) no-repeat $margin center;
        background-size: $headerHeight * 0.7;
        box-sizing: border-box;
        color: white;
        padding-left: $headerHeight * 1.1;
        p {
            font-size: $font_320;
            padding-top: $margin * 0.8;
        }
        .f-fw {
            font-size: $font_400;
        }
        .app {
            position: absolute;
            right: $margin;
            top: 25%;
            height: 50%;
            color: white;
            background-color: $c-main;
            padding: 0 $margin;
            overflow: hidden;
            border-radius: 10vw;
            font-size: $font_320;
            @include flexColumn;
        }
    }
}
</style>
