<template>
    <div class="reportshop">
        <StateBar />
        <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>

        <AutoView ref="view" id="view" :header="true" :footer="false" class="reportshop--view">
            <!-- 展示列表 -->
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
                <div class="content">
                    <CityItem :bgmData="cityList" @selectItem="selectItem" />
                    <div class="map-wrap">
                        <h5 class="title">城市门店分布情况</h5>
                        <Hotranking :tableData="tableData" />
                    </div>
                    <div class="shop-wrap" v-if="list.length">
                        <h5 class="head-tips">
                            {{ cityInfo.name }}在营门店 <i class="i">{{ cityInfo.count }}</i> 家
                        </h5>
                        <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="getShopLost">
                            <ShopItem v-for="(item, index) in list" :bgmData="item" :idx="index" :key="item.key" />
                        </van-list>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>

        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import CityItem from "./components/cityItem.vue";
import Hotranking from "./components/hotRanking.vue";
import ShopItem from "./components/shopItem.vue";

export default {
    components: { CityItem, Hotranking, ShopItem },
    data() {
        return {
            firstLoading: true,
            loading: true,
            finished: false,
            current: 1,
            tableData: [],
            cityList: [],
            list: [],
            total: 0,
            cityInfo: {},
            finishedText: "我是有底线的",
        };
    },
    computed: {
        provinceCode() {
            return this.$route.query.code;
        },
        brandCode() {
            return this.$route.query.brandCode;
        },
        brandName() {
            return this.$route.query.brandName;
        },
        title() {
            return `${this.brandName || ""}-${this.cityInfo.name || ""}门店信息`;
        },
    },
    mounted() {
        if (this.provinceCode && this.brandCode) {
            this.loadData();
        }
    },
    methods: {
        loadData() {
            this.HTTP.brandReportDetail({
                brandCode: this.brandCode,
                province: this.provinceCode,
            }).then((res) => {
                this.cityList = res || [];
                let result = (this.cityList.length > 0 && this.cityList[0]) || [];
                this.tableData = this.sortTable(result.value);
                this.cityInfo = result;
                this.getShopLost();
            });
        },
        sortTable(data) {
            return data.sort(function (a, b) {
                return b.count - a.count;
            });
        },
        getShopLost() {
            this.loading = true;
            this.HTTP.allDpPage({
                brandCode: this.brandCode,
                cityCode: this.cityInfo.code,
                current: this.current,
                size: 10,
            })
                .then((res) => {
                    this.firstLoading = false;
                    this.loading = false;
                    this.current++;
                    if (res) {
                      this.total = res.total;
                      if (this.list.length) {
                        this.list = this.list.concat(res.data);
                      } else {
                        this.list = res.data;
                      }
                      if (res.data.length < res.size || this.list.length >= this.total) {
                        this.finished = true; // 完成加载
                      }
                    } else {
                      this.finished = true; // 完成加载
                    }
                })
                .catch((e) => {
                    if (e.code == 201022) {
                        this.finished = true; // 完成加载
                        this.finishedText = "";
                        this.loading = false;
                    } else {
                        this.$toast("网络不稳定，请稍候重试！");
                        this.loading = false;
                    }
                });
        },
        selectItem(data) {
            this.tableData = this.sortTable(data.value);
            this.current = 1;
            this.finished = false;
            this.cityInfo = data;
            this.list = [];
            this.getShopLost();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.reportshop {
    height: 100vh;
    overflow: hidden;

    #{&}--view {
        scroll-behavior: smooth;
        position: relative;
        .content {
            position: relative;
            padding: 4vw 3.2vw;
            .map-wrap {
                background: #fff;
                .title {
                    height: 10.13vw;
                    line-height: 10.13vw;
                    font-size: 4vw;
                    font-weight: bold;
                    color: #333;
                }
            }
            .shop-wrap {
                margin-top: 2.67vw;
                .head-tips {
                    height: 10.13vw;
                    line-height: 10.13vw;
                    font-size: 4vw;
                    font-weight: bold;
                    color: #1d2233;
                    .i {
                        color: #347fff;
                        font-style: normal;
                    }
                }
            }
        }
    }
}
</style>
