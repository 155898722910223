<template>
    <div class="search-res" :class="'type-' + type">
        <!-- 标准问题 -->
        <template v-if="type === 1">
            <div class="title">
                <h1 v-html="highlight">---</h1>
            </div>
            <div v-if="item.modelTags && item.modelTags.length" class="labels">
                <span v-for="( label, index ) in item.modelTags" :key="index">{{ label }}</span>
            </div>
        </template>
        <!-- 文章 -->
        <div v-else-if="type === 2" class="title">
            <NewsCard :item="newsItem" />
        </div>
    </div>
</template>

<script>
import NewsCard from '@/components/card/news';

export default {
    name      : 'searchRes',
    components: {
        NewsCard,
    },
    props: {
        item: Object,
    },
    data () {
        return {
            title  : '',
            type   : 0,
            keyword: [],
            labels : [],
        };
    },
    watch: {
        item: {
            immediate: true,
            handler ( item ) {
                // 属性提取
                this.title   = item.showText;
                this.type    = item.type;
                this.keyword = item.keywords;
                this.labels  = item.modelTags;
            },
        },
    },
    computed: {
        highlight () {
            let title = this.title;
            if ( typeof title === 'string' && this.keyword instanceof Array && this.keyword.length ) {
                this.keyword.forEach( ( key ) => {
                    title = title.replace( key, `<em>${key}</em>` );
                } );
            }
            return title || '---';
        },
        newsItem () {
            return {
                id    : this.item.dataId,
                cover : this.item.ext,
                title : this.item.showText,
                labels: this.item.modelTags,
                // format: ,
                // time  : '',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$searchIcon: 4vw;

.search-res {
    padding: 4.8vw 5.2vw;
    background: white;
    margin-bottom: $margin;
    @extend .s-radius__s;

    &.type-2 {
        .title {
            background-image: url(/static/icon/article.png);
            padding-right: 0;
        }
    }

    .title {
        position: relative;
        padding: 0 $padding 0 $padding * 1.25;
        background: url(/static/icon/search_res.png) no-repeat left top .5vw;
        // background: url(/static/icon/search_res.png) no-repeat left top .5vw, url(/static/icon/arrow_right_black.png) no-repeat right top .75vw;
        background-size: $searchIcon;

        h1 {
            color: $c-text;
            width: 100%;
            font-size: $font_400;
            line-height: $font_533;
            margin: 0;

            ::v-deep em {
                font-style: normal;
                color: $c-main;
            }
        }

        .fa-chevron-right {
            position: absolute;
            right: 0;
            top: 0;
            line-height: 5vw;
            font-size: 3vw;
        }
    }
    .labels {
        overflow: hidden;
        width: 100%;
        height: $font_480;
        margin-top: 1.87vw;
        padding-left: $padding * 1.25;
        box-sizing: border-box;
        span {
            float: left;
            font-size: $font_253;
            line-height: $font_480;
            color: $c-info;
            border-radius: $radius-xs;
            background-color: $c-pageBg;
            padding: 0 2.67vw;
            margin-right: 2.13vw;
        }
    }
    .info {
        position: absolute;
        overflow: hidden;
        bottom: 0;
        width: 100%;
        margin: 0;
        color: $c-info;
        font-size: .6rem;
    }
}
</style>
