<template>
  <div class="searchBar" :class="{ full }">
    <div class="mask s-radius__xs">
      <form action="" @submit="validate" class="searchBar--form" :class="{ icon }">
        <img class="search-icon" src="/static/icon/search_icon.png" alt="" />
        <input ref="search" v-model="keyword" type="text" :placeholder="placeholder" class="searchBar--input" :class="{ on }" />
        <i v-show="showClear" class="searchBar--clear" @click="clear" />
        <!-- <a href="javascript:;" class="searchBar--cancel" @click="cancel">取消</a> -->
      </form>
    </div>
    <div v-if="!full" class="view view--r">
      <slot name="right" class="view view--r" />
    </div>
  </div>
</template>

<script>
export default {
  name: "searchBar",
  props: {
    full: false,
    icon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请输入关键字...",
    },
    on: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    inputContent: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      keyword: this.inputContent,
      showClear: false,
    };
  },
  watch: {
    keyword(nVal, oVal) {
      const val = nVal.trim();
      this.showClear = val && val.length;
      // 搜索推荐
      this.$emit("change", { keyword: nVal });
    },
    inputContent(nVal, oVal) {
      this.keyword = nVal;
    },
    back: {
      immediate: true,
      handler(val) {
        this.JSBridge.callHandler("switchBackBtn", val);
      },
    },
  },
  methods: {
    validate(e) {
      // this.$toast( `${this.keyword || this.placeholder}` );
      this.$emit("entry", { target: this.keyword || this.placeholder });
      // this.setBlur();
      e.preventDefault();
    },
    setKeyword(keyword) {
      this.keyword = keyword;
    },
    setFocus() {
      this.$refs.search.focus();
    },
    setBlur() {
      this.$refs.search.blur();
    },
    clear() {
      this.keyword = "";
      this.$emit("clear");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

$searchIcon: $headerHeight - $headerMargin * 2;

.searchBar {
  @include flexColumn;
  position: relative;
  height: $headerHeight;
  padding: 0 $headerHeight;
  background-color: $c-searchBarBg;
  box-sizing: border-box;
  // box-shadow: inset 0 -1px 0 #ebeaee, 0 -1px 0 white;

  &.full {
    padding: 0;
  }
  .view {
    &--r {
      overflow: hidden;
      @include flexColumn;
      position: absolute;
      width: $headerHeight;
      height: $headerHeight;
      top: 0;
      right: 0;
    }
  }

  #{&}--form {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: $margin;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // &.icon {
    //   padding-left: $padding * 1.4;
    //   background: url(/static/icon/search@64_gray.png) no-repeat 1.6vw center;
    //   background-size: 6vw;
    // }
    .search-icon {
      width: 3.47vw;
      height: 3.47vw;
      margin-right: 2.13vw;
    }
  }
  #{&}--input {
    background-color: transparent;
    border: none;
    flex: 1;
    height: 100%;
    padding: 0;
    font-weight: normal;
    // &.on {
    //     font-weight: bold;
    // }
  }
  #{&}--clear {
    position: relative;
    right: 2vw;
    display: block;
    width: $searchIcon;
    height: 100%;
    background: url(/static/icon/close.png) no-repeat center center;
    background-size: 4.27vw;
  }
  #{&}--cancel {
    width: 11.2vw;
    height: 3.4vw;
    line-height: 3.4vw;
    font-size: $font_320;
    text-align: right;
    box-shadow: inset 1px 0 0 $c-gray;
  }

  .mask {
    @extend .l-flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 10.67vw;
    background-color: $c-pageBg;
    font-size: 4vw;
    font-weight: bold;
    box-shadow: inset 0 0 0 0.5px $c-border;

    span {
      color: $c-main;
    }
  }
}
</style>
