// 不同环境的通用配置

const dev = process.env.NODE_ENV === "production" ? 'product' : 'test'

const commonConfig = {
  // 正式环境
  "product": {
    // 小程序默认token
    wx_default_token: 'f347d4bc0c7749ad95462e58dc6acac2',
    version_name: '5.3.0', // 版本号
  },
  // 非正式环境
  "test": {
    // 小程序默认token
    wx_default_token: '434a1dd789a349cb94d370bc27f5d240',
    version_name: '5.3.0', // 版本号
  }
}

export const commonConfigObj = commonConfig[dev]
