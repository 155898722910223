<template>
    <div class="card-wrap">
        <div class="line-wrap">
            <img src="/static/icon/icon_hot_shop@2x.png" class="title-icon" />
            <span class="title-label">附近热门店</span>
        </div>
        <div class="line-wrap" v-for="(item,index) in list" :key="index" @click="gotoShop(item)">
            <span :class="'index-label '+'color'+(index+1)">{{index+1}}.</span>
            <span class="name-label">{{getDisplay(item)}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type    : Array,
            required: true
        }
    },
    data () {
        return {

        };
    },
    methods: {
        getDisplay ( item ) {
            const shopName = item.shopName;
            const branch = item.branch;
            if ( branch.lengtn > 0 ) {
                return `${shopName}（${branch}）`;
            }
            return shopName;
        },
        gotoShop ( item ) {
            this.HTTP.getShopDetail( item.openShopId ).then( ( res ) => {
                // console.log( 'getShopDetail res:', res );
                this.$router.push( '/views/' + res.openShopId );
            } ).catch( ( e ) => {
                // this.$toast( '获取数据失败，请稍候重试！' );
                this.$emit( 'shop-error', undefined );
            } );
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.card-wrap {
    position: relative;
    padding: 0 0 6vw 4vw;
    background-color: #ffffff;
    border-radius: $radius-xs;
    .line-wrap {
        position: relative;
        padding: 4vw 4vw 4vw 0;
        border-bottom: 1px solid #EDF1FB;
        .title-icon {
            width: 3.2vw;
            height: 4vw;
        }
        .title-label {
            position: relative;
            margin-left: 2.1vw;
            color: #808080;
            font-size: 3.5vw;
            line-height: 5vw;
            text-align: left;
        }
        .index-label {
            color: #D1D2D6;
            font-size: 3.7vw;
            line-height: 4.5vw;
            font-weight: bold;
            font-style: italic;
            &.color1 {
                color: #FE6405;
            }
            &.color2 {
                color: #FF9E61;
            }
            &.color3 {
                color: #EDC27F;
            }
        }
        .name-label {
            margin-left: 1.3vw;
            color: #1D2233;
            font-size: 3.7vw;
            line-height: 5.3vw;
            font-weight: bold;
            text-align: left;
        }
    }
}
</style>
