<template>
  <div class="asking4Rent-list">
    <div class="header">
      <StateBar />
      <TitleHeader title="我的求租信息" bgcolor="transparent" color="black" :back="true"
        style="border-bottom: 1px solid #f3f4f7">
      </TitleHeader>
    </div>
    <AutoView :header="true" :footer="false">
      <van-cell-group v-for="(item, index) in lists" :key="index">
        <van-cell>
          <template #title>
            <div class="card-item-header">
              <div class="card-item-icon" v-if="item.iconUrl">
                <img :src="item.iconUrl" alt="">
              </div>
              <div class="card-item-title">
                {{ item.businessTitle }}
              </div>
              <div class="card-item-opts" @click="handlerSelect(item)">
                操作
              </div>
            </div>
          </template>
          <template #label>
            <div class="card-item-container" @click="handlerClick(item)">
              <div class="base-info">
                <div class="base-info-item">
                  <div class="bii-value">{{ item.areaContent }}</div>
                  <div class="bii-title">{{ item.areaTitle }}</div>
                </div>
                <div class="split-line" />
                <div class="base-info-item">
                  <div class="bii-value">{{ item.rentContent }}</div>
                  <div class="bii-title">{{ item.rentTitle }}</div>
                </div>
                <div class="split-line" />
                <div class="base-info-item">
                  <div class="bii-value bii-value2">{{ item.transferType === 1 ? '接受' : item.transferType === 2 ? '不接受' : '无要求' }}</div>
                  <div class="bii-title">转让费</div>
                </div>
              </div>
              <div class="location-info">
                <div class="location-item">
                  <img src="/static/shopEcology/shopRequirements/icon/location-small.png" alt="">
                  <div class="small-title">求租区域</div>
                </div>
                <div class="city-item">
                  <div class="small-title">{{ item.rentCity }}</div>
                </div>
                <div class="city-item" v-for="(region, regionIndex) in item.rentRegionList || []" :key="regionIndex">
                  <div class="regionName"> {{ region }} </div>
                </div>
                <div class="mask" />
              </div>
              <div class="isClose" v-if="!item.isOpen" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </AutoView>
    <van-action-sheet v-model="showAction" :actions="actions" cancel-text="取消" close-on-click-action @select="onSelect"
      @cannel="onCannel" />
  </div>
</template>

<script>
import { openOrCloseIntentionRent, getIntentionRentListPage } from '@/request/asking4Rent';
export default {
  data() {
    return {
      lists: [],
      currInfo: {},
      showAction: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getIntentionRentListPage({
        "current": 1,
        "size": 500
      }).then(res => {
        this.lists = res.data || [];
        console.log(res.data)
      });
    },
    handlerClick(item) {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, '3210100', '3210101');
      this.swRouter('/shopEcology/askingRentDetails?id=' + item.id);
    },
    handlerSelect(item) {
      this.currInfo = item;
      this.showAction = true;
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, '3210100', '3210102');
    },
    onSelect(action, index) {
      switch (index) {
        case 0:
          this.swRouter('/h5/#/shop/rent?id=' + this.currInfo.id);
          break;
        case 1:
          let params = {
            id: this.currInfo.id,
            isOpen: !this.currInfo.isOpen ? 1 : 0
          }
          openOrCloseIntentionRent(params).finally(e => {
            this.currInfo = {};
            this.init();
          })
          break;
      }
    },
    onCannel() {
      this.currInfo = {};
      this.showAction = false;
    }
  },
  computed: {
    actions() {
      if (this.currInfo.isOpen) return [{ name: '修改' }, { name: '关闭求租信息' }];
      else return [{ name: '修改' }, { name: '重新开启' }]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
  @return $args/750 * 100+vw;
}

.asking4Rent-list {
  .autoView {
    background: #f2f2f2;

    ::v-deep .van-cell-group {
      min-width: 0;
      overflow: hidden;
      border-radius: vw(10);
      margin: vw(20) vw(20);
      position: relative;
    }
  }

  .card-item-header {
    display: flex;
    padding-top: vw(10);
    padding-bottom: vw(20);
    border-bottom: #DEDEE7 vw(1) solid;

    .card-item-icon {
      width: vw(40);
      height: vw(40);
      margin-right: vw(10);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-item-title {
      flex: 1;
      font-size: vw(30);
      font-weight: 600;
      line-height: vw(42);
    }

    .card-item-opts {
      flex-shrink: 0;
      height: vw(37);
      color: #347FFF;
      font-size: vw(26);
      font-weight: 600;
      line-height: vw(37);
    }
  }

  ::v-deep .van-cell__label {
    margin-top: vw(30);
  }

  ::v-deep .van-cell__title {
    overflow: hidden;
  }

  .card-item-container {


    .isClose {
      right: vw(0);
      z-index: 999;
      width: vw(100);
      height: vw(120);
      bottom: vw(46);
      position: absolute;
      background: url(/static/shopEcology/shopRequirements/icon/rent_close.png);
      background-size: vw(100) vw(120);
    }
    .base-info {
      display: flex;
      align-items: center;
      margin-bottom: vw(30);

      .split-line {
        width: vw(1);
        height: vw(60);
        opacity: 0.8;
        background: #DEDEE7;
      }

      .base-info-item {
        flex: 1;
        text-align: center;

        .bii-value {
          font-size: vw(28);
          color: #FF8047;
          line-height: vw(40);
          margin-bottom: vw(4);
          &.bii-value2 {
            color: #1D2233;
            font-weight: bold;
          }
        }

        .bii-title {
          font-size: vw(24);
          color: #676C84;
          line-height: vw(33);
        }
      }
    }

    .location-info {
      display: flex;
      position: relative;
      align-items: center;

      .mask {
        top: 0;
        right: vw(-15);
        width: vw(105);
        height: vw(50);
        position: absolute;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }

      .location-item {
        display: flex;
        flex-shrink: 0;
        height: vw(30);
        align-items: center;
        padding: vw(10) vw(18);
        background: linear-gradient(270deg, #F2F5FC 0%, #E5EFFF 100%);

        img {
          width: vw(18);
          height: vw(21);
        }

        .small-title {
          font-size: vw(22);
          font-weight: 600;
          color: #347FFF;
          margin-left: vw(10);
        }
      }

      .city-item {
        height: vw(30);
        margin-left: vw(4);
        padding: vw(10) vw(18);
        background: #F7F8FB;

        .small-title {
          font-size: vw(22);
          color: #1D2233;
          overflow: hidden;
          white-space: nowrap;
        }

        .regionName {
          font-size: vw(22);
          color: #676C84;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
