<template>
    <div class="page" :class="{ 'page-bottom': showFooter && !firstLoading }" ref="pageRef">
        <StateBar bgcolor="white" />
        <TitleHeader title="开店选位置 加盟选品牌" bgcolor="#ffffff" color="black" :back="true"></TitleHeader>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <AutoView ref="view" class="auto-view" :style="{opacity: firstLoading ? 0 : 1}" >
            <div class="main" ref="mainRef">
                <div class="part">
                    <VanImage src="/static/brandAlliance/settle/settle5.png" @load="imageLoadDone(1)"></VanImage>
                </div>
                <div class="part">
                    <VanImage class="two" src="/static/brandAlliance/settle/settle5-1.png" @load="imageLoadDone(2)"></VanImage>
                    <div class="settle-form" ref="formRef">
                        <div class="form-input">
                            <div class="form-header">
                                <div>
                                    <span class="form-name">品牌名称</span>
                                    <span class="form-requried">*</span>
                                </div>
                                <div class="valid-text" v-if="isCommit && !name">请填写信息</div>
                            </div>
                            <div class="input-wrap">
                                <input maxlength="30" v-model="name" placeholder="请输入品牌名称" :class="{ 'error-input': isCommit && !name }" />
                            </div>
                        </div>
                        <div class="form-input">
                            <div class="form-header">
                                <div>
                                    <span class="form-name">手机号</span>
                                    <span class="form-requried">*</span>
                                </div>
                                <div class="valid-text" v-if="isCommit && !phone">请填写信息</div>
                            </div>
                            <div class="input-wrap">
                                <input
                                    v-model="phone"
                                    placeholder="请输入手机号，以方便联系您"
                                    :class="{ 'error-input': isCommit && !phone }"
                                    @input="inputPhone"
                                />
                            </div>
                            <div class="form-input">
                                <div class="form-header">
                                    <div><span class="form-name">门店数量</span></div>
                                </div>
                                <div class="input-wrap">
                                    <input type="number" v-model="storesCount" placeholder="请输入门店数量" @input="inputCount" />
                                </div>
                            </div>
                            <div class="form-input">
                              <div class="form-header">
                                <div><span class="form-name">公司所在地</span><span class="form-requried">*</span></div>
                                <div class="valid-text" v-if="isCommit && !zone">请填写信息</div>
                              </div>
                              <div class="input-wrap">
                                <input @click="cityVisible=true" readonly :value="zone" placeholder="请选择公司所在地" />
                                <img class="arrow-icon" src="/static/icon/choice_icon.png" />
                              </div>
                            </div>
                        </div>
                        <button class="commit-btn" @click="commit">提交入驻申请</button>
                    </div>
                </div>
                <VanImage class="part" src="/static/brandAlliance/settle/part2.png"></VanImage>
                <VanImage class="part" src="/static/brandAlliance/settle/part6.png"></VanImage>
                <VanImage class="part" src="/static/brandAlliance/settle/partnew.png"></VanImage>
            </div>
        </AutoView>
        <!-- 底栏提交按钮 -->
        <div class="footer-btn" v-if="showFooter && !firstLoading">
            <button class="commit-btn" @click="scrollToTop">提交入驻申请</button>
        </div>
      <van-popup v-model="cityVisible" round position="bottom">
        <van-area
          title="选择城市"
          :area-list="cityList"
          :columns-num="2"
          @confirm="handleCityCheck"
          @cancel="cityVisible=false"
        />
      </van-popup>
    </div>
</template>

<script>
import md5 from "md5";
import http from "@/request/http";
let picNum = 0;

import { areaList } from '@vant/area-data';

export default {
    name: "settle",
    data() {
        return {
            cityList: areaList,
            cityVisible: false,
            firstLoading: true,
            vcode: "",
            phone: "",
            name: "",
            storesCount: null,
            time: 60,
            showFooter: false,
            observer: null,
            isCommit: false, // 是否点过提交
            applying: false,
            isAndroid: false,
            isIOS: false,
            isIPX: false,
            isClient: true,
            openApp: 0,
            channelId: "", // 渠道id
            city: '',
            cityCode: '',
            zone: '',
            originPhone: '',
            encodePhone: '',
            isFirstChangePhone: false
        };
    },
    beforeMount() {
        this.isAndroid = window.isAndroid;
        this.isIOS = window.isIOS;
        this.isIPX = window.isIPX;
        this.isClient = window.isClient;
        this.openApp = this.$route.query.openApp || 0;
        this.channelId = this.$route.query.channelId || "";
    },
    mounted() {
        this.getPosition()
        if (window.isClient) {
          this.HTTP.getUserInfo().then(res => {
            this.originPhone = res.decodePhone
            this.encodePhone = this.originPhone.replace(/(\d{3})(\d*)(\d{4})/, '$1****$3');
            this.phone = this.encodePhone
          })
        }
        this.observer = new IntersectionObserver(
            entries => {
                // console.log(entries)
                const e = entries[0];
                if (e.boundingClientRect.height) {
                    // console.log(e.intersectionRatio, e.isIntersecting)
                    this.showFooter = !e.isIntersecting;
                }
            },
            {
                threshold: [0, 0.25, 0.5, 0.75, 1]
            }
        );
        // 开始观察
        this.observer.observe(this.$refs.formRef);
        if (this.openApp == 1) {
            this.toApp();
        }
        if (!window.isClient) {
            this.report();
        }
    },
    methods: {
      getPosition() {
        if (window.isClient) {
          this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
            if (!data || data == '00') return
            if ( typeof data === 'string' ) data = JSON.parse( data );
            console.log('position', data)
            if (data) {
              if (data.city && data.adCode) {
                this.cityCode = data.adCode.substr( 0, 4 ) + '00';
                this.city = data.city;
                const province = data.province
                if (province==this.city) {
                  this.zone = province
                } else {
                  this.zone = `${province}-${this.city}`
                }
              }
            }
          })
        }
      },
      handleCityCheck(data) {
        console.log(data)
        this.city = data[1].name
        this.cityCode = data[1].code
        const province = data[0].name
        if (province==this.city) {
          this.zone = province
        } else {
          this.zone = `${province}-${this.city}`
        }
        this.cityVisible = false
      },
        // 页面埋点请求
        report() {
            const track = [
                {
                    pageId: this.$route.meta.id,
                    refId: this.channelId || ""
                }
            ];
            this.HTTP.sendTrack({
                deviceType: 4,
                platform: "sscm",
                version: "0.0.1",
                track: JSON.stringify(track)
            });
        },
        toApp() {
            // 打开app
            if (isAndroid) {
                window.location.href = `androidsscm://link/params?type=6&url=${encodeURIComponent("/#/brandAlliance/settle")}&id=`;
            } else {
                window.location.href = "SSCM-Shuwei://link/params?type=6&url=#/brandAlliance/settle&id=";
            }
        },
        commit() {
          if (this.applying) return;
            this.isCommit = true;
            if (!this.name || !this.phone || !this.city) {
                return;
            }
            this.applying = true
            this.HTTP.brandAllianceHttp
                .joinBrandApplyJoin({
                    brandName: this.name,
                    phone: this.phone===this.encodePhone ? this.originPhone : this.phone,
                    smsCode: this.vcode,
                    storesCount: this.storesCount,
                    type: 1,
                    city: this.city,
                    cityCode: this.cityCode
                })
                .then(res => {
                    console.log(res);
                    if (res.data.code == 0) {
                        this.$toast("提交成功，平台将有专人联系您，请耐心等待");
                        // setTimeout(() => {
                        //     if (window.isClient) {
                        //         // 返回app首页
                        //         this.commonApi.pageBack();
                        //     } else {
                        //         location.reload();
                        //     }
                        // }, 1000);
                    }
                    setTimeout(() => {
                      this.applying = false
                    }, 1000)
                })
                .catch(res => {
                    if (res.code === 189002) {
                        this.$toast(res.msg);
                    }
                    if (res.code === 168041) {
                        this.$toast(res.msg);
                    }
                    this.applying = false
                });
        },
        sendCode() {
            let timer = null;
            const fun = () => {
                if (this.time > 0) {
                    this.time -= 1;
                    timer = setTimeout(() => {
                        fun();
                    }, 1000);
                } else {
                    this.time = 60;
                    timer && clearTimeout(timer);
                }
            };
            if (this.phone.length !== 11) {
                // this.$toast('请输入11位手机号码');
                this.$toast({
                    message: "请输入11位手机号码",
                    position: "middle"
                });
                return;
            }
            this.sendMsg()
                .then(() => {
                    this.$toast({
                        message: "发送成功",
                        position: "middle"
                    });
                    fun();
                })
                .catch(e => {
                    if (e.msg) {
                        this.$toast({
                            message: e.msg,
                            position: "middle"
                        });
                    }
                });
        },
        sendMsg() {
            let data = {
                phone: this.phone
            };
            let sign = md5(JSON.stringify(data) + "51cbb163-2214-48f0-8d4a-c0ed217f40a7");
            data.sign = sign;
            return this.HTTP.brandAllianceHttp.sendLoginSmsV2(data);
        },
        scrollToTop() {
            this.$refs.mainRef.scrollIntoView({
                behavior: "smooth",
                inline: "nearest",
                block: "start"
            });
        },
        imageLoadDone(index) {
            picNum++;
            this.firstLoading = !(picNum >= 2)
        },
        //input事件处理
        inputPhone(e) {
          let val = this.phone
          if (this.encodePhone && !this.isFirstChangePhone) {
            val =  ''
            this.isFirstChangePhone = true
          } else if (val && String(val).length > 11) {
            val = String(val).slice(0, 11);
          }
          this.phone = val.replace(/\D/g, '');
        },
        inputCount() {
          let count = this.storesCount
          if (count && String(count).length > 9) {
            count = String(count).slice(0, 9);
          }
          this.storesCount = String(count).replace(/[^\d]/g,'')
        }
    },
    beforeDestroy() {
        if (this.observer) this.observer.disconnect();
    }
};
</script>

<style lang="scss" scoped>
.page {
    //overflow: hidden;
    background: #fff;
    &.page-bottom {
        padding-bottom: 17.4vw;
        .auto-view {
            overflow: auto;
            &.only-header {
                height: calc(100vh - #{$headerHeight} - 17.4vw);
                &.android {
                    height: calc(100vh - #{$androidStateBar} - #{$headerHeight} - 17.4vw);
                }
                &.ios {
                    height: calc(100vh - #{$iOSStateBar} - #{$headerHeight} - 17.4vw);
                }
                &.ipx {
                    height: calc(100vh - #{$iPXStateBar} - #{$headerHeight} - 17.4vw);
                }
            }
        }
    }
    .titleHeader {
    }

    .footer-btn {
        width: 100%;
        padding: 3.2vw 4vw;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        box-sizing: border-box;
        .commit-btn {
            margin-top: 0;
        }
    }
    .commit-btn {
        background: linear-gradient(270deg, #ffc03d 0%, #ff9c2e 100%);
        border-radius: vw(10);
        height: vw(82);
        color: #ffffff;
        font-size: vw(30);
        margin-top: vw(30);
        border: 0;
        width: 100%;
    }
    .main {
        .part {
            position: relative;
            display: block;
            &:nth-child(2) {
                margin-top: vw(-20);
            }
            ::v-deep .van-image {
                display: block;
                &.two {
                    height: vw(800);
                }
            }

            .settle-form {
                position: absolute;
                width: vw(710);
                // vw(460);
                height: auto;
                bottom: 0;
                left: vw(20);
                top: vw(18);
                background: #ffffff;
                box-shadow: inset 0 0 vw(30) 0 #ffeeec;
                border-radius: vw(10);
                padding: vw(30) vw(40);
                box-sizing: border-box;
                .form-input {
                    margin-top: vw(30);
                    &:first-child {
                        margin-top: 0;
                    }
                    .form-header {
                        font-size: vw(28);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .form-name {
                            color: #121622;
                            font-weight: bold;
                            line-height: vw(40);
                        }
                        .form-requried {
                            color: #ff504e;
                        }
                        .valid-text {
                            color: #ff504e;
                            font-size: vw(24);
                        }
                    }
                    .input-wrap {
                        position: relative;
                        height: vw(75);
                        background: #f7f8fb;
                        border-radius: vw(10);
                        margin-top: vw(14);
                        font-size: vw(26);
                        display: flex;
                      .arrow-icon{
                        position: absolute;
                        width: vw(18);
                        height: vw(12);
                        top: 50%;
                        transform: translateY(-50%);
                        right: vw(12);
                      }
                        input {
                            height: 100%;
                            flex: 1;
                            background: none;
                            padding: vw(20) vw(30);
                            box-sizing: border-box;
                            border: 1px solid #e4e7ed;
                            border-radius: vw(10);
                            color: #1d2233;
                            &.error-input {
                                border: 1px solid #ff504e;
                            }
                        }
                        .vcode {
                            width: vw(200);
                            font-weight: 400;
                            color: #347fff;
                            background: rgba(52, 127, 255, 0.1);
                            border-radius: vw(10);
                            border: 1px solid #347fff;
                            margin-left: vw(20);
                            &.no-border {
                                border: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
