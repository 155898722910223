<template>
  <div class="brand-vertical-list">
    <div class="brand-mask"></div>
    <div class="title" v-if="title">{{ title }}</div>
    <div class="brand-list">
      <brandVerticalBox v-for="(brand, index) in curBrandData" :key="index" :brandData="brand" />
    </div>
  </div>
</template>

<script>
const brandVerticalBox = () => import('./brand-vertical-box')
export default {
  name: 'brand-vertical-list',
  props: {
    brandData: null,
    title: String
  },
  components: {
    brandVerticalBox
  },
  computed: {
    curBrandData() {
      // 处理瀑布流左右两边的数据
      if (!this.brandData) return []
      const a1 = []
      const a2 = []
      this.brandData.forEach((el, index) => {
        if (index % 2 === 0) {
          a1.push(el)
        } else {
          a2.push(el)
        }
      })
      return a1.concat(a2)
    }
  }
};
</script>

<style lang="scss" scoped>
.brand-vertical-list {
  position: relative;
  .title {
    font-size: vw(34);
    font-weight: bold;
    color: #1D2233;
    line-height: vw(48);
    position: relative;
    z-index: 1;
    padding: vw(20) vw(30);
  }
  .brand-mask {
    position: absolute;
    width: 100%;
    height: vw(400);
    top: 0;
    left: 0;
    background: linear-gradient(360deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  }
  .brand-list {
    column-count: 2;
    column-gap: vw(20);
    background: #f8f8f8;
    padding: 0 vw(20) vw(20) vw(20);
  }
  .brand-vertical-box {

  }
}
</style>
