import { render, staticRenderFns } from "./questions.vue?vue&type=template&id=01fdf39c&scoped=true"
import script from "./questions.vue?vue&type=script&lang=js"
export * from "./questions.vue?vue&type=script&lang=js"
import style0 from "./questions.vue?vue&type=style&index=0&id=01fdf39c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fdf39c",
  null
  
)

export default component.exports