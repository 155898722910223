<template>
  <div class="xsth-wrap">
    <p class="timer">
      距离优惠结束仅剩
      <span>{{h}}</span>:
      <span>{{m}}</span>:
      <span>{{s}}</span>
    </p>
    <div class="cell">
      <div class="cell-bd text">
        <p>
          <span>共3人</span>，<b>合计：</b><i>¥</i><ins>6498</ins>
        </p>
        <p class="orange">原价8998，立省2500</p>
      </div>
      <div class="cell-bd">
        <van-button>确认协议并支付</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      date: new Date(),
      remaining: 0,
      h: 0,
      m: 0,
      s: 0,
    };
  },
  mounted() {
    setInterval(this.tiemr, 1000)
  },
  methods: {
    tiemr() {
      let date = new Date();
      this.h = 24 - date.getHours() - 1;
      this.m = 60 - date.getMinutes() - 1;
      this.s = 60 - date.getSeconds() - 1;
    }
  }
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import '@/styles/_cell.scss';
//@import "@/styles/index.scss";

.xsth-wrap{
  background: #fff;
  .timer{
    width: 100%;
    height: vw(70);
    background: url("/static/vip/enterprise/img-bg-xsth.png") no-repeat;
    background-size: 100%;
    font-size: vw(26);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: vw(70);
    text-align: right;
    padding-right: vw(12);
    span{
      background: #FFFAF7;
      box-shadow: 0px 0px vw(8) 0px rgba(255, 89, 0, 0.41);
      border-radius: vw(5);
      padding: vw(6) vw(9);
      font-size: vw(24);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #743F00;
      line-height: vw(33);
      margin: 0 vw(12);
    }
  }
  .text{
    padding: vw(16);
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: vw(33);
    text-shadow: 0px vw(-2) vw(6) rgba(34, 34, 34, 0.12);
    b{
      color: #1D2233;
    }
    i{
      font-size: vw(24);
      color: #FF5900;
      font-style: normal;
    }
    ins{
      font-size: vw(36);
      color: #FF5900;
      font-style: normal;
      text-decoration: none;
    }
    .orange{
      color: #FF5900
    }
    p{
      line-height: vw(40);
    }
  }
  .van-button{
    width: 100%;
    height: vw(80);
    background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
    box-shadow: 0px vw(-2) vw(6) 0px rgba(34, 34, 34, 0.12);
    border-radius: vw(10);
    color:#fff;
  }
}
</style>
