<template>
    <ul class="shop-item">
        <li class="left">
            <img class="top-icon" src="/static/icon/hot-take-1.png" alt="" v-if="idx == 0" />
            <img class="top-icon" src="/static/icon/hot-take-2.png" alt="" v-else-if="idx == 1" />
            <img class="top-icon" src="/static/icon/hot-take-3.png" alt="" v-else-if="idx == 2" />
            <span class="num-icon" v-else>{{ idx + 1 }}.</span>
        </li>
        <li class="right">
            <p class="name">{{ bgmData.name }}</p>
            <div class="mid-wrap">
                <span> <i class="i">评分：</i> {{ bgmData.score === '0.00' ? '-' : bgmData.score || "-" }}</span>
                <span v-if="bgmData.comment && bgmData.comment !== '0.00'"> <i class="i">评价条数：</i> {{ bgmData.comment || "-" }}</span>
                <span v-if="bgmData.avePrice && bgmData.avePrice !== '0.00'"> <i class="i">人均：</i> {{ bgmData.avePrice || "-" }}</span>
            </div>
            <p class="addr"><span>店址：</span>{{ bgmData.address }}</p>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        idx: {
            type: Number,
            default: 0,
        },
        bgmData: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.shop-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 1.33vw;
    border: 1px solid #e7e7e7;
    padding: 2.67vw 3.2vw;
    margin-bottom: 2.67vw;
    .left {
        flex: 0 0 7.47vw;
        text-align: center;
        .top-icon {
            width: 5.87vw;
            height: 7.47vw;
        }
        .num-icon {
            font-size: 3.47vw;
            font-weight: bold;
            color: #ff5900;
        }
    }
    .right {
        flex: 1;
        min-width: 0;
        padding-left: 2.13vw;
        .name {
            height: 5.87vw;
            line-height: 5.87vw;
            font-size: 4vw;
            font-weight: 500;
            color: #1d2233;
        }
        .mid-wrap {
            height: 5.33vw;
            line-height: 5.33vw;
            font-size: 3.47vw;
            color: #666;
            margin: 1.6vw 0;
            .i {
                color: #8e9099;
                font-style: normal;
            }
            span {
                margin-right: 4vw;
            }
            :nth-last-child(1) {
                margin-right: 0;
            }
        }
        .addr {
            height: 4.8vw;
            line-height: 4.8vw;
            font-size: 3.47vw;
            color: #666;
            @include singleLine;
            span {
                color: #8e9099;
            }
        }
    }
}
</style>
