<template>
    <div class="charts">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" color="#518CDB" :back="showBack"></TitleHeader>

        <AutoView :header="false" :footer="! isPC" class="charts--view" :class="{ isPC }">
            <iframe ref="view" :src="url" frameborder="0" class="innerView">不支持内嵌窗口</iframe>
        </AutoView>

        <!-- 右侧栏 -->
        <template v-if="showMenu && menus && ctrMenu">
            <div class="charts--icon" @click="openCatalog"></div>
            <van-popup v-model="showCatalog" position="right">
                <div class="charts--menu">
                    <div class="header">
                        <h1>{{ title }}</h1>
                    </div>
                    <ul class="list">
                        <li v-for="( item, index ) of menus" :key="index"
                            :data-page="index + 1"
                            :class="{ on: lastClick === item.id, lock: item.lock, sub: item.isBgm }"
                            @click="jumpTo( item.id )">{{ item.name }}</li>
                    </ul>
                </div>
            </van-popup>
        </template>

        <SoltFooter v-show="! isPC">
            <div class="charts--footer">
                <van-button type="info" block class="s-radius__s" @click="onTap">{{ text }}</van-button>
            </div>
        </SoltFooter>

        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
const _timer = 0;
export default {
    name: 'charts',
    data () {
        return {
            title      : '上上参谋',
            loading    : true, // 数据加载
            subTitle   : '---',
            text       : '下载APP，解答您的问题',
            showBack   : true,
            showMenu   : true,
            showCatalog: false,
            isReady    : false, // 界面已就绪
            isSended   : false, // 已下发数据
            isClient   : false,
            isPC       : false,
            lastClick  : 0,

            id     : 0,
            menus  : [], // 菜单列表
            pageMap: '一,二,三,四,五,六,七,八,九,十,十一,十二,十三,十四,十五,十六,十七,十八,十九,二十'.split( ',' ), // 序号表
            first  : false,
            host   : '/report/?id=',
            entity : null, // 报告实体
        };
    },
    methods: {
        onTap () {
            top.location.href = this.HTTP.getAppStore();
        },
        jumpTo ( id ) {
            this.showCatalog = false;
            this.lastClick   = id;
            this.viewTarget.postMessage( { type: 'scroll', content: id }, '*' );
        },
        openCatalog () {
            this.showCatalog = true;
        },
        addListener ( e ) {
            switch ( e.data.type ) {
                case 'ready': {
                    this.isReady = true;
                    this.sendData();
                    break;
                }
                case 'switchFullscreen': {
                    this.showMenu = ! e.data.content;
                    this.JSBridge.callHandler( 'switchShareBtn', this.showMenu );
                    break;
                }
                case 'tapContinue': {
                    this.onTap();
                    break;
                }
            }
        },
        sendData () {
            // 已发送或未就绪或未得到数据将不调用下发
            if ( this.isSended || ! this.isReady || ! this.entity ) return;
            this.isSended = true; // 标记为已下发
            clearTimeout( _timer ); // 清理超时提醒
            setTimeout( () => { this.loading = false; }, 1000 );
            this.viewTarget.postMessage( { type: 'data', content: this.entity }, '*' );
        },
    },
    watch: {
        entity ( e ) {
            // 组装菜单数据
            const list = [];
            if ( e.config.showCover ) {
                list.push( {
                    id  : 'cover',
                    name: '封面',
                } );
            }
            if ( e.config.showCatalog ) {
                list.push( {
                    id  : 'catalog',
                    name: '目录',
                } );
            }
            if ( e.config.showConclusion ) {
                list.push( {
                    id  : 'conclusion',
                    name: '概览',
                } );
            }
            let index = 0;
            for ( const model of e.reportModelInstanceFullList ) {
                const lock = ! e.payed && model.type > 1;
                list.push( {
                    id   : model.id,
                    name : `${this.pageMap[ index ]}. ${model.name}`,
                    isBgm: false,
                    lock,
                } );
                index ++;
                if ( model.hasOwnProperty( 'bgmInstanceFullList' ) === false ) continue;
                for ( const bgm of model.bgmInstanceFullList ) {
                    let name = bgm.name.split( '-' );
                    name = name[ name.length - 1 ];
                    list.push( {
                        id   : bgm.bgmId,
                        name,
                        isBgm: true,
                        lock,
                    } );
                }
            }
            this.menus = list;
        },
    },
    computed: {
        unlock () {
            return this.entity && ( this.entity.payed || this.entity.priceFen === 0 );
        },
        ctrMenu () {
            return this.entity && [ 10001 ].includes( this.entity.stdQuestionId );
        },
        url () {
            if ( ! this.entity ) return 'about:blank';
            return this.host + Date.now();
        },
        viewTarget () {
            return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
        },
    },
    created () {
        // 获取分享报告TOKEN
        this.token = this.$route.params.token;
        // 获取报告地址
        this.host  = this.HTTP.getReportUrl();
        // 添加通信监听
        window.addEventListener( 'message', this.addListener );
        // 获取报告数据
        this.HTTP.getReportShare( this.token ).then( ( res ) => {
            this.title  = res.name || '上上参谋';
            this.id     = res.id;
            // 是否定制报告
            const stdId     = res[ 'stdQuestionId' ];
            const isCustom  = [ 10001, 10002, 10003, 10004, 10005, 10006 ].includes( stdId );
            // 扩展字段
            res[ 'config' ] = {
                isCustom      : isCustom, // 是否定制报告
                isShare       : true, // 是否来自分享
                showTitle     : false, // 是否显示报告标题
                showHeader    : true, // 是否显示报告头
                showConclusion: true, // 是否显示报告结论
                showCover     : isCustom ? `//${window.location.host}/static/img/${stdId}.jpg` : true, // 是否显示报告封面 支持url
                showInputs    : true, // 是否显示报告参数
                showCatalog   : true, // 是否显示报告目录
                templateId    : stdId,
                isNewNeay: window._appCode >= 200 || !this.isClient, // 新版本显示新的周边
            };
            this.entity = res;
            // 修改报告标题
            const titleMap = {
                0    : '生意军师，上上参谋',
                10001: '选址分析报告',
                10002: '市场调研报告',
            };
            document.title = titleMap.hasOwnProperty( stdId ) ? titleMap[ stdId ] : titleMap[ 0 ];
            // 修改下载按钮文案
            if ( stdId === 10001 ) this.text = '下载上上参谋，定制选址分析报告';
            // 下发数据
            this.sendData();
        } ).catch( ( e ) => {
            if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                if ( e.code === 168000 ) {
                    this.$toast( '链接已过期！' );
                }
            } else {
                this.$toast( '网络不稳定，请稍候重试！' );
            }
        } );
        // 添加通信监听
        window.addEventListener( 'message', this.addListener );
    },
    beforeMount () {
        this.isClient = window.isClient;
        this.isPC     = window.innerWidth > 414 || this.isClient || this.$route.query.from === 'livechat';
    },
    destroyed () {
        // 移除通信监听
        window.removeEventListener( 'message', this.addListener );
        // 移除定时监听
        clearTimeout( _timer );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.charts {
    overflow: hidden;
    height: 100vh;
    background-color: $c-pageBg2;

    #{&}--view {
        overflow-y: hidden;
        .innerView {
            width: 100%;
            height: 100%;
        }

        &.isPC {
            height: 100vh;
            .innerView {
                width: 360px;
                margin-left: calc( ( 100vw - 360px ) / 2 );
            }
        }
    }

    #{&}--icon {
        position: fixed;
        right: 0;
        bottom: 13vh;
        width: 14.4vw;
        height: 12vw;
        border-top-left-radius: 6vw;
        border-bottom-left-radius: 6vw;
        background-color: white;
        box-shadow:0rem 1.33vw 4vw rgba(#33465F, .11);
        &::before {
            content: '';
            display: block;
            width: 10vw;
            height: 10vw;
            background: #283950 url(/static/icon/menu.png) no-repeat center center;
            background-size: cover;
            border-radius: 50%;
            margin: 1vw 0 0 1vw;
        }
    }
    #{&}--menu {
        position: relative;
        overflow: hidden;
        width: 65vw;
        height: 100vh;
        background-color: white;
        padding: 35vw 0 $padding;
        box-sizing: border-box;

        .header {
            position: absolute;
            top: 13.2vw;
            left: 0;
            width: 100%;
            padding: 0 $padding;
            box-sizing: border-box;
            box-shadow: 0 calc( 5vw - 1px ) 0 white, 0 5vw 0 $c-line;

            h1 {
                @include singleLine;
                font-size: $font_453;
                line-height: $font_653;
                font-weight: bold;
            }
            p {
                font-size: $font_320;
                line-height: $font_440;
                color: $c-info;
                margin-top: 1vw;
            }
        }
        .list {
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            height: 100%;
            li {
                @include singleLine;
                padding: 0 $padding * 2 0 $padding;
                height: 14.53vw;
                line-height: 14.53vw;
                font-size: $font_373;
                font-weight: bold;
                &.on {
                    color: $c-main;
                    background-color: #F8F8F9;
                }
                &.lock {
                    background: url(/static/icon/lock.png) no-repeat right $padding center;
                    background-size: 5.6vw;
                    color: $c-info;
                }
            }
        }
    }
    #{&}--footer {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;

        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
    }
}

// PC适配
@media all and (min-width: 768px) {
    .charts {
        #{&}--icon {
            width: 5vw;
            height: 4vw;

            &::before {
                width: 3vw;
                height: 3vw;
                margin: .5vw 0 0 .5vw;
                background-size: 2vw;
            }
        }

        #{&}--menu {
            width: 35vw;
            padding: 10vw 0 2.5vw;
            .header {
                top: 0;
                padding: 0 2vw;
                height: 10vw;
                h1, p {
                    font-size: 1.5vw;
                    line-height: 5vw;
                    margin: 0;
                }
            }

            .list {
                li {
                    padding: 0 2vw;
                    font-size: 1.5vw;
                    line-height: 5vw;
                    height: auto;

                    &.lock {
                        background-size: 2vw;
                    }
                }
            }
        }
    }
}
</style>
