 <template>
    <div class="detail">
        <StateBar></StateBar>
        <TitleHeader title="地图标注-店铺上传至地图" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView :header="true" :footer="true" class="detail--view">
            <van-skeleton :loading="!firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
            <div v-show="firstLoading">
                <van-swipe :autoplay="3000" class="swiper" ref="swipe">
                    <van-swipe-item style="width: 100vw" v-for="(item, index) of bannerResList" :key="index">
                        <div class="swipe-item">
                            <PlayVideo v-if="item.type == 'video'" :src="item.url" :poster="item.cover"/>
                            <van-image v-if="item.type == 'image'" fit="contain" :src="item.url" />
                        </div>
                    </van-swipe-item>
                </van-swipe>
                <VanImage @load="imageLoadDone" src="/static/img/mapMark_bg1.png"></VanImage>
                <VanImage @load="imageLoadDone" src="/static/img/mapMark_bg2.png"></VanImage>
                <VanImage @load="imageLoadDone" src="/static/img/mapMark_bg3.png"></VanImage>
            </div>
        </AutoView>
        <SoltFooter class="footerBar">
            <van-row class="view">
                <van-col span="12">
                    <p class="price"><sub>￥</sub>78.00<span>/起</span></p>
                </van-col>
                <van-col span="12">
                    <van-button type="info" block @click="onTapClick">立即标注</van-button>
                </van-col>
            </van-row>
        </SoltFooter>
        <!-- 标注类型弹窗 -->
        <van-popup
            v-model="showMarkType"
            round
            closeable
            position="bottom"
            :safe-area-inset-bottom="false"
            :close-on-click-overlay="false"
            @click-overlay="onTapClose"
        >
            <div class="l-panel f-vp subview">
                <h3>选择需要标注的地图平台</h3>
                <p>支持标注高德地图/百度地图/腾讯地图/微信地图</p>
                <div class="view">
                    <div class="item" :class="{ selected : goodsId === item.goodId }" v-for="item in goodsList" :key="item.goodsId" @click="selectMarkType(item)">
                        <div class="left">
                            <div class="map-info">
                                <img :src="getIcon(item.goodId)" alt="" />
                                <span>{{item.name}}</span>
                            </div>
                            <div class="price-info">￥{{getPrice(item.price)}}</div>
                        </div>
                        <span class="checked-item" :class="{ checked : goodsId === item.goodId }"></span>
                    </div>
                </div>
                <van-button type="info" block class="s-radius__s" @click="gotoFillng">立即标注</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
let picNum = 0;
import PlayVideo from '@/components/VideoPlay/index'
export default {
    data() {
        return {
            videoLoading: false,
            firstLoading: false,
            showMarkType: false,
            price: "",
            goodsId: 0,
            poster: "",
            src: "",
            goodsList: [],
            bannerResList: null
        };
    },
    components: {
        PlayVideo
    },
    mounted() {
        this.HTTP.getGoodsDetail(10085).then(res => {
            this.bannerResList = res.data.bannerResList
        })
        this.HTTP.getMapGoods().then(res => {
            this.goodsList = res
            this.price = this.getPrice(res[0].price)
            this.goodsId = res[0].goodId
        })
    },
    methods: {
        imageLoadDone() {
            picNum++;
            this.firstLoading = picNum === 3;
        },
        getPrice(val) {
            return (val / 100).toFixed(2)
        },
        getIcon(id) {
            if(id === 10085) {
                return "/static/icon/all_map.png"
            }
            if(id === 10086) {
                return "/static/icon/gaode_map.png"
            }
            if(id === 10087) {
                return "/static/icon/baidu_map.png"
            }
            if(id === 10088) {
                return "/static/icon/tx_map.png"
            }
            if(id === 10089) {
                return "/static/icon/wx_map.png"
            }
        },
        onTapClick() {
            this.showMarkType = true;
        },
        onTapClose() {
            this.showMarkType = false;
        },
        selectMarkType(item) {
            this.price = this.getPrice(item.price)
            this.goodsId = item.goodId
        },
        gotoFillng() {
            this.showMarkType = false
            this.swRouter({
                path: "/mapMark/filling",
                query: {
                    price: this.price,
                    goodsId: this.goodsId
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.detail {
    width: 100%;
    height: 100vh;
}
::v-deep .van-swipe-item {
    img {
        width: 100vw;
        height: 56.26vw;
    }
}
::v-deep .van-image {
    display: block;
    // position: relative;
}
.footerBar {
    .view {
        box-sizing: border-box;
        padding: $headerMargin $padding;
        width: 100%;
        height: $footerHeight;
        ::v-deep .van-col {
            height: 100%;
        }
    }
    .price {
        font-size: $font_533;
        line-height: 10vw;
        font-weight: bold;
        color: #FF504E;
        span {
            color: #8893a7;
            font-size: $font_293;
        }
    }
    ::v-deep sub {
        font-size: $font_320;
        vertical-align: bottom;
    }
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
        background-color: $c-main;
    }
}
.subview {
    h3 {
        text-align: center;
        font-size: $font_400;
        margin-bottom: 2vw;
    }
    p {
        text-align: center;
        font-size: $font_320;
        color: #676c84;
    }
    .view {
        padding: $margin 0;
        .item {
            font-size: $font_373;
            height: 5.33vw;
            line-height: 5.33vw;
            border: 0.267vw solid transparent;
            border-radius: $radius-xs;
            padding: 4vw 5.33vw;
            margin-bottom: 2.667vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
                width: 65vw;
                height: 5.33vw;
                line-height: 5.33vw;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .map-info {
                    color: #121622;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    img {
                        width: 5.6vw;
                        height: 5.6vw;
                        margin-right: 2vw;
                    }
                }
                .price-info {
                    color: #ff504e;
                }
            }
            .checked-item {
                width: 4vw;
                height: 4vw;
                font-size: 0;
                border-radius: 50%;
                background: white url(/static/icon/unselect@2x.png) no-repeat center center;
                background-size: 4vw 4vw;

                &.checked {
                    background: url(/static/icon/success.png) no-repeat center center;
                    background-size: 3.8vw 3.8vw;
                }
            }
        }
        .selected {
            border: 0.267vw solid $c-main;
            border-radius: $radius-xs;
        }
    }
    ::v-deep .van-button {
        font-size: $font_400;
        line-height: normal;
    }
}
</style>
