<template>
    <ul class="header-wrap">
        <li class="left">
            <img :src="bgmData.logo" alt="" />
        </li>
        <li class="right">
            <div class="top">
                {{ bgmData.name }}
                <van-tag style="border: 1px solid #ff9f6b; border-radius: 3px" color="#FFF1E9" text-color="#FF5900" v-if="bgmData.categoryNameL3">
                    {{ bgmData.categoryNameL3 }}
                </van-tag>
            </div>
            <div class="mid">
                <img class="icon" src="/static/report/icon_shop.png" alt="" />
                在营：{{ bgmData.openingNumber ? bgmData.openingNumber + "家" : "-" }}
            </div>
            <div class="bottom">
                <img class="icon" src="/static/report/icon_money.png" alt="" />
                人均：{{ bgmData.avePrice ? bgmData.avePrice + "元" : "-" }}
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        bgmData: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            show: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.header-wrap {
    display: flex;
    width: 100%;
    padding: 4vw;
    box-sizing: border-box;
    background: #f7f7f7;

    .left {
        flex: 0 0 21.87vw;
        text-align: left;

        img {
            width: 18.67vw;
            height: 18.67vw;
            object-fit: cover;
            border-radius: 0.8vw;
        }
    }

    .right {
        flex: 1;
        text-align: left;

        .top {
            height: 5.87vw;
            line-height: 5.87vw;
            font-size: 4.27vw;
            color: #1d2233;
            font-weight: bold;
        }

        .mid {
            font-size: 3.2vw;
            color: #333;
            margin: 2.13vw 0;
        }

        .bottom {
            font-size: 3.2vw;
            color: #333;
        }

        .icon {
            width: 3.73vw;
            height: 3.73vw;
            vertical-align: middle;
        }
    }
}
</style>
