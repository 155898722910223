<template>
	<div id="player" class="player">
    <video v-if="isIOS"
           poster="https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/luojianqijieshao.mp4?x-oss-process=video/snapshot,t_0,f_jpg,w_750"
           controls
           ref="video"
      src="https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/luojianqijieshao.mp4"
    />
  </div>
</template>

<script>
	import Player from "xgplayer";
  import Views from "../../home/views";
	export default {
    components: { Views },
    props: {
			poster: {
				type: String,
				default: ""
			},
			src: {
				type: String
			},
			autoplay: {
				type: Boolean,
				default: false
			}
		},
    data() {
      return {
        isIOS: false,
      }
    },
		mounted() {
      if (window.isIOS) {
        this.isIOS = true;
        this.$refs.video.onplay = () => {
          this.onplay();
        }
      } else {
        this.initVideo();
      }
      // setTimeout(() => {
      //   this.initVideo();
      // }, 100);
		},
    methods: {
      onplay(){
        this.$emit('onceClick')
      },
      initVideo(){
        let sscmPlayer = new Player({
          id: "player",
          // poster: this.poster + '?x-oss-process=video/snapshot,t_0,f_jpg,w_750', //  封面图
          // url: this.src,
          poster: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/luojianqijieshao.mp4?x-oss-process=video/snapshot,t_0,f_jpg,w_750', // 封面图
          url: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/luojianqijieshao.mp4',
          playsinline: true,
          "x5-video-player-type": "h5",
          "x5-video-player-fullscreen": "false",
          closeVideoClick: true,
          closeVideoTouch: true,
          autoplay: this.autoplay, // 自动播放
          videoInit: true, // 显示首帧
          fluid: true, // 流式布局
          // fitVideoSize: "fixWidth",
          ignores: ["volume"],
        });
        sscmPlayer.on('play', () => {
          this.$emit('onceClick')
        })
      }
    }
	}
</script>

<style lang="scss" scoped>
	.player {
		width: 100%;
    video{
      width: 100%;
      //height: vw(100);
    }
	}
</style>
