<template>
    <div class="page">
        <StateBar bgcolor="#fff" />
        <TitleHeader title="万店复苏计划" color="#333" bgcolor="#fff" :back="true"></TitleHeader>
        <van-skeleton :loading="!loading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
        <AutoView class="wdfs-view">
            <div v-show="loading">
                <div class="banner">
                    <VanImage @load="imageLoadDone" src="/static/img/channel/img-main-banner.png"></VanImage>
                    <img class="activity-rules" @click="showActivityPopup" src="/static/img/channel/activity-rules.png" alt="">
                </div>
                <section class="video-wrap">
                    <div class="mse" id="mse"></div>
                </section>
                <section class="welfare-one">
                    <ul>
                        <li v-for="(item, index) in welfareOneList" :key="index" @click="clickHandle(index)">
                        <img :src="item.url">
                        <div class="desc">
                            <div class="title">{{ item.title }}</div>
                            <div class="count">{{ item.count }}<span>折</span><span v-if="index == 2">起</span></div>
                        </div>
                        </li>
                    </ul>
                  <div class="full-img-wrap">
                    <img v-lazy="'/static/img/channel/stlm.png'">
                  </div>
                </section>
                <!-- <section class="welfare-two">
                    <VanImage @load="imageLoadDone" src="/static/img/channel/img-welfare-two.png"></VanImage>
                    <VanImage @load="imageLoadDone" src="/static/img/channel/img-welfare-three.png"></VanImage>
                </section> -->
            </div>
        </AutoView>
        <van-popup
            v-model="showActivity"
            round
            closeable
        >
            <div class="rules">
                <h3>活动规则</h3>
                <div class="rules-desc">
                    <span>1、活动时间：2022年4月24日10:00至2022年6月15日23点59分59秒。</span><br/>
                    <span>2、会员试用权益领取后，账号注册成功即开始生效，到期则自动失效，请领取后尽快试用体验相关会员服务，过期未使用不予补发。试用期结束后用户可选择购买正式VIP会员，如购买时尚在试用期内，则正式VIP会员生效时间自试用期结束之日起。</span><br/>
                    <span>3、本活动链接中VIP会员试用服务仅适用于上上参谋/数位拓店云/数位观察未注册新用户及上上参谋已注册用户。</span><br/>
                    <span>4、会员试用权益每个手机号仅限领取1次，同手机号、终端设备相同等与用户身份相关的信息存在相同、相似或非真实有效等情况的，均可能被认定为同用户，按照营销活动规则中的同一用户处理。</span><br/>
                    <span>5、成为试用期会员后，可享受的会员权益以网站或APP内公布的信息为准。为更好的向会员提供服务，上上参谋/数位观察/数位拓店云有权基于自身业务发展需要调整全部或部分会员权益。前述权益调整将在相应服务页面进行通知或公告。</span><br/>
                    <span>6、如出现不可抗力或情势变更的情况（包括但不限于重大灾害事件、活动受政府机关指令停办或调整，活动遭受严重网络攻击或因系统故障需要暂停举办的），活动方可依据相关法律法规的规定主张免责。</span><br/>
                    <span>7、用户参与本活动过程中不得将所获得的优惠转让予他人，不得将该优惠用于出售等目的，也不可将该优惠进行任何形式的变现或赢利。</span><br/>
                    <span>8、如有任何关于此活动的咨询和帮助需要，请咨询上上参谋APP在线客服/数位观察数据咨询。</span>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
let picNum = 0
import Player from "xgplayer";
export default {
    name: 'index',
    data () {
        return {
            active: '',
            id: null,
            moduleId: 120000,
            loading: false,
            showActivity: false,
            welfareOneList: [
                { url: "/static/img/channel/img-card-1.png", title: "开店位置推荐", count: 5.9 },
                { url: "/static/img/channel/img-card-2.png", title: "开店选址评估", count: 6 },
                { url: "/static/img/channel/img-card-3.png", title: "VIP会员", count: 3.7 },
                { url: "/static/img/channel/img-card-4.png", title: "外卖选址评估", count: 6 },
                { url: "/static/img/channel/img-card-5.png", title: "地图标注", count: 6 },
                { url: "/static/img/channel/img-card-6.png", title: "赚钱选品类", count: 5.8 },
                { url: "/static/img/channel/img-card-7.png", title: "竞争分析", count: 6.9 },
                { url: "/static/img/channel/img-card-8.png", title: "包君成套餐", count: 6 },
            ]
        };
    },
    mounted() {
        this.id = this.$route.meta.id
        this.$nextTick(() => {
            let sscmPlayer = new Player({
                id: "mse",
                // poster: "", // 封面图
                url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_sscm.mp4",
                playsinline: true,
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": "false",
                closeVideoClick: true,
                closeVideoTouch: true,
                autoplay: false, // 自动播放
                videoInit: true, // 显示首帧
                fluid: true, // 流式布局
                // fitVideoSize: "fixWidth",
                ignores: ["volume"],
            });

            sscmPlayer.once('play', () => {
                this.JSBridge.BTNCLICK(this.id, '', 130000, 130001);
            })
        })
    },
    methods: {
        imageLoadDone() {
            picNum++;
            this.loading = picNum === 1;
        },
        clickHandle(index) {
            if(window.isClient) {
                let id, btnId
                switch(index) {
                    case 0: // 位置推荐
                    case 1: // 选址评估
                    case 3: // 外卖选址
                        id = index === 0 ? 10026 : index === 1 ? 10001 : 10084
                        btnId = index === 0 ? 120001 : index === 1 ? 120002 : 120004
                        this.JSBridge.BTNCLICK(this.id, '', this.moduleId, btnId);
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: window._appCode >= 300 ? "BRAND_INTRODUCTION_V3" : "BRAND_INTRODUCTION_V2",
                            id: id,
                            isClear: null,
                        });
                        break;
                    case 2: // 会员
                        this.JSBridge.BTNCLICK(this.id, '', this.moduleId, 120003);
                        this.swRouter("/h5/#/member")
                        break;
                    case 4: // 地图标注
                        this.JSBridge.BTNCLICK(this.id, '', this.moduleId, 120005);
                        this.swRouter("/mapMark/detail")
                        break;
                    case 5: // 招商机
                    case 6: // 竞争分析
                        id = index === 5 ? 10015 : 10006
                        btnId = index === 5 ? 120006 : 120007
                        this.JSBridge.BTNCLICK(this.id, '', this.moduleId, btnId);
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "BRAND_INTRODUCTION",
                            id: id,
                            params: null,
                            trackData: null,
                            isClear: null,
                        });
                        break;
                    case 7: // 包君成套餐
                        this.JSBridge.BTNCLICK(this.id, '', this.moduleId, 120008);
                        let pkgId = "131328350905667"
                        if(process.env.NODE_ENV === "production") {
                            pkgId = "130344226383685"
                        }
                        this.swRouter(`/payGoods/${pkgId}`)
                        break
                    default:
                        this.$toast.fail("网络连接错误")
                }
            }
        },
        showActivityPopup() {
            this.showActivity = true
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import "@/styles/index.scss";

*{
    padding: 0;
    margin: 0;
    border: 0;
}
.page{
    font-size: 0;
    .banner{
        position: relative;
        img{
            width: 100%;
        }
        .activity-rules {
            width: vw(98);
            height: vw(38);
            position: absolute;
            top: vw(20);
            right: 0;
        }
    }
    .video-wrap{
        background: #DF4F32;
        padding: vw(18);
        padding-top: vw(30);
        min-height: vw(404);
        .mse {
            width: 90vw;
            height: 20vw;
        }
    }
    .welfare-one{
        position: relative;
        padding: vw(10);
        background: url("/static/img/channel/img-sub-forapp.png") no-repeat;
        background-color: #FFDEC2;
        background-size: 100% auto;
        padding-top: vw(100) ;
        ul{
            li{
                width: vw(345);
                height: vw(269);
                overflow: hidden;
                display: inline-block;
                margin: vw(10);
                img{
                    width: 100%;
                }
                .desc {
                    height: vw(69);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #fff;
                    color: #451D11;
                    font-weight: bold;
                    font-size: $font_373;
                    padding: 0 vw(20);
                    border-bottom-left-radius: $radius-xs;
                    border-bottom-right-radius: $radius-xs;
                    .count {
                        color: #FF8246;
                        font-size: $font_400;
                        span {
                            font-size: $font_320;
                        }
                    }
                }
            }
        }
    }
    .welfare-two{
        background: #FFDEC2;
        img{
            width: 100%;
            margin-bottom: vw(27);
        }
    }
    .rules {
        width: vw(638);
        height: vw(814);
        background-image: linear-gradient(rgba(243, 248, 255, 1), rgba(243, 248, 255, 0));
        h3 {
            text-align: center;
            font-size: $font_400;
            padding-top: vw(30);
            line-height: vw(50);
        }
        .rules-desc {
            font-size: $font_347;
            padding: vw(20) vw(40);
            span {
                color: #8893A7;
                display: inline-block;
                line-height: vw(37);
                margin-bottom: vw(20);
            }
        }
    }
    .full-img-wrap{
      padding-top: vw(40);
      img{
        width: 100%;
      }
    }
}

::v-deep .xgplayer-skin-default .xgplayer-enter .xgplayer-enter-spinner {
    height: 60px;
    width: 60px;
}
</style>
