<template>
  <div class="module-view">
    <div class="ask-wrap">
      <van-image class="ask-icon" fit="cover" src="/static/icon/ask.png" />
      {{ dataInfo.questionName || "主公" }}
      <p class="ask-question fs-14">
        {{ dataInfo.question }}
      </p>
      <div class="float-right" v-if="icon">{{ dataInfo.tag }}</div>
    </div>
    <div class="ask-wrap mr-10" v-if="dataInfo.answer">
      <van-image class="ask-icon" fit="cover" src="/static/icon/reply.png" />
      {{ dataInfo.answerName || "上上参谋" }}
      <div class="ask-question fs-13">
        <p>{{ dataInfo.answer }}</p>
        <template v-if="dataInfo.answerImages">
          <van-image
            radius="3"
            @click="seeBigImg(index)"
            v-for="(item, index) in dataInfo.answerImages"
            :key="item"
            class="ask-img mr-10"
            fit="cover"
            :src="item"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";

export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    seeBigImg(idx) {
      ImagePreview({
        images: this.dataInfo.answerImages,
        loop: true,
        showIndex: false,
        closeable: false,
        startPosition: idx,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.module-view {
  background: #fff;
  padding: 3.2vw;
  border-radius: 1.33vw;
  margin-bottom: 2.67vw;
  .ask-wrap {
    position: relative;
    line-height: 4.8vw;
    font-size: 3.2vw;
    color: #666;
    .ask-icon {
      width: 4.8vw;
      height: 4.8vw;
      vertical-align: middle;
      margin-right: 1.6vw;
    }
    .ask-question {
      color: #333;
      margin-top: 1.33vw;
      padding-left: 6.4vw;
      word-break: break-all;
    }
    .fs-14 {
      font-size: 3.73vw;
      font-weight: bold;
    }
    .fs-13 {
      font-size: 3.47vw;
    }
    .ask-img {
      width: 20.27vw;
      height: 20.27vw;
      margin-right: 2.67vw;
    }
    .float-right {
      position: absolute;
      top: 0;
      right: 3.2vw;
      height: 5.33vw;
      line-height: 5.33vw;
      padding: 0 1.6vw;
      font-size: 3.2vw;
      color: #609eff;
      background: #f1f7ff;
      border-radius: 0.8vw;
    }
  }
  .mr-10 {
    margin-top: 2.67vw;
  }
}
</style>
