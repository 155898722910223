<template>
  <div class="signin">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true">
      <div slot="right" v-show="!loading" class="rule" @click="ruleSuccess = true">活动规则</div>
    </TitleHeader>

    <!-- 骨架屏占位图 -->
    <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

    <AutoView ref="view" :header="true" :footer="false" class="signin--view" :style="{ opacity: loading ? 0 : 1 }">
      <div class="signin--main">
        <van-image class="banner" fit="cover" :src="activeResp.bannerImg" />
        <div class="main-warp">
          <!-- 签到打卡 -->
          <Signin
            :userActiveRes="userActiveRes"
            :goodsList="goodsRespList"
            :computedWid="computedWid"
            :status="activeResp.activeStatus"
            :activeId="activeId"
            @loadData="loadData"
          />

          <div class="sum-img"></div>

          <!-- 优惠券列表 -->
          <Award :signData="goodsRespList" />

          <div class="explain-card">
            <van-image class="img" fit="cover" src="/static/img/signin/signin_explain.png" v-if="userActiveRes.bigGiftStatus == 0" />
            <van-image class="img" fit="cover" src="/static/img/signin/signin_explain_has.png" v-else />
            <div class="explain-top">
              <p class="top">限量福利</p>
              <p class="bottom">累计7天签到，前50名免费送神秘大礼包</p>
            </div>
            <p class="explain-bottom">7样大礼包；价值￥1128免费送</p>
          </div>

          <!-- 下期预告icon -->
          <div class="hint-img"></div>
          <ul class="expect-ul">
            <li class="li-left">
              <p class="title-t">清爽夏至</p>
              <p class="title-m">多重好礼，助力创业</p>
            </li>
            <li class="li-right">
              <van-image class="expect-img" fit="cover" :src="activeResp.bottomBanner" />
              <p class="next-time">6月21日0：00</p>
            </li>
          </ul>
          <!-- 企业微信 -->
          <div class="contact-img"></div>
          <div class="wechat-img">
            <van-image class="expect-img" fit="cover" src="/static/img/signin/signin_wechat.png" />
          </div>
        </div>
      </div>
    </AutoView>

    <!-- 规则 -->
    <van-popup v-model="ruleSuccess" round closeable>
      <div class="wrap">
        <h4>活动规则</h4>
        <div class="tips">
          <p class="tips-title">活动规则：</p>
          1. 首次参与签到即可获得第一阶段礼品；<br />
          2. 签到进度达到对应每个礼品进度可获得对应礼品权益折扣/兑换券；（券的有效期为7天）<br />
          3. 每次签到增加进度累积，累计签到满7次可领取签到活动中所有不限量奖励（千元大礼包除外）；<br />
          4. 前50名签到满7天的用户可获得价值千元超级大礼（*详情见活动奖品第5条）。
        </div>
        <div class="tips">
          <p class="tips-title">活动奖品：</p>
          1. 签到满1天可获得 商圈数据（1次）、客群画像（1次）7.5折折扣券（有效期7天）；<br />
          2. 签到满3天可获得 商圈数据（1次）、客群画像(1次)5折折扣券（有效期7天）;<br />
          3. 签到满5天可获得 商圈数据（1次）、客群画像(1次)兑换券（有效期7天）；<br />
          4. 签到满7天可获得AI智能选址（1次）3折折扣券（有效期7天）；<br />
          5. 前50名签到满7天的用户获得以下大礼包（礼包内所有礼品以兑换券形式发放，兑换券有效期7天）：
          <p class="tips-list">
            AI智能选址（1次），价值358元；<br />
            周边分析单月（1份），价值158元；<br />
            周边数据导出（3次），价值15元；<br />
            商圈数据（1次），价值138元；<br />
            赚钱找商机（1次），价值168元；<br />
            加盟品牌分析（1次），价值38元；<br />
            加盟找位置（1次），价值258元。
          </p>
        </div>
        <p class="tips">注：活动最终解释权为上上参谋APP运营方所有。</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Award from "@/components/card/award.vue";
import Signin from "@/components/card/signin.vue";

export default {
  components: { Award, Signin },
  data() {
    return {
      loading: true,
      activeId: 2,
      ruleSuccess: false, // 规则弹框
      title: "",
      activeResp: {},
      goodsRespList: [],
      userActiveRes: {},
      computedWid: 0,
    };
  },
  created() {
    const id = this.$route.params.id;
    if (!id) {
      this.$toast("无效活动id，即将返回首页！");
      this.$router.push("/");
      return;
    }
    this.activeId = id;
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.HTTP.getSigninDetail(this.activeId)
        .then((res) => {
          console.log(res);
          this.activeResp = res.data.activeResp || {};
          this.activeResp.bannerImg = this.activeResp.bannerImages && this.activeResp.bannerImages[0];
          this.activeResp.bottomBanner = this.activeResp.bottomBanner;
          this.title = res.data.activeResp.activeName;
          this.goodsRespList = res.data.goodsRespList || [];
          this.userActiveRes = res.data.userActiveRes || {};
          this.computedWid = this.userActiveRes.total * 14.28;
          this.loading = false;
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍候重试！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.signin {
  height: 100vh;
  .rule {
    font-size: 3.47vw;
    color: #4b8eea;
  }

  #{&}--view {
    scroll-behavior: smooth;
    background: linear-gradient(99deg, #ffe49a 0%, #ffd36a 66%, #fdc441 100%);
  }

  #{&}--main {
    border-radius: 2.67vw 2.67vw 0 0;

    .banner {
      width: 100%;
      height: 80vw;
      // background: url("/static/img/signin/signin_banner.png") no-repeat center;
      // background-size: cover;
    }

    .main-warp {
      position: relative;
      top: -4.27vw;
      padding: 0 2.13vw;
      border-radius: 2.67vw;
      background: linear-gradient(99deg, #ffe49a 0%, #ffd36a 66%, #fdc441 100%);

      .sum-img {
        position: relative;
        top: -1.6vw;
        width: 52.8vw;
        height: 5.87vw;
        margin: 0 auto;
        background: url("/static/img/signin/signin_sum.png") no-repeat center;
        background-size: cover;
      }

      .explain-card {
        position: relative;
        margin: 4.8vw 0;
        .explain-top {
          position: absolute;
          top: 3.73vw;
          left: 4vw;
          .top {
            font-size: 4.27vw;
            color: #9e5000;
            font-weight: bold;
          }
          .bottom {
            font-size: 3.47vw;
            color: #ffe289;
            margin-top: 2.13vw;
          }
        }
        .explain-bottom {
          position: absolute;
          bottom: 4.27vw;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 3.47vw;
          color: #feedc2;
        }
      }
      .hint-img {
        width: 41.33vw;
        height: 5.87vw;
        margin: 0 auto 4.8vw;
        background: url("/static/img/signin/signin_next.png") no-repeat center;
        background-size: cover;
      }
      .expect-ul {
        display: flex;
        padding: 3.2vw;
        background: #fff;
        border-radius: 2.67vw;
        .li-left {
          flex: 1;
          margin-top: 4.8vw;
          .title-t {
            font-size: 4.27vw;
            color: #333;
          }
          .title-m {
            font-size: 3.73vw;
            color: #666;
            margin-top: 2.67vw;
          }
        }
        .li-right {
          position: relative;
          flex: 1;
          text-align: center;
          .expect-img {
            width: 100%;
            height: 24vw;
            object-fit: cover;
            border-radius: 3px;
          }
          .next-time {
            position: absolute;
            bottom: 0.53vw;
            left: 0;
            width: 100%;
            height: 5.87vw;
            line-height: 5.87vw;
            font-size: 3.2vw;
            color: #fff;
            opacity: 0.89;
            background: rgba(0, 0, 0, 0.53);
            text-align: center;
          }
        }
      }
      .contact-img {
        width: 41.33vw;
        height: 5.87vw;
        margin: 4.8vw auto;
        background: url("/static/img/signin/signin_contact.png") no-repeat center;
        background-size: cover;
      }

      .wechat-img {
        height: 48vw;
        margin-top: 4.8vw;
      }
    }
  }

  .wrap {
    position: relative;
    padding: 0 4vw 0 8vw;
    margin: 10vw 0 4vw 0;
    text-align: center;
    height: 70vh;
    width: 78vw;
    overflow: auto;

    .tips {
      font-size: 3.73vw;
      color: #333;
      text-align: left;
      line-height: 6.4vw;
      margin: 2.13vw 0 3.73vw 0;
      .tips-title {
        font-size: 4vw;
        color: #000;
      }
      .tips-list {
        font-size: 3.47vw;
      }
    }
  }
}
</style>
