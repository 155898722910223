<template>
<div class="review">
    <StateBar ></StateBar>
    <TitleHeader :title="investmentTitle" :back="true" style="border-bottom: 1px solid #EBEAEE;box-sizing: border-box;"></TitleHeader>

    <AutoView :header="true" :footer="false" class="investment--view">
        <div class="review--content">
            <div class="card-box">
                <div class="head-title">{{name}}</div>
                <brand-table :dataList="tableList"></brand-table>
                <template v-if="imgList && imgList.length > 0">
                    <div class="head-title">资质证书</div>
                    <scroll-new-swiper :imgArr="imgList" :widthParams="24.53" :heightParams="17.33"></scroll-new-swiper>
                </template>

                <template v-if="collList && collList.length > 0">
                    <div class="head-title marginTop">数位线下实地采集图片</div>
                    <scroll-new-swiper :imgArr="collList" :widthParams="34.67" :heightParams="26" :type="3"></scroll-new-swiper>
                </template>
            </div>
        </div>
    </AutoView>
</div>
</template>


<script>
    import { getLocalStorage } from "@/utils/index";
    import brandTable from '../components/newBrandDetail/brandTable'
    import scrollNewSwiper from '../components/newBrandDetail/scrollNewSwiper'
    export default {
        name: "newBrandReview",
        components: {
            brandTable,scrollNewSwiper
        },
        data() {
            return {
                investmentTitle: '',
                imgList: [],
                tableList: [],
                collList: [],
                reViewShow: false,
                name: null,
            }
        },
        mounted() {
            this.investmentTitle = this.$route.query.name;
            this.imgList = getLocalStorage("reviewList");
            this.collList = getLocalStorage("collectList");
            let list = getLocalStorage("companyList");
            this.name = list.name;
            let newArray = [];
            for(let i=0; i < list.data.length ;i++) {
                if(i > 2) {
                    if(list.data[i].value != '暂未披露') {
                        newArray.push({
                            name: list.data[i].name,
                            value: list.data[i].value
                        })
                    }
                } else {
                    newArray.push({
                        name: list.data[i].name,
                        value: list.data[i].value
                    })
                }
            }
            this.tableList = newArray;
        }
    }
</script>

<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .review {
        height: 100vh;
        #{&}--content{
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding: 4vw;
            .card-box{
                background: #fff;
                .head-title{
                    font-size: 4.53vw;
                    font-weight: bold;
                    color: #1D2233;
                    line-height: 6.4vw;
                }
                .marginTop{
                    margin-top: 5.06vw;
                }
            }
        }

    }
</style>
