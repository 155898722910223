<template>
    <Wraper title="消费者评分" :showIcon="true" @clickTips="clickTips" v-if="show">
        <div class="box-item">
            <!-- <div class="box-body">
                <div class="box-name">{{ bgmData.count ? `${bgmData.count}家` : "-" }}</div>
                <div class="box-value">统计门店数</div>
            </div> -->
            <!-- <div class="box-cut-line"></div> -->
            <div class="box-body">
                <div class="box-name">{{ bgmData.star || "-" }}</div>
                <div class="box-value">店均评分</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.feedbackRate ? `${bgmData.feedbackRate}%` : "-" }}</div>
                <div class="box-value">好评率</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.badReviewRate ? `${bgmData.badReviewRate}%` : "-" }}</div>
                <div class="box-value">差评率</div>
            </div>
        </div>
        <div class="box-item">
            <div class="box-body">
                <div class="box-name">{{ bgmData.singleComments ? `${bgmData.singleComments}条` : "-" }}</div>
                <div class="box-value">店均评论数</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.tasteScore || "-" }}</div>
                <div class="box-value">口味评分</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.environmentScore || "-" }}</div>
                <div class="box-value">环境评分</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.serviceScore || "-" }}</div>
                <div class="box-value">服务评分</div>
            </div>
        </div>
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";

export default {
    props: {
        bgmData: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Wraper,
    },
    computed: {
        show() {
            const { count, star, feedbackRate, badReviewRate, singleComments, tasteScore, environmentScore, serviceScore } = this.bgmData;
            return count || star || feedbackRate || badReviewRate || singleComments || tasteScore || environmentScore || serviceScore;
        },
    },
    methods: {
        clickTips() {
            this.swRouter("/reportView/dataExplain");
        },
    },
};
</script>

<style lang="scss" scoped>
.box-item {
    position: relative;
    border-radius: 1.33vw;
    background-color: #fafafb;
    padding: 3.2vw 0 4vw 0;
    display: flex;
    align-items: center;
    margin-top: 1.33vw;

    &:first-child {
        margin-top: 0;
    }

    .box-cut-line {
        position: relative;
        width: 1px;
        height: 30px;
        background-color: rgba(222, 222, 231, 0.8);
    }

    .box-body {
        position: relative;
        width: 100%;
        .box-name {
            position: relative;

            color: #1d2233;
            font-size: 3.47vw;
            font-weight: bold;
            text-align: center;
        }
        .box-value {
            position: relative;
            color: #676c84;
            font-size: 2.93vw;
            text-align: center;
            line-height: 5.33vw;
            margin-right: 0.53vw;
            margin-top: 1.33vw;
        }
    }
}
</style>
