<template>
  <div class="about">
    <StateBar></StateBar>
    <TitleHeader title="品牌加盟用户服务协议"  bgcolor="transparent" :back="showBack"></TitleHeader>

    <AutoView :header="true" :footer="false" class="about-view">
        <div class="page">
            <div class="content" style="padding-top: 0">
                <div class="subTitle center">上上参谋 品牌加盟用户服务协议</div>
                <span class="block center date">生效日期：2021年5月20日</span>
            </div>
            <div class="content">
                <p>欢迎您注册成为上上参谋品牌加盟用户！上上参谋品牌加盟服务协议（以下简称“本协议”）是由深圳市数位汇聚科技有限公司（以下简称“数位汇聚”或者“我们”）和您签订。</p>
            </div>

            <div class="content">
                <div class="subTitle">第一条 上上参谋品牌加盟用户协议的确认</div>
                <p>1、您在注册、登陆使用上上参谋品牌加盟平台提供的各项服务之前，请您仔细阅读并理解本协议全部内容，做出您认为适当的选择。我们努力用通俗易懂、简单明了的文字表达，同时对于在本协议中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。当您点击“注册”或者“登录”或其他方式确认即表示您已经仔细阅读并完全理解、同意本协议项下的全部条款。</p>
                <p>2、如果我们对本协议进行修改，我们将在上上参谋品牌加盟界面提前予以公布，更变公示期（7日）届满时生效。若您不同意修改后的协议，您有权停止使用上上参谋品牌加盟服务或者注销您的上上参谋账户；您如果继续“登录”或其他方式确认，使用上上参谋品牌加盟板块提供的服务，即表示已经仔细阅读并完全理解、同意修改后的协议。</p>
            </div>
            <div class="content">
                <div class="subTitle">第二条 账户的注册、使用、注销</div>
                <p>1、 在您开始使用上上参谋品牌加盟服务前，您应当具备中华人民共和国法律规定的与您的行为相适应的民事行为能力。</p>
                <p>2、 您同意本协议的全部条款并按照登陆页面上的提示提供您本人真实、准确、有效信息并完成全部注册程序后，才能成为上上参谋品牌加盟服务的用户。我们通过上上参谋品牌加盟平台为您提供服务。</p>
                <ul>
                    <li>（1）<strong>为了给您提供更好的品牌加盟服务，我们将会把您在本平台填写的相关联系信息提供给相似品牌方。具体对接和沟通服务由品牌方提供。</strong></li>
                </ul>
                <p>3、 您应妥善保证自己的帐号和密码，您应当对以其账户进行的所有活动和事件负法律责任。</p>
                <p>4、 您在使用上上参谋品牌加盟服务过程中，应当遵守国家法律法规以及所有与上上参谋品牌加盟服务有关的网络协议、规定、产品规则或程序，不得有下列任何行为，否则我们有权不通知您自行采取措施，包括但不限于删除用户发布的信息，限制、暂停用户使用，或终止合作永久封禁账户等措施：</p>
                <ul>
                    <li>（1）通过任何技术手段侵入我们的平台、数据库等系统或者干扰上上参谋产品和/或服务正常运行的；</li>
                    <li>（2）任何利用自有或第三方的插件、程序抓取、读取、收集上上参谋品牌加盟平台展示的任何信息的；</li>
                    <li>（3）侵犯上上参谋或第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的行为；</li>
                    <li>（4）其他违反国家法律法规以及所有与上上参谋服务有关的网络协议、规定、产品规则或程序的行为。</li>
                </ul>
                <p>5、 用户同意以其注册的手机号码、电子邮箱接收上上参谋发送的验证码、安全信息、商业广告。</p>
                <p>6、 如需要注销上上参谋账户，您可以在上上参谋APP上申请注销您的上上参谋账户。在注销账户之后，我们将停止为您提供所有的产品和服务，并依据您的要求，删除您的个人信息，但法律法规另有规定的除外。</p>
            </div>
            <div class="content">
                <div class="subTitle">第三条 用户承诺</div>
                <p>1、您应遵守中华人民共和国相关法律法规、所居住或者开展经营活动或者业务国家或者地区的法律法规，不得将我们的服务用于非法目的，不也得以非法方式使用我们的服务。</p>
                <p>2、您承诺在注册或者认证过程中，提供的资料合法、真实、有效。如提交的资料有任何变动，应该及时、准确更新。如因提交的资料不真实或者更新不及时而引发的问题，您应承担相应责任。</p>
                <p>3、您应按照平台相关规则及您与平台的相关约定合理、合法使用在上上参谋品牌加盟服务平台获取、下载的信息。</p>
                <p>4、我们将会尽其商业上的合理努力保障您在使用上上参谋服务中的数据存储安全，并在法律要求的期限内保留您的个人信息。我们不对您在上上参谋品牌加盟服务中相关数据的删除或储存失败、超出法律规定及平台承诺的储存期限以外的数据负责，您应当根据自身需求自行备份相关数据。</p>
                <p>5、您承诺在上上参谋品牌加盟服务平台上传、或者传输填写的信息合法、真实、有效，并对内容承担全部责任。您不得在上上参谋品牌加盟服务平台的任何页面发布、转载、传送含有下列任何内容的信息，否则我们有权不通知您自行采取措施，包括但不限于删除用户发布的信息，限制、暂停使用，或终止合作永久封禁账户等措施：</p>
                <ul>
                    <li>（1）违反宪法确定的基本原则的；</li>
                    <li>（2）危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</li>
                    <li>（3）损害国家荣誉和利益的；</li>
                    <li>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                    <li>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                    <li>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                    <li>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</li>
                    <li>（8）侮辱或者诽谤他人，侵害他人合法权益的；</li>
                    <li>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</li>
                    <li>（10）含有歧视内容的，如民族歧视、地域歧视、性别歧视、宗教歧视等；</li>
                    <li>（11）虚假、胁迫、猥亵、骚扰、中伤、粗俗、侵害他人隐私或其他有悖道德、令人反感的内容；</li>
                    <li>（12）侵犯我们或任何第三方的合法权利的，包括但不限于知识产权等权利；</li>
                </ul>
            </div>
            <div class="content">
                <div class="subTitle">第四条 用户违规行为的处理</div>
                <p>1、您如违反法律法规或本协议中的约定的，我们有权要求改正或直接采取一切必要的措施。必要的措施包括但不限于删除用户发布的内容、暂停或终止用户使用上上参谋品牌加盟平台的服务，由此产生的纠纷、赔偿处罚等相应法律责任您应承担。</p>
                <p>2、除因我们存在过错外，您自行承担使用上上参谋品牌加盟服务平台产生的风险。您如因本人过错或行为而遭受人身损害、财产损害，造成第三方人身损害、财产损害或由第三人造成您人身、财产损害的，应当依法向责任主体追究法律责任，我们不承担任何责任。</p>
                <p>3、用户如违反法律法规或本协议约定，导致数位汇聚或其关联公司遭受损失（损失包括但不限于直接经济损失、行政罚款、维权成本），用户应赔偿数位汇聚或其关联公司的全部损失。</p>
                <p>4、数位汇聚未行使或延迟行使其在本协议权利并不构成对这些权利的放弃，单独或部分行使其在本协议项下的任何权利不妨碍任何其它权利的行使。数位汇聚随时有权要求用户继续履行义务并承担相应的违约责任。</p>
            </div>
            <div class="content">
                <div class="subTitle">第五条 用户的个人信息保护</div>
                <p><strong>我们重视对您的信息保护，具体详情请参阅《上上参谋隐私政策》。</strong></p>
            </div>
            <div class="content">
                <div class="subTitle">第六条  版权声明</div>
                <p>我们平台特有的标识、版面设计、编排方式等知识产权均属数位汇聚享有，未经数位汇聚许可，不得任意复制、转载或使用。</p>
            </div>
            <div class="content">
                <div class="subTitle">第七条 不可抗力以及免责</div>
                <p>1、因遇不可抗力造成的数位汇聚无法为用户提供服务的，数位汇聚不承担任何法律责任，不可抗力包括但不限于：政府行为、自然灾害、战争、罢工、电脑病毒、黑客攻击、电信管理部门因技术调整导致网络中断等。</p>
                <p>2、用户理解上上参谋无法保证网络服务任何条件下均不会中断，故上上参谋对网络服务的及时性、安全性、准确性不作担保。同时，上上参谋对服务器受病毒侵袭及其他故障侵扰免责。用户应做好数据备份，若发生使用本平台时的数据丢失的情况，上上参谋免责。</p>
            </div>
            <div class="content">
                <div class="subTitle">第八条 通知、送达</div>
                <p>1、上上参谋对于用户所有的通知均可通过【推送通知、电子邮件、短信、公告】，该等通知于发送之日视为已送达收件人。</p>
                <p>2、我们的联系信息</p>
                <p>地址：深圳市南山区粤海街道高新南环路北邮科技大厦10层</p>
                <p>电话：0755-86936460</p>
            </div>
            <div class="content">
                <div class="subTitle">第九条 法律适用、管辖与其他</div>
                <p>1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</p>
                <p>2、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向深圳市南山区人民法院提起诉讼。</p>
                <p>3、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
            </div>
        </div>
    </AutoView>
  </div>
</template>

<script>
export default {
  name: "agreement",
  data() {
    return {
      showBack: true,
    };
  },
  methods: {
      openAtt() {
        //   this.swRouter("/join/demand")
      }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.about {
  position: relative;
  min-height: 100vh;
  background-color: $c-pageBg2;
  .about-view {
    scroll-behavior: smooth;
    padding: 2.67vw;
    box-sizing: border-box;
    .title {
        display: none;
        position: fixed;
        top: 0;
        width: 100%;
        height: 25vw;
        color: #1D2233;
        font-size: 4vw;
        line-height: 36.5vw;
        font-weight: bold;
        background-color: white;
        box-shadow: 0 .5vw .5vw rgba(0,0,0,.05);
    }
    .page{
        width: 94%;
        margin: 0 auto;
        // padding: 5vw 0;
        ul {
            padding-inline-start: unset;
        }
        li{
            list-style: none;
            font-size: 3vw;
            line-height: 6vw;
            text-indent: 6vw;
        }
        .subTitle {
            padding: 2vw 0;
            color: #1D2233;
            font-size: 3.25vw;
            font-weight: bold;
        }
        .content p {
            line-height: 8vw;
            padding: 0;
            margin: 0;
            text-indent: 6vw;
            font-size: 3vw;
            color: #1D2233;
        }
        span{
            font-size: 3vw;
        }
        .date{
            font-size: 3vw;
            margin-bottom: 3vw;
        }
        .fontBold{
            font-size: 3.2vw;
            font-weight: bold;
        }
        .underLine{
            text-decoration:underline;
        }
        .block{
            display: block;
        }
        .center{
            text-align: center;
        }
    }
  }
}
</style>
