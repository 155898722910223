import UniUtils from '@/utils/uni'
import { UniRouteType } from '@/utils/uni'
//跳转到H5页面方法
const swRouter = function(url, type = "push") {
    const reg = /\#/
    if (typeof url == "object" && url != null) {
      url = dealUrl(url);
    }
  if (window.isClient) {
    this.JSBridge.callHandler("onNormalizingCall", {
      type: 6,//6 H5  5原生页面
      url: reg.test(url) ? url : `#${url}`,
    });
    if (type === 'replace') {
      this.JSBridge.callHandler("closePage");
    }
  } else if (window.isMini) {
    const t = type === 'replace' ? UniRouteType.redirectTo : UniRouteType.navigateTo
    url = `${url.includes('#') ? '' : '#'}${url}`
    UniUtils.webviewRouter(url, t);
  } else {
    if(reg.test(url)) {
        window.location.href = window.location.host + url
    } else {
        type == "push" && this.$router.push(url);
        type == "replace" && this.$router.replace(url);
    }
  }
};

// 简单处理参数传递
function dealUrl(url) {
  let params = [];
  let str = null;
  const path = url.path || url.name ||  "/";
  const query = url.query || {};
  for (let key in query) {
    let val = query[key];
    if(Array.isArray(val)) { // 处理数组
      let arr = [];
      for(let arrKey of val) {
        arr.push(`${key}=${arrKey}&`)
      }
      str = arr.join('');
    } else if(typeof val == 'object' && val != null) {
      str = `${key}=${encodeURI(JSON.stringify(val))}&`;
    } else {
      str = `${key}=${val}&`;
    }
    params.push(str);
  }
  let fullPath = params.join('');
  fullPath = path + '?' + fullPath.substring(0, fullPath.length - 1);
  return fullPath;
}

export default swRouter;
