<template>
    <div class="paskages">
        <StateBar></StateBar>
        <TitleHeader title="我的订单" bgcolor="transparent" :back="showBack">
        </TitleHeader>

        <AutoView :header="true" :footer="false" class="paskages--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="!list.length" type="elemOrder"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadData">
                        <div v-for="(item, index) in list" :key="item.id" class="order-card" :class="index == 0 ? 'first-card' : ''">
                            <div class="top">
                                <div class="time">下单时间 {{item.placeTime}}</div>
                                <div class="name"><span>{{item.type === 7 ? '套餐包' : '会员'}}</span>{{item.questionName}}</div>
                            </div>
                            <div class="bottom">
                                <div class="status">已支付</div>
                                <div>{{item.payAmount}}元</div>
                            </div>
                        </div>
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showBack: true,
            firstLoading: true,
            loading: true,
            finished: false,
            searchOpt: {
                current: 1,
                size: 10,
            },
            list: []
        };
    },
    methods: {
        loadData() {
            this.loading = true;
            this.HTTP.elePackageList({
                ...this.searchOpt,
            })
                .then((res) => {
                    console.log(res);
                    this.firstLoading = false;
                    this.loading = false;
                    this.searchOpt.current ++;
                    if (this.list.length) {
                        this.list = this.list.concat(res.data);
                    } else {
                        this.list = res.data;
                    }
                    if (res.data.length < this.searchOpt.size || this.list.length >= res.total) {
                        this.finished = true;
                    }
                })
                .catch((e) => {
                    // console.log( e );
                    this.$toast("网络连接不稳定，请稍候重试！");
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.loadData();
        this.HTTP.elemeSubmit({
            platform: sessionStorage.getItem("platform"),
            track: JSON.stringify([{
                pageId: "10190",
                timestamp: new Date().getTime()
            }])
        })
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.paskages {
    height: 100vh;
    background-color: $c-pageBg;
    .order-card {
        background-image: url("/static/element/order_bg.png");
        background-size: cover;
        width: 84vw;
        margin-top: 2.667vw;
        font-size: 2.933vw;
        padding: 4vw;
        border-radius: 1.5vw;
        .top {
            color: #8E9099;
            .name {
                font-size: 4vw;
                color: #1D2233;
                font-weight: bold;
                margin-top: 2.133vw;
                span {
                    background-color: #FCCB8D;
                    border-radius: 0.267vw 1.33vw 0.267vw 1.33vw;
                    font-size: 2.667vw;
                    color: #8D5D22;
                    line-height: 3.733vw;
                    padding: 0 2.667vw;
                    margin-right: 2vw;
                }
            }
        }
        .bottom {
            color: #FF5900;
            display: flex;
            justify-content: space-between;
            margin-top: 4vw;
            font-weight: bold;
            .status {
                color: #518CDB;
            }
        }
    }
    .first-card {
        margin-top: 0;
    }
}
</style>
