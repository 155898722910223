<template>
    <div class="percent-component">
        <div class="legend-container" v-if="legendOpt.tb=='top'" :style="{marginBottom: legendOpt.margin + 'px'}">
            <div class="legend-panel" :style="{float: legendOpt.lr}">
                <div v-for="(item,index) in legendItems" :key="index" class="legend-item" :style="{margin: index==0?'0':'0 0 0 12px'}">
                    <div class="legend-box-panel">
                        <div class="legend-box" :style="{
                            background: getColor(item),
                            border: getBorder(item)
                        }"></div>
                    </div>
                    <div class="legend-name">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="percent-container">
            <div class="percent-name" v-if="nameOpt.content" :style="{
                color: nameOpt.color,
                fontSize:nameOpt.fontSize + 'px',
                fontWeight: nameOpt.fontWeight,
                height: barOpt.realHeight + 'px'}"
            >
                {{nameOpt.content}}
            </div>
            <div class="percent-chart" v-if="itemList.length == 0">
                <div class="percent-item" :style="{
                    background: 'rgba(142, 144, 153, 0.4)',
                    borderRadius: barOpt.radius + 'px',
                    width: '100%',
                    height: barOpt.realHeight + 'px',
                }">
                    <div class="percent-value" :style="{
                        fontSize:barOpt.fontSize + 'px',
                        height: barOpt.realHeight + 'px'
                    }">{{noDataTitle}}</div>
                </div>
            </div>
            <div class="percent-chart" v-else>
                <div v-for="(item,index) in itemList" :key="index" class="percent-item" :style="{
                    background: getColor(item),
                    border: getBorder(item),
                    borderRadius: barOpt.radius + 'px',
                    width: item.widthStr,
                    height: barOpt.height + 'px',
                    zIndex: item.zIndex,
                    margin: item.margin
                }">
                    <div class="percent-value" :style="{fontSize: barOpt.fontSize + 'px',margin: item.fontMargin, height: barOpt.realHeight + 'px'}">
                        {{item.valueStr}}
                    </div>
                </div>
            </div>
        </div>
        <div class="legend-container" v-if="legendOpt.tb=='bottom'" :style="{marginTop: legendOpt.margin + 'px'}">
            <div class="legend-panel" :style="{float: legendOpt.lr}">
                <div v-for="(item,index) in legendItems" :key="index" class="legend-item" :style="{margin: index==legendItems.length-1?'0':'0 12px 0 0'}">
                    <div class="legend-box-panel">
                        <div class="legend-box" :style="{
                            background: getColor(item),
                            border: getBorder(item),
                        }"></div>
                    </div>
                    <div class="legend-name">{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// 一个专门用于使用横向条形图来展示分类之间占比的控件

import { calcListPercent, deepCopy } from "../valueUtils"
export default {
    props: {
        name: {
            type: Object
        },
        noDataTitle: {
            type: String,
            default: '没有数据'
        },
        dataObject: {
            type: Array,
            required: true
        },
        color: {
            type: Array,
            default: function() {
                return [
                    {'color': '#AC58F5', 'border': '#9746DD'},
                    {'color': '#8879F4', 'border': '#6C5ED4'},
                    {'color': '#30C8D7', 'border': '#25A9B6'},
                    {'color': '#F58958', 'border': '#E97A48'},
                    {'color': '#518CDB', 'border': '#3079DA'}
                ]
            }
        },
        bar: {
            type: Object
        },
        legend: {
            type: Object
        },
        legendList: Array
    },
    data() {
        return {
            nameDefault: {
                content: '',
                color: '#8e9099', //legend字体颜色
                fontSize: 'auto', //legend字体大小
                fontWeight: 'normal'
            },
            barDefault: {
                color: '#fff', //bar里面字体颜色
                fontSize: 'auto', //bar里面字体大小
                minWidth: 12, //最小宽度比例
                height: 14, //bar的高度
                fixed: 1,
                topToLow: true // bar的压盖规则。true时由高到低
            },
            legendDefault: {
                margin: 10,
                fontSize: 9, //legend字体大小
                color: '#8e9099', //legend字体颜色
                position: 'none' // 可选值: none, top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
            },
            itemList: [],
            legendItems: []
        }
    },
    watch: {
        dataObject: function(nv, ov) {
            this.load(nv);
        }
    },
    computed: {
        nameOpt() {
            let nameOpt = undefined;
            if (this.name == undefined) {
                nameOpt = this.nameDefault;
            }
            else {
                nameOpt = Object.assign(this.nameDefault, this.name);
            }
            return nameOpt;
        },
        barOpt() {
            let barOpt = undefined;
            if (this.bar == undefined) {
                barOpt = this.barDefault;
            }
            else {
                barOpt = Object.assign(this.barDefault, this.bar);
            }
            barOpt.hasBorder = false;
            if (this.color.length > 0) {
                let colorItem = this.color[0];
                if (colorItem.border != undefined) {
                    barOpt.hasBorder = true;
                }
            }
            barOpt.realHeight = barOpt.hasBorder ? barOpt.height + 2 : barOpt.height;
            barOpt.radius = Math.ceil(barOpt.realHeight / 2);
            barOpt.moveLeft = Math.ceil(1.73 * barOpt.radius);
            if (barOpt.fontSize == 'auto') {
                const basicHeight = 14;
                const basicFont = 9;
                barOpt.fontSize = Math.floor((barOpt.height - basicHeight) / 4) + basicFont;
            }
            // console.log('barOpt:', barOpt);
            return barOpt;
        },
        legendOpt() {
            if (this.legend == undefined) {
                return this.legendDefault;
            }
            let legendOpt = Object.assign(this.legendDefault, this.legend);
            if (legendOpt.position != 'none') {
                let legendArray = legendOpt.position.split('-');
                legendOpt.tb = legendArray[0];
                legendOpt.lr = legendArray[1];
            }
            // console.log('legendOpt:', legendOpt);
            return legendOpt;
        }
    },
    mounted() {
        this.load(this.dataObject);
    },
    methods: {
        load(data) {
            if (data == undefined) {
                return;
            }
            if (data.length == 0) {
                return;
            }
            if (this.nameOpt.fontSize == 'auto') {
                this.nameOpt.fontSize = this.barOpt.fontSize + 1;
            }
            // console.log('percent bar chart load data:', data);
            if (this.legendList && this.legendList.length) {
              this.legendItems = this.legendList
            } else {
              this.loadLegend(data);
            }
            this.itemList = this.loadData(data);
            // console.log('percent bar chart final data:', this.itemList);
        },
        loadLegend(data) {
            this.legendItems = [];
            for (let i = 0; i < data.length; ++i) {
                const item = data[i];
                this.legendItems.push({
                    name: item.name,
                    index: item.index == undefined ? i : item.index,
                    color: item.color,
                    border: item.border
                });
            }
        },
        loadData(data) {
            let newData = [];
            // data 长度一定不为0，前面判断过
            if (data.length == 0) {
                return newData;
            }
            let dataCopy = deepCopy(data);
            let firstItem = dataCopy[0];
            if (!firstItem.hasOwnProperty('percent')) {
                if (firstItem.hasOwnProperty('count')) {
                    calcListPercent(dataCopy, 'count', this.barOpt.fixed == 0);
                } else {
                    if (firstItem.hasOwnProperty('value')) {
                        let valueTotal = 0;
                        for (let i = 0; i < dataCopy.length; ++i) {
                            let valueItem = dataCopy[i].value;
                            if (typeof(valueItem) == 'string') {
                                valueItem = parseFloat(valueItem);
                            }
                            valueTotal += valueItem;
                        }
                        if (Math.abs(valueTotal - 100) < 0.5) { // 认定value就是percent
                            dataCopy.forEach((item) => {
                                let valueItem = item.value;
                                if (typeof(valueItem) == 'string') {
                                    valueItem = parseFloat(valueItem);
                                }
                                item.percent = valueItem;
                            })
                        } else { // 可认定value是count
                            calcListPercent(dataCopy, 'value', this.barOpt.fixed == 0);
                        }
                    } else { // 连value都没有
                        return newData;
                    }
                }
            }
            for (let i = 0; i < dataCopy.length; ++i) {
                const item = dataCopy[i];
                // 数据为0的不显示
                if (item.value == 0) {
                    continue;
                }
                let percent = item.percent;
                if (typeof(percent) == 'string') {
                    percent = parseFloat(percent);
                }
                let index = item.index==undefined?i:item.index;
                let zIndex = this.barOpt.topToLow?dataCopy.length-1-index:index;
                newData.push({
                    name: item.name,
                    index: index,
                    zIndex: zIndex,
                    count: item.count,
                    percent: item.percent,
                    valueStr: item.valueStr == undefined ? percent.toFixed(this.barOpt.fixed) + '%' : item.valueStr,
                    color: item.color,
                    border: item.border
                })
            }
            if (newData.length == 0) {
                return [];
            }
            newData.sort(function(a, b) {
                return a.index - b.index;
            });
            let readLeftWidth = 100;
            for (let i = 0; i < newData.length; ++i) {
                let item = newData[i];
                item.width = item.percent;
                if (item.width < this.barOpt.minWidth) {
                    readLeftWidth -= (this.barOpt.minWidth - item.width);
                }
            }
            if (readLeftWidth != 100) {
                for (let i = 0; i < newData.length; ++i) {
                    let item = newData[i];
                    if (item.width < this.barOpt.minWidth) {
                        item.width = this.barOpt.minWidth
                    }
                    else {
                        item.width = item.width * readLeftWidth / 100;
                    }
                }
            }
            for (let i = 0; i < newData.length; ++i) {
                let item = newData[i];
                // 因为text-algin为center，所以只需要偏移一半就够了
                // const fontMove = this.barOpt.hasBorder?Math.ceil(this.barOpt.moveLeft/2)+6:Math.ceil(this.barOpt.moveLeft/2);
                const fontMove = Math.ceil(this.barOpt.moveLeft*1.73/2);
                if (this.barOpt.topToLow) {
                    if (i == 0) {
                        item.widthStr = item.width + '%';
                    }
                    else {
                        item.widthStr = `calc(${item.width}% + ${this.barOpt.moveLeft}px)`;
                        item.margin = `0 0 0 -${this.barOpt.moveLeft}px`;
                        item.fontMargin = `0 0 0 ${fontMove}px`;
                    }
                }
                else {
                    if (i == newData.length - 1) {
                        item.widthStr = item.width + '%';
                    }
                    else {
                        item.widthStr = `calc(${item.width}% + ${this.barOpt.moveLeft}px)`;
                        item.margin = `0 -${this.barOpt.moveLeft}px 0 0`;
                        item.fontMargin = `0 ${fontMove}px 0 0`;
                    }
                }
            }
            return newData;
        },
        getColor(item) {
            if (item.color != undefined) {
                return item.color;
            }
            const index = item.index;
            if (index < this.color.length) {
                return this.color[index].color;
            }
            // 默认色
            return '#AC58F5';
        },
        getBorder(item) {
            if (item.border != undefined) {
                return '1px solid ' + item.border;
            }
            const index = item.index;
            if (index < this.color.length) {
                let border = this.color[index].border;
                if (border == undefined) {
                    border = 'none';
                }
                else {
                    border = '1px solid ' + border;
                }
                return border;
            }
            // 默认无border
            return 'none';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.percent-component {
    position: relative;
    width: 100%;
    height: 100%;
}
.percent-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
}
.percent-name {
    text-align: left;
    margin-right: 10px;
    white-space:nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}
.percent-chart {
    position: relative;
    width: 100%;
    display: flex;
}
.percent-item {
    display: flex;
    align-items: center;
}
.percent-value {
    text-align: center;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.legend-container {
    position: relative;
    width: 100%;
    display: inline-block;
}
.legend-panel {
    position: relative;
}
.legend-item {
    position: relative;
    display: inline-flex;
}
.legend-box-panel {
    display: flex;
    align-items: center;
}
.legend-box {
    width: vw(20);
    height: vw(12);
    border-radius: vw(6);
}
.legend-name {
    margin-left: 4px;
    color: #8e9099;
    font-size: 11px;
    text-align: left;
}
</style>
