<template>
    <div class="page">
        <StateBar bgcolor="#fff" />
        <TitleHeader title="峰会盛典" bgcolor="#fff" :back="true"></TitleHeader>
        <!-- 介绍界面 -->
        <img class="banner-img" src="/static/img/banner_210416.png">
        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
export default {
    name: 'page',
    data () {
        return {
            loading   : true
        };
    },
    created () {
        setTimeout( () => {
            this.loading = false;
        }, 500 );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.page {
    position: relative;
    min-height: 100vh;
    background-color: #9339a9;
    box-sizing: border-box;
    .banner-img {
      width: 100%;
      height: 100%;
    }
}
</style>
