<template>
  <div class="dialog-wrap" v-show="visible">
    <div class="dialog-content">
      <p class="title">提示</p>
      <p class="msg">要删除「百果园（阳光海滨店）」吗？ 删除后不可恢复</p>
      <div class="footer">
        <button class="cancel-btn" @click="cancel()">取消</button>
        <button class="delete-btn" @click="confirm()">删除</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    visible:{
      type: Boolean,
      required: false,
      require: false,
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel');
    },
    confirm () {
      this.$emit('confirm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

*{
  box-sizing: border-box;
  border: 0;
  padding: 0;
}
.dialog-wrap{
  background: rgba(0,0,0,0.1);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  .dialog-content{
    position: relative;
    width: vw(654);
    background: #fff;
    border-radius: vw(24);
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    padding: vw(50);
    .title{
      font-size: vw(34);
      font-weight: 600;
      color: #333333;
      text-align: center;
      line-height: vw(48);
    }
    .msg{
      width: 18em;
      font-size: vw(28);
      font-weight: 400;
      color: #999999;
      line-height: vw(44);
      text-align: center;
      margin: vw(28) auto 0 vw(28);
    }
    .footer{
      margin-top: vw(50);
      button{
        width: vw(265);
        height: vw(90);
        background: #F7F6F9;
        border-radius: vw(10);
      }
      .cancel-btn{
        background: #F7F6F9;
      }
      .delete-btn{
        background: #FF5900;
        color: #fff;
      }
    }
  }
}
</style>
