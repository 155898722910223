<template>
    <div class="reportshop">
        <StateBar />
        <TitleHeader title="门店信息" bgcolor="transparent" color="black" :back="true"></TitleHeader>

        <AutoView ref="view" id="view" :header="true" :footer="false" class="reportshop--view">
            <!-- 展示列表 -->
            <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__xs">
                <div class="content">
                    <ProvinceItem :bgmData="provinceInfo" @clickProvince="clickProvince" />
                    <div class="shop-wrap" v-if="list.length">
                        <div class="head-tips">
                            全国在营门店 <i class="i">{{ total }}</i> 家
                        </div>
                        <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="loadData">
                            <ShopItem v-for="(item, index) in list" :bgmData="item" :idx="index" :key="item.key" />
                        </van-list>
                    </div>
                    <LockVip v-if="isLock" tips="查看剩余品牌门店" />
                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import ProvinceItem from "./components/provinceItem.vue";
import ShopItem from "./components/shopItem.vue";
import LockVip from "./components/lockVip.vue";

export default {
    components: { ProvinceItem, ShopItem, LockVip },
    data() {
        return {
            firstLoading: true,
            loading: true,
            finished: false,
            current: 1,
            provinceInfo: [],
            list: [],
            total: 0,
            finishedText: "我是有底线的",
            isLock: false,
            isMember: false,
        };
    },
    computed: {
        brandCode() {
            return this.$route.params.brandCode;
        },
        brandName() {
            return this.$route.query.brandName;
        },
    },
    mounted() {
        this.JSBridge.callHandler("getString", "provinceInfo", (data) => {
            if (data) {
                if (typeof data === "string") {
                    data = JSON.parse(data);
                }
                this.provinceInfo = data;
            }
        });
        this.JSBridge.callHandler("getString", "isMember", (data) => {
            if (data) {
                if (typeof data === "string") {
                    data = JSON.parse(data);
                }
                this.isMember = data;
            }
        });
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            this.HTTP.allDpPage({
                brandCode: this.brandCode,
                current: this.current,
                size: 10,
            })
                .then((res) => {
                    this.firstLoading = false;
                    this.loading = false;
                    this.current++;
                    if (res) {
                      this.total = res.total;
                      if (this.list.length) {
                        this.list = this.list.concat(res.data);
                      } else {
                        this.list = res.data;
                      }
                      if (res.data.length < res.size || this.list.length >= this.total) {
                        this.finished = true; // 完成加载
                      }
                    } else {
                      this.finishedText = "";
                      this.finished = true; // 完成加载
                      this.loading = false;
                    }
                })
                .catch((e) => {
                    if (e.code == 201022) {
                        this.isLock = true;
                        this.finishedText = "";
                        this.finished = true; // 完成加载
                        this.loading = false;
                    } else {
                        this.$toast("网络不稳定，请稍候重试！");
                        this.loading = false;
                    }
                });
        },
        clickProvince(data) {
            if (this.isMember) {
                this.swRouter({
                    path: "/reportView/shopDetail",
                    query: {
                        code: data,
                        brandCode: this.brandCode,
                        brandName: this.brandName,
                    },
                });
            } else {
                this.swRouter("/h5/#/member");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.reportshop {
    height: 100vh;
    overflow: hidden;

    #{&}--view {
        scroll-behavior: smooth;
        position: relative;

        .content {
            position: relative;
            .shop-wrap {
                padding: 0 3.2vw 18vw 3.2vw;
                .head-tips {
                    height: 10.13vw;
                    line-height: 10.13vw;
                    font-size: 15px;
                    font-weight: 500;
                    color: #1d2233;
                    .i {
                        color: #347fff;
                        font-style: normal;
                    }
                }
            }
        }
    }
}
</style>
