<template>
  <div class="container" :class="{emptyWrapper: loading}">
    <StateBar bgcolor="#fff" />
    <TitleHeader title="我的铺位" bgcolor="#fff" color="black" :back="true">
      <span slot="right" class="link-txt" @click="jumpMap">地图</span>
    </TitleHeader>
    <van-skeleton :loading="loading && firstLoad" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
    <AutoView :header="true" :footer="false">
      <van-pull-refresh v-if="!firstLoad" v-model="loading" @refresh="reload" id="list-wrap">
        <template v-if="berthList && berthList.length">
          <shop-item
            v-for="(item,index) in berthList"
            @delete="showDeleteConfirm(item.shopId)"
            @click="clickHandle(item.shopId)"
            :item="item"
            :key="index"/>
          <delete-confirm
            :visible="deleteConfirmVisible"
            @cancel="cancelDelete()"
            @confirm="confirmDelete()"
          />
        </template>
        <div v-else class="empty">
          <img src="/static/img/me/surveyedShops/img-null.png" alt="">
          暂无数据
        </div>
      </van-pull-refresh>
    </AutoView>
    <!--<foot-load v-if="hasMore" :target-id="'list-wrap'" v-show="loading" @isEnd="loadMore"/>-->
    <div class="event-mask"></div>
  </div>
</template>

<script>
import ShopItem from './components/shopItem/index.vue';
import DeleteConfirm from './components/deleteConfirm/index.vue';
// import FootLoad from './components/foot-load';

export default {
  name: 'index',
  components: {
    ShopItem,
    DeleteConfirm,
    // FootLoad,
  },
  data () {
    return {
      deleteConfirmVisible: false,
      berthList: [],
      loading: false,
      firstLoad: true,
      searchOpts: {
        current: 0,
        size: 10000,
      },
      hasMore: true,
      shopId: null
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    shopCollectDelete(id) {
      this.HTTP.shopCollectDelete(id)
        .then((res) => {
          this.load()
        })
        .catch((e) => {
          this.loading = false;
          this.$toast("网络不稳定，请稍候重试。");
        })
    },
    load (isReload) {
      this.loading = true;
      this.HTTP.shopCollectMylist(this.searchOpts)
        .then((res) => {
          if (isReload) {
            this.berthList = res.data;
          } else {
            this.berthList = this.berthList.concat(res.data || []);
          }
          if (this.berthList.length === 0 || this.berthList.length < this.searchOpts.size){
            this.hasMore = false;
          } else {
            this.hasMore = true;
          }
        })
        .catch((e) => {
          this.$toast('网络不稳定，请稍候重试。');
        }).finally(() => {
        this.loading = false;
        this.firstLoad = false;
      });
    },
    reload () {
      this.searchOpts.current = 1;
      this.load(true);
    },
    loadMore () {
      if (!this.hasMore) return;
      this.searchOpts.current += 1;
      this.load();
    },
    jumpMap () {
      this.JSBridge.callHandler('onNormalizingCall', {
        type: 5,
        url: 'SHOP_COLLECT_HOME',
      });
    },
    clickHandle (id) {
      this.swRouter({
        path: `/me/surveyedShops/details/${id}`,
        query: {
          editable: true
        }
      })
    },
    showDeleteConfirm (id) {
      this.shopId = id;
      this.deleteConfirmVisible = true;
    },
    cancelDelete () {
      this.deleteConfirmVisible = false;
    },
    confirmDelete () {
      this.shopCollectDelete(this.shopId)
      this.deleteConfirmVisible = false;
    },
  }
};
</script>

<style scoped lang="scss">
//@import "@/styles/index.scss";

*{
  box-sizing: border-box;
  border: 0;
  padding: 0;
}
.container{
  background: #F7F6F9;
  height: 100vh;
  padding: 0.1px;
  .autoView {
    padding: vw(18) 0;
  }
  .van-pull-refresh{
    min-height: 100%;
  }
}
.emptyWrapper {
  background: #fff;
}

.link-txt {
  color: #347fff;
}
.empty {
  width: vw(690);
  height: vw(600);
  background-color: #fff;
  margin: vw(31);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #BDBDBD;
  font-size: $font_373;
  font-weight: bold;
  img {
    width: vw(174);
    height: vw(174);
    margin-bottom: vw(31);
  }
}
.event-mask{
  width: vw(30);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(247,246,249,0);
  opacity: 0;
}
</style>
