<template>
  <div class="emptyWrap">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>

    <AutoView ref="view" :header="true" :footer="false" class="emptyWrap--view">
      <div class="empty isCard">
        <van-image class="icon" fit="cover" src="/static/icon/empty_icon.png" />
        <h3>该内容已被发布者删除</h3>
      </div>
    </AutoView>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.emptyWrap {
  height: 100vh;
  overflow: hidden;

  #{&}--view {
    scroll-behavior: smooth;
    background: #f7f7f7;
    padding: $padding1;

    .empty {
      padding: $padding;
      padding-bottom: 35vw;
      text-align: center;

      &.isCard {
        @extend .l-panel;
      }

      .icon {
        width: $iconSize;
        height: $iconSize;
        margin-top: 4vw;
      }
      h3 {
        margin-top: 7.47vw;
        font-size: $font_373;
        line-height: $font_533;
      }
    }
  }
}
</style>
