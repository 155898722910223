<template>
    <div>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-show="!firstLoading">
            <!-- <VanImage @load="imageLoadDone" src="/static/img/store-map/tab1-1@2x.png"></VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab1-2@2x.png">
                <div class="def-button" @click="deeplink({ type: '6', url: '#/hot/selling/0', btnId: '80001' })">
                    创业热门/热卖单品/开店热门区域
                </div>
            </VanImage> -->
            <div class="content" v-if="!firstLoading">
                <div class="pic">
                    <img src="/static/img/store-map/pic@3x (1).png" alt="">
                </div>
                <div class="common hot-list">
                    <p class="title">全国什么行业最火？</p>
                    <p class="txt">
                        全国开店创业者首选咨询行业是<span>{{ pieIndustryRatioData[0] ? pieIndustryRatioData[0].name : '' }}</span
                        >，在开店用户中占比高达{{ pieIndustryRatioData[0] ? pieIndustryRatioData[0].value : '' }}%
                    </p>
                    <div class="chart-con">
                        <PieChart :seriesData="pieIndustryRatioData" />
                    </div>
                    <!-- <h4 class="bd-txt">查询周边行业分布</h4>
                    <p class="txt" style="text-align:center">
                        周边概况、业态分布、经营数据，了解这些再开店！
                    </p>
                    <div
                        class="btn"
                        @click="deeplink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '1' }, id: '83100', btnId: '83101' })"
                    >
                        免费查周边
                    </div> -->
                    <div class="line"></div>
                    <p class="title">全国餐饮行业热度</p>
                    <p class="txt">
                        餐饮行业中最受欢迎的是<span>{{ xAxisNamesEatRatio.slice(-1)[0] }}</span> ，热度占比为{{ seriesDataEatRatio[0].slice(-1)[0] }}%
                    </p>
                    <div class="chart-con" style="height: 70vw">
                        <van-skeleton :loading="!xAxisNamesEatRatio.length" animate :row="7" class="s-skeleton s-radius__s"> </van-skeleton>
                        <VerticalBar v-if="xAxisNamesEatRatio.length" :xAxisNames="xAxisNamesEatRatio" :seriesData="seriesDataEatRatio" :otherOption="{colorList: [['#FFA16E', '#FF6759']], labelColor: '#FF5900'}"/>
                    </div>
                    <div class="line"></div>
                    <p class="title">全国零售行业热度</p>
                    <p class="txt">
                        零售行业中最受欢迎的是<span>{{ xAxisNamesRetailRatio.slice(-1)[0] }}</span
                        >,热度占比为{{ seriesDataRetailRatio[0].slice(-1)[0] }}%
                    </p>
                    <div class="chart-con" style="height: 100vw">
                        <van-skeleton :loading="!xAxisNamesRetailRatio.length" animate :row="7" class="s-skeleton s-radius__s"> </van-skeleton>
                        <VerticalBar v-if="xAxisNamesRetailRatio.length" :xAxisNames="xAxisNamesRetailRatio" :seriesData="seriesDataRetailRatio" :otherOption="{colorList: [['#FFA16E', '#FF6759']], labelColor: '#FF5900'}"/>
                    </div>
                    <div class="more">
                        <span class="span_left"></span>
                        点击查看更多行业热榜资讯
                        <span></span>
                    </div>
                    <div class="btn industory_btn" @click="jumpClick">
                        创业热门/开店热搜区域
                        <img class="btn-img" src="/static/img/store-map/icon@3x.png" alt="">
                    </div>
                </div>
            </div>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab1-3@2x.png">
                <div
                    class="def-button"
                    style="bottom: 5.33vw"
                    @click="deeplink({ type: '6', url: '#/community/goodsList?tag=加盟避坑', btnId: '80002' })"
                >
                    加盟科普/避坑误区/品牌案例
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab1-4@2x.png">
                <div class="def-button" @click="deeplink({ type: '6', url: '#/brand', btnId: '80003' })">
                    小吃餐饮/饮品甜品/商超便利等
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab1-5@2x.png">
                <div class="button-group">
                    <div class="btn left" @click="deeplink({ type: '6', url: '#/community/goodsList?tag=老板生意经', btnId: '80004' })">
                        <p>点击查看</p>
                        大家在做的好生意
                    </div>
                    <div class="btn" @click="hyHotLink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '2' }, btnId: '80005' })">
                        立即查询热门行业
                    </div>
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab1-6@2x.png">
                <div class="def-button download" @click="deeplink({ type: '5', url: 'OPEN_SHOP', btnId: '80006' })">
                    点击下载更多财务报表
                </div>
            </VanImage>
        </div>
    </div>
</template>

<script>
import PieChart from '@/views/hotSearch/components/echarts/echarts-pie.vue';
import VerticalBar from '@/views/hotSearch/components/echarts/VerticalBar.vue';
// import setupMap from '@/views/hotSearch/components/echarts/setupMap';
let picNum = 0;
export default {
    name: 'ProjectExploratory',
    data () {
        return {
            firstLoading: true,
            searchOpt: {
                tabName : 0,
                cityCode: ''
            },
            currentCity : '当前城市',
            pieIndustryRatioData : [], // 全国行业
            pieAreaRatioData     : [], //
            xAxisNamesEatRatio   : [],
            seriesDataEatRatio   : [[]],
            xAxisNamesRetailRatio: [],
            seriesDataRetailRatio: [[]]
        };
    },
    components: {
        PieChart,
        VerticalBar
    },
    mounted () {
        this.firstLoading = true;
        const city = this.$store.getters.locaCity;
        if ( city && city.name ) {
            this.currentCity = city.name;
            this.searchOpt.cityCode = city.code;
            this.fetchData();
            return;
        }
        try {
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                // 处理未拿到定位信息情况
                if (!data) {
                  this.settingDefaultCity()
                  return
                }
                const storeCity = {
                    name: data.city,
                    code: data.adCode.slice( 0, 4 ) + '00'
                };
                this.currentCity = data.city;
                this.searchOpt.cityCode = data.adCode.slice( 0, 4 ) + '00';
                this.$store.commit( 'setLocaCity', storeCity );
                // 获取数据
                this.fetchData();
            } );
        } catch ( e ) {
            console.log( e );
            this.settingDefaultCity()
        }
    },
    methods: {
      settingDefaultCity() {
        const storeCity = {
          name: '深圳市',
          code: '440300'
        };
        this.currentCity = storeCity.name;
        this.searchOpt.cityCode = storeCity.code;
        this.$store.commit( 'setLocaCity', storeCity );
        // 获取数据
        this.fetchData();
      },
        imageLoadDone () {
            picNum ++;
            if ( picNum >= 5 ) {
                this.firstLoading = false;
            }
        },
        fetchData () {
            this.xAxisNamesEatRatio = [];
            this.xAxisNamesRetailRatio = [];
            this.HTTP.hotIndustry( this.searchOpt.cityCode ).then( ( res ) => {
                console.log( '创业热搜：', res );
                const arr = [];
                const arr1 = [];
                this.pieIndustryRatioData = res.data.industryRatio;
                this.pieAreaRatioData = res.data.areaRatio;
                this.xAxisNamesEatRatio = res.data.eatRatio.reverse().map( ( el ) => {
                    arr.push( el.value );
                    return el.name;
                } );
                this.seriesDataEatRatio = [arr];
                this.xAxisNamesRetailRatio = res.data.retailRatio.reverse().map( ( el ) => {
                    arr1.push( el.value );
                    return el.name;
                } );
                this.seriesDataRetailRatio = [arr1];
                this.firstLoading = false;
                console.log(this.xAxisNamesRetailRatio, this.seriesDataRetailRatio, "222222")
            } );
        },
        jumpClick() {
            this.swRouter('/hot/selling/1')
        },
        deeplink ( { type, url, params, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80000, btnId );
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                params: JSON.stringify( params )
            } );
        },
        // 行业热力跳转
        hyHotLink( { type, url, params, btnId } ) {
          console.log( type, url, params, btnId, this.$route.meta.id );
          this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80000, btnId );
          if (window._appCode && window._appCode >= 300) {
            this.JSBridge.callHandler( 'onNormalizingCall', {
              type: 5,
              url: "CHANGE_MAIN_TAB",
              id: "queryData",
              isClear: true,
            } );
          } else {
            this.JSBridge.callHandler( 'onNormalizingCall', {
              type,
              url,
              params: JSON.stringify( params )
            } );
          }
        }
    }
};
</script>

<style lang="scss" scoped>
.van-image {
    width: 100%;
    margin-bottom: -3px;
    position: relative;
    ::v-deep .def-button {
        position: absolute;
        width: 81.33vw;
        height: 12.26vw;
        line-height: 12.26vw;
        background: linear-gradient(90deg, #ff965c 0%, #ff6759 100%, #ff9d7c 100%);
        box-shadow: 0 1.33vw 5.33vw 0 rgba(255, 106, 89, 0.3);
        border-radius: 1.06vw;
        bottom: 7.6vw;
        left: 50%;
        transform: translateX(-50%);
        font-size: 4vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        &::after {
            position: absolute;
            content: '';
            width: 4vw;
            height: 4vw;
            transform: translateY(-2vw);
            top: 50%;
            margin-left: 1vw;
            background: url('/static/icon/icon_arrow_right.png') center / 4vw 4vw no-repeat;
        }
        &.download {
            bottom: 11.73vw;
            &::after {
                display: none;
            }
        }
    }
    ::v-deep .button-group {
        position: absolute;
        width: 100vw;
        display: flex;
        justify-content: center;
        bottom: 4.93vw;
        .btn {
            width: 39.2vw;
            height: 12.26vw;
            line-height: 12.26vw;
            font-size: 3.46vw;
            color: #fff;
            background: linear-gradient(90deg, #ff965c 0%, #ff6759 100%, #ff9d7c 100%);
            box-shadow: 0 1.33vw 5.33vw 0 rgba(255, 106, 89, 0.3);
            font-family: PingFangSC-Medium, PingFang SC;
            border-radius: 1.06vw;
            font-weight: 600;
            text-align: center;
            &.left {
                box-sizing: border-box;
                margin-right: 2.67vw;
                line-height: 6.26vw;
                padding-top: 1.2vw;
                p {
                    font-size: 2.93vw;
                    font-weight: normal;
                    line-height: 4vw;
                }
            }
        }
    }
}
.content {
    padding: 0 2.666vw;
    margin-top: 3.333vw;
    background-color: #FDECE4;
    .pic {
        width: 100%;
        padding-top: 5.73vw;
        background-color: #fff;
        img {
            width: 100%;
        }
    }
    .common {
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 2.666vw;
        padding: 3.333vw 0;
    }
    .title {
        height: 5.333vw;
        font-size: 3.733vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #1d2233;
        line-height: 5.333vw;
        position: relative;
        padding-left: 6.8vw;
        margin-bottom: 1.6vw;
        &::before {
            position: absolute;
            content: '';
            width: 2.4vw;
            height: 2.4vw;
            background: #ffa54f;
            border-radius: 50%;
            top: 1.2vw;
            left: 3.33vw;
        }
    }
    .bd-txt {
        font-size: 4.266vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 700;
        color: #1d2233;
        line-height: 6vw;
        text-align: center;
    }
    .btn {
        width: 77.33vw;
        height: 10.66vw;
        line-height: 10.66vw;
        text-align: center;
        background: linear-gradient(90deg, #ff5900 0%, #ff8000 100%);
        border-radius: 1.066vw;
        color: #fff;
        font-weight: bold;
        font-size: 4vw;
        margin: 2.133vw auto;
    }
    .txt {
        font-size: 3.466vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 4.93vw;
        padding-left: 3.46vw;
        > span {
            color: #FF5900;
        }
    }
    .chart-con {
        width: 100%;
        height: 54.6vw;
    }
    .line {
        height: 0.2333vw;
        background: #ffe8b7;
        margin: 4.8vw 0 4vw;
    }
    .more {
        text-align: center;
        font-size: 3.73vw;
        font-weight: 600;
        margin-bottom: 4vw;
        position: relative;
        .span_left {
            left: 20vw;
        }
        span {
            background-image: url('/static/img/store-map/triangle.png');
            background-size: cover;
            width: 2.267vw;
            height: 1.867vw;
            position: absolute;
            top: 1vw;
            right: 20vw;
        }
        // &::after {
        //     content: '';
        //     // height: 0;
        //     // // border: 1.867vw;
        //     // border-left: 0.867vw solid transparent;
        //     // border-right: 0.867vw solid transparent;
        //     // border-top: 1.267vw solid #FF9D7C;
        //     // position: absolute;
        //     // top: 1.067vw;
        //     // right: 20vw
        // }
    }
    .industory_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 81.3vw;
        background: linear-gradient(to right, #FF9D7C, #FF6759 );
    }
    .btn-img {
        width: 4vw;
        height: 4vw;
        margin-left: 2vw;
    }
}
</style>
