/**
 * 发现页界面
 * APP发现页栏目
 */

// const showUp   = () => import( /* webpackChunkName: "module-discovery" */ '@/views/discovery/showUp' );
const inviteSP = () => import( /* webpackChunkName: "module-discovery" */ '@/views/discovery/inviteSP' );
const ipRight  = () => import( /* webpackChunkName: "module-discovery" */ '@/views/discovery/ipRight' );

export default [
    // 已弃用
    // {
    //     path     : '/discovery',
    //     name     : 'discovery',
    //     component: import( /* webpackChunkName: "module-discovery" */ '@/views/discovery/index' ),
    // },
    // {
    //     // 参谋二楼
    //     path     : '/discovery/show',
    //     name     : 'showUp',
    //     component: showUp,
    // },
    {
        // 市调报告邀请分享
        path     : '/discovery/inviteSP',
        name     : 'inviteSP',
        component: inviteSP,
    }, {
        // 知识产权
        path     : '/ipRight',
        name     : 'ipRight',
        component: ipRight,
        meta     : { id: 10058 },
    }
];
