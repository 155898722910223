/**
 * 小区筛选接口
 */
import { Axios } from './http'

const MAPPING = {
  queryCondition: '/v1/community/screen/query/condition',
  queryRegionByCity: '/v1/data/public/community',
  checkBenefit: '/v1/community/screen/check/benefit',
  queryScreenPage: '/v1/community/screen/page',
  communityCollect: '/v1/collect/add',
  cancelCollect: '/v1/collect/cancel',
  collectPage: '/v1/collect/page',
  communityDetail: '/v1/community/screen/detail',
  communityBrandDetail: '/v1/community/screen/brand/detail',
  queryScreenInitialize: '/v2/community/screen/initialize'
}

const commonRequest = (config) => {
  return new Promise((resolve, reject) => {
    Axios.request(config).then(res => {
      resolve(res.data.data);
    }).catch(e => {
      reject(e);
    });
  })
}

// 小区筛选条件
const queryCondition = () => {
  return commonRequest( { url: MAPPING.queryCondition, method: 'get' } );
}

// 通过城市名称查找行政区、片区
const queryRegionByCity = (cityName) => {
  return commonRequest( { url: MAPPING.queryRegionByCity + '/' + cityName, method: 'get' } );
}

// 判断小区筛选有没有查询次数
const checkBenefit = () => {
  return commonRequest( { url: MAPPING.checkBenefit, method: 'get' } );
}

// 判断小区筛选有没有查询次数
const queryScreenPage = (data) => {
  return new Promise((resolve, reject) => {
    Axios.request({ url: MAPPING.queryScreenPage, method: 'post', data }).then(res => {
      resolve(res.data);
    }).catch(e => {
      reject(e);
    });
  })
}

const queryScreenInitialize = (data) => {
  return new Promise((resolve, reject) => {
    Axios.request({ url: MAPPING.queryScreenInitialize, method: 'post', data }).then(res => {
      resolve(res.data);
    }).catch(e => {
      reject(e);
    });
  })
}

// 新增收藏
const communityCollect = (data) => {
  return commonRequest( { url: MAPPING.communityCollect, method: 'post', data } );
}

// 取消收藏
const cancelCollect = (data) => {
  return commonRequest( { url: MAPPING.cancelCollect, method: 'post', data } );
}

// 收藏列表
const collectPage = (data) => {
  return commonRequest( { url: MAPPING.collectPage, method: 'post', data } );
}

// 小区详情
const communityDetail = (code) => {
  return commonRequest( { url: MAPPING.communityDetail, method: 'post', data: { communityCode: code } } );
}

// 小区详情
const communityBrandDetail = (code) => {
  return commonRequest( { url: MAPPING.communityBrandDetail, method: 'post', data: { communityCode: code } } );
}

export default {
  queryCondition,
  queryRegionByCity,
  checkBenefit,
  queryScreenPage,
  communityCollect,
  cancelCollect,
  collectPage,
  communityDetail,
  queryScreenInitialize,
  communityBrandDetail
}
