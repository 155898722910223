<template>
    <div class="page-container">
      <StateBar />
      <TitleHeader title="平安店家宝" bgcolor="#fff" :back="true"></TitleHeader>
      <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
      <AutoView class="page-view">
        <div v-show="!firstLoading">
          <VanImage v-for="(img, index) in imgList" :src="img" :key="img" @load="imageLoadDone(index)"></VanImage>
        </div>
      </AutoView>
      <div class="footer-view" v-show="!firstLoading">
        <div class="btn" @click="confirm" >立即投保</div>
      </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
  name: 'dianjiabao',
  data() {
    return {
      firstLoading: true,
      imgList: [
        '/static/dianjiabao/bg1.png',
        '/static/dianjiabao/bg2.png',
        '/static/dianjiabao/bg3.png',
        '/static/dianjiabao/bg4.png'
      ],
      firstImageLoad: false
    }
  },
  methods: {
    imageLoadDone(index) {
      picNum++;
      if (index===0) {
        this.firstImageLoad = true
      }
      if (picNum >= 3 && this.firstImageLoad) {
        this.firstLoading = false;
      }
    },
    confirm() {
      this.JSBridge.BTNCLICK( 10223, '', 131000, 131001);
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 6,
        url: 'https://emcs.pa18.com/product/m_general/index.html?appType=01&key=12206170000000920352#/apply',
        id: null,
        params: JSON.stringify({ title: '平安店家宝' })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.page-container {
  position: relative;
  padding-bottom: 17.067vw;
  .page-view {
    &.only-header {
      height: calc( 100vh - #{$headerHeight} - 17.067vw );
      &.android {
        height: calc( 100vh - #{$androidStateBar} - #{$headerHeight} - 17.067vw );
      }
      &.ios {
        height: calc( 100vh - #{$iOSStateBar} - #{$headerHeight} - 17.067vw );
      }
      &.ipx {
        height: calc( 100vh - #{$iPXStateBar} - #{$headerHeight} - 17.067vw );
      }
    }
  }
  .footer-view {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 3.2vw 4vw;
    box-sizing: border-box;
    .btn {
      height: 10.667vw;
      text-align: center;
      line-height: 10.667vw;
      background: #4E78FA;
      border-radius: 1.067vw;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 4vw;
    }
  }
  ::v-deep .van-image {
    width: 100%;
    margin-bottom: -4px;
    position: relative;
  }

}
</style>
