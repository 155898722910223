<template>
  <div class="brandNew">
        <div class="header_top">
            <template v-if="isClient">
                <StateBar :bgcolor="newBrandBg"></StateBar>
                <TitleHeader :title="newBrandTitle" :bgcolor="newBrandBg" :color="titleColor" :back="codeIsShow">
                    <span slot="left" @click="openBack" v-if="entity && !codeIsShow">
                        <img class="share-img" src="/static/icon/new_brand_back.png" alt="">
                    </span>
                    <span slot="right" v-if="entity">
                        <img class="collect-img" @click="openInputCommonts" src="/static/icon/new_brandEdit.png" alt=""  />
                        <img class="share-img" v-if="entity.shareCode != null" @click="share" src="/static/icon/new_brandShare.png" alt=""  />
                    </span>
                </TitleHeader>
            </template>
            <div v-else class="share_top">
                <p class="f-fw">上上参谋</p>
                <p>生意人的数据军师</p>
                <div class="app-wrap">
                    <a ref="openApp" :href="downloadLink" target="_blank" class="app">APP查看</a>
                </div>
            </div>
            <div v-if="entity" ref="content" style="height: 0px"></div>
        </div>
        <!-- 骨架屏占位图 -->
        <img v-if="scaffLoading" src="/static/skt/introduce.png" width="100%" />
        <AutoView ref="view" :header="false" :footer="true" class="brandNew--view" @scroll.native="pageScrollHandle">
            <!-- <van-skeleton :loading="brandDetailNew" title :animate="false" :row="3" avatar class="s-skeleton s-radius__s"> -->
                <div class="brandNew--content"  ref="top" v-if="entity">
                    <div class="brandNew--hot" :class="entity.baseInfo.relPoi == 0 ? '' : 'brandNew--noHot'">
                        <!-- banner -->
                        <template v-if="entity.cardInfo.proImage && entity.cardInfo.proImage.length > 0">
                            <banner-component :bannerList="entity.cardInfo.proImage"></banner-component>
                        </template>
                        <div class="empty" :class="{ androidPosition: isAndroidOne, iosPosition: isIOSOne, ipxPosition: isIPXOne }" v-else></div>

                        <div class="content-box">
                            <!-- 品牌基本信息 -->
                            <div class="top_info">
                                <brand-new-card
                                    :infoData="entity.cardInfo"
                                    :addData="entity.baseInfo"
                                    :arrowContent="entity.join"></brand-new-card>
                                <van-tabs class="tabs_new" :scrollspy="true" :ellipsis="false" sticky :offset-top="offsetTop"
                                    :class="{ androidPositionFixed: isAndroidOne, iosPositionFixed: isIOSOne, ipxPositionFixed: isIPXOne }">
                                    <van-tab title="经营概况">
                                        <!-- 公司审查概况 -->
                                        <company-info
                                            :companyList="entity.companyInfo.costList"
                                            :titleName="entity.cardInfo.brandName"
                                            :baseList="entity.baseInfo.bookUrlList"
                                            :collectList="entity.companyInfo.swCollectImage"></company-info>
                                        <!-- 门店数据概况 -->
                                        <template v-if="entity.baseInfo.distributionList && entity.baseInfo.distributionList.length > 0">
                                            <template v-if="entity.baseInfo.distributionList.length != 1 && entity.baseInfo.distributionList[0].count != 0">
                                                <china-chart :chartList="entity.baseInfo.distributionList" :currentCity="entity.baseInfo.developmentCity"></china-chart>
                                            </template>
                                        </template>
                                        <!-- 品牌经营情况 -->
                                        <!-- <brand-sales></brand-sales> -->
                                    </van-tab>
                                    <van-tab title="品牌介绍">
                                        <project-introduce
                                            :content="entity.introduce.introduce"
                                            :projectData="entity.introduce"></project-introduce>
                                    </van-tab>
                                    <van-tab title="投资方案">
                                        <plan
                                            :titlePlan = "entity.cardInfo.brandName"
                                            :tableData="entity.join"></plan>
                                    </van-tab>
                                    <van-tab title="品牌评价">
                                        <comments-tab
                                            :isCommontsClient="isClient"
                                            :commontsList="entity.commentHotRes"
                                            :brandId="entity.cardInfo.id"
                                            :codeBrand="entity.cardInfo.brandCode"
                                            :nameBrand="entity.cardInfo.brandName"></comments-tab>
                                    </van-tab>
                                    <van-tab title="相似推荐">
                                        <brand-pecommended @brandDetailCommend="brandDetailCommend" :recommendList="brandList"></brand-pecommended>
                                    </van-tab>
                                </van-tabs>
                            </div>
                        </div>
                    </div>
                    <img v-if="entity.baseInfo.relPoi == 0" src="/static/img/brand_bot_new.png" class="brandNew--bot" :class="{ ipx: isIPX }" @click="onTapNext" />
                </div>
            <!-- </van-skeleton> -->
        </AutoView>

        <!-- 底栏提交按钮 -->
        <SoltFooter v-if="entity">
            <div class="brandNew--footer">
                <van-button type="info" block class="s-radius__s btn1" :class="entity.baseInfo.relPoi == 1 ? 'joinClass':''"  :disabled="disabled" :loading="submitting" @click="onTap">免费获取开店方案</van-button>
                <van-button v-if="entity.baseInfo.relPoi == 0"  type="info" block class="s-radius__s btn2"  :disabled="disabled" :loading="submitting" @click="onNext">品牌分析</van-button>
            </div>
        </SoltFooter>
        <!-- 获取开店方案预留信息 -->
        <van-popup v-model="showConfirm" position="bottom" round>
            <div class="brandNew--popup">
                <div class="popup-head-title">免费咨询，获取开店方案</div>
                <div class="l-panel s-radius__s">
                    <template>
                        <div class="name-wrap s-select s-radius__s f-vmt van-cell">
                            <input v-model="form.liaison" placeholder="请填写你的姓名" maxlength="5" class="name-input noborder"/>
                            <div class="gender">
                                <span class="gender-tag" :class="{ active: form.gender === 1 }" @click="form.gender = 1">先生</span>
                                <span class="gender-tag" :class="{ active: form.gender === 0 }" @click="form.gender = 0" style="margin-right:0">女士</span>
                            </div>
                        </div>
                    </template>
                    <input @keyup.delete="delectPhone" v-model.number="form.mobile" maxlength="11" v-if="typeMobile == 1" type="tel" class="s-select s-radius__s f-vmt van-cell noborder"/>
                    <input type="Number" ref="mobile" v-else v-model.number="form.mobile" placeholder="请填写你的手机号码" oninput="if(value.length>11) value = value.slice(0,11)" class="s-select s-radius__s f-vmt van-cell noborder"/>

                    <div class="l-panel s-radius__s f-vmt regionPicker" :class="{ on: field.city }" @click="showCityPicker = true">
                        {{ field.city ? field.city : '请选择加盟意向地区' }}
                    </div>
                    <input v-model="form.remark" placeholder="请填写留言描述你的加盟需求" maxlength="50" class="s-select s-radius__s f-vmt van-cell noborder"/>
                    <div class="reading_text">
                        <input type="checkbox" v-model="isChecked" id="join_box">
                        <label for="join_box">我已阅读并同意</label><span @click="openAgreement">《上上参谋品牌加盟服务协议》</span>
                    </div>
                    <p class="tips">仅支持选择品牌所覆盖地区</p>
                </div>
                <SoltFooter class="btnView">
                    <van-button type="info" block class="submit_pop s-radius__s" @click="onConfimBefore">提交</van-button>
                </SoltFooter>
            </div>
        </van-popup>

        <!-- 预留信息提交提示 -->
        <van-dialog v-model="showConfirmToast" title="" show-cancel-button @confirm="toastConfirm" @cancel="toastCancel">
            <div class="brandNew--toast">
                <div class="toast-line">提交信息后，将会致电联系</div>
                <div class="toast-line">确认提交联系方式？</div>
            </div>
        </van-dialog>

        <!-- 报告提交 -->
        <van-popup v-model="showInputs" position="bottom" round>
            <div class="brandNew--popup">
                <div class="popup-head-title">请确认意向加盟地区</div>
                <div class="l-panel s-radius__s">
                    <div class="l-panel s-radius__s f-vmt regionPicker" :class="{ on: field.city }" @click="showRegionPicker = true">
                        {{ field.city || '请选择加盟意向地区' }}
                    </div>
                </div>
                <SoltFooter class="btnView">
                    <van-button type="info" :disabled="check" block class="s-radius__s" @click="onSubmit">立即分析</van-button>
                </SoltFooter>
            </div>
        </van-popup>
        <!-- 省市区选择 -->
        <OpenCityPicker type="v2open" v-model="showRegionPicker" @confirm="getCityZone"/>

        <!-- 开放城市选择 -->
        <OpenCity :code="form.brandCode" v-model="showCityPicker" @confirm="getCityZone"/>

        <!-- 全局遮罩层 -->
        <LoadingOverlay :show="loading" />

  </div>
</template>
<script>
    import { isPhoneNo } from '@/utils/index.js';
    import OpenCity from './components/openCityPicker';
    import OpenCityPicker from '../home/components/openCityPicker';
    import chinaChart from './components/newBrandDetail/chart';
    import bannerComponent from './components/newBrandDetail/banner';
    import brandNewCard from './components/newBrandDetail/brandNewCard';
    import projectIntroduce from './components/newBrandDetail/projectIntroduce';
    import brandPecommended from './components/newBrandDetail/brandPecommended';
    import companyInfo from './components/newBrandDetail/companyInfo';
    import brandSales from './components/newBrandDetail/brandSales';
    import plan from './components/newBrandDetail/plan';
    import commentsTab from './components/newBrandDetail/comments';
    import { setLocalStorage, getLocalStorage, removeLocalStorage } from "@/utils/index";
    export default {
        components: {
            OpenCity,OpenCityPicker,companyInfo,brandSales,
            chinaChart,bannerComponent,brandNewCard,projectIntroduce,brandPecommended,
            commentsTab,plan
        },
        data() {
            return {
                submitting          : false, // 提交状态
                disabled            : false, // 提交按钮禁用状态
                showConfirm         : false,
                showConfirmToast    : false,
                isChecked           : false,
                showCityPicker      : false,
                showInputs          : false,
                showRegionPicker    : false,
                isAndroidOne        : false,
                isIOSOne            : false,
                isIPXOne            : false,

                field   : {
                    city: null,
                    mobile: null
                },
                form: {
                    brandCode   : null,
                    brandName   : null,
                    gender      : 1,
                    liaison     : '',
                    mobile      : null,
                    zone        : null,
                    code        : null,
                    city        : null,
                    type        : 1,
                    remark      : null,
                    fuxiBrandCode: null
                },

                newBrandTitle   : '',      //标题名称
                newBrandBg      :     'transparent',      //背景颜色
                titleColor      : 'transparent',      //字体颜色
                defaultBack     : false,
                isClient        :   true,
                isTop           :      true,
                downloadLink    : this.HTTP.getAppStore(),
                offsetTop       : 0,

                loading         : false, // 数据加载状态
                scaffLoading    : false,
                location        : null, // 定位信息
                entity  : null,
                formBrand: {
                    current: 1,
                    notCode: null,
                    newCategoryCodeL1: null,
                    newCategoryCodeL2: null
                },
                brandList: [],
                isIPX   : false,
                codeIsShow: false,

                typeMobile: 1,
            }
        },
        computed: {
            check () {
                return ! this.form.zone;
            },
        },
        watch: {
            '$route.params.code': {
                immediate: true,
                handler () {
                    if ( window.isClient && ! this.location ) {
                        // 获取定位信息
                        if ( window.isClient ) {
                            // 添加延迟处理
                            if(getLocalStorage('currentLocation')) {
                                this.location = getLocalStorage('currentLocation');
                                this.loadData();
                                this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                                    if ( typeof data === 'string' ) data = JSON.parse( data );
                                    if ( data ) {
                                        this.location   = [ data.longitude, data.latitude ];
                                        this.form.code  = data.adCode;
                                        this.form.zone  = data.province + data.city + data.district;
                                        this.form.city  = [ data.province, data.city, data.district ].join( '-' );
                                        this.field.city = data.province + data.city + data.district;
                                        setLocalStorage('currentLocation', this.location);
                                    }
                                } );
                            } else {
                                const timer = setTimeout( () => {
                                    // this.loadData();
                                }, 10 );
                                this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                                    if ( typeof data === 'string' ) data = JSON.parse( data );
                                    if ( data ) {
                                        this.location   = [ data.longitude, data.latitude ];
                                        this.form.code  = data.adCode;
                                        this.form.zone  = data.province + data.city + data.district;
                                        this.form.city  = [ data.province, data.city, data.district ].join( '-' );
                                        this.field.city = data.province + data.city + data.district;
                                        setLocalStorage('currentLocation', this.location);
                                    }
                                    clearTimeout( timer );
                                    this.loadData();
                                } );
                            }
                        }
                    } else {
                        this.loadData();
                    }
                }
            }
        },
        beforeMount () {
            console.log(getLocalStorage('versionCode'))
            if(getLocalStorage('versionCode') >= 208) {
                this.codeIsShow = false;
            } else {
                this.codeIsShow = true;
            }
            // this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
            this.isAndroidOne = window.isClient && window.isAndroid;
            if ( window.isClient && ! window.isAndroid ) {
                this.isIOSOne = window.isIOS;
                this.isIPXOne = window.isIPX;
            }
            this.isClient = window.isClient;
        },
        mounted() {
            this.isIPX = window.isIPX;
            // this.$nextTick(() => {
            //     this.offsetTop = this.$refs.content.getBoundingClientRect().top;
            // });
            // 获取用户手机号
            this.HTTP.getUserInfo().then(res => {
                this.form.mobile = res.phone;
                this.field.mobile = res.phone;
            });
        },
        beforeDestroy () {
            // 隐藏分享按钮
            this.JSBridge.callHandler( 'switchShareBtn', false );
            this.JSBridge.callHandler( 'switchShareBtnV2', false );
        },
        methods: {
            // 删除键
            delectPhone() {
                this.form.mobile = null;
                this.typeMobile = 2;
                this.$nextTick(() => {
                    this.$refs.mobile.focus();
                })
            },
            //
            onTapNext() {
                if(this.isClient) {
                    this.swRouter( { path: '/card/D', query: { brandCode: this.form.brandCode, brandName: this.form.brandName } } );
                } else {
                    window.location.href = this.HTTP.getAppStore();
                }
            },
            // 分享
            share() {
                this.JSBridge.callHandler("callShareV2", {
                    code: this.entity.shareCode,
                    id: this.entity.cardInfo.id,
                });
            },
            loadData () {
                this.loading = true;
                this.scaffLoading = true;
                const code = this.$route.params.code;
                if ( ! this.location ) this.location = [ null, null ];
                this.HTTP.getBrand2( code, this.location[ 0 ], this.location[ 1 ] ).then( ( res ) => {
                  console.log(res)
                    this.loading = false;
                    this.scaffLoading = false;
                    this.entity = res;
                    this.form.brandCode = res.cardInfo.brandCode;
                    this.form.fuxiBrandCode = res.cardInfo.fuxiBrandCode;
                    this.form.brandName = res.cardInfo.brandName;
                    this.newBrandTitle = res.cardInfo.brandName;
                    this.formBrand.notCode = res.cardInfo.brandCode;
                    this.formBrand.newCategoryCodeL1 = res.cardInfo.newCategoryCodeL1;
                    this.formBrand.newCategoryCodeL2 = res.cardInfo.newCategoryCodeL2;
                    if(res.cardInfo.proImage && res.cardInfo.proImage.length > 0) ;
                    else {
                        this.newBrandBg = 'white';
                        this.titleColor = '#1D2233';
                    }
                    this.brandReco();
                    console.log(new Date().getTime()+"=====loadData")
                } ).catch( ( e ) => {
                    this.$toast( '该品牌暂时无法访问！' );
                    setTimeout( () => {
                        this.$router.replace( '/brand' );
                    }, 2000 );
                } );

            },
            // 点击推荐更多
            brandDetailCommend(code) {
                this.entity = null;
                this.$route.params.code = code;
                this.newBrandBg = 'transparent';
                this.titleColor = 'transparent';
                this.loadData();
                // this.scrollTop();
            },
            // scrollTop() {
            //     const comRef = this.$refs["top"];
            //     if (comRef) {
            //         comRef.scrollTo(0, 0);
            //     }
            // },
            // 获取brand推荐
            brandReco() {
                this.HTTP.brandReco(this.formBrand).then(res => {
                    this.brandList = res.data;
                    // console.log('品牌', res.data)
                })
            },
            // 前往评论
            openInputCommonts() {
                this.swRouter({
                    path: '/brand/input_commonts/' + this.entity.cardInfo.id,
                    query: {
                        name: this.form.brandName,
                        code: this.form.brandCode,
                    }
                });
            },
            // 返回上一层
            openBack() {
                this.JSBridge.callHandler("onBackPressed");
            },
            onTap () {
                // 埋点上报
                // if(this.isClient) {
                //     this.JSBridge.report( this.JSBridge.REPORT_EVENT.BRAND.TAP_SOLUTIONS );
                    this.isChecked = false;
                    this.showConfirm = true;
                // } else {
                //     window.location.href = this.HTTP.getAppStore();
                // }
            },
            onNext() {
                // 埋点上报
                if(this.isClient) {
                    this.JSBridge.report( this.JSBridge.REPORT_EVENT.BRAND.TAP_ANALYSIS );
                    this.showInputs = true;
                } else {
                    window.location.href = this.HTTP.getAppStore();
                }
            },
            // 立即分析
            onSubmit () {
                if ( this.loading ) return;
                // 埋点上报
                this.JSBridge.report( this.JSBridge.REPORT_EVENT.BRAND.TAP_IMMEDIATELY );
                this.loading = true;
                const title = `【${this.form.brandName}】加盟品牌推荐分析报告`;
                const input = [ {
                    code : 'REGION',
                    value: {
                        code: this.form.code,
                        text: this.form.city,
                    },
                }, {
                    code : 'BRAND',
                    value: {
                        code: this.form.brandCode,
                        text: this.form.brandName,
                    },
                } ];
                this.showInputs = false;
                this.HTTP.createReport( 10003, input, title, title ).then( ( res ) => {
                    console.log(res)
                    const id     = res.reportInstanceId;
                    const report = {
                        id,
                        title,
                        labels: [],
                    };
                    this.showInputs = false;
                    this.submitting = false;
                    this.loading    = false;
                    this.$store.commit( 'setReport', report );
                    // this.$toast( '正在生成，请稍候...' );
                    this.goto( res.reportInstanceId, res.waitTime, res.type );
                } ).catch( ( e ) => {
                    if ( e.code ) {
                        if ( e.code === 201004 ) {
                            this.backHome();
                            return;
                        } else {
                            this.$toast( e.msg || '网络连接不佳，请稍候重试！' );
                        }
                    } else {
                        this.$toast( '网络连接不佳，请稍候重试！' );
                    }
                    setTimeout( () => {
                        this.submitting = false;
                        this.showInputs = true;
                        this.loading    = false;
                    }, 2000 );
                } );
            },
            goto ( id, seconds, type ) {
                const ms = typeof seconds === 'number' ? seconds * 1000 : 0;
                if ( ms ) {
                    this.loading = true;
                }
                setTimeout( () => {
                    switch ( type ) {
                        case 2:
                            this.swRouter( '/pay/' + id );
                            break;
                        case 3:
                            this.swRouter( '/h5/#/my/reports?index=2' );
                            break;
                        default:
                            this.swRouter( { path: '/charts/' + id, query: { first: true } } ); // first 为首次打开
                    }
                }, ms );
            },
            validate () {
                if ( ! this.form.liaison ) {
                    this.$toast( '请输入联系人姓名' );
                    return false;
                }
                // if ( ! isPhoneNo( this.form.mobile ) ) {
                //     this.$toast( '手机号码格式不正确' );
                //     return false;
                // }
                if(!/^1[3-9]\d{9}$/.test(this.form.mobile) && this.typeMobile == 2) {
                    this.$toast({ message: "请输入有效的手机号码" });
                    return false;
                }
                if ( ! this.form.zone ) {
                    this.$toast( '请输入选择加盟地区' );
                    return false;
                }
                if ( ! this.isChecked ) {
                    this.$toast( '请先阅读上上参谋品牌加盟协议' );
                    return false;
                }
                return true;
            },
            getCityZone ( picker, code ) {
                this.field.city = picker.join( '' );
                this.form.zone  = picker.join( '' );
                this.form.city  = picker.join( '-' );
                this.form.code  = code[ 2 ] || code[ 1 ] || code[ 0 ];
            },
            // 加盟协议
            openAgreement() {
                this.swRouter("/join/agreement")
            },
            // 提交按钮
            onConfimBefore() {
                if ( ! this.validate() ) return;
                this.showConfirm = false;
                this.showConfirmToast = true;
            },
            toastConfirm() {
                this.showConfirmToast = false;
                this.onConfim();
            },
            toastCancel() {
                this.showConfirmToast = false;
            },
            onConfim() {
                this.submitting  = true;
                const name = this.form.liaison;
                this.form.liaison = name + ( this.form.gender === 1 ? '先生' : '女士' );
                this.HTTP.createBrand( this.form ).then( ( res ) => {
                    this.$toast( '提交成功，我们将尽快与您致电联系' );
                    this.submitting = false;
                    setTimeout( () => {
                        this.form.gender  = 1;
                        this.form.liaison = '';
                        this.form.zone    = null;
                        this.form.remark    = null;
                        this.field.city   = null;
                        this.typeMobile = 1;
                        this.form.mobile = this.field.mobile;
                    }, 100 );
                } ).catch( ( e ) => {
                    // this.$toast( '网络不稳定，请稍候重试！' );
                    setTimeout( () => {
                        this.showConfirm = true;
                        this.submitting  = false;
                    }, 2000 );
                } );
            },
            pageScrollHandle() {
                this.offsetTop = this.$refs.content.getBoundingClientRect().top;
                if(this.entity.cardInfo.proImage && this.entity.cardInfo.proImage.length) {
                    let tg = this.$refs.standard;
                    let sv = null;
                    if ( ! tg ) {
                        tg = this.$refs.top;
                        sv = - 100;
                    }
                    const stdTop = tg.getBoundingClientRect().top;
                    const check  = stdTop > sv;
                    if ( this.isTop !== check ) {
                        this.isTop      = check;
                        this.newBrandBg  = check ? 'transparent' : 'white';
                        this.titleColor = check ? 'transparent' : '#1D2233';
                        // this.defaultBack = check ? false : true;
                        // this.JSBridge.callHandler( 'setStatebarTheme', check ? 'white' : 'black' );
                    }
                }
            }
        },
        created() {
            console.log(new Date().getTime()+"=====created")
        }

    }
</script>
<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .brandNew {
        height: 100vh;
        overflow: hidden;
        .header_top{
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
            .collect-img{
                width: 8vw;
                height: 8vw;
                margin-right: 1.33vw;
            }
            .share-img{
                width: 8vw;
                height: 8vw;
            }
            .share_top {
                position: relative;
                height: $headerHeight;
                background: rgba(black, .8) url(/static/icon/logo@96.png) no-repeat $margin center;
                background-size: $headerHeight * 0.7;
                color: white;
                padding-left: $headerHeight * 1.1;
                p {
                    font-size: $font_320;
                    padding-top: $margin * 0.8;
                }
                .f-fw {
                    font-size: $font_400;
                }
                .app-wrap {
                    position: absolute;
                    right: $margin;
                    top: 25%;
                    height: 50%;
                    font-size: $font_320;
                    display: flex;
                    .app {
                        color: white;
                        background-color: $c-main;
                        padding: 0 $margin;
                        overflow: hidden;
                        border-radius: 10vw;
                        font-size: $font_320;
                        @include flexColumn;
                        margin-left: $margin;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        // #{&}--view {
        //     scroll-behavior: smooth;
        // }
        #{&}--content{
            box-sizing: border-box;
            position: relative;
            width: 100%;
            height: 100%;
            // overflow-y: scroll;
        }
        #{&}--hot {
            background:#F7F7F7;
            padding-bottom: 9.33vw;
            .empty {
                height: calc(#{$headerHeight} + 9.47vw);
                background: #fff;
            }
            .androidPosition{
                height: calc(#{$headerHeight} + #{$androidStateBar} + 9.47vw);
            }
            .iosPosition{
                height: calc(#{$headerHeight} + #{$iOSStateBar} + 9.47vw);
            }
            .ipxPosition{
                height: calc(#{$headerHeight} + #{$iPXStateBar} + 9.47vw);
            }

            .content-box{
                position: relative;
                top: -4vw;
                left: 0;
                right: 0;
                width: 100%;
                box-shadow: 0 0 1.6vw 0vw rgba(63, 61, 61, 0.14);
                border-radius: 2.67vw 2.67vw 0 0;

                // overflow: hidden;
                .top_info{
                    // padding-bottom: 9.33vw;
                    .tabs_new{
                        background: transparent;
                        ::v-deep .van-tabs__wrap{
                            height: auto;
                            width: 100%;
                            padding: 0 4vw;
                            box-sizing: border-box;
                            // background: linear-gradient(to top right, #fff , #fcfcfc );
                            background: #fcfcfc ;
                        }
                        ::v-deep .van-sticky--fixed{
                            z-index: 5;
                            background: #fff;
                            box-shadow: 0px -1vw 0.53vw -0.53vw #fff;
                            position: fixed;
                            top: 15.5vw;
                            left: 0;
                        }
                        ::v-deep .van-sticky--fixed .van-tabs__wrap{
                            background: #fff;
                        }
                        ::v-deep .van-tab{
                            font-size: 3.73vw;
                            color: #666666;
                            line-height: 6.4vw;
                            text-align: left;
                            flex: 1 0 auto;
                            padding: 1.33vw 0 0 0;
                            margin-right: 5.87vw;
                            height: 8.53vw;
                            margin-bottom: 1.33vw;
                            &:nth-child(5){
                                margin-right: 0;
                            }
                        }
                        ::v-deep .van-tab--active{
                            color: #333333;
                            font-weight: bold;
                            font-size: 4.53vw;
                        }
                        ::v-deep .van-tabs__line{
                            width: 9.07vw;
                            height: 0.8vw;
                            background: #347fff;
                            border-radius: 0.4vw;
                            bottom: 0px;
                        }
                        ::v-deep .van-tabs__nav{ background-color: transparent;}
                        ::v-deep .van-tabs__nav--line{ padding: 0px;}
                    }
                    .androidPositionFixed{
                        ::v-deep .van-sticky--fixed{
                            top: calc(#{$androidStateBar} + #{$headerHeight});
                        }
                    }
                    .iosPositionFixed{
                        ::v-deep .van-sticky--fixed{
                            top: calc(#{$iOSStateBar} + #{$headerHeight});
                        }
                    }
                    .ipxPositionFixed{
                        ::v-deep .van-sticky--fixed{
                            top: calc(#{$iPXStateBar} + #{$headerHeight});
                        }
                    }
                }
            }
        }
        #{&}--noHot{
            padding-bottom: 0;
        }
        #{&}--bot {
            position: fixed;
            bottom: $footerHeight;
            left: 0;
            width: 100%;
            height: 9.33vw;
            z-index: 2;
            &.ipx {
                bottom: calc( #{$footerHeight} + #{$iPXBottomBar} );
            }
        }
        #{&}--footer {
            width: 100%;
            height: $footerHeight;
            padding: 2.13vw 4.67vw;
            box-sizing: border-box;
            box-shadow: 0 -1px 0 #EBEAEE;
            ::v-deep .van-button {
                height: 100%;
                padding: 0;
                font-size: $font_400;
                line-height: normal;
            }

            .btn1 {
                float: left;
                width: calc( 50% - 1.5vw );
                background-color: white;
                border-color: #347FFF;
                color: #347FFF;
            }
            .btn2 {
                float: right;
                width: calc( 50% - 1.5vw );
                background: #347FFF;
                border-color: #347FFF;
            }
            .joinClass{
                width: 100%;
                background-color: #347FFF;
                border-color: #347FFF;
                color: #fff;
            }
        }
        #{&}--toast {
            position: relative;
            padding: 4vw;
            .toast-line {
                color: #1D2233;
                font-size: 3.47vw;
                font-weight: normal;
                line-height: 5.87vw;
                text-align: center;
            }
        }
        #{&}--popup {
            border-top-left-radius: $radius-s;
            h3 {
                padding: $padding2 $padding;
                font-size: $font_480;
                line-height: $font_667;
            }
            .tips {
                color: $c-info;
                font-size: $font_320;
                line-height: $font_440;
                margin-top: $margin;
                text-align: center;
                &.sty2 {
                    display: inline-block;
                    margin: 12vw 0 3vw;
                    color: $c-main;
                    font-size: $font_347;
                    padding-left: 5.5vw;
                    background: url( /static/icon/mark.png ) no-repeat left center;
                    background-size: contain;
                }
            }
            .popup-head-title {
                text-align: center;
                padding: 5.87vw 0 4.4vw;
                font-size:4.5vw;
                font-weight:800;
                color:rgba(29,34,51,1);
            }

            .btnView {
                position: static;
                // height: $footerHeight;
                box-sizing: border-box;
                padding: $headerMargin $padding;
                margin-top: 2vw;
                ::v-deep .soltFooter--view {
                    height: 10.67vw;
                }
                ::v-deep .van-col {
                    height: 100%;
                }
            }
            .regionPicker {
                position: relative;
                background-color: #F7F6F9;
                color: #AEB1BD;
                height: 13.3vw;
                font-size: 4.27vw;
                line-height: 13.3vw;
                box-shadow: inset 0 0 0 1px #EBEAEE;

                &.on {
                    color: $c-text;
                }

                &:after {
                    position: absolute;
                    content: '切换';
                    right: $padding1;
                    color: #347FFF;
                    font-weight: normal;
                    font-size: $font_320;
                }
            }
            ::v-deep .van-button {
                height: 100%;
                font-size: $font_400;
                line-height: normal;
            }
            ::v-deep .van-cell, .s-label {
                padding-left: $padding;
                padding-right: $padding;
            }
            ::v-deep .van-cell:not(:last-child)::after {
                left: 6.4vw;
                right: 6.4vw;
            }
            .s-label {
                margin: 4vw 0 6.4vw;
            }
            .l-panel {
                padding: 0 $padding;
            }
            .gap{
                margin-top:  4vw;
            }
            .noborder{
                font-size: $font_400;
                min-height: 7vw;
                border:  none;
                line-height: normal;
                &::-webkit-input-placeholder {
                    color: #AEB1BD;
                }
            }
            .reading_text{
                margin-top: $margin;
                font-size: 0;
                input{
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 0;
                    margin-right: 1.33vw;
                }
                label{
                    color: #333;
                    font-size: $font_320;
                    line-height: 4.4vw;
                    display: inline-block;
                    vertical-align: middle;

                }
                span{
                    font-size: $font_320;
                    line-height: 4.4vw;
                    display: inline-block;
                    vertical-align: middle;
                    color: #347FFF;
                }
            }
            .name-wrap{
                position: relative;
                padding-right: 36vw;
                .name-input{
                    padding: 0;
                    width: 100%;
                    background-color: rgb(247, 246, 249);
                }
                .gender{
                    position: absolute;
                    right: 4vw;
                    top: 3vw;
                    display: flex;
                }
                .gender-tag{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width:14vw;
                    height:7vw;
                    margin-right: 1.5vw;
                    border-radius:6px;
                    font-size:3vw;
                    border:1px solid rgba(235,234,238,1);
                    background:rgba(255,255,255,1);
                    &.active{
                        color: #347FFF;
                        border:1px solid #347FFF;;
                    }
                }
            }
            .submit_pop{
                background: #347FFF;
                border-color: #347FFF;
            }
        }
    }
</style>
