<template>
<div class="partner">
    <StateBar />
    <TitleHeader title="邀请记录" :back="true"></TitleHeader>
    <van-cell-group v-if="list.length > 0">
        <van-cell title="邀请人数" :value="total" />
    </van-cell-group>
    <AutoView :header="true" :footer="false" class="partner--view">
        <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__s ">
            <div class="tion_al">
                <EmptyCard v-if="list.length <= 0 && !isFlag" type="messages"></EmptyCard>
                <template v-else>
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        :immediate-check="false"
                        @load="loadData">
                        <div class="account">
                            <div class="card" v-for="(item, index) in list" :key="index">
                                <van-image
                                    :src="item.avatar"
                                    lazy-load
                                    round
                                    fit="cover"
                                    class="photo">
                                    <template v-slot:loading>
                                        <van-loading type="spinner" size="20" />
                                    </template>
                                </van-image>
                                <div class="content">
                                    <h5 class="name">{{item.userName}}</h5>
                                </div>
                                <span class="amount">{{ item.phone }} </span>
                            </div>
                        </div>
                    </van-list>
                        <!-- </van-list>
                    </van-pull-refresh> -->
                </template>
            </div>
        </van-skeleton>
    </AutoView>
</div>
</template>

<script>
export default {
    name : 'invitation',
    data() {
        return {
            firstLoading: true,
            loading: true,
            list: [],
            isFlag: false,
            params: {
                size: 10,
                current: 1,
            },
            total: 0,
            finished: false,
        }
    },
    beforeMount () {

    },
    methods: {
        loadData () {
            this.isFlag = true;
            this.loading = true;
            this.HTTP.invitationList( this.params ).then( ( res ) => {
                console.log(res.data.total)
                this.loading = false;
                this.firstLoading = false;
                this.isFlag = false;

                this.params.current++;
                this.total = res.data.total;
                if(this.list.length) {
                    this.list = this.list.concat(res.data.data);
                } else {
                    if(res.data.data && res.data.data.length > 0) {
                        res.data.data.forEach(item => {
                            if(item.phone) {
                                item.phone = item.phone.substring(0,3)+"****"+item.phone.substring(item.phone.length - 4,item.phone.length);
                            }
                        })
                        this.list = res.data.data;
                    }
                }
                if(res.data.data) {
                    if (res.data.data.length < res.data.size || this.list.length >= this.total) {
                        this.finished = true; // 完成加载
                    }
                }
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
    },
    created() {
        this.loadData();
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.partner{
    height: 100vh;
    overflow: hidden;
    background: #F7F7F7;
    .tion_al{
        padding: 4vw 4vw 16.53vw;
        box-sizing: border-box;
    }
    ::v-deep .van-cell{
        height: 12.53vw;
        padding: 3.6vw 9.33vw;
        box-sizing: border-box;
    }
    ::v-deep .van-cell__title{
        font-size: 3.73vw;
        font-weight: bold;
        color: #1D2233;
        line-height: 5.33vw;
    }
    ::v-deep .van-cell__value{
        font-size: 3.73vw;
        font-weight: bold;
        color: #FF5900;
        line-height: 5.33vw;
    }
    ::v-deep .van-hairline--top-bottom::after,
    ::v-deep.van-hairline-unset--top-bottom::after {
        border-width: 2px 0px 0px;
        border-color: #EDF1FB;
    }
    .account{
        position: relative;
        padding:  2.67vw 5.33vw;
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        border-radius: 1.33vw;
    }
    .card {
        @include flex;
        width: 100%;
        height: 100%;
        padding: 4vw 0;
        border-bottom: 1px solid #EDF1FB;
        .photo {
            width: 9.6vw;
            height: 9.6vw;
            border-radius: 4.8vw;
        }
        .content {
            flex: 1;
            margin: 0 2.67vw;
            .name {
                @include font( $c-text, $font_347, bold );
                line-height: $font_480;
            }
            .desc {
                @include font( $c-info, 2.94vw, normal );
                line-height: $font_400;
                margin-top: 0.67vw;
            }
        }
        &:last-child{
            border-bottom: none;
        }
        .amount{
            // @include font( $c-notice, $font_347, bold );
            font-size: 3.2vw;
            color: #8E9099;
            line-height: 4vw;
        }
    }
}
</style>
