<!--
Desc: 空状态提示卡
Props:
  type  : 基准样式类型
  isCard: 是否卡片样式
Events:  无
Methods: 无
-->
<template>
  <div class="empty" :class="{ isCard }">
    <van-image class="icon" fit="cover" :src="icon" />
    <h3>{{ description }}</h3>
    <p v-html="text"></p>
    <van-button v-if="buttonText" type="info" class="s-radius__s" @click="onTap">{{ buttonText }}</van-button>
    <!-- 品牌搜索特有 -->
    <ul class="ul-flex" v-if="recommend">
      <li @click="swRouter('/brand')">
        <img src="/static/icon/brand_recommend.png" alt="" />
        <i class="float-icon"></i>
      </li>
      <li @click="swRouter('/brand/application_for')">
        <img src="/static/icon/brand_join.png" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "empty",
  props: {
    type: {
      type: String,
      default: "default",
    },
    isCard: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mapping: {
        address: {
          icon: "/static/icon/search_addr.png",
          message: "没有找到相关地点",
          text: "请检查输入的文字是否有误",
          button: null,
          url: null,
        },
        bills: {
          icon: "/static/icon/wallets.png",
          text: "没有可用以开票的记录",
        },
        coupons: {
          icon: "/static/icon/coupons.png",
          message: "暂无优惠券",
          text: "财富往往重在积累",
        },
        default: {
          icon: null,
          message: "欢迎使用" + this.JSBridge.appName(),
          text: "开店选址，参谋一下！",
          button: "前往下载",
          url: "/download",
        },
        hot: {
          icon: "/static/icon/hot.png",
          message: "店铺订阅火爆",
          text: "名额已满，明天再来哦~",
        },
        invitations: {
          icon: "/static/icon/invitations.png",
          message: "暂无记录",
          button: "去邀请",
          url() {
            if (!window.isClient) {
              this.$toast("非客户端环境！");
              return;
            }
            this.JSBridge.callHandler(
              "callShare",
              {
                type: "invite",
                url: "/#/share/invite",
                data: {},
              },
              (st) => {
                // console.log( st );
              }
            );
          },
        },
        invitations_10002: {
          icon: "/static/icon/invitations.png",
          message: "暂无记录",
          text: "邀请好友注册" + this.JSBridge.appName() + "<br>你将获得<em>1次免费查询机会</em>",
          button: "去邀请",
          url() {
            if (!window.isClient) {
              this.$toast("非客户端环境！");
              return;
            }
            this.JSBridge.callHandler(
              "callShare",
              {
                type: "normal",
                url: "/#/share/inviteSP",
                data: {},
              },
              (st) => {
                // console.log( st );
              }
            );
          },
        },
        location: {
          icon: "/static/icon/location_no.png",
          message: "定位服务尚未开启",
          text: "开启后为你推荐周边店铺",
        },
        messages: {
          icon: "/static/icon/wallets.png",
          text: "暂无记录",
        },
        community: {
          icon: "/static/icon/wallets.png",
          text: "暂无数据",
        },
        search: {
          icon: "/static/icon/empty.png",
          message: "无任何相关结果",
          text: "请换个关键词",
          recommend: true,
        },
        searchNoRecommend: {
          icon: "/static/icon/empty.png",
          message: "无任何相关结果",
          text: "请换个关键词",
        },
        orders: {
          icon: "/static/icon/wallets.png",
          text: "没有任何购买记录",
        },
        packages: {
          icon: "/static/icon/packages.png",
          message: "暂无优惠套餐",
          text: "财富往往重在积累",
          button: "了解更多优惠",
          url() {
            if (!window.isClient) {
              this.$toast("非客户端环境！");
            } else {
              // this.JSBridge.callHandler("gotoPage", "home_package");
              this.swRouter('/h5/#/member')
            }
          },
        },
        packagesNormal: {
          icon: "/static/icon/packages.png",
          message: "暂无优惠套餐",
          text: "财富往往重在积累",
        },
        partner: {
          icon: "/static/icon/wallets.png",
          text: "暂无提现记录",
        },
        questions: {
          icon: "/static/icon/questions.png",
          message: "未提交过定制需求",
          text: "个性化定制专业报告",
          button: "去定制需求",
          url: "/custom",
        },
        reports: {
          icon: "/static/icon/reports.png",
          message: "暂无报告",
          text: "海量优质报告等你发掘",
          button: "去找报告",
          url: sessionStorage.getItem("platform") ? `/element/home/${sessionStorage.getItem("type")}` : "/",
          report() {
            // 埋点上报
            this.JSBridge.report(this.JSBridge.REPORT_EVENT.MYREPORT.TAP_FIND);
          },
        },
        elemOrder: {
          icon: "/static/icon/reports.png",
          message: "暂无购买订单",
          text: "请前往购买",
          button: "去购买",
          url: `/element/home/${sessionStorage.getItem("type")}`,
        },
        shops: {
          icon: "/static/icon/wallets.png",
          text: "暂无订阅记录",
        },
        wallets: {
          icon: "/static/icon/wallets.png",
          text: "没有任何购买记录",
        },
      },
      view: null,
    };
  },
  computed: {
    icon() {
      return this.view.icon || "/static/icon/more.png";
    },
    description() {
      return this.view.message || "";
    },
    text() {
      return this.view.text || "　";
    },
    buttonText() {
      return this.view.button;
    },
    url() {
      return this.view.url || null;
    },
    recommend() {
      return this.view.recommend;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        const view = this.mapping[val];
        if (!view) throw new Error("请传入指定的type");
        this.view = view;
      },
    },
  },
  methods: {
    onTap() {
      const url = this.url;
      if (this.view && this.view.report) {
        // 埋点上报
        this.view.report.call(this);
      }
      if (url) {
        if (typeof url === "function") {
          url.call(this);
        } else if (window.isClient && url === "/") {
          this.JSBridge.callHandler("gotoPage", "home");
        } else {
          this.$router.push(url);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.empty {
  padding: $padding;
  padding-bottom: 16vw;
  text-align: center;

  &.isCard {
    @extend .l-panel;
  }

  .icon {
    width: $iconSize;
    height: $iconSize;
    margin-top: 4vw;
  }
  h3 {
    margin-top: 7.47vw;
    font-size: $font_373;
    line-height: $font_533;
  }
  p {
    margin-top: 1vw;
    font-size: $font_293;
    line-height: $font_440;
    color: $c-info;

    ::v-deep em {
      color: $c-main;
      font-style: normal;
      font-weight: bold;
    }
  }
  .van-button--info {
    min-width: 28vw;
    padding: 0 5vw;
    margin-top: 5.6vw;
    height: 9.87vw;
    line-height: inherit;
    font-size: $font_320;
  }
  .link {
    font-size: $font_400;
    margin-top: 4.8vw;
  }
  .s-radius__s{
    background: #347fff;
    border-color: #347fff;
  }
  .ul-flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 13.87vw;
    li {
      position: relative;
      img {
        width: 36.8vw;
        height: 17.33vw;
      }
    }
    .float-icon {
      position: absolute;
      top: 2.67vw;
      right: 10.13vw;
      width: 8vw;
      height: 4.53vw;
      background: url("/static/icon/icon_recommend.png") no-repeat;
      background-size: cover;
    }
  }
}
</style>
