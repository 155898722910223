<!--
Name:
    商铺信息卡片
Props:
    item    : (必填)数据对象
    mode    : 展示模式 normal:普通(默认) card:卡片
    showSub : 是否展示订阅按钮 false:不展示(默认)
Events:
    tap   : 点击控件主体
    tapSub: 点击订阅
    tapBtn: 点击主按钮
-->
<template>
    <div class="shop" :class="[ mode, { dis: item.status === 4 } ]" @click="onTap">
        <div class="shop--view">
            <van-image class="field logo s-radius__xs"
                fit="cover"
                lazy-load
                :src="item.shopLogo">
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
            <h3 class="field title f-tof">{{ item.shopName || '{TITLE}' }}</h3>
            <div class="field rate">
                <sw-rate :score="item.avgRating"></sw-rate>
            </div>
            <p class="field type f-tof"><strong>{{ item.tag || '无' || '{TYPE}' }}</strong>&nbsp;{{ item.shoppingDistrict || '无' || '{MALL}' }}</p>
            <p class="field address f-tof">{{ item.shopAddress || '无' || '{ADDRESS}' }}</p>
        </div>

        <a v-if="showSub" href="javascript:;" class="field subbtn icon icon-subscription" :class="{ on: subscripted, dis: item.status === 4 }" @click.stop="onTapSub">{{ stateText }}</a>

        <!-- 卡片样式 -->
        <template v-if="mode === 'card'">
            <!-- TODO -->
        </template>
        <!-- 默认样式 -->
        <template v-else>
            <van-button v-if="item.status === 1 || item.status === 2" type="info" block class="f-vmt btn" :class="'st-' + item.status" @click.stop="onTapBtn">
                <template v-if="item.status === 1">
                    <i class="icon icon-time"></i>
                    <span>数据准备中，需等待1天</span>
                </template>
                <template v-else-if="item.status === 2">
                    <i class="icon icon-message"></i>
                    <span v-if="item.unReadNewsCount">{{ item.unReadNewsCount }} 条新动态</span>
                    <span v-else>暂无新动态</span>
                    <i class="icon icon-arrow-right"></i>
                </template>
            </van-button>
            <van-button v-else type="info" block class="f-vmt btn" :class="'st-' + item.status" >
              <template>
                <i class="icon icon-ban"></i>
                <span>订阅已过期</span>
              </template>
            </van-button>
        </template>
    </div>
</template>

<script>
import Rate from '@/components/widget/rate';

export default {
    name      : 'shop',
    components: {
        'sw-rate': Rate,
    },
    props: {
        mode: {
            type   : String,
            default: 'normal',
        },
        showSub: {
            type   : Boolean,
            default: false,
        },
        item: Object,
    },
    data () {
        return {
            showMore: false,
            showRule: false,
        };
    },
    methods: {
        onTap () {
            this.$emit( 'tap', this.item );
        },
        onTapBtn () {
            this.$emit( 'tapBtn', this.item );
        },
        onTapSub () {
            this.$emit( 'tapSub', this.item );
        },
    },
    computed: {
        subscripted () {
            return this.item.status === 2;
        },
        stateText () {
            const map = {
                1: '未订阅',
                2: '已订阅',
                3: '已过期',
                4: '敬请期待',
            };
            return map[ this.item.status ] || map[ 4 ];
        },
    },
    filters: {

    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$xMargin: $padding_3;
$yMargin: $padding_3;
$compHeight: 28.5vw;
$contWidth: 60vw;
$logoSize: $compHeight - $yMargin * 2;

.shop {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: $radius_xs;
    padding: $yMargin $xMargin;
    box-sizing: border-box;

    &.dis {
        opacity: .6;
    }

    #{&}--view {
        position: relative;
        overflow: hidden;
        height: $logoSize;
    }

    .field {
        position: absolute;
    }

    .logo {
        top: 0;
        left: 0;
        width: $logoSize;
        height: $logoSize;
    }

    .title {
        top: 0;
        right: 0;
        width: $contWidth;
        font-size: $font_400;
        line-height: $font_560;
    }

    .rate {
        top: 8vw;
        right: 0;
        width: $contWidth;
    }

    .type {
        bottom: 5vw;
        right: 0;
        width: $contWidth;
        font-size: $font_293;
        line-height: $font_400;
        color: $c-info;
    }

    .address {
        bottom: 0;
        right: 0;
        width: $contWidth;
        font-size: $font_293;
        line-height: $font_400;
        color: $c-info;
    }

    .subbtn {
        @include flexColumn;
        top: 10.75vw;
        right: 1vw;
        min-width: 14vw;
        height: 7vw;
        padding-left: 7vw;
        padding-right: 1vw;
        background: $c-pageBg2 url(/static/icon/mark_add.png) no-repeat 2vw center;
        background-size: 4.4vw 4.4vw;
        color: $c-main;
        font-size: $font_320;
        border-radius: 999px 0 0 999px;
        box-sizing: border-box;

        &.on {
            background-image: url(/static/icon/mark_ok.png);
            color: $c-info;
        }
        &.dis {
            background-image: none;
            color: $c-info;
            padding-left: 3vw;
        }
    }

    .btn {
        border-color: transparent;

        &.st-1 {
            background-color: $c-pageBg2;
            color: $c-notice;
        }
        &.st-2 {
            background-color: $c-pageBg2;
            color: $c-main;
        }
        &.st-3 {
            background-color: $c-pageBg2;
            color: #676C84;
        }

        ::v-deep .van-button__text {
            @include flex;
            line-height: normal;
            font-size: $font_320;

            .icon {
                width: 3.2vw;
                height: 3.2vw;
                background: url(/static/icon/arrow_right_blue.png) no-repeat;
                background-size: contain;
                margin: 0 1vw;

                &.icon-message {
                    background-image: url(/static/icon/msg.png);
                    opacity: .6;
                }
                &.icon-time {
                    background-image: url(/static/icon/clock.png);
                }
                &.icon-ban {
                    background-image: url(/static/icon/ban.png);
                }
                &.icon-arrow-right {
                    background-size: 2.8vw 2.8vw;
                }
            }
        }
    }
}
</style>
