<template>
  <div class="timer">
    距离优惠结束仅剩
    <span>{{d}}</span>天<span>{{h > 10 ? h : `0${h}`}}</span>:<span>{{m > 10 ? m : `0${m}`}}</span>:<span>{{s > 10 ? s : `0${s}`}}</span>
  </div>

</template>

<script>
export default {
  data(){
    return {
      d: 0,
      h: 0,
      m: 0,
      s: 0,
    }
  },
  mounted() {
    setInterval(this.tiemr, 1000);
  },
  methods: {
    // 函数区
    tiemr() {
      let date = new Date();
      this.d = 31 - new Date().getDate();
      this.h = 24 - date.getHours() - 1;
      this.m = 60 - date.getMinutes() - 1;
      this.s = 60 - date.getSeconds() - 1;
    }
  }
}
</script>

<style lang="scss" scoped>
.timer{
  width: 100%;
  height: vw(70);
  background: url("/static/vip/enterprise/img-bg-xsth.png") no-repeat;
  background-size: 100%;
  font-size: vw(26);
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: vw(70);
  text-align: right;
  padding-right: vw(12);
  span{
    background: #FFFAF7;
    box-shadow: 0px 0px vw(8) 0px rgba(255, 89, 0, 0.41);
    border-radius: vw(5);
    padding: vw(6) vw(9);
    font-size: vw(24);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #743F00;
    line-height: vw(33);
    margin: 0 vw(12);
  }
}
</style>
