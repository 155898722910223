<template>
    <div class="search">
        <StateBar bgcolor="white" />
        <SearchBar ref="compSearchBar" :placeholder="placeholder" :on="false" @change="change" @entry="search" @cancel="cancel"></SearchBar>

        <AutoView :footer="true" class="search--view l-view">
            <div v-if="tops && tops.length" class="l-panel search--tops">
                <h1 class="s-line__b">热门搜索</h1>
                <ul class="list">
                    <li v-for="( item, index ) in tops" :key="index" @click="tapHot( item )">
                        <i>0{{ index + 1 }}.</i>
                        <p>{{ item.showText }}</p>
                        <span v-if="item.num">{{ item.num }}</span>
                    </li>
                </ul>
            </div>

            <!-- 搜索推荐 -->
            <ul v-show="recommends" class="search--recoms">
                <li v-for="( item, index ) in recommends" :key="index" @click="tapKeyword( item )"><span v-html="highlight( item )"></span></li>
            </ul>

            <!-- 搜索结果 -->
            <div v-if="results" class="search--results">
                <SearchRes v-for="( item, index ) in results" :key="index"
                    :item="item"
                    @click.native="chose( item )" >
                </SearchRes>

                <!-- 问题定制 -->
                <!-- <div v-show="results.length" class="search--custom s-radius__s">
                    <p>没有您想找的问题？<router-link to="custom">去定制</router-link></p>
                </div> -->
                <!-- 无结果 -->
                <div v-show="results.length === 0" class="search--tips l-panel s-radius__s">
                    <van-image class="icon"
                        fit="cover"
                        :src="tipsIcon" />
                    <h3>搜不到<em>"{{ staticKw }}"</em></h3>
                    <p>定制您的问题我们将有专业人员帮您解答</p>
                    <van-button type="info" to="custom" class="s-radius__s" @click.native="reportTap">定制问题</van-button>
                </div>
            </div>
        </AutoView>

        <!-- 问题定制 -->
        <SoltFooter v-show="results && results.length" class="s-line__t">
            <div class="search--customBot"><p>没有您想找的问题？<router-link to="custom" @click.native="reportTap">去定制</router-link></p></div>
        </SoltFooter>
    </div>
</template>

<script>
import SearchBar from '@/components/header/search';
import SearchRes from '@/components/card/search';
import { debounce } from '@/utils/index';

export default {
    name      : 'search',
    components: {
        SearchBar,
        SearchRes,
    },
    props: {
        autoFocus         : Boolean,
        defaultPlaceholder: String,
        hotSearch         : Array,
    },
    data () {
        return {
            isAndroid  : false,
            isIOS      : false,
            searching  : false,
            association: false,
            tipsIcon   : '/static/icon/search_empty.png',
            placeholder: '',
            keyword    : '',
            staticKw   : '',
            ignore     : false, // 忽略一次更新事件（用以处理点选联想词）
            tops       : [], // 热门搜索
            recommends : null,
            results    : null,
            runSearch  : null,
        };
    },
    methods: {
        change ( data ) {
            this.keyword = data.keyword;
            if ( this.ignore ) {
                this.ignore = false;
                return;
            }
            if ( ! data || ! data.keyword ) {
                this.recommends = null;
                this.results    = null;
            }
            if ( ! this.runSearch ) {
                this.runSearch = debounce( () => {
                    if ( ! this.keyword || this.searching ) return;
                    if ( this.ignore ) {
                        this.ignore = false;
                        return;
                    }
                    this.HTTP.searchList( this.keyword ).then( ( res ) => {
                        this.association = false;
                        this.recommends  = res || null;
                        this.results     = null;
                        if ( ! this.recommends || this.recommends.length === 0 ) {
                            this.recommends = null;
                            // this.$toast( '无相关问题' );
                        }
                    } );
                }, 500 );
            }
            // 查询搜索推荐列表（节流）
            this.association = true;
            this.runSearch();
        },
        chose ( item ) {
            if ( item.type === 1 ) {
                // 埋点上报
                this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.TAP_RESULT );
                item.originalQuestion = this.keyword;
                // v1.1.0数据格式兼容
                if ( item.ext ) {
                    item.showQuestion = item.showText;
                    item.inputTypes   = item.ext;
                }
                this.$router.push( { name: 'additional', params: item } );
            } else if ( item.type === 2 ) {
                this.swRouter( '/article/' + item.dataId );
            } else {
                this.$toast( '暂不支持该类型！' );
            }
        },
        cancel () {
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.TAP_CANCEL );
            this.$emit( 'cancel' );
        },
        tapHot ( e ) {
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.TAP_HOT );
            this.swRouter( {
                path : '/additional/' + e.dataId,
                query: {
                    title: e.showText,
                },
            } );
        },
        tapKeyword ( item ) {
            this.$refs.compSearchBar.setKeyword( item.showQuestion );
            this.ignore = true;
            this.$nextTick( () => { this.search(); } );
        },
        search () {
            if ( this.searching ) return;
            this.staticKw = this.keyword.trim();
            if ( ! this.staticKw.length ) {
                this.results = null;
                return;
            }
            // 正在联想则忽略
            if ( this.association ) {
                this.ignore = true;
            }
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.SEARCH );
            // 搜索请求
            this.searching = true;
            this.$refs.compSearchBar.setBlur(); // 移除焦点
            this.HTTP.searchResult( this.staticKw ).then( ( res ) => {
                this.recommends = null;
                this.results    = res || [];
                this.searching  = false;
                // 埋点上报
                if ( this.results.length ) {
                    this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.RESULT );
                } else {
                    this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.NO_RESULT );
                }
            } ).catch( ( e ) => {
                // this.$toast( '网络不佳T.T请稍候重试' );
                setTimeout( () => {
                    this.recommends = null;
                    this.searching = false;
                }, 500 );
            } );
        },
        highlight ( item ) {
            let title = item.showQuestion;
            if ( typeof title === 'string' && item.keywords instanceof Array && item.keywords.length ) {
                item.keywords.forEach( ( key ) => {
                    title = title.replace( key, `<em>${key}</em>` );
                } );
            }
            return title || '---';
        },
        reportTap () {
            // 埋点上报
            if ( this.results && this.results.length ) {
                this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.TAP_CUSTOM );
            } else {
                this.JSBridge.report( this.JSBridge.REPORT_EVENT.SEARCH.TAP_CUSTOM_NO_RESULT );
            }
        },
    },
    beforeMount () {
        this.placeholder = this.defaultPlaceholder;
        if ( this.hotSearch && this.hotSearch.length ) {
            this.tops = this.hotSearch;
        }
    },
    mounted () {
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );

        this.isAndroid = window.isClient && window.isAndroid;
        if ( ! this.isAndroid ) this.isIOS = window.isClient && window.isIOS;

        if ( this.autoFocus ) {
            this.$refs.compSearchBar.setFocus();
            document.documentElement.scrollTop = 0;
            window.pageYOffset = 0;
            document.body.scrollTop = 0;
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$searchIcon: 4vw;
$iconSize: 23.2vw;

.search {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $c-pageBg;

    #{&}--view {
        opacity: 1;
    }

    #{&}--tops {
        h1 {
            padding: 0 $padding;
            font-size: $font_347;
            line-height: 12.13vw;
        }
        .list {
            list-style: none;
            padding: $margin $padding;
            li {
                position: relative;
                height: $topHeight;
                width: 100%;
                line-height: $topHeight;
                // padding: 0 $padding * 2 0 $padding * 1.25;
                padding-left: $padding * 1.25;
                box-sizing: border-box;

                i, span {
                    position: absolute;
                    top: 0;
                    height: 100%;
                }
                i {
                    left: 0;
                    width: $padding;
                    font-size: $font_320;
                    font-weight: bold;
                    color: $c-topn;
                }
                p {
                    @include singleLine;
                    font-size: $font_347;
                }
                span {
                    right: 0;
                    width: $padding * 2;
                    font-size: 2.67vw;
                    text-align: right;
                    color: $c-gray2;
                }
            }
            li:nth-child(1) i {
                color: $c-top1;
            }
            li:nth-child(2) i {
                color: $c-top2;
            }
            li:nth-child(3) i {
                color: $c-top3;
            }
        }
    }

    #{&}--recoms, #{&}--results {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $c-pageBg;
        box-sizing: border-box;
        padding: $margin $padding;
        list-style: none;
    }

    #{&}--recoms {
        li {
            @include singleLine;
            height: $topHeight;
            width: 100%;
            padding: $margin 0 $margin $padding;
            box-sizing: border-box;
            font-size: $font_347;

            background: url(/static/icon/search_black.png) no-repeat left center;
            background-size: $searchIcon;

            ::v-deep em {
                color: $c-main;
                font-style: normal;
                font-weight: bold;
                word-wrap: normal;
            }
        }
    }

    #{&}--custom {
        background-color: $c-light2;
        margin: 8vw 10.93vw;
        text-align: center;
        padding: $padding;
        font-weight: bold;
        font-size: $font_320;
        color: $c-info;

        p {
            display: inline-block;
            background: url(/static/icon/edit.png) no-repeat left center;
            background-size: $searchIcon $searchIcon;
            padding-left: $padding;
            line-height: $font_400;
        }

        a {
            color: $c-main;
        }
    }

    #{&}--customBot {
        font-weight: bold;
        font-size: $font_320;
        color: $c-info;

        p {
            display: inline-block;
            background: url(/static/icon/edit.png) no-repeat left center;
            background-size: $searchIcon $searchIcon;
            padding-left: $padding;
            line-height: $font_400;
        }

        a {
            color: $c-main;
        }
    }

    #{&}--tips {
        text-align: center;
        padding: 8.67vw 0 11.73vw;
        .icon {
            width: $iconSize;
            height: $iconSize;
        }
        h3 {
            font-size: $font_373;
            line-height: $font_533;
            padding-top: 5.73vw;
            em {
                color: $c-main;
                font-style: normal;
                font-weight: bold;
                word-wrap: normal;
                margin-left: .8vw;
            }
        }
        p {
            font-size: $font_293;
            color: $c-gray3;
            padding-top: .8vw;
        }
        .van-button--info {
            width: 26.93vw;
            margin-top: 5.6vw;
        }
    }
}
</style>
