<template>
  <div class="coupon-container">
        <StateBar ref="stateBar"></StateBar>
        <TitleHeader title="" bgcolor="transparent" color="black" :back="true">
        </TitleHeader>
        <div class="goods_title">
            <h3>{{list.title}}</h3>
            <p>{{list.clickCountFormat}} · {{list.total}}</p>
        </div>
        <!-- :class="{ androidPosition: isAndroidOne, iosPosition: isIOSOne, ipxPosition: isIPXOne }" -->
        <AutoView :header="true" :footer="false" class="coupon--view">
            <van-skeleton title :loading="goodsListLoading" :animate="false" :row="3" avatar class="s-skeleton s-radius__s">
                <div class="goods_list" >
                    <div class="list_swrap">
                        <EmptyCard v-if="list.resourceList.length <= 0 && !isFlag" type="community"></EmptyCard>
                        <template v-else>
                            <div class="list_list" v-for="(val, index) in list.resourceList" :key="index" @click="openDetai(val.id)">
                                <van-image class="item_list_img" lazy-load :src="val.image" />
                                <div class="item_list_content">
                                    <h3>{{val.title}}</h3>
                                    <div class="author_view">
                                        <span class="tab_item_list" v-if="val.keywordList">
                                            <span class="tag_item" v-for="(tag, i) in val.keywordList" :key="i">{{ tag }}</span>
                                        </span>
                                        <span v-else>
                                            <!-- {{val.author}} -->
                                        </span>
                                        <span>{{val.clickCountFormat}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                </div>
            </van-skeleton>
        </AutoView>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                isAndroidOne: false,
                isIOSOne    : false,
                isIPXOne    : false,
                goodsListLoading: true,
                list:       {
                    resourceList: [],
                },
                isFlag: false,
            }
        },
        methods: {
            openDetai(id) {
                this.swRouter( '/h5/#/community/article/'+id );
            },
            getInfo() {
                let params = { title: this.$route.query.tag };
                this.isFlag = true;
                this.HTTP.getArticleCategoryDetail(params).then(res => {
                    this.goodsListLoading = false;
                    this.isFlag = false;
                    this.list = res.data;
                    // console.log(res.data)
                }).catch((e) => {
                    this.isFlag = false;
                    this.$toast("网络连接不稳定，请稍候重试！");
                });
            },
        },
        mounted() {
            this.getInfo();
        },
        beforeMount () {
            this.isAndroidOne = window.isClient && window.isAndroid;
            if ( window.isClient && ! window.isAndroid ) {
                this.isIOSOne = window.isIOS;
                this.isIPXOne = window.isIPX;
            }
        },
    }
</script>
<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .coupon-container {
        height: 100vh;
        overflow: hidden;
        .goods_title{
            padding: 0 4vw 0.53vw;
            width: 100%;
            box-sizing: border-box;
            background: #fff;
            h3{ font-size: 5.06vw;font-weight: bold;color: #333333;line-height: 7.06vw;margin-bottom: 0.53vw; }
            p{ font-size: 3.2vw;color: #999999;line-height: 4.4vw; }
        }
        .coupon--view {
            position: relative;
            background: #F7F7F7;
            width: 100%;
            .goods_list{
                padding-bottom: 12.5vw;
                .list_swrap{
                    background: #fff;padding: 0 4vw;width: 100%;box-sizing: border-box;
                    .list_list{
                        padding: 3.33vw 0;
                        border-bottom: 1px solid #E7E7E7;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .item_list_img{
                            width: 29.6vw;height: 20vw;margin-right: 3.33vw;
                            border-radius: 1.06vw;overflow: hidden;
                            ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                                width: 100%;height: 100%;object-fit: cover;
                                // object-fit: scale-down;
                            }
                        }
                        .item_list_content{
                            flex: 1;
                            overflow: hidden;
                            h3{ height: 10.67vw;font-size: 3.74vw;font-weight: bold;color: #333333;line-height: 5.33vw;word-break: break-all;text-overflow: ellipsis; display: -webkit-box;
                            -webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden; }
                            .author_view{
                                margin-top: 4.53vw;
                                color: #666666;
                                font-size: 0;
                                vertical-align: middle;
                                >span{
                                    font-size: 3.47vw;
                                    color: #666666;
                                    line-height: 4.93vw;
                                    display: inline-block;
                                    &:first-child{
                                        width: calc(100% - 18vw);
                                        text-align: left;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        padding-right: 2.67vw;
                                        box-sizing: border-box;
                                    }
                                    &:last-child{
                                        color: #999;
                                        max-width: 18vw;
                                        width: 100%;
                                        font-size: 3.2vw;
                                        text-align: right;
                                        overflow: hidden;
                                        text-overflow:ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                            }

                            .tab_item_list {
                                display: flex;
                                width: calc(100% - 18vw);
                                margin-right: 0;
                                text-align: left;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding-right: 2.67vw;
                                box-sizing: border-box;
                                .tag_item {
                                    font-size: 2.4vw;
                                    color: #518CDB;
                                    height: 4.53vw;
                                    line-height: 4.53vw;
                                    text-align: center;
                                    border: 0.13vw solid #518CDB;
                                    border-radius: 0.67vw;
                                    padding: 0 1.6vw;
                                }
                            }
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }
            }
            // .androidPosition{
            //     .goods_title { z-index: 2;position: fixed;top: calc(15.5vw + $iOSStateBar);left: 0;width: 100%; }
            //     .list_swrap{ padding-top: 12.52vw; }
            // }
            // .iosPosition{
            //     .goods_title { z-index: 2;position: fixed;top: calc(15.5vw + $iOSStateBar);left: 0;width: 100%; }
            //     .list_swrap{ padding-top: 12.52vw; }
            // }
            // .ipxPosition{
            //     .goods_title { z-index: 2;position: fixed;top: calc(15.5vw + $iOSStateBar);left: 0;width: 100%; }
            //     .list_swrap{ padding-top: 12.52vw; }
            // }
        }
    }
</style>
