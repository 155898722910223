<template>
    <div class="wrapper">
        <div class="positionContent">
            <div class="left">
                <div class="city" @click="clickCityPicker">{{ city }}</div>
                <div class="moreIcon"></div>
            </div>
            <div class="right">
                <SearchBar 
                style="border: none; padding-left: 0" 
                placeholder="搜索商场、购物中心" 
                :value="value"
                type=""
                @change="searchHandle"/>
            </div>
        </div>
        <div class="contorl-bar">
            <div class="left">
                <div @click="screenClick" :class="leftActive ? 'active' : ''">{{search.title}}</div>
                <div class="moreIcon" :class="leftActive ? 'moreIcon_avtive' : ''" ></div>
            </div>
            <div class="right">
                <div @click="sortClick" :class="rightActive ? 'active' : ''">排序规则</div>
                <div class="moreIcon" :class="rightActive ? 'moreIcon_avtive' : ''"></div>
            </div>
        </div>
        <div class="shopList">
            <LoadMore :onLoadMore="onLoadMore" :enableLoadMore="enableLoadMore" v-if="shopList && shopList.length">
                <template>
                    <div class="item" v-for="item in shopList" :key="item.id" @click="getReport(item.code, item.name)">
                        <div class="shop-item">
                            <img class="left" :src="item.url" alt="">
                            <div class="right">
                                <div class="title">
                                    <div class="name">{{item.name}}</div>
                                    <div class="city">{{item.city}}</div>
                                </div>
                                <div>{{item.openingDate}}</div>
                                <div class="desc">
                                    <span>{{item.shopCount}}</span>
                                    <span>{{item.builtArea}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </LoadMore>
            <div class="empty" v-else>
                <img src="/static/element/empty.png" alt="">
                <div class="empty_desc">
                    <div class="title">未搜到商场？</div>
                    <div class="tip">商场数据正在持续补充中</div>
                </div>
            </div>
            
            <div class="mask" v-if="leftActive || rightActive" @click="closeMask"></div>
            <div class="display" v-if="leftActive || rightActive">
                <template v-if="leftActive">
                    <div class="item" v-for="(item, index) in search.optionList" :key="index">
                        <div class="name">{{item.column.name}}</div>
                        <div class="tips">
                            <div class="tip-item" :class="(items.value === isAreaActive || items.value === isYearActive || items.value === isShopCountActive) ? 'item-active' : ''" @click="leftSortClick(items.value )" v-for="items in item.nameValues" :key="items.value">{{items.name}}</div>
                        </div>
                    </div>
                    <div class="btn" @click="leftSumbitClick">确定</div>
                </template>
                <template v-if="rightActive">
                    <div class="item" v-for="(item, index) in order.optionList" :key="index">
                        <div class="name">{{item.column.name}}</div>
                        <div class="tips">
                            <div class="tip-item" :class="items.value === isOrderActive ? 'item-active' : ''" @click="rightSortClick(items.value)" v-for="items in item.nameValues" :key="items.value">{{items.name}}</div>
                        </div>
                    </div>
                </template>
            </div>
            <!-- <div class="height"></div> -->
        </div>
    </div>
</template>

<script>
import SearchBar from "@/components/SearchBar/index.vue"
import LoadMore from './components/loadMore.vue';
export default {
    data() {
        return {
            city: "全国",
            value: "",
            leftActive: false, 
            rightActive: false,
            isAreaActive: "",
            isYearActive: "",
            isShopCountActive: "",
            isOrderActive: "",
            timer: null,
            search: {},
            order: {},
            searchOptions: {
                current: 1,
                size: 10,
                name: '',
                cityCode: ''
            },
            shopList: [],
            enableLoadMore: true
        };
    },
    mounted() {
        this.HTTP.elemeSubmit({
            platform: sessionStorage.getItem("platform"),
            track: JSON.stringify([{
                pageId: "10187",
                timestamp: new Date().getTime(),
                refId: "10030"
            }])
        })
        if(this.$route.query.city) {
            this.city = this.$route.query.city
            this.searchOptions.cityCode = this.$route.query.cityCode
        }
        this.getListData()
        this.getShopMarketSearch()
    },
    components: {
        SearchBar,
        LoadMore
    },
    methods: {
        clickCityPicker() {
            this.swRouter("/element/selectCity")
        },
        getShopMarketSearch() {
            this.HTTP.getShopMarketSearch().then(res => {
                console.log(res)
                if(res.code == 0) {
                    this.search = res.data.search
                    this.order = res.data.order
                }
            })
        },
        getReport(code, text) {
            this.HTTP.reportSubmit({
                goodsId: this.$route.query.goodsId,
                market: {
                    code,
                    text,
                }
            }).then(res => {
                if(res.code == 0) {
                    const linkUrl = res.data.link.url.split("#")[1]
                    this.swRouter(linkUrl)
                }
            })
        },
        getListData() {
            let searchOpt = {}
            let orderOpt = {}
            if(this.isAreaActive) {
                searchOpt.area = this.isAreaActive
            }
            if(this.isYearActive) {
                searchOpt.year = this.isYearActive
            }
            if(this.isShopCountActive) {
                searchOpt.shopCount = this.isShopCountActive
            }
            if(this.isOrderActive) {
                orderOpt = {
                    order: this.isOrderActive
                }
            }
            this.HTTP.getShopMarketList({
                ...this.searchOptions,
                condition: JSON.stringify(searchOpt), 
                sort: JSON.stringify(orderOpt)
            }).then(res => {
                console.log(res)
                if(res.code == 0) {
                    if(res.data.data.length < this.searchOptions.size) {
                        this.enableLoadMore = false
                    }
                    if(this.shopList.length === 0) {
                        this.shopList = res.data.data 
                    } else {
                        this.shopList = this.shopList.concat(res.data.data)
                    }
                }
            })
        },
        onLoadMore(done) {
            setTimeout(()=>{
                if(!this.enableLoadMore) {
                    return
                }
                this.searchOptions.current = this.searchOptions.current + 1
                this.getListData();
                done();
            }, 200)
        },
        searchHandle(e) {
            if(this.timeout){
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
                this.searchOptions.name = e
                this.initListData()
            }, 500);
        },
        closeMask() {
            this.leftActive = false
            this.rightActive = false
        },
        leftSortClick(value) {
            if(value < 6) {
                this.isAreaActive = value
            } else if(value >= 6 && value < 13) {
                this.isYearActive = value
            } else {
                this.isShopCountActive = value
            }
        },
        initListData() {
            this.searchOptions.current = 1
            this.shopList = []
            this.getListData()
        },
        leftSumbitClick() {
            this.leftActive = false
            this.initListData()
        },
        rightSortClick(value) {
            this.isOrderActive = value
            this.rightActive = false
            this.initListData()
        },
        screenClick() {
            this.leftActive = !this.leftActive
            this.rightActive = false
            this.showMask = true
        },
        sortClick() {
            this.rightActive = !this.rightActive
            this.leftActive = false
            this.showMask = true
        }
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    // padding: 4vw;
    overflow-y: hidden;
    // -webkit-overflow-scrolling: touch;
}
.positionContent {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 7vw;
    background: #f7f6f9;
    border-radius: 1.333vw;
    border: 0.133vw solid #ebeaee;
    padding: 2vw;
    // margin: 4vw;
    margin: 4vw;
    margin-bottom: 0;
    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5.467vw;
        padding-right: 2.8vw;
        border-right: 0.267vw solid #dadada;
        .icon {
            width: 4.533vw;
            height: 4.533vw;
            background-image: url("/static/icon/location_icon.png");
            background-size: 100% 100%;
            margin-right: 1.067vw;
        }
        .city {
            font-size: 3.467vw;
            color: #666666;
            margin-right: 2.133vw;
        }
    }
    .right {
        flex: 1;
        font-size: 3.467vw;
        font-weight: bold;
        color: #333333;
        padding-left: 2.8vw;
        #search-input {
            border: none;
            background: #f7f6f9;
        }
    }
}
.contorl-bar {
    display: flex;
    align-items: center;
    height: 10vw;
        .left, .right {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3.733vw;
            width: 50%;
            height: 5vw;
            div {
            margin-right: 1vw;
        }
    }
}
.shopList {
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 24vw);
    background-color: #f7f7f7;
    .shop-item {
        width: 85vw;
        background-color: #ffffff;
        padding: 3.5vw;
        margin: 0 4vw 4vw 4vw; 
        display: flex;
        box-shadow: 0 0 2.267vw 0 rgba(0,0,0,0.09);
        border-radius: 1.33vw;
        &:nth-child(1) {
            margin-top: 4vw;
        }
        .left {
            width: 20vw;
            height: 20vw;
            margin-right: 3vw;
        }
        .right {
            min-width: 63vw;
            font-size: 3.467vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
                display: flex;
                justify-content: space-between;
                .name {
                    font-size: 4vw;
                    font-weight: bold;
                }
            }
        }
    }
    .empty {
        width: 92vw;
        height: 85vw;
        background: #ffffff;
        border-radius: 1.33vw;
        display: flex;
        margin: 4vw;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            width: 23.2vw;
            height: 23.2vw;
        }
        .empty_desc {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 4.5vw;
            font-size: 3.2vw;
            line-height: 4.5vw;
            color: #999999;
            .title {
                color: #1D2233;
                font-size: 3.733vw;
                font-weight: bold;
                line-height: 5.33vw;
            }
        }
    }
    .display {
        position: absolute;
        top: 0;
        left: 0;
        width: 92vw;
        font-size: 3.733vw;
        background-color: #fff;
        // position: absolute;
        padding: 4vw; 
        z-index: 10;
        .item {
            margin-top: 2vw;
            .name {
                font-weight: 600;
            }
            .tips {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .tip-item {
                    min-width: 28.66vw;
                    flex: 1;
                    height: 7vw;
                    margin-top: 2vw;
                    border-radius: 0.8vw;
                    background-color: #eee;
                    text-align: center;
                    line-height: 7vw;
                    margin-right: 2vw;
                }
                .item-active {
                    background-color:skyblue;
                    color: #518CDB;
                }
            }
        }
        .btn {
            width: 92vw;
            height: 8vw;
            border-radius: 1vw;
            background-color: #518CDB;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10vw;
        }
    }
}

.mask {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
}
.moreIcon {
    width: 2.267vw;
    height: 1.733vw;
    background-image: url("/static/icon/choice_icon.png");
    background-size: 100% 100%;
}
.moreIcon_avtive {
    background-image: url("/static/icon/choice_icon_active.png");
}
.active {
    color: #518CDB;
}
</style>