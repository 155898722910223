import Main from '@/views/channel/recovery/main/index.vue';
import Branch from '@/views/channel/recovery/branch/index.vue';
import App from '@/views/channel/recovery/app/index.vue';
import Sem_pc from '@/views/sem/sem_pc.vue'
import Sem_H5 from '@/views/sem/sem_h5.vue'
import Sem_Succ from '@/views/sem/sem_success.vue'
import shareWX from '@/views/channel/shareWX.vue'
import Sem_APP from '@/views/sem/sem_app.vue'

export default [
  {
    path: '/channel/wdfs',
    name: 'Main',
    component: Main,
    meta:{
      title:'万店复苏计划',
    }
  },
  {
    path: '/channel/wdfs/branch',
    name: 'Branch',
    component: Branch,
    meta:{
      title:'上上参谋分会场',
    }
  },
  {
    path: '/channel/wdfs/app',
    name: 'App',
    component: App,
    meta: { id: 10221 }
  },
  {
    path: '/sem_pc',
    name: 'sem_pc',
    component: Sem_pc
  },
  {
    path: '/sem_h5',
    name: 'sem_h5',
    component: Sem_H5,
    meta: { id: 10631 }
  },
  {
    path: '/sem_success',
    name: 'sem_success',
    component: Sem_Succ
  },
  {
    path: '/sem_app',
    name: 'sem_app',
    component: Sem_APP
  },
  {
    path: '/shareWX',
    name: 'shareWX',
    component: shareWX
  },

];
