<template>
    <div class="question l-panel">
        <div class="question--content">
            <p class="time">{{ item.gmtCreate | convDate }}</p>
            <div class="view">
                <h3>问题描述：</h3>
                <p class="content">{{ item.content || '---' }}</p>
            </div>
            <div class="question--reply">
                <a href="javascript:;" class="btn" :class="{ on: isReply, open }" @click="loadData">{{ statusStr }}</a>
                <div v-show="open" class="view">
                    <van-loading v-if="!list" color="#518CDB" style="text-align: center" />
                    <template v-else>
                        <div v-for="( msg, index ) of list" :key="index">
                            <p class="time" :class="{ mt: index }">{{ msg.replyTime | convDate }}</p>
                            <p class="content">{{ msg.replyText }}<a v-show="msg.showTemplateCnfId" @click="saveTitle( msg.showTemplateCnfId )" class="link">{{ msg.showQuestion || '---'  }}</a></p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'question',
    props: {
        item: Object, // 报告对象
    },
    data: function () {
        return {
            loading: false,
            open   : false,
            list   : null,
        };
    },
    filters: {
        convDate ( value ) {
            const sp1 = value.split( ' ' );
            return sp1[ 0 ].split( '-' ).join( '.' ) + ' ' + sp1[ 1 ].substr( 0, 5 );
        },
    },
    computed: {
        statusStr () {
            return this.isReply ? '已回复，点击查看' : '未回复';
        },
        isReply () {
            return this.item.status === 2;
        },
    },
    methods: {
        loadData () {
            if ( ! this.isReply ) return;
            this.open = ! this.open;
            if ( this.list !== null || ! this.open ) return;
            this.loading = true;
            this.HTTP.getReply( this.item.id ).then( ( res ) => {
                this.loading = false;
                this.list    = res;
            } );
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.MYQUESTION.TAP_REPLY );
        },
        saveTitle ( id ) {
            let title = null;
            if ( event.target.textContent && typeof event.target.textContent === 'string' ) {
                title = event.target.textContent.trim();
            }
            this.$router.push( {
                path : '/additional/' + id,
                query: {
                    title,
                },
            } );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$iconSize: 3.47vw;

.question {
    position: relative;
    padding: $padding1;
    margin-bottom: $margin;

    #{&}--content {
        .time {
            font-size: $font_293;
            line-height: $font_400;
            color: $c-info;
        }
        .view {
            margin-top: 2.13vw;
            padding: $margin $padding2;
            background-color: $c-pageBg;
            border-radius: 0.67vw 2.67vw 2.67vw 2.67vw;
            font-weight: bold;

            h3 {
                color: $c-info;
                font-size: $font_320;
                line-height: $font_440;
                margin: 0 0 1.87vw 0;
            }
            .content {
                font-size: $font_400;
                line-height: $font_560;
            }
        }
    }
    #{&}--reply {
        text-align: left;
        .btn {
            display: inline-block;
            margin-top: $padding2;
            font-size: $font_320;
            font-weight: bold;
            line-height: $font_440;
            text-align: right;
            color: $c-info;
            padding-left: $padding1;
            background: url(/static/icon/waitting.png) no-repeat left center;
            background-size: $iconSize;

            &.on {
                color: $c-main;
                padding-right: $padding1;
                background: url(/static/icon/arrow_down_blue.png) no-repeat right center, url(/static/icon/msg.png) no-repeat left center;
                background-size: $iconSize;
            }
            &.open {
                background: url(/static/icon/arrow_up_blue.png) no-repeat right center, url(/static/icon/msg.png) no-repeat left center;
                background-size: $iconSize;
            }
        }
        .view {
            text-align: left;
            font-weight: normal;
            margin-top: 4vw;
            // border-radius: 2.67vw 0.67vw 2.67vw 2.67vw;
            border-radius: 2.67vw;
            .mt {
                margin-top: 4vw;
            }
            .content {
                margin-top: 2.13vw;
                font-size: $font_320;
                line-height: $font_440;
            }
            .link {
                text-decoration: underline;
            }
        }
    }
}
</style>
