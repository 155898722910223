<template>
  <div class="ranking">
    <van-divider v-if="showLine" />
    <ul class="ranking-flex" @click="swRouter('/brand/details/' + item.brandCode)">
      <li class="num" :class="{ oneColor: idx == 0, twoColor: idx == 1, threeColor: idx == 2 }">{{ idx + 1 }}</li>
      <li class="img">
        <img :src="item.logoUrl" alt="" />
      </li>
      <li class="info">
        <p class="title">{{ item.brandName }}</p>
        <p class="budget">
          预估总投资额：<i>{{ item.joinTotalCost || "暂未披露" }}</i>
        </p>
      </li>
      <li class="icon">
        <van-tag color="#FFF1E9" text-color="#FF5900">{{ item.categoryTagsL2Name }}</van-tag>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    showLine: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: 1,
    },
    item: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking {
  .ranking-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.67vw 0;
    .num {
      flex: 0 0 4.8vw;
      font-size: 4.27vw;
      color: #bdbdbd;
      font-weight: bold;
    }
    .oneColor {
      color: #ff3627;
    }
    .twoColor {
      color: #ff8702;
    }
    .threeColor {
      color: #ffbb26;
    }
    .img {
      flex: 0 0 15.47vw;
      img {
        width: 13.33vw;
        height: 13.33vw;
        border-radius: 0.8vw;
        object-fit: cover;
      }
    }
    .info {
      flex: 1;
      .title {
        line-height: 5.33vw;
        font-size: 4vw;
        color: #1d2233;
        font-weight: bold;
      }
      .budget {
        font-size: 3.47vw;
        color: #999;
        margin-top: 2.13vw;
        i {
          font-style: normal;
          color: #666;
        }
      }
    }
    .icon {
      flex: 0 0 20vw;
      text-align: center;
    }
  }
}
</style>
