/**
 * 落地页路由组
 * 特定功能或入口的独立界面
 * 一般为单页
 */

import assessIndex from "@/views/shops/assess";
import assessDetails from "@/views/shops/assessDetails";
import assessHistory from "@/views/shops/assessHistory";
import assessPoster from "@/views/shops/assessPoster";
import assessSummary from "@/views/shops/assessSummary";

import introduce from "@/views/home/introduce";
import openApp from "@/views/home/openApp";

const brandBlank = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/blank");
// const brandIndex     = () => import( /* webpackChunkName: "module-shop" */ '@/views/shops/brand' );
const brandDiscovery = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandDiscovery");
const brandRecommend = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandRecommend");
const brandTop = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandRecord");
const brandDetails = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandDetails");
const brandEnter = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandEnter");
const brandEnterInfo = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandEnterInfo");
const brandCustom = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandCustom");
const brandContrast = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandContrast");
const brandMain = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandMain");
const brandSearch = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/brandSearch");
const brandHome = () => import(/* webpackChunkName: "module-brand" */ "@/views/shops/brandHome")
const newBrandSearch = () => import(/* webpackChunkName: "module-brand" */ "@/views/shops/brandSearch2")
const brandSearchResult = () => import(/* webpackChunkName: "module-brand" */ "@/views/shops/brandSearchResult")
// const selectCityPage = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/select-city-page")

const mallSearch = () => import(/* webpackChunkName: "module-brand" */ "@/views/mall/mall-search")

const allCommonts = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/allCommonts");
const myCommonts = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/myCommonts");
const inputCommonts = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/commontsInput");
const commontsSuccess = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/commontsSuccess");
const detailCommonts = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/commontsDetail");
const investmentDetails = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/investmentDetails");
const newBrandReview = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/newBrandReview");
const applicationFor = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/commonts/brandApplicationFor");

const shopList = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/list");
const temporary = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/temporary");
const brandFeedback = () => import(/* webpackChunkName: "module-shop" */ "@/views/shops/feedback");
const joinDemand = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/join");
const joinAgreement = () => import(/* webpackChunkName: "channel-index" */ "@/views/shops/agreement");

const partner = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/index");
const partnerApply = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/apply");
const partnerCashout = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/cashout");
const partnerHistory = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/history");
const partnerInvite = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/invite");
const partnerJoin = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/join");
const partnerEnter = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/enter");
const partnerQR = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/qrcode");
const commissionRecord = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/commissionRecord");
const invitation = () => import(/* webpackChunkName: "module-partner" */ "@/views/partner/invitation");

const feedback = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/feedback");
const signin = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/signin");
const celebrate = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/celebrate618");
const coolSummer = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/coolSummer");
const customization = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/customization");
const download = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/download");
const downloadActive = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/downloadActive");
const guideDown = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/guideDown");
const nationalDay = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/nationalDay");
const tubatuPromo = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/tubatuPromo");
const smsJumpPage = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/smsjumpPage");
const huaxiaLoan = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/huaxiaLoan");
const huaxiaLoanDetail = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/huaxiaLoanDetail");
const huaxiaLoanSuccess = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/huaxiaLoanSuccess");
const zhihuInformation = () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/zhihuInformation");

// 营销活动
const doubleEleven = () => import(/* webpackChunkName: "double-eleven" */ "@/views/marketingCampaign/doubleEleven");
const newPeople = () => import(/* webpackChunkName: new-people" */ "@/views/marketingCampaign/newPeople");
const yearActivities = () => import(/* webpackChunkName: new-people" */ "@/views/marketingCampaign/yearActivities");

// 新人活动
const newUserActivity = () => import(/* webpackChunkName: marketingCampaign" */ "@/views/marketingCampaign/newUserActivity")
const operationPlan = () => import(/* webpackChunkName: operation-plan" */ "@/views/marketingCampaign/operationPlan")
const operationDetail = () => import(/* webpackChunkName: operation-plan" */ "@/views/marketingCampaign/operationDetail")

// 首单特惠
const firstOrderActivity = () => import(/* webpackChunkName: marketingCampaign" */ "@/views/marketingCampaign/first-order-discount-activity")

// 地图标注
const mapMarkLocation = () => import(/* webpackChunkName: mapMark" */ "@/views/mapMark/mapMarkLocation")
const mapMarkFilling = () => import(/* webpackChunkName: mapMark" */ "@/views/mapMark/mapMarkFilling")
const mapMarkRecord = () => import(/* webpackChunkName: mapMark" */ "@/views/mapMark/mapMarkRecord")
const mapMarkDetail = () => import(/* webpackChunkName: mapMark" */ "@/views/mapMark/mapMarkDetail")

// 平安运营活动
const djb = () => import(/* webpackChunkName: activity-page" */ "@/views/marketingCampaign/dianjiabao")
const zxb = () => import(/* webpackChunkName: activity-page" */ "@/views/marketingCampaign/zhuangxiubao")

// 周年庆活动
const anniversaryActivity = () => import(/* webpackChunkName: anniversaryActivity" */ "@/views/marketingCampaign/anniversary-activity")

export default [
  {
    path: "/anniversaryActivity",
    name: "anniversaryActivity",
    component: anniversaryActivity,
    meta: { id: 10302 }
  },
  {
    path: "/zxb",
    name: "zxb",
    component: zxb,
    meta: { id: 10224 }
  },
  {
    path: "/djb",
    name: "djb",
    component: djb,
    meta: { id: 10223 }
  },
    {
        // 文章查看界面 参数 文章id
        path: "/article/:id",
        name: "article",
        component: () => import(/* webpackChunkName: "single-article" */ "@/views/home/article"),
        meta: { id: 10034 }
    },
    {
        // 课程查看界面 参数 课程id
        path: "/courseInfo/:id",
        name: "courseInfo",
        component: () => import(/* webpackChunkName: "single-article" */ "@/views/home/courseInfo"),
        meta: { id: 10034 }
    },
    {
        // 店铺评估页
        path: "/assess",
        name: "assess",
        component: assessIndex,
        meta: { id: 10021 }
    },
    {
        // 通用店铺评估页
        path: "/assess/:id",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "/",
                name: "assessIndex",
                component: assessIndex,
                meta: { id: 10021, keepAlive: true }
            },
            {
                path: "history",
                name: "assessHistory",
                component: assessHistory,
                meta: { id: 10022 }
            },
            {
                path: "poster/:history",
                name: "assessPoster",
                component: assessPoster,
                meta: { id: 10023 }
            },
            {
                path: "poster/:history/:modelId/:paramId",
                name: "assessDetails",
                component: assessDetails,
                meta: { id: 10024 }
            },
            {
                path: "summary/:history",
                name: "assessSummary",
                component: assessSummary,
                meta: { id: 10025 }
            }
        ]
    },
    {
        // 品牌页
        path: "/brand",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "/",
                name: "brand",
                component: brandRecommend,
                meta: { id: 10013 }
            },
            {
                path: "discovery",
                name: "brandDiscovery",
                component: brandDiscovery,
                meta: { id: 10082 }
            },
            {
                path: "demo",
                name: "brandDemo",
                component: brandBlank,
                meta: { id: 10015 }
            },
            {
                // 品牌详情 参数 品牌code
                path: "details/:code",
                name: "brandDetails",
                component: brandDetails,
                meta: { id: 10014 }
            },
            {
                // new品牌详情-所有评论
                path: "all_commonts/:id",
                name: "allCommonts",
                component: allCommonts,
                meta: { id: 10136 }
            },
            {
                // new品牌详情-评论详情
                path: "commonts_detail/:id",
                name: "detailCommonts",
                component: detailCommonts,
                meta: { id: 10140 }
            },
            {
                // new品牌详情-投资详情
                path: "investment_details",
                name: "investmentDetails",
                component: investmentDetails,
                meta: { id: 10131 }
            },
            {
                // new品牌详情-公司审查概况
                path: "brand_review",
                name: "newBrandReview",
                component: newBrandReview,
                meta: { id: 10132 }
            },
            {
                // new品牌详情-我的评论
                path: "my_commonts",
                name: "myCommonts",
                component: myCommonts,
                meta: { id: 10137 }
            },
            {
                // new品牌详情-品牌评价输入
                path: "input_commonts/:id",
                name: "inputCommonts",
                component: inputCommonts,
                meta: { id: 10138 }
            },
            {
                // new品牌详情-品牌评价成功
                path: "commonts_success",
                name: "commontsSuccess",
                component: commontsSuccess,
                meta: { id: 10139 }
            },
            {
                // 品牌入驻
                path: "enter",
                name: "brandEnter",
                component: brandEnter,
                meta: { id: 10080 }
            },
            {
                // 品牌入驻信息填写
                path: "enterInfo",
                name: "brandEnterInfo",
                component: brandEnterInfo,
                meta: { id: 10081 }
            },
            {
                // 1对1定制化数据服务
                path: "custom",
                name: "brandCustom",
                component: brandCustom,
                meta: { id: 10146 }
            },
            {
                // 品牌对比
                path: "contrast",
                name: "contrast",
                component: brandContrast,
                meta: { id: 10114 }
            },
            {
                path: "recommend",
                name: "brandTop",
                component: brandTop,
                meta: { id: 10070 }
            },
            {
                path: "main",
                name: "main",
                component: brandMain,
                meta: { id: 10127 }
            },
            {
              path: "home",
              name: 'home',
              component: brandHome,
              meta: { id: 10191 }
            },
            {
                path: "search",
                name: "search",
                component: brandSearch,
                meta: { id: 10128 }
            },
            {
              path     : 'search/index',
              name     : 'search/index',
              component: newBrandSearch,
              meta: { id: 10192 }
            },
            {
              path     : 'search/result',
              name     : 'searchResult',
              component: brandSearchResult,
              meta: {
                id: 10193,
              }
            },

            // 品牌合作申请
            {
                path: "application_for",
                name: "applicationFor",
                component: applicationFor,
                meta: { id: 10141 }
            }
        ]
    },
    {
        // 热门报告
        path: "/hotspot",
        name: "hotspot",
        component: () => import("@/views/home/hotspot"),
        meta: { id: 10016 }
    },
    {
        // 参数 报告类型 1000x
        path: "/introduce/:id",
        name: "introduce",
        component: introduce,
        meta: { id: 10012 }
    },
    {
        path: "/openApp",
        name: "openApp",
        component: openApp
    },
    // 地图标注
    {
        path: "/mapMark",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "location",
                name: "location",
                component: mapMarkLocation,
                meta: { id: 10208 }
            },
            {
                path: "filling",
                name: "filling",
                component: mapMarkFilling,
                meta: { id: 10209 }
            },
            {
                path: "record",
                name: "record",
                component: mapMarkRecord,
                meta: { id: 10210 }
            },
            {
                path: "detail",
                name: "detail",
                component: mapMarkDetail,
                meta: { id: 10211 }
            }
        ]
    },
    {
        // 合伙人
        path: "/partner",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "/",
                name: "partner",
                component: partner,
                meta: { id: 10084 }
            },
            {
                path: "apply",
                name: "partnerApply",
                component: partnerApply,
                meta: { id: 10099 }
            },
            {
                path: "cashout",
                name: "partnerCashout",
                component: partnerCashout,
                meta: { id: 10087 }
            },
            {
                path: "history",
                name: "partnerHistory",
                component: partnerHistory
            },
            {
                path: "invite",
                name: "partnerInvite",
                component: partnerInvite,
                meta: { id: 10085 }
            },
            {
                path: "join/:id",
                name: "partnerJoin",
                component: partnerJoin
            },
            {
                path: "enter",
                name: "partnerEnter",
                component: partnerEnter,
                meta: { id: 10083 }
            },
            {
                path: "qr",
                name: "partnerQR",
                component: partnerQR,
                meta: { id: 10086 }
            },
            {
                path: "commission_record",
                name: "commissionRecord",
                component: commissionRecord,
                meta: { id: 10148 }
            },
            {
                path: "invitation",
                name: "invitation",
                component: invitation,
                meta: { id: 10155 }
            }
        ]
    },
    {
        // 店铺列表
        path: "/shop",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "/",
                name: "shopList",
                component: shopList,
                meta: { id: 10065 }
            },
            {
                path: "/temporary",
                component: temporary
            },
            {
                path: "/brandFeedback",
                component: brandFeedback,
                meta: { id: 10116 }
            }
        ]
    },
    // {
    //   // 推荐加盟需求
    //   path: "/join/demand",
    //   name: "joinDemand",
    //   component: joinDemand,
    //   meta: { id: 10113 }
    // },
    {
        // 加盟协议
        path: "/join/agreement",
        name: "joinAgreement",
        component: joinAgreement,
        meta: { id: 10115 }
    },
    {
        // 分享界面
        path: "/share",
        component: {
            render: c => c("router-view")
        },
        // 参数 分享token
        children: [
            {
                path: "/",
                name: "share",
                component: () => import(/* webpackChunkName: "share-index" */ "@/views/share/invite")
            },
            {
                path: ":code",
                component: () => import(/* webpackChunkName: "share-index" */ "@/views/share/index")
            },
            {
                path: "invite/:token",
                component: () => import(/* webpackChunkName: "share-invite" */ "@/views/share/invite")
            },
            {
                path: "inviteSP/:token",
                component: () => import(/* webpackChunkName: "share-invite-10002" */ "@/views/share/inviteSP")
            },
            {
                path: "report/:token",
                component: () => import(/* webpackChunkName: "share-report" */ "@/views/share/report")
            },
            {
                path: "course/:id",
                component: () => import(/* webpackChunkName: "share-report" */ "@/views/share/course")
            },
            {
                path: "inviteAct/:token",
                component: () => import(/* webpackChunkName: "share-act" */ "@/views/share/inviteAct")
            }
        ]
    },
    {
        // 推广分享
        path: "/shareEx",
        name: "shareEx",
        component: () => import(/* webpackChunkName: "share-report" */ "@/views/share/inviteEx")
    },
    {
        // 专题页
        path: "/topic/:id",
        name: "topic",
        component: () => import(/* webpackChunkName: "single-topic" */ "@/views/home/topic"),
        meta: { id: 10019 }
    },
    {
        // channel
        path: "/channel",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "invite/:inviteCode",
                name: "channel",
                component: () => import(/* webpackChunkName: "channel-index" */ "@/views/channel/inviteRegister")
            },
            {
                path: "feedback",
                component: feedback
            },
            {
                path: "signin/:id",
                component: signin,
                meta: { id: 10103 }
            },
            {
                path: "celebrate/:id",
                component: celebrate,
                meta: { id: 10112 }
            },
            {
                path: "coolSummer",
                component: coolSummer,
                meta: { id: 10133 }
            },
            {
                path: "customization/:id",
                component: customization,
                meta: { id: 10142 }
            },
            {
                path: "download",
                component: download,
                meta: { id: 10149 }
            },
            {
                path: "downloadActive",
                component: downloadActive,
                meta: { id: 10157 }
            },
            {
                path: "guideDown",
                component: guideDown,
                meta: { id: 10156 }
            },
            {
                path: "nationalDay",
                component: nationalDay,
                meta: { id: 10163 }
            },
            {
                path: "tubatuPromo",
                component: tubatuPromo,
                meta: { id: 10166 }
            },
            {
                path: "smsJumpPage",
                component: smsJumpPage,
                meta: { id: 10165 }
            },
            {
                path: "huaxiaLoan",
                component: huaxiaLoan,
                meta: { id: 10167 }
            },
            {
                path: "huaxiaLoanDetail",
                component: huaxiaLoanDetail,
                meta: { id: 10168 }
            },
            {
                path: "huaxiaLoanSuccess",
                component: huaxiaLoanSuccess,
                meta: { id: 10169 }
            },
            {
                path: "zhihuInformation",
                component: zhihuInformation,
                meta: { id: 10206 }
            }
        ]
    },
    {
        // coupons 领券中心
        path: "/couponCenter/:id",
        name: "couponCenter",
        component: () => import(/* webpackChunkName: "coupon-center" */ "@/views/coupon/index")
    },
    {
        // 活动落地页 - 双十一
        path: "/doubleEleven/:id",
        name: "doubleEleven",
        meta: {
            id: "10165"
        },
        component: doubleEleven
    },
    {
        // 活动落地页 - 新人落地页
        path: "/newPeople/:bannerid",
        name: "newPeople",
        meta: {
            id: "10172"
        },
        component: newPeople
    },
    {
        // 活动落地页 - 年终活动页
        path: "/yearActivities/:bannerid",
        name: "yearActivities",
        component: yearActivities
    },
    {
        // 活动落地页 - 新人落地页
        path: '/newUserActivity/:bannerid',
        name: 'newUserActivity',
        meta: {
          id: '10182'
        },
        component: newUserActivity
    },
    {
        // 活动落地页 - 开店作战计划落地页
        path: '/operationPlan',
        name: 'operationPlan',
        meta: {
          id: '10204'
        },
        component: operationPlan
    },
    {
        // 活动落地页 - 开店作战计划落地页
        path: '/operationDetail',
        name: 'operationDetail',
        meta: {
          id: '10205'
        },
        component: operationDetail
    },
    // {
    //     path: '/select-city-page',
    //     name: 'selectCityPage',
    //     component: selectCityPage
    // },
    {
      path     : '/mall/search',
      name     : 'mallSearch',
      component: mallSearch,
      meta: {
        id: '10200'
      }
    },
    {
      path     : '/firstOrderActivity/:bannerid',
      name     : 'firstOrderActivity',
      component: firstOrderActivity,
      meta: {
        id: '10201'
      }
    },

];
