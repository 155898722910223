/**
 * 数据查询接口
 */

import { Axios } from './http'

const commonRequest = (config) => {
  return new Promise((resolve, reject) => {
    Axios.request(config).then(res => {
      resolve(res.data);
    }).catch(e => {
      reject(e);
    });
  })
}

const dataQueryHttp = {}

dataQueryHttp.openRegion = (longitude, latitude) => {
  return commonRequest( { url: '/v1/data/public/location', method: 'post', data: {
      latitude,
      longitude,
    } } )
}

export default { dataQueryHttp };
