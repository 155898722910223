<template>
    <van-popup position="bottom" v-model="showPop" class="poiPicker" :class="{ ios: isIOS, ipx: isIPX }">
        <van-picker :visible-item-count="5" value-key="name" @confirm="confirm" @cancel="cancel"  show-toolbar title="选择行业" :columns="columns" />
    </van-popup>
</template>
<script>
export default {
    model: {
        prop : 'show',
        event: 'showPicker'
    },
    props: {
        type: {
            type   : String,
            default: 'open',
        },
        show: {
            type   : Boolean,
            default: false,
        }
    },
    data () {
        return {
            columns: [],
            isIOS  : false,
            isIPX  : false,
        };
    },
    computed: {
        showPop: {
            get () {
                return this.show;
            },
            set ( nv ) {
                this.$emit( 'showPicker', nv );
            }
        }
    },
    methods: {
        confirm ( picker, indexArr ) {
            const oodeArr = this.getIndustryCode( indexArr, this.columns );
            this.showPop = false;
            this.$emit( 'confirm', picker, oodeArr );
        },
        cancel () {
            this.showPop = false;
        },
        getIndustry () {
            this.HTTP.formatCategory( this.type ).then( ( res ) => {
                // 处理数据
                this.columns = this.normalizeData( res.data );
            } );
        },
        normalizeData ( data ) {
            // 处理成三级的选择器picker
            for ( let i = 0; i < data.length; i ++ ) {
                if ( ! data[i].children ) {
                    data[i].children = [];
                }
                const arr1 = data[i].children;
                if ( ! arr1.length ) {
                    // 如果为空，填充空数组，保证数据结构为三层
                    arr1.push( { name: '', children: [] } );
                }
                for ( let j = 0; j < arr1.length; j ++ ) {
                    if ( ! arr1[j].children ) {
                        arr1[j].children = [];
                    }
                    const arr2 = arr1[j].children;
                    if ( ! arr2.length ) {
                        arr2.push( { name: '' } );
                    }
                    for ( let k = 0; k < arr2.length; k ++ ) {
                        if ( arr2[k].children ) {
                            delete ( arr2[k] ).children;
                        }
                    }
                }
            }
            return data;
        },
        getIndustryCode ( indexArr, data ) {
            const code = [];
            const firstData  = data[indexArr[0]];
            const secondData = firstData.children[indexArr[1]];
            const thirdData  = secondData.children[indexArr[2]];
            if ( firstData.name && firstData.code ) {
                code.push( firstData.code );
            }
            if ( secondData.name && secondData.code ) {
                code.push( secondData.code );
            }
            if ( thirdData.name && thirdData.code ) {
                code.push( thirdData.code );
            }
            return code;
        }
    },
    created () {
        this.getIndustry();
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

// 临时提供样式兼容调整（iPhone X +）
.poiPicker {
    &.ipx {
        padding-bottom: $iPXBottomBar * 2;
    }
    ::v-deep .van-picker__toolbar {
        height: 14vw;
    }
}
</style>
