<template>
	<div>
		<StateBar />
		<TitleHeader title="美宜佳-大冲城市花园-住宅-沿街商铺" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
			<img slot="right" class="title-icon" src="../../../../static/shopEcology/unCollection.png" alt="">
		</TitleHeader>
		<AutoView :header="true" :footer="false">
			<img src="../../../../static/shopEcology/examples-page.png" alt="">
		</AutoView>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.title-icon {
		width: vw(35);
		height: vw(31);
	}

	img {
		width: 100%;
	}
</style>
