<template>
    <div class="invite">
        <!-- 介绍界面 -->
        <img src="/static/img/invite_201107.jpg" class="invite--bg">
        <div class="invite--footer">
            <button type="button" @click="showRegister = true">立即领取福利</button>
            <p class="info">不限时，免费领！（仅限新用户）</p>
        </div>

        <!-- 注册界面 -->
        <transition name="fade">
        <div v-show="showRegister" class="invite--mask" @click.self="showRegister = false">
            <div v-if="!isRegistered" class="panel">
                <form class="invite--form">
                    <h3 class="f-tac">请输入手机号码领取福利</h3>
                    <input type="phone" ref="phone" v-model="form.phone" :disabled="submitting" placeholder="请输入手机号" maxlength="11" />
                    <div class="f-clear">
                        <input type="text" ref="code" v-model="form.code" :disabled="submitting" placeholder="请输入验证码" maxlength="6" class="f-fl w_1" />
                        <input type="button" :disabled="submitting || isSendCode" :value="isSendCode ? waitingTime + '秒' : '获取验证码'" class="f-fr w_2" @click="onGetCode" />
                    </div>
                    <button type="button" :disabled="submitting" @click="onSubmit">{{ submitting ? '请稍候' : '立即领取' }}</button>
                </form>
            </div>
            <!-- 注册成功 -->
            <div v-else class="panel">
                <form class="invite--form">
                    <img src="/static/icon/success_light.png" />
                    <h3 class="f-tac">领取成功</h3>
                    <p class="info f-tac">立即下载上上参谋APP，查看报告</p>
                    <button type="button" @click="download">立即查看</button>
                </form>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
const BASE_NUM = 60; // 默认等待时间

export default {
    name: 'invite',
    data () {
        return {
            title: '上上参谋，生意人的数据军师',

            loading     : true,
            showRegister: false,
            submitting  : false,
            isRegistered: false,
            isSendCode  : false,
            timer       : 0,
            waitingTime : BASE_NUM,

            inviteDetail: {
                avatar            : '',
                discount          : '',
                username          : '',
                invitePic         : '',
                couponExist       : false,
                haveRegisterCoupon: false,
                inviteDescribe    : '',
            },
            form: {
                code      : '',
                phone     : '',
                fromType  : '',
                inviteCode: '',
                inviteType: 2, // 市调报告邀请 定值
            },
        };
    },
    methods: {
        download () {
            top.location.href = this.HTTP.getAppStore();
        },
        validate ( justPhone ) {
            if ( ! ( /^1[3456789]\d{9}$/.test( this.form.phone ) ) ) {
                this.$toast( '请输入正确的手机号' );
                this.$refs.phone.focus();
                return false;
            }
            if ( justPhone ) return true;
            if ( ! ( /^\d{6}$/.test( this.form.code ) ) ) {
                this.$toast( '请输入6位数验证码' );
                this.$refs.code.focus();
                return false;
            }
            return true;
        },
        onGetCode () {
            if ( this.isSendCode || this.validate( true ) === false ) return;
            this.HTTP.captcha( { phone: this.form.phone } ).then( ( res ) => {
                this.isSendCode = true;
                this.$toast( '验证码发送成功' );
                clearInterval( this.timer );
                this.timer = setInterval( () => {
                    if ( this.waitingTime === 1 ) {
                        clearInterval( this.timer );
                        this.isSendCode  = false;
                        this.waitingTime = BASE_NUM;
                    } else {
                        this.waitingTime --;
                    }
                }, 1000 );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg );
                } else {
                    this.$toast( '网络不稳定，请稍候重试！' );
                }
                setTimeout( () => { this.isSendCode = false; }, 2000 );
            } );
        },
        onSubmit () {
            if ( this.submitting || this.validate() === false ) return;
            this.submitting = true;
            this.HTTP.inviteRegister( this.form ).then( ( res ) => {
                this.isRegistered = true;
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg );
                } else {
                    this.$toast( '网络不稳定，请稍候重试！' );
                }
                setTimeout( () => { this.submitting = false; }, 2000 );
            } );
        },
    },
    created () {
        // 设置标题
        document.title = this.title;
        // 解析邀请码
        this.form.inviteCode = this.$route.params.token;
        if ( this.form.inviteCode ) {
            this.HTTP.getInviteDetail( this.form.inviteCode ).then( ( res ) => {
                this.inviteDetail = res;
                this.loading      = false;
            } );
        }
        // 渠道检测
        this.form.fromType = this.$route.query.fromtype;
        if ( ! this.form.fromType ) {
            if ( location.href.indexOf( 'singlemessage' ) > - 1 ) {
                this.form.fromType = 'singlemessage';
            } else if ( location.href.indexOf( 'timeline' ) > - 1 ) {
                this.form.fromType = 'timeline';
            }
        }
        // 进行上报
        if ( this.form.fromType ) {
            this.HTTP.reportPV( this.form.fromType, this.form.inviteType ).then( ( res ) => {} );
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.invite {
    position: relative;
    min-height: 100vh;
    background-color: #451DB2;
    padding-bottom: 25vw;
    box-sizing: border-box;

    #{&}--bg {
        width: 100%;
    }

    #{&}--footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 25vw;
        background: linear-gradient(to bottom, #7335E6, #451DB4);
        border-radius: $radius_s $radius_s 0 0;
        text-align: center;
        padding: $margin $padding;
        box-sizing: border-box;
        box-shadow: 0 -2vw 5vw rgba(#451DB4, .3);

        .info {
            @include font( #FFBB00, 2.66vw, normal );
            margin-top: $margin * 0.5;
        }
    }

    #{&}--form {
        position: absolute;
        top: $padding;
        width: calc( 84vw - #{ $padding * 1.6 } );
        z-index: 2;

        input, button {
            @include ani;
            display: block;
            width: 100%;
            height: 13vw;
            font-size: $font_400;
            line-height: normal;
            padding: 0 4.8vw;
            margin-bottom: 3.6vw;
            border-radius: $radius_xs;
            border: none;
            box-sizing: border-box;
            -webkit-appearance: none;

            &::-webkit-input-placeholder {
                color: #AEB1BD;
            }
            &[disabled] {
                color: #D2D2D2!important;
                opacity: .8;
            }
        }

        .w_1 {
            width: 60%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .w_2 {
            width: 40%;
            @include font( #451DB4, 2.93vw, bold );
            background-color: white;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    button {
        background: linear-gradient(to right, #FFA943, #FE6D40);
        color: white;
        font-size: $font_373;
        font-weight: bold;
        width: 100%;
        height: 13vw;
        border-radius: $radius_xs;
        border: none;
    }

    #{&}--mask {
        @include flexColumn;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, .7);

        .panel {
            position: absolute;
            width: 84vw;
            height: 70vw;
            top: 25%;
            padding: $padding $padding * .8;
            box-sizing: border-box;
            border-radius: $radius;
            background: linear-gradient(to bottom, #9C67FF, #6334E3);

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                width: calc( 100% - 2px );
                height: calc( 100% - 2px );
                border-radius: $radius;
                background: linear-gradient(to bottom, #7335E6, #451DB4);
            }

            &:after {
                content: '';
                position: absolute;
                top: 1vw;
                left: 0;
                width: 90vw;
                height: 18vw;
                margin-left: -3vw;
                background-size: contain;
            }

            img {
                display: block;
                width: 17.6vw;
                height: 17.6vw;
                margin: 0 auto 6.1vw;
            }

            h3 {
                @include font( #FFDB00, $font_560, bold );
                margin-bottom: $margin * 2;
            }

            .info {
                color: white;
                font-size: 2.93vw;
                margin-bottom: $padding * 1;
                opacity: .8;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        @include ani(.5s);
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
}
</style>
