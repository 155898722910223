<template>
    <div class="store_info">
        <h3><span>在营门店分布</span> <img @click="openPop" src="/static/icon/remind.png" alt=""></h3>
        <!-- <van-row class="store_tab">
            <van-col>
                <h3>4023</h3>
                <p>在营门店</p>
            </van-col>
            <van-col>
                <h3>76</h3>
                <p>新增门店(一年内)</p>
            </van-col>
            <van-col>
                <h3>21</h3>
                <p>倒闭门店(一年内)</p>
            </van-col>
        </van-row> -->
        <div id="china_map"> </div>
        <!-- 查询城市能否开店 -->
        <!-- <div class="open_shop">
            <h3>查询我的城市能否开店？</h3>
            <p>加盟找个品牌赚不赚钱？开在哪里？一键获取！</p>
        </div>
        <div class="store_search">
            <div class="search_input">
                <input type="text" v-model="searchValue" placeholder="选择开店城市">
            </div>
            <div class="search_btn">立即查询</div>
        </div> -->

        <!--  -->
        <pop :paramsNum="1" ref="popRef"></pop>
    </div>
</template>



<script>
import * as echarts from 'echarts';
import pop from './pop';
require('echarts/map/js/china');
export default {
    data() {
        return {
            chinachart: null,
            searchValue: null,
            newData: [],
            currentIndex: null,
            maxMax: null,
        }
    },
    components: {
        pop
    },
    props: {
        chartList: {
            type: Array
        },
        currentCity: {
            type: String
        },
        scrollBool: {
            type: Boolean
        }
    },
    methods: {
        //
        openPop() {
            this.$refs['popRef'].getIsShow();
        },
        chartInfo() {
            // 初始化echarts实例
            this.chinachart = echarts.init(document.getElementById('china_map'));
            // 进行相关配置
            this.chartOption = {
                tooltip: { // 鼠标移到图里面的浮动提示框
                    alwaysShowContent: true,
                    padding: 7.5,
                    // 相对位置
                    formatter (params) {
                        let value;
                        if (params.data) {
                            value = params.data.value;
                        } else { // 为了防止没有定义数据的时候报错写的
                            value = 0
                        }
                        let htmlStr = `<div class='chartTool'><div> ${params.name}</div>
                        <p>门店：${value}</p></div>`
                        return htmlStr
                    },
                    extraCssText: 'z-index: 1',
                    position: 'inside',
                    backgroundColor: "rgba(0,0,0, .6)",//提示标签背景颜色
                    textStyle:{ color:"#fff" }, //提示标签字体颜色
                },
                visualMap: { // 左下角的颜色区域
                    show: true,
                    showLabel: true,
                    hoverLink: false,  //
                    itemWidth: 6,
                    itemHeight: 6,
                    textGap: 4,
                    itemGap: 2,
                    align: "left",
                    type: 'piecewise', // 定义为分段型 visualMap
                    pieces: [ // 自定义『分段式视觉映射组件（visualMapPiecewise）』的每一段的范围，以及每一段的文字，以及每一段的特别的样式
                        { gte: this.maxMax, label: "≥ "+this.maxMax, color: "#4B8DFF" },
                        { gte: Math.round(((this.maxMax-1)*(2/3))+1),lt: (this.maxMax-1),label: Math.round(((this.maxMax-1)*(2/3))+1)+" - "+(this.maxMax-1),color: "#85B2FF" },
                        { gte: Math.round(((this.maxMax-1)/3)+1),lt: Math.round((this.maxMax-1)*(2/3)),label: Math.round(((this.maxMax-1)/3)+1)+" - "+Math.round((this.maxMax-1)*(2/3)),color: "#BBD4FF" },
                        { gte: 1, lt:Math.round((this.maxMax-1)/3), label:'1 - '+Math.round((this.maxMax-1)/3),color: "#E7F2FF"  }
                    ],
                    textStyle: {
                        color: '#8E9099',
                        fontSize: 9,
                    },
                    padding: 0,
                    text:['门店数（家）'],
                    right: '3%',
                    bottom: '25%',
                    selectedMode: true,
                },
                // geo配置详解：
                geo: { // 地理坐标系组件用于地图的绘制
                    map: 'china', // 表示中国地图
                    scaleLimit: {
                        min: 1,
                        max: 2
                    },
                    zoom: 1.2,
                     // 当前视角的缩放比例（地图的放大比例）
                    label: {
                        normal: {
                            show: false,
                            fontSize: "11",
                            color: "rgba(0,0,0,0.7)"
                        }
                    },
                    itemStyle: { // 地图区域的多边形 图形样式。 borderColor: '#0055B1',
                        normal: {
                            borderColor: "#0055B1",
                        },
                    },

                },
                series: [
                    {
                        name: '中国',
                        type: 'map',
                        mapType: 'china',
                        zoom: 1.2,
                        clickable: false,
                        silent: false,  //图形是否不响应和触发鼠标事件
                        itemStyle: {
                            normal: {   //未选中状态
                                borderColor:  '#0055B1',
                                // areaColor: 'rgb(231,242,255)'
                            },
                            emphasis: {// 也是选中样式
                                borderColor: 'rgba(255, 117, 43, 1)',
                                areaColor: 'rgba(255, 117, 43, 1)',
                                label: { show: true,textStyle: { color: '#fff' } }
                            }
                        },
                        data: this.newData
                    }
                ]
            }
            // 使用刚指定的配置项和数据显示地图数据
            this.chinachart.setOption(this.chartOption);
            this.chinachart.dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: this.currentIndex }); // 默认高亮第一条数据和展示第一条数据的提示框
            // this.chinachart.dispatchAction({ type: 'highlight', dataIndex: 1 }); // dataIndex属性伟data传入的索引值
            // this.chinachart.on('mouseover', (e) => {
            //     if (e.dataIndex !== 1) { // 当鼠标移除的时候 使默认的索引值去除默认选中
            //         this.chinachart.dispatchAction({ type: 'hideTip', seriesIndex: 0, dataIndex: 1 });
            //         this.chinachart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 1 });
            //     }
            // });
            // this.chinachart.on('click', e => {
            //     for(let i=0;i<this.newData.length;i++) {
            //         if(this.newData[i].name == e.name) {
            //             this.chinachart.dispatchAction({
            //                 type: 'geoSelect',
            //                 seriesIndex: i,
            //                 seriesName: this.newData[i].name,
            //                 dataIndex: i,
            //                 name: this.newData[i].name
            //             })
            //         }
            //     }
            //     // this.chinachart.dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: 1 });
            //     // this.chinachart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: 1 });
            // });
        },
    },
    mounted () {
        let numArry = []
        for(let i=0;i<this.chartList.length; i++) {
            if(this.chartList[i].province != "其他省份") {
                this.newData.push({
                    name: this.chartList[i].province.substring(0,2),
                    value: this.chartList[i].count,
                })
                numArry.push(this.chartList[i].count)
            }
            if(this.chartList[i].province == this.currentCity) {
                this.currentIndex = i;
            }
        }
        this.maxMax = Math.max.apply(null,numArry);
        this.chartInfo();
    },
}

</script>


<style scoped lang="scss">
.store_info{
    background: #fff;
    width: 100%;
    height: auto;
    padding: 4.53vw 4vw;
    box-sizing: border-box;
    margin-bottom: 2.67vw;
    h3{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span{
            font-size: 4.53vw;
            font-weight: bold;
            color: #1D2233;
            // line-height: 6.4vw;
            margin-right: 1.33vw;
        }
        img{
            width: 4.53vw;
            height: 4.53vw;
        }
    }

    .store_tab{
        margin: 4vw auto;
        ::v-deep .van-col{
            background: #F7F7F7;
            border-radius: 1.87vw;
            padding: 2.67vw 0vw;
            text-align: center;
            box-sizing: border-box;
            width: calc(33.3% - 1.6vw);
            h3{
                font-size: 4.8vw;
                font-weight: bold;
                color: #333333;
                line-height: 5.6vw;
            }
            p{
                font-size: 2.93vw;
                color: #999999;
                line-height: 4vw;
                margin-top: 1.33vw;
            }
            &:nth-child(2) {
                margin: 0 2.4vw;
            }
        }
    }
    #china_map{
        width:100%;
        height: 62.4vw;
        ::v-deep .chartTool{
            font-size: 2.93vw;
            box-sizing: border-box;
            line-height: 4vw;
        }
    }
    .open_shop{
        margin: 2.67vw 0 0 0;
        text-align: center;
        h3{
            font-size: 4.27vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 6vw;
        }
        p{
            margin: 0.4vw 0 0;
            font-size: 3.2vw;
            color: #666666;
            line-height: 4.4vw;
        }
    }
    .store_search{
        margin: 4.8vw auto 0 ;
        width: calc(100% - 10.13vw);
        box-sizing: border-box;
        height: 9.87vw;
        border-radius: 1.33vw;
        overflow: hidden;
        display: flex;
        background: #F7F7F7;
        align-items: center;
        justify-content: center;
        .search_input{
            flex: 1;
            height: 100%;
            padding: 2.27vw 4.53vw;
            line-height: normal;
            box-sizing: border-box;
            border: 1px solid #E7E7E7;
            border-right: none;
            border-top-left-radius: 1.33vw;;
            border-bottom-left-radius: 1.33vw;;
            overflow: hidden;
            input {
                padding: 0;
                background: transparent;
                border: none;
                padding: 0;
                margin: 0;
                color: #999999;
                font-size: 3.73vw;
                display: block;
                width: 100%;
                line-height: normal;
                box-sizing: border-box;
            }
            input::-webkit-input-placeholder {
                font-size: 3.73vw;
                line-height: normal;
            }
        }
        .search_btn {
            width: 24vw;
            height: 100%;
            background: #347FFF;
            font-size: 3.73vw;
            font-weight: bold;
            color: #FFFFFF;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

</style>
