<template>
    <van-popup position="bottom" v-model="showPop" class="poiPicker" :class="{ ios: isIOS, ipx: isIPX }">
        <van-picker value-key="name" @confirm="confirm" @cancel="cancel" show-toolbar title="选择所在的地区" :columns="columns" />
    </van-popup>
</template>
<script>
export default {
    model: {
        prop : 'show',
        event: 'showPicker'
    },
    props: {
        code: String,
        show: {
            type   : Boolean,
            default: false,
        },
        list: {
            type   : Array,
            default: null,
        },
    },
    data () {
        return {
            columns: [],
            isIOS  : false,
            isIPX  : false,
        };
    },
    computed: {
        showPop: {
            get () {
                return this.show;
            },
            set ( nv ) {
                this.$emit( 'showPicker', nv );
            }
        }
    },
    methods: {
        confirm ( picker, indexArr ) {
            const oodeArr = this.getCityCode( indexArr, this.columns );
            this.showPop = false;
            this.$emit( 'confirm', picker, oodeArr );
        },
        cancel () {
            this.showPop = false;
        },
        getOpenCity () {
            if ( this.list ) {
                this.columns = this.list;
            } else {
                // 201010已废弃
                // this.HTTP.brandCityList( this.code ).then( ( res ) => {
                //     this.columns = res;
                // } );

                this.HTTP.getCityZone( 'v2all' ).then( ( res ) => {
                    this.columns = this.normalizeData( res.data );
                } );
            }
        },
        getCityCode ( indexArr, data ) {
            const code = [];
            let firstData, secondData, thirdData;
            if ( indexArr[0] !== undefined ) {
                firstData = data[indexArr[0]];
            }
            if ( indexArr[1] !== undefined ) {
                secondData = firstData.children[indexArr[1]];
            }
            if ( indexArr[2] !== undefined ) {
                thirdData = secondData.children[indexArr[2]];
            }
            if ( firstData && ( firstData.name && firstData.code ) ) {
                code.push( firstData.code );
            }
            if ( secondData && ( secondData.name && secondData.code ) ) {
                code.push( secondData.code );
            }
            if ( thirdData && ( thirdData.name && thirdData.code ) ) {
                code.push( thirdData.code );
            }
            return code;
        },
        normalizeData ( data ) {
            for ( let i = 0; i < data.length; i ++ ) {
                if ( ! data[i].children ) {
                    data[i].children = [];
                }
                const arr1 = data[i].children;
                if ( ! arr1.length ) {
                    arr1.push( { name: '', children: [] } );
                }
                for ( let j = 0; j < arr1.length; j ++ ) {
                    if ( ! arr1[j].children ) {
                        arr1[j].children = [];
                    }
                    const arr2 = arr1[j].children;
                    if ( ! arr2.length ) {
                        arr2.push( { name: '' } );
                    }
                    for ( let k = 0; k < arr2.length; k ++ ) {
                        if ( arr2[k].children ) {
                            delete ( arr2[k] ).children;
                        }
                    }
                }
            }
            return data;
        },
    },
    created () {
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
        }
        this.getOpenCity();
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

// 临时提供样式兼容调整（iPhone X +）
.poiPicker {
    &.ipx {
        padding-bottom: $iPXBottomBar * 2;
    }
    ::v-deep .van-picker__toolbar {
        height: 14vw;
    }
}
</style>
