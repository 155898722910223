<template>
    <div class="wrapper">
        <StateBar/>
        <TitleHeader title="点位雷达" color="black" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView ref="view" :footer="false">
            <div class="pay-success">
				<img src="/static/pointRadar/paySuccessful.png" alt="">
				<div class="title">雷达启动成功，正在扫描中…</div>
				<div class="small-title">预计24小时内完成扫描</div>
				<div class="small-title">请在【我的-点位雷达】查看点位雷达结果</div>
				<div class="button" @click="look">确认</div>
			</div>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        look() {
			this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 3180100, 3180101);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "CHANGE_MAIN_TAB",
                id: "me",
                params: null,
                trackData: null,
                isClear: true,
            });
            this.swRouter('/pointRadar/radarList')
        }
    },
    beforeMount() {
        // 禁止返回操作
        this.JSBridge.callHandler("interceptBack", true);
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import "@/styles/index.scss";

.pay-success {
    padding-top: vw(112);
    text-align: center;

    img {
        width: vw(174);
        height: vw(174);
    }

    .title {
        margin-top: vw(40);
        height: vw(42);
        font-size: vw(30);
        font-weight: 600;
        color: #333333;
        line-height: vw(42);
    }

    .small-title {
        width: vw(650);
        font-size: vw(24);
        color: #999999;
        line-height: vw(30);
        margin: vw(6) auto 0;
    }

    .button {
        width: vw(420);
        text-align: center;
        height: vw(80);
        line-height: vw(80);
        background: #347FFF;
        border-radius: vw(8);
        font-size: vw(30);
        font-weight: 600;
        color: #FFFFFF;
        margin: vw(68) auto 0;
    }
}

</style>