<template>
  <div class="page-content">
    <StateBar></StateBar>
    <TitleHeader title="系统通知" bgcolor="transparent" :back="showBack">
      <div class="right-icon" slot="right">
        <img src="/static/me/clear.png" alt="" @click="readAllNotice" />
        <img src="/static/me/setting.png" alt="" @click="goSetting" />
      </div>
    </TitleHeader>
    <AutoView :header="true" :footer="false" class="message--view l-view">
      <van-skeleton :loading="!list || firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <div class="message-card" v-for="cardItem in list" @click="lookMore(cardItem)">
          <div class="message-card-header">
            <div class="message-card-header-left">
              <img class="message-card-header-icon" :src="cardItem.icon" alt="" />
              <span>{{ cardItem.name }}</span>
            </div>
            <div class="message-card-header-right">
              <span class="spot" v-if="cardItem.status == 0"></span>
              <span class="time">{{ cardItem.time }}</span>
            </div>
          </div>
          <div class="message-card-title">
            {{ cardItem.title }}
          </div>
          <div class="message-card-des" @click.stop="onTap(cardItem)"> {{ cardItem.content }}</div>
        </div>
      </van-skeleton>
    </AutoView>
  </div>
</template>

<script>
export default {
  name: 'message-notification',
  data() {
    return {
      firstLoading: false,
      showBack    : true,
      loading: true,
      list: [],
      listType: {
        "1": "报告通知",
        "3": "优惠福利",
        "4": "参谋学院",
        "5": "开店助手",
        "6": "精选品牌",
        "7": "最新资讯",
        "8": "合伙人",
        "9": "留言回复"
      },
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    goSetting() {
      this.swRouter('/setting?msgType=0')
    },
    // 一键已读
    readAllNotice() {
      if (!this.list.length || this.list.every(el => el.status == 1)) {
        this.$toast({
          message: '暂无未读通知',
          position: 'middle'
        })
        return
      }
      this.HTTP.readAllMsg().then(res => {
        if (res) {
          this.loadData()
        }
      })
    },
    // c查看某个类型的通知列表
    lookMore(item) {
      this.JSBridge.callHandler("onNormalizingCall", item.link);
    },
    // 查看某个通知详情
    onTap(item) {
      this.HTTP.readUserMsg(item.id).then(res => {
        item.status = 1;
      });
      this.JSBridge.callHandler("onNormalizingCall", item.contentLink);
    },
    loadData() {
      this.HTTP.queryMessageNotice().then(res => {
        this.list = res.msgList || []
        console.log(res)
      }).finally(() => {
        this.firstLoading = false;
        this.loading = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  background: #fff;
}
.message-card {
  background: #FFFFFF;
  border-radius: 1.333vw;
  padding: 2.4vw 3.333vw;
  margin-bottom: 2.667vw;
  &:last-child {
    margin-bottom: 0;
  }
  .message-card-des {
    font-weight: 400;
    color: #999999;
    font-size: 3.2vw;
    margin-top: 1.333vw;
    line-height: 4.4vw;
  }
  .message-card-title {
    font-weight: bold;
    color: #333333;
    font-size: 3.733vw;
    margin-top: 2.4vw;
    line-height: 5.333vw;
  }
  .message-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .message-card-header-left {
      font-weight: 400;
      color: #333333;
      font-size: 3.477vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .message-card-header-icon {
        width: 8vw;
        height: 8vw;
        margin-right: 1.866vw;
      }
    }
    .message-card-header-right {
      display: flex;
      align-items: center;
      .spot {
        background: #FF5900;
        width: 2.667vw;
        height: 2.667vw;
        border-radius: 50%;
        margin-right: 1.066vw;
        display: inline-block;
      }
      .time {
        font-weight: 400;
        color: #999999;
        font-size: 2.933vw;
      }
    }
  }
}
.right-icon {
  img {
    width: 5.33vw;
    &:first-child {
      margin-right: 4.2vw;
    }
  }
}

</style>
