<template>
  <div class="varieties">
    <StateBar></StateBar>
    <TitleHeader :title="title" bgcolor="transparent" :back="true"></TitleHeader>
    <AutoView :header="true" :footer="false" class="bills--view l-view">
      <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__s">
        <el-table
          :data="tableData"
          border
          width="100%"
          height="100%"
          :header-cell-style="tableHeaderCellStyle"
          :cell-style="tableCellStyle"
          ref="tableRef"
        >
          <el-table-column v-for="(table, index) in tableProps" :key="index" :label="table.label" align="center" :prop="table.prop" :width="table.width" >
<!--            自定义表头-->
            <template slot="header" v-if="table.supLabel">
              <p>{{ table.label }}</p>
              <p>{{ table.supLabel }}</p>
            </template>
            <template slot-scope="scope">
              <van-image class="brand-icon" :src="scope.row[table.prop]" fit="cover" v-if="table.type==='img'">
                <template #error>
                  <img class="default-icon" src="/static/icon/mall-default-logo.png" />
                </template>
                <template #loading>
                  <img class="default-icon" src="/static/icon/mall-default-logo.png" />
                </template>
              </van-image>
              <div v-else>
                {{ scope.row[table.prop] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </van-skeleton>
    </AutoView>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage } from '../../utils';
import Vue from "vue";
import 'element-ui/lib/theme-chalk/table.css';
import 'element-ui/lib/theme-chalk/table-column.css';
import { Table, TableColumn } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)

export default {
  name: 'rank-page',
  data() {
    return {
      firstLoading: false,
      tableProps: [],
      tableData: [],
      title: ''
    }
  },
  created() {
  },
  mounted () {
    const data = getLocalStorage('tableData')
    console.log('data', data)
    const { tableData, props, title } = data
    this.tableProps = props
    this.tableData = tableData
    this.title = title

    // 自动计算没有宽度的第一列（需要控制最多只有一列不设置宽度），为了解决在某些机型上表格宽度溢出等问题
    console.log('tableRef', this.$refs.tableRef.$el.clientWidth)
    const tableWidth = this.$refs.tableRef.$el.clientWidth
    const widths = this.tableProps.map(el => el.width || 0)
    const totalWidth = eval(widths.join('+'))
    console.log('totalWidth', totalWidth)
    const firstProp = this.tableProps.find(el => !el.width)
    if (firstProp && tableWidth > totalWidth) {
      firstProp.width = tableWidth - totalWidth
    }
    setLocalStorage('tableData', '')
  },
  methods: {
    tableHeaderCellStyle() {
      return {
        "background-color": "#F8F8F9",
        color: "#1D2233",
        "font-size": "12px",
        "line-height": "15px",
        "font-weight": "bold",
        "text-align": "center",
        padding: "5px 0"
      };
    },
    tableCellStyle() {
      return {
        "background-color": "#FFFFFF",
        color: "#1D2233",
        "font-size": "12px",
        "line-height": "15px",
        "font-weight": "400",
        "text-align": "center",
        padding: "5px 0"
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.brand-icon {
  width: 9.866667vw;
  height: 9.866667vw;
  vertical-align: middle;
  .default-icon {
    width: 100%;
    height: 100%;
  }
}
::v-deep.el-table {
  th.el-table__cell>.cell {
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
  }
  .cell {
    padding-left: 0;
    padding-right: 0;
    display: block;
    min-width: 0;
  }
}
::v-deep.el-table--border .el-table__cell:first-child .cell {
  padding-left: 0;
}
</style>
