<template>
	<div>

    <template v-if="!isWX">
      <StateBar />
      <TitleHeader class="header" :title="detailsObj.headTitle" bgcolor="transparent" color="black" :back="true"
                   style="border-bottom: 1px solid #F3F4F7;">
        <img slot="right" class="title-icon" v-if="!detailsObj.collect"
             src="../../../../static/shopEcology/unCollection.png" alt="" @click="collectionChange(1)">
        <img slot="right" class="title-icon" v-else src="../../../../static/shopEcology/Collection.png"
             @click="collectionChange(0)" alt="">

        <img slot="right" class="title-icon share" src="../../../../static/shopEcology/share-icon.png"
             @click="shareHandle" alt="">
      </TitleHeader>
    </template>

		<AutoView :header="!isWX" :footer="true">
			<div class="shopDetails">
				<img style="width: 100%;" v-if="bodyType" src="../../../../static/shopEcology/vip.png" alt="">
				<div class="banner-box">
					<van-swipe :loop="false" :width="getScreen()*636/750" :show-indicators="false">
						<template v-for="(item,key) in detailsObj.banners">
              <template v-for="(items,keys) in JSON.parse(item.url)">
                <van-swipe-item :key="'a'+key+'b'+keys" @click="bannerClick(item,items)">
                  <div class="banner-item">
                    <template v-if="isVideo(items)">
                      <img class="banner" v-lazy="formatUrl(items)+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_400'"
                           :src="formatUrl(items)+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_400'" alt="" onerror="this.src = this.src.split('?')[0]">
                      <img class="pay" src="../../../../static/shopEcology/list-pay.png" alt="">
                    </template>
                    <template v-else>
                      <img
                        class="banner" v-lazy="formatUrl(items) + '?x-oss-process=image/resize,m_fill,w_300'"
                        :src="formatUrl(items) + '?x-oss-process=image/resize,m_fill,w_300'" alt=""  onerror="this.src = this.src.split('?')[0]">
                    </template>

                    <div class="bottom-box">{{item.name}}</div>
                  </div>
                </van-swipe-item>
              </template>
<!--							<van-swipe-item v-if="item.type==='video'" v-for="(items,keys) in JSON.parse(item.url)"-->
<!--								:key="key+'-'+keys" @click="bannerClick(item,items)">-->
<!--								<div class="banner-item">-->
<!--									<img class="banner"-->
<!--										:src="formatUrl(items)+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_400'" alt="">-->
<!--									<img class="pay" src="../../../../static/shopEcology/list-pay.png" alt="">-->
<!--									<div class="bottom-box">{{item.name}}</div>-->
<!--								</div>-->
<!--							</van-swipe-item>-->
<!--							<van-swipe-item v-if="item.type==='image'" v-for="(items,keys) in JSON.parse(item.url)"-->
<!--								:key="key+'-'+keys" @click="bannerClick(item, items)">-->
<!--								<div class="banner-item">-->
<!--									<img class="banner" :src="formatUrl(items) + '?x-oss-process=image/resize,m_fill,w_300'" alt="">-->
<!--									<div class="bottom-box">{{item.name}}</div>-->
<!--								</div>-->
<!--							</van-swipe-item>-->
						</template>
					</van-swipe>
					<div class="icon" v-if="detailsObj.actual">限时免费</div>
				</div>
				<div class="body borderRadius">
					<!--<div class="first-content" :class="{grey: detailsObj.rentStatus==='不可租'}">-->
					<div class="first-content" :class="{grey: detailsObj.rentStatus==='不可租'}">
						<template v-if="detailsObj.rentStatus==='不可租'">
							<div class="left-box">
								<span v-if="!isNull(detailsObj.build)">{{detailsObj.build}}</span>丨<span
									v-if="!isNull(detailsObj.openStatus)">{{detailsObj.openStatus}}</span>
							</div>
							<div class="right-box" v-if="detailsObj.forecastRentLow&&detailsObj.forecastRentUp">
								根据周边预估月租<span
									v-if="!isNull(detailsObj.forecastRentLow)">{{detailsObj.forecastRentLow}}</span>-<span
									v-if="!isNull(detailsObj.forecastRentUp)">{{detailsObj.forecastRentUp}}</span>
							</div>
						</template>
						<template v-if="detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中'">
							<div class="left-box" v-if="!isNull(detailsObj.build)">{{detailsObj.build}}丨<span
									v-if="!isNull(detailsObj.openStatus)">{{detailsObj.openStatus}}</span><span>丨</span><span
									class="orange"
									v-if="!isNull(detailsObj.rentStatus)">{{detailsObj.rentStatus}}</span>
							</div>
              <div class="right-box">
                <span
                     v-if="detailsObj.rentMonth&&(!isNull(detailsObj.rentMonth))">{{detailsObj.rentMonth}}</span>
                <span
                     v-if="detailsObj.transferFee&&(!isNull(detailsObj.transferFee))">转让费{{detailsObj.transferFee}}
                </span>
                <span
                      v-if="detailsObj.inFee&&(!isNull(detailsObj.inFee))">入场费{{detailsObj.inFee}}</span>
              </div>
						</template>
					</div>
					<div class="icon-box" v-if="detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中'">
						<div class="icon-item"
							v-if="detailsObj.monthRentPer&&(!isNull(detailsObj.monthRentPer))">
							{{detailsObj.monthRentPer}}
						</div>
						<div class="icon-item" v-if="!isNull(detailsObj.rentdDay)">
							租期{{detailsObj.rentdDay}}
						</div>
						<div class="icon-item" v-if="!isNull(detailsObj.noRentDay)">
							免租期{{detailsObj.noRentDay}}
						</div>
						<div class="icon-item"
							v-if="!isNull(detailsObj.managerFee)">
							{{detailsObj.managerFee}}
						</div>
						<div class="icon-item"
							v-if="!isNull(detailsObj.rentPerDay)">
							{{detailsObj.rentPerDay}}
						</div>
					</div>
					<div class="icon-box1" v-if="detailsObj.shopComment">
						<div class="icon-item" v-for="(item,key) in JSON.parse(detailsObj.shopComment)" :key="key">
							{{item}}
						</div>
					</div>
					<div class="address-box ">
						<van-icon class="icon" name="location-o" />{{detailsObj.shopAddress}}
					</div>
					<div class="content-box" v-if="detailsObj.rentInfoList&&detailsObj.rentInfoList.length">
						<div class="content-item" v-for="(item,key) in detailsObj.rentInfoList" :key="key">
							<div class="content-left">{{item.name}}</div>
							<!--<div class="content-right" v-if="(item.isFree == '0' && item.value != '示例数据')||item.isFree||detailsObj.payed">{{item.value}}</div>-->
              <div class="content-right" v-if="item.isFree == '0' && !detailsObj.isShow" @click="detailsUnlock"><span
                class="glass5">{{item.value}}</span><img
                src="../../../../static/shopEcology/unlock.png" alt=""> </div>
              <div class="content-right" v-else>{{item.value}}</div>

						</div>
					</div>
					<div v-if="detailsObj.rentInfoList&&detailsObj.rentInfoList.length" class="look-more"
						@click="lookAll()"></div>
				</div>
        <report
          :toast="detailsObj.toast"
          :lastCheck="lastCheck"
          :currentCheck="detailsObj.currentCheck"
          :free="detailsObj.actual"
        />
				<div class="body bodyTop">
					<div class="title1">商铺参谋</div>
					<div class="agent-box" @click="agentClick" v-if="detailsObj.broker">
						<div class="left-box">
							<img :src="formatUrl(detailsObj.broker.avatar)" alt="">
						</div>
						<div class="right-box">
							<div class="top-box">
								<div class="left-top" @click="goAgentDetail(detailsObj.broker)">
									{{detailsObj.broker.name}}
									<van-icon class="icon" name="arrow" />
								</div>
								<!-- <div class="button" @click="onsultingService">我要咨询</div> -->
							</div>
							<div class="bottom-box">
								{{detailsObj.broker.introduction}}
							</div>
						</div>
					</div>
					<div class="title-box" v-if="detailsObj.broker">
						<div class="title-box-left">专业点评</div>
						<div class="title-box-right">{{detailsObj.broker.mofifyTime}}</div>
					</div>
					<div class="tips">
						{{detailsObj.publicComment}}
					</div>
					<template v-for="(itemss,keyss) in detailsObj.commentInfoList">
						<div class="content-box1" v-if="itemss.name==='综合评价'" :key="keyss">
							<div class="content-title">{{itemss.name}}</div>
              <div class="video-wrap">
                <div :class="itemss.isFree == '0' && !detailsObj.isShow ? 'glass5' : ''" >
                  <div class="tips">
                    {{itemss.value}}
                  </div>
                  <div class="video-box" v-if="detailsObj.speakVedios&&detailsObj.speakVedios[0]">
                    <div class="video-item"
                         v-for="(item,key) in JSON.parse(detailsObj.speakVedios[0].value)"
                         @click="videoPay(item)">
                      <img class="img"
                           v-if="item"
                           :src="formatUrl(item)+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_250'" alt="">
                      <img class="img"
                           v-else
                           src="../../../../static/shopEcology/video.png" alt="">
                      <img class="pay" v-if="item" src="../../../../static/shopEcology/list-pay.png"
                           alt="">
                    </div>
                  </div>
                </div>
                <img class="unlock" v-if="itemss.isFree == '0' && !detailsObj.isShow" @click="commentUnlock" src="static/shopEcology/unlock-btn.png">
              </div>
						</div>
					</template>
					<template v-for="(itemss,keyss) in detailsObj.commentInfoList">
						<div class="content-middle-box" v-if="itemss.name!=='综合评价'" :key="keyss">
							<div class="content-middle-item">
								<div class="left">{{itemss.name}}</div>
                <div class="val-wrap">
                  <div class="right" :class="itemss.isFree == '0' && !detailsObj.isShow ? 'glass5' : ''">{{itemss.value}}</div>
                  <img class="unlock" v-if="itemss.isFree == '0' && !detailsObj.isShow" @click="commentUnlock" src="static/shopEcology/unlock-btn.png">
                </div>
							</div>
						</div>
					</template>
					<div class="content-bottom-box" v-if="passengerList.length">
						<div class="title">客流统计</div>
            <div class="video-wrap">
              <div :class="passengerList[0].isFree == '0' && !detailsObj.isShow ? 'glass5' : ''">
                <div class="box" v-for="(item,key) in passengerList" :key="key">
                  <div class="img-box" @click="goVideo(JSON.parse(item.video)[0])">
                    <img class="img"
                         v-if="JSON.parse(item.video)[0]"
                         :src="formatUrl(JSON.parse(item.video)[0])+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_150'"
                         alt="">
                    <img class="img"
                         v-else
                         src="../../../../static/shopEcology/video.png" alt="">
                    <img class="pay" v-if="JSON.parse(item.video)[0]"
                         src="../../../../static/shopEcology/list-pay.png" alt="">
                  </div>
                  <div>
                    <div class="content">
                      高峰期客流量：{{item.traffic}}
                    </div>
                    <div class="content">
                      采集时间：{{item.sampleTime}}
                    </div>
                  </div>
                </div>
              </div>
              <img class="unlock" v-if="passengerList[0].isFree == '0' && !detailsObj.isShow" @click="commentUnlock" src="static/shopEcology/unlock-btn.png">
            </div>
					</div>
				</div>
			</div>
		</AutoView>
    <div class="details-footer">

      <template v-if="isWX">
        <div class="button-bottom-box">
          <van-button type="info" block class="s-radius__s btn1">
            信息未解锁，请登录上上参谋APP解锁
          </van-button>
        </div>
      </template>
      <template v-else>
        <div class="button-bottom-box2" @click="linkMe" v-if="showLinkBtn">
          <img class="chat-icon" src="/static/shopEcology/chat.png" alt="" />
          联系Ta
        </div>
        <div class="button-bottom-box" v-else>
          <div class="right-button" @click="goSubmit">我有意向</div>
        </div>
      </template>
<!--      <template v-else>-->
<!--        &lt;!&ndash;需要解锁 == 没有付费&ndash;&gt;-->
<!--        <div class="cell button-bottom-box" v-if="!detailsObj.payed && detailsObj.rentStatus =='不可租' && !detailsObj.isShow">-->
<!--          <div class="cell-bd">-->
<!--            <div class="left-button" @click="example">示例信息</div>-->
<!--          </div>-->
<!--          <div class="cell-bd">-->
<!--            <div class="content-button" @click="gotoPay">解锁查看</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;需要解锁，且可租/转让中&ndash;&gt;-->
<!--        <div class="cell button-bottom-box"  v-if="!detailsObj.payed &&(detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中')">-->
<!--          <div class="cell-bd" v-if="!detailsObj.isShow">-->
<!--            <div class="left-button" @click="example">示例信息</div>-->
<!--          </div>-->
<!--          <div class="cell-bd" v-if="!detailsObj.isShow" >-->
<!--            <div class="content-button" @click="gotoPay">解锁查看</div>-->
<!--          </div>-->
<!--          <div class="cell-bd">-->
<!--            <div class="right-button" @click="goSubmit">我有意向</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;3. 不需要解锁，且不可租 不显示&ndash;&gt;-->
<!--        &lt;!&ndash;不需要解锁，且可租/转让中&ndash;&gt;-->
<!--        <div class="button-bottom-box" v-if="detailsObj.payed &&(detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中')">-->
<!--          <div class="right-button" @click="goSubmit">我有意向</div>-->
<!--        </div>-->
<!--      </template>-->
    </div>
		<!--<div class="button-bottom-box1"-->
		<!--	v-if="detailsObj.payed&&(detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中')">-->
		<!--	<div class="left-button" v-if="detailsObj.rentMonth.indexOf('null')"><span-->
		<!--			class="number">{{detailsObj.rentMonth}}</span></div>-->
		<!--	<div class="right-button" @click="goSubmit">我有意向</div>-->
		<!--</div>-->
		<!-- 查看全部指标 -->
		<content-mask v-if="contentMaskType" :id="$route.query.id" :time="detailsObj.lastModify"
			:isShowUnlock="!detailsObj.isShow" @close="contentMaskType=false" @allMaskClick="allMaskClick" @unlock="unlock">
		</content-mask>
		<!-- 我的意向 -->
		<my-intention-mask v-if="myIntentionMaskType" @close="myIntentionMaskType=false" @submit="myIntentionSubmit">
		</my-intention-mask>
		<!-- 我的咨询 -->
		<my-consulting-service v-if="myConsultingServiceType" @close="myConsultingServiceType=false"
			@submit="myConsultingServiceSubmit"></my-consulting-service>
		<!-- 视频弹窗 -->
		<video-mask v-if="videoMaskType" :autoplay="true" @close="videoMaskType=false" :src="formatUrl(videoSrc)" :title="videoTitle"></video-mask>
		<!-- 图片弹窗 -->
		<image-mask v-if="imgMaskType" @close="imgMaskType=false" :src="formatUrl(imgSrc)" :title="imgTitle"></image-mask>
    <van-popup v-model:show="unclockMaskVisible" position="bottom">
      <div class="footer-wrap">
        <div class="img-wrap" @click="toSVIP()">
          <img src="static/shopEcology/details-foot-1.png" />
          <van-button class="vip">立即开通</van-button>
        </div>
        <div class="img-wrap" @click="toUnclock()">
          <img src="static/shopEcology/details-foot-2.png" />
          <van-button class="unclock">立即开通</van-button>
        </div>
      </div>
    </van-popup>
    <!--预加载 勿删-->
    <img v-show="false" src="static/shopEcology/details-foot-1.png" />
    <img v-show="false" src="static/shopEcology/details-foot-2.png" />
<!--    发布求租信息弹窗-->
    <van-dialog v-model="showRentPopup" :showConfirmButton="false" get-container="#app" closeOnClickOverlay>
      <div class="rent-confirm-dialog">
        <img class="close-icon" src="/static/icon/icon_close@2x.png" @click="showRentPopup=false" />
        <div class="confirm-dialog-title">请先发布求租信息</div>
        <div class="confirm-dialog-text">您需要发布求租信息，才能开启和对方的聊天</div>
        <div class="confirm-dialog-btns">
          <div class="confirm-dialog-left-btn" @click="goRentPage('/shopEcology/asking4RentList')">管理我的求租信息</div>
          <div class="confirm-dialog-right-btn" @click="goRentPage('/h5/#/shop/rent')">发布求租信息</div>
        </div>
      </div>
    </van-dialog>
	</div>
</template>

<script>
	import PlayVideo from '@/components/VideoPlay/index'
	import contentMask from "../components/content-mask"
	import myIntentionMask from "../components/my-intention-mask"
	import myConsultingService from "../components/my-consulting-service"
	import videoMask from "../components/video-mask"
	import imageMask from "../components/image-mask"
  import {
    save,
    saveSitingIntention,
    platformDetail, hasRentIntention,
  } from '@/request/shopEcology';
  import { ImagePreview } from "vant";
  import { samplePlatformShopCode } from "../../../request/shopEcology";
  import report from './report'
	export default {
		data() {
			return {
				current: 0,
				contentMaskType: false, //查看全部指标弹窗开关
				myIntentionMaskType: false, //我的意向开关
				myConsultingServiceType: false, //我要咨询
				videoMaskType: false, //
				videoSrc: '', //
        videoTitle: '', // 视频标题
				detailsObj: {}, //接口返回对象
				passengerList: [], //客流统计数组
				bodyType: true,
				imgSrc: '',
        imgTitle: '',
				isSubmit: false,
				imgMaskType: false,
        unclockMaskVisible: false,
        isWX: false,
        lastCheck: 0,
        showRentPopup: false,
        showLinkBtn: true,
			}
		},
		components: {
			PlayVideo,
			contentMask,
			myIntentionMask,
			myConsultingService,
			videoMask,
			imageMask,
      report
		},
    computed: {
      showFooter(){
        let detailsObj = this.detailsObj;
        if (this.isWX && !detailsObj.isShow && !detailsObj.payed) {
          return true;
        } else if (this.isWX) {
          return false;
        }
        if (!detailsObj.payed && detailsObj.rentStatus =='不可租' && !detailsObj.isShow) {
          return true;
        }

        if (!detailsObj.payed &&(detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中')) {
          return true;
        }

        if (detailsObj.payed &&(detailsObj.rentStatus==='可租'||detailsObj.rentStatus==='转让中')) {
          return true;
        }

      }
    },
    created () {
      if (window.isClient) {
        this.JSBridge.callHandler("getVersionCode", null,  (code) => {
          console.log('shop getVersionCode', ~~code)
          this.showLinkBtn = ~~code >= 309
        });
      }
    },
    mounted() {
			this.getDetails()
      this.$nextTick(() => {
                this.isWX = window.isMini
                console.log(this.isWX, 'this.isWX')
                if(this.isWX) {
                    this.HTTP.upload({
                        deviceType: 3,
                        platform: 'sscm',
                        version: '2.0.0',
                        userId: '',
                        track: JSON.stringify([{pageId:'10255',refId: this.$route.query.id,timestamp: new Date().getTime()}])
                    })
                }
            })
      		document.getElementsByTagName('title')[0].innerText = '商铺详情页';
		},
		methods: {
      goRentPage(url) {
        this.showRentPopup = false
        this.swRouter(url)
      },
      linkMe() {
        this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 2350400, 2350401);
        this.startIMChat()
      },
      startIMChat() {
        this.JSBridge.callHandler("startIMChat", {
          "chatId": this.detailsObj.imAccount.userID,
          "chatName": this.detailsObj.imAccount.name,
          "cardData": JSON.stringify({
            type: 0,
            obj: JSON.stringify(this.detailsObj.cardDetail)
          })
        });
      },
      isNull(val){
        if (!val) {
          return true;
        }
        if (val === 'null') {
          return true;
        }
        return false;
      },
      getScreen(){
        return window.screen.width;
      },
			getDetails() {
				let data = {
					code: this.$route.query.id
				}
				platformDetail(data).then(res => {
					this.passengerList = []
					this.bodyType = false
					this.detailsObj = res;
          // if (this.detailsObj.toast) {
          //   this.$toast( this.detailsObj.toast);
          // }
          this.lastCheck = this.detailsObj.lastCheck
					console.log(res, 99)
					res.samples && res.samples.map(item => {
						if (item.sampleTime && item.traffic && item.video) {
							this.passengerList.push(item)
						}
					})
				}).catch(() => {
          this.$toast( '网络连接不稳定，请稍候重试！' );
        })
			},
			myIntentionSubmit(form) {
				//提交意向
				if (this.isSubmit) return;
        this.isSubmit = true;
				form.platformShopId = this.$route.query.id
				saveSitingIntention(form).then(res => {
					this.myIntentionMaskType = false
					this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 151000, 151001);
					this.swRouter({
						path: '/shopEcology/intentionsubmitSuccessful',
						query: {
							id: res,
							name: '求租意向详情'
						}
					})
				}).finally(e => {
					this.isSubmit = false;
				});
			},
			collectionChange(type) {
				if (type == 1) {
					//收藏
					this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 144000, 144001);
				} else {
					//取消收藏
					this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 144000, 144002);
				}
				let data = {
					code: this.$route.query.id,
					status: type
				}
				save(data).then(res => {
					console.log(res)
					this.getDetails()
				})
			},
      shareHandle() {
        // 分享商铺
        this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 2350100, 2350101)
        this.JSBridge.callHandler('shareShopInfo', {
          id: this.$route.query.id
        })
      },
			bannerClick(item, url) {
				// 点击banner事件
				if (!this.isVideo(url)) {
					// 点击图片
					this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 145000, 145002);
					this.imgSrc = this.formatUrl(url) + '?x-oss-process=image/resize,w_1500,m_lfit'; //JSON.parse(item.url)[index]
					this.imgMaskType = true;
          this.imgTitle = item.name;
          // ImagePreview([this.imgSrc]);
				} else {
					//点击视频
					this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 145000, 145001);
					this.videoSrc = this.formatUrl(url); // JSON.parse(item.url)[index]
					this.videoMaskType = true
          this.videoTitle = item.name;
				}
			},
			lookAll(item) {
				//查看全部
				this.contentMaskType = true
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 146000, 146001);
			},
			allMaskClick() {
				//点击查看全部弹窗 点击切换 Tab
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 147000, 147001);
			},
			unlock() {
				//弹窗解锁
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 147000, 147003);
        this.showUnclockMas();
				// this.swRouter({
				// 	path: '/shopEcology/orderPayment',
				// 	query: {
				// 		id: this.$route.query.id
				// 	}
				// })
			},
			agentClick() {
				// 铺参谋区域
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 148000, 148001);
			},
			onsultingService() {
				// 点击「我要咨询」
				this.myConsultingServiceType = true
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 148000, 148002);
			},
			detailsUnlock() {
				//详情解锁
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 149000, 149001);
        this.showUnclockMas();
				// this.swRouter({
				// 	path: '/shopEcology/orderPayment',
				// 	query: {
				// 		id: this.$route.query.id
				// 	}
				// })
			},
      commentUnlock(){
        this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 149000, 149001);
        this.showUnclockMas();
      },
      showUnclockMas(){
        this.unclockMaskVisible = true;
      },
			videoPay(url) {
				// 专业点评视频播放
				if (url) {
					this.videoSrc = this.formatUrl(url)
					this.videoMaskType = true
					this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 149000, 149002);
				}
			},
			async example() {
				// 示例数据按钮
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 150000, 150001);
        let id = await samplePlatformShopCode();
        // 请求数据
        if (id) {
          this.swRouter({
            path: '/shopEcology/shopDetails',
            query: {
              id: id,
            }
          })
        } else {
          this.swRouter({
            path: '/shopEcology/examplesPage'
          })
        }
			},
			gotoPay() {
				//去解锁
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 150000, 150002);
        this.showUnclockMas();
				// this.swRouter({
				// 	path: '/shopEcology/orderPayment',
				// 	query: {
				// 		id: this.$route.query.id
				// 	}
				// })
			},
			goSubmit() {
				//我有意向
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 150000, 150003);
				this.myIntentionMaskType = true
			},
			myConsultingServiceSubmit() {
				//咨询提交  this.detailsObj.broker.userId 铺参谋id
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 152000, 152001);
			},
			goAgentDetail(item) {
				//前往铺参谋首页
				this.swRouter({
					path: '/shopEcology/brokerIntroduction',
					query: {
						id: item.userId
					}
				})
			},
			goVideo(url) {
        this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 154000, 154001);
				if (url) {
					this.videoSrc = this.formatUrl(url);
					this.videoMaskType = true
				}
			},
      toSVIP(){
        this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 153000, 153001);
        this.swRouter({
          path: `/h5/#/member?source=shop`,
        })
      },
      toUnclock(){
        this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 153000, 153002);
        this.swRouter({
        	path: '/shopEcology/orderPayment',
        	query: {
        		id: this.$route.query.id
        	}
        })
      },
      formatUrl(url){
        if (!url) {
          return '';
        }
        return url.replace(/\\/g, '');
      },
      isVideo (url) {
        let re = /\.mp4|\.m4v|\.wmv|\.mpg|\.mpeg|\.3gp|\.mov|\.avi|\.flv/;
        return re.test(url);
        // if (url.split('.').pop() === 'mp4') {
        //   return true;
        // }
        // return false;
      },
      handleImageOnError(e){
        console.log(e, 88)
      },
		}
	}
</script>

<style lang="scss" scoped>
@import '@/styles/_cell.scss';
// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}
.van-dialog {
  width: 90%;
}
.rent-confirm-dialog {
  text-align: center;
  padding: vw(50) vw(20) vw(44) vw(20);
  position: relative;
  .close-icon {
    position: absolute;
    width: vw(21);
    height: vw(21);
    top: 4vw;
    right: 4vw;
  }
  .confirm-dialog-title {
    font-weight: bold;
    color: #333333;
    font-size: vw(34);
    line-height: vw(48);
  }
  .confirm-dialog-text {
    font-weight: 400;
    color: #999999;
    font-size: vw(28);
    line-height: vw(44);
    margin-top: vw(28);
  }
  .confirm-dialog-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: vw(68);
    >div {
      width: 44%;
      min-width: vw(265);
      height: vw(90);
      line-height: vw(90);
      font-size: 4vw;
      font-weight: bold;
      border-radius: vw(10);
      &:first-child {
        margin-right: vw(24);
      }
    }
    .confirm-dialog-left-btn {
      border: vw(2) solid #347FFF;
      color: #347FFF;
      background: #fff;
    }
    .confirm-dialog-right-btn {
      background: linear-gradient(319deg, #3888F8 0%, #86C6FF 100%);
      color: #fff;
    }
  }
}
  .autoView {
    padding-bottom: vw(20);
    background: #F7F6F9;
  }
	.title-icon {
		width: vw(35);
		height: vw(31);
	}
    .share {
        margin-left: vw(32);
    }
	.shopDetails {
		padding: vw(20) vw(20) 0;
		box-sizing: border-box;
		min-height: 100%;

		.banner {
			width: 100%;
			//height: vw(400);
			vertical-align: middle;
		}

		.banner-box {
			padding: vw(25) 0 vw(10) vw(26);
			box-sizing: border-box;
			background: #fff;
			border-radius: vw(10) vw(10) 0 0;
			position: relative;

			.banner-item {
				position: relative;
				margin-right: vw(20);
        height: vw(400);
        overflow: hidden;
				.pay {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: vw(64);
					height: vw(64);
				}

				.bottom-box {
					position: absolute;
					bottom: 0%;
					left: 0;
					height: vw(42);
					line-height: vw(42);
					text-align: center;
					background: rgba(0, 0, 0, 0.41);
					z-index: 999;
					width: 100%;
					font-size: vw(22);
					color: #FFFFFF;
				}
			}

			.icon {
				position: absolute;
				left: vw(26);
				top: vw(25);
				z-index: 999;
				width: vw(120);
				height: vw(40);
				background: #F4E6B1;
				border-radius: 0 0px vw(4) 0px;
				font-size: vw(20);
				font-weight: 600;
				color: #9D7744;
				line-height: vw(40);
				text-align: center;
			}
		}

    .bodyTop {
      margin-top: vw(20);
    }

		.borderRadius {
			border-radius: 0 0 vw(10) vw(10) !important;
			padding-top: 0 !important;
		}

		.body {
			padding: vw(24) vw(26);
			box-sizing: border-box;
			background: #fff;
			padding-bottom: vw(20);
			border-radius: vw(10);
			.orange {
				color: #FF6900;
			}

			.first-content {
				display: flex;
				flex-wrap: wrap;
				line-height: vw(44);
				padding: vw(10) 0;

				.left-box {
					font-size: vw(26);
					font-weight: 500;
					color: #2D2F33;
					letter-spacing: vw(1);
					margin-right: vw(20);
          flex-grow: 0;
          flex-shrink:0;
				}

				.right-box {
					font-size: vw(26);
					font-weight: 500;
					color: #FF6900;
          margin: 0 vw(5);
          flex-grow: 1;
          flex-shrink:1;
          text-align: right;
				}
			}

			.icon-box {
				display: flex;
				flex-wrap: wrap;

				.icon-item {
					height: vw(48);
					font-size: vw(24);
					color: #F86E21;
					line-height: vw(48);
					text-align: center;
					padding: 0 vw(13);
					margin-right: vw(10);
					margin-bottom: vw(20);
					background: #F6F6F6;
					border-radius: vw(4);
				}
			}

			.icon-box1 {
				display: flex;
				flex-wrap: wrap;

				.icon-item {
					height: vw(48);
					font-size: vw(24);
					color: #5A5E66;
					line-height: vw(48);
					text-align: center;
					padding: 0 vw(13);
					margin-right: vw(10);
					margin-bottom: vw(10);
					background: #F6F6F6;
					border-radius: vw(4);
				}
			}

			.address-box {
				display: flex;
				font-size: vw(26);
				color: #333333;
				line-height: vw(37);
				margin: vw(10) 0 vw(20);
				font-weight: 600;
				align-items: center;

				.icon {
					margin-right: vw(6);
					font-weight: 600;
					font-size: vw(30);
				}
			}

			.content-box {
				border-top: vw(1) solid #E7E7E7;
				display: flex;
				flex-wrap: wrap;
				padding-top: vw(20);

				.content-item {
					line-height: vw(34);
					width: 50%;
					display: flex;
					justify-content: space-between;
					padding: vw(10) vw(20);
					box-sizing: border-box;

					.content-left {
						font-size: vw(28);
						color: #999999;
					}

					.content-right {
						font-size: vw(28);
						color: #333333;
						position: relative;

						img {
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
							width: vw(37);
							z-index: 10;
						}
					}
				}

				.content-item:nth-child(odd) {
					border-right: vw(1) solid #E7E7E7;
				}
			}

			.look-more {
				width: vw(220);
				height: vw(52);
				margin: vw(40) auto vw(20);
				background: url('../../../../static/shopEcology/look-more.png') no-repeat;
				background-size: 100% 100%;
			}
		}

		.title1 {
			height: vw(45);
			font-size: vw(32);
			font-weight: 600;
			color: #333333;
			line-height: vw(45);
		}

		.agent-box {
			margin-top: vw(26);
			display: flex;
			border-bottom: vw(1) solid #E7E7E7;
			padding-bottom: vw(30);

			.left-box {
				display: flex;

				img {
					width: vw(104);
					height: vw(104);
					background: #D8D8D8;
				}
			}

			.right-box {
				padding-left: vw(12);
				box-sizing: border-box;
				flex: 1;

				.top-box {
					display: flex;
					justify-content: space-between;

					.left-top {
						height: vw(40);
						font-size: vw(28);
						line-height: vw(40);
						font-weight: 600;
						color: #333333;
						display: flex;
						align-items: center;

						.icon {
							color: #347FFF;
						}
					}

					.button {
						height: vw(50);
						line-height: vw(50);
						background: #FFFFFF;
						border-radius: vw(8);
						border: vw(1) solid rgba(#347FFF, 0.76);
						text-align: center;
						font-size: vw(24);
						color: #108EE9;
						box-sizing: border-box;
						padding: 0 vw(12);
					}
				}

				.bottom-box {
					margin-top: vw(19);
					font-size: vw(26);
					color: #999999;
					line-height: vw(40);
					word-wrap: break-word;
					word-break: break-all;
				}
			}

		}

		.title-box {
			height: vw(40);
			font-size: vw(28);
			color: #333333;
			line-height: vw(40);
			margin: vw(20) 0;
			display: flex;
			justify-content: space-between;

			.title-box-left {
				font-weight: 600;
			}

			.title-box-right {
				font-size: vw(26);
				color: #999999;
			}
		}

		.content-box1 {
			border-radius: vw(6);
			border: vw(2) solid #E7E7E7;
			box-sizing: border-box;
			padding: vw(10) vw(28) vw(20);
			position: relative;
			margin-top: vw(20);
      .video-wrap{
        position: relative;
        min-height: vw(155);
        .unlock{
          width: vw(249);
          height: vw(60);
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: vw(-30);
          margin-left: vw(-125);
        }
      }
			.content-title {
				height: vw(70);
				font-size: vw(28);
				color: #333333;
				line-height: vw(70);
				font-weight: 600;
			}
		}

		.tips {
			font-size: vw(26);
			line-height: vw(40);
			color: #5A5E66;
		}

		.glass {
			filter: blur(3px);
		}

		.glass5 {
      pointer-events: none;
			filter: blur(5px);
		}

		.video-box {
			display: flex;
			flex-wrap: wrap;

			.video-item {
				position: relative;
				width: vw(200);
				height: vw(200);

				.img {
					width: 100%;
					height: 100%;
				}

				.pay {
					position: absolute;
					left: 50%;
					top: 50%;
					width: vw(64);
					height: vw(64);
					transform: translateX(-50%) translateY(-50%);
				}
			}
		}

		.content-middle-box {
			margin-top: vw(20);

			.content-middle-item {
				margin-bottom: vw(20);
				padding: vw(28);
				box-sizing: border-box;
				border-radius: vw(6);
				border: vw(2) solid #E7E7E7;
				box-sizing: border-box;
				position: relative;
        .val-wrap{
          position: relative;
          .unlock{
            width: vw(249);
            height: vw(60);
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: vw(-30);
            margin-left: vw(-125);
          }
        }

				.left {
					height: vw(40);
					font-size: vw(28);
					line-height: vw(40);
					font-weight: 600;
					color: #333333;
				}

				.right {
					margin-top: vw(19);
					font-size: vw(26);
					line-height: vw(40);
					color: #5A5E66;
					word-wrap: break-word;
					word-break: break-all;
          min-height: vw(80);
				}
			}
		}

		.content-bottom-box {
			margin-bottom: vw(20);
			padding: vw(28);
			box-sizing: border-box;
			border-radius: vw(6);
			border: vw(2) solid #E7E7E7;
			box-sizing: border-box;
      .video-wrap{
        position: relative;
        min-height: vw(155);
        .unlock{
          width: vw(249);
          height: vw(60);
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: vw(-30);
          margin-left: vw(-125);
        }
      }
			.title {
				width: vw(140);
				height: vw(40);
				font-size: vw(28);
				line-height: vw(40);
				font-weight: 600;
				color: #333333;
			}

			.box {
				display: flex;
				margin-top: vw(19);
				position: relative;

				.img-box {
					position: relative;

					.pay {
						position: absolute;
						left: 50%;
						top: 50%;
						width: vw(64);
						height: vw(64);
						transform: translateX(-50%) translateY(-50%);
					}
				}

				.img {
					width: vw(155);
					height: vw(155);
					background: #D8D8D8;
				}

				.content {
					padding-left: vw(20);
					font-size: vw(26);
					line-height: vw(40);
					color: #5A5E66;
				}
			}
		}

		.unlock-box {
			width: vw(249);
			height: vw(60);
			line-height: vw(58);
			background: #FFFFFF;
			border-radius: vw(30);
			border: vw(1) solid #347FFF;
			font-size: vw(28);
			font-weight: 600;
			color: #347FFF;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
			box-sizing: border-box;

			img {
				width: vw(28);
				margin-right: vw(20);
				vertical-align: middle;
			}

		}
	}

  .details-footer{
    background: #fff;
    padding: vw(20) vw(25) 0 vw(25);
    box-sizing: border-box;
    box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);
  }
  .button-bottom-box2 {
    width: 100%;
    height: vw(80);
    background: linear-gradient(319deg, #3888F8 0%, #86C6FF 100%);
    border-radius: vw(10);
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    font-size: vw(28);
    display: flex;
    align-items: center;
    justify-content: center;
    .chat-icon {
      width: vw(35);
      height: vw(35);
      margin-right: vw(8);
    }
  }
	.button-bottom-box {
		width: 100%;
		//height: vw(127);

    .cell-bd{
     padding: 0 vw(10);
    }
		.left-button {
			flex: 1;
      width: 100%;
			height: vw(80);
			line-height: vw(80);
			border-radius: vw(10);
			border: vw(2) solid #347FFF;
			box-sizing: border-box;
			font-size: vw(30);
			font-weight: 600;
			color: #347FFF;
      text-align: center;
		}

		.content-button {
      width: 100%;
			flex: 1;
			height: vw(80);
			background: #347FFF;
			border-radius: vw(10);
			font-size: vw(30);
			font-weight: 600;
			color: #FFFFFF;
			line-height: vw(80);
			border: vw(2) solid #347FFF;
			box-sizing: border-box;
      text-align: center;
		}

		 .right-button {
		 	// width: vw(214);
		 	width: 100%;
		 	height: vw(80);
      background: linear-gradient(319deg, #3888F8 0%, #86C6FF 100%);
		 	border-radius: vw(10);
		 	font-size: vw(28);
		 	font-weight: bold;
		 	color: #FFFFFF;
		 	line-height: vw(80);
       text-align: center;
		 }
	}

    .button-bottom-box-mini {
		height: vw(157);
    }
	.button-bottom-box1 {
		width: 100%;
		height: vw(127);
		line-height: vw(127);
		padding: vw(23) vw(25);
		box-sizing: border-box;
		background: #fff;
		display: flex;
		justify-content: space-between;
		text-align: center;
		box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);


		.left-button {
			font-size: vw(34);
			font-weight: 600;
			color: #FF6900;
			height: vw(82);
			line-height: vw(82);
			display: flex;
			justify-content: center;
			width: vw(300);

			.number {
				font-size: vw(34);
			}
		}


		.right-button {
			width: vw(449);
			height: vw(80);
			background: #FF6900;
			border-radius: vw(10);
			font-size: vw(30);
			font-weight: 600;
			color: #FFFFFF;
			line-height: vw(80);
		}
	}

  .footer-wrap{
    padding: vw(46) vw(25);
    .img-wrap{
      position: relative;
      margin-bottom: vw(43);
      .vip{
        position: absolute;
        left: vw(34);
        bottom: vw(31);
        width: vw(214);
        height: vw(60);
        background: linear-gradient(125deg, #FFE8A1 0%, #FEC74B 100%);
        border-radius: vw(8);
        font-size: vw(28);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #8B392A;
        line-height: vw(40);
      }
      .unclock{
        position: absolute;
        left: vw(34);
        bottom: vw(31);
        width: vw(214);
        height: vw(60);
        background: linear-gradient(135deg, #FF9177 0%, #F56A49 100%);
        border-radius: vw(8);
        font-size: vw(28);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: vw(40);
        border: none;
      }
      img{
        width: 100%;
      }
    }
  }

.grey{
  .right-box{
    color: #aaa !important;
  }
}
::v-deep .titleHeader {
  padding: 0 20vw !important;
  .title{
    padding-right: vw(25);
  }
}
</style>
