<template>
    <div class="feedback">
        <StateBar />
        <TitleHeader title="帮助与反馈" bgcolor="transparent"></TitleHeader>

        <AutoView :footer="false" class="feedback--view">
            <div class="l-panel s-radius__s">
                <label require class="s-label">帮助与反馈</label>
                <van-field v-model="message" class="s-text s-radius__s f-vmt"
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入您所需的帮助或反馈意见"
                    show-word-limit
                    :disabled="submitting" />

              <van-button type="info" block :disabled="disabled" :loading="submitting" to="feedback" class="s-radius__s f-vmt" @click="submit">提交</van-button>
            </div>

          <!-- 容器 -->
          <div class="wrap">
            <div class="cell">
              <div class="cell-bd label nomargin">
                上上参谋满意度调研回访
              </div>
              <div class="cell-ft">
                <div class="ball" @click="openLink">点击<br />查看</div>
              </div>
            </div>
          </div>

            <div class="kf"></div>
        </AutoView>

        <!-- <div class="footer">
            <p>客服电话：<a href="tel:400-698-8881">400-698-8881</a></p>
            <SafeHolder />
        </div> -->
    </div>
</template>

<script>
import SafeHolder from '@/components/layout/safeHolder';

export default {
    name      : 'feedback',
    components: {
        SafeHolder,
    },
    data () {
        return {
            message   : '',
            submitting: false, // 提交中
            disabled  : true,
        };
    },
    watch: {
        message ( val ) {
            this.disabled = ! val.trim().length;
        },
    },
    methods: {
      submit () {
            if ( this.submitting ) return;
            this.submitting = true;
            this.$toast( '正在提交...' );
            this.HTTP.createFeedback( this.message ).then( ( res ) => {
              this.$toast( '已收到您的问题，我们会尽快处理！' );
              setTimeout( () => {
                this.message    = '';
                this.submitting = false;
              }, 2000 );
                // this.$router.push( '/success?view=feedback' );
                // 埋点上报
                // this.JSBridge.report( this.JSBridge.REPORT_EVENT.feedback.TAP_SUBMIT_SUC );
            } ).catch( ( e ) => {
                // this.$toast( '网络不佳T.T请稍候重试' );
                setTimeout( () => { this.submitting = false; }, 2000 );
                // 埋点上报
                // this.JSBridge.report( this.JSBridge.REPORT_EVENT.feedback.TAP_SUBMIT_FAL );
            } );
        },
      openLink(){
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 6,
          url: '/h5/#/product-survey'
        });
      }
    },
    mounted () {
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        this.JSBridge.callHandler( 'switchBackBtn', true );
        this.JSBridge.callHandler( 'switchNavbar', false );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';


.wrap{
  padding: vw(30);
  background: #fff;
  border-radius: vw(10);
  margin-top: vw(30);
  margin-bottom: vw(30);
  .label{
    font-size: vw(32);
    color: #333;
  }
  textarea{
    width: 100%;
    height: vw(400);
    font-size: vw(28);
    border: 1px solid #eee;
    border-radius: vw(10);
    padding: vw(20);
    margin-bottom: vw(20);
  }
  .btn{
    width: 100%;
    height: vw(80);
    line-height: vw(80);
    text-align: center;
    background: rgb(0, 136, 255);
    color: #fff;
    border-radius: vw(10);
    font-size: vw(32);
  }
}
.ball{
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  text-align: center;
  background: #fff;
  color: rgb(0, 136, 255);
  border-radius: 50%;
  line-height: 18px;
  padding-top: 6px;
  font-size: 14px;
  border: 1px solid rgb(0, 136, 255);
}

.feedback {
    height: 100vh;
  .s-label{
    font-size: vw(28);
  }
  .kf {
    width: 100%;
    height: 37.333vw;
    background: url("/static/discovery/qrcode.png") no-repeat;
    background-size: cover;
    margin-top: 4.267vw;
  }

    #{&}--view {
        padding: 4vw;
        background-color: $c-pageBg2;
        .l-panel {
            padding: 4vw;
        }
        .s-text ::v-deep .van-field__control {
            height: 31.47vw;
            font-size: $font_427;
            line-height: 6vw;
            &::-webkit-input-placeholder {
                font-size: $font_427;
                line-height: 6vw;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        p {
            @include flex;
            justify-content: center;
            font-size: $font_320;
            line-height: $font_440;
            padding-bottom: $padding_4;
            color: #AEB1BD;
        }
        a {
            color: #AEB1BD;
        }
    }
}
</style>
