<template>
  <div class="coupon-container">
        <StateBar ref="stateBar"></StateBar>
        <TitleHeader title="收藏" bgcolor="transparent" color="black" :back="true"></TitleHeader>
        <AutoView :header="true" :footer="false" class="coupon--view">
            <van-skeleton :loading="cellectionLoading" title :animate="false" :row="3" avatar class="s-skeleton s-radius__s">
                <div class="cell_swrap">
                    <div class="cell_item">
                        <EmptyCard v-if="list.length <= 0 && !isFlag" type="community"></EmptyCard>
                        <template v-else>
                            <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多了"
                                :immediate-check="false"
                                @load="loadData">
                                <div class="cell_list" v-for="(val, index) in list" :key="index"
                                    :class="`${typeClass == index ? 'bgColor' : ''}${(index+1) == list.length ? 'lastBorder' : ''}`"
                                    @touchstart="gtouchstart(val.id, index)"
                                    @touchend="cleartime()"
                                    @click.prevent="openDetail(val.refId)">
                                    <div class="cell_img cell_img_video" v-if="val.type == 3">
                                        <van-image class="item_list_img" lazy-load :src="val.image" />
                                        <van-image class="video_icon" lazy-load src="/static/icon/video_icon.png" />
                                    </div>
                                    <div class="cell_img cell_img_img" v-else>
                                        <van-image class="item_list_img" lazy-load :src="val.image" />
                                    </div>
                                    <div class="text_item">
                                        <h3 class="cell_text">{{val.refName}}</h3>
                                        <p>{{ val.gmtCreate | formDataYear }}</p>
                                    </div>
                                </div>
                            </van-list>
                        </template>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>
  </div>
</template>
<script>
    import { Dialog } from 'vant';
    export default {
        data() {
            return {
                cellectionLoading: true,
                loading: true,
                finished: false,
                params: {
                    current: 1,
                    size: 10,
                },
                list: [],
                total: 0,
                isFlag: false,
                typeClass: null,
            }
        },
        filters: {
            formDataYear(year) {
                return year.substring(0, 10);
            }
        },
        methods: {
            gtouchstart(id, index) {
                let _this = this;
                clearInterval(this.Loop); //再次清空定时器，防止重复注册定时器
                this.Loop = setTimeout(function() {
                    _this.typeClass = index;
                    Dialog.confirm({
                        title: '提示',
                        message: '确定要删除这条收藏记录?',
                    }).then(() => {
                        _this.HTTP.collectionDel(id).then(res => {
                            _this.list.splice(index, 1);
                            _this.typeClass = null;
                            _this.$toast( '删除成功!' );
                        }).catch((e) => {
                            _this.typeClass = null;
                            this.$toast("网络连接不稳定，请稍候重试！");
                        });
                    })
                    .catch(() => {
                        _this.typeClass = null;
                    });
                }.bind(this), 1000);
            },
            cleartime() {
                // 这个方法主要是用来将每次手指移出之后将计时器清零
                clearInterval(this.Loop);
            },

            loadData() {
                this.loading = true;
                this.isFlag = true;
                this.HTTP.collectionAll(this.params).then( res => {
                    this.loading = false;
                    this.isFlag = false;
                    this.cellectionLoading = false;
                    this.params.current++;
                    this.total = res.data.total;
                    if(this.list.length) {
                        this.list = this.list.concat(res.data.data);
                    } else {
                        this.list = res.data.data;
                    }
                    if (res.data.data.length < res.data.size || this.list.length >= this.total) {
                        this.finished = true; // 完成加载
                    }
                    console.log(res)
                }).catch((e) => {
                    this.$toast("网络连接不稳定，请稍候重试！");
                    this.loading = false;
                });
            },
            // 详情
            openDetail(refId) {
                this.swRouter( '/h5/#/community/article/'+refId );
            },
        },
        mounted() {
            this.loadData();
        }
    }
</script>
<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .coupon-container {
        height: 100vh;
        .coupon--view {
            position: relative;
            background: #F7F7F7;
            width: 100%;
            .cell_swrap{
                padding: 4vw;
                width: 100% ;
                height: auto;
                box-sizing: border-box;
                .cell_item{
                    width: 100%;
                    // background: #fff;
                    border-radius: 1.33vw;
                    box-sizing: border-box;
                    .cell_list{
                        background: #fff;
                        padding: 3.33vw 4vw;
                        // border-bottom: 1px solid #E7E7E7;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        &::after{
                            position: absolute;
                            content: " ";
                            width: calc(100% - 8vw);
                            height: 1px;
                            left: 4vw;
                            right: 4vw;
                            top: 100%;
                            background: #E7E7E7;
                            z-index: 2;
                        }
                        &:last-child::after{
                            width: 0px;
                        }
                        .cell_img{
                            margin-right: 3.33vw;
                            position: relative;
                            border-radius: 1.06vw;
                            overflow: hidden;
                            background: #F7F7F7;
                            .item_list_img{
                                width: 100%;height: 100%;border-radius: 1.06vw;overflow: hidden;
                                ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                                    width: 100%;height: 100%;
                                    object-fit: cover;
                                    // object-fit: scale-down;
                                }
                            }
                            .video_icon{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                z-index: 2;
                                transform: translate(-50%, -50%);
                                width: 6.67vw;
                                height: 6.67vw;
                                ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                                    width: 100%;height: 100%;
                                    // object-fit: scale-down;
                                }
                            }
                        }
                        .cell_img_video{
                            width: 33.06vw;
                            height: 18.67vw;
                        }
                        .cell_img_img{
                            height: 20vw;
                            width: 29.6vw;
                        }
                        .text_item{
                            flex: 1;
                            .cell_text{
                                font-size: 3.73vw;
                                font-weight: bold;
                                color: #333333;
                                line-height: 5.33vw;
                                height: 10.67vw;
                                word-break: break-all;text-overflow: ellipsis; display: -webkit-box;
                                -webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;
                            }
                            p{
                                margin-top: 2.93vw;
                                font-size: 3.47vw;
                                color: #82848E;
                                line-height: 5vw;
                            }
                        }
                        &:first-child{ border-top-left-radius: 1.33vw;border-top-right-radius: 1.33vw; }
                    }
                    .lastBorder {
                        border-bottom-left-radius: 1.33vw;border-bottom-right-radius: 1.33vw;
                        &::after{
                            width: 0;
                        }
                    }
                    .bgColor{
                        background: #E7E7E7;
                        &::after{
                            width: 0;
                        }
                    }
                }
            }
        }
    }
</style>
