
import AutoView from '@/components/layout/autoView';
import ButtonFooter from '@/components/footer/button';
import EmptyCard from '@/components/card/empty';
import Loading from '@/components/layout/loading';
import StackView from '@/components/layout/stackView';
import StateBar from '@/components/header/state';
import SoltFooter from '@/components/footer/main';
import TitleHeader from '@/components/header/main';
import newTitleHeader from '@/components/header/newIndex';
const Loader = {
    install: function ( Vue ) {
        Vue.component( 'AutoView', AutoView );
        Vue.component( 'ButtonFooter', ButtonFooter );
        Vue.component( 'EmptyCard', EmptyCard );
        Vue.component( 'LoadingOverlay', Loading );
        Vue.component( 'StackView', StackView );
        Vue.component( 'StateBar', StateBar );
        Vue.component( 'SoltFooter', SoltFooter );
        Vue.component( 'TitleHeader', TitleHeader );
        Vue.component( 'newTitleHeader', newTitleHeader );
    }
};

export default Loader;
