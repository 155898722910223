<!--
Name:
    星标评分条
Props:
    max     : 最大星数 默认:5
    score   : (必填)当前分数 正值0.5递增
    suffix  : 后缀文本 默认:分
Events:
    无
-->
<template>
    <div class="rate f-clear">
        <div class="rate--bar f-fl">
            <i v-for="( n, index ) of max" :key="index" class="icon icon-star" :class="{
                half: n - 0.5 == score,
                full: n <= score,
            }" />
        </div>
        <p class="rate--score f-fl">{{ score || 0 }}{{ suffix }}</p>
    </div>
</template>

<script>
export default {
    name : 'rate',
    props: {
        max: {
            type   : Number,
            default: 5,
        },
        score : Number,
        suffix: {
            type   : String,
            default: '分',
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$compHeight: 4vw;

.rate {
    width: 100%;
    height: 100%;

    #{&}--bar {
        @include flex;
        height: $compHeight;

        .icon {
            position: relative;
            width: $compHeight;
            height: $compHeight;
            background: url(/static/icon/star@64.png) no-repeat left top;
            background-size: cover;
            margin-right: .5vw;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                width: 0%;
                height: 100%;
                background: url(/static/icon/star@64.png) no-repeat left bottom;
                background-size: $compHeight auto;
            }
            &.full:after {
                width: 100%;
            }
            &.half:after {
                width: 50%;
            }
        }
    }

    #{&}--score {
        margin-left: 1vw;
        font-size: $font_320;
        line-height: $font_400;
        color: $c-notice;
    }
}
</style>
