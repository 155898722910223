import { Axios } from './http'
let host
if (process.env.NODE_ENV === "production") {
    host = "//appv2.sscanmou.com/app";
} else {
    host = "https://huawei-test-appv2.sscanmou.com/app";
}

const getOSSKeyId = () => {
    return new Promise((resolve, reject) => {
        Axios.get(`${host}/v1/upload/policy`)
        .then(res => {
            resolve(res)
        })
        .catch(err => {
            reject(err)
        })
    })
}

let signature,expire;
const TIME_GAP = 180000; // 签名三分钟过期
async function getSignature(){
    if( ! signature || new Date().getTime() - expire > TIME_GAP ){ // 过期则重新请求
        const { data:{ data } } = await getOSSKeyId();
        // const data = await getOSSKeyId();
        console.log(data, "getSignature")
        expire = new Date().getTime();
        signature = data;
        return data;
    }else{
        return signature;
    }
}

export async function uploadImgToAliyun( file ) {
    const fileObj = file.file;
    if( ! fileObj )return;
    // 获取Img服务器信息
    // 要根据你们后台的返回数据进行拼接 以下是我项目中的
    //后台接口getUploadToken() 接口返回数据  图片展示进行 host + '/' + fileName
    //accessid: "LTAIDQhPlHf4UQLs"
    //dir: "merchant/gl_24_ads/"
    //expire: "1586504494"
    //host: "https://gl365dev.oss-cn-shenzhen.aliyuncs.com"
    //policy: "eyJleHBpcmF0aW9uIjoiMjAyMC0wNC0xMFQwNzo0MTozNC41MjVaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJtZXJjaGFudC9nbF8yNF9hZHMvIl1dfQ=="
    //signature: "UdJ1I2ZsnvgNq9KcOszAUg69xE0="
    // return getSignature().then( ( data ) => {
    //     console.log(data, "data")
    //     const dateMs = new Date().getTime();
    //     const formData = new FormData();
    //     const host = data.host;
    //     const extIndex = fileObj.name.lastIndexOf( '.' );
    //     const ext = fileObj.name.slice( extIndex );
    //     const fileName = `${data.dir}${dateMs}${ext}`;
    //     formData.append( 'OSSAccessKeyId', data.accessKeyId );// Bucket 拥有者的Access Key Id。
    //     formData.append( 'policy', data.policy );// policy规定了请求的表单域的合法性
    //     formData.append( 'Signature', data.signature );// 根据Access Key Secret和policy计算的签名信息，OSS验证该签名信息从而验证该Post请求的合法性
    //     formData.append( 'key', fileName );// 文件名字，可设置路径
    //     formData.append( 'success_action_status', '200' );// 让服务端返回200,不然，默认会返回204
    //     formData.append( 'file', fileObj );// 需要上传的文件 file
    //     console.log(host, formData, "formData")
    //     return Axios.request( {
    //         method: 'post',
    //         url   : host,
    //         data  : formData
    //     } ).then( ( res ) => {
    //         // 返回图片地址
    //         return `${data.host}/${fileName}`;
    //     } ).catch( (err) => {
    //         console.log(err)
    //         console.error('图片上传失败')
    //     } );
    // } ).catch( ( res ) => {
    //     console.error('获取图片上传签名失败')
    // } );
    const signature = await getSignature()
    if(!signature) {
        console.error('获取图片上传签名失败')
        return
    }
    const dateMs = new Date().getTime();
    const formData = new FormData();
    const host = signature.host;
    const extIndex = fileObj.name.lastIndexOf( '.' );
    const ext = fileObj.name.slice( extIndex );
    const fileName = `${signature.dir}${dateMs}${ext}`;
    formData.append( 'OSSAccessKeyId', signature.accessKeyId );// Bucket 拥有者的Access Key Id。
    formData.append( 'policy', signature.policy );// policy规定了请求的表单域的合法性
    formData.append( 'Signature', signature.signature );// 根据Access Key Secret和policy计算的签名信息，OSS验证该签名信息从而验证该Post请求的合法性
    formData.append( 'key', fileName );// 文件名字，可设置路径
    formData.append( 'success_action_status', '200' );// 让服务端返回200,不然，默认会返回204
    formData.append( 'file', fileObj );// 需要上传的文件 file
    return new Promise((resolve, reject) => {
        console.log(host, formData, "req")
        Axios.post(host, formData)
        .then(res => {
            resolve(`${signature.host}/${fileName}`)
        })
        .catch(err => {
            console.error('图片上传失败')
            reject(err)
        })
    })
}