<template>
<div class="hot-project">
    <!-- 优势 -->
    <detailPro 
        :content="tableData.joinAdvantage"
        :titlePro="planProName" />
        
    <div class="card-box" v-if="tableData.brandService" :class="planTwoMore == 'down' ? 'paddHeight':'paddHeightUp' ">
        <div class="head-title">{{titlePlan}}的服务</div>
        <div class="brand_detail" >
            <div class="plan_content" :class="planTwoMore == 'down' ? '':'moreHeight' ">
                <div class="plan_list">
                    <template v-for="(val, index) in brandServices">
                        <div class="plan_title" :key="'plan1' + index">{{val.title}}</div>
                        <div class="plan_box" :key="'plan2' + index">
                            <ul class="clearfix">
                                <li class="clearfix" v-for="(item, key) in val.list" :key="key">
                                    <img :src="item.check ? '/static/icon/err_plan.png' : '/static/icon/succ_plan.png'" alt="">
                                    <span>{{item.code}}</span>
                                </li>
                            </ul>
                        </div>
                    </template>
                </div>
                <div :class="planTwoMore == 'down' ? 'plan_bg':''" ></div>
                <!-- 查看更多 -->
                <div :class="planTwoMore == 'down' ? 'more_btn':'up_btn'"  @click="planMore()">
                    <template v-if="planTwoMore == 'down'">
                        <span>查看更多</span>
                        <img src="/static/icon/new_brand_down.png" alt="">
                    </template>
                    <template v-else>
                        <span>收起</span>
                        <img src="/static/icon/plan_up.png" alt="">
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="card-box" 
        v-if="(tableData.costList && tableData.costList.length > 0) || tableData.costEstimateImage || tableData.profitEstimateImage">
        <div class="head-title">投资预算</div>

        <template v-if="tableData.costEstimateImage && tableData.profitEstimateImage">
            <p class="head-subTitle">费用预估</p>
            <img class="const_img" :src="tableData.costEstimateImage" alt="">
            <p class="head-subTitle">盈利预估（受地域影响，仅供参考）</p>
            <img class="const_img" :src="tableData.profitEstimateImage" alt="">
        </template>

        <template v-else-if="tableData.costEstimateImage && tableData.profitEstimateImage == null">
            <p class="head-subTitle">费用预估</p>
            <img class="const_img" :src="tableData.costEstimateImage" alt="">
            <p class="head-subTitle">{{tableData.costList[1].name}}</p>
            <brand-table :dataList="tableData.costList[1].data"></brand-table>
            <p class="tip">注：以上数据均为预估数值， 仅供参考</p>
        </template>

        <template v-else-if="tableData.costEstimateImage == null && tableData.profitEstimateImage">
            <p class="head-subTitle">{{tableData.costList[0].name}}</p>
            <brand-table :dataList="tableData.costList[0].data"></brand-table>
            <p class="head-subTitle">盈利预估（受地域影响，仅供参考）</p>
            <img class="const_img" :src="tableData.profitEstimateImage" alt="">
        </template>

        <template v-else>
            <div v-for="(val, index) in tableData.costList" :key="index">
                <p class="head-subTitle">{{val.name}}</p>
                <brand-table :dataList="val.data"></brand-table>
            </div>
            <p class="tip">注：以上数据均为预估数值， 仅供参考</p>
        </template>
    </div>

</div>

</template>


<script>
    import detailPro from './detail_pro'
    import brandTable from './brandTable';
    export default {
        name: "plan",
        components: {
            brandTable,detailPro
        },
        props: {
            tableData: {
                type: Object
            },
            titlePlan: {
                type: String
            }
        },
        data() {
            return {
                brandServices: [
                    { 
                        title: "运营督导", 
                        list: [
                            { code: "人员管理",check: true },
                            { code: "产品销售",check: true },
                            { code: "促销方案",check: true },
                            { code: "成本管控",check: true },
                            { code: "人员聘用",check: true },
                            { code: "标准检查",check: true },
                            { code: "巡店考核",check: true },
                            { code: "突发事件",check: true }
                        ] 
                    },{
                        title: "培训支持", 
                        list: [
                            { code: "产品技术培训",check: true },
                            { code: "设备操作培训",check: true },
                            { code: "财务系统培训",check: true },
                            { code: "人员聘用培训",check: true },
                            { code: "员工培训指导",check: true },
                            { code: "服务礼仪培训",check: true },
                            { code: "营销推广培训",check: true },
                            { code: "经营管理培训",check: true },
                            { code: "销售知识培训",check: true },
                            { code: "外卖运营培训",check: true },
                            { code: "管理者培训",check: true },
                            { code: "试营业培训",check: true }
                        ]
                    },{
                        title: "供应链", 
                        list: [
                            { code: "自有供应链",check: true }, 
                            { code: "第三方供应链",check: true }
                        ]
                    },{
                        title: "供应链", 
                        list: [
                            { code: "实地考察选址",check: true }, 
                            { code: "实地考察评估",check: true }, 
                            { code: "远程选址评估",check: true }
                        ]
                    },{
                        title: "经营支持", 
                        list: [
                            { code: "标准运营手册",check: true },
                            { code: "区域保护支持",check: true },
                            { code: "开业带店支持",check: true },
                            { code: "定期督导巡店",check: true },
                            { code: "外卖运营托管",check: true },
                            { code: "售后服务支持",check: true },
                            { code: "活动策划支持",check: true },
                            { code: "经营淡季扶持",check: true },
                            { code: "品牌推广支持",check: true },
                            { code: "采购方案支持",check: true },
                            { code: "物流运输支持",check: true },
                            { code: "市场调研评估",check: true }
                        ]
                    },
                    {
                        title: "营建装修", 
                        list: [
                            { code: "量身定制装修",check: true }, 
                            { code: "总部统一装修",check: true }, 
                            { code: "门头统一装修",check: true }, 
                            { code: "远程装修指导",check: true }
                        ]
                    },{
                        title: "产品更新", 
                        list: [
                           { code: "5次以上/年",check: true }
                        ]
                    },{
                        title: "物料支持", 
                        list: [
                           { code: "全部物料支持",check: true }
                        ]
                    }
                ],
                planTwoMore: 'down',
                planProName: this.titlePlan+"的优势"
            }
        },
        methods: {
            // 
            planMore() {
                if(this.planTwoMore == 'down') {
                    this.planTwoMore = 'up';
                } else {
                    this.planTwoMore = 'down';
                }
            }
        },
        mounted() {
            if(this.tableData.brandService) {
                let newArray  = this.tableData.brandService.split(",");
                for(let i = 0; i < this.brandServices.length; i++) {
                    for(let j = 0; j < this.brandServices[i].list.length ; j++) {
                       for(let z = 0; z < newArray.length ; z++) {
                           if(this.brandServices[i].list[j].code == newArray[z]){
                               this.brandServices[i].list[j].check = false;
                           }
                       }
                    }
                }
            }
        }
    }

</script>


<style lang="scss" scoped>
@import "@/styles/index.scss";
.hot-project{
    width: 100%;
    height: auto;
    .card-box{
        padding: 4.53vw 4vw;
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 2.67vw;
        .head-title{
            font-size: 4.53vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 6.4vw;
        }
        // 优势
        .brand_detail{
            margin-top: 2.67vw;
            text-align: center;
            .brand_detail_box{
                width: 100%;
                // height: 46vw;
                height: auto;
                padding: 3.33vw 3.33vw 0;
                box-sizing: border-box;
                border-radius: 1.87vw;
                // background: linear-gradient(to top right, #fff , #F7F7F7 );
                background: linear-gradient(#F7F7F7, #fff);
                div{  
                    width: 100%;
                    font-size: 3.73vw;
                    color: #333333;
                    line-height: 5.33vw;
                    text-align: left;
                }
                .axios_content{
                    transition: all .2s;
                    >>> img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .suleaHeight{
                    height: 41.33vw;
                    overflow:hidden; 
                }
            }
            .click_btn{
                padding: 2.27vw 2.93vw;
                margin: 1.46vw auto 0;
                display: inline-block;
                background: rgba(0, 0, 0, 0.53);
                border-radius: 1.33vw;
                line-height: normal;
                font-size: 0;
                span{
                    font-size: 3.73vw;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: normal;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 1.07vw;
                }
                img{
                    width: 2.93vw;
                    height: 1.47vw;
                    display: inline-block;
                    vertical-align: middle;
                }
                .tran{
                    transform: rotate(180deg);
                }
            }
            .plan_content{
                @include aniShop;
                width: 100%;
                height: 69.07vw;
                background: #FFFFFF;
                border-radius: 1.33vw;
                border-radius: 1.33vw;
                border: 1px solid #DEDEE7;
                padding: 3.33vw 3.33vw;
                box-sizing: border-box;
                text-align: left;
                position: relative;
                // transition: all .2s;
                text-align: center;
                .plan_list{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    .plan_title{
                        margin-top: 3.33vw;
                        font-size: 3.73vw;
                        font-weight: bold;
                        color: #000000;
                        line-height: 5.33vw;
                        text-align: left;
                        &:first-child{
                            margin-top: 0;
                        }
                    }
                    .plan_box{
                        width: 100%;
                        ul{
                            width: 100%;
                            margin: 0;
                            li{
                                margin-top: 2.4vw;
                                width: 33.33%;
                                float: left;
                                img{
                                    width: 3.2vw;
                                    height: 2.93vw;
                                    margin-right: 0.8vw;
                                    float: left;
                                    position: relative;
                                    top: 0.53vw;
                                }
                                span{
                                    font-size: 2.93vw;
                                    color: #666666;
                                    float: left;
                                    line-height: 4vw;
                                }
                            }
                        }
                    }
                }
                .plan_bg{
                    position: absolute;
                    width: calc(100% + 8vw);
                    bottom: -1px;
                    left: -4vw;
                    height: 15.33vw;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
                    border-radius: 1.33vw;
                    z-index: 1;
                }
                .more_btn{
                    padding: 2.27vw 2.93vw;
                    margin: 1.46vw auto 0;
                    display: inline-block;
                    background: rgba(0, 0, 0, 0.53);
                    border-radius: 1.33vw;
                    line-height: normal;
                    font-size: 0;
                    position: absolute;
                    bottom: -4.4vw;
                    left: 50%;
                    z-index: 1;
                    transform: translateX(-50%);
                    span{
                        font-size: 3.73vw;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: normal;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 1.07vw;
                    }
                    img{
                        width: 2.93vw;
                        height: 1.47vw;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .tran{
                        transform: rotate(180deg);
                    }
                }
                .up_btn{
                    display: inline-block;
                    border-radius: 1.33vw;
                    line-height: normal;
                    font-size: 0;
                    padding: 6vw 0 0;
                    margin: auto;
                    span{
                        font-size: 3.73vw;
                        font-weight: bold;
                        color: #999999;
                        line-height: normal;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 1.07vw;
                    }
                    img{
                        width: 2.93vw;
                        height: 1.47vw;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
            .moreHeight{
                height: 186.13vw;
            }
        }
        // 投资预算
        .head-subTitle{
            margin-top: 2.67vw;
            font-size: 3.47vw;
            color: #000000;
            line-height: 4.93vw;
        }
        .const_img{
            margin-top: 2.67vw;
            width: 100%;
        }
        .tip{
            font-size: 2.93vw;
            color: #999999;
            line-height: 4vw;
        }
    }
    .paddHeight{
        padding-bottom: 8.93vw;
    }
    .paddHeightUp{
        padding-bottom: 10vw;
    }
}
.clearfix:after {    
    visibility: hidden;    
    display: block;    
    font-size: 0;    
    content: " ";    
    clear: both;    
    height: 0;    
}    
.clearfix { display: inline-table; }  

</style>