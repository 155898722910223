var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signin"},[_c('StateBar'),_c('TitleHeader',{attrs:{"title":_vm.title,"bgcolor":"transparent","color":"black","back":true}}),_c('van-skeleton',{staticClass:"s-skeleton",attrs:{"loading":_vm.loading,"title":"","animate":false,"row":5}}),_c('AutoView',{ref:"view",staticClass:"signin--view",style:({ opacity: _vm.loading ? 0 : 1 }),attrs:{"header":true,"footer":false}},[_c('div',{staticClass:"signin--main"},[_c('van-image',{staticClass:"banner min-h",attrs:{"fit":"contain","src":_vm.bannerImages}}),_c('div',{staticClass:"main-warp"},[_c('div',{staticClass:"content-warp"},_vm._l((_vm.activeItemResList),function(item,index){return _c('ul',{key:item.couponId,staticClass:"coupon-card",style:({
              backgroundImage: 'url(' + (item.receiveStatus ? '/static/icon/coupon_bg_has.png' : '/static/icon/coupon_bg.png') + ')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              marginBottom: index == _vm.activeItemResList.length - 1 ? '0' : '4vw',
            }),on:{"click":function($event){return _vm.getCoupon(item)}}},[_c('li',{staticClass:"li-top"},[_c('i',[_vm._v(_vm._s(item.couponName))])]),_c('li',{staticClass:"li-bottom"},[_vm._v(_vm._s(item.remark))])])}),0)]),(_vm.bottomBanner && _vm.bottomBanner.length > 0)?_vm._l((_vm.bottomBanner),function(val,index){return _c('van-image',{key:index,staticClass:"banner",attrs:{"fit":"contain","src":val}})}):_vm._e()],2)]),_c('van-popup',{attrs:{"round":"","closeable":""},model:{value:(_vm.couponPopup),callback:function ($$v) {_vm.couponPopup=$$v},expression:"couponPopup"}},[_c('div',{staticClass:"signin-popup-wrap"},[_c('div',{staticClass:"type-noprize"},[_c('img',{attrs:{"src":"/static/img/signin/signin-checked.png","alt":""}}),_c('p',{staticClass:"title-noprize"},[_vm._v("领取成功")])]),_c('div',{staticClass:"uesr-btn",on:{"click":_vm.goHome}},[_vm._v("去使用")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }