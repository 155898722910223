import JSBridge from './jsbridge'
import UniUtils from '@/utils/uni'
const pageBack = function () {
  if (window.isClient) {
    JSBridge.callHandler("onBackPressed");
  } else if (window.isMini) {
    UniUtils.miniPageBack()
  } else {
    window.history.go(-1)
  }
}

export default {
  pageBack
}
