<template>
    <div class="national">
        <StateBar />
        <TitleHeader title="国庆大促" bgcolor="transparent" :back="true"></TitleHeader>

        <!-- 骨架屏占位图 -->
        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <StackView ref="view" class="national--view" :style="{ opacity: loading ? 0 : 1 }">
            <div class="title-wrap">
                <p class="explain-fot">活动时间：10月1日 - 10月7日</p>
            </div>
            <div class="content-wrap">
                <div class="loop-wrap" v-for="(item, index) in picList" :key="item.key">
                    <van-image class="pic-img" fit="contain" :src="item" />
                    <div class="btn-wrap" @click="tapRob(index)">去抢购<i class="i"></i></div>
                </div>
                <div class="tips-wrap">
                    <h4 class="h4-title">赠品发放说明：</h4>
                    <p class="p-tips">
                        1、活动期间下单：开店选址评估（5次）、开店位置推荐（5次）、数据查询会员6个月，即赠品为选址产品的订单，赠品将于7个工作日内发放兑换券到下单账户，可在
                        我的-优惠券 中兑换使用；
                    </p>
                    <p class="p-tips">
                        2、活动期间下单：数据查询会员1个月、数据查询会员3个月，即赠品为会员产品的订单，将于7个工作日内为下单账户延长会员权益时长。
                    </p>
                    <p class="p-tips">*本活动最终解释权归上上参谋运营方所有。</p>
                </div>
            </div>
        </StackView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            picList: [
                "/static/img/10_01/pica.png",
                "/static/img/10_01/picb.png",
                "/static/img/10_01/picc.png",
                "/static/img/10_01/picd.png",
                "/static/img/10_01/pice.png",
            ],
        };
    },
    mounted() {
        let _this = this;
        let timer = setInterval(function () {
            if (document.readyState === "complete") {
                //执行方法
                _this.fn();
                window.clearInterval(timer);
            }
        }, 500);
    },
    methods: {
        fn() {
            this.loading = false;
        },
        tapRob(idx) {
            switch (idx) {
                case 0:
                    this.swRouter("/h5/#/member");
                    break;
                case 1:
                    this.swRouter("/h5/#/member");
                    break;
                case 2:
                    this.JSBridge.callHandler("onNormalizingCall", {
                        type: 5,
                        url: "BRAND_INTRODUCTION_V2",
                        id: "10001",
                        isClear: null,
                        params: null,
                        trackData: null,
                    });
                    break;
                case 3:
                    this.JSBridge.callHandler("onNormalizingCall", {
                        type: 5,
                        url: "BRAND_INTRODUCTION_V2",
                        id: "10026",
                        isClear: null,
                        params: null,
                        trackData: null,
                    });
                    break;
                case 4:
                    this.swRouter("/h5/#/member");
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.national {
    height: 100vh;
    overflow: hidden;

    #{&}--view {
        scroll-behavior: smooth;
        background: #d6353b;
        .cool-national {
            width: 100%;
            align-items: center;
        }
    }

    .title-wrap {
        position: relative;
        height: 102.4vw;
        background: url("/static/img/10_01/pic_bg.png") no-repeat;
        background-size: contain;
        .explain-fot {
            position: absolute;
            top: 3.2vw;
            right: 2.67vw;
            font-size: 3.47vw;
            color: #fff;
        }
    }
    .content-wrap {
        background: linear-gradient(180deg, #d6353b 0%, #b9161c 100%);
        .loop-wrap {
            position: relative;
            top: -21.33vw;
            text-align: center;
            .pic-img {
                width: 92vw;
            }
            .btn-wrap {
                position: relative;
                top: -4.27vw;
                width: 26.13vw;
                height: 8.53vw;
                line-height: 8.53vw;
                font-size: 4.53vw;
                font-weight: 800;
                color: #fff;
                letter-spacing: 0.53vw;
                text-shadow: 0px 1px 1px #a04600;
                background: linear-gradient(90deg, #ff9801 0%, #ff5900 100%);
                box-shadow: 0px 1px 2px 0px #ac5218, 0px 1px 3px 0px #ffb003;
                border-radius: 4.27vw;
                margin: 0 auto;
                .i {
                    display: inline-block;
                    width: 2.93vw;
                    height: 3.2vw;
                    background: url("/static/img/10_01/pic_arr.png") no-repeat;
                    background-size: contain;
                    margin-left: 1vw;
                }
            }
        }
        .tips-wrap {
            position: relative;
            top: -21.33vw;
            padding: 0 5.33vw 10vw 5.33vw;
            .h4-title {
                height: 5.33vw;
                line-height: 5.33vw;
                font-size: 3.47vw;
                font-weight: bold;
                color: #ffd26c;
            }
            .p-tips {
                font-size: 3.47vw;
                color: #ffd26c;
                line-height: 5.87vw;
                margin-bottom: 2.13vw;
            }
        }
    }
}
</style>
