<template>
    <div class="item" :class="{ noRadius: showVipBg }">
        <h3>{{vipTitle}}</h3>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name      : 'vipCard',
        props   : {
            vipTitle: {
                type: String,
            },
            showVipBg: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {

            }
        },
        methods: {

        }
    }
</script>
<style  lang="scss" scoped>
//@import "@/styles/index.scss";
.item{
    background: #fff;
    border-radius: 2.67vw;
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;
    padding: 4vw;
    height: auto;
    margin-bottom: 3.2vw;
    h3{
        font-size: 4vw;
        font-weight: bold;
        color: #333333;
        line-height: 5.6vw;
        text-align: center;
        background: url('/static/icon/vip_topBg.png') no-repeat center;
        background-size: 29.07vw 3.87vw;
    }
    &:last-child{
        margin-bottom: 0;
    }
}
.noRadius {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
</style>
