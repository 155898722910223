<template>
    <van-image class="service" fit="cover" :src="src" lazy-load />
</template>

<script>
export default {
    data() {
        return {
            src: "/static/img/holiday.png"
        }
    }
}
</script>

<style lang="scss" scoped>
.service {
    width: 100vw;
}
</style>