<template>
  <div class="item" @click="clickHandle">
    <van-swipe-cell :stop-propagation="false">
     <div class="wrap">
       <div class="info">
         <van-cell :stop-propagation="false">
           <!-- 使用 title 插槽来自定义标题 -->
           <template #title>
             <p class="title">{{item.shopName}}</p>
             <p class="subtitle">{{ item.secondText }}</p>
           </template>
           <template #right-icon  v-if="item && item.shopPhoto">
             <div class="avatar-wrap">
               <img :src="getMediaUrl(shopPhoto)" />
             </div>
           </template>
         </van-cell>
       </div>
     </div>
      <template #right>
        <div class="btn-wrap">
          <img src="/static/img/me/surveyedShops/btn-delete.png" @click="deleteHandler()" />
        </div>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
export default {
  name: 'shopItem',
  props: {
    item: Object,
  },
  computed: {
      shopPhoto() {
          return JSON.parse(this.item.shopPhoto) && JSON.parse(this.item.shopPhoto)[0]
      }
  },
  methods: {
    mediaType (url) {
      return url.split('.').pop() || 'img';
    },
    getMediaUrl (url) {
      if (this.mediaType(url) === 'mp4') {
        return url + '?x-oss-process=video/snapshot,t_1000,f_jpg,h_100,m_fast'
      }
      return  url + '?x-oss-process=image/resize,m_fill,h_100,w_100';
    },
    deleteHandler () {
      this.$emit('delete', this.$props.item.id);
    },
    clickHandle() {
      this.$emit('click', this.$props.item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

.item{
  display: block;
  width: vw(750);
  //margin: vw(24) vw(30);
  //background: #fff;
}

.wrap{
  padding: vw(12) vw(30);
  .van-cell{
    padding: vw(25);
    border-radius: vw(10);
  }
  .info{
    width: 100%;
    height: vw(142);
    background: #fff;
    border-radius: vw(10);
    .title{
      font-size: vw(30);
      font-weight: 500;
      color: #333333;
      line-height: vw(42);
    }
    .subtitle{
      font-size: vw(26);
      color: #666666;
      line-height: vw(37);
      margin-top: vw(14);
    }
    .avatar-wrap{
      width: vw(92);
      height: vw(92);
      overflow: hidden;
      img{
        width: vw(92);
      }
    }
  }
}
.btn-wrap{
  width: vw(126);
  padding-top: vw(58);
  img{
    display: inline-block;
    width: vw(50);
  }
}
.swiper{
  width: 100%;
  height: vw(142);
}
</style>
