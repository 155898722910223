export const defaultNeeds = [{
    code: 'catering',
    name: '可做餐饮',
    value: 0
  },
  {
    code: 'parking',
    name: '需要停车场',
    value: 0
  },
  {
    code: 'street',
    name: '需要沿街',
    value: 0
  },
  {
    code: 'commerce_at_the_bottom',
    name: '需要底商',
    value: 0
  },
  {
    code: 'naked_light',
    name: '需要明火',
    value: 0
  },
  {
    code: 'eject_smoke',
    name: '需要排烟',
    value: 0
  },
  {
    code: 'plumbing',
    name: '需要上下水',
    value: 0
  },
  {
    code: 'step',
    name: '门口无台阶',
    value: 0
  },
  {
    code: 'voltage',
    name: '380V电压',
    value: 0
  },
  {
    code: 'gate_width',
    name: '展面要求',
    value: 0
  },
  {
    code: 'decoration',
    name: '装修程度',
    value: 0
  },
  {
    code: 'certificate',
    name: '证件齐全',
    value: 0
  }
];

export const defaultDecoration = [{
    code: 'decoration',
    name: '无要求',
    value: '0',
  }, {
    code: 'decoration',
    name: '毛胚',
    value: '1',
  },
  {
    code: 'decoration',
    name: '简装',
    value: '2',
  },
  {
    code: 'decoration',
    name: '精装',
    value: '3',
  }
];
export const defaultGateWidth = [{
    code: 'gate_width',
    name: '无要求',
    value: '0',
  },
  {
    code: 'gate_width',
    name: '展面3.5米以上',
    value: '1',
  },
  {
    code: 'gate_width',
    name: '展面6米以上',
    value: '2',
  },
  {
    code: 'gate_width',
    name: '展面8米以上',
    value: '3',
  }
];
