<template>
  <div class="brand">
    <StateBar />
    <!-- <TitleHeader title="品牌商机" bgcolor="transparent" color="black" :back="true"></TitleHeader> -->
    <!-- 搜索框 -->
    <ul class="search-flex">
      <li class="left" @click="swRouter('/h5/#/brand/search')">
        <van-search v-model="value" disabled placeholder="搜索品牌" left-icon="/static/discovery/search.png" />
      </li>
      <li class="right" @click="onLivechat">
        <img src="/static/icon/service_icon_new.png" alt="" />
        <div class="service-spot" v-if="showSpot"></div>
      </li>
    </ul>
    <div ref="content" style="height: 0"></div>
    <!-- 骨架屏占位图 -->
    <van-skeleton :loading="loading" :animate="false" :row="4" class="s-skeleton" />

    <AutoView ref="view" id="view" :header="true" :footer="false" class="brand--view" :style="{ opacity: loading ? 0 : 1 }">
      <div class="brand--main">
        <div class="brand-main-header">
          <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" v-if="bannerList && bannerList.length">
            <van-swipe-item v-for="item in bannerList" :key="item.key">
              <img class="banner-img" :src="item.pictureUrl" alt="" @click="jump(item)" />
            </van-swipe-item>
          </van-swipe>
          <div class="icon-wrap">
            <van-row type="flex" justify="center">
              <van-col
                span="6"
                v-for="icon in columnList"
                :key="icon.name"
                @click="goOtherPage(icon)"
              >
                <img class="icon-big-img" :src="icon.icon" alt="" />
                <p class="title">{{ icon.name }}</p>
              </van-col>
            </van-row>
          </div>
<!--          平安运营金刚位-->
          <div class="pingan-box">
            <div @click="goPingAnPage('/zxb')"><img src="/static/discovery/zxb-bg.png" alt="" /></div>
            <div @click="goPingAnPage('/djb')"><img src="/static/discovery/djb-bg.png" alt="" /></div>
          </div>
<!--          精品课程-->
          <div class="course-view" v-if="courseList.length">
            <div class="course-view-header">
              <div class="course-view-header-left">精品课程</div>
             <div class="course-view-header-right">
               <img @click="goCourse" src="/static/discovery/kc.png" alt="" />
             </div>
            </div>
            <div class="course-view-list">
              <div class="course-item" v-for="(course,index) in courseList" :key="index" @click="onTap(course)">
                <div class="course-cover">
                  <van-image class="brand-icon" :src="course.cover" fit="cover" />
                </div>
                <div class="course-title">{{ course.bigTitle }}</div>
                <div class="course-info">
                  <div class="course-info-left">
                    <div class="free-box" v-if="course.price==0">限时免费</div>
                    <div class="course-price-box" v-if="course.price>0">
                      <div class="course-price">¥<span>{{ course.price/100 }}</span></div>
                      <div class="course-origin-price">{{ course.salePrice }}</div>
                    </div>
                  </div>
                  <div class="course-info-right" v-if="course.des">
                    <img class="hot-icon" src="/static/discovery/hot-icon.png" alt="" />
                    <span>{{ course.des }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
<!--          门店设计-->
          <div class="store-view" v-if="storeList.length">
            <div class="store-view-header">
              <div class="store-view-header-left">门店设计</div>
              <div class="store-view-header-right" @click="swRouter('/community/store')">
                全部
                <img class="arrow-icon" src="/static/discovery/arrow-right.png" alt="" />
              </div>
            </div>
            <div class="store-view-list">
              <storeItem v-for="(store, index) in storeList" :key="index" :store="store" />
            </div>
          </div>
        </div>
        <div class="tabs-wrap" ref="tabWrap">
          <van-tabs v-model="active" color="#347FFF" background="linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 100%)" swipeable sticky :offset-top="offsetTop" class="my-tabs" @change="scrollTop">
            <van-tab v-for="(item, index) in tabsList" :title="item" :key="index">
              <div class="tabs-main">
                <div v-if="active == 0" class="tab-list">
                  <brandHorizontalBox v-for="(brand, index) in hotBrandList" :key="index" :brandData="brand" @click.native="goBrandPage(brand.brandCode)" />
<!--                  <BrandList v-for="item in hotBrandList" :item="item" :key="item.code" class="item" recommendIcon />-->
<!--                  <brandItem v-for="(item, index) in hotBrandList" :key="index" :brandItem="item"/>-->
                </div>
                <div ref="tabMain" v-else class="tab-list">
                  <van-skeleton :loading="tabsLoading" :animate="false" :row="3" class="s-skeleton s-radius__xs">
                    <brandHorizontalBox v-for="(brand, index) in cacheAllTabList[active]"  :key="index" :brandData="brand" @click.native="goBrandPage(brand.brandCode)" />
<!--                    <BrandCard-->
<!--                      v-for="item in cacheAllTabList[active]"-->
<!--                      :item="item"-->
<!--                      :key="item.code"-->
<!--                      class="item"-->
<!--                      @click="openDetail(item.brandCode)"-->
<!--                      recommendIcon-->
<!--                    />-->
<!--                    <brandItem v-for="(item, index) in cacheAllTabList[active]" :key="index" :brandItem="item"/>-->
                  </van-skeleton>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </AutoView>
  </div>
</template>

<script>
  import BrandList from "./components/brandList.vue";
  import BrandCard from "./components/card";
  import { setLocalStorage } from '../../utils';

  const brandItem = () => import('./components/brandItem')
  const storeItem = () => import('./components/storeItem')
  const brandHorizontalBox = () => import('@/views/brandAlliance/components/brand-horizontal-box')
  export default {
    name   : 'brandHome',
    components: {
      brandItem,
      BrandList,
      BrandCard,
      storeItem,
      brandHorizontalBox
    },
    data () {
      return {
        loading: true,
        value: "",
        tabTop: 0,
        offsetTop: 0,
        active: 0,
        columnList: [],
        bannerList: [], // 轮播图
        iconBigList: [
          {
            url: "/static/discovery/cmxy.png",
            title: "参谋学院",
          },
          {
            url: "/static/discovery/jmtj.png",
            title: "加盟推荐",
          },
          {
            url: "/static/discovery/kddt.png",
            title: "开店地图",
          },
          {
            url: "/static/discovery/xbbk.png",
            title: "小白必看",
          },
        ],
        tabsList: ["精选品牌", "中餐", "小吃", "饮品/奶茶", "烧烤/炸串", "麻辣烫/冒菜", "炸鸡汉堡", "零售连锁"],
        tabsLoading: false,
        cacheAllTabList: {},
        hotBrandList: [],
        showSpot: false, // 是否显示客服红点icon
        timer: null,
        courseList: [], // 课程列表
        storeList: [], //
        requestComplete: false
      };
    },
    created() {
    },
    mounted() {
      if (window.isClient) {
        this.JSBridge.registerHandler("onAppResume", (data) => {
          console.log('onAppResumeonAppResume')
          this.JSBridge.callHandler("getServiceUnreadCount", null, (data) => {
            console.log('尝试获得客服消息未读数量=' + data)
            this.showSpot = data > 0
          })
          this.requestData()
        })
      } else {
        this.requestData()
      }

      this.$nextTick(() => {
        this.offsetTop = this.$refs.content.getBoundingClientRect().top;
      });
    },
    methods: {
      goBrandPage (code) {
        this.swRouter( '/h5/#/brand/detail/' + code )
      },
      getInfo() {
        let params = { title: '门店设计' };
        this.HTTP.getArticleCategoryDetail(params).then(res => {
          this.storeList = res.data.resourceList.slice(0,2) || [];
        })
      },
      async requestData() {
        if (this.requestComplete) {
          return
        }
        await this.getInfo()
        this.HTTP.brandAllianceHttp.findPageData()
          .then((res) => {
            console.log(res);
            this.loading = false;
            this.loadData(res.data.data);
            this.requestComplete = true
          })
          .catch((e) => {
            this.$toast("网络不稳定，正在重试！");
            setTimeout(() => {
              location.reload();
            }, 2000);
          });
      },
      goPingAnPage(url) {
        this.swRouter(url)
      },
      reportBtn(btnId, refId) {
        this.JSBridge.BTNCLICK(this.$route.meta.id, refId, 50008, btnId);
      },
      // 跳转到原生
      onTap ( item ) {
        if ( window._appCode && window._appCode >= 170 ) {
          this.reportBtn(500082, item.goodsId)
          this.JSBridge.callHandler( 'onNormalizingCall', {
            type  : 5,
            url   : 'COURSE_PAGE',
            id    : item.goodsId,
            params: null
          } );
        } else {
          this.$toast( 'APP版本过低，请升级以获取更好的体验！' );
        }
      },
      openDetail(code) {
        setLocalStorage("versionCode", window._appCode);
        this.swRouter("/brand/details/" + code);
      },
      goCourse() {
        this.reportBtn(500081)
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: `SCHOOL_PAGE`
        });
      },
      goOtherPage(item) {
        // console.log(item)
        this.JSBridge.callHandler("onNormalizingCall", item.link)
      },
      scrollTop(name, title) {
        if (name == 0 || this.cacheAllTabList[this.active]) {
          document.getElementById("view").scrollTop = this.tabTop;
          return;
        }
        this.tabsLoading = true;
        const CodeL1 = {
          零售连锁: "L01",
        };
        const CodeL2 = {
          中餐: "W0101",
          小吃: "W0104",
          "饮品/奶茶": "W0103",
          "烧烤/炸串": "W0106",
          "麻辣烫/冒菜": "W0105",
          炸鸡汉堡: "W0107",
        };
        let params = {
          current: 1,
          newCategoryCodeL1: CodeL1[title],
          newCategoryCodeL2: CodeL2[title],
          recommend: 1,
          size: 10,
        };
        this.HTTP.brandAllianceHttp.getHotRecommend(params)
          .then((res) => {
            this.cacheAllTabList[this.active] = res.data.data.data || [];
            this.tabsLoading = false;
            this.$nextTick(() => {
              document.getElementById("view").scrollTop = this.tabTop;
            })
          })
          .catch((e) => {
            this.tabsLoading = false;
            this.$toast("网络不稳定，请稍候重试！");
          });
      },
      jump(item) {
        const { btnId, moduleId } = item.link.trackData || {};
        this.JSBridge.BTNCLICK(this.$route.meta.id, "", moduleId, btnId);
        this.JSBridge.callHandler("onNormalizingCall", item.link);
      },
      loadData(data) {
        this.columnList = data.columnList || []
        this.bannerList = data.banners || [];
        this.hotBrandList = data.newBrandList || [];
        this.newBrandList = data.hotBrandList || [];
        this.courseList = data.courseList || [];
        // this.hotBrandList = this.newBrandList.map(el => {
        //   el.brandName = el.name
        //   el.logoUrl = el.logo
        //   el.brandCode = el.code
        //   return el
        // })
        this.$nextTick(() => {
          let top = this.$refs.tabWrap.getBoundingClientRect().top;
          this.tabTop = top - this.offsetTop;
        });
      },
      onLivechat () {
        if (window._appCode >= 175) {
          this.JSBridge.callHandler( 'onNormalizingCall', {
            type  : 5,
            url   : 'ONLINE_SERVICE',
            params: null,
          } );
        } else {
          this.JSBridge.callHandler(
            'callLiveChat',
            {
              type: 'normal',
              data: {},
            },
            ( st ) => {
            },
          );
        }
      },
    },
    beforeDestroy () {}
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/index.scss";
  @import '@/styles/px2vw.scss';
  .brand-horizontal-box {
    margin-bottom: vw(20);
  }
  ::v-deep {
    .float-cite {
      top: 1.067vw !important;
      right: -0.8vw !important;
    }
  }
  .pingan-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4vw 4vw 0 4vw;
    >div {
      width: 44.67vw;
      height: 16vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .store-view {
    padding: 4vw 4vw 2.4vw 4vw;
    .store-view-list {
      display: grid;
      grid-column-gap: vw(20);
      grid-template-columns: 1fr 1fr;
      //display: flex;
      //justify-content: space-between;
      //.store-item {
      //  width: 44.67vw;
      //  background: #FFFFFF;
      //  border-radius: vw(8);
      //  margin-top: 3.467vw;
      //  .course-cover {
      //    height: 25.067vw;
      //    background: #ccc;
      //    border-radius: vw(8);
      //    .brand-icon {
      //      width: 100%;
      //      height: 100%;
      //      border-radius: vw(8);
      //      ::v-deep img {
      //        border-radius: vw(8);
      //      }
      //    }
      //  }
      //  .course-title {
      //    font-weight: 400;
      //    color: #333333;
      //    font-size: 3.467vw;
      //    height: 10vw;
      //    line-height: 4.933vw;
      //    overflow : hidden;
      //    text-overflow: ellipsis;
      //    display: -webkit-box;
      //    -webkit-line-clamp: 2;
      //    -webkit-box-orient: vertical;
      //    margin: 2.133vw 0 1.2vw;
      //  }
      //}
    }
    .store-view-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .store-view-header-left {
        font-weight: bolder;
        color: #1D2233;
        font-size: vw(32);
        line-height: vw(45);
      }
      .store-view-header-right {
        color: #999999;
        font-size: vw(24);
        .arrow-icon {
          width: vw(10);
          height: vw(21);
          margin-left: vw(10);
        }
      }
    }
  }
  .course-view {
    padding: 4vw 4vw 2.4vw 4vw;
    .course-view-list {
      display: flex;
      flex-wrap: wrap;
      .course-item {
        width: calc((100% - 3.467vw) / 2);
        height: 48vw;
        background: #FFFFFF;
        box-shadow: 0 0 1.067vw 0 rgba(0, 0, 0, 0.13);
        border-radius: 1.333vw;
        margin-right: 3.467vw;
        margin-top: 3.467vw;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .course-cover {
          height: 26.667vw;
          background: #ccc;
          border-radius: 1.333vw 1.333vw 0 0;
          .brand-icon {
            width: 100%;
            height: 100%;
            border-radius: 1.333vw 1.333vw 0 0;
            ::v-deep img {
              border-radius: 1.333vw 1.333vw 0 0;
            }
          }
        }
        .course-title {
          font-weight: bolder;
          color: #333333;
          font-size: 3.467vw;
          padding: 0 2.667vw;
          height: 10vw;
          line-height: 4.933vw;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 2.133vw 0 1.2vw;
        }
        .course-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 6vw;
          padding: 0 2.667vw;
          .course-info-left {
            .course-price-box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .course-price {
                font-size: 3.2vw;
                color: #FF5900;
                font-weight: 500;
                span {
                  font-size: 4.267vw;
                }
              }
              .course-origin-price {
                font-size: 2.933vw;
                color: #B4B4B4;
                text-decoration: line-through;
              }
            }
            .free-box {
              height: 5.067vw;
              line-height: 5.067vw;
              background: linear-gradient(90deg, #F84947 0%, #FF6F54 100%);
              border-radius: 0.667vw;
              font-weight: 500;
              color: #FFFFFF;
              font-size: 2.933vw;
              padding: 0.533vw 1.067vw;
            }
          }
          .course-info-right {
            font-weight: 400;
            color: #6D7289;
            font-size: 3.2vw;
            display: flex;
            align-items: baseline;
            .hot-icon {
              width: 2.5vw;
              //height: 3.333vw;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
    .course-view-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .course-view-header-left {
        font-weight: bolder;
        color: #1D2233;
        font-size: 4.533vw;
        margin-right: 2.933vw;
      }
      .course-view-header-right {
       flex: 1;
        img {
          width: 100%;
          max-width: 70.66vw;
          height: 6.4vw;
        }
      }
    }
  }
  .brand {
    height: 100vh;
    overflow: hidden;
    .search-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 4vw 0 4vw;
      .left {
        flex: 1;
      }
      .right {
        flex: 0 0 8.53vw;
        text-align: right;
        position: relative;
        img {
          width: 5.87vw;
          height: 5.33vw;
        }
        .service-spot {
          position: absolute;
          width: 1.333vw;
          height: 1.333vw;
          border-radius: 50%;
          background: #FF504E;
          border: .4vw solid #FFFFFF;
          right: -.4vw;
          top: -.267vw;
        }
      }
    }

    #{&}--view {
      scroll-behavior: smooth;
      position: relative;
      background: #ffffff;
      height: calc( 100vh - 50px) !important;
    }

    #{&}--main {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      .my-swipe {
        height: 26.67vw;
        margin: 4vw 4vw 0;
        border-radius: 3px;
        .banner-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .icon-wrap {
        text-align: center;
        padding: 4vw 4vw 2.4vw 4vw;
        .spacing {
          position: relative;
          margin-top: 4.2vw;
          .float-hot {
            position: absolute;
            top: -2.13vw;
            left: 12.8vw;
            width: 8.27vw;
            height: 5.07vw;
            background: url("/static/icon/icon_hot.png") no-repeat;
            background-size: cover;
          }
        }
        .icon-big-img {
          width: 9.33vw;
          height: 9.33vw;
        }
        .icon-small-img {
          width: 9.33vw;
          height: 9.33vw;
        }
        .title {
          font-size: 3.2vw;
          color: #666666;
          font-weight: bold;
          margin-top: 0.8vw;
        }
      }
      .hot-brand {
        padding-left: 4vw;
        h4 {
          font-size: 4.53vw;
          color: #1d2233;
          margin-bottom: 3.2vw;
        }
        .scroll-wrap {
          position: relative;
          overflow: hidden;
          overflow-x: auto;
          white-space: nowrap;
          background: #fff;
          .wrap {
            display: inline-block;
            width: 26.67vw;
            overflow: hidden;
            margin-right: 2.13vw;
            .scroll-img {
              width: 100%;
              height: 20vw;
              ::v-deep img {
                border-radius: 1.07vw;
              }
            }
            .name {
              font-size: 3.47vw;
              color: #333;
              font-weight: bold;
              line-height: 4.8vw;
              margin-top: 1.5vw;
              text-align: center;
            }
            .money {
              font-size: 3.2vw;
              color: #666;
              line-height: 4.27vw;
              margin-top: 1vw;
              text-align: center;
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .tabs-wrap {
        position: relative;
        flex: 1;
        background: #F7F7F7;
        ::v-deep .van-tabs__wrap {
          height: 10.667vw;
        }
        .tabs-main {
          padding: 3.733vw 4vw 16vw;
          overflow: auto;
          background: #F7F7F7;
          .item {
            margin-bottom: 4vw;
            border-radius: $radius_xs;
            box-shadow: 0 0 2.26vw 0 rgba(0, 0, 0, 0.09);
            width: 100%;
          }
          .loading {
            margin-top: 5.33vw;
          }
          .tab-list {
            display: flex;
            flex-wrap: wrap;

          }
        }
      }
    }
    ::v-deep .van-search {
      padding: 0 12px 0 0;
      .van-search__content {
        background: #F7F7F7;
        border-radius: 1.333vw;
        border: 1px solid #E7E7E7;
      }
      .van-icon {
        display: flex;
        .van-icon__image {
          width: 3.466vw;
          height: 3.466vw;
        }
      }
      input {
        ::-webkit-input-placeholder {
          color: #999999;
          font-size: 3.733vw;
        }
      }
    }
    ::v-deep .van-swipe__indicator {
      height: 0.53vw;
    }
    ::v-deep .van-tab {
      font-size: 3.733vw;
    }
    ::v-deep .van-tab--active {
      font-weight: bold;
      font-size: 4.267vw;
    }
    ::v-deep .van-search .van-cell {
      padding: 1.87vw 2.13vw 1.87vw 0;
      display: flex;
      align-items: center;
    }
  }
</style>
