<template>
  <div class="video" @click="videoDetail">
    <div class="top-img">
      <van-image class="banner-gif" fit="fill" :src="videoInfo.image" />
      <!-- 播放器icon -->
      <div class="play-icon">
        <img src="/static/icon/video_icon.png" alt="" />
      </div>
      <div class="float-text">
        <p class="title f-tof">{{ videoInfo.title }}</p>
        <p class="left">{{ videoInfo.videoTimeFormat || "" }}</p>
      </div>
    </div>
    <ul class="video-flex">
      <li class="left">
        <img :src="videoInfo.avatar || null" alt="" />
      </li>
      <li class="mid f-tof">{{ videoInfo.author }}</li>
      <li class="right">{{ videoInfo.clickCountFormat || "--" }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    videoInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    videoDetail() {
      this.swRouter("/h5/#/community/article/" + this.videoInfo.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.video {
  width: 100%;
  height: 61.33vw;
  background: #d8d8d8;
  border-radius: 1.07vw;
  margin-bottom: 3.2vw;
  .top-img {
    position: relative;
    .banner-gif {
      display: block;
      width: 100%;
      height: 51.2vw;
      object-fit: cover;
    }
    ::v-deep img {
      border-radius: 1.07vw 1.07vw 0 0;
    }
    .play-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 9.33vw;
        height: 9.33vw;
      }
    }
    .float-text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 13.87vw;
      padding: 2.13vw 2.67vw;
      box-sizing: border-box;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.49) 100%);
      .title {
        line-height: 4.8vw;
        font-size: 3.47vw;
        color: #fff;
        font-weight: bold;
      }
      .left {
        font-size: $font_293;
        color: #fff;
        margin-top: 1.07vw;
      }
    }
  }
  .video-flex {
    display: flex;
    align-items: center;
    height: 10.13vw;
    background: #fff;
    padding: 0 2.67vw;
    border-radius: 0 0 1.07vw 1.07vw;
    .left {
      flex: 0 0 8vw;
      img {
        width: 5.87vw;
        height: 5.87vw;
        border-radius: 50%;
      }
    }
    .mid {
      flex: 2;
      line-height: 4.8vw;
      font-size: 3.2vw;
      color: #333;
      font-weight: bold;
    }
    .right {
      flex: 1;
      line-height: 4.8vw;
      font-size: $font_293;
      color: #999;
      text-align: right;
    }
  }
}
</style>
