<template>
  <div class="guide" @click="download">
    <van-image fit="cover" src="/static/img/download/download.png" />
  </div>
</template>

<script>
export default {
  methods: {
    download() {
      this.$toast.loading();
      window.location.href = this.HTTP.getAppStore();
    },
  },
};
</script>

<style lang="scss" scoped>
.guide {
  width: 100%;
  height: 100vh;
}
</style>
