<template>
    <div class="brand">
        <StateBar />
        <!-- <TitleHeader title="品牌商机" bgcolor="transparent" color="black" :back="true"></TitleHeader> -->

        <!-- 搜索框 -->
        <ul class="search-flex">
            <li class="left" @click="swRouter('/brand/search')">
                <van-search v-model="value" disabled placeholder="请输入品牌名称" />
            </li>
            <li class="right" @click="onLivechat">
                <img src="/static/icon/service_icon.png" alt="" />
            </li>
        </ul>
        <div ref="content" style="height: 0"></div>
        <!-- 骨架屏占位图 -->
        <van-skeleton :loading="loading" :animate="false" :row="4" class="s-skeleton" />

        <AutoView ref="view" id="view" :header="true" :footer="false" class="brand--view" :style="{ opacity: loading ? 0 : 1 }">
            <div class="brand--main">
                <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" v-if="bannerList && bannerList.length">
                    <van-swipe-item v-for="item in bannerList" :key="item.key">
                        <img class="banner-img" :src="item.pictureUrl" alt="" @click="jump(item)" />
                    </van-swipe-item>
                </van-swipe>
                <div class="icon-wrap">
                    <van-row type="flex" justify="center">
                        <van-col
                            span="6"
                            v-for="icon in iconBigList"
                            :key="icon.key"
                            @click="swRouter(`/brand/discovery?categoryCode1=${icon.categoryCode1}&categoryCode2=${icon.categoryCode2}`)"
                        >
                            <img class="icon-big-img" :src="icon.url" alt="" />
                            <p class="title">{{ icon.title }}</p>
                        </van-col>
                    </van-row>
                    <van-row type="flex" justify="center" class="spacing">
                        <van-col span="6" v-for="icon in iconSmallList" :key="icon.key" @click="goToApp(icon.title)">
                            <img class="icon-small-img" :src="icon.url" alt="" />
                            <p class="title">{{ icon.title }}</p>
                            <i class="float-hot"></i>
                        </van-col>
                    </van-row>
                </div>
                <div class="hot-brand" v-if="newBrandList && newBrandList.length">
                    <h4>热门品牌</h4>
                    <div class="scroll-wrap">
                        <div class="wrap" v-for="item in newBrandList" :key="item.brandCode" @click="swRouter('/brand/details/' + item.code)">
                            <van-image class="scroll-img" fit="cover" :src="item.logo" />
                            <p class="name f-tof">{{ item.name }}</p>
                            <p class="money f-tof">{{ item.joinTotalCost }}</p>
                        </div>
                    </div>
                </div>
                <div class="tabs-wrap" ref="tabWrap">
                    <van-tabs v-model="active" color="#347FFF" swipeable sticky :offset-top="offsetTop" class="my-tabs" @change="scrollTop">
                        <van-tab v-for="(item, index) in tabsList" :title="item" :key="index">
                            <div class="tabs-main">
                                <div v-if="active == 0">
                                    <BrandList v-for="item in hotBrandList" :item="item" :key="item.code" class="item" />
                                </div>
                                <div ref="tabMain" v-if="[1, 2, 3, 4, 5, 6, 7, 8].includes(active)">
                                    <van-skeleton :loading="tabsLoading" :animate="false" :row="3" class="s-skeleton s-radius__xs">
                                        <BrandCard
                                            v-for="item in cacheAllTabList[active]"
                                            :item="item"
                                            :key="item.code"
                                            class="item"
                                            @click="mainOpenDetail(item.brandCode)"
                                        />
                                    </van-skeleton>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>
            </div>
        </AutoView>
    </div>
</template>
<script>
import BrandList from "./components/brandList.vue";
import BrandCard from "./components/card";
import { setLocalStorage } from "@/utils/index";
export default {
    components: { BrandList, BrandCard },
    data() {
        return {
            loading: true,
            value: "",
            active: 0,
            offsetTop: 0,
            tabTop: 0,
            bannerList: [], // 轮播图
            iconBigList: [
                {
                    url: "/static/icon/brand/icon1.png",
                    title: "全部",
                    categoryCode1: "",
                    categoryCode2: "",
                },
                {
                    url: "/static/icon/brand/icon2.png",
                    title: "小吃快餐",
                    categoryCode1: "",
                    categoryCode2: "W0104",
                },
                {
                    url: "/static/icon/brand/icon3.png",
                    title: "饮品甜品",
                    categoryCode1: "",
                    categoryCode2: "W0103",
                },
                {
                    url: "/static/icon/brand/icon4.png",
                    title: "商超便利",
                    categoryCode1: "",
                    categoryCode2: "Q0101",
                },
            ],
            iconSmallList: [
                {
                    url: "/static/icon/brand/icon5.png",
                    title: "品牌推荐",
                },
                {
                    url: "/static/icon/brand/icon6.png",
                    title: "选址评估",
                },
                {
                    url: "/static/icon/brand/icon7.png",
                    title: "赚钱品类",
                },
                {
                    url: "/static/icon/brand/icon8.png",
                    title: "小白必看",
                },
            ],
            hotBrandList: [], // 精品品牌
            newBrandList: [], // 热门品牌
            otherBrandList: [], // 其他品牌
            tabsList: ["精选品牌", "中餐", "小吃", "饮品/奶茶", "烧烤/炸串", "麻辣烫/冒菜", "炸鸡汉堡", "零售连锁"],
            tabsLoading: false,
            cacheAllTabList: {},
        };
    },
    created() {
        this.HTTP.getBrandInfo()
            .then((res) => {
                console.log(res);
                this.loading = false;
                this.loadData(res.data);
            })
            .catch((e) => {
                this.$toast("网络不稳定，正在重试！");
                setTimeout(() => {
                    location.reload();
                }, 2000);
            });
    },
    mounted() {
        this.$nextTick(() => {
            this.offsetTop = this.$refs.content.getBoundingClientRect().top;
        });
    },
    methods: {
        mainOpenDetail(code) {
            setLocalStorage("versionCode", window._appCode);
            this.swRouter("/brand/details/" + code);
        },
        jump(item) {
            const { btnId, moduleId } = item.link.trackData || {};
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", moduleId, btnId);
            this.JSBridge.callHandler("onNormalizingCall", item.link);
        },
        loadData(data) {
            this.bannerList = data.bannerList || [];
            this.hotBrandList = data.newBrandList || [];
            this.newBrandList = data.hotBrandList || [];
            this.$nextTick(() => {
                let top = this.$refs.tabWrap.getBoundingClientRect().top;
                this.tabTop = top - this.offsetTop;
            });
        },
        scrollTop(name, title) {
            document.getElementById("view").scrollTop = this.tabTop;
            if (name == 0) {
                return;
            }
            if (this.cacheAllTabList[this.active]) {
                return;
            }
            this.tabsLoading = true;
            const CodeL1 = {
                零售连锁: "L01",
            };
            const CodeL2 = {
                中餐: "W0101",
                小吃: "W0104",
                "饮品/奶茶": "W0103",
                "烧烤/炸串": "W0106",
                "麻辣烫/冒菜": "W0105",
                炸鸡汉堡: "W0107",
            };
            let params = {
                current: 1,
                newCategoryCodeL1: CodeL1[title],
                newCategoryCodeL2: CodeL2[title],
                recommend: 1,
                size: 10,
            };
            this.HTTP.brandList2(params)
                .then((res) => {
                    this.cacheAllTabList[this.active] = res.data || [];
                    this.tabsLoading = false;
                })
                .catch((e) => {
                    this.tabsLoading = false;
                    this.$toast("网络不稳定，请稍候重试！");
                });
        },
        goToApp(title) {
            switch (title) {
                case "品牌推荐":
                    this.swRouter("/brand");
                    break;
                case "选址评估": {
                    if (window._appCode >= 173) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            id: "10004",
                            url: "BRAND_INTRODUCTION",
                            params: null,
                            trackData: { moduleId: "20400", btnId: "20401" },
                        });
                    } else {
                        this.swRouter("/introduce/10004");
                    }
                    break;
                }
                case "赚钱品类": {
                    if (window._appCode >= 173) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            id: "10015",
                            url: "BRAND_INTRODUCTION",
                            params: null,
                            trackData: { moduleId: "20400", btnId: "20401" },
                        });
                    } else {
                        this.swRouter("/introduce/10015");
                    }
                    break;
                }
                case "小白必看":
                    // this.JSBridge.callHandler("onNormalizingCall", {
                    //     type: 5,
                    //     url: "ARTICLE_PAGE",
                    //     params: JSON.stringify({
                    //         selected_category_id: "5",
                    //     }),
                    // });
                    this.swRouter("/community/drgGoods");
                    break;
            }
        },
        onLivechat() {
            if (window._appCode >= 175) {
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "ONLINE_SERVICE",
                    params: null,
                });
            } else {
                this.JSBridge.callHandler(
                    "callLiveChat",
                    {
                        type: "normal",
                        data: {},
                    },
                    (st) => {}
                );
            }
        },
    },
    // beforeDestroy() {
    //   setLocalStorage("versionCode", window._appCode)
    // }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.brand {
    height: 100vh;
    overflow: hidden;

    .search-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 4vw;
        .left {
            flex: 1;
        }
        .right {
            flex: 0 0 8.53vw;
            text-align: right;
            img {
                width: 5.87vw;
                height: 5.33vw;
            }
        }
    }

    #{&}--view {
        scroll-behavior: smooth;
        position: relative;
        background: linear-gradient(180deg, #fff 0%, #f7f7f7 100%);
    }

    #{&}--main {
        position: relative;
        padding-bottom: 16vw;
        .my-swipe {
            height: 26.67vw;
            margin: 0 4vw;
            border-radius: 3px;
            .banner-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .icon-wrap {
            text-align: center;
            padding: 4vw 0 8vw 0;
            .spacing {
                position: relative;
                margin-top: 4.2vw;
                .float-hot {
                    position: absolute;
                    top: -2.13vw;
                    left: 12.8vw;
                    width: 8.27vw;
                    height: 5.07vw;
                    background: url("/static/icon/icon_hot.png") no-repeat;
                    background-size: cover;
                }
            }
            .icon-big-img {
                width: 9.33vw;
                height: 9.33vw;
            }
            .icon-small-img {
                width: 9.33vw;
                height: 9.33vw;
            }
            .title {
                font-size: 3.2vw;
                color: #666666;
                font-weight: bold;
                margin-top: 0.8vw;
            }
        }
        .hot-brand {
            padding-left: 4vw;
            h4 {
                font-size: 4.53vw;
                color: #1d2233;
                margin-bottom: 3.2vw;
            }
            .scroll-wrap {
                position: relative;
                overflow: hidden;
                overflow-x: auto;
                white-space: nowrap;
                background: #fff;
                .wrap {
                    display: inline-block;
                    width: 26.67vw;
                    overflow: hidden;
                    margin-right: 2.13vw;
                    .scroll-img {
                        width: 100%;
                        height: 20vw;
                        ::v-deep img {
                            border-radius: 1.07vw;
                        }
                    }
                    .name {
                        font-size: 3.47vw;
                        color: #333;
                        font-weight: bold;
                        line-height: 4.8vw;
                        margin-top: 1.5vw;
                        text-align: center;
                    }
                    .money {
                        font-size: 3.2vw;
                        color: #666;
                        line-height: 4.27vw;
                        margin-top: 1vw;
                        text-align: center;
                    }
                }
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
        .tabs-wrap {
            position: relative;
            background: linear-gradient(180deg, #fff 0%, #f7f7f7 100%);
            .tabs-main {
                min-height: 100vh;
                padding: 2.67vw 4vw;
                overflow: auto;
                .item {
                    margin-bottom: 4vw;
                    border-radius: $radius_xs;
                    box-shadow: 0 0 2.13vw 0 rgba(0, 0, 0, 0.09);
                }
                .loading {
                    margin-top: 5.33vw;
                }
            }
        }
    }
    ::v-deep .van-search {
        padding: 10px 0;
    }
    ::v-deep .van-swipe__indicator {
        height: 0.53vw;
    }
    ::v-deep .van-tab--active {
        font-weight: bold;
    }
    ::v-deep .van-search .van-cell {
        padding: 1.87vw 2.13vw 1.87vw 0;
    }
}
</style>
