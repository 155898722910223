<template>
  <div class="titleHeader" :style="{ color: color, backgroundColor: bgcolor }" v-if="!isWX">
    <div class="view view--l">
      <slot name="left" />
    </div>
    <div class="view">
      <p class="title"
        :class="active == xindex ? 'aTitle':''"
        v-for="(val, xindex) in newList"
        :key="xindex" @click="openTab(xindex, val.isCheck)">{{ val.name }}</p>
    </div>
    <div class="view view--r">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "titleHeader",
  props: {
    title: Array,
    color: String,
    bgcolor: {
      type: String,
      default: "white",
    },
    back: {
      // 是否显示后退按钮
      type: Boolean,
      default: false,
    },
    active: {
      actIndex: Number,
      default: 0,
    }
  },
  data() {
    return {
      isWX: false,
      newList: this.title,
    };
  },
  watch: {
    back: {
      immediate: true,
      handler(val) {
        this.JSBridge.callHandler("switchBackBtn", val);
      },
    },
  },
  beforeMount() {
    // this.JSBridge.callHandler( 'switchBackBtn', this.back );
    this.isWX = window.isMini;
  },
  methods: {
    openTab(index, check) {
      if(!check) {
        for(let i=0; i<this.newList.length; i++) {
          if(i == index) {
            this.newList[index].isCheck = true;
          } else {
            this.newList[i].isCheck = false;
          }
        }
        this.$emit('clickParans', index);
      }

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.titleHeader {
  position: relative;
  @extend .l-header;
  padding: 0 0 0 10vw;
  // flex-shrink: 0;
  // box-shadow: inset 0 calc( #{$headerHeight * 0.5} - 1px ) 0 1px white, inset 0 $headerHeight * 0.5 0 1px red;
  // background-color: pink!important;
  .search {
    // display: flex;
    // justify-content: center;
    // align-self: center;
    width: 100%;
    height: 15.5vw;
  }

  .view {
    @extend .l-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    height: 100%;

    .title {
      // @include singleLine;
      font-weight: bold;
      font-size: 4vw;
      margin: 0vw 6.4vw 0 0;
      color: #666666;
      line-height: 6.4vw;
      &:last-child{
          margin-right: 0;
      }
    }
    .aTitle{
      color: #333333;
      font-size: 4.53vw;
      position: relative;
    }
    .aTitle::after{
      content: " ";
      position: absolute;
      width: 7.47vw;
      height: 1.07vw;
      left: 50%;
      transform: translateX(-50%);
      background: url('/static/icon/hot_icon.png') no-repeat;
      background-size: 7.47vw 1.07vw;
      bottom: -1.07vw;
    }
    &--l {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-left: $padding;
      width: auto;
      font-size: $font_347;
      color: $c-info;
    }
    &--r {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      margin-right: $padding;
      width: auto;
      font-size: $font_347;
      color: $c-info;
    }
  }

  &.no-mr {
    .view--r {
      margin-right: 0;
    }
  }
}
</style>
