<template>
    <div class="hot-search">
        <div class="hot-search--header" ref="searchtitle">
            <StateBar :bgcolor="bgColor" />
            <TitleHeader :title="barTitle" :color="titleColor" :bgcolor="bgColor" :back="showBack"></TitleHeader>
        </div>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s">
        </van-skeleton>
        <AutoView ref="view" :header="false" @scroll.native="pageScrollHandle" :footer="false" class="hot-search--view l-view">
            <VanImage @load="imageLoadDone" ref="searcheader" src="/static/img/hot-search/header-bg.png"></VanImage>
            <van-tabs v-if="!firstLoading" v-model="tab" class="tabs-wrap" :swipe-threshold="4" @change="tabChange">
                <van-tab :title="tab.name" :name="tab.tabId" v-for="(tab, i) in tabList" :key="i">
                    <keep-alive>
                        <component :headerHeight="headerHeight" :is="tab.component"></component>
                    </keep-alive>
                </van-tab>
            </van-tabs>
        </AutoView>
    </div>
</template>

<script>
import brandList from '@/views/hotSearch/components/brandList'
import tokeoutList from '@/views/hotSearch/components/tokeoutList'
import openStoreList from '@/views/hotSearch/components/openStoreList'
import setupList from '@/views/hotSearch/components/setupList'
import specialList from '@/views/hotSearch/components/specialList'
const modulesMap = {
    0: 83000, // 外卖榜单
    1: 83100, // 创业热选
    2: 83200, // 开店热搜
    3: 83300, // 商业价值榜
}
export default {
    components: {
        brandList,tokeoutList,openStoreList,setupList,specialList
    },
    data() {
        return {
            firstLoading: true,
            tab: 0,
            bgColor: 'transparent',
            titleColor: 'transparent',
            showBack: true,
            isTop: false,
            headerHeight: 0,
            tabList: [
                // {
                //     name: '品牌榜单',
                //     component: 'brandList'
                // },
                {
                    name: '外卖榜单',
                    component: 'tokeoutList',
                    tabId: 0,
                },
                {
                    name: '创业热选',
                    component: 'setupList',
                    tabId: 1,
                },
                {
                    name: '开店热搜',
                    component: 'openStoreList',
                    tabId: 2,
                },
                {
                    name: '商业价值榜',
                    component: 'specialList',
                    tabId: 3,
                }
            ]
        }
    },
    computed: {
        barTitle() {
            return this.tabList[this.tab].name
        },
    },
    mounted() {
        this.tab = +this.$route.params.type
        this.addRecord(this.tab)
    },
    methods: {
        // 埋点
        addRecord(tabId, btnId = null) {
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, modulesMap[tabId], btnId)
        },
        // 切换tab
        tabChange(tabId) {
            this.addRecord(tabId)
        },
        pageScrollHandle(e) {
            const h = this.$refs.searcheader.$el.offsetHeight
            const t = this.$refs.searchtitle.offsetHeight
            this.headerHeight = t
            const check = e.target.scrollTop <= h - t;
            if (this.isTop !== check) {
                this.isTop = check;
                this.bgColor = check ? "transparent" : "white";
                this.titleColor = check ? "transparent" : "#1D2233";
                this.JSBridge.callHandler("setStatebarTheme", check ? "black" : "black");
            }
        },
        imageLoadDone() {
            this.firstLoading = false
        }
    }
};
</script>

<style lang="scss" scoped>
.hot-search {
    height: 100vh;
    #{&}--header {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 6;
    }
    .l-view {
        padding: 0;
        height: 100%;
        padding-bottom: 10vw;
    }
    .s-skeleton {
        margin-top: 20vw;
    }
    .tabs-wrap {
        background: #F7F7F7;
        margin-top: -4px;
        padding-top: 4vw;
        ::v-deep .van-tabs__wrap {
            height: 12.533vw;
        }
        ::v-deep .van-tabs__nav{
            background: transparent;
            padding: 0;
            padding-left: 4vw;
            .van-tab {
                min-width: 20.26667vw;
                // width: 22vw;
                height: 8.533vw;
                background: #FFFFFF;
                box-shadow: 0px 0px 2.2667vw 0px rgba(0, 0, 0, 0.09);
                border-radius: 0.8vw;
                margin-right: 1.86vw;
                font-size: 3.733vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                &--active{
                    background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
                    color: #FFFFFF;
                    font-weight: 600;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        border: 1.33vw solid transparent;
                        border-top: 1.33vw solid #FF6D00;
                        bottom: -2.53vw;
                        filter: drop-shadow();
                        z-index: 1;
                    }
                }
            }
            .van-tabs__line {
                display: none;
            }
        }
    }
}
</style>
