<template>
    <div class="partner" :class="{ loaded: !loading }">
        <StateBar />
        <TitleHeader title="合伙人中心" bgcolor="transparent" color="white" :back="true">
            <div slot="right" v-show="!loading" class="partner--rule" @click="$router.push( '/partner/apply' )">合伙人规则</div>
        </TitleHeader>

        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <AutoView v-if="!loading" :header="true" :footer="false" class="partner--view">
            <div class="partner--info">
                <h5 class="title">累计收入(元)</h5>
                <h3 class="amount">{{ entity.commissionDetail.totalCommission }}</h3>
                <p v-if="entity.commissionDetail.frozenCommission" class="freeze">冻结金额：{{ entity.commissionDetail.frozenCommission }}元</p>
                <div class="panel">
                    <van-row>
                        <div class="record">
                            <div @click="openHistroy">佣金记录<van-icon name="arrow" /></div>
                        </div>
                    </van-row>
                    <van-row >
                        <van-col span="12" class="column">
                            <p>今日收入(元)</p>
                            <p class="num">{{ entity.commissionDetail.dayCommission }}</p>
                        </van-col>
                        <van-col span="12" class="column">
                            <p>本月收入(元)</p>
                            <p class="num">{{ entity.commissionDetail.monthCommission }}</p>
                        </van-col>
                    </van-row>
                </div>
                <div class="panel-a">
                    <div class="text">
                        <div class="label">可提现金额(元)</div>
                        <div class="icon-attention" @click="showDialog = true"></div>
                    </div>
                    <p class="num">{{ entity.commissionDetail.availableCommission }}<span class="warn-label" v-if="isLessThanMin">(未达到最低提现标准)</span></p>
                    <button class="btn" :class="{gray:isLessThanMin}" @click="gotoNext">提现</button>
                </div>
                <div class="panel-c">
                    <div class="item" @click="openTion">
                        <div class="label">累计邀请</div>
                        <div class="value">{{partnerTotalInfo.totalPartner}}</div>
                    </div>
                    <div class="panel-divider"></div>
                    <div class="item" @click="openHistroy">
                        <div class="label">累计订单</div>
                        <div class="value">{{partnerTotalInfo.totalOrder}}</div>
                    </div>
                </div>
                <div class="panel-d">
                    <div class="img-wrap" @click="gotoInvite">
                        <img class="bkg-img" src="/static/img/partner/partner-bkg.png" />
                    </div>
                    <div class="invite-wrap">
                        <div class="item" @click="callShare">
                            <img src="/static/icon/icon_share@2x.png" class="icon" />
                            <span class="label">分享链接</span>
                        </div>
                        <div class="divider"></div>
                        <div class="item" @click="callQRCode">
                            <img src="/static/icon/icon_invite@2x.png" class="icon" />
                            <span class="label">面对面邀请</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="partner--list f-vmt">
                <div class="head">
                    <img class="icon" src="/static/icon/left-arrow@2x.png" />
                    <span>分享商品获佣金</span>
                    <img class="icon" src="/static/icon/right-arrow@2x.png" />
                </div>
                <div class="tab">
                    <div class="item" >
                        <!-- @click="changeSelectTab(1)":class="{active:activeIndex==1}" -->
                        <span class="label">分享商品给好友，好友购买后，您可获得佣金</span>
                        <!-- <div class="underline" v-show="activeIndex==1"></div> -->
                    </div>
                    <!-- <div class="item" @click="changeSelectTab(2)">
                        <span class="label" :class="{active:activeIndex==2}">二级佣金</span>
                        <div class="underline" v-show="activeIndex==2"></div>
                    </div> -->
                </div>
                <!-- <EmptyCard v-if="!list || !list.length" type="partner"></EmptyCard> -->
                <div class="account">
                    <EmptyCard v-if="goodsShareList.length <= 0  && !isFlag" type="community"></EmptyCard>
                    <template v-else>
                        <Profile v-for="(val, index) in goodsShareList" :key="index" :itemList="goodsShareList[index]" />
                    </template>
                </div>
            </div>
        </AutoView>

        <!-- 提现说明 -->
        <van-dialog v-model="showDialog" confirmButtonText="关闭" get-container="#app">
            <h1>可提现金额</h1>
            <div class="content">
                <p><strong>1.</strong>可提现金额 = 累计收入 - 已提现收入 - 冻结收入；</p>
                <p><strong>2.</strong>可提现金额 ≥ {{ entity && entity.minWithdrawalAmount ? entity.minWithdrawalAmount : 100 }} {{ entity && entity.withdrawalConditionType === 2 ? ' 且为100的整数倍 ' : '' }} 才能提现；</p>
                <p><strong>3.</strong>到账金额会自动为您扣缴6%的相关税费</p>
                <!-- <p><strong>3.</strong>提现申请需1-3个工作日审核，审核通过转账到你的支付宝账户；</p> -->
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Profile from '@/components/card/profile';

export default {
    name      : 'partner',
    components: {
        Profile,
    },
    data () {
        return {
            loading   : true,
            submitting: false,

            showDialog: false,

            already: false, // 是否合伙人
            entity : null, // 合伙人信息
            income : null, // 收益信息
            list   : [],
            // 一级合伙人or二级合伙人
            activeIndex: 1,
            partnerTotalInfo: {
                totalOrder: 0,
                totalPartner: 0
            },
            goodsShareList: [],
            isFlag: false,
        };
    },
    methods: {
        loadData () {
            this.loading = false;
            this.list = [];
            this.HTTP.incomeList( this.activeIndex, null, 'before', 50,  ).then( ( res ) => {
                // console.log( 'loadData:', res );
                if ( res ) {
                    this.list = res.list;
                }
            } );
        },
        // 佣金记录
        openHistroy() {
            this.swRouter( '/partner/commission_record' );
        },
        // 累计邀请
        openTion() {
            this.swRouter( '/partner/invitation' );
        },
        getPartnerTotal() {
            this.HTTP.partnerTotal().then( ( res ) => {
                console.log( 'partnerTotal:', res );
                const { code, data } = res;
                if ( code == 0 ) {
                    this.partnerTotalInfo = data;
                }
            } )
        },
        // 分享商品
        getGoodsShareList() {
            this.isFlag = true;
            this.HTTP.getGoodsShareList().then( ( res ) => {
                this.goodsShareList = res;
                this.isFlag = false;
            } )
        },
        gotoNext () {
            if ( this.isLessThanMin ) {
                return;
            }
            this.swRouter( '/partner/cashout' );
        },
        getTypeText ( type ) {
            const map = {
                1 : '选址报告',
                2 : '上上贝',
                3 : '会员',
                4 : '选址尊享服务包',
                5 : '品牌洼地报告',
                6 : '数据导出服务',
                7 : '套餐包',
                8 : '店铺监测',
                9 : '品牌报告',
                10: '数据查询服务',
                11: '周边客群报告',
            };

            return '购买' + ( map[ type ] || map[ 1 ] );
        },
        // changeSelectTab ( index ) {
        //     this.activeIndex = index;
        //     this.loadData();
        // },
        gotoInvite () {
            const { shareId, shareCode } = this.entity;
            this.swRouter( {
                path: '/partner/invite',
                query: {
                    shareId,
                    shareCode
                }
            } );
        },
        callShare () {
            const { shareId, shareCode } = this.entity;
            if ( shareId == undefined || shareId.length == 0 ) {
                return;
            }
            if ( shareCode == undefined || shareCode.length == 0 ) {
                return;
            }
            this.JSBridge.callHandler( 'callShareV2', {
                code: shareCode,
                id: shareId
            } );
        },
        callQRCode() {
            this.swRouter( '/partner/qr' );
        }
    },
    computed: {
        isLessThanMin() {
            const availableCommission = this.entity.commissionDetail.availableCommission;
            console.log(this.entity.minWithdrawalAmount+"==========")
            const num = parseFloat( availableCommission );
            // < 500
            if ( num < this.entity.minWithdrawalAmount ) {
                return true;
            }
            return false;
        }
    },
    watch: {
        entity ( res ) {
            this.$store.commit( 'setPartnerInfo', res );
            this.already = res.isPartner;
            if ( this.already ) {
                this.loadData();
            } else {
                // this.$router.replace( '/partner/apply?active=' + ( res.isEligible ? 1 : '' ) );
                this.$router.replace( '/partner/enter' );
            }
        },
    },
    created () {
        // 查询合伙人信息getGoodsShareList
        this.HTTP.getPartnerInfo().then( ( res ) => {
            console.log( 'getPartnerInfo:', res );
            this.entity = res;
            this.getGoodsShareList();
        } ).catch( ( e ) => {
            this.$toast( '网络连接不稳定，请稍候重试！' );
        } );
        this.getPartnerTotal();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.partner {
    height: 100vh;
    &.loaded {
        // background: linear-gradient(to bottom, #FD8752 35%, $c-pageBg 55%);
        background: $c-pageBg url(/static/img/bg_partner.jpg) no-repeat left top;
        background-size: 100% auto;
    }

    #{&}--rule {
        color: white;
    }

    #{&}--view {
        padding: 6vw 4vw;
        background: url( /static/img/partner.png ) no-repeat left $padding;
        background-size: contain;
    }

    #{&}--info {
        color: white;
        .title, .freeze {
            font-size: $font_320;
            font-weight: normal;
            line-height: $font_467;
            text-align: center;
        }
        .freeze {
            opacity: .7;
        }
        .amount {
            font-size: 8.81vw;
            line-height: 12vw;
            margin-top: 1vw;
            text-align: center;
        }
        .panel {
            // height: 26.67vw;
            height: 29.33vw;
            background: linear-gradient(to right, rgba(#F6AE5A, .4), rgba(#FFB156, .8));
            border-radius: $radius_s;
            box-shadow: inset 0 0 0 1px #FFBF74;
            color: white;
            margin-top: 4vw;
            .record{
                padding-top: 2.67vw;
                padding-right: 3.73vw;
                box-sizing: border-box;
                text-align: right;
                font-size: 3.47vw;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 4.93vw;
                div{
                    display: inline-block;
                    i{
                        top: 0.53vw;
                    }
                }
            }
            .column {
                @include flexColumn;
                padding-top: 2.67vw;
                p {
                    font-size: $font_320;
                }
                .num {
                    margin-top: $margin;
                    font-size: 4.54vw;
                    font-weight: bold;
                }
                &:first-child{
                    position: relative;
                    &::after{
                        position: absolute;
                        content: "";
                        top: 3.47vw;
                        right: 0;
                        width: 1px;
                        height: 9.33vw;
                        background: rgba(255, 255, 255, .4);
                        z-index: 2;
                    }
                }
            }
        }
        .panel-a {
            position: relative;
            top: -$margin;
            height: 20.27vw;
            background-color: white;
            border-radius: $radius_s;

            .text {
                @include flex;
                position: absolute;
                top: $margin* 1.5;;
                left: $padding;
                .label {
                    color: $c-info;
                    font-size: $font_320;
                    line-height: 4.27vw;
                }
                .icon-attention {
                    display: block;
                    width: 3.47vw;
                    height: 3.47vw;
                    background: url(/static/icon/attention.png) no-repeat center center;
                    background-size: contain;
                    margin-left: $margin;
                }
            }
            .num {
                position: absolute;
                bottom: $margin* 1.5;
                left: $padding;
                color:#347FFF;
                font-size: 5.34vw;
                font-weight: bold;
                // display: flex;
                // align-items: center;
                .warn-label {
                    position: relative;
                    color: $c-warn;
                    font-size: 2.4vw;
                    font-weight: normal;
                    line-height: 3.0vw;
                    margin-left: 1.33vw;
                }
            }
        }
        .panel-b {
            position: relative;
            height: 20vw;
            background: url( /static/img/partner_2.png ) no-repeat;
            background-size: cover;
            border-radius: $radius_s;
            box-shadow: 0 2.67vw 5.34vw #EEEFF1;

            .btn {
                background: linear-gradient(to bottom, #F4B66A, #FCCA8D);
                box-shadow: 0rem 2vw 4vw rgba(#F4B76B, .22);
            }
            .text {
                position: absolute;
                top: $margin* 1.5;;
                left: $padding;
                font-size: $font_400;
                color: $c-text;
                font-weight: bold;
            }
            .desc {
                position: absolute;
                bottom: $margin* 1.5;
                left: $padding;
                color: $c-info;
                font-size: 2.94vw;
            }
        }
        .panel-c {
            position: relative;
            background-color: #ffffff;
            border-radius: $radius-s;
            padding: 4.8vw 0;
            display: flex;
            justify-content: space-evenly;
            .item {
                position: relative;
                text-align: center;
                .label {
                    color: #8E9099;
                    font-size: 3.2vw;
                    font-weight: normal;
                    line-height: 4.27vw;
                }
                .value {
                    color: #1D2233;
                    font-size: 4.53vw;
                    font-weight: bolder;
                    line-height: 6.4vw;
                    margin-top: 1vw;
                }
            }
            .panel-divider {
                position: relative;
                background-color: rgba(142,144,153,0.3);
                width: 1px;
                height: 9.6vw;
            }
        }
        .panel-d {
            position: relative;
            margin-top: 1.87vw;
            .img-wrap {
                position: relative;
                .bkg-img {
                position: relative;
                    width: 100%;
                }
            }
            .invite-wrap {
                position: relative;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-top: -2px;
                border-radius: 0.27vw 0.27vw 2.67vw 2.67vw;
                .divider {
                    position: relative;
                    width: 1px;
                    height: 4.27vw;
                    background-color: rgba(142,144,153,0.3);
                }
                .item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 2.67vw 4vw 3.2vw 4vw;
                    .icon {
                        width: 4.27vw;
                        height: 4.27vw;
                    }
                    .label {
                        position: relative;
                        color: #CC5531;
                        font-size: 3.2vw;
                        font-weight: normal;
                        line-height: 4.27vw;
                        margin-left: 1.07vw;
                    }
                }
            }
        }
        .btn {
            position: absolute;
            top: 50%;
            right: $padding;
            width: 16vw;
            height: 8vw;
            border: none;
            border-radius: $radius_s;
            font-size: 3.34vw;
            font-weight: bold;
            transform: translateY(-50%);
            background: linear-gradient(360deg, #4A98FF 0%, #347FFF 100%);
            box-shadow: 0vw 2vw 4vw 0vw rgba(81, 140, 219, 0.22);
            &.gray {
                background: #8E9099;
                box-shadow: none;
            }
        }
    }

    #{&}--list {
        position: relative;
        padding: $padding 0 0;
        background-color: white;
        border-radius: $radius_s;

        .head {
            position: relative;
            color: #1D2233;
            font-size: 4vw;
            font-weight: bold;
            line-height: 5.6vw;
            text-align: center;
            .icon {
                width: 5.6vw;
                height: 2.93vw;
                margin: 0 2.93vw;
            }
        }
        .tab {
            position: relative;
            width: 100%;
            background: linear-gradient(360deg, rgba(247, 246, 249, 0.51) 0%, rgba(247, 246, 249, 0) 100%);
            display: flex;
            justify-content: space-evenly;
            .item {
                position: relative;
                padding: 1.6vw 2vw 2.4vw;
                .label {
                    position: relative;
                    font-size: 3.2vw;
                    color: #999999;
                    line-height: 4.4vw;
                    &.active {
                        color: #518CDB;
                        font-weight: bold;
                    }
                }
                .underline {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    border-radius: 1px;
                    background-color: #518CDB;
                }
            }
        }
        .account {
            position: relative;
            padding:  0 3.33vw;
            width: 100%;
            box-sizing: border-box;
            box-shadow: inset 0 -1px 0 $c-line;

            .amount {
                position: absolute;
                top: 2.5vw;
                right: 6.4vw;
                height: 4vw;
                @include font( $c-notice, $font_347, bold );
            }
        }
        .account:last-child {
            box-shadow: none;
        }
    }
    // ::v-deep .van-overlay {
    //     .qr-code {
    //         width: 200px;
    //         height: 200px;
    //         top: 40%;
    //         margin: 0 auto;
    //     }
    // }
}

::v-deep .van-dialog__content {
    background: url(/static/img/partner_rule.jpg) no-repeat center top;
    background-size: contain;
    padding: $padding;
    h1 {
        font-size: 6.4vw;
        text-align: center;
        padding: 8vw 0;
    }
    .content {
        margin-top: $padding;
        p {
            color: $c-info;
            font-size: $font_347;
            line-height: $font_587;
            margin-top: $margin;
        }
        strong {
            color: $c-main;
        }
    }
}
::v-deep .van-overlay {
    .qr-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .qr-code {
            position: relative;
            width: 200px;
            height: 200px;

        }
    }
}
</style>
