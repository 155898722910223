<template>
  <div class="reports">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" bgcolor="white" :back="showBack">
      <div v-if="isClient" slot="right" @click="gotoDownload" class="btnColor">我的下载</div>
    </TitleHeader>
    <TabBar :option="platform ? elemeTabs : tabs" :value="current" @change="change"></TabBar>

    <StackView ref="view" class="reports--view">
      <van-skeleton :loading="!list || list.firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
        <EmptyCard v-if="!list || list.list.length === 0" type="reports"></EmptyCard>
        <template v-else>
          <van-pull-refresh v-model="list.dataLoading" @refresh="loadNewData">
            <van-list v-model="loading" :finished="list.finished" finished-text="我是有底线的" @load="loadData">
              <ReportCard v-for="item of list.list" :key="item.key" :item="item" @tap="onTap" @expertsAnswer="expertsAnswer($event)" />
            </van-list>
          </van-pull-refresh>
        </template>
      </van-skeleton>
    </StackView>
    <!-- 二维码图标  -->
    <van-dialog v-model="erCodeShow" class="coupon_dialog" :showConfirmButton="false">
      <img src="/static/img/company_qrcode.png" />
      <div class="coupon_colse" @click.stop="openClose"><van-icon name="close" /></div>
    </van-dialog>
    <!--预加载 勿删除-->
    <template>
      <img v-show="false" src="/static/img/company_qrcode.png" />
    </template>
  </div>
</template>

<script>
import UniUtils from '@/utils/uni'
import { formatDate } from "@/utils/filters";

import ReportCard from "@/components/card/report";
import TabBar from "@/components/widget/tabBar";

export default {
  name: "reports",
  components: {
    ReportCard,
    TabBar,
  },
  data() {
    return {
      title: "我的报告",
      showBack: true,
      isClient: false,
      loading: true,
      dataMap: {},
      tabs: [
        { key: 1, text: "开店选址" },
        { key: 2, text: "加盟品牌" },
        { key: 4, text: "选品类" },
        // { key: 3, text: "周边数据" },
        { key: 6, text: "数据查询" },
        { key: 7, text: "店铺设计" },
        { key: 8, text: "数据对比" },

      ],
      elemeTabs: [
        { key: 1, text: "开店选址" },
        { key: 6, text: "数据查询" },
      ],
      current: 1,
      erCodeShow: false,
      platform: ''
    };
  },
  methods: {
    // 关闭
    openClose() {
      this.erCodeShow = false;
    },
    expertsAnswer(id) {
      if (window.isMini) {
        UniUtils.goKefuPage()
        return
      }
      if(this.current==7){
        this.JSBridge.callHandler("startIMChat",    {
    "cardData":"{\"obj\":\"报告ID："+id+"\",\"type\":4}",
    "chatId":"CFZR6685V8293814934958914",
    "chatName":"Ai设计顾问",
    "type":6
})
      }else{
       if (window._appCode >= 175) {
      this.JSBridge.callHandler( 'onNormalizingCall', {
      type  : 5,
      url   : 'ONLINE_SERVICE',
      params: null,
    } );
  } else {
    this.JSBridge.callHandler(
      'callLiveChat',
      {
        type: 'normal',
        data: {},
      },
      ( st ) => {
      },
    );
  }
      }

    },
    loadData() {
      if (!this.list) this.initData();
      this.loading = true;
      this.HTTP.reportList(this.list.remote, "before", this.current)
        .then((res) => {
          console.log("reports:", res);
          this.loading = false;
          this.list.firstLoading = false;
          if (res.list.length) {
            this.list.list = this.list.list.concat(res.list);
            this.list.finished = !res.more;
          } else {
            this.list.finished = true
          }
          if (this.list.list.length) {
            this.list.recent = this.list.list[0].gmtCreate;
            let remote = this.list.list[this.list.list.length - 1].gmtCreate;
            // 重复报告id问题修复
            remote = remote.replace(/-/g, '/');
            const remoteDate = new Date(remote);
            const nextRemoteDate = new Date(remoteDate.getTime() - 1000);
            this.list.remote = formatDate(nextRemoteDate);
          }
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
          this.list.finished = true;
        });
    },
    loadNewData() {
      this.list.dataLoading = true;
      // 重新获取
      this.list.remote = null;
      this.HTTP.reportList(this.list.remote, "before", this.current)
        .then((res) => {
          this.list.list = res.list;
          if (this.list.list.length) {
            this.list.recent = this.list.list[0].gmtCreate;
            this.list.remote = this.list.list[this.list.list.length - 1].gmtCreate;
          }
          this.list.finished = !res.more;
          setTimeout(() => {
            this.list.dataLoading = false;
          }, 1000);
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          setTimeout(() => {
            this.list.dataLoading = false;
          }, 1000);
        });
    },
    onTap(id,stdQuestionId) {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.MYREPORT.TAP_OPEN);
      let url = "/charts/" + id;
      if(stdQuestionId===10119){
        url=`/h5/#/design/detail/${id}`
      }else if(stdQuestionId===10130){
        url=`/h5/#/design/effectPicture/detail/${id}`
      }
      this.swRouter(url);
    },
    change(res) {
      if (this.loading) {
        this.$toast("数据加载中，请勿过快切换！");
        return;
      }
      console.log('res--------')
      console.log(res)
      this.current = res.key;
      if (!this.list) this.loadData();
    },
    gotoDownload() {
      if (this.isClient) {
        this.JSBridge.callHandler("gotoPage", "my-download");
      } else {
        this.$toast("非客户端环境！");
      }
    },
    initData() {
      this.$set(this.dataMap, this.current, {
        list: [],
        recent: null,
        remote: null,
        total: 0,
        finished: false,
        dataLoading: false,
        firstLoading: true,
      });
      return this.list;
    },
  },
  computed: {
    list() {
      return this.dataMap[this.current] || null;
    },
  },
  created() {
    this.platform = sessionStorage.getItem("platform")
    // 第三方平台埋点
    if(this.platform) {
        this.HTTP.elemeSubmit({
            platform: this.platform,
            track: JSON.stringify([{
                pageId: "10042",
                timestamp: new Date().getTime()
            }])
        })
    }
    // this.light = this.$route.query.light || null; // 已废弃 处理高亮跳转
    const tabIndex = this.$route.query.index;
    if (["1", "2", "6", "4", "5",'7'].includes(tabIndex)) {
      this.current = tabIndex;
    }
    // console.log( 'get tabIndex:', tabIndex, ', typeof tabIdnex:', typeof tabIndex, ', current:', this.current );
  },
  mounted () {
    this.loadData();
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    if (this.$route.meta && this.$route.meta.clear) {
      this.JSBridge.callHandler("clearHistory", "my");
    }

    this.isClient = window.isClient;
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.reports {
  // display: flex;
  height: 100vh;
  overflow: hidden;
  // flex-direction: column;
  background-color: $c-pageBg;

  .btnColor {
    color: $c-main;
  }

  #{&}--view {
    padding: $padding2 $padding2 38vw $padding2;
  }
  .coupon_dialog{
    width: 100%;
    background: transparent;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep .van-dialog__content{
      position: relative;
      text-align: center;
    }
    img{
      // width: calc(100% - 10.13vw);
      height: 46.67vw;
    }
    .coupon_colse{
      width: 9.33vw;
      height: 9.33vw;
      border-radius: 4vw;
      margin: 6.67vw auto 0;
      overflow: hidden;
      ::v-deep .van-icon-close{
        font-size: 9.33vw;
        color: #fff;
      }
    }
  }
}
</style>
