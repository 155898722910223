<template>
    <div class="investment">
        <StateBar ></StateBar>
        <TitleHeader :title="investmentTitle" :back="true" style="border-bottom: 1px solid #EBEAEE;box-sizing: border-box;"></TitleHeader>
        <AutoView :header="true" :footer="false" class="investment--view">
            <div class="investment--content">
                <div class="card-box">
                    <div class="head-title">投资预算</div>
                    <!-- <div v-for="(val, index) in listArray" :key="index">
                        <p class="head-subTitle">{{val.name}}</p>
                        <brand-table :dataList="val.data"></brand-table>
                    </div>
                    <p class="tip">注：以上数据均为预估数值， 仅供参考</p> -->
                    <template v-if="listArray.costEstimateImage && listArray.profitEstimateImage">
                        <p class="head-subTitle">费用预估</p>
                        <img class="const_img" :src="listArray.costEstimateImage" alt="">
                        <p class="head-subTitle">盈利预估（受地域影响，仅供参考）</p>
                        <img class="const_img" :src="listArray.profitEstimateImage" alt="">
                    </template>

                    <template v-else-if="listArray.costEstimateImage && listArray.profitEstimateImage == null">
                        <p class="head-subTitle">费用预估</p>
                        <img class="const_img" :src="listArray.costEstimateImage" alt="">
                        <p class="head-subTitle">{{listArray.costList[1].name}}</p>
                        <brand-table :dataList="listArray.costList[1].data"></brand-table>
                        <p class="tip">注：以上数据均为预估数值， 仅供参考</p>
                    </template>

                    <template v-else-if="listArray.costEstimateImage == null && listArray.profitEstimateImage">
                        <p class="head-subTitle">{{listArray.costList[0].name}}</p>
                        <brand-table :dataList="listArray.costList[0].data"></brand-table>
                        <p class="head-subTitle">盈利预估（受地域影响，仅供参考）</p>
                        <img class="const_img" :src="listArray.profitEstimateImage" alt="">
                    </template>

                    <template v-else>
                        <div v-for="(val, index) in listArray.costList" :key="index">
                            <p class="head-subTitle">{{val.name}}</p>
                            <brand-table :dataList="val.data"></brand-table>
                        </div>
                        <p class="tip">注：以上数据均为预估数值， 仅供参考</p>
                    </template>
                </div>
            </div>
        </AutoView>
    </div>
</template>

<script>
    import { getLocalStorage } from "@/utils/index";
    import brandTable from '../components/newBrandDetail/brandTable'
    export default {
        name: "investmentDetails",
        components: {
            brandTable
        },
        data() {
            return {
                detailShow: false,
                investmentTitle: '',
                listArray: [],
            }
        },
        methods: {

        },
        mounted() {
            this.investmentTitle = this.$route.query.name;
            this.listArray = getLocalStorage("investmentList");
        }
    }
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
    .investment {
        height: 100vh;
        #{&}--content{
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding: 4vw;
            .card-box{
                background: #fff;
                .head-title{
                    font-size: 4.53vw;
                    font-weight: bold;
                    color: #1D2233;
                    line-height: 6.4vw;
                }
            }
            // 投资预算
            .head-subTitle{
                margin-top: 2.67vw;
                font-size: 3.47vw;
                color: #000000;
                line-height: 4.93vw;
            }
            .const_img{
                margin-top: 2.67vw;
                width: 100%;
            }
            .tip{
                font-size: 2.93vw;
                color: #999999;
                line-height: 4vw;
            }
        }
    }
</style>
