<template>
  <div class="search">
    <StateBar />
    <!--搜索框-->
    <searchBarFilter @search="searchData" @selectPop="selectPop" />
    <!-- 骨架屏占位图 -->
    <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

    <AutoView ref="view" :header="true" :footer="false" class="search--view" :style="{ opacity: loading ? 0 : 1 }">
      <div class="search--main">
        <div class="search--history" v-show="showHistory.length" ref="historyRef">
          <div class="search--history--header">
            <span>历史搜索</span>
            <img src="/static/discovery/delete.png" alt="" @click="deleteHistory" />
          </div>
          <div class="search--history-list">
            <span class="" v-for="(item, index) in showHistory" :key="index" @click="clickHistory(item)">{{ item.keyWord }}</span>
            <span class="last-icon" v-if="!canShowAll" @click="showAll=!showAll">
              <img :class="{'reverse-icon': showAll}"  src="/static/discovery/arrow-down-gray.png" alt="" />
            </span>
          </div>
        </div>
        <div class="hot-recommend-box" v-if="hotSearchBrandList.length || hotMallList.length">
          <div class="hot-recommend-tabs">
            <div class="hot-recommend-tab" :class="{'hot-recommend-active-tab': tabActive2===index}"
                 v-for="(item,index) in tabsList" :key="index" @click="selectTab(index)">
              <span class="hot-recommend-tab-item">{{ item }}</span>
            </div>
          </div>
          <!--热门品牌-->
          <div class="hot-brand-list" v-show="tabActive2===0">
            <div class="hot-brand-item" v-for="(item, index) in hotSearchBrandList" :key="index" @click="goDetail(item)">
              <div class="brand-sort"
                :class="{'brand-sort-first': index===0, 'brand-sort-second': index===1, 'brand-sort-third': index===2}"
              >{{ index + 1 }}</div>
              <div class="brand-icon">
                <van-image class="card--img s-radius__xs" fit="cover" lazy-load :src="item.logo || ''">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <div class="brand-info-right">
                <div class="brand-name">{{ item.name }}</div>
                <div class="brand-count">门店数：<span>{{ item.openingNumber || '--' }}</span></div>
              </div>
            </div>
          </div>
          <!--热门商场-->
          <div class="hot-mall-list" v-show="tabActive2===1">
            <div class="hot-mall-item" v-for="(item, index) in hotMallList" :key="index" @click="goDetail(item)">
              <div class="brand-sort"
                   :class="{'brand-sort-first': index===0, 'brand-sort-second': index===1, 'brand-sort-third': index===2}"
              >{{ index + 1 }}</div>
              <div class="brand-icon">
                <van-image class="card--img s-radius__xs" fit="cover" lazy-load :src="item.logo">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <div class="mall-info-right">
                <div class="mall-info-top">
                  <div class="mall-name">{{ item.name }}</div>
                  <div class="mall-city" v-if="item.city">{{ item.city }}</div>
                </div>
                <div class="mall-info-middle">{{ item.openDate }}</div>
                <div class="mall-info-bottom">{{ item.area }}万㎡商业面积</div>
              </div>
            </div>
          </div>
          <div class="look-more" @click="lookMore">
            查看更多
            <img src="/static/discovery/arrow-right-blue.png" alt="" />
          </div>
        </div>
      </div>
    </AutoView>
    <!-- 开通会员弹框 -->
    <OpenVip :showDialog="showDialog" @cancel="showDialog = false" />
  </div>
</template>

<script>
  import OpenVip from "@/components/openVip/index.vue";
  import searchBarFilter from './components/search-bar-filter'
  import SearchBar from "@/components/header/search";
  import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../utils';
  export default {
    name: 'brandSearch2',
    components: {
      SearchBar,
      searchBarFilter,
      OpenVip
    },
    computed: {
      showHistory() {
        if (this.showAll || this.canShowAll) {
          return this.history
        }
        return this.history2
      }
    },
    data() {
      return {
        tabsList: ['热门品牌', '热门商场'],
        tabActive: 0,
        tabActive2: 0,
        loading: false,
        keyword: '',
        history: [],
        history2: [],
        canShowAll: true, // 历史记录是否可以显示全部
        showAll: false, // 显示全部的历史记录
        hotSearchBrandList: [], // 热门品牌
        hotMallList: [], // 热门商场
        clientWidth: 0,
        showDialog: false
      }
    },
    created () {
    },
    mounted () {
      this.loading = true
      this.clientWidth = this.$refs.view.$el.clientWidth
      this.HTTP.querySearchHistory().then(res => {
        // console.log(res)
        if (res && res.length) {
          this.history = res.slice(0, 10)
          this.handleHistoryData()
        }
      })
      this.getHotBrands()
      this.getHotMalls()
    },
    methods: {
      // 处理历史搜索数据
      handleHistoryData() {
        let length = 0
        let index = 0
        let secondStartIndex = 0
        let secondRowWidth = 0

        const pageWidth = this.clientWidth - 4 * 2 * this.clientWidth / 100

        for (let i=0; i < this.history.length; i++) {
          // console.log('i=' + i)
          const keyWordW = this.calculationChartLength(this.history[i].keyWord)
          // console.log(keyWordW)
          const keyWordL = keyWordW + (2.8 * 2 + 2.133) * this.clientWidth / 100
          // console.log(keyWordL)
          if (secondStartIndex && i > secondStartIndex) {
            secondRowWidth += keyWordL
          }
          length += keyWordL

          // 找到第一行最后一组内容的index
          if (!secondStartIndex && length > pageWidth) {
            secondStartIndex = i
            // console.log(secondStartIndex)
            secondRowWidth = keyWordL
          }
          // 当第二行内容的长度大于容器长度
          // 当内容总长度大于实际可用长度
          const iconWidth = ((9.333 + 2.133) * this.clientWidth / 100) // “查看更多”的长度
          // console.log(i, secondRowWidth, pageWidth - iconWidth)
          if (secondRowWidth > pageWidth - iconWidth || length > pageWidth * 2 - iconWidth) {
            index = i
            // console.log(index)
            break
          }
          index = i + 1
        }
        this.canShowAll = index === this.history.length
        // console.log(index)
        this.history2 = this.history.slice(0, index)
      },
      clickHistory(item) {
        this.swRouter(`/brand/search/result?keyword=${item.keyWord}&index=${item.type-1}`)
      },
      goDetail(item) {
        if (this.tabActive2 === 0) {
          setLocalStorage("versionCode", window._appCode);
          this.HTTP.checkBrandStatus(item.code)
            .then((res) => {
              this.swRouter({
                path: "/reportView/brandLoader",
                query: {
                  reportId: item.code,
                },
              });
            })
            .catch((e) => {
              if (e.code == 201022) {
                this.showDialog = true;
              } else {
                this.$toast("网络不稳定，请稍候重试！");
              }
            });
          // this.swRouter("/brand/details/" + item.code);
        } else {
          this.HTTP.reportSubmit({
            goodsId: '10030',
            market: {
              code: item.code,
              text: item.name
            }
          }).then(res => {
            setLocalStorage("versionCode", window._appCode);
            this.swRouter(res.data.link.url.split("#")[1]);
          }).catch((e) => {
            if (e.code == 201022) {
              this.showDialog = true;
            } else {
              this.$toast("网络不稳定，请稍候重试！");
            }
          })
        }
      },
      searchData(val) {
        this.keyword = val
        this.keyword && this.HTTP.saveSearchHistory({ keyWord: this.keyword, type: this.tabActive + 1 })
        this.swRouter(`/brand/search/result?keyword=${this.keyword}&index=${this.tabActive}`)
      },
      selectPop(index) {
        this.tabActive = index
        // this.tabActive2 = index
      },
      lookMore() {
        if (this.tabActive2 === 0) {
          // 品牌评估页
          this.swRouter('/reportView/searchBrand')
        } else if (this.tabActive2 === 1) {
          // 商场列表页
          this.swRouter('/mall/search')
        }
      },
      deleteHistory() {
        this.HTTP.clearSearchHistory().then(res => {
          if (res) {
            this.history = []
            this.history2 = []
          }
        })
      },
      selectTab(index) {
        this.tabActive2 = index
      },
      getHotMalls() {
        this.HTTP.hotMallList().then(res => {
          this.hotMallList = res.map(el => {
            if (el.builtArea) {
              el.area = Number((el.builtArea / 10000).toFixed(2))
            }
            if (el.openingDate) {
              const year = el.openingDate.substring(0, 4)
              const month = el.openingDate.substring(4, 6)
              el.openDate = `${year}年`
              if (month) {
                el.openDate += `${Number(month)}月开业`
              }
            }
            return {
              ...el
            }
          }) || []
        })
      },
      getHotBrands() {
        this.HTTP.hotBrandList()
          .then((res) => {
            console.log(res)
            this.hotSearchBrandList = res || []
          }).finally(() => {
            this.loading = false
        }).catch(() => {
          this.$toast("网络不稳定，正在重试！");
        })
      },
      change(data) {
        this.keyword = data.keyword;
      },
      clear() {},
      // 计算字符长度
      calculationChartLength(str) {
        let hanCount = 0;
        let charCount = 0;
        let upperCharCount = 0;
        if (str) {
          for (let j = 0; j < str.length; ++j) {
            let unicode = str.charCodeAt(j);
            if (unicode > 150) {
              hanCount++;
            } else if (unicode >= 65 && unicode <= 90) {
              upperCharCount++;
            } else {
              charCount++;
            }
          }
        }
        // console.log(currentLength)
        const hanWidth = 3.2 * this.clientWidth / 100
        return 4 * charCount + 7 * upperCharCount + hanWidth * hanCount
      }
    }
  };
</script>

<style lang="scss" scoped>
  .search {
    .right-title {
      font-size: 3.73vw;
      color: #347fff;
    }
    #{&}--view {
      scroll-behavior: smooth;
      position: relative;
      background: #fff;
    }
    #{&}--main {
      position: relative;
      /*height: 100%;*/
      padding: 4vw;
      .hot-recommend-box {
        border-radius: 1.333vw;
        border: .267vw solid #FFD4BE;
        .look-more {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3.2vw;
          font-weight: 400;
          color: #347FFF;
          width: 19.2vw;
          height: 6.667vw;
          background: #F7F7F7;
          border-radius: 3.333vw;
          margin: 2vw auto 4vw;
          img {
            width: 1.333vw;
            margin-left: 0.667vw;
          }
        }
        .hot-mall-list {
          padding: 0 2.667vw;
          .hot-mall-item {
            display: flex;
            align-items: center;
            padding: 2.667vw 0;
            border-bottom: 0.133vw solid #E7E7E7;
            &:last-child {
              border-bottom: 0;
            }
            .mall-info-right {
              color: #666666;
              font-size: 3.2vw;
              margin-left: 2.667vw;
              flex: 1;
              overflow: hidden;
              height: 17.333vw;
              .mall-info-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2vw;
                overflow: hidden;
                line-height: 5.6vw;
                .mall-name {
                  font-weight: 600;
                  color: #1D2233;
                  font-size: 4vw;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  flex: 1;
                  margin-right: 1vw;
                  line-height: 5.6vw;
                }
                .mall-city {
                  font-size: 3.333vw;
                }
              }
              .mall-info-middle {
                margin-bottom: 1.6vw;
              }
            }
            .brand-icon {
              width: 17.333vw;
              height: 17.333vw;
              border-radius: 0.667vw;
              margin-left: 2.667vw;
              .card--img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .brand-sort {
          font-weight: bold;
          color: #BDBDBD;
          font-size: 4.267vw;
          width: 4vw;
          &.brand-sort-first {
            color: #FF3627;
          }
          &.brand-sort-second {
            color: #FF8702;
          }
          &.brand-sort-third {
            color: #FFBB26;
          }
        }
        .hot-brand-list {
          padding: 0 2.667vw;
          .hot-brand-item {
            display: flex;
            align-items: center;
            padding: 2.667vw 0;
            border-bottom: 0.133vw solid #E7E7E7;
            &:last-child {
              border-bottom: 0;
            }
            .brand-info-right {
              margin-left: 2.667vw;
              .brand-name {
                font-weight: 600;
                color: #1D2233;
                font-size: 4vw;
                margin-bottom: 2.4vw;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                margin-right: 1.333vw;
                line-height: 5.6vw;
              }
              .brand-count {
                font-weight: 400;
                color: #999999;
                font-size: 3.2vw;
                span {
                  color: #666666;
                }
              }
            }
            .brand-icon {
              width: 13.333vw;
              height: 13.333vw;
              border-radius: 0.667vw;
              margin-left: 2.667vw;
              .card--img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .hot-recommend-tabs {
          display: flex;
          align-items: center;
          height: 12vw;
          background: linear-gradient(to bottom, rgba(255, 89, 0, 0.14) 0%, rgba(255, 255, 255, 0) 100%);
          .hot-recommend-tab {
            font-size: 3.733vw;
            color: #666666;
            height: 100%;
            padding: 2.667vw 3.467vw 0 3.467vw;
            box-sizing: border-box;
            .hot-recommend-tab-item {
              line-height: 5.333vw;
            }
            &.hot-recommend-active-tab {
              font-size: 4.267vw;
              font-weight: 600;
              color: #333333;
              position: relative;
              &:after {
                position: absolute;
                bottom: 1.333vw;
                left: 50%;
                transform: translateX(-50%);
                content: '';
                width: 9.067vw;
                height: 1.067vw;
                background: #347FFF;
                border-radius: 0.533vw;
              }
            }
          }
        }
      }
      .search--history {
        margin-bottom: 4.667vw;
        .search--history-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.267vw;
          span {
            height: 7.733vw;
            background: #F7F7F7;
            border-radius: 3.8665vw;
            font-weight: 400;
            color: #666666;
            font-size: 3.2vw;
            padding: 0 2.8vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2.133vw;
            margin-top: 2.133vw;
            &.last-icon {
              width: 9.333vw;
              box-sizing: border-box;
              img {
                width: 2.133vw;
                height: 1.333vw;
                transition: all 0.5s;
              }
              .reverse-icon {
                transform: rotate(-180deg);
              }
            }
          }
        }
        .search--history--header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 6vw;
          span {
            font-weight: 600;
            color: #333333;
            font-size: 4.267vw;
          }
          img {
            width: 4vw;
            height: 4vw;
          }
        }
      }
    }

  }
</style>
