<template>
  <div class="coupon-container">
        <StateBar ref="stateBar"></StateBar>
        <TitleHeader title="最新资讯" bgcolor="transparent" color="black" :back="true">

        </TitleHeader>
        <AutoView :header="true" :footer="false" class="coupon--view">
            <van-skeleton :loading="latestLoading" title :animate="false" :row="3" avatar class="s-skeleton s-radius__s">
                <div class="goods_list">
                    <div class="list_swrap">
                        <EmptyCard v-if="list.length === 0" type="community"></EmptyCard>
                        <template v-else>
                            <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多了"
                                :immediate-check="false"
                                @load="loadData">
                                <div class="list_list"
                                    v-for="(val, index) in list"
                                    :class="(index+1) == list.length ? 'lastBorder': '' "
                                    :key="index" @click="openDetai(val.id)">
                                    <div class="item_list_content">
                                        <h3>{{val.title}}</h3>
                                        <p>
                                            {{val.publishTimeFormat}} ·
                                            <span v-if="val.clickCountFormat == null || val.clickCountFormat == ''">0留言</span>
                                            <span v-else>{{val.commentCount}}</span>
                                        </p>
                                    </div>
                                    <van-image class="item_list_img" lazy-load :src="val.image" />
                                </div>
                            </van-list>
                        </template>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                latestLoading: true,  //
                loading: true,
                finished: false,
                params: {
                    type: 1,
                    size: 10,
                    current: 1,
                },
                list: [],
                total: 0,
            }
        },
        methods: {
            loadData() {
                this.loading = true;
                this.HTTP.getLatest(this.params).then(res => {
                    this.latestLoading = false;
                    this.loading = false;
                    this.params.current++;
                    this.total = res.data.total;
                    if(this.list.length) {
                        this.list = this.list.concat(res.data.data);
                    } else {
                        this.list = res.data.data;
                    }
                    if (res.data.data.length < res.data.size || this.list.length >= this.total) {
                        this.finished = true; // 完成加载
                    }
                    console.log(res.data)
                }).catch((e) => {
                    this.$toast("网络连接不稳定，请稍候重试！");
                    this.loading = false;
                });
            },
            // 跳转到详情
            openDetai(id) {
                this.swRouter( '/h5/#/community/article/'+id );
            },
        },
        created() {
            this.loadData();
        },
    }
</script>
<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .coupon-container {
        height: 100vh;
        .coupon--view {
            position: relative;
            background: #F7F7F7;
            width: 100%;
            .list_swrap{
                width: 100%;
                box-sizing: border-box;
                .list_list{
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding:  3.33vw 4vw;
                    box-sizing: border-box;
                    background: #fff;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        width: calc(100% - 8vw);
                        left: 4vw;
                        right: 4vw;
                        height: 1px;
                        top: 100%;
                        background: #E7E7E7;
                        z-index: 2;
                    }
                    .item_list_content{
                        flex: 1;
                        overflow: hidden;
                        h3{
                            height: 10.67vw;
                            font-size: 3.73vw;
                            font-weight: bold;
                            color: #1D2233;
                            line-height: 5.33vw;
                            word-break: break-all;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                        p{
                            margin-top: 0.8vw;
                            color: #999999;
                            vertical-align: middle;
                            font-size: 3.2vw;
                            line-height: 4.4vw;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .item_list_img{
                        width: 28.4vw;height: 16vw;margin-left: 2.27vw;
                        border-radius: 1.06vw;overflow: hidden;
                        ::v-deep .van-image__error, ::v-deep .van-image__img, ::v-deep .van-image__loading{
                            width: 100%;height: 100%;object-fit: cover;
                            // object-fit: scale-down;
                        }
                    }
                }
                .lastBorder{
                    &::before {
                        height: 0px;
                    }
                }
            }
        }
    }
</style>
