<template>
	<div class="box" v-if="slides.length">
		<carousel-3d :autoplay="true" :autoplayTimeout="3000" :perspective="35" :display="5" :animationSpeed="2000" :controlsVisible="false" :controlsHeight="60" :space="550" @after-slide-change="onSlideChange" :startIndex="startIndex">
			<slide v-for="(item, i) in slides" :index="i" :key="i">
				<template slot-scope="obj">
					<div class="banner-item">
						<!-- <img :src="item" alt=""> -->
                        <van-image fit="contain" :src="item" @click="imageClick"/>
					</div>
				</template>
			</slide>
		</carousel-3d>
	</div>
</template>
<script>
	import {
		Carousel3d,
		Slide
	} from 'vue-carousel-3d'
	export default {
		data() {
			return {
			}
		},
        props: {
            slides: {
                type: Array,
                default: () => {}
            },
            startIndex: {
                type: Number,
                default: 0
            }
        },
		mounted() {
		},
		methods: {
            onSlideChange(index) {
                this.$emit('update:currentIndex', index)
            },
            imageClick() {
                this.$emit('click')
            }
		},
		components: {
			Carousel3d,
			Slide
		}
	}
</script>
<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.carousel-3d-container {
		margin: 0 auto !important;
        height: 776px !important;
	}

	.carousel-3d-slide {
		border: none;
		border-width: 0;
        cursor: pointer;
        height: 746px !important;
		background: transparent;
        margin-top: 30px;
	}

	.box {
		width: 100%;
        height: 746px;
		left: 0%;
		z-index: 20;

		.banner-item {
            width: 363px;
			background: #FFFFFF;
			::v-deep .van-image {
                width: 100% !important;
                // height: 100%;
			}
		}
	}
</style>
