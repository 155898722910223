<template>
  <div class="intention-form">
    <div class="header">
      <StateBar />
      <TitleHeader :title="pageTitle" bgcolor="transparent" color="black" :back="true"
        style="border-bottom: 1px solid #f3f4f7">
        <span slot="right" class="detail" @click="openTips">提交</span>
      </TitleHeader>
    </div>
    <AutoView :header="true" :footer="false">
      <div class="banner" />
      <div class="main-content">
        <van-cell-group>
          <van-field v-model="form.name" name="真实姓名" label="真实姓名" placeholder="请填写" :rules="[{ required: true }]"
            class="link-right" maxlength="10" />
        </van-cell-group>
        <van-cell-group>
          <van-cell readonly clickable title="求租城市" @click="openPicker" class="link-right link-other">
            <template #default>
              <div class="van-field__control" v-if="form.rentCity && form.rentProvince">
                {{ `${form.rentProvince} ${form.rentCity}` }}
              </div>
              <div class="van-field__control" v-else>无法获取定位，请<span style="color: #347FFF;">手动选择</span></div>
            </template>
            <template #right-icon>
              <div class="location-icon" />
            </template>
          </van-cell>
          <van-field readonly clickable :value="rentRegion" label="求租区域" placeholder="请选择" class="link-right"
            @click="openCommunity">
            <template #right-icon>
              <div class="select-go" />
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group>
          <van-field readonly clickable :value="formatCategory" label="准备经营行业" placeholder="请选择" @click="openIndustries"
            class="link-right">
            <template #right-icon>
              <div class="select-go" />
            </template>
          </van-field>
          <van-field readonly clickable :value="businessCategory" label="求租商铺类型" placeholder="请选择" @click="openFormats"
            class="link-right">
            <template #right-icon>
              <div class="select-go" />
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="基础信息" class="link-right link-base">
            <template #label>
              <div class="base-container">
                <div class="bc-item bc-margin-right" @click="openArea">
                  <div class="title">期望实用面积</div>
                  <div class="info" v-if="areaText">{{ areaText }}</div>
                  <div class="info active" v-else>请填写</div>
                </div>
                <div class="bc-item bc-margin-left" @click="openRent">
                  <div class="title">期望月租金</div>
                  <div class="info" v-if="rentText">{{ rentText }}</div>
                  <div class="info active" v-else>请填写</div>
                </div>
              </div>
              <div class="base-container">
                <div class="bc-item bc-margin-right" @click="openBudget">
                  <div class="title">投资预算<span>(选填)</span></div>
                  <div class="info" v-if="budgetText">{{ budgetText }}</div>
                  <div class="info active" v-else>请填写</div>
                </div>
                <div class="bc-item bc-margin-left" @click="openTransfer">
                  <div class="title">转让费<span>(选填)</span></div>
                  <div class="info" v-if="transferText">{{ transferText }}</div>
                  <div class="info" v-else-if="form.transferType == '0'">-</div>
                  <div class="info active" v-else>请选择</div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell class="link-right link-base">
            <template #title>
              <div class="title-base">
                商铺要求
                <span>(选填，点击选择)</span>
              </div>
            </template>
            <template #label>
              <shop-requirements :edit="true" :data.sync="form.intentionShopRequire" :showAll="true" />
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell class="link-right link-left link-base">
            <template #title>
              <div class="title-base">
                其他要求
                <span>(选填)</span>
              </div>
            </template>
            <template #label>
              <van-field v-model="form.otherRequire" rows="7" autosize type="textarea" maxlength="200"
                input-align="left" class="text-disign" placeholder="请填写你的额外要求" show-word-limit />
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <van-popup v-model="showArea" round position="bottom" :style="clientPlatStyle">
        <div class="popup-container">
          <div class="popup-header">
            <div class="header-cannel" @click="cancelArea">取消</div>
            <div class="header-title">期望实用面积</div>
            <div class="header-submit" @click="submitArea">完成</div>
          </div>
          <div class="popup-content">
            <van-field v-model="areaMin" type="digit" placeholder="最小面积" input-align="center" ref="areaMinInput"
              :maxlength="9">
              <template #extra>
                <span> ㎡</span>
              </template>
            </van-field>
            <div class="split">
              <div class="split-bar" />
            </div>
            <van-field v-model="areaMax" type="digit" placeholder="最大面积" input-align="center" ref="areaMaxInput"
              :maxlength="9">
              <template #extra>
                <span> ㎡</span>
              </template>
            </van-field>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showRent" round position="bottom" :style="clientPlatStyle">
        <div class="popup-container">
          <div class="popup-header">
            <div class="header-cannel" @click="cancelRent">取消</div>
            <div class="header-title">期望月租金</div>
            <div class="header-submit" @click="submitRent">完成</div>
          </div>
          <div class="popup-content">
            <van-field v-model="rentMin" type="digit" placeholder="自定义最低价" input-align="center" ref="rentMinInput"
              :maxlength="9" :formatter="formatter">
              <template #left-icon>
                <span>￥</span>
              </template>
            </van-field>
            <div class="split">
              <div class="split-bar" />
            </div>
            <van-field v-model="rentMax" type="digit" placeholder="自定义最高价" input-align="center" ref="rentMaxInput"
              :maxlength="9" :formatter="formatter">
              <template #left-icon>
                <span>￥</span>
              </template>
            </van-field>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showBudget" round position="bottom" :style="clientPlatStyle">
        <div class="popup-container">
          <div class="popup-header">
            <div class="header-cannel" @click="cancelBudget">取消</div>
            <div class="header-title">投资预算</div>
            <div class="header-submit" @click="submitBudget">完成</div>
          </div>
          <div class="popup-content">
            <van-field v-model="budgetMin" type="digit" placeholder="自定义最低价" input-align="center" ref="budgetMinInput"
              :maxlength="9">
              <template #extra>
                <span> 万</span>
              </template>
            </van-field>
            <div class="split">
              <div class="split-bar" />
            </div>
            <van-field v-model="budgetMax" type="digit" placeholder="自定义最高价" input-align="center" ref="budgetMaxInput"
              :maxlength="9">
              <template #extra>
                <span> 万</span>
              </template>
            </van-field>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker ref="cityPicker" show-toolbar title="选择求租城市" :columns="columns" @confirm="pickerConfirm"
          @cancel="pickerCannel" value-key="name" />
      </van-popup>
      <van-popup v-model="showSubmitTips">
        <div class="popup-tips">
          <div class="popup-tips-title">提交求租信息</div>
          <div class="popup-tips-content">提交后，将显示在「求租广场」，求租信息默认90天后自动关闭</div>
          <div class="popup-btn-bar">
            <div class="pbb-cannel" @click="showSubmitTips = false;">
              <p>取消</p>
            </div>
            <div class="pbb-sumbit" @click="submit">
              <p>提交</p>
            </div>
          </div>
        </div>
      </van-popup>
      <van-action-sheet v-model="showTransfer" :round="false" :actions="actions" cancel-text="取消" description="转让费"
        close-on-click-action @select="onSelect" @cancel="onCancel" />
      <van-overlay :show="showLoading">
        <div class="wrapper">
          <div class="block">
            <van-loading color="#fff" vertical>提交中... </van-loading>
          </div>
        </div>
      </van-overlay>
    </AutoView>
  </div>
</template>

<script>
import { defaultNeeds } from './default-shops-needs.js';
import shopRequirements from './shopRequirements.vue';
import { getAllCity, saveOrUpdateIntentionRent, getIntentionRentInfoById, getIntentionEchoV2ByUserId } from '@/request/asking4Rent';
export default {
  data() {
    return {
      form: {
        type: 5,
        name: "",
        rentCity: "", // 城市
        rentRegion: [], // 区域
        rentProvince: "", //省份
        formatCategory: [], // 行业
        businessCategory: [], // 类型
        rentMin: "", // 月租
        rentMax: "",
        areaMin: "", // 面积
        areaMax: "",
        budgetMin: "", // 预算
        budgetMax: "",
        transferType: "", // 转让费
        otherRequire: "", // 其他需求
        intentionLongitude: null, //经度
        intentionLatitude: null, //纬度
        intentionShopRequire: defaultNeeds,
      },
      actions: [
        { name: '接受', value: 1 },
        { name: '不接受', value: 2 }
      ],
      columns: [],
      rentMin: "", // 月租
      rentMax: "",
      areaMin: "", // 面积
      areaMax: "",
      budgetMin: "", // 预算
      budgetMax: "",
      showRent: false,
      showArea: false,
      showPicker: false,
      showBudget: false,
      showTransfer: false,
      showLoading: false,
      isGetLocation: true,
      showSubmitTips: false
    };
  },
  created() {
    this.JSBridge.callHandler(
      "putString",
      JSON.stringify({
        key: "sscm-muti-formats",
        value: JSON.stringify([]),
      })
    );
    this.JSBridge.callHandler(
      "putString",
      JSON.stringify({
        key: "sscm-muti-Industries",
        value: JSON.stringify([]),
      })
    );
    this.JSBridge.callHandler(
      "putString",
      JSON.stringify({
        key: "sscm-muti-community",
        value: JSON.stringify([]),
      })
    );
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const id = this.$route.query.id;
      getAllCity().then(res => {
        if (res) {
          res.map(v1 => {
            v1.children && v1.children.map(v2 => {
              v2.children && delete v2.children
            })
          })
        }
        this.columns = res || [];
      })

      if (id) {
        getIntentionRentInfoById(id).then(res => {
          this.form = {
            ...res,
            rentMin: res.rentMin += "",
            rentMax: res.rentMax += "",
            areaMin: res.areaMin += "", // 面积
            areaMax: res.areaMax += "",
            budgetMin: res.budgetMin += "", // 预算
            budgetMax: res.budgetMax += "",
            rentRegion: JSON.parse(res.rentRegion),
            formatCategory: JSON.parse(res.formatCategory),
            businessCategory: JSON.parse(res.businessCategory),
            intentionShopRequire: JSON.parse(res.intentionShopRequire),
          }
          this.JSBridge.callHandler(
            "putString",
            JSON.stringify({
              key: "sscm-muti-formats",
              value: res.formatCategory,
            })
          );
          this.JSBridge.callHandler(
            "putString",
            JSON.stringify({
              key: "sscm-muti-Industries",
              value: res.businessCategory,
            })
          );
          this.JSBridge.callHandler(
            "putString",
            JSON.stringify({
              key: "sscm-muti-community",
              value: res.rentRegion,
            })
          );
        })
      }
      else {
        getIntentionEchoV2ByUserId().then((res) => {
          this.form.name = res.name || "";
        });
        this.JSBridge.callHandler("getLocation", null, (data) => {
          this.isGetLocation = false;
          if (typeof data === "string") data = JSON.parse(data);
          if (data) {
            this.form.locationLatitude = data.latitude;
            this.form.locationLongitude = data.longitude;
            this.form.rentCity = data.city;
            this.form.rentProvince = data.province;
          }
        });
      }
      this.JSBridge.registerHandler("onAppResume", (data) => {
        console.log("app 调用 onAppResume 函数成功");
        this.JSBridge.callHandler("getString", "sscm-muti-formats", (data) => {
          // console.log('formats', data)
          let temp = typeof data == 'string' ? JSON.parse(data) : data;
          this.form.formatCategory = temp.length ? temp : this.form.formatCategory;
        });
        this.JSBridge.callHandler("getString", "sscm-muti-Industries", (data) => {
          // console.log('Industries', data)
          let temp = typeof data == 'string' ? JSON.parse(data) : data;
          this.form.businessCategory = temp.length ? temp : this.form.businessCategory;
        });
        this.JSBridge.callHandler("getString", "sscm-muti-community", (data) => {
          // console.log('community', data)
          let temp = typeof data == 'string' ? JSON.parse(data) : data;
          this.form.rentRegion = temp.length ? temp : this.form.rentRegion;
        });
      });
    },
    submit() {
      if (this.showLoading) return;
      this.showLoading = true;

      const filterData = (data) => {
        return data.filter((val) => {
          if (val.children) val.children = filterData(val.children);
          return val.num > 0 || val.is_select == true;
        });
      };
      const data = JSON.parse(JSON.stringify(this.form));

      const params = {
        ...data,
        rentRegion: JSON.stringify(filterData(data.rentRegion)),
        formatCategory: JSON.stringify(filterData(data.formatCategory)),
        businessCategory: JSON.stringify(filterData(data.businessCategory)),
        intentionShopRequire: JSON.stringify(data.intentionShopRequire),
        rentMin: data.rentMin.replace(/￥|\,/g, "") * 1,
        rentMax: data.rentMax.replace(/￥|\,/g, "") * 1,
        budgetMin: data.budgetMin.replace(/￥|\,/g, "") * 1,
        budgetMax: data.budgetMax.replace(/￥|\,/g, "") * 1,
      };
      console.log(params)
      saveOrUpdateIntentionRent(params)
        .then((res) => {
          console.log(res)
          if (res) if (res) this.toSuccessful(res);
          else this.$toast.fail("提交失败");
        })
        .finally((e) => {
          this.showLoading = false;
        });
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3180100, 3180101);
    },
    openTips() {
      const data = JSON.parse(JSON.stringify(this.form));
      if (this.checkBase(data) || this.checkOther(data)) {
        return;
      }
      this.showSubmitTips = true;
    },
    openMap() {
      this.JSBridge.callHandler(
        "callLocationPicker",
        {
          component: {
            poi: true, // 位置选择（扎针）
            now: true, // 展示当前位置
            radius: true, // 半径选择
            search: true, // 搜索栏
          },
          value: {
            lat: this.form.intentionLatitude,
            lng: this.form.intentionLongitude,
            radius: this.form.radius * 1000, // 单位m
            search: this.form.poiName,
          },
        },
        (data) => {
          // 将数据转换后发送到处理方法
          if (data) {
            if (typeof data === "string") {
              data = JSON.parse(data); // 兼容安卓数据解析
            }
            this.selectedLocation([data.lng, data.lat, data.search, data.cityName], data.radius);
          }
        }
      );
    },
    openPicker() {
      this.showPicker = true;
    },
    pickerCannel() {
      this.showPicker = false;
    },
    pickerConfirm(value) {
      const citys = this.$refs.cityPicker.getValues();
      this.form.rentCity = citys[1].name;
      this.form.rentProvince = citys[0].name;
      this.form.rentRegion = [];
      this.JSBridge.callHandler(
        "putString",
        JSON.stringify({
          key: "sscm-muti-community",
          value: JSON.stringify([]),
        })
      );
      this.pickerCannel();
    },
    formatter(value) {
      function format(num) {
        var reg = /\d{1,3}(?=(\d{3})+$)/g;
        return (num + "").replace(reg, "$&,");
      }
      if (value) return format(value);
      else return "";
    },
    formatterM2(value) {
      if (value) return value + "㎡";
      else return "";
    },
    checkData(v1, v2) {
      v1 += "";
      v2 += "";
      let n1 = v1.replace(/￥|\,/g, "");
      let n2 = v2.replace(/￥|\,/g, "");
      if (n2 - n1 > 0) return true;
      else return false;
    },
    openArea() {
      this.showArea = true;
      this.$nextTick(res => {
        this.$refs['areaMinInput'].focus();
      })
    },
    openRent() {
      this.showRent = true;
      this.$nextTick(res => {
        this.$refs['rentMinInput'].focus();
      })
    },
    openBudget() {
      this.showBudget = true;
      this.$nextTick(res => {
        this.$refs['budgetMinInput'].focus();
      })
    },
    onSelect(val) {
      this.form.transferType = val.value;
    },
    onCancel() {

    },
    openTransfer() {
      this.showTransfer = true
    },
    cancelRent() {
      this.showRent = false;
    },
    cancelArea() {
      this.showArea = false;
    },
    cancelBudget() {
      this.showBudget = false;
    },
    submitRent() {
      if (this.checkData(this.rentMin, this.rentMax)) {
        this.showRent = false;
        this.form.rentMin = this.rentMin;
        this.form.rentMax = this.rentMax;
      }
      else if (!this.rentMin) {
        this.$toast({
          message: "请填写最低价",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['rentMinInput'].focus();
      }
      else if (!this.rentMax) {
        this.$toast({
          message: "请填写最高价",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['rentMaxInput'].focus();
      }
      else {
        this.$toast({
          message: "最高价不能低于最低价",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['rentMaxInput'].focus();
      }
    },
    submitArea() {
      if (this.checkData(this.areaMin, this.areaMax)) {
        this.showArea = false;
        this.form.areaMin = this.areaMin;
        this.form.areaMax = this.areaMax;
      }
      else if (!this.areaMin) {
        this.$toast({
          message: "请填写最小面积",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['areaMinInput'].focus();
      }
      else if (!this.areaMax) {
        this.$toast({
          message: "请填写最大面积",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['areaMaxInput'].focus();
      }
      else {
        this.$toast({
          message: "最大面积不能小于最小面积",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['areaMaxInput'].focus();
      }
    },
    submitBudget() {
      if (this.checkData(this.budgetMin, this.budgetMax)) {
        this.showBudget = false;
        this.form.budgetMin = this.budgetMin;
        this.form.budgetMax = this.budgetMax;
      }
      else if (!this.budgetMin) {
        this.$toast({
          message: "请填写最低价",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['budgetMinInput'].focus();
      }
      else if (!this.budgetMax) {
        this.$toast({
          message: "请填写最高价",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['budgetMaxInput'].focus();
      }
      else {
        this.$toast({
          message: "最高价不能低于最低价",
          icon: "/static/icon/toast_wrong.png",
        });
        this.$refs['budgetMaxInput'].focus();
      }
    },
    openFormats() {
      this.swRouter({
        path: "/shopEcology/multiChoiceFormats",
      });
    },
    openIndustries() {
      this.swRouter({
        path: "/shopEcology/multiChoiceIndustries",
      });
    },
    openCommunity() {
      if (!this.form.rentCity || !this.form.rentProvince) {
        this.$toast({
          message: "请先选择求租城市",
          icon: "/static/icon/toast_wrong.png",
        });
        return
      }
      this.swRouter({
        path: "/shopEcology/multiCommunitySelect",
        query: {
          cityName: this.form.rentCity
        }
      });
    },
    selectClick(item, key) {
      this.form.type = item.id;
    },
    selectedLocation(e, radius) {
      this.isGetLocation = false;
      this.showPoiPicker = false;
      this.form.intentionLongitude = e[0];
      this.form.intentionLatitude = e[1];
      this.form.poiName = e[2];
      this.form.address = e[3];
      this.form.radius = radius / 1000;
    },
    getByteLength(str = "") {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      return len;
    },
    checkBase(data) {
      if (!data.name) {
        this.$toast({
          message: "请填写姓名",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else if (this.getByteLength(data.name) > 20) {
        this.$toast({
          message: "名称太长，请重新输入",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else if (!data.rentCity) {
        this.$toast({
          message: "请选择求租城市",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else if (this.computedListNumb(data.rentRegion) == 0) {
        this.$toast({
          message: "请选择意求租区域",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else return false
    },
    checkOther(data) {
      if (this.computedListNumb(data.formatCategory) == 0) {
        this.$toast({
          message: "请选择经营行业",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else if (this.computedListNumb(data.businessCategory) == 0) {
        this.$toast({
          message: "请选择商铺类型",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else if (!data.areaMin || !data.areaMax) {
        this.$toast({
          message: "请填写期望实用面积",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else if (!data.rentMin || !data.rentMax) {
        this.$toast({
          message: "请填写期望月租金",
          icon: "/static/icon/toast_wrong.png",
        });
        return true;
      } else return false
    },
    toSuccessful(res) {
      // 关掉页面
      if (window.isIOS) this.JSBridge.callHandler("closeCurPage");
      else this.JSBridge.callHandler("closePage");
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134900, 134901);
      this.swRouter({
        path: "/shopEcology/asking4RentSuccessful",
        query: {
          id: res,
        },
      });
    },
    computedListNumb(data) {
      let num = 0;
      if (data && data.length) {
        data.map((v) => (num += v.num || 0));
        return num;
      } else return num;
    },
  },
  computed: {
    areaText() {
      let v1 = this.form.areaMin;
      let v2 = this.form.areaMax;
      if (v1 && v2 && this.checkData(v1, v2)) {
        return `${v1}㎡～${v2}㎡`;
      } else return "";
    },
    rentText() {
      let v1 = this.form.rentMin;
      let v2 = this.form.rentMax;
      if (v1 && v2 && this.checkData(v1, v2)) {
        return `￥${v1}～￥${v2}`;
      } else return "";
    },
    hasLnglat() {
      return this.form.intentionLongitude && this.form.intentionLatitude && this.form.poiName;
    },
    budgetText() {
      let v1 = this.form.budgetMin;
      let v2 = this.form.budgetMax;
      if (v1 && v2 && this.checkData(v1, v2)) {
        return `${v1}万～${v2}万`;
      } else return "";
    },
    transferText() {
      let v = this.form.transferType;
      if (v) {
        let c = this.actions.find(a => a.value == v);
        return c ? c.name : ''
      }
      else return ''
    },
    clientPlatStyle() {
      if (window.isIOS) return `height: 15%`
      else return `height: 22%`
    },
    formatCategory() {
      let data = this.form.formatCategory;
      if (data && data.length) {
        let num = this.computedListNumb(data);
        return num ? `已选${num}类` : "";
      } else return "";
    },
    businessCategory() {
      let data = this.form.businessCategory;
      if (data && data.length) {
        let num = this.computedListNumb(data);
        return num ? `已选${num}类` : "";
      } else return "";
    },
    rentRegion() {
      let text = '';
      let arr1 = [];
      let data = JSON.parse(JSON.stringify(this.form.rentRegion));
      if (data && data.length) {
        data.filter(v => v.num > 0).map((v1) => {
          v1.children.map((v2) => {
            if (v2.is_select) arr1.push(`${v1.name}·${v2.name}`);
          })
        });
        arr1.map((v3, i3) => { text += !i3 ? v3 : `、${v3}` });
        return text || "";
      } else return "";
    },
    pageTitle() {
      const id = this.$route.query.id;
      return id ? '修改求租信息' : '发布求租信息';
    }
  },
  components: { shopRequirements }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
  @return $args/750 * 100+vw;
}

.intention-form {
  .header {
    .detail {
      font-size: vw(28);
      font-weight: 600;
      color: #347fff;
      line-height: vw(40);
    }

    .loading {
      font-size: vw(28);
      font-weight: 600;
      line-height: vw(40);

      ::v-deep .van-loading__spinner {
        width: vw(28);
        height: vw(28);
      }
    }
  }

  .banner {
    width: 100%;
    height: vw(300);
    background-size: cover;
    background-image: url(/static/shopEcology/asking4rent-banner.png);
  }

  .main-content {
    padding-top: vw(30);
    background: #F8F8F9;
    transform: translateY(vw(-52));
    border-radius: 26px 26px 0px 0px;
  }

  .autoView {
    background: #F8F8F9;
  }

  .wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .block {
      width: 120px;
      height: 120px;
    }
  }

  .body-box {
    padding: vw(20) vw(20) vw(0);
    box-sizing: border-box;

    .title {
      height: vw(48);
      font-size: vw(34);
      font-weight: 600;
      line-height: vw(48);
      text-align: center;
      color: #1d2233;
    }

    .form-box {
      .form-item {
        margin-bottom: vw(10);

        .label {
          height: vw(72);
          font-size: vw(30);
          font-weight: 600;
          color: #121622;
          line-height: vw(72);
        }

        .value {
          height: vw(80);
          border-radius: vw(10);

          input {
            border: none;
            height: 100%;
            width: 100%;
            background: #f7f8fb;
            font-size: vw(28);
            color: #1d2233;
            padding: 0 vw(30);
            box-sizing: border-box;
          }
        }
      }
    }

    .select-box {
      margin-bottom: 10px;

      .left-title {
        height: vw(82);
        font-size: vw(30);
        font-weight: 600;
        color: #121622;
        line-height: vw(82);
      }

      .right-body {
        display: flex;
        justify-content: space-between;

        .right-body-item {
          height: vw(174);
          border-radius: vw(10);
          border: vw(2.5) solid #e4e7ed;
          box-sizing: border-box;
          padding: vw(20) vw(10) vw(20) vw(30);
          width: vw(345);
          background: #fff;
          display: flex;

          .left-box {
            .title {
              font-size: vw(28);
              font-weight: 600;
              height: vw(40);
              line-height: vw(40);
              text-align: left;
              color: #1d2233;
            }

            .content {
              margin-top: vw(4);
              font-size: vw(22);
              height: vw(28);
              line-height: vw(30);
              color: #676c84;
            }
          }

          .right-box {
            img {
              width: vw(100);
              height: vw(100);
            }
          }
        }

        .active {
          background: #F4F9FF;
          border-color: #347fff;

          .title {
            color: #347fff !important;
          }
        }
      }
    }

    .mask-button {
      padding-top: vw(30);
      box-sizing: border-box;

      .button {
        height: vw(80);
        background: #347fff;
        border-radius: vw(10);
        font-size: vw(30);
        font-weight: 600;
        color: #ffffff;
        line-height: vw(80);
        text-align: center;
      }
    }
  }

  ::v-deep .van-cell-group {
    border-radius: vw(10);
    margin: vw(20) vw(20);
  }

  .link-right {
    padding: vw(30) vw(20) vw(30) vw(30);
    background-color: transparent;

    ::v-deep .van-cell__title,
    ::v-deep .van-field__label {
      font-size: vw(28);
      font-weight: 600;
      color: #333333;
      line-height: vw(40);
    }

    ::v-deep .van-field__control {
      font-size: vw(28);
      text-align: right;
      color: #676c84;
      line-height: vw(40);
    }

    ::v-deep .title-base {
      span {
        font-size: vw(24);
        color: #999999;
        line-height: vw(33);
      }
    }

    ::v-deep .van-field__right-icon {
      padding-left: 0;
    }

    &::-webkit-input-placeholder {
      color: #999999;
    }
  }

  .link-left {
    ::v-deep .van-field__control {
      text-align: left;
    }

  }

  .link-base {

    ::v-deep .van-cell__title,
    ::v-deep .van-field__label {
      font-size: vw(32);
      font-weight: 600;
      color: #333333;
      line-height: vw(45);
    }

    ::v-deep .van-cell__label {
      margin-top: vw(20);
    }

    ::v-deep .text-disign {
      height: vw(350);
      background: #F7F6F9;
      border-radius: vw(10);

      .van-field__control {
        font-size: vw(26);
        color: #333333;
        line-height: vw(37);
        font-weight: 400;

        &::-webkit-input-placeholder {
          color: #999999;
        }
      }
    }
  }

  .link-blue {
    ::v-deep .van-field__control {
      &::-webkit-input-placeholder {
        color: #347fff;
      }
    }

    ::v-deep .van-cell__right-icon {
      color: #347fff;
    }
  }

  .link-other {
    ::v-deep .van-cell__title {
      flex: 0 0 auto;
    }
  }

  .loadingLocation {
    width: 100%;
    min-height: vw(126);
    background: linear-gradient(90deg, #f7f8fb 0%, rgba(247, 248, 251, 0) 100%);
    border-radius: vw(10) vw(0) vw(0) vw(10);

    .has-lngLat {
      min-height: vw(75);
      padding: vw(27) vw(5) vw(24) vw(30);
      display: flex;
      align-items: center;

      .has-item-content {
        flex: 1;
        min-height: vw(75);
        overflow: hidden;
        margin-right: vw(30);

        .ht-title {
          display: flex;

          .poi-name {
            height: vw(37);
            font-size: vw(26);
            color: #1d2233;
            line-height: vw(37);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100% - 150 / 750 * 100vw);
          }

          .poi-radius {
            height: vw(33);
            font-size: vw(24);
            font-weight: 400;
            color: #676c84;
            line-height: vw(33);
          }
        }

        .ht-addr {
          min-height: vw(33);
          font-size: vw(24);
          font-weight: 400;
          color: #676c84;
          line-height: vw(33);
          margin-top: vw(9);
        }
      }
    }

    .is-get-loading {
      display: flex;
      padding: vw(45) vw(5) vw(44) vw(30);
    }
  }

  .select-go {
    width: vw(13);
    height: vw(26);
    margin-left: vw(30);
    background: url(/static/shopEcology/shopRequirements/icon/list_icon_go.png) no-repeat center;
    background-size: vw(13) vw(26);
  }

  .location-icon {
    width: vw(30);
    height: vw(36);
    margin-left: 8px;
    background: url(/static/shopEcology/shopRequirements/icon/location-line.png) no-repeat center;
    background-size: vw(30) vw(36);
  }

  .location_error {
    width: vw(33);
    height: vw(33);
    margin-right: vw(2);
    background: url(/static/icon/location_error.png) no-repeat center;
    background-size: vw(33) vw(33);
  }

  .manual-title {
    height: vw(37);
    font-size: vw(26);
    color: #347fff;
    line-height: vw(37);
    font-weight: 400;
  }

  .location-load-title {
    height: vw(37);
    font-size: vw(26);
    color: #676c84;
    line-height: vw(37);
    font-weight: 400;
    flex: 1;
  }

  .popup-container {
    padding: vw(30) vw(30) vw(0);

    .popup-header {
      display: flex;
      text-align: center;
      margin-bottom: vw(40);

      .header-title {
        flex: 1;
        font-size: vw(30);
        font-weight: 600;
        color: #1d2233;
        line-height: vw(42);
      }

      .header-submit {
        // width: vw(70);
        font-size: vw(30);
        font-weight: 600;
        line-height: vw(42);
        color: #347fff;
      }

      .header-cannel {
        // width: vw(70);
        font-size: vw(30);
        font-weight: 600;
        line-height: vw(42);
        color: #676c84;
      }
    }

    .popup-content {
      display: flex;
      margin-bottom: vw(10);

      .split {
        margin: vw(39) vw(30);

        .split-bar {
          width: vw(30);
          height: vw(2);
          background: #abb3c1;
        }
      }

      .van-field {
        height: vw(80);
        background: #f7f8fb;
        border-radius: vw(10);
        border: vw(2) solid #e4e7ed;
      }
    }
  }

  .base-container {
    display: flex;
    width: 100%;

    .bc-item {
      flex: 1;
      width: 50%;
      height: vw(100);
      text-align: center;
      border-radius: vw(6);
      padding: vw(26) vw(0);
      background: #F7F7F7;
      border: vw(2) solid #E7E7E7;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .title {
        color: #333333;
        font-size: vw(28);
        font-weight: 600;
        line-height: vw(40);

        span {
          font-size: vw(24);
          color: #999999;
          line-height: vw(33);
        }
      }

      .info {
        margin-top: vw(27);
        font-size: vw(24);
        line-height: vw(33);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .active {
        color: #999999;
      }
    }

    .bc-margin-left {
      margin-left: vw(10);
    }

    .bc-margin-right {
      margin-right: vw(10);
    }
  }

  .base-container+.base-container {
    margin-top: vw(20);
  }

  ::v-deep .van-popup--center {
    width: 100%;
    background: transparent;

    .popup-tips {
      background: #FFFFFF;
      border-radius: 24px;
      margin: 0 vw(48);
      height: vw(302);
      padding: vw(50) vw(54) vw(42) vw(54);

      .popup-tips-title {
        font-weight: 600;
        color: #333333;
        line-height: vw(48);
        font-size: vw(34);
        height: vw(48);
        text-align: center;
      }

      .popup-tips-content {
        margin-top: vw(28);
        font-size: vw(28);
        color: #999999;
        line-height: vw(44);
        text-align: center;
      }

      .popup-btn-bar {
        display: flex;
        margin-top: vw(60);

        .pbb-cannel {
          flex: 1;
          height: vw(90);
          background: #FFFFFF;
          border-radius: vw(10);
          border: vw(2) solid #347FFF;
          box-sizing: border-box;
          margin-right: vw(24);
          text-align: center;

          p {
            padding: vw(24) 0;
            height: vw(42);
            font-size: vw(30);
            font-weight: 600;
            color: #347FFF;
            line-height: vw(42);
          }
        }

        .pbb-sumbit {
          flex: 1;
          height: vw(90);
          text-align: center;
          border-radius: vw(10);
          background: linear-gradient(319deg, #3888F8 0%, #86C6FF 100%);

          p {
            height: vw(42);
            font-size: vw(30);
            font-weight: 600;
            color: #FFFFFF;
            line-height: vw(42);
            padding: vw(24) 0;
          }
        }
      }
    }
  }

  ::v-deep .van-grid {
    padding-left: 0 !important;
  }

  ::v-deep .van-action-sheet__description {
    font-size: vw(26);
    color: #808080;
    line-height: vw(39);
    background: #F8F8F8;
    padding: vw(24) vw(0) vw(25) vw(0);

    &:after {
      content: none;
    }
  }

  ::v-deep .van-action-sheet__content {
    .van-action-sheet__item {
      font-size: vw(26);
      color: #333333;
      line-height: vw(40);
      border-bottom: vw(1) #E7E7E7 solid;
    }
  }

  ::v-deep .van-action-sheet__cancel {
    font-size: vw(28);
    color: #333333;
    line-height: vw(40);
  }

  ::v-deep .van-field__word-limit {
    color: #999999;
  }
}
</style>
