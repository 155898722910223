<template>
    <div class="invite">
        <!-- 介绍界面 -->
        <img src="/static/img/invite_210107.png" class="invite--bg">
        <div class="invite--footer">
            <button type="button" @click="showRegister = true">立即领取福利</button>
        </div>
        <!-- 注册界面 -->
        <transition name="fade">
            <div v-show="showRegister" class="invite--mask" @click.self="showRegister = false">
                <div v-if="!isRegistered" class="panel">
                    <form class="invite--form">
                        <h3 class="f-tac">请输入手机号码领取福利</h3>
                        <input type="phone" ref="phone" v-model="form.phone" :disabled="submitting" placeholder="请输入手机号" maxlength="11" />
                        <div class="nc-wrap">
                            <div id="sscm_share_nc"></div>
                        </div>
                        <div class="f-clear">
                            <input type="text" ref="code" v-model="form.code" :disabled="submitting" placeholder="请输入验证码" maxlength="6" class="f-fl w_1" />
                            <input type="button" :disabled="submitting || isSendCode" :value="isSendCode ? waitingTime + '秒' : '获取验证码'" class="f-fr w_2" @click="onGetCode" />
                        </div>
                        <button type="button" :disabled="submitting" @click="onSubmit">{{ submitting ? '请稍候' : '立即领取' }}</button>
                    </form>
                </div>
                <div v-else class="panel">
                    <form class="invite--form">
                        <img src="/static/icon/success_light.png" />
                        <h3 class="f-tac">领取成功</h3>
                        <p class="info f-tac">立即下载上上参谋APP，查看报告</p>
                        <button type="button" @click="download">立即查看</button>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// import AWSC from 'AWSC';

const BASE_NUM = 60; // 默认等待时间

export default {
    name: 'invite',
    data () {
        return {
            title: '上上参谋，生意人的数据军师',

            loading     : true,
            showRegister: false,
            submitting  : false,
            isRegistered: false,
            isSendCode  : false,
            timer       : 0,
            waitingTime : BASE_NUM,

            inviteDetail: {
                avatar            : '',
                discount          : '',
                username          : '',
                invitePic         : '',
                couponExist       : false,
                haveRegisterCoupon: false,
                inviteDescribe    : '',
            },
            form: {
                code      : '',
                phone     : '',
                fromType  : '',
                inviteCode: '',
                inviteType: 1, // 普通邀请 定值
            },

            // 是否进行了滑块验证
            hasVerification: false,
            verification   : {
                phone    : '',
                scene    : 'nc_login',
                sessionId: '',
                sig      : '',
                token    : ''
            }
        };
    },
    methods: {
        download () {
            top.location.href = this.HTTP.getAppStore();
        },
        validate ( justPhone ) {
            if ( ! ( /^1[3456789]\d{9}$/.test( this.form.phone ) ) ) {
                this.$toast( '请输入正确的手机号' );
                this.$refs.phone.focus();
                return false;
            }
            if ( justPhone ) return true;
            if ( ! ( /^\d{6}$/.test( this.form.code ) ) ) {
                this.$toast( '请输入6位数验证码' );
                this.$refs.code.focus();
                return false;
            }
            return true;
        },
        onGetCode () {
            if ( this.isSendCode || this.validate( true ) === false ) return;
            this.verification.phone = this.form.phone;
            if ( ! this.hasVerification ) {
                this.$toast( '请拖动滑块进行验证' );
                return;
            }
            this.verificationCode();
        },
        onSubmit () {
            if ( this.submitting || this.validate() === false ) return;
            this.submitting = true;
            this.HTTP.inviteRegister( this.form ).then( ( res ) => {
                this.isRegistered = true;
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg );
                } else {
                    this.$toast( '网络不稳定，请稍候重试！' );
                }
                setTimeout( () => { this.submitting = false; }, 2000 );
            } );
        },
        initAWSC () {
            const appkey = 'FFFF0N2N0000000069E7';
            const scene = this.verification.scene;
            window.AWSC.use( 'nc', ( state, module ) => {
                // console.log( 'awsc loaded' );
                // 初始化
                // const ncToken = [ appkey, ( new Date() ).getTime(), Math.random() ].join( ':' );
                window.nc = module.init( {
                    // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                    appkey  : appkey,
                    // 使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
                    scene   : scene,
                    // 声明滑动验证需要渲染的目标ID。
                    renderTo: 'sscm_share_nc',
                    // 前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                    success : ( data ) => {
                        // window.console && console.log( 'data:', data );
                        this.hasVerification = true;
                        this.verification.sessionId = data.sessionId;
                        this.verification.sig = data.sig;
                        this.verification.token = data.token;
                        // this.verification.token = ncToken;
                    },
                    // 滑动验证失败时触发该回调参数。
                    fail: function ( failCode ) {
                        window.console && console.error( failCode );
                    },
                    // 验证码加载出现异常时触发该回调参数。
                    error: function ( errorCode ) {
                        window.console && console.error( errorCode );
                    }
                } );
            } );
        },
        verificationCode () {
            this.HTTP.verification( this.verification ).then( ( res ) => {
                // console.log( 'res:', res );
                if ( res.code === 0 ) {
                    this.isSendCode = true;
                    this.$toast( '验证码发送成功' );
                    clearInterval( this.timer );
                    this.timer = setInterval( () => {
                        if ( this.waitingTime === 1 ) {
                            clearInterval( this.timer );
                            this.isSendCode  = false;
                            this.waitingTime = BASE_NUM;
                        } else {
                            this.waitingTime --;
                        }
                    }, 1000 );
                } else {
                    this.resetVerification();
                }
            } ).catch( ( e ) => {
                this.resetVerification();
            } );
        },
        resetVerification () {
            this.$toast( '滑块验证失败，请检查手机号，重新滑动验证' );
            window.nc.reset();
            this.hasVerification = false;
        }
    },
    created () {
        // 设置标题
        document.title = this.title;
        // 解析邀请码
        this.form.inviteCode = this.$route.params.token;
        if ( this.form.inviteCode ) {
            this.HTTP.getInviteDetail( this.form.inviteCode ).then( ( res ) => {
                this.inviteDetail = res;
                this.loading      = false;
            } );
        }
        // 渠道检测
        this.form.fromType = this.$route.query.fromtype;
        if ( ! this.form.fromType ) {
            if ( location.href.indexOf( 'singlemessage' ) > - 1 ) {
                this.form.fromType = 'singlemessage';
            } else if ( location.href.indexOf( 'timeline' ) > - 1 ) {
                this.form.fromType = 'timeline';
            }
        }
        // 进行上报
        if ( this.form.fromType ) {
            this.HTTP.reportPV( this.form.fromType, this.form.inviteType ).then( ( res ) => {} );
        }
    },
    beforeMount () {
        // console.log( 'invite before mount' );
        const script = document.createElement( 'script' );
        script.type = 'text/javascript';
        script.src = 'https://g.alicdn.com/AWSC/AWSC/awsc.js';
        if ( script.readyState ) {
            script.onreadystatechange = () => {
                if ( script.readyState === 'loaded' || script.readyState === 'complete' ) {
                    script.onreadystatechange = null;
                    // console.log( 'script has loaded by onreadystatechange' );
                    this.initAWSC();
                }
            };
        } else {
            script.onload = () => {
                // console.log( 'script has loaded by on onload' );
                this.initAWSC();
            };
        }
        document.getElementsByTagName( 'head' )[ 0 ].appendChild( script );
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.invite {
    position: relative;
    min-height: 100vh;
    background-color: #451DB2;
    padding-bottom: 20vw;
    box-sizing: border-box;
    // 25vw

    #{&}--bg {
        width: 100%;
    }

    #{&}--footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 20vw;
        background: linear-gradient(to bottom, #7335E6, #451DB4);
        border-radius: $radius_s $radius_s 0 0;
        text-align: center;
        padding: $margin $padding;
        box-sizing: border-box;
        box-shadow: 0 -2vw 5vw rgba(#451DB4, .3);

        .info {
            @include font( #FFBB00, 2.66vw, normal );
            margin-top: $margin * 0.5;
        }
    }

    #{&}--form {
        position: absolute;
        top: $padding;
        width: calc( 84vw - #{ $padding * 1.6 } );
        z-index: 2;

        input, button {
            @include ani;
            display: block;
            width: 100%;
            height: 13vw;
            font-size: $font_400;
            line-height: normal;
            padding: 0 4.8vw;
            margin-bottom: 3.6vw;
            border-radius: $radius_xs;
            border: none;
            box-sizing: border-box;
            -webkit-appearance: none;

            &::-webkit-input-placeholder {
                color: #AEB1BD;
            }
            &[disabled] {
                color: #D2D2D2!important;
                opacity: .8;
            }
        }

        .w_1 {
            width: 60%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .w_2 {
            width: 40%;
            @include font( #451DB4, 2.93vw, bold );
            background-color: white;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .nc-wrap {
            position: block;
            width: 100%;
            height: 34px;
            margin-bottom: 3.6vw;
        }
    }

    button {
        background: linear-gradient(to right, #FFA943, #FE6D40);
        color: white;
        font-size: $font_373;
        font-weight: bold;
        width: 100%;
        height: 13vw;
        border-radius: $radius_xs;
        border: none;
    }

    #{&}--mask {
        @include flexColumn;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, .7);

        .panel {
            position: absolute;
            width: 84vw;
            height: 80vw;
            top: 25%;
            padding: $padding $padding * .8;
            box-sizing: border-box;
            border-radius: $radius;
            background: linear-gradient(to bottom, #9C67FF, #6334E3);

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                width: calc( 100% - 2px );
                height: calc( 100% - 2px );
                border-radius: $radius;
                background: linear-gradient(to bottom, #7335E6, #451DB4);
            }

            // &:after {
            //     content: '';
            //     position: absolute;
            //     top: 1vw;
            //     left: 0;
            //     width: 90vw;
            //     height: 18vw;
            //     margin-left: -3vw;
            //     background: url(/static/img/invite_star.png) no-repeat;
            //     background-size: contain;
            // }

            img {
                display: block;
                width: 17.6vw;
                height: 17.6vw;
                margin: 0 auto 6.1vw;
            }

            h3 {
                @include font( #FFDB00, $font_560, bold );
                margin-bottom: $margin * 2;
            }

            .info {
                color: white;
                font-size: 2.93vw;
                margin-bottom: $padding * 1;
                opacity: .8;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        @include ani(.5s);
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
}
</style>
<style lang="scss">
.nc-wrap {
    .nc-container #nc_1_wrapper {
        width: 100%;
    }
}
</style>
