<template>
  <div class="prices">
    <div
      class="item"
      v-for="(item, index) in prices"
      :key="index"
      :class="{active: checkId == item.id}"
      @click="check(item)"
    >
      <p class="time">{{item.name}}</p>
      <p class="price"><span>¥</span>{{item.showPrice}}</p>
      <p class="day-price"><s>¥{{item.salePrice}}</s></p>
      <div class="footer">
        <p>{{item.name}}数据查询</p>
        <p>{{item.pkgTimes}}次服务套餐</p>
      </div>
      <div class="discounts" v-if="item.tag">
        <span>{{ item.tag }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    prices: {
      type: Array,
      default: () => []
    },
    checkId: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    num(str){
      try {
        let i = 1;
        if (str.includes('年')){
          i = str.replace('年', '');
          i = parseInt(i) * 240;
        } else {
          i = str.replace('个月', '');
          i = parseInt(i) * 20;
        }
        return i + '次';
      } catch (e) {
        return ''
      }
    },
    check(item){
      this.$emit('check', item);
    },
  }
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import "@/styles/index.scss";
*{
  box-sizing: border-box;
}
.prices{
  white-space: nowrap;
  margin-top: 3.2vw;
  padding: vw(35) vw(15) vw(25) vw(15);
  width: 100%;
  overflow: auto;
  .item{
    position: relative;
    width: vw(190);
    background: #F2F2F2;
    border-radius: vw(10);
    padding-top: vw(25);
    display: inline-block;
    margin-left: vw(15);
    border: 1px solid #E7E7E7;
    &.active{
      background: #FFF1F1;
      border: 1px solid #F8C2C2;
      .footer{
        background: #F8C2C2;
        p{
          color: #A0232D;
        }
      }
    }
    .time{
      font-size: vw(32);
      font-weight: 600;
      color: #1D2233;
      line-height: vw(45);
      text-align: center;
    }
    .price{
      font-size: vw(48);
      font-weight: bold;
      color: #A0232D;
      line-height: 100%;
      margin-top: vw(6);
      text-align: center;
      span{
        font-size: vw(28);
        margin-right: vw(5);
        font-weight: bold;
      }
    }
    .day-price{
      margin-top: vw(6);
      font-size: vw(20);
      font-weight: 500;
      color: #666666;
      line-height: vw(28);
      text-align: center;
    }
    .footer{
      width: 100%;
      background: #E7E7E7;
      padding: vw(25) vw(0) vw(13) vw(0);
      margin-top: vw(16);
      border-radius: vw(0) vw(0) vw(8) vw(8);
      p{
        width: 100%;
        font-size: vw(20);
        font-weight: 500;
        color: #1D2233;
        line-height: 100%;
        text-align: center;
        margin-bottom: vw(13);
        //transform: scale(0.9);
      }
    }
    .discounts {
      position: absolute;
      left: vw(-2);
      width: 19.06vw;
      height: vw(38);
      line-height: 4.8vw;
      text-align: center;
      background: linear-gradient(213deg, #FE3326 0%, #FE5548 100%);
      border-radius: 1.86vw 0.266vw 2.66vw 0;
      font-size: 2.66vw;
      font-weight: 600;
      color: #ffffff;
      top: -1.77vw;
      span {
        display: inline-block;
        transform: scale(0.9);
      }
    }
  }
}
//.list {
//  // width: 100%;
//  height: auto;
//  padding: 3.2vw 4vw;
//  padding-right: 0;
//  .vip_box {
//    width: 100%;
//    height: auto;
//    white-space: nowrap;
//    overflow: hidden;
//    overflow-x: auto;
//    padding-top: 1.5vw;
//    .one {
//      width: 22.67vw;
//      height: 28vw;
//      box-sizing: border-box;
//      background: #F2F2F2;
//      border-radius: 1.33vw;
//      border: 0.27vw solid #e7e7e7;
//      margin-right: 2.4vw;
//      display: inline-block;
//      padding: 3.47vw 1.33vw;
//      text-align: center;
//      position: relative;
//      h4 {
//        font-size: vw(32);
//        font-weight: bold;
//        color: #666666;
//        line-height: 6vw;
//      }
//      .vipP {
//        color: #955100;
//      }
//      .svipP {
//        color: #A0232D;
//      }
//      p {
//        margin-top: 1.33vw;
//        font-size: vw(48);
//        font-weight: bold;
//        sub {
//          font-size: vw(28);
//          line-height: normal;
//          vertical-align: bottom;
//          position: relative;
//          top: -1.07vw;
//        }
//        span {
//          line-height: 7.87vw;
//          vertical-align: middle;
//        }
//      }
//      .vipDay {
//        color: #8d5c21;
//      }
//      .svipDay {
//        color: #A0232D;
//      }
//      .day {
//        font-size: 2.93vw;
//        line-height: 4vw;
//        margin-top: 1.87vw;
//      }
//      &:last-child {
//        margin-right: 4vw;
//      }
//    }
//    .discounts {
//      left: -0.27vw;
//    }
//    .activeIndex {
//      position: relative;
//      // overflow: hidden;
//      .discounts {
//        left: -0.4vw;
//      }
//    }
//  }
//}
</style>
