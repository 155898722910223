import { render, staticRenderFns } from "./invoice.vue?vue&type=template&id=a2970bd6&scoped=true"
import script from "./invoice.vue?vue&type=script&lang=js"
export * from "./invoice.vue?vue&type=script&lang=js"
import style0 from "./invoice.vue?vue&type=style&index=0&id=a2970bd6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2970bd6",
  null
  
)

export default component.exports