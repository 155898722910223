<template>
    <div class="wrapper">
        <StateBar></StateBar>
        <TitleHeader title="协议签署" bgcolor="transparent" :back="true"></TitleHeader>
		<AutoView :footer="false" class="">
            <div class="agreement-wrapper">
                <div class="head">
                    <h3>商铺租赁代理协议</h3>
                    <p class="right">最新版本生效日期：2022年06月09日</p>
                    <p>【审慎阅读】您在购买“商铺转租服务”之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款。如您对协议有任何疑问，可向《上上参谋》平台客服咨询。</p>
                    <p>【签约动作】当您点击“立即签署协议”按钮后，即表示您已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果您不同意本协议或其中的任何条款约定，您应立即停止购买行为。</p>
                </div>
                <div class="content">
                    <div class="title">一、签约主体</div>
                    <p>【平等主体】本协议由您与上上参谋经营者共同签署，本协议对您与上上参谋经营者均具有合同效力。</p>
                    <p>【上上参谋平台主体信息】上上参谋平台经营者是指经营上上参谋平台的各法律主体（包含不限于“深圳市数位汇聚科技有限公司”）。本协议项下，上上参谋平台经营者可能根据上上参谋平台的业务调整而发生变更，变更后的上上参谋平台经营者与您共同履行本协议并向您提供服务，上上参谋平台经营者的变更不会影响您本协议项下的权益。上上参谋平台经营者还有可能因为提供新的上上参谋平台服务而新增，如您使用新增的上上参谋平台服务的，视为您同意新增的上上参谋平台经营者与您共同履行本协议。</p>
                </div>

                <div class="content">
                    <div class="title">二、委托事项</div>
                    <p>1. 您同意将商铺委托给上上参谋平台，由上上参谋平台代理此商铺的租赁相关事宜。您保证上上参谋平台对所委托的房屋具有租赁代理权。</p>
                    <p>2. 您同意支付费用给上上参谋平台，委托上上参谋平台，对您的房屋进行实地勘察、收集房屋数据、对外发布房屋信息以及租赁信息等行为。</p>
                </div>

                <div class="content">
                    <div class="title">三、委托期限</div>
                    <p>自付款购买《商铺转租服务》当日开始计算，累计90天服务期限。期限结束后，合同自动终止，如需继续享受本服务，则要再次购买《商铺转租服务》。</p>
                </div>

                <div class="content">
                    <div class="title">四、您的义务</div>
                    <p>1. 应保证自己提供的商铺资料真实、合法；</p>
                    <p>2. 应对上上参谋平台的服务内容提供必要的协助与配合；</p>
                    <p>3. 应对上上参谋平台提供的承租人资料保密；</p>
                    <p>4. 不得在委托期限内及期限届满后6个月内与上上参谋平台介绍的承租人进行私下交易；</p>
                    <p>5. 应向上上参谋平台提供商铺的图片及视频，或协助上上参谋平台到现场拍摄照片及视频。</p>
                </div>

                <div class="content">
                    <div class="title">五、上上参谋平台义务</div>
                    <p>1. 应认真完成您的委托事项，按照商铺用途和您对承租人条件的特别要求寻找承租人，将处理情况及时向您如实汇报，并为您与承租人签订商铺租赁合同提供联络、协助、撮合等服务；</p>
                    <p>2. 不得提供虚假信息、隐瞒重要事实或与他人恶意串通，损害您的利益；</p>
                    <p>3. 收取必要费用、佣金的，应向您开具合法发票。</p>
                </div>

                <div class="content">
                    <div class="title">六、佣金</div>
                    <p>1. 当您签署本协议并付款完成后，上上参谋平台经营者会与您协商佣金事宜，具体佣金数额和支付方式需签订补充协议。</p>
                    <p>2. 委托事项未完成的，上上参谋平台不得要求支付佣金。</p>
                </div>

                <div class="content">
                    <div class="title">七、本合同解除的条件</div>
                    <p>1. 核实商铺状况与您提供的资料不一致，上上参谋平台要求您对合同进行修改而您拒绝修改的，上上参谋平台有权解除合同；</p>
                    <p>2. 您没有商铺所有权证书或证明自己对出租商铺依法享有出租权利的其他证明或身份证、营业执照等身份资格证明的，或提供虚假的商铺资料的，上上参谋平台有权解除合同，由此造成的上上参谋平台一切损失，均由您承担。</p>
                </div>

                <div class="content">
                    <div class="title">八、违约责任</div>
                    <p>1. 您与上上参谋平台介绍的承租人进行私下交易的，上上参谋有权要求您赔付一个月的租金作为上上参谋平台的服务佣金；</p>
                    <p>2. 上上参谋平台提供虚假信息、隐瞒重要事实或有恶意串通行为的，应退还已收取的佣金。</p>
                </div>

                <div class="content">
                    <div class="title">九、合同争议的解决办法</div>
                    <p>因本合同引起的或与本合同有关的任何争议，应友好协商解决，协商或调解不成的，任一方可依法向 原告所在地有管辖权的人民法院起诉。</p>
                </div>
            </div>
            <div class="submitFooter">
                <div class="agreement" @click="selectClick">
                    <img src="static/shopEcology/lease/agreement-checked.png" v-if="selected">
                    <img src="static/shopEcology/lease/agreement-uncheck.png" v-else>
                    <span>我已完整阅读以上协议，并同意协议内容</span>
                </div>
                <van-button type="info" block class="s-radius__s" :class="{isPayed: isPayed, uncheck: !selected }" @click="signClick">{{btnText}}</van-button>
            </div>
		</AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAgree: '',
            isPayed: ''
        }
    },
    computed: {
        selected() {
            if(this.isPayed) {
                return true
            } else if(!this.isPayed && this.isAgree) {
                return true
            } else return false
        },
        btnText() {
            return this.isPayed ? '已签署协议' : '立即签署协议'
        }
    },
    mounted() {
        this.isAgree = this.$route.query.isAgree === 'true'
        this.isPayed = this.$route.query.isPayed === 'true'
    },
    methods: {
        signClick() {
            if(this.isPayed) {
                return
            }
            if(this.selected) {
               this.isPayed = true
               // this.JSBridge.callHandler("onNormalizingCall", {
               //      type: 5,
               //      url: "SHOP_INTENT_SQUARE",
               //      id: null,
               //      params: null,
               //      trackData: null,
               // });
               // this.swRouter('/shopEcology/lease/create-order?isAgree=true')
              this.JSBridge.callHandler('putString', JSON.stringify({
                key: 'agreement',
                value: 'true',
              }));
              this.JSBridge.callHandler("onBackPressed");
            }
        },
        selectClick() {
            if(!this.isPayed) {
                this.isAgree = !this.isAgree
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import "@/styles/index.scss";
.agreement-wrapper {
    background-color: #F8F8F9;
    font-size: vw(26);
    line-height: vw(37);
    padding: vw(30);
    p {
        text-indent: vw(35);
    }
    .head {
        h3 {
            text-align: center;
        }
        .right {
            text-align: right;
            margin: vw(10) 0;
        }
    }
    .content {
        margin-top: vw(20);
        .title {
            font-weight: bold;
            margin-bottom: vw(10);
        }
    }
}
.submitFooter {
    width: 100%;
    padding: $headerMargin vw(30);
    box-sizing: border-box;
    box-shadow: 0 -0.267vw 0.8vw 0 #ebeaee;
    ::v-deep .van-button {
        height: vw(80);
        font-size: $font_400;
        line-height: normal;
        margin-top: vw(20);
    }
    .agreement{
        font-weight: 400;
        color: #999999;
        padding-top: vw(10);
        margin: 0 3.2vw 0 4vw;
        font-size: 0;
        img{
            vertical-align: middle;
            width: vw(28);
        }
        span{
            line-height: vw(30);
            vertical-align: middle;
            font-size: vw(22);
            margin-left: vw(8);
        }
    }
}
.uncheck {
    background-color: #A0A9B9 !important;
    border-color: #A0A9B9 !important
}
.isPayed {
    background-color: rgba(52, 127, 255, 0.65) !important;
    border-color: rgba(52, 127, 255, 0.65) !important
}
</style>
