<template>
    <div class="wraper-com">
        <ul v-if="title.length > 0" class="wrap-title">
            <li class="line">
                <span class="wrap-legend"></span>
                <span class="wrap-label">{{ title }}</span>
                <img v-if="showIcon" @click="$emit('clickTips')" class="wrap-icon" src="/static/report/icon_ask.png" alt="" />
                <span v-if="tips" class="wrap-tips">{{ tips }}</span>
            </li>
            <li class="wrap-tips" v-if="longTips">{{ longTips }}</li>
            <li style="flex: 1"></li>
            <li class="more-new" v-if="getMore" @click="$emit('openMoreData')">{{ moreTips }}</li>
        </ul>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        tips: {
            type: String,
            default: "",
        },
        longTips: {
            type: String,
            default: "",
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        getMore: {
            type: Boolean,
            default: false,
        },
        moreTips: {
            type: String,
            default: "查看更多",
        },
    },
};
</script>

<style lang="scss" scoped>
.wraper-com {
    position: relative;
    border-radius: 1.33vw;
    background-color: #fff;
    padding: 4vw 4vw 5.33vw 4vw;
    margin-bottom: 2.67vw;

    .wrap-title {
        position: relative;
        padding-bottom: 2.67vw;
        text-align: left;
        display: flex;
        align-items: center;

        .line {
            display: inline-flex;
            align-items: center;

            .wrap-legend {
                position: relative;
                width: 1.07vw;
                height: 3.73vw;
                border-radius: 0.53vw;
                background-color: #347fff;
            }

            .wrap-label {
                position: relative;
                margin-left: 1.33vw;
                color: #1d2233;
                font-size: 4vw;
                font-weight: bold;
                text-align: left;
                word-break: keep-all;
            }

            .wrap-icon {
                width: 3.73vw;
                height: 3.73vw;
                margin-left: 1.07vw;
            }
        }

        .wrap-tips {
            font-size: 2.67vw;
            color: #999;
            margin-left: 1.6vw;
        }

        .more-new {
            text-align: right;
            color: #347fff;
            font-size: 3.2vw;
            position: relative;
            padding-right: 2.13vw;

            &::after {
                position: absolute;
                content: "";
                top: 50%;
                right: 0;
                width: 1.33vw;
                height: 2.13vw;
                transform: translateY(-50%);
                background: url("/static/report/icon_jump.png") no-repeat center;
                background-size: 100%;
            }
        }
    }
}
</style>
