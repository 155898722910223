<template>
    <!-- <div class="comment_text">
        <p class="comments_fab" :class="textOver && !btnFold ? 'moreClass':''" ref="divRef">{{commontText}}</p>
        <div class="btn" v-if="showAndHide">
            <span @click="btnFold = !btnFold">{{ btnFold ? "收起" : "展开" }}</span>
            <img @click="btnFold = !btnFold" :src="btnFold ? '/static/icon/commont_up.png' : '/static/icon/commont_down.png'" alt="">
        </div>
    </div> -->
    <div :class="showTotal ? 'total-introduce' : 'detailed-introduce'">
        <div class="intro-content" :title="introduce" ref="desc">
            <span class="merchant-desc" v-if="introduce">{{introduce}}</span>
            <div class="unfold" @click.stop="showTotalIntro" v-if="showExchangeButton">
                <p>{{exchangeButton ? '展开' : '收起'}}<img :src="!exchangeButton ? '/static/icon/commont_up.png' : '/static/icon/commont_down.png'" alt=""></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    // name: "commontsText",
    // props: {
    //     commontText: {
    //         type: String
    //     }
    // },
    // data() {
    //     return {
    //         textOver: false, //文本是否超出3行
    //         btnFold: false, //按钮默认显示展开
    //         showAndHide: false,
    //     }
    // },
    // methods: {

    // },
    // mounted() {
    //     if(this.$refs.divRef) {
    //         let descHeight = this.$refs.divRef.offsetHeight;
    //         if(descHeight > 56) {
    //             this.showAndHide = true;
    //             this.textOver = true;
    //         } else {
    //             this.showAndHide = false;
    //             this.textOver = false;
    //         }
    //     }
    // }
    name: 'commontsText',
    props: {
        commontText: {
            type: String
        }
    },
    data () {
        return {
            introduce: null,
            // 是否展示所有文本内容
            showTotal: true,
            // 显示展开还是收起
            exchangeButton: true,
            // 是否显示展开收起按钮
            showExchangeButton: false,
            rem: ''
        };
    },
    mounted () {
       this.init();
    },
    methods: {
        init() {
            this.introduce = this.commontText;
        },
        showTotalIntro () {
            this.showTotal = !this.showTotal;
            this.exchangeButton = !this.exchangeButton;
        },
    },
    watch: {
        'introduce': function () {
            this.$nextTick(function () {
                if (!this.$refs.desc) {
                    return;
                }
                let descHeight = window.getComputedStyle(this.$refs.desc).height.replace('px', '');
                let heightSj = descHeight / 3.75;
                if (heightSj > 14.8) {
                    // 显示展开收起按钮
                    this.showExchangeButton = true;
                    this.exchangeButton = true;
                    // 不是显示所有
                    this.showTotal = false;
                } else {
                    // 不显示展开收起按钮
                    this.showExchangeButton = false;
                    // 没有超过四行就显示所有
                    this.showTotal = true;
                }
            }.bind(this));
        }
    }
    };
</script>

<style lang="scss" scoped>
// @import "@/styles/index.scss";
// .comment_text{
//     .comments_fab{
//         font-size: 3.47vw;
//         color: #333333;
//         line-height: 4.93vw;
//     }
//     .btn{
//         cursor: pointer;
//         font-size: 0;
//         text-align: right;
//         span{
//             font-size: 2.93vw;
//             color: #FF5900;
//             line-height: normal;
//             vertical-align: middle;
//             display: inline-block;
//             margin-right: 0.67vw;
//         }
//         img{
//             display: inline-block;
//             line-height: normal;
//             vertical-align: middle;
//             width: 2.93vw;
//             height: 1.47vw;
//         }
//     }
// }
// .moreClass{
//     display: -webkit-box;
//     -webkit-box-orient: vertical;
//     -webkit-line-clamp: 3;
//     overflow: hidden;
// }
.total-introduce {
    height: auto;
    overflow: hidden;
    font-size: 3.47vw;
    color: #333333;
    .intro-content {
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
        // overflow: hidden;
        .merchant-desc {
            width: 100%;
            line-height: 4.93vw;
        }
    }
    .unfold {
        display: block;
        z-index: 11;
        float: right;
        font-size: 0vw;
        p {
            line-height: 4vw;
            color: #FF5900;
            vertical-align: middle;
            display: inline-block;
            font-size: 2.93vw;
            background: #fff;
            padding-left: 1.33vw;
            height: 4.13vw;
            img{
                display: inline-block;
                line-height: normal;
                vertical-align: middle;
                width: 2.93vw;
                height: 1.47vw;
                position: relative;
                margin-left: 0.53vw;
                top: -0.4vw;
            }
        }
    }
}
.detailed-introduce {
    font-size: 3.47vw;
    color: #333333;
    position: relative;
    overflow: hidden;
    .intro-content {
        // 最大高度设为4倍的行间距
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        line-height: 4.93vw;
        word-wrap: break-word;
        /*强制打散字符*/
        word-break: break-all;
        /*同背景色*/
        color: #ffffff;
        overflow: hidden;
        .merchant-desc {
            width: 100%;
            line-height: 4.93vw;
        }
        &:after,
        // 这是展开前实际显示的内容
        &:before {
            content: attr(title);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            color: #333333
            // overflow: hidden;
        }
        // 把最后最后一行自身的上面三行遮住
        &:before {
            display: block;
            overflow: hidden;
            z-index: 1;
            // max-height: 63px;
            background: #ffffff;
        }
        &:after {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            // height: 81px;
            /*截取行数*/
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }
        .unfold {
            z-index: 11;
            outline: 0;
            position: absolute;
            right: 0;
            bottom: 0vw;
            font-size: 0;
            p {
                line-height: 4vw;
                font-size: 2.93vw;
                color: #FF5900;
                vertical-align: middle;
                display: inline-block;
                background: #fff;
                height: 4.13vw;
                padding-left: 1.33vw;
                img{
                    display: inline-block;
                    line-height: normal;
                    vertical-align: middle;
                    width: 2.93vw;
                    height: 1.47vw;
                    position: relative;
                    top: -0.4vw;
                    margin-left: 0.53vw;
                }
            }
        }
    }
}
</style>
