import Vue from "vue";
import Router from "vue-router";

import DiscoveryRouters from "@/router/discovery.routers";
import LandingRouters from "@/router/landing.routers";
import MainRouters from "@/router/main.routers";
import MeRouters from "@/router/me.routers";
import CommunityRouters from "@/router/community.routers";
import HotSearch from "@/router/hot_search.routers";
import Take from "@/router/take.routers";
import Report from "@/router/report.routers";
import element from "@/router/element.routers";
import RecoveryRouters from '@/router/channel.routers';

import comingSoon from "@/views/comingSoon";
import download from "@/views/download";
import index from "@/views/index";
import shopEcology from "@/router/shop_ecology.routers";
import asking4Rent from '@/router/asking4Rent.routers.js';
import pointRadar from "@/router/point_radar.router";

import cellScreen from '@/router/cellScreen.routers';
import locatingRouters from '@/router/locating.routers';
import brandAllianceRouters from '@/router/brandAlliance.routers';
import swRouter from '@/utils/swrouter';
import dataQueryRouters from '@/router/dataQuery.routers';

Vue.use(Router);

const router = new Router({
    routes: [
        ...cellScreen, // 小区筛选路由
        ...DiscoveryRouters, // 发现栏目界面路由
        ...LandingRouters, // 落地&分享界面路由
        ...MainRouters, // 主界面路由
        ...MeRouters, // 我的栏目界面路由
        ...CommunityRouters, // 社区路由
        ...HotSearch, //热搜
        ...Take, //外卖
        ...Report, // 报告
        ...RecoveryRouters,
        ...element, // 饿了么商家
		    ...shopEcology,//铺生态
        ...asking4Rent, //铺生态三期 商铺求租
        ...pointRadar, // 点位雷达
        ...locatingRouters, // 寻址
        ...brandAllianceRouters, // 加盟品牌路由
        ...dataQueryRouters, // 数据查询
        {
            path: "/",
            name: "index",
            component: index,
            meta: { id: 10008 }
        },
        {
            path: "/download",
            name: "download",
            component: download
        },
        {
            path: "/test",
            name: "test",
            component: () => import("@/views/test")
        },
        {
            path: "*",
            name: "404",
            component: comingSoon
        }
    ]
});

router.beforeEach((to, from, next) => {
  console.log('页面路径：' + to.fullPath)
  console.log('进入之前时间:' + window.enterDate)
    if (to.meta.title ) {
        document.title = to.meta.title;
    }
    // if (to.path === '/vipShop') {
    //   // next({ path: '/h5/#/member' })
    //   // this.$router.replace('/h5/#/member');
    //   next(false)
    //   swRouter('/h5/#/member','replace')
    // } else {
    //   next()
    // }
    next()
})
const ignorePath = ['/vipShop'];
router.afterEach((to, from, next) => {
  console.log('进入之后时间:' + new Date().getTime())
  console.log('时间差:' + (new Date().getTime() - window.enterDate))
  console.log('当前路由：' + to.fullPath);
    // 小程序定位城市名称
    if (to.query.mn) {
      window.mn = decodeURIComponent(to.query.mn)
    }
    // 小程序定位城市code
    if (to.query.mc) {
      window.mc = to.query.mc
    }
    // 小程序定位经度
    if (to.query.mlg) {
      window.mlg = to.query.mlg
    }
    // 小程序定位维度
    if (to.query.mlt) {
      window.mlt = to.query.mlt
    }
    // 埋点上报
    setTimeout(() => {
        if (Vue.prototype.JSBridge && !ignorePath.includes(to.path)) {
            Vue.prototype.JSBridge.BPR(to.meta.id, to.params.id || to.params.code, to.query);
        }
    }, 0);
});

export default router;
