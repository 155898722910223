<template>
    <div class="operation-plan">
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-show="!firstLoading">
            <VanImage @load="imageLoadDone" src="/static/img/operation-plan/bg_1.png">
                <VanImage class="btn btn_1_1" @click="jumpSKU(10006, 110001)" @load="imageLoadDone" src="/static/img/operation-plan/btn_1_1.png" />
                <VanImage class="btn btn_1_2" @click="jumpSKU(10015, 110002)" @load="imageLoadDone" src="/static/img/operation-plan/btn_1_2.png" />
                <VanImage class="btn btn_1_3" @click="jumpQueryData(110000, 110003)" @load="imageLoadDone" src="/static/img/operation-plan/btn_1_3.png" />
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/operation-plan/bg_2.png">
                <VanImage class="btn btn_2_1" @click="jumpSearchBrand" @load="imageLoadDone" src="/static/img/operation-plan/btn_2_1.png" />
                <VanImage class="btn btn_2_2" @click="jumpSpatialBrand" @load="imageLoadDone" src="/static/img/operation-plan/btn_2_2.png" />
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/operation-plan/bg_3.png">
                <VanImage class="btn btn_3_1" @click="jumpMainSku(10026, 112001)" @load="imageLoadDone" src="/static/img/operation-plan/btn_3_1.png" />
                <VanImage class="btn btn_3_2" @click="jumpMainSku(10001, 112002)" @load="imageLoadDone" src="/static/img/operation-plan/btn_3_2.png" />
                <VanImage class="btn btn_3_3" @click="jumpPackageDetail" @load="imageLoadDone" src="/static/img/operation-plan/btn_3_3.png" />
                <VanImage class="btn btn_3_4" @click="jumpAssess" @load="imageLoadDone" src="/static/img/operation-plan/btn_3_4.png" />
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/operation-plan/bg_4.png">
                <VanImage class="btn btn_4_1" @click="jumpArticle" @load="imageLoadDone" src="/static/img/operation-plan/btn_4_1.png" />
                <VanImage class="btn btn_4_2" @click="jumpDownLoad" @load="imageLoadDone" src="/static/img/operation-plan/btn_4_2.png" />
                <VanImage class="btn btn_4_3" @click="jumpQueryData(113000, 113003)" @load="imageLoadDone" src="/static/img/operation-plan/btn_4_3.png" />
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/operation-plan/bg_5.png"></VanImage>
        </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
    data() {
        return {
            firstLoading: true,
        };
    },
    methods: {
        imageLoadDone() {
            picNum++;
            if (picNum >= 3) {
                this.firstLoading = false;
            }
        },
        jumpSKU(id, btnId) {
            this.JSBridge.BTNCLICK( 10204, '', 110000, btnId);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "BRAND_INTRODUCTION",
                id: id,
                params: null,
                trackData: null,
                isClear: null,
            });
        },
        jumpQueryData(id, btnId) {
            this.JSBridge.BTNCLICK( 10204, '', id, btnId);
            if (window._appCode < 300) {
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "SEARCH_SURROUNDING_V2",
                });
            } else {
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CHANGE_MAIN_TAB",
                    id: "queryData",
                    isClear: true,
                });
            }
        },
        jumpSearchBrand() {
            this.JSBridge.BTNCLICK(10204, '', 111000, 111001);
            this.swRouter('reportView/searchBrand')
        },
        jumpSpatialBrand() {
            this.JSBridge.BTNCLICK(10204, '', 111000, 111002);
            this.swRouter('/brand')
        },
        jumpMainSku(id, btnId) {
            this.JSBridge.BTNCLICK(10204, '', 112000, btnId);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: window._appCode >= 300 ? "BRAND_INTRODUCTION_V3" : "BRAND_INTRODUCTION_V2",
                id: id,
                isClear: null,
            });
        },
        jumpPackageDetail() {
            this.JSBridge.BTNCLICK(10204, '', 112000, 112003);
            this.swRouter('/payGoods/130344226383685')
        },
        jumpAssess() {
            this.JSBridge.BTNCLICK(10204, '', 112000, 112004);
            this.swRouter("/introduce/10009");
        },
        jumpDownLoad() {
            this.JSBridge.BTNCLICK(10204, '', 113000, 113002);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "OPEN_SHOP",
                isClear: null,
            });
        },
        jumpArticle() {
            this.JSBridge.BTNCLICK(10204, '', 113000, 113001);
            this.swRouter('/community/drgGoods')
        }
    },
};
</script>

<style lang="scss" scoped>
.operation-plan {
    height: 100vh;
    overflow: auto;
    .l-view {
        padding: 0;
        height: 100%;
        // padding-bottom: 10vw;
    }
    ::v-deep .van-image {
        display: block;
        position: relative;
    }
    .btn {
        position: absolute;
    }
    .btn_1_1,
    .btn_1_2,
    .btn_2_1,
    .btn_2_2 {
        top: 108.267vw;
        left: 15.6vw;
        width: 27vw;
        height: 11.467vw;
    }
    .btn_1_2,
    .btn_2_2 {
        left: 44.933vw;
    }
    .btn_1_3 {
        bottom: 15.733vw;
        left: 40vw;
        width: 22.667vw;
        height: 20vw;
    }
    .btn_2_1,
    .btn_2_2 {
        top: 43.067vw;
    }
    .btn_3_1,
    .btn_3_3 {
        width: 34.933vw;
        height: 11.467vw;
        left: 15.6vw;
    }
    .btn_3_2,
    .btn_3_4 {
        width: 27vw;
        height: 11.467vw;
        left: 15.6vw;
    }
    .btn_3_1 {
        top: 46.533vw;
    }
    .btn_3_2 {
        top: 90.8vw;
    }
    .btn_3_3 {
        bottom: 50.267vw;
    }
    .btn_3_4 {
        bottom: 10vw;
    }
    .btn_4_1,
    .btn_4_2,
    .btn_4_3 {
        left: 15.6vw;
        height: 11.467vw;
    }
    .btn_4_1 {
        width: 30.933vw;
        top: 46.667vw;
    }
    .btn_4_2 {
        width: 34.933vw;
        bottom: 53.2vw;
    }
    .btn_4_3 {
        width: 27vw;
        bottom: 7.733vw;
    }
}
</style>
