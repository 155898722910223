<template>
    <div class="qr">
        <StateBar bgcolor="white" />
        <TitleHeader title="邀请合伙人" bgcolor="white" color="black" :back="true">
        </TitleHeader>

        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <AutoView v-if="!loading" :header="true" :footer="false" class="qr--view">
            <div class="wrap">
                <!-- <img class="qr-bkg" src="/static/img/partner/qr-bkg.png" /> -->
                <div class="line1">
                    <img class="arrow" src="/static/icon/partner/arrow-left-3.png" />
                    <span class="label">邀您一起使用</span>
                    <img class="arrow" src="/static/icon/partner/arrow-right-3.png" />
                </div>
                <div class="line2">
                    <div class="header-wrap">
                        <img class="header" :src="user.head" />
                    </div>
                    <div class="name">{{user.name}}</div>
                </div>
                <div class="line3">
                    <div class="content-1">AI大数据开店神器</div>
                    <div class="content-2">查数据，选店址，百万商家开店首选</div>
                </div>
                <div class="line4">
                    <img class="qr-icon" :src="qrCode" />
                </div>
                <div class="line5">
                    <div class="label-1">好友扫码，一起领50元券！</div>
                    <!-- <div class="label-2">邀请Ta加入</div> -->
                </div>
            </div>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            user: {
                name: '上上参谋',
                head: '/static/icon/default.png'
            },
            qrCode: ''
        }
    },
    methods: {
        getUserInfo() {
            this.HTTP.getUserInfo().then( ( res ) => {
                // console.log( 'getUserInfo:', res );
                this.user.name = res.username;
                if ( res.avatar ) {
                    this.user.head = res.avatar;
                }
            } ).catch( () => {
                this.$toast( '获取用户信息失败' );
            } );
        },
        getQRCode() {
            this.HTTP.inviteQRCode().then( ( res ) => {
                const { code, data }  = res;
                if ( code == 0 ) {
                    // console.log( 'inviteQRCode:', data );
                    this.qrCode = data;
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
            } );
        }
    },
    created() {
        this.getUserInfo();
        this.getQRCode();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
}
</script>

<style lang="scss" scoped>
.qr {
    // position: relative;
    height: 100vh;
    .qr--view {
        position: relative;
        padding: 4vw;
        box-sizing: border-box;
        background-color: #ffffff;
        .wrap {
            position: relative;
            padding: 4vw;
            box-sizing: border-box;
            height: 100%;
            border-radius: 2.67vw;
            background: url("/static/img/qr_bg.png") no-repeat center bottom;
            background-size: 100%;
            // background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);
            // .qr-bkg {
            //     position: absolute;
            //     top: -1.33vw;
            //     width: 100%;
            // }
            .line1 {
                position: relative;
                // margin-top: 4vw;
                display: flex;
                align-items: center;
                justify-content: center;
                .arrow {
                    width: 16.8vw;
                    height: 1.87vw;
                }
                .label {
                    color: #E29E5A;
                    font-size: 4vw;
                    font-weight: bold;
                    line-height: 6.13vw;
                    text-align: center;
                    margin-left: 4vw;
                    margin-right: 4vw;
                }
            }
            .line2 {
                position: relative;
                margin-top: 5.33vw;
                .header-wrap {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }
                .header {
                    position: relative;
                    width: 12vw;
                    height: 12vw;
                    border-radius: 6vw;
                    border: 1px solid #fff;
                }
                .name {
                    margin-top: 2.67vw;
                    font-size: 4vw;
                    font-weight: bold;
                    color: #C65000;
                    line-height: 6vw;
                    text-align: center;
                }
            }
            .line3 {
                position: relative;
                margin-top: 6.13vw;
                .content-1 {
                    // color: #C65000;
                    // font-size: 3.47vw;
                    // font-weight: bold;
                    // line-height: 5.33vw;
                    // text-align: center;
                    font-size: 8.53vw;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 10.13vw;
                    background: linear-gradient(90deg, #FD9E1C 0%, #FF4B4F 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                }
                .content-2 {
                    margin-top: 2.67vw;
                    font-size: 4.27vw;
                    font-weight: bold;
                    color: #FF7111;
                    line-height: 6.4vw;
                    text-align: center;
                }
            }
            .line4 {
                position: relative;
                margin-top: 12.4vw;
                display: flex;
                justify-content: center;
                overflow: hidden;
                .qr-icon {
                    width: 53.33vw;
                    height: 53.33vw;
                    margin: 0 auto;
                    border-radius: 2.67vw;
                }
            }
            .line5 {
                position: relative;
                margin-top: 3.33vw;
                font-size: 3.47vw;
                color: #4C577E;
                line-height: 5.33vw;
                text-align: center;
                .label-1 {
                    color: #4C577E;
                }
                // .label-2 {
                //     color: #C65000;
                // }
            }
        }
    }
}
</style>