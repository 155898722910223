<template>
    <div class="assess">
        <StateBar :bgcolor="bgcolor" />
        <TitleHeader title="测评记录" :bgcolor="bgcolor" color="white" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="assess--view">
            <div v-if="list.length === 0" class="empty">
                <img src="/static/img/assess/empty.png" />
                <p>暂无记录</p>
            </div>
            <template v-else>
                <van-list v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="loadData">
                    <div v-for="( item, index ) in list" :key="index" class="l-panel" @click="onTap( item )">
                        <div class="title">
                            <h3>{{ titleMapping[item.stdQuestionId] || '---' }}</h3>
                            <p class="datetime">{{ item.gmtModified | convDate }}</p>
                        </div>
                        <div class="inputs">
                            <p class="subTitle f-tof">名称：{{ item.storeName || '---' }}</p>
                            <p class="subTitle f-tof">行业：{{ item.categoryText || '---' }}</p>
                            <p class="subTitle f-tof">类型：{{ storeTypes[item.storeType - 1 ] || '---' }}</p>
                            <p class="subTitle f-tof">位置：{{ item.address || '---' }}</p>
                        </div>
                        <hr class="van-divider" />
                        <p class="score">测评得分：{{ item.totalScore }}</p>
                        <p v-if="item.conclusion"><strong>评估结论：</strong>{{ item.conclusion || '---' }}</p>
                    </div>
                </van-list>
            </template>
        </AutoView>
    </div>
</template>

<script>
export default {
    name: 'assessHistory',
    data () {
        return {
            bgcolor: '#2148AD',

            titleMapping: {
                10007: '街边店铺位置测评',
                10008: '商城店铺位置测评',
                10009: '通用行业店铺位置测评',
            },
            storeTypes: [ '街边店', '商场店' ],

            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            selectAll   : false,
            page        : 1,
            total       : 0,
            list        : [],
        };
    },
    methods: {
        loadData () {
            this.loading = true;
            this.HTTP.getAssessList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        onTap ( item ) {
            this.swRouter( `/assess/${item.stdQuestionId}/summary/${item.id}` );
        },
    },
    filters: {
        convDate ( value ) {
            if ( ! value ) return '---';
            return value.split( '-' ).join( '.' );
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.assess {
    min-height: 100vh;
    background-color: #2148AD;

    #{&}--view {
        background-color: #2148AD;
        padding: $margin $padding;

        .empty {
            @include flexColumn;
            img {
                margin-top: 30vw;
                width: 42vw;
                height: 42vw;
            }
            p {
                margin-top: 4vw;
                color: white;
                font-size: $font_400;
            }
        }

        .l-panel {
            position: relative;
            padding: $margin;
            padding-top: 16vw;
            font-size: 3.73vw;
            line-height: 5.33vw;
            border-radius: $radius_xs;
            box-shadow: inset 0 0 0 1px $c-text;

            .title {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 15vw;
                background: url(/static/img/assess/title_history.png) no-repeat;
                background-size: contain;
                margin-top: -1vw;

                h3 {
                    position: absolute;
                    top: 2vw;
                    left: $margin;
                    color: white;
                    font-size: $font_400;
                }
                .datetime {
                    @include flexColumn;
                    position: absolute;
                    bottom: 3vw;
                    left: $margin;
                    height: 4vw;
                    color: $c-text;
                    font-size: $font_293;
                    font-weight: bold;
                    line-height: normal;
                    background-color: #BFFEC9;
                    padding: 0 2vw;
                    border-radius: 999px;
                    box-shadow: inset 0 0 0 1px $c-text;
                }
            }
            .inputs {
                background: url(/static/img/assess/icons.png) no-repeat left top;
                background-size: 8vw auto;
                min-height: 28vw;
                padding-left: $padding * 1.1;
                padding-top: 1.2vw;

                .subTitle {
                    @include font( $c-text, $font_373, normal );
                    line-height: $font_533;
                    margin-top: $margin * 0.5;
                    letter-spacing: .25vw;
                }
            }
            .score {
                color: #08BCCA;
                font-weight: bold;
                margin-bottom: $margin;
            }
        }

        .l-panel:not(:first-child) {
            margin-top: $margin;
        }
    }
}
</style>
