/**
 * 品牌加盟路由
 */

// 加盟品牌主页
// 品牌入驻
const brandASettle = () => import(/* webpackChunkName: "module-brandAlliance" */ "@/views/brandAlliance/page/settle")

export default [
  {
    path: '/brandAlliance',
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: 'settle',
        name: 'brandASettle',
        component: brandASettle,
        meta: { id: 10344 }
      }
    ]
  }
]
