import Vue from "vue";
import Vuex from "vuex";
import VuexStorage from "vuejs-storage";
import createLogger from "vuex/dist/logger";
import Http from "@/request/http";
const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

const plugins = [
  VuexStorage({
    namespace: "SSCM",
    keys: ["data", "showStoreMapTip"]
  })
];
if (debug) plugins.push(createLogger());

export default new Vuex.Store({
  state: {
    test: null,
    home: null, // 首页数据
    token: null,
    couponRule: null, // 优惠券使用规则
    currentTitle: null, // 报告标题（部分接口需要该字段）
    currentAddr: { province: "", city: "" }, // 用户当前所在的城市
    currentQst: null, // 当前问题
    partnerInfo: null, // 合伙人信息
    assessInfo: null, // 店铺评估信息
    temp: null, // 临时数据
    data: {
      // data内的数据会做持久化
      user: {}, // 用户信息（登录后写入）
      auth: {}, // 授权信息（登录后写入）
      report: {}, // 报告信息（在创建报告时写入）
      locaCity: {} // 当前定位城市
    },
    showStoreMapTip: false, // 开店地图开店指南是否已经弹过窗口
    isShowEle: true, // 是否显示饿了么广告位
    industryType: "", // 品牌排行榜
    tokeoutType: "", // 外卖排行榜
    shopPoiInfo: { lng: '', lat: '', address: '' }, // 铺位测评poi点数据
    myCityInfo: {}, // 选择的城市
    // 待请求的api接口
    apiConfigList: [],
    // 是否处于验证中
    apiValidating: false,
    // 是否关闭校验弹窗
    isCloseValidatePopup: false
  },
  getters: {
    apiConfigList(state) {
      return state.apiConfigList;
    },
    apiValidating(state) {
      return state.apiValidating;
    },
    isCloseValidatePopup(state) {
      return state.isCloseValidatePopup;
    },
    user(state) {
      return state.data.user;
    },
    auth(state) {
      return state.data.auth;
    },
    home(state) {
      return state.home;
    },
    token(state) {
      return state.token;
    },
    report(state) {
      return state.data.report;
    },
    currentAddr(state) {
      return state.currentAddr;
    },
    currentTitle(state) {
      return state.currentTitle;
    },
    currentQst(state) {
      return state.currentQst;
    },
    partnerInfo(state) {
      return state.partnerInfo;
    },
    assessInfo(state) {
      return state.assessInfo;
    },
    temp(state) {
      return state.temp;
    },
    showStoreMapTip(state) {
      return state.showStoreMapTip;
    },
    industryType(state) {
      return state.industryType;
    },
    tokeoutType(state) {
      return state.tokeoutType;
    },
    locaCity(state) {
      return state.data.locaCity;
    },
    shopPoiInfo(state) {
      return state.shopPoiInfo
    },
    myCityInfo(state) {
      return state.myCityInfo
    }
  },
  mutations: {
    initHomeData(state, playload) {
      const data = {
        keys: [], // 提示
        hots: [], // 热搜
        words: [], // 词条
        news: [] // 列表
      };
      if (playload.searchTips) {
        data.keys = playload.searchTips;
      }
      data.words = [
        "便利店如何经营？",
        "成功的甜品店应如何经营？",
        "如何提升门店效率？",
        "如何经营好门店？",
        "如何提高店面的人流量？",
        "如何判断一家餐厅的客流量？",
        "饮品加盟店如何经营？",
        "怎样经营店铺生意会更好",
        "同在一条街，为什么你的生意比别人差？",
        "开实体店生意不好怎么办？"
      ];
      if (playload.hotSearches) {
        data.hots = playload.hotSearches;
      }
      state.home = data;
    },
    setTest(state, playload) {
      state.test = playload;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAuth(state, auth) {
      state.data.auth = auth;
    },
    setUser(state, user) {
      state.data.user = user;
    },
    setReport(state, playload) {
      state.data.report = playload;
    },
    setCouponRule(state, playload) {
      state.couponRule = playload;
    },
    setCurrentAddr(state, payload) {
      state.currentAddr = payload;
    },
    setCurrentTitle(state, title) {
      state.currentTitle = title;
    },
    setCurrentQst(state, payload) {
      state.currentQst = payload;
    },
    setPartnerInfo(state, payload) {
      state.partnerInfo = payload;
    },
    setAssessInfo(state, payload) {
      state.assessInfo = payload;
    },
    setTemp(state, payload) {
      state.temp = payload;
    },
    setShowStoreMapTip(state, payload) {
      state.showStoreMapTip = payload;
    },
    setIndustryType(state, payload) {
      state.industryType = payload;
    },
    setTokeoutType(state, payload) {
      state.tokeoutType = payload;
    },
    setLocaCity(state, payload) {
      state.data.locaCity = payload;
    },
    setShopPoiInfo(state, data) {
      console.log('data',data)
      state.shopPoiInfo = data
    },
    setCityInfo(state, data) {
      state.myCityInfo = data
    },
    setValidatePopup(state, data) {
      state.isCloseValidatePopup = data
    },
    setApiValidate(state, data) {
      state.apiValidating = data
    },
    setApiConfigList(state, data) {
      if (!data) {
        state.apiConfigList = []
      } else {
        // 过滤已经存在的接口
        if (!state.apiConfigList.some(e => e.url==data.url)) {
          state.apiConfigList.push(data)
        }
      }
    }
  },
  actions: {
    setTest({ commit }, data) {
      commit("setTest", data);
    },
    getHomeData(state) {
      Http.homeData()
        .then(res => {
          state.commit("initHomeData", res);
        })
        .catch(e => {
          setTimeout(() => {
            state.dispatch("getHomeData");
          }, 500);
        });
    }
  },
  plugins
});
