<template>
    <div class="report l-panel" @click="onTap">
        <div class="report--content">
            <!-- <p v-if="item.status === 1" class="time"> {{ item.placeTime || '刚刚' }}</p> -->
            <p  class="time">{{ item.placeTime }}</p>
            <van-cell v-if="item.courseName" :is-link="true" :title="item.courseName" :border="true" :clickable="false"  class="title-info"/>
            <van-cell :title="price" :value="info" class="info" />
        </div>
    </div>
</template>

<script>
export default {
    name : 'report',
    props: {
        item: Object, // 订单对象
    },
    computed: {
        price () {
            return '¥'+this.item.price;
        },
        info () {
            return '已购买';
            // return this.paid ? this.item.status === 5 ? '已取消' : '已支付' : '待支付';
        },
        paid () {
            return this.item.status > 1;
        },
    },
    methods: {
        onTap () {
            // if ( this.paid ) return;
            this.$emit( 'tap', { courseId: this.item.courseId || 0 } );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.report {
    position: relative;
    margin-bottom: $margin;
    #{&}--content {
        position: relative;
        height: 100%;
        h1 {
            color: $c-text;
            width: 100%;
            font-size: $font_400;
            line-height: $font_560;
            margin: 0;
            margin-top: 2.13vw;
        }
        .time {
            font-size: $font_293;
            line-height: $font_400;
            color: $c-info;
        }
        .desc, .light {
            margin: $margin 0;
            padding: $margin;
            background-color: $c-pageBg;
            font-size: $font_293;
            line-height: $font_400;
        }
        .light {
            background-color: #FFF1E1;
            color: #8D5D22;
            font-weight: bold;
        }
        .title-info{
            ::v-deep .van-cell__title {
                span {
                    font-size: $font_400;
                     @include limitLine(1);
                }
            }
            &.van-cell{
                padding-left: 0;
                padding-right: 0;
                padding-top: 1.8vw;
                .van-cell__right-icon{
                     color: #1D2233;
                }
                &:not(:last-child)::after{
                    left:0
                }
            }
        }
        .info {
            padding: 0;
            padding-top: 10px;
            ::v-deep .van-cell__title {
                color: $c-warn;
                font-weight: bold;
            }
            ::v-deep .van-cell__value {
                color:#8E9099;
                 font-size: $font_300;
            }
            &.paid {
                ::v-deep .van-cell__title {
                    color: $c-main;
                    span {
                        background-image: url(/static/icon/success.png);
                    }
                }
            }
        }
    }
}
</style>
