<template>
    <div class="message l-panel" :class="{ isRead: read }" @click="onTap">
        <div class="message--content">
            <p class="time f-vmb">{{ item.gmtCreate || '刚刚' }}</p>
            <van-cell v-if="item.title" :is-link="isLink" :title="item.title" :border="false" :clickable="false" />
            <div class="content" v-html="getContent()"></div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'message',
    props: {
        item: Object,
    },
    data () {
        return {
            read    : false,
            metadata: null,
        };
    },
    methods: {
        onTap () {
          console.log(this.item)
            if ( ! this.read ) {
                this.read = true;
                // this.HTTP.markerRead( this.item.id ).then( ( res ) => {} );
            }
            switch ( this.item.msgType ) {
                case 2: // 问题回复
                    if ( this.metadata && this.metadata.showTemplateCnfId ) {
                        this.$router.push( '/questions' );
                        // this.$router.push( '/additional/' + this.metadata.showTemplateCnfId );
                    }
                    break;
                case 3: // 报告就绪
                    if (this.item.content.includes('商业片区推荐')) {
                      this.swRouter('/h5/#/area/recommend/report/' + this.metadata.reportInstanceId)
                    } else {
                      if ( this.metadata ) {
                        this.$router.push( '/charts/' + this.metadata.reportInstanceId );
                      } else {
                        this.$router.push( '/reports' );
                      }
                    }
                    break;
                case 4: // 解锁成功
                    this.$router.push( '/reports?light=' + this.metadata.reportInstanceId );
                    break;
                default:
                    break;
            }
        },
        convBr ( str ) {
            return str ? str.replace( /\n/gm, '<br>' ) : '---';
        },
        getContent () {
            let str = '';
            if ( this.item.msgType === 2 ) {
                str += `<p class="subTitle">${this.metadata.subTitle || '---'}</p>
                        <p class="info mt2">${this.convBr( this.metadata.questionDesc )}</p>`;
                if ( this.metadata.reply ) {
                    str += `<hr/>
                       <p class="label">回复内容：</p>
                       <p class="reply mt2">${this.metadata.reply || '---'}</p>`;
                }
            } else if ( this.item.msgType === 3 ) {
                str = this.item.content;
            } else {
                str = this.item.content;
            }
            return str;
        },
    },
    computed: {
        isLink () {
            if ( this.item.msgType === 2 && this.metadata && this.metadata.showTemplateCnfId ) {
                return true;
            } else if ( this.item.msgType === 3 && this.metadata && this.metadata.reportInstanceId ) {
                return true;
            } else if ( this.item.msgType === 4 && this.metadata && this.metadata.reportInstanceId ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        item: {
            immediate: true,
            handler ( item ) {
                if ( item.metadata ) {
                    this.metadata = JSON.parse( item.metadata );
                }
            },
        },
    },
    created () {
        this.read = this.item.status;
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.message {
    @extend .s-ani;
    position: relative;
    padding: $padding1;
    height: auto;
    margin-bottom: $margin;

    // &.isRead {
    //     opacity: .5;
    // }

    ::v-deep .van-cell {
        padding: 0;

        .van-cell__title {
            color: $c-text;
            font-size: $font_400;
            line-height: $font_560;
            font-weight: bold;
            margin-bottom: 3.33vw;
        }
        .van-cell--clickable:active {
            background-color: transparent;
        }
    }

    #{&}--content {
        position: relative;

        .time {
            font-size: $font_293;
            line-height: $font_400;
            color: $c-info;
        }
        .content, ::v-deep .subTitle, ::v-deep .reply {
            font-size: $font_320;
            line-height: $font_440;
        }
        .info, ::v-deep .info {
            font-size: $font_293;
            line-height: $font_440;
            color: $c-info;
            &.mt2 {
                margin-top: 2vw;
            }
        }
        ::v-deep .subTitle {
            font-weight: bold;
        }
        ::v-deep hr {
            height: 1px;
            border: none;
            @extend .s-line;
            margin: 4vw 0;
        }
        ::v-deep .label {
            margin-top: $padding2;
            font-size: $font_320;
            line-height: $font_440;
            font-weight: bold;
            padding-left: $padding;
            background: url(/static/icon/msg_gray.png) no-repeat left center;
            background-size: 4vw;
        }
        ::v-deep .reply {
            &.mt2 {
                margin-top: 2vw;
            }
        }
    }
}
</style>
