<template>
    <div>
      <StateBar ref="stateBar"></StateBar>
      <TitleHeader title="门店设计" bgcolor="transparent" color="black" :back="true">
      </TitleHeader>
      <!-- :class="{ androidPosition: isAndroidOne, iosPosition: isIOSOne, ipxPosition: isIPXOne }" -->
      <AutoView :header="true" :footer="false" class="coupon--view">
        <van-skeleton title :loading="goodsListLoading" :animate="false" :row="3" avatar class="s-skeleton s-radius__s">
          <div class="goods_list" >
            <div class="list_swrap">
              <EmptyCard v-if="list.length <= 0 && !isFlag" type="community"></EmptyCard>
              <template v-else>
                <div class="store-view-list">
                  <storeItem v-for="(store, index) in list" :key="index" :store="store" />
                </div>
              </template>
            </div>

          </div>
        </van-skeleton>
      </AutoView>
    </div>
</template>

<script>
const storeItem = () => import('./components/storeItem')
export default {
  name: 'store',
  components: {
    storeItem
  },
  data() {
    return {
      goodsListLoading: true,
      list:       {
        resourceList: [],
      },
      isFlag: false,
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    getInfo() {
      let params = { title: '门店设计' };
      this.isFlag = true;
      this.HTTP.getArticleCategoryDetail(params).then(res => {
        this.goodsListLoading = false;
        this.isFlag = false;
        this.list = res.data.resourceList;
        console.log('this.list', this.list)
      }).catch((e) => {
        this.isFlag = false;
        this.$toast("网络连接不稳定，请稍候重试！");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.titleHeader {
  border-bottom: 1px solid #EBEAEE;
}
.store-view-list {
  display: grid;
  grid-column-gap: vw(20);
  grid-template-columns: 1fr 1fr;
  padding: 0 vw(30);
}
</style>
