<template>
    <div class="evaluate">
        <van-field class="input define" v-model="message" readonly type="text" placeholder="一起讨论下..." @click="inputClick" />

        <van-popup
            v-model="alertShow"
            position="bottom"
            closeable
            close-icon-position="top-left"
            :style="{ height: '66%', borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }"
        >
            <div class="popup-wrap">
                <ul class="ul-flex">
                    <li class="left"></li>
                    <li class="mid">加入讨论</li>
                    <li class="right">
                        <van-button type="info" :disabled="!message" size="mini" :loading="loading" @click="submit">提交</van-button>
                    </li>
                </ul>
                <van-field
                    class="input"
                    ref="inputbox"
                    v-model.trim="message"
                    autosize
                    maxlength="150"
                    type="textarea"
                    placeholder="内容被平台审核后，将对所有人可见"
                />
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    props: {
        evaluateInfo: {
            type   : Object,
            default: () => {}
        },
        id   : { type: String },
        title: {
            type   : String,
            default: ''
        },
        type: {
            type: Number,
            default: 5
        }
    },
    data () {
        return {
            message  : '',
            alertShow: false,
            loading  : false
        };
    },
    methods: {
        submit () {
            this.loading = true;
            this.HTTP.addComment( {
                type   : this.type,
                content: this.message
            } )
                .then( ( res ) => {
                    if ( res.data ) {
                        this.$toast( '提交成功，评审通过后即可展示' );
                        this.message = '';
                        this.alertShow = false;
                        this.loading = false;
                    }
                } )
                .catch( ( err ) => {
                    // this.$toast( '网络不稳定，请稍后再试！' );
                    this.loading = false;
                } );
        },
        inputClick () {
            this.alertShow = true;
            setTimeout( () => {
                this.$refs.inputbox.focus();
            }, 120 );
        }
    }
};
</script>

<style lang="scss" scoped>
.evaluate {
    .input {
        margin: 2.67vw 0 4vw 0;
        border-radius: 1.33vw;
    }
    .define {
        padding: 2.666vw 3.733vw 0;
        height: 10.666vw;
        background: #f7f7f7;
        border-radius: 1.333vw;
        border: 0.1333vw solid #e7e7e7;
        margin: 2.8vw 0 4.13vw;
    }
    ::v-deep .van-popup__close-icon {
        color: #c5c3cA;
    }
}
.popup-wrap {
    width: 100%;
    .ul-flex {
        display: flex;
        flex-flow: row nowrap;
        padding-right: 5.33vw;
        .left {
            flex: 0 0 10.13vw;
        }
        .mid {
            flex: 1;
            font-size: 3.73vw;
            color: #000;
            font-weight: bold;
            margin-top: 4.8vw;
            text-align: center;
        }
        .right {
            flex: 0 0 15.47vw;
            margin-top: 1.6vw;
            text-align: right;
        }
    }
}
</style>
