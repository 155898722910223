<template>
  <div class="wrapper">
        <StateBar bgcolor="white" />
        <TitleHeader title="华夏龙商贷" color="black">
        </TitleHeader>
        <AutoView :footer="true">
            <div class="scrollWrapper">
                <img class="success" src="/static/icon/loan_success.png" alt="">
                <div class="title">审核资料提交成功！</div>
                <div class="tip">后续请进入【华夏龙商贷】微信公众号，凭本次填写的身份证号、手机号信息进行借款操作！</div>
                <img class="guidelines" src="/static/img/loan_guidelines.png" alt="">
            </div>
        </AutoView>
        <SoltFooter>
            <div class="backFooter">
                <van-button type="info" block class="s-radius__s" @click="goBackHome">回到首页</van-button>
            </div>
        </SoltFooter>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        this.JSBridge.callHandler("interceptBack", "true")
        this.JSBridge.callHandler("switchBackPic", "false")
    },
    methods: {
        goBackHome() {
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                id: "home",
                url: "CHANGE_MAIN_TAB",
                params: null,
                trackData: null,
                isClear: true,
            });
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.wrapper {
    height: 100vh;
    overflow-x: hidden;
    background-color: #fff;
    .scrollWrapper {
        display: flex;
        overflow-y: hidden;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .success {
            width: 21.333vw;
            height: 21.333vw;
        }
        .title {
            color: #333333;
            font-weight: 600;
            margin-top: 3.2vw;
        }
        .tip {
            color: #999999;
            font-size: 3.467vw;
            margin: 3.733vw 0;
            padding: 0 4.8vw;
            line-height: 4.933vw;
        }
        .guidelines {
            width: calc(100vw - 9.6vw);
            margin-bottom: 8vw;
        }
    }
    .backFooter {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;
        border: 0.133vw solid #EBEAEE;
        box-shadow: 0 -0.267vw 0.8vw 0 #EBEAEE;
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
            background-color: $c-main;
        }
    }
}
</style>
