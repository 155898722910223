// 检查值
// 如果是字符串，转换为float之后取fixed有效位
// 如果是number型，直接取fix有效位.fix为负数时，不进行toFixed转换
// 如果是undefined，取none值
// 如果是其它类型，不做处理，直接返回原值
export function valueCheck(value, fix, none) {
  if (fix == undefined) {
    fix = 0
  }
  if (none == undefined) {
    none = '-'
  }
  if (value == undefined) {
    // 过滤掉undefined 和 null
    return none;
  }
  if (value == 'NaN') {
    return none;
  }
  if (typeof(value) == 'string') {
    let temp = parseFloat(value);
    if (fix >= 0) {
      return temp.toFixed(fix);
    } else {
      return temp;
    }
  } else if (typeof(value) == 'number') {
    if (value == 0) {
      return none;
    }
    if (fix >= 0) {
      return value.toFixed(fix);
    } else {
      return value;
    }
  }
  return value;
}

/**
 * 计算2个数的比值，可换算为百分比或者是个数差值
 * @param {*} current
 * @param {*} around
 * @param {*} isPercent
 */
export function getCompareValue(current, around, isPercent) {
  let compare = 0;
  if (current == 0 || around == 0) {
    // 存在一方为0的情况，都不会使用到百分比
    return compare;
  }
  if (isPercent) {
    // 计算百分比
    compare = ((current - around) * 100) / around;
  } else {
    // 计算差值
    compare = current - Math.round(around);
  }
  return compare;
}
/**
 * 深拷贝一个对象或者数组
 * @param {json} object
 */
export function deepCopy(object) {
  if (typeof object != "object") {
    return {};
  }
  let objStr = JSON.stringify(object);
  return JSON.parse(objStr);
}

/**
 * 计算数组的百分比
 * @param {*} dataList
 * @param {*} dataField
 * @param {*} needRound 是否需要取整
 */
export function calcListPercent(dataList, dataField, needRound) {
  if (!Array.isArray(dataList)) {
    return;
  }
  if (dataList.length == 0) {
    return;
  }
  if (dataField == undefined || typeof dataField != "string") {
    dataField = "count";
  }
  if (needRound == undefined) {
    needRound = true;
  }
  const firstItem = dataList[0];
  if (!firstItem.hasOwnProperty(dataField)) {
    return;
  }
  let totalCount = dataList.reduce((total, item) => {
    let targetValue = item[dataField];
    if (typeof targetValue == "string") {
      targetValue = parseFloat(targetValue);
    }
    total += targetValue;
    return total;
  }, 0);
  if (totalCount == 0) {
    dataList.forEach(item => {
      item.percent = 0;
    });
    return;
  }
  let roundFix = 0;
  for (let i = 0; i < dataList.length; ++i) {
    let dataItem = dataList[i];
    let targetValue = dataItem[dataField];
    if (typeof targetValue == "string") {
      targetValue = parseFloat(targetValue);
    }
    let percentTmp = (targetValue * 100) / totalCount;
    let percent = 0;
    if (needRound) {
      if (i == dataList.length - 1) {
        percent = Math.round(percentTmp - roundFix);
      } else {
        percent = Math.round(percentTmp);
        roundFix += percent - percentTmp;
      }
      dataItem.percent = percent;
    } else {
      dataItem.percent = percentTmp;
    }
  }
}
