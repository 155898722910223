<template>
    <div class="picker-wrap">
        <div class="picker-l">
            <span>
                <img src="/static/icon/location.png" class="picker-icon" @click="handleSelectCity" />
            </span>
            <span class="picker-label" @click="handleSelectCity">{{cityName}}</span>
            <span class="picker-icon-wrap">
                <img src="/static/icon/arrow_down_2.png" class="picker-drapdown" />
            </span>
        </div>
        <div class="picker-line"></div>
        <div class="picker-r">
            <van-field v-model="input" placeholder="输入想要监测店铺名称" :clearable="true" @clear="handleInputClear" @input="handleInputChange"></van-field>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        city: {
            type   : String,
            default: '定位中'
        }
    },
    watch: {
        city ( nv, ov ) {
            this.cityName = nv;
        }
    },
    data () {
        return {
            input   : '',
            cityName: '定位中'
        };
    },
    mounted () {
        if ( this.city.length > 0 ) {
            this.cityName = this.city.slice( 0 );
        }
    },
    methods: {
        handleInputClear () {
            this.input = '';
            this.$emit( 'input-change', this.input );
        },
        handleInputChange () {
            this.$emit( 'input-change', this.input );
        },
        handleSelectCity () {
            this.JSBridge.callHandler( 'selectCity', null, ( res ) => {
                if ( res == null ) {
                    this.$emit( 'city-change', undefined );
                } else {
                    let cityObj = res;
                    if ( typeof cityObj === 'string' ) {
                        cityObj = JSON.parse( cityObj );
                    }
                    this.cityName = cityObj.name;
                    this.$emit( 'city-change', cityObj );
                }
            } );
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.picker-wrap {
    position: relative;
    background-color: #ffffff;
    border-radius: $radius-xs;
    box-shadow: 0px $radius-xs $radius 0px rgba(29,34,51,0.06);
    padding: 1vw 2.1vw;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .picker-l {
        position: relative;
        display: flex;
        align-items: center;
        .picker-icon {
            position: relative;
            width: 4.5vw;
            height: 4.5vw;
        }
        .picker-label {
            margin-left: 1vw;
            color: #808080;
            font-size: 3.5vw;
            line-height: 4.5vw;
            text-align: left;
            white-space: nowrap;
        }
        .picker-icon-wrap {
            position: relative;
            display: flex;
            align-items: center;
        }
        .picker-drapdown {
            margin-left: 1vw;
            width: 2.4vw;
            height: 1.9vw;
        }
    }
    .picker-line {
        background-color: #DADADA;
        margin-left: 2.9vw;
        width: 1px;
        height: 5.6vw;
    }
    .picker-r {
        position: relative;
        margin-left: 1vw;
    }
}
</style>
