<template>
  <div class="assess">
    <StateBar bgcolor="transparent" />
    <TitleHeader title="评估汇总" color="white" bgcolor="transparent" :back="true" />

    <AutoView :header="true" :footer="false" class="assess--view">
      <p class="subTitle">名称：{{ params.storeName || "---" }}</p>
      <p class="subTitle">行业：{{ params.categoryText || "---" }}</p>
      <p class="subTitle">类型：{{ params.storeTypeText || "---" }}</p>
      <p class="subTitle">位置：{{ params.address || "---" }}</p>

      <div class="view">
        <van-skeleton :loading="!entity" title :row="5" class="s-skeleton">
          <template v-if="entity">
            <div class="conclusion">
              <van-row>
                <van-col :span="16" class="score light f-clear">
                  <p class="f-fl">{{ params.star }}</p>
                  <div class="starBar f-fl">
                    <i
                      v-for="(n, index) of maxStar"
                      :key="index"
                      class="icon icon-star"
                      :class="{
                        half: n - 0.5 == params.star,
                        full: n <= params.star,
                      }"
                    />
                  </div>
                </van-col>
                <van-col :span="8" class="score f-tar">{{ params.totalScore }}</van-col>
              </van-row>
              <van-row>
                <van-col :span="16" class="desc">评估星级</van-col>
                <van-col :span="8" class="desc f-tar">评估总分</van-col>
              </van-row>
            </div>

            <template v-if="id === '10009'">
              <table class="table">
                <tr>
                  <th style="width: 75%">评估项</th>
                  <th style="width: 25%">评分</th>
                </tr>

                <template v-for="(item, index) in list">
                  <tr :key="item.id">
                    <td>{{ item.modelName }}</td>
                    <td>{{ item.socre }}</td>
                  </tr>
                  <tr v-if="index === 1" :key="index" class="sp">
                    <td>区域分数合计</td>
                    <td>{{ countRegion }}</td>
                  </tr>
                  <tr v-else-if="index === 4" :key="index" class="sp">
                    <td>店址分数合计</td>
                    <td>{{ countAddress }}</td>
                  </tr>
                </template>

                <tr>
                  <td class="score" style="text-align: right">(满分100)&nbsp;<strong>总分</strong>&emsp;</td>
                  <td class="score">
                    <strong>{{ params.totalScore }}</strong>
                  </td>
                </tr>
              </table>
              <div class="so">{{ params.conclusion }}</div>
            </template>
            <template v-else>
              <div v-for="(item, index) in list" :key="item.id" class="sections" :class="{ first: index === 0 }">
                <p class="title">{{ item.modelName }}结论</p>
                <p class="content">{{ item.conclusion }}</p>

                <van-row>
                  <van-col :span="8" class="block">
                    <p class="value">{{ item.socre }}</p>
                    <p class="label">评估得分</p>
                  </van-col>
                  <van-col :span="8" class="block space">
                    <p class="value">{{ item.weight }}%</p>
                    <p class="label">权重</p>
                  </van-col>
                  <van-col :span="8" class="block space">
                    <p class="value">{{ item.weightScore }}</p>
                    <p class="label">加权得分</p>
                  </van-col>
                </van-row>
              </div>
            </template>
          </template>
        </van-skeleton>
      </div>

      <!-- 占位 -->
      <div class="result"></div>

      <img src="/static/img/assess/slogan_a.png" class="banner" />
      <img src="/static/img/assess/slogan_b.png" class="banner" @click="goToApp" />
      <img src="/static/img/assess/slogan_c.png" class="banner" />

      <div class="assess--footerBar">
        <van-button type="info" block @click="goToApp">
          <img src="/static/img/assess/text/unlock.png" class="btn-text" />
        </van-button>
      </div>
    </AutoView>
  </div>
</template>

<script>
export default {
  name: "assessIndex",
  data() {
    return {
      storeTypes: ["街边店", "商场店"],

      id: null,
      hid: null,

      entity: null,
      params: {},
      list: [],
      maxStar: 5,
    };
  },
  methods: {
    goToApp() {
      if (window.isClient) {
        if (window._appCode && window._appCode >= 207) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: window._appCode >= 300 ? 'SKU_FILL_INFO_PAGE' : "BRAND_INTRODUCTION_V2",
            id: 10001,
            params: null,
          });
        } else if (window._appCode && window._appCode >= 200) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "PICK_LOCATION",
            id: 10001,
            params: null,
          });
        } else {
          this.swRouter("/introduce/10001");
        }
      } else {
        this.swRouter("/introduce/10001");
      }
    },
    loadData() {
      this.id = this.$route.params.id;
      this.hid = this.$route.params.history;
      this.HTTP.getAssessInfo(this.id, this.hid)
        .then((res) => {
          this.entity = res;
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
        });
    },
    onTap() {
      if (window.isClient) {
        this.JSBridge.callHandler("gotoPage", "card_10001");
      } else {
        this.swRouter("/card/10001");
      }
    },
  },
  watch: {
    "$route.params"(res) {
      this.loadData();
    },
    entity(res) {
      this.params = res.history;
      this.params.storeTypeText = this.storeTypes[this.params.storeType - 1];
      this.params.star = (Math.round((10 * this.params.totalScore) / 100) / 2).toFixed(1);
      this.list = res.modelList;
    },
  },
  computed: {
    countRegion() {
      if (!this.list || this.list.length < 5) {
        return "--";
      } else {
        return parseInt(this.list[0].socre) + parseInt(this.list[1].socre);
      }
    },
    countAddress() {
      if (!this.list || this.list.length < 5) {
        return "--";
      } else {
        return parseInt(this.list[2].socre) + parseInt(this.list[3].socre) + parseInt(this.list[4].socre);
      }
    },
  },
  beforeMount() {
    this.JSBridge.callHandler("setStatebarTheme", "white");
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.assess {
  height: 100vh;
  background-color: #2148ad;

  #{&}--view {
    padding: $padding;

    .subTitle {
      @include font(white, $font_320, normal);
      line-height: $font_440;
      margin-top: $margin * 0.5;
      letter-spacing: 0.25vw;
    }

    .view {
      position: relative;
      background-color: white;
      margin-top: $margin * 2;
      padding: $padding $padding $padding * 2.5;
      box-shadow: inset 0 0 0 1px $c-text;

      &:before {
        content: "";
        position: absolute;
        width: 13vw;
        height: 13vw;
        bottom: 0;
        right: 0;
        background: url(/static/img/assess/dogear.png) no-repeat;
        background-size: contain;
      }
      &:after {
        content: "";
        position: absolute;
        width: 4vw;
        height: calc(100% - 5vw);
        right: 0;
        top: 5vw;
        background-color: #d2e2ec;
        transform: translateX(100%);
        box-shadow: inset -1px 1px 0 $c-text;
      }

      .conclusion {
        padding-bottom: $padding;
        box-shadow: 0 1px 0 rgba(black, 0.05);

        .score {
          font-size: 6.4vw;
          font-weight: bold;
          &.light {
            color: #ff982a;
          }
        }
        .desc {
          margin-top: $margin * 0.5;
          font-size: 2.94vw;
        }
        .starBar {
          @include flex;
          height: 6.4vw;
          margin-left: $margin * 0.5;

          .icon {
            position: relative;
            width: 5.4vw;
            height: 5.4vw;
            background: url(/static/icon/star.png) no-repeat left top;
            background-size: cover;
            margin-right: 1.2vw;

            &:after {
              position: absolute;
              content: "";
              top: 0;
              width: 0%;
              height: 100%;
              background: url(/static/icon/star.png) no-repeat left bottom;
              background-size: 5.4vw auto;
            }
            &.full:after {
              width: 100%;
            }
            &.half:after {
              width: 50%;
            }
          }
        }
      }

      .table {
        width: 100%;
        border-radius: $radius_xs $radius_xs 0 0;
        margin-top: $padding;
        color: #495b95;
        font-size: $font_373;
        border-collapse: collapse;
        border-spacing: 0;
        overflow: hidden;

        th,
        td {
          height: 10vw;
          padding: 0;
          vertical-align: middle;
          text-align: center;
          border: 1px solid #dedee7;
        }
        th,
        .score {
          background-color: #ebebf5;
        }
        th:first-child {
          text-align: left;
          text-indent: 4vw;
        }
        tr td:nth-child(odd) {
          text-align: left;
          text-indent: 4vw;
        }
        .sp {
          background-color: #f2f2f2;
        }
        .score {
          text-align: center;
        }
      }
      .so {
        padding: $margin $margin 0;
        font-size: $font_373;
        line-height: 5.33vw;
      }

      .sections {
        overflow: hidden;
        margin-top: $margin;
        padding-top: $padding * 0.5;
        &:not(:last-child) {
          box-shadow: 0 1px 0 rgba(black, 0.05);
          padding-bottom: $padding;
        }

        .title {
          font-size: $font_400;
          font-weight: bold;
        }
        .content {
          margin-top: $margin * 0.5;
          color: $c-info;
          font-size: 2.94vw;
          line-height: $font_400;
        }
        .block {
          margin-top: $margin * 2;
          box-shadow: 1px 0 0 #ebebeb;
          .value {
            @include font($c-text, $font_480, bold);
          }
          .label {
            margin-top: $margin * 0.5;
            font-size: 2.94vw;
            color: $c-info;
          }
          &.space {
            text-indent: 33%;
          }
        }
      }
    }

    .result {
      width: 100%;
      min-height: 5vw;
      background-color: #d2e2ec;
      transform: translateX(4vw);
      box-shadow: inset 1px 0 0 $c-text, inset 0 -1px 0 $c-text, inset -1px 0 0 $c-text;
    }

    .banner {
      width: 100%;
      margin-top: $margin * 2;
    }
  }

  #{&}--footerBar {
    width: 100%;
    padding: $padding $padding * 0.5;
    box-sizing: border-box;

    ::v-deep .van-button {
      height: 17vw;
      font-size: 7.5vw;
      font-weight: bold;
      line-height: normal;
      background: url(/static/img/assess/btn.png) no-repeat;
      background-size: 100% 100%;
      border: none;

      .van-button__text {
        @include flexColumn;
        .btn-text {
          height: 8vw;
          margin-top: 1.3vw;
        }
      }
    }
  }
}
</style>
