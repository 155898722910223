<template>
	<div>
		<StateBar />
		<TitleHeader title="意向提交成功" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
		</TitleHeader>
		<AutoView :footer="false">
			<div class="intentionsubmitSuccessful">
				<img src="../../../../static/shopEcology/intentionsubmit.png" alt="">
				<div class="title">意向提交成功</div>
				<div class="bottom-box">
					<div class="button-left" @click="goSquare">返回商铺广场</div>
					<div class="button-right" @click="goDetails">查看详情</div>
				</div>
			</div>
		</AutoView>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			goSquare() {
				//去广场
        if (window._appCode >= 407) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "SHOP_SQUARE_HOME_V2"
          });
        } else {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "SHOP_SQUARE_HOME"
          });
        }
			},
			goDetails() {
				this.swRouter({
					path: '/shopEcology/transferDetails',
					query: {
						id: this.$route.query.id,
						name: this.$route.query.name?this.$route.query.name:''
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.intentionsubmitSuccessful {
		padding-top: vw(112);
		text-align: center;

		img {
			width: vw(174);
			height: vw(174);
		}

		.title {
			margin-top: vw(43);
			height: vw(42);
			font-size: vw(30);
			font-weight: 600;
			color: #333333;
			line-height: vw(42);
		}


		.bottom-box {
			margin-top: vw(100);
			display: flex;
			justify-content: space-between;
			text-align: center;
			border-radius: vw(10);
			font-size: vw(26);
			font-weight: 600;
			padding: 0 vw(80);
			box-sizing: border-box;

			.button-left {
				border: vw(2) solid #347FFF;
				color: #347FFF;
				width: vw(285);
				height: vw(80);
				line-height: vw(80);
				border-radius: vw(10);
				box-sizing: border-box;
			}

			.button-right {
				width: vw(285);
				height: vw(80);
				line-height: vw(80);
				background: #347FFF;
				border-radius: vw(10);
				color: #FFFFFF;
			}
		}

	}
</style>
