<template>
    <div class="wrapper">
        <StateBar></StateBar>
        <TitleHeader title="选择城市" bgcolor="transparent" color="black" :back="true"></TitleHeader>
        <AutoView>
            <div class="search">
                <SearchBar
                    style="border-radius: 1.33vw; padding-left: 4vw; background: #fff"
                    placeholder="请输入城市"
                    :value="value"
                    type=""
                    @change="searchHandle"/>
            </div>
            <template v-if="!value">
                <div class="content">
                    <div class="alphabet" >
                        <div v-for="(item, index) in alphabet" :key="index">
                            <div class="item" :class="{ active: currentLetter == item }" @click="clickLetter">
                            {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="hot" >
                        <div class="title" ref="hot">热门城市</div>
                        <div class="hot_wrapper">
                            <div class="item" v-for="item in hostCities" :key="item.code" @click="selectCity">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="city_list" v-for="item in cityList" :key="item.word">
                        <div class="title" :ref="item.word">{{item.word}}</div>
                        <div class="item" v-for="item in item.list" :key="item.code" @click="selectCity">{{item.name}}</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="content" v-if="searchList && searchList.length">
                    <div class="city_list">
                        <div class="item" v-for="item in searchList" :key="item.code" @click="selectCity">{{item.name}}</div>
                    </div>
                </div>
                <div class="empty" v-else>
                    <img src="/static/element/empty.png" alt="">
                    <div class="empty_desc">
                        <div class="title">无任何相关结果</div>
                        <div class="tip">请换个关键词</div>
                    </div>
                </div>
            </template>
        </AutoView>
    </div>
</template>

<script>
import SearchBar from "@/components/SearchBar/index.vue"
export default {
    data() {
        return {
            value: "",
            alphabet: ["#", "热", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "W", "X", "Y", "Z"],
            currentLetter: '',
            hostCities: [],
            cityList: [],
            timeout: null,
            searchList: [],
            city: "",
            cityCode: ""
        }
    },
    components: {
        SearchBar
    },
    mounted() {
        this.getCityList()
        this.HTTP.elemeSubmit({
            platform: sessionStorage.getItem("platform"),
            track: JSON.stringify([{
                pageId: "10184",
                timestamp: new Date().getTime()
            }])
        })
    },
    methods: {
        searchHandle(e) {
            if(this.timeout){
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
                this.value = e
                this.searchList = []
                this.cityList.map(item => {
                    item.list.map(items => {
                        if(items.name.indexOf(this.value) > -1) {
                            this.searchList.push(items)
                        }
                    })
                })
            }, 300);
        },
        getCityList() {
            this.HTTP.getCityList().then(res => {
                console.log(res.data)
                if(res.data.code == 0) {
                    this.hostCities = res.data.data.hostCities
                    this.cityList = res.data.data.openCityWordList
                }
            })
        },
        clickLetter(e) {
            let innerText
            let element
            if(e.target.innerText === "热" || e.target.innerText === '#') {
                innerText = 'hot'
                element = this.$refs[innerText]
            } else {
                innerText = e.target.innerText
                element = this.$refs[innerText][0]
            }
            this.currentLetter = e.target.innerText
            element.scrollIntoView()
        },
        selectCity(e) {
            console.log(e.target.innerText)
            this.city = e.target.innerText
            this.cityList.map(item => {
                item.list.map(items => {
                    if(items.name.indexOf(e.target.innerText) > -1) {
                        this.cityCode = items.code
                    }
                })
            })
            this.$router.back(-1)
        }
    },
    beforeRouteLeave(to, from, next) {
        // console.log(to.name)
        if (to.name == 'selectPOI' || to.name == "shopSearch") {
            to.query.city = this.city;
            to.query.cityCode = this.cityCode;
        }
        next();
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    background-color: #F8F8F9;
}
.search {
    margin: 2.667vw 4vw;
    // box-shadow: 0 1.33vw 4vw 0 rgba(29,34,51,0.06)
}
.content {
    position: relative;
    padding: 1vw 6.667vw 0 6.66vw;
    font-size: 3.733vw;
    color: #333333;
    .alphabet {
        position: fixed;
        width: 2.66vw;
        font-size: 2.66vw;
        right: 1vw;
        top: 31.467vw;
        color: #8E9099;
        text-align: center;
        .item {
            height: 2.66vw;
            margin-bottom: 2vw;
        }
        .active {
            color: #518CDB;
        }
    }
    .hot {
        .hot_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .item {
                width: 26.8vw;
                height: 9.33vw;
                text-align: center;
                line-height: 9.33vw;
                margin-bottom: 2.667vw;
                background-color: #fff;
            }
        }
    }
    .city_list {
        .item {
            padding: 4vw 0;
            border-bottom: 0.0133vw solid #EDF1FB;
        }
    }
    .title {
        color: #8E9099;
        font-size: 3.467vw;
        margin: 2.667vw 0;
        line-height: 4.933vw;
    }
}
.empty {
    width: 92vw;
    height: 85vw;
    background: #ffffff;
    border-radius: 1.33vw;
    display: flex;
    margin: 4vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 23.2vw;
        height: 23.2vw;
    }
    .empty_desc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 4.5vw;
        font-size: 3.2vw;
        line-height: 4.5vw;
        color: #999999;
        .title {
            color: #1D2233;
            font-size: 3.733vw;
            font-weight: bold;
            line-height: 5.33vw;
        }
    }
}
</style>
