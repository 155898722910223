const varieties = () => import(  '@/views/take/varieties' );  //外卖品类
const sales     = () => import(  '@/views/take/sales' );  //门店月销售排行
const details   = () => import(  '@/views/take/details' );  //门店详情
const data   = () => import(  '@/views/take/dataState' );  //数据说明
const rank   = () => import(  '@/views/take/rank' );  //外卖选址品类热度查看更多

const mallRank = () => import('@/views/ranks/mall-rank') // 商场查询更多店铺
const commonRankPage = () => import('@/views/ranks/rank-page') // 通用的排行页面
export default [
    {
      path: '/rank/page',
      name: 'commonRankPage',
      component: commonRankPage,
      meta     : { id: 10227 },
    },
    {
      path: '/rank/mall',
      name: 'mallRank',
      component: mallRank,
      meta     : { id: 10217 },
    },
    {
        path     : '/take/varieties',
        name     : 'varieties',
        component: varieties,
        meta     : { id: 10158 },
    },
    {
        path     : '/take/sales',
        name     : 'sales',
        component: sales,
        meta     : { id: 10159 },
    },
    {
        path     : '/take/details',
        name     : 'details',
        component: details,
        meta     : { id: 10160 },
    },
    {
        path     : '/take/data',
        name     : 'data',
        component: data,
        meta     : { id: 10161 },
    },
    {
        path     : '/take/rank',
        name     : 'rank',
        component: rank,
        meta     : { id: 10207 },
    }
]
