import { Axios } from './http'
const commonRequest = (config) => {
	return new Promise((resolve, reject) => {
		Axios.request(config).then(res => {
			resolve(res.data.data);
		}).catch(e => {
			reject(e);
		});
	})
}
const MAPPING = {
    addQuestion: '/v11/question/add',
    getCanUsePkg: '/v11/question/canUsePkg',
    getDownLoadUrl: '/v5/report/export'
}

const generateReport= (data) => {
	return commonRequest({
		url: MAPPING.addQuestion,
		method: 'post',
		data
	});
}

const getCanUsePkg= (goodsId) => {
	return commonRequest({
		url: MAPPING.getCanUsePkg + '/' + goodsId,
		method: 'get',
	});
}

const getDownLoadUrl= (id) => {
  return commonRequest({
    url: MAPPING.getDownLoadUrl + '/' + id,
    method: 'post',
  });
}

export default {
    generateReport,
    getCanUsePkg,
    getDownLoadUrl
}
