<template>
    <div class="brand">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true" />

        <AutoView :header="true" :footer="false" class="brand--view">
            <van-skeleton :loading="! entity" title avatar :animate="true" :row="10" class="s-skeleton">
                <div v-if="entity" class="brand--content">
                    <div class="brand--hot">
                        <div class="hot-box">
                            <div v-if="entity.matched" class="head-box">根据你的条件，为你推荐以下{{ entity.brandList.length || '?' }}个品牌</div>
                            <div v-else class="head-box icon">暂无匹配条件的品牌，为您推荐以下热门品牌</div>
                            <div class="card-box">
                                <!-- <van-card v-for="( item, index ) of entity.brandList" :key="index"
                                    :title="item.brandName"
                                    @click="onDetails( item )">
                                    <template #thumb>
                                        <van-image
                                            fit="cover"
                                            lazy-load
                                            :src="item.logoUrl" />
                                    </template>
                                    <template v-if="item.isRecord" #tag>已备案</template>
                                    <template #tags><van-tag plain>{{ item.category }}</van-tag></template>
                                    <template #price><p>预估投资总额：{{ item.joinTotalCost || '暂未披露' }}</p></template>
                                    <template #footer>
                                        <van-button size="mini" @click.stop="onTap( item )">品牌分析</van-button>
                                    </template>
                                </van-card> -->
                                <BrandCard v-for="( item, index ) of entity.brandList" :key="index" :item="item" :top="index + 1" :analyse="true"
                                    @click="onDetails" @click2="onTap" class="item" ref="brandItemRef" />
                            </div>
                        </div>
                    </div>

                    <div class="brand--hot bgFade">
                        <div class="hot-box">
                            <div class="head-title">对比详情</div>
                            <van-tabs>
                                <van-tab v-for="( item, index ) in brands" :key="index" :title="item">
                                    <van-grid :column-num="entity.brandList.length + 1" >
                                        <template v-for="( table, ix ) of list[ index ]">
                                        <van-grid-item v-for="( cell, cix ) of table" :key="ix +'-'+ cix" :class="{
                                            bg1: ix === 0 && cix === 0,
                                            bg2: ix === 0 && cix > 0 || ix > 0 && cix === 0,
                                            bg3: ix > 0 && cix > 0 }" :text="cell" />
                                        </template>
                                    </van-grid>
                                </van-tab>
                            </van-tabs>
                        </div>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>

        <!-- 报告提交 -->
        <van-popup v-model="showInputs" position="bottom" round>
            <div class="brand--popup">
                <div class="head-title f-tac">请确认意向加盟地区</div>
                <div class="l-panel s-radius__s">
                    <div class="l-panel s-radius__s f-vmt regionPicker" :class="{ on: field.city }" @click="showRegionPicker = true">
                        {{ field.city ? '当前定位 ' + field.city : '请选择加盟意向地区' }}
                    </div>
                    <!-- <div class="f-tac">
                        <router-link class="tips sty2" :to="{ name: 'brandDemo' }">查看样例</router-link>
                    </div> -->
                </div>
                <SoltFooter class="btnView">
                    <van-button type="info" :disabled="check" block class="s-radius__s" @click="onSubmit">立即分析</van-button>
                </SoltFooter>
            </div>
        </van-popup>

        <!-- 省市区选择 -->
        <OpenCityPicker type="v2open" v-model="showRegionPicker" @confirm="getCityZone"/>

        <!-- 全局遮罩层 -->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import BrandCard from './components/card';
import OpenCityPicker from '../home/components/openCityPicker';
import { setLocalStorage } from "@/utils/index";
let _timer = 0;

export default {
    components: {
        OpenCityPicker,
        BrandCard
    },
    data () {
        return {
            title           : '品牌对比',
            loading         : false, // 数据加载状态
            showRegionPicker: false,
            showInputs      : false,
            brands          : [],
            list            : [],
            entity          : null,

            field: {
                city: null,
            },
            form: {
                brandCode: null,
                brandName: null,
                code     : null,
                city     : null,
            },
        };
    },
    computed: {
        check () {
            return ! this.form.code;
        },
    },
    methods: {
        loadData ( params ) {
            let method = " ";
            if (params.isIndex == '1') {
                method = this.HTTP.comparisonAndAnalysis;
            } else {
                method = this.HTTP.brandRecommList;
            }
            method( params ).then( ( res ) => {
                if ( ! res ) throw new Error();
                this.entity = res;
            } ).catch( ( e ) => {
                this.$toast( '没有查询到数据，请稍候重试！' );
                setTimeout( () => {
                    this.$router.replace( '/brand' );
                }, 2000 );
            } );
        },
        getCityZone ( picker, code ) {
            this.field.city = picker.join( '' );
            this.form.city  = picker.join( '-' );
            this.form.code  = code[ 2 ] || code[ 1 ] || code[ 0 ];
        },
        onDetails ( item ) {
            setLocalStorage("versionCode", window._appCode);
            this.swRouter( '/brand/details/' + item.brandCode );
        },
        onTap ( item ) {
            this.form.brandCode = item.brandCode;
            this.form.brandName = item.brandName;
            this.showInputs = true;
        },
        onSubmit () {
            if ( this.loading ) return;
            this.loading    = true;
            this.showInputs = false;
            const title = `【${this.form.brandName}】加盟品牌推荐分析报告`;
            const input = [ {
                code : 'REGION',
                value: {
                    code: this.form.code,
                    text: this.form.city,
                },
            }, {
                code : 'BRAND',
                value: {
                    code: this.form.brandCode,
                    text: this.form.brandName,
                },
            } ];
            this.HTTP.createReport( 10003, input, title, title ).then( ( res ) => {
                const id     = res.reportInstanceId;
                const report = {
                    id,
                    title,
                    labels: [],
                };
                this.submitting = false;
                this.$store.commit( 'setReport', report );
                // this.$toast( '正在生成，请稍候...' );
                this.goto( res.reportInstanceId, res.waitTime, res.type );
            } ).catch( ( e ) => {
                if ( e.code ) {
                    if ( e.code === 201004 ) {
                        this.backHome();
                        return;
                    } else if ( e.code === 201011 ) {
                        this.$toast( e.msg );
                    }
                } else {
                    this.$toast( '网络连接不佳，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                    this.loading    = false;
                }, 2000 );
            } );
        },
        backHome () {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'gotoPage', 'home' );
            } else {
                this.$router.push( '/' );
            }
        },
        goto ( id, seconds, type ) {
            const ms = typeof seconds === 'number' ? seconds * 1000 : 0;
            if ( ms ) {
                this.loading = true;
            }
            _timer = setTimeout( () => {
                switch ( type ) {
                    case 2:
                        this.swRouter( '/pay/' + id );
                        break;
                    case 3:
                        this.swRouter( '/h5/#/my/reports' );
                        break;
                    default:
                        this.swRouter( { path: '/charts/' + id, query: { first: true } } ); // first 为首次打开
                }
            }, ms );
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler ( params ) {
                // 回填参数
                this.field.city = params.cityName || null;
                this.form.city  = params.cityName || null;
                this.form.code  = params.regionCode || null;
                this.loadData( params );
            },
        },
        entity ( data ) {
            if ( data.hasOwnProperty( 'brandCompareList' ) ) {
                const brands = [];
                const list   = [];
                for ( const column of data.brandCompareList ) {
                    brands.push( column.topColumn );
                    const table  = [];
                    for ( const item of column.compareColumnList ) {
                        const row = [ item.desc ];
                        for ( const cell of column.compareColumnValues ) {
                            row.push( cell[ item.key ] );
                        }
                        table.push( row );
                    }
                    list.push( table );
                }
                this.brands = brands;
                this.list   = list;
            }
        },
    },
    created() {
        this.title = this.$route.query.title;
    },
    destroyed () {
        clearTimeout( _timer );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.brand {
    width: 100%;
    background-color: #F7F6FA;

    .brand--content {
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #EAF3FE, #FFFFFF);
    }
    .head-box {
        padding: 3.7vw 0 8vw;
        font-size: $font_320;
        line-height: $font_440;
        color: #3596F3;

        &.icon {
            background: url(/static/icon/light.png) no-repeat left top 3.5vw;
            background-size: 4vw;
            padding-left: 4.5vw;
        }
    }
    .head-title {
        padding: 0 0 3.6vw;
        font-size: 4.5vw;
        font-weight: 800;
    }
    #{&}--hot {
        padding: 2vw $padding_4 $padding_4;

        .card-box {
            margin-top: -4vw;
            // padding: 0 3.9vw 0 4.5vw;
            // background-color: white;
            border-radius: $radius_xs;
            // box-shadow: inset 0 0 0 1px #EBEAEE;
            box-shadow: 0 0 1vw rgba(95, 169, 230, 0.18);
            .item {
                margin-bottom: $padding_3;
                border-radius: $radius_xs;
                box-shadow: 0 0.8vw 0.8vw 0 rgba(#5FA9E6, .08);
            }
        }

        &.bgFade {
            // background: linear-gradient(to bottom,white 10%, $c-pageBg2 50%);
            padding-bottom: $padding;
        }
    }
    #{&}--popup {
        border-top-left-radius: $radius-s;
        h3 {
            padding: $padding2 $padding;
            font-size: $font_480;
            line-height: $font_667;
        }
        .tips {
            color: $c-info;
            font-size: $font_320;
            line-height: $font_440;
            margin-top: $margin;
            &.sty2 {
                display: inline-block;
                margin: 12vw 0 3vw;
                color: $c-main;
                font-size: $font_347;
                padding-left: 5.5vw;
                background: url( /static/icon/mark.png ) no-repeat left center;
                background-size: contain;
            }
        }
        .head-title {
            padding-top: 5.87vw;
        }
        .btnView {
            position: static;
            // height: $footerHeight;
            box-sizing: border-box;
            padding: $headerMargin $padding;
            margin-top: 2vw;
            ::v-deep .soltFooter--view {
                height: 10.67vw;
            }
            ::v-deep .van-col {
                height: 100%;
            }
        }
        .regionPicker {
            position: relative;
            background-color: #F7F6F9;
            color: #AEB1BD;
            height: 13.3vw;
            font-size: 4.27vw;
            line-height: 13.3vw;
            box-shadow: inset 0 0 0 1px #EBEAEE;

            &.on {
                color: $c-text;
            }

            &:after {
                position: absolute;
                content: '切换';
                right: $padding1;
                color: $c-main;
                font-weight: normal;
                font-size: $font_320;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
        ::v-deep .van-cell, .s-label {
            padding-left: $padding;
            padding-right: $padding;
        }
        ::v-deep .van-cell:not(:last-child)::after {
            left: 6.4vw;
            right: 6.4vw;
        }
        .s-label {
            margin: 4vw 0 6.4vw;
        }
        .l-panel {
            padding: 0 $padding;
        }
        .gap{
            margin-top:  4vw;
        }
        .noborder{
            font-size: 4.27vw;
            border:  none;
            line-height: normal;
            &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color:    #AEB1BD;
            }
        }
        .name-wrap{
            position: relative;
            padding-right: 36vw;
            .name-input{
                padding: 0;
                width: 100%;
                background-color: rgb(247, 246, 249);
            }
            .gender{
                position: absolute;
                right: 4vw;
                top: 3vw;
                display: flex;
            }
            .gender-tag{
                display: flex;
                align-items: center;
                justify-content: center;
                width:14vw;
                height:7vw;
                margin-right: 1.5vw;
                border-radius:6px;
                font-size:3vw;
                border:1px solid rgba(235,234,238,1);
                background:rgba(255,255,255,1);
                &.active{
                    color: rgba(81,140,219,1);
                    border:1px solid rgba(81,140,219,1);
                }
            }
        }
    }
}
::v-deep .van-card__content {
    min-height: 80px;
}
::v-deep .van-card:not(:first-child) {
    margin-top: 0;
}
::v-deep .van-tabs__nav {
    background-color: transparent;
}
::v-deep.van-tabs.van-tabs--line .van-tabs__wrap{
    height: auto;
}
::v-deep.van-tabs.van-tabs--line .van-tabs__wrap::after{
    border: none;
}
::v-deep.van-tabs__nav--line{
    padding-bottom: 0;
}
::v-deep.van-tabs .van-tab{
    min-width: 20vw;
    line-height: 6.4vw;
    color:#8E9099;
    font-size:3.2vw;
    font-weight:500;
    background: #E4EDFF;
    border-radius: $radius_xs;
    margin-right: 2.4vw;
    flex: none;
}
::v-deep.van-tabs .van-tab.van-tab--active{
    color:#347fff;
    box-shadow: inset 0 0 0 1px #347fff;
}

::v-deep.van-card{
    padding: 2.8vw 0 2.3vw;
    background: none;
    border-bottom: solid 0.1vw #EDF1FB;
}
::v-deep.van-card:last-child{
    border-bottom: 0;
}
::v-deep.van-card__thumb {
    margin-top: 1.5vw;
    margin-right: $padding_3;
    width: 18.7vw;
    height: 18.7vw;
    border-radius: $radius_xs;
    border: none;

    .van-image {
        width: 100%;
        height: 100%;
    }
    .van-image__error {
        width: 18.7vw;
        height: 18.7vw;
    }
}
::v-deep.van-card__title{
    font-size: $font_373;
    line-height: 6.4vw;
    min-height: 6.4vw;
    font-weight: bold;
}
::v-deep.van-tag--default.van-tag--plain{
    margin: 1.5vw 0 2.3vw;
    height:4.5vw;
    border-radius: $radius_xs;
    font-size:2.7vw;
    font-weight:400;
    line-height:4.5vw;
    background-color: #DCF3FD;
    color: #47BADD;
}
::v-deep.van-tag--default.van-tag--plain::after{
    border: none;
}
::v-deep.van-card__price{
    font-size:3.2vw;
    font-weight:400;
    color:rgba(142,144,153,1);
    line-height:4.4vw;
}
::v-deep.van-card__tag{
    width: 10.4vw;
    top: inherit;
    left: 50%;
    bottom: 1.6vw;
    margin-left: -5.2vw;
    padding: 0.5vw 0;
    border-radius: $radius_xs;
    font-size:2.7vw;
    font-weight:400;
    color:rgba(255,255,255,1);
    line-height:4vw;
    text-align: center;
    background: linear-gradient(to bottom, #00D69F, #26C8A5);
}
::v-deep.van-card__footer{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -3.4vw;
}
::v-deep.van-card__footer .van-button{
    height: 6.8vw;
    padding: 0 2.1vw;
    background:rgba(81,140,219,1);
    border-radius: $radius_xs;
    font-size:3.2vw;
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:6.8vw;
}
.van-hairline--top-bottom::after{
    border: 0 !important;
}
::v-deep .van-grid {
    overflow: hidden;
    border-radius: $radius_xs;
    box-shadow: 0 0 0 1px #DEDEE7;
}
::v-deep .van-grid-item__content{
    padding: 2.7vw 0;
    .van-grid-item__text{
        font-size: 2.9vw !important;
    }
}
::v-deep.van-grid-item__text{
    font-size: 3vw;
    text-align: center;
}
::v-deep .van-grid-item__content::after{
    border-color: #DEDEE7;
}
.bg1{
    ::v-deep .van-grid-item__content{
        background-color: #CDCDE8;
        .van-grid-item__text{
            color: #495B95;
            font-weight: bold;
        }
    }
}
.bg2{
    ::v-deep .van-grid-item__content{
        background-color: #F8F8F9;
        .van-grid-item__text{
            color: #1D2233;
            font-weight: bold;
        }
    }
}
.bg3{
    ::v-deep .van-grid-item__content{
        background-color: #ffffff;
        .van-grid-item__text{
            color: $c-text;
        }
    }
}
.van-hairline--top::after{
    border: 0 !important;
}
.van-hairline--top-bottom::after{
    border: 0 !important;
}
::v-deep .van-tabs__line {
  opacity: 0;
}
</style>
