<template>
    <div id="china_map_box">
        <div id="china_map"></div>
    </div>
</template>

<script>
import "echarts/map/js/china.js";
import echarts from "echarts";

export default {
    props: {
        provinceData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            mapData: [],
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.deelData();
        });
    },
    methods: {
        deelData() {
            let data = this.provinceData;
            let newArr = [];
            data.forEach((item) => {
                let obj = {
                    name: this.deelProvince(item.name),
                    value: Number(item.count),
                };
                newArr.push(obj);
            });
            this.mapData = newArr;
            this.initEchartMap();
        },
        deelProvince(name) {
            if (!name || name.length < 3) {
                return name;
            }
            let isAutonomy = name.search("自治区") != -1;
            if (isAutonomy) {
                return name.search("内") != -1 ? name.substr(0, 3) : name.substr(0, 2);
            } else {
                try {
                    return name.match(/(\S*)(省|市|特别行政区)/)[1];
                } catch (error) {
                    return name;
                }
            }
        },
        //初始化中国地图
        initEchartMap() {
            let mapDiv = document.getElementById("china_map");
            let myChart = echarts.init(mapDiv);

            let options = {
                tooltip: {
                    triggerOn: "click", //mousemove、click
                    padding: 8,
                    borderWidth: 0,
                    borderColor: "#1D2233",
                    backgroundColor: "rgba(29, 34, 51, 0.5)",
                    textStyle: {
                        color: "#fff",
                        fontSize: 12,
                    },
                    formatter: function (e, t, n) {
                        let data = e.data || {};
                        let context = `
                            <div>
                                <p style="font-size:13px;">${data.name || e.name}</p>
                                <p class="tooltip_style">
                                        <span class="tooltip_left">门店：</span>
                                        <span class="tooltip_right">${data.value || 0}</span>
                                </p>
                            </div>
                            `;
                        return context;
                    },
                },
                title: {
                    text: "注：在营门店分布",
                    left: "left",
                    top: "16",
                    textStyle: {
                        color: "#666",
                        fontSize: 12,
                        fontWeight: "normal",
                    },
                },
                visualMap: {
                    roam: "scale",
                    show: true,
                    right: 0,
                    bottom: 62,
                    showLabel: true,
                    textStyle: {
                        color: "#999",
                        fontSize: 9,
                    },
                    pieces: [
                        {
                            gt: 500,
                            label: "> 500",
                            color: "#619BFF",
                        },
                        {
                            gte: 100,
                            lt: 499,
                            label: "100 - 499",
                            color: "#88B4FF",
                        },
                        {
                            gte: 50,
                            lt: 99,
                            label: "50 - 99",
                            color: "#A7C7FF",
                        },
                        {
                            gte: 0,
                            lt: 49,
                            label: "0 - 49",
                            color: "#DAE8FF",
                        },
                    ],
                },
                geo: {
                    roam: false,
                    map: "china",
                    // scaleLimit: {
                    //     min: 1,
                    //     max: 2,
                    // },
                    // zoom: 1,
                    top: 10,
                    left: 0,
                    right: 0,
                    bottom: 10,
                    label: {
                        normal: {
                            show: true,
                            fontSize: 6,
                            color: "#666",
                        },
                    },
                    itemStyle: {
                        normal: {
                            //shadowBlur: 50,
                            //shadowColor: 'rgba(0, 0, 0, 0.2)',
                            borderColor: "rgba(0, 0, 0, 0.2)",
                        },
                        emphasis: {
                            areaColor: "#ff752b",
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            borderWidth: 0,
                        },
                    },
                },
                series: [
                    {
                        roam: false,
                        name: "门店数(家)",
                        type: "map",
                        geoIndex: 0,
                        data: this.mapData,
                    },
                ],
            };
            myChart.setOption(options);
            // myChart.on("mouseover", function () {
            //     myChart.dispatchAction({
            //         type: "downplay",
            //     });
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
#china_map_box {
    height: 64vw;
    position: relative;
}

#china_map_box #china_map {
    height: 100%;
}

#china_map_box .china_map_logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 12vw;
}
</style>
<style>
#china_map .tooltip_style {
    line-height: 1.7;
    overflow: hidden;
    z-index: 1;
}
#china_map .tooltip_left {
    float: left;
}
#china_map .tooltip_right {
    float: right;
}
</style>
