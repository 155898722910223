<template>
    <div class="province-wrap">
        <div class="province-item" v-for="item in bgmData" :key="item.key" @click="$emit('clickProvince', item.code)">
            <p class="name">{{ item.name || "-" }}</p>
            <div class="num-wrap">
                <span class="num">{{ item.count }}</span>
                <img class="icon" src="/static/icon/icon_bg_right.png" alt="" />
            </div>
        </div>
        <div class="province-item temp"></div>
    </div>
</template>

<script>
export default {
    props: {
        bgmData: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.province-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 4vw 3.2vw;
    .province-item {
        width: 29.33vw;
        height: 17.07vw;
        background: #fafafb;
        text-align: center;
        border-radius: 1.33vw;
        margin-bottom: 2.13vw;
        padding: 2.67vw 0;
        box-sizing: border-box;
        align-self: start;
        .name {
            height: 4.8vw;
            line-height: 4.8vw;
            font-size: 3.2vw;
            font-weight: bold;
            color: #2f354b;
        }
        .num-wrap {
            margin-top: 0.53vw;
            .num {
                height: 4.8vw;
                line-height: 4.8vw;
                font-size: 3.2vw;
                color: #676c84;
            }
            .icon {
                width: 4vw;
                height: 4vw;
                vertical-align: bottom;
            }
        }
    }
    .temp {
        height: 0;
        margin-bottom: 0;
        border: none;
        padding: 0;
    }
}
</style>
