<template>
  <div class="charts">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" color="#347fff" :back="showBack" :class="{ sub: showSubTitle }">
      <h6 v-if="showSubTitle" slot="right" class="subTitle">参谋告诉你答案</h6>
    </TitleHeader>

    <AutoView :footer="!unlock" class="charts--view">
      <iframe ref="view" :src="url" frameborder="0" class="innerView">不支持内嵌窗口</iframe>
    </AutoView>
    <LoadingOverlay :show="loading" />
    <ReportPayPopupV2 v-if="showReportPayPopup"  ref="payPopupRef" :goodsId="goodsId" :linkParams="linkParams.value" @success="payReportSuccess" />
  </div>
</template>

<script>
import ReportPayPopupV2 from '@/components/base-pay/report-pay-popup-v2.vue';
import { checkOrderStatus } from '@/request/order';
let _timer = 0;
import reportConfig from '@/utils/config'
export default {
  name: "charts",
  components: {
    ReportPayPopupV2
  },
  data() {
    return {
      goodsId: 10001,
      title: "正在加载",
      loading: true, // 数据加载
      subTitle: "---",
      text: "解锁深度报告",
      showBack: true,
      showMenu: true,
      shouExpert: true, // 显示专家解读
      showSubTitle: false,
      showCatalog: false,
      showContinue: true, // 是否显示解锁按钮（特殊报告）
      isReady: false, // 界面已就绪
      isSended: false, // 已下发数据
      isClient: false,
      lastClick: "cover", // 当前页码
      needLocation: false, // 是否需要定位

      id: 0, // 报告ID
      stdId: 0, // 类型ID
      menus: [], // 菜单列表
      pageMap: "一,二,三,四,五,六,七,八,九,十,十一,十二,十三,十四,十五,十六,十七,十八,十九,二十".split(","), // 序号表
      first: false, // 首次访问
      host: "/report/?id=",
      benefit: null, // 用户权益
      entity: null, // 报告实体
      isFirstOpenReportInV2_0_0: false, // 首次进入页面标识
      showSaveBtn: true,
      showCoupon:false,//是否展示优惠卷弹框
      memberGoodsId:14004,//特惠商品id
      payExtParams: {},
      linkParams: {}
    };
  },
  methods: {
    payReportSuccess(pkgId,orderNo) {
      this.checkOrder(pkgId,orderNo)
    },
    checkOrder(pkgId,orderNo) {
      checkOrderStatus({
        orderNo,
        isApplePaySuccess: true
      }).then(res => {
        console.log('check order', res)
        if (res.status) {
          if(pkgId) {
            this.swRouter(res.link.url)
          } else {
            const linkParams = JSON.stringify(this.linkParams)
            this.swRouter("/success?view=reportPayment&id=" + this.goodsId + "&linkParams=" + linkParams);
          }
        } else {
          this.checkOrder(pkgId,orderNo)
        }
      })
    },
    clickPayPopup() {
      if (window._appCode>=50300) {
        this.swRouter(`/h5/#/member/sku?source=free_report&ps=${encodeURIComponent(JSON.stringify(this.linkParams.value))}`)
      } else {
        this.$refs.payPopupRef.showPopup()
      }
    },
    onTap() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.REPORT.TAP_UNLOCK);
      this.swRouter("/pay/" + this.id);
    },
    switchShare(st) {
      // console.log( 'switchShare:', st );
      // 排除 10002市调报告 10014查周边
      if (this.entity && ![10002, 10014, 10018, 10019].includes(this.stdId)) {
        this.JSBridge.callHandler(
          "switchShareBtnV2",
          st
            ? {
              code: "REPORT",
              id: this.id,
            }
            : false
        );
      }
      // 市调及解锁报告显示分享按钮 排除10014查周边
      if (this.entity && ([10002].includes(this.stdId) || this.unlock) && ![10014, 10018, 10019].includes(this.stdId)) {
        this.JSBridge.callHandler(
          "switchShareBtn",
          st
            ? {
              type: "normal",
              id: this.id,
              check: false,
            }
            : false
        );
      }
      // 市调/品牌洼地 报告不显示分析按钮
      // if ( ! this.entity || [ 10002, 10004 ].includes( this.entity.stdQuestionId ) ) return;
      // this.JSBridge.callHandler( 'switchShareBtn', st ? { id: this.id, type: 'normal', check: [ 10003 ].includes( this.entity.stdQuestionId ) !== true } : false );
    },
    addListener(e) {
      switch (e.data.type) {
        case "payEvent": {
          this.goodsId = e.data.content.id
          this.payExtParams = {
            ext: JSON.stringify({
              source: "free_report_mask_unlocking",
              pageId: this.$route.meta.id,
              moduleId: 6130100,
              btnId: e.data.content.btnId,
              refId: ''
            })
          }
          this.JSBridge.BTNCLICK(this.$route.meta.id, null, 6130100, e.data.content.btnId)
          this.clickPayPopup()
          break;
        }
        case "ready": {
          this.isReady = true;
          this.sendData();
          break;
        }
      }
    },
    sendData() {
      // 已发送或未就绪或未得到数据将不调用下发
      if (this.isSended || !this.isReady || !this.entity) return;
      this.isSended = true; // 标记为已下发
      clearTimeout(_timer); // 清理超时提醒
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.viewTarget.postMessage({ type: "data", content: this.entity }, "*");
    }
  },
  watch: {
    showCatalog(val) {
      this.switchShare(!val);
    },
    entity(e) {
      // 组装菜单数据
      const list = [];
      if (e.config.showCover) {
        list.push({
          id: "cover",
          name: "封面",
        });
      }
      if (e.config.showCatalog) {
        list.push({
          id: "catalog",
          name: "目录",
        });
      }
      if (e.config.showConclusion) {
        list.push({
          id: "conclusion",
          name: "概览",
        });
      }
      let index = 0;
      for (const model of e.reportModelInstanceFullList) {
        const lock = !e.payed && model.type > 1;
        list.push({
          id: model.id,
          name: `${this.pageMap[index]}. ${model.name}`,
          isBgm: false,
          lock,
        });
        index++;
        if (model.hasOwnProperty("bgmInstanceFullList") === false) continue;
        for (const bgm of model.bgmInstanceFullList) {
          let name = bgm.name.split("-");
          name = name[name.length - 1];
          list.push({
            id: bgm.bgmId,
            name,
            isBgm: true,
            lock,
          });
        }
      }
      this.menus = list;
      this.benefit = e.userBenefitResp;
      // console.log( 'switchShare entity' );
      this.switchShare(true);
    },
  },
  computed: {
    unlock() {
      return this.entity && (this.entity.payed || this.entity.priceFen === 0);
    },
    url() {
      if (!this.entity) return "about:blank";
      console.log(this.host + this.id)
      return this.host + this.id;
      // return 'http://localhost:9002/' + this.id;
    },
    viewTarget() {
      return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
    },
    showReportPayPopup() {
      return this.entity && [10140].includes(this.entity.stdQuestionId);
    }
  },
  created() {
    // 获取报告ID
    const id = this.$route.params.id;
    if (!id || id == 'null') {
      this.$toast("无效报告信息，即将返回首页！");
      this.$router.push("/");
      return;
    }
    if (id=='searchBrand') {
      this.$router.replace('/reportView/searchBrand')
      return;
    }
    this.first = this.$route.query.first || false;

    // 获取报告地址
    this.host = this.HTTP.getReportUrl();
    const entityHandler = (res) => {
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      console.log("entity:", res);
      // 数据提取
      this.id = res.id;
      this.stdId = res.stdQuestionId;
      const stdId = this.stdId;


      if (stdId === 10001) this.title = "选址分析报告";
      else if (stdId === 10004) this.title = "加盟品牌位置分析报告";
      else if (stdId === 10124) {
        let bgmOutput = res.reportModelInstanceFullList[0].bgmInstanceFullList[0].output
        if (typeof bgmOutput === 'string') {
          bgmOutput = JSON.parse(bgmOutput)
        }
        if (bgmOutput.output) {
          bgmOutput.output = JSON.parse(bgmOutput.output)
        }
        const stdQuestionId = bgmOutput.output[0].stdQuestionId
        const bgmConfigs = {
          10032: '查周边',
          10037: '查外卖',
          10018: '行业热力',
          10019: '人口热力'
        }
        this.title = bgmConfigs[stdQuestionId] + "数据对比"
      }
      else this.title = res.name || "阅读报告";

      // 是否需要定位
      const location = this.needLocation;
      // 是否定制报告
      const isCustom = [10001, 10002, 10003, 10004, 10005, 10006, 10011, 10014, 10015, 10018, 10019, 10020, 10021, 10022,10124].includes(stdId);
      this.showSubTitle = !isCustom;
      // 扩展字段
      res["config"] = {
        isCustom: isCustom, // 是否定制报告
        isShare: false, // 是否来自分享
        showTitle: false, // 是否显示报告标题
        showHeader: this.first, // 是否显示报告头
        showConclusion: stdId !== 10002, // 是否显示报告结论
        showCover: isCustom ? `//${window.location.host}/static/img/${stdId}.jpg` : false, // 是否显示报告封面 支持url
        showInputs: !this.first && stdId !== 10002, // 是否显示报告参数
        showCatalog: isCustom && stdId !== 10002, // 是否显示报告目录
        isShowEle: true, // 是否显示饿了么广告位
        templateId: stdId,
        tokenId: null,
        isNewNeay: window._appCode >= 200,
        isFirstGuide: stdId === 10014 && window._appCode >= 200 && !this.isFirstOpenReportInV2_0_0, // 首次打开
        location: location,
        showWm: window._appCode >= 302, // 显示外卖sku广告位
        showCommunity: window._appCode >= 305, // 显示社区sku广告位
      };
      // 保存修改后的数据实体
      this.entity = res;
      let linkParams = {
        poi: '',
        "value":{
          "atv":{},
          "goodsId":this.goodsId,
          "gps":{},
          "industry":{},
          "remark":"",
        }
      }
      const input = JSON.parse(this.entity.input)
      input.forEach(e => {
        if (e.code==='GPS_R') {
          linkParams.value.gps = e.value
          linkParams.poi = JSON.stringify({
            lat: e.value.lat,
            lng: e.value.lng,
            radius: e.value.radiusKm,
            search: e.value.poiName
          })
        } else if (e.code ==='INDUSTRY') {
          linkParams.value.industry = e.value
        } else if (e.code ==='ATV') {
          linkParams.value.atv = e.value
        }
      })
      this.linkParams = linkParams
      // 设置超时并下发数据
      _timer = setTimeout(() => {
        this.$toast("无法获取报告，请稍候重试！");
        this.loading = false;
      }, 30000);
      this.sendData();
    };

    const entity = this.$store.getters.temp;
    if (entity && id === "" + entity.id) {
      this.$store.commit("setTemp", null);
      entityHandler(entity);
    } else {
      // 加载网络数据
      // this.HTTP.getReportData(446604181177399)
      this.HTTP.getReportData(id)
        .then((res) => {
          entityHandler(res);
        })
        .catch((e) => {
          if (e.code==201062) {
            this.loading = false
            this.JSBridge.callHandler("closePage");
            this.JSBridge.callHandler('onNormalizingCall', {
              type: 4,
              id: reportConfig.openStoreReportId,
              params: null,
              isClear: false,
            });
          } else {
            this.$toast(e.msg || "网络不稳定，请稍候重试！");
          }
        });
    }
    // 添加通信监听
    window.addEventListener("message", this.addListener);
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 设置客户端类型
    this.isClient = window.isClient || true;
  },
  beforeDestroy() {
    // 隐藏分享按钮
    this.JSBridge.callHandler("switchShareBtnV2", false);
    this.JSBridge.callHandler("switchShareBtn", false);
  },
  destroyed() {
    // 移除通信监听
    window.removeEventListener("message", this.addListener);
    // 移除定时监听
    clearTimeout(_timer);
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.charts {
  overflow: hidden;
  height: 100vh;
  background-color: $c-pageBg2;
  ::v-deep .titleHeader {
    .view {
      &--r {
        width: 100%;
        top: 0;
        left: 0;
      }
      .subTitle {
        position: absolute;
        bottom: 2.5vw;
        left: 50%;
        color: #9aa9be;
        transform: translateX(-50%);
        font-weight: normal;
        font-size: $font_320;
      }
      .right {
        position: relative;
        @include flexColumn;
        width: 100%;
        height: $headerHeight;
        align-items: flex-end;

        .save {
          @include flexColumn;
          width: $headerHeight;
          height: 100%;
          color: $c-main;

          &.dis {
            color: $c-info;
          }
        }

        .tips {
          position: absolute;
          bottom: 0;
          right: 1.5vw;
          width: 30vw;
          height: 10vw;
          background: url(/static/img/tips_save.png) no-repeat left top;
          background-size: cover;
          transform: translateY(50%);
          z-index: 99;
        }
      }
    }
    &.sub {
      .title {
        padding-bottom: 5.75vw;
      }
    }
  }

  #{&}--view {
    overflow-y: hidden;
    .innerView {
      width: 100%;
      height: 100%;
    }
  }

  #{&}--icon {
    position: fixed;
    right: 0;
    bottom: 13vh;
    width: 14.4vw;
    height: 12vw;
    border-top-left-radius: 6vw;
    border-bottom-left-radius: 6vw;
    background-color: white;
    box-shadow: 0rem 1.33vw 4vw rgba(#33465f, 0.11);
    &::before {
      content: "";
      display: block;
      width: 10vw;
      height: 10vw;
      background: #283950 url(/static/icon/menu.png) no-repeat center center;
      background-size: cover;
      border-radius: 50%;
      margin: 1vw 0 0 1vw;
    }

    &.icon2 {
      bottom: 20vh;
      &::before {
        background: url(/static/icon/livechat.jpg) no-repeat center center, linear-gradient(#f4b66a, #fcca8d);
        background-size: cover;
      }
    }
  }
  #{&}--livechat {
    position: fixed;
    right: 0;
    bottom: 12vh;
    height: 12vw;
    width: 24vw;
    background: url(/static/img/report/livechat.png) no-repeat right top;
    background-size: cover;
  }
  #{&}--menu {
    position: relative;
    overflow: hidden;
    width: 65vw;
    height: 100vh;
    background-color: white;
    padding: 24.8vw 0 $padding;
    box-sizing: border-box;

    .header {
      position: absolute;
      top: 13.2vw;
      left: 0;
      width: 100%;
      padding: 0 $padding;
      box-sizing: border-box;
      box-shadow: 0 calc(5vw - 1px) 0 white, 0 5vw 0 $c-line;

      h1 {
        @include singleLine;
        font-size: $font_453;
        line-height: $font_653;
        font-weight: bold;
      }
      p {
        font-size: $font_320;
        line-height: $font_440;
        color: $c-info;
        margin-top: 1vw;
      }
    }
    .list {
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      li {
        position: relative;
        @include singleLine;
        padding: 0 $padding 0 $padding;
        height: 14.53vw;
        line-height: 14.53vw;
        font-size: $font_373;
        font-weight: bold;
        &.on {
          color: $c-main;
          background-color: #f8f8f9;
        }
        &.sub {
          padding-left: 12.4vw;
          font-weight: normal;
          &.lock {
            background: url(/static/icon/lock.png) no-repeat left $padding center;
            background-size: 5vw;
            color: $c-info;
          }
        }
        // &:after {
        //     content: attr( data-page );
        //     position: absolute;
        //     right: $margin;
        //     top: 0;
        //     color: $c-info;
        //     font-weight: normal;
        //     font-size: $font_320;
        // }
      }
    }
  }
  #{&}--footer {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;

    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      line-height: normal;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity transform 0.25s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(-75%);
    }
  }

  // 延迟隐藏动画
  .hid_delay_35 {
    animation: fadeOut 1s forwards 4s;
  }
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
}
</style>
