<template>
  <div>
    <div class="coupon-wrap" v-for="item in signData" :key="item.key">
      <div
        :class="{
          common: item.goodsRespList,
          'common-card1': item.goodsRespList && item.goodsRespList.length == 1 && item.status == 0,
          'common-card-has1': item.goodsRespList && item.goodsRespList.length == 1 && item.status == 1,
          'common-card2': item.goodsRespList && item.goodsRespList.length == 2 && item.status == 0,
          'common-card-has2': item.goodsRespList && item.goodsRespList.length == 2 && item.status == 1,
          'common-card3': item.goodsRespList && item.goodsRespList.length == 3 && item.status == 0,
          'common-card-has3': item.goodsRespList && item.goodsRespList.length == 3 && item.status == 1,
          'common-card4': item.goodsRespList && item.goodsRespList.length == 4 && item.status == 0,
          'common-card-has4': item.goodsRespList && item.goodsRespList.length == 4 && item.status == 1,
        }"
      >
        <p class="title">{{ item.title }}</p>
        <ul class="ul-flex" v-for="card in item.goodsRespList" :key="card.key">
          <li class="li-left">{{ card.goodsName }}</li>
          <li class="li-mid">
            <p class="discount">{{ card.couponDesc }}</p>
            <p class="auto" v-if="card.discountDesc">{{ card.discountDesc }}</p>
          </li>
          <li class="li-right"></li>
        </ul>
        <div class="float-mid-right">
          立省
          <p class="coupon-btn">
            <strong>{{ item.desc }}</strong>
            元
          </p>
          <p class="coupon-btn" @click="swRouter('/coupons?keepAlive=1')" v-if="item.status == 1">
            <span>去查看</span>
          </p>
        </div>
        <div class="float-top-right">{{ item.tips }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    signData: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-wrap {
  width: 100%;
  .common {
    position: relative;
    margin: 4.8vw 0;
    overflow: hidden;
    padding: 0 4.8vw;
    .title {
      font-size: 4.27vw;
      color: #ad5904;
      font-weight: bold;
      margin-top: 4.8vw;
    }
  }
  .common-card1 {
    height: 36.27vw;
    background: url("/static/img/signin/signin_card.png") no-repeat center;
    background-size: cover;
  }
  .common-card-has1 {
    height: 36.27vw;
    background: url("/static/img/signin/signin_card_has.png") no-repeat center;
    background-size: cover;
  }
  .common-card2 {
    height: 58.13vw;
    background: url("/static/img/signin/signin_card_2.png") no-repeat center;
    background-size: cover;
  }
  .common-card-has2 {
    height: 58.13vw;
    background: url("/static/img/signin/signin_card_has_2.png") no-repeat center;
    background-size: cover;
  }
  .common-card3 {
    height: 79.47vw;
    background: url("/static/img/signin/signin_card_3.png") no-repeat center;
    background-size: cover;
  }
  .common-card-has3 {
    height: 79.47vw;
    background: url("/static/img/signin/signin_card_has_3.png") no-repeat center;
    background-size: cover;
  }
  .common-card4 {
    height: 99.73vw;
    background: url("/static/img/signin/signin_card_4.png") no-repeat center;
    background-size: cover;
  }
  .common-card-has4 {
    height: 99.73vw;
    background: url("/static/img/signin/signin_card_has_4.png") no-repeat center;
    background-size: cover;
  }
  .ul-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16vw;
    margin-top: 5.33vw;
    text-align: center;
    .li-left {
      flex: 0 0 45.33vw;
      font-size: 3.73vw;
      color: #7e3409;
      font-weight: bold;
    }
    .li-mid {
      flex: 0 0 21.87vw;
      font-size: 3.73vw;
      color: #ff5900;
      .discount {
        font-size: 4.27vw;
        font-weight: bold;
      }
      .auto {
        color: #ffa22a;
        margin-top: 1.6vw;
      }
    }
    .li-right {
      flex: 1;
    }
  }
  .float-mid-right {
    position: absolute;
    top: 43%;
    right: 0.53vw;
    font-size: 3.73vw;
    width: 22.93vw;
    text-align: center;
    color: #feedc2;
    font-weight: bold;
    .coupon-btn {
      margin-top: 1.6vw;
      strong {
        font-size: 20px;
      }
      span {
        display: inline-block;
        width: 12.8vw;
        height: 5.87vw;
        line-height: 5.87vw;
        font-size: 2.93vw;
        color: #feedc2;
        border-radius: 3.2vw;
        border: 1px solid #feedc2;
      }
    }
  }
  .float-top-right {
    position: absolute;
    top: 2.67vw;
    right: 2.67vw;
    font-size: 3.2vw;
    color: #ad5904;
    font-weight: bold;
    transform: rotate(28deg);
  }
}
</style>
