<template>
    <div ref="coustomEl" class="custom">
        <StateBar bgcolor="#F4F5FA" />
        <TitleHeader title="定制您的需求" bgcolor="#F4F5FA"></TitleHeader>

        <AutoView :header="true" :footer="false" class="custom--view" @touchmove.native="scroll">
            <div class="content">
                <div class="head--img">
                    <div class="head-title">定制您的需求</div>
                    <div class="bdb-line"></div>
                    <div class="head-tips">请提供以下信息，以便我们更好的为您分析</div>
                </div>
                <div class="l-panel s-radius__s">
                    <template>
                        <label require class="s-label">需求类型</label>
                        <van-cell is-link arrow-direction="down" @click="showTypePicker=true" :value="form.type||'选择需求类型'" :class="{fontbold:form.type}" class="s-select s-radius__s f-vmt" />
                    </template>

                    <template>
                        <label require class="s-label gap">需求描述</label>
                        <van-field v-model="form.question" class="s-text s-radius__s f-vmt text-area"
                            type="textarea"
                            @focus="focusInput" @blur="blurInput"
                            maxlength="100"
                            placeholder="请填写详细需求描述"
                            show-word-limit
                            :disabled="submitting" />
                    </template>

                    <template>
                        <label require class="s-label gap">所在地区</label>
                        <van-cell is-link arrow-direction="down" @click="showOpenCityPicker=true" :value="form.zone||'选择所在的地区'" :class="{fontbold:form.zone}" class="s-select s-radius__s f-vmt" />
                    </template>
                    <template>
                        <!-- 行业选择 INDUSTRY -->
                        <label require class="s-label gap">行业</label>
                        <van-cell is-link arrow-direction="down" @click="showIndustryPicker=true" :value="form.industry||'选择行业'" :class="{fontbold:form.industry}" class="s-select s-radius__s f-vmt" />
                    </template>

                    <template>
                        <label require class="s-label gap">联系人称呼</label>
                        <div class="name-wrap s-select s-radius__s f-vmt van-cell">
                            <input v-model="form.liaison" @focus="focusInput" @blur="blurInput('name',$event)" placeholder="输入姓名" maxlength="5" class="name-input noborder"/>
                            <div class="gender">
                                <span class="gender-tag" :class="{active:genderType===0}" @click="changeGenderType(0)">先生</span>
                                <span class="gender-tag" :class="{active:genderType===1}" @click="changeGenderType(1)" style="margin-right:0">女士</span>
                            </div>
                        </div>
                    </template>

                    <template>
                        <label require class="s-label gap">联系人手机号码</label>
                        <input v-model="form.mobile" @focus="focusInput" @blur="blurInput('phone',$event)" maxlength="11" placeholder="输入手机号码" type="tel" class="s-select s-radius__s f-vmt van-cell noborder"/>
                    </template>
                </div>

                <van-button type="info" block :disabled="disabled" :loading="submitting" class="s-radius__s submit-btn" @click="submit">提交</van-button>
            </div>
        </AutoView>

        <!-- 需求类型选择 -->
        <van-popup v-model="showTypePicker" round position="bottom">
            <van-picker show-toolbar title="选择需求类型" :columns="reqTypes" @cancel="showTypePicker = false" @confirm="confirmType" />
        </van-popup>
        <!-- 行业选择 -->
        <IndustryPicker type="all" v-model="showIndustryPicker" @confirm="getIndustry"/>
        <OpenCityPicker type="all" v-model="showOpenCityPicker" @confirm="getCityZone"/>
    </div>
</template>

<script>
import IndustryPicker from './components/IndustryPicker';
import OpenCityPicker from './components/openCityPicker';
import { isNotEmpty, isPhoneNo } from '@/utils/index.js';
const reqTypes = ['经营诊断', '开店选址', '招商加盟', '其他'];
export default {
    name      : 'custom',
    components: {
        IndustryPicker,
        OpenCityPicker,
    },
    data () {
        return {
            reqTypes,
            submitting        : false, // 提交中
            disabled          : true,
            showIndustryPicker: false,
            showTypePicker    : false,
            showOpenCityPicker: false,
            genderType        : 0,
            form              : {
                industry: '',
                liaison : '',
                mobile  : '',
                question: '',
                type    : '',
                zone    : ''
            }
        };
    },
    watch: {
        form: {
            deep: true,
            handler ( formOpts ) {
                let isOk = true;
                for ( const key in formOpts ) {
                    if ( key === 'mobile' && ! isPhoneNo( formOpts[key] ) ) {
                        isOk = false;
                        break;
                    } else {
                        if ( ! isNotEmpty( formOpts[key] ) ) {
                            isOk = false;
                            break;
                        }
                    }
                }
                this.disabled = ! isOk;
            }
        }
    },
    methods: {
        focusInput ( e ) {
            this.inputFocus = e.target;
        },
        blurInput ( type, $event ) {
            this.inputFocus = null;
            if ( typeof type === 'object' ) {
                $event = type;
            } else if ( type === 'phone' ) {
                if ( ! isPhoneNo( this.form.mobile ) ) {
                    this.$toast( '手机号码格式不正确' );
                }
            }
            $event && $event.target.blur();
        },
        scroll ( e ) {
            if ( this.inputFocus ) {
                this.inputFocus.blur();
            }
        },
        changeGenderType ( genderType ) {
            this.genderType = genderType;
        },
        submit () {
            if ( this.submitting ) return;
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.CUSTOM.TAP_SUBMIT );
            this.submitting = true;
            this.$toast( '正在提交...' );
            const param = { ...this.form };
            param.liaison += ( ['先生', '女士'][this.genderType] );
            this.HTTP.createQueation( param ).then( ( res ) => {
                this.swRouter( '/success?view=custom' );
                // 埋点上报
                this.JSBridge.report( this.JSBridge.REPORT_EVENT.CUSTOM.TAP_SUBMIT_SUC );
            } ).catch( ( e ) => {
                console.warn( e );
                this.$toast( '网络不佳T.T请稍候重试' );
                setTimeout( () => { this.submitting = false; }, 2000 );
                // 埋点上报
                this.JSBridge.report( this.JSBridge.REPORT_EVENT.CUSTOM.TAP_SUBMIT_FAL );
            } );
        },
        getIndustry ( picker, code ) {
            // 选择行业
            this.form.industry = picker.filter( ( item ) => !! item ).join( '-' );
        },
        confirmType ( type ) {
            this.form.type = type;
            this.showTypePicker = false;
        },
        getCityZone ( picker, code ) {
            this.form.zone = picker.join( '-' );
        },
    },
    // created () {
    //     this.HTTP.getUserInfo().then( ( res ) => {
    //         this.form.mobile = res.phone;
    //     } );
    // },
    mounted () {
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        this.JSBridge.callHandler( 'switchBackBtn', true );
        this.JSBridge.callHandler( 'switchNavbar', false );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.custom {
    height: 100vh;
    background-color: $c-pageBg2;
    overflow: hidden;
    #{&}--view {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
         -webkit-overflow-scrolling: touch;
         height: calc( 100% - 15.47vw) !important;
        .l-panel {
            padding: 4vw;
        }
        .s-text ::v-deep .van-field__control {
            height: 31.47vw;
            font-size: $font_427;
            line-height: 6vw;
            &::-webkit-input-placeholder {
                font-size: $font_427;
                line-height: 6vw;
            }
        }
    }
    .content{
        padding: $margin $padding;
        background: url(/static/img/custom.jpg) no-repeat left top;
        background-size: 100%;
    }
    .bgc-title{
        background-image: linear-gradient(to bottom right, #FCFDFF, #EEEFF8);
    }
    .bgc-state{
        background-image: linear-gradient(to bottom right, #FFFFFF, #F7F8FB);
    }
    .head--img{
        margin-top: -1.2vw;
        height: 31vw;
        .head-title{
            height: 8vw;
            font-size:5vw;
            font-weight:900;
            color:rgba(29,34,51,1);
            line-height:8vw;
        }
        .bdb-line{
            margin: 3vw 0;
            width:7vw;
            height:4px;
            background:rgba(81,140,219,1);
            border-radius:2px;
        }
        .head-tips{
            width:43vw;
            font-size:3vw;
            font-weight:400;
            color:rgba(142,144,153,1);
            line-height:4vw;
        }
    }
    .fontbold{
        font-size: $font_427;
        font-weight:700;
        color:rgba(29,34,51,1) !important;
        ::v-deep .van-cell__value--alone{
           font-size: $font_427;
            font-weight:700;
            color:rgba(29,34,51,1) !important;
        }
    }
    .gap{
        margin-top:  4vw;
    }
    .noborder{
        font-size: 4.27vw;
        border:  none;
        line-height: normal;
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    #AEB1BD;
        }
    }

    .text-area{
        ::v-deep .van-field__control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    #AEB1BD;
        }
    }
    .name-wrap{
        position: relative;
        padding-right: 36vw;
        .name-input{
            padding: 0;
            width: 100%;
            background-color: rgb(247, 246, 249);
        }
        .gender{
            position: absolute;
            right: 4vw;
            top: 3vw;
            display: flex;
        }
        .gender-tag{
            display: flex;
            align-items: center;
            justify-content: center;
            width:14vw;
            height:7vw;
            margin-right: 1.5vw;
            border-radius:6px;
            font-size:3vw;
            border:1px solid rgba(235,234,238,1);
            background:rgba(255,255,255,1);
            &.active{
                color: rgba(81,140,219,1);
                border:1px solid rgba(81,140,219,1);
            }
        }
    }
    .submit-btn{
        margin: $margin 0 $padding * 2;
        ::v-deep .van-button__text{
            font-size: $font_400;
        }
    }
}
</style>
