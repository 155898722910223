<template>
    <div class="block-component">
        <div class="chart-mask"></div>
        <div ref="blockChartRef" class="chart-item"></div>
        <div class="total-container">
            <div class="total-label">总计：{{totalCount}}家</div>
        </div>
    </div>
</template>

<script>
import { deepCopy, calcListPercent } from "../valueUtils"
import echarts from 'echarts'
export default {
    props: {
        dataObject: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            chartItem: undefined,
            dataList: [],
            colors: [],
            totalCount: 0,
            minValue: 10
        }
    },
    mounted() {
        this.load(this.dataObject);
    },
    watch: {
        dataObject: function(nv, ov) {
            this.load(nv);
        }
    },
    methods: {
        dispose() {
            if (this.chartItem !== undefined) {
                echarts.dispose(this.chartItem);
                this.chartItem = undefined;
            }
        },
        load(dataObject) {
            if (dataObject == undefined) {
                console.log('block chart dataObject is undefined');
                return;
            }
            let copyDataObject = this.loadData(dataObject);
            this.colors = [];
            this.dataList = [];
            this.totalCount = 0;
            for (let i = 0; i < copyDataObject.length; ++i) {
                const dataItem = copyDataObject[i];
                this.totalCount += dataItem.count;
                const color = dataItem.color;
                this.colors.push({
                    'color': [color]
                });
                let children = this.getChildrenData(i, dataItem.name, dataItem.value, dataItem.count, dataItem.percent);
                this.dataList.push(children);
            }
            this.intiChart();
        },
        loadData(data) {
            if (data.length == 0) {
                return [];
            }
            let dataCopy = deepCopy(data);
            const firstItem = dataCopy[0];
            if (!firstItem.hasOwnProperty('percent')) {
                if (firstItem.hasOwnProperty('count')) {
                    calcListPercent(dataCopy, 'count');
                } else {
                    if (firstItem.hasOwnProperty('value')) { // 认定value是count
                        dataCopy.forEach((item) => {
                            let valueObj = item.value;
                            if (typeof(valueObj) == 'string') {
                                valueObj = parseInt(valueObj);
                            }
                            item.count = valueObj;
                        })
                        calcListPercent(dataCopy, 'count');
                    } else { // 连value都没有
                        return [];
                    }
                }
            }
            dataCopy.sort(function(a, b) {
                return b.percent - a.percent;
            });
            let readLeftWidth = 100;
            for (let i = 0; i < dataCopy.length; ++i) {
                let item = dataCopy[i];
                if (item.percent < this.minValue) {
                    readLeftWidth -= (this.minValue - item.percent);
                }
            }
            if (readLeftWidth != 100) {
                for (let i = 0; i < dataCopy.length; ++i) {
                    let item = dataCopy[i];
                    if (item.percent < this.minValue) {
                        item.value = this.minValue;
                    }
                    else {
                        item.value = item.percent * readLeftWidth / 100;
                    }
                }
            }
            return dataCopy;
        },
        getChildrenData(level, name, value, count, percent) {
            if (level > 0) {
                let children = this.getChildrenData(level - 1, name, value, count, percent);
                return {
                    'name': name,
                    'children': [children]
                };
            }
            return {
                'name': name,
                'value': value,
                'count': count,
                'percent': percent
            };
        },
        getColorByName(name) {
            let color = '#fff';
            if (name == '娱乐') {
                color = '#AC58F5';
            }
            else if (name == '酒店') {
                color = '#8879F4';
            }
            else if (name == '教育') {
                color = '#30C8D7';
            }
            else if (name == '餐饮') {
                color = '#F58958';
            }
            else if (name == '零售') {
                color = '#518CDB';
            }
            return color;
        },
        intiChart() {
            let element = this.$refs.blockChartRef;
            if (element == undefined) {
                console.log('block chart no element');
                return;
            }
            if (this.chartItem == undefined) {
                this.chartItem = echarts.init(element);
            }
            let option = {
                series: [
                    {
                        type: 'treemap',
                        roam: false,
                        nodeClick: false,
                        breadcrumb: {
                            show: false
                        },
                        width: '100%',
                        height: '100%',
                        label: {
                            position: 'insideBottomLeft',
                            formatter: (param) => {
                                let name = param.name;
                                let count = param.data.count;
                                let percent = param.data.percent.toFixed(0);
                                let labelStr = [];
                                labelStr.push('{countStyle|' + count + '家}');
                                labelStr.push('{nameStyle|' + name + ':' + percent + '%}');
                                return labelStr.join('\n');
                            },
                            rich: {
                                countStyle: {
                                    color: '#fff',
                                    fontSize: 10,
                                    fontWeight: 600,
                                    align: 'left'
                                },
                                nameStyle: {
                                    color: '#fff',
                                    fontSize: 9,
                                    fontWeight: 500,
                                    align: 'left'
                                }
                            }
                        },
                        colorMappingBy: 'index',
                        levels: this.colors,
                        data: this.dataList
                    }
                ]
            };
            this.chartItem.setOption(option);
        }
    }
}
</script>

<style scoped>
.block-component {
    position: relative;
    width: 100%;
    height: 100%;
}
.chart-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.chart-item {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.total-container {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 3px;
    padding: 2px 5px;
    background-color: rgba(29, 34, 51, 0.2);
}
.total-label {
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
}
</style>
