<template>
    <div class="wrapper">
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-if="!firstLoading">
            <div class="top">
                <VanImage class="bg" @load="imageLoadDone" src="/static/newPeople/newPeople_head.png" />
                <div :class="canReceiveCoupon ? 'receiveBtn headBtn' : 'disable disable_head'" @click="receiveCoupon(84000)">{{canReceiveCoupon ? "一键免费领取" : "已领取"}}</div>
            </div>
            <div class="bottom">
                <div class="partOne">
                    <VanImage class="bg_part" @load="imageLoadDone" src="/static/newPeople/part1.png" />
                    <img class="coins" src="/static/newPeople/coins.png" alt="" />
                    <div class="partOne_title">
                        <div class="name_l">
                            <div>新人专享</div>
                            <span>免费数据查询</span>
                            <div>X3</div>
                        </div>
                        <div class="btn_r" @click="gotoVipShop">
                            查询
                            <img class="right" src="/static/newPeople/right.png" alt="" />
                        </div>
                    </div>
                    <div class="partOne_card">
                        <VanImage class="bg_card" @load="imageLoadDone" src="/static/newPeople/partOne_card.png" />
                    </div>
                </div>
                <div class="partTwo">
                    <VanImage class="bg_part" @load="imageLoadDone" src="/static/newPeople/part2.png" />
                    <div class="part_title">
                        <div class="label">
                            <div class="label_l">开店补贴金</div>
                            <div class="label_r"><span>60</span>元</div>
                            <img class="icon" src="/static/newPeople/value.png" alt="" />
                        </div>
                        <div class="value">全场无门槛优惠券，下单立减！</div>
                    </div>
                    <div class="coupon">
                        <div class="couponItem" v-for="item in couponList" :key="item.name">
                            <img class="couponItemImg" :src="item.url" alt="" />
                            <div class="shadow"></div>
                        </div>
                    </div>
                    <div :class="canReceiveCoupon ? 'receiveBtn part2Btn' : 'disable part2Btn disable_body'" @click="receiveCoupon(84200)">{{canReceiveCoupon ? "一键领取" : "已领取"}}</div>
                    <div class="ad part2_ad">
                        <div class="adItem" v-for="item in part2AdList" :key="item.url">
                            <img :src="item.url" alt="" />
                            <div class="gotoUser" @click="gotoDetail(item.id, item.btnId)">
                                去使用
                                <img class="right" src="/static/newPeople/right.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="partThird">
                    <VanImage class="bg_part" @load="imageLoadDone" src="/static/newPeople/part3.png" />
                    <div class="part_title">
                        <div class="label">
                            <div class="label_l">新手开店礼包</div>
                            <div class="label_r"><span>399</span>元</div>
                            <img class="icon" src="/static/newPeople/value.png" alt="" />
                        </div>
                        <div class="value">开店知识<span>地图1份</span>+开店资料包<span>100份+</span>专属开店顾问<span>1V1分析</span>
                        </div>
                    </div>
                    <div class="ad part3_ad">
                        <div class="adItem" v-for="item in part3AdList" :key="item.url">
                            <img :src="item.url" alt="" />
                        </div>
                    </div>
                    <div class="introduce">
                        <img class="avator" src="/static/newPeople/avator.png" alt="">
                        <div class="introduce_wrapper">
                            <div class="skill">
                                <span>擅长行业：</span>
                                <span>餐饮</span>
                                <span>零售</span>
                            </div>
                            <div class="introduce_detail">前国家某智库特聘政策顾问，曾为百果园、九方等知名企业提供咨询服务……</div>
                            <div class="name">分析师：{{name}}</div>
                        </div>
                    </div>
                    <div class="title">专属开店顾问1V1咨询分析</div>
                    <div class="part3Btn" @click="onLineService">回复“新人礼包”免费领取</div>
                </div>
                <div class="partFouth" v-if="isShowEle">
                    <VanImage class="bg_part" @load="imageLoadDone" src="/static/newPeople/part4.png" />
                    <div class="btn" @click="jumpEle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
    data() {
        return {
            couponList: [
                { url: "/static/newPeople/coupon1.png", name: "开店补贴金 1", value: "10" },
                { url: "/static/newPeople/coupon2.png", name: "开店补贴金 2", value: "20" },
                { url: "/static/newPeople/coupon3.png", name: "开店补贴金 3", value: "30" },
            ],
            part2AdList: [
                { url: "/static/newPeople/part2_ad1.png", id: "10001", btnId: "84202" },
                { url: "/static/newPeople/part2_ad2.png", id: "10026", btnId: "84203" },
            ],
            part3AdList:[
                { url: "/static/newPeople/part3_ad1.png" },
                { url: "/static/newPeople/part3_ad2.png" },
            ],
            name: "Amy",
            userStatus: 0,
            activeId: "",
            couponIdList: [],
            isShowEle: false,
            loading: false,
            firstLoading: false
        };
    },
    computed: {
        canReceiveCoupon() {
            return this.loading && this.userStatus === 0
        }
    },
    created() {
        this.activeId = this.$route.params.bannerid
    },
    mounted() {
        this.HTTP.getActiveInfo(this.activeId).then(res => {
            const list = res.data.activeItemResList
            this.couponIdList = list.map(item => {
                return item.couponId
            })
            const status = list.map(item => {
                return item.receiveStatus
            }).some(item => {
                return item == 0
            })
            this.userStatus = status ? 0 : 1
            this.loading = true
        })
        this.isShowEle = this.$store.state.isShowEle
    },
    methods: {
        imageLoadDone() {
            picNum++;
            if (picNum >= 3) {
                this.firstLoading = false;
            }
        },
        receiveCoupon(id) {
            if(id == 84000) {
                this.JSBridge.BTNCLICK( 10172, '', id, '' );
            } else {
                this.JSBridge.BTNCLICK( 10172, '', id, 84201);
            }
            if(this.canReceiveCoupon) {
                this.HTTP.batchReceive({
                    activeId: this.activeId,
                    couponIdList: this.couponIdList
                }).then(res => {
                    this.$toast.success("领取成功")
                    this.userStatus = 1
                })
            }
        },
        gotoVipShop() {
            this.JSBridge.BTNCLICK( 10172, '', 84100, 84101);
            if (window._appCode >= 300) {
              this.JSBridge.callHandler( 'onNormalizingCall', {
                type: 5,
                url: "CHANGE_MAIN_TAB",
                id: "queryData",
                isClear: true
              } );
            } else {
              this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "SEARCH_SURROUNDING_V2",
              })
            }
            // this.swRouter('/h5/#/member')
        },
        jumpEle() {
            this.JSBridge.BTNCLICK( 10172, '', 84400, 84401);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 6,
                id: null,
                url: 'https://kaidian.ele.me/?origin=sscm',
                params: JSON.stringify({
                    title: "饿了么商家入驻"
                })
            })
        },
        gotoDetail(id, btnId) {
            this.JSBridge.BTNCLICK( 10172, '', 84200, btnId);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: window._appCode >= 300 ? 'BRAND_INTRODUCTION_V3' : "BRAND_INTRODUCTION_V2",
                id: id,
                isClear: null,
             })
        },
        onLineService() {
            this.JSBridge.BTNCLICK( 10172, '', 84300, 84301);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "ONLINE_SERVICE",
                params: JSON.stringify({
                    type: "1",
                    title: "在线客服"
                })
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    ::v-deep .van-image {
        display: block;
        position: relative;
    }
    .top {
        .bg {
            width: 100vw;
        }
        .headBtn {
            background-image: url("/static/newPeople/btn_head.png");
            background-position: 0 -1vw;
            background-size: cover;
        }
    }
    .bottom {
        width: 100vw;
        background-color: #FFEBDF;
        .partOne {
            width: 100vw;
            position: relative;
            margin-top: -0.8vw;
            .coins {
                position: absolute;
                right: -8vw;
                top: -20vw;
                width: 40vw;
                height: 40vw;
            }
            .partOne_title {
                display: flex;
                width: 81.333vw;
                position: absolute;
                top: 16vw;
                left: 9.333vw;
                font-size: 4vw;
                justify-content: space-between;
                align-items: center;
                .name_l {
                    font-weight: 600;
                    color: #323232;
                    div {
                        display: inline;
                    }
                    span {
                        color: #ff3344;
                    }
                }
                .btn_r {
                    color: #fff;
                    width: 25.6vw;
                    height: 10.667vw;
                    border-radius: 26.667vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-image: linear-gradient(to right, #fd8764, #ff3344);
                    .right {
                        height: 5.33vw;
                        margin: 0.5vw 0 0 2vw;
                    }
                }
            }
            .partOne_card {
                width: 76vw;
                height: 36.8vw;
                border-radius: 2.13vw;
                position: absolute;
                top: 32vw;
                left: 8.333vw;
                border-radius: 2.133vw;
                padding: 3.2vw;
                .bg_card {
                    width: 110%;
                    position: absolute;
                    left: -3.5vw;
                    top: -3.5vw;
                }
            }
        }
        .partTwo {
            width: 100vw;
            position: relative;
            .coupon {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                width: 80vw;
                top: 42vw;
                left: 9.6vw;
                .couponItemImg {
                    width: 25vw;
                    height: 21.867vw;
                }
                .shadow {
                    width: 24vw;
                    height: 17.867vw;
                    position: absolute;
                    top: 4vw;
                    border-radius: 0.8vw;
                    margin-left: 0.5vw;
                    box-shadow: 0 2px 4px -1px #fe6858;
                }
            }
            .part2_ad {
                top: 90vw;
                left: 7.5vw;
            }
            .part2Btn {
                position: absolute;
                top: 68vw;
                background-image: url("/static/newPeople/btn_body.png");
                background-size: cover;
            }
            .disable_body {
                background-image: url("/static/newPeople/disable_body.png");
            }
        }
        .partThird {
            width: 100vw;
            position: relative;
            .part3_ad {
                top: 37vw;
                left: 7vw;
            }
            .introduce {
                width: 85vw;
                position: absolute;
                top: 65vw;
                left: 4vw;
                padding: 3.467vw;
                display: flex;
                justify-content: space-between;
                .avator {
                    width: 24vw;
                    height: 30.4vw;
                }
                .introduce_wrapper {
                    width: 57.6vw;
                    height: 30.4vw;
                    .skill {
                        font-size: 2.4vw;
                        color: #518CDB;
                        span {
                            text-align: center;
                            line-height: 4.933vw;
                            height: 4.933vw;
                            display: inline-block;
                            background-repeat: no-repeat;
                            background-size: cover;
                            &:nth-child(1) {
                                width: 16.53vw;
                                color: #8D5D22;
                                background-image: url('/static/newPeople/image_1.png');
                            }
                            &:nth-child(2) {
                                width:10.667vw;
                                background-image: url('/static/newPeople/image_2.png');
                            }
                            &:nth-child(3) {
                                width:10.667vw;
                                background-image: url('/static/newPeople/image_3.png');
                            }
                        }
                    }
                    .introduce_detail {
                        font-size: 3.2vw;
                        color: #888888;
                        height: 13vw;
                        line-height: 4.533vw;
                        margin: 4vw 0 4.267vw 0;
                    }
                    .name {
                        color: #676C84;
                        font-size: 3.467vw;
                    }
                }
            }
            .title {
                position: absolute;
                top: 104vw;
                width: 100vw;
                text-align: center;
                color: #323232;
                font-size: 3.733vw;
            }
            .part3Btn {
                position: absolute;
                width: 64vw;
                margin-left: 18vw;
                height: 16vw;
                color: #b77101;
                text-align: center;
                line-height: 16vw;
                background-image: linear-gradient(to right, #fff7d5, #ffc66f);
                border-radius: 8vw;
                font-weight: 600;
                top: 111vw;
                font-size: 4.26vw;
                background-image: url("/static/newPeople/btn_body.png");
                background-size: cover;
            }
            .disable_body {
                background-image: url("/static/newPeople/disable_body.png");
            }
        }
        .partFouth {
            width: 100vw;
            position: relative;
            margin-top: -6vw;
            .btn {
                position: absolute;
                width: 74vw;
                margin-left: 13vw;
                height: 16vw;
                bottom: 12vw;
                border-radius: 8vw;
            }
        }
        .part_title {
            position: absolute;
            top: 20vw;
            left: 9vw;
            width: 78vw;
            font-size: 4.267vw;
            color: #323232;
            font-weight: 600;
            .label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2.33vw;
                .label_r {
                    color: #ff3344;
                    font-size: 4.8vw;
                    span {
                        font-size: 6.667vw;
                    }
                }
            }
            .value {
                font-size: 3.2vw;
                width: 90vw;
            }
            span {
                color: #ff3344;
            }
            .icon {
                position: absolute;
                top: -7vw;
                right: 2vw;
                width: 11.73vw;
            }
        }
        .ad {
            height: 23.46vw;
            width: 85.6vw;
            display: flex;
            position: absolute;
            justify-content: space-between;
            .adItem {
                img {
                    width: 41.6vw;
                    height: 100%;
                }
                .gotoUser {
                    width: 26.6vw;
                    height: 10.667vw;
                    font-size: 3.73vw;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-image: linear-gradient(to right, #3f87ff, #2f6ef8);
                    border-radius: 26.6vw;
                    position: absolute;
                    bottom: 0;
                    margin-left: 8.53vw;
                    transform: translateY(50%);
                    .right {
                        height: 4.33vw;
                        width: 3.5vw;
                        margin: 0 0 0 2vw;
                    }
                }
            }
        }
    }
}
.bg_part {
    width: 100%;
    display: block;
}
.receiveBtn {
    position: absolute;
    top: 108.533vw;
    width: 64vw;
    margin-left: 18vw;
    height: 16vw;
    color: #b77101;
    text-align: center;
    line-height: 16vw;
    background-image: linear-gradient(to right, #fff7d5, #ffc66f);
    border-radius: 8vw;
    font-weight: 600;
    animation-name: scale;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.disable {
    position: absolute;
    top: 108.533vw;
    width: 64vw;
    margin-left: 18vw;
    color: #807D79;
    height: 16vw;
    text-align: center;
    line-height: 16vw;
    border-radius: 8vw;
    font-weight: 600;
}
.disable_head {
    background-image: url("/static/newPeople/disable_head.png");
    background-position: 0 -1vw;
    background-size: cover;
}
@keyframes scale {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
</style>
