<template>
    <van-dialog v-model="showDialog" :showConfirmButton="false">
        <div class="wrap">
            <h4 class="h4">提示</h4>
            <p class="success-tips">每日查询次数已耗尽~</p>
            <div>
              <img class="vip-icon" src="/static/discovery/vip-ts.png" />
            </div>
            <!--<p class="success-tips t-color">开通VIP会员无限制</p>-->
            <div class="btn-wrap">
                <span class="cancle" @click="$emit('cancel')">取消</span>
                <span class="info" @click="confirm">开通VIP</span>
            </div>
        </div>
    </van-dialog>
</template>


<script>
export default {
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm')
            this.swRouter("/h5/#/member");
        },
    },
};
</script>

<style lang="scss" scoped>
.wrap {
    padding: 4.8vw;
    text-align: center;
    .vip-icon {
      width: 37.6vw;
      height: 4vw;
      margin-top: 2.667vw;
    }
    .wrap-img {
        width: 21.33vw;
        height: 21.33vw;
        padding: 4vw 0;
    }
    .h4 {
        font-size: 4.53vw;
        color: #1d2233;
        font-weight: bold;
        margin-bottom: 5.87vw;
    }
    .success-tips {
        font-size: 3.73vw;
        color: #8e9099;
        line-height: 7.47vw;
    }
    .t-color {
        font-size: 4vw;
        color: #fdae37;
        line-height: 8.53vw;
    }
    .btn-wrap {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: center;
        margin-top: 5.33vw;
        .cancle {
            display: inline-block;
            width: 34.13vw;
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 4vw;
            color: #8e9099;
            background: #f7f6f9;
            border-radius: 1.6vw;
            text-align: center;
        }
        .info {
            display: inline-block;
            width: 34.13vw;
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 4vw;
            color: #fff;
            background: #347fff;
            border-radius: 1.6vw;
            text-align: center;
        }
    }
}
</style>
