<template>
    <div class="hot-search-list__wrap">
        <van-sticky :offset-top="headerHeight">
            <div class="select-box">
                <div class="industry-tabs-wrap" :swipe-threshold="4">
                    <van-tabs v-model="tabIndex">
                        <van-tab title="热搜榜" :name="3"></van-tab>
                        <van-tab title="黑马榜" :name="4"></van-tab>
                    </van-tabs>
                </div>
            </div>
        </van-sticky>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div class="content" v-show="tabIndex === 3">
            <ul class="hot-list">
                <li class="item" v-for="(top, i) in hotList" :key="i" @click="deeplink({ type: '6', url: '/h5/#/community/article/'+top.refId })">
                    <span :class="['sort', 'sort--' + top.sortNum]">{{ top.sortNum > 3 ? top.sortNum + '.' : '' }}</span>
                    <span class="name">{{ top.name }}</span>
                    <span class="view-num">{{ top.viewCount }}</span>
                    <i class="fire-icon" v-if="top.sortNum === 1"></i>
                </li>
            </ul>
        </div>
        <div class="content" v-show="tabIndex === 4">
            <ul class="hot-list">
                <li class="item" v-for="(top, i) in blackList" :key="i" @click="deeplink({ type: '6', url: '/h5/#/community/article/'+top.refId })">
                    <span class="black-sort"></span>
                    <span class="name">{{ top.name }}</span>
                    <span class="view-num">{{ top.viewCount }}</span>
                </li>
            </ul>
        </div>
        <div class="forum-topic" v-if="!isCloseComment">
            <p class="title">
                加入讨论<span class="num">({{ commentList.length }})</span>
            </p>
            <EvaluateCard />
            <one-comment :hasBorder="i !== commentList.length - 1" v-for="(item, i) in commentList" :key="i" :itemObj="item" :hasReply="!!item.appReplyRes">
                <div  v-if="item.appReplyRes">
                    <one-comment v-for="(rep ,idx) in item.appReplyRes" :key="idx" :itemObj="rep"></one-comment>
                </div>
            </one-comment>
        </div>
    </div>
</template>

<script>
import OneComment from '@/views/hotSearch/components/OneComment';
import EvaluateCard from "./evaluate-card.vue";
export default {
    name      : 'openStoreList',
    components: { OneComment, EvaluateCard },
    props     : {
        headerHeight: {
            type   : Number,
            default: 0
        }
    },
    data () {
        return {
            isCloseComment:  false,
            firstLoading: false,
            tabIndex    : 3,
            selType     : false, // 选择类型
            hotList     : [
                /* 热搜榜 */
            ],
            blackList: [
                /* 黑马榜 */
            ],
            commentList: [] // 评论列表
        };
    },
    mounted () {
        this.isCloseComment = window.isCloseComment
        this.getPageData();
        this.getComment();
    },
    methods: {
        getPageData () {
            this.firstLoading = true;
            this.HTTP.openShop( 3 ).then( ( res ) => {
                console.log( '排行榜：', res );
                this.hotList = res.data.map( ( e, i ) => ( {
                    sortNum  : i + 1,
                    name     : e.name,
                    viewCount: e.value,
                    refId    : e.refId
                } ) );
                this.firstLoading = false;
            } );
            this.HTTP.openShop( 4 ).then( ( res ) => {
                console.log( '排行榜：', res );
                this.blackList = res.data.map( ( e, i ) => ( {
                    sortNum  : i + 1,
                    name     : e.name,
                    viewCount: e.value,
                    refId    : e.refId
                } ) );
            } );
        },
        getComment () {
            const param = {
                current: 1,
                size   : 1000,
                type   : 5
            };
            this.HTTP.getReplys(param).then(res => {
                console.log('评论list：', res);
                this.commentList = res.data || []
            })
        },
        // 跳转
        deeplink ( { type, url, params, id, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            btnId && this.JSBridge.BTNCLICK( this.$route.meta.id, '', 83200, btnId );
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                id,
                params: JSON.stringify( params )
            } );
        }
    }
};
</script>

<style lang="scss" scoped>
.hot-search-list__wrap {
    ::v-deep .van-sticky--fixed {
        background: #f7f7f7;
    }
    .select-box {
        display: flex;
        align-items: center;
        height: 5.6vw;
        padding-top: 1vw;
        padding-bottom: 4vw;
        .industry-tabs-wrap {
            min-width: 28.13vw;
            height: 7.5vw;
            line-height: 100%;
            white-space: nowrap;
            margin: auto;
            ::v-deep .van-tabs__wrap {
                height: 12.533vw;
            }
            ::v-deep .van-tabs__nav {
                background: transparent;
                padding: 0;
                .van-tab {
                    min-width: 9.26667vw;
                    height: 7.5vw;
                    margin-right: 1.06vw;
                    font-size: 3.733vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    background: transparent;
                    box-shadow: none;
                    color: #666;
                    &--active {
                        color: #333333;
                        font-weight: 700;
                        position: relative;
                        &::after {
                            display: none;
                        }
                    }
                }
                .van-tabs__line {
                    display: block;
                    height: 1.33vw;
                    background: url('/static/icon/hot_icon.png') center no-repeat;
                    background-size: 7.47vw 1.07vw;
                }
            }
        }
    }
    .content {
        padding: 2.6667vw;
    }
    ul.hot-list {
        padding: 0 3.33vw;
        background: #fff;
        border-radius: 2.6667vw;
        .item {
            height: 12vw;
            border-bottom: 0.1333vw solid #e7e7e7;
            display: flex;
            align-items: center;
            position: relative;
            &:nth-last-child(1) {
                border: none;
            }
            .sort {
                display: inline-block;
                width: 5.86vw;
                height: 7.733vw;
                line-height: 7.733vw;
                font-size: 3.46vw;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #ff5900;
                text-align: center;
                margin-right: 2.933vw;
                &--1 {
                    background: url('/static/img/hot-search/sort-1.png') 0 0 / 100% 100% no-repeat;
                }
                &--2 {
                    background: url('/static/img/hot-search/sort-2.png') 0 0 / 100% 100% no-repeat;
                }
                &--3 {
                    background: url('/static/img/hot-search/sort-3.png') 0 0 / 100% 100% no-repeat;
                }
            }
            .black-sort {
                width: 3.733vw;
                height: 4.266vw;
                margin-right: 2.933vw;
                background: url('/static/img/hot-search/zhiding@2x.png') 0 0 / 100% 100% no-repeat;
            }
            .name {
                font-size: 3.733vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #1d2233;
                margin-right: 1.86vw;
            }
            .view-num {
                font-size: 3.4667vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #999999;
                // padding-top: 0.5vw;
            }
            .fire-icon {
                position: absolute;
                width: 3.466vw;
                height: 4.133vw;
                top: 4vw;
                right: 0;
                background: url('/static/img/hot-search/hot-fire.png') center / cover no-repeat;
            }
        }
    }
    .forum-topic {
        box-sizing: border-box;
        margin-top: 5.33vw;
        min-height: 50vh;
        width: 100vw;
        padding: 4vw;
        background: #fff;
        .title {
            font-size: 4.26vw;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #1d2233;
            .num {
                color: #999;
                font-size: 3.733vw;
            }
        }
    }
}
</style>
