<!--
Name:
    搜索栏
Props:
    full        : 暂未使用 最大化模式 默认:false
    icon        : 是否展示搜索图标 默认:false
    placeholder : 占位文本 默认:请输入关键字...
    maxlength   : 最大长度 默认:64
    on          : 暂未使用 选中模式 默认:false
Events:
    change      : 输入变更
    clear       : 清空
    entry       : 确定
Methods:
    setBlur     : 取消焦点
-->

<template>
    <div class="search" :class="{ full }" @click="$emit('searchClick')">
        <form action="" @submit="validate" class="search--form" :class="{ icon }">
            <input ref="search" v-model="keyword" type="search" :placeholder="placeholder" disabled :maxlength="maxlength" class="search--input" :class="{ on }" />
            <i v-show="showClear" class="search--clear" @click="clear" />
        </form>
    </div>
</template>

<script>
export default {
    name : 'search',
    props: {
        full: true,
        icon: {
            type   : Boolean,
            default: false,
        },
        placeholder: {
            type   : String,
            default: '请输入关键字...',
        },
        maxlength: {
            type   : Number,
            default: 64,
        },
        on: {
            type   : Boolean,
            default: false,
        },
        value: {
            type   : String,
            default: ''
        }
    },
    data () {
        return {
            keyword  : '',
            showClear: false,
        };
    },
    mounted () {
        this.loadKeyword( this.value );
    },
    watch: {
        keyword ( nVal, oVal ) {
            const val = nVal.trim();
            this.showClear = val && val.length;
            // 搜索推荐
            this.$emit( 'change', { keyword: nVal } );
        },
        value ( nv, ov ) {
            this.loadKeyword( nv );
        }
    },
    methods: {
        loadKeyword ( keyword ) {
            if ( typeof ( keyword ) === 'string' ) {
                if ( keyword.length > 0 ) {
                    this.keyword = keyword;
                }
            }
        },
        validate ( e ) {
            this.$emit( 'entry', { value: this.keyword || this.placeholder } );
            e.preventDefault();
        },
        setKeyword ( keyword ) {
            this.keyword = keyword;
        },
        setFocus () {
            this.$refs.search.focus();
        },
        setBlur () {
            this.$refs.search.blur();
        },
        clear () {
            this.keyword = '';
            this.$emit( 'clear' );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$searchIcon: $headerHeight - $headerMargin * 2;

.search {
    @include flexColumn;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 12vw;
    background-color: $c-pageBg;
    box-sizing: border-box;
    font-size: 4vw;
    font-weight: bold;
    box-shadow: inset 0 0 0 0.5px $c-border;
    border-radius: $radius_xs;

    &.full {
        padding: 0;
    }
    .view {
        &--r {
            overflow: hidden;
            @include flexColumn;
            position: absolute;
            width: $headerHeight;
            height: $headerHeight;
            top: 0;
            right: 0;
        }
    }

    #{&}--form {
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: $margin;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.icon {
            padding-left: $padding * 1.4;
            background: url(/static/icon/search@64_gray.png) no-repeat 1.6vw center;
            background-size: 6vw;
        }
    }
    #{&}--input {
        background-color: transparent;
        border: none;
        width: 90%;
        height: 100%;
        padding: 0;
        font-weight: normal;
    }
    #{&}--clear {
        position: absolute;
        top: 0;
        right: 0;
        width: $searchIcon;
        height: 100%;
        background: url(/static/icon/close.png) no-repeat center center;
        background-size: 4.27vw;
    }
}
</style>
