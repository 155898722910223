<template>
  <div class="community-card" @click="lookCommunityReport">
    <div class="community-card-header">
      <div class="community-name">{{ communityObj.communityName || '' }}</div>
      <img v-if="communityObj.collectionFlag" class="store-icon" src="/static/cellScreen/store.png" alt="" @click.stop="cancelCollection" />
      <img v-else class="store-icon" src="/static/cellScreen/un-store.png" alt="" @click.stop="collection" />
    </div>
    <div class="community-card-middle">
      <span>{{ communityObj.regionName }}</span>
      <span v-if="communityObj.year">|</span>
      <span v-if="communityObj.year">{{ communityObj.year || '-' }}年建成</span>
    </div>
    <div class="community-card-bottom">
      <div class="community-card-bottom-item">
        <div class="community-val">
          <span>{{ communityObj.communityNum || '-' }}</span>户
        </div>
        <div class="community-text">户数</div>
      </div>
      <div class="community-card-bottom-item">
        <div class="community-val">
          <span>{{ communityObj.price || '-' }}</span>元/平
        </div>
        <div class="community-text">房价</div>
      </div>
      <div class="community-card-bottom-item">
        <div class="community-val">
          <span>{{ communityObj.buildingAge || '-' }}</span>年
        </div>
        <div class="community-text">楼龄</div>
      </div>
    </div>
    <van-dialog v-model="showDialog" :showConfirmButton="false" get-container="#app">
      <div class="confirm-dialog">
        <div class="confirm-dialog-title">提示</div>
        <div class="confirm-dialog-text">确认要取消收藏？</div>
        <div class="confirm-dialog-btns">
          <div class="cancel-btn" @click="cancel">取消</div>
          <div class="confirm-btn" @click="confirm">确认</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'communityCard',
  props: {
    communityObj: null,
    // 父级页面是不是收藏列表
    formCollectList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    collection() {
      this.HTTP.communityCollect({
        type: 4,
        refId: this.communityObj.communityCode || this.communityObj.refId,
      }).then(res => {
        if (res) {
          this.communityObj.collectionFlag = true
        }
      }).catch(() => {
        this.$toast("网络不稳定，请稍后重试！");
      })
    },
    cancelCollection() {
      if (this.formCollectList) {
        this.showDialog = true
      } else {
        this.confirmCancel()
      }
    },
    confirmCancel() {
      this.HTTP.cancelCollect({
        type: 4,
        refId: this.communityObj.communityCode || this.communityObj.refId,
      }).then(res => {
        if (res) {
          this.communityObj.collectionFlag = false
          if (this.formCollectList) {
            this.showDialog = false
            this.$emit('cancelComplete')
          }
        }
      }).catch(() => {
        this.$toast("网络不稳定，请稍后重试！");
      })
    },
    lookCommunityReport() {
      const code = this.communityObj.communityCode || this.communityObj.refId
      if (!code) return
      this.$router.push(`/cellScreen/report/${code}`)
    },
    cancel() {
      this.showDialog = false
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3040100, 3040101)
    },
    confirm() {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3040100, 3040102)
      this.confirmCancel()
    }
  }
};
</script>
<style lang="scss">
.confirm-dialog {
  text-align: center;
  padding: 6.67vw 5.333vw 5.333vw 5.333vw;
  .confirm-dialog-title {
    font-weight: bold;
    color: #1D2233;
    font-size: 4.533vw;
    line-height: 6.4vw;
  }
  .confirm-dialog-text {
    font-weight: 400;
    color: #8E9099;
    font-size: 3.7333vw;
    line-height: 5.867vw;
    margin-top: 7.47vw;
  }
  .confirm-dialog-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10.133vw;
    > div {
      width: 48%;
      line-height: 12vw;
      border-radius: 2.667vw;
      font-size: 4vw;
      font-weight: bold;
      &.cancel-btn {
        background: #F7F6F9;
        color: #8E9099;
      }
      &.confirm-btn {
        background: #347FFF;
        color: #fff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
  .community-card {
    background: #FFFFFF;
    box-shadow: 0 0 2.27vw 0 rgba(0, 0, 0, 0.09);
    border-radius: 1.87vw;
    padding: 4vw;
    margin-bottom: 3.2vw;
    .community-card-bottom {
      background: #F3F8FF;
      border-radius: 1.333vw;
      display: flex;
      margin-top: 3.07vw;
      padding: 4vw 0 3vw 0;
      .community-card-bottom-item {
        flex: 1;
        font-weight: 400;
        color: #676C84;
        font-size: 2.933vw;
        text-align: center;
        border-right: .1333vw solid #DADFE9;
        &:last-child {
          border-right: 0;
        }
        .community-val {
          color: #333333;
          margin-bottom: 1.333vw;
          //line-height: 5.6vw;
          span {
            color: #FF5900;
            font-size: 4vw;
            font-weight: bold;
          }
        }
        .community-text {
          line-height: 4vw;
        }
      }
    }
    .community-card-middle {
      font-weight: 400;
      color: #666666;
      font-size: 3.47vw;
      margin-top: 1.333vw;
      line-height: 4.933vw;
    }
    .community-card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .community-name {
        flex: 1;
        font-weight: bold;
        color: #333333;
        font-size: 4vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 5.6vw;
      }
      .store-icon {
        width: 4.27vw;
        height: 3.733vw;
        margin-left: 1.333vw;
      }
    }
  }
</style>
