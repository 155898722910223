<template>
    <div class="vipShop" ref="page">
        <div class="vipShop--header" ref="header" v-show="!loading">
            <StateBar :bgcolor="bgColor" />
            <TitleHeader title="会员中心" :color="titleColor" :bgcolor="bgColor" :back="showBack"></TitleHeader>
        </div>
        <!-- 骨架屏占位图 -->
        <img v-if="loading" src="/static/skt/newVip.png" width="100%" />
        <AutoView
          v-if="!loading"
          id="auto-view"
          ref="view"
          :header="false"
          @scroll.native="pageScrollHandle"
          :footer="footer"
          :noIPXBar="!footer"
          class="vipShop--view"
        >
            <div ref="top">
                <div class="top">
                    <div class="info">
                        <div class="left">
                            <img class="user_logo" :src="info.avatar ? info.avatar : '/static/icon/default.png'" alt="" />
                        </div>
                        <div class="right">
                            <h3>
                                {{ this.info.nickname }}
                                <img class="vip_icon" v-if="info.member.vipImage" :src="info.member.vipImage" alt="">
                            </h3>
                            <p @click="showDetail">{{ isVip ? `${info.member.endTime}` : "当前未开通数据调研VIP会员" }}<img v-if="isVip" src="/static/vip/detail-arrow-icon.png" alt=""></p>
                        </div>
                    </div>
                </div>
                <div class="tab" :style="actived">
                    <div class="tab-item" v-for="(tab, index) in tabList" :key="index" @click="toggleTab(index + 1)">
                        <img v-show="active == index + 1" class="tab-icon" :src="tab.active" alt="">
                        <img v-show="active != index + 1" class="tab-icon" :src="tab.icon" alt="">
                        <div v-show="active == index + 1" :style="`color: ${tab.color}; font-weight: bold;`">
                            {{tab.name}}
                            <!--  <img class="enterprise-tab-icon" src="/static/vip/enterprise/enterprise-tab-icon.png" alt="">-->
                        </div>
                        <div v-show="active != index + 1">{{tab.name}}</div>
                    </div>
                </div>
                <template v-if="active != '3'">
                    <div class="vipShop--content">
                    <!-- 会员套餐 -->
                        <template v-if="active == '1'">
                          <div class="list">
                            <!-- clearfix -->
                            <div class="vip_box">
                              <div
                                class="one"
                                v-for="(val, index) in list"
                                :key="index"
                                :class="info.select.id == val.id ? 'activeIndex' : ''"
                                :style="info.select.id == val.id ? activeStyle : ''"
                                @click="vipTab(val)"
                              >
                                <div class="discounts" v-if="val.tag">
                                  <span>{{ val.tag }}</span>
                                </div>
                                <h4>{{ val.name }}</h4>
                                <p :class="vipBoxP">
                                  <sub>￥</sub><span>{{ val.showPrice }}</span>
                                </p>
                                <div class="day" :class="vipBoxDay">{{ val.avgDayPrice }}</div>
                              </div>
                            </div>
                          </div>
                          <div class="part-bg">
                            <img src="/static/vip/college/img1.png" alt="" />
                          </div>
                        </template>

                        <!--svip-->
                        <Svip  v-if="active == '2'" :prices="list" :checkId="info.select && info.select.id" @check="vipTab"/>
                        <!--iOS-->
                        <template v-if="isIOS">
                            <template v-if="isSubscription">
                                <div class="agreement" @click="selectClick">
                                    <img src="static/vip/enterprise/checked.png" v-if="selected">
                                    <img src="static/vip/enterprise/uncheck.png" v-else>
                                    <span>购买前请仔细阅读<b @click.stop="agreementClick('subscription')">《连续订阅服务协议》</b>、<b @click.stop="agreementClick('memberservice')">《上上参谋会员服务协议》</b>及<b @click.stop="agreementClick('paymentagreement')">《支付协议》</b></span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="agreement" @click="selectClick">
                                    <img src="static/vip/enterprise/checked.png" v-if="selected">
                                    <img src="static/vip/enterprise/uncheck.png" v-else>
                                    <span>购买前请仔细阅读<b @click.stop="agreementClick('memberservice')">《上上参谋会员服务协议》</b>及<b @click.stop="agreementClick('paymentagreement')">《支付协议》</b></span>
                                </div>
                            </template>

                        </template>
                        <!--android-->
                        <template v-else>
                            <div class="agreement" v-if="isSubscription" @click="selectClick">
                                <img src="static/vip/enterprise/checked.png" v-if="selected">
                                <img src="static/vip/enterprise/uncheck.png" v-else>
                                每月自动扣费，可随时取消；购买前请仔细阅读
                                <span>购买前请仔细阅读<b @click.stop="agreementClick('subscription')">《连续订阅服务协议》</b>、<b @click.stop="agreementClick('memberservice')">《上上参谋会员服务协议》</b>及<b @click.stop="agreementClick('paymentagreement')">《支付协议》</b></span>
                            </div>
                            <div class="agreement" v-else  @click="selectClick">
                                <img src="static/vip/enterprise/checked.png" v-if="selected">
                                <img src="static/vip/enterprise/uncheck.png" v-else>
                                <span>购买前请仔细阅读<b @click.stop="agreementClick('memberservice')">《上上参谋会员服务协议》</b>及<b @click.stop="agreementClick('paymentagreement')">《支付协议》</b></span>
                            </div>
                        </template>
                        <div class="vipShop--footer" v-if="vipType === 'MAIN_TAB_VIP' && !loading">
                            <div class="footer c-footer">
                                <van-button type="info" block class="s-radius__s btn1" :class="footBtn" @click="onTapPay">
                                    支付￥{{info.select && getPriceNum(info.select.price)}} {{isVip ? '续费' : '开通'}}{{footBtnText}}
                                </van-button>
                            </div>
                        </div>
                    </div>
<!--                    <van-image class="de-adver" src="/static/vip/de.png" @click="toDouble11"></van-image>-->
                    <template v-if="active == '1'">
                        <div class="part-img">
                            <van-image src="/static/vip/college/img2.png"></van-image>
                        </div>
                        <div class="part-img">
                            <van-image src="/static/vip/college/img3.png"></van-image>
                        </div>
                        <div class="detail" v-if="isIOS && isSubscription">
                            <van-image src="/static/vip/vip/subscription.png"></van-image>
                        </div>
                      <div class="part-img" v-if="isAndroid && isSubscription">
                        <van-image src="/static/vip/vip/subscription-android.png"></van-image>
                      </div>
                        <div class="detail">
                            <van-image src="/static/vip/vip-brand.png"></van-image>
                        </div>
                    </template>
                    <template v-if="active == '2'">
                        <div class="detail">
                            <van-image v-if="info.select.id == '10093' || info.select.id == '10109'" src="/static/vip/svip/qy-10.png"></van-image>
                            <van-image v-if="info.select.id == '10095' || info.select.id == '10110'" src="/static/vip/svip/qy-20.png"></van-image>
                            <van-image v-if="info.select.id == '10096' || info.select.id == '10111'" src="/static/vip/svip/qy-40.png"></van-image>
                            <van-image v-if="info.select.id == '10094' || info.select.id == '10112'" src="/static/vip/svip/qy-80.png"></van-image>
                            <div class="check-detail" @click="checkSVip">
                                查看权益对比
                                <img src="/static/vip/arrow-icon.png" alt="">
                            </div>
                        </div>
                        <div class="detail more">
                            <van-image src="/static/vip/svip-more.png"></van-image>
                          <div class="privilege-wrap">
                                <Privilege v-for="(item, index) in privileges" :data='item' :key="index"/>
                            </div>
                            <!-- 滚动占位  -->
                            <div class="scroll" ref="scroll"></div>
                        </div>
                        <div class="detail" ref="sVipList">
                            <!-- <div class="check-detail">
                                查看权益详情
                                <img src="/static/vip/arrow-icon.png" alt="">
                            </div> -->
                            <van-image v-for="item in sVipDetailList" :key="item" :src="item"></van-image>
                        </div>
                        <div class="detail">
                            <van-image src="/static/vip/vip-brand.png"></van-image>
                        </div>
                    </template>
                    <div class="msg">
                        您购买的是虚拟内容产品，线下数据需要耗费大量数据资源计算，确认支付后不支持退款，请仔细确认，以免造成困扰。
                    </div>
                </template>
              <template v-if="active == '3'">
                <div class="enterprise-wrap">
                  <enterprise-vip
                    ref="enterprise"
                    :saas-info="saasInfo"
                    :memberEnterpriseList = "memberEnterpriseList"
                    :showPayBtn="vipType"
                    :agreement.sync="selected"
                    :vipLevel="info.member ? info.member.vipLevel : 0"
                    @onTapPay="onTapPay"
                    @agreementClick="agreementClick"
                    @packageChange="enterprisePackageChange"
                    :isVip="isVip"
                  />
                </div>
              </template>
            </div>
        </AutoView>

        <!-- 底栏提交按钮 v-if="entity"-->
        <SoltFooter v-if="vipType !== 'MAIN_TAB_VIP' && !loading" class="vipShop--footer">
<!--          <Timer818 class="footer-818" v-show="active == 2" />-->
            <div class="footer">
              <template v-if="active != '3'">
                <van-button type="info" block class="s-radius__s btn1" :class="footBtn" @click="onTapPay">
                  支付￥{{info.select && getPriceNum(info.select.price)}} {{isVip ? '续费' : '开通'}}{{footBtnText}}
                </van-button>
              </template>
              <template v-else>
<!--                <pay-button-group @onTapPay="onTapPay" :btn-text="`${info.select && getPriceNum(info.select.price)} ${isVip ? '续费' : '开通'} ${footBtnText}${enterpriseCurPackageName}`"/>-->
                <pay-button-group @onTapPay="onTapPay" :btn-text="`${info.select && getPriceNum(info.select.price)} ${isVip ? '续费' : '开通'} ${footBtnText}`"/>
              </template>
                <!--<XsthButton />-->
            </div>
        </SoltFooter>

        <!-- 会员详情 -->
        <van-popup
            v-model="isShowDetail"
            round
            closeable
        >
            <div class="l-panel vipShop--detail">
                <img class="bg" src="/static/vip/vip-detail-bg.png" alt="">
                <div class="item" v-for="item in vipDetail" :key="item.icon">
                    <img :src="item.icon" alt="">
                    <div class="validity">{{item.name}}</div>
                </div>
                <div class="desc">
                    <p>有效期计算方式：</p>
                    <p>会员二次开通会优先使用等级高的会员权益，等级低的会员权益暂停，有效期顺延。</p>
                </div>
            </div>
        </van-popup>

        <!-- 支付选择界面 -->
        <van-popup
            v-model="showPayment"
            round
            position="bottom"
            :safe-area-inset-bottom="false"
            :close-on-click-overlay="false"
            @click-overlay="onTapClose"
        >
            <div class="l-panel f-vp vipShop--subview">
                <h3>开通会员<i class="icon_vip" /></h3>
                <div class="view">
                    <!-- 支付方式选择 -->
                    <PayTypes :payList="payList" @setPayType="setPayType" />
                </div>
                <van-button type="info" block :loading="waiting" class="s-radius__s" @click="onTap">确认支付</van-button>
            </div>
        </van-popup>

        <!-- 购买结果提醒 -->
        <van-overlay :show="showPayTips">
            <div class="l-wrapper">
                <div class="vipShop--tips l-panel s-radius__s">
                    <van-image class="icon" fit="cover" :src="payTips.icon" />
                    <h3>{{ payTips.title }}</h3>
                    <p>{{ payTips.text }}</p>
                    <van-button type="info" class="s-radius__s" @click="onConfirm">确定</van-button>
                </div>
            </div>
        </van-overlay>

        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>
      <template>
        <!--tab图片预加载 勿删除-->
        <img v-show="false" src="/static/vip/tab-bg1.png">
        <img v-show="false" src="/static/vip/tab-bg2.png">
        <img v-show="false" src="/static/vip/tab-bg3.png">
        <img v-show="false" src="/static/vip/college/img2.png">
      </template>
      <!--      <Timer818 class="fixed-818" v-show="active == 2 && !footer" />-->

      <!-- 拨打电话 -->
    <van-popup class="phone-popup" v-model:show="showPhonePopup" position="bottom">
        <div class="phone-container">
            <a class="phone" :href="'tel:' + phone" @click="showPhonePopup=false">呼叫 {{ phone }}</a>
            <div class="phone-cancel-btn" @click="showPhonePopup=false">取消</div>
        </div>
    </van-popup>
    </div>
</template>
<script>
import PayTypes from "@/components/payTypes";
import EnterpriseVip from '../enterprise-vip';
import Privilege from '../components/privilege/index.vue';
import XsthButton from "../components/xsth-button";
import PayButtonGroup from "../components/pay-button-group/pay-button-group";
import Svip from "../svip";
import Timer818 from "@/views/me/vip-shop/components/timer-818";

export default {
    name: "vipShop",
    components: {
        PayTypes,
      EnterpriseVip,
      Privilege,
      XsthButton,
      PayButtonGroup,
      Svip,
      Timer818,
    },
    computed: {
        actived() {
            if(this.active == '1') {
                return 'background-image: url("/static/vip/tab-bg1.png");'
            } else if (this.active == '2') {
                return 'background-image: url("/static/vip/tab-bg2.png");'
            } else {
                return 'background-image: url("/static/vip/tab-bg3.png");'
            }
        },
        footBtn() {
            if(this.active == '1') {
                return 'vipBtn'
            } else if (this.active == '2') {
                return 'svipBtn'
            } else {
                return 'saasVipBtn'
            }
        },

        footBtnText() {
            if(this.active == '1') {
                return '数据调研VIP'
            } else if (this.active == '2') {
                return '全站服务SVIP'
            } else {
                return '品牌招商版';
            }
        },
        vipBoxP() {
            if(this.active == '1') {
                return 'vipP'
            } else if (this.active == '2') {
                return 'svipP'
            }
        },
        vipBoxDay() {
            if(this.active == '1') {
                return 'vipDay'
            } else if (this.active == '2') {
                return 'svipDay'
            }
        },
        activeStyle() {
            if(this.active == '1') {
                return `
                    border: 0.4vw solid #8d5c21;
                    background: #ffebd1;`
            } else if (this.active == '2') {
                return `
                    border: 0.4vw solid rgba(254, 79, 66, 0.47);
                    background: #FFD7D7;`
            }
        },
        enterpriseCurPackageName(){
          try {
            if (this.info.select.id == '10097') return 'VIP';
            if (this.info.select.id == '10105') return 'SVIP';
          } catch (e) {
            console.log('未选择企业vip');
          }
          return 'VIP';
        },
    },
    data() {
        return {
            // 连锁品牌VIP -- 打电话
            showPhonePopup: false,
            phone: '17304450809',

            active: null,
            selected: true,
            tabList: [
                { name: '数据调研VIP', icon: '/static/vip/icon-vip.png', active: '/static/vip/icon-vip-active.png', color: "#FF8700" },
                { name: '全站服务SVIP', icon: '/static/vip/icon-svip.png', active: '/static/vip/icon-svip-active.png', color: "#FE392C" },
                { name: '连锁品牌会员', icon: '/static/vip/icon-saasVip.png', active: '/static/vip/icon-saasVip-active.png', color: "#347FFF" },
            ],
            detailList: [
                "/static/vip/vip/detail1.png",
                "/static/vip/vip/detail2.png",
            ],
            sVipDetailList: [
                "/static/vip/svip/svip-detail1.png",
                "/static/vip/svip/svip-detail2.png",
            ],
            privileges: [
                {
                    title: '大数据选址（SVIP独享权益）',
                    head:{
                        title: '开店选址套餐',
                        desc: '立省2380元',
                        bg: '/static/vip/svip/svip-bg.png',
                        titleColor: '#A0232D',
                        descColor: '#F3626D'
                    },
                    icons: [
                        {
                            icon: '/static/vip/svip/module-1-1@2x.png',
                            title: '开店选址评估',
                            desc: 'AI智能数据评估',
                            mark: '/static/vip/svip/marker-1-1@2x.png',
                        },
                        {
                            icon: '/static/vip/svip/module-1-2@2x.png',
                            title: '外卖店选址',
                            desc: '专注3Km外卖选址',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-1-3@2x.png',
                            title: '开店位置推荐',
                            desc: '3个开店新址',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-1-4@2x.png',
                            title: '社区店选址',
                            desc: '搞定家门口好生意',
                            mark: '/static/vip/svip/marker-1-2@2x.png',
                        }
                    ],
                },
                {
                    title: '开店工具（SVIP独享权益）',
                    head:{
                        title: '开店工具套餐',
                        desc: '开店实用工具',
                        bg: '/static/vip/svip/svip-bg.png',
                        titleColor: '#A0232D',
                        descColor: '#F3626D'
                    },
                    icons: [
                        {
                            icon: '/static/vip/svip/module-2-1@2x.png',
                            title: '赚钱选品类',
                            desc: '赚钱好生意',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-2-2@2x.png',
                            title: '竞争分析',
                            desc: '快速抢占客流商机',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-2-3@2x.png',
                            title: '地图标注',
                            desc: '获得万亿流量',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-2-4@2x.png',
                            title: '点位雷达',
                            desc: '批量扫描，精准匹配',
                            mark: null,
                        },
                    ],
                },
                {
                    title: '数据查询',
                    head:{
                        title: '7大数据查询',
                        desc: '多源数据无限查',
                        bg: '/static/vip/svip/svip-bg.png',
                        mark: '/static/vip/svip/mark2.png',
                        titleColor: '#A0232D',
                        descColor: '#F3626D'
                    },
                    icons: [
                        {
                            icon: '/static/vip/svip/module-3-1@2x.png',
                            title: '城市查询',
                            desc: '城市宏观&经济',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-2@2x.png',
                            title: '外卖查询',
                            desc: '外卖经营&分布',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-3@2x.png',
                            title: '品牌查询',
                            desc: '品牌经营&分布',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-4@2x.png',
                            title: '行业热力',
                            desc: '行业哪里最密集',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-5@2x.png',
                            title: '周边查询',
                            desc: '周边商圈&客群',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-6@2x.png',
                            title: '商场查询',
                            desc: '商场业态&配套',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-7@2x.png',
                            title: '人口热力',
                            desc: '人口哪里最密集',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-3-8@2x.png',
                            title: '小区筛选',
                            desc: '筛选开店小区',
                            mark: null,
                        },
                    ],
                },
                {
                    title: '商铺广场（SVIP独享权益）',
                    head:{
                        title: '商铺广场服务',
                        desc: '直接找铺',
                        bg: '/static/vip/svip/svip-bg.png',
                        mark: '/static/vip/svip/mark3.png',
                        titleColor: '#A0232D',
                        descColor: '#F3626D'
                    },
                    icons: [
                        {
                            icon: '/static/vip/svip/module-4-1@2x.png',
                            title: '商铺信息',
                            desc: '十万铺位任你逛',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-4-2@2x.png',
                            title: '选址服务',
                            desc: '选址直接选到铺',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-4-3@2x.png',
                            title: '铺位测评',
                            desc: '给你的铺位打分',
                            mark: null,
                        },
                        {
                            icon: '/static/vip/svip/module-4-4@2x.png',
                            title: '商铺转让',
                            desc: '发布铺位商机',
                            mark: null,
                        },
                    ],
                },
            ],
            list: [],
            vipList: [],
            sVipList: [],
            loading: true,
            appCode: null, // app版本号
            showPayment: false,
            showPayTips: false,
            isIOS: false,
            isAndroid: false,
            vipType: null,
            memberItemList: [],
            vipTypeMap: {
                1: 'MAIN_TAB_VIP'
            },
            showVipDetail: false,
            isSubscription: false, // 是否是连续订阅商品
            vipDetail: [],
            client: 3, // 1iOS 2Android 3WEB
            payList: ["支付宝", "微信"],
            payType: null,
            payMode: null,
            payCall: null,
            payMap: {
                支付宝: {
                    mode: "ALIPAY_MOBILE",
                    call: "aliPay",
                    unit: "￥",
                },
                微信: {
                    mode: "WX_APP",
                    call: "wxPay",
                    unit: "￥",
                },
                微信连续续费: {
                    mode: "WX_CONTRACT",
                    call: "wxPreEntrust",
                    unit: "￥",
                },
                "Apple Pay": {
                    mode: "APPLE_IN",
                    call: "applePay",
                    unit: "￥",
                },
            },
            waiting: false,
            payTips: {
                icon: "",
                title: "",
                text: "",
            },
            payTipsMap: {
                success: {
                    icon: "/static/icon/msg_success_orange.png",
                    title: "操作成功",
                    text: "您已成功完成支付",
                },
                failed: {
                    icon: "/static/icon/more.png",
                    title: "操作失败",
                    text: "未能完成支付操作",
                },
            },
            info: {
                nickname: "",
                member: null,
                select: null,
                avatar: null,
            },
            orderNo: null, // 订单号 下单时生成
            isVip: false, // 是否会员
            isShowDetail: false,
            footer: true,
            bgColor: "transparent",
            titleColor: "white",
            showBack: true,
            idx: 0, // 套餐默认选中tab
            saasInfo: {
              account: null,
              brandName: null,
              companyName: null,
              expireDate: null,
              merchantCode: null,
              status: 1,
              price: '',
              url: null,
            },
            memberEnterprise: {
              avgDayPrice: '',
              id: '',
              iosGoodsCode: '',
              name: '',
              price: '',
              tag: ''
            },
            memberEnterpriseList: null,
            sscmVipTabIndexId: 'vip',
            // 页面app进入来源
            source: '',
            scene: '',
            pageId: '',
            moduleId: '',
            btnId: '',
            refId: '',
            // 是不是ios内购支付失败页
            pageType: ''
        };
    },
    beforeCreate () {
    },
    created () {
    },
  mounted() {
      this.initPage();
    },
  methods: {
        // getEnterpriseCurPackageName(){
        //   if (this.selected.id == '10097') return 'VIP';
        //   if (this.selected.id == '10105') return 'SVIP';
        //   // return this.$refs.enterprise?.getCurPackageName();
        // },
        initPage(){
          this.handleUrl();
          this.pageType = this.$route.query.pageType
          this.vipType = this.vipTypeMap[this.$route.query.type]
          this.footer = !this.vipType
          this.getVipInfo(() => {
            this.getDefaultTabIndex().then(() => {
              this.setDefaultPackage();
            });
          });
          this.JSBridge.registerHandler("onAppResume", (data) => {
            console.log('app 调用 onAppResume 函数成功');
            this.getVipInfo();
          })

          let _this = this
          this.JSBridge.callHandler("setStatebarTheme", "white");
          this.JSBridge.callHandler("getVersionCode", null, function (code) {
            _this.appCode = code;
          });
          // 注册支付回调
          this.JSBridge.registerHandler("paymentResult", (data) => {
            console.log(data, "state")
            // 安卓端需要进行转义
            if (typeof data === "string") {
              data = JSON.parse(data);
            }
            console.log(data, "state")
            if (typeof data === "object") {
              if (!data.id) return;
              this.waiting = false;
              switch (data.state) {
                case 1:
                  // 支付成功
                  this.showPayment = false;
                  this.payTips = this.payTipsMap.success;
                  this.payTips.state = true;
                  this.showPayTips = true;
                  // 通知客户端更新用户信息
                  this.JSBridge.callHandler("noticeUpdate", "userInfo");
                  break;
                case 5: // 兼容微信
                  break;
                case 7: // 超时
                case 8: // 取消
                  this.$toast("已取消支付");
                  break;
                default:
                  // 支付失败
                  this.showPayment = false;
                  this.payTips = this.payTipsMap.failed;
                  this.payTips.state = false;
                  this.showPayTips = true;
                  console.log("支付失败", data.status, this.payTips)
              }
            } else {
              this.$toast("支付时发生错误！");
              this.waiting = false;
            }
          });
          // 设置客户端类型
          if (window.isIOS) {
            this.isIOS  = true;
            this.client = 1;
          } else if (window.isAndroid) {
            this.isAndroid = true
            this.client = 2;
          }

        },
        getDefaultTabIndex(){
          return this.HTTP.getMemberTab(this.source).then((res) => {
            if(this.$route.query.active) {
              this.toggleTab(this.$route.query.active, true);
            } else {
              this.toggleTab(res.data.data, true);
            }
            // 发送埋点
            this.sendPvTrack();
            console.log(res, 'getTabIndex')
          }).catch(e =>{
            this.toggleTab(this.getTabIndex(), true);
            // 发送埋点
            this.sendPvTrack();
          })
        },
        setDefaultPackage(){
          // 如果tab是vip或者svi且idx存在，就默认选中
          if (!(this.$route.query.active == 1 || this.$route.query.active == 2)) return;
          if (!this.idx && this.idx != 0) return ;
          this.info.select = this.list[this.idx];
          console.log(this.info.select, this.list[this.idx], 999)
        },
        /**
         * 处理url参数
         */
        handleUrl(){
              this.source = this.$route.query.source || '';
              // 记录支付页之前的页面信息
              this.pageId = this.$route.query.pageId || '';
              this.moduleId = this.$route.query.moduleId || '';
              this.btnId = this.$route.query.btnId || '';
              this.refId = this.$route.query.refId || '';

              const idx = this.$route.query.idx;
              if (idx != 0 && !idx) {
                this.idx = 0;
              } else {
                this.idx = idx;
              }
              this.scene =  this.$route.query.scene || '';
        },
        sendPvTrack(){
          let data = {
            refId: null,
            pageId: this.$route.meta.id,
            extra : JSON.stringify({
              source: this.source,
              active: this.active,
              goodsId: this.$route.query.goodsId || '',
              type: this.$route.query.type || 0,
            })
          }
          console.log(data,'上报埋点')
          this.JSBridge.callHandler("onEventReport", data);
        },
        scrollEvent(top){
          let offTop = this.getTabIndex() != 3 ? -400 : -683;
          if(top < offTop) {
            this.vipType = '0';
            this.footer = true;
          } else {
            this.vipType = this.vipTypeMap["1"];
            this.footer = false;
          }
        },
        getVipInfo(callback){
          // 查询会员信息
          this.HTTP.getVipInfo()
            .then((res) => {
              console.log("查询会员信息", res);
              this.loading = false;
              this.isVip = res.userDetail ? res.userDetail.member : false;
              this.vipList = res.memberGoodsList;
              this.sVipList = res.memberSvipList
              // this.list = this.vipList
              // this.info.select = this.list && this.list.length ? this.list[this.idx] : null;
              // this.isSubscription = this.info.select.type == 2
              this.info.nickname = res.userDetail ? res.userDetail.username : null;
              this.info.member = res.userDetail ? res.userDetail.memberInfo : null;
              this.info.avatar = res.userDetail ? res.userDetail.avatar : null;
              this.vipDetail = res.memberItemList ? res.memberItemList.reverse() : [];
              this.saasInfo = Object.assign(this.saasInfo, res.saasRes || {});
              this.memberEnterprise =  Object.assign(this.memberEnterprise, res.memberEnterprise || {});
              this.memberEnterpriseList = res.memberEnterpriseList;
              callback && callback();
              // let code = this.getTabIndex();
              // switch(code) {
              //   case 0: // 默认
              //   case 2: // svip
              //     this.toggleTab(2, stopTrack);
              //     break
              //   case 1: // vip
              //     this.toggleTab(1, stopTrack);
              //     break
              //   case 3: // 企业vip
              //     this.toggleTab(3, stopTrack);
              //     break
              // }
            })
            .catch((e) => {
              this.$toast("网络连接不稳定，请稍候重试！");
            });
        },
        toggleTab(index, stopTrack) {
            index = Number(index)
            this.active = index;
          switch (index) {
            case 1:
                this.list = this.vipList;
                !stopTrack && this.JSBridge.BTNCLICK(this.$route.meta.id, "", 163000, 163001);
                break;
            case 2:
                this.list = this.sVipList;
                this.isSubscription = false
                !stopTrack && this.JSBridge.BTNCLICK(this.$route.meta.id, "", 163000, 163002);
                break;
            case 3:
                this.list = [this.getEnterpriseVip()];
                !stopTrack &&this.JSBridge.BTNCLICK(this.$route.meta.id, "", 163000, 163003);
                break;
          }
          this.info.select = this.list[0];
          console.log(this.info.select, 888)
          // 设置详情图片
          if(index === 2) {
            this.vipTab(this.info.select);
          }
          if (this.info.select) {
            this.isSubscription = this.info.select.type == 2
          }
          this.saveTabIndex(index);
          if (!stopTrack) {
            // 发送埋点
            this.sendPvTrack();
          }
        },
        saveTabIndex(index){
          window.sessionStorage.setItem('sscm-vip-tab-index-' + this.sscmVipTabIndexId, index);
        },
        // tab 读取逻辑 ssesion->url->内存->接口
        getTabIndex(){
          let sessionIndex = window.sessionStorage.getItem('sscm-vip-tab-index-' + this.sscmVipTabIndexId);
          if (sessionIndex != '' && sessionIndex != null && sessionIndex != 'undefined') {
            return parseInt(sessionIndex);
          }
          if ( this.$route.query.active != '' && this.$route.query.active != null && this.$route.query.active != 'undefined') {
            return parseInt(this.$route.query.active);
          }
          if (this.info.member.vipLevel != '' && this.info.member.vipLevel != null && this.info.member.vipLevel != 'undefined') {
            return this.info.member.vipLevel;
          }
          return this.active || 2;
        },
        showDetail() {
            if(this.isVip) {
                this.isShowDetail = true
            }
        },
        agreementClick(type) {
            let query = {}
            switch(type) {
                case 'subscription':
                    query.queryPath = '/subscription.html'
                    query.title = '连续订阅服务协议'
                    break
                case 'memberservice':
                    query.queryPath = '/memberservice.html'
                    query.title = '上上参谋会员服务协议'
                    break
                case 'paymentagreement':
                    query.queryPath = '/paymentagreement.html'
                    query.title = '上上参谋支付协议'
                    break
              case 'enterprise':
                query.queryPath = '/enterprise.html'
                query.title = '上上参谋企业版-数位拓店云服务协议'
                break
              case 'enterpriseSvip':
                query.queryPath = '/SSCM_VIP.html'
                query.title = '上上参谋企业版-数位拓店云服务协议'
                break
            }
            this.swRouter({
                path: '/iframeAgreement',
                query,
            })
        },
        pageScrollHandle() {
        this.scrollEvent();
          let tg = this.$refs.standard;
          let sv = 20;
          if (!tg) {
            tg = this.$refs.top;
            sv = -10;
          }
          const stdTop = tg.getBoundingClientRect().top;
          const check = stdTop > sv;
          if (this.isTop !== check) {
            this.isTop = check;
            this.bgColor = check ? "transparent" : "white";
            this.titleColor = check ? "white" : "#1D2233";
            this.JSBridge.callHandler("setStatebarTheme", check ? "white" : "black");
          }

          this.scrollEvent(stdTop);
          if (this.$refs.enterprise) {
            this.$refs.enterprise.scrollEvent(stdTop);
          }
        },
        selectClick() {
            this.selected = !this.selected
        },
        onConfirm() {
          // 支付失败
          if (!this.payTips.state) {
            this.showPayTips = false;
            console.log('支付失败，停留在当前页面')
            return;
          }
          // 连锁品牌svip
          if (this.info.select.id == '10105') {
            this.showPayTips = false;
            // 如果是企业svip就跳转到客服
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              url: "ONLINE_SERVICE",
              params: null,
            });
            return;
          }
            // 企业支付成功页面 优化中
          if (this.active == 3 && this.saasInfo.companyName) {
            this.swRouter({
              path: "/vip/enterprise/pay-successe-complete",
            });
            this.showPayTips = false;
            return;
          }
          // 企业支付成功页面
          if (this.active == 3 && !this.saasInfo.companyName) {
            this.swRouter({
              path: "/vip/enterprise/pay-successe-incomplete",
            });
            this.showPayTips = false;
            return;
          }
          // 如果有goodsId sku 选址页面
          if(this.$route.query.goodsId && this.active == 1) {
            this.showPayTips = false;
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              url: "CHANGE_MAIN_TAB",
              id: "queryData",
              params: null,
              trackData: null,
              isClear: true,
            });
            return;
          }
          // 如果有goodsId sku 选址页面
          if(this.$route.query.goodsId && this.active == 2) {
            this.showPayTips = false;
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              url: "SKU_FILL_INFO_PAGE",
              id: this.$route.query.goodsId,
              params: null,
              isClear: true,
            });
            return;
          }
            if (this.payTips.state) {
                this.showPayTips = false;
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CHANGE_MAIN_TAB",
                    id: "me",
                    params: null,
                    trackData: null,
                    isClear: true,
                });
            } else {
                this.showPayTips = false;
            }
        },
        getPriceNum(num) {
          let numStr = num + '';
          return numStr.replace('￥','').replace('¥','');
        },
        // 立即开通
        onTapPay() {
            if(!this.selected && this.active!=3/* 连锁品牌会员改为拨打电话，不需要勾选协议 */) {
                this.$toast('请先勾选协议')
                return
            }
            // 按钮埋点上报
            switch(this.active) {
                case 1:
                    this.JSBridge.BTNCLICK(this.$route.meta.id, "", 164000, 164001);
                    break;
                case 2:
                    this.JSBridge.BTNCLICK(this.$route.meta.id, "", 164000, 164002);
                    break;
                case 3:
                    // this.JSBridge.BTNCLICK(this.$route.meta.id, "", 164000, 164003); // 取消连锁支付功能
                    this.JSBridge.BTNCLICK(this.$route.meta.id, "", 164000, 164004); // 拨打电话埋点
                    break;
                default:
                    break;
            }

            // 连锁品牌VIP 咨询企业顾问 -- 非支付逻辑
            if (this.active == 3) {
                this.swRouter({
              path: "/h5/#/enterpriseInformation",
            });
                //  修改部分 跳转到留资页面
                // if (this.isIOS) {
                //     if (window._appCode >= 322) {
                //         this.JSBridge.callHandler('makeaCall', this.phone)
                //     } else if (window._appCode >= 175) {
                //         this.JSBridge.callHandler("onNormalizingCall", {
                //         type: 5,
                //         url: "ONLINE_SERVICE",
                //         params: null,
                //         });
                //     } else {
                //         this.JSBridge.callHandler(
                //         "callLiveChat",
                //         {
                //             type: "normal",
                //             data: {},
                //         },
                //         (st) => {
                //             // console.log( st );
                //         }
                //         );
                //     }
                // } else {
                //     this.showPhonePopup = !!this.phone
                // }
                return
            }
            if(this.isAndroid || (this.isIOS && window._appCode && window._appCode < 301)) {
                this.showPayment = true;
                if(this.info.select.type == 2 && window._appCode < 313) {
                    this.payList = ['支付宝']
                    this.setPayType("支付宝")
                } else {
                    this.payList = ['支付宝', '微信']
                }
            } else {
              if (this.info.select.id != '10091') {
                this.showPayment = true;
                this.payList = ['支付宝', '微信']
              } else {
                this.setPayType("Apple Pay")
                this.onTap();
              }

                // if(
                //   ((this.active == 3 || this.active == 2) && window._appCode && window._appCode >= 310)
                //   ||
                //   (this.scene == 'PAY_TIMEOUT' && this.info.select.id != '10091')
                // ) {
                //     this.showPayment = true;
                //     // this.setPayType("支付宝")
                //     this.payList = ['支付宝', '微信']
                // } else {
                //     if (this.pageType === 'applePay') {
                //       this.showPayment = true;
                //       this.payList = ['支付宝', '微信']
                //     } else {
                //       this.setPayType("Apple Pay")
                //       this.onTap();
                //     }
                // }
            }
        },
        onTap() {
            console.log(1);
            //业务流程
            // this.waiting = true;
            console.log("创建订单..." + this.payMode + "====" + this.info.select.id + "=====" + this.client);
            if (this.payMode == "WX_CONTRACT" || this.payMode == "WX_APP") {
              if (this.isSubscription) {
                this.setPayType('微信连续续费');
              } else {
                this.setPayType('微信');
              }
            }
            this.HTTP.createVip(
              this.payMode,
              this.info.select.id,
              this.client,
              this.$route.meta.id,
              JSON.stringify({
                source: this.source,
                pageId: this.pageId,
                moduleId: this.moduleId,
                btnId: this.btnId,
                refId: this.refId
              })
            ).then((res) => {
                    console.log(res, "打印payResult")
                    console.log("创建订单...end");
                    console.log("调起支付...");
                    this.orderNo = res.orderNo;
                    const data = {
                        id: res.orderNo,
                        data: res.payResult,
                        pageId: this.$route.meta.id,

                    };
                    if (window.isClient) {
                        this.JSBridge.callHandler(this.payCall, data, (st) => {
                            if (!st) {
                                this.$toast("未安装客户端，调起支付失败！");
                                setTimeout(() => {
                                    this.waiting = false;
                                }, 1000);
                                console.log(`调起支付...失败`);
                                return;
                            }
                            console.log(`调起支付...end`);
                        });
                    } else {
                        this.$toast("暂不支持网页版支付");
                        setTimeout(() => {
                            this.waiting = false;
                        }, 1000);
                    }
                })
                .catch((e) => {
                    this.$toast("创建订单时发生错误！");
                    setTimeout(() => {
                        this.waiting = false;
                    }, 1000);
                });
        },
        onTapClose() {
            // if (this.payLoading) return;
            this.showPayment = false;
        },
        setPayType(value) {
            if(window._appCode >= 313 && value === '微信' && this.info.select.type == 2) {
                const map = this.payMap["微信连续续费"]
                this.payType = value;
                this.payMode = map.mode;
                this.payCall = map.call;
                return
            }
            const map = this.payMap[value];
            this.payType = value;
            this.payMode = map.mode;
            this.payCall = map.call;
            // this.info.unit = map.unit;
        },
        checkSVip() {
            this.$refs.scroll.scrollIntoView()
        },
        vipTab(item) {
            this.info.select = item;
            this.isSubscription = this.info.select.type == 2 // 连续包月商品type = 2

            switch (item.id) {
              case 10093:
              case 10109:
                this.sVipDetailList[0] = "/static/vip/svip/10/1.png";
                this.sVipDetailList[1] = "/static/vip/svip/10/2.png";
                break;
              case 10095:
              case 10110:
                this.sVipDetailList[0] = "/static/vip/svip/20/1.png";
                this.sVipDetailList[1] = "/static/vip/svip/20/2.png";
                break;
              case 10096:
              case 10111:
                this.sVipDetailList[0] = "/static/vip/svip/40/1.png";
                this.sVipDetailList[1] = "/static/vip/svip/40/2.png";
                break;
              case 10094:
              case 10112:
                this.sVipDetailList[0] = "/static/vip/svip/80/1.png";
                this.sVipDetailList[1] = "/static/vip/svip/80/2.png";
                break;
            }
        },
        enterprisePackageChange(enterprisePackage){
          if (this.active != 3) return;
          this.list = [enterprisePackage];
          this.info.select = this.list[0];
          console.log('企业vip套餐包改变：', enterprisePackage);
        },
        getEnterpriseVip(){
          const packages = this.memberEnterpriseList.filter((item) => {
            return item.id == '10105'; // id是后端给的固定值
          })
          console.log(packages, 444)
          if (packages.length > 0) {
            return packages[0];
          } else {
            return null;
          }
        },
        toDouble11(){
          this.swRouter("/h5/#/adver/double-eleven");
        },
    },
};
</script>
<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import "@/styles/index.scss";

::v-deep .phone-popup {
  padding: 0;
}
.phone-container {
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  text-align: center;
  .phone {
    color: #333333;
    line-height: 60px;
    display: block;
  }
  .phone-cancel-btn {
    line-height: 40px;
    border-top: 8px solid #F7F7F7;
  }
}

.part-bg {
  padding-bottom: vw(15);
  img {
    width: 100%;
  }
}
.part-img {
  margin-top: vw2(10);
}
.vipShop {
    height: 100vh;
    background: #f7f7f7;

    // overflow: hidden;
    #{&}--header {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 116;
    }
    #{&}--view {
        position: relative;
    }
    .top {
        width: 100%;
        height: 46.6vw;
        position: relative;
        padding: 0 4vw;
        box-sizing: border-box;
        background: url("/static/vip/vip-head-bg.png") no-repeat;
        background-size: cover;
        .info {
            position: absolute;
            bottom: 0vw;
            left: 4vw;
            right: 4vw;
            height: 28vw;
            z-index: 2;
            background: transparent;
            padding: 4vw;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .left {
                width: 15.47vw;
                height: 15.47vw;
                margin-right: 3.33vw;
                border-radius: 7.73vw;
                position: relative;
                .user_logo {
                    width: 100%;
                    height: 100%;
                    border-radius: 7.73vw;
                }
            }
            .right {
                flex: 1;
                h3 {
                    font-size: 4.27vw;
                    font-weight: bold;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    line-height: vw(45);
                    .vip_icon {
                        //width: vw(130);
                        height: vw(36);
                        display: inline-block;
                        margin-left: vw(12);
                    }
                }
                p {
                    margin-top: 1.2vw;
                    font-size: 3.47vw;
                    color: #C4A57E;
                    display: flex;
                    align-items: center;
                    line-height: 4.93vw;
                    img {
                        width: vw(10);
                        height: vw(21);
                        display: inline-block;
                        margin-left: vw(10);
                    }
                }
            }
            .detail {
                opacity: 0.69;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 5.33vw;
                border: 0.2667vw solid #1d2233;
                width: 18.267vw;
                height: 5.33vw;
                color: #1d2233;
                font-size: 2.933vw;
                border-radius: 2.933vw;
                margin-top: 7vw;
                img {
                    width: 1.2vw;
                    height: 2.133vw;
                    margin-left: 1vw;
                }
            }
        }
    }
    .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: vw(329);
        height: vw(74);
        background-repeat: no-repeat;
        background-size: cover;
        .tab-item {
            width: 33.3%;
            height: 100%;
            color: #fff;
            font-size: vw(28);
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .tab-icon {
                width: vw(30);
                height: vw(30);
                margin-right: vw(4);
            }
            .enterprise-tab-icon {
                position: absolute;
                width: vw(142);
                height: vw(38);
                left: vw(500);
                top: 0;
                transform: translateY(-50%);
            }
        }
    }
    .privilege-wrap{
      .privilege{
        padding-top: vw(28);
        border-bottom: vw(1) solid #E7E7E7;
        &:last-of-type{
          border-bottom: none;
        }
      }
    }
    .count-bg {
        width: 92vw;
        height: 6.667vw;
        display: block;
    }
    #{&}--content {
        margin-top: vw(53);
        box-sizing: border-box;
        background-color: #fff;
        padding-bottom: vw(20);
        .list {
            // width: 100%;
            height: auto;
            padding: 3.2vw 4vw;
            padding-right: 0;
            .vip_box {
                width: 100%;
                height: auto;
                white-space: nowrap;
                overflow: hidden;
                overflow-x: auto;
                padding-top: 1.5vw;
                .one {
                    width: 22.67vw;
                    height: 28vw;
                    box-sizing: border-box;
                    background: #F2F2F2;
                    border-radius: 1.33vw;
                    border: 0.27vw solid #e7e7e7;
                    margin-right: 2.4vw;
                    display: inline-block;
                    padding: 3.47vw 1.33vw;
                    text-align: center;
                    position: relative;
                    h4 {
                        font-size: vw(32);
                        font-weight: bold;
                        color: #666666;
                        line-height: 6vw;
                    }
                    .vipP {
                        color: #955100;
                    }
                    .svipP {
                        color: #A0232D;
                    }
                    p {
                        margin-top: 1.33vw;
                        font-size: vw(48);
                        font-weight: bold;
                        sub {
                            font-size: vw(28);
                            line-height: normal;
                            vertical-align: bottom;
                            position: relative;
                            top: -1.07vw;
                        }
                        span {
                            line-height: 7.87vw;
                            vertical-align: middle;
                        }
                    }
                    .vipDay {
                        color: #8d5c21;
                    }
                    .svipDay {
                        color: #A0232D;
                    }
                    .day {
                        font-size: 2.93vw;
                        line-height: 4vw;
                        margin-top: 1.87vw;
                    }
                    &:last-child {
                        margin-right: 4vw;
                    }
                }
                .discounts {
                    left: -0.27vw;
                }
                .activeIndex {
                    position: relative;
                    // overflow: hidden;
                    .discounts {
                        left: -0.4vw;
                    }
                }
            }
        }
    }
    .de-adver{
      margin-top: vw(34);
    }
    .detail {
        background-color: #fff;
        padding: vw(33) vw(29);
        margin: vw(30) 0 vw(25);
        position: relative;
        .check-detail {
            position: absolute;
            line-height: vw(35);
            right: vw(29);
            top: vw(33);
            font-size: vw(24);
            color: #347FFF;
            display: flex;
            align-items: center;
            justify-content: start;
            img {
                width: vw(10);
                height: vw(21);
                margin-left: vw(5);
            }
        }
        ::v-deep .van-image {
            display: block;
        }
    }
    .more {
        ::v-deep .van-image {
            width: vw(260);
            height: vw(31);
            display: block;
        }
        .scroll {
            width: 100%;
            height: #{$headerHeight};
            position: absolute;
            left: 0;
            top: vw(1050);
        }
    }
    .discounts {
        position: absolute;
        width: 19.06vw;
        height: 4.8vw;
        line-height: 4.8vw;
        text-align: center;
        background: #ff5900;
        border-radius: 1.86vw 0.266vw 2.66vw 0;
        font-size: 2.66vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        top: -1.77vw;
        span {
            display: inline-block;
            transform: scale(0.9);
        }
    }
    #{&}--footer {
        z-index: 2;
        .footer {
            width: 100%;
            padding: 0 4vw;
            box-sizing: border-box;
            .s-radius__s {
                overflow: inherit !important;
            }
            .btn1 {
                border-radius: 1.33vw;
                box-shadow: 0vw -0.27vw 0.8vw 0vw rgba(34, 34, 34, 0.12);
                border-color: transparent;
                font-size: 4vw;
                font-weight: bold;
                border: none;
            }
            .vipBtn {
                background-image: linear-gradient(to right, #FFCE8F, #FFAC42 );
                color: #333333;
            }
            .svipBtn {
                background-image: linear-gradient(to right, #FD7F80, #FF4D41 );
                color: #fff;
            }
            .saasVipBtn {
                background-image: linear-gradient(to right, #62AFFF, #347FFF );
                color: #fff;
            }
        }
        .c-footer {
            margin-top: vw(25);
        }
    }
    #{&}--detail {
        width: vw(630);
        position: relative;
        background-image: linear-gradient(rgba(246, 174, 90, 0), rgba(255, 177, 86, 0.42));
        .bg {
            position: absolute;
            right: vw(64);
            top: vw(40);
            display: inline-block;
            width: vw(239);
            height: vw(101);
        }
        .item {
            margin-top: vw(30);
            padding: 0 vw(40);
            img {
                //width: vw(159);
                height: vw(44);
                display: inline-block;
                margin-bottom: vw(10);
            }
            .validity {
                font-size: vw(28);
                color: #333;
                line-height: vw(44);
            }
        }
        .desc {
            margin-top: vw(30);
            background-color: #fff;
            color: #999;
            line-height: vw(33);
            padding: vw(24) vw(40);
            font-size: vw(24);
        }
    }
    #{&}--subview {
        h3 {
            text-align: center;
            font-size: $font_400;
            .icon_vip {
                display: inline-block;
                width: 7.86vw;
                height: 4vw;
                background: url(/static/icon/vip_card.png) no-repeat left center;
                background-size: cover;
                vertical-align: bottom;
                margin-left: 1.5vw;
            }
        }
        .view {
            padding: $margin 0;
        }
        ::v-deep .van-button {
            font-size: $font_400;
            line-height: normal;
        }
    }
    #{&}--tips {
        width: 87.2vw;
        padding: $padding;
        background-color: white;
        text-align: center;

        .icon {
            width: $iconSize;
            height: $iconSize;
            margin-top: 4.3vw;
        }
        h3 {
            font-size: $font_373;
            line-height: $font_533;
            padding-top: 7.47vw;
        }
        p {
            font-size: $font_293;
            color: $c-info;
            padding-top: 0.8vw;
        }
        .van-button--info {
            width: 59.2vw;
            margin-top: 10.93vw;
        }
        .link {
            font-size: $font_400;
            margin-top: 4.8vw;
        }
    }
    ::v-deep .van-overlay {
        z-index: 9999;
    }
    .msg {
        color: #8893A7;
        font-size: $font_320;
        line-height: 4.933vw;
        margin: 0 3.2vw 4.8vw 4vw;
    }
    .iosDesc {
        font-size: vw(22);
        margin: 0 3.2vw 0 4vw;
        color: #999999;
        line-height: vw(30);
    }
    .agreement{
        font-weight: 400;
        color: #999999;
        line-height: vw(30);
        height: vw(60);
        padding-top: vw(10);
        margin: 0 3.2vw 0 4vw;
        font-size: 0;
        img{
          position: relative;
            vertical-align: middle;
            width: vw(28);
        }
        span{
            line-height: vw(30);
            vertical-align: middle;
            font-size: vw(22);
            margin-left: vw(8);
            b{
                font-weight: 400;
                color: $c-main;
            }
        }
    }
    ::v-deep .van-popup {
        border-radius: 2.667vw 2.667vw 0 0;
    }
    .enterprise-wrap{
      padding-top: vw(52);
    }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.footer-818{
  position: absolute;
  left: 0px;
  top:  vw(-70);
  width: 100%;
}
.fixed-818{
  position: fixed;
  left: 0px;
  bottom:  vw(0);
  width: 100%;
  z-index: 100;
}
.vipShop--view{
  padding-bottom: vw(70);
}
</style>
