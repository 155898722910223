<template>
  <div>
    <van-popup v-model="showBottom" @click-overlay="closePopup" position="bottom" round>
      <div class="pay-list">
        <div class="top" @click="closePopup"></div>
        <div class="pay-container">
          <template v-if="isNewPayView && vipInfo.items?.length">
            <div class="title p15">
              {{ vipInfo.title }}
              <span class="link-txt" @click="routeTo">
          {{ vipInfo.more.name }}
          <img src="/static/icon/arrow_right_blue.png" />
        </span>
            </div>
            <div class="package-box2">
              <div
                class="package-item"
                :class="{ 'package-active-item': index === actIdx }"
                v-for="(goods, index) in vipInfo.items"
                :key="index"
                @click="selectPackage(index)"
              >
                <div class="package-inner-box"></div>
                <img class="tag-img" :src="goods.tag" v-if="goods.tag" />
                <div class="package-name">{{ goods.name }}</div>
                <div class="package-count"><span class="package-cnt">{{ goods.cnt }}</span>{{ goods.countSuffix }}</div>
                <div class="package-price">
                  ¥<span>{{ goods.price }}</span>
                </div>
              </div>
            </div>
            <div class="vip-intro-box p15" v-show="actIdx2>-1">
              <img class="vip-intro-image" :src="vipInfo.items[actIdx2].firstVipImage" />
              <img class="vip-intro-image" :src="vipInfo.items[actIdx2].secondVipImage" v-show="showVipConfig" />
              <img class="vip-intro-image" v-if="!showVipConfig" src="/static/report/zhankai.png" @click="showVipConfig=!showVipConfig" />
              <img class="vip-intro-image" v-else src="/static/report/shouqi.png" @click="showVipConfig=!showVipConfig" />
            </div>
          </template>
          <div class="p15" v-if="!isNewPayView && vipInfo.items?.length">
            <div class="title">
              {{ vipInfo.title }}
              <span class="link-txt" @click="routeTo">
          {{ vipInfo.more.name }}
          <img src="/static/icon/arrow_right_blue.png" />
        </span>
            </div>
            <div
              class="pay-item"
              :class="{ active: index === actIdx }"
              v-for="(item, index) in vipInfo.items"
              :key="item.goodsId"
              @click="selectPackage(index)"
            >
              <div class="item-title">
                <div>
                  {{ item.pre }}
                  <em> {{ item.cnt }}</em
                  >{{ item.suffix }}
                </div>
                <span>{{ item.pricePre + item.price }}</span>
              </div>
              <div class="desc">{{ item.desc }}</div>
              <div class="detail">
                <span v-for="(txt, idx) in item.labels" :key="idx">{{ txt }}</span>
              </div>
            </div>
          </div>

          <div class="p15" v-if="pkgInfo.items?.length">
            <div class="title mt16">{{ pkgInfo.title }}</div>
            <div
              class="pay-item"
              :class="{ active: -1 === actIdx }"
              v-for="(item, index) in pkgInfo.items"
              :key="item.goodsId"
              @click="selectPackage(-1)"
            >
              <div class="item-title">
                <div>
                  {{ item.pre }}
                  <em> {{ item.cnt }}</em
                  >{{ item.suffix }}
                </div>
                <span>{{ item.pricePre + item.price }}</span>
              </div>
              <div class="desc">{{ item.desc }}</div>
              <div class="detail">
                <span v-for="(txt, idx) in item.labels" :key="idx">{{ txt }}</span>
              </div>
            </div>
          </div>
          <div class="pay-agreement p15" @click="selected=!selected">
            <img class="agree-icon" src="/static/vip/enterprise/checked.png" v-if="selected">
            <img class="agree-icon" src="/static/vip/enterprise/uncheck.png" v-else>
            <span>购买前请仔细阅读：<b @click.stop="agreementClick(vipAgreement.link)">{{ vipAgreement.name }}</b>|<b @click.stop="agreementClick(payAgreement.link)">{{ payAgreement.name }}</b></span>
          </div>
        </div>
        <div class="pay-other">
          <div class="pay-styles">
            <span>{{ payObj.title }}</span>
            <div class="pay-style" v-for="(item,index) in payObj.payList" :class="{'pay-active-style': payStyleIndex==index}" :key="index" @click="selectPayStyle(index)">
              <img class="pay-icon" :src="item.icon" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="pay-btn">
          <div class="count">
            <p class="total-price">{{ currentPkg.pricePre + currentPkg.price }}</p>
            <p class="pkg-info">共<span class="pkg-cnt">{{ currentPkg.cnt }}</span>次，<span class="pkg-price">{{ currentPkg.pricePre }}{{ actIdx > -1 ? currentPkg.avgPrice : currentPkg.price }}</span>/次</p>
          </div>
          <div class="btn" @click="payNow">确认支付</div>
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="showDialog" :showConfirmButton="false">
      <div class="confirm-wrap">
        <h4 class="h4">确认开通</h4>
        <p class="t-color">
          我已阅读并同意 《会员协议》，《支付协议》
        </p>
        <div class="btn-wrap">
          <span class="cancel" @click="showDialog = false">取消</span>
          <span class="info" @click="confirmPay">确认开通</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { pgk, placeMember, settlementV5 } from '@/request/order';


export default {
  name: 'report-pay-popup',
  props: {
    goodsId: {
      type: Number,
      required: true
    },
    // 创建订单需要的额外参数
    // extParams: {
    //   default: () => {}
    // },
    linkParams: {
      default: () => {}
    }
  },
  data() {
    return {
      showBottom: false,
      actIdx: 0,
      actIdx2: 0,
      payParams: {
        pageId: '',
        stdQuestionId: '',
      },
      vipInfo: {},
      pkgInfo: {},
      payObj: {},
      selected: false,
      vipAgreement: {},
      payAgreement: {},
      payStyleIndex: 0,
      showDialog: false,
      // 支付方式类型
      payMode: null,
      // 支付调用桥接名
      payCall: "wxPay",
      payMap: {
        1: {
          mode: "ALIPAY_MOBILE",
          call: "aliPay"
        },
        2: {
          mode: "WX_APP",
          call: "wxPay"
        }
      },
      hasPrePay: false,
      reportInstanceId: null,
      orderNo: null,
      showVipConfig: false,
      isNewPayView: false
    }
  },
  computed: {
    currentPkg() {
      let obj = {
        pricePre: 0,
        price: 0
      }
      if (this.actIdx===-1 && this.pkgInfo.items?.length) {
        obj = this.pkgInfo.items[0]
      } else if (this.actIdx>=0 && this.vipInfo.items?.length){
        obj = this.vipInfo.items[this.actIdx]
      }
      return obj;
    }
  },
  mounted () {
    this.registerPayCallback()
  },
  methods: {
    selectPackage(index) {
      this.actIdx = index
      if (index>-1) {
        this.actIdx2 = index
      }
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 6130200, 6130201)
    },
    closePopup() {
      this.showBottom = false
    },
    payNow() {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 6130200, 6130203)
      if (!this.selected) {
        this.showDialog =  true
        return
      }
      this.createOrder()
    },
    showPopup() {
      if (this.pkgInfo.items?.length || this.vipInfo.items?.length) {
        this.actIdx = 0
        this.payStyleIndex = 0
        this.hasPrePay = false
        this.showBottom = true

        console.log(this.actIdx)
        return
      }
      settlementV5({ goodsId: this.goodsId, ...this.linkParams }).then(res => {
        console.log('res',res)
        this.vipInfo = res?.vipInfo || {}
        this.pkgInfo = res?.pkgInfo || {}
        this.payObj = res?.payObj || {}
        this.payAgreement = res?.payAgreement || {}
        this.vipAgreement = res?.vipAgreement || {}
        this.reportInstanceId = res?.reportInstanceId || null
        this.isNewPayView = res?.isNewPayView
        this.showBottom = true
      })
    },
    routeTo() {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 6130200, 6130202)
      this.JSBridge.callHandler("onNormalizingCall", this.vipInfo.more?.link);
    },
    agreementClick(link) {
      this.JSBridge.callHandler("onNormalizingCall", link);
    },
    selectPayStyle(index) {
      this.payStyleIndex = index
    },
    confirmPay() {
      this.selected = true
      this.showDialog =  false
      this.createOrder()
    },
    createOrder() {
      if (this.hasPrePay) return;
      const map = this.payMap[this.payObj.payList[this.payStyleIndex].type]
      this.payMode = map.mode
      this.payCall = map.call
      const payParams = {
        // app或h5
        payMode: window.isClient ? 2 : 1,
        // 支付方式
        payTypeChannel: this.payMode,
        // 支付系统
        sourceType: window.isAndroid ? 2 : window.isIOS ? 1 : 0,
        stdQuestionId: undefined,
        memberGoodsId: undefined,
        pageId: this.$route.meta?.id || '',
        ext: JSON.stringify({
          source: this.actIdx === -1 ? `h5_once_dialog_skuV2_${this.pkgInfo.items[0].goodsId}` : `h5_vip_dialog_skuV2_${this.vipInfo.items[this.actIdx].goodsId}`
        })
      }
      let p = undefined
      if (this.actIdx === -1) {
        payParams.stdQuestionId = this.pkgInfo.items[0].goodsId
        payParams.reportInstanceId = this.reportInstanceId
        p = pgk
      } else {
        payParams.memberGoodsId = this.vipInfo.items[this.actIdx].goodsId
        p = placeMember
      }
      console.log('支付参数',payParams)
      this.hasPrePay = true;
      p(payParams).then(res => {
        console.log(res)
        if (res && res.orderNo) {
          const data = {
            id: res.orderNo,
            data: res.payResult,
            pageId: this.$route.meta?.id || ''
          };
          this.orderNo = res.orderNo
          if (window.isClient) {
            this.hasPrePay = false;
            this.JSBridge.callHandler(this.payCall, data, st => {
              if (!st) {
                this.$toast("未安装客户端，调起支付失败！");
                console.log(`调起支付...失败`);
                this.complete();
                return;
              }
              console.log(`调起支付...end`);
              this.complete();
            });
          } else {
            this.$toast("暂不支持网页版支付");
            setTimeout(() => {
              this.complete();
              this.hasPrePay = false;
            }, 1000);
          }
        } else {
          this.complete();
          this.hasPrePay = false;
        }
      }).catch(() => {
        this.complete();
        this.hasPrePay = false;
      })
    },
    complete() {
      this.showBottom = false
    },
    registerPayCallback() {
      this.JSBridge.registerHandler("paymentResult", data => {
        console.log("支付结果回调", data);
        this.hasPrePay = false;
        // 安卓端需要进行转义
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        if (typeof data === "object") {
          switch (data.state) {
            case 1: // 支付成功
              this.$toast("支付成功");
              this.complete();
              this.$emit("success", this.actIdx==-1? this.pkgInfo.items[0].goodsId : '', this.orderNo);
              // 通知客户端更新用户信息
              this.JSBridge.callHandler("noticeUpdate", "userInfo");
              break;
            case 5: // 兼容微信
              break;
            case 7: // 超时
            case 8: // 取消
              this.$toast("已取消支付");
              this.$emit("cancel");
              break;
            default:
              // 支付失败
              this.$emit("payError");
          }
        } else {
          this.$toast("支付时发生错误！");
          this.$emit("error");
        }
        this.complete();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.p15 {
  padding: 0 vw2(15);
}
.confirm-wrap {
  padding: 4.8vw;
  text-align: center;
  .vip-icon {
    width: 37.6vw;
    height: 4vw;
    margin-top: 2.667vw;
  }
  .wrap-img {
    width: 21.33vw;
    height: 21.33vw;
    padding: 4vw 0;
  }
  .h4 {
    font-size: 4.53vw;
    color: #1d2233;
    font-weight: bold;
    margin-bottom: 5.87vw;
  }
  .t-color {
    font-size: 4vw;
    color: #8e9099;
    line-height: 25px;
  }
  .btn-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    margin-top: 20px;
    .cancel {
      display: inline-block;
      width: 34.13vw;
      height: 10.67vw;
      line-height: 10.67vw;
      font-size: 4vw;
      color: #8e9099;
      background: #f7f6f9;
      border-radius: 1.6vw;
      text-align: center;
    }
    .info {
      display: inline-block;
      width: 34.13vw;
      height: 10.67vw;
      line-height: 10.67vw;
      font-size: 4vw;
      color: #fff;
      background: #347fff;
      border-radius: 1.6vw;
      text-align: center;
      &.info2 {
        width: 100%;
      }
    }
  }
}
@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pay-agreement {
  font-weight: 400;
  color: #999999;
  font-size: vw2(11);
  display: flex;
  align-items: center;
  margin: vw2(25) 0 vw2(20);
  .agree-icon {
    width: vw2(15);
    height: vw2(15);
    margin-right: vw2(4);
  }
  b {
    color: #347FFF;
    margin: 0 vw2(4);
    font-weight: 400;
  }
}
.pay-list {
  .pay-other {
    background: #F7F8FB;
    padding: vw2(10) vw2(15);

    .pay-styles {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: #121622;
      font-size: vw2(13);
      .pay-style {
        flex: 1;
        height: vw2(40);
        background: #fff;
        border-radius: 20px;
        border: 1px solid #E4E7ED;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        position: relative;
        &:nth-child(2) {
          margin-left: vw2(14);
        }
        &:nth-child(3) {
          margin-left: vw2(10);
        }
        &.pay-active-style {
          border: 1px solid #347FFF;
          &::after {
            position: absolute;
            bottom: -1px;
            right: -1px;
            content: '';
            width: vw2(25);
            height: vw2(17);
            background: url("/static/icon/pay-selected.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .pay-icon {
          width: vw2(20);
          height: auto;
          margin-right: vw2(7);
        }
      }
    }
  }

  .pay-container {
    max-height: 65vh;
    overflow: auto;
  }
  .top {
    position: sticky;
    left: vw2(-15);
    z-index: 2;
    top: 0;
    width: 100%;
    height: vw2(20);
    background: #fff url('/static/icon/bottom-arrow.png') center vw2(8) / vw2(17) vw2(8) no-repeat;
  }
  .title {
    @include flex-between;
    height: vw2(21);
    font-size: vw2(15);
    font-weight: bold;
    color: #121622;
    .link-txt {
      font-size: vw2(12);
      font-weight: 400;
      color: #347fff;
      line-height: vw2(17);
      img {
        width: vw2(12);
        vertical-align: text-top;
      }
    }
  }
  .vip-intro-box {
    margin-top: vw2(10);
    .vip-intro-image {
      width: 100%;
      display: block;
    }
  }
  .package-box2 {
    overflow-x: scroll;
    white-space: nowrap;
    padding: vw2(13) 0 0 0;
    background: #ffffff;
    border-radius: vw2(10) vw2(10) 0 0;
    margin: 0 0 0 vw2(15);
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .package-item {
    width: vw2(105);
    height: vw2(114);
    background: #f7f8fb;
    border-radius: vw2(10);
    display: inline-block;
    margin-right: vw2(8);
    padding: vw2(15) vw2(2) vw2(10) vw2(2);
    text-align: center;
    box-sizing: border-box;
    position: relative;
    .tag-img {
      position: absolute;
      width: vw2(55);
      height: vw2(16);
      top: vw2(-8);
      left: 0;
      display: block;
    }
    &:last-child {
      margin-right: vw2(15);
    }
    &.package-active-item {
      background: #ffffff linear-gradient(360deg, rgba(255, 160, 42, 0) 0%, rgba(255, 160, 42, 0.25) 100%);
      .package-price {
        color: #ff504e;
      }
      .special-discount-icon {
        left: vw2(-3);
      }
      .package-inner-box {
        border: vw2(3) solid #ffa83b;
      }
    }
    .package-inner-box {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: vw2(10);
      border: 1px solid #e4e7ed;
      top: 0;
      left: 0;
      box-sizing: border-box;
    }
    .special-discount-icon {
      width: vw2(46);
      height: vw2(15);
      position: absolute;
      top: vw2(-7);
      left: -1px;
    }
    .package-count {
      height: vw2(20);
      line-height: vw2(20);
      background: linear-gradient( 90deg, #FFC67D 0%, #FFD195 100%);
      border-radius: vw2(10);
      border: 1px solid #FFC67D;
      font-weight: bold;
      font-size: vw2(9);
      margin-top: vw2(5);
      padding: 0 vw2(10);
      display: inline-block;
      color: #844812;
      .package-cnt {
        font-size: vw2(14);
        margin-right: vw2(2);
      }
    }
    .package-name {
      font-weight: bold;
      color: #1d2233;
      font-size: vw2(16);
      line-height: vw2(23);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
    }
    .package-price {
      font-weight: bolder;
      color: #955100;
      line-height: vw2(30);
      font-size: vw2(14);
      margin-top: vw2(10);
      span {
        font-size: vw2(22);
      }
    }
  }

  .pay-item {
    width: 100%;
    background: #f7f8fb;
    border-radius: vw2(5);
    border: 1px solid #e7e7e7;
    margin: vw2(10) 0;
    padding: vw2(10) vw2(12);
    color: #666666;
    font-size: vw2(12);
    box-sizing: border-box;
    .item-title {
      @include flex-between;
      height: vw2(20);
      font-size: vw2(14);
      font-weight: bold;
      color: #121622;
      span {
        font-size: vw2(18);
        font-weight: bold;
        color: #ff5900;
        &::first-letter {
          font-size: vw2(14);
        }
      }
      em {
        color: #ff5900;
        font-style: normal;
      }
    }
    .desc {
      margin: vw2(4) 0;
    }

    .detail {
      display: flex;
      span {
        font-size: vw2(10);
        color: #999999;
        line-height: vw2(14);
        padding: vw2(2) vw2(6);
        margin-right: vw2(5);
        background: #ebebeb;
        border-radius: vw2(2);
        line-height: vw2(14);
      }
    }
    &.active {
      background: #fff2e1;
      border: 1px solid #ffac45;
      .detail {
        span {
          color: #ff5900;
          background: #ffdfc7;
        }
      }
    }
  }
  .pay-btn {
    width: 100%;
    background: #ffffff;
    //box-shadow: 0px -1px vw2(3) 0px rgba(34, 34, 34, 0.12);
    padding: vw2(12) vw2(15);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .count {
      padding-top: vw2(2);
      font-size: vw2(12);
      color: #8e9099;
      .pkg-info {
        font-size: 11px;
        color: #8E9099;
        margin-top: 2px;
        .pkg-cnt {
          color: #676C84;
          font-weight: bold;
        }
        .pkg-price {
          color: #B15A24;
          font-weight: bold;
        }
      }
      .total-price {
        color: #ff5900;
        font-size: vw2(18);
        font-weight: bold;
        margin-top: vw2(6);
      }
    }
    .btn {
      width: vw2(157);
      height: vw2(40);
      line-height: vw2(40);
      font-weight: bold;
      font-size: vw2(15);
      color: #fff;
      background: linear-gradient( to left, #FF5900 0%, #FF8000 100%);
      border-radius: vw2(5);
      text-align: center;
    }
  }
  .mt16 {
    margin-top: vw2(16);
  }
}
</style>
