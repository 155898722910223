/**
 * 品牌加盟接口
 */
import { Axios } from './http'

const commonRequest = (config) => {
  return new Promise((resolve, reject) => {
    Axios.request(config).then(res => {
      resolve(res);
    }).catch(e => {
      reject(e);
    });
  })
}

const brandAllianceHttp = {}

// 品牌主页
brandAllianceHttp.joinBrandHome = () => {
  return commonRequest( { url: '/v3/joinBrand/home', method: 'get' } )
}
// 品牌列表/搜索
brandAllianceHttp.joinBrandList = (data) => {
  return commonRequest( { url: '/v3/joinBrand/list', method: 'post', data } )
}
// 热搜（大家都在搜）
brandAllianceHttp.joinBrandHotSearch = (data) => {
  return commonRequest( { url: '/v3/joinBrand/hotSearch', method: 'post', data } )
}
// 品牌榜单
brandAllianceHttp.joinBrandRank = (data) => {
  return commonRequest( { url: '/v3/joinBrand/rank', method: 'post', data } )
}
// 申请入驻
brandAllianceHttp.joinBrandApplyJoin = (data) => {
  return commonRequest( { url: '/v3/joinBrand/applyJoin', method: 'post', data } )
}

// 发送验证码
brandAllianceHttp.sendLoginSmsV2 = (data) => {
  return commonRequest( { url: '/v1/user/sendLoginSmsV2', method: 'post', data } )
}

// 热门品牌（分页）
brandAllianceHttp.getHomeHotBrandList = (data) => {
  return commonRequest( { url: '/v3/joinBrand/getHomeHotBrandList', method: 'post', data } )
}

// 品牌详情
brandAllianceHttp.getBrandDetail = (data) => {
  return commonRequest( { url: '/v3/joinBrand/detailV3', method: 'post', data } )
}

// 品牌详情（更多品牌）
brandAllianceHttp.getBrandDetailMore = (data) => {
  return commonRequest( { url: '/v3/joinBrand/getMore', method: 'post', data } )
}

// 发现页数据
brandAllianceHttp.findPageData = () => {
  return commonRequest( { url: '/v4/find/data', method: 'post' } )
}

// 发现页数据
brandAllianceHttp.getHotRecommend = (data) => {
  return commonRequest( { url: '/v3/joinBrand/getHotRecommend', method: 'post', data } )
}

// 发现页数据
brandAllianceHttp.getCalculateWaimaiData = (code) => {
  return commonRequest( { url: '/v3/joinBrand/getCalculateWaimaiData/' + code, method: 'get' } )
}

export default { brandAllianceHttp };
