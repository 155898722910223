<template>
    <Wraper title="周边配套信息">
        <div v-if="currentData.length > 0">
            <div class="market-business-chart-container-1">
                <blockChart :dataObject="currentData" />
            </div>
            <div class="market-business-chart-explain-container">
                <div class="chart-explain">{{ chartConclusion }}</div>
            </div>
        </div>
        <div class="box-container flex-panel-box">
            <div v-for="item in boxItems" :key="item.name" class="box-panel">
                <div class="box-name">{{ item.name }}</div>
                <div class="box-count">{{ item.count }}{{ item.unit }}</div>
                <div v-if="item.compare > 0" class="box-compare compare-more">
                    <label>{{ item.conclusion }}</label>
                    <img src="/static/shopEcology/report/icon_good@2x.png" class="box-image" style="margin-left: 4px" />
                </div>
                <div v-else-if="item.compare < 0" class="box-compare compare-less">
                    <label>{{ item.conclusion }}</label>
                    <img src="/static/shopEcology/report/icon_not_good@2x.png" class="box-image" style="margin-left: 4px" />
                </div>
                <div v-else class="box-compare compare-equal">{{ item.conclusion }}</div>
            </div>
        </div>
        <div class="market-business-chart-container-2">
            <percentBarChart
              :name="{ content: '当前区域' }"
              :dataObject="currentData"
              :bar="{ height: 18, fixed: 0 }"
              noDataTitle="暂无店铺"
              :legend="{ position: 'top', margin: 8 }"
            />
            <div style="margin-top: 6px">
                <percentBarChart
                    :name="{ content: '周边区域' }"
                    :dataObject="aroundData"
                    :bar="{ height: 18, fixed: 0 }"
                    noDataTitle="暂无店铺"
                />
            </div>
        </div>
    </Wraper>
</template>
<script>
import blockChart from "./blockChart.vue";
import percentBarChart from "./percentBarChart.vue";
import Wraper from "./wraper";
import { deepCopy, getCompareValue } from "../valueUtils";

export default {
    components: { blockChart, percentBarChart, Wraper },
    props: {
        bgmData: {
            type: Object,
            required: true,
        },
        dataObj: {
            type: Object,
            required: true,
        },
        // userBenefitResp: {
        //     type: Object,
        //     default: {},
        // },
    },
    data() {
        return {
            currentData: [], // 当前数据列表
            // 5个分类的颜色和顺序
            categoryColor: [
                { index: 0, name: "娱乐", value: "#AC58F5" },
                { index: 1, name: "酒店", value: "#8879F4" },
                { index: 2, name: "教育", value: "#30C8D7" },
                { index: 3, name: "餐饮", value: "#F58958" },
                { index: 4, name: "零售", value: "#518CDB" },
            ],
            chartConclusion: "", // 数量结论
            boxItems: [], // 店铺和业态盒子数据
            aroundData: [],
            boxData: [
                {
                    key: "residenceCnt",
                    name: "住宅区",
                    value: 17,
                    icon: "img_box_house@2x.png",
                    categoryLevelOneCode: "W03",
                    categoryLevelOneName: "住宅区",
                },
                {
                    key: "officeCnt",
                    name: "写字楼",
                    value: 66,
                    icon: "img_box_office@2x.png",
                    categoryLevelOneCode: "W04",
                    categoryLevelOneName: "写字楼",
                },
                {
                    key: "mallCnt",
                    name: "商业区",
                    value: 3,
                    icon: "img_box_business@2x.png",
                    categoryLevelOneCode: "W02",
                    categoryLevelOneName: "商业区",
                    categoryLevelTwoCode: 'W0216'
                },
                {
                    key: "restaurantCnt",
                    name: "餐饮",
                    value: 523,
                    icon: "img_box_eat@2x.png",
                    categoryLevelOneCode: "W01",
                    categoryLevelOneName: "餐饮",
                },
                {
                    key: "retailCnt",
                    name: "零售",
                    value: 204,
                    icon: "img_box_retail@2x.png",
                    categoryLevelOneCode: "W02",
                    categoryLevelOneName: "零售",
                },
                { key: "hotelCnt", name: "酒店", value: 50, icon: "img_box_hotel@2x.png", categoryLevelOneCode: "W07", categoryLevelOneName: "酒店" },
                {
                    key: "trafficCnt",
                    name: "交通站点",
                    value: 36,
                    icon: "img_box_traffic@2x.png",
                    categoryLevelOneCode: "W09",
                    categoryLevelOneName: "交通",
                },
                {
                    key: "educationCnt",
                    name: "教育",
                    value: 344,
                    icon: "img_box_education@2x.png",
                    categoryLevelOneCode: "W06",
                    categoryLevelOneName: "教育",
                },
                {
                    key: "playCnt",
                    name: "休闲娱乐",
                    value: 39,
                    icon: "img_box_entertainment@2x.png",
                    categoryLevelOneCode: "W08",
                    categoryLevelOneName: "娱乐",
                },
            ],
        };
    },
    mounted() {
        this.load(this.bgmData);
        this.dellData(this.dataObj);
    },
    methods: {
        dellData(data) {
            if (data == undefined) {
                console.log("nearby facility data is undefined, use default data");
                return;
            }
            for (let i = 0; i < this.boxData.length; ++i) {
                let boxItem = this.boxData[i];
                const key = boxItem.key;
                boxItem.value = data[key];
            }
            for (let i = 0; i < this.boxData.length; ++i) {
                let dataItem = this.boxData[i];
                if (dataItem.value == undefined || dataItem.value == 0) {
                    dataItem.value = "-";
                }
            }
        },
        load(output) {
            if (typeof output == "string") {
                output = JSON.parse(output);
            }
            // 得到当前和周边的百分比和颜色
            const cntList = output.categoryShopCntList || [];
            // 得到店铺总数
            const currentTotal = cntList.reduce((total, item) => {
                total += item.value;
                return total;
            }, 0);
            this.currentData = deepCopy(cntList);
            this.currentData.forEach((item) => {
                item.count = item.value;
            });
            this.setListColor(this.currentData, this.categoryColor);
            this.currentData.sort(function (a, b) {
                return a.index - b.index;
            });
            // 周边
            const aroundCntList = output.categoryShopCntAroundList || [];
            // 得到周边店铺总数
            const aroundTotal = aroundCntList.reduce((total, item) => {
                total += item.value;
                return total;
            }, 0);
            this.aroundData = deepCopy(aroundCntList);
            this.aroundData.forEach((item) => {
                item.count = item.value;
            });
            this.setListColor(this.aroundData, this.categoryColor);
            this.aroundData.sort(function (a, b) {
                return a.index - b.index;
            });
            // 得到店铺总数和周边店铺总数
            this.totalCount = currentTotal;
            this.aroundCount = aroundTotal;
            // 得到 数量结论
            let cntListCopy = cntList.slice(0);
            cntListCopy.sort(function (a, b) {
                return b.value - a.value;
            });
            this.chartConclusion = "(";
            for (let i = 0; i < cntListCopy.length; ++i) {
                if (i !== 0) {
                    this.chartConclusion += " / ";
                }
                const item = cntListCopy[i];
                this.chartConclusion += item.name;
                this.chartConclusion += item.value;
                this.chartConclusion += "家";
            }
            this.chartConclusion += ")";
            // 店铺和业态盒子数据
            this.boxItems = [];
            this.addBoxData(output);
        },
        setListColor(dataList, colorList) {
            for (let i = 0; i < dataList.length; ++i) {
                let dataItem = dataList[i];
                let colorItem = colorList.find(function (item) {
                    if (item.name == dataItem.name) {
                        return true;
                    }
                    return false;
                });
                if (colorItem != undefined) {
                    dataItem.color = colorItem.value;
                    dataItem.index = colorItem.index;
                }
            }
        },
        addBoxData(output) {
            this.loadBoxData("店铺数量", this.totalCount, this.aroundCount, getCompareValue(this.totalCount, this.aroundCount / 3, true));
            this.loadBoxData(
                "业态类型",
                output.categoryCnt,
                output.categoryCntAround,
                getCompareValue(output.categoryCnt, output.categoryCntAround, true)
            );
        },
        loadBoxData(name, current, around, compare) {
            if (current == undefined) {
                current = 0;
            }
            if (around == undefined) {
                around = 0;
            }
            if (compare == undefined) {
                compare = 0;
            }
            if (current == 0 && around == 0) {
                this.loadBoxDataNoBoth(name);
            } else if (current == 0) {
                this.loadBoxDataNoCurrent(name, around);
            } else if (around == 0) {
                this.loadBoxDataNoAround(name, current);
            } else {
                this.loadBoxDataNoraml(name, current, around, compare);
            }
        },
        loadBoxDataNoraml(name, current, around, compare) {
            let conclusion = "";
            if (compare > 5) {
                if (compare >= 100) {
                    compare = (compare / 100).toFixed(1);
                    conclusion = "高于周边" + compare + "倍";
                } else {
                    conclusion = "高于周边" + compare.toFixed(1) + "%";
                }
            } else if (compare < -5) {
                conclusion = "低于周边" + Math.abs(compare).toFixed(1) + "%";
            } else {
                compare = 0;
                conclusion = "与周边持平";
            }
            let unit = "家";
            if (name == "业态类型") {
                unit = "个";
            } else {
                conclusion = "密度" + conclusion;
            }
            this.boxItems.push({
                name: name,
                count: current,
                compare: compare,
                unit: unit,
                conclusion: conclusion,
            });
        },
        loadBoxDataNoCurrent(name, around) {
            if (name == "店铺数量") {
                this.boxItems.push({
                    name: "店铺数量",
                    count: 0,
                    unit: "家",
                    compare: -1,
                    conclusion: "低于周边" + around + "家",
                });
            } else {
                this.boxItems.push({
                    name: "业态类型",
                    count: 0,
                    unit: "个",
                    compare: -1,
                    conclusion: "低于周边" + around + "个",
                });
            }
        },
        loadBoxDataNoAround(name, current) {
            if (name == "店铺数量") {
                this.boxItems.push({
                    name: "店铺数量",
                    count: current,
                    unit: "家",
                    compare: 1,
                    conclusion: "高于周边" + current + "家",
                });
            } else {
                this.boxItems.push({
                    name: "业态类型",
                    count: current,
                    unit: "个",
                    compare: 1,
                    conclusion: "高于周边" + current + "个",
                });
            }
        },
        loadBoxDataNoBoth(name) {
            if (name == "店铺数量") {
                this.boxItems.push({
                    name: "店铺数量",
                    count: 0,
                    unit: "家",
                    compare: 0,
                    conclusion: "该区域无店铺",
                });
            } else {
                this.boxItems.push({
                    name: "业态类型",
                    count: 0,
                    unit: "个",
                    compare: 0,
                    conclusion: "该区域无业态类型",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.flex-panel-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .box-panel {
        flex: 1;
    }
}

.market-business-chart-container-1 {
    position: relative;
    height: vw2(150);
}

.market-business-chart-explain-container {
    position: relative;
    padding: vw2(5) 0 vw2(10) 0;
    width: 100%;
}

.chart-explain {
    color: #8E9099;
    font-size: vw2(9);
    line-height: vw2(15);
    text-align: center;
}

.market-region-chart-container-2 {
    position: relative;
    width: 100%;
}

.market-business-chart-container-2 {
    position: relative;
    background-color: #F8F8F9;
    border-radius: vw(10);
    padding: vw(12) vw(25) vw(20) vw(25);
    margin-top: vw(20);
}

.box-list-wrap {
    position: relative;
    margin-top: vw2(10);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    .box-list-info {
        position: relative;
        border-radius: 5px;
        background-color: #FAFAFB;
        padding: vw2(12) 0 vw2(15) 0;
        box-sizing: border-box;
        width: 32%;
        margin-right: vw2(5);
        margin-bottom: vw2(5);

        &:nth-child(3n) {
            margin-right: 0;
        }

        .box-list-img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: vw2(45);
            height: vw2(45);
            z-index: 1;
        }

        .box-list-value {
            color: #2F354B;
            font-size: vw2(14);
            line-height: vw2(20);
            font-weight: bold;
            z-index: 2;
        }

        .box-list-name {
            color: #676C84;
            font-size: vw2(11);
            line-height: vw2(15);
            z-index: 2;
            margin-top: vw2(5);
        }
    }
}

.search-wrap {
    position: relative;
    background: linear-gradient(281deg, #2D7CE9 0%, #518BDB 100%);
    display: flex;
    align-items: center;
    padding: vw2(12) vw2(15);
    border-radius: 5px;
    text-align: left;
    margin-top: vw2(10);

    .main-label {
        color: #FFFFFF;
        font-size: vw2(13);
        font-weight: bolder;
        line-height: vw2(17);
    }

    .sub-label {
        color: #ffffff;
        font-size: vw2(13);
        line-height: vw2(17);
        margin-left: vw2(10);
    }

    .search-btn-img {
        position: absolute;
        right: vw2(15);
        width: vw2(48);
        height: vw2(24);
        line-height: vw2(24);
        font-size: vw2(12);
        color: #518CDB;
        background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7) 100%);
        border-radius: vw2(4);
        border: 1px solid #fff;
        text-align: center;
    }
}

.lock-wrap {
    position: relative;
    height: vw2(252);
    padding-top: vw2(8);

    .tips {
        position: absolute;
        top: vw2(66);
        left: 0;
        width: 100%;
        font-size: vw2(14);
        font-weight: 500;
        color: #fff;
        z-index: 12;
    }

    .lock-img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
}
</style>
