import { Toast } from "vant";

const PROJECT_NAME = "sw-sscm-web";

// 防抖
export function debounce(func, delay) {
    var timeout = null;
    return function() {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, arguments);
        }, delay);
    };
}

// 节流
export function throttle(func, delay) {
    var timer = null;
    var startTime = Date.now();
    return function() {
        var curTime = Date.now();
        var remaining = delay - (curTime - startTime);
        var context = this;
        var args = arguments;
        clearTimeout(timer);
        if (remaining <= 0) {
            func.apply(context, args);
            startTime = Date.now();
        } else {
            timer = setTimeout(func, remaining);
        }
    };
}

// 范围随机数
export function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// 格式化name字符串, 使搜索关键字高亮
export function formatNameToSegments(name = "", searchStr = "") {
    if (!name.includes(searchStr)) {
        return [{ str: name, highlight: false }];
    }
    const nameSegments = [];
    const separator = Math.random().toString();
    name.replace(new RegExp(searchStr, "g"), `${separator}${searchStr}${separator}`)
        .split(separator)
        .forEach(strSegment => {
            if (strSegment) {
                nameSegments.push({
                    str: strSegment,
                    highlight: strSegment === searchStr
                });
            }
        });
    return nameSegments;
}
// 非空
export const isNotEmpty = val => {
    if (Number.isNaN(val) || val === undefined || val === null) {
        return false;
    }
    // eslint-disable-next-line no-new-wrappers
    return new Boolean(`${val}`.trim()).valueOf();
};
// 电话号码校验
export const isPhoneNo = val => /^1[3-9]\d{9}$/.test(val);

// 设置localstorage
export function setLocalStorage(key, value) {
    return localStorage.setItem(PROJECT_NAME + "-" + key, JSON.stringify(value));
}

// 获取localstorage
export function getLocalStorage(key) {
    let json = localStorage.getItem(PROJECT_NAME + "-" + key);
    if (!json || json === "undefined") {
        return "";
    }
    return JSON.parse(json);
}

// 删除localstorage
export function removeLocalStorage(key) {
    return localStorage.removeItem(PROJECT_NAME + "-" + key);
}

export function chartsFontSize(res) {
    let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (!clientWidth) return;
    let fontSize = clientWidth / 750;
    return res * fontSize;
}

export function setCookie(name, value) {
    let Days = 30;
    let exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

export function getCookie(name) {
    let arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    else return null;
}

export function delCookie(name) {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = getCookie(name);
    if(cval != null)
    document.cookie = name + "=" +cval + ";expires=" + exp.toGMTString();
}
export function copyText(str) {
    let input = str + '';
    const el = document.createElement('textarea');
    el.value = input;
    el.setAttribute('readonly', '');
    el.style.contain = 'strict';
    el.style.position = 'absolute';
    el.style.left = '-100vw';
    el.style.fontSize = '12px'; // Prevent zooming on iOS
    document.body.appendChild(el);
    el.select();
    el.selectionStart = 0;
    el.selectionEnd = input.length;
    let success = false;
    try {
        success = document.execCommand('copy');
        Toast('复制成功');
    } catch (err) {
        Toast('复制失败');
    }
    document.body.removeChild(el);
    return success;
}

// 动态加载js文件
export function loadScript(url, callback) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  if (typeof (callback) != "undefined") {
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState == "loaded" || script.readyState == "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        callback();
      };
    }
  }
  script.src = url;
  document.head.appendChild(script);
}
