<template>
  <div class="wrapper">
    <StateBar/>
    <TitleHeader title="点位雷达" color="black" bgcolor="transparent" :back="true"></TitleHeader>
    <AutoView ref="view" :footer="true" class="view">
      <div id="map-container"></div>
      <div class="base">
        <div class="step">
          <div class="title">
            <img class="icon" src="/static/pointRadar/step_2.png" alt="">
            <div class="name">选择点位辐射范围</div>
            <img class="icon_detail" @click="showDetailDialog" src="/static/pointRadar/icon.png" alt="">
          </div>
          <div class="desc">例: 点位周边<span>{{range}}米</span>范围内，是否满足后续筛选条件</div>
          <div class="option-list step_2">
            <div class="item item-ft" :class="{select: item.value === range}" v-for="item in rangeList" :key="item.name" @click="selectRadio(item.value)">{{item.name}}</div>
          </div>
        </div>
        <div class="step">
          <div class="title">
            <img class="icon" src="/static/pointRadar/step_3.png" alt="">
            <div class="name">选择点位偏好的条件</div>
          </div>
          <div class="desc">选择下方符合您偏好的场景，系统将为您扫描符合条件的点位 操作提示：单击=选中，再次点击=取消选中</div>
          <div class="step-item">
            <div class="lable">区域属性</div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in regionList" :key="item.text" @click="select(regionList, index)">{{item.text}}</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">购物配套</div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in shopList" :key="item.text" @click="select(shopList, index)">{{item.text}}</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">医疗配套</div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in medicalList" :key="item.text" @click="select(medicalList, index)">{{item.text}}</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">教育配套</div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in educationList" :key="item.text" @click="select(educationList, index)">{{item.text}}</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">交通配套</div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in trafficList" :key="item.text" @click="select(trafficList, index)">{{item.text}}</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">业态条件</div>
            <div class="desc">
              <p>可以扫描偏好某业态的点位，避开某业态的点位</p>
              <p>例：扫描有“火锅”的点位，避开有“麻辣烫”的点位</p>
            </div>
            <div class="option-list">
              <div class="item item-rt" :class="{select: businessPrefer.length}"  @click="selectBusinessPrefer()">
                <div class="cell">
                  <div class="cell-ft">选择偏好业态</div>
                  <div class="cell-ft arrow">
                    <img src="/static/pointRadar/arr-right.png" />
                  </div>
                </div>
                <div class="item_content" v-if="businessPreferText">{{businessPreferText}}</div>
              </div>
              <div class="item item-rt" :class="{select: businessAvoid.length}"  @click="selectBusinessAvoid()">
                <div class="cell">
                  <div class="cell-ft">选择避开业态</div>
                  <div class="cell-ft arrow">
                    <img src="/static/pointRadar/arr-right.png" />
                  </div>
                </div>
                <div class="item_content" v-if="businessAvoidText">{{businessAvoidText}}</div>
              </div>
              <div class="item unlimited" :class="{select: businessUnlimited}"  @click="selectbusinessUnlimited()">不限</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">品牌条件</div>
            <div class="desc">
              <p>可以扫描偏好某品牌的点位，避开某品牌的点位</p>
              <p>例：扫描有“麦当劳”的点位，避开有“钱大妈”的点位</p>
            </div>
            <div class="option-list">
              <div class="item item-rt" :class="{select: brandPrefer.length}"  @click="selectBrandPrefer()">
                <div class="cell">
                  <div class="cell-ft">选择偏好品牌</div>
                  <div class="cell-ft arrow">
                    <img src="/static/pointRadar/arr-right.png" />
                  </div>
                </div>
                <div class="item_content" v-if="brandPreferText">{{brandPreferText}}</div>
              </div>
              <div class="item item-rt" :class="{select: brandAvoid.length}"  @click="selectBrandAvoid()">
                <div class="cell">
                  <div class="cell-ft">选择避开品牌</div>
                  <div class="cell-ft arrow">
                    <img src="/static/pointRadar/arr-right.png" />
                  </div>
                </div>
                <div class="item_content" v-if="brandAvoidText">{{brandAvoidText}}</div>
              </div>
              <div class="item unlimited" :class="{select: brandUnlimited}"  @click="selectBrandUnlimited()">不限</div>
            </div>
          </div>
          <div class="step-item" v-if="isShowPosition">
            <div class="lable">位置类型</div>
            <div class="desc">
              <p>金角：点位周边有街道交叉口，人流动线汇集点</p>
              <p>银边：金角位置的周边</p>
              <p>注：目前金角银边支持{{ cityList.length }}个城市<span @click="checkCity">查看城市清单</span></p>
            </div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in positionTypeList" :key="item.text" @click="select(positionTypeList, index)">{{item.text}}</div>
            </div>
          </div>
          <div class="step-item">
            <div class="lable">外卖偏好</div>
            <div class="option-list">
              <div class="item item-ft" :class="{select: item.isSelected}" v-for="(item, index) in takeOutList" :key="item.text" @click="select(takeOutList, index)">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>

    </AutoView>
    <SoltFooter class="input--footer">
<!--      <img class="ad" v-if="!packageInfo" src="/static/pointRadar/svip_ad.png" alt="" @click="gotoSvip">-->
      <div class="pkg" v-if="packageInfo">
        <div>已选择<span>{{packageInfo.firstText}}</span></div>
        <div>{{packageInfo.secondText}} <span>{{packageInfo.restExchangeTimes}}</span> {{packageInfo.suffixText}}</div>
      </div>
      <div class="footer">
        <van-button type="info" block class="s-radius__s" @click="onTapPay">
          <div class="btn">
            <template v-if="!packageInfo">
              <div class="price">¥39<span>/次起</span></div>
              <div class="line"></div>
            </template>
            <div>立即扫描点位</div>
          </div>
        </van-button>
      </div>
    </SoltFooter>
    <van-popup
      v-model="showExample"
      round
      closeable
      :safe-area-inset-bottom="false"
      :close-on-click-overlay="false"
      @click-overlay="showExample = false"
    >
      <div class="subview">
        <h3>为什么要选择点位的大小？</h3>
        <p>首先，系统会在您所选的扫描区域内，拆分若干个同等大小规格的点位。</p>
        <img class="example_1" src="/static/pointRadar/example_1.png" alt="">
        <p>其次，系统会根据您选择的点位条件，自动扫描符合条件的点位，统一输出。</p>
        <img class="example_2" src="/static/pointRadar/example_2.png" alt="">
      </div>
    </van-popup>
    <van-popup
      v-model="showCityList"
      round
      closeable
      :safe-area-inset-bottom="false"
      :close-on-click-overlay="false"
      @click-overlay="showCityList = false"
    >
      <div class="city-subview">
        <h3>城市清单</h3>
        <div>支持扫描金角银边的城市：</div>
        <div class="city">{{cityText}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
const GOODS_ID = 10102 // 点位雷达商品Id
import LoadMap from "@/components/baseMap/loadMap";
import temporary from '@/views/shops/temporary.vue';
export default {
  components: { temporary },
  data() {
    return {
      range: 300,
      GOODS_ID,
      rangeList: [
        { name: '300米', value: 300 },
        { name: '500米', value: 500 }
      ],
      regionList: [
        { text: '住宅', code: 'W030201', isSelected: false },
        { text: '写字楼', code: 'W040301', isSelected: false }
      ],
      shopList: [
        { text: '购物中心/百货商场', code: 'W021602', isSelected: false },
        { text: '超市', code: 'W020516', isSelected: false },
        { text: '便利店', code: 'W020517', isSelected: false },
      ],
      medicalList: [
        { text: '综合医院（三甲）', code: 'W100103', isSelected: false },
        { text: '社康', code: 'W100105', isSelected: false },
        { text: '药店', code: 'W100601', isSelected: false },
      ],
      educationList: [
        { text: '幼儿园', code: 'W060109', isSelected: false },
        { text: '小学', code: 'W060110', isSelected: false },
        { text: '中学', code: 'W060111', isSelected: false },
      ],
      trafficList: [
        { text: '公交站', code: 'W090102', isSelected: false },
        { text: '地铁站', code: 'W090201', isSelected: false },
      ],
      positionTypeList: [
        { text: '金角', code: '1', isSelected: false },
        { text: '银边', code: '2', isSelected: false },
      ],
      takeOutList: [
        { text: '外卖店密集地', code: '1', isSelected: false },
        { text: '外卖万单店（月销9999+）', code: '2', isSelected: false },
      ],
      businessPrefer: [],
      businessAvoid: [],
      brandPrefer: [],
      brandAvoid: [],
      type: '',
      businessUnlimited: false,
      brandUnlimited: false,
      showExample: false,
      showCityList: false,
      canClick: true, // 扫描按钮是否可点击
      client: 3, // 1: ios 2: android 3: 网页
      gps: {
        lat: null,
        lng: null,
        poiName: "",
        radiusKm: null
      },
      region: {
        code: '',
        text: ''
      },
      isShowPosition: false,
      cityList: [
        "北京市",
        "天津市",
        "石家庄市",
        "唐山市",
        "秦皇岛市",
        "邯郸市",
        "邢台市",
        "保定市",
        "承德市",
        "沧州市",
        "廊坊市",
        "衡水市",
        "太原市",
        "大同市",
        "阳泉市",
        "长治市",
        "晋城市",
        "朔州市",
        "晋中市",
        "运城市",
        "忻州市",
        "临汾市",
        "吕梁市",
        "呼和浩特市",
        "包头市",
        "乌海市",
        "巴彦淖尔市",
        "乌兰察布市",
        "沈阳市",
        "大连市",
        "鞍山市",
        "抚顺市",
        "本溪市",
        "丹东市",
        "锦州市",
        "营口市",
        "阜新市",
        "辽阳市",
        "盘锦市",
        "铁岭市",
        "朝阳市",
        "葫芦岛市",
        "长春市",
        "吉林市",
        "四平市",
        "辽源市",
        "通化市",
        "白山市",
        "松原市",
        "白城市",
        "延边朝鲜族自治州",
        "哈尔滨市",
        "鸡西市",
        "鹤岗市",
        "双鸭山市",
        "大庆市",
        "伊春市",
        "七台河市",
        "牡丹江市",
        "上海市",
        "南京市",
        "无锡市",
        "徐州市",
        "常州市",
        "苏州市",
        "南通市",
        "连云港市",
        "淮安市",
        "盐城市",
        "扬州市",
        "镇江市",
        "泰州市",
        "宿迁市",
        "杭州市",
        "宁波市",
        "温州市",
        "嘉兴市",
        "湖州市",
        "绍兴市",
        "金华市",
        "衢州市",
        "舟山市",
        "台州市",
        "丽水市",
        "合肥市",
        "芜湖市",
        "蚌埠市",
        "淮南市",
        "马鞍山市",
        "淮北市",
        "铜陵市",
        "安庆市",
        "黄山市",
        "滁州市",
        "阜阳市",
        "宿州市",
        "六安市",
        "亳州市",
        "池州市",
        "宣城市",
        "福州市",
        "厦门市",
        "莆田市",
        "三明市",
        "泉州市",
        "漳州市",
        "南平市",
        "龙岩市",
        "宁德市",
        "南昌市",
        "景德镇市",
        "萍乡市",
        "九江市",
        "新余市",
        "鹰潭市",
        "赣州市",
        "吉安市",
        "宜春市",
        "抚州市",
        "上饶市",
        "济南市",
        "青岛市",
        "淄博市",
        "枣庄市",
        "东营市",
        "烟台市",
        "潍坊市",
        "济宁市",
        "泰安市",
        "威海市",
        "日照市",
        "临沂市",
        "德州市",
        "聊城市",
        "滨州市",
        "菏泽市",
        "郑州市",
        "开封市",
        "洛阳市",
        "平顶山市",
        "安阳市",
        "鹤壁市",
        "新乡市",
        "焦作市",
        "濮阳市",
        "许昌市",
        "漯河市",
        "三门峡市",
        "南阳市",
        "商丘市",
        "信阳市",
        "周口市",
        "驻马店市",
        "武汉市",
        "黄石市",
        "十堰市",
        "宜昌市",
        "襄阳市",
        "鄂州市",
        "荆门市",
        "孝感市",
        "荆州市",
        "黄冈市",
        "咸宁市",
        "随州市",
        "恩施土家族苗族自治州",
        "长沙市",
        "株洲市",
        "湘潭市",
        "衡阳市",
        "邵阳市",
        "岳阳市",
        "常德市",
        "张家界市",
        "益阳市",
        "郴州市",
        "永州市",
        "怀化市",
        "娄底市",
        "湘西土家族苗族自治州",
        "广州市",
        "韶关市",
        "深圳市",
        "珠海市",
        "汕头市",
        "佛山市",
        "江门市",
        "湛江市",
        "茂名市",
        "肇庆市",
        "惠州市",
        "梅州市",
        "汕尾市",
        "河源市",
        "阳江市",
        "清远市",
        "东莞市",
        "中山市",
        "潮州市",
        "揭阳市",
        "云浮市",
        "南宁市",
        "柳州市",
        "桂林市",
        "梧州市",
        "北海市",
        "防城港市",
        "钦州市",
        "贵港市",
        "玉林市",
        "百色市",
        "贺州市",
        "河池市",
        "来宾市",
        "崇左市",
        "海口市",
        "三亚市",
        "儋州市",
        "重庆市",
        "成都市",
        "自贡市",
        "攀枝花市",
        "泸州市",
        "德阳市",
        "绵阳市",
        "广元市",
        "遂宁市",
        "内江市",
        "乐山市",
        "南充市",
        "眉山市",
        "宜宾市",
        "广安市",
        "达州市",
        "雅安市",
        "巴中市",
        "资阳市",
        "凉山彝族自治州",
        "贵阳市",
        "六盘水市",
        "遵义市",
        "安顺市",
        "毕节市",
        "铜仁市",
        "黔西南布依族苗族自治州",
        "黔东南苗族侗族自治州",
        "黔南布依族苗族自治州",
        "昆明市",
        "曲靖市",
        "玉溪市",
        "保山市",
        "昭通市",
        "丽江市",
        "临沧市",
        "楚雄彝族自治州",
        "红河哈尼族彝族自治州",
        "文山壮族苗族自治州",
        "西双版纳傣族自治州",
        "大理白族自治州",
        "德宏傣族景颇族自治州",
        "怒江傈僳族自治州",
        "迪庆藏族自治州",
        "拉萨市",
        "山南市",
        "西安市",
        "铜川市",
        "宝鸡市",
        "咸阳市",
        "渭南市",
        "延安市",
        "汉中市",
        "榆林市",
        "安康市",
        "商洛市",
        "兰州市",
        "嘉峪关市",
        "金昌市",
        "白银市",
        "天水市",
        "武威市",
        "张掖市",
        "平凉市",
        "酒泉市",
        "庆阳市",
        "定西市",
        "陇南市",
        "临夏回族自治州",
        "甘南藏族自治州",
        "西宁市",
        "海东市",
        "海北藏族自治州",
        "黄南藏族自治州",
        "银川市",
        "石嘴山市",
        "吴忠市",
        "固原市",
        "中卫市",
        "乌鲁木齐市",
        "克拉玛依市",
        "博尔塔拉蒙古自治州",
        "克孜勒苏柯尔克孜自治州",
      ],
      packageInfo: {
        firstText: '',
        restExchangeTimes: null,
        secondText: '',
        suffixText: '',
        questionId: null,
      },
      map: null,
      geocoder: null
    }
  },
  computed: {
    businessPreferText() {
      const businessPreferArr =  this.businessPrefer.map(item => {
        return item.text
      })
      return businessPreferArr.join('、')
    },
    businessAvoidText() {
      const businessAvoidArr =  this.businessAvoid.map(item => {
        return item.text
      })
      return businessAvoidArr.join('、')
    },
    brandPreferText() {
      const brandPreferArr =  this.brandPrefer.map(item => {
        return item.text
      })
      return brandPreferArr.join('、')
    },
    brandAvoidText() {
      const brandAvoidArr =  this.brandAvoid.map(item => {
        return item.text
      })
      return brandAvoidArr.join('、')
    },
    cityText() {
      return this.cityList.join('、')
    }
  },
  activated() {
    console.log('actived')
    this.canClick = true
  },
  beforeMount() {
    console.log('beforeMount')
    this.canClick = true
  },
  mounted() {
    // 设置客户端类型
    this.client = window.isIOS ? 1 : 2
    const params = this.$route.query
    console.log(params, 'params')
    this.type = params.type
    if(params.type === 'location') {
      this.gps.lat = parseFloat(params.lat)
      this.gps.lng = parseFloat(params.lng)
      this.gps.poiName = params.poiName
      this.gps.radiusKm = params.radius / 1000
    } else {
      if(!params.region) {
        this.$toast.fail('未识别扫描区域，请重新选择')
        setTimeout(() => {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "POINT_RADAR",
            id: null,
            params: null,
            trackData: null,
            isClear: true,
          });
        }, 1000)
        return
      }
      this.region.code = params.regionCode
      this.region.text = `${params.province}-${params.city}-${params.region}`
      this.isShowPosition = this.cityList.includes(params.city )
    }
    this.getCanUsePkg()
    LoadMap().then(() => {
      this.init();
    });
  },
  methods: {
    init() {
      let that = this;
      let lnglat = that.gps.lng && that.gps.lat ? [that.gps.lng, that.gps.lat] : null;
      that.map = new AMap.Map("map-container", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 14, //初始化地图层级
        center: lnglat, //初始化地图中心点
      });
      that.geocoder = new AMap.Geocoder();
      if(that.gps.lat && that.gps.lng) {
        that.geocoder.getAddress(new AMap.LngLat(that.gps.lng, that.gps.lat), (res, value) => {
          if (res === "complete") {
            let city = value.regeocode.addressComponent.city
            this.isShowPosition = this.cityList.includes(city)
          }
        });
      }
    },
    getCanUsePkg() {
      this.HTTP.getCanUsePkg(this.GOODS_ID).then(res => {
        console.log(res)
        this.packageInfo = res || null
      })
    },
    onTapPay() {
      // 防止用户多次点击
      if(!this.canClick) {
        return
      }
      if(!this.verificationHandle()) {
        this.$toast.fail('请至少选择一个条件')
        return
      }
      let residence = this.regionList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      let shop = this.shopList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      let hospital = this.medicalList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      let education = this.educationList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      let traffic = this.trafficList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      let locationType = this.positionTypeList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      let waiMai = this.takeOutList.filter(item => item.isSelected).map(item => {
        return {code: item.code, text: item.text}
      })
      const poi = []
      poi.push(...residence, ...shop, ...hospital, ...education, ...traffic)
      const param = {
        inputTypeRadarReq: {
          poi,
          range: this.range,
          brandAvoid: this.brandAvoid,
          brandPrefer: this.brandPrefer,
          industryAvoid: this.businessAvoid,
          industryPrefer: this.businessPrefer,
          locationType,
          waiMai,
          type: this.type
        },
        goodsId: this.GOODS_ID,
      }
      if(this.type === 'region') {
        param.region = this.region
      } else {
        param.gps = this.gps
      }
      if(this.packageInfo) {
        param.userQuestionPkgItemId = this.packageInfo.questionId
      }
      this.canClick = false
      this.HTTP.generateReport(param).then(res => {
        if(this.packageInfo) {
          this.JSBridge.BTNCLICK(this.$route.meta.id, '', 3100100, 3100102);
          console.log(JSON.stringify(res.link))
          this.JSBridge.callHandler("onNormalizingCall", JSON.stringify(res.link))
        } else {
          this.JSBridge.BTNCLICK(this.$route.meta.id, '', 3100100, 3100101);
          this.swRouter(`/pointRadar/payOrder?reportInstanceId=${res.reportInstanceId}`)
        }
        this.canClick = true
      }).catch(err => {
        this.$toast.fail('生成报告失败，请稍后再试')
        this.canClick = true
      })
    },
    verificationHandle() {
      return this.checkSelected(this.regionList) || this.checkSelected(this.shopList) || this.checkSelected(this.medicalList) || this.checkSelected(this.educationList) || this.checkSelected(this.trafficList) || this.checkSelected(this.positionTypeList) || this.checkSelected(this.takeOutList) || this.businessPreferText || this.businessAvoidText || this.brandPreferText || this.brandAvoidText || this.businessUnlimited || this.brandUnlimited
    },
    checkSelected(arr) {
      return arr.some(item => {
        return item.isSelected
      })
    },
    showDetailDialog() {
      this.showExample = true
    },
    selectRadio(item) {
      this.range = item
    },
    select(obj, index) {
      obj.forEach((item, v) => {
        if(index === v) {
          item.isSelected = !item.isSelected
        }
      })
    },
    selectBusinessPrefer() {
      const data = JSON.stringify({
        title: '业态偏好',
        maxSize: 3,
        pageType: 1,
        type: 1,
        prefer: this.businessPrefer.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        }),
        avoid: this.businessAvoid.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        })
      })
      this.JSBridge.callHandler('selectIndustry', data, (res => {
        if(res === undefined) {
          this.businessPrefer = []
        } else {
          let result = JSON.parse(res)
          if(typeof result === 'string') {
            result = JSON.parse(result)
          }
          if(result && result.length) {
            this.businessUnlimited = false
            this.businessPrefer = result.map(item => {
              return {
                code: item.code,
                text: item.name
              }
            })
          } else {
            this.businessPrefer = []
          }
        }
      }))
    },
    selectBusinessAvoid() {
      const data = JSON.stringify({
        title: '避开业态',
        maxSize: 3,
        pageType: 1,
        type: 2,
        prefer: this.businessPrefer.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        }),
        avoid: this.businessAvoid.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        })
      })
      this.JSBridge.callHandler('selectIndustry', data, (res => {
        if(res === undefined) {
          this.businessAvoid = []
        } else {
          let result = JSON.parse(res)
          if(typeof result === 'string') {
            result = JSON.parse(result)
          }
          if(result && result.length) {
            this.businessUnlimited = false
            this.businessAvoid = result.map(item => {
              return {
                code: item.code,
                text: item.name
              }
            })
          } else {
            this.businessAvoid = []
          }
        }
      }))
    },
    gotoSvip() {
      this.swRouter('/h5/#/member')
    },
    selectbusinessUnlimited() {
      this.businessUnlimited = true
      this.businessPrefer = []
      this.businessAvoid = []
    },
    selectBrandPrefer() {
      const data = JSON.stringify({
        title: '品牌偏好',
        maxSize: 3,
        type: 1,
        prefer: this.brandPrefer.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        }),
        avoid: this.brandAvoid.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        })
      })
      this.JSBridge.callHandler('selectBrand', data, (res => {
        if(res === undefined) {
          this.brandPrefer = []
        } else {
          let result = JSON.parse(res)
          if(typeof result === 'string') {
            result = JSON.parse(result)
          }
          if(result && result.length) {
            this.brandUnlimited = false
            this.brandPrefer = result.map(item => {
              return {
                code: item.code,
                text: item.name
              }
            })
          } else {
            this.brandPrefer = []
          }
        }
      }))
    },
    selectBrandAvoid() {
      const data = JSON.stringify({
        title: '避开品牌',
        maxSize: 3,
        type: 2,
        prefer: this.brandPrefer.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        }),
        avoid: this.brandAvoid.map(item => {
          return {
            code: item.code,
            name: item.text
          }
        })
      })
      this.JSBridge.callHandler('selectBrand', data, (res => {
        if(res === undefined) {
          this.brandAvoid = []
        } else {
          let result = JSON.parse(res)
          if(typeof result === 'string') {
            result = JSON.parse(result)
          }
          if(result && result.length) {
            this.brandUnlimited = false
            this.brandAvoid = result.map(item => {
              return {
                code: item.code,
                text: item.name
              }
            })
          } else {
            this.brandAvoid = []
          }
        }
      }))
    },
    selectBrandUnlimited() {
      this.brandUnlimited = true
      this.brandPrefer = []
      this.brandAvoid = []
    },
    checkCity() {
      this.showCityList = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import "@/styles/index.scss";
.wrapper {
  height: 100vh;
}
.view {
  padding-bottom: vw(70);
  position: relative;
}
.base {
  padding: vw(30);
  .step {
    color: #121622;
    font-size: vw(30);
    margin-top: vw(57);
    .title {
      display: flex;
      align-items: center;
      font-weight: bold;
      line-height: vw(47);
      .icon {
        width: vw(142);
        height: vw(47);
      }
      .icon_detail {
        width: vw(30);
        height: vw(30);
        margin-left: vw(10);
      }
    }
    .desc {
      margin-top: vw(20);
      font-size: vw(24);
      color: #8893A7;
      line-height: vw(33);
      span {
        font-weight: bold;
        color: #1D2233;
      }
      p {
        line-height: vw(33);
        span {
          color: #347FFF;
          margin-left: vw(20);
          font-weight: normal;
          text-decoration: underline;
        }
      }
    }
    .lable {
      display: block;
      font-weight: bold;
      line-height: vw(33);
      margin-top: vw(30);
    }
    .option-list {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      margin-top: vw(20);
      .item {
        margin-right: vw(16);
        text-align: center;
        font-size: vw(25);
        padding: vw(17) vw(30);
        color: #1D2233;
        border-radius: vw(8);
        margin-bottom: vw(10);
        background-color: #fff;
        position: relative;
        border: vw(2) solid #8893A7;

        .cell {
          padding: vw(17) vw(30);
          display: flex;
          align-items: center;
          .cell-ft {
            img {
              width: vw(30);
              height: vw(30);
            }
          }
        }
        .item_content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: vw(49);
          line-height: vw(49);
          padding: 0 vw(10);
          font-size: vw(22);
          background-color: rgba(52, 127, 255, 0.08);
        }
      }
      .item:last-child {
        margin-right: 0;
      }
      .item-ft {
        min-width: vw(120);
      }
      .item-rt {
        width: vw(239);
        padding: 0;
      }
      .unlimited {
        height: vw(34);
        width: vw(90);
        line-height: vw(34);
      }
      .select {
        background-color: rgba(52, 127, 255, 0.1);
        color: #347FFF;
        font-weight: bold;
        border: vw(2) solid #347FFF;
      }
    }
    .step_2 {
      margin-top: vw(30);
    }

  }
  .step:first-child {
    margin-top: 0;
  }
}
.subview {
  width: vw(530);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: vw(40) vw(50);
  background-image: linear-gradient(#F1F6FF, #fff);
  h3 {
    font-size: vw(34);
  }
  p {
    padding: vw(20) vw(10);
    color: #1D2233;
    font-size: vw(24);
    line-height: vw(33);
  }
  .example_1 {
    width: vw(530);
    height: vw(457);
  }
  .example_2 {
    width: vw(530);
    height: vw(429);
  }
}
.city-subview {
  width: vw(490);
  // height: vw(570);
  background-image: url('/static/pointRadar/city_list_bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  padding: vw(50) vw(40);
  h3 {
    font-size: vw(36);
    line-height: vw(50);
    text-align: center;
    margin-bottom: vw(22);
  }
  div {
    font-size: vw(26);
    line-height: vw(37);
  }
  .city {
    color: #8893A7;
    margin-top: vw(20);
    max-height: 60vh;
    overflow-y: auto;
  }
}
.input--footer {
  position: relative;
  .ad, .pkg {
    width: 100%;
    height: vw(70);
    position: absolute;
    left: 0;
    top: vw(-70);
  }
  .pkg {
    height: vw(87);
    top: vw(-87);
    background-color: #FFF4E7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      color: #1D2233;
      font-size: vw(24);
      line-height: vw(33);
      span {
        color: #8D5D22;
        font-weight: bold;
      }
    }
  }
  .footer {
    width: 100%;
    padding: vw(15) vw(30);
    box-sizing: border-box;
    .btn {
      display: flex;
      align-items: center;
      .price {
        span {
          font-size: vw(22);
          margin-left: vw(5);
        }
      }
      .line {
        height: vw(36);
        width: vw(1);
        margin: 0 vw(30);
        background-color: #fff;
      }
    }
    .s-radius__s {
      background-color: #FF8047 !important;
      border: vw(1) solid #FF8047 !important;
      overflow: inherit !important;
    }
  }
}
</style>
