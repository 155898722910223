<template>
    <!-- 品牌详情介绍 -->
    <div class="card-box" v-if="content" :class="isTwoMore == 'down' ? 'deHieghtP':'deppHieghtPp'">
        <div class="head-title">{{titlePro}}</div>
        <div class="brand_detail">
            <div class="brand_detail_box" :style="[{'min-height': (theHeight > '54.67' ? '54.67vw' : '')}]">
                <div class="detail_pro" :style="[{height: (theHeight > boxHeight && isTwoMore == 'down' ? boxHeight+'vw' : 'auto')}]">
                    <div class="axios_content" ref="axios_content" v-html="content"></div>
                </div>
                <div :class="isTwoMore == 'down' && theHeight > boxHeight ? 'brand_detail_box_bg' : ''"></div>
                <div :class="isTwoMore == 'down' ? 'click_btn':'up_btn'" v-if="isShowMore" @click="moreConetent()">
                    <template v-if="isTwoMore == 'down'">
                        <span>查看更多</span>
                        <img src="/static/icon/new_brand_down.png" alt="">
                    </template>
                    <template v-else>
                        <span>收起</span>
                        <img src="/static/icon/plan_up.png" alt="">
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "detail_pro",
        props: {
            content: {
                type: String
            },
            titlePro: {
                type: String
            },
            isNum: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                isShowMore: false,
                isTwoMore: 'down',
                theHeight: null, //实际高度
                boxHeight: this.isNum == 1 ? '42.67' : "64"
            }
        },
        methods: {
            // 查看更多
            moreConetent() {
                if(this.isTwoMore == 'down') {
                    this.isTwoMore = 'up';
                } else {
                    this.isTwoMore = 'down';
                }
            },
        },
        watch: {
            content(newVal, oldVal) {
                console.log(newVal)
                console.log(oldVal)
            }
        },
        mounted() {
            if(this.content) {
                this.$nextTick(() => {
                    // setTimeout( () => {
                        let height = this.$refs.axios_content.offsetHeight;
                        console.log(height)
                        // console.log(document.querySelector('.axios_content img'))
                        this.theHeight = height / (document.body.clientWidth / 100);
                        if(height > 160) {
                            this.isShowMore = true;
                        } else this.isShowMore = false;
                    // },500)
                })
            } else this.isShowMore = false;
        }
    }
</script>
<style lang="scss" scoped>
@import "@/styles/index.scss";
.card-box{
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 2.67vw;
    .head-title{
        font-size: 4.53vw;
        font-weight: bold;
        color: #1D2233;
        line-height: 6.4vw;
    }
    // 品牌详情介绍
    .brand_detail{
        margin-top: 2.67vw;
        text-align: center;
        .brand_detail_box{
            width: 100%;
            // min-height: 54.67vw;
            padding: 3.33vw;
            box-sizing: border-box;
            border-radius: 1.87vw;
            background: #F7F7F7;
            position: relative;
            .detail_pro{
                @include aniShop;
                width: 100%;
                // height: 42.67vw;
                overflow: hidden;
                // transition: all .2s;
                .axios_content{
                    width: 100%;
                    font-size: 3.73vw;
                    color: #333333;
                    line-height: 5.33vw;
                    text-align: left;
                    >>> img{
                        width: 100%;
                        height: 100%;
                        margin-bottom: 1.33vw;
                        border-radius: 2.13vw;
                        &:last-child{
                            margin-bottom: 0vw;
                        }
                    }
                    >>> p{
                        font-size: 3.73vw;
                        color: #333333;
                        line-height: 5.33vw;
                        margin-bottom: 1.33vw;
                        &:last-child{
                            margin-bottom: 0vw;
                        }
                    }
                }
            }
            .brand_detail_box_bg{
                position: absolute;
                width: calc(100% + 8vw);
                bottom: 0;
                left: -4vw;
                height: 15.33vw;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
                border-radius: 1.33vw;
                z-index: 1;
            }
            .click_btn{
                padding: 2.27vw 2.93vw;
                margin: 1.46vw auto 0;
                display: inline-block;
                background: rgba(0, 0, 0, 0.53);
                border-radius: 1.33vw;
                line-height: normal;
                font-size: 0;
                position: absolute;
                bottom: -4.4vw;
                left: 50%;
                z-index: 1;
                transform: translateX(-50%);
                span{
                    font-size: 3.73vw;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: normal;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 1.07vw;
                }
                img{
                    width: 2.93vw;
                    height: 1.47vw;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .up_btn{
                display: inline-block;
                border-radius: 1.33vw;
                line-height: normal;
                font-size: 0;
                position: absolute;
                bottom: 0;
                left: 50%;
                z-index: 1;
                transform: translateX(-50%);
                bottom: -7.7vw;
                span{
                    font-size: 3.73vw;
                    font-weight: bold;
                    color: #999999;
                    line-height: normal;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 1.07vw;
                }
                img{
                    width: 2.93vw;
                    height: 1.47vw;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}
.deHieghtP{
    padding: 4.53vw 4vw 8.53vw !important;
}
.deppHieghtPp{
    padding: 4.53vw 4vw 10.53vw !important;
}
</style>