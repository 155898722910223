<template>
  <div class="wrapper">
      <div class="part part-1">
        <img class="bg" src="/static/newUserActivity/part-1.png" alt=""/>
      </div>
      <div class="part middle-part">
        <div class="part-title">
          <img class="title-icon" src="/static/newUserActivity/icon.png" alt=""/>
          <p class="title-name">热销爆款 首单特惠</p>
          <img class="title-icon" src="/static/newUserActivity/icon.png" alt=""/>
        </div>
        <img class="bg" src="/static/newUserActivity/part-2.png" alt="" />
        <img class="bg" src="/static/newUserActivity/part-3.png" alt="" />
        <img class="bg" src="/static/newUserActivity/part-4.png" alt="" />
      </div>
      <div class="bottom-part">
        <div class="part-title">
          <img class="title-icon" src="/static/newUserActivity/icon.png" alt=""/>
          <p class="title-name">超值换购 9.9元入会</p>
          <img class="title-icon" src="/static/newUserActivity/icon.png" alt=""/>
        </div>
        <img class="bg" src="/static/newUserActivity/part-5.png" alt="" />
        <div class="part2_ad">
          <div class="adItem" v-for="item in part2AdList" :key="item.url">
            <img :src="item.url" alt="" />
            <div class="gotoUser" @click="gotoDetail(item.id, item.btnId)">
              <p class="btn-text">¥<span>{{ item.price }}/次</span>起</p>
              <img class="right" src="/static/newUserActivity/right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="introduce">
        <p>本活动最终解释权归上上参谋所有</p>
        <p>如有疑问，请联系在线客服。</p>
      </div>
  </div>
</template>

<script>
    export default {
        name: 'newUserActivity',
        data() {
            return {
              part2AdList: [
                {
                  url: '/static/newPeople/part2_ad1.png',
                  id: '10001',
                  btnId: '84202',
                  price: '79'
                },
                {
                  url: '/static/newPeople/part2_ad2.png',
                  id: '10026',
                  btnId: '84203',
                  price: '123'
                },
              ],
            }
        },
        methods: {
          gotoDetail(id, btnId) {
            // this.JSBridge.BTNCLICK( 10172, '', 84200, btnId);
            // this.JSBridge.callHandler("onNormalizingCall", {
            //   type: 5,
            //   url: "BRAND_INTRODUCTION_V2",
            //   id: id,
            //   isClear: null,
            // })
          },
        }
    };
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    background: url("/static/newUserActivity/bg.png") repeat;
    background-size: 100% auto;
    .bg {
      width: 100%;
    }
    .part-1 {

    }
    .part-title {
      display: flex;
      align-items: center;
      justify-content: center;

      .title-icon {
        width: 5.33vw;
      }
      .title-name {
        font-size: 4.266vw;
        color: #fff;
        font-weight: 600;
        margin: 0 2.93vw;
      }

    }
    .middle-part {
      padding: 0 1.6vw;
      .part-title {
        padding: 4.8vw 0 2.4vw 0;
      }
    }
    .bottom-part {
      padding: 0 4vw;
      position: relative;
      .part-title {
        padding: 2.4vw 0 4.8vw 0;
      }
    }
    .part2_ad {
      display: flex;
      justify-content: space-between;
      padding: 0 8.266vw;
      position: absolute;
      top: 76.5vw;
      left: 0;
      .adItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:first-child {
          margin-right: 4vw;
        }
        img {
          width: 100%;
        }
        .gotoUser {
          width: 28.8vw;
          height: 10.133vw;
          background: #FF3B57;
          box-shadow: 0 0.53vw 1.06vw 0 #FF615B;
          border-radius: 5.066vw;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -3vw;
          z-index: 1;
          position: relative;
          box-sizing: border-box;
          .right {
            width: 1.6vw;
            height: 3.2vw;
            margin-left: 1.6vw;
          }
          .btn-text {
            font-size: 2.933vw;
            color: #fff;
            span {
              font-size: 4.266vw;
            }
          }
        }
      }
    }
    .introduce {
      font-weight: 400;
      color: #FFFFFF;
      font-size: 2.666vw;
      padding: 4.266vw 0;
      text-align: center;
      p {
        line-height: 3.73vw;
      }
    }
  }
</style>
