<template>
    <div class="tabs">
        <ul class="title-bar">
            <li>开店位置推荐</li>
            <li class="active">开店选址评估</li>
            <li>外卖选址评估</li>
            <li>数据查询</li>
        </ul>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'index'
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

.tabs{
    .title-bar{
        background: linear-gradient(360deg, #FFE9D5 0%, #FFF7EC 100%);
        border-radius: vw(10) vw(10) 0px 0px;
        border: vw(2) solid rgba(255, 255, 255, 0.7);
        font-size: 0;
        margin-top: vw(26);
        li{
            width: 25%;
            height: vw(80);
            font-size: vw(24);
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #A84627;
            line-height: vw(80);
            display: inline-block;
            text-align: center;
            &.active{
                background: url("/static/img/channel/tab-bottom-line.png") no-repeat;
                background-size: vw(150);
                background-position: center bottom;
                font-size: vw(26);
                font-weight: bold;
            }
        }
    }
    .content{
        background: #fff;
        padding: vw(20);
        border-radius: 0 0 10px 10px;
    }
}
</style>