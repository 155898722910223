const brokerIntroduction = () => import('@/views/shopEcology/brokerIntroduction');
const brokerServices = () => import('@/views/shopEcology/brokerServices');
const transferList = () => import('@/views/shopEcology/my/transfer-list');
const siteSelectionList = () => import('@/views/shopEcology/my/site-selection-list');
const transferDetails = () => import('@/views/shopEcology/my/transfer-details');
const shopTransfer = () => import('@/views/shopEcology/shopTransfer');
const spreadList = () => import('@/views/shopEcology/my/spread-list');
// const myList = () => import('@/views/shopEcology/my/myList');
const myList = () => import('@/views/shopEcology/my/new-my-list');
const unlockList = () => import('@/views/shopEcology/my/unlock-list.vue');
const siteSelectionCase = () => import('@/views/shopEcology/case/siteSelectionCase');
const transferShops = () => import('@/views/shopEcology/case/transferShops');
const shopDetails = () => import('@/views/shopEcology/shopDetails');
const paySuccessful = () => import('@/views/shopEcology/pay/pay-successful');
const shopTransferSuccess = () => import('@/views/shopEcology/lease/shop-transfer-pay');
const orderPayment = () => import('@/views/shopEcology/pay/order-payment');
const intentionsubmitSuccessful = () => import('@/views/shopEcology/intentionsubmitSuccessful');
const examplesPage = () => import('@/views/shopEcology/examples-page');
const leaseCreateOrder = () => import('@/views/shopEcology/lease/create-order/index.vue');
const agreement = () => import('@/views/shopEcology/lease/agreement/index.vue');

const leaseOrderDetails = () => import('@/views/shopEcology/lease/order-details/index.vue');
const intentionSubmit = () => import('@/views/shopEcology/intentionSubmit');
const multiChoiceFormats = () => import('@/views/shopEcology/intentionSubmit/multiChoiceFormats');
const multiChoiceIndustries = () => import('@/views/shopEcology/intentionSubmit/multiChoiceIndustries');

const shopEcologyReport = () => import('@/views/shopEcology/shopDetails/report/detail')

export default [{
    path: '/shopEcology/brokerServices',
    name: 'brokerServices', //经纪人选址服务
    component: brokerServices,
    meta: {
      id: 10228
    },
  }, {
    path: '/shopEcology/shopTransfer',
    name: 'shopTransfer', //商铺转让服务
    component: shopTransfer,
    meta: {
      id: 10231
    },
  },
  {
    path: '/shopEcology/brokerIntroduction',
    name: 'brokerIntroduction', //经纪人主页
    component: brokerIntroduction,
    meta: {
      id: 10233
    },
  },
  {
    path: '/shopEcology/transferList',
    name: 'transferList', //我的铺转让意向列表
    component: transferList,
    meta: {
      id: 10232
    },
  },
  {
    path: '/shopEcology/transferDetails',
    name: 'transferDetails', //意向详情
    component: transferDetails,
  },
  {
    path: '/shopEcology/siteSelectionList',
    name: 'siteSelectionList', //我的选址意向列表
    component: siteSelectionList,
    meta: {
      id: 10230
    },
  },

	// {
	// 	path: '/shopEcology/spreadList',
	// 	name: 'spreadList', //收藏的铺列表
	// 	component: spreadList,
	// 	meta: {
	// 		id: 10242
	// 	},
	// },
	// {
	// 	path: '/shopEcology/myList',
	// 	name: 'myList', //我的铺列表
	// 	component: myList,
	// 	meta: {
	// 		id: 10244
	// 	},
	// },
	// {
	// 	path: '/shopEcology/siteSelectionCase',
	// 	name: 'siteSelectionCase', //选址案例
	// 	component: siteSelectionCase,
	// 	// meta     : { id: 10217 },
	// },
	// {
	// 	path: '/shopEcology/transferShops',
	// 	name: 'transferShops', //商铺转让案例
	// 	component: transferShops,
	// 	// meta     : { id: 10217 },
	// },
	// {
	// 	path: '/shopEcology/shopDetails',
	// 	name: 'shopDetails', //店铺详情
	// 	component: shopDetails,
	// 	meta: {
	// 		id: 10238
	// 	},
	// },
	// {
	// 	path: '/shopEcology/paySuccessful',
	// 	name: 'paySuccessful', //支付成功
	// 	component: paySuccessful,
	// 	meta: {
	// 		id: 10240
	// 	},
	// },
	{
		path: '/shopEcology/shopTransferSuccess',
		name: 'shopTransferSuccess', //支付成功
		component: shopTransferSuccess,
		meta: {
			id: 10240
		},
	},
	{
		path: '/shopEcology/agreement',
		name: 'agreement', //支付成功
		component: agreement,
		meta: {
			id: 10249
		},
	},
	// {
	// 	path: '/shopEcology/orderPayment',
	// 	name: 'orderPayment', //支付页面
  //       component: orderPayment,
	// 	meta: {
	// 		id: 10239
	// 	},
	// },
	// {
	// 	path: '/shopEcology/intentionsubmitSuccessful',
	// 	name: 'intentionsubmitSuccessful', //意向提交成功
	// 	component: intentionsubmitSuccessful,
	// 	meta: {
	// 		id: 10240
	// 	},
	// },
	// {
	// 	path: '/shopEcology/unlockList',
	// 	name: 'unlockList', //解锁铺列表
	// 	component: unlockList,
	// 	meta: {
	// 		id: 10243
	// 	},
	// },
	// {
	// 	path: '/shopEcology/examplesPage',
	// 	name: 'examplesPage', //示例数据页面
	// 	component: examplesPage,
	// },
	{
		path: '/shopEcology/lease/create-order',
		name: 'leaseCreateOrder', //示例数据页面
		component: leaseCreateOrder,
		meta: {
			id: 10248
		},
	},
	{
		path: '/shopEcology/lease/order-details',
		name: 'leaseOrderDetails', //示例数据页面
		component: leaseOrderDetails,
		meta: {
			id: 10250
		},
	},

  {
    path: '/shopEcology/spreadList',
    name: 'spreadList', //收藏的铺列表
    component: spreadList,
    meta: {
      id: 10242
    },
  },
  {
    path: '/shopEcology/myList',
    name: 'myList', //我的铺列表
    component: myList,
    meta: {
      id: 10244
    },
  },
  {
    path: '/shopEcology/siteSelectionCase',
    name: 'siteSelectionCase', //选址案例
    component: siteSelectionCase,
    // meta     : { id: 10217 },
  },
  {
    path: '/shopEcology/transferShops',
    name: 'transferShops', //商铺转让案例
    component: transferShops,
    // meta     : { id: 10217 },
  },
  {
    path: '/shopEcology/shopDetails',
    name: 'shopDetails', //店铺详情
    component: shopDetails,
    meta: {
      id: 10238
    },
  },
  {
    path: '/shopEcology/paySuccessful',
    name: 'paySuccessful', //支付成功
    component: paySuccessful,
    meta: {
      id: 10240
    },
  },
  {
    path: '/shopEcology/orderPayment',
    name: 'orderPayment', //支付页面
    component: orderPayment,
    meta: {
      id: 10239
    },
  },
  {
    path: '/shopEcology/intentionsubmitSuccessful',
    name: 'intentionsubmitSuccessful', //意向提交成功
    component: intentionsubmitSuccessful,
    meta: {
      id: 10240
    },
  },
  {
    path: '/shopEcology/unlockList',
    name: 'unlockList', //解锁铺列表
    component: unlockList,
    meta: {
      id: 10243
    },
  },
  {
    path: '/shopEcology/examplesPage',
    name: 'examplesPage', //示例数据页面
    component: examplesPage,
  },
  {
    path: '/shopEcology/intentionSubmit',
    name: 'intentionSubmit', //选址意向提交页面
    component: intentionSubmit,
    meta: {
      id: 10251
    },
  },
  {
    path: '/shopEcology/multiChoiceIndustries',
    name: 'multiChoiceIndustries', //经营行业多选页
    component: multiChoiceIndustries,
    meta: {
      id: 10253
    },
  },
  {
    path: '/shopEcology/multiChoiceFormats',
    name: 'multiChoiceFormats', //商铺类型多选页
    component: multiChoiceFormats,
    meta: {
      id: 10254
    },
  },
  {
    path: '/shopEcology/report/:id',
    component: shopEcologyReport,
    meta: {
      id: 10327
    },
  }
]
