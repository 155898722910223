<template>
  <div class="soltFooter" :class="{ ios: isIOS, ipx: isIPX, ipmx: isIPMX }"  :style="{ backgroundColor: bgcolor }">
    <div class="soltFooter--view l-flex l-flex__c">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name : 'soltFooter',
  props: {
    bgcolor: {
      type   : String,
      default: 'white',
    },
  },
  data () {
    return {
      isIOS: false,
      isIPX: false,
      isIPMX: false
    };
  },
  beforeMount () {
    if ( window.isClient && ! window.isAndroid ) {
      this.isIOS = window.isIOS;
      this.isIPX = window.isIPX;
      this.isIPMX = window.isIPMX
    }
  },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.soltFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $footerHeight;
  z-index: 2;

  &.ipx {
    height: calc( #{$footerHeight} + #{$iPXBottomBar} );
  }
  &.ipmx {
    height: calc( #{$footerHeight} + #{$iPXBottomBar} );
  }

  &--view {
    height: $footerHeight;
  }
}
</style>
