var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"percent-component"},[(_vm.legendOpt.tb=='top')?_c('div',{staticClass:"legend-container",style:({marginBottom: _vm.legendOpt.margin + 'px'})},[_c('div',{staticClass:"legend-panel",style:({float: _vm.legendOpt.lr})},_vm._l((_vm.legendItems),function(item,index){return _c('div',{key:index,staticClass:"legend-item",style:({margin: index==0?'0':'0 0 0 12px'})},[_c('div',{staticClass:"legend-box-panel"},[_c('div',{staticClass:"legend-box",style:({
                        background: _vm.getColor(item),
                        border: _vm.getBorder(item)
                    })})]),_c('div',{staticClass:"legend-name"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),_c('div',{staticClass:"percent-container"},[(_vm.nameOpt.content)?_c('div',{staticClass:"percent-name",style:({
            color: _vm.nameOpt.color,
            fontSize:_vm.nameOpt.fontSize + 'px',
            fontWeight: _vm.nameOpt.fontWeight,
            height: _vm.barOpt.realHeight + 'px'})},[_vm._v(" "+_vm._s(_vm.nameOpt.content)+" ")]):_vm._e(),(_vm.itemList.length == 0)?_c('div',{staticClass:"percent-chart"},[_c('div',{staticClass:"percent-item",style:({
                background: 'rgba(142, 144, 153, 0.4)',
                borderRadius: _vm.barOpt.radius + 'px',
                width: '100%',
                height: _vm.barOpt.realHeight + 'px',
            })},[_c('div',{staticClass:"percent-value",style:({
                    fontSize:_vm.barOpt.fontSize + 'px',
                    height: _vm.barOpt.realHeight + 'px'
                })},[_vm._v(_vm._s(_vm.noDataTitle))])])]):_c('div',{staticClass:"percent-chart"},_vm._l((_vm.itemList),function(item,index){return _c('div',{key:index,staticClass:"percent-item",style:({
                background: _vm.getColor(item),
                border: _vm.getBorder(item),
                borderRadius: _vm.barOpt.radius + 'px',
                width: item.widthStr,
                height: _vm.barOpt.height + 'px',
                zIndex: item.zIndex,
                margin: item.margin
            })},[_c('div',{staticClass:"percent-value",style:({fontSize: _vm.barOpt.fontSize + 'px',margin: item.fontMargin, height: _vm.barOpt.realHeight + 'px'})},[_vm._v(" "+_vm._s(item.valueStr)+" ")])])}),0)]),(_vm.legendOpt.tb=='bottom')?_c('div',{staticClass:"legend-container",style:({marginTop: _vm.legendOpt.margin + 'px'})},[_c('div',{staticClass:"legend-panel",style:({float: _vm.legendOpt.lr})},_vm._l((_vm.legendItems),function(item,index){return _c('div',{key:index,staticClass:"legend-item",style:({margin: index==_vm.legendItems.length-1?'0':'0 12px 0 0'})},[_c('div',{staticClass:"legend-box-panel"},[_c('div',{staticClass:"legend-box",style:({
                        background: _vm.getColor(item),
                        border: _vm.getBorder(item),
                    })})]),_c('div',{staticClass:"legend-name"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }