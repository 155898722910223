var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.signData),function(item){return _c('div',{key:item.key,staticClass:"coupon-wrap"},[_c('div',{class:{
        common: item.goodsRespList,
        'common-card1': item.goodsRespList && item.goodsRespList.length == 1 && item.status == 0,
        'common-card-has1': item.goodsRespList && item.goodsRespList.length == 1 && item.status == 1,
        'common-card2': item.goodsRespList && item.goodsRespList.length == 2 && item.status == 0,
        'common-card-has2': item.goodsRespList && item.goodsRespList.length == 2 && item.status == 1,
        'common-card3': item.goodsRespList && item.goodsRespList.length == 3 && item.status == 0,
        'common-card-has3': item.goodsRespList && item.goodsRespList.length == 3 && item.status == 1,
        'common-card4': item.goodsRespList && item.goodsRespList.length == 4 && item.status == 0,
        'common-card-has4': item.goodsRespList && item.goodsRespList.length == 4 && item.status == 1,
      }},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_vm._l((item.goodsRespList),function(card){return _c('ul',{key:card.key,staticClass:"ul-flex"},[_c('li',{staticClass:"li-left"},[_vm._v(_vm._s(card.goodsName))]),_c('li',{staticClass:"li-mid"},[_c('p',{staticClass:"discount"},[_vm._v(_vm._s(card.couponDesc))]),(card.discountDesc)?_c('p',{staticClass:"auto"},[_vm._v(_vm._s(card.discountDesc))]):_vm._e()]),_c('li',{staticClass:"li-right"})])}),_c('div',{staticClass:"float-mid-right"},[_vm._v(" 立省 "),_c('p',{staticClass:"coupon-btn"},[_c('strong',[_vm._v(_vm._s(item.desc))]),_vm._v(" 元 ")]),(item.status == 1)?_c('p',{staticClass:"coupon-btn",on:{"click":function($event){return _vm.swRouter('/coupons?keepAlive=1')}}},[_c('span',[_vm._v("去查看")])]):_vm._e()]),_c('div',{staticClass:"float-top-right"},[_vm._v(_vm._s(item.tips))])],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }