<template>
  <div class="feedback">
    <StateBar />
    <TitleHeader title="投诉" bgcolor="transparent" :back="showBack"></TitleHeader>

    <AutoView :footer="true" class="feedback--view">
      <div class="l-panel s-radius__s">
        <label require class="s-label help_text">请选择投诉的原因</label>
        <div class="pro_desc">
          <span class="tag-wrap" v-for="(item, index) in defaultMessage" :key="item">
            <van-tag round plain size="large" :color="computedColor(index)" @click="idx = index">{{ item }}</van-tag>
          </span>
        </div>
        <van-field
          v-model="message"
          class="s-text s-radius__s"
          type="textarea"
          maxlength="200"
          placeholder="请你描述问题详情，以便于上上参谋帮你核实情况！"
          show-word-limit
          :disabled="submitting"
        />
      </div>
      <p class="bottom-tips">为了核实你的投诉，在你提交投诉后上上参谋将会获取该聊天窗口的最近100条信息进行审查！</p>
    </AutoView>
    <SoltFooter>
      <div class="feedback--footer">
        <van-button type="info" block class="s-radius__s" @click="submit" :disabled="submitting" :loading="submitting">立即提交</van-button>
      </div>
    </SoltFooter>

    <van-dialog v-model="showSuccess" :showConfirmButton="false">
      <div class="wrap">
        <img class="wrap-img" src="/static/icon/msg_success_green.png" />
        <p class="success-icon">提交成功</p>
        <p class="success-tips">您的投诉反馈已提交，我们将马上为您处理！</p>
        <van-button type="info" block class="s-radius__s" @click="confirm">确定</van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "feedback",
  data() {
    return {
      showBack: true,
      defaultMessage: ["发布不当内容造成骚扰", "存在侵权行为", "存在欺诈骗钱行为", "其他"],
      idx: 0, // 默认选中下标
      message: "",
      submitting: false, // 提交中
      showSuccess: false,
    };
  },
  methods: {
    computedColor(index) {
      if (this.idx == index) {
        return "#518CDB";
      } else {
        return "#666";
      }
    },
    confirm() {
      this.showSuccess = false;
      setTimeout(() => {
        this.message = "";
        this.submitting = false;
      }, 800);
    },
    submit() {
      const message = `${this.defaultMessage[this.idx]}-${this.message}`;
      this.submitting = true;
      this.HTTP.createFeedback(message)
        .then((res) => {
          this.showSuccess = true;
        })
        .catch((e) => {
          this.$toast("网络不佳T.T请稍候重试");
          setTimeout(() => {
            this.submitting = false;
          }, 2000);
        });
    },
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.feedback {
  width: 100%;
  padding-bottom: 15.4vw;
  height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
  #{&}--view {
    padding: 4vw;
    .l-panel {
      padding: 3.73334vw 4vw;
    }
    .s-text {
      background: #f7f7f7;
      border-radius: 1.06667vw;
      padding: 2.67vw;
    }
    .s-text ::v-deep .van-field__control {
      height: 2vw;
      font-size: $font_373;
      line-height: 5.33334vw;
      &::-webkit-input-placeholder {
        font-size: $font_373;
        line-height: 5.33334vw;
      }
    }
    .help_text {
      font-size: 4.267vw;
      color: #333300;
      font-weight: bold;
      line-height: 6vw;
      margin-bottom: 2.13334vw;
    }
    .help_text::before {
      line-height: 6vw;
      color: #ff5900;
    }
  }
  .bottom-tips {
    font-size: $font_293;
    color: #bdbdbd;
    line-height: 4.8vw;
    margin-top: 4vw;
  }
  .tag-wrap {
    display: inline-block;
    margin: 0 3.2vw 2.13vw 0;
  }

  #{&}--footer {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    box-shadow: 0 -1px 0 #ebeaee;
    ::v-deep .van-button {
      height: 100%;
      padding: 0;
      font-size: $font_400;
      line-height: normal;
    }
  }

  .wrap {
    padding: 4.8vw;
    text-align: center;
    .wrap-img {
      width: 21.33vw;
      height: 21.33vw;
      padding: 4vw 0;
    }
    .success-icon {
      font-size: 4.27vw;
      color: #333;
      font-weight: bold;
    }
    .success-tips {
      font-size: 3.73vw;
      color: #8e9099;
      margin: 2.13vw 0 3.73vw 0;
    }
    ::v-deep .van-button {
      height: 12vw;
      padding: 0;
      font-size: $font_400;
      line-height: normal;
    }
  }
}
</style>
