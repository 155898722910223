/**
 * 数据查询页面
 */

const searchPoi = () => import(/* webpackChunkName: "module-data-query" */ '@/views/dataQuery/search-poi.vue')
const selectCity = () => import(/* webpackChunkName: "module-data-query" */ '@/views/dataQuery/select-city.vue')
export default [
  {
    path     : '/dataQuery/searchPoi',
    component: searchPoi,
    meta: { keepAlive: true, id: 10590 }
  },
  {
    path     : '/dataQuery/selectCity',
    component: selectCity,
    meta: { id: 10591 }
  }
]
