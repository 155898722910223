<template>
	<div>
		<StateBar />
		<TitleHeader title="商铺转让服务" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
			<div slot="right" class="right-title" @click="goList">我的意向</div>
		</TitleHeader>
		<AutoView :footer="true">
			<div class="brokerServices">
				<div class="background"></div>
				<vear-carousel @goDetails="goDetails"></vear-carousel>
				<div class="body">
					<div class="first-box">
						<PlayVideo :poster='poster' :src="src" @onceClick="videoOnceClick" />
					</div>
					<div class="title-box">
						<div class="big-title">商铺转让全流程服务</div>
						<div class="small-title">转让加速度</div>
					</div>
					<div class="secound-box">
						<div class="icon-box"></div>
					</div>
					<div class="title-box">
						<div class="big-title">铺参谋1对1服务</div>
						<div class="small-title">选址诉求定制化</div>
					</div>
					<div class="third-box">
					</div>
					<div class="title-box">
						<div class="big-title">精准客户推荐</div>
						<div class="small-title">合适商铺匹配适合的店主</div>
					</div>
					<div class="fifth-box">
						<div class="mall-item2" v-for="(item,key) in arr1" :key="key">
							<div class="left-content">
								<img :src="item.img" alt="">
							</div>
							<div class="right-content">
								<div class="title">{{item.name}}</div>
								<div class="small-title">{{item.address}}</div>
								<div class="small-title1">{{item.type}}</div>
								<div class="bottom-box">
									<div class="bottom-box-title">意向投资金额 </div>
									<div class="bottom-box-content">{{item.money}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="title-box">
						<div class="big-title">商铺曝光</div>
						<div class="small-title">百万创业者都在看</div>
					</div>
					<div class="map-img-box">
						<img class="mapImg" src="../../../../static/shopEcology/transferShops3.png" alt="">
					</div>
					<div class="title-box">
						<div class="big-title">商铺评估分析</div>
						<div class="small-title">交付内容</div>
					</div>
					<div class="map-img-box">
						<img class="mapImg" src="../../../../static/shopEcology/transferShops4.png" alt="">
					</div>
					<div class="title-box">
						<div class="big-title">商铺转让案例</div>
						<div class="small-title">精准对接客户</div>
					</div>
					<div class="fourth-box">
						<div class="mall-box">
							<div class="mall-item1">
								<img src="../../../../static/shopEcology/p11.png" alt="">
								<div class="right-content">
									<div class="title">餐饮·火锅店</div>
									<div class="small-title">深圳·宝安·大仟里购物中心</div>
									<div class="speed">
										<div class="speed-title">平均客流</div>
										<div class="speed-content"> 1~2千人/时</div>
									</div>
									<div class="bottom-box">
										<div class="bottom-left">出租</div>
										<div class="bottom-right">
											<div class="type">￥</div>
											<div class="type-right">4万/月</div>
										</div>
									</div>
								</div>
							</div>
							<div class="total-box">
								<img src="../../../../static/shopEcology/transferShops6.png" alt="">
							</div>
							<div class="mall-item2">
								<div class="left-content">
									<img src="../../../../static/shopEcology/p1.png" alt="">
								</div>
								<div class="right-content">
									<div class="title">零售创业者·王女士</div>
									<div class="small-title">意向位置 深圳宝安区</div>
									<div class="small-title1">意向大小 80㎡</div>
									<div class="bottom-box">
										<div class="bottom-box-title">意向投资金额 </div>
										<div class="bottom-box-content">80万</div>
									</div>
								</div>
							</div>
							<div class="mall-item3">
								<div class="top-box">
									<img src="../../../../static/shopEcology/p12.png" alt="">
									<div class="right-content cell-1">
										王牌铺参谋-卢炟
									</div>
								</div>
								<div class="content">
									王先生从美国归来，非常喜欢西餐品类，对于西餐有独到的见解。王先生预算比较充足，对于品质和格调要求较高。我们为其匹配了深圳南山区万象城的商圈，符合王先生的诉求。并且位于万象城的这家商铺面积有320㎡，位于1层出入口，人流充沛。王先生对于这个铺面也非常满意。
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</AutoView>
		<div class="button-bottom-box">
			<div class="right-box" @click="submitMaskClick">
				提交转让意向
			</div>
		</div>
		<!-- 转让意向 -->
		<transfer-intention-mask v-if="transferIntentionMaskType" @submit="transferIntentionSubmit"
			@close="transferIntentionMaskType=false"></transfer-intention-mask>
	</div>
</template>

<script>
	import PlayVideo from '../components/VideoPlay'
	import transferIntentionMask from "../components/transfer-intention-mask"
	import vearCarousel from "../brokerServices/components/vear-carousel"
	import {
		saveSitingIntention
	} from "@/request/shopEcology"
	export default {
		data() {
			return {
				iconArr: [{
					icon: '',
					title: '商铺参谋1对1服务'
				}, {
					icon: '',
					title: '精准客户推荐'
				}, {
					icon: '',
					title: '商铺曝光'
				}, {
					icon: '',
					title: '商铺评估分析'
				}],
				transferIntentionMaskType: false,
				arr: [{
					img: require('../../../../static/shopEcology/p1.png'),
					name: '零售创业者·王女士',
					address: '意向位置 深圳宝安区',
					type: '意向大小 80㎡',
					money: '80万'
				}, {
					img: require('../../../../static/shopEcology/p2.png'),
					name: '丽人创业者·李先生',
					address: '意向位置 深圳宝安区',
					type: '意向大小 80㎡',
					money: '120万'
				}, {
					img: require('../../../../static/shopEcology/p3.png'),
					name: '教育培训创业·李先生',
					address: '意向位置 深圳龙岗区',
					type: '意向大小 180㎡',
					money: '320万'
				}, {
					img: require('../../../../static/shopEcology/p4.png'),
					name: '餐饮创业者·李女士',
					address: '意向位置 深圳南山区',
					type: '意向大小 180㎡',
					money: '140万'
				}, {
					img: require('../../../../static/shopEcology/p5.png'),
					name: '酒店创业者·谭先生',
					address: '意向位置 深圳福田区',
					type: '意向大小 500㎡',
					money: '300万'
				}, {
					img: require('../../../../static/shopEcology/p6.png'),
					name: '足浴店创业者·黄女士',
					address: '意向位置 深圳福田区',
					type: '意向大小 200㎡',
					money: '120万'
				}, {
					img: require('../../../../static/shopEcology/p7.png'),
					name: '医疗创业者·姜先生',
					address: '意向位置 深圳宝安区',
					type: '意向大小 50㎡',
					money: '30万'
				}, {
					img: require('../../../../static/shopEcology/p8.png'),
					name: '丽人创业者·宋先生',
					address: '意向位置 深圳福田区',
					type: '意向大小 30㎡',
					money: '10万'
				}, {
					img: require('../../../../static/shopEcology/p9.png'),
					name: '健身创业者·曾先生',
					address: '意向位置 深圳南山区',
					type: '意向大小 180㎡',
					money: '50万'
				}, {
					img: require('../../../../static/shopEcology/p10.png'),
					name: '餐饮创业者·蒋先生',
					address: '意向位置 深圳宝安区',
					type: '意向大小 100㎡',
					money: '50万'
				}],
				arr1: [],
				isSubmit: false,
				poster:'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/wuzhuzhuanrangjieshao.mp4?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600',
				src:'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/wuzhuzhuanrangjieshao.mp4'
			};
		},
		mounted() {
			let newArr = []
			for (var i = 0; i < 9999; i++) {
				var index = Math.floor(Math.random() * 10);
				if (newArr.indexOf(index) === -1) {
					newArr.push(index)
					this.arr1.push(this.arr[index])
				}
				if (newArr.length === 3) {
					return
				}
			}
		},
		methods: {
			goDetails(item) {
				// 点击个人主页
				this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 133800, 133801);
				this.swRouter({
					path: '/shopEcology/brokerIntroduction',
					query: {
						id: item.userId
					}
				})
			},
			videoOnceClick() {
				// 视频播放埋点
				this.JSBridge.BTNCLICK(this.$route.meta.id, '', 133700, 133701);
			},
			submitMaskClick() {
				//帮我转让铺
				this.transferIntentionMaskType = true
				this.JSBridge.BTNCLICK(this.$route.meta.id, null, 133900, 133901);
			},
			transferIntentionSubmit(form) {
				//转让意向
				if (this.isSubmit) return;
				this.isSubmit = true;
				saveSitingIntention(form).then(res => {
					this.transferIntentionMaskType = false
					this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134000, 134001);
					this.swRouter({
						path: '/shopEcology/intentionsubmitSuccessful',
						query: {
							id: res
						}
					})
				}).finally(e => {
						this.isSubmit = false;
				});
			},
			goList() {
				//按钮点击_我的意向
				this.JSBridge.BTNCLICK(this.$route.meta.id, '', 134100, 134101);
				this.swRouter({
					path: '/shopEcology/transferList'
				})
			}
		},
		components: {
			PlayVideo,
			transferIntentionMask,
			vearCarousel
		}
	};
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.right-title {
		font-size: vw(28);
		color: #347FFF;
	}

	.brokerServices {
		padding: 0 vw(30);
		background: linear-gradient(180deg, #157CF9 0%, #C7E0FE 41%, #FFFFFF 100%);
		background-size: 100% 100%;
		box-sizing: border-box;


		.background {
			width: 100%;
			height: vw(518);
			background-image: url('../../../../static/shopEcology/transferShops1.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			position: absolute;
			left: 0%;
			top: 0;
			z-index: 0;
		}

		.body {
			position: relative;
			top: vw(290);
			left: 0;
			width: 100%;
			box-sizing: border-box;
			background: #FFFFFF;
			border-radius: vw(16);

			.title-box {
				text-align: center;

				.big-title {
					width: vw(560);
					height: vw(64);
					background: linear-gradient(90deg, #FFFFFF 0%, #DFF1FF 48%, #FFFFFF 100%);
					font-size: vw(40);
					font-weight: 600;
					color: #2F2F36;
					line-height: vw(64);
					margin: 0 auto;
				}

				.small-title {
					margin-top: vw(16);
					height: vw(44);
					font-size: vw(32);
					font-weight: 600;
					color: #9F9FAE;
					line-height: vw(44);
				}
			}

			.first-box {
				padding: vw(48) vw(36);
				box-sizing: border-box;
			}

			.secound-box {
				padding: vw(48) vw(30);
				box-sizing: border-box;

				.icon-box {
					height: vw(412);
					background: url('../../../../static/shopEcology/agent2-1.png') no-repeat;
					background-size: 100% 100%;
				}
			}

			.third-box {
				height: vw(950);
			}

			.fourth-box {
				padding: vw(48) vw(30) 0;
				box-sizing: border-box;

				.mall-box {
					.mall-item1 {
						display: flex;
						box-sizing: border-box;
						border-radius: vw(12);

						img {
							width: vw(246);
							height: vw(214);
							background: #FFFFFF;
							border-radius: vw(4);
						}

						.right-content {
							padding-left: vw(28);
							box-sizing: border-box;

							.title {
								height: vw(44);
								font-size: vw(32);
								font-weight: 600;
								line-height: vw(44);
								text-align: left;
								color: #2F2F36;
							}

							.small-title {
								margin-top: vw(8);
								height: vw(40);
								font-size: vw(28);
								line-height: vw(40);
								font-weight: 600;
								color: #2F2F36;
							}

							.speed {
								margin-top: vw(8);
								display: flex;
								height: vw(40);
								font-size: vw(28);
								line-height: vw(40);
								color: #666666;

								.speed-title {
									color: #666666;
									margin-right: vw(20);
								}

								.speed-content {
									color: #2F2F36;
								}
							}

							.bottom-box {
								display: flex;
								margin-top: vw(16);
								width: vw(242);
								height: vw(52);
								border-radius: vw(4);
								border: vw(1) solid #FF3636;

								.bottom-left {
									width: vw(84);
									height: vw(52);
									background: #FF3636;
									height: vw(52);
									font-size: vw(28);
									color: #FFFFFF;
									line-height: vw(52);
									box-sizing: border-box;
									text-align: center;
									background: #FF3636;
								}

								.bottom-right {
									flex: 1;
									display: flex;
									justify-content: center;
									font-size: vw(28);
									height: vw(52);
									line-height: vw(52);
									color: #FF3636;

									.type {
										font-size: vw(28);
									}
								}
							}
						}
					}

					.total-box {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: vw(24);

						img {
							width: vw(84);
							height: vw(84);
						}
					}

					.mall-item3 {
						padding: vw(34) vw(30);
						box-sizing: border-box;
						background: #EFEFEF;
						border-radius: vw(12);
						margin-top: vw(55);
						text-align: left;
						background: url('../../../../static/shopEcology/transferShops2.png') no-repeat;
						background-size: 100% 100%;

						.top-box {
							display: flex;

							img {
								width: vw(80);
								height: vw(80);
								border-radius: 50%;
								background: #E3E3E3;
							}

							.right-content {
								height: vw(44);
								font-size: vw(32);
								font-weight: 600;
								color: #2F2F36;
								line-height: vw(44);
								padding-left: vw(28);
								box-sizing: border-box;
							}
						}

						.content {
							margin-top: vw(30);
							font-size: vw(28);
							color: #2F2F37;
							line-height: vw(40);
						}

					}
				}
			}

			.map-img-box {
				padding: vw(36) vw(44) vw(40);
				box-sizing: border-box;
				// background: green;

				.mapImg {
					width: 100%;
				}
			}

			.mall-item2 {
				display: flex;
				padding: vw(32) vw(40);
				box-sizing: border-box;
				margin-bottom: vw(32);
				background: url('../../../../static/shopEcology/transferShops2.png') no-repeat;
				background-size: 100% 100%;

				.left-content {
					display: flex;
					align-items: center;

					img {
						width: vw(112);
						height: vw(112);
						background: #FFFFFF;
						border-radius: 50%;
					}
				}


				.right-content {
					padding-left: vw(28);
					box-sizing: border-box;

					.title {
						height: vw(44);
						font-size: vw(32);
						font-weight: 600;
						line-height: vw(44);
						text-align: left;
						color: #2F2F36;
					}

					.small-title {
						margin-top: vw(8);
						font-size: vw(28);
						height: vw(40);
						line-height: vw(40);
						text-align: left;
						font-weight: 600;
						color: #2F2F36;
					}

					.small-title1 {
						margin-top: vw(4);
						font-size: vw(28);
						height: vw(40);
						line-height: vw(40);
						text-align: left;
						font-weight: 600;
						color: #666666;
					}

					.bottom-box {
						display: flex;
						height: vw(40);
						line-height: vw(40);
						font-size: vw(28);
						color: #666666;
						margin-top: vw(4);

						.bottom-box-title {
							color: #2F2F36;
							text-align: left;
						}

						.bottom-box-content {
							color: #FD4142;
							margin-left: vw(10);
						}
					}
				}
			}

			.fifth-box {
				padding: vw(40) vw(30) vw(26);
				box-sizing: border-box;

			}
		}


	}

	.button-bottom-box {
		width: 100%;
		padding: vw(16) vw(30) vw(20);
		box-sizing: border-box;
		background: #fff;
		z-index: 300;
		box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);

		.right-box {
			background: #347FFF;
			border-radius: vw(10);
			height: vw(80);
			font-size: vw(32);
			font-weight: 600;
			color: #FFFFFF;
			line-height: vw(80);
			text-align: center;
		}
	}

	.body-box {
		padding: vw(30) vw(20) 0;
		box-sizing: border-box;

		.title {
			height: vw(88);
			font-size: vw(28);
			font-weight: 600;
			color: #242424;
			line-height: vw(88);
			text-align: center;
		}

		.mask-button {
			position: absolute;
			bottom: 0%;
			left: 0;
			width: 100%;
			padding: vw(16) vw(20);
			box-sizing: border-box;
		}
	}
</style>
