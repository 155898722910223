<template>
  <div class="information">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>
<!--    <div class="top_flex" v-if="list && list.length > 0">-->
<!--      <van-image-->
<!--        :src="topInfo.icon"-->
<!--        lazy-load-->
<!--        round-->
<!--        fit="cover"-->
<!--        class="top_img">-->
<!--        <template v-slot:loading>-->
<!--            <van-loading type="spinner" size="20" />-->
<!--        </template>-->
<!--      </van-image>-->
<!--      <div class="top_text">-->
<!--        <h3>{{topInfo.name}}</h3>-->
<!--        <p>{{topInfo.desc}}</p>-->
<!--      </div>-->
<!--    </div>-->

    <StackView ref="view" class="information--view" :class="{ androidPosition: isAndroidOne, iosPosition: isIOSOne, ipxPosition: isIPXOne }">
      <div class="view_list">
        <van-skeleton :loading="!list || firstLoading" :animate="false" :row="3" class="s-skeleton s-radius__xs">
          <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多数据了" @load="loadData">
            <MsgCard v-for="(item, index) in list" :item="item" :key="item.key" :msgType="msgType" :isLine="index != list.length - 1" @onTap="onTap(item)" />
          </van-list>
        </van-skeleton>
      </div>
    </StackView>
  </div>
</template>
<script>
import MsgCard from "./components/msgCard.vue";

export default {
  components: { MsgCard },
  data() {
    return {
      firstLoading: true,
      loading: true,
      current: 1,
      finished: false,
      title: "",
      list: [],
      listType: {
        "1": "系统通知",
        "3": "优惠福利",
        "4": "参谋学院",
        "5": "开店助手",
        "6": "精选品牌",
        "7": "最新资讯",
        "8": "合伙人",
        "9": "留言回复"
      },
      msgType: "",
      topInfo: {},


      isAndroidOne: false,
      isIOSOne    : false,
      isIPXOne    : false,
    };
  },
  created() {
    this.msgType = this.$route.params.id;
    if (!this.msgType || !["1", "3", "4", "5", "6", "7", "8", "9"].includes(this.msgType)) {
      this.$router.push("/");
      return;
    }
    this.title = this.listType[this.msgType];
    this.JSBridge.callHandler("getVersionCode", null, (code) => {
      window._appCode = ~~code;
      this.loadData();
    });
  },
  methods: {
    onTap(item) {
      let idx = this.list.findIndex(ele => {
        return ele.id == item.id;
      });
      this.HTTP.readUserMsg(item.id).then(res => {
        this.list[idx].status = 1;
      });
      this.JSBridge.callHandler("onNormalizingCall", item.link);
    },
    loadData() {
      let p = null
      if (window._appCode >= 316) {
        p = this.HTTP.listUserMsgInfoV316({
          size: 15,
          current: this.current,
          msgType: this.msgType
        })
      } else {
        p = this.HTTP.listUserMsgInfo({
          size: 15,
          current: this.current,
          type: this.msgType
        })
      }
      p.then(res => {
        this.current++;
        this.firstLoading = false;
        this.loading = false;

        if (res.data.data.length) {
          this.list = this.list.concat(res.data.data);
        }
        this.finished = res.data.data.length < 15;
        if(res.data.data && res.data.data.length > 0) {
          this.topInfo = res.data.data[0].messageTitle;
        }
        console.log(res);
      });
    }
  },
  beforeMount () {
    this.isAndroidOne = window.isClient && window.isAndroid;
    if ( window.isClient && ! window.isAndroid ) {
        this.isIOSOne = window.isIOS;
        this.isIPXOne = window.isIPX;
    }
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.information {
  height: 100vh;
  overflow: hidden;

  .setting {
    width: 6.4vw;
    height: 5.87vw;
  }
  .top_flex{
    width: 100%;
    padding: 4vw;
    box-sizing: border-box;
    background: #fff;
    border-top: 1px solid #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    .top_img{
      width: 12vw;
      height: 12vw;
    }
    .top_text{
      flex: 1;
      margin-left: 2.67vw;
      overflow:hidden;
      h3{
        font-size: 4.27vw;
        font-weight: bold;
        color: #333333;
        line-height: 6vw;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      p{
        font-size: 3.2vw;
        color: #999999;
        line-height: 4.4vw;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        margin-top: 2.13vw;
      }
    }
  }

  .view_list{
    padding: 4vw;
    box-sizing: border-box;
  }
  #{&}--view {
    scroll-behavior: smooth;
    background: #f7f7f7;
    // padding: 0 0 25vw 0;
    height: calc(100% - 19.2vw - 15.5vw);
  }
  .androidPosition{
    height: calc(100% - 15.5vw - 19.2vw - #{$androidStateBar}) !important;
  }
  .iosPosition{
    height: calc(100% - 15.5vw - 19.2vw - #{$iOSStateBar}) !important;
  }
  .ipxPosition{
    height: calc(100% - 15.5vw - 19.2vw - #{$iPXStateBar}) !important;
  }
  #{&}--main {
    width: 100%;
  }
}
</style>
