<template>
  <div class="page">
    <StateBar :bgcolor="bgColor" />
    <TitleHeader :title="shopName" :bgcolor="bgColor" color="black" :back="true"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="editable"
    >
      <div ref="top">
        <div class="swiper-wrap">
          <van-swipe
            :loop="false"
            :show-indicators="false"
            :width="shopPictureArr.length < 2 ? fullWidth : bannerWidth"
            v-if="shopPicture && shopPicture.length > 0"
          >
            <template
              v-for="(item, index) in shopPicture"
            >
              <template v-for="(v, i) in item.pic">
                <van-swipe-item :key="`${index}-${i}`"  v-if="item != null && item != 'null'">
                  <div class="banner-img-wrap"  v-if="v.split('.').pop() !== 'mp4'" @click="preview(v)">
                    <img :src="v"/>
                    <p>{{item.typeName}}</p>
                  </div>
                    <div class="banner-video-wrap" v-if="v.split('.').pop() === 'mp4'" @click="showMultiMedia(v)">
                        <img :src="`${v}?x-oss-process=video/snapshot,t_1000,f_jpg,h_300,m_fast`"/>
                        <img class="play-icon" src="/static/icon/video_icon.png" />
                        <p>{{item.typeName}}</p>
                    </div>
                </van-swipe-item>
              </template>
            </template>
          </van-swipe>
          <div class="title">
            <p class="price" v-if="monthRent && monthRent.length > 0">
              ¥<ins>{{monthRent}}</ins>/月
            </p>
            <p class="prices" v-else>暂无数据</p>
            <p class="date">{{modifyTime}}</p>
          </div>
          <p class="addr">{{address}}</p>
        </div>
        <!-- tabs -->
        <div class="tab-wrap">
        <van-tabs v-model:activeTab="activeTab" swipeable animated :offset-top="40">
          <template v-for="(item, index) in collectionShop">
            <van-tab
              :key="index"
              :title="item.tepName"
            >
              <card-list :shops="item.lv3List" v-if="!item.isEmpty"/>
              <div v-else class="empty">
                <img src="/static/img/me/surveyedShops/img-null.png" alt="">
                暂无数据
              </div>
            </van-tab>

          </template>
        </van-tabs>
      </div>
      </div>
    </AutoView>
    <!-- <SoltFooter v-if="editable"> -->
    <div class="submitFooter" v-if="editable">
        <van-button type="info" block class="s-radius__s" @click="modifyInformation">修改信息</van-button>
    </div>
    <!-- </SoltFooter> -->
    <multi-media-preview ref="medioPreView" :index="0" :visible.sync="multiMediaVisible"/>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import CardList from './components/cardList/index.vue';
import MultiMediaPreview from './components/multiMediaPreview/index.vue';

export default {
  name: 'index',
  components: {
    CardList,
      MultiMediaPreview,
  },
  data () {
    return {
        multiMediaVisible: false,
      activeTab: '0',
      fullWidth: window.screen.width -  window.screen.width * 0.12,
      bannerWidth: window.screen.width*0.62,
      shopId: '',
      collectionShop: [],
      address: '',
      modifyTime: '',
      monthRent: '',
      shopPicture: [],
      shopPictureArr: [], // 拉平的图片数组
      editable: false,
      shopName: '',
      bgColor: '#fff',
    }
  },
  mounted () {
    if (this.$route.query.editable === 'true' || this.$route.query.editable === true ) {
      this.editable = true;
    }
    this.shopId = this.$route.params.id;
    window.onresize = () => {
      this.bannerWidth = this.getBannerWidth();
    };
    this.shopCollectDatail()
    this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
  },
  methods: {
    isEmpty (collectionShopItem) {
      collectionShopItem.isEmpty = true;
      collectionShopItem.lv3List.forEach((item, index) => {
        if (!item.respList || item.tepName === '图片和视频') return;
        let arr = item.respList.some(v => {
          return v.keyValue !== '' && v.keyValue !== null;
        });
        if (arr) {
          collectionShopItem.isEmpty = false;
        }
      });
    },
    shopCollectDatail() {
      this.btnLoading = true;
      this.HTTP.shopCollectDatail({shopId: this.shopId})
      .then((res) => {
        this.shopName = res.shopName;
        this.collectionShop = res.shopResp || [];
        this.address = res.address;
        this.modifyTime = res.modifyTime;
        this.monthRent = res.monthRent || '';
        this.shopPicture = res.shopPic || [];
        this.shopPicture.forEach(item => {
          item.pic.forEach((v) => {
            this.shopPictureArr.push(v)
          })
        });
        this.btnLoading = false;
        this.collectionShop.forEach(v => this.isEmpty(v));
      })
      .catch((e) => {
        console.error('异常：', e);
        this.btnLoading = false;
        this.$toast("网络不稳定，请稍候重试。");
      });
    },
    modifyInformation () {
      this.JSBridge.callHandler('onNormalizingCall', {
        type: 5,
        url: 'SHOP_COLLECT_PAGE',
        id: this.shopId,
      });
    },
      showMultiMedia (url) {
        this.multiMediaVisible = true;
        this.$refs.medioPreView.init(url);
      },
    preview (url) {
        ImagePreview({
          images: [url],
          startPosition: 0
        });
        // this.multiMediaVisible = true;
      // let pictures = [];
      // let i = 0;
      // this.shopPicture.forEach(item => {
      //   item.pic.forEach((v) => {
      //     pictures.push(v)
      //     if (v === url) {
      //       i = pictures.length-1;
      //     }
      //   })
      // });
      // ImagePreview({
      //   images: pictures,
      //   startPosition: i
      // });
    },
    getBannerWidth () {
      return window.screen.width*0.62;
    },
  },
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import "@/styles/index.scss";

*{
  box-sizing: border-box;
}
.page{
  //height: 100vh;
  background: #F7F6F9;
  .page-view {
    padding: vw(27) vw(20);
  }
  .swiper-wrap{
    padding: vw(25);
    background: #fff;
    border-radius: vw(10);
    .van-swipe-item{
      padding-right: vw(15);
      .banner-img-wrap,.banner-video-wrap{
        position: relative;
        width: 100%;
        height: vw(338);
        overflow: hidden;
        background: #eee;
        img{
          width: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
        p{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          font-size: vw(22);
          line-height: vw(22);
          padding: vw(13) 0 vw(10) 0;
          text-align: center;
          background: rgba(0, 0, 0, 0.41);
          color: #FFFFFF;
        }
      }
        .banner-video-wrap{
            position: relative;
            .play-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: vw(80);
            }
        }
    }
    .van-swipe{
      height: vw(338)
    }
    //.van-swipe-item{
    //  p{
    //    position: absolute;
    //    left: 0;
    //    right: vw(15);
    //    bottom: 0;
    //    font-size: vw(22);
    //    line-height: vw(22);
    //    padding: vw(13) 0 vw(10) 0;
    //    text-align: center;
    //    background: rgba(0, 0, 0, 0.41);
    //    color: #FFFFFF;
    //  }
    //}
    .title{
      display: table;
      width: 100%;
      margin-top: vw(20);
      .price{
        width: 50%;
        display: table-cell;
        font-size: vw(30);
        font-weight: bold;
        color: #FF6900;
        line-height: vw(35);
        letter-spacing: 1px;
        height: vw(51);
        padding: vw(8) 0;
        ins{
          font-size: vw(44);
          text-decoration: none;
          font-style: normal;
          font-weight: vw(600);
        }
      }
      .prices {
        font-size: vw(32);
        color: #999999;
        height: vw(45);
        line-height: vw(45);
      }
      .date{
        display: table-cell;
        text-align: right;
        height: vw(37);
        font-size: vw(26);
        font-weight: 400;
        color: #999999;
        line-height: vw(37);
      }
    }
    .addr{
      position: relative;
      font-size: vw(26);
      font-weight: 500;
      color: #333333;
      line-height: vw(37);
      padding-left: vw(38);
      margin-top: vw(20);
      &::before{
        content: ' ';
        display: block;
        width: vw(34);
        height: vw(34);
        position: absolute;
        left: vw(0);
        background: url('/static/img/me/surveyedShops/icon-addr.png') no-repeat;
        background-size: vw(34) vw(34);
      }
    }
  }
  .tab-wrap{
    margin-top: vw(34);
    .empty {
      width: vw(690);
      height: vw(600);
      background-color: #fff;
      margin: auto;
      margin-top: vw(31);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #BDBDBD;
      font-size: $font_373;
      font-weight: bold;
      border-radius: vw(10);
      img {
        width: vw(174);
        height: vw(174);
        margin-bottom: vw(31);
      }
    }
  }
  .fixed-btn-wrap{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: vw(24) vw(30);
    background: #fff;
    box-shadow: 0 0 vw(10) rgba(0,0,0,0.1);
    p{
      width: 100%;
      height: vw(80);
      background: #347FFF;
      border-radius: vw(8);
      font-size: vw(30);
      font-weight: 500;
      color: #FFFFFF;
      line-height: vw(80);
      text-align: center;
    }
  }
}
::v-deep .van-tabs {
  .van-tabs__wrap{
    height: vw(79);
  }
  .van-tabs__nav--line{
    height: vw(59);
    padding-bottom: vw(20);
  }
  .van-tab{
    font-size: vw(28);
    font-weight: 400;
    color: #666666;
    height: vw(59);
    padding: 0 sz(4);
    &.van-tab--active{
      font-size: vw(30);
      font-weight: 600;
      color: #333333;
      .van-tab__text{
        line-height: vw(45);
      }
    }
    .van-tab__text{
      height: vw(45);
      line-height: vw(45);
      display: inline-block;
    }
  }
  .van-tabs__nav{
    background: none;
    height: vw(73);
    .van-tabs__line{
      width: vw(50);
      background: #347FFF;
    }
  }
}
.submitFooter {
    width: 100%;
    background: #fff;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    border-top: 0.133vw solid #ebeaee;
    box-shadow: 0 -0.267vw 0.8vw 0 #ebeaee;
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
</style>
