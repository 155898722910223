<template>
  <div class="report l-panel" @click="onTap">
    <div class="report--content">
      <p v-if="item.status === 1" class="time">下单时间: {{ item.placeTime || "刚刚" }}</p>
      <p v-else class="time">下单时间: {{ item.payTime || "刚刚" }}</p>
      <h1>
        <span class="card-tag">
          {{ reportType[item.businessType] || "其他" }}
        </span>
        {{ (item.businessType == 37 ? item.questionName : item.businessName) || '---' }}
      </h1>
      <div v-if="item.inputList" class="desc s-radius__xs">
        <p v-for="(field, index) in item.inputList" :key="index">
          <strong>{{ field.name }}：</strong>{{ field.value }}
        </p>
      </div>
      <div v-if="item.otherInputList" class="light s-radius__xs">
        <p v-for="(field, index) in item.otherInputList" :key="index">
          <strong>{{ field.name }}：{{ field.value }}</strong>
        </p>
      </div>
      <div class="desc s-radius__xs" v-if="item.businessType == 37">
        <p v-if="item.marketInfo && item.marketInfo.price">
          <strong>服务单价：</strong>¥{{ item.marketInfo.price }}
        </p>
        <p v-if="item.marketInfo && item.marketInfo.unit"><strong>购买数量：</strong>{{ item.marketInfo.unit }}</p>
         <p> <strong>商家：</strong>{{ item.businessName }}</p>

      </div>
      <div class="stage-box" v-if="item.marketInfo && item.marketInfo.stageList && item.marketInfo.stageList.length">
        <div class="stage-label">分阶段付款</div>
        <div class="stage-content">
          {{ item.marketInfo.stageList.length === 3 ? '首款/中款/尾款：' : '首款/尾款：' }}
          <span v-for="(stage,index) in item.marketInfo.stageList" :key="index">
            <span class="ratio">{{ stage.ratio }}%</span>
            <span class="line" v-if="index<item.marketInfo.stageList.length-1"> / </span>
          </span>
        </div>
      </div>
      <div class="order-status-box">
        <div class="order-status-box-left">
          <img class="icon" src="/static/icon/success.png" />
          {{ info }}{{ stagePayInfo.payed }}
        </div>
        <div class="order-status-box-right">
          <span class="order-text" v-if="stagePayInfo.totalPrice">订单合计</span>
          {{ stagePayInfo.totalPrice ? stagePayInfo.totalPrice + '元' : price }}
        </div>
      </div>
<!--      <van-cell :title="info" :value="price" class="info" :class="{ paid: paid }" />-->
      <template v-if="item.businessType == 37 && paid">
        <div class="stage-pay-box" v-if="item.marketInfo && item.marketInfo.porderNo && item.marketInfo.stageList && item.marketInfo.stageList.length">
          <div class="stage-pay-box-left">
            本订单分阶段付费，请在获取商家约定的服务后继续支付<span>{{ stagePayInfo.unpay }}</span>获取下一阶段服务
          </div>
          <div class="stage-pay-box-right" @click="stagePopup=true">支付{{ stagePayInfo.unpay }}</div>
        </div>
        <!--确认服务-->
        <div class="service-box" v-else-if="!item.comment">
          <div class="service-box-left">
            <img class="service-icon" src="/static/icon/ddd.png" />
            <span class="service-uncomplete-text">服务完成后，请确认</span>
          </div>
          <div class="service-box-right" @click="showConfirm">确认完成</div>
        </div>
        <div class="service-box" v-else-if="item.comment">
          <div class="service-box-left">
            <img class="service-icon" src="/static/icon/loan_success.png" />
            <span class="service-complete-text">服务已完成</span>
            <van-rate readonly v-model="item.comment.starLevel" gutter="0.53vw" size="3.2vw" void-icon="star" color="#FF8047" void-color="#E4E7ED" />
          </div>
          <div class="service-box-right2" @click="onLivechat">联系客服</div>
        </div>
      </template>
    </div>
    <van-popup v-model="show" round>
      <div class="service-popup-container">
        <img class="close-icon" src="/static/icon/detail_close.png" alt="" @click="show=false" />
        <div class="popup-title">服务确认</div>
        <div class="service-name">{{ item.questionName }}</div>
        <div class="shop-name">{{ item.businessName }}</div>
        <van-rate class="service-rate" v-model="rateVal" gutter="3vw" size="8vw" icon="/static/icon/star-icon.png" void-icon="/static/icon/dis-star-icon.png"></van-rate>
        <div class="comment-types">
          <div class="comment-item" :class="{'comment-active-item': selectCommentType.includes(item)}" v-for="(item, index) in commentTypes" :key="index" @click="selectComment(item)">{{ item }}</div>
        </div>
        <van-field
          v-model="message"
          type="textarea"
          placeholder="您对我们的服务满意吗，您的反馈将帮助我们提高服务质量"
          :error="showError"
          @input="showError=false"
        />
        <div class="service-btns">
          <div class="chat-btn" @click="onLivechat">联系客服</div>
          <div class="commit-btn" @click="commitGoodsComment">确认完成</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="stagePopup" position="bottom" class="stage-popup">
      <div class="stage-popup-container">
<!--        <img class="close-icon" src="/static/icon/detail_close.png" alt="" @click="stagePopup=false" />-->
        <div class="popup-title">支付<span>{{ stagePayInfo.unpay }}</span></div>
        <div class="need-pay-price">
          ¥<span>{{ stagePayInfo.unpayPrice }}</span>
        </div>
        <div class="stage-info">
          <div class="stage-item">订单合计：<span class="unit">¥</span><span class="price">{{ stagePayInfo.totalPrice }}</span></div>
          <div class="stage-item">已支付：{{ stagePayInfo.payed }}，合计<span class="unit">¥</span><span class="price">{{ stagePayInfo.payPrice }}</span></div>
        </div>
        <div class="stage-box" v-if="item.marketInfo && item.marketInfo.stageList">
          <div class="stage-label">分阶段付款</div>
          <div class="stage-content">
            {{ item.marketInfo.stageList.length === 3 ? '首款/中款/尾款：' : '首款/尾款：' }}
            <span v-for="(stage,index) in item.marketInfo.stageList" :key="index">
            <span class="ratio">{{ stage.ratio }}%</span>
            <span class="line" v-if="index<item.marketInfo.stageList.length-1"> / </span>
          </span>
          </div>
        </div>
        <div class="pay-btn" @click="confirmPay">确认支付{{ stagePayInfo.unpay }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
const reportType = {
  1: "选址报告",
  2: "充值",
  3: "会员",
  4: "尊享包",
  5: "工具",
  6: "数据导出",
  7: "套餐包",
  8: "店铺订阅",
  9: "品牌报告",
  10: "数据查询",
  11: "客群画像",
  12: "选品类",
  13: "课程",
  14: "品牌加盟",
  15: "定制服务",
  16: "热力图",
  17: "开店位置推荐",
  29: "地图标注",
  33: "铺参谋服务",
  37: "服务",
  38: "开店地图"
};

export default {
  name: "report",
  props: {
    item: Object // 订单对象
  },
  data: function() {
    return {
      reportType,
      rate: 4,
      show: false,
      rateVal: 0,
      commentTypes: ['点赞','好评','按时交付','服务周到','有耐心','专业'],
      selectCommentType: [],
      message: '',
      showError: false,
      stagePopup: false
    };
  },
  computed: {
    price() {
      return this.item.payAmount + (this.item.payType === "SSCM" ? "上上贝" : "元");
    },
    info() {
      return this.paid ? (this.item.status === 5 ? "已取消" : "已支付") : "待支付";
    },
    paid() {
      return this.item.status > 1;
    },
    stagePayInfo() {
      let obj = {
        payed: '',
        unpay: '',
        totalPrice: '',
        payPrice: '',
        unpayPrice: ''
      }
      if (this.item.marketInfo) {
        const { stageList, level, price, unit, porderNo } = this.item.marketInfo
        if (this.item.marketInfo && stageList && stageList.length) {
          obj.totalPrice = Number(price) * unit
          if (level === 1) {
            obj.payed = '首款'
            if (stageList.length === 2) {
              obj.unpay = '尾款'
              obj.unpayPrice = Number((obj.totalPrice * stageList[stageList.length-1].ratio / 100).toFixed(2))
              obj.payPrice = Number((obj.totalPrice - obj.unpayPrice).toFixed(2))
            } else {
              obj.unpay = '中款'
              obj.unpayPrice = Number((obj.totalPrice * stageList[stageList.length-2].ratio / 100).toFixed(2))
              obj.payPrice =  Number((obj.totalPrice * stageList[0].ratio / 100).toFixed(2))
            }
          } else if (level === stageList.length) {
            if (stageList.length === 2) {
              obj.payed = '首款/尾款'
            } else {
              obj.payed = '首款/中款/尾款'
            }
          } else {
            obj.payed = '首款/中款'
            obj.unpay = '尾款'
            obj.unpayPrice = Number((obj.totalPrice * stageList[stageList.length-1].ratio / 100).toFixed(2))
            obj.payPrice = Number((obj.totalPrice - obj.unpayPrice).toFixed(2))
          }
        }
      }
      // console.log('obj', obj)
      return obj
    }
  },
  methods: {
    confirmPay() {
      this.$emit('pay', {
        goodsId: this.item.questionId,
        refId: String(this.item.questionId),
        unitCnt: this.item.marketInfo.unit,
        level: this.item.marketInfo.level + 1,
        refOrderNo: this.item.marketInfo.porderNo,
        itemId: this.item.marketInfo.itemId,
      })
      this.stagePopup = false
    },
    showConfirm() {
      this.message = ''
      this.rateVal = 0
      this.selectCommentType = []
      this.show = true
    },
    commitGoodsComment() {
      if(!this.message) {
        this.showError = true
        return
      }
      this.showError = false
      this.HTTP.addQuestion({
        question: this.message,
        starLevel: this.rateVal || 0,
        tag: this.selectCommentType.join(','),
        goodsId: this.item.questionId,
        orderNo: this.item.orderNo
      }).then(res => {
        this.show = false
        this.item.comment = {
          starLevel: this.rateVal || 0
        }
        this.$toast.success('确认完成')
      })
    },
    selectComment(val) {
      const index = this.selectCommentType.findIndex(e => e === val)
      if (index > -1) {
        this.selectCommentType.splice(index, 1)
      } else {
        this.selectCommentType.push(val)
      }
    },
    onTap() {
      // 转租服务跳转到详情页面
      if (this.item.businessType == 33) {
        this.JSBridge.BTNCLICK( this.$route.meta.id, this.item.orderNo, '0460100','0460101');
        this.swRouter('/shopEcology/lease/order-details?id=' + this.item.orderNo)
      }
      if (this.paid) return;
      let businessId = this.item.businessId;
      if (this.item.businessType == 7) {
        // 套餐包
        businessId = this.item.questionId;
      }
      this.$emit("tap", { businessId: businessId || 0, orderNo: this.item.orderNo || 0, businessType: this.item.businessType });
      this.JSBridge.BTNCLICK( this.$route.meta.id, this.$route.query.id, '0460100','0460101');
    },
    onLivechat () {
      this.show = false
      if (window._appCode >= 175) {
        this.JSBridge.callHandler( 'onNormalizingCall', {
          type  : 5,
          url   : 'ONLINE_SERVICE',
          params: null,
        } );
      } else {
        this.JSBridge.callHandler(
          'callLiveChat',
          {
            type: 'normal',
            data: {},
          },
          ( st ) => {
          },
        );
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.order-status-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: vw2(15);
  .order-status-box-left {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #518CDB;
    font-size: vw2(12);
    .icon {
      width: vw2(13);
      height: vw2(13);
      margin-right: vw2(4);
    }
  }
  .order-status-box-right {
    display: flex;
    align-items: center;
    font-size: vw2(18);
    color: #FF5900;
    font-weight: bold;
    .order-text {
      font-weight: 400;
      color: #676C84;
      font-size: vw2(11);
      margin-right: vw2(5);
    }
  }
}
.stage-box {
  display: flex;
  align-items: center;
  font-size: vw2(11);
  .stage-label {
    padding: vw2(2) vw2(5);
    background: rgba(255,128,71,0.1);
    border-radius: vw2(2) 0 0 vw2(2);
    line-height: vw2(20);
    text-align: center;
    color: #FF8047;
    margin-right: vw2(2);
  }
  .stage-content {
    flex: 1;
    line-height: vw2(20);
    padding: vw2(2) vw2(5);
    background: #F8F8F9;
    border-radius: 0 vw2(2) vw2(2) 0;
    color: #676C84;
    .ratio {
      color: #FF8047;
    }
  }
}
.service-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: vw2(15);
  margin-top: vw2(15);
  .chat-btn {
    width: vw2(120);
    height: vw2(40);
    line-height: vw2(40);
    text-align: center;
    border-radius: vw2(5);
    border: vw2(1) solid #347FFF;
    color: #347FFF;
    box-sizing: border-box;
  }
  .commit-btn {
    width: vw2(120);
    height: vw2(40);
    line-height: vw2(40);
    text-align: center;
    border-radius: vw2(5);
    background: #347FFF;
    color: #fff;
    box-sizing: border-box;
  }
}
.van-field {
  height: vw2(100);
  background: #F8F8F9;
  border-radius: vw2(4);
  margin-top: vw2(15);
  font-weight: 400;
  color: #1D2233;
  font-size: vw2(12);
  padding: vw2(10);
  box-sizing: border-box;
 ::v-deep .van-cell__value {
   .van-field__body {
     height: 100%;
     textarea {
       height: 100%;
       font-size: vw2(12);
       ::-webkit-input-placeholder {
         color: #999999;
       }
     }
   }
 }
}
.van-popup--round {
  border-radius: vw2(5);
}
.stage-popup {
  border-radius: vw2(10) vw2(10) 0 0;
}
.stage-popup-container {
  background: #FFFFFF;
  border-radius: vw2(10) vw2(10) 0 0;
  padding: vw2(15);
  .pay-btn {
    height: vw2(40);
    line-height: vw2(40);
    text-align: center;
    background: #347FFF;
    border-radius: vw2(5);
    font-size: vw2(15);
    color: #fff;
    font-weight: 500;
    margin-top: vw2(35);
  }
  .stage-box {
    margin-top: vw2(15);
  }
  .stage-info {
    margin-top: vw2(20);
    .stage-item {
      font-weight: 400;
      color: #1D2233;
      font-size: vw2(13);
      margin-top: vw(15);
      line-height: vw2(20);
      .unit {
        font-weight: bold;
        margin-left: vw2(5);
      }
      .price {
        font-size: vw2(14);
        font-weight: bold;
        margin-left: vw2(2);
      }
    }
  }
  .need-pay-price {
    text-align: center;
    font-weight: bold;
    color: #FF504E;
    font-size: vw2(13);
    margin-top: vw2(10);
    span {
      font-size: vw2(18);
      margin-left: vw2(2);
    }
  }
  .popup-title {
    text-align: center;
    font-weight: bold;
    color: #1D2233;
    font-size: vw2(15);
    span {
      color: #FF8047;
    }
  }
}
.service-popup-container {
  text-align: center;
  width: vw2(291);
  //height: vw2(400);
  background: #FFFFFF;
  border-radius: vw2(5);
  padding: vw2(20) vw2(20) vw2(15) vw2(20);
  box-sizing: border-box;
  position: relative;
  .close-icon {
    position: absolute;
    width: vw2(20);
    height: vw2(20);
    top: vw2(10);
    right: vw2(10);
  }
  .comment-types {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: vw2(5);
    margin-top: vw2(20);
    padding: 0 vw2(15);
    .comment-item {
      height: vw2(25);
      line-height: vw2(25);
      border-radius: vw2(13);
      opacity: 0.6;
      border: vw2(1) solid #ABB3C1;
      text-align: center;
      font-weight: 400;
      color: #8893A7;
      font-size: vw2(11);
      background: #fff;
      box-sizing: border-box;
      &.comment-active-item {
        background: rgba(255,128,71,0.1);
        border: vw2(1) solid #FF8047;
        color: #FF8047;
      }
    }
  }
  .popup-title {
    font-weight: bold;
    color: #121622;
    font-size: vw2(17);
    line-height: vw2(24);
  }
  .service-name {
    font-weight: 400;
    color: #1D2233;
    font-size: vw2(15);
    line-height: vw2(21);
    margin-top: vw2(10);
  }
  .shop-name {
    font-weight: 400;
    color: #676C84;
    font-size: vw2(13);
    line-height: vw2(19);
    margin-top: vw2(4);
  }
  .service-rate {
    margin-top: vw2(15);
  }
}
.van-cell::after {
  border-bottom: 0;
}
.stage-pay-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: vw2(10);
  border-top: 1px solid #E4E7ED;
  margin-top: vw2(12);
  .stage-pay-box-left {
    font-weight: 400;
    color: #676C84;
    font-size: vw2(11);
    flex: 1;
    margin-right: vw2(10);
    line-height: vw2(15);
    span {
      color: #FF8047;
      font-weight: 500;
    }
  }
  .stage-pay-box-right {
    width: vw2(78);
    height: vw2(28);
    line-height: vw2(28);
    background: #FF8047;
    border-radius: 4px;
    border: 1px solid #FF8047;
    text-align: center;
    font-weight: 500;
    color: #FFFFFF;
    font-size: vw2(12);
  }
}
.service-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: vw2(10);
  border-top: 1px solid #E4E7ED;
  margin-top: vw2(12);
  .service-box-right {
    width: vw2(78);
    height: vw2(28);
    line-height: vw2(28);
    text-align: center;
    background: #347FFF;
    border-radius: vw2(4);
    border: vw2(1) solid #347FFF;
    color: #ffffff;
    font-size: vw2(12);
    font-weight: 500;
  }
  .service-box-right2 {
    width: vw2(78);
    height: vw2(28);
    line-height: vw2(28);
    text-align: center;
    border-radius: vw2(4);
    border: vw2(1) solid #347FFF;
    font-weight: 500;
    color: #347FFF;
    font-size: vw2(12);
  }
  .service-box-left {
    font-size: vw2(12);
    display: flex;
    align-items: center;
    .service-icon {
      width: 3.68vw;
      height: 3.68vw;
      margin-right: vw2(4);
      vertical-align: middle;
    }
    .service-uncomplete-text {
      color: #676C84;
    }
    .service-complete-text {
      color: #1D2233;
      margin-right: vw2(8);
    }
  }
}
.report {
  position: relative;
  padding: $padding1;
  margin-bottom: $margin;

  #{&}--content {
    position: relative;
    height: 100%;
    h1 {
      color: $c-text;
      width: 100%;
      font-size: $font_400;
      line-height: $font_560;
      margin: 0;
      margin-top: 2.13vw;
      .card-tag {
        position: relative;
        top: -0.8vw;
        display: inline-flex;
        align-items: center;
        padding: 0.53vw 1.33vw;
        line-height: normal;
        font-size: $font_267;
        color: #8d5d22;
        background: #fdd6a4;
        border-radius: 1px 5px 1px 5px;
        margin-right: 1.07vw;
      }
    }
    .time {
      font-size: $font_293;
      line-height: $font_400;
      color: $c-info;
    }
    .desc,
    .light {
      margin: $margin 0;
      padding: $margin;
      background-color: $c-pageBg;
      font-size: $font_293;
      line-height: $font_400;
    }
    .light {
      background-color: #fff1e1;
      color: #8d5d22;
      font-weight: bold;
    }
    .info {
      padding: 0;
      font-size: $font_400;
      font-weight: bold;
      margin-top: vw(17);

      ::v-deep .van-cell__title {
        font-size: $font_347;
        color: $c-warn;
        span {
          display: inline-block;
          padding-left: 5vw;
          background: url(/static/icon/clock.png) no-repeat left center;
          background-size: 3.68vw;
        }
      }
      ::v-deep .van-cell__value {
        color: $c-price;
      }

      &.paid {
        ::v-deep .van-cell__title {
          color: $c-main;
          span {
            background-image: url(/static/icon/success.png);
          }
        }
      }
    }
  }
}
</style>
