<template>
  <div class="page-content">
    <StateBar />
    <TitleHeader title="我的收藏" bgcolor="transparent" color="black" :back="true"></TitleHeader>
    <AutoView :header="true" :footer="false" class="bills--view l-view">
      <van-list class="community-list"
                finished-text="——无更多小区——"
                v-if="list.length"
                :finished="enableLoadMore"
                :immediate-check="false"
                v-model="loading"
                @load="loadMore"
      >
        <communityCard v-for="(community,index) in list" :key="index" :communityObj="community" formCollectList @cancelComplete="cancelComplete" />
      </van-list>
      <div class="empty-card" v-else>
        <img class="empty-icon" src="/static/cellScreen/empty.png" />
        <img class="empty-text-icon" src="/static/cellScreen/no-data.png" />
      </div>
    </AutoView>
  </div>
</template>

<script>
const communityCard = () => import('./components/communityCard')
export default {
  name: 'communityCollection',
  components: {
    communityCard
  },
  data() {
    return {
      current: 1,
      size: 10,
      list: [],
      enableLoadMore: false,
      loading: true
    }
  },
  mounted () {
    this.getPage()
  },
  methods: {
    cancelComplete() {
      this.current = 1
      this.getPage()
    },
    loadMore() {
      this.current++
    },
    getPage() {
      this.loading = true
      this.HTTP.collectPage({
        current: this.current,
        size: this.size,
        type: 4
      }).then(res => {
        console.log(res)
        const { total, data } = res
        if(data.length < this.size) {
          this.enableLoadMore = true
        } else {
          this.enableLoadMore = false
        }
        let list = []
        if (data.length) {
          list = data.map(el => {
            return {
              ...el,
              year: el.buildingYears ? el.buildingYears.substring(0,4) : ''
            }
          })
        }
        if (this.current > 1) {
          this.list = this.list.concat(list)
        } else {
          this.list = list
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .empty-card {
    background: #fff;
    border-radius: 1.87vw;
    height: 106.67vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-icon {
      width: 23.2vw;
      height: 23.2vw;
      margin-top: 26.67vw;
    }
    .empty-text-icon {
      width: 15.733vw;
      height: 4vw;
      margin-top: 4vw;
    }
  }
</style>
