import router from '../router'
import http from '@/request/http';
import UniUtils from '@/utils/uni'
const miniPlatformDevice = {
  'WX': '4',
  'ALI': '5',
  'DY': '6'
}
function setupJSBridge ( callback ) {
    if ( window.WebViewJavascriptBridge ) {
        return callback( window.WebViewJavascriptBridge );
    } else if ( window.isClient && window.isAndroid ) {
        document.addEventListener(
            'WebViewJavascriptBridgeReady',
            () => {
                callback( window.WebViewJavascriptBridge );
            },
            false
        );
    } else if ( window.isClient && window.isIOS ) {
        if ( window.WVJBCallbacks ) {
            return window.WVJBCallbacks.push( callback );
        }
        window.WVJBCallbacks = [ callback ];
        const WVJBIframe = document.createElement( 'iframe' );
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild( WVJBIframe );
        setTimeout( () => { document.documentElement.removeChild( WVJBIframe ); }, 0 );
    } else {
        // console.warn( 'JSBridge: WebViewJavascriptBridge undefined.' );
        // throw new Error( 'JSBridge: WebViewJavascriptBridge undefined.' );
        return callback( null );
    }
}

const JSBridge = {
    init () {
        const ua = navigator.userAgent;
        const apps = {
            SSCM: '上上参谋',
            CYKD: '创业开店',
        };
        for ( const appId in apps ) {
            if ( ua.indexOf( appId ) !== - 1 ) {
                window.isClient = true;
                window._appId   = appId;
                window._appName = apps[ appId ];
                break;
            }
        }
        // window.isClient  = ua.indexOf( 'SSCM' ) !== - 1;
        // window.isHUAWEI = ua.indexOf( 'HUAWEI' ) > - 1;
        window.isCloseComment = false;
        window.isAndroid = ua.indexOf( 'Android' ) !== - 1;
        window.isIOS     = ua.indexOf( 'iPhone' ) !== - 1 || ua.indexOf( 'iPad' ) !== - 1;
        window.isIPX     = false;
        window.isIPMX = false;
        // 小程序
        // window.isMini      = window[ '__wxjs_environment' ] === 'miniprogram'; /* || ua.indexOf( 'MicroMessenger' ) > - 1 */
        // 微信
        window.isWeChat  = ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger';

        if ( window.isIOS ) {
            // XR
            if ( window.devicePixelRatio === 2 && window.screen.width === 414 && window.screen.height === 896 ) {
                window.isIPX = true;
            } else if ( window.devicePixelRatio === 3 ) {
                // X, XS
                if ( window.screen.width === 375 && window.screen.height === 812 ) {
                    window.isIPX = true;
                // XS Max
                } else if ( window.screen.width === 414 && window.screen.height === 896 ) {
                    window.isIPX = true;
                // 12, Pro
                } else if ( window.screen.width === 390 && window.screen.height === 844 ) {
                    window.isIPX = true;
                // 12 Mini
                } else if ( window.screen.width === 360 && window.screen.height === 780 ) {
                    window.isIPX = true;
                // 12 Max
                } else if ( window.screen.width === 428 && window.screen.height === 926 ) {
                    window.isIPX = true;
                } else if ( window.screen.width === 430 && window.screen.height === 932 ) {
                    window.isIPMX = true;
                } else if ( window.screen.width === 393 && window.screen.height === 852 ) {
                    window.isIPMX = true;
                }
            }
        }
        if ( window.isClient ) {
            console.log( `SSCM client ${window.isAndroid ? 'Android' : 'iOS'}, version: ${ua.substr( ua.indexOf( 'SSCM' ) + 5 )}` );
        }
        setupJSBridge( ( bridge ) => {
            // 安卓需要进行一次写入
            if ( window.isAndroid && bridge ) {
                bridge.init( ( message, responseCallback ) => {
                    responseCallback( { 'Hi': 'SSCM' } );
                } );
            }
        } );
        // 兼容处理 iOS v1.4.4 桥接接口错误及分享标识错误 v1.6.0 下发后废弃 v1.7.0 移除
        // setupJSBridge( ( bridge ) => {
        //     if ( bridge ) {
        //         bridge.callHandler( 'getVersion', null, function ( code ) {
        //             window._appCode = ~ ~ code;
        //             console.log( 'getVersion:', window._appCode );
        //         } );
        //         bridge.callHandler( 'getVersionCode', null, function ( code ) {
        //             window._appCode = ~ ~ code;
        //             console.log( 'getVersionCode:', window._appCode );
        //         } );
        //     }
        // } );
    },
    appName () {
        return window._appName || '上上参谋';
    },
    callHandler ( method, data, callback ) {
      console.log('method',method)
        // Methods validate
        // TODO
        if (window.isClient) {
          if (method === 'onNormalizingCall') {
            // 全局处理原生支付弹窗，跳转h5页面
            if (data.url=='DIALOG' && window._appCode>=50300) {
              if (data.params) {
                const p = JSON.parse(data.params)
                if (p?.type==4) {
                  this.callHandler(method, {
                    ...data,
                    type: 6,
                    url: `/h5/#/member/pay?source=${data?.id||''}`
                  }, callback)
                  return
                }
              }
            }
          }
          setupJSBridge( ( bridge ) => {
            if ( bridge ) {
              bridge.callHandler( method, data, callback );
            } else if ( typeof callback === 'function' ) {
              callback( null );
            }
          } );
        } else if (window.isMini) {
          // 兼容小程序跳转
          if (method === 'onNormalizingCall') {
            if (data.type == 6 || data.url.indexOf('/pages') === 0) {
              UniUtils.webviewRouter(data.url)
            } else if (data.url === 'DIALOG' || data.url.includes('/h5/#/member')) {
              // 处理会员页跳转
              UniUtils.goMiniVipPage()
            } else if (data.url === 'SKU_FILL_INFO_PAGE' && data.id == '10026' ) {
              // 小程序位置推荐页
              UniUtils.miniRouter({ url: '/pages_home/locationRecommend' })
            } else if (data.url === 'SKU_FILL_INFO_PAGE' && data.id == '10001' ) {
              // 小程序位置评估页
              UniUtils.miniRouter({ url: '/pages_home/searchPoi?goodsId=10001' })
            }    else {
              console.log('请下载app')
              UniUtils.goDefaultPage()
            }
          } else if (method === 'onBackPressed') {
              UniUtils.miniPageBack()
          }
        }

        console.log('调用桥：', data);
    },
    check ( code ) {
        return code === window._appCode;
    },
    registerHandler ( method, callback ) {
        // Methods validate
        // TODO
        setupJSBridge( ( bridge ) => {
            if ( bridge ) {
                bridge.registerHandler( method, ( data, responseCallback ) => {
                    callback( data, responseCallback );
                } );
            }
        } );
    },
    report ( id, data ) {
        // Get Source Data
        // TODO
        const template = {
            'event_time'     : '',
            'ref_id'         : '',
            'ref_name'       : '',
            'ref_type'       : '', // 业务类型 文章: ARTICLE 前置报告: PRE_REPORT 报告表单: FORM_REPORT 关键字搜索：SEARCH,
            'source_ref_id'  : '', // 来源业务id,
            'source_ref_name': '', // 来源业务名称,
            'source_ref_type': '', // 来源业务类型 如以上业务为文章浏览，那么文章浏览来源首页、发现、搜索
        };
        setupJSBridge( ( bridge ) => {
            if ( bridge ) {
                bridge.callHandler( 'onEventObject', {
                    'event_id': id,
                    map       : data && typeof data === 'object' ? Object.assign( template, data ) : template,
                } );
            }
        } );
    },
    BTNCLICK(pId, rId, mId, bId) {
        const dataBtn = {
            "pageId"  : pId || router.currentRoute.path ||  null, //页面id
            "refId"    : rId || null,  //商品或订单id
            "moduleId" : mId || null,  //模块id
            "btnId"    : bId || null, // 按钮id
        }
        if (window.isClient) {
          setupJSBridge( ( bridge ) => {
            if ( bridge ) {
              bridge.callHandler( 'onUploadClickEvent', dataBtn );
            }
          } );
        } else {
          const track = [
            {
              pageId: pId || '00000',
              refId: rId || null,
              source: {
                btnId: bId,
                moduleId: mId
              },
              timestamp: new Date().getTime()
            }
          ]
          http.sendBtnTrack({
            deviceType: this.getDeviceType(),
            platform: "sscm",
            version: '1.0.0',
            track: JSON.stringify(track)
          })
        }
        console.log('按钮桥接数据',JSON.stringify(dataBtn));
    },
    getDeviceType() {
      let deviceType = '1'
      if (window.isMini) {
        deviceType = miniPlatformDevice[window.miniPlatform]
      } else {
        deviceType = window.isAndroid ? '1' : '2'
      }
      return deviceType
    },
    BPR ( id, pid, data ) {
        // console.log( {
        //     pageId: id || '00000',
        //     refId : pid || null,
        //     extra : data || null,
        // } );
      if (window.isClient) {
        setupJSBridge( ( bridge ) => {
          if ( bridge ) {
            if ( data && typeof data !== 'string' ) data = JSON.stringify( data );
            bridge.callHandler( 'onEventReport', {
              pageId: id || router.currentRoute.path || '00000',
              refId : pid || null,
              extra : ! data || data === '{}' ? null : data,
            } );
            console.log(`pv埋点：pageId: ${id},refId: ${pid},extra: ${! data || data === '{}' ? null : data}`)
          }
        } );
      } else {
        const track = [
          {
            pageId: id || '00000',
            refId: pid || null,
            extra : ! data || data === '{}' ? null : data,
            timestamp: new Date().getTime()
          }
        ]
        http.sendTrack({
          deviceType: this.getDeviceType(),
          platform: "sscm",
          version: '1.0.0',
          track: JSON.stringify(track)
        })
      }
    },
    // 上报事件ID映射
    REPORT_EVENT: {
        ARTICLE: {
            TAP_ADVISE: 'ArticlePage_ClickGetAdvise',
        },
        ASSESS: {
            ENTRY: 'Page_StoreReview',
        },
        BRAND: {
            TAP_RECOMMENDL : 'JoinAnalysisPage_ClickBrandsRecommend',
            TAP_DETAIL     : 'JoinAnalysisPage_ClickBrandDetail',
            TAP_SOLUTIONS  : 'BrandDetailsPage_GetJoinSolutions',
            TAP_ANALYSIS   : 'BrandDetailsPage_ClickBrandAnalysis',
            TAP_IMMEDIATELY: 'BrandDetailsPage_ClickAnalysisImmediately',
            TAP_CONTRAST   : 'Brandontrast_ClickBrandContrast',
            TAP_COOPERATION_SERVICE: 'BrandService_ClickBrandCooperationService'

        },
        CREATE: {
            TAP_ADVISE     : 'InputConditionsPage_ClickGetAdvise',
            TAP_MK_GENERATE: 'GenerateMRReportPage_ClickGenerate',
        },
        CUSTOM: {
            TAP_SUBMIT    : 'SubmitRequirementPage_ClickSubmit',
            TAP_SUBMIT_SUC: 'SubmitQuestionPage_ClickSubmit_Success',
            TAP_SUBMIT_FAL: 'SubmitQuestionPage_ClickSubmit_Failed',
        },
        DISCOVERY: {
            TAP_BUBBLE: 'DiscoveryPage_ClickBubble',
            SEARCH    : 'DiscoveryPage_ClickSearchPoi',
            TAP_POI   : 'DiscoveryPage_ClickOnePoi',
        },
        HOME: {
            TAP_SEARCH : 'HomePage_ClickSearchBar',
            TAP_ARTICLE: 'HomePage_ClickArticle',
        },
        MYQUESTION: {
            TAP_REPLY: 'MyQuestionsPage_UnfoldReply',
        },
        MYREPORT: {
            TAP_OPEN: 'MyReportPage_ClickOneReport',
            TAP_FIND: 'MyReportPage_ClickFindReport',
        },
        PAY: {
            TAP_PAY      : 'PayPage_ClickConfirmPayment',
            TAP_OK       : 'PayPage_PaySuccess_ClickSure',
            CANCEL       : 'PayPage_PayFailed_Cancel',
            SUC_COUPON   : 'PayPage_Success_WithCoupon',
            SUC_NO_COUPON: 'PayPage_Success_WithoutCoupon',
            SUC_WX       : 'PayPage_Success_WithWeChat',
            SUC_ALI      : 'PayPage_Success_WithAlipay',
            SUC_APPLE    : 'PayPage_Success_WithApplepay',
            SUC_PKG    : 'PayPage_Success_WithPackage',
        },
        INTRODUCE: {
            ENTRY: 'Page_', // 注意 需要组合商品ID
        },
        REPORT: {
            TAP_UNLOCK : 'ReportPage_ClickReadDeepReport',
            TAP_CATALOG: 'ReportPage_ClickLookCatalog',
        },
        SEARCH: {
            TAP_HOT             : 'SearchPage_ClickHotQuestion',
            TAP_CANCEL          : 'SearchPage_ClickCancel',
            TAP_RESULT          : 'SearchPage_Click_OneSearchResultQuestion',
            TAP_CUSTOM          : 'SearchPage_Click_AskQuestion_HasResult',
            TAP_CUSTOM_NO_RESULT: 'SearchPage_Click_AskQuestion_NoResult',
            SEARCH              : 'SearchPage_SearchKeyword',
            RESULT              : 'SearchPage_SearchKeyword_HasResult',
            NO_RESULT           : 'SearchPage_SearchKeyword_NoResult',
        },
        VIP: {
            TAP_PAY  : 'PurchaseVIPPage_ClickToPay',
            TAP_RENEW: 'VIPDetailsPage_ClickToRenew',
            TAP_TAKE : 'VIPDetailsPage_ClickGetVIPWelfare',
        },
    },
};

export default JSBridge;
