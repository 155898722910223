<template>
	<div>
		<StateBar />
		<TitleHeader title="收藏的铺" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
			<div slot="right" class="right-title" @click="goIndex">商铺广场</div>
		</TitleHeader>
		<AutoView :footer="false">
			<img class="img" v-show="false" src="/static/shopEcology/img-default.png" alt="">
			<div class="spread-list">
				<van-list v-model="loading" :finished="finished" finished-text="" @load="getList">
					<van-swipe-cell v-for="(item,key) in dataList" :key="key">
						<div class="spread-list-item" @click="gotoDetails(item)">
							<div class="left-box">
                                <van-image class="img" v-if="item.frontDoorUrl" :src="formatUrl(item.frontDoorUrl) + '?x-oss-process=image/resize,m_fill,w_100'">
                                    <template v-slot:loading>
								        <img class="img" src="/static/shopEcology/img-default.png" alt="">
                                    </template>
                                </van-image>
								<img class="img" v-else src="/static/shopEcology/img-default.png" alt="">
								<div class="list-icon" v-if="item.platformSurveyTagUrl">限时免费</div>
								<div class="bottom-box">
									<img class="botton-content" v-if="item.unlocked"
										src="../../../../static/shopEcology/list-type.png" alt="">
									<img class="pay" v-if="item.hasVideo"
										src="../../../../static/shopEcology/list-pay.png" alt="">
								</div>
							</div>
							<div class="right-box">
								<div class="title ell-1">{{item.title}}</div>
								<div class="content">{{item.leftSubtitle}}<span
										:class="{'orange':item.rightSubtitleHighlight}">{{item.rightSubtitle}}</span>
								</div>
								<div class="bottom-box">
									<div class="botton-item" v-for="(items,keys) in item.tagList" :key="keys">
										<span>{{items}}</span>
									</div>
								</div>
							</div>
						</div>
						<template #right>
							<van-button square text="取消收藏" type="danger" class="delete-button" @click="close(item)" />
						</template>
					</van-swipe-cell>

				</van-list>
			</div>
			<van-empty
				style="position: absolute;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);width: 100%;"
				v-if="!dataList.length" description="暂无数据" />
		</AutoView>
	</div>
</template>

<script>
	import {
		collectedShopList,
		save
	} from "@/request/shopEcology"
	export default {
		data() {
			return {
				form: {
					current: 1, //	当前页		false
					size: 20, //	分页大小		false
				},
				dataList: [],
				loading: false,
				finished: false,
			}
		},
		methods: {
			getList() {
				collectedShopList(this.form).then(res => {
					console.log(res)
					this.dataList = this.dataList.concat(res.data || [])
					// 加载状态结束
					this.loading = false;
					// 数据全部加载完成
					if (this.dataList.length == res.total) {
						this.finished = true;
					} else {
						++this.form.current
					}
				})
			},
			gotoDetails(item) {
				this.JSBridge.BTNCLICK(this.$route.meta.id, null, 158000, 158001);
                this.JSBridge.callHandler("onNormalizingCall", item.link)
				// this.swRouter({
				// 	path: '/shopEcology/shopDetails',
				// 	query: {
				// 		id: item.code
				// 	}
				// })
			},
			close(item) {
				//取消关注
				let data = {
					code: item.code,
					status: 0
				}
				save(data).then(res => {
					this.$toast.fail('取消成功');
					this.dataList = []
					this.getList()
				})
			},
			goIndex() {
				this.JSBridge.BTNCLICK(this.$route.meta.id, null, 157000, 157001);
        if (window._appCode >= 407) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "SHOP_SQUARE_HOME_V2"
          });
        } else {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "SHOP_SQUARE_HOME"
          });
        }
			},
      formatUrl(url){
        if (!url) {
          return '';
        }
        return url.replace(/\\/g, '');
      }
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	.delete-button {
		height: 100%;
	}

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.right-title {
		font-size: vw(26);
		color: #347FFF;
	}

	.spread-list {
		padding: 0 0 0 vw(30);
		box-sizing: border-box;
		border-bottom: vw(1) solid #E7E7E7;


		.spread-list-item {
			padding: vw(20) vw(0) vw(30);
			box-sizing: border-box;
			display: flex;
			border-bottom: vw(1) solid #E7E7E7;

			.left-box {
				position: relative;
				background: #979797;
				width: vw(155);
				height: vw(118);
        overflow: hidden;
				.img {
					width: 100%;
					//height: 100%;
					vertical-align: middle;
				}

				.list-icon {
					position: absolute;
					top: - vw(0);
					left: - vw(0);
					z-index: 1;
					width: vw(120);
					height: vw(40);
					background: #F4E6B1;
					border-radius: vw(8) 0px vw(4) 0px;
					font-size: vw(20);
					font-weight: 600;
					color: #9D7744;
					line-height: vw(40);
					text-align: center;
				}

				.bottom-box {
					position: absolute;
					bottom: vw(8);
					left: vw(9);

					.botton-content {
						width: vw(80);
						height: vw(28);
					}

					.pay {
						width: vw(28);
						height: vw(28);
					}

				}

			}

			.right-box {
				flex: 1;
				padding-left: vw(20);

				.orange {
					color: #FF6900;
				}

				.title {
					height: vw(42);
					font-size: vw(30);
					line-height: vw(42);
					font-weight: 600;
					color: #333333;
				}

				.content {
					height: vw(37);
					font-size: vw(26);
					color: #666666;
					line-height: vw(37);
					margin-top: vw(18);
					display: flex;

					.color {
						color: #F86E21;
					}
				}

				.bottom-box {
					height: vw(36);
					overflow: hidden;
					display: flex;
					margin-top: vw(18);
					flex-wrap: wrap;

					.botton-item {
						background: #F6F6F6;
						margin-right: vw(20);
						font-size: vw(24);
						height: vw(36);
						line-height: vw(36);
						padding: 0 vw(13);
						box-sizing: border-box;
						color: #5A5E66;
						display: flex;
						align-items: center;
					}
				}

				.button-box {
					display: flex;
					justify-content: space-between;
					margin-top: vw(36);

					.left-button {
						width: vw(110);
						height: vw(44);
						background: #FFFFFF;
						border: vw(1) solid #979797;
						font-size: vw(28);
						color: #F86E21;
						line-height: vw(44);
						text-align: center;
						box-sizing: border-box;
					}

					.right-button {
						width: vw(140);
						height: vw(44);
						line-height: vw(44);
						background: #FFFFFF;
						border: vw(1) solid #108EE9;
						box-sizing: border-box;
						font-size: vw(26);
						color: #108EE9;
						text-align: center;
					}
				}
			}
		}

		.spread-list-item:last-child {
			border: none;
		}
	}
</style>
