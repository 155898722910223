<template>
    <Wraper title="品牌门店分布" :showIcon="true" :getMore="true" moreTips="查看全国在营门店" @clickTips="clickTips" @openMoreData="openMoreData">
        <div class="box-item">
            <div class="box-body">
                <div class="box-name">{{ bgmData.openShopCount ? bgmData.openShopCount + "家" : "-" }}</div>
                <div class="box-value">正常营业</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.closedNumber ? bgmData.closedNumber + "家" : "-" }}</div>
                <div class="box-value">暂停营业</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.disOpenNumber ? bgmData.disOpenNumber + "家" : "-" }}</div>
                <div class="box-value">尚未营业</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">{{ bgmData.waiMaiCount ? bgmData.waiMaiCount + "家" : "-" }}</div>
                <div class="box-value">外卖店铺</div>
            </div>
        </div>
        <ChinaMap :provinceData="provinceData" />
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";
import ChinaMap from "../../echarts/chinaMap.vue";

export default {
    props: {
        brandCode: {
            type: String,
            default: "",
        },
        brandName: {
            type: String,
            default: "",
        },
        bgmData: {
            type: Object,
            default: {},
        },
        provinceData: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Wraper,
        ChinaMap,
    },
    methods: {
        clickTips() {
            this.swRouter("/reportView/dataExplain");
        },
        openMoreData() {
            const data = JSON.stringify({
                key: "provinceInfo",
                value: JSON.stringify(this.provinceData),
            });
            this.JSBridge.callHandler("putString", data);
            this.swRouter(`/reportView/shopList/${this.brandCode}?brandName=${this.brandName}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.box-item {
    position: relative;
    border-radius: 5px;
    background-color: #fafafb;
    padding: 12px 0 15px 0;
    display: flex;
    align-items: center;
    margin-top: 5px;

    &:first-child {
        margin-top: 0;
    }

    .box-cut-line {
        position: relative;
        width: 1px;
        height: 30px;
        background-color: rgba(222, 222, 231, 0.8);
    }

    .box-body {
        position: relative;
        width: 100%;

        .box-value {
            position: relative;
            color: #676c84;
            font-size: 11px;
            text-align: center;
            line-height: 20px;
            margin-right: 2px;
        }

        .box-name {
            position: relative;
            margin-top: 5px;
            color: #1d2233;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
        }
    }
}
</style>
