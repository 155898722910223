<template>
    <div class="reportshop">
        <StateBar />
        <TitleHeader title="城市分布统计" bgcolor="transparent" color="black" :back="true"></TitleHeader>

        <AutoView ref="view" id="view" :header="true" :footer="false" class="reportshop--view">
            <van-skeleton :loading="loading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
                <div class="content">
                    <div class="tier-wrap">
                        <h5 class="title">城市层级门店占比</h5>
                        <Hotranking :tableData="ratioPieInfo" />
                    </div>
                    <div class="tier-wrap">
                        <h5 class="title">门店占比最高的城市前十</h5>
                        <Hotranking :tableData="cityDataList" v-if="isMember"/>
                        <Lock v-else :lockImg="lockImg"/>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import Hotranking from "./components/hotRanking.vue";
import Lock from "@/components/openVip/lock.vue"

export default {
    components: { Hotranking, Lock },
    data() {
        return {
            loading: true,
            ratioPieInfo: [],
            cityDataList: [],
            lockImg: 'static/img/city-not-vip.png',
            isMember: false
        };
    },
    mounted() {
        this.JSBridge.callHandler("getString", "isMember", (data) => {
            if (data) {
                if (typeof data === "string") {
                    data = JSON.parse(data);
                }
                this.isMember = data;
            }
        });
        this.JSBridge.callHandler("getString", "setReportInfo", (data) => {
            if (data) {
                if (typeof data === "string") {
                    data = JSON.parse(data);
                }
                this.ratioPieInfo = data.ratioPieInfo;
                this.cityDataList = data.cityDataList;
                this.loading = false;
            }
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.reportshop {
    height: 100vh;
    overflow: hidden;

    #{&}--view {
        scroll-behavior: smooth;
        position: relative;
        .content {
            position: relative;
            padding: 4vw 3.2vw;
            .tier-wrap {
                margin-bottom: 4vw;
                .title {
                    height: 10.13vw;
                    line-height: 10.13vw;
                    font-size: 4vw;
                    font-weight: bold;
                    color: #333;
                }
            }
        }
    }
}
</style>
