<template>
    <div>
        <section class="video-wrap">
                <div id="player" class="player"></div>
        </section>
    </div>
</template>

<script>
import Player from "xgplayer";
export default {
    props: {
        poster: {
            type: String,
            default: ""
        },
        src: {
            type: String
        },
        autoplay: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        new Player({
            id: "player",
            poster: this.poster, // 封面图
            url: this.src,
            playsinline: true,
            "x5-video-player-type": "h5",
            "x5-video-player-fullscreen": "false",
            closeVideoClick: true,
            closeVideoTouch: true,
            autoplay: this.autoplay, // 自动播放
            videoInit: true, // 显示首帧
            fluid: true, // 流式布局
            // fitVideoSize: "fixWidth",
            ignores: ["volume"],
        });
    }
}
</script>

<style lang="scss" scoped>
.video-wrap {
    min-height: 53.3vw;
    .player {
        width: 100%;
    }
}
</style>