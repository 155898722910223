<template>
  <div class="msg" @click="$emit('onTap')">
    <!-- 报告 -->
    <ul class="report-flex" v-if="msgType == 1">
      <!-- <li class="report-left"><img :src="item.icon" alt="" /></li> -->
      <li class="report-right">
        <ul class="top-flex">
          <li class="title">{{ item.title }}</li>
          <li class="time">{{ item.gmtCreate }}</li>
        </ul>
        <ul class="bottom-flex">
          <li class="tips">{{ item.content }}</li>
          <li class="circle" :class="{ unread: item.status == 0, read: item.status == 1 }"></li>
        </ul>
      </li>
    </ul>
    <!-- "3": "优惠福利", "4": "参谋学院", "5": "开店助手", "6": "精选品牌", "7": "最新资讯" -->
    <ul class="ul-flex" v-else>
      <!-- <li class="left">
        <img :src="item.icon" alt="" />
      </li> -->
      <li class="mid">
        <p class="title f-tof">{{ item.title }}</p>
        <p class="explain">{{ item.content }}</p>
      </li>
      <li class="right">
        <p class="time">{{ item.time }}</p>
        <p class="radius">
          <span class="circle" :class="{ unread: item.status == 0, read: item.status == 1 }"></span>
        </p>
      </li>
    </ul>
    <!-- 下划线 -->
    <!-- <ul class="line-flex" v-if="isLine">
      <li class="empty"></li>
      <li class="line">
        <van-divider :style="{ borderColor: '#e7e7e7', padding: '0', margin: '0' }"></van-divider>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    isLine: {
      type: Boolean,
      default: false,
    },
    msgType: {
      type: [String, Number],
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";
.msg {
  width: 100%;
  margin-bottom: 2.67vw;
  border-radius: 1.33vw;
  overflow: hidden;
  &:last-child{
    margin-bottom: 0;
  }
  .ul-flex {
    display: flex;
    flex-flow: row nowrap;
    padding: 3.2vw 2.67vw;
    background: #fff;
    .left {
      flex: 0 0 14.67vw;
      align-self: center;
      img {
        width: 12vw;
        height: 12vw;
        border-radius: 50%;
      }
    }
    .mid {
      flex: 1;
      width: 58.67vw;
      .title {
        height: 5.33vw;
        line-height: 5.33vw;
        font-size: 3.73vw;
        font-weight: bold;
        color: #333;
      }
      .explain {
        line-height: 4.27vw;
        font-size: 3.2vw;
        color: #999;
        margin-top: 1.6vw;
        @include limitLine(2);
      }
    }
    .right {
      text-align: right;
      .time {
        height: 5.33vw;
        line-height: 5.33vw;
        font-size: 2.67vw;
        color: #999;
      }
      .radius {
        line-height: 4.27vw;
        margin-top: 1.6vw;
        .circle {
          display: inline-block;
          width: 2vw;
          height: 2vw;
          border: 0.27vw solid #fff;
          border-radius: 50%;
        }
      }
      .read {
        background: #fff;
      }
      .unread {
        background: #ff5900;
      }
    }
  }

  .report-flex {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 3.2vw 2.67vw;
    background: #fff;
    .report-left {
      flex: 0 0 14.67vw;
      img {
        width: 12vw;
        height: 12vw;
        border-radius: 50%;
      }
    }
    .report-right {
      flex: 1;
      .top-flex {
        display: flex;
        .title {
          flex: 1;
          line-height: 5.33vw;
          font-size: 3.73vw;
          color: #333;
        }
        .time {
          flex: 1;
          text-align: right;
          line-height: 5.33vw;
          font-size: 3.2vw;
          color: #999;
        }
      }
      .bottom-flex {
        display: flex;
        line-height: 4.27vw;
        margin-top: 1.6vw;
        .tips {
          flex: 1;
          width: 0;
          font-size: 2.67vw;
          color: #999;
          @include limitLine(2);
        }
        .circle {
          display: inline-block;
          width: 2vw;
          height: 2vw;
          border: 0.27vw solid #fff;
          border-radius: 50%;
          margin-top: 1vw;
        }
      }
      .read {
        background: #fff;
      }
      .unread {
        background: #ff5900;
      }
    }
  }

  .line-flex {
    display: flex;
    // height: 1px;
    .empty {
      flex: 0 0 14.67vw;
      background: #fff;
    }
    .line {
      flex: 1;
    }
  }
}
</style>
