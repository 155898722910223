<template>
    <div class="wrapper">
        <StateBar />
        <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true">
            <span slot="right" class="link-txt" @click="showTip = true">地图指南</span>
        </TitleHeader>

        <AutoView>
            <img class="bg" src="/static/img/store-map/discuss_bg.png" alt="" />
            <div class="forum-topic" v-if="!isCloseComment">
                <p class="title">
                    加入讨论<span class="num">({{ commentList.length }})</span>
                </p>
                <EvaluateCard :type="6"/>
                <one-comment :hasBorder="i !== commentList.length - 1" v-for="(item, i) in commentList" :key="i" :itemObj="item" :hasReply="!!item.appReplyRes">
                    <div  v-if="item.appReplyRes">
                        <one-comment v-for="(rep ,idx) in item.appReplyRes" :key="idx" :itemObj="rep"></one-comment>
                    </div>
                </one-comment>
            </div>
        </AutoView>
        <van-popup v-model="showTip">
            <div class="tip-box">
                <van-icon name="cross" @click="closeTip"/>
                <AutoView class="tip-content">
                <div class="one-qa" v-for="(item, i) in questions" :key="i">
                    <p class="question">{{ item.question }}</p>
                    <p class="answer" v-for="(ans, idx) in item.answers" :key="idx">{{ ans }}</p>
                </div>
                </AutoView>
                <div class="open-map">
                    <van-button type="info" @click="closeTip">点击开启地图</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
const questions = [
    {
        question: '开一家店到底有多难？',
        answers : [
            '大到选项目选址，小到菜单设计，至少有365个事项需要决策！【开店地图】应运而生，梳理开店全流程知识科普和注意事项，帮您查缺补漏、规避风险！'
        ]
    },
    {
        question: '我们将开店分为4个阶段：',
        answers : [
            '项目考察：选定开什么店，加盟好还是自营？',
            '开店选址：选商圈选铺位，全方面选址评估',
            '开店筹备：涵盖装修设计选品进货，面面俱到',
            '经营管理：从吸引客流到营销秘诀，认真搞钱'
        ]
    },
    {
        question: '您可以利用本地图做什么：',
        answers : [
            '开店前：了解开店全流程，做到心中有数',
            '开店中：让开店地图辅助你检查，查缺补漏',
            '开店后：从管理到营销，成功的经营方法可复制'
        ]
    },
    {
        question: '希望通过我们的努力，让您开好店！真正做到，地图在手，开店无忧！',
        answers : []
    }
];
const params = {
    current: 1,
    size: 1000,
}
import OneComment from '@/views/hotSearch/components/OneComment';
import EvaluateCard from "@/views/hotSearch/components/evaluate-card.vue";
export default {
    data() {
        return {
            inputText: "",
            commentList: [],
            searchOptions: {...params},
            title: '开店地图',
            showTip: false,
            questions,
            showComplate: false,
            isCloseComment: false
        };
    },
    components: {
        OneComment,
        EvaluateCard
    },
    mounted() {
        this.isCloseComment = window.isCloseComment
        this.getComment()
    },
    methods: {
        changeHandle() {
            console.log(this.inputText)
            this.HTTP.addEvaluate({
                content: this.inputText,
                type: 6
            }).then(() => {
                this.$toast.success('发布成功')
                this.getComment()
            })
        },
        getComment() {
            this.HTTP.getReplys({
                ...this.searchOptions,
                type: 6
            }).then(res => {
                this.commentList = res.data
            })
        },
        closeTip () {
            this.showTip = false
            this.$store.commit('setShowStoreMapTip', true)
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    box-sizing: border-box;
    min-height: 50vh;
    width: 100vw;
    overflow-x: hidden;
    background: #fff;
    .link-txt {
        color: #347fff;
    }
    .bg {
        width: 100%;
    }
    .forum-topic {
        box-sizing: border-box;
        margin-top: 5.33vw;
        min-height: 50vh;
        width: 100vw;
        padding: 4vw;
        background: #fff;
        .title {
            font-size: 4.26vw;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #1d2233;
            .num {
                color: #999;
                font-size: 3.733vw;
            }
        }
    }
    .tip-box {
        box-sizing: border-box;
        width: 92vw;
        padding: 4vw;
        padding-right: 0;
        padding-bottom: 8vw;
        background: url('/static/img/store-map/tip-bg.png') bottom center / 100% auto no-repeat;
        position: relative;
        .tip-content {
          height: 60vh;
          max-height: 100vw;
          overflow-y: auto;
          padding-right: 4vw;
        }
        .van-button {
            width: 100%;
        }
        .van-icon {
            position: absolute;
            right: 4vw;
            z-index: 20;
        }
        .one-qa {
            margin-bottom: 3.73vw;
            line-height: 5.86vw;
            .question {
                color: #333;
                font-size: 4vw;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
            }
            .answer {
                color: #666;
                font-size: 3.46vw;
                font-family: PingFangSC-Regular, PingFang SC;
                padding-left: 2.93vw;
                margin-top: 1.46vw;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    width: 2.13vw;
                    height: 2.13vw;
                    background: #347fff;
                    border-radius: 50%;
                    left: 0;
                    top: 1.86vw;
                }
            }
        }
        .open-map {
            padding-right: 4vw;
        }
    }
}
</style>
