import { Axios } from './http'

const MAPPING = {
  brandDiscover: '/v3/find/data', // 发现页接口
  saveSearchHistory: '/v3/user/search/save', // 保存搜索历史
  clearSearchHistory: '/v3/user/search/del', // 清除搜索历史
  querySearchHistory: '/v3/user/search/searchList', // 查询搜索历史
  saveReportComment: '/v1/report/comment/save', // 报告评价
  hotBrandList: '/v3/find/hotBrand', // 热门品牌
  hotMallList: '/v3/find/hotMall', // 热门商场
  queryBrandAndMall: '/v3/find/searchBrandAndMall', // 品牌与商场搜索
  queryMessageNotice: '/v1/message/data', // 消息通知接口
  readAllMsg: '/v1/user/msg/readAllMsg', // 一键已读
  createBusinessMap: '/v311/queryData/save/map', // 创建地图配置
  getBusinessMapDetail: '/v311/queryData/getById', // 获取商业地图详情
  queryAppCollegeData: '/v5/school/data', // 参谋学院接口
}

const commonRequest = (config) => {
  return new Promise((resolve, reject) => {
    Axios.request(config).then(res => {
      resolve(res.data.data);
    }).catch(e => {
      reject(e);
    });
  })
}

const brandDiscover = () => {
  return commonRequest( { url: MAPPING.brandDiscover, method: 'post' } );
}

const saveSearchHistory = (data = { keyWord: '', type: '' }) => {
  return commonRequest( { url: MAPPING.saveSearchHistory, method: 'post', data  } );
}

const clearSearchHistory = () => {
  return commonRequest( { url: MAPPING.clearSearchHistory, method: 'get' } );
}
const querySearchHistory = () => {
  return commonRequest( { url: MAPPING.querySearchHistory, method: 'get'  } );
}
const saveReportComment = (data) => {
  return commonRequest( { url: MAPPING.saveReportComment, method: 'post', data } );
}

const hotBrandList = (data = { current: 1, size: 10 }) => {
  return commonRequest( { url: MAPPING.hotBrandList, method: 'post', data } );
}

const hotMallList = (data = { current: 1, size: 10 }) => {
  return commonRequest( { url: MAPPING.hotMallList, method: 'post', data } );
}

const queryBrandAndMall = (data) => {
  return commonRequest( { url: MAPPING.queryBrandAndMall, method: 'post', data } );
}

const queryMessageNotice = () => {
  return commonRequest( { url: MAPPING.queryMessageNotice, method: 'post' } )
}

const readAllMsg = () => {
  return commonRequest( { url: MAPPING.readAllMsg, method: 'post' } )
}

const createBusinessMap = (data) => {
  return commonRequest( { url: MAPPING.createBusinessMap, method: 'post', data } )
}

const getBusinessMapDetail = (id) => {
  return commonRequest({ url: MAPPING.getBusinessMapDetail + '/' + id, method: 'get' })
}

const queryAppCollegeData = () => {
  return commonRequest({ url: MAPPING.queryAppCollegeData, method: 'get' })
}

export default {
  brandDiscover,
  saveSearchHistory,
  clearSearchHistory,
  querySearchHistory,
  saveReportComment,
  hotMallList,
  hotBrandList,
  queryBrandAndMall,
  queryMessageNotice,
  readAllMsg,
  createBusinessMap,
  getBusinessMapDetail,
  queryAppCollegeData
}
