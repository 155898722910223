<template>
    <Wraper title="销量订单分析（代金券/团购）">
        <div class="box-item">
            <div class="box-body">
                <div class="box-name">123家</div>
                <div class="box-value">统计店铺数</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">≈134单</div>
                <div class="box-value">半年店均销量</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">10000单</div>
                <div class="box-value">近半年订单量</div>
            </div>
            <div class="box-cut-line"></div>
            <div class="box-body">
                <div class="box-name">90单</div>
                <div class="box-value">销量:中位数</div>
            </div>
        </div>
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";

export default {
    components: {
        Wraper,
    },
};
</script>

<style lang="scss" scoped>
.box-item {
    position: relative;
    border-radius: 1.33vw;
    background-color: #fafafb;
    padding: 3.2vw 0 4vw 0;
    display: flex;
    align-items: center;
    margin-top: 1.33vw;

    &:first-child {
        margin-top: 0;
    }

    .box-cut-line {
        position: relative;
        width: 0.27vw;
        height: 8vw;
        background-color: rgba(222, 222, 231, 0.8);
    }

    .box-body {
        position: relative;
        width: 100%;

        .box-value {
            position: relative;
            color: #676c84;
            font-size: 2.93vw;
            text-align: center;
            line-height: 5.33vw;
            margin-right: 0.53vw;
            margin-top: 1.33vw;
        }

        .box-name {
            position: relative;
            color: #1d2233;
            font-size: 3.47vw;
            font-weight: bold;
            text-align: center;
        }
    }
}
</style>
