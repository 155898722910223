import {
	Axios
} from './http'
const commonRequest = (config) => {
	return new Promise((resolve, reject) => {
		Axios.request(config).then(res => {
			resolve(res.data.data);
		}).catch(e => {
			reject(e);
		});
	})
}

const MAPPING = {
	saveSitingIntention: '/v1/shop/collect/saveSitingIntention', // 提交选址评估
	getIntentionListPage: '/v1/shop/collect/getIntentionListPage', // 我的选址意向列表
	getIntentionDetailById: '/v1/shop/collect/getIntentionDetailById/', // 选址意向详情
	collectedShopList: '/v1/shopSquare/collectedShopList', // 已收藏的铺列表
	myShopList: '/v1/shopSquare/myShopList', // 我的铺列表
	unlockedShopList: '/v1/shopSquare/unlockedShopList', // 已解锁的铺列表
	save: '/v1/shopSquare/userCollect/save', // 添加或取消收藏
	platformDetail: '/v1/shop/collect/platformDetail', // 官方铺详情
	detail: '/v1/shop/broker/detail/', // 查询经纪人详情
	secondDetail: '/v1/shop/collect/secondDetail', // 官方铺二级详情
	getShopOrderInfo: '/v1/shop/collect/getShopOrderInfo/', // 订单支付信息
	brokerIntroductionList: '/v1/shop/broker/brokerIntroductionList', // 查询经纪人列表
	samplePlatformShopCode: '/v1/shopSquare/samplePlatformShopCode', // 示例店铺code
	getBusinessTypeList: '/v1/shop/collect/getBusinessTypeList', //  商铺业态列表
	saveLocationIntentionRecomm: '/v1/shop/collect/saveLocationIntentionRecomm', //提交选址意向-铺位推荐
	saveLocationIntentionAssess: '/v1/shop/collect/saveLocationIntentionAssess', //提交选址意向-商铺评估
	getIntentionEchoByUserId: '/v1/shop/collect/getIntentionEchoByUserId',
    getShopReport: '/v1/shop/collect/platform/report', // 获取铺位详情报告
    getShopFullReport: '/v1/report/full/platformShop', // 获取铺位详情完整报告
    hasRentIntention: '/v1/collection/intentionRent/hasRentIntention', // 是否有求租信息
    getMyList: '/v2/shopSquare/myShopList',  // 获取我的铺信息
    deployShop: '/v2/shopSquare/deployShop', // 发布/取消我的铺位
    cancelAudit: '/v2/collect/cancelAudit',
}
export const getBusinessTypeList =() => {
	return commonRequest({
		url: MAPPING.getBusinessTypeList,
		method: 'get'
	});
}
export const saveSitingIntention = (data) => {
	return commonRequest({
		url: MAPPING.saveSitingIntention,
		method: 'post',
		data
	});
}

export const getIntentionListPage = (data) => {
	return commonRequest({
		url: MAPPING.getIntentionListPage,
		method: 'post',
		data
	});
}

export const getIntentionDetailById = (id) => {
	return commonRequest({
		url: MAPPING.getIntentionDetailById + id,
		method: 'get'
	});
}
export const collectedShopList = (data) => {
	return commonRequest({
		url: MAPPING.collectedShopList,
		method: 'post',
		data
	});
}
export const myShopList = (data) => {
	return commonRequest({
		url: MAPPING.myShopList,
		method: 'post',
		data
	});
}
export const unlockedShopList = (data) => {
	return commonRequest({
		url: MAPPING.unlockedShopList,
		method: 'post',
		data
	});
}
export const save = (data) => {
	return commonRequest({
		url: MAPPING.save,
		method: 'post',
		data
	});
}
export const platformDetail = (data) => {
	return commonRequest({
		url: MAPPING.platformDetail,
		method: 'post',
		data
	});
}
export const detail = (id) => {
	return commonRequest({
		url: MAPPING.detail + id,
		method: 'get'
	});
}
export const secondDetail = (data) => {
	return commonRequest({
		url: MAPPING.secondDetail,
		method: 'post',
		data
	});
}

export const getShopOrderInfo = (shopId) => {
	return commonRequest({
		url: MAPPING.getShopOrderInfo + shopId,
		method: 'get',
	});
}
export const brokerIntroductionList = () => {
	return commonRequest({
		url: MAPPING.brokerIntroductionList,
		method: 'get'
	});
}
export const samplePlatformShopCode = () => {
	return commonRequest({
		url: MAPPING.samplePlatformShopCode,
		method: 'get'
	});
}
export const saveLocationIntentionRecomm = (data) => {
	return commonRequest({
		url: MAPPING.saveLocationIntentionRecomm,
		method: 'post',
		data
	});
}
export const saveLocationIntentionAssess = (data) => {
	return commonRequest({
		url: MAPPING.saveLocationIntentionAssess,
		method: 'post',
		data
	});
}
export const getIntentionEchoByUserId = () => {
	return commonRequest({
		url: MAPPING.getIntentionEchoByUserId,
		method: 'get'
	});
}

export const getShopReport = (code) => {
  return commonRequest({
    url: MAPPING.getShopReport + '/' + code,
    method: 'get'
  });
}

export const getShopFullReport = (code) => {
  return commonRequest({
    url: MAPPING.getShopFullReport + '/' + code,
    method: 'get'
  });
}

export const hasRentIntention = () => {
  return commonRequest({
    url: MAPPING.hasRentIntention,
    method: 'get'
  });
}

// 获取我的铺位列表
export const getMyList = (data) => {
  return commonRequest({
    url: MAPPING.getMyList,
    method: 'post',
    data
  });
}

// 发布/取消发布
export const deployShop = (data) => {
    return commonRequest({
        url: MAPPING.deployShop,
        method: 'post',
        data
    })
}

// 取消审核
export const cancelAudit = (data) => {
    return commonRequest({
        url: MAPPING.cancelAudit,
        method: 'post',
        data
    })
}