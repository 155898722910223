<!--通用支付组件-->
<template>
    <div>
        <!--选择支付-->
        <van-popup v-model:show="paymentVisible" round position="bottom">
            <div class="payment-popup">
                <p class="title">{{ title }}</p>
                <payment :payList="payList" @setPayType="setPayType" />
                <div class="pay-btn-wrap">
                    <van-button class="pay-btn" type="primary" @click="doPay">确认支付</van-button>
                </div>
            </div>
        </van-popup>
        <!--// 苹果内购-->
        <van-overlay :show="applePayVisible">
            <div>
                <van-loading class="status" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
// 内部组件
import Payment from "@/components/payTypes/index.vue";
import { placeGoods, placeMember, pgk, servicePayOrder } from "../../request/order.js";
export default {
    components: {
        Payment
    },
    props: {
        // 创建订单需要的额外参数
        extParams: {
            type: Object,
            required: true
        },
        // 创建订单调用的方法
        api: {
            type: String
        },
        // 是否是微信连续付费
        isSubscription: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "支付"
        },
        payList: {
            default: ["支付宝", "微信"]
        }
    },
    data() {
        return {
            paymentVisible: false,
            applePayVisible: false,
            clientType: 0, // 1: IOS 2： Android
            // 准备创建支付
            hasPrePay: false,
            // 支付方式类型
            payMode: null,
            // 支付调用桥接名
            payCall: "wxPay",
            payMap: {
                支付宝: {
                    mode: "ALIPAY_MOBILE",
                    call: "aliPay"
                },
                微信: {
                    mode: "WX_APP",
                    call: "wxPay"
                },
                微信连续付费: {
                    mode: "WX_CONTRACT",
                    call: "wxPreEntrust"
                },
                苹果: {
                    mode: "APPLE_IN",
                    call: "applePay"
                }
            }
        };
    },
    mounted() {
        this.registerPayCallback();
        if (window.isIOS) {
            this.clientType = 1;
            console.log("系统: ios");
        } else if (window.isAndroid) {
            this.clientType = 2;
            console.log("系统: android");
        }
    },
    methods: {
        // 唤醒选择支付方式组件
        pay() {
            this.paymentVisible = true;
        },

        complete() {
            this.applePayVisible = false;
            this.paymentVisible = false;
        },
        // 设置支付的状态
        setPayType(value) {
            const map = this.payMap[value];
            this.payMode = map.mode;
            this.payCall = map.call;
            console.log("支付方式：", map);
        },
        // 调起支付方法
        async doPay() {
            if (this.hasPrePay) return;
            if (!this.payMode) {
                // 提示选择支付方式
                return;
            }
            // 如果是微信连续支付更改支付类型和回调类型
            if (this.payMode == "WX_APP" || this.payMode == "WX_CONTRACT") {
                if (this.isSubscription) {
                    this.setPayType("微信连续付费");
                } else {
                    this.setPayType("微信");
                }
            }
            const params = {
                ...this.extParams,
                pageId: this.$route ? this.$route.meta.id : this.extParams.pageId,
                payTypeChannel: this.payMode,
                sourceType: this.clientType,
                payMode: window.isClient ? 2 : 1
            };
            let api = null;
            if (this.api == "placeGoods") {
                api = placeGoods;
            } else if (this.api == "pgk") {
                api = pgk;
            } else if (this.api == "servicePayOrder") {
                api = servicePayOrder;
            } else {
                api = placeMember;
            }
            // return;
            this.hasPrePay = true;
            const res = await api(params);
            const data = {
                id: res.orderNo,
                data: res.payResult,
                pageId: this.$route ? this.$route.meta.id : this.extParams.pageId
            };
            this.$emit("payStatus", res.orderNo);
            if (window.isClient) {
                this.hasPrePay = false;
                this.JSBridge.callHandler(this.payCall, data, st => {
                    if (!st) {
                        this.$toast("未安装客户端，调起支付失败！");
                        console.log(`调起支付...失败`);
                        this.complete();
                        return;
                    }
                    console.log(`调起支付...end`);
                    this.complete();
                });
            } else {
                this.$toast("暂不支持网页版支付");
                setTimeout(() => {
                    this.complete();
                    this.hasPrePay = false;
                }, 1000);
            }
        },

        iosPurchasePay() {
            this.setPayType("苹果");
            this.applePayVisible = true;
            this.doPay();
        },

        registerPayCallback() {
            this.JSBridge.registerHandler("paymentResult", data => {
                console.log("支付结果回调", data);
                this.hasPrePay = false;
                // 安卓端需要进行转义
                if (typeof data === "string") {
                    data = JSON.parse(data);
                }
                if (typeof data === "object") {
                    switch (data.state) {
                        case 1: // 支付成功
                            this.$toast("支付成功");
                            this.$emit("success");
                            // 通知客户端更新用户信息
                            this.JSBridge.callHandler("noticeUpdate", "userInfo");
                            break;
                        case 5: // 兼容微信
                            break;
                        case 7: // 超时
                        case 8: // 取消
                            this.$toast("已取消支付");
                            this.$emit("cancel");
                            break;
                        default:
                            // 支付失败
                            this.$emit("payError");
                    }
                } else {
                    this.$toast("支付时发生错误！");
                    this.$emit("error");
                }
                this.complete();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.van-overlay {
    z-index: 100;
    .status {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.title {
    text-align: center;
    font-size: vw2(18);
    padding: vw2(20);
    font-weight: bold;
}
.pay-btn-wrap {
    padding: vw2(15);
    .pay-btn {
        width: 100%;
    }
}
::v-deep .van-button {
    background-color: #1989fa;
    border: vw2(1) solid #1989fa;
    height: vw2(42);
}
</style>
