<template>
    <div class="download">
        <StateBar></StateBar>
        <TitleHeader title="APP下载" color="#518CDB" :back="true" class="s-line__b"></TitleHeader>

        <AutoView :header="true" :footer="false" class="download--view">
            <div class="f-vp">
                <van-image class="icon"
                    fit="cover"
                    :src="img" />
            </div>
        </AutoView>

        <ButtonFooter text="下载 APP" @click="onTap"></ButtonFooter>
    </div>
</template>

<script>
export default {
    name      : 'download',
    components: {

    },
    data () {
        return {
            img: '/static/img/default_cover.jpg',
        };
    },
    methods: {
        onTap () {
            top.location.href = this.HTTP.getAppStore();
        },
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.download {
    height: 100vh;
    // background-color: $c-pageBg2;
}
</style>
