<template>
	<div class="intention-form">
		<van-popup v-model="submitMaskType" closeable close-icon-position="top-left" position="bottom"
			:style="{ height: '50%' }" @close="close">
			<div class="body-box">
				<div class="title">商铺信息由铺参谋更新于{{time}}</div>
				<div class="table-box">
					<van-tabs color="#347FFF" v-model="active" swipeable @click="onClick">
						<van-tab :title="item.templateName" v-for="(item,key) in listArr" :key="key">
							<div class="list-box" v-if="item.childList">
								<template v-for="(items,keys) in item.childList">
									<div class="list-item" v-if="items.childList" :key="keys">
										<div class="type">{{items.templateName}}</div>
										<div class="list-item-item" v-for="(itemss,keyss) in items.childList"
											:key="keyss">
											<div class="label">{{itemss.key}}</div>
											<div class="content  glass" v-if="itemss.value==='示例数据'">{{itemss.value}}
											</div>
											<div class="content" v-else>{{itemss.value}}</div>
										</div>
<!--										<div class="button" v-if="isShowUnlock" @click="unlock"><span>解锁商铺后即可查看全部信息</span>-->
<!--											<van-icon name="arrow" />-->
<!--										</div>-->
									</div>
								</template>
							</div>
							<div class="list-box" v-else>
								<van-empty description="暂无数据" />
							</div>
						</van-tab>
					</van-tabs>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		secondDetail
	} from "@/request/shopEcology"
	export default {
		data() {
			return {
				active: 0,
				submitMaskType: true,
				listArr: [],
			}
		},
		props: {
			time: {},
      isShowUnlock: {},
			id: {}
		},
		mounted() {
			this.getDetails()
		},
		methods: {
			close() {
				this.$emit('close')
			},
			onClick() {
				this.$emit('allMaskClick')
			},
			unlock() {
				this.$emit('unlock')
			},
			getDetails() {
				let data = {
					code: this.id
				}
				secondDetail(data).then(res => {
					console.log(res)
					this.listArr = res
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.intention-form {
		// @import '@/styles/index.scss';

		@function vw($args) {
			@return $args/750*100+vw;
		}

		.body-box {
			padding: vw(30);
			box-sizing: border-box;

			.glass {
				filter: blur(3px);
			}

			.title {
				text-align: right;
				height: vw(40);
				font-size: vw(26);
				color: #999999;
				line-height: vw(40);
				margin-top: vw(10);
			}

			.table-box {
				margin-top: vw(20);

				.list-box {
					height: vw(580);
					overflow-y: auto;

					.list-item {
						background: #FFFFFF;
						border-radius: vw(10);
						border: vw(2) solid #E7E7E7;
						margin-top: vw(22);
						padding: vw(60) 0 vw(20);
						box-sizing: border-box;
						position: relative;

						.type {
							position: absolute;
							left: 0%;
							top: 0;
							height: vw(48);
							line-height: vw(48);
							text-align: center;
							background: #DFEDFF;
							border-radius: 0px 0px vw(10) 0px;
							font-size: vw(26);
							font-weight: 600;
							color: #347FFF;
							padding: 0 vw(10);
						}

						.list-item-item {
							display: flex;
							justify-content: space-between;
							box-sizing: border-box;
							line-height: vw(34);
							padding: vw(10) vw(25);

							.label {
								width: 50%;
								font-size: vw(28);
								color: #999999;
							}

							.content {
								width: 50%;
								font-size: vw(28);
								color: #333333;
								text-align: right;
							}
						}

						.button {
							width: vw(400);
							height: vw(52);
							border-radius: vw(27);
							border: vw(1) solid rgba(#347FFF, 0.76);
							font-size: vw(24);
							color: #347FFF;
							background: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: vw(20) auto;

							span {
								margin-top: vw(4);
							}
						}
					}

				}
			}
		}
	}
</style>
