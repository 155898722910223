<template>
  <div class="paskages">
    <StateBar></StateBar>
    <TitleHeader title="我的购买" bgcolor="transparent" :back="showBack">
      <div slot="right" class="l-header--r" @click="swRouter('/my/packages/history')">套餐使用明细</div>
    </TitleHeader>

    <AutoView :header="true" :footer="false" class="paskages--view l-view">
      <div class="s-tabs s-radius__s">
        <span
          v-for="(name, key) in stateMapping"
          :key="key"
          class="s-tab s-radius__xs"
          :class="{ on: status == key }"
          @click="status = parseInt(key)"
          >{{ name }}</span
        >
      </div>
      <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <EmptyCard v-if="!list[status].list.length" :type="status === 1 ? 'packages' : 'packagesNormal'"></EmptyCard>
        <template v-else>
          <van-list v-model="loading" :finished="list[status].finished" finished-text="没有更多了" @load="loadData">
            <PackageCard
              v-for="(item, index) in list[status].list"
              :key="item.id"
              :class="{ 'f-vmt': index > 0 }"
              :status="status"
              :item="item"
            />
          </van-list>
        </template>
      </van-skeleton>
    </AutoView>
  </div>
</template>

<script>
import PackageCard from "@/components/card/package";

export default {
  name: "paskages",
  components: {
    PackageCard,
  },
  data() {
    return {
      showBack: true,
      firstLoading: true,
      loading: true,
      stateMapping: {
        1: "未使用",
        2: "已使用",
        3: "已过期",
      },
      status: 1,
      list: {
        1: {
          finished: false,
          recent: null,
          remote: null,
          list: [],
        },
        2: {
          finished: false,
          recent: null,
          remote: null,
          list: [],
        },
        3: {
          finished: false,
          recent: null,
          remote: null,
          list: [],
        },
      },
    };
  },
  watch: {
    status(val) {
      if (!this.list[val].finished) this.loadData();
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      this.HTTP.packageList(this.list[this.status].remote, "before", this.status)
        .then((res) => {
          this.firstLoading = false;
          this.loading = false;
          if (this.list[this.status].list.length) {
            this.list[this.status].list = this.list[this.status].list.concat(res.list);
          } else {
            this.list[this.status].list = res.list;
          }
          if (this.list[this.status].list.length) {
            this.list[this.status].recent = this.list[this.status].list[0].id;
            this.list[this.status].remote = this.list[this.status].list[this.list[this.status].list.length - 1].id;
          }
          this.list[this.status].finished = !res.more;
          console.log(res);
        })
        .catch((e) => {
          // console.log( e );
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
        });
    }
  },
  created() {
    this.loadData();
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    this.JSBridge.callHandler("clearHistory", "my");
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.paskages {
  height: 100vh;
  background-color: $c-pageBg;

  .s-tabs {
    display: flex;
    margin-bottom: $margin;

    .s-tab {
      flex: 1;
    }
  }

  #{&}--view {
    .l-panel {
      padding: $padding2;
    }
  }
}
</style>
