<template>
  <div class="brand-horizontal-box">
    <div class="brand-horizontal-box-left">
      <div class="brand-rule-tag" v-if="brandData.rulesTag1">
        <p class="tag-content">
          <img class="rule-icon" src="/static/brandAlliance/brand-rule-icon.png" />
          <span>{{ brandData.rulesTag1 }}</span>
        </p>
      </div>
      <van-image class="brand-logo" fit="cover" :src="brandData.logoUrl" />
    </div>
    <div class="brand-horizontal-box-right">
      <div class="brand-info-top">
        <div class="brand-name">{{ brandData.brandName }}</div>
        <div class="brand-hot" v-if="brandData.heatIndex">
          <img class="hot-icon" src="/static/brandAlliance/hot.png" alt="" />
          <span class="hot-text">热度指数</span>
          <span class="hot-count">{{ brandData.heatIndex }}</span>
        </div>
      </div>
      <div class="brand-info-middle">
        <span class="brand-price-range" v-if="brandData.minCost || brandData.maxCost">
          ¥
          <span v-if="brandData.minCost">{{ brandData.minCost }}</span>
          <span v-if="brandData.minCost">-</span>
          <span v-if="brandData.maxCost">{{ brandData.maxCost }}</span>
          万起
        </span>
        <span class="brand-price-range" v-else>-万起</span>
        <span class="brand-type">{{ brandData.newCategoryNameL1 }}<span v-if="brandData.newCategoryNameL1 && brandData.newCategoryNameL2">｜</span>{{ brandData.newCategoryNameL2 }}</span>
      </div>
      <div class="brand-auth-tags">
        <div class="brand-auth-tag brand-auth-tag1" v-if="brandData.authenticationTag && brandData.authenticationTag.indexOf('上上参谋认证') > -1">
          <img class="auth-icon" src="/static/brandAlliance/tag1.png" alt="" />
          <span>上上参谋认证</span>
        </div>
        <div class="brand-auth-tag brand-auth-tag2" v-if="brandData.authenticationTag && brandData.authenticationTag.indexOf('商务部备案') > -1">
          <img class="auth-icon" src="/static/brandAlliance/tag2.png" alt="" />
          <span>商务部备案</span>
        </div>
      </div>
      <div class="brand-tags-box" v-if="brandTags.length">
        <div class="brand-tags" @touchmove.stop>
          <span class="brand-tag" v-for="(tag, i) in brandTags" :key="i">{{ tag }}</span>
        </div>
        <div class="tag-mask"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'brand-horizontal-box',
  props: {
    brandData: null
  },
  computed: {
    brandTags() {
      if (!this.brandData) return []
      let arr1 = []
      if (this.brandData.rulesTag2) {
        arr1 = this.brandData.rulesTag2.split(',')
      }
      return arr1
    }
  },
  data() {
    return {
    }
  }
};
</script>

<style lang="scss" scoped>
  .brand-horizontal-box {
    display: flex;
    //align-items: center;
    background: #FFFFFF;
    border-radius: vw(10);
    width: 100%;
    padding: vw(20);
    box-sizing: border-box;
    .f1 {
      font-weight: bold;
      vertical-align: middle;
    }
    .brand-horizontal-box-left {
      position: relative;
      width: vw(180);
      height: vw(180);
      background: #f7f7f7;
      margin-right: vw(20);
      .brand-logo {
        width: 100%;
        height: 100%;
        border-radius: vw(8);
        //border: vw(1) solid #E4E7ED;
        ::v-deep .van-image__img {
          border-radius: vw(8);
          border: 1px solid #E4E7ED;
        }
      }
      .brand-rule-tag {
        position: absolute;
        top: 0;
        left: vw(8);
        border-radius: 0 vw(4) vw(4) 0;
        color: #A33F00;
        font-size: vw(16);
        z-index: 1;
        .tag-content {
          background: linear-gradient(270deg, #FFEE99 0%, #FFD670 100%);
          display: flex;
          align-items: center;
          padding-right: vw(10);
          height: vw(30);
          border-radius: 0 vw(4) vw(4) 0;
          .rule-icon {
            width: vw(34);
            height: vw(34);
            margin-left: vw(-20);
            margin-right: vw(4);
          }
        }
      }
    }
    .brand-horizontal-box-right {
      flex: 1;
      overflow: hidden;
      height: vw(180);
      .brand-tags-box {
        margin-top: vw(4);
        position: relative;
        .tag-mask {
          background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
          position: absolute;
          width: vw(30);
          height: 100%;
          top: 0;
          right: 0;
        }
      }
      .brand-tags {
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        height: 100%;
        .brand-tag {
          background: rgba(255,128,71,0.1000);
          border-radius: vw(4);
          color: #FF8047;
          font-size: 10px;
          line-height: vw(36);
          padding: 0 vw(12);
          margin-right: vw(8);
          display: inline-block;
        }
      }
      .brand-auth-tags {
        margin-top: vw(10);
        display: flex;
        .brand-auth-tag {
          font-size: vw(20);
          border-radius: vw(4);
          padding: vw(6) vw(10) vw(6) vw(6);
          display: flex;
          align-items: center;
          margin-right: vw(8);
          .auth-icon {
            width: vw(24);
            height: vw(24);
            margin-right: vw(5);
          }
          &.brand-auth-tag1 {
            background: linear-gradient(90deg, #FFF9E4 0%, #FFF2C5 100%);
            border: vw(1) solid #F5CF98;
            color: #904921;
          }
          &.brand-auth-tag2 {
            background: linear-gradient(90deg, #F2F9FD 0%, #E1F0FB 100%);
            border: vw(1) solid #B8D7F1;
            color: #3F80BD;;
          }
        }
      }
      .brand-info-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        .brand-hot {
          background: linear-gradient(90deg, #F7F8FB 0%, rgba(247,248,251,0) 100%);
          border-radius: vw(100) 0 0 vw(100);
          padding: 0 vw(20) 0 vw(10);
          display: flex;
          align-items: center;
          height: vw(36);
          .hot-icon {
            width: vw(24);
            height: vw(24);
            margin-right: vw(4);
          }
          .hot-text {
            color: #757A8F;
            font-size: vw(20);
            margin-right: vw(10);
          }
          .hot-count {
            color: #A33F00;
            font-size: vw(22);
            font-weight: bold;
            background: linear-gradient(270deg, #D05508 0%, #E97F10 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .brand-name {
          flex: 1;
          line-height: vw(40);
          font-size: vw(28);
          color: #1F2435;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .brand-info-middle {
        display: flex;
        align-items: baseline;
        margin-top: vw(6);
        overflow: hidden;
        line-height: vw(40);
        .brand-price-range {
          font-size: vw(20);
          color: #FF504E;
          margin-right: vw(20);
          display: flex;
          align-items: baseline;
          //font-weight: bold;
          span {
            font-size: vw(28);
          }
        }
        .brand-type {
          color: #757A8F;
          font-size: vw(20);
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow:ellipsis;
        }
      }
    }
  }
</style>
