<template>
    <div>
        <StateBar />
        <TitleHeader title="选址案例-商铺评估报告" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #f3f4f7" />
        <AutoView :header="true" :footer="true">
            <div class="transferShops">
                <div class="big-title">商铺评估报告 | 购物中心底商</div>
                <div class="small-title"><img src="../../../../static/shopEcology/address-icon.png" alt="" />深圳市宝安区西乡大仟里购物中心</div>
                <!-- 	<div class="video-box">
					<PlayVideo src="https://media.swguancha.com/pc-videos/1.mp4" />
				</div> -->
                <img class="img" src="../../../../static/shopEcology/case1-1-1.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-2.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-3.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-4.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-5.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-6.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-7.png" alt="" />
                <img class="img" src="../../../../static/shopEcology/case1-1-8.png" alt="" />
            </div>
        </AutoView>
        <div class="button-bottom-box">
            <div class="right-box" @click="pageSubmit">帮我选址</div>
        </div>
        <!-- 提交选址意向 -->
        <intention-mask v-if="intentionMaskType" @submit="intentionSubmit" @close="intentionMaskType = false"> </intention-mask>
    </div>
</template>

<script>
import PlayVideo from "@/components/VideoPlay/index";
import intentionMask from "../components/intention-mask.vue";
import { saveSitingIntention } from "@/request/shopEcology";
export default {
    data() {
        return {
            isSubmit: false,
            intentionMaskType: false,
        };
    },
    mounted() {},
    components: {
        PlayVideo,
        intentionMask,
    },
    methods: {
        pageSubmit() {
            // 帮我选址
            // this.intentionMaskType = true
            if (window._appCode && window._appCode >= 307) {
                this.swRouter({
                    path: "/shopEcology/intentionSubmit",
                });
            } else {
                this.intentionMaskType = true;
            }
        },
        intentionSubmit(form) {
            // 选址意向
            if (this.isSubmit) return;
            this.isSubmit = true;
            saveSitingIntention(form).then((res) => {
                this.intentionMaskType = false;
                this.swRouter({
                    path: "/shopEcology/intentionsubmitSuccessful",
                    query: {
                        id: res,
                    },
                });
            }).finally(e => {
				this.isSubmit = false;
			});
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
    @return $args/750 * 100 + vw;
}

.transferShops {
    padding: vw(42) vw(28) vw(0);
    background: #f2f3f8 url("../../../../static/shopEcology/case1.png") no-repeat;
    background-size: 100% vw(562);
    box-sizing: border-box;

    .big-title {
        height: vw(38);
        font-size: vw(38);
        font-weight: 600;
        line-height: vw(38);
        color: #ffffff;
    }

    .small-title {
        height: vw(33);
        font-size: vw(24);
        font-weight: 600;
        line-height: vw(33);
        color: #ffffff;
        margin-top: vw(18);
        display: flex;
        margin-bottom: vw(10);

        img {
            width: vw(21);
            height: vw(25);
            margin-right: vw(14);
        }
    }

    // .video-box {
    // 	margin-top: vw(40);
    // }

    .img {
        width: 100%;
        margin-top: vw(20);
    }
}

.button-bottom-box {
    width: 100%;
    padding: vw(15) vw(30) vw(22);
    box-sizing: border-box;
    background: #fff;
    z-index: 300;
    box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);

    .right-box {
        background: #347fff;
        border-radius: vw(10);
        height: vw(80);
        font-size: vw(30);
        font-weight: 600;
        color: #ffffff;
        line-height: vw(80);
        text-align: center;
    }
}
</style>
