<template>
    <van-dialog v-model="isShow" title="" class="van_dia" :showCancelButton="false" :showConfirmButton="false" >
        <!-- <img src="https://img.yzcdn.cn/vant/apple-3.jpg" /> -->
        <img src="/static/icon/msg_success_green.png" alt="">
        <h3 >{{title}}</h3>
        <p v-html="popText"></p>
        <button class="pop_btn" @click="openConfirm">确定</button>
    </van-dialog>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
            },
            popText: {
                type: String,
            }
        },
        data () {
            return {
                isShow: false,
                setup() {
                    const show = ref(false);
                    return { show }
                }
            }
        },
        methods: {
            getParams() {
                this.isShow = !this.isShow;
            },
            openConfirm() {
                this.isShow = false;
                this.$emit("confirmBtn")
            },
        },
    }
</script>
<style lang="scss" scoped>
    // @import '@/styles/index.scss';
    .van_dia{
        padding: 5.07vw;
        text-align: center;
        box-sizing: border-box;
        img{
            width: 21.33vw;
            height: 21.33vw;
            padding: 4.53vw 0 4vw;
        }
        h3{
            font-size: 4.27vw;
            font-weight: bold;
            color: #333333;
            line-height: 5.87vw;
        }
        p{
            margin-top: 2.13vw;
            font-size: 3.47vw;
            color: #8E9099;
            line-height: 5.2vw;
            margin-bottom: 3.73vw;
        }
        .pop_btn{
            height: 12vw;
            border-radius: 1.33vw;
            background: #518CDB;
            color: #fff;
            font-size: 4vw;
            font-weight: bold;
            width: 100%;
            border: none;
        }
        ::v-deep .van-button{
            display: none;
            height: 12vw;
            border-radius: 1.33vw;
            background: #518CDB;
            color: #fff;
            font-size: 4vw;
            font-weight: bold;
        }
        ::v-deep .van-hairline--top::after{
            display: none;
            border-top-width: 0px;
        }
    }
</style>
