<template>
    <div class="article">
        <StateBar bgcolor="white"></StateBar>
        <TitleHeader v-if="!isWX" :title="title" :back="showBack"></TitleHeader>
        <div v-if="!isClient && !isWX" class="share">
            <p class="f-fw">上上参谋</p>
            <p>生意人的数据军师</p>
            <a ref="openApp" :href="link" target="_blank" class="app">APP查看</a>
        </div>

        <!-- 骨架屏占位图 -->
        <img v-if="loading" src="/static/skt/article.png" width="100%" />

        <AutoView :header="!isWX" :footer="showFooter" class="article--view" :class="{ full: isWX }" @scroll.native="pageScrollHandle">
            <template v-if="info">
                <h1 class="title" ref="title">{{ info.title || '---' }}</h1>
                <div class="info">
                    <span class="highlight">{{ info.formattedClickCount || 1 }}人浏览 • </span>
                    <span>{{ info.gmtCreate }}</span>
                </div>
                <div ref="content" class="content" @click="onClick" v-html="info.content"></div>
            </template>
        </AutoView>

        <SoltFooter v-show="showFooter" bgcolor="#F7F6F9" class="article--footerBar">
            <van-row class="view">
                <van-col span="17" class="left" @click="selectAll = ! selectAll">
                    <p class="secondary-text">猜你想问</p>
                    <p class="primary-text">{{ info ? info.questionTitle : '---' }}</p>
                </van-col>
                <van-col span="7" class="f-tar">
                    <van-button type="info" class="action-btn s-radius__s" @click="goto">参谋一下</van-button>
                </van-col>
            </van-row>
        </SoltFooter>
    </div>
</template>

<script>
import { ImagePreview } from 'vant';

export default {
    name: 'articleView',
    data () {
        return {
            showTitle : false,
            showBack  : true,
            showFooter: false,
            id        : null,
            loading   : true,
            info      : null,
            isWX      : false,
            isClient  : false,
            link      : this.HTTP.getAppStore(),
        };
    },
    methods: {
        loadData () {
            this.id = this.$route.params.id;
            this.HTTP.getArticle( this.id ).then( ( res ) => {
                // console.log( 'getArticle:', res );
                this.info       = res;
                this.loading    = false;
                this.showFooter = false; // !! ( res.questionId && res.questionTitle );
            } );
        },
        pageScrollHandle () {
            this.showTitle = this.$refs.title.getBoundingClientRect().top < 0;
        },
        goto () {
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.ARTICLE.TAP_ADVISE );
            // 跳转至提问界面
            const title = this.info.questionTitle || null;
            this.swRouter( {
                path : '/additional/' + this.info.questionId,
                query: {
                    title,
                },
            } );
        },
        switchShare ( st ) {
            console.log( 'appCode:', window._appCode );
            this.JSBridge.callHandler( 'switchShareBtnV2', ! st ? false : {
                code: 'ARTICLE',
                id  : this.id
            } );
            const img = this.$refs.content.querySelector( 'img' );
            this.JSBridge.callHandler( 'switchShareBtn', ! st ? false : {
                type  : 'article',
                id    : this.id,
                title : this.info.title,
                desc  : this.$refs.content.textContent.substr( 0, 32 ),
                cover : img ? img.src : document.location.protocol + '//' + document.location.host + '/static/img/default_cover.jpg',
                url   : location.href,
                page  : '/pages/webview/index?type=ARTICLE&articleId=' + this.id,
                target: window._appCode === 15 ? 'wxmp_tools' : 'wxmp_report',
            } );
        },
        onClick ( e ) {
            if ( e.target && e.target.nodeName === 'IMG' ) {
                this.showBack = false;
                this.switchShare( false );
                ImagePreview( {
                    images   : [ e.target.src ],
                    loop     : false,
                    showIndex: false,
                    closeable: false,
                    onClose  : () => {
                        this.showBack = true;
                        this.switchShare( true );
                    },
                } );
            }
        },
    },
    computed: {
        title () {
            return this.showTitle && ! this.loading ? this.info.title : '';
        },
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler () {
                this.loadData();
            }
        },
        info () {
            this.$nextTick( () => {
                this.switchShare( true );
            } );
        },
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        this.isWX = window.isMini;
        this.isClient = window.isClient;
    },
    beforeDestroy () {
        // 隐藏分享按钮
        this.switchShare( false );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.article {
    height: 100vh;
    background-color: white;

    #{&}--view {
        padding: $margin $padding $padding;
        &.full {
            height: 100vh;
        }
        .title {
            @include font($c-text, $font_560, $weight_500);
            line-height: 8vw;
            padding-bottom: 4vw;
            font-weight: bold;
        }
        .info {
            @include font($c-gray3, $font_347, $weight_300);
            line-height: 4.93vw;
            margin-bottom: 6.13vw;
            .highlight {
                color: $c-main;
            }
        }
        .content {
            font-size: $font_373;
            line-height: normal;
            letter-spacing: 0.13vw;
            ::v-deep img {
                width: 100%;
                height: auto!important;
                @extend .s-radius__s;
            }
            ::v-deep ol, ::v-deep ul{
                list-style:initial;
                list-style-type:decimal;
                >li{
                    list-style-type:initial;
                    list-style: initial;
                }
            }
            ::v-deep p {
                font-size: $font_373;
                line-height: normal;
                letter-spacing: 0.13vw;
                &.ql-align-center {
                 text-align: center;
                }
                &.ql-align-justify {
                    text-align: justify;
                }
                &.ql-align-right {
                    text-align: right;
                }
            }
        }
    }

    #{&}--footerBar {
        .view {
            width: 100%;
            height: $footerHeight;
            padding: $margin $padding;
            box-sizing: border-box;
        }
        .secondary-text {
            color: $c-info;
            font-size: $font_267;
            line-height: $font_373;
        }
        .primary-text {
            @include singleLine;
            color: $c-main;
            font-size: $font_320;
            line-height: $font_440;
            font-weight: bold;
            margin-top: .5vw;
        }
        ::v-deep .van-col {
            height: 100%;
        }
        ::v-deep .van-button {
            width: 19.47vw;
            height: 100%;
            font-size: $font_320;
            padding: 0;
            line-height: normal;
        }
    }

    .share {
        position: fixed;
        top: 0;
        width: 100%;
        height: $headerHeight;
        background: rgba(black, .8) url(/static/icon/logo@96.png) no-repeat $margin center;
        background-size: $headerHeight * 0.7;
        box-sizing: border-box;
        color: white;
        padding-left: $headerHeight * 1.1;
        p {
            font-size: $font_320;
            padding-top: $margin * 0.8;
        }
        .f-fw {
            font-size: $font_400;
        }
        .app {
            position: absolute;
            right: $margin;
            top: 25%;
            height: 50%;
            color: white;
            background-color: $c-main;
            padding: 0 $margin;
            overflow: hidden;
            border-radius: 10vw;
            font-size: $font_320;
            @include flexColumn;
        }
    }
}
</style>
