<template>
    <div class="brand-wrap" @click="$emit('reportCharts', item.brandCode)">
        <ul class="flex-ul">
            <li class="left">
                <van-image radius="3" class="icon-img" fit="cover" :src="item.logo" />
            </li>
            <li class="right">
                <div class="top">
                    {{ item.name }}
                    <van-tag style="border: 1px solid #ff9f6b; border-radius: 3px" color="#FFF1E9" text-color="#FF5900" v-if="item.categoryName">
                        {{ item.categoryName }}
                    </van-tag>
                </div>
                <p class="mid">人均消费：<span>{{ item.avePrice ? item.avePrice + "元" : '-' }}</span></p>
                <p class="bottom">
                    在营门店：<span>{{ item.openingNumber ? item.openingNumber + '家' : '-' }}</span>
                </p>
            </li>
        </ul>
    </div>
</template>
<script>
import { setLocalStorage } from "@/utils/index";
export default {
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        onTap() {
            this.$emit("click", this.item);
        },
        onTap2() {
            this.$emit("click2", this.item);
        },
        openDetail(code) {
            setLocalStorage("versionCode", window._appCode);
            this.swRouter("/brand/details/" + code);
        },
    },
};
</script>
<style lang="scss" scoped>
.brand-wrap {
    background: #fff;
    box-shadow: 0 0 2.13vw 0 rgba(0, 0, 0, 0.09);
    border-radius: 1.33vw;
    margin-bottom: 2.67vw;

    .flex-ul {
        display: flex;
        padding: 3.2vw 4vw;
        .left {
            flex: 0 0 22.67vw;
            .icon-img {
                width: 20vw;
                height: 20vw;
                border-radius: 1.07vw;
                object-fit: cover;
            }
        }
        .right {
            flex: 1;
            color: #8e9099;
            .top {
                line-height: 5.87vw;
                font-size: 4vw;
                color: #1d2233;
                font-weight: bold;
            }
            .mid {
                line-height: 4.8vw;
                font-size: 3.47vw;
                margin-top: 1.87vw;
            }
            .bottom {
                line-height: 4.8vw;
                font-size: 3.47vw;
                margin-top: 1.07vw;
            }
            span {
                color: #666;
            }
        }
    }
}
</style>
