<template>
    <Wraper title="网友推荐单品" v-if="bgmData.length > 0">
        <ul class="item-wrap" v-for="(item, idx) in bgmData" :key="item.key">
            <li class="left">
                <img class="top-icon" src="/static/report/hot-take-1.png" alt="" v-if="idx == 0" />
                <img class="top-icon" src="/static/report/hot-take-2.png" alt="" v-else-if="idx == 1" />
                <img class="top-icon" src="/static/report/hot-take-3.png" alt="" v-else-if="idx == 2" />
                <span class="num-icon" v-else>{{ idx + 1 }}.</span>
            </li>
            <li class="mid">
                <p class="name">{{ item.code || "-" }}</p>
                <p class="pirce"><i class="i">价格：</i> {{ item.ext1 }}元</p>
            </li>
            <li class="right">
                <img class="good-icon" src="/static/report/icon_good.png" alt="" />
                {{ item.count || "-" }}
            </li>
        </ul>
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";

export default {
    props: {
        bgmData: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Wraper,
    },
};
</script>

<style lang="scss" scoped>
.item-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
    border-radius: 1.33vw;
    border: 0.27vw solid #e7e7e7;
    box-sizing: border-box;
    padding: 2.67vw 4vw;
    margin-bottom: 2.67vw;

    .left {
        flex: 0 0 7.47vw;
        text-align: center;

        .top-icon {
            width: 5.87vw;
            height: 7.47vw;
        }

        .num-icon {
            font-size: 3.47vw;
            font-weight: bold;
            color: #ff5900;
        }
    }

    .mid {
        flex: 1;
        text-align: left;
        padding-left: 2.13vw;

        .name {
            height: 5.87vw;
            line-height: 5.87vw;
            font-size: 4vw;
            font-weight: bold;
            color: #1d2233;
        }

        .pirce {
            height: 4.8vw;
            line-height: 4.8vw;
            font-size: 3.47vw;
            color: #666;
            margin-top: 2.13vw;

            .i {
                font-style: normal;
                color: #8e9099;
            }
        }
    }

    .right {
        flex: 0 0 18.13vw;
        font-size: 3.2vw;
        color: #666;
        align-self: flex-start;
        margin-top: 0.53vw;

        .good-icon {
            width: 4vw;
            height: 4vw;
            vertical-align: middle;
        }
    }
}
</style>
