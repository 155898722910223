<template>
    <div class="store-map">
        <StateBar />
        <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true">
<!--            <span slot="right" class="link-txt" @click="showTip = true">地图指南</span>-->
        </TitleHeader>

        <van-tabs v-model="current" swipeable line-width="30" color="#347FFF" @change="tabsChange" v-if="tabsList && tabsList.length">
            <template v-for="item in tabsList">
                <van-tab v-if="!item.show" :title="item.name" :key="item.id">
                    <AutoView ref="view" class="store-map--view l-view">
                        <van-skeleton :loading="firstLoading || loading" :animate="false" :row="4" class="s-skeleton s-radius__s">
                            <component :is="item.compName"></component>
                        </van-skeleton>
                    </AutoView>
                </van-tab>
            </template>

        </van-tabs>
        <van-popup v-model="showTip">
            <div class="tip-box">
                <van-icon name="cross" @click="closeTip"/>
                <AutoView class="tip-content">
                <div class="one-qa" v-for="(item, i) in questions" :key="i">
                    <p class="question">{{ item.question }}</p>
                    <p class="answer" v-for="(ans, idx) in item.answers" :key="idx">{{ ans }}</p>
                </div>
                </AutoView>
                <div class="open-map">
                    <van-button type="info" @click="closeTip">点击开启地图</van-button>
                </div>
            </div>
        </van-popup>
        <div class="suspended" @click="gotoDiscuss" v-if="!isCloseComment">
            <img class="discuss" src="/static/img/store-map/discuss.png" alt="">
        </div>
    </div>
</template>
<script>
import VideoCard from './components/video-card.vue';
import ProjectExploratory from './components/ProjectExploratory.vue';
import SiteSelection from './components/SiteSelection.vue';
import StorePreparation from './components/StorePreparation.vue';
import OperatingManagement from './components/OperatingManagement.vue';
import College from './components/College';

const tabsList = [
    {
        name    : '项目考察',
        id      : 1,
        compName: 'ProjectExploratory',
        show    : sessionStorage.getItem('platform')
    },
    {
        name    : '开店选址',
        id      : 2,
        compName: 'SiteSelection'
    },
    {
        name    : '开店筹备',
        id      : 3,
        compName: 'StorePreparation'
    },
    {
        name    : '经营管理',
        id      : 4,
        compName: 'OperatingManagement'
    },
    {
        name    : '精选课程',
        id      : 5,
        compName: 'College'
    }
];

const questions = [
    {
        question: '开一家店到底有多难？',
        answers : [
            '大到选项目选址，小到菜单设计，至少有365个事项需要决策！【开店地图】应运而生，梳理开店全流程知识科普和注意事项，帮您查缺补漏、规避风险！'
        ]
    },
    {
        question: '我们将开店分为4个阶段：',
        answers : [
            '项目考察：选定开什么店，加盟好还是自营？',
            '开店选址：选商圈选铺位，全方面选址评估',
            '开店筹备：涵盖装修设计选品进货，面面俱到',
            '经营管理：从吸引客流到营销秘诀，认真搞钱'
        ]
    },
    {
        question: '您可以利用本地图做什么：',
        answers : [
            '开店前：了解开店全流程，做到心中有数',
            '开店中：让开店地图辅助你检查，查缺补漏',
            '开店后：从管理到营销，成功的经营方法可复制'
        ]
    },
    {
        question: '希望通过我们的努力，让您开好店！真正做到，地图在手，开店无忧！',
        answers : []
    }
];

export default {
    components: { VideoCard, ProjectExploratory, SiteSelection, StorePreparation, OperatingManagement, College },
    data () {
        return {
            questions,
            showTip     : false,
            loading     : false,
            firstLoading: true,
            title       : '开店必学',
            id          : '',
            current     : 4,
            tabsList    : tabsList,
            isCloseComment: false
        };
    },
    created () {
        this.isCloseComment = window.isCloseComment
        this.id = this.$route.query.id || null;
        this.showTip = !this.$store.getters.showStoreMapTip
    },
    mounted () {
        this.firstLoading = false;
    },
    methods: {
        tabsChange ( index, title ) {
            if ( this.loading ) {
                this.$toast( '数据加载中，请勿过快切换！' );
                return;
            }

            // 模块埋点上报 -- 暂时不做
            // this.JSBridge.BTNCLICK(this.$route.meta.id, "", "", "");

            const tabs = this.tabsList.filter( ( item ) => {
                return item.name == title;
            } );
            this.id = tabs[0].id;
        },
        closeTip () {
            this.showTip = false
            this.$store.commit('setShowStoreMapTip', true)
        },
        gotoDiscuss() {
            this.swRouter("/community/discuss")
        },
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.store-map {
    height: 100vh;
    overflow: hidden;

    #{&}--view {
        scroll-behavior: smooth;
        background: #f7f7f7;
        padding-bottom: 35vw;
    }
    .l-view {
        padding: 0;
        padding-bottom: 10vw;
    }
    .link-txt {
        color: #347fff;
    }
    .van-popup {
        border-radius: 2.66vw;
    }
    .tip-box {
        box-sizing: border-box;
        width: 92vw;
        padding: 4vw;
        padding-right: 0;
        padding-bottom: 8vw;
        background: url('/static/img/store-map/tip-bg.png') bottom center / 100% auto no-repeat;
        position: relative;
        .tip-content {
          height: 60vh;
          max-height: 100vw;
          overflow-y: auto;
          padding-right: 4vw;
        }
        .van-button {
            width: 100%;
        }
        .van-icon {
            position: absolute;
            right: 4vw;
            z-index: 20;
        }
        .one-qa {
            margin-bottom: 3.73vw;
            line-height: 5.86vw;
            .question {
                color: #333;
                font-size: 4vw;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
            }
            .answer {
                color: #666;
                font-size: 3.46vw;
                font-family: PingFangSC-Regular, PingFang SC;
                padding-left: 2.93vw;
                margin-top: 1.46vw;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    width: 2.13vw;
                    height: 2.13vw;
                    background: #347fff;
                    border-radius: 50%;
                    left: 0;
                    top: 1.86vw;
                }
            }
        }
        .open-map {
            padding-right: 4vw;
        }
    }
}

.suspended {
        position: fixed;
        bottom: 16.667vw;
        right: 0;
        width: 12.8vw;
        height: 11.2vw;
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
        border-top-left-radius: 5.6vw;
        border-bottom-left-radius: 5.6vw;
    .discuss {
        width: 9.867vw;
        height: 9.867vw;
        margin: 0.8vw;
    }
}

::v-deep .van-tab--active {
    font-size: 15px;
    color: #323233;
    font-weight: 600;
}
</style>
