<template>
  <div class="page-container">
    <StateBar />
    <div class="search-box">
      <searchHeader @input="input" @search="searchKey" />
    </div>
    <AutoView ref="view" :header="true" :footer="false" class="search--view">
      <div class="search--main" ref="main">
        <div class="community-list" v-if="communityList.length">
          <div class="community-item" @click="goPage(community.name)" v-for="community in communityList">
            <div class="community-item-left">
              <div class="community-name">{{ community.name }}</div>
              <div class="community-address"><span>{{ cityName }}</span><span>{{ community.region }}</span></div>
            </div>
            <div class="community-item-right">{{ community.households || '-' }}户</div>
          </div>
        </div>
        <div class="empty-card" v-if="!firstLoad || !communityList.length">
            <img class="empty-icon" src="/static/cellScreen/empty2.png" />
            <img class="empty-text-icon" v-if="firstLoad" src="/static/cellScreen/no-data-text.png" />
            <img class="empty-text-icon2" v-else src="/static/cellScreen/data-text.png" />
            <p class="no-data-text" v-if="firstLoad">可以换个词重新试试</p>
        </div>
      </div>

    </AutoView>
  </div>
</template>

<script>
import { throttle } from '../../utils';

const searchHeader = () => import('./components/searchHeader')
import { EventBus } from './eventBus'
export default {
  name: 'search',
  components: {
    searchHeader,
  },
  data() {
    return {
      form: {
        keyWord: '',
        current: 1,
        size: 15,
        cityCode: '', // 城市code
        circleCode: null, // 片区编码
        communityPeopleNum: 'house', // 户数
        price: 'price', // 房价
        avoidBrands: [], // 避免品牌
        preferenceBrands: [], // 偏好品牌
        avoidCategorys: [], // 避免业态
        preferenceCategorys: [], // 偏好业态
        sortKey: 'sort', // 排序字段key
        houseSpecial: 'house_special',
        searchType: 1, // 搜索类型 0 消耗次数 1 不消耗次数
      },
      enableLoadMore: false,
      communityList: [],
      loading: true,
      cityName: '',
      firstLoad: false,
      timer: null,
      source: ''
    }
  },
  beforeMount () {
    this.JSBridge.callHandler( 'switchBackBtn', false );
  },
  mounted () {
    this.form.cityCode = this.$route.query.cityCode || '440300'
    this.cityName = this.$route.query.cityName || '深圳市'
    this.source = this.$route.query.source || ''
  },
  beforeDestroy () {
    this.$store.commit('setValidatePopup', true)
    this.$store.commit('setApiConfigList', null)
  },
  methods: {
    searchKey(val) {
      if (!val) {
        this.communityList = []
        this.form.keyWord = val
      }
    },
    goPage(val) {
      if (this.source) {
        localStorage.setItem('communitySearch', val || '')
        this.commonApi.pageBack()
        // EventBus.$emit('communitySearch', { name: val || '' })
        // this.$router.back(-1)
      } else {
        this.swRouter('/cellScreen?keyword=' + val)
        this.JSBridge.callHandler("closePage");
      }
      this.JSBridge.callHandler("switchBackBtn", true);
    },
    input(val) {
      this.form.keyWord = val
      if (!val) {
        this.communityList = []
        return
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.form.current = 1
        this.search()
      }, 200)
    },
    loadMore() {
      this.form.current++
      this.search()
    },
    search() {
      const requestFunc = throttle(this.queryData, 500)
      requestFunc()
    },
    queryData() {
      this.loading = true
      this.firstLoad = false
      this.HTTP.queryScreenInitialize({ cityName: this.cityName, searchType: 1, keyWord: this.form.keyWord }).then(res => {
        this.communityList = res.data.poi
        this.firstLoad = true
      }).catch(() => {
        this.firstLoad = true
      })
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.van-list {
  .van-list__finished-text {
    font-size: 3.2vw;
    color: #BDBDBD;
  }
}
  .page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .search-box {
      padding: 10px 0 10px 4vw;
    }
    .search--view {
      scroll-behavior: smooth;
      position: relative;
      background: #ffffff;
      flex: 1;
    }
    .search--main {
      position: relative;
      padding: 4vw;
      height: 100%;
      box-sizing: border-box;
      overflow-y: auto;
      .empty-card {
        background: #fff;
        border-radius: 1.87vw;
        height: 106.67vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        .empty-icon {
          width: 23.2vw;
          height: 23.2vw;
          margin-top: 32.67vw;
        }
        .empty-text-icon {
          width: 19.733vw;
          height: 4vw;
          margin-top: 4vw;
        }
        .empty-text-icon2 {
          width: 11.733vw;
          height: 4vw;
          margin-top: 4vw;
        }
        .no-data-text {
          font-weight: 400;
          color: #8E9099;
          font-size: 3.47vw;
          margin-top: 2.4vw;
        }
      }
      .community-list {
        .community-item {
          font-weight: 400;
          color: #666666;
          font-size: 3.467vw;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 0.1333vw solid #E7E7E7;
          padding: 12px 0 4vw;
          .community-item-left {
            flex: 1;
            overflow: hidden;
            .community-name {
              font-size: 4vw;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #333333;
              line-height: 22px;
              span {
                color: #347FFF;
              }
            }
            .community-address {
              margin-top: 1.867vw;
              span {
                margin-right: 1.333vw;
              }
            }
          }
          .community-item-right {
            margin-left: 1.333vw;
          }
        }
      }
    }
  }
</style>
