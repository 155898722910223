// 订单支付接口
import {
    Axios
  } from './http'

  const commonRequest = (config) => {
    return new Promise((resolve, reject) => {
      Axios.request(config).then(res => {
        resolve(res.data.data);
      }).catch(e => {
        reject(e);
      });
    })
  }

// 商品下单
export const placeGoods = (data) => {
    return commonRequest({
      url: '/v1/order/place/goods',
      method: 'post',
      data
    });
  }

  // 购买会员下单
export const placeMember = (data) => {
    return commonRequest({
      url: '/v1/order/place/member',
      method: 'post',
      data
    });
  }

    // 单次解锁下单
export const pgk = (data) => {
    return commonRequest({
      url: '/v1/order/place/pgk',
      method: 'post',
      data
    });
  }

      // 服务市场下单接口
export const servicePayOrder = (data) => {
    return commonRequest({
      url: '/v1/order/place/market',
      method: 'post',
      data
    });
  }

        // 检查特惠商品
export const getCrowd = () => {
  return commonRequest({
    url: '/v1/memberSpecialCrowd/getCrowd',
    method: 'get',
  });
}

export const settlementV4NoReport = (data) => {
  return commonRequest({
    url: '/v4/order/settlement/noReport',
    method: 'post',
    data
  });
}

export const settlementV4 = (data) => {
  return commonRequest({
    url: '/v4/order/settlement',
    method: 'post',
    data
  });
}

export const settlementV5 = (data) => {
  return commonRequest({
    url: '/v5/order/settlement',
    method: 'post',
    data
  });
}

export const getInputById = (id) => {
  return commonRequest({
    url: '/v11/question/getInputById/'+id,
    method: 'get'
  });
}

export const createReportV450 = (data) => {
  return commonRequest({
    url: '/v10/question/add',
    method: 'post',
    data
  });
}

export const getInputByIdV450 = (id) => {
  return commonRequest({
    url: '/v450/question/getInputById/' + id,
    method: 'get'
  });
}

export const checkOrderStatus = (data) => {
  return commonRequest({
    url: '/v3/order/pay/status',
    method: 'post',
    data
  });
}

/**
 * 获取广告配置
 */
export const queryAdConfig = (data) => {
  return commonRequest({
    url: '/v1/ad/getAdWatchConfig',
    method: 'post',
    data
  });
}

/**
 * 上报广告
 */
export const reportAdConfig = (data) => {
  return commonRequest({
    url: '/v1/ad/uploadAdWatchRecord',
    method: 'post',
    data
  });
}
