<template>
    <div class="card-wrap">
            <div class="line-step">
                    <div class="title">订阅步骤</div>
                    <div class="step-box">
                        <div class="step"><i>1</i>选择城市</div>
                        <div class="bord"></div>
                        <div class="step"><i>2</i>输入店铺名称</div>
                        <div class="bord"></div>
                        <div class="step"><i>3</i>点击店铺，立即订阅</div>
                    </div>
            </div>
            <div class="ex-shop">
                <div class="title">大家都在订阅这些店铺</div>
                <div class="shop-list">
                    <div class="name">书亦烧仙草</div>
                    <div class="name">蜜雪冰城</div>
                    <div class="name">CoCo都可</div>
                    <div class="name">周黑鸭</div>
                    <div class="name">真功夫</div>
                    <div class="name">……</div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data () {
        return {
            active: ''
        };
    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.card-wrap {
    position: relative;
    padding:  7vw 0 6vw 7vw;
    background-color: #ffffff;
    border-radius: $radius-xs;
    width: 100%;
    height: calc(100% - 18vw);
    box-sizing: border-box;
    .line-step{
        position: relative;
        .title{
            font-size: $font_373;
            line-height: $font_533;
            color: #676C84;
            font-weight: $weight_600;
        }
        .step-box{
            margin-top: 5vw;
            margin-bottom: 7vw;
            .step{
                font-size: 3.47vw;
                line-height: 5.07vw;
                color: #8E9099;
                display: flex;
                align-items: center;
                i{
                    content: '';
                    display: inline-block;
                    width: 4vw;
                    height: 4vw;
                    background: #F8F8F9;
                    border: 1px solid #518CDB;
                    border-radius: 4vw;
                    font-style: normal;
                    text-align: center;
                    margin-right: 2vw;
                    color: #518CDB;
                    font-size: $font_293;
                    line-height: 4vw;
                }
            }
        }
        .bord{
            width: 0px;
            height: 3vw;
            border: 0.5px dashed rgba(81, 140, 219, .5);
            margin:1.5vw 2vw;
        }
    }
    .ex-shop{
        .title{
            font-size: 3.73vw;
            line-height: 5.33vw;
            color: #676C84;
            font-weight: $weight_600;
        }
        .shop-list{
            margin-top: 4vw;
            .name{
                font-size: 3.73vw;
                line-height: 5.33vw;
                color: #8E9099;
                margin-bottom: 3vw;
            }
        }
    }
    .line-wrap {
        position: relative;
        padding: 4vw 4vw 4vw 0;
        border-bottom: 1px solid #EDF1FB;
        .title-icon {
            width: 3.2vw;
            height: 4vw;
        }
        .title-label {
            position: relative;
            margin-left: 2.1vw;
            color: #808080;
            font-size: 3.5vw;
            line-height: 5vw;
            text-align: left;
        }
        .index-label {
            color: #D1D2D6;
            font-size: 3.7vw;
            line-height: 4.5vw;
            font-weight: bold;
            font-style: italic;
            &.color1 {
                color: #FE6405;
            }
            &.color2 {
                color: #FF9E61;
            }
            &.color3 {
                color: #EDC27F;
            }
        }
        .name-label {
            margin-left: 1.3vw;
            color: #1D2233;
            font-size: 3.7vw;
            line-height: 5.3vw;
            font-weight: bold;
            text-align: left;
        }
    }
}
</style>
