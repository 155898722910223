<template>
  <div class="container">
    <div class="map_container" :id="id"></div>
  </div>
</template>
<script>
let defaultIndex = 0
// 热力图
export const hotMapConfig = {
  center: [114.085947, 22.547], //中心点 深圳
  zoom: 15, //地图层级
  zooms: [15, 16], // 地图缩放范围
  rotateEnable: false, // 不可旋转
  resizeEnable: true, // 监听地图缩放事件
  dragEnable: true // 可拖拽
};
import LoadMap from "@/components/baseMap/loadMap";
export default {
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    mapOpts: {
      type: Object
    }
  },
  data() {
    return {
      id: '',
      map: undefined,
      heatmapData: [],
      markerLayer: undefined,
      heatmapLayer: undefined,
      markerColorSetList: [
        { from: 0, to: 10, rgb: "rgb(163,1,255)", alpha: 1.0, rgba: "rgba(163,1,255, 1.0)" },
        { from: 10, to: 50, rgb: "rgb(86,1,255)", alpha: 1.0, rgba: "rgba(86,1,255, 1.0)" },
        { from: 50, to: 100, rgb: "rgb(0,219,255)", alpha: 1.0, rgba: "rgba(0,219,255, 1.0)" },
        { from: 100, to: 500, rgb: "rgb(255,112,15)", alpha: 1.0, rgba: "rgba(255,112,15, 1.0)" },
        { from: 500, to: 10000, rgb: "rgb(255,2,0)", alpha: 1.0, rgba: "rgba(255,2,0, 1.0)" }
      ],
    };
  },
  created () {
    this.id = 'hot-map-' + defaultIndex++
  },
  mounted() {
    LoadMap().then(() => {
      this.initMap()
    });
  },
  methods: {
    /**
     * 初始化插件
     */
    initMap() {
      let mapOption = Object.assign({}, hotMapConfig, this.mapOpts);
      this.map = new AMap.Map(this.id, mapOption);
      this.map.on("complete", this.completeCallBack);
      this.map.on("click", this.clickCallBack);
    },
    // 标注图层
    addLabelDataInMap(data) {
      this.map.clearMap();
      for (let i = 0; i < data.length; ++i) {
        const item = data[i];
        // 画一个文本
        let text = new AMap.Text({
          text: item.value.toFixed(0),
          anchor: "center", // 设置文本标记锚点
          cursor: "pointer",
          angle: 0,
          style: {
            "background-color": "rgba(0, 0, 0, 0)",
            width: "32px",
            height: "32px",
            "line-height": "32px",
            "text-align": "center",
            "font-size": "12px",
            "border-width": 0,
            color: "rgb(0,0,0)"
          },
          position: [item.lng, item.lat],
          zIndex: 3
        });
        text.setMap(this.map);
      }
    },
    // 所有地图实例加载完成
    completeCallBack() {
      this.$emit("map-complete");
    },
    // 获取点击地经纬度
    clickCallBack(e) {},
    destroyMap() {
      if (this.map != undefined) {
        this.map.destroy();
      }
    },
    // 设置地图不可交互
    setMapStatus() {
      this.map.setStatus({
        showIndoorMap: false,
        resizeEnable: true,
        dragEnable: false,
        keyboardEnable: false,
        doubleClickZoom: false,
        zoomEnable: false,
        rotateEnable: false
      });
    },
    // 设置定位地点
    setCenter(inputData) {
      if (!inputData || !inputData.hasOwnProperty("GPS_R")) {
        return;
      }
      let lnglat = [inputData.GPS_R.lng, inputData.GPS_R.lat];
      this.map.setCenter(lnglat);
    },
    // 设置地图等级、禁用缩放
    setZoomAndEnable(zoom = 12) {
      this.map.setZoom(zoom);
      this.map.setStatus({
        zoomEnable: false
      });
    },
    // 回到中心点
    panToCenter(inputData) {
      if (!inputData || !inputData.hasOwnProperty("GPS_R")) {
        return;
      }
      let lnglat = [inputData.GPS_R.lng, inputData.GPS_R.lat];
      this.map.panTo(lnglat);
    },
    /**
     * inputData: 定位点
     * radius 热点点大小
     * heatData 热力数据
     * moreLnglat 热力集中点
     */
    addInputOverlay(inputData, radius, heatData, moreLnglat) {
      if (!inputData || !inputData.hasOwnProperty("GPS_R")) {
        return;
      }
      if (heatData) {
        this.heatmapLayer = new AMap.Heatmap(this.map, {
          radius,
          opacity: [0, 0.85],
          blur: 0.75,
          gradient: {
            0.1: "#a301ff",
            0.3: "#5601ff",
            0.4: "#008cff",
            0.5: "#00dbff",
            0.7: "#3bff00",
            0.8: "#fbea00",
            0.9: "#ff700f",
            0.97: "#ff0200"
          }
        });
        this.heatmapLayer.setDataSet({
          data: heatData
        });
      }
      // 定位
      let lnglat = [inputData.GPS_R.lng, inputData.GPS_R.lat];
      let marker = new AMap.Marker({
        icon: new AMap.Icon({
          image: "/static/shopEcology/report/map_icon_needle.png",
          imageSize: new AMap.Size(28, 36),
          size: new AMap.Size(28, 36)
        }),
        position: lnglat,
        offset: new AMap.Pixel(-14, -18),
        zIndex: 1,
        clickable: false
      });
      marker.setMap(this.map);
      // 圆范围
      // let circle = new AMap.Circle({
      //     center: lnglat,
      //     radius: 500, //半径
      //     borderWeight: 1,
      //     strokeColor: "#518CDB",
      //     strokeWeight: 2,
      //     strokeOpacity: 0.17,
      //     strokeStyle: "solid",
      //     fillOpacity: 0.3,
      //     fillColor: "#518CDB",
      //     bubble: false,
      //     zIndex: 2,
      // });
      // circle.setMap(this.map);

      // 创建纯文本标记
      if (!moreLnglat || moreLnglat.length == 0) {
        return;
      }
      for (let i = 0; i < moreLnglat.length; i++) {
        let text = new AMap.Text({
          text: i + 1,
          anchor: "center", // 设置文本标记锚点
          // draggable: true,
          cursor: "pointer",
          angle: 0,
          style: {
            "border-radius": "50%",
            "background-color": "rgba(22, 22, 22, .6)",
            width: "32px",
            height: "32px",
            "line-height": "32px",
            "border-width": 0,
            "box-shadow": "0 2px 6px 0 rgba(114, 124, 245, .5)",
            "text-align": "center",
            "font-size": "16px",
            color: "#fff"
          },
          position: moreLnglat[i],
          zIndex: 3
        });
        text.setMap(this.map);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.container {
    position: relative;
    height: 100%;
  .map_container {
    height: 100%;
  }
}
</style>
