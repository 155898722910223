<template>
  <div class="warpper">
        <StateBar bgcolor="white" />
        <TitleHeader title="华夏龙商贷" color="black" :back="true">
        </TitleHeader>
        <AutoView :footer="true">
            <img class="img" v-for="item in imgUrl" :key="item.url" :src="item.url" alt="">
        </AutoView>
        <!-- 底栏提交按钮 -->
        <div class="loanFooter">
            <van-button type="info" block class="s-radius__s" @click="gotoLoanDetail">立即申请贷款</van-button>
        </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            imgUrl: [
                {url: "/static/img/huaxiaLoan_img_A.png"},
                {url: "/static/img/huaxiaLoan_img_B.png"},
                {url: "/static/img/huaxiaLoan_img_C.png"},
                {url: "/static/img/huaxiaLoan_img_D.png"},
                {url: "/static/img/huaxiaLoan_img_E.png"},
                {url: "/static/img/huaxiaLoan_img_F.png"},
            ]
        }
    },
    methods: {
        gotoLoanDetail() {
            this.swRouter('/channel/huaxiaLoanDetail')
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.wrapper {
    height: 100vh;
    overflow-x: hidden;
    background-color: #fff;
}
.img {
    width: 100vw;
    display: block;
}
.loanFooter {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;

    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
</style>
