<template>
  <div class="download">
    <StackView ref="view" class="download--view">
      <div class="content-wrap">
        <div class="login">
          <form class="join-form">
            <input type="number" ref="phone" v-model="form.phone" :disabled="submitting" placeholder="请输入手机号" maxlength="11" />
            <div class="nc-wrap">
              <div id="sscm_share_join_nc"></div>
            </div>
            <div class="f-clear">
              <input type="number" ref="code" v-model="form.code" :disabled="submitting" placeholder="请输入验证码" maxlength="6" class="f-fl w_1" />
              <input
                type="button"
                :disabled="submitting || isSendCode"
                :value="isSendCode ? waitingTime + '秒' : '获取验证码'"
                class="f-fr w_2"
                @click="onGetCode"
              />
            </div>
            <button type="button" class="f-btn" :disabled="submitting" @click="onSubmit">{{ submitting ? "请稍候" : "立即领取" }}</button>
          </form>
        </div>
      </div>
      <!-- <van-image class="cool-img" fit="cover" v-for="item in imgList" :src="item" :key="item" /> -->
      <!-- <van-swipe :show-indicators="false" :autoplay="3500">
        <van-swipe-item v-for="(img, i) of bannerList" :key="i">
          <van-image class="cool-img" fit="cover" :src="img" />
        </van-swipe-item>
      </van-swipe> -->
    </StackView>
    <!-- 全局加载遮罩-->
    <LoadingOverlay :show="loading" />
  </div>
</template>

<script>
const BASE_NUM = 60; // 默认等待时间

export default {
  data() {
    return {
      loading: true,
      imgList: [
        "/static/img/download/page_2.png",
        "/static/img/download/page_3.png",
        "/static/img/download/page_4.png",
        "/static/img/download/page_5.png",
        "/static/img/download/page_6.png",
        "/static/img/download/page_7.png",
        "/static/img/download/page_8.png",
      ],
      bannerList: ["/static/img/download/page_9_1.png", "/static/img/download/page_9_2.png", "/static/img/download/page_9_3.png"],
      submitting: false,
      isSendCode: false,
      waitingTime: 60,
      // 是否进行了滑块验证
      hasVerification: false,
      verification: {
        phone: "",
        scene: "nc_login",
        sessionId: "",
        sig: "",
        token: "",
      },
      form: {
        code: "",
        phone: "",
        // fromType: "",
        // inviteCode: "",
        // inviteType: 3, // 1:普通邀请 2:合伙人邀请 3:为渠道类
        deviceType: 3,
        versionName: '1.0.0',
      },
      isActive: null,
    };
  },
  beforeMount() {
    this.isAndroid = window.isClient && window.isAndroid;
    if (window.isClient && !window.isAndroid) {
      this.isIOS = window.isIOS;
      this.isIPX = window.isIPX;
    }
    // 滑动验证
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://g.alicdn.com/AWSC/AWSC/awsc.js";
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          this.initAWSC();
        }
      };
    } else {
      script.onload = () => {
        this.initAWSC();
      };
    }
    document.getElementsByTagName("head")[0].appendChild(script);
  },
  computed: {
    // inviteCode() {
    //   return this.$route.query.inviteCode || "";
    // },
  },
  mounted() {
    var _this = this;
    var timer = setInterval(function () {
      if (document.readyState === "complete") {
        //执行方法
        _this.fn();
        window.clearInterval(timer);
      }
    }, 500);
    // 获取url参数
    const url = window.location.href;
    const findIndex = url.indexOf('?');
    if ( findIndex == -1 ) {
      return;
    }
    const queryString = url.slice(findIndex + 1);
    if ( queryString == '' ) {
      return;
    }
    const queryItems = queryString.split('&');
    for (let i = 0; i < queryItems.length; ++i) {
      const queryData = queryItems[i].split('=');
      const queryKey = queryData[0];
      const queryValue = queryData[1];
      if ( queryKey == 'active' ) {
        this.isActive = queryValue;
      }
    }

  },
  methods: {
    onGetCode() {
      if (this.isSendCode || this.validate(true) === false) return;
      this.verification.phone = this.form.phone;
      if (!this.hasVerification) {
        this.$toast("请拖动滑块进行验证");
        return;
      }
      this.verificationCode();
    },
    validate(justPhone) {
      if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$toast("请输入正确的手机号");
        this.$refs.phone.focus();
        return false;
      }
      if (justPhone) return true;
      if (!/^\d{6}$/.test(this.form.code)) {
        this.$toast("请输入6位数验证码");
        this.$refs.code.focus();
        return false;
      }
      return true;
    },
    onSubmit() {
      if (this.submitting || this.validate() === false) return;
      this.submitting = true;
      // this.$set(this.form, "inviteCode", this.inviteCode, );
      this.HTTP.loginDownLoad(this.form)
        .then((res) => {
          if(this.isActive) {
            this.swRouter("/channel/celebrate/"+this.isActive+"?token="+res.data.session);
          } else {
            this.swRouter("/channel/guideDown");
          }
        })
        .catch((e) => {
          if (typeof e === "object" && e.hasOwnProperty("code")) {
            if (e.code == 189003) {
              this.$toast("您已注册过上上参谋");
              if(this.isActive) {
                setTimeout(() => {
                  this.swRouter("/channel/celebrate/"+this.isActive+"?token="+e.data.session);
                }, 2000);
              } else {
                setTimeout(() => {
                  this.swRouter("/channel/guideDown");
                }, 2000);
              }
            } else {
              this.$toast(e.msg);
            }
          } else {
            this.$toast("网络不稳定，请稍候重试！");
          }
          setTimeout(() => {
            this.submitting = false;
          }, 2000);
        });
    },
    verificationCode() {
      this.HTTP.verification(this.verification)
        .then((res) => {
          if (res.code === 0) {
            this.isSendCode = true;
            this.$toast("验证码发送成功");
            clearInterval(this.timer);
            this.timer = setInterval(() => {
              if (this.waitingTime === 1) {
                clearInterval(this.timer);
                this.isSendCode = false;
                this.waitingTime = BASE_NUM;
              } else {
                this.waitingTime--;
              }
            }, 1000);
          } else {
            this.resetVerification();
          }
        })
        .catch((e) => {
          this.resetVerification();
        });
    },
    resetVerification() {
      this.$toast("滑块验证失败，请检查手机号，重新滑动验证");
      window.nc.reset();
      this.hasVerification = false;
    },
    initAWSC() {
      const appkey = "FFFF0N2N0000000069E7";
      const scene = this.verification.scene;
      window.AWSC.use("nc", (state, module) => {
        // console.log( 'awsc loaded' );
        // 初始化
        // const ncToken = [ appkey, ( new Date() ).getTime(), Math.random() ].join( ':' );
        window.nc = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: appkey,
          // 使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: scene,
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "sscm_share_join_nc",
          // 前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
          success: (data) => {
            // window.console && console.log( 'data:', data );
            this.hasVerification = true;
            this.verification.sessionId = data.sessionId;
            this.verification.sig = data.sig;
            this.verification.token = data.token;
            // this.verification.token = ncToken;
          },
          // 滑动验证失败时触发该回调参数。
          fail: function (failCode) {
            window.console && console.error(failCode);
          },
          // 验证码加载出现异常时触发该回调参数。
          error: function (errorCode) {
            window.console && console.error(errorCode);
          },
        });
      });
    },
    fn() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.download {
  height: 100vh;
  overflow: hidden;

  #{&}--view {
    scroll-behavior: smooth;
    background: #fff;
    .cool-img {
      position: relative;
      display: block;
      width: 100%;
    }
  }
  .content-wrap {
    height: 204.8vw;
    background: url("/static/img/download/page_1.png") no-repeat;
    background-size: cover;
    .login {
      position: relative;
      bottom: -132.8vw;
      left: 0;
      height: 69.33vw;
      background: #d8e1fe;
      border-radius: 2.67vw;
      margin: 0 4vw;
      padding: 0 5.87vw;
      box-sizing: border-box;

      .join-form {
        position: absolute;
        top: 5.4vw;
        width: calc(90vw - #{$padding * 1.6});
        z-index: 2;

        .f-btn {
          background: #518cdb;
          color: #fff;
        }

        input,
        button {
          @include ani;
          display: block;
          width: 100%;
          height: 13vw;
          font-size: $font_400;
          line-height: normal;
          padding: 0 4.8vw;
          margin-bottom: 3.6vw;
          border-radius: $radius_xs;
          border: none;
          background: #fff;
          box-sizing: border-box;
          -webkit-appearance: none;

          &::-webkit-input-placeholder {
            color: #aeb1bd;
          }
          &[disabled] {
            color: #d2d2d2 !important;
            opacity: 0.8;
          }
        }

        .w_1 {
          width: 60%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .w_2 {
          width: 40%;
          // @include font( #451DB4, 2.93vw, bold );
          @include font(#347fff, 2.93vw, bold);
          background-color: white;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .nc-wrap {
          position: block;
          width: 100%;
          height: 34px;
          margin-bottom: 3.6vw;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.nc-wrap {
  .nc-container #nc_1_wrapper {
    width: 100%;
  }
}
</style>
