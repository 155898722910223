<template>
    <div>
        <div class="wrapper" v-if="!isPay">
            <div class="part part-1">
                <img class="bg" src="/static/newUserActivity/part-1.png" alt="" />
            </div>
            <div class="part middle-part">
                <div class="part-title">
                    <img class="title-icon" src="/static/newUserActivity/icon.png" alt="" />
                    <p class="title-name">热销爆款 首单特惠</p>
                    <img class="title-icon" src="/static/newUserActivity/icon.png" alt="" />
                </div>
                <img class="bg" @click="gotoVipShop(87001)" src="/static/newUserActivity/part-2.png" alt="" />
                <img class="bg" @click="gotoDetail(10001, 87002)" src="/static/newUserActivity/part-3.png" alt="" />
                <img class="bg" @click="gotoDetail(10026, 87003)" src="/static/newUserActivity/part-4.png" alt="" />
            </div>
            <div class="bottom-part">
                <div class="part-title">
                    <img class="title-icon" src="/static/newUserActivity/icon.png" alt="" />
                    <p class="title-name">超值换购 9.9元入会</p>
                    <img class="title-icon" src="/static/newUserActivity/icon.png" alt="" />
                </div>
                <img class="bg" src="/static/newUserActivity/part-5.png" alt="" />
                <div class="part2_ad">
                    <div class="adItem" v-for="item in part2AdList" :key="item.url">
                        <img :src="item.url" alt="" />
                        <div class="gotoUser" @click="gotoDetail(item.id, item.btnId)">
                            <p class="btn-text">
                                ¥<span>{{ item.price }}/次</span>起
                            </p>
                            <img class="right" src="/static/newUserActivity/right.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="introduce">
                <p>本活动最终解释权归上上参谋所有</p>
                <p>如有疑问，请联系在线客服。</p>
            </div>
        </div>
        <div class="old-wrapper" v-else>
            <div class="img-bg head">
                <div class="head_img">
                    <img :src="headImg" alt="" />
                </div>
                <img v-if="oldUserStatus == 0" @click="receiveCoupon" class="head_btn" src="/static/yearActivity/head_btn.png" alt="" />
                <img v-else class="head_btn" src="/static/yearActivity/head_disable.png" alt="" />
            </div>
            <div class="img-bg part1" @click="gotoVipShop(88009)">
                <div class="part1_img">
                    <img :src="part1Img" alt="" />
                </div>
                <img
                    class="part1_btn"
                    v-for="item in oldVipList"
                    :key="item.btnId"
                    @click.stop="gotoVipShop(item.btnId)"
                    src="/static/yearActivity/part1_btn.png"
                    alt=""
                />
            </div>
            <div class="img-bg part2">
                <div class="part2_img">
                    <img :src="part2Img" alt="" />
                </div>
                <img class="part2_btn" v-for="item in oldAdList" :key="item.url" @click="gotoDetail(item.id, item.btnId)" :src="item.url" alt="" />
                <img
                    class="part2_btn_1"
                    v-for="item in oldGoodsList"
                    :key="item.url"
                    @click="gotoDetail(item.id, item.btnId, 'v1')"
                    :src="item.url"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "yearActivity",
    data() {
        return {
            isPay: false,
            part2AdList: [
                {
                    url: "/static/newPeople/part2_ad1.png",
                    id: "10001",
                    btnId: "87004",
                    price: "79",
                },
                {
                    url: "/static/newPeople/part2_ad2.png",
                    id: "10026",
                    btnId: "87005",
                    price: "123",
                },
            ],
            oldVipList: [
                {
                    idx: 3,
                    btnId: "88002",
                },
                {
                    idx: 0,
                    btnId: "88003",
                },
            ],
            oldAdList: [
                {
                    url: "/static/yearActivity/part2_btn1.png",
                    id: "10001",
                    btnId: "88004",
                },
                {
                    url: "/static/yearActivity/part2_btn2.png",
                    id: "10026",
                    btnId: "88005",
                },
            ],
            oldGoodsList: [
                {
                    url: "/static/yearActivity/part2_btn3.png",
                    id: "10006",
                    btnId: "88006",
                },
                {
                    url: "/static/yearActivity/part2_btn4.png",
                    id: "10015",
                    btnId: "88007",
                },
                {
                    url: "/static/yearActivity/part2_btn5.png",
                    id: "10004",
                    btnId: "88008",
                },
            ],
            newCouponIdList: [],
            newUserStatus: 0,
            oldCouponIdList: [],
            oldUserStatus: 0,
            headImg: "/static/yearActivity/head_bg.png",
            part1Img: "/static/yearActivity/part1_bg.png",
            part2Img: "/static/yearActivity/part2_bg.png",
        };
    },
    created() {
        this.activeId = this.$route.params.bannerid;
    },
    mounted() {
        this.HTTP.getIsPayUser().then((res) => {
            console.log(res);
            this.isPay = res.data;
        });
        this.JSBridge.BPR("10183", this.isPay ? "1" : "2", {});
        this.HTTP.getActiveInfo(this.activeId).then((res) => {
            console.log(res);
            const list = res.data.activeItemResList;
            // 新人优惠券处理
            const newList = list.filter((item) => {
                return item.couponName.indexOf("6折") > -1;
            });
            this.newCouponIdList = newList.map((items) => {
                return items.couponId;
            });
            const newStatus = newList
                .map((item) => {
                    return item.receiveStatus;
                })
                .some((item) => {
                    return item == 0;
                });
            this.newUserStatus = newStatus ? 0 : 1;
            if (!this.isPay) {
                if (this.newUserStatus === 0) {
                    this.HTTP.batchReceive({
                        activeId: this.activeId,
                        couponIdList: this.newCouponIdList,
                    }).then((res) => {
                        this.newUserStatus = 1;
                    });
                }
            }

            // 老人优惠券处理
            const oldList = list.filter((item) => {
                return item.couponName.indexOf("8折") > -1;
            });
            this.oldCouponIdList = oldList.map((items) => {
                return items.couponId;
            });
            const oldStatus = oldList
                .map((item) => {
                    return item.receiveStatus;
                })
                .some((item) => {
                    return item == 0;
                });
            this.oldUserStatus = oldStatus ? 0 : 1;
        });
    },
    methods: {
        gotoDetail(id, btnId, type = "v2") {
            this.JSBridge.BTNCLICK(10183, "", this.isPay ? 88000 : 87000, btnId);
            if (window._appCode >= 300 && type === 'v2') {
              this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: 'BRAND_INTRODUCTION_V3',
                id: id,
                isClear: null,
              });
            } else {
              this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: type === "v2" ? "BRAND_INTRODUCTION_V2" : "BRAND_INTRODUCTION",
                id: id,
                isClear: null,
              });
            }
        },
        gotoVipShop(btnId) {
            this.JSBridge.BTNCLICK(10183, "", this.isPay ? 88000 : 87000, btnId);
            this.swRouter({
                path: "/h5/#/member",
            });
        },
        receiveCoupon() {
            this.JSBridge.BTNCLICK(10183, "", 88000, 88001);
            if (this.oldUserStatus === 0) {
                this.HTTP.batchReceive({
                    activeId: this.activeId,
                    couponIdList: this.oldCouponIdList,
                }).then((res) => {
                    this.$toast.success("领取成功");
                    this.oldUserStatus = 1;
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    background: url("/static/newUserActivity/bg.png") repeat;
    background-size: 100% auto;
    .bg {
        width: 100%;
    }
    .part-1 {
    }
    .part-title {
        display: flex;
        align-items: center;
        justify-content: center;

        .title-icon {
            width: 5.33vw;
        }
        .title-name {
            font-size: 4.266vw;
            color: #fff;
            font-weight: 600;
            margin: 0 2.93vw;
        }
    }
    .middle-part {
        padding: 0 1.6vw;
        .part-title {
            padding: 4.8vw 0 2.4vw 0;
        }
    }
    .bottom-part {
        padding: 0 4vw;
        position: relative;
        .part-title {
            padding: 2.4vw 0 4.8vw 0;
        }
    }
    .part2_ad {
        display: flex;
        justify-content: space-between;
        padding: 0 8.266vw;
        position: absolute;
        top: 76.5vw;
        left: 0;
        .adItem {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-child {
                margin-right: 4vw;
            }
            img {
                width: 100%;
            }
            .gotoUser {
                width: 28.8vw;
                height: 10.133vw;
                background: #ff3b57;
                box-shadow: 0 0.53vw 1.06vw 0 #ff615b;
                border-radius: 5.066vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -3vw;
                z-index: 1;
                position: relative;
                box-sizing: border-box;
                .right {
                    width: 1.6vw;
                    height: 3.2vw;
                    margin-left: 1.6vw;
                }
                .btn-text {
                    font-size: 2.933vw;
                    color: #fff;
                    span {
                        font-size: 4.266vw;
                    }
                }
            }
        }
    }
    .introduce {
        font-weight: 400;
        color: #ffffff;
        font-size: 2.666vw;
        padding: 4.266vw 0;
        text-align: center;
        p {
            line-height: 3.73vw;
        }
    }
}
.old-wrapper {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    .img-bg {
        .head_img {
            height: 130.4vw;
        }
        .part1_img {
            height: 115.06vw;
        }
        .part2_img {
            height: 118.66vw;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    .head {
        position: relative;
        .head_btn {
            width: 65.33vw;
            position: absolute;
            bottom: 8.267vw;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .part1 {
        position: relative;
        .part1_btn {
            width: 28.8vw;
            position: absolute;
            bottom: 12.933vw;
            &:nth-child(2) {
                left: 13.067vw;
            }
            &:nth-child(3) {
                right: 13.067vw;
            }
        }
    }
    .part2 {
        position: relative;
        .part2_btn {
            width: 29.067vw;
            position: absolute;
            top: 37.333vw;
            &:nth-child(2) {
                left: 13.067vw;
            }
            &:nth-child(3) {
                right: 13.067vw;
            }
        }
        .part2_btn_1 {
            width: 26.8vw;
            position: absolute;
            bottom: 33.467vw;
            &:nth-child(4) {
                left: 6.8vw;
            }
            &:nth-child(5) {
                left: 36.667vw;
            }
            &:nth-child(6) {
                right: 6.8vw;
            }
        }
    }
}
</style>
