/**
 * 非单页型报告路由
 */

// 品牌查询报告
const brandReport = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/index");
const brandLoader = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/reportLoader");
const reportShopList = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/shopList");
const reportShopDetail = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/shopDetail");
const shopDistribute = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/shopDistribute");
const dataExplain = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/dataExplain");
const brandDetailData = () => import(/* webpackChunkName: brand-report" */ "@/views/report/brandReport/brandDetailData");

export default [
    {
        path: "/reportView",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
              path: "dataDetail",
              name: "brandDetailData",
              component: brandDetailData,
              meta: { id: 10518 }
            },
            {
                path: "searchBrand",
                name: "searchBrand",
                component: brandReport,
                meta: { id: 10174 }
            },
            {
                path: "brandLoader",
                name: "brandLoader",
                component: brandLoader,
                meta: { id: 10175 }
            },
            {
                name: "shopList",
                path: "shopList/:brandCode",
                component: reportShopList,
                meta: { id: 10176 }
            },
            {
                name: "shopDetail",
                path: "shopDetail",
                component: reportShopDetail,
                meta: { id: 10177 }
            },
            {
                name: "shopDistribute",
                path: "shopDistribute",
                component: shopDistribute,
                meta: { id: 10178 }
            },
            {
                name: "dataExplain",
                path: "dataExplain",
                component: dataExplain,
                meta: { id: 10179 }
            }
        ]
    }
];
