<template>
    <div class="invoice">
        <StateBar></StateBar>
        <TitleHeader title="开发票" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="true" class="invoice--view l-view">
            <div class="l-panel icon-bill" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
                <h3>共<em>{{ orderNum }}</em>个订单</h3>
                <label class="s-label gray">全部以{{ typeStr }}进行开具</label>
            </div>

            <div v-show="this.info.adv" class="s-tabs s-radius__s mt">
                <span class="s-radius__s" :class="{ on : form.type === 1 }" @click="form.type = 1">普票</span>
                <span class="s-radius__s" :class="{ on : form.type === 2 }" @click="form.type = 2">专票</span>
            </div>

            <div class="l-panel ptb2 mt" style="border-radius: 0">
                <van-cell title="发票类型" :value="typeStr" />
                <van-field name="radio" label="抬头类型" required>
                    <template #input>
                        <div class="s-radio">
                            <i :class="{ checked: form.titleType === 1 }" @click="form.titleType = 1">企业单位</i>
                            <i :class="{ checked: form.titleType === 2 }" @click="form.titleType = 2">个人/非企业</i>
                        </div>
                    </template>
                </van-field>
                <van-field
                    v-model="form.title"
                    required
                    label="发票抬头"
                    placeholder="请填写发票抬头"
                    maxlength="64" />
                <van-field
                    v-show="form.titleType === 1"
                    v-model="form.taxNum"
                    required
                    label="税号"
                    placeholder="请填写税号"
                    maxlength="20" />

                <van-cell title="发票内容" :value="form.content" />
                <van-cell title="发票金额" :value="amountStr" class="blod" />
                <van-cell v-show="form.type === 1" title="更多信息" isLink :value="moreStr" @click="showMoreInfo = true" />
                <div v-show="form.type === 2">
                    <van-field
                        v-model="form.invoiceRemark"
                        required
                        label="备注说明"
                        placeholder="请填写备注说明"
                        maxlength="64" />
                    <van-field
                        v-model="form.registerAddress"
                        required
                        type="textarea"
                        label="注册地址"
                        placeholder="请填写注册地址"
                        :autosize="false"
                        maxlength="128" />
                    <div v-if="isShowUpload" class="s-upload">
                        <div class="lable">
                            <div class="title">一般纳税人证明</div>
                            <div class="name">（纳税认定通知书或相关证明图片）</div>
                        </div>
                        <UploadImg @getImgUploadUrl="getImgUploadUrl" :maxCount="1" @deleteImg="deleteImg"/>
                    </div>
                    <van-field
                        v-model="form.registerPhone"
                        required
                        type="tel"
                        label="注册电话"
                        placeholder="请填写注册电话"
                        maxlength="18" />
                    <van-field
                        v-model="form.bank"
                        required
                        label="开户银行"
                        placeholder="请填写开户银行"
                        maxlength="64" />
                    <van-field
                        v-model="form.bankAccount"
                        required
                        label="银行账号"
                        placeholder="请填写银行账号"
                        maxlength="30" />
                </div>
            </div>

            <div class="l-panel ptb3 mt" style="border-top-left-radius: 0; border-top-right-radius: 0">
                <h4>收件人信息</h4>
                <van-divider />
                <div v-show="form.type === 1">
                    <van-field
                        v-model="form.email"
                        required
                        type="email"
                        label="邮箱地址"
                        placeholder="请填写邮箱地址"
                        maxlength="64" />
                    <label class="s-label red">电子发票将在系统开具后发送至你填写的邮箱，请确认邮箱地址无误。</label>
                </div>
                <div v-show="form.type === 2">
                    <van-field
                        v-model="form.contactName"
                        required
                        label="收件人"
                        placeholder="请填写姓名"
                        maxlength="6" />
                    <van-field
                        v-model="form.contactPhone"
                        required
                        label="联系电话"
                        placeholder="请填写手机号"
                        maxlength="11" />
                    <van-field
                        v-model="form.contactAddress"
                        required
                        type="textarea"
                        label="详细收件地址"
                        placeholder="请填写邮寄详细地址"
                        :autosize="false"
                        maxlength="128" />
                    <label class="s-label red">纸质专票将在开具后通过EMS快递寄出，邮费到付，请确认收件人信息无误。</label>
                </div>
            </div>
        </AutoView>

        <SoltFooter class="invoice--footerBar">
            <div class="view">
                <van-button type="info" block :disabled="disabled" @click="onSubmit" class="s-radius__s">提交</van-button>
            </div>
        </SoltFooter>

        <!-- 更多信息 -->
        <van-popup v-model="showMoreInfo" position="bottom" round closeable>
            <div class="invoice--popup">
                <h3>填写更多信息</h3>
                <div class="l-panel">
                    <van-field
                        v-model="form.invoiceRemark"
                        label="备注说明"
                        placeholder="请填写备注说明"
                        maxlength="64" />
                    <van-field
                        v-model="form.registerAddress"
                        type="textarea"
                        label="注册地址"
                        placeholder="请填写注册地址"
                        :autosize="false"
                        maxlength="128" />
                    <van-field
                        v-model="form.registerPhone"
                        type="tel"
                        label="注册电话"
                        placeholder="请填写注册电话"
                        maxlength="18" />
                    <van-field
                        v-model="form.bank"
                        label="开户银行"
                        placeholder="请填写开户银行"
                        maxlength="64" />
                    <van-field
                        v-model="form.bankAccount"
                        label="银行账号"
                        placeholder="请填写银行账号"
                        maxlength="30" />
                </div>
                <SoltFooter class="btnView">
                    <van-button type="info" block class="s-radius__s" @click="showMoreInfo = false">确认</van-button>
                </SoltFooter>
                <!-- <div class="btnView">
                    <van-button type="info" block class="s-radius__s" @click="showMoreInfo = false">确认</van-button>
                </div> -->
            </div>
        </van-popup>

        <!-- 确认发票信息 -->
        <van-popup v-model="showConfirm" position="bottom" round closeable>
            <div class="invoice--popup">
                <h3>开票信息确认</h3>
                <div class="l-panel">
                    <van-cell title="发票抬头" :value="form.title" />
                    <van-cell title="税号" :value="form.taxNum" />
                    <template v-if="!info.adv">
                        <van-cell title="电子邮箱" :value="form.email" />
                        <label class="s-label red">请确认邮箱地址无误，电子发票将在系统开具后发送至你填写的邮箱。</label>
                    </template>
                    <template v-else>
                        <van-cell title="注册地址" :value="form.registerAddress" />
                        <van-cell title="注册电话" :value="form.registerPhone" />
                        <van-cell title="开户银行" :value="form.bank" />
                        <van-cell title="银行账户" :value="form.bankAccount" />
                        <van-cell title="收件人" :value="form.contactName" />
                        <van-cell title="联系电话" :value="form.contactPhone" />
                        <van-cell title="详细收件地址" :value="form.contactAddress" />
                        <label class="s-label red">纸质专票将在开具后通过EMS快递寄出，邮费到付，请确认收件人信息无误。</label>
                    </template>
                </div>
                <SoltFooter class="btnView">
                    <van-button type="info" block class="s-radius__s" @click="onConfim">确认</van-button>
                </SoltFooter>
            </div>
        </van-popup>

        <!-- 全局加载遮罩 -->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import UploadImg from "@/components/uploadImg"
export default {
    name: 'invoice',
    components: {
        UploadImg
    },
    data () {
        return {
            appCode     : null,
            disabled    : false,
            loading     : false,
            showMoreInfo: false,
            showConfirm : false,
            info        : {
                amount: 0,
                adv   : false, // 是否开启专票选项
            },
            form: {
                orderNoList: [], // 开票订单列表
                type       : 1, // 1电子 2纸质
                titleType  : 1, // 1企业 2个人
                title      : '', // 抬头
                taxNum     : '', // 税号
                content    : '信息服务费',

                // 扩展信息
                invoiceRemark  : '', // 备注说明
                registerAddress: '', // 注册地址
                registerPhone  : '', // 注册电话
                bank           : '', // 开户行
                bankAccount    : '', // 银行账户
                taxpayerImage       : '', // 一般纳税人证明

                // 用户信息
                email         : '', // 邮箱地址
                contactName   : '', // 联系人
                contactPhone  : '', // 联系电话
                contactAddress: '', // 联系地址
            },
        };
    },
    watch: {
      // 'form.taxNum' : {
      //   handler: (val) => {
      //     console.log(val)
      //     this.form.taxNum = val.replaceAll(' ','');
      //   },
      //   deep: true,
      // }
      'form.taxNum'(val){
        this.form.taxNum = val.replaceAll(' ','');
        console.log(val, this.form.taxNum)
      }
    },
    methods: {
        validate () {
            if ( ! this.form.title.trim() ) {
                this.$toast( '请填写抬头！' );
                return false;
            }
            if ( this.form.titleType === 1 && ! this.form.taxNum.trim() ) {
                this.$toast( '请填写税号！' );
                return false;
            }
            if ( this.form.type === 1 && ! this.form.email.trim() ) {
                this.$toast( '请填写邮箱地址！' );
                return false;
            } else if ( this.form.type === 2 ) {
                // if ( ! this.form.invoiceRemark.trim() ) {
                //     this.$toast( '请填写注备注说明！' );
                //     return false;
                // }
                if ( ! this.form.registerAddress.trim() ) {
                    this.$toast( '请填写注册地址！' );
                    return false;
                }
                if ( ! this.form.registerPhone.trim() ) {
                    this.$toast( '请填写注册电话！' );
                    return false;
                }
                if ( this.isShowUpload && !this.form.taxpayerImage ) {
                    this.$toast( '请上传一般纳税人证明图片！' );
                    return false;
                }
                if ( ! this.form.bank.trim() ) {
                    this.$toast( '请填写开户行！' );
                    return false;
                }
                if ( ! this.form.bankAccount.trim() ) {
                    this.$toast( '请填写开户行账号！' );
                    return false;
                }
                if ( ! this.form.contactName.trim() ) {
                    this.$toast( '请填写收件人姓名！' );
                    return false;
                }
                if ( ! this.form.contactPhone.trim() ) {
                    this.$toast( '请填写联系电话！' );
                    return false;
                }
                if ( ! this.form.contactAddress.trim() ) {
                    this.$toast( '请填写邮寄详细地址！' );
                    return false;
                }
            }
            return true;
        },
        onSubmit () {
            if ( ! this.validate() ) return;
            this.showConfirm = true;
        },
        getImgUploadUrl(value) {
            this.form.taxpayerImage = value.join("")
        },
        deleteImg() {
            this.form.taxpayerImage = ""
        },
        onConfim () {
            this.loading = true;
            this.HTTP.createInvoice( this.form ).then( ( res ) => {
                // 开票成功
                if ( res === true ) {
                    this.loading = false;
                    this.swRouter( '/success?view=bill' );
                } else {
                    this.$toast( '开票失败，请检查输入项并重新提交！' );
                    setTimeout( () => { this.loading = false; }, 2000 );
                }
            } ).catch( ( e ) => {
                if ( e.code && e.msg ) {
                    this.$toast( e.msg );
                } else {
                    this.$toast( '开票失败，请稍候重试！' );
                }
                setTimeout( () => { this.loading = false; }, 2000 );
            } );
        },
    },
    computed: {
        orderNum () {
            return this.form.orderNoList.length;
        },
        typeStr () {
            return this.form.type === 2 ? '纸质发票' : '电子发票';
        },
        amountStr () {
            return ( this.info.amount && ! isNaN( this.info.amount ) ? parseFloat( this.info.amount ).toFixed( 2 ) : '0.00' ) + '元';
        },
        moreStr () {
            const check = [ 'invoiceRemark', 'registerAddress', 'registerPhone', 'bank', 'bankAccount' ];
            let pass    = 0;
            for ( const key of check ) {
                if ( this.form[ key ].trim().length ) pass ++;
            }
            return `共${check.length}项，已填写${pass}项`;
        },
        isShowUpload () {
            return window.isIOS || this.appCode >= 301
        }
    },
    created () {
        this.form.orderNoList = typeof this.$route.query.orderNo === 'string' ? this.$route.query.orderNo.split( '-' ) : [];
        this.info.amount      = this.$route.query.amount || 0;
        if ( ! this.form.orderNoList || ! this.form.orderNoList.length ) {
            this.$router.push( '/bills' );
            return;
        }
        if ( ~ ~ this.info.amount >= 1000 ) {
            this.info.adv = true;
        }
    },
    beforeMount () {
        var _this = this
        this.JSBridge.callHandler( 'switchNavbar', false );
        // this.JSBridge.callHandler( 'clearHistory', true );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        this.JSBridge.callHandler("getVersionCode", null, function (code) {
            _this.appCode = code;
        });
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.invoice {
    height: 100vh;
    background-color: $c-pageBg;

    .mt {
        margin-top: 1.33vw;
    }

    #{&}--view {
        .icon-bill {
            background: white url(/static/icon/bill.png) no-repeat top right;
            background-size: 12.53vw;
        }
        .l-panel {
            padding: $padding1;

            h3 {
                font-size: $font_400;
                margin-bottom: $font_240;
                em {
                    font-style: normal;
                    margin: 0 2vw;
                    color: $c-main;
                }
            }

            &.ptb2 {
                padding: 1vw 0;
            }

            &.ptb3 {
                padding: $padding 0;
                h4 {
                    font-size: $font_347;
                    padding: 0 $padding;
                    font-weight: bold;
                }
                .van-divider {
                    margin: $padding2 0 0 $padding1;
                }
                .s-label {
                    padding: $margin $padding1 0;
                }
            }
        }

        .s-radio {
            width: 100%;
            text-align: right;
            i {
                position: relative;
                margin-left: 4vw;
                padding-left: 5vw;
                font-style: normal;
                height: 4vw;
                line-height: $font_400;
            }
            i:first-child {
                margin-left: 0;
            }
            ::before {
                @extend .s-ani;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 4vw;
                height: 4vw;
                border-radius: 50%;
                box-shadow: inset 0 0 0 2px $c-info;
            }
            .checked {
                color: $c-main;
                &::before {
                    box-shadow: inset 0 0 0 4px $c-main;
                }
            }
        }
        .s-upload {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 2.67vw 4.26vw;
            border-bottom: 1px solid #ebedf0;
            position: relative;
            &::before {
                position: absolute;
                left: -2vw;
                color: #FF5900;
                font-size: 3vw;
                content: '*';
            }
            .lable {
                color: #646566;
                font-size: 3.47vw;
                padding-left: 2vw;
                .name {
                    margin-top: 2vw;
                    font-size: 3vw;

                }
            }
        }
        ::v-deep .van-field__label {
            max-width: 26.6vw;
        }
        ::v-deep .van-field__control--custom {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    #{&}--footerBar {
        .view {
            background: white;
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-col {
                height: 100%;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
    }

    #{&}--popup {
        border-top-left-radius: $radius-s;
        h3 {
            padding: $padding2 $padding;
            font-size: $font_480;
            line-height: $font_667;
        }
        .btnView {
            position: static;
            // height: $footerHeight;
            box-sizing: border-box;
            padding: $headerMargin $padding;
            margin-top: $margin;
            ::v-deep .soltFooter--view {
                height: 10.67vw;
            }
            ::v-deep .van-col {
                height: 100%;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
        ::v-deep .van-cell, .s-label {
            padding-left: $padding;
            padding-right: $padding;
        }
        ::v-deep .van-cell:not(:last-child)::after {
            left: 6.4vw;
            right: 6.4vw;
        }
        .s-label {
            margin: 4vw 0 6.4vw;
        }
    }

    ::v-deep .van-cell {
        padding-left: $padding1;
        padding-right: $padding1;
    }
    ::v-deep .van-cell__title {
        font-size: $font_347;
        // font-weight: bold;
    }
    ::v-deep .van-cell__value {
        font-size: $font_347;
        color: $c-text;
    }
    ::v-deep .van-field__control {
        text-align: right;
        font-size: $font_300;

        &::-webkit-input-placeholder {
            font-size: $font_347;
            color: $c-gray2;
        }
    }
    .blod ::v-deep .van-cell__value {
        font-weight: bold;
    }
}
</style>
