<template>
  <div class="success">
    <StateBar></StateBar>
    <TitleHeader :title="title" bgcolor="transparent" :back="false" />

    <AutoView :header="true" :footer="true" class="success--view l-view">
      <van-skeleton :loading="loading" :animate="false" :row="3" class="s-skeleton s-radius__s">
        <div class="success--tips l-panel s-radius__s">
          <van-image class="icon" fit="cover" src="/static/icon/payment_success.png" />
          <template v-if="surplus">
            <h3>支付成功</h3>
            <p class="strong">{{ reportData.goodsName }}</p>
            <p class="tips-p">您已购买成功，并于{{ reportData.userTime }}使用1次，<i>还剩{{ reportData.leftDesc }}次</i>，剩余套餐次数可在<i>{{ reportData.gotoDesc }}</i>中使用</p>
          </template>
          <template v-if="!surplus">
            <PayUnlock :reportName="reportData.reportName" />
          </template>
        </div>
        <div class="success--unlock l-panel s-radius__s" v-if="surplus">
          <PayUnlock :reportName="reportData.reportName" />
        </div>
      </van-skeleton>
    </AutoView>

    <SoltFooter class="success--footerBar">
      <div class="confirm-btn">
        <van-button type="warning" block @click="onTap">确定</van-button>
      </div>
    </SoltFooter>
  </div>
</template>

<script>
import PayUnlock from "./components/pay_unlock";

const DELAY_TIME = 2000;

export default {
  name: "success",
  components: { PayUnlock },
  data() {
    return {
      title: "支付成功",
      loading: true, // 数据加载
      reportData: {},
    };
  },
  computed: {
    surplus() {
      return parseInt(this.reportData.leftDesc) || 0;
    }
  },
  created() {
    // 获取订单ID
    const orderNo = this.$route.query.orderNo;
    if (!orderNo) {
      this.$router.push("/");
      return;
    }
    this.HTTP.paySuccess(orderNo)
      .then((res) => {
        console.log(res);
        this.reportData = res.data || {};
        this.loading = false;
      })
      .catch((e) => {
        this.$toast("网络不稳定，正在重试！");
        this.timer = setTimeout(() => {
          location.reload();
        }, DELAY_TIME * 2.5);
      });
  },
  beforeMount() {
    this.JSBridge.callHandler("setStatebarTheme", "black");
    this.JSBridge.callHandler("switchNavbar", false);
    // 禁止返回操作
    this.JSBridge.callHandler("interceptBack", true);
  },
  methods: {
    onTap() {
      this.swRouter("/h5/#/my/reports?index=1");
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.success {
  height: 100vh;
  background-color: $c-pageBg2;

  #{&}--view {
    opacity: 1;
    .sub-msg {
      color: #8d5d22;
      font-size: $font_293;
      line-height: $font_400;
      text-align: center;
      font-weight: bold;
    }
  }

  #{&}--tips {
    padding: $padding;
    text-align: center;

    .icon {
      width: $iconSize;
      height: $iconSize;
      margin: 2.4vw 0;
    }
    h3 {
      font-size: $font_373;
      line-height: $font_533;
      color: #333;
      margin-top: 1.6vw;
    }
    .strong {
      font-size: 4.27vw;
      color: #1d2233;
      font-weight: bold;
      margin-top: 4vw;
    }
    .tips-p {
      font-size: 3.47vw;
      color: #999;
      padding-top: 2.13vw;
      line-height: 4.8vw;
      text-align: left;
      i {
        font-style: normal;
        color: #ff5900;
      }
    }
  }

  #{&}--unlock {
    padding: $padding2;
    text-align: center;
    margin-top: 2.13vw;
  }

  #{&}--footerBar {
    .confirm-btn {
      width: 100%;
      padding: 0 4vw;
    }
  }

  ::v-deep .van-button {
    background: linear-gradient(90deg, #ff8000 0%, #ff5900 100%);
  }
}
</style>
