<template>
    <div class="all_content">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true" style="border-bottom: 1px solid #EBEAEE;box-sizing: content-box;">
            <template slot="right">
                <span class="my_commonts" @click="openMyCommonts">我的评价</span>
            </template>
        </TitleHeader>
        <div class="comments_btn">
            <div class="btn_list" :class="{'checked': currentIndex == index, 'noClassColor': val.name == '需谨慎加盟'}"
                v-for="(val, index) in rateVal"
                :key="index"
                @click="topTab(index)">
                <p>{{val.name}}<span>({{val.code}})</span></p>
            </div>
        </div>
        <AutoView ref="view" :header="true" :footer="false" class="all_content--view" :class="{ androidPosition: isAndroidOne, iosPosition: isIOSOne, ipxPosition: isIPXOne }">
            <van-skeleton :loading="allLoading" :animate="false" :row="4" class="s-skeleton">
                <div class="all_content--main">
                    <div class="no_data" v-if="list.length <= 0 && !isFlag">
                        <EmptyCard  type="community"></EmptyCard>
                    </div>
                    <div class="comments_swrap" v-else>
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            :immediate-check="false"
                            @load="loadData">
                            <div v-for="(val, index) in list" :key="index" :class="(list.length > 1 && index == 0) ? 'imgTextClass':''" @click="openDetail(val.id, 1)">
                                <div class="comments_list" :class="(index+1) == list.length ? 'lastClass': ''"  >
                                    <div class="list_title">
                                        <div class="comments_img">
                                            <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="val.avatar">
                                                <template v-slot:loading>
                                                    <van-loading type="spinner" size="20" />
                                                </template>
                                            </van-image>
                                        </div>
                                        <div class="comments_text">
                                            <div class="text_top">
                                                <div class="name">{{val.username}}</div>
                                                <div class="time">{{val.time}}</div>
                                            </div>
                                            <div class="text_rate">
                                                <span >打分</span>
                                                <van-rate readonly v-model="val.score" gutter="0.53vw" size="3.2vw" void-icon="star" color="#FFCB00" void-color="#D8D8D8" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="the_label">
                                        <template v-if="val.tags != null">
                                            <template v-if="val.tags.length > 0">
                                                <p v-for="(item, key) in val.tags" :class="item == '需谨慎加盟' ? 'noColor': ''" :key="key">{{item}}</p>
                                            </template>
                                        </template>
                                    </div>
                                    <foldText :commontText="val.content"></foldText>
                                    <!-- 品牌回复 -->
                                    <template v-if="val.commentList != null">
                                        <template v-if="val.commentList.length > 0">
                                            <div class="brand_commonts" v-for="citem in val.commentList" :key="citem.id">
                                                <div class="brand_commonts_img">
                                                    <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="citem.avatar">
                                                        <template v-slot:loading>
                                                            <van-loading type="spinner" size="20" />
                                                        </template>
                                                    </van-image>
                                                </div>
                                                <div class="brand_commonts_text">
                                                    <div class="brand_commonts_title">
                                                        <h3>{{citem.username}}</h3>
                                                        <span>{{citem.time}}</span>
                                                    </div>
                                                    <p>{{citem.content}}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </template>

                                    <div class="click_com">
                                        <div class="click_com_item" @click.stop="giveLike(val.id, val.isNice,index)">
                                            <img v-if="val.isNice == false" src="/static/icon/zan-4.png" alt="">
                                            <img v-else src="/static/icon/zan2.png" alt="">
                                            <span v-if="val.nice == 0">赞</span>
                                            <span v-else>{{val.nice}}</span>
                                        </div>
                                        <div class="click_com_item click_com_last" @click.stop="openDetail(val.id, 2)">
                                            <img src="/static/icon/pinglun.png" alt="">
                                            <span v-if="val.commentCount == 0">评价</span>
                                            <span v-else>{{val.commentCount}}</span>
                                        </div>
                                    </div>
                                    <img class="top_select" v-if="val.top == 1" src="/static/img/select_top.png" alt="">
                                </div>
                                <div class="two_class" v-if="list.length > 1 && index == 0">
                                    上上参谋致力于呈现最客观的品牌评价
                                </div>
                            </div>
                        </van-list>
                    </div>

                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>


<script>
import foldText from '../components/newBrandDetail/commontsText'
export default {
    name: "allCommonts",
    components: {
        foldText
    },
    data() {
        return {
            title: "",
            allLoading: true,

            loading: true,
            finished: false,
            isFlag: false,
            params: {
                current: 1,
                size: 10,
                refId: null,
                tag: null,
            },
            total: 0,
            list: [],

            rateVal: [],
            currentIndex: null,


            isAndroidOne        : false,
            isIOSOne            : false,
            isIPXOne            : false,
        }
    },
    beforeMount () {
        this.isAndroidOne = window.isClient && window.isAndroid;
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOSOne = window.isIOS;
            this.isIPXOne = window.isIPX;
        }
    },
    methods: {
        // 点击评论类型
        topTab(index) {
            for(let i=0; i<this.rateVal.length; i++ ) {
                if(i == index) {
                    this.rateVal[index].checked = !this.rateVal[index].checked;
                    if(this.rateVal[index].checked == true) {
                        this.currentIndex = index;
                        this.params.tag = this.rateVal[index].name;
                        this.params = {
                            current: 1,
                            size: 10,
                            refId: this.$route.params.id,
                            tag: this.rateVal[index].name
                        };
                    } else {
                        this.currentIndex = null;
                        this.params.tag = '';
                        this.params.current = 1;
                    }
                } else {
                    this.rateVal[i].checked = false;
                }
            }
            this.list = [];
            this.finished = false;
            this.loadData();
        },
        // 我的评论
        openMyCommonts() {
            this.swRouter({
                path: '/brand/my_commonts/',
                query: {
                    name: this.$route.query.name,
                    id: this.$route.params.id
                }
            });
        },
        // 跳转到详情
        openDetail(id, num) {
            this.swRouter({
                path: '/brand/commonts_detail/'+id,
                query: { num: num}
            });
        },
        // 点赞
        giveLike(id, bool, index) {
            let status = '';
            if(bool) {
                status = 0;
            } else {
                status = 1;
            }
            this.HTTP.giveLike(id,status).then(res => {
                this.list[index].isNice = !this.list[index].isNice;
                if(status == 1) {
                    this.list[index].nice = ++this.list[index].nice;
                } else {
                    this.list[index].nice = --this.list[index].nice;
                }
            }).catch(err => {
                this.$toast("网络不稳定，请稍后再试！");
            })
        },

        loadData() {
            this.loading = true;
            this.isFlag = true;
            this.HTTP.allCommonts(this.params).then(res => {
                console.log(res)
                this.allLoading = false;
                this.loading = false;
                this.isFlag = false;
                this.params.current++;
                this.total = res.data.total;
                if(this.list.length) {
                    this.list = this.list.concat(res.data.data);
                } else {
                    this.list = res.data.data;
                }
                if (res.data.data.length < res.data.size || this.list.length >= this.total) {
                    this.finished = true; // 完成加载
                }
            }).catch((e) => {
                this.$toast("网络连接不稳定，请稍候重试！");
                this.loading = false;
            });
        }
    },
    mounted() {
        this.params.refId = this.$route.params.id;
        this.title = this.$route.query.name+"品牌评价";
        if(this.$route.query.cindex) {
            this.currentIndex = this.$route.query.cindex;
        } else {
            this.currentIndex = null;
        }
        this.HTTP.commontsTabs(this.$route.params.id).then(res => {
            let nwe = [];
            for(let key in res.data) {
                nwe.push({
                    code: res.data[key],
                    name: key,
                    checked: false,
                })
            }
            this.rateVal = nwe;
            if(this.currentIndex != null) {
                this.params.tag = nwe[this.currentIndex].name;
                this.rateVal[this.currentIndex].checked = true;
            }
            this.loadData();
        }).catch((e) => {
            this.$toast("网络连接不稳定，请稍候重试！");
        });
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.all_content{
    height: 100vh;
    overflow: hidden;
    background: #F7F7F7;
    .my_commonts{
        font-size: 3.47vw;
        color: #4B8EEA;
    }
    .comments_btn{
        position: sticky;
        top: 0;
        z-index: 2;
        left: 0;
        padding: 4vw;
        margin-bottom: 2.67vw;
        box-sizing: border-box;
        background: #fff;
        width: 100%;
        height: auto;
        font-size: 0;
        vertical-align: middle;
        line-height: normal;
        .btn_list{
            width: calc(33.33% - 1.33vw);
            height: 7.2vw;
            border: 1px solid #FF9F6B;
            border-radius: 0.67vw;
            box-sizing: border-box;
            color: #FF5900;
            font-size: 3.47vw;
            text-align: center;
            display: inline-block;
            margin: 0 2vw 2.4vw 0;
            transition: all .5s;
            p{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                span{
                    font-size: 3.2vw;
                }
            }
            &:nth-child(3n) {
                margin-right: 0px;
            }
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
        .noClassColor{
            border: 1px solid #D8D9DF;
            color: #999999;
            background: transparent;
        }
        .checked{
            background: #FFF1E9;
            border: 1px solid #FF9F6B;
            color: #FF5900;
            font-weight: bold;
        }

    }
    #{&}--view{
        width: 100%;
        height: calc(100% - 15.5vw - 27.47vw) !important;
    }
    #{&}--main{
       width: 100%;
       height: 100%;
        .no_data{
           padding: 1.33vw 4vw 4vw !important;
        }
        .comments_swrap{
            width: 100%;
            //  4vw 0.93vw
            box-sizing: border-box;
            height: auto;
            .comments_list{
                padding: 4vw;
                background: #fff;
                width: 100%;
                height: auto;
                box-sizing: border-box;
                position: relative;
                .list_title{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .comments_img{
                        width: 9.06vw;
                        height: 9.06vw;
                        border-radius: 9.06vw;
                        overflow: hidden;
                        margin-right: 1.33vw;
                    }
                    .comments_text{
                        flex: 1;
                        .text_top{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 0.53vw;
                            .name{
                                font-size: 3.73vw;
                                font-weight: bold;
                                color: #333333;
                                line-height: 5.33vw;
                                flex: 1;
                            }
                            .time{
                                font-size: 2.93vw;
                                color: #999999;
                            }
                        }
                        .text_rate{
                            width: 100%;
                            height: auto;
                            font-size: 0;
                            span{
                                display: inline-block;
                                font-size: 2.93vw;
                                color: #333333;
                                line-height: 4vw;
                                margin-right: 0.8vw;
                                vertical-align: middle;
                                line-height: normal;

                            }
                            ::v-deep .van-rate--readonly{
                                vertical-align: middle;
                            }
                        }
                    }
                }
                .the_label{
                    margin: 2.93vw 0 2.53vw;
                    overflow-y: auto;
                    white-space: nowrap;
                    line-height: normal;
                    font-size: 0;
                    p{
                        display: inline-block;
                        // background: #FFF1E9;
                        border-radius: 0.67vw;
                        line-height: normal;
                        border: 1px solid #FF9F6B;
                        padding: 0.8vw 2.27vw;
                        font-size: 2.93vw;
                        color: #FF5900;
                        margin-right: 1.33vw;
                        &:last-child{
                            margin-right: 0px;
                        }
                    }
                    .noColor{
                        border: 1px solid #D8D9DF;
                        color: #999999;
                        background: transparent;
                    }
                }
                .brand_commonts{
                    width: 100%;
                    height: auto;
                    background: #F7F7F7;
                    border-radius: 0.8vw;
                    padding: 3.33vw;
                    margin-top: 4.8vw;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: top;
                    .brand_commonts_img{
                        width: 9.07vw;
                        margin-right: 1.33vw;
                        border-radius: 9.07vw;
                        overflow: hidden;
                        height: 9.07vw;
                        ::v-deep .s-radius__xs{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .brand_commonts_text{
                        flex: 1;
                        .brand_commonts_title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h3{
                                flex: 1;
                                font-size: 3.73vw;
                                font-weight: bold;
                                color: #333333;
                                line-height: 5.33vw;
                            }
                            span{
                                font-size: 2.93vw;
                                color: #999999;
                            }
                        }
                        p{
                            font-size: 3.47vw;
                            color: #333333;
                            line-height: 4.93vw;
                            margin-top: 1.47vw;
                        }
                    }
                }
                .click_com{
                    width: 100%;
                    height: auto;
                    font-size: 0;
                    line-height: normal;
                    margin-top: 2.27vw;
                    display: flex;
                    align-content: center;
                    flex: 1;
                    .click_com_item{
                        margin-left:auto;
                        line-height: normal;
                        vertical-align: middle;
                        img {
                            width: 4vw;
                            height: 4vw;
                            vertical-align: middle;
                            margin-right: 1.33vw;
                        }
                        span{
                            font-size: 3.2vw;
                            color: #666666;
                            line-height: normal;
                            vertical-align: middle;
                        }
                    }
                    .click_com_last{
                        margin-left: 7.47vw;
                    }
                }
                .top_select{
                    position: absolute;
                    width: 10.8vw;
                    height: 8.93vw;
                    top: 11.07vw;
                    right: 4.4vw;
                    z-index: 0;
                }
                &::after{
                    height: 1px;
                    width: calc(100% - 8vw);
                    position: absolute;
                    left: 4vw;
                    right: 4vw;
                    bottom: 0vw;
                    content: "";
                    z-index: 2;
                    background: #E7E7E7;
                }
            }
            .lastClass{
                &:after{
                    height: 0;
                }
            }
            .two_class{
                width: 100%;
                height: 8vw;
                background: url("/static/img/commonts_two.png") no-repeat;
                background-size: 100%;
                font-size: 3.2vw;
                font-weight: bold;
                color: #4E83DD;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: normal;
            }
            .imgTextClass .comments_list {
                &::after{
                    height: 0;
                }
            }
        }
    }
    .androidPosition{
        height: calc(100% - #{$headerHeight} - 27.47vw - #{$androidStateBar}) !important;
    }
    .iosPosition{
        height: calc(100% - #{$headerHeight} - 27.47vw - #{$iOSStateBar}) !important;
    }
    .ipxPosition{
        height: calc(100% - #{$headerHeight} - 27.47vw - #{$iPXStateBar}) !important;
    }
}
</style>
