<template>
    <div class="cpnDialog">
        <StateBar />
        <TitleHeader title="我的优惠券" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="cpnDialog--view">
            <p class="txt">
                <span><i>1.</i>如何获取更多优惠券？</span>
                <!-- 、推荐好友加入{{ appName }}等积极贡献行为都是你获取优惠券的通道，{{ appName }}会根据行为贡献评估和设置优惠券获得后的有效期。 -->
                <span><i>1</i>关注APP内最新活动通知，及时领取；</span>
                <span><i>2</i>积极转发分享、推荐好友加入{{ appName }}</span>
            </p>
            <p class="txt">
                <span><i>2.</i>适用范围</span>
                <span>
                    {{ appName }}APP内产品，详见每张优惠券限定条件。
                </span>
            </p>
            <p class="txt">
                <span><i>3.</i>优惠券规则</span>
                <span>
                    优惠券仅作为{{ appName }}奖励用户贡献行为的临时方式，优惠券获取后在有效期内可使用。
                </span>
            </p>

            <!-- <p v-if="list && list.length" class="txt-box">
                <van-grid :gutter="5" :column-num="3" >
                    <van-grid-item class="bg1" text="获取方式" />
                    <van-grid-item class="bg2" text="奖励" />
                    <van-grid-item class="bg1" text="规则说明" />

                    <template v-for="( item, index ) of list">
                        <van-grid-item class="bg3" :key="'A'+index" :text="item.name" />
                        <van-grid-item class="bg4" :key="'B'+index" :text="item.couponName + ' x 1'" />
                        <van-grid-item class="bg3" :key="'C'+index" :text="item.remark" />
                    </template>
                </van-grid>
            </p> -->
        </AutoView>
    </div>
</template>

<script>
export default {
    name: 'cpnDialog',
    data () {
        return {
            appName: this.JSBridge.appName(),
        };
    },
    computed: {
        list () {
            return this.$store.state.couponRule;
        },
    },
    mounted () {
        if ( ! this.list ) {
            this.HTTP.couponRule().then( ( res ) => {
                this.$store.commit( 'setCouponRule', res );
            } );
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.cpnDialog {
    height: 100vh;

    #{&}--view {
        padding-bottom: $padding;
    }

    .txt{
        padding: 5vw 7vw 0;
        span{
            display: block;
        }
        :nth-child(1){
            font-size: 4vw;
            font-weight: 500;
            color: #1D2233;
            i{
                font-style: normal;
                color:#4B8EEA;
            }
        }
        :nth-child(2){
            margin: 2vw auto 0;
            font-size: 3vw;
            color: #8E9099;
            line-height: 6vw;
            display: inline-block;
            vertical-align: middle;
            i{
                display: inline-block;
                width: 3.47vw;
                height: 3.47vw;
                border: 1px solid #8E9099;
                border-radius: 3.47vw;
                font-size: 3vw;
                line-height: 3.47vw;
                margin-right: 1.07vw;
                text-align: center;
                font-style: normal;
                box-sizing: border-box;
                color: #8E9099;
            }
        }
        :nth-child(3) {
            margin: 0vw auto 5vw;
            font-size: 3vw;
            color: #8E9099;
            line-height: 6vw;
            i{
                display: inline-block;
                width: 3.47vw;
                height: 3.47vw;
                border: 1px solid #8E9099;
                border-radius: 1.73vw;
                font-size: 3vw;
                line-height: 3.47vw;
                margin-right: 1.07vw;
                text-align: center;
                color: #8E9099;
                box-sizing: border-box;
                font-style: normal;
            }
        }
    }
    .txt-box{
        padding: 0 7vw;
    }
    ::v-deep .van-grid-item__content{
        border: none;
    }
    ::v-deep .van-grid-item__content--surround::after{
        border: none;
    }
    ::v-deep .van-grid-item__content::after{
        border: none;
    }
    ::v-deep .van-grid-item:first-child .van-grid-item__content{
        border-radius: 3vw 0 0 0;
    }
    ::v-deep .van-grid-item:nth-child(3) .van-grid-item__content{
        border-radius: 0 3vw 0 0;
    }
    ::v-deep .van-grid-item:nth-last-child(3) .van-grid-item__content{
        border-radius: 0 0 0 3vw;
    }
    ::v-deep .van-grid-item:last-child .van-grid-item__content{
        border-radius: 0 0 3vw 0;
    }
    ::v-deep.van-grid-item__text{
        font-size: 3vw;
    }
    .bg1{
        ::v-deep .van-grid-item__content{
            background: #EFEDF1;
            .van-grid-item__text{
                color: #1D2233;
            }
        }
    }
    .bg2{
        ::v-deep .van-grid-item__content{
            background: #F9E8DE;
            .van-grid-item__text{
                color: #C85D2D;
            }
        }
    }
    .bg3{
        ::v-deep .van-grid-item__content{
            background: #F7F6F9;
            .van-grid-item__text{
                color: #1D2233;
            }
        }
    }
    .bg4{
        ::v-deep .van-grid-item__content{
            background: #FBF2ED;
            .van-grid-item__text{
                color: #F58958;
            }
        }
    }
}
</style>
