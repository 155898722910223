<template>
  <div class="orders">
    <StateBar></StateBar>
    <TitleHeader :title="title" bgcolor="transparent" :back="showBack">
      <div slot="right" @click="swRouter('/bills')" class="btnColor">开发票</div>
    </TitleHeader>

    <AutoView :header="true" :footer="false" class="orders--view l-view">
      <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <EmptyCard v-if="list.length === 0" type="orders"></EmptyCard>
        <template v-else>
          <van-list v-model="loading" :finished="finished" finished-text="我是有底线的" @load="loadData">
            <OrderCard v-for="item in list" :key="item.id" @tap="onTap" :item="item" @pay="prePay" />
          </van-list>
        </template>
      </van-skeleton>
    </AutoView>
    <!-- 支付选择界面 -->
    <van-popup
      v-model="showPayment"
      round
      position="bottom"
      :safe-area-inset-bottom="false"
      :close-on-click-overlay="false"
      @click-overlay="showPayment=false"
    >
      <div class="l-panel f-vp">
        <div class="pay-title">选择支付方式</div>
        <div class="view">
          <!-- 支付方式选择 -->
          <PayTypes :payList="payList" @setPayType="setPayType" />
        </div>
        <van-button type="info" block :loading="waiting" class="s-radius__s" @click="onPay">确认支付</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import OrderCard from "@/components/card/order";
import PayTypes from "@/components/payTypes";

export default {
  name: "orders",
  components: {
    OrderCard,
    PayTypes
  },
  data() {
    return {
      title: "我的订单",
      showBack: true,
      firstLoading: true,
      loading: true,
      dataLoading: false,
      finished: false,
      page: 1,
      total: 0,
      list: [],
      // 支付
      showPayment: false,
      client: 3, // 1iOS 2Android 3WEB
      payList: ["支付宝", "微信"],
      payType: null,
      payMode: null,
      payCall: null,
      payMap: {
        支付宝: {
          mode: "ALIPAY_MOBILE",
          call: "aliPay",
          unit: "￥",
        },
        微信: {
          mode: "WX_APP",
          call: "wxPay",
          unit: "￥",
        },
        "Apple Pay": {
          mode: "APPLE_IN",
          call: "applePay",
          unit: "￥",
        },
      },
      waiting: false,
      payParam: {}
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      this.HTTP.orderList(this.page)
        .then(res => {
          this.firstLoading = false;
          this.loading = false;
          this.page++;
          this.total = res.total;
          if (this.list.length) {
            this.list = this.list.concat(res.data);
          } else {
            this.list = res.data;
          }
          if (res.data.length < res.size || this.list.length >= this.total) {
            this.finished = true; // 完成加载
          }
          console.log(res);
        })
        .catch(e => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
          this.finished = true;
        });
    },
    onTap(item) {
      this.openPage(item.businessId, item.orderNo, item.businessType);
    },
    openPage(id, orderNo, businessType) {
      let url = "/pay/" + id + "?orderNo=" + orderNo;
      if (businessType == 7) {
        url = "/aiPay/" + id + "?orderNo=" + orderNo;
      }
      this.swRouter(url);
    },
    // 支付
    prePay(param) {
      this.showPayment = true
      this.payParam = param
    },
    onPay() {
      //业务流程
      this.waiting = true;
      console.log("创建订单..." + this.payMode + "=====" + this.client);
      if (this.payMode == "WX_CONTRACT" || this.payMode == "WX_APP") {
        this.setPayType('微信');
      }
      console.log('支付信息',{
        ...this.payParam,
        payTypeChannel: this.payMode,
        sourceType: this.client,
        pageId: String(this.$route.meta.id)
      })
      this.HTTP.servicePayOrder({
        ...this.payParam,
        payTypeChannel: this.payMode,
        sourceType: this.client,
        pageId: String(this.$route.meta.id)
      }).then((res) => {
        console.log(res, "打印payResult")
        console.log("创建订单...end");
        console.log("调起支付...");
        this.orderNo = res.orderNo;
        const data = {
          id: res.orderNo,
          data: res.payResult,
          pageId: this.$route.meta.id,

        };
        if (window.isClient) {
          this.JSBridge.callHandler(this.payCall, data, (st) => {
            if (!st) {
              this.$toast("未安装客户端，调起支付失败！");
              setTimeout(() => {
                this.waiting = false;
              }, 1000);
              console.log(`调起支付...失败`);
              return;
            }
            console.log(`调起支付...end`);
          });
        } else {
          this.$toast("暂不支持网页版支付");
          setTimeout(() => {
            this.waiting = false;
          }, 1000);
        }
      })
        .catch((e) => {
          this.$toast("创建订单时发生错误！");
          setTimeout(() => {
            this.waiting = false;
          }, 1000);
        });
    },
    setPayType(value) {
      if(window._appCode >= 313 && value === '微信') {
        const map = this.payMap["微信"]
        this.payType = value;
        this.payMode = map.mode;
        this.payCall = map.call;
        return
      }
      const map = this.payMap[value];
      this.payType = value;
      this.payMode = map.mode;
      this.payCall = map.call;
      // this.info.unit = map.unit;
    },
  },
  created() {
    this.loadData();
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("clearHistory", "my");
    this.JSBridge.callHandler("setStatebarTheme", "black");

    // 注册支付回调
    this.JSBridge.registerHandler("paymentResult", (data) => {
      console.log(data, "state")
      // 安卓端需要进行转义
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
      console.log(data, "state")
      if (typeof data === "object") {
        if (!data.id) return;
        this.waiting = false;
        switch (data.state) {
          case 1:
            // 支付成功
            this.showPayment = false;
            this.$toast("支付成功");
            this.list = []
            this.page = 1
            this.loadData()
            break;
          case 5: // 兼容微信
            break;
          case 7: // 超时
          case 8: // 取消
            this.$toast("已取消支付");
            break;
          default:
            // 支付失败
            this.showPayment = false;
            this.$toast("支付失败");
        }
      } else {
        this.$toast("支付时发生错误！");
        this.waiting = false;
      }
    });
    // 设置客户端类型
    if (window.isIOS) {
      this.isIOS  = true;
      this.client = 1;
    } else if (window.isAndroid) {
      this.isAndroid = true
      this.client = 2;
    }
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";
.f-vp {
  .pay-title {
    font-size: 4vw;
    text-align: center;
    margin-bottom: vw2(20);
    font-weight: bold;
  }
}
.orders {
  height: 100vh;
  background-color: $c-pageBg;

  .btnColor {
    color: $c-main;
  }

  #{&}--view {
    .l-panel {
      padding: 4vw 4vw vw2(12) 4vw;
    }
  }
}
</style>
