// 动态获取高德地图key，异步加载高德地图
function getKey () {
    return Promise.resolve( 'fee5094dc0300f1001a61bef444e041b' );
}

export default async function loadMap () {
    if ( window.AMap ) {
        return new Promise( function ( resolve, reject ) {
            resolve( window.AMap );
        } );
    }
    const key = await getKey();
    const src = `https://webapi.amap.com/maps?v=1.4.15&plugin=AMap.Heatmap,AMap.PlaceSearch,AMap.Autocomplete,AMap.Geocoder,AMap.DistrictLayer,AMap.DistrictSearch&key=${key}`;
    const script = document.createElement( 'script' );
    script.src = src;
    document.body.appendChild( script );
    return new Promise( function ( resolve, reject ) {
        script.onload = function () {
            resolve( window.AMap );
        };
        script.onerror = function () {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject();
        };
    } );
    // 通过监听window.onload事件,来初始化高德地图
}
