<template>
    <div class="page">
        <div class="banner">
            <img src="static/img/channel/branch/head-img.png">
        </div>
        <a class="back" :href="getMainUrl()" v-if="!isClient">
            <img src="static/img/channel/icon-back.png">
        </a>
        <div class="content">
            <div class="card card-custom">
                <img src="/static/img/channel/branch/card-bg-01.png">
              <div class="p-15">
                <img src="/static/img/channel/branch/card-bg-02.png">
              </div>
                <template v-if="userInfo.benefitInfo">
                    <div class="btn-wrap" v-if="query.d == DOWN_TYPE_ENUM.LINK">
                        <p class="title">{{ userInfo.benefitInfo }}</p>
                        <button @click="toLink">下载APP 使用权益</button>
                    </div>
                    <div class="btn-wrap" v-else>
                        <p class="title">{{ userInfo.benefitInfo }}</p>
                        <button @click="showDownload">使用权益</button>
                    </div>
                </template>
                <div v-else class="btn-wrap">
                    <button @click="showLogin()">限时免费领取</button>
                </div>
              <div class="progress">
                <img src="/static/img/channel/branch/progress.png">
              </div>
            </div>
            <div class="app">
                <ul class="nav">
                    <li :class="taba == 'tab_1' ? 'active' : ''" @click="tabChange('a', 1)">开店选址</li>
                    <li :class="taba == 'tab_2' ? 'active' : ''" @click="tabChange('a', 2)">开店选品类</li>
                    <li :class="taba == 'tab_3' ? 'active' : ''" @click="tabChange('a', 3)">开店选品牌</li>
                    <li :class="taba == 'tab_4' ? 'active' : ''" @click="tabChange('a', 4)">开店找商铺</li>
                </ul>
                <div class="content">
                    <div class="tab-item" v-show="taba == 'tab_1' ">
                        <img src="/static/img/channel/branch/img-6.png"/>
                    </div>
                    <div class="tab-item" v-show="taba == 'tab_2' ">
                        <img src="/static/img/channel/branch/img-7.png"/>
                    </div>
                    <div class="tab-item" v-show="taba == 'tab_3' ">
                        <img src="/static/img/channel/branch/img-8.png"/>
                    </div>
                    <div class="tab-item" v-show="taba == 'tab_4' ">
                        <img src="/static/img/channel/branch/img-9.png"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-wrap" v-show="loginVisible">
            <div class="dialog-content">
                <van-icon class="close-icon" name="cross" @click="close"/>
                <p class="title">免费领取</p>
                <div class="form-wrap">
                    <div class="input-wrap">
                        <input maxlength="11" v-model="phone" placeholder="请输入手机号"/>
                    </div>
                    <div class="input-wrap">
                        <input placeholder="请输入验证码" v-model="vcode" maxlength="10"/>
                        <button class="vcode">
                            <span v-if="time === 60" @click="sendCode">发送验证码</span>
                            <span v-else>{{time}}s</span>
                        </button>
                    </div>
                    <div class="btn-wrap">
                        <button @click="login">登录并领取权益</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-wrap down-dialog" v-show="downVisible">
            <div class="dialog-content">
                <van-icon class="close-icon" name="cross" @click="closeDown"/>
                <p class="title">如何使用权益</p>
                <div class="form-wrap">
                    <div class="img-wrap">
                        <img src="static/img/channel/branch/qrcode.png">
                    </div>
                    <p class="img-desc">
                        保存图片，打开微信扫一扫添加客服
                    </p>
                    <div class="btn-wrap">
                        <button @click="downImg">保存图片</button>
                    </div>
                </div>
            </div>
        </div>
        <!--<sw-dialog :visible.sync="downVisible">-->
        <!--    图片-->
        <!--</sw-dialog>-->
    </div>
</template>

<script>
import http from '@/request/http';
import md5 from 'md5';
import tabs from '../components/tabs/index.vue';
import Login from '../components/login/index.vue';
import SwDialog from '../components/dialog/index.vue';
import Player from "xgplayer";

export default {
    name: 'index',
    components: {
        tabs,
        Login,
        SwDialog,
    },
    data () {
        return {
            urls: {
                sscm: {
                    development: 'https://huawei-test-webclient.sscanmou.com/#/channel/wdfs',
                    test: 'https://huawei-test-webclient.sscanmou.com/#/channel/wdfs',
                    production: 'https://webclient.sscanmou.com/#/channel/wdfs',
                },
            },
            query: {
                c: '',
                m: '',
                b: '',
                p: '',
                d: '', // 1图片 2链接
            },
            taba: 'tab_1',
            loginVisible: false,
            downVisible: false,
            phone: '',
            name: '',
            time: 60,
            received: false,
            vcode: '',
            showTitle: false,
            userInfo: {
                benefitInfo: '',
            },
            DOWN_TYPE_ENUM: {
                IMG: 1,
                LINK: 2,
            },
            isClient: false,
        };
    },
    methods: {
        getUserId () {
            return window.localStorage.getItem('sscm-wdfs-user-id') || '';
        },
        setUserId (userId) {
            return window.localStorage.setItem('sscm-wdfs-user-id', userId);
        },
        closeDown(){
            this.downVisible = false;
        },
        downImg () {
            this.track({
                m: 'Module_wdfs_110004',
                b: 'Button_sscm_110005',
            }, 'btn');
            const a = document.createElement('a') // 创建一个a标签
            a.href = '/static/img/channel/branch/qrcode.png' // a标签的src属性赋值
            a.target = '_blank';
            a.download = 'sscm';
            document.body.appendChild(a) // 添加a标签到body下
            a.click() // 触发a标签点击事件
            document.body.removeChild(a) //  完成后删除a标签
            // this.$toast('下载成功');
            this.$toast({
                message: '下载成功',
                position: 'middle',
            });
            this.downVisible = false;
        },
        close () {
           this.loginVisible = false;
        },
        toLink() {
            this.track({
                m: 'Module_wdfs_110003',
                b: 'Button_sscm_110003',
            }, 'btn');
            window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.shuwei.sscm&fromcase=40003';
        },
        showDownload() {
            this.downVisible = true;
            this.track({
                m: 'Module_wdfs_110003',
                b: 'Button_sscm_110004',
            }, 'btn');
        },
        showLogin () {
            this.loginVisible = true;
            this.track({
                m: 'Module_wdfs_110001',
                b: 'Button_sscm_110001',
            }, 'btn');
        },
        sendMsg () {
            let data = {
                phone: this.phone,
            };
            let sign = md5(JSON.stringify(data) + '51cbb163-2214-48f0-8d4a-c0ed217f40a7');
            data.sign = sign;
            return http.sendLoginSmsV2(data);
        },
        sendCode () {
            let timer = null;
            const fun = () => {
                if (this.time > 0) {
                    this.time -= 1;
                    timer = setTimeout(() => {
                        fun();
                    }, 1000);
                } else {
                    this.time = 60;
                    timer && clearTimeout(timer);
                }
            }
            if (this.phone.length !== 11) {
                // this.$toast('请输入11位手机号码');
                this.$toast({
                    message: '请输入11位手机号码',
                    position: 'middle',
                });
                return;
            }
            this.sendMsg().then(() => {
                this.$toast({
                    message: '发送成功',
                    position: 'middle',
                });
                fun();
            }).catch((e) => {
                if (e.msg) {
                    this.$toast({
                        message: e.msg,
                        position: 'middle',
                    });
                }
            });
        },
        checkPhone () {
            let phone = String(this.phone);
            if (phone && phone.length === 11 ){
                return true;
            }
            return false;
        },
        checkCode () {
            if (this.vcode && this.vcode != ''){
                return true;
            }
            return false;
        },
        login () {
            if (!this.checkPhone()) {
                // this.$toast('请输入11位手机号码');
                this.$toast({
                    message: '请输入11位手机号码',
                    position: 'middle',
                });
                return;
            }
            if (!this.checkCode()) {
                // this.$toast('请输入验证码');
                this.$toast({
                    message: '请输入验证码',
                    position: 'middle',
                });
                return;
            }
            let c = this.$route.query.c || 'wdfs';
            let data = {
                code: this.vcode,
                phone: this.phone,
                platform: 'sscm',
                deviceType: 4,
                channelCode: c,
                imei: '',
                android_id: '',
                oaid: '',
                uuid: '',
                versionName: '1.0.0',
                versionCode: 100
            };
            http.login(data).then((res) => {
                console.log('登录成功：',res)
                if (res.data.code != 0 && res.data.msg) {
                    // this.$toast(res.data.msg);
                    this.$toast({
                        message: res.data.msg,
                        position: 'middle',
                    });
                    return;
                } else if(res.data.code != 0 && !res.data.msg){
                    // this.$toast('网络连接超时');
                    this.$toast({
                        message: '网络连接超时',
                        position: 'middle',
                    });
                    return;
                }
                if (res.data.data.benefitInfo == null) {
                    // this.$toast('领取权益失败！');
                    this.$toast({
                        message: '领取权益失败',
                        position: 'middle',
                    });
                    return;
                }
                console.log('登录成功：', res);
                // this.$toast('领取成功');
                this.$toast({
                    message: '领取成功',
                    position: 'middle',
                });
                res = res.data.data;
                this.userInfo.benefitInfo = res.benefitInfo;
                window.localStorage.setItem('benefitInfo',  res.benefitInfo);
                this.setUserId(res.id);
                this.loginVisible = false;
                this.loginTrack();
            }).catch((e) => {
                if (e.msg) {
                    // this.$toast(e.msg);
                    this.$toast({
                        message: e.msg,
                        position: 'middle',
                    });
                } else {
                    // this.$toast('网络连接超时');
                    this.$toast({
                        message: '网络连接超时',
                        position: 'middle',
                    });
                }
            });
        },
        track (_data, type) {
            let data = {
                c: '',
                m: '',
                b: '',
                p: '',
                d: '', // 1图片 2链接
            };
            data = Object.assign(data, this.query, _data);
            let trackRequestApi = http.sendTrack;

            if (type === 'btn') {
                trackRequestApi = http.sendBtnTrack;
            }
            trackRequestApi({
                deviceType: 4,
                platform: 'sscm',
                version: '0.0.1',
                userId: this.getUserId(),
                // parentPageId: data.p,
                track:  `[{\"pageId\":\"wdfs_110001\",\"refId\":\"wdfs\",\"extra\":\"${data.c}\",\"source\":{\"btnId\":\"${data.b}\",\"moduleId\":\"${data.m}\"},\"timestamp\":${new Date().getTime()}}]`
            })
        },
        pvTrack () {
            this.track({});
        },
        loginTrack() {
            this.track({
                m: 'Module_wdfs_110002',
                b: 'Button_sscm_110002',
            }, 'btn');
        },
        // 临时代码不要模仿
        tabChange(type,index){
            this[`tab${type}`] = `tab_${index}`
        },
        jsontoQuery(json){
            let str = [];
            for (let key in json){
                str.push(key + '=' + json[key])
            }
            return str.join('&');
        },
        getEnv () {
            console.log(process.env.NODE_ENV)
            return process.env.NODE_ENV;
        },

        getMainUrl () {
            return this.urls['sscm'][this.getEnv()]+'?'+ this.jsontoQuery(this.query);
        }
    },
    created() {
        http.setAuth('');
        document.documentElement.scrollTop = 0;
    },
    destroyed() {
        document.documentElement.scrollTop = 0;
    },
    mounted () {
        this.isClient = window.isClient;
        this.userInfo.benefitInfo = window.localStorage.getItem('benefitInfo');
        this.$nextTick(() => {
            let sscmPlayer = new Player({
                id: "mse",
                // poster: "", // 封面图
                url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_sscm.mp4",
                playsinline: true,
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": "false",
                closeVideoClick: true,
                closeVideoTouch: true,
                autoplay: false, // 自动播放
                videoInit: true, // 显示首帧
                fluid: true, // 流式布局
                // fitVideoSize: "fixWidth",
                ignores: ["volume"],
            });
            sscmPlayer.once('play', () => {
                this.track({m:'Module_wdfs_110005',b:'Button_sscm_110006'}, 'btn')
            })
        })
        this.query = Object.assign(this.query, this.$route.query);
        // pv
        this.pvTrack();
        document.documentElement.scrollTop = 0;
    }
};
</script>


<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

*{
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}
.page{
    background: #FFDEC2;
    font-size: 0;
    .back{
        position: absolute;
        left: 0px;
        top: vw(23);
        width: vw(98);
        img{
            width: 100%;
        }
    }
    .banner{
        img{
            width: 100%;
        }
    }
    .app{
        background: url("/static/img/channel/branch/card-bg-4.png") no-repeat;
        background-size: 100% auto;
        padding-top: vw(100);
        .img-wrap{
            text-align: center;
            padding-top: vw(38);
            img{
                display: inline-block;
                height: vw(43);
            }
        }
        .video-wrap{
            video{
                width: vw(710);
                height: vw(400);
            }
        }
        .content{
            // background: #fff;
            padding: 0;
            border-radius: 0 0 vw(10) vw(10);
            .tab-item{
                img{
                    width: 100%;
                }
            }
        }
        .btn-wrap{
            padding: vw(20) 0;
            display: inline-block;
            img{
                width: 100%;
            }
        }
    }
    .content{
        padding: vw(20);
        background: #ffe0c6;
        .card{
            position: relative;
            margin-bottom: vw(22);
            img{
                width: 100%;
            }
            video{
                width: 100%;
            }
        }
        .card-custom{
            background: #fff;
            border-radius: vw(10);
            .btn-wrap{
                padding: 0 vw(30) vw(20) vw(30);
                width: 100%;
                .title{
                    margin-top: vw(20);
                    font-size: vw(26);
                    font-weight: 400;
                    color: #D08428;
                    line-height: 100%;
                    text-align: center;
                }
                button{
                    width: 100%;
                    height: vw(80);
                    background: linear-gradient(90deg, #FF5900 0%, #FF8000 100%);
                    border-radius:vw(40);
                    font-size: vw(30);
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: vw(80);
                    margin-top: vw(20);
                }
            }
        }
        .video-wrap{
            position: absolute;
            width: vw(650);
            top: vw(100);
            left: vw(30);
        }
        .tab-item{
            img{
                width: 100%;
            }
        }
        //.card{
        //    padding: vw(30);
        //    background: #FFFFFF;
        //    border-radius: 10px;
        //    margin-bottom: vw(30);
        //}
    }
}

.dialog-wrap{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 999;
    *{
        font-size: vw(28);
    }
    .dialog-content{
        width: vw(670);
        background: #fff;
        border-radius: vw(20);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 vw(30);
        .close-icon{
            position: absolute;
            top: vw(20);
            right: vw(20);
            font-weight: bold;
        }
        .title{
            padding: vw(30);
            font-size: vw(34);
            font-weight: 600;
            color: #333333;
            line-height: vw(48);
            text-align: center;
        }
        .input-wrap{
            position: relative;
            height: vw(100);
            background: #F7F7F7;
            border-radius: vw(10);
            border: 1px solid #EBEAEE;
            margin-bottom: vw(20);
            input{
                height: 100%;
                width: 100%;
                background: none;
                padding: vw(30) vw(200) vw(30) vw(30);
            }
            .vcode{
                font-size: vw(28);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF5900;
                line-height: vw(100);
                position: absolute;
                top: 0;
                right: vw(30);
                background: none;
                height: vw(100);
            }
        }
        .btn-wrap{
            padding: vw(14) 0 vw(34) 0;
            button{
                width: 100%;
                height: vw(90);
                background: linear-gradient(90deg, #FF5900 0%, #FF8000 100%);
                border-radius: vw(45);
                font-size: vw(30);
                font-weight: 500;
                color: #FFFFFF;
                line-height: vw(42);
                text-align: center;
            }
        }
    }
}
.down-dialog{
    .img-wrap{
        padding: 0 vw(30);
        text-align: center;
        img{
            width: vw(400);
            display: inline-block;
        }
    }
    .img-desc{
        font-size: vw(28);
        color: #333333;
        line-height: vw(40);
        text-align: center;
        padding: vw(24) 0;
    }
}
.nav{
    font-size: 0;
    margin-top: vw(26);
    padding-bottom: vw(20);
    text-align: center;
    li{
        height: vw(60);
        width: vw(150);
        font-size: vw(24);
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        line-height: vw(60);
        display: inline-block;
        text-align: center;
        color: #1D2233;
        background: #fff;
        border-radius:  vw(8);
        margin: 0 vw(10);
        &.active{
            font-size: vw(26);
            color: #fff;
            background: #FF783A;
        }
    }
}
.p-15{
  padding: vw(15);
}
.progress{
  padding: vw(10) vw(104) vw(30) vw(104);
}
</style>
