<template>
  <div class="feedback">
    <StateBar />
    <TitleHeader title="反馈" bgcolor="#ffffff">
      <div slot="right" class="right">
        <img src="/static/icon/service_icon_new.png" alt="" @click="onLivechat" />
      </div>
    </TitleHeader>

    <AutoView :footer="false" class="feedback--view">
      <div class="l-panel s-radius__s">
        <label class="s-label">您对商业地图有任何的疑问和意见，请及时向我们反馈！</label>
        <van-field v-model="message" class="s-text s-radius__s f-vmt"
                   type="textarea"
                   maxlength="100"
                   placeholder=""
                   show-word-limit
                   :disabled="submitting" />

        <van-button type="info" block :disabled="disabled" :loading="submitting" to="feedback" class="s-radius__s f-vmt" @click="submit">提交</van-button>
      </div>

      <div class="feedback-tip">
        <p class="feedback-tip-title">常见问题：</p>
        <div class="feedback-tip-item">
          <p class="feedback-tip-item-question">1:数据来源是什么？</p>
          <p class="feedback-tip-item-answer">基于数位店铺级精准客流检测数据，结合大样本算法开展的数据挖掘和统计分析</p>
        </div>
        <div class="feedback-tip-item">
          <p class="feedback-tip-item-question">2:金角银边是什么？</p>
          <p class="feedback-tip-item-answer">
            金角：点位周边有街道交叉口，人流动线汇集点<br/>
            银边：金角位置的周边<br/>
            【当前仅支持64个城市，更多城市陆续更新中】
          </p>
        </div>
        <div class="feedback-tip-item">
          <p class="feedback-tip-item-question">3:铺位数据是如何获取的？</p>
          <p class="feedback-tip-item-answer">
            铺：参谋团队线下实地考察，多维度客观铺位数据<br/>
            【更多城市铺数据陆续更新中】
          </p>
        </div>
        <div class="feedback-tip-item">
          <p class="feedback-tip-item-question">4:更多问题可直接联系客服</p>
        </div>
      </div>
    </AutoView>
  </div>
</template>

<script>

export default {
  name      : 'feedback',
  components: {
  },
  data () {
    return {
      message   : '',
      submitting: false, // 提交中
      disabled  : true,
    };
  },
  watch: {
    message ( val ) {
      this.disabled = ! val.trim().length;
    },
  },
  methods: {
    submit () {
      if ( this.submitting ) return;
      this.submitting = true;
      this.$toast( '正在提交...' );
      this.HTTP.createFeedback( '商业地图：' + this.message ).then( ( res ) => {
        this.$toast( '已收到您的问题，我们会尽快处理！' );
        setTimeout( () => {
          this.message    = '';
          this.submitting = false;
        }, 2000 );
      } ).catch( ( e ) => {
        this.$toast( '网络不佳T.T请稍候重试' );
        setTimeout( () => { this.submitting = false; }, 2000 );
      } );
    },
    onLivechat () {
      if (window._appCode >= 175) {
        this.JSBridge.callHandler( 'onNormalizingCall', {
          type  : 5,
          url   : 'ONLINE_SERVICE',
          params: null,
        } );
      } else {
        this.JSBridge.callHandler(
          'callLiveChat',
          {
            type: 'normal',
            data: {},
          },
          ( st ) => {
          },
        );
      }
    },
  },
  mounted () {
    this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    this.JSBridge.callHandler( 'switchBackBtn', true );
    this.JSBridge.callHandler( 'switchNavbar', false );
  },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
@import '@/styles/px2vw.scss';
.feedback {
  height: 100vh;

  .right {
    img {
      width: 5.87vw;
      height: 5.33vw;
    }
  }
  .feedback-tip {
    color: #1D2233;
    margin-top: vw(50);
    .feedback-tip-title {
      font-size: vw(26);
    }
    .feedback-tip-item {
      margin-top: vw(20);
      .feedback-tip-item-question {
        font-size: vw(24);
      }
      .feedback-tip-item-answer {
        font-size: vw(22);
        margin-top: vw(6);
        line-height: vw(40);
        color: #999999;
      }
    }
  }
  #{&}--view {
    padding: 4vw;
    background-color: $c-pageBg2;
    .l-panel {
      padding: 4vw;
    }
    .s-text ::v-deep .van-field__control {
      height: 31.47vw;
      font-size: $font_427;
      line-height: 6vw;
      &::-webkit-input-placeholder {
        font-size: $font_427;
        line-height: 6vw;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    p {
      @include flex;
      justify-content: center;
      font-size: $font_320;
      line-height: $font_440;
      padding-bottom: $padding_4;
      color: #AEB1BD;
    }
    a {
      color: #AEB1BD;
    }
  }
}
</style>
