<template>
    <Wraper title="消费者评价" v-if="bgmData.length > 0">
        <div class="search-chart-city">
            <Wordcloud :bgmData="bgmData" />
        </div>
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";
import Wordcloud from "../../echarts/wordcloud.vue";

export default {
    components: { Wraper, Wordcloud },
    props: {
        bgmData: {
            type: Array,
            default: [],
        },
    },
};
</script>
<style lang="scss" scoped>
.search-chart-city {
    position: relative;
    width: 100%;
    height: 53.33vw;
}
</style>
