<template>
  <div class="charts">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" color="#1D2233" :back="showBack">
      <template slot="right" v-if="entity">
        <div class="right" @click="collectCommunity">
          <i class="store-icon" v-if="entity.isCollect"></i>
          <i class="un-store-icon" v-else></i>
        </div>
      </template>
    </TitleHeader>
    <AutoView :footer="false" class="charts--view">
      <iframe ref="view" :src="url" frameborder="0" class="innerView">不支持内嵌窗口</iframe>
    </AutoView>
    <LoadingOverlay :show="loading" />
  </div>
</template>

<script>
import { setLocalStorage } from '../../utils';

let _timer = 0;
export default {
  name: 'index',
  data() {
    return {
      title: '正在加载',
      showBack: true,
      loading: true, // 数据加载
      host: "/report/?id=",
      id: '',
      entity: null,
      isReady: false, // 报告界面已就绪
      isSended: false, // 已下发数据
      isClient: false,
    }
  },
  computed: {
    url() {
      if (!this.entity) return "about:blank";
      return this.host + this.id;
      // return 'http://10.1.2.33:9002/?id=' + this.id;
    },
    viewTarget() {
      return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
    },
  },
  methods: {
    sendData() {
      // 已发送或未就绪或未得到数据将不调用下发
      if (this.isSended || !this.isReady || !this.entity) return;
      this.isSended = true; // 标记为已下发
      clearTimeout(_timer); // 清理超时提醒
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.viewTarget.postMessage({ type: "data", content: this.entity }, "*");
    },
    addListener(e) {
      switch (e.data.type) {
        case "ready":
          this.isReady = true;
          this.sendData();
          break;
        case "lookMorePage":
          const data = e.data.content.content
          setLocalStorage('tableData', data)
          this.swRouter("/matchDetail");
          break;
        case "lookRankPage":
          const params = e.data.content.content
          console.log('params', params)
          setLocalStorage('tableData', params)
          this.swRouter("/rank/page");
          break;
        case "hotChart": {
          const result = e.data.content;
          switch (result.type) {
            case "switchBackBtn":
              // 隐藏、显示返回按钮
              this.showBack = result.input;
              break;
          }
          break;
        }
        case "JumpCard":
          if (window.isClient) {
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              id: "10092",
              url: "SKU_FILL_INFO_PAGE",
            });
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
      }
    },
    collectCommunity() {
      if (!this.entity) return

      if (this.entity.isCollect) {
        this.HTTP.cancelCollect({
          type: 4,
          refId: this.id,
        }).then(res => {
          if (res) {
            this.entity.isCollect = false
          }
        }).catch(() => {
          this.$toast("网络不稳定，请稍后重试！");
        })
      } else {
        this.HTTP.communityCollect({
          type: 4,
          refId: this.id
        }).then(res => {
          if (res) {
            this.entity.isCollect = true
          }
        }).catch(() => {
          this.$toast("网络不稳定，请稍后重试！");
        })
      }
    }
  },
  mounted() {
    // 获取报告ID
    const id = this.$route.params.id;
    this.id = id
    if (!id) {
      this.$toast("无效报告信息，即将返回首页！");
      this.$router.push("/");
      return;
    }

    // 获取报告地址
    this.host = this.HTTP.getReportUrl();
    const entityHandler = (res) => {
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      console.log("entity:", res);
      // 数据提取
      // 保存修改后的数据实体
      if (res) {
        res.config = {
          templateId: 'cellScreen'
        }
        if (res.communityName) {
          this.title = res.communityName + '小区详情'
        }
      }
      this.entity = res;
      // 设置超时并下发数据
      _timer = setTimeout(() => {
        this.$toast("无法获取报告，请稍候重试！");
        this.loading = false;
      }, 30000);
      this.sendData();
    };

    // 加载网络数据
    this.HTTP.communityDetail(id)
      .then((res) => {
        // const brandInfo = await this.HTTP.communityBrandDetail(id)
        // res.brandLocation = brandInfo.brandLocation
        entityHandler(res);
      })
      .catch((e) => {
        this.loading = false
        this.$toast("网络不稳定，请稍候重试！");
      });
    // 添加通信监听
    window.addEventListener("message", this.addListener);
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 设置客户端类型
    this.isClient = window.isClient || true;
  },
  beforeDestroy() {
  },
  destroyed() {
    // 移除通信监听
    window.removeEventListener("message", this.addListener);
    // 移除定时监听
    clearTimeout(_timer);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.charts {
  overflow: hidden;
  height: 100vh;
  ::v-deep .titleHeader {
    .view {
      &--r {
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: .1333vw solid #E7E7E7;
        box-sizing: border-box;
      }

      .subTitle {
        position: absolute;
        bottom: 2.5vw;
        left: 50%;
        color: #9aa9be;
        transform: translateX(-50%);
        font-weight: normal;
      }
    }
  }
  #{&}--view {
    overflow-y: hidden;
    .innerView {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15.5vw;
    height: 100%;
    position: absolute;
    right: 0;
  }
  .store-icon {
    width: 4.27vw;
    height: 3.733vw;
    background: url(/static/cellScreen/store.png) no-repeat left top;
    background-size: cover;
  }
  .un-store-icon {
    width: 4.27vw;
    height: 3.733vw;
    background: url(/static/cellScreen/un-store.png) no-repeat left top;
    background-size: cover;
  }
}
</style>
