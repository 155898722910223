<template>
	<div>
		<StateBar />
		<TitleHeader title="支付成功" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
		</TitleHeader>
		<AutoView :footer="false">
			<div class="pay-success">
				<img src="/static/shopEcology/paySuccessful.png" alt="">
				<div class="title">支付成功</div>
				<div class="small-title">已解锁「{{addressName}}」，<br>可以到「我的-我解锁的」查看</div>
				<div class="button" @click="look">立即查看</div>
			</div>
		</AutoView>
	</div>
</template>

<script>
	export default {
		data() {
			return {
                addressName: ''
			}
		},
		mounted() {
            this.addressName = this.$route.query.addressName
        },
		components: {

		},
		methods: {
			look(){
				this.JSBridge.BTNCLICK(this.$route.meta.id, this.$route.query.id, 155000, 155001);
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CHANGE_MAIN_TAB",
                    id: "me",
                    params: null,
                    trackData: null,
                    isClear: true,
                });
                this.swRouter({
                    path: '/shopEcology/shopDetails',
                    query: {
                        id: this.$route.query.id
                    }
                })
			}
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.pay-success {
		padding-top: vw(112);
		text-align: center;

		img {
			width: vw(174);
			height: vw(174);
		}

		.title {
			margin-top: vw(40);
			height: vw(42);
			font-size: vw(30);
			font-weight: 600;
			color: #333333;
			line-height: vw(42);
		}

		.small-title {
			width: vw(650);
			font-size: vw(24);
			color: #999999;
			line-height: vw(40);
			margin: vw(6) auto 0;
		}

		.button {
			width: vw(420);
			text-align: center;
			height: vw(80);
			line-height: vw(80);
			background: #347FFF;
			border-radius: vw(8);
			font-size: vw(30);
			font-weight: 600;
			color: #FFFFFF;
			margin: vw(68) auto 0;
		}

	}
</style>
