<template>
    <div class="billHistory">
        <StateBar></StateBar>
        <TitleHeader title="开票记录" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="billHistory--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="messages"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <div v-for="( data, key ) in showList" :key="key">
                            <h3 class="s-subtitle">{{ key }}</h3>
                            <div class="billHistory--panel l-panel">
                                <div v-for="( item, index ) of data" :key="index" class="item" :class="{ noSpace: index === data.length - 1 }" @click="onSelected( item )">
                                    <h3 class="f-tof">{{ item.content }}</h3>
                                    <p class="f-tof">{{ item.gmtCreate | convDate }}</p>
                                    <span class="f-tof">￥{{ item.totalAmount }}</span>
                                    <p class="f-tof status">{{ item.status | toStatusStr }}</p>
                                    <i :class="'st-' + item.type"></i>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
export default {
    name: 'billHistory',
    data () {
        return {
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            page        : 1,
            total       : 0,
            list        : [],
            showList    : {},
        };
    },
    filters: {
        convDate ( value ) {
            const sp1 = value.split( ' ' );
            return sp1[ 0 ].split( '-' ).join( '.' ) + ' ' + sp1[ 1 ].substr( 0, 5 );
        },
        toStatusStr ( value ) {
            const stMap = {
                1: '普票-开票中',
                2: '普票-已出票',
                3: '普票-开票中',
                4: '专票-开票中',
                5: '专票-已出票',
                6: '专票-已邮寄',
            };
            return stMap[ value ];
        },
    },
    methods: {
        loadData () {
            this.loading  = true;
            this.HTTP.invoiceList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
                // 数据分组
                for ( const item of res.data ) {
                    const yms = item.gmtCreate.split( '-' );
                    const key = `${yms[0]}年${yms[1]}月`;
                    if ( ! this.showList.hasOwnProperty( key ) ) {
                        this.showList[ key ] = [];
                    }
                    this.showList[ key ].push( item );
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
    },
    created () {
        if ( this.$route.query.home ) {
            this.JSBridge.callHandler( 'clearHistory', 'my' );
        }
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.billHistory {
    height: 100vh;
    background-color: $c-pageBg;

    #{&}--view {
        .s-subtitle {
            color: $c-info;
            font-size: $font_347;
        }
        .l-panel {
            padding: 5.33vw;
        }
    }

    #{&}--panel {
        margin-bottom: $padding2;

        .item {
            position: relative;
            padding-bottom: 4.53vw;
            margin-bottom: 5.2vw;
            box-shadow: inset 0 -1px $c-line;

            i {
                position: absolute;
                top: 0;
                right: 0;
                width: 10.67vw;
                height: 10.67vw;
                background-size: 100%;
                background-position: top right;
                background-repeat: no-repeat;
                margin: -5.33vw -5.33vw 0 0;

                &.st-1 {
                    background-image: url('/static/icon/invoice_1.png');
                }
                &.st-2 {
                    background-image: url('/static/icon/invoice_2.png');
                }
            }

            &.noSpace {
                padding-bottom: 0;
                margin-bottom: 0;
                box-shadow: none;
            }

            h3, span {
                font-size: $font_400;
                line-height: $font_560;
            }
            p, .status {
                color: $c-info;
                font-size: $font_293;
                line-height: $font_400;
                margin-top: 1.6vw;
            }
            span {
                position: absolute;
                top: 0;
                right: 0;
                font-weight: bold;
            }
            .status {
                position: absolute;
                top: 7.2vw;
                right: 0;
                margin-top: 0;
            }
        }
    }
}
</style>
