<template>
  <div class="page">
    <StateBar bgcolor="#fff" />
    <TitleHeader :title="'开店选址'" bgcolor="#fff" color="black" :back="true">
      <img
        slot="right"
        class="title-icon service"
        src="static/shopEcology/icon-service.png"
        @click="toService"
        v-log="{ mid:3300100,bid:3300101 }"
        alt=""
      >
    </TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="false"
    >
      <div class="header">
        上上参谋通过商业大数据，多维度评估选址点位，助您科学开店
      </div>
      <div class="list-module">
        <p class="module-title-blue">还没有意向选址，为您推荐</p>
        <div
          :class="recommend.skin"
          v-log="recommend.cardLog"
          @click.stop.stop="toDetails(recommend.detailsType, recommend.typeId)"
        >
          <p class="card-title">{{recommend.cardTitle}}</p>
          <p class="bubble">{{recommend.bubble}}</p>
          <p class="card-desc">{{recommend.cardDesc}}</p>
          <div class="cell module-footer">
            <div class="cell-bd price">
              <span>¥<ins>{{recommend.price}}</ins>起/次</span>
            </div>
            <div class="cell-ft">
              <van-button
                class='report'
                v-log="recommend.reportLog"
                @click.stop="example(recommend.reportId)"
              >示例报告</van-button>
              <van-button
                class="buy"
                v-log="recommend.toUseLog"
                @click.stop="toUse(recommend.useType, recommend.typeId)">立即评估</van-button>
            </div>
          </div>
          <img class="icon" :src="recommend.iconPath"/>
        </div>

        <p class="module-title-orange">已有意向选址，为您评估</p>
        <div
          v-for="(card, index) in assessment"
          :class="card.skin"
          v-log="card.cardLog"
          :key="index"
          @click.stop="toDetails(card.detailsType, card.typeId)"
        >
          <p class="card-title">{{card.cardTitle}}</p>
          <p class="bubble">{{card.bubble}}</p>
          <p class="card-desc">{{card.cardDesc}}</p>
          <div class="cell module-footer">
            <div class="cell-bd price">
              <span>¥<ins>{{card.price}}</ins>起/次</span>
            </div>
            <div class="cell-ft">
              <van-button
                class='report'
                v-log="card.reportLog"
                @click.stop="example(card.reportId)"
              >示例报告</van-button>
              <van-button
                class="buy"
                v-log="card.toUseLog"
                @click.stop="toUse(card.useType, card.typeId)">立即评估</van-button>
            </div>
          </div>
          <img class="icon" :src="card.iconPath"/>
        </div>

      </div>
      <div class="aq" v-if="!isCloseComment">
        <div class="cell aq-title">
          <div class="cell-hd">有问题直接问 (30)</div>
          <div class="cell-ft more" @click="more"  v-log="allQuestionLog">查看全部</div>
        </div>
        <div class="aq-list" v-for="(aq, index) in aqList" :key="index">
          <div class="aq-item">
            <p class="a-title">{{aq.nickname}}</p>
            <p class="a-content">{{aq.question}}</p>
            <p class="q-content">{{aq.answer}}</p>
          </div>
        </div>
      </div>
      <div class="footer" v-if="!isCloseComment">
        <van-button @click="showInputMask" v-log="doQuestionLog">我要问</van-button>
      </div>
    </AutoView>


    <van-popup v-model:show="showInput" position="bottom">
      <div class="input-box">
        <p>我要问</p>
        <textarea rows="8" maxlength="200" v-model="question" placeholder="请你描述你对产品的疑问（200字以内）"/>
        <van-button @click="addQuestion">立即提交</van-button>
      </div>
    </van-popup>

  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      isCloseComment: false,
      showInput: false,
      question: '',
      serverLog: {},
      recommend: {
        skin: 'card',
        cardTitle: '开店位置推荐',
        bubble: '找不到好位置？AI大数据为你推荐',
        cardDesc: '根据你关心的要素推荐',
        price: '76',
        reportId: '137119887968002',
        iconPath: 'static/img/locating/marker.png',
        detailsType: 'BRAND_INTRODUCTION_V3',
        useType: 'SKU_FILL_INFO_PAGE', //'BRAND_INTRODUCTION_V3',
        typeId: 10026,
        cardLog: { mid:3300200,bid:3300201 },
        reportLog: { mid:3300200,bid:3300202 },
        toUseLog: { mid:3300200,bid:3300203 },
      },
      assessment: [
        {
          skin: 'card-orange',
          cardTitle: '开店选址评估',
          bubble: '知名品牌多年选址模型，AI数据智能评估',
          cardDesc: '位置好不好一测便知道',
          price: '53',
          reportId: '127568427276126',
          iconPath: 'static/img/locating/yes.png',
          detailsType: 'BRAND_INTRODUCTION_V3',
          useType: 'SKU_FILL_INFO_PAGE',
          typeId: 10001,
          cardLog: { mid:3300300,bid:3300301 },
          reportLog: { mid:3300300,bid:3300302 },
          toUseLog: { mid:3300300,bid:3300303 },
        },
        {
          skin: 'card-orange',
          cardTitle: '社区选址评估',
          bubble: '专注社区店选址，搞定15分钟便民生意',
          cardDesc: '家门口的好生意',
          price: '49',
          reportId: '250356081951549',
          iconPath: 'static/img/locating/shop.png',
          detailsType: 'BRAND_INTRODUCTION_V3',
          useType: 'SKU_FILL_INFO_PAGE',
          typeId: 10092,
          cardLog: { mid:3300400,bid:3300401 },
          reportLog: { mid:3300400,bid:3300402 },
          toUseLog: { mid:3300400,bid:3300403 },
        },
        {
          skin: 'card-orange',
          cardTitle: '外卖店选址评估',
          bubble: '数据+算法外卖点位评估，降低选址风险',
          cardDesc: '专注3公里外卖选址',
          price: '28',
          reportId: '231618758452001',
          iconPath: 'static/img/locating/electric.png',
          detailsType: 'BRAND_INTRODUCTION_V3',
          useType: 'SKU_FILL_INFO_PAGE',
          typeId: 10084,
          cardLog: { mid:3300500,bid:3300501 },
          reportLog: { mid:3300500,bid:3300502 },
          toUseLog: { mid:3300500,bid:3300503 },
        }
      ],
      aqList: [
        {
          nickname: '主公AMCR',
          question: '我想在广州大学城开一家寿司店，只做外卖。可以帮我看看外卖好不好卖吗？',
          answer: '你好，可以的！外卖店选址评估报告中的品类热度，包含周边外卖商家经营的品类数量、销量和单品热度，帮你判断寿司是否畅销。更多问题可咨询在线客服，祝您开店成功！',
        },
        {
          nickname: '主公AKIF',
          question: '你好，呼和浩特市那个位置适合开面馆？',
          answer: '您好，可在本产品（【开店位置推荐】）的开店信息处选择“去填写”，【行政区范围】中选择内蒙古自治区-呼和浩特市-具体行政区，【意向行业】选择餐饮-小吃快餐-粥/粉/面，其余需求按照您的实际选址偏好填写，有特殊要求在备注中填写说明即可~如有疑问，可随时咨询客服，祝您开店成功！',
        },
        {
          nickname: '主公CMOS',
          question: '河北邢台市高开区哪里适合开餐饮',
          answer: '您好，可在本产品（【开店位置推荐】）的开店信息处选择“去填写”，【行政区范围】中选择河北省-邢台市-高开区，【意向行业】选择餐饮-具体餐饮品类，其余需求按照您的实际选址偏好填写，有特殊要求在备注中填写说明即可~如有疑问，可随时咨询客服，祝您开店成功！',
        },
        {
          nickname: '主公FIEJ',
          question: '我想在邯郸市里开一个粥摊位',
          answer: '您好，开店信息中，选择行政区范围为“河北省-邯郸市-具体行政区”，意向行业为“餐饮-小吃快餐-粥/粉/面”，其他条件按照您实际需求填写即可。如有其他要求，请在备注中说明，以获得更精准的推荐哦！祝您开店成功！',
        },
        {
          nickname: '主公JFEIW',
          question: '你好！江西省上饶市婺源县城区哪个位置最适合开卤肉店？',
          answer: '您好，开店信息中，选择行政区范围为“江西省-上饶市-婺源县”，意向行业为“餐饮-小吃快餐-卤味”，其他条件按照您实际需求填写即可。如限定范围为城区，请在备注中说明，以获得更精准的推荐哦！祝您开店成功！',
        },
        {
          nickname: '主公ALIC',
          question: '成都青白江哪儿适合开女装店',
          answer: '您好，行政区范围选择“四川省-成都市-青江白区”，意向行业选择“零售-服饰鞋包-综合服饰”，目标客群选择“女性”，其他条件按照您个人需求选择即可。另外您可在备注中特别标明是“女装店”，以得到更精准的推荐！',
        },
        {
          nickname: '主公YEIF',
          question: '我想在浙江省宁波市慈溪市开个甜品店。',
          answer: '您好！您选择的行业和城市均在我们的服务范围内，点击下方【立即评估】，选择您要评估的位置，【行业】选择【餐饮】-【甜品饮品】-【具体品类】即可，有其他具体要求可备注说明！祝您开店成功！',
        },
      ],
      allQuestionLog: { mid:3300600,bid:3300601 },
      doQuestionLog: { mid:3300600,bid:3300602 },
    }
  },
  mounted () {
    this.isCloseComment = window.isCloseComment
  },
  methods: {
    more(){
      if (window._appCode >= 326) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: 'QA_HOME',
          params: "{\"index\":1}",
        });
      } else {
        this.swRouter( '/queAsk/10026');
      }
    },
    showInputMask(){
      if (window._appCode >= 326) {
        this.swRouter( '/h5/#/chat/ask');
      } else {
        this.showInput = true;
      }
    },
    example(id){
      // this.swRouter( '/charts/' + id );
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 4,
        id: id,
        params: null,
        isClear: false,
      });
    },
    toDetails(url, id){
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: url,
        id: id,
        params: null,
        isClear: false,
      });
    },
    toUse(url, id){
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "SKU_FILL_INFO_PAGE",
        id: id,
        params: null,
        isClear: false,
      });
      // this.JSBridge.callHandler("onNormalizingCall", {
      //   type: 5,
      //   url: url,
      //   id: id,
      //   isClear: null,
      // });
    },
    addQuestion(){
      if (!this.question) {
        this.$toast('请你描述你对产品的疑问');
        return;
      }
      this.HTTP.addQuestion({
        goodsId: '10026',
        question: this.question,
      }).then(res => {
        this.$toast('提交成功');
        this.showInput = false;
      })
    },
    toService(){
      this.JSBridge.BTNCLICK( this.$route.meta.id, this.$route.query.id, '2500100','2500101');
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "ONLINE_SERVICE",
        params: null,
      });
    },
    // sendPvTrack(mid, bid){
    //   console.log(data,'上报埋点')
    //   this.JSBridge.BTNCLICK( this.$route.meta.id, '', mid, bid );
    // },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_cell.scss';
@import '@/styles/px2vw.scss';

*{
  box-sizing: border-box;
}
.service{
  width: vw(44);
}
.card{
  position: relative;
  background: #FFFFFF linear-gradient(180deg, rgba(135, 182, 247, 0.2) 0%, rgba(135, 182, 247, 0.07) 100%);
  border-radius: vw(12);
  border: vw(2) solid #D5E8FE;
  padding: vw(25) vw(30) vw(30) vw(30);
  margin-bottom: vw(30);
  .card-title{
    font-size: vw(32);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: vw(45);
  }
  .bubble{
    background: rgba(52,127,255, 0.1);
    border-radius: vw(5) vw(23) vw(23) vw(23);
    height: vw(46);
    padding: 0 vw(15);
    line-height: vw(46);
    display: inline-block;
    margin-top: vw(15);
    color: #347FFF;
    font-size: vw(22);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #347FFF;
  }
  .card-desc{
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: vw(33);
    margin-top: vw(16);
  }
  .module-footer{
    margin-top: vw(20);
    .price{
      font-size: vw(18);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #347FFF;
      line-height: vw(25);
      margin-top: vw(30);
      ins{
        text-decoration: none;
        font-style: normal;
        font-size: vw(36);
        margin: 0 vw(5);
      }
    }
    .report{
      border: vw(2) solid #D5E8FE;
      color: #347FFF;
      font-size: vw(28);
      font-weight: normal;
      height: vw(70);
    }
    .buy{
      border: none;
      background: linear-gradient(90deg, #58B7FF 0%, #347FFF 100%);
      color: #fff;
      font-size: vw(28);
      font-weight: normal;
      height: vw(70);
    }
  }
  .icon{
    position: absolute;
    width: vw(100);
    top: vw(30);
    right:vw(30);
  }
}
.card-orange{
  @extend .card;
  background: #FFFFFF linear-gradient(180deg, rgba(255, 89, 0, 0.10) 0%, rgba(255, 209, 186, 0.12) 100%);
  border: vw(2) solid #FFDFC9;
  .bubble{
    background: rgba(255, 89, 0, 0.04);
    color: #FF5900;
  }
  .module-footer{
    .price{
      color: #FF5900;
    }
    .report{
      border: vw(2) solid #FFDFC9;
      color: #FF5900;
    }
    .buy{
      border: none;
      background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
      color: #fff;
    }
  }
}

.page-view{
  background: linear-gradient(180deg, #347FFF 0%, rgba(52, 127, 255, 0.47) vw(176));
  background-size: 100% vw(500);
  background-repeat: no-repeat;
}
.header{
  padding: vw(37) vw(44) 0 vw(142);
  height: vw(176);
  background-image: url("/static/locating/logo.png");
  background-repeat: no-repeat;
  background-size:  vw(76) vw(46);
  background-position: vw(44) vw(54);
  font-size: vw(28);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: vw(40);
}
.list-module{
  background: #FFFFFF;
  border-radius: vw(18) vw(18) vw(0) vw(0);
  margin-top: vw(-24);
  padding: vw(30);
  .module-title{
    position: relative;
    margin-top: 35px;
    margin-bottom: 25px;
    font-weight: bold;
    padding-left: vw(22);
    &::before{
      content: ' ';
      position: absolute;
      left: vw(-0);
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: vw(14);
      height: vw(14);
      border-radius: vw(7);
      background: #eee;
      opacity: 0.45;
    }
  }
  .module-title-blue{
    @extend .module-title;
    &::before{
      background: #347FFF;
    }
  }
  .module-title-orange{
    @extend .module-title;
    &::before{
      background: #FF5900;
    }
  }
}

.aq{
  background: #fff;
  padding: vw(30);
  border-bottom: 1px solid #E7E7E7;
  .aq-title{
    color: #333333;
    font-size: vw(30);
    font-weight: bold;
    .more{
      color: #FF5900;
      font-size: vw(24);
      font-weight: 400;
      padding-right: vw(19);
      background: url("/static/img/locating/arrow.png") no-repeat;
      background-size: vw(10) vw(21);
      background-position: right center;
    }
  }
  .aq-list{
    border-radius: vw(14);
    padding: 0 vw(30);
    margin-top: vw(20);
    border: 1px solid #E7E7E7;
    .aq-item{
      padding: vw(30) 0;
      border-bottom: 1px solid #E7E7E7;
      &:last-child{
        border-bottom: none;
      }
      .a-title{
        padding-left: vw(42);
        line-height: vw(34);
        background: url("/static/img/locating/question.png") no-repeat;
        background-size: vw(34) vw(34);
        font-size: vw(24);
        color: #666666;
      }
      .a-content{
        font-size: vw(26);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding-left: vw(42);
        line-height: vw(37);
        margin-top: vw(10);
      }
      .q-content{
        padding-left: vw(42);
        line-height: vw(34);
        background: url("/static/img/locating/answer.png") no-repeat;
        background-size: vw(34) vw(34);
        font-size: vw(24);
        color: #333333;
        margin-top: vw(16);
      }
    }
  }
}

.footer{
  padding: vw(15) vw(30);
  .van-button{
    width: 100%;
    border: none;
    background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
    border-radius: vw(11);
    color: #fff;
  }
}
.input-box{
  padding: 0 vw(30) vw(30) vw(30);
  border-radius: vw(15) vw(15) 0 0;
  p{
    text-align: center;
    font-size: vw(32);
    color: #000;
    line-height: vw(100);
  }
  textarea{
    width: 100%;
    border-radius: vw(5);
    border: 1px solid #ebebeb;
    padding: vw(20);
    font-size: vw(28);
    color: #000;
    -webkit-appearance:none;/*去除阴影边框*/
    outline: none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);/*点击高亮的颜色*/
  }
  .van-button{
    margin-top: vw(20);
    width: 100%;
    border: none;
    background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
    border-radius: vw(11);
    color: #fff;
  }

}
</style>
