import { render, staticRenderFns } from "./settle.vue?vue&type=template&id=ddb5c214&scoped=true"
import script from "./settle.vue?vue&type=script&lang=js"
export * from "./settle.vue?vue&type=script&lang=js"
import style0 from "./settle.vue?vue&type=style&index=0&id=ddb5c214&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddb5c214",
  null
  
)

export default component.exports