<template>
    <div class="stateBar" :class="{ android: isAndroid, ios: isIOS, ipx: isIPX, ipmx: isIPMX }" :style="{ backgroundColor: bgcolor }">
        <!-- Empty -->
    </div>
</template>

<script>
export default {
    name : 'stateBar',
    props: {
        bgcolor: {
            type   : String,
            default: 'transparent',
        },
    },
    data () {
        return {
            isAndroid: false,
            isIOS    : false,
            isIPX    : false,
            isIPMX: false
        };
    },
    beforeMount () {
        this.isAndroid = window.isClient && window.isAndroid;
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
            this.isIPMX = window.isIPMX
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.stateBar {
    // min-height: $iOSStateBar;
    // background-color: red!important;
    &.android {
        height: $androidStateBar;
    }
    &.ios {
        height: $iOSStateBar;
    }
    &.ipx {
        height: $iPXStateBar;
    }
    &.ipmx {
      height: $IPMXStateBar;
    }
}
</style>
