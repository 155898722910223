<template>
    <div class="update">
        <StateBar />
        <TitleHeader title="更新说明" bgcolor="transparent" color="black" style="border-bottom: 1px solid #E7E7E7;box-sizing: border-box;" :back="true"></TitleHeader>
        <AutoView ref="view" :header="true" :footer="false" class="setting--view">
            <div class="update--container">
                <div class="content" v-for="(val, index) in list" :key="index">
                    <h3>{{val.title}}</h3>
                    <div class="name clearfix" v-for="(item, cindex) in val.subList" :key="cindex">
                        <van-icon class="icon_cir" name="circle"  />
                        <p>{{item.content}}</p>
                    </div>
                </div>
                <div class="tips">
                    <p>注意：<br/>商圈数据、客群画像、行业热力、人口热力套餐将下架，剩余套餐的用户将自动升级为会员：系统将根据您的套餐包剩余价值，与会员价格体系作比较，换算成对应价值的会员时长；</p>
                    <p>如有疑问，请联系客服。</p>
                </div>
                <van-button class="btn_blur" @click="openVip">查看权益</van-button>
            </div>
        </AutoView>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [
                {
                    title: "全新会员权益升级",
                    subList: [
                        { content: "会员拥有无限制数据查询权益，包含城市查询、周边查询、热力分析、人口热力、商场查询" }
                    ]
                },
                {
                    title: "全新数据查询能力升级",
                    subList: [
                        { content: "周边查询将融合商圈数据、客群画像，数据更全面" },
                        { content: "新增商场查询，查询商场信息、周边客群及周边配套" },
                        { content: "每日拥有免费查询的机会，开通会员享有无限制查询特权及锁定数据解锁特权" }
                    ]
                },
            ]
        }
    },
    methods: {
        openVip() {
            this.swRouter( '/h5/#/member' );
        }
    }
}
</script>
<style lang="scss" scoped>
    //@import "@/styles/index.scss";
    .update{
        height: 100vh;
        background: #FFFFFF;
        #{&}--container {
            background: url("/static/img/update_icon.png") no-repeat top 0.4vw right;
            background-size: 25.2vw 20.4vw;
            height: 100%;
            padding: 12.67vw 4.8vw;
            box-sizing: border-box;
            .content{
                margin-bottom: 5.33vw;
                h3{
                    font-size: 4.27vw;
                    font-weight: bold;
                    color: #333333;
                    line-height: 5.87vw;
                    margin-bottom: 2.67vw;
                }
                .name{
                    margin-bottom: 2.4vw;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    .icon_cir{
                        float: left;
                        width: 2.13vw;
                        position: relative;
                        top: 1.1vw;
                        left: 0;
                        border-radius: 50%;
                        height: 2.13vw;
                        box-sizing: border-box;
                        font-size: 2.13vw;
                        &::before {
                            color: #347FFF;
                            font-size: 2.13vw;
                            box-sizing: border-box;
                            background: #347FFF;
                            border-radius: 50%;
                        }
                    }
                    p{
                        flex: 1;
                        width: calc(100% - 4.27vw);
                        margin-left: 2.13vw;
                        font-size: 3.73vw;
                        color: #666666;
                        line-height: 5.87vw;
                        float: left;
                    }
                }
                .clearfix:after {
                    content: ".";
                    display: block;
                    clear: both;
                    visibility: hidden;
                    height: 0;
                    font-size:0;
                }
            }
            .tips{
                font-size: 3.47vw;
                color: #999999;
                line-height: 5.07vw;
                p{
                    margin-bottom: 1.6vw;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            .btn_blur{
                height: 10.67vw;
                width: 100%;
                background: #347FFF;
                border-radius: 1.33vw;
                margin-top: 8.4vw;
                color: #fff;
                font-size: 3.73vw;
                font-weight: bold;
                line-height: normal;
            }
        }
    }

</style>
