<template>
  <div class="hotVedio">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>

    <van-tabs v-model="current" swipeable line-width="30" color="#347FFF" @change="tabsChange" v-if="tabsList && tabsList.length">
      <van-tab v-for="item in tabsList" :title="item.name" :key="item.key" />
    </van-tabs>

    <StackView ref="view" class="hotVedio--view l-view">
      <van-skeleton :loading="firstLoading || loading" :animate="false" :row="4" class="s-skeleton s-radius__s">
        <EmptyCard v-if="list.list.length === 0" type="community"></EmptyCard>
        <template v-else>
          <van-list v-model="loading" :finished="list.finished" :immediate-check="false" finished-text="没有更多了" @load="loadMoreData">
            <VideoCard v-for="item in list.list" :videoInfo="item" :key="item.id" />
          </van-list>
        </template>
      </van-skeleton>
    </StackView>
  </div>
</template>
<script>
import VideoCard from "./components/video-card.vue";

export default {
  components: { VideoCard },
  data() {
    return {
      loading: true,
      firstLoading: true,
      title: "内容视频",
      id: "",
      current: 0,
      tabsList: [],
      list: {
        page: 1,
        total: 0,
        finished: false,
        list: [],
      },
    };
  },
  created() {
    this.id = this.$route.query.id || null;
    this.loadData();
  },
  methods: {
    tabsChange(name, title) {
      if (this.loading) {
        this.$toast("数据加载中，请勿过快切换！");
        return;
      }

      let tabs = this.tabsList.filter((item) => {
        return item.name == title;
      });
      this.list.page = 1;
      this.list.total = 0;
      this.list.finished = false;
      this.list.list = [];
      this.id = tabs[0].id;
      this.loadMoreData();
    },
    loadData() {
      this.HTTP.getVideoContent({ id: this.id })
        .then((res) => {
          this.firstLoading = false;
          this.loading = false;
          this.tabsList = res.data.categoryList || [];
          this.list.list = res.data.resourceList || [];
          this.list.finished = true; // 暂时不做分页
          setTimeout(() => {
            let idx = this.tabsList.findIndex((item) => {
              return item.id == this.id;
            });
            if (idx != -1) {
              this.current = idx;
            }
          }, 50);
          console.log(res);
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
          this.firstLoading = false;
        });
    },
    loadMoreData() {
      this.loading = true;
      this.HTTP.getCategoryResource(this.id)
        .then((res) => {
          this.loading = false;
          this.list.page++;
          this.list.total = res.total;
          if (this.list.list.length) {
            this.list.list = this.list.list.concat(res.data);
          } else {
            this.list.list = res.data;
          }
          this.list.finished = true;
          console.log("res:", res);
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.hotVedio {
  height: 100vh;
  overflow: hidden;

  #{&}--view {
    scroll-behavior: smooth;
    background: #f7f7f7;
    padding-bottom: 35vw;
  }
}

::v-deep .van-tab--active {
  font-size: 15px;
  color: #323233;
  font-weight: 600;
}
</style>
