<template>
    <div class="partner">
        <StateBar />
        <TitleHeader title="合伙人规则" bgcolor="transparent" color="black" :back="true" />

        <AutoView :header="true" :footer="false" class="partner--view">
            <p class="txt">
                <span><i>1.</i>什么是合伙人？</span>
                <span>
                    合伙人可邀请好友注册{{ appName }}APP，当好友购买相应的商品服务后，合伙人可以获得对应的商品佣金。佣金在完成结算后，合伙人可以提现到支付宝。
                </span>
            </p>
            <p class="txt">
                <span><i>2.</i>如何推广赚钱？</span>
                <span>
                    合伙人通过邀请好友注册、并成功推荐好友购买商品后，会按照一定的计算方法算作你的一级佣金；当好友成功推荐他人购买商品后，按照一定计算标准产生二级佣金。
                </span>
            </p>
            <p class="txt">
                <span><i>3.</i>如何佣金提现？</span>
                <span>
                    佣金结算后，进入合伙人中心进行提现操作。
                    <br>
                    每笔提现需扣除6%的相关税费，上上参谋将直接为您扣缴
                </span>
            </p>
            <p class="txt">
                <span><i>4.</i>如何邀请好友？</span>
                <span>
                    在合伙人中心，点击分享链接，将链接转发或生成专属海报分享给好友，好友不仅可购买商品，还可申请成为你的下级合伙人。
                </span>
            </p>
            <p class="txt">
                <span><i>5.</i>如何计算佣金收益？</span>
                <span>
                    有效合伙人购买商品成功付款后，将根据订单中每个商品的提成比例计算提成，最后累加计算整体提成收益。
                </span>
            </p>
            <p class="txt">
                <span><i>6.</i>按照规则分享了，还是无佣金？</span>
                <span>
                    好友可能并未与你建立合伙人关系（这个好友已经是合伙人了），无法计算佣金。
                </span>
            </p>
            <p class="txt">
                <span><i>7.</i>服务佣金比例示例图</span>
            </p>

            <p v-if="list && list.length" class="txt-box">
                <van-grid :gutter="2" :column-num="3" >
                    <van-grid-item class="bg1 width1" text="服务名称" />
                    <van-grid-item class="bg2 width2" text="一级佣金比例" />
                    <van-grid-item class="bg1 width2" text="二级佣金比例" />

                    <template v-for="( item, index ) of list">
                        <van-grid-item class="bg3 width1" :key="'A'+index" :text="item.name" />
                        <van-grid-item class="bg4 width2" :key="'B'+index" :text="item.commissionPercent + '%'" />
                        <van-grid-item class="bg3 width2" :key="'C'+index" :text="item.commissionPercentTwo==null?0:item.commissionPercentTwo+'%'" />
                    </template>
                </van-grid>
            </p>
        </AutoView>
    </div>
</template>

<script>
export default {
    name: 'partner',
    data () {
        return {
            submitting: false,
            bgcolor   : 'transparent',
            list      : [],
            appName   : this.JSBridge.appName(),
        };
    },
    methods: {
        onTap () {
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.registerPantner().then( () => {
                this.$toast( '恭喜您成为' + this.JSBridge.appName() + '合伙人！' );
                setTimeout( () => {
                    this.$router.replace( '/partner' );
                }, 2000 );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg || '未知错误' );
                    if ( e.code === 189020 ) {
                        setTimeout( () => {
                            this.$router.replace( '/partner' );
                        }, 2000 );
                    }
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
    },
    created () {
        const res   = this.$store.getters.partnerInfo;
        if ( res == null ) {
            return;
        }
        const list = res.goodsList;
        this.list = [];
        for ( let i = 0; i < list.length; ++i ) {
            const item = list[i];
            this.list.push({
                name: item.goodsName,
                commissionPercent: item.commissionPercent ? item.commissionPercent : 0,
                commissionPercentTwo: item.commissionPercentTwo ? item.commissionPercentTwo : 0
            });
        }
    },
    beforeMount () {
        // this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.partner {
    height: 100vh;

    #{&}--view {
        padding-bottom: $padding;
    }
    #{&}--list {
        padding: $margin $padding $padding * 4;
        .table {
            width: 100%;
            text-align: center;
            @include font( #666666, $font_320, normal );
            line-height: 8vw;
            border-top: 1px solid #C1DAFC;
            border-left: 1px solid #C1DAFC;
            border-collapse: collapse;

            td {
                border-right: 1px solid #C1DAFC;
                border-bottom: 1px solid #C1DAFC;
            }
            tr:nth-child(odd) {
                td {
                    background-color: #F6FAFF;
                }
            }

            .title {
                td {
                    background-color: #D7E8FF!important;
                    font-weight: bold;
                    color: $c-main;
                }
            }
        }
    }
    .txt{
        padding: 5vw 7vw 0;
        span{
            display: block;
        }
        :nth-child(1){
            font-size: 4vw;
            font-weight: 500;
            color: #1D2233;
            i{
                font-style: normal;
                color:#4B8EEA;
            }
        }
        :nth-child(2){
            margin: 2vw auto 5vw;
            font-size: 3vw;
            color: #8E9099;
            line-height: 6vw;
        }
    }
    .txt-box{
        margin-top: 4vw;
        padding: 0 3vw;
    }
    ::v-deep .van-grid-item__content{
        border: none;
    }
    ::v-deep .van-grid-item__content--surround::after{
        border: none;
    }
    ::v-deep .van-grid-item__content::after{
        border: none;
    }
    ::v-deep .van-grid-item:first-child .van-grid-item__content{
        border-radius: 3vw 0 0 0;
    }
    ::v-deep .van-grid-item:nth-child(3) .van-grid-item__content{
        border-radius: 0 3vw 0 0;
    }
    ::v-deep .van-grid-item:nth-last-child(3) .van-grid-item__content{
        border-radius: 0 0 0 3vw;
    }
    ::v-deep .van-grid-item:last-child .van-grid-item__content{
        border-radius: 0 0 3vw 0;
    }
    ::v-deep.van-grid-item__text{
        font-size: 3vw;
    }
    .bg1{
        ::v-deep .van-grid-item__content{
            background: #EFEDF1;
            .van-grid-item__text{
                color: #1D2233;
            }
        }
    }
    .bg2{
        ::v-deep .van-grid-item__content{
            background: #F9E8DE;
            .van-grid-item__text{
                color: #C85D2D;
            }
        }
    }
    .bg3{
        ::v-deep .van-grid-item__content{
            background: #F7F6F9;
            .van-grid-item__text{
                color: #1D2233;
            }
        }
    }
    .bg4{
        ::v-deep .van-grid-item__content{
            background: #FBF2ED;
            .van-grid-item__text{
                color: #F58958;
            }
        }
    }
    .width1 {
        flex-basis: 38%!important;
    }
    .width2 {
        flex-basis: 30%!important;
    }
}
</style>
