<template>
    <div class="wrapper">
        <StateBar/>
        <TitleHeader title="点位雷达" color="black" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView ref="view" :footer="false" class="card-view">
            <van-skeleton :loading="!list || list.firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
                <div class="empty" v-if="!list || list.list.length === 0">
                    <img src="/static/img/state_img_nothing.png" alt="">
                    <span>暂无点位雷达</span>
                    <div class="btn" @click="jumpDetail">了解详情</div>
                </div>
                <template v-else>
                    <van-list v-model="loading" :finished="list.finished" finished-text="我是有底线的" @load="loadData">
                        <div class="card_wrapper" v-for="item of list.list" :key="item.key">
                            <div class="card" @click="checkReport(item)">
                                <div class="time">{{item.gmtCreate}}</div>
                                <div class="title">{{item.name}}</div>
                                <div class="content" v-if="item.prefer && item.avoid">
                                    <div class="perfer"><span>{{item.prefer.name}}：</span>{{item.prefer.value || '无'}}</div>
                                    <div class="avoid"><span>{{item.avoid.name}}：</span>{{item.avoid.value || '无'}}</div>
                                </div>
                                <div class="bottom">
                                    <div class="status">
                                        <img class="icon" :src="statusMap[item.displayStatus].icon" alt="">
                                        <div class="status_title" :class="statusMap[item.displayStatus].class">{{statusMap[item.displayStatus].content}}</div>
                                    </div>
                                    <div class="desc" v-if="item.displayStatus === 1">预计24小时内推送</div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
const statusMap = {
    1: { icon: 'static/pointRadar/order_icon_wait.png', content: '雷达扫描中', class: 'waiting' },
    2: { icon: 'static/pointRadar/order_icon_OK.png', content: '已扫描完成', class: 'complate' },
    3: { icon: 'static/pointRadar/order_icon_OK.png', content: '已扫描完成', class: 'complate' },
    4: { icon: 'static/pointRadar/order_icon_no.png', content: '无法找到符合您所选条件的点位，已返还套餐次数；', class: 'noPoint' },
}
const BUSINESS_TYPE = 35 // 点位雷达业务类型
import { formatDate } from "@/utils/filters";
export default {
    data() {
        return {
            statusMap,
            loading: false,
            radarList: [],
            list: {
                list: [],
                recent: null,
                remote: null,
                finished: false,
                firstLoading: true,
            },
            businessType: BUSINESS_TYPE,
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.loading = true;
            this.HTTP.reportList(this.list.remote, "before", this.businessType).then((res) => {
                console.log("reports:", res);
                this.loading = false;
                this.list.firstLoading = false;
                if (res.list.length) {
                    this.list.list = this.list.list.concat(res.list);
                    this.list.finished = !res.more;
                } else {
                    this.list.finished = true
                }
                this.list.list.forEach(item => {
                    if(item.inputList) {
                        item.inputList.map(items => {
                            if(items.name === '偏好') {
                                item.prefer = items
                            }
                        })
                        item.inputList.forEach(items => {
                            if(items.name === '避开') {
                                item.avoid = items
                            }
                        })
                    }
                })
                if (this.list.list.length) {
                    this.list.recent = this.list.list[0].gmtCreate;
                    let remote = this.list.list[this.list.list.length - 1].gmtCreate;
                    // 重复报告id问题修复
                    remote = remote.replace(/-/g, '/');
                    const remoteDate = new Date(remote);
                    const nextRemoteDate = new Date(remoteDate.getTime() - 1000);
                    this.list.remote = formatDate(nextRemoteDate);
                }
            }).catch((e) => {
                this.$toast("网络连接不稳定，请稍候重试！");
                this.loading = false;
                this.list.finished = true;
            });
        },
        jumpDetail() {
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "POINT_RADAR",
                id: null,
                params: null,
                trackData: null,
                isClear: true,
            });
        },
        checkReport(item) {
            if(item.displayStatus == 2 || item.displayStatus == 3) {
                this.swRouter(`pointRadar/report/${item.id}`)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import "@/styles/index.scss";
.card-view {
    background-color: #F7F6F9;
    padding: vw(30);
    .card_wrapper {
        .card {
            padding: vw(30);
            background-color: #fff;
            border-radius: vw(10);
            margin-bottom: vw(24);
            .time {
                color: #8E9099;
                font-size: vw(22);
                line-height: vw(30);
            }
            .title {
                font-weight: bold;
                color: #1D2233;
                font-size: vw(30);
                line-height: vw(42);
                margin: vw(16) 0;
            }
            .content {
                padding: vw(30);
                background-color: #F8F8F9;
                border-radius: vw(10);
                div {
                    line-height: vw(30);
                    color: #8E9099;
                    font-size: vw(22);
                    span {
                        color: #1D2233;
                    }
                }
            }
            .bottom {
                display: flex;
                justify-content: space-between;
                margin-top: vw(30);
                .status {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    .icon {
                        width: vw(26);
                        height: vw(26);
                    }
                    .status_title {
                        font-size: vw(24);
                        line-height: vw(33);
                        font-weight: bold;
                        margin-left: vw(5);
                    }
                    .waiting {
                        color: #F58958;
                    }
                    .complate {
                        color: #4B8EEA;
                    }
                    .noPoint {
                        color: #8E9099;
                    }
                }
                .desc {
                    color: #8E9099;
                    line-height: vw(30);
                    font-size: vw(22);
                }
            }
        }
    }
    .empty {
        // width: 94.6vw;
        height: 75vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $font_373;
        font-weight: bold;
        color: $c-text;
        img {
            width: 23.2vw;
            height: 23.2vw;
            margin-top: 8.667vw;
        }
        span {
            margin: 5.733vw 0 9.33vw;
        }
        .btn {
            width: 26.66vw;
            height: 10vw;
            background-color: $c-main;
            color: #fff;
            font-weight: normal;
            font-size: $font_320;
            border-radius: $radius-xs;
            text-align: center;
            line-height: 10vw;
        }
    }
}
</style>