<template>
  <div class="brand-vertical-box" @click="goDetail">
    <div class="brand-rule-tag" v-if="brandData.rulesTag1">
      <p class="tag-content">
        <img class="rule-icon" src="/static/brandAlliance/brand-rule-icon.png" />
        <span>{{ brandData.rulesTag1 }}</span>
      </p>
    </div>
    <div class="brand-cover-box">
      <van-image class="brand-cover" fit="cover" :src="brandData.brandFrameImage || brandData.logoUrl" lazy-load />
      <img class="play-icon" v-if="brandData.brandTopVideo" src="/static/brandAlliance/play-icon.png" alt="" />
    </div>
    <div class="brand-content">
      <div class="brand-middle-box">
        <van-image class="brand-logo" fit="cover" :src="brandData.logoUrl" v-if="brandData.logoUrl" />
        <span class="brand-name">{{ brandData.brandName }}</span>
      </div>
      <div class="brand-tags" v-if="brandTags.length">
        <span class="brand-tag" v-for="(tag, i) in brandTags" :key="i">{{ tag }}</span>
      </div>
      <div class="brand-price-range" v-if="brandData.minCost || brandData.maxCost">
        ¥
        <span v-if="brandData.minCost">{{ brandData.minCost }}</span>
        <span v-if="brandData.minCost">-</span>
        <span v-if="brandData.maxCost">{{ brandData.maxCost }}</span>
        万起
      </div>
      <div class="brand-price-range" v-else>-万起</div>
    </div>

  </div>
</template>

<script>
import Player from "xgplayer";
export default {
  name: 'brand-vertical-box',
  props: {
    brandData: null
  },
  data() {
    return {}
  },
  computed: {
    brandTags() {
      if (!this.brandData) return []
      let arr1 = []
      if (this.brandData.rulesTag2) {
        arr1 = this.brandData.rulesTag2.split(',')
      }
      return arr1
    }
  },
  methods: {
    goDetail() {
      this.swRouter('/h5/#/brand/detail/' + this.brandData.brandCode)
    }
  }
};
</script>

<style lang="scss" scoped>
.brand-vertical-box {
  border-radius: vw(10);
  background: #ffffff;
  overflow: hidden;
  break-inside: avoid;
  margin-bottom: vw(20);
  position: relative;
  .f1 {
    font-weight: bold;
    vertical-align: middle;
  }
  .brand-rule-tag {
    position: absolute;
    top: vw(12);
    left: vw(25);
    border-radius: 0 vw(4) vw(4) 0;
    color: #A33F00;
    font-size: vw(20);
    z-index: 1;
    .tag-content {
      background: linear-gradient(270deg, #FFEE99 0%, #FFD670 100%);
      display: flex;
      align-items: center;
      padding-right: vw(10);
      height: vw(34);
      line-height: vw(34);
      border-radius: 0 vw(4) vw(4) 0;
      .rule-icon {
        width: vw(40);
        height: vw(40);
        margin-left: vw(-20);
        margin-right: vw(4);
      }
    }
  }
  .brand-cover-box{
    height: vw(345);
    border-radius: vw(10) vw(10) 0 0;
    position: relative;
    .brand-cover {
      width: 100%;
      height: 100%;
    }
    .play-icon {
      position: absolute;
      width: vw(80);
      height: vw(80);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .brand-content {
    padding: vw(14) vw(15) vw(20) vw(15);
    .brand-tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: vw(14);
      .brand-tag {
        background: rgba(255,128,71,0.1000);
        border-radius: vw(4);
        color: #FF8047;
        font-size: 10px;
        line-height: vw(28);
        padding: vw(4) vw(12);
        margin-right: vw(8);
        margin-bottom: vw(8);
      }
    }
    .brand-middle-box {
      display: flex;
      align-items: center;
      ::v-deep .van-image {
        width: vw(52);
        height: vw(52);
        margin-right: vw(10);
        border: vw(1) solid #E4E7ED;
        border-radius: 50%;
        .van-image__img {
          border-radius: 50%;
        }
      }
      //.brand-logo {
      //  width: vw(52);
      //  height: vw(52);
      //  margin-right: vw(10);
      //  border: vw(1) solid #E4E7ED;
      //  border-radius: 50%;
      //  ::v-deep.van-image__img {
      //    border-radius: 50%;
      //  }
      //}
      .brand-name {
        flex: 1;
        line-height: vw(40);
        font-size: vw(28);
        color: #1F2435;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    .brand-price-range {
      margin-top: vw(10);
      font-size: vw(20);
      line-height: vw(40);
      color: #FF504E;
      span {
        font-size: vw(28);
        font-weight: bold;
      }
    }
  }

}
</style>
