<template>
<div class="head_top">
  <div class="card_info">
    <!-- item. -->
    <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="infoData.logoUrl">
      <template v-slot:loading>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image>
    <div class="card_info--content">
      <div class="title">
        <h3>{{infoData.brandName}}</h3>
        <div class="title_tag" v-if="infoData.newCategoryNameL2 != null">
          <span>{{infoData.newCategoryNameL2}}</span>
        </div>
      </div>
      <div class="sub_title" v-if="infoData.tagList && infoData.tagList.length > 0">
        <span v-for="(val, index) in infoData.tagList" :key="index">{{val}}</span>
      </div>
    </div>
    <!-- 严选 -->
    <template v-if="infoData.isRecord">
      <img class="mark" @click="openCardPop" src="/static/icon/forTheRecord.png" alt="">
    </template>
  </div>
  <div class="card_price">
    <div class="pricde_left">
      <span class="num">{{ infoData.joinTotalCost.substring(0,infoData.joinTotalCost.length-2) }}</span>
      <span class="unit">{{ infoData.joinTotalCost.substring(infoData.joinTotalCost.length-2,infoData.joinTotalCost.length) }}</span>
      <span class="text">/总投资额</span>
    </div>
    <div class="arrow_right">
      <template v-if="(arrowContent.costList && arrowContent.costList.length > 0) || arrowContent.costEstimateImage || arrowContent.profitEstimateImage">
        <span @click="openInvestment">查看投资详情</span>
        <img @click="openInvestment" class="new_arrow" src="/static/icon/new_arrow.png" alt="">
      </template>
    </div>
  </div>
  <div class="tabs_icon">
    <div class="tabs_list" v-if="addData.joinAddress">
      <img src="/static/icon/new_icon1.png" alt="">
      <span>加盟地区：{{addData.joinAddress || "---"}}</span>
    </div>
    <!-- <div class="tabs_list">
      <img src="/static/icon/new_icon2.png" alt="">
      <span>{{addData.formed || "---"}} </span>
    </div> -->
    <div class="tabs_list">
      <img src="/static/icon/new_icon3.png" alt="">
      <span>在营门店：{{infoData.shopNum || "---"}}</span>
      <!-- 家 -->
    </div>
  </div>
  <pop :paramsNum="2" ref="popCardRef" :brandTitle="infoData.brandName"></pop>
</div>
</template>

<script>
import { setLocalStorage } from "@/utils/index";
import pop from './pop';
export default {
  name: "card",
  props: {
    // item: Object,
    // top: {
    //   type: Number,
    //   default: 0,
    // },
    // analyse: {
    //   type: Boolean,
    //   default: false,
    // },
    infoData: {
      type: Object
    },
    addData: {
      type: Object
    },
    arrowContent: {
      type: Object
    }
  },
  components: {
    pop
  },
  data() {
    return {
      
    }
  },
  methods: {
    openInvestment () {
      setLocalStorage("investmentList", this.arrowContent)
      this.swRouter( { 
        path: '/brand/investment_details', 
        query: { name: this.infoData.brandName, } 
      });
    },
    openCardPop() {
      this.$refs['popCardRef'].getIsShow();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
$cardHeight: 18.67vw;
.head_top{
  padding: 0 4vw;
  height: auto;
  box-sizing: border-box;
  border-radius: 2.67vw 2.67vw 0 0;
  line-height: normal;
  // background: linear-gradient(to top right, #fff , #F7F7F7 );
  background: #fcfcfc ;
}
.card_info{
  position: relative;
  height: 15.2vw;
  // padding-left: calc(2.93vw + 4.13vw + 18.67vw);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .mark {
    // &:after {
      // content: "";
      position: absolute;
      top: -0.8vw;
      right: 1vw;
      @include flexColumn;
      width: 11.07vw;
      height: 5.73vw;
      padding: 0 0vw 2vw 0;
      color: #333333;
      font-size: $font_293;
      line-height: normal;
      font-weight: bold;
      border-radius: 0 $radius_xs 0 $radius_xs;
    // }
  }
  #{&}--img {
    position: absolute;
    top: -3.47vw;
    width: $cardHeight;
    height: $cardHeight;
    box-shadow: 0px 0px 2.27vw 0px rgba(0, 0, 0, 0.09);
    border-radius: 1.07vw;
    overflow: hidden;
  }
  #{&}--content{
    width: 100%;
    height: 100%;
    padding: 2.67vw 0 0 22vw;
    box-sizing: border-box;
    .title{
      // display: flex;
      font-size: 0;
      vertical-align: middle;
      h3{
        font-size: 4.53vw;
        font-weight: bold;
        color: #1D2233;
        line-height: 6.4vw;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 16vw);
        width: auto;
      }
      .title_tag{
        display: inline-block;
        vertical-align: middle;
        margin-left: 2.4vw;
        font-size: 0;
        span{
          height: 4.8vw;
          line-height: normal;
          box-sizing: border-box;
          font-size: 3.2vw;
          color: #FF5900;
          padding: 0px 2.4vw;
          background: #FFF1E9;
          border-radius: 0.67vw;
          border: 1px solid #FF9F6B;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: -0.53vw;
        }
      }
    }
    .sub_title{
      margin-top: 1.33vw;
      font-size: 0;
      white-space: nowrap;
      width: 100%;
      overflow-y: auto;
      line-height: normal;
      span{
        vertical-align: middle;
        // height: 4.53vw;
        display: inline-block;
        vertical-align: middle;
        border-radius: 0.67vw;
        font-size: 2.67vw;
        line-height: 1;
        padding: 0.93vw 1.6vw;
        margin-right: 1.6vw;
        background: #EEF6FF;
        color: #347FFF;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
.card_price{
  margin-top: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .pricde_left{
    line-height: 7.07vw;
    font-size: 0;
    .num{
      font-size: 6.13vw;
      font-weight: bold;
      color: #FF5900;
      display: inline-block;
      vertical-align: middle;
      line-height: 8.67vw;
    }
    .unit{
      font-size: 3.2vw;
      font-weight: bold;
      color: #FF5900;
      display: inline;
      vertical-align: bottom;
    }
    .text{
      font-size: 3.2vw;
      color: #666666;
      display: inline;
      vertical-align: bottom;
    }
  }
  .arrow_right{
    flex: 1;
    text-align: right;
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
    span{
      font-size: 3.2vw;
      color: #FF5900;
      line-height: normal;
      margin-right: 1.33vw;
    }
    .new_arrow{
      line-height: normal;
      width: 1.33vw;
      height: 2.8vw;
    }
  }
}
.tabs_icon{
  margin-top: 2.4vw;
  display: flex;
  padding-bottom: 4.8vw;
  .tabs_list{
    margin-right: 5.07vw;
    vertical-align: middle;
    font-size: 0;
    img{
      width: 3.73vw;
      height: 3.73vw;
      margin-right: 0.8vw;
      vertical-align: middle;
      display: inline-block;
    }
    span{
      font-size: 3.2vw;
      color: #333333;
      vertical-align: middle;
      display: inline-block;
      line-height: 4.4vw;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>
