<template>
    <div class="order">
        <StateBar></StateBar>
        <TitleHeader title="订单支付" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView :header="true" :footer="true" class="detail--view">
            <div class="l-panel">
                <!-- 标题 -->
                <h3>{{businessName}}</h3>
                <!-- 参数信息 -->
                <div class="desc s-radius__xs">
                    <p v-for="(field, index) in inputList" :key="index">
                        <span>{{ field.name }}：</span><span style="color: #999">{{ field.value || '无'}}</span>
                    </p>
                </div>
                <div class="list" v-if="packageList && packageList.length">
                    <div class="package_box">
                        <div
                            class="one"
                            :class="{activeIndex: select.stdQuestionId == val.stdQuestionId}"
                            v-for="(val, index) in packageList"
                            :key="index"
                            @click="packageTab(val)"
                        >
                            <div class="discounts" v-if="val.tag">
                                <span>{{ val.tag }}</span>
                            </div>
                            <h4>{{ val.exchangeTimes }}次</h4>
                            <p>
                                <sub>￥</sub><span>{{ val.price }}</span>
                            </p>
                            <div class="day">单次仅需¥{{ getSinglePrice(val) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 优惠信息区域 -->
            <div class="l-panel f-vmt" v-if="isAndroid">
                <div class="coupon__detail" :class="{ on: enabledCoupon, disabled: lockCoupon }" @click="showCoupon">
                <div class="coupon__detail_left">
                    <span class="s-subtitle">优惠券</span>
                    <span class="coupon__name" v-if="getCouponName.name"
                    ><em>{{ getCouponName.name }}</em></span
                    >
                </div>
                <div class="coupon__w">
                    <span v-if="getCouponName.alias" :class="{ on: getCouponName.on, dis: getCouponName.dis }">{{ getCouponName.alias }}</span>
                    <template v-else>
                    <span class="coupon__num">- {{ getDiscount(discount).value }}</span>
                    <span class="coupon__unit">{{ getDiscount(discount).unit }}</span>
                    </template>
                </div>
                </div>
            </div>
            <!-- 支付方式选择区域 -->
            <div class="l-panel nop f-vmt" style="padding-bottom: 0" v-if="showPayMode">
                <h3 class="s-subtitle">支付方式</h3>
                <!-- 支付方式列表 -->
                <PayTypes :payList="payList" :payType="payType" @setPayType="setPayType"/>
            </div>
            <div class="s-tips f-vpb">
                温馨提示：<br />1、您将解锁的商品为虚拟内容产品，确认支付后不支持退换、转让，请仔细确认。<br />
                2、大数据产品服务是基于一定样本量的算法结果，上上参谋不保证用户在平台获得的任何信息、内容（包括但不限于模型结论等）将是完整和没有错误的。由于数据产品服务的特殊性和时效性，在报告完成交付后，用户不得以前述理由要求平台进行退款。
            </div>
        </AutoView>
        <div class="footerBar">
            <van-row class="view">
                <van-col span="12">
                    <p class="desc">应付：</p>
                    <p class="price"><span>￥</span>{{getAmount(select.price)}}</p>
                </van-col>
                <van-col span="12">
                    <van-button type="info" block @click="onTapClick">确认支付</van-button>
                </van-col>
            </van-row>
        </div>
        <van-popup v-model="showCouponPicker" position="bottom" round closeable>
            <div class="l-view pay--coupon">
                <h3 class="f-tac">我的优惠券</h3>
                <div class="coupons">
                <CouponCard
                    v-for="(item, index) in couponList"
                    :key="index"
                    :class="{ 'f-vmt': index > 0 }"
                    :item="item"
                    :checked="coupon && coupon.voucherId === item.voucherId"
                    @selected="setCoupon"
                />
                </div>
                <van-button type="info" block @click="showCouponPicker = false">确定</van-button>
            </div>
        </van-popup>

        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>

        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
const DELAY_TIME = 2000;
import PackageCard from "@/components/card/package";
import PayTypes from "@/components/payTypes";
import { toFixed } from "@/utils/filters";
import CouponCard from "@/components/card/coupon";
export default {
    data() {
        return {
            loading: false,
            isIOS: false,
            isAndroid: false,
            client: 3, // 1: ios 2: android 3: 网页
            waiting: false,
            reportInstanceId: null, // 报告id,
            businessName: '',
            payList: ["支付宝", "微信"],
            payType: "微信", // 默认支付方式
            payMode: "WX_APP",
            payCall: "wxPay",
            payMap: {
                支付宝: {
                    mode: "ALIPAY_MOBILE",
                    call: "aliPay",
                },
                微信: {
                    mode: "WX_APP",
                    call: "wxPay",
                },
                套餐包: {
                    mode: "STD_QUESTION_PKG",
                    call: null,
                }
            },
            canClick: true,
            select: {
                price: '',
                stdQuestionId: ''
            },
            paySuccess: false, // 支付成功状态
            lockCoupon: false,
            showCouponPicker: false,
            showPayMode: true, // 是否显示支付方式
            packageList: [], // 套餐包列表
            inputList: [], // 用户输入条件列表
            businessType: 0, // 业务类型
            orderNo: null, // 订单号
            goodsOrderNo: null, // 正常支付需要的订单号
            pkgId: null,
            discount: 0.0, // 优惠
            couponList: [],
            userStdQuestionPkgItemId: null, // 套餐包解锁id
            couponId: null,
            coupon: {
                voucherId: '',
                couponName: ''
            }
        }
    },
    components: {
        PackageCard,
        PayTypes,
        CouponCard
    },
    computed: {
        enabledCoupon() {
            return this.couponList && this.couponList.length;
        },
        getDiscount() {
            return (num) => {
                return {
                    value: toFixed(num),
                    unit: '元',
                };
            };
        },
        getCouponName() {
            const obj = { name: "", alias: "", on: false, dis: false };
            if (this.loading) {
                obj.alias = "未选择";
            } else if (this.lockCoupon) {
                obj.alias = "不可用";
                obj.dis = true;
            } else if (this.coupon) {
                obj.name = this.coupon.couponName;
            } else if (this.orderNo) {
                obj.alias = "未使用";
                obj.dis = true;
            } else if (this.couponList && this.couponList.length) {
                obj.alias = this.couponList.length + "个可用";
                obj.on = true;
            } else {
                obj.alias = "无可用";
                obj.dis = true;
            }
            return obj;
        },
    },
    async mounted() {
        // 设置客户端类型
        if (window.isIOS) {
            this.isIOS = true;
            this.client = 1;
        } else if (window.isAndroid) {
            this.isAndroid = true;
            this.client = 2;
        }
        const query = this.$route.query
        const orderNo = query.orderNo || null
        this.reportInstanceId = query.reportInstanceId
        const res = await this.HTTP.checkout(this.reportInstanceId, orderNo, this.$route.meta.id)
        console.log(res, "支付信息")
        this.businessType = res.businessType
        this.packageList = res.questionPkgList || []
        this.orderNo = res.orderNo;
        this.businessName = res.businessName
        res.inputList.forEach(item => {
            if(item.name == '偏好' || item.name == '避开' || item.name == '条件扫码范围') {
                this.inputList.push(item)
            }
        })
        this.select = res.questionPkgList[0] || {}
        this.getlistVoucher(this.select.stdQuestionId)
    },
    beforeMount() {
        // 新增回退跳出确认弹窗的接口
        const backMsg = {
            msg: "订单已生成，确认放弃付款？",
            leftButtonText: "暂时放弃",
            rightButtonText: "再想想",
        };
        this.JSBridge.callHandler("backDoubleCheck", JSON.stringify(backMsg));
        this.JSBridge.callHandler("switchNavbar", false);
        this.JSBridge.callHandler("setStatebarTheme", "black");
        // 注册支付回调
        this.JSBridge.registerHandler("paymentResult", (data) => {
            // 安卓端需要进行转义
            if (typeof data === "string") {
                data = JSON.parse(data);
            }
            console.log(data, "state")
            if (typeof data === "object") {
                switch (data.state) {
                    case 1:
                        // 支付成功
                        let timer = 0;
                        const checkPolling = () => {
                            const errorHandler = function (e) {
                                clearTimeout(timer);
                                this.$toast("网络错误，正在重试！");
                                setTimeout(() => {
                                    this.waiting = false;
                                }, DELAY_TIME);
                            };
                            this.HTTP.checkPackage(this.reportInstanceId, this.goodsOrderNo).then((res) => {
                                if (res) {
                                    this.userStdQuestionPkgItemId = res.userStdQuestionPkgItemId;
                                    this.paySuccess = true;
                                    this.onTap(); // 发起解锁
                                }  else {
                                    timer = setTimeout(checkPolling, DELAY_TIME);
                                }
                            }).catch(errorHandler);
                        };
                        this.$toast("正在完成支付，请稍候...");
                        checkPolling();
                        break;
                    case 5: // 兼容微信
                        break;
                    case 7: // 超时
                    case 8: // 取消
                        this.$toast("已取消支付，您可以在订单界面重新进行支付！");
                        setTimeout(() => {
                            this.canClick = true
                            this.waiting = false;
                            this.swRouter("/h5/#/orders");
                        }, DELAY_TIME);
                        break;
                    default:
                        // 支付失败
                        this.$toast("支付失败，请稍候重试！");
                        setTimeout(() => {
                            this.canClick = true
                            this.waiting = false;
                        }, DELAY_TIME);
                }
                return
            }
            this.$toast("发生错误，请稍候重试！");
            this.waiting = false;
        });
    },
    methods: {
        packageTab(val) {
            this.select = val
            this.getlistVoucher(val.stdQuestionId)
        },
        onTapClick() {
            if(this.canClick) {
                this.onTap()
                this.canClick = false
            }
        },
        getSinglePrice(item) {
            if (item.exchangeTimes && item.exchangeTimes <= 0) {
                return item.price;
            }
            const price = parseFloat(item.price);
            let single = price / item.exchangeTimes;
            return Math.round(single);
        },
        showCoupon() {
            if (this.lockCoupon || this.orderNo || !this.couponList.length) return;
            this.showCouponPicker = true;
        },
        onTap() {
            this.waiting = true;
            this.couponId = this.coupon ? this.coupon.voucherId : ''
            if (!this.paySuccess) {
                this.onPayment();
                return;
            }
            // 去解锁报告
            console.log(this.orderNo, this.reportInstanceId, this.couponId, this.client, this.userStdQuestionPkgItemId, this.businessType)
            this.HTTP.createOrder(this.orderNo, 'STD_QUESTION_PKG', this.reportInstanceId, this.couponId, this.client, 0, this.userStdQuestionPkgItemId).then(res => {
                console.log(res, '完成解锁')
                // 埋点
                if (this.couponId) {
                    this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_COUPON);
                } else {
                    this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_NO_COUPON);
                }
                // 支付方式
                if (this.payMode === "ALIPAY_MOBILE") {
                    this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_ALI);
                } else if (this.payMode === "WX_APP") {
                    this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_WX);
                } else if (this.payMode === "STD_QUESTION_PKG") {
                    // 套餐包
                    this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_PKG);
                } else {
                    this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_APPLE);
                }
                if (res.status === 2) {
                    this.waiting = false;
                    // 解锁报告
                    this.swRouter(`/pointRadar/successful`)
                } else {
                    // 正常支付
                    this.orderNo = res.orderNo;
                    console.log("调起支付...");
                    this.callPayment(res.orderNo, res.payResult, this.payCall);
                }
            }).catch((e) => {
                setTimeout(() => {
                    this.waiting = false;
                }, DELAY_TIME);
            });
        },
        onPayment() {
            this.HTTP.createPackage(this.payMode, this.select.stdQuestionId, this.client, this.couponId, this.reportInstanceId, this.orderNo).then(res => {
                if(res.status === 2) {
                    // 状态为已支付, 检查套餐包状态
                    this.HTTP.checkPackage(this.reportInstanceId, res.orderNo).then((res) => {
                        if (res) {
                            this.setPayType('套餐包')
                            this.userStdQuestionPkgItemId = res.userStdQuestionPkgItemId;
                            this.paySuccess = true;
                            this.onTap(); // 发起解锁
                        }
                    }).catch(() => {
                        this.$toast("网络错误，正在重试！");
                        setTimeout(() => {
                            this.waiting = false
                        }, DELAY_TIME);
                    });
                } else {
                    this.goodsOrderNo = res.orderNo
                    this.callPayment(res.orderNo, res.payResult, this.payCall)
                }
            })
        },
        callPayment(id, data, payCall) {
            if (!window.isClient) {
                this.$toast("暂不支持网页版支付");
                setTimeout(() => {
                    this.waiting = false;
                }, DELAY_TIME);
                return;
            } else {
                this.JSBridge.callHandler(
                    payCall || this.payCall,
                    {
                        id,
                        data,
                    },
                    (st) => {
                        if (!st) {
                        this.$toast("未安装客户端，调起支付失败！");
                            setTimeout(() => {
                                this.waiting = false;
                            }, DELAY_TIME);
                        }
                        console.log("调起支付...end");
                    }
                );
            }
        },
        getAmount(price) {
            if(Number(price) < Number(this.getDiscount(this.discount).value)) {
                return 0
            }
            return toFixed(price - this.getDiscount(this.discount).value)
        },
        setCoupon(item) {
            if (this.coupon === item) {
                this.coupon = null;
                this.discount = 0;
            } else {
                this.coupon = item;
                this.discount = parseFloat(item.discountAmount);
            }
        },
        getlistVoucher(stdQuestionId) {
            if(this.isIOS) return
            this.loading = true;
            this.HTTP.getlistVoucher(stdQuestionId)
            .then((res) => {
                console.log("pay res:", res);
                this.couponList = res.data || [];
                // 优惠券信息
                if (this.couponList && this.couponList.length) {
                    const coupon = this.couponList[0];
                    this.coupon = coupon;
                    this.discount = parseFloat(this.coupon.discountAmount);
                } else {
                    this.coupon = null;
                    this.discount = 0;
                }
                this.loading = false;
            })
            .catch((e) => {
                this.$toast("网络不稳定，正在重试！");
                setTimeout(() => {
                    // location.reload();
                }, DELAY_TIME);
            });
        },
        setPayType(type, id) {
            const map = this.payMap[type];
            this.payType = type;
            this.payMode = map.mode;
            this.payCall = map.call;
            this.pkgId = id
        },
    }
}
</script>


<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import "@/styles/index.scss";
.detail--view {
    padding: vw(20);
    background-color: #F2F3F8;
}
.l-panel {
    background-color: #fff;
    border-radius: vw(10);
    padding: vw(30);
    font-size: vw(30);
    .desc {
        margin-top: vw(16);
        background-color: #F8F8F9;
        padding: vw(30);
        font-size: vw(24);
        line-height: vw(42);
    }
    .list {
        height: auto;
        margin-top: vw(20);
        .package_box {
            width: 100%;
            height: auto;
            white-space: nowrap;
            padding-top: vw(30);
            overflow-x: auto;
            .one {
                width: vw(190);
                height: vw(210);
                box-sizing: border-box;
                background: #F7F8FB;
                border-radius: vw(10);
                border: vw(1) solid #E4E7ED;
                margin-right: vw(20);
                display: inline-block;
                padding: vw(30) vw(25);
                text-align: center;
                position: relative;

                .discounts {
                    position: absolute;
                    width: vw(70);
                    height: vw(34);
                    line-height: vw(34);
                    text-align: center;
                    background: #ff5900;
                    border-radius: 1.86vw 0.266vw 2.66vw 0;
                    font-size: 2.66vw;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    top: vw(-20);
                    span {
                        display: inline-block;
                        transform: scale(0.9);
                    }
                }
                h4 {
                    font-size: vw(30);
                    font-weight: bold;
                    color: #1D2233;
                    line-height: vw(42);
                }
                p {
                    // margin-top: 1.33vw;
                    font-size: vw(44);
                    font-weight: bold;
                    color: #F46958;
                    sub {
                        font-size: vw(28);
                        line-height: normal;
                        vertical-align: bottom;
                        position: relative;
                        vertical-align: middle;
                        // top: -1.07vw;
                    }
                    span {
                        line-height: vw(60);
                        vertical-align: middle;
                    }
                }
                .day {
                    font-size: vw(24);
                    line-height: vw(33);
                    margin-top: vw(10);
                    color: #8893A7;
                }
                &:last-child {
                    margin-right: 4vw;
                }
            }
            .activeIndex {
                // position: relative;
                // overflow: hidden;
                border: vw(5) solid #F7D1A1;
                background-color: #FFF8F0;
                h4 {
                    color: #8D5D22;
                }
                .discounts {
                    left: -0.4vw;
                }
            }
        }
    }
}
.s-tips {
    margin-top: vw(40);
    font-size: vw(22);
    color: #bdbdbd;
}
.coupon__detail {
    width: 100%;
    height: 5.33vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @include font($c-text, $font_320, normal);

    &.on {
      background: url(/static/icon/arrow_right_gray.png) no-repeat right center;
      background-size: 5.33vw auto;
      padding-right: 5.33vw;
    }
    &.disabled {
      padding-right: 0;
      background: none;

      .coupon__name {
        display: none;
      }
      .s-subtitle,
      .coupon__num,
      .coupon__unit {
        color: #cccccc;
      }
    }
    .s-subtitle {
      padding: 0;
      margin-bottom: 0;
    }
    .coupon__detail_left {
      display: flex;
      align-items: center;
    }
    .coupon__name {
      @include flexColumn;
      height: 4vw;
      max-width: 32vw;
      margin-left: 2.2vw;
      padding: 0 1vw;
      line-height: normal;
      font-size: 2.93vw;
      border-radius: 2px;
      color: $c-notice;
      box-shadow: 0 0 0 1px $c-notice;
      box-sizing: border-box;
      em {
        display: block;
        width: 100%;
        font-style: normal;
        @include singleLine;
      }
    }
    .coupon__w {
      text-align: right;
      display: flex;
      align-items: center;
      .on {
        color: $c-main;
      }
      .dis {
        color: #cccccc;
      }
    }
    .coupon__num {
      font-size: 3.4vw;
      font-weight: 600;
      color: $c-notice;
    }
    .coupon__unit {
      font-size: 3vw;
      color: $c-notice;
    }
}
.pay--coupon {
    h3 {
      font-size: $font_480;
      line-height: $font_667;
    }
    .coupons {
      height: 70vw;
      overflow: auto;
      margin: $margin1 0;
    }
}
.footerBar {
    .view {
        box-sizing: border-box;
        padding: $headerMargin $padding;
        width: 100%;
        height: $footerHeight;
        ::v-deep .van-col {
            height: 100%;
        }
    }
    .desc {
        color: #676C84;
        line-height: vw(28);
        font-size: vw(20);
    }
    .price {
        font-size: vw(40);
        font-weight: bold;
        color: #FF504E;
        span {
            font-size: vw(24);
        }
    }
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
        background-color: #FF8047;
        border: vw(1) solid #FF8047;
    }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
