// 异步加载loca

export default function loadLoca () {
  if ( window.Loca ) {
    return new Promise( function ( resolve, reject ) {
      resolve( window.Loca );
    } );
  }
  const src = `https://webapi.amap.com/loca?key=fee5094dc0300f1001a61bef444e041b&v=1.3.2`;
  const script = document.createElement( 'script' );
  script.src = src;
  document.body.appendChild( script );
  return new Promise( function ( resolve, reject ) {
    script.onload = function () {
      resolve( window.Loca );
    };
    script.onerror = function () {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject();
    };
  } );
}
