<template>
    <div class="input_content">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true">
            <template slot="right">
                <span class="success" @click="openNewDetail">完成</span>
            </template>
        </TitleHeader>

        <AutoView ref="view" :header="true" :footer="false" class="input_content--view">
            <van-skeleton :loading="loading" :animate="false" :row="4" class="s-skeleton">
                <div class="input_content--main">
                    <div class="box">
                        <div class="text_box">
                            <div class="img">
                                <img src="/static/icon/msg_success_green.png" alt="">
                            </div>
                            <p>发布完成</p>
                            <van-button type="info" block class="s-radius__s toViewDetail" @click="openCommontsDetail">查看品牌评价</van-button>
                        </div>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>


<script>
export default {
    name: "commontsSuccess",
    data() {
        return {
            title: "",
            loading: false,
        }
    },
    methods: {
        // 跳转到详情
        openCommontsDetail() {
            this.swRouter({
                path: '/brand/commonts_detail/'+this.$route.query.detailId,
                // query: {
                //     name: this.$route.query.name
                // }
                query: { num: 1 }
            });
            this.JSBridge.callHandler("onBackPressed");
        },
        // 跳转到品牌详情
        openNewDetail() {
            // this.swRouter( '/brand/details/' + this.$route.query.code );
            this.JSBridge.callHandler("onBackPressed");
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.input_content{
    height: 100vh;
    background: #F7F7F7;
    overflow: hidden;
    line-height: normal;
    .success{
        font-size: 3.47vw;
        color: #4B8EEA;
    }
    #{&}--main{
        height: 100%;
        padding: 4vw;
        box-sizing: border-box;
        .box{
            background: #fff;
            width: 100%;
            height: 88vw;
            border-radius: 1.33vw;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            .text_box{
                flex: 1;
                text-align: center;
                .img{
                    line-height: 0;
                    width: 21.33vw;
                    height: 21.33vw;
                    margin: auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                p{
                    font-size: 4.27vw;
                    font-weight: bold;
                    color: #333333;
                    margin-top: 3.73vw;
                    line-height: 5.87vw;
                }
                .toViewDetail{
                    width: calc(100% - 9.2vw - 9.2vw);
                    background: #518CDB;
                    border-radius: 1.33vw;
                    font-size: 4vw;
                    font-weight: bold;
                    margin: 12vw auto 0;
                    color: #FFFFFF;
                }
            }
        }
    }
   
}
</style>