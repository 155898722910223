<template>
    <div class="vipShop">
        <div class="vipShop--header" v-show="!loading">
            <StateBar :bgcolor="bgColor" />
            <TitleHeader title="会员权益" :color="titleColor" :bgcolor="bgColor" :back="showBack"></TitleHeader>
        </div>
        <!-- 骨架屏占位图 -->
        <img v-if="loading" src="/static/skt/newVip.png" width="100%" />
        <AutoView v-if="!loading" ref="view" :header="false" @scroll.native="pageScrollHandle" :footer="true" class="vipShop--view">
            <div ref="top">
                <div class="top" :class="isVip ? 'isVipBg' : 'notVipBg'">
                    <div class="info">
                        <div class="left">
                            <img class="user_logo" :src="info.avatar ? info.avatar : '/static/icon/default.png'" alt="" />
                            <img v-if="isVip" class="vip_icon" src="/static/icon/vip_icon.png" alt="" />
                        </div>
                        <div class="right">
                            <h3>{{ this.info.nickname }}</h3>
                            <p>{{ isVip ? `${info.member.endTime}` : "当前未开通VIP会员" }}</p>
                        </div>
                    </div>
                </div>
                <van-swipe :autoplay="3000" class="swipe" style="height: 8vw;" ref="swipe" vertical :show-indicators="false" :touchable="false" v-if="pollingObj">
                    <van-swipe-item style="width: 100vw" v-for="(item, index) of pollingObj.list" :key="index">
                        <div class="swipe-item">
                            <img :src="pollingObj.icon" alt="">
                            <div>{{item.userName}}<span>{{item.text}}</span> </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
                <div class="vipShop--content">
                    <img class="count-bg" v-if="activeFlag" src="/static/img/wdfs_bg.png" alt="">
                    <!-- 会员套餐 -->
                    <vip-card :vipTitle="`会员套餐`" :showVipBg="activeFlag">
                        <div class="list">
                            <!-- clearfix -->
                            <div class="vip_box">
                                <div
                                    class="one"
                                    v-for="(val, index) in list"
                                    :key="index"
                                    :class="info.select.id == val.id ? 'activeIndex' : ''"
                                    @click="vipTab(val)"
                                >
                                    <div class="discounts" v-if="val.tag">
                                        <span>{{ val.tag }}</span>
                                    </div>
                                    <h4>{{ val.name }}</h4>
                                    <p>
                                        <sub>￥</sub><span>{{ getPriceNum(val.price) }}</span>
                                    </p>
                                    <div class="day" :class="{deleteLine : activeFlag}">{{ val.avgDayPrice }}</div>
                                </div>
                            </div>
                        </div>
                    </vip-card>
                </div>


              <!--iOS-->
              <template v-if="isIOS">
                <div class="agreement" v-if="isSubscription">
                  确认购买后，将向您的 iTunes 账户收款。自动续费 iTunes 账户会在到期前 24小时内扣费。在此之前，
                  您可以手动在 iTunes/ Apple ID 设置管理中关闭自动续费，该项目在 iPhone 和 iPad 上均可使用，由上上参谋提供与 Apple Inc. 无关。
                  购买前请仔细阅读
                  <span @click="agreementClick('subscription')">《连续订阅服务协议》</span>、
                  <span @click="agreementClick('memberservice')">《上上参谋会员服务协议》</span>及
                  <span @click="agreementClick('paymentagreement')">《上上参谋支付协议》</span>，
                  您点击购买后即视为您已同意前述协议。
                </div>
                <div class="agreement" v-else>
                  确认购买后，将向您的 iTunes 账户收款。自动续费 iTunes 账户会在到期前 24小时内扣费。在此之前，
                  您可以手动在 iTunes/ Apple ID 设置管理中关闭自动续费，该项目在 iPhone 和 iPad 上均可使用，
                  由上上参谋提供与 Apple Inc. 无关。购买前请仔细阅读
                  <span @click="agreementClick('memberservice')">《上上参谋会员服务协议》</span>及
                  <span @click="agreementClick('paymentagreement')">《上上参谋支付协议》</span>，
                  您点击购买后即视为您已同意前述协议。
                </div>
              </template>
              <!--android-->
              <template v-else>
                <div class="agreement" v-if="isSubscription">
                  每月自动扣费，可随时取消；购买前请仔细阅读<span @click="agreementClick('subscription')">《连续订阅服务协议》</span>、<span @click="agreementClick('memberservice')">《上上参谋会员服务协议》</span>及<span @click="agreementClick('paymentagreement')">《上上参谋支付协议》</span>，您点击购买后即视为您已同意前述协议。
                </div>
                <div class="agreement" v-else>
                  购买前请仔细阅读<span @click="agreementClick('memberservice')">《上上参谋会员服务协议》</span>及<span @click="agreementClick('paymentagreement')">《上上参谋支付协议》</span>，您点击购买后即视为您已同意前述协议。
                </div>
              </template>

                <div class="ad_img">
                    <van-image v-for="item in vipList" :key="item" :src="item"></van-image>
                </div>
                <div class="msg">
                    您购买的是虚拟内容产品，线下数据需要耗费大量数据资源计算，确认支付后不支持退款，请仔细确认，以免造成困扰。
                </div>
            </div>
        </AutoView>

        <!-- 底栏提交按钮 v-if="entity"-->
        <SoltFooter v-show="!loading" class="vipShop--footer">
            <div class="footer">
                <van-button type="info" block class="s-radius__s btn1" @click="onTapPay">
                    支付￥{{info.select && getPriceNum(info.select.price)}} 开通VIP
                    <!-- <div class="discounts" v-if="!this.info.isPay && !this.activeFlag">
                        <span>首单特惠</span>
                    </div> -->
                </van-button>
            </div>
        </SoltFooter>

        <!-- 支付选择界面 -->
        <van-popup
            v-model="showPayment"
            round
            position="bottom"
            :safe-area-inset-bottom="false"
            :close-on-click-overlay="false"
            @click-overlay="onTapClose"
        >
            <div class="l-panel f-vp vipShop--subview">
                <h3>开通会员<i class="icon_vip" /></h3>
                <div class="view">
                    <!-- 支付方式选择 -->
                    <PayTypes :payList="payList" @setPayType="setPayType" />
                </div>
                <van-button type="info" block :loading="waiting" class="s-radius__s" @click="onTap">确认支付</van-button>
            </div>
        </van-popup>

        <!-- 购买结果提醒 -->
        <van-overlay :show="showPayTips">
            <div class="l-wrapper">
                <div class="vipShop--tips l-panel s-radius__s">
                    <van-image class="icon" fit="cover" :src="payTips.icon" />
                    <h3>{{ payTips.title }}</h3>
                    <p>{{ payTips.text }}</p>
                    <van-button type="info" class="s-radius__s" @click="onConfirm">确定</van-button>
                </div>
            </div>
        </van-overlay>

        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>

    </div>
</template>
<script>
import VipCard from "./components/vipCard";
import PayTypes from "@/components/payTypes";
export default {
    name: "vipShop",
    components: {
        VipCard,
        PayTypes,
    },
    data() {
        return {
            list: [],
            vipList: [
                "/static/img/vip_1.png",
                // "/static/img/vip_2.png",
                "/static/img/vip_3.png",
            ],
            moduleVipEquity: [],
            pollingObj: null,
            loading: true,
            current: 0,
            appCode: null, // app版本号
            showPayment: false,
            showPayTips: false,
            isIOS: false,
            isAndroid: false,
            isSuperVip: true,
            vipType: 0,
            showVipDetail: false,
            isSubscription: false, // 是否是连续订阅商品
            vipDetail: [],
            client: 3, // 1iOS 2Android 3WEB
            payList: ["支付宝", "微信"],
            payType: null,
            payMode: null,
            payCall: null,
            payMap: {
                支付宝: {
                    mode: "ALIPAY_MOBILE",
                    call: "aliPay",
                    unit: "￥",
                },
                微信: {
                    mode: "WX_APP",
                    call: "wxPay",
                    unit: "￥",
                },
                "Apple Pay": {
                    mode: "APPLE_IN",
                    call: "applePay",
                    unit: "￥",
                },
            },
            waiting: false,

            payTips: {
                icon: "",
                title: "",
                text: "",
            },
            payTipsMap: {
                success: {
                    icon: "/static/icon/msg_success_orange.png",
                    title: "操作成功",
                    text: "您已成功完成支付",
                },
                failed: {
                    icon: "/static/icon/more.png",
                    title: "操作失败",
                    text: "未能完成支付操作",
                },
            },
            info: {
                nickname: "",
                member: null,
                select: null,
                avatar: null,
            },
            orderNo: null, // 订单号 下单时生成
            isVip: false, // 是否会员

            bgColor: "transparent",
            titleColor: "white",
            showBack: true,
            isShowEle: false,
            idx: 0, // 套餐默认选中tab
            activeFlag: null, // 活动标识
        };
    },
    created() {
        const idx = this.$route.query.idx;
        if (idx != 0 && !idx) {
            this.idx = 0;
        } else {
            this.idx = idx;
        }
        // 查询会员信息
        this.HTTP.getVipInfo()
            .then((res) => {
                console.log("查询会员信息", res);
                this.loading = false;
                this.isVip = res.userDetail ? res.userDetail.member : false;
                this.list = res.memberGoodsList;
                this.info.select = this.list && this.list.length ? this.list[this.idx] : null;
                this.isSubscription = this.info.select.type == 2
                this.info.nickname = res.userDetail ? res.userDetail.username : null;
                this.info.member = res.userDetail ? res.userDetail.memberInfo : null;
                this.info.avatar = res.userDetail ? res.userDetail.avatar : null;
                this.info.isPay = res.userDetail ? res.userDetail.isPay : null;
                this.vipDetail = res.memberItemList
                this.activeFlag = res.activeFlag
            })
            .catch((e) => {
                this.$toast("网络连接不稳定，请稍候重试！");
            });
        // 查询播报数据
        this.getPollingData()
    },
    beforeMount() {
        let _this = this
        this.JSBridge.callHandler("setStatebarTheme", "white");
        this.JSBridge.callHandler("getVersionCode", null, function (code) {
            _this.appCode = code;
        });
        // 注册支付回调
        this.JSBridge.registerHandler("paymentResult", (data) => {
            console.log(data.state, "state")
            // 安卓端需要进行转义
            if (typeof data === "string") {
                data = JSON.parse(data);
            }
            if (typeof data === "object") {
                this.waiting = false;
                switch (data.state) {
                    case 1:
                        // 支付成功
                        this.showPayment = false;
                        this.payTips = this.payTipsMap.success;
                        this.payTips.state = true;
                        this.showPayTips = true;
                        // 通知客户端更新用户信息
                        this.JSBridge.callHandler("noticeUpdate", "userInfo");
                        break;
                    case 5: // 兼容微信
                        break;
                    case 7: // 超时
                    case 8: // 取消
                        this.$toast("已取消支付");
                        break;
                    default:
                        // 支付失败
                        this.showPayment = false;
                        this.payTips = this.payTipsMap.failed;
                        this.payTips.state = false;
                        this.showPayTips = true;
                        console.log("支付失败", data.status, this.payTips)
                }
            } else {
                this.$toast("支付时发生错误！");
                this.waiting = false;
            }
        });
        // 设置客户端类型
        if (window.isIOS) {
            this.isIOS  = true;
            this.client = 1;
            // if(window._appCode >= 301 ) {
            //     this.vipList.splice(1,1)
            // }
        } else if (window.isAndroid) {
            this.isAndroid = true
            this.client = 2;
        }
    },
    mounted() {
        this.isShowEle = this.$store.state.isShowEle;
    },
    methods: {
        agreementClick(type) {
            let query = {}
            switch(type) {
                case 'subscription':
                    query.queryPath = '/subscription.html'
                    query.title = '连续订阅服务协议'
                    break
                case 'memberservice':
                    query.queryPath = '/memberservice.html'
                    query.title = '上上参谋会员服务协议'
                    break
                case 'paymentagreement':
                    query.queryPath = '/paymentagreement.html'
                    query.title = '上上参谋支付协议'
                    break
            }
            this.swRouter({
                path: '/iframeAgreement',
                query,
            })
        },
        pageScrollHandle() {
            let tg = this.$refs.standard;
            let sv = 20;
            if (!tg) {
                tg = this.$refs.top;
                sv = -10;
            }
            const stdTop = tg.getBoundingClientRect().top;
            const check = stdTop > sv;
            if (this.isTop !== check) {
                this.isTop = check;
                this.bgColor = check ? "transparent" : "white";
                this.titleColor = check ? "white" : "#1D2233";
                this.JSBridge.callHandler("setStatebarTheme", check ? "white" : "black");
            }
        },
        onConfirm() {
            if (this.payTips.state) {
                this.showPayTips = false;
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CHANGE_MAIN_TAB",
                    id: "me",
                    params: null,
                    trackData: null,
                    isClear: true,
                });
            } else {
                this.showPayTips = false;
            }
        },
        getPriceNum(num) {
            return num.substring(1, num.length);
        },
        // 立即开通
        onTapPay() {
            // 按钮埋点上报
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 60003, "");
            if ( this.isIOS && window._appCode >= 301 ) {
                this.setPayType("Apple Pay")
                this.onTap();
            } else {
                this.showPayment = true;
                if(this.info.select.type == 2) {
                    this.payList = ['支付宝']
                } else {
                    this.payList = ['支付宝', '微信']
                }
            }
        },
        onTap() {
            //业务流程
            this.waiting = true;
            console.log("创建订单..." + this.payMode + "====" + this.info.select.id + "=====" + this.client);
            this.HTTP.createVip(this.payMode, this.info.select.id, this.client)
                .then((res) => {
                    console.log(res, "打印payResult")
                    console.log("创建订单...end");
                    console.log("调起支付...");
                    this.orderNo = res.orderNo;
                    const data = {
                        id: res.orderNo,
                        data: res.payResult,
                    };
                    if (window.isClient) {
                        this.JSBridge.callHandler(this.payCall, data, (st) => {
                            if (!st) {
                                this.$toast("未安装客户端，调起支付失败！");
                                setTimeout(() => {
                                    this.waiting = false;
                                }, 1000);
                                console.log(`调起支付...失败`);
                                return;
                            }
                            console.log(`调起支付...end`);
                        });
                    } else {
                        this.$toast("暂不支持网页版支付");
                        setTimeout(() => {
                            this.waiting = false;
                        }, 1000);
                    }
                })
                .catch((e) => {
                    this.$toast("创建订单时发生错误！");
                    setTimeout(() => {
                        this.waiting = false;
                    }, 1000);
                });
        },
        onTapClose() {
            if (this.payLoading) return;
            this.showPayment = false;
        },
        setPayType(value) {
            const map = this.payMap[value];
            this.payType = value;
            this.payMode = map.mode;
            this.payCall = map.call;
            // this.info.unit = map.unit;
        },
        getPollingData() {
            this.HTTP.getPollingData().then(res => {
                this.pollingObj = res.data.data
            }).catch(err => {
                console.log(err, "获取数据失败")
            })
        },
        vipTab(item) {
            this.info.select = item;
            this.isSubscription = this.info.select.type == 2

        },
    },
};
</script>
<style lang="scss" scoped>
//@import "@/styles/index.scss";
.vipShop {
    height: 100vh;
    background: #f7f7f7;

    // overflow: hidden;
    #{&}--header {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 6;
    }
    .top {
        width: 100%;
        height: 46.6vw;
        position: relative;
        padding: 0 4vw;
        box-sizing: border-box;
        .info {
            position: absolute;
            bottom: -4vw;
            left: 4vw;
            right: 4vw;
            height: 28vw;
            z-index: 2;
            background: transparent;
            padding: 4vw;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .left {
                width: 15.47vw;
                height: 15.47vw;
                margin-right: 3.33vw;
                border-radius: 7.73vw;
                position: relative;
                .user_logo {
                    width: 100%;
                    height: 100%;
                    border-radius: 7.73vw;
                }
                .vip_icon {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    width: 5.6vw;
                    height: 5.6vw;
                }
            }
            .right {
                flex: 1;
                h3 {
                    font-size: 4.27vw;
                    font-weight: bold;
                    color: #1d2233;
                    line-height: 6vw;
                }
                p {
                    margin-top: 1.2vw;
                    font-size: 3.47vw;
                    color: #999999;
                    line-height: 4.93vw;
                }
            }
            .detail {
                opacity: 0.69;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 5.33vw;
                border: 0.2667vw solid #1d2233;
                width: 18.267vw;
                height: 5.33vw;
                color: #1d2233;
                font-size: 2.933vw;
                border-radius: 2.933vw;
                margin-top: 7vw;
                img {
                    width: 1.2vw;
                    height: 2.133vw;
                    margin-left: 1vw;
                }
            }
        }
    }
    ::v-deep .van-swipe-item {
        display: flex;
        align-items: center;
        .swipe-item {
            font-size: 2.93vw;
            color: #1D2233;
            display: flex;
            img {
                height: 2.667vw;
                width: 3.33vw;
                margin-left: 6vw;
                margin-right: 2vw;
            }
            span {
                color: #8893A7;
                margin-left: 2vw;
            }
        }
    }
    .isVipBg {
        background: url("/static/img/vip_shop_top.png") no-repeat;
        background-size: cover;
    }
    .notVipBg {
        background: url("/static/img/not_vip.png") no-repeat;
        background-size: cover;
    }
    .count-bg {
        width: 92vw;
        height: 6.667vw;
        display: block;
    }
    #{&}--content {
        // height: calc(100% - 53.6vw);
        padding: 3.2vw 4vw;
        padding-top: 0;
        box-sizing: border-box;
        // overflow-y: auto;
        .list {
            margin-top: 3.33vw;
            width: 100%;
            height: auto;
            .vip_box {
                width: 100%;
                height: auto;
                white-space: nowrap;
                overflow: hidden;
                overflow-x: auto;
                padding-top: 1.5vw;
                .one {
                    width: 22.67vw;
                    height: 28vw;
                    box-sizing: border-box;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 1.33vw;
                    border: 0.27vw solid #e7e7e7;
                    margin-right: 2.4vw;
                    display: inline-block;
                    padding: 3.47vw 1.33vw;
                    text-align: center;
                    position: relative;
                    h4 {
                        font-size: 4.27vw;
                        font-weight: bold;
                        color: #1d2233;
                        line-height: 6vw;
                    }
                    p {
                        margin-top: 1.33vw;
                        font-size: 5.6vw;
                        font-weight: bold;
                        color: #ff5900;
                        sub {
                            font-size: 3.47vw;
                            line-height: normal;
                            vertical-align: bottom;
                            position: relative;
                            top: -1.07vw;
                        }
                        span {
                            line-height: 7.87vw;
                            vertical-align: middle;
                        }
                    }
                    .day {
                        font-size: 2.93vw;
                        color: #8d5c21;
                        line-height: 4vw;
                        margin-top: 1.87vw;
                    }
                    .deleteLine {
                        text-decoration: line-through;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .discounts {
                    left: -0.27vw;
                }
                .activeIndex {
                    border: 0.4vw solid #8d5c21;
                    background: #ffebd1;
                    position: relative;
                    // overflow: hidden;
                    .discounts {
                        left: -0.4vw;
                    }
                }
                .activeIndex::after {
                    position: absolute;
                    bottom: -0.27vw;
                    right: -0.27vw;
                    background: url("/static/icon/vip_check.png") no-repeat;
                    background-size: 6.67vw 5.73vw;
                    content: "";
                    z-index: 1;
                    width: 6.67vw;
                    height: 5.73vw;
                }
            }
            // .clearfix{
            //     &:after {
            //         visibility: hidden;
            //         display: block;
            //         content: " ";
            //         clear: both;
            //     }
            // }
        }
        .vip_table {
            width: 100%;
            height: auto;
            margin-top: 3.33vw;
            // border-radius: 1.33vw;
            // overflow: hidden;
            ::v-deep .van-grid-item__content {
                padding: 2.93vw 1.33vw;
            }
            ::v-deep [class*="van-hairline"]::after {
                border: 0 solid #dedee7;
                border-width: 0 0.13vw 0.13vw 0;
            }
            ::v-deep .van-hairline--top::after {
                border-top-width: 0.13vw;
                border-left-width: 0.13vw;
                border-bottom-width: 0;
            }
            ::v-deep .van-grid-item__text {
                color: #1d2233;
                line-height: 4.4vw;
            }
            ::v-deep .van-grid-item:nth-child(1),
            ::v-deep .van-grid-item:nth-child(2),
            ::v-deep .van-grid-item:nth-child(3) {
                .van-grid-item__content {
                    background: #f7f7f7;
                    .van-grid-item__text {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .discounts {
        position: absolute;
        width: 19.06vw;
        height: 4.8vw;
        line-height: 4.8vw;
        text-align: center;
        background: #ff5900;
        border-radius: 1.86vw 0.266vw 2.66vw 0;
        font-size: 2.66vw;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        top: -1.77vw;
        span {
            display: inline-block;
            transform: scale(0.9);
        }
    }
    #{&}--footer {
        z-index: 2;
        .footer {
            width: 100%;
            padding: 0 4vw;
            box-sizing: border-box;
            .s-radius__s {
                overflow: inherit !important;
            }
            .btn1 {
                border-radius: 1.33vw;
                // background: linear-gradient(90deg, #FFCE8F 0%, #FFAC42 100%);
                background: linear-gradient(to right, #ffb962, #ff9f48);
                box-shadow: 0vw -0.27vw 0.8vw 0vw rgba(34, 34, 34, 0.12);
                border-color: transparent;
                font-size: 4vw;
                font-weight: bold;
                border: none;
            }
        }
        .discounts {
            right: 0;
            border-radius: 0.533vw 2.133vw 0.533vw 2.133vw;
        }
    }

    #{&}--subview {
        h3 {
            text-align: center;
            font-size: $font_400;
            .icon_vip {
                display: inline-block;
                width: 7.86vw;
                height: 4vw;
                background: url(/static/icon/vip_card.png) no-repeat left center;
                background-size: cover;
                vertical-align: bottom;
                margin-left: 1.5vw;
            }
        }
        .view {
            padding: $margin 0;
        }
        ::v-deep .van-button {
            font-size: $font_400;
            line-height: normal;
        }
    }
    #{&}--tips {
        width: 87.2vw;
        padding: $padding;
        background-color: white;
        text-align: center;

        .icon {
            width: $iconSize;
            height: $iconSize;
            margin-top: 4.3vw;
        }
        h3 {
            font-size: $font_373;
            line-height: $font_533;
            padding-top: 7.47vw;
        }
        p {
            font-size: $font_293;
            color: $c-info;
            padding-top: 0.8vw;
        }
        .van-button--info {
            width: 59.2vw;
            margin-top: 10.93vw;
        }
        .link {
            font-size: $font_400;
            margin-top: 4.8vw;
        }
    }
    ::v-deep .van-overlay {
        z-index: 3;
    }
    .ad_img {
        width: 92vw;
        padding: 0 4vw 2.667vw 4vw;
        img {
            width: 100%;
        }
        ::v-deep .van-image {
            margin-bottom: 2.67vw;
        }
    }
    .msg {
        color: #8893A7;
        font-size: $font_320;
        line-height: 4.933vw;
        margin: 0 3.2vw 4.8vw 4vw;
    }
    .agreement {
        color: #8893A7;
        font-size: $font_267;
        line-height: 4.933vw;
        margin: 0 3.2vw 3.2vw 4vw;
        span {
            color: $c-main;
        }
    }

    .module-list {
        padding: 3.2vw 4vw;
        background-color: #fff;
        box-sizing: border-box;
        color: #333333;
        font-size: 3.2vw;
        .title {
            color: #333333;
            font-weight: 600;
            font-size: 3.467vw;
            height: 6vw;
            line-height: 6vw;
            display: flex;
            align-items: center;
            img {
                width: 3.6vw;
                height: 3.6vw;
                margin-right: 1vw;
            }
        }
        .tip {
            color: #8893A7;
            line-height: 5vw;
        }
        .module-item {
            display: flex;
            align-items: center;
            margin-top: 2.5vw;
            .moduleVip {
                width: 70vw;
                .item {
                    float: left;
                    width: 20.3vw;
                    height: 7.6vw;
                    margin-left: 2.5vw;
                    margin-top: 2.5vw;
                    border: 0.267vw solid #E7E7E7;
                    text-align: center;
                    line-height: 7.6vw;
                    border-radius: 0.8vw;
                }
                .active {
                    background: linear-gradient(#FFAC42, #FFCE8F);
                    color: #fff;
                    border: 0.267vw solid #FFA02A;
                }
            }
            .superVip {
                width: 21vw;
                height: 21vw;
                border: 0.267vw solid #E7E7E7;
                border-radius: 1.333vw;
                padding: 2.5vw;
                margin-top: 2.667vw;
                box-sizing: border-box;
                position: relative;
                span {
                    line-height: 4.53vw;
                }
                img {
                    position: absolute;
                    width: 11.467vw;
                    height: 11.467vw;
                    bottom: 0;
                    right: 0;
                }
            }
            .active {
                background: linear-gradient(#FFAC42, #FFCE8F);
                color: #fff;
                border: 0.267vw solid #FFA02A;
            }
        }
    }
    .tip-box {
        // box-sizing: border-box;
        position: relative;
        width: 78vw;
        padding: 4vw;
        background: linear-gradient(#FFF5EC , #FFFFFF);
        .detail_bg {
            position: absolute;
            top: 4vw;
            left: 50%;
            transform: translateX(-50%);
            width: 58vw;
        }
        .detail_close {
            position: absolute;
            top: 2.667vw;
            right: 2.667vw;
            width: 5vw;
            height: 5vw;
        }
        .myVip {
            color: #653A21;
            text-align: center;
            margin-top: 8vw;
            font-weight: 600;
            position: relative;
            img {
                position: absolute;
                width: 16.4vw;
                height: 0.8vw;
                top: 2.5vw;
            }
            .detail_left {
                left: 4vw;
            }
            .detail_right {
                right: 4vw;
                transform: rotate(180deg);
            }
        }
        .detail_wrapper {
            max-height: 100vw;
            overflow: auto;
            .vip-detail {
                display: flex;
                align-items: center;
                margin-top: 4vw;
                padding-bottom: 4vw;
                // background-color: red;
                border-bottom: 0.133vw solid #E4E7ED;
                img {
                    width: 10.133vw;
                    height: 10.133vw;
                    margin-right: 2.5vw;
                }
                .detail-item {
                    color: #8893A7;
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 15.133vw;
                    .top {
                        font-weight: 600;
                        height: 4vw;
                        line-height: 4vw;
                        font-size: 4vw;
                        padding: 0;
                        margin-top: 1vw;
                        span {
                            font-size: 2.933vw;
                            padding: 0 1.33vw;
                            border-radius: 2.133vw;
                            border: 0.266vw solid #8893A7;
                        }
                    }
                    .bottom {
                        font-size: 2.933vw;
                        margin-top: 2vw;
                    }
                }
            }
        }

    }
    ::v-deep .van-popup {
        border-radius: 2.667vw;
    }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
