<template>
  <div class="card-list">
    <div v-if="shops && shops.length > 0">
      <template v-for="(item, index) in shops">
      <!-- 这段判断一会抽离-->
        <div
          class="tab-card"
          v-if="!MEDIA_NAME.includes(item.tepName) && item.respList && item.respList.length > 0 && item.respList.some(v => v.keyValue != '' && v.keyValue != null)"
          :key="index"
        >
        <cite>{{item.tepName}}</cite>
          <template v-for="(col,index) in item.respList">
            <div class="cell" :key="index" v-if="col.keyValue">
              <div class="cell-hd">{{col.keyName}}：</div>
              <div class="cell-bd">{{col | valueFilter}}{{col.unit}}</div>
            </div>
          </template>
      </div>
      </template>
    </div>
    <div v-else class="no-data">
      <img src="/static/img/me/surveyedShops/img-null.png" />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    shops: Array,
  },
  data () {
    return {
      MEDIA_TYPE: [11, 33, 75, 96, 117, 136, 167, '11', '33', '75', '96', '117', '136', '167'],
      MEDIA_NAME: ['图片和视频'],
    }
  },
  filters: {
    valueFilter: function(col) {
      if (col.dictType === 'multiple') {
        let val = [];
        let dictValue = JSON.parse(col.dictValue);
        let keyValue = JSON.parse(col.keyValue);
        keyValue.forEach((item) => {
          dictValue.forEach(v => {
            if (v.key == item) {
              val.push(v.value);
            }
          })
        })
        return val.join('，');
      } else if (col.dictType === 'single') {
        let val = '';
        let dictValue = JSON.parse(col.dictValue);
        let keyValue = col.keyValue;
        dictValue.forEach(v => {
          if (v.key == keyValue) {
            val = v.value;
          }
        })
        return val;
      } else {
        return col.keyValue;
      }
    }
  }
};

</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import '@/styles/_cell.scss';

.card-list{
  min-height: vw(800);
}
.tab-card{
  position: relative;
  background: #FFFFFF;
  border-radius: vw(10);
  margin-top: vw(22);
  padding: vw(66) vw(25) vw(25) vw(25);
  font-size: 0px;
  cite{
    min-width: vw(149);
    height: vw(48);
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    line-height: vw(48);
    text-align: center;
    background: #DFEDFF;
    border-radius: vw(10) 0 vw(10) 0;
    font-size: vw(26);
    font-weight: 500;
    color: #347FFF;
    font-style: normal;
    padding: 0px vw(10);
  }
  .cell{
    margin-bottom: vw(20);
    align-items: start;
    .cell-hd{
      display: inline-block;
      font-size: vw(28);
      font-weight: 400;
      color: #999999;
      line-height: vw(40);
      //min-width: 4em;
    }
    .cell-bd{
      font-size: vw(28);
      font-weight: 400;
      color: #333333;
      line-height: vw(40);
      font-style: normal;
      text-decoration: none;
      margin: 0 vw(12);
      display: inline-block;
      vertical-align: top;
    }
  }
}
.no-data{
  width: vw(710);
  height: vw(600);
  background: #FFFFFF;
  border-radius: vw(10);
  margin-top: vw(10);
  padding-top: vw(160);
  text-align: center;
  img{
    display: block;
    width: vw(174);
    margin: auto;
  }
  p{
    height: vw(40);
    font-size: vw(28);
    font-weight: 500;
    color: #BDBDBD;
    line-height: vw(40);
    margin-top: vw(31);
  }
}
</style>
