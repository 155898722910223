<template>
    <div class="varieties">
        <StateBar></StateBar>
        <TitleHeader :title="title" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="bills--view l-view">
            <!-- :loading="firstLoading" -->
            <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__s">
                <!-- <EmptyCard v-if="list.length === 0" type="bills"></EmptyCard> -->
                <!-- <template v-else> -->
                <div v-if="varietiesTable" class="box-table">
                    <van-grid :column-num="4" border>
                        <template v-if="this.$route.query.type === 'categoryNums'">
                            <template>
                                <van-grid-item text="排行"></van-grid-item>
                                <van-grid-item text="品类"></van-grid-item>
                                <van-grid-item text="商家数量"></van-grid-item>
                                <van-grid-item text="占比"></van-grid-item>
                            </template>
                            <template v-for="(item, ix) in varietiesTable">
                              <van-grid-item :key="'c1' + ix" :text="item.rank"></van-grid-item>
                                <van-grid-item :key="'c2' + ix" :text="item.categoryName ? item.categoryName : '-'"></van-grid-item>
                                <van-grid-item :key="'c3' + ix" :text="item.shopTotal ? String(item.shopTotal) : '-'"></van-grid-item>
                                <van-grid-item :key="'c4' + ix" :text="item.percent ? String(item.percent) + '%' : '-'"></van-grid-item>
                            </template>
                        </template>
                        <template v-else>
                            <template>
                                <van-grid-item text="品类"></van-grid-item>
                                <van-grid-item text="店均订单数"></van-grid-item>
                                <van-grid-item text="人均(元)"></van-grid-item>
                                <van-grid-item text="店均销售额(元)"></van-grid-item>
                            </template>
                            <template v-for="(item, ix) in varietiesTable">
                                <van-grid-item :key="'c1' + ix" :text="item.categoryName ? item.categoryName : '-'"></van-grid-item>
                                <van-grid-item :key="'c2' + ix" :text="item.orderNumber ? String(item.orderNumber) : '-'"></van-grid-item>
                                <van-grid-item :key="'c3' + ix" :text="item.avgPrice ? String(item.avgPrice) : '-'"></van-grid-item>
                                <van-grid-item :key="'c4' + ix" :text="item.saleMoney ? String(item.saleMoney) : '-'"></van-grid-item>
                            </template>
                        </template>

                    </van-grid>
                </div>
                <!-- </template> -->
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
export default {
    name: "monthRank",
    data() {
        return {
            firstLoading: false,
            varietiesTable: [],
            title: "",
        };
    },
    methods: {},

    created() {
        console.log(this.$route.query.type, "query");
        if (this.$route.query.type === "categoryNums") {
            this.title = "外卖品类";
            this.getData("categoryNums")

        } else {
            this.title = "月订单量排行";
            this.getData("orderRankList")
        }
    },
    methods: {
        getData(key) {
            this.JSBridge.callHandler("getString", key, (data) => {
                console.log(JSON.parse(data), "data")
                // 将数据转换后发送到处理方法
                if (data) {
                    if (typeof data === "string") {
                        this.varietiesTable = JSON.parse(data); // 兼容安卓数据解析
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";
.varieties {
    width: 100%;
    .box-table {
        width: 100%;
        height: auto;
        ::v-deep .van-grid-item__content {
            padding: 2.93vw 1.33vw;
        }
        ::v-deep [class*="van-hairline"]::after {
            border: 0 solid #dedee7;
            border-width: 0 0.13vw 0.13vw 0;
        }
        ::v-deep .van-hairline--top::after {
            border-top-width: 0.13vw;
            border-left-width: 0.13vw;
            border-bottom-width: 0;
        }
        ::v-deep .van-grid-item__text {
            color: #1d2233;
            line-height: 4.4vw;
        }
        ::v-deep .van-grid-item:nth-child(1),
        ::v-deep .van-grid-item:nth-child(2),
        ::v-deep .van-grid-item:nth-child(3),
        ::v-deep .van-grid-item:nth-child(4) {
            .van-grid-item__content {
                background: #f8f8f9;
                .van-grid-item__text {
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
