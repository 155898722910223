<template>
  <div class="searchBar" @click="">
    <form action="" @submit="validate" class="searchBar--form" @click="goSearch">
      <img class="search-icon" src="/static/icon/search_icon.png" v-if="!isIos" />
      <input ref="search" v-model="keyword" type="search" :disabled="!canSearch" placeholder="请输入小区名称" class="searchBar--input" @input="input" />
      <i v-show="keyword" class="searchBar--clear" @click.stop="clear" />
    </form>
    <div class="cancel-btn" v-if="canSearch" @click="back">取消</div>
    <div class="map-location" @click="goMapPage" v-else>
      <img src="/static/cellScreen/map-location.png" />
      <span>地图</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'searchHeader',
  props: {
    isVip: {
      type: Boolean,
      default: false
    },
    canSearch: {
      type: Boolean,
      default: true
    },
    content: String
  },
  data () {
    return {
      keyword: this.content,
      showClear: false,
      isIos: false,
    };
  },
  watch: {
    content(nv) {
      this.keyword = nv
    }
  },
  mounted () {
    this.isIos = window.isIOS
    if (this.canSearch) {
      this.$nextTick(() => {
        this.$refs.search.focus();
      })
    }
  },
  methods: {
    goSearch() {
      if (this.canSearch) return
      if (!this.isVip) {
        this.$emit('lockVip')
        return
      }
      this.$emit('goSearchPage')
    },
    back() {
      this.JSBridge.callHandler("onBackPressed");
      this.JSBridge.callHandler("switchBackBtn", true);
    },
    input() {
      this.$emit('input', this.keyword ? this.keyword.trim() : '')
    },
    search() {
      this.$emit('search', this.keyword ? this.keyword.trim() : '')
    },
    validate(e) {
      e.preventDefault();
      this.search()
    },
    clear() {
      this.keyword = "";
      this.$emit('search', this.keyword)
    },
    goMapPage() {
      this.$emit('goMapPage')
    }
  }
};
</script>

<style lang="scss" scoped>
 //@import "@/styles/index.scss";

 $searchIcon: $headerHeight - $headerMargin * 2;

 .searchBar {
   position: relative;
   display: flex;
   align-items: center;
   height: 10.667vw;
   .cancel-btn {
     font-weight: 400;
     color: #347FFF;
     line-height: 10.667vw;
     font-size: 3.733vw;
     padding: 0 4.533vw 0 3.47vw;
   }
  .map-location {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 4.533vw 0 3.47vw;
    img {
      width: 4.533vw;
      height: 4.533vw;
      margin-right: .933vw;
    }
    span {
      font-weight: 400;
      color: #347FFF;
      line-height: 4.933vw;
      font-size: 3.47vw;
    }
  }
   #{&}--form {
     position: relative;
     flex: 1;
     padding-left: $margin;
     box-sizing: border-box;
     display: flex;
     align-items: center;
     justify-content: space-between;
     height: 100%;
     background: #F7F8FB;
     border-radius: 1.333vw;
     border: .1333vw solid #E4E7ED;
     .search-icon {
       width: 4vw;
       height: 4vw;
     }
   }
   #{&}--input {
     background-color: transparent;
     border: none;
     flex: 1;
     height: 100%;
     width: 100%;
     padding: 0;
     font-weight: normal;
     padding-left: 2.933vw;
     font-size: 3.733vw;
     color: #333333;
     -webkit-text-fill-color: #333333;
     -webkit-opacity: 1;
     opacity: 1;
   }
   #{&}--clear {
     position: relative;
     right: 2vw;
     display: block;
     width: $searchIcon;
     height: 100%;
     background: url(/static/cellScreen/clear-search.png) no-repeat center center;
     background-size: 4.27vw;
   }
   #{&}--cancel {
     width: 11.2vw;
     height: 3.4vw;
     line-height: 3.4vw;
     font-size: $font_320;
     text-align: right;
     box-shadow: inset 1px 0 0 $c-gray;
   }
 }
</style>
