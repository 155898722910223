const toFixed = ( value, pre = 2 ) => {
    return typeof value === 'number' ? parseFloat( value ).toFixed( pre ) : 0;
};

const toTimeStr = ( value ) => {
    if ( typeof value !== 'number' || isNaN( value ) ) return '---';
    const step  = [
        { t: 86400, s: '天' },
        { t: 3600, s: '小时' },
        { t: 60, s: '分' },
    ];
    const time = [];
    for ( const sp of step ) {
        if ( value < sp.t ) continue;
        time.push( ~ ~ ( value / sp.t ), sp.s );
        value = value % sp.t;
    }
    if ( value ) time.push( value, '秒' );
    return time.join( '' );
};

const formatTPS = ( value ) => {
    if ( typeof value !== 'number' ) {
        return '0';
    } else if ( value < 1000 ) {
        return value;
    } else {
        return value.toLocaleString();
    }
};

const formatDate = ( date ) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if ( month < 10 ) {
        month = '0' + month;
    }
    let day = date.getDate();
    if ( day < 10 ) {
        day = '0' + day;
    }
    let hour = date.getHours();
    if ( hour < 10 ) {
        hour = '0' + hour;
    }
    let minute = date.getMinutes();
    if ( minute < 10 ) {
        minute = '0' + minute;
    }
    let second = date.getSeconds();
    if ( second < 10 ) {
        second = '0' + second;
    }
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

const formatDate2 = ( date, fmt ) => {
  const o = {
    "M+" : date.getMonth()+1, //月份
    "d+" : date.getDate(), //日
    "h+" : date.getHours()%12 == 0 ? 12 : date.getHours()%12, //小时
    "H+" : date.getHours(), //小时
    "m+" : date.getMinutes(), //分
    "s+" : date.getSeconds(), //秒
    "q+" : Math.floor((date.getMonth()+3)/3), //季度
    "S"  : date.getMilliseconds() //毫秒
  };
  if ( /(y+)/.test( fmt ) ) {
    fmt = fmt.replace(RegExp.$1, ( date.getFullYear() + "" ).substr( 4 - RegExp.$1.length ) );
  }
  for ( let k in o ) {
    if ( new RegExp("("+ k +")").test( fmt ) ) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    }
  }
  return fmt;
}

export { toFixed, toTimeStr, formatTPS, formatDate, formatDate2 };
