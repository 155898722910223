<template>
	<div class="intention-form">
		<van-popup v-model="submitMaskType" closeable position="bottom" :style="{ height: '50%' }" @close="close">
			<div class="body-box">
				<div class="title">提交选址意向</div>
				<div class="form-box">
					<div class="form-item">
						<div class="label">姓名</div>
						<div class="value"><input type="text" v-model="form.name" placeholder="请输入姓名">
						</div>
					</div>
					<div class="form-item">
						<div class="label">手机号</div>
						<div class="value"><input type="tel" maxlength="11" v-model="form.phone" placeholder="请输入手机号">
						</div>
					</div>
				</div>
				<div class="select-box">
					<div class="left-title">需要服务</div>
					<div class="right-body">
						<div class="right-body-item" @click="selectClick(item,key)"
							:class="{'active':key===selectIndex}" v-for="(item,key) in selectArr" :key="key">
							<div class="left-box">
								<div class="title">{{item.title}}</div>
								<div class="content">{{item.content}}</div>
							</div>
							<div class="right-box">
								<img v-if="key===selectIndex" :src="item.icon1" alt="">
								<img v-else :src="item.icon" alt="">
							</div>
						</div>
					</div>
				</div>
				<div class="mask-button">
					<div class="button" @click="maskSubmit">提交选址意向</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				selectIndex: -1,
				submitMaskType: true,
				form: {
					name: '',
					phone: '',
					type: -1, //服务类型：0商铺评估 1铺位推荐 2铺位转让 3我有意向  4我要咨询"
					city: ''
				},
				selectArr: [{
					title: '商铺评估',
					content: '选址参谋线下采集商铺信息，并给出评估分析',
					icon: require('../../../../static/shopEcology/icon0.png'),
					icon1: require('../../../../static/shopEcology/icon0-active.png'),
					id: 0
				}, {
					title: '铺位推荐',
					content: '根据你的开店诉求，选址参谋进行铺位推荐',
					icon: require('../../../../static/shopEcology/icon1.png'),
					icon1: require('../../../../static/shopEcology/icon1-active.png'),
					id: 1
				}],
				city: ''
			}
		},
		mounted() {
			this.JSBridge.callHandler('getLocation', null, (data) => {
				if (typeof data === 'string') data = JSON.parse(data);
				this.form.city = data ? data.province + data.city : ''
				// console.log(data.adCode) //440305
				// console.log(data.city + data.district) //深圳市南山区
				// console.log([data.city, data.district].join('-')) //深圳市-南山区
				// console.log(data.address) //详细地址
			});
		},
		methods: {
			selectClick(item, key) {
				this.selectIndex = key
				this.form.type = item.id
			},
			maskSubmit() {
				const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (!this.form.name) {
					this.$toast.fail('请填写姓名');
				} else if (this.getByteLength(this.form.name) > 14) {
					this.$toast.fail('名称太长，请重新输入');
				} else if (!this.form.phone) {
					this.$toast.fail('请填写手机号码');
				} else if (!phoneReg.test(this.form.phone)) {
					this.$toast.fail('请填写正确手机号码');
				} else if (this.form.type === -1) {
					this.$toast.fail('请选择服务类型');
				} else {
					this.$emit('submit', this.form)
				}
			},
			close() {
				this.$emit('close')
			},
			getByteLength(str = '') {
				let len = 0;
				for (let i = 0; i < str.length; i++) {
					if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
						len += 2;
					} else {
						len++;
					}
				}
				return len;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.intention-form {
		// @import '@/styles/index.scss';

		@function vw($args) {
			@return $args/750*100+vw;
		}

		.body-box {
			padding: vw(30) vw(30) vw(0);
			box-sizing: border-box;

			.title {
				height: vw(48);
				font-size: vw(34);
				font-weight: 600;
				line-height: vw(48);
				text-align: center;
				color: #1D2233;
			}

			.form-box {
				.form-item {
					margin-bottom: vw(10);

					.label {
						height: vw(72);
						font-size: vw(30);
						font-weight: 600;
						color: #121622;
						line-height: vw(72);
					}

					.value {
						height: vw(80);
						border-radius: vw(10);

						input {
							border: none;
							height: 100%;
							width: 100%;
							background: #F7F8FB;
							font-size: vw(28);
							color: #1D2233;
							padding: 0 vw(30);
							box-sizing: border-box;
						}
					}
				}
			}

			.select-box {
				.left-title {
					height: vw(82);
					font-size: vw(30);
					font-weight: 600;
					color: #121622;
					line-height: vw(82);
				}

				.right-body {
					display: flex;
					justify-content: space-between;

					.right-body-item {
						height: vw(174);
						border-radius: vw(10);
						border: vw(2) solid #E4E7ED;
						box-sizing: border-box;
						padding: vw(20) vw(10) vw(20) vw(30);
						width: vw(335);
						background: #F7F8FB;
						display: flex;

						.left-box {
							.title {
								font-size: vw(28);
								font-weight: 600;
								height: vw(40);
								line-height: vw(40);
								text-align: left;
								color: #1D2233;
							}

							.content {
								margin-top: vw(4);
								font-size: vw(22);
								height: vw(28);
								line-height: vw(30);
								color: #676C84;
							}
						}

						.right-box {
							img {
								width: vw(100);
								height: vw(100);
							}
						}
					}

					.active {
						border-color: #347FFF;

						.title {
							color: #347FFF !important;
						}
					}
				}
			}

			.mask-button {
				padding-top: vw(30);
				box-sizing: border-box;

				.button {
					height: vw(80);
					background: #347FFF;
					border-radius: vw(10);
					font-size: vw(30);
					font-weight: 600;
					color: #FFFFFF;
					line-height: vw(80);
					text-align: center;
				}
			}

		}
	}
</style>
