<template>
  <div>
    <div class="img-wrap">
      <img src="static/vip/enterprise/svip/1.png" />
      <span class="track" @click="toPrivilege">查看权益对比<van-icon name="arrow" /></span>
    </div>
    <div class="img-wrap" ref="pointA">
      <img src="static/vip/enterprise/svip/2.png" />
      <img src="static/vip/enterprise/svip/3.png" />
    </div>
    <div class="img-wrap">
      <img src="static/vip/enterprise/svip/4.png" />
      <van-button @click="toService">在线客服</van-button>
    </div>
    <div class="img-wrap">
      <img src="static/vip/enterprise/svip/5.png" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toService(){
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "ONLINE_SERVICE",
        params: null,
      });
    },
    toPrivilege(){
      this.$refs.pointA.scrollIntoView();
      const el = document.getElementById('auto-view');
      el.scrollTop = el.scrollTop - 100;
    }
  }
}
</script>

<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.img-wrap{
  background: #fff;
  padding: vw(36) vw(29);
  margin-top: vw(20);
  position: relative;
  .privilege-wrap{
    .privilege{
      padding-top: vw(28);
      border-bottom: vw(1) solid #E7E7E7;
      &:last-of-type{
        border-bottom: none;
      }
    }
  }
  img{
    display: block;
    width: 100%;
  }
  .track{
    position: absolute;
    right: vw(29);
    top: vw(36);
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #347FFF;
    line-height: 100%;
    vertical-align: top;
    .van-icon{
      line-height: 100%;
      vertical-align: top;
    }
    &::after{
      //content: ' ';
      //position: absolute;
      //width: vw(10);
      //height: vw(21);
      //right: vw(-13);
      //top: vw(6);
      //display: block;
      //background: url('/static/vip/enterprise/icon-right-arry.png') no-repeat;
      //background-size: auto vw(21);
    }
  }
  .title-img{
    display: block;
    width: auto;
    height: vw(32);
  }
  .more{
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #347FFF;
    line-height: vw(33);
    text-align: center;
    padding-top: vw(33);
    img{
      display: inline-block;
      width: vw(22);
    }
  }
  .van-button{
    width: 100%;
    height: vw(80);
    background: #FFFFFF;
    border-radius: vw(10);
    border: vw(2) solid #347FFF;
    font-size: vw(30);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #347FFF;
    margin-top: vw(35);
  }
}

.track{
  position: absolute;
  right: vw(29);
  top: vw(36);
  font-size: vw(24);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #347FFF;
  line-height: 100%;
  vertical-align: top;
  .van-icon{
    line-height: 100%;
    vertical-align: top;
  }
  &::after{
    //content: ' ';
    //position: absolute;
    //width: vw(10);
    //height: vw(21);
    //right: vw(-13);
    //top: vw(6);
    //display: block;
    //background: url('/static/vip/enterprise/icon-right-arry.png') no-repeat;
    //background-size: auto vw(21);
  }
}
</style>
