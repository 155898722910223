import Vue from "vue";
import VueBus from "vue-bus";
import App from "./App";
import router from "./router";
import store from "./store";
window.enterDate = new Date().getTime()
import {
  Image as VanImage,
  Icon as VanIcon,
  SwipeCell,
  Button,
  Card,
  Circle,
  Cell,
  CellGroup,
  Col,
  Row,
  Dialog,
  Divider,
  Field,
  Grid,
  GridItem,
  List,
  Loading,
  Overlay,
  Picker,
  Popup,
  PullRefresh,
  Skeleton,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Tag,
  Lazyload,
  Toast,
  IndexBar,
  IndexAnchor,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Slider,
  Sticky,
  Search,
  Switch,
  Rate,
  DropdownMenu,
  DropdownItem,
  Area,
  Radio ,
  RadioGroup,
  Cascader,
  DatetimePicker,
  Popover,
  Uploader,
  Step,
  Steps,
  Form,
  Empty,
  ActionSheet,
  ShareSheet,
  Badge
} from "vant";
import Http from "./request/http";
import JSBridge from "./utils/jsbridge";
import ComponentLoader from "@/components/loader";
import * as filters from "./utils/filters";
import swrouter from "./utils/swrouter";
import "vant/lib/index.css";
// import "@/styles/index.scss";
import vcallapp from './utils/callapp';
import aegis from './utils/aegis';
import VueClipboard from 'vue-clipboard2'
//引入vue懒加载
import VueLazyload from 'vue-lazyload'

Vue.use(VueClipboard)
// 引入Vant UI组件库
Vue.use(SwipeCell);
Vue.use(Popover);
Vue.use(VanIcon);
Vue.use(VanImage);
Vue.use(Button);
Vue.use(Card);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Rate);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Field);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(List);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(PullRefresh);
Vue.use(Skeleton);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Slider);
Vue.use(Sticky);
Vue.use(Search);
Vue.use(Switch);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Form);
Vue.use(Lazyload, {
  lazyComponent: true
});
Vue.use(Area);
Vue.use(DatetimePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cascader);
Vue.use(Uploader);
Vue.use(Step);
Vue.use(Steps);
Vue.use(VueLazyload)
Vue.use(Empty)
Vue.use(ActionSheet)
Vue.use(ShareSheet)
Vue.use(Badge)


Toast.setDefaultOptions({ position: "bottom" });
// 引入自定义组件库
Vue.use(ComponentLoader);
// 引入消息总线
Vue.use(VueBus);
// 唤起APP
Vue.use(vcallapp);

// 装载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// 挂载JS桥接库
Vue.prototype.JSBridge = JSBridge;
// 挂载请求库
Vue.prototype.HTTP = Http;

// 挂载自定义路由跳转
Vue.prototype.swRouter = swrouter;

import commonApi from '@/utils/common'
Vue.prototype.commonApi = commonApi;

// Http.setAuth( store.getters.auth );
// 挂载用户信息（当前版本由客户端接管）
// Vue.prototype.GET_USER = () => {
//     return store.getters.user;
// };
// 关闭控制台提示信息
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue( {
  router,
  store,
  aegis,
  render: ( h ) => h( App )
} ).$mount( '#app' );

Vue.directive('log',{
  inserted: function (el,binding,vnode) {
    el.addEventListener('click', (e) => {
      let that = vnode.context;
      that.JSBridge.BTNCLICK( that.$route.meta.id, '', binding.value.mid, binding.value.bid);
    });
  }
})
