<template>
  <div>
    <!--介绍-续费-信息模块-->
    <div class="module">
      <div class="cell" v-if="memberEnterpriseList && memberEnterpriseList.length > 0">
<!--        <div class="cell-hd">-->
<!--          <div class="vip-card" :class="{active: curPackage == 'vip'}" @click="curPackageChnage('vip')">-->
<!--            <p class="title">{{getVipInfo().name}}</p>-->
<!--            <p class="price"><b>¥{{getVipInfo().showPrice}}</b></p>-->
<!--            &lt;!&ndash;<p class="rights">{{memberEnterprise.avgDayPrice}}</p>&ndash;&gt;-->
<!--            <div class="save">-->
<!--              <p>-->
<!--                尊享26大权益<br />-->
<!--                专注选址服务-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="cell-bd">
          <div class="svip-card" :class="{active: curPackage == 'svip'}" @click="curPackageChnage('svip')">
            <!-- <p class="title">{{getSVipInfo().name}}</p> -->
            <!-- <p class="price"><b>¥{{getSVipInfo().showPrice}}</b></p> -->
            <p class="title"><span>连锁品牌招商版</span></p>
            <p class="price"><b>1年</b></p>
            <div class="save">
              <p>
                40大核心权益<br/>
                招商选址找铺一站式服务
              </p>
            </div>
            <img class="track" src="/static/vip/enterprise/enterprise-tab-icon.png" alt="">
<!--            <img class="track" src="/static/vip/enterprise/brand-track.png" />-->
          </div>
        </div>
      </div>

      <div class="img-wrap no-padding" v-if="curPackage == 'vip'">
        <img src="static/vip/enterprise/vip-introduce.png" />
      </div>

      <div class="img-wrap no-padding" v-if="curPackage == 'svip'">
        <img src="static/vip/enterprise/svip/svip-introduce.png" />
      </div>

      <!--协议-->
      <div v-if="false" class="agreement" @click="checkAgreement">
        <span>
        <img src="static/vip/enterprise/checked.png" v-if="agreement" />
        <img src="static/vip/enterprise/uncheck.png" v-else />
      </span>
        <span>
        购买前请仔细阅读<b @click.stop="toAgreement('memberservice')">《上上参谋会员服务协议》</b>及<b @click.stop="toAgreement('paymentagreement')">《支付协议》</b><b @click.stop="handleAgreement()">《上上参谋企业版-数位拓店云服务协议》</b>，您点击购买后视为您已同意前述协议
      </span>
      </div>
      <pay-button-group v-if="showPayBtn" :btnText="btnText" @onTapPay="onTapPay"/>

      <div v-if="curPackage == 'vip'">
        <!--未购买-->
        <div class="package-card" v-if="vipLevel != STATUS_ENUM.EVIP">
          <p class="title">连锁品牌VIP</p>
          <p class="account">开通企业账号（主账号）：<ins>{{saasInfo.account}}</ins></p>
          <p class="date">到期时间：<ins>{{saasInfo.expireDate}}</ins></p>
          <p class="city">权益品牌：<ins>填写资料定制</ins></p>
          <p class="price">权益城市：<ins>填写资料定制</ins></p>
          <template v-if="saasInfo.isParent">
            <van-button class="input-btn" v-if="!saasInfo.companyName" @click="toInput(0)">填写企业信息</van-button>
            <van-button class="details-btn" v-else @click="toInput(3)">查看详情</van-button>
          </template>
        </div>
        <!--已购买并填写资料-->
        <div class="package-card" v-if="vipLevel == STATUS_ENUM.EVIP && saasInfo.companyName">
          <p class="title">连锁品牌VIP(已开通)</p>
          <p class="account">开通企业账号（主账号）：<ins>{{saasInfo.account}}</ins></p>
          <p class="date">到期时间：<ins>{{saasInfo.expireDate}}</ins> <span class="code-key">商户码：</span><ins>{{saasInfo.merchantCode || '-'}}</ins></p>
          <p class="city">公司名称：<ins>{{saasInfo.companyName}}</ins></p>
          <p>品牌名称：<ins>{{saasInfo.brand}}</ins></p>
          <template v-if="saasInfo.isParent">
            <van-button class="details-btn" @click="toDetails">查看详情</van-button>
          </template>
        </div>
        <!--已购买未填写资料-->
        <div class="package-card" v-if="vipLevel == STATUS_ENUM.EVIP && !saasInfo.companyName">
          <p class="title">连锁品牌VIP(已购买未开通)</p>
          <p class="account">开通企业账号（主账号）：<ins>{{saasInfo.account}}</ins></p>
          <p class="date">到期时间：<ins>{{saasInfo.expireDate}}</ins></p>
          <p class="city">公司名称：<ins>填写资料定制</ins></p>
          <p>品牌名称：<ins>填写资料定制</ins></p>
          <template v-if="saasInfo.isParent">
            <van-button class="input-btn" @click="toInput(1)">补充信息开通</van-button>
          </template>
        </div>
      </div>

    </div>
    <div class="video-wrap" ref="video" v-show="curPackage == 'vip'">
      <div id="vip-video-1"></div>
    </div>
    <div class="vip-introduce" v-if="curPackage == 'vip'">
      <!--企业特权-->
      <div class="img-wrap no-margin">
        <img src="static/vip/enterprise/7.png" />
        <span class="track" @click="toPrivilege">查看权益对比<van-icon name="arrow" /></span>
      </div>
      <!--权益-icons-->
      <div class="img-wrap">
        <img class="title-img" src="static/vip/enterprise/8.png" />
        <div class="privilege-wrap">
          <privilege
            v-for="(privilege,index) in privileges"
            :data="privilege"
            :key="index"
          />
        </div>
        <!--      <div ref="pointA">-->
        <!--        <p class="more" @click="showMore" v-show="privilegesAdditional.length !== 0">-->
        <!--          查看更多-->
        <!--          <img src="static/vip/enterprise/arr-down.png" />-->
        <!--        </p>-->
        <!--        <p class="more" @click="closeMore" v-show="privilegesAdditional.length === 0">-->
        <!--          收起-->
        <!--          <img src="static/vip/enterprise/arr-down.png" style="transform:rotate(180deg)"/>-->
        <!--        </p>-->
        <!--      </div>-->
      </div>
      <!--权益-->
      <div class="img-wrap" ref="pointA">
        <!--<span class="track" @click="toDetails">查看权益详情<van-icon name="arrow" /></span>-->
        <img src="static/vip/enterprise/1.png">
        <img src="static/vip/enterprise/2.png">
      </div>

      <div class="img-wrap">
        <img src="static/vip/enterprise/5.png">
        <van-button @click="toService">在线客服</van-button>
      </div>
      <div class="img-wrap">
        <img src="static/vip/enterprise/6.png">
      </div>
    </div>

    <div class="svip-introduce" v-if="curPackage == 'svip'">
      <SVip />
    </div>

    <p class="foot-wrod">
      您购买的是虚拟内容产品，线下数据需要耗费大量数据资源计算，确认支付后不支持退款，请仔细确认，以免造成困扰。
    </p>
    <div v-drag class="server ">
      <img @click="toService" src="static/vip/enterprise/server.png" />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import MultiMediaPreview from '../components/multiMediaPreview/index.vue';
import Privilege from '../components/privilege/index.vue';
import Player from "xgplayer";
import PayButtonGroup from "../components/pay-button-group/pay-button-group";
import SVip from './svip.vue';

export default {
  name: 'index',
  components: {
    MultiMediaPreview,
    Privilege,
    PayButtonGroup,
    SVip
  },
  props: {
    saasInfo: {
      type: Object,
      default: () => {
        return {
          account: null,
          brandName: null,
          companyName: null,
          expireDate: null,
          merchantCode: null,
          status: 1, // 1: 未开通 2：已开通
          url: null,
          price: '',
          isParent: true,
        };
      }
    },
    memberEnterprise: {
      type: Object,
      default: () => {
        return {
          avgDayPrice: '',
          id: '',
          iosGoodsCode: '',
          name: '',
          price: '',
          tag: '',
        };
      }
    },
    memberEnterpriseList: {
      type: Array,
      default: () => null
    },
    showPayBtn: {
      type: String,
      default: null,
    },
    agreement: {
      type: Boolean,
      default: false,
    },
    vipLevel: {
      type: Number,
      default: false,
    },
    userInfo: {
      type: Object,
      default: (val) => {
        return {
          member: {
            vipLevel: 0, // 0默认 1vip 2svip 3企业vip
          }
        }
      },
    },
    // 是否开通了vip
    isVip: {
      type: Boolean,
      default: false,
    }
  },
  computed:{
    btnText(){
      return `${this.getCurPackage().price.replace('￥','').replace('¥','')} ${ this.isVip ? '续费' : '开通' }品牌招商版`; // ${this.curPackage == 'vip' ? 'VIP' : 'SVIP'}
    }
  },
  data(){
    return {
      // STATUS_ENUM: {
      //   NOT_PAID: 1,
      //   PAID: 2,
      //   PAID_NO_DATA: 3,
      // },
      STATUS_ENUM: {
        VIP: 1,
        SVIP: 2,
        EVIP: 3,
      },
      moving: false,
      isIOS: false,
      isAndroid: false,
      client: 3,
      multiMediaVisible: false, // 显示/关闭多媒体预览
      videoPendantVisible: true, // 显示、关闭视频挂件
      privileges: [
        {
          title: '品牌战略规划',
          head:{
            title: '品牌战略规划',
            desc: '宏观战略分析',
            bg: '/static/vip/enterprise/privilege-bg-1.png',
            mark: '',
          },
          icons: [
            {
              icon: 'static/vip/enterprise/privilege-icon-1.png',
              title: '品牌战略地图',
              desc: '宏观战略分析',
              mark: 'static/vip/enterprise/privlege-mark-1.png',
            },
            {
              icon: 'static/vip/enterprise/privilege-icon-3.png',
              title: '协同办公',
              desc: '多账号共享权益',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-icon-2.png',
              title: '战略看板',
              desc: '实时拓店数据预警',
              mark: null,
            }
          ],
        },
        {
          title: '大数据选址',
          head:{
            title: '开店选址套餐',
            desc: '立省2380元',
            bg: '/static/vip/enterprise/privilege-bg-1.png',
          },
          icons: [
            {
              icon: 'static/vip/enterprise/privilege-2-1.png',
              title: '地图数据可视',
              desc: '多源数据可视分析',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-2-2.png',
              title: '位置推荐',
              desc: '定制商圈标准智能推荐',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-3-2.png',
              title: '商场分析',
              desc: '门店&客流',
              mark: null,
            },

            {
              icon: 'static/vip/enterprise/privilege-3-3.png',
              title: '外卖分析',
              desc: '区域外卖经营分析',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-5-1.png',
              title: '开店选址评估',
              desc: 'AI智能大数据',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-5-2.png',
              title: '外卖店选址',
              desc: '专注3Km外卖选址',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-2-3.png',
              title: '数据多源分析',
              desc: '多图层下钻分析',
              mark: 'static/vip/enterprise/marker-2.png',
            },
            {
              icon: 'static/vip/enterprise/privilege-2-4.png',
              title: '点位评估',
              desc: '5A模型跑分科学评估',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-3-4.png',
              title: '商圈分析',
              desc: '周边&商业潜力',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-3-5.png',
              title: '竞对&协同分析',
              desc: '竞对&市场容量',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-5-3.png',
              title: '开店位置推荐',
              desc: '3个开店新址',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-1.png',
              title: '社区店选址',
              desc: '搞定家门口好生意',
              mark: null,
            }

          ],
        },
        {
          title: '开店工具',
          head:{
            title: '开店工具套餐',
            desc: '开店实用工具',
            bg: '/static/vip/enterprise/privilege-bg-1.png',
          },
          icons: [
            {
              icon: 'static/vip/enterprise/privilege-6-1.png',
              title: '赚钱选品类',
              desc: '赚钱好生意',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-6-2.png',
              title: '竞争分析',
              desc: '快速抢占客流商机',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-6-3.png',
              title: '地图标注',
              desc: '获得万亿流量',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-6-4.png',
              title: '点位雷达',
              desc: '批量扫描，精准匹配',
              mark: null,
            },
          ],
        },
        {
          title: '数据查询',
          head:{
            title: '7大数据查询',
            desc: '宏观战略分析',
            bg: '/static/vip/enterprise/privilege-bg-1.png',
            mark: ''
          },
          icons: [
            {
              icon: 'static/vip/enterprise/privilege-7-1.png',
              title: '城市查询',
              desc: '城市宏观&经济',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-2.png',
              title: '外卖查询',
              desc: '外卖经营&分布',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-3.png',
              title: '品牌查询',
              desc: '品牌经营&分布',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-4.png',
              title: '行业热力',
              desc: '行业哪里最密集',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-5.png',
              title: '周边查询',
              desc: '周边商圈&客群',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-6.png',
              title: '商场查询',
              desc: '商场业态&配套',
              mark: null,
            },
            {
              icon: 'static/vip/enterprise/privilege-7-7.png',
              title: '人口热力',
              desc: '人口哪里最密集',
              mark: null,
            },
            // {
            //   icon: 'static/vip/enterprise/privilege-7-8.png',
            //   title: '小区筛选',
            //   desc: '筛选开店小区',
            //   mark: null,
            // }
          ],
        },
      ],
      privilegesAdditional: [],
      videoPlay: null,
      isPlaying: false,
      curPackage: 'svip',
    };
  },
  directives: {
    drag(el,bindings) {
      let disX= 0;
      let disY= 0;
      let elWidth = el.style.width;
      window.maxDragZIndex = 100;
      el.addEventListener('touchstart', (e) => {
        elWidth = e.target.clientWidth;
        el.setAttribute('class', el.getAttribute("class").replace(' to-right', ''));
        disX= el.offsetLeft;
        disY= el.offsetTop;
        window.maxDragZIndex += 1;
        el.style.zIndex = window.maxDragZIndex;
      }, false);
      el.addEventListener('touchmove', (e) => {
        let clientX = e.changedTouches[0].clientX;
        let clientY = e.changedTouches[0].clientY;
        if (clientX > 0) {
          el.style.left = e.changedTouches[0].clientX - elWidth/2 + 'px';
        }
        if (clientY > 0) {
          el.style.top =  e.changedTouches[0].clientY + 'px';
        }
      }, false)
      el.addEventListener('touchend', (e) => {
        el.setAttribute('class', el.getAttribute("class") + ' to-right');
        el.style.left = window.screen.width - elWidth - (window.screen.width*0.012) +'px';
        document.onmousemove = document.onmouseup = null;
      }, false)
    }
  },
  mounted() {
    // 设置客户端类型
    if ( window.isIOS ) {
      this.isIOS  = true;
      this.client = 1;
    } else if ( window.isAndroid ) {
      this.isAndroid = true;
      this.client = 2;
    }
    // this.videoPlay = new Player({
    //   id: "vip-video-1",
    //   poster: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/vip/enterprise_vip.mp4?x-oss-process=video/snapshot,t_5000,f_jpg,w_750', // 封面图
    //   url: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/vip/enterprise_vip.mp4',
    //   playsinline: true,
    //   "x5-video-player-type": "h5",
    //   "x5-video-player-fullscreen": "false",
    //   closeVideoClick: true,
    //   closeVideoTouch: true,
    //   autoplay: false, // 自动播放
    //   videoInit: false, // 显示首帧
    //   fluid: true, // 流式布局
    //   preload: true,
    //   // fitVideoSize: "fixWidth",
    //   ignores: ["volume"],
    // });
    this.curPackageChnage('svip');
  },
  methods: {
    getCurPackageName(){
      return this.curPackage;
    },
    getVipInfo(){
      const packages = this.$props.memberEnterpriseList.filter((item) => {
        return item.id == '10097'; // id是后端给的固定值
      })
      if (packages.length > 0) {
        return packages[0];
      } else {
        return null;
      }
    },
    getSVipInfo(){
      const packages = this.$props.memberEnterpriseList.filter((item) => {
        return item.id == '10105'; // id是后端给的固定值
      })
      if (packages.length > 0) {
        return packages[0];
      }
    },
    getCurPackage(){
      if (this.curPackage == 'vip') {
        return this.getVipInfo();
      }
      if (this.curPackage == 'svip') {
        return this.getSVipInfo();
      }
    },
    curPackageChnage(name){
      this.curPackage = name;
      this.$emit('packageChange', this.getCurPackage());
    },
    closeVideoPendant(){
      this.videoPendantVisible = false;
    },
    scrollEvent(top){
      if (this.$refs.video.getBoundingClientRect().y < window.screen.height/2 && window.isAndroid && !this.isPlaying) {
        this.isPlaying = true;
        this.videoPlay.autoplay = true;
        this.videoPlay.start();
        console.log('autoplay', this.videoPlay.autoplay)
      }
      if (this.$refs.video.getBoundingClientRect().y > window.screen.height || this.$refs.video.getBoundingClientRect().y < 0) {
        // this.videoPlay.pause();
      }
      // console.log(top)
    },
    /**
     * 去填写资料
     * @param status 支付状态，0：未支付 1：已支付
     */
    toInput (status) {
      if (!this.$props.agreement && status === 0) {
        Toast('请先勾选协议');
        return;
      }
      this.swRouter({
        path: `/vip/enterprise-form/${status}`,
      })
      // this.$router.push({
      //   name: 'enterpriseForm',
      //   params: {
      //     payStatus: status
      //   }
      // })
    },
    showVieo(url){
      this.multiMediaVisible = true;
      this.$refs.medioPreView.init(url);
    },
    toDetails () {
      this.swRouter({
        path: "/vip/enterprise-details",
      })
      // this.$router.push({
      //   name: 'enterpriseDetails'
      // })
    },
    onTapPay(){
      this.$emit('onTapPay')
    },
    checkAgreement() {
      this.$emit('update:agreement', !this.$props.agreement)
    },
    handleAgreement(){
      if (this.curPackage == 'svip') {
        this.$emit('agreementClick', 'enterpriseSvip');
      } else {
        this.$emit('agreementClick', 'enterprise');
      }
    },
    toAgreement(name){
      this.$emit('agreementClick',name)
    },
    toService(){
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "ONLINE_SERVICE",
        params: null,
      });
    },
    toggleMore(){
      if (this.privilegesAdditional.length > 0) {
        this.closeMore();
      } else {
        this.showMore();
      }
    },
    showMore(){
      this.privileges = this.privileges.concat(this.privilegesAdditional);
      this.privilegesAdditional = [];
    },
    closeMore(){
      this.privilegesAdditional = this.privileges.splice(5,4)
    },
    toPrivilege(){
      this.$refs.pointA.scrollIntoView();
      const el = document.getElementById('auto-view');
      el.scrollTop = el.scrollTop - 100;
    }
  },
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import '@/styles/_cell.scss';
//@import "@/styles/index.scss";

*{
  box-sizing: border-box;
}
.module{
  padding: vw(36) vw(30);
  background: #fff;
}
.foot-wrod{
  font-size: vw(22);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8893A7;
  line-height: vw(37);
  padding: vw(36) vw(30);
}
.to-right{
  transition: all 0.35s ease-out;
  //right: 0 !important;
}
.img-wrap{
  background: #fff;
  padding: vw(36) vw(29);
  margin-top: vw(20);
  position: relative;
  .privilege-wrap{
    .privilege{
      padding-top: vw(28);
      border-bottom: vw(1) solid #E7E7E7;
      &:last-of-type{
        border-bottom: none;
      }
    }
  }
  img{
    display: block;
    width: 100%;
  }
  .track{
    position: absolute;
    right: vw(29);
    top: vw(36);
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #347FFF;
    line-height: 100%;
    vertical-align: top;
    .van-icon{
      line-height: 100%;
      vertical-align: top;
    }
    &::after{
      //content: ' ';
      //position: absolute;
      //width: vw(10);
      //height: vw(21);
      //right: vw(-13);
      //top: vw(6);
      //display: block;
      //background: url('/static/vip/enterprise/icon-right-arry.png') no-repeat;
      //background-size: auto vw(21);
    }
  }
  .title-img{
    display: block;
    width: auto;
    height: vw(32);
  }
  .more{
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #347FFF;
    line-height: vw(33);
    text-align: center;
    padding-top: vw(33);
    img{
      display: inline-block;
      width: vw(22);
    }
  }
  .van-button{
    width: 100%;
    height: vw(80);
    background: #FFFFFF;
    border-radius: vw(10);
    border: vw(2) solid #347FFF;
    font-size: vw(30);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #347FFF;
    margin-top: vw(35);
  }
}
//.vip-card{
//  position: relative;
//  width: vw(690);
//  background: #DAEAFF;
//  border-radius: vw(10);
//  border: vw(3) solid rgba(52, 127, 255, 0.59);
//  .title{
//    font-size: vw(32);
//    font-weight: 600;
//    color: #1D2233;
//    line-height: vw(45);
//    padding-top: vw(22);
//    text-align: center;
//  }
//  .price{
//    font-size: vw(28);
//    color: #012D7A;
//    text-align: center;
//    line-height: vw(68);
//    b{
//      font-size: vw(48);
//    }
//  }
//  .track{
//    height: vw(40);
//    position: absolute;
//    top: vw(-20);
//    left: vw(-2);
//  }
//  .rights{
//      font-size: vw(22);
//      font-family: PingFangSC-Regular, PingFang SC;
//      font-weight: 400;
//      color: #012D7A;
//      line-height: vw(30);
//      text-align: center;
//  }
//  .save{
//    margin-top: vw(12);
//    font-size: vw(22);
//    font-weight: 500;
//    color: #012D7A;
//    line-height: vw(44);
//    text-align: center;
//    ins{
//      color: #347FFF;
//      text-decoration: none;
//      font-style: normal;
//      margin-left: vw(4);
//    }
//    background: rgba(52, 127, 255, 0.21);
//  }
//}

.vip-card,.svip-card{
  position: relative;
  width: 160px;
  background: #F2F2F2;
  border-radius: vw(10);
  border: 1px solid #E7E7E7;
  .title{
    font-size: vw(32);
    font-weight: 600;
    color: #666666;
    line-height: vw(45);
    padding-top: vw(22);
    text-align: center;
  }
  .price{
    font-size: vw(28);
    color: #012D7A;
    text-align: center;
    line-height: vw(68);
    b{
      font-size: vw(48);
    }
  }
  .track{
    height: vw(40);
    position: absolute;
    top: vw(-20);
    left: vw(-2);
  }
  .rights{
    font-size: vw(22);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #012D7A;
    line-height: vw(30);
    text-align: center;
  }
  .save{
    margin-top: vw(12);
    font-size: vw(22);
    font-weight: 500;
    background: #E7E7E7;
    line-height: 15px;
    text-align: center;
    padding: 5px 0;
    color: #666666;
    ins{
      color: #347FFF;
      text-decoration: none;
      font-style: normal;
      margin-left: vw(4);
    }
  }
  &.active{
    background: #DAEAFF;
    border: 1px solid rgba(52, 127, 255, 0.59);
    .title{
      color: #333333;
    }
    .save{
      color: #012D7A;
      background: rgba(52,127,255,0.21);
    }
  }
}
.svip-card{
  //position: relative;
  width: 100%;
  //background: #DAEAFF;
  border-radius: vw(10);
  //border: vw(3) solid rgba(52, 127, 255, 0.59);
  //margin-left: 5%;
  .price b{
    position: relative;
    font-size: vw(48);
  }
  .title{
    span {
      position: relative;
      &::before{
        position: absolute;
        top: 50%;
        left: vw(-40);
        transform: translateY(-50%);
        content: ' ';
        display: inline-block;
        width: vw(31);
        height: vw(30);
        background: url("/static/vip/enterprise/svip/svip-icon.png") no-repeat;
        background-size: 100% auto;
      }
    }
  }
}

.agreement{
  position: relative;
  font-weight: 400;
  color: #999999;
  line-height: vw(30);
  padding-top: vw(20);
  font-size: 0;
  &::before{
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: vw(100);
    height: vw(100);
    z-index: 10;
  }
  img{
    //vertical-align: top;
    width: vw(28);
    vertical-align: middle;
  }
  span{
    line-height: vw(40);
    vertical-align: middle;
    font-size: vw(22);
    margin-left: vw(8);
    b{
      font-weight: 400;
      color: $c-main;
      line-height: 100%;
    }
  }
}
.package-card{
  position: relative;
  width: 100%;
  height: vw(338);
  margin-top: vw(30);
  padding: vw(24) vw(30);
  background: url('/static/vip/img-card-bg.png') no-repeat;
  background-size: 100% auto;
  font-size: vw(26);
  font-weight: 400;
  color: #666666;
  p{
    margin-top: vw(14);
    line-height: vw(30);
  }
  ins{
    font-size: vw(26);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    font-style: normal;
    text-decoration: none;
  }
  .title{
    height: vw(42);
    font-size: vw(30);
    font-weight: 600;
    color: #333333;
    line-height: vw(42);
  }
  .account{
    margin-top: vw(24);
  }
  .date{

  }
  .code-key{
    margin-left: vw(80);
  }
  .city{
    margin-top: vw(55);
  }
  .input-btn{
    position: absolute;
    right: vw(30);
    bottom: vw(30);
    width: auto;
    height: vw(60);
    border: 0;
    background: linear-gradient(270deg, #347FFF 0%, #62AFFF 100%);
    border-radius: vw(10);
    font-size: vw(24);
    font-weight: 500;
    color: #FFFFFF;
    line-height: vw(33);
    z-index: 10;
    padding-left: vw(18);
    padding-right: vw(18);
  }
  .details-btn{
    position: absolute;
    right: vw(30);
    bottom: vw(30);
    height: vw(60);
    background: #FFFFFF;
    border-radius: vw(10);
    border: vw(2) solid #347FFF;
    z-index: 10;
    font-size: vw(24);
    font-weight: 500;
    color: #347FFF;
    line-height: vw(33);
  }
}
.pay-btn{
  padding-left: vw(24);
  .van-button{
    background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
    border-radius: vw(10);
    border: 0;
    width: 100%;
  }
}
.video{
  position: fixed;
  right: vw(12);
  width: vw(150);
  display: block;
  top: 60%;
  transform: translateY(-50%);
  z-index: 10;
  border-radius: vw(6);
  overflow: hidden;
  box-shadow: 0 0 vw(15) vw(1) rgba(0,0,0,0.1);
  img{
    width: 100%;
    position: relative;
  }
  .close-video-pendant{
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    display: block;
    border-radius: 0 0 0 vw(25);
    background: rgba(0,0,0,0.3);
    width: vw(50);
    height: vw(50);
    color:#fff;
    text-align: center;
    font-size: 0;
    .van-icon{
      font-weight: bold;
      font-size: vw(24);
      line-height: vw(50);
    }
  }
}
.server{
  position: fixed;
  right: vw(12);
  width: vw(104);
  display: block;
  top: 80%;
  z-index: 100;
  img{
    width: 100%;
    position: relative;
    left: vw(0)
  }
}
.module-title{
  font-size: vw(24);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: vw(33);
  margin-bottom: vw(30);
}
.video-wrap{
  width: 100%;
  padding: vw(36) vw(30);
  >div{
    width: 100%;
    height: vw(387);
    background: #eee;
  }
}
.xsth-wrap{
  background: #fff;
  .timer{
    width: 100%;
    height: vw(70);
    background: url("/static/vip/enterprise/img-bg-xsth.png") no-repeat;
    background-size: 100%;
    font-size: vw(26);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: vw(70);
    text-align: right;
    padding-right: vw(12);
    span{
      background: #FFFAF7;
      box-shadow: 0px 0px vw(8) 0px rgba(255, 89, 0, 0.41);
      border-radius: vw(5);
      padding: vw(6) vw(9);
      font-size: vw(24);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #743F00;
      line-height: vw(33);
      margin: 0 vw(12);
    }
  }
  .text{
    padding: vw(16);
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: vw(33);
    text-shadow: 0px vw(-2) vw(6) rgba(34, 34, 34, 0.12);
    b{
      color: #1D2233;
    }
    i{
      font-size: vw(24);
      color: #FF5900;
      font-style: normal;
    }
    ins{
      font-size: vw(36);
      color: #FF5900;
      font-style: normal;
      text-decoration: none;
    }
    .orange{
      color: #FF5900
    }
    p{
      line-height: vw(40);
    }
  }
  .van-button{
    width: 100%;
    height: vw(80);
    background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
    box-shadow: 0px vw(-2) vw(6) 0px rgba(34, 34, 34, 0.12);
    border-radius: vw(10);
    color:#fff;
  }
}
.no-margin{
  margin-top: 0;
}
.no-padding{
  padding: 0;
}
.package-card{
  .price{
    b{
      font-size: vw(26);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #347FFF;
    }
    i{
      font-style: normal;
      font-size: vw(32);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #347FFF;
    }
  }
}
.pay-btn-wrap{
  margin-top: vw(26);
  .company-pay{
    //border: none;
  }
}
</style>
