<template>
	<div class="intention-form">
		<van-popup v-model="submitMaskType" position="bottom" :style="{ height: '38%' }" @close="close">
			<div class="body-box">
				<div class="title">
					<div class="left">我有意向</div>
					<div class="right">专业铺参谋为你匹配铺源，联系租赁</div>
				</div>
				<div class="form-box">
					<div class="form-item">
						<div class="label">姓名</div>
						<div class="value"><input type="text" v-model="form.name" placeholder="请输入姓名"></div>
					</div>
					<div class="form-item">
						<div class="label">手机号</div>
						<div class="value"><input type="tel" maxlength="11" v-model="form.phone" placeholder="请输入手机号">
						</div>
					</div>
				</div>
				<div class="bottom-box">提交意向后，铺参谋将会第一时间与你联系，请耐心等待</div>
				<div class="mask-button">
					<div class="button" @click="maskSubmit">提交意向</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				submitMaskType: true,
				form: {
					name: '',
					phone: '',
					type: 3, //服务类型：0商铺评估 1铺位推荐 2铺位转让 3我有意向  4我要咨询"
					city: ''
				},
			}
		},
		mounted() {
			this.JSBridge.callHandler('getLocation', null, (data) => {
				if (typeof data === 'string') data = JSON.parse(data);
				this.form.city = data ? data.province + data.city : ''
				// console.log(data.adCode) //440305
				// console.log(data.city + data.district) //深圳市南山区
				// console.log([data.city, data.district].join('-')) //深圳市-南山区
				// console.log(data.address) //详细地址
			});
		},
		methods: {
			maskSubmit() {
				const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (!this.form.name) {
					this.$toast.fail('请填写姓名');
				} else if (this.getByteLength(this.form.name) > 14) {
					this.$toast.fail('名称太长，请重新输入');
				} else if (!this.form.phone) {
					this.$toast.fail('请填写手机号码');
				} else if (!phoneReg.test(this.form.phone)) {
					this.$toast.fail('请填写正确手机号码');
				} else {
					this.$emit('submit', this.form)
				}
			},
			close() {
				this.$emit('close')
			},
			getByteLength(str = '') {
				let len = 0;
				for (let i = 0; i < str.length; i++) {
					if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
						len += 2;
					} else {
						len++;
					}
				}
				return len;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.intention-form {
		// @import '@/styles/index.scss';

		@function vw($args) {
			@return $args/750*100+vw;
		}

		.body-box {
			padding: vw(30);
			box-sizing: border-box;

			.title {
				height: vw(48);
				font-size: vw(34);
				font-weight: 600;
				line-height: vw(48);
				text-align: center;
				color: #1D2233;
				display: flex;
				justify-content: space-between;

				.right {
					font-size: vw(26);
					font-weight: 400;
					color: #999999;
				}
			}

			.form-box {
				.form-item {
					margin-bottom: vw(10);

					.label {
						height: vw(72);
						font-size: vw(30);
						font-weight: 600;
						color: #121622;
						line-height: vw(72);
					}

					.value {
						height: vw(80);
						border-radius: vw(10);

						input {
							border: none;
							height: 100%;
							width: 100%;
							background: #F7F8FB;
							font-size: vw(28);
							color: #1D2233;
							padding: 0 vw(30);
							box-sizing: border-box;
						}
					}
				}
			}

			.bottom-box {
				height: vw(37);
				font-size: vw(26);
				color: #999999;
				line-height: vw(37);
				margin-top: vw(56);
			}

			.mask-button {
				padding: vw(16) vw(0);
				box-sizing: border-box;

				.button {
					height: vw(80);
					background: #347FFF;
					border-radius: vw(10);
					font-size: vw(30);
					font-weight: 600;
					color: #FFFFFF;
					line-height: vw(80);
					text-align: center;
				}
			}

		}
	}
</style>
