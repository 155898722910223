<template>
  <div class="about">
    <StateBar></StateBar>
    <TitleHeader title="介绍" bgcolor="transparent" :back="showBack"></TitleHeader>

    <AutoView :header="true" :footer="false" class="about-view">
      <van-image class="img" fit="cover" v-for="(item, index) in imgList" :key="index" :src="item" />
    </AutoView>
  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      showBack: true,
      imgList: [
        "/static/img/about/about_001.png",
        "/static/img/about/about_002.png",
        "/static/img/about/about_003.png",
        "/static/img/about/about_004.png",
        "/static/img/about/about_005.png",
        "/static/img/about/about_006.png",
        "/static/img/about/about_007.png",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.about {
  position: relative;
  min-height: 100vh;
  background-color: $c-pageBg2;
  .about-view {
    scroll-behavior: smooth;
    .img {
      display: block;
      min-height: 50vw;
    }
  }
}
</style>
