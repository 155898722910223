import axios from "axios";
import Vue from "vue";
import store from "@/store";
import { reportEvent } from '@/utils/aegis'
var CryptoJS = require("crypto-js");
import discoverHttp from "./discover";
import communityHttp from "./community"
import pointRadarHttp from "./pointRadar";
import brandAllianceHttp from "./brandAlliance"
import dataQueryHttp from './dataQuery'
import { Toast } from 'vant'
import UniUtils from '@/utils/uni'

// const host = '/api';
let host = "//appv2.sscanmou.com/app"; // 加密接口地址
let rept = "//admin.sscanmou.com/report-xx";
if (process.env.NODE_ENV !== "production") {
  // 开发
  rept = "//huawei-test-admin.sscanmou.com/report-xx";
  // rept = '//10.1.2.186:9003';
  host = "https://huawei-test-appv2.sscanmou.com/app";
}

const MAPPING = {
  allCity: "/v1/data/public/allCity",
  v2allCity: "/v2/data/public/allCity",
  authCode: "/v1/user/sendAuthCodeSms",
  billList: "/v1/invoice/orderPage",
  brandBannerList: "/v1/joinBanner/list",
  brandCityList: "/v1/joinBrandCity/list",
  brandHotList: "/v1/joinBrand/hotRecommend",
  brandList: "/v1/joinBrand/hotCategory",
  brandReco: "/v1/joinBrand/hotCategory",
  brandList2: "/v1/joinBrand/getHotRecommend",
  // brandList2: "/v2/joinBrand/appPage",

  brandCategory: "/v1/joinBrand/getFormatCategory",
  brandRecommList: "/v1/joinBrand/recommendList",
  brandJoin: "/v1/order/business/brandJoin",
  listAllQuestionOfBrandJoin: "/v1/question/listAllQuestionOfBrandJoin",
  captcha: "/v1/user/sendLoginSms",
  verification: "/v1/user/sendLoginSmsV3",
  cashoutList: "/v1/partner/expenses/list",
  chargeList: "/v1/account/record/page",
  checkout: "/v1/order/settlement",
  checkout2: "/v2/order/settlement",
  checkPackage: "/v1/order/place/pkg/check",
  checkViewInfo: "/v1/report/marketing/viewInfo",
  couponList: "/v1/coupon/page",
  couponRule: "/v1/coupon/actions",
  createBrand: "/v1/joinIntention/add",
  createBusiness: "/v1/order/place",
  createCard: "/v1/order/place/card",
  createCashout: "/v1/partner/apply/expenses",
  createCharge: "/v1/order/place/recharge",
  createDataQuary: "/v1/order/business/dataQuery",
  createDownload: "/v1/download/record/add",
  createFeedback: "/v1/feedback",
  goodsFeedback: "/v1/joinBrand/feedback",
  createInvoice: "/v1/invoice/makeInvoice",
  createOrder: "/v1/order/place/report",
  createPackage: "/v1/order/place/pgk",
  createQueation: "/v1/question/custom",
  createReport: "/v1/question/add",
  createReportSP: "/v1/report/marketing/see",
  createShopSub: "/v1/order/business/shop",
  createVip: "/v1/order/place/member",
  createMap: "/v1/order/place/goods",
  discoveryList: "/v1/discovery/list",
  formatCategory: "/v1/data/public/formatCategory",
  getArticle: "/v1/index/article",
  getCourseInfo: "/v1/question/getCourseDetailById",
  getAssessData: "/v2/storeEvaluate/getEvaluateHistoryDetail",
  getAssessDesc: "/v2/storeEvaluate/description",
  getAssessInfo: "/v2/storeEvaluate/getStoreInfo",
  getAssessList: "/v2/storeEvaluate/getEvaluateHistory",
  submitAssess: "/v2/storeEvaluate/submitStoreInfo",
  getAssessOption: "/v1/storeEvaluate/item/list",
  getAssessParams: "/v1/storeEvaluate/getUserParam",
  getAssessResult: "/v1/storeEvaluate/getResult",
  getAssessTarget: "/v2/storeEvaluate/getStoreEvaluateParam",
  getBalance: "/v1/account/balance",
  getBrand: "/v1/joinBrand/detail",
  getBrand2: "/v2/joinBrand/detail",
  getInviteDetail: "/v1/user/getInviteDetail",
  getPartnerInfo: "/v1/partner/checkPartner",
  getGoodsShareList: "/v1/partner/getGoodsShareList",
  getQuestion: "/v1/question/showQuestion",
  getQuestionInfo: "/v1/question/stdQuestionCnf",
  listAllQuestionOfShop: "/v1/question/listAllQuestionOfShop",
  getReply: "/v1/question/custom/reply",
  getReport: "/v1/report/overview",
  getReportData: "/v6/report/full",
  getReportShare: "/v1/report/instance/shareDetail",
  getReportName: "/v1/report/instance",
  getShopData: "/v1/shop/subscribed/detail",
  getShopDetail: "/v1/shop/detail/aggregation",
  getShowUpData: "/v1/data/elbs/secondFloor",
  getUserInfo: "/v1/user/detail",
  getVipInfo: "/v1/member/info",
  goodsList: "/v1/account/recharge/goods/list",
  homeData: "/v1/index/all",
  hotspotList: "/v1/industryReport/page",
  incomeList: "/v1/partner/commission/list",
  inviteList: "/v1/user/inviteList",
  inviteRegister: "/v1/user/inviteRegister",
  invoiceList: "/v1/invoice/page",
  markerAllRead: "/v1/user/msg/check/batch",
  markerRead: "/v1/user/msg/check",
  messageList: "/v1/user/msg/list",
  openCity: "/v1/data/public/openCity",
  v2openCity: "/v2/data/public/openCity",
  openMarketCity: "/v1/data/public/openMarketCity",
  orderCheck: "/v1/order/place/coupon/check",
  orderList: "/v1/order/page",
  goodsComment: "/v1/marketServiceStdQuestion/saveComment",
  courseList: "/v1/order/course/list",
  orderStatus: "/v1/order",
  packageHistory: "/v1/question/user/stdQuestionPkg/order/page",
  packageList: "/v1/question/user/stdQuestionPkg/page",
  elePackageList: "/v1/order/pageEleme",
  payment: "/v1/order/pay",
  questionList: "/v1/question/custom/list",
  registerPantner: "/v1/partner/registerPartner",
  reportList: "/v1/report/instance/list",
  reportPV: "/v1/user/invitePageView",
  reportSave: "/v1/report/user/save",
  savePartnerInfo: "/v1/partner/save/payInfo",
  searchList: "/v1/question/association",
  searchResult: "/v1/question/search",
  shopList: "/v1/shop/page",
  subShopList: "/v1/shop/subscribed/page",
  recommendShops: "/v1/shop/recommends",
  takeCoins: "/v1/member/take/sscm/coin",
  topicList: "/v1/article/column/getColumnArticles",
  useBenefit: "/v1/user/benefit/consume",
  appStore: "https://a.app.qq.com/o/simple.jsp?pkgname=com.shuwei.sscm",
  iosAppStore: "https://apps.apple.com/cn/app/%E4%B8%8A%E4%B8%8A%E5%8F%82%E8%B0%8B/id1524795113",
  getCourseDetail: "/v1/question/getCourseDetailById",
  shareCourse: "/v1/question/shareCourse",
  inviteQRCode: "/v1/partner/inviteQrcode",
  partnerConfig: "/v1/partner/config",
  partnerTotal: "/v1/partner/getPartnerTotal",
  invitationList: "/v1/partner/myPartner",
  inviteUserInfo: "/v1/user/getInviteUserInfo",
  getStdQuestionCnf: "/v1/question/stdQuestionCnf",
  getListAllQuestion: "/v1/question/listAllQuestionOfOneByOne",
  getOrderServer: "/v1/order/business/orderServer",
  getlistVoucher: "/v1/order/listVoucher",
  getActiveCoupon: "/v1/coupon/getActive",
  receiveCoupon: "/v1/coupon/receive",
  contrastList: "/v1/joinBrand/list",
  comparisonAndAnalysis: "/v1/joinBrand/brandCompare",
  listGoodsPkg: "/v4/question/settlement",
  industry: "/v2/joinBrand/getCategory",
  cityList: "/v1/data/public/listCity",
  getSigninDetail: "/v1/coupon/active/detail",
  sign: "/v1/coupon/active/sign",
  questionAnswerPage: "/v6/question/questionAnswerPage",
  myQuestionAnswerPage: "/v6/question/myQuestionAnswerPage",
  paySuccess: "/v2/order/paySuccess",
  updateQuestionnaire: "/v1/user/updateQuestionnaire",
  updatePersonalInfo: "/v1/user/updatePersonalInfo",
  getQuestionnaireById: "/v1/user/getQuestionnaireById",
  getPersonalInfo: "/v1/user/getPersonalInfo",
  listOrderPayWithPackage: "/v1/order/listOrderPayWithPackage",
  getBrandInfo: "/v1/brand/index/config",
  searchBrandByName: "/v1/joinBrand/listConditionByName",
  listHotRank: "/v1/joinBrand/v1/join/brandHot/listHotRank",
  getVideoContent: "/v1/resource/getVideoContent",
  getCategoryResource: "/v1/resource/getCategoryResource",
  getResourceById: "/v1/resource/getResourceById",
  getEvaluate: "/v1/comment/page",
  addEvaluate: "/v1/comment/add",
  addCollect: "/v1/collect/add",
  cancelCollect: "/v1/collect/cancel",
  tubatuUpload: "/v1/cooperation/tubatu/upload",
  upload: "/v1/user/visit/submit",
  huaxiaUpload: "/v1/cooperation/huaXia/upload",
  batchReceive: "/v1/coupon/batchReceive",
  getActiveInfo: "/v1/coupon/getActive",
  getModuleVipInfo: "/v1/member/listByType/",
  selectAd: "/v1/adConfig/selectAd",
  reportSubmit: "/v6/report/submit",
  getIsPayUser: "/v2/order/isPayUser",
  getPollingData: "/v21/index/ubroadcasts",
  perfectInformation: "/v6/user/saveSaasInfo",
  getInformation: "/v6/user/searchDetail",
  getGoodsDetail: "/v10/question/stdQuestionCnf/detail", // 获取商品详情

  // 地图标注业务
  mapLabelingSave: "/v1/mapLabeling/saveOrUpdate", // 新增修改地图标注业务
  getMapGoods: "/v1/mapLabeling/list/map/goods", // 查地图标注所有商品
  getMyList: "/v1/mapLabeling/list/myList", // 查看个人进度列表

  // 饿了么登录授权
  handleLogin: "/v1/eleme/handleLogin",
  getShopMarketList: "/v6/report/shopMarket/page", // 获取商场查询列表
  getShopMarketSearch: "/v2/report/shopMarket/search", // 获取商场查询条件
  getProcess: "/v1/report/get/process?reportInstanceId=", // 获取生成报告进度
  addSitingReport: "/v10/question/add", // 饿了么生成选址评估报告
  getReportInfo: "v4/user/detail", // 获取报告数量
  getPackageInfo: "v3/report/config", // 获取套餐包信息
  getCityList: "/v2/data/public/openCityWithWord", // 获取城市列表
  elemeSubmit: "v1/user/visit/submit", // 饿了么项目埋点

  // 社区
  getLatest: "/v1/resource/page",
  getDrgGoods: "/v1/resource/articleData",
  getDrgGoodsList: "/v1/resource/articleCategory",
  getArticleCategoryDetail: "/v1/resource/articleCategoryDetail",
  collectionAll: "/v1/collect/page",
  collectionDel: "/v1/collect/delete",
  listUserMsgInfo: "/v1/user/msg/listUserMsgInfo",
  listUserMsgInfoV316: "/v316/message/listUserMsgInfo ",
  getSettingByType: "/v1/user/setting/getSettingByType",
  updateSetting: "/v1/user/setting/updateSetting",
  readUserMsg: "/v1/user/msg/readUserMsg",

  // 新品牌详情
  myCommonts: "/v1/brand/comment/myComment", //我的评论
  commontsDetail: "/v1/brand/comment/detail", //评论详情
  addCommonts: "/v1/brand/comment/add", //新增详情
  allCommonts: "/v1/brand/comment/appPage", //所有评论
  giveLike: "/v1/brand/comment/nice", //评论点赞
  commontsTabs: "/v1/brand/comment/getTagCount", //评论标签
  brandJoinApply: "/v1/joinBrand/apply", //品牌合作申请

  // 查周边新、查城市、查商场保存与下载接口
  saveReport: "/v1/report/user/saveReport", // 保存用户报告
  downloadCheck: "/v1/report/pdf/downloadCheck", // 下载检查pdf生成接口
  listDataQuery: "/v1/report/listDataQuery", // 原 我的报告-周边数据 改为 我的报告-数据查询，此为新的数据查询接口

  //
  loginDownLoad: "/v1/user/login", //抖音推广下载注册页->跳转至活动

  // 开店必看榜单
  waiMaiPage: "/v1/rank/waiMaiPage", // 外卖榜单
  shopSaleList: "/v1/report/waimai/shopSaleList",

  searchBrand: "/v1/brand/page",

  searchBrandReport: "/v1/brand/detail",
  allDpPage: "/v2/brand/dpPage",
  brandReportDetail: "/v1/brand/province/detail",
  checkBrandStatus: "/v1/brand/check",

  shopCollectDelete: "/v1/shop/collect/delete",// 删除店铺接口
  transferLast: '/v3/order/transfer/last', // 获取凭证
  shopCollectMylist: "/v1/shop/collect/myList",// 我的铺位列表接口
  shopCollectDatail: "/v1/shop/collect/detail",//采集的店铺详情接口

  //sem
  saveSemAd: '/v1/user/visit/sem',
  semLogin: '/v1/user/external/semLogin',
  getQRCode: '/v1/user/visit/imageCode',
  getVersionInfo: '/v1/user/visit/launchVersionLatest',

  //商场报告详情
  mallReportDetail: '/v6/report/query/mall',
  sendLoginSmsV2: '/v1/user/external/sendLoginSmsV2',
  sendLoginSmsV2Bd: '/v1/user/external/sendLoginSmsV2/bd',
  login: '/v1/user/external/login',
  'track': '/v1/user/visit/external/submit',
  'trackforBtn': '/v1/user/visit/external/click/submit',
  saveSaasInfo: '/v1/enterprise/saas/save',
  submitTransfer: '/v3/order/transfer/submit',
  getSaasInfo: '/v1/enterprise/saas/info',
  getMemberTab: '/v1/member/getMemberTab',
  payOrderInfo: '/v1/shop/intentionSquare/payOrderInfo',
  orderDetail: '/v1/shop/intentionSquare/orderDetail',
  getUrlLink: '/v1/shopSquare/getWeChatAppletPlatformShopDetailLink',
  addQuestion: '/v6/question/addQuestion',
  queryReportUpdateDate: '/v1/dataResourceTime/list',

  captchaValidate: '/v1/captcha/validate', // 接口安全校验

};

const PAGE_ROW = 10; // 全局每页记录数
const REQ_RETRY = 5; // 请求重试次数
const REQ_DELAY = 1000; // 请求重试间隔ms
const TIMEOUT = 30000; // 请求超时时间ms

// 创建实例
axios.defaults.isSetAuth = false; // 是否设置授权信息
export const Axios = axios.create({
  baseURL: host,
  timeout: TIMEOUT,
  retry: REQ_RETRY, // 未实现
  retryDelay: REQ_DELAY, // 未实现
  headers: {
    // 'Content-Type': 'application/json;charset=UTF-8',
  }
});

// 请求拦截
Axios.interceptors.request.use(
  request => {
    // 未设置请求头则延迟请求
    if (!Axios.defaults.isSetAuth) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(function () {
        if (Axios.defaults.isSetAuth) {
          request.headers.Authorization = Axios.defaults.headers.Authorization;
        }
        return request;
      });
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);
let routeTimer = -1
// 响应拦截
Axios.interceptors.response.use(
  response => {
    // console.log( 'Axios response data:', response.data );
    if (typeof response.data === "string") {
      if (response.data.length > 0) {
        if (response.data[0] === "{") {
          const output = JSON.parse(response.data);
          response.data = output;
        } else {
          // 新增AES解密
          const key = CryptoJS.enc.Utf8.parse("Ds3o00ra43ym9X8s");
          const bytes = CryptoJS.AES.decrypt(response.data, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          const outputStr = bytes.toString(CryptoJS.enc.Utf8);
          const output = JSON.parse(outputStr);
          // console.log( 'Axios response output:', output );
          response.data = output;
        }
      }
    }
    return response;
  },
  error => {
    // console.log( 'Axios response error data:', error.response.data, ', status:', error.response.status )
    if (typeof error.response.data === "string") {
      if (error.response.data.length > 0) {
        if (error.response.data[0] === "{") {
          error.response.data = JSON.parse(error.response.data);
        } else {
          // 新增AES解密
          const key = CryptoJS.enc.Utf8.parse("Ds3o00ra43ym9X8s");
          const bytes = CryptoJS.AES.decrypt(error.response.data, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          const outputStr = bytes.toString(CryptoJS.enc.Utf8);
          const output = JSON.parse(outputStr);
          error.response.data = output;
        }
      }
    }
    // 接口安全检测
    if (error.response?.data?.code == 201067) {
      store.commit('setApiConfigList',error.config)
      store.commit('setApiValidate',true)
      store.commit('setValidatePopup', false)
      return new Promise((resolve, reject) => {
        const t = setInterval(() => {
          if (store.getters.isCloseValidatePopup) {
            clearInterval(t)
          }
          if (!store.getters.apiValidating) {
            clearInterval(t)
            resolve();
          }
        },100)
      }).then(res => {
        return Axios.request(error.config)
      })
    }

    console.log(error)
    const content = {
      name: 'Axios请求失败',
      ext1: JSON.stringify({
        headers: error.response.config.headers,
        data: error.response.data,
        request: error.response.request,
        code: error.response.status,
        params: error.config.data
      })
    }
    const code = error.response.status;
    if (code === 400) {
      Toast(error.response.data.msg)
      content.name = 'Axios请求失败-400'
      reportEvent(content)
      // 请求失败
      return Promise.reject(error.response.data);
    } else if (code === 401) {
      content.name = 'Axios请求失败-401'
      reportEvent(content)
      if (window.isClient) {
        // 未登录或登录超时
        new Vue().$bus.emit("sscm-relogin", error.response.data || null); // 通过消息总线发送重登通知
      } else if (window.isMini) {
        clearTimeout(routeTimer)
        routeTimer = setTimeout(() => {
          UniUtils.goLogin()
        }, 500)
      }

    } else {
      content.name = 'Axios请求失败-其它状态码'
      reportEvent(content)
      console.warn(error);
    }
    return Promise.reject(error);
  }
);

// 设置全局授权请求头
const setAuth = token => {
  const headers = Axios.defaults.headers;
  // 开发环境发送模拟用户ID
  // if ( develop ) headers[ 'mock-user' ] = '{"id": 1}';
  if (typeof token === "string") {
    store.commit("setToken", token);
    Axios.defaults.isSetAuth = true;
    headers["Authorization"] = token;
  } else {
    Axios.defaults.isSetAuth = false;
    delete headers["Authorization"];
  }
  Axios.defaults.headers = headers;
};

/**
 * API Export
 * Last 20201021
 */
const GET = (url, data) => {
  return new Promise((resolve, reject) => {
    Axios.get(url)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const POST = (url, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(url, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

/**
 * API List
 * Remark ※表示需登录接口
 * Last 20200417
 */

// 查询提现记录
const cashoutList = (marker, order, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.cashoutList, {
      marker,
      order: order || "after",
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取充值记录列表
const chargeList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.chargeList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 接口安全校验
const captchaValidate = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.captchaValidate, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 完善信息
const perfectInformation = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.perfectInformation, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取用户填写的信息
const getInformation = type => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getInformation + "?type=" + type)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取商品详情
const getGoodsDetail = (id) => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getGoodsDetail + "/" + id)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 获取订单信息
const getPayOrderInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.payOrderInfo)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 获取订单详情
const getOrderDetail = (id) => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.orderDetail + '/' + id)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 获取分享小程序link
const getUrlLink = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getUrlLink, data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 获取APP商城链接
const getAppStore = () => {
  if (window.isIOS) {
    return MAPPING.iosAppStore;
  } else {
    return MAPPING.appStore;
  }
};
// 获取品牌对比数据
const contrastList = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.contrastList)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获取文章详情
const getArticle = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getArticle + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获取文章详情
const getCourseInfo = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getCourseInfo + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const submitAssess = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.submitAssess, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
}
// 获取评估记录列表
const getAssessList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getAssessList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 提交问题
const addQuestion = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.addQuestion, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取店铺评估选项列表
const getAssessOption = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getAssessOption)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取模块会员信息
const getModuleVipInfo = type => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getModuleVipInfo + "/" + type)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取广告配置
const selectAd = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.selectAd, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 提交数据生成报告
const reportSubmit = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.reportSubmit, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 饿了么登录授权
const handleLogin = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.handleLogin, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取商场查询列表
const getShopMarketList = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getShopMarketList, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取商场查询条件
const getShopMarketSearch = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getShopMarketSearch)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取生成报告进度
const getProcess = reportId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getProcess + reportId)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 生成选址评估报告
const addSitingReport = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.addSitingReport, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取报告信息
const getReportInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getReportInfo)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取套餐包信息
const getPackageInfo = goodsId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getPackageInfo + "/" + goodsId)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取城市列表
const getCityList = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getCityList)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 饿了么埋点上报
const elemeSubmit = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.elemeSubmit, {
      deviceType: 3,
      version: "1.0.0",
      ...data
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取年终活动是否为付费用户
const getIsPayUser = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getIsPayUser)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取会员播报数据
const getPollingData = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getPollingData)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取店铺评估参数
const getAssessParams = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getAssessParams)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取店铺评估结果
const getAssessResult = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getAssessResult, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取店铺指标数据
const getAssessTarget = (moduleId, targetCode) => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getAssessTarget + "/" + moduleId + "/" + targetCode)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取账户余额
const getBalance = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getBalance)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 用户进入页面信息上报
const getMessageUpload = upload => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.upload, upload)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取品牌信息
const getBrand = (brandCode, longitude = null, latitude = null) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getBrand, {
      brandCode,
      longitude,
      latitude
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获取品牌信息
const getBrand2 = (brandCode, longitude = null, latitude = null) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getBrand2, {
      brandCode,
      longitude,
      latitude
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 创建品牌加盟业务
const brandJoin = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandJoin, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获取品牌加盟
const listAllQuestionOfBrandJoin = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.listAllQuestionOfBrandJoin)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 首页数据
const getHomeData = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.homeData)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取提问回复
const getReply = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getReply + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取报告摘要
const getReport = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getReport + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取报告详情
const getReportData = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getReportData + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取商场报告详情
const getMallReportDetail = code => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.mallReportDetail, { code })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
}

// 获取分享报告详情
const getReportShare = token => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getReportShare, {
      shareCode: token
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取报告名称
const getReportName = data => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getReportName + "/" + data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 获取报告地址
const getReportUrl = id => {
  return rept + "/?id=" + (id || "");
};

// 获取小区报告地址
const getCommunityReportUrl = () => {
  return rept;
};

// 获取店铺数据详情
const getShopData = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getShopData + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const getShopDetail = openId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getShopDetail + "/" + openId)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取彩蛋页数据
const getShowUpData = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getShowUpData)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取指标描述信息
const getAssessDesc = code => {
  return new Promise((resolve, reject) => {
    Axios.get(`${MAPPING.getAssessDesc}/${code}`)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取店铺信息 第二选填参数 版本号
const getAssessInfo = (id, history = null) => {
  return new Promise((resolve, reject) => {
    const URL = history ? `${MAPPING.getAssessData}/${history}` : `${MAPPING.getAssessInfo}/${id}`;
    Axios.get(URL)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取专题数据列表
const topicList = (id, marker, order, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.topicList, {
      columnId: id,
      order: order || "after",
      marker,
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 使用用户权益
const useBenefit = benefitKey => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.useBenefit, {
      benefitKey
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取充值列表
const goodsList = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.goodsList)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 全部标记已读
const markerAllRead = () => {
  return new Promise((resolve, reject) => {
    Axios.put(MAPPING.markerAllRead)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 标记已读
const markerRead = id => {
  return new Promise((resolve, reject) => {
    Axios.put(MAPPING.markerRead, {
      id
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取消息列表
const messageList = (marker, order, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.messageList, {
      marker,
      order: order || "after",
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询账单列表
const billList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.billList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 土巴兔合作信息上报
const tubatuUpload = uploadData => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.tubatuUpload, uploadData)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 华夏银行信息上报
const huaxiaUpload = uploadData => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.huaxiaUpload, uploadData)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询品牌轮播列表
const brandBannerList = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.brandBannerList)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取品牌开放城市列表
const brandCityList = brandCode => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandCityList, {
      brandCode
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询品牌分类列表
const brandList = (categoryCode = false, page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    const url = categoryCode ? MAPPING.brandList : MAPPING.brandHotList;
    Axios.post(url, {
      current: page,
      size: row,
      categoryCode
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询品牌分类列表2
const brandList2 = (params, page, row = PAGE_ROW) => {
  if (page) params.current = page;
  if (row) params.size = row;
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandList2, params)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 查询品牌
const brandReco = (params, page, row = PAGE_ROW) => {
  if (page) params.current = page;
  if (row) params.size = 6;
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandReco, params)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询品牌分类列表
const brandCategory = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.brandCategory)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 开放地区列表
const cityList = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.cityList)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 查询品牌推荐列表
const brandRecommList = (form = {}) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandRecommList, form)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 品牌推荐意向行业
const industry = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.industry)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 检出
const checkout = (id, orderNo, pageId, form = null) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.checkout, {
      businessId: id,
      orderNo,
      pageId,
      ...form
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 检出
const checkout2 = (id, orderNo, pkgGoodsId, pageId) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.checkout2, {
      businessId: id,
      orderNo,
      pkgGoodsId,
      pageId
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 检查套餐包状态
const checkPackage = (reportInstanceId, orderNo) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.checkPackage, {
      reportInstanceId,
      orderNo
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 检查可用查看次数
const checkViewInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.checkViewInfo)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 优惠券列表
const couponList = (page, status) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.couponList, {
      current: page,
      size: PAGE_ROW,
      status: status
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 优惠券规则
const couponRule = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.couponRule)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 创建品牌加盟
const createBrand = (form = {}) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createBrand, form)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 购买尊享卡
const createCard = (payType, goodsId, sourceType) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createCard, {
      memberGoodsId: goodsId,
      payTypeChannel: payType || "ALIPAY_MOBILE", // WX_APP
      sourceType: sourceType || 3
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 申请提现
const createCashout = (amount, authCode) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createCashout, {
      amount,
      authCode
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 充值
const createCharge = (payType, goodsId, sourceType) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createCharge, {
      payTypeChannel: payType || "ALIPAY_MOBILE",
      rechargeGoodsId: goodsId,
      sourceType: sourceType || 3
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 创建数据查询订单
const createDataQuary = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createDataQuary)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 创建下载报告
const createDownload = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.createDownload + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 反馈
const createFeedback = content => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createFeedback, {
      content
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 商品反馈
const goodsFeedback = content => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.goodsFeedback, content)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 开票
const createInvoice = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createInvoice, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 下单
const createOrder = (
  orderNo = null,
  payType,
  reportId,
  couponId,
  sourceType,
  questionId,
  userStdQuestionPkgItemId,
  businessId,
  remark,
  goodsSkuId,
  exchangeActivity
) => {
  let url = businessId ? MAPPING.createBusiness : MAPPING.createOrder;
  if (orderNo) url = MAPPING.payment;
  return new Promise((resolve, reject) => {
    Axios.post(url, {
      payTypeChannel: payType || "ALIPAY_MOBILE", // WX_APP
      reportInstanceId: reportId || 0,
      voucherId: couponId || null,
      questionId: questionId || 0,
      remark: remark || "",
      goodsSkuId: goodsSkuId || null,
      sourceType: sourceType || 3,
      orderNo,
      userStdQuestionPkgItemId,
      businessId,
      exchangeActivity
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 新增修改地图标注业务
const mapLabelingSave = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.mapLabelingSave, data)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 查地图标注所有商品
const getMapGoods = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getMapGoods)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 查看个人进度列表
const getMyList = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getMyList)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 服务包下单
const createPackage = (payType, packageId, sourceType, couponId, reportInstanceId, orderNo, exchangeActivity) => {
  let url = MAPPING.createPackage;
  if (orderNo) url = MAPPING.payment;
  return new Promise((resolve, reject) => {
    Axios.post(url, {
      payTypeChannel: payType || "ALIPAY_MOBILE", // WX_APP
      stdQuestionId: packageId,
      sourceType: sourceType || 3,
      voucherId: couponId || null,
      reportInstanceId: reportInstanceId || null,
      orderNo,
      exchangeActivity
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

//服务包兑换
const createBusiness = (orderNo = null, reportId, couponId, sourceType, userStdQuestionPkgItemId, businessId) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createBusiness, {
      payTypeChannel: "STD_QUESTION_PKG", // 套餐包解锁
      reportInstanceId: reportId || 0,
      voucherId: couponId || null,
      questionId: 0,
      remark: "",
      goodsSkuId: null,
      sourceType: sourceType || 3,
      orderNo,
      userStdQuestionPkgItemId,
      businessId
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 创建问题
const createQueation = question => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createQueation, question)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 创建报告
const createReport = (templateId, input, question, oriQuestion = null, sourceType = null) => {
  return new Promise((resolve, reject) => {
    let url = MAPPING.createReport;
    const data = {
      showTemplateCnfId: templateId,
      input: typeof input === "object" ? JSON.stringify(input) : "[]",
      showQuestion: question,
      originalQuestion: oriQuestion
    };
    if (templateId === 10002) {
      url = MAPPING.createReportSP;
      data["sourceType"] = sourceType;
    }
    Axios.post(url, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 创建店铺订阅（续订）
const createShopSub = (shopId, openShopId, type = 1) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createShopSub, {
      shopId,
      openShopId,
      type
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
/**
 * 服务市场下单接口
 */
const servicePayOrder = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post('/v1/order/place/market', data).then(res => {
      resolve(res.data.data);
    }).catch(e => {
      reject(e);
    });
  })
}
// 购买VIP
const createVip = (payType, goodsId, sourceType, pageId, ext) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createVip, {
      memberGoodsId: goodsId,
      payTypeChannel: payType || "ALIPAY_MOBILE", // WX_APP
      sourceType: sourceType || 3,
      pageId,
      ext: ext || '',
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 购买地图标注
const createMap = (goodsId, payTypeChannel, refId, sourceType, pageId) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.createMap, {
      goodsId,
      payMode: 1,
      payTypeChannel,
      refId,
      sourceType,
      pageId
    })
      .then(res => {
        resolve(res.data.data
        )
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 合伙人收益列表
const incomeList = (commissionLevel, marker, order, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.incomeList, {
      commissionLevel,
      marker,
      order: order || "after",
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 邀请好友记录列表
const inviteList = (marker, order, type = 1, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.inviteList, {
      marker,
      type,
      order: order || "after",
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 邀请注册表单提交
const inviteRegister = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.inviteRegister, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const loginDownLoad = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.loginDownLoad, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取行业报告列表
const hotspotList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.hotspotList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取开票列表
const invoiceList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.invoiceList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 检测订单
const orderCheck = (reportInstanceId, payTypeChannel) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.orderCheck, {
      payTypeChannel,
      reportInstanceId
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询订单列表
const orderList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.orderList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 提交订单评论
const goodsComment = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.goodsComment, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 查询课程列表
const courseList = (page, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.courseList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 检查订单状态
const orderStatus = orderId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.orderStatus + "/" + orderId)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询我的套餐购买列表
const packageHistory = (marker, order, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.packageHistory, {
      marker,
      order: order || "after",
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询我的套餐列表
const packageList = (marker, order, type, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.packageList, {
      marker,
      order: order || "after",
      type: type || 1,
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 饿了么我的订单列表
const elePackageList = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.elePackageList, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 查询我的提问列表
const questionList = (marker, order, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.questionList, {
      marker,
      order: order || "after",
      row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询报告列表
const reportList = (marker, order, queryType = 0, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      MAPPING.reportList,
      {
        marker,
        order: order || "after",
        row,
        queryType
      }
      //, { headers: { 'mock-user': '{"id": 1}' }, }
    )
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 注册成为合伙人
const registerPantner = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.registerPantner)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// PV上报
const reportPV = (fromType, inviteType = 1) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.reportPV, {
      fromType,
      inviteType
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 报告保存
const reportSave = reportInstanceId => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.reportSave, {
      reportInstanceId
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 保存合伙人信息
const savePartnerInfo = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.savePartnerInfo, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 搜索列表
const searchList = keyword => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.searchList, {
      input: keyword
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 搜索结果
const searchResult = question => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.searchResult, {
      originalQuestion: question
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取验证码
const captcha = data => {
  return new Promise((resolve, reject) => {
    const url = typeof data === "object" ? MAPPING.captcha : MAPPING.authCode;
    Axios.post(url, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// authCode 获取验证码 用于提现
const authCode = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.authCode)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 滑块验证-避免机器调用接口
const verification = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.verification, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 搜索列表
const discoveryList = params => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.discoveryList, { params })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取邀请好友页相关信息
const getInviteDetail = inviteCode => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getInviteDetail, {
      inviteCode
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// sem
const saveSemAd = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.saveSemAd, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const semLogin = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.semLogin, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取sem二维码
const getQRCode = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getQRCode, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取sem版本信息
const getVersionInfo = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getVersionInfo, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};


// 获取合伙人信息
const getPartnerInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getPartnerInfo)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 分享佣金
const getGoodsShareList = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getGoodsShareList)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取城市列表
const getOpenCityZone = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.openCity)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取用户信息
const getUserInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getUserInfo)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取会员信息
const getVipInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getVipInfo)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取问题列表
const getQuestion = templateId => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getQuestion, "showTemplateId=" + templateId)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取问题信息
const getQuestionInfo = templateId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getQuestionInfo + "/" + templateId)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获取店铺订阅商品
const listAllQuestionOfShop = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.listAllQuestionOfShop)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获取城市列表
const getCityZone = (type = "all") => {
  return new Promise((resolve, reject) => {
    const urlKey = `${type}City`;
    Axios.get(MAPPING[urlKey])
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取行业列表
const formatCategory = (type = "open") => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.formatCategory, {
      params: {
        showAll: type === "all" ? 1 : 0
      }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 店铺列表
const shopList = (form, page = 1, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.shopList, {
      ...form,
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 已订阅店铺列表
const subShopList = (page = 1, row = PAGE_ROW) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.subShopList, {
      current: page,
      size: row
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获得周边推荐的店铺
const recommendShops = (form, count) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.recommendShops, {
      ...form,
      count: count
    })
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 领取上上贝（会员）
const takeCoins = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.takeCoins)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获得课程详情
const getCourseDetail = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getCourseDetail + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 获得课程详情
const shareCourse = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.shareCourse + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 合伙人面对面邀请二维码
const inviteQRCode = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.inviteQRCode)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 合伙人配置项
const partnerConfig = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.partnerConfig)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 合伙人统计信息-累计邀请+累计订单
const partnerTotal = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.partnerTotal)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 合伙人-邀请记录
const invitationList = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.invitationList, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 通过邀请码获取合伙人信息
const inviteUserInfo = code => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.inviteUserInfo, {
      inviteCode: code
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 1对1定制化数据服务
const getStdQuestionCnf = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getStdQuestionCnf + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 1对1定制化数据服务-立即购买展示定制页商品
const getListAllQuestion = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getListAllQuestion)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 1对1定制化数据服务-获取商品详情
const getOrderServer = goodsId => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getOrderServer, {
      goodsId
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 套餐包-优惠券
const getlistVoucher = goodsId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getlistVoucher, { params: { goodsId } })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 活动--领取优惠券
const getActiveCoupon = activeId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getActiveCoupon + "/" + activeId)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const receiveCoupon = (activeId, couponId) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.receiveCoupon, {
      activeId,
      couponId
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 查询品牌推荐列表
const comparisonAndAnalysis = (form = {}) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.comparisonAndAnalysis, form)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 新版支付页-查询商品信息
const listGoodsPkg = goodsId => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.listGoodsPkg + "/" + goodsId)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 端午活动详情
const getSigninDetail = goodsId => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getSigninDetail, { activeId: goodsId })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 端午活动签到
const sign = goodsId => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.sign, { activeId: goodsId })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 商品问答
const questionAnswerPage = (current = 0, goodsId) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.questionAnswerPage, {
      current,
      goodsId,
      size: 10
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 我的商品问答
const myQuestionAnswerPage = (current = 0, goodsId) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.myQuestionAnswerPage, {
      current,
      goodsId,
      size: 10
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 购买成功页
const paySuccess = orderNo => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.paySuccess + "/" + orderNo)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询省市区信息
const v2openCity = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.v2openCity)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 更新问卷调查信息page1
const updateQuestionnaire = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.updateQuestionnaire, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 更新问卷调查信息page2
const updatePersonalInfo = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.updatePersonalInfo, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询问卷调查信息page1
const getQuestionnaireById = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getQuestionnaireById)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查询问卷调查信息page2
const getPersonalInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getPersonalInfo)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 套餐使用明细
const listOrderPayWithPackage = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.listOrderPayWithPackage, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 品牌首页
const getBrandInfo = () => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getBrandInfo)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 搜索品牌联想接口
const searchBrandByName = keyWord => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.searchBrandByName + "/" + keyWord)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 热门品牌搜索列表
const listHotRank = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.listHotRank)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容视频
const getVideoContent = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getVideoContent, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const upload = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.upload, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容视频tab切换
const getCategoryResource = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getCategoryResource + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容详情
const getResourceById = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getResourceById + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容详情-评价列表
const getEvaluate = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getEvaluate, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容详情-发布评价
const addEvaluate = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.addEvaluate, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容详情-收藏
const addCollect = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.addCollect, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 社区-最新资讯
const getLatest = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getLatest, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 内容详情-取消收藏
const cancelCollect = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.cancelCollect, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 文章干货
const getDrgGoods = data => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getDrgGoods)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const getDrgGoodsList = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getDrgGoodsList + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const getArticleCategoryDetail = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getArticleCategoryDetail, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const collectionAll = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.collectionAll, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 删除收藏
const collectionDel = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.collectionDel + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 消息列表(新)
const listUserMsgInfoV316 = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.listUserMsgInfoV316, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 消息列表
const listUserMsgInfo = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.listUserMsgInfo, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 查看通知开关状态
const getSettingByType = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.getSettingByType, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 更新通知开关状态
const updateSetting = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.updateSetting, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 通知已读
const readUserMsg = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.readUserMsg + "/" + data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 新品牌详情-我的评论
const myCommonts = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.myCommonts, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 新品牌详情-评论详情
const commontsDetail = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.commontsDetail + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 新品牌详情-新增评论
const addCommonts = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.addCommonts, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 新品牌详情-所有评论
const allCommonts = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.allCommonts, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 评论点赞
const giveLike = (id, status) => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.giveLike + `/` + id + `/` + status)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 评论tab
const commontsTabs = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.commontsTabs + `/` + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 品牌合作申请
const brandJoinApply = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandJoinApply, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 保存报告，仅限 查周边、查城市、查商场
const saveReport = id => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.saveReport, { reportId: id })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 检查报告是否可以下载，仅限 查周边、查城市、查商场
const downloadCheck = id => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.downloadCheck, { reportId: id })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 原 我的报告-周边数据 改为 我的报告-数据查询，此为新的数据查询接口
const listDataQuery = (row, marker, order) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.listDataQuery, {
      row: row,
      marker: marker,
      order: order
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 批量领用优惠券
const batchReceive = data =>
  new Promise((resolve, reject) => {
    Axios.post(MAPPING.batchReceive, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 批量领用优惠券
const getActiveInfo = id =>
  new Promise((resolve, reject) => {
    Axios.get(MAPPING.getActiveInfo + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 开店必看榜单 - 外卖榜单
const waiMaiPage = data =>
  new Promise((resolve, reject) => {
    Axios.post(MAPPING.waiMaiPage, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 外卖排行榜
const shopSaleList = id =>
  new Promise((resolve, reject) => {
    Axios.get(MAPPING.shopSaleList + "/" + id)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 查询品牌
const searchBrand = data =>
  new Promise((resolve, reject) => {
    Axios.post(MAPPING.searchBrand, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 查询品牌
const searchBrandReport = brandCode =>
  new Promise((resolve, reject) => {
    Axios.post(`${MAPPING.searchBrandReport}/${brandCode}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 全国门店
const allDpPage = data =>
  new Promise((resolve, reject) => {
    Axios.post(MAPPING.allDpPage, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 报告门店详情
const brandReportDetail = data =>
  new Promise((resolve, reject) => {
    Axios.post(MAPPING.brandReportDetail, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 检测会员状态
const checkBrandStatus = brandCode =>
  new Promise((resolve, reject) => {
    Axios.post(`${MAPPING.checkBrandStatus}/${brandCode}`)
      .then(res => {
        console.log(res);
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });

// 删除店铺接口
const shopCollectDelete = id => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.shopCollectDelete + "/" + id)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const transferLast = () => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.transferLast)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};
// 我的铺位列表
const shopCollectMylist = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.shopCollectMylist, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

//采集的店铺详情接口
const shopCollectDatail = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.shopCollectDatail, data)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 获取验证码
const sendLoginSmsV2 = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.sendLoginSmsV2, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// 百度sem获取验证码
const sendLoginSmsV2Bd = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.sendLoginSmsV2Bd, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
};

const login = data => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.login, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
};
const sendTrack = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.track, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}
const sendBtnTrack = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.trackforBtn, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}
const saveSaasInfo = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.saveSaasInfo, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}
const submitTransfer = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.submitTransfer, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}
const getSaasInfo = (data) => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getSaasInfo, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}
const getMemberTab = (source) => {
  return new Promise((resolve, reject) => {
    Axios.get(MAPPING.getMemberTab + '?source=' + source)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}

const queryReportUpdateDate = (data) => {
  return new Promise((resolve, reject) => {
    Axios.post(MAPPING.queryReportUpdateDate, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
  });
}
export default {
  setAuth, // 设置授权请求头 非请求接口定义

  GET, // 导出GET请求接口
  POST, // 导出POST请求接口

  billList,
  brandBannerList,
  brandCityList,
  brandList,
  brandReco,
  brandList2,
  brandCategory,
  brandRecommList,
  brandJoin,
  listAllQuestionOfBrandJoin,
  captcha,
  authCode,
  verification,
  cashoutList,
  chargeList,
  checkout,
  checkout2,
  checkPackage,
  checkViewInfo,
  couponList,
  couponRule,
  createBrand,
  createCard,
  createCashout,
  createCharge,
  createDataQuary,
  createDownload,
  createFeedback,
  createInvoice,
  createOrder,
  createPackage,
  createBusiness,
  createQueation,
  createReport,
  createShopSub,
  createVip,
  servicePayOrder,
  createMap,
  saveSemAd,
  semLogin,
  getQRCode,
  getVersionInfo,
  discoveryList,
  formatCategory,
  mapLabelingSave,
  getMapGoods,
  getMyList,
  getAppStore,
  getArticle,
  getCourseInfo,
  getAssessDesc,
  getAssessInfo,
  getAssessList,
  submitAssess,
  getAssessOption,
  getAssessParams,
  getAssessResult,
  getAssessTarget,
  getBalance,
  getBrand,
  getBrand2,
  getCityZone,
  getHomeData,
  getInviteDetail,
  getOpenCityZone,
  getPartnerInfo,
  getGoodsShareList,
  getQuestion,
  getQuestionInfo,
  listAllQuestionOfShop,
  getReply,
  getReport,
  getReportData,
  getReportShare,
  getReportName,
  getReportUrl,
  getShopData,
  getShopDetail,
  getShowUpData,
  getUserInfo,
  getVipInfo,
  goodsList,
  hotspotList,
  incomeList,
  inviteList,
  inviteRegister,
  loginDownLoad,
  invoiceList,
  markerAllRead,
  markerRead,
  messageList,
  orderCheck,
  orderList,
  goodsComment,
  courseList,
  orderStatus,
  packageHistory,
  packageList,
  elePackageList,
  questionList,
  registerPantner,
  reportList,
  reportPV,
  reportSave,
  upload,
  savePartnerInfo,
  getUrlLink,
  searchList,
  searchResult,
  shopList,
  subShopList,
  recommendShops,
  takeCoins,
  topicList,
  tubatuUpload,
  huaxiaUpload,
  useBenefit,
  getCourseDetail,
  shareCourse,
  inviteQRCode,
  partnerConfig,
  partnerTotal,
  invitationList,
  inviteUserInfo,
  getStdQuestionCnf,
  getListAllQuestion,
  getOrderServer,
  getlistVoucher,
  getActiveCoupon,
  receiveCoupon,
  goodsFeedback,
  contrastList,
  comparisonAndAnalysis,
  listGoodsPkg,
  industry,
  cityList,
  getSigninDetail,
  sign,
  questionAnswerPage,
  myQuestionAnswerPage,
  paySuccess,
  v2openCity,
  updateQuestionnaire,
  updatePersonalInfo,
  getQuestionnaireById,
  getPersonalInfo,
  listOrderPayWithPackage,
  getBrandInfo,
  searchBrandByName,
  listHotRank,
  getVideoContent,
  getCategoryResource,
  getResourceById,
  getEvaluate,
  addEvaluate,
  addCollect,
  cancelCollect,
  getLatest,
  getDrgGoods,
  getDrgGoodsList,
  getArticleCategoryDetail,
  collectionAll,
  collectionDel,
  listUserMsgInfo,
  listUserMsgInfoV316,
  getSettingByType,
  updateSetting,
  readUserMsg,

  myCommonts,
  commontsDetail,
  addCommonts,
  allCommonts,
  giveLike,
  commontsTabs,
  brandJoinApply,
  batchReceive,
  getActiveInfo,

  saveReport,
  downloadCheck,
  listDataQuery,
  getMessageUpload,
  waiMaiPage,
  shopSaleList,
  searchBrand,
  getModuleVipInfo,
  selectAd,
  reportSubmit,
  perfectInformation,
  getInformation,
  getGoodsDetail,
  handleLogin,
  getShopMarketList,
  getShopMarketSearch,
  getProcess,
  addSitingReport,
  getReportInfo,
  getPackageInfo,
  getCityList,
  elemeSubmit,
  getIsPayUser,
  getPollingData,
  searchBrandReport,
  allDpPage,
  brandReportDetail,
  checkBrandStatus,
  shopCollectDelete,
  shopCollectMylist,
  shopCollectDatail,
  sendLoginSmsV2,
  sendLoginSmsV2Bd,
  login,
  sendTrack,
  sendBtnTrack,
  saveSaasInfo,
  getSaasInfo,
  submitTransfer,
  transferLast,
  getMemberTab,
  getPayOrderInfo,
  getOrderDetail,
  addQuestion,
  captchaValidate,
  // 开店热搜榜   type： 3 热搜     4 黑马     5 特殊
  openShop(type) {
    return new Promise((resolve, reject) => {
      Axios.post(`/v1/rank/openShop/${type}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  // 创业热搜
  hotIndustry(cityCode) {
    return new Promise((resolve, reject) => {
      Axios.post(`/v1/rank/hotIndustry`, { cityCode })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  // 获取评论与回复
  getReplys(data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/v1/comment/getReply`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  // 添加评论
  addComment(data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/v1/comment/add`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  // 点赞
  niceComment(data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/v1/comment/niceComment`, data)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  // 创业热选地图
  allCityCount() {
    return new Promise((resolve, reject) => {
      Axios.get(`/v1/rank/allCityCount`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getMallReportDetail,
  ...discoverHttp,
  ...pointRadarHttp,
  ...communityHttp,
  getCommunityReportUrl,
  ...brandAllianceHttp,
  queryReportUpdateDate,
  ...dataQueryHttp
};
