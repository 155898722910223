<template>
    <div class="topic">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true" />

        <!-- 骨架屏占位图 -->
        <img v-if="firstLoading" src="/static/skt/topic.png" width="100%" />

        <AutoView v-show="!firstLoading" :header="true" :footer="false" class="topic--view l-view">
            <div v-if="list.length === 0" class="empty">暂无数据</div>
            <template v-else>
                <van-pull-refresh v-model="dataLoading" @refresh="loadNewData">
                    <div class="topic--list">
                        <template v-if="slogan">
                            <div class="topic--slogan" @click="onTap( list[0] )">
                                <img :src="list[0].coverImage || null" />
                                <p class="title">{{ list[0].title || '---' }}</p>
                            </div>
                        </template>
                        <van-list v-model="loading"
                            :finished="finished"
                            @load="loadData" >
                            <div v-for="( item, index ) in list" :key="item.id" class="card" :class="{ hidden: slogan && index === 0 }" @click="onTap( item )">
                                <NewsCard :item="item" />
                            </div>
                        </van-list>
                    </div>
                </van-pull-refresh>
            </template>
        </AutoView>
    </div>
</template>

<script>
import NewsCard from '@/components/card/news';

export default {
    name      : 'topic',
    components: {
        NewsCard,
    },
    data () {
        return {
            title : '专题栏目',
            slogan: false, // true: 首篇作为焦点图进行展示 false: 普通列表展示
            id    : 0,

            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            light       : null, // 高亮条目
            recent      : null,
            remote      : null,
            list        : [],
        };
    },
    methods: {
        loadData ( clear ) {
            this.id = this.$route.params.id;
            this.loading = true;
            this.HTTP.topicList( this.id, this.remote, 'before' ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                if ( clear && res.list.length ) {
                    this.list     = [];
                    this.finished = false;
                    setTimeout( () => {
                        this.dataLoading = false;
                    }, 2000 );
                }
                if ( res.list.length ) {
                    this.list = this.list.concat( res.list );
                }
                if ( this.list.length ) {
                    this.recent = this.list[ 0 ].gmtCreate;
                    this.remote = this.list[ this.list.length - 1 ].gmtCreate;
                }
                this.finished = ! res.more;
                this.title = res.column.columnName;
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.firstLoading = false;
                this.loading  = false;
                this.finished = true;
                setTimeout( () => {
                    this.dataLoading = false;
                }, 2000 );
            } );
        },
        loadNewData () {
            this.recent = null;
            this.remote = null;
            this.loadData( true );
        },
        onTap ( item ) {
            this.swRouter( '/article/' + item.id );
        },
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler () {
                this.slogan = this.$route.query.slogan;
                this.loadData();
            }
        },
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.topic {
    height: 100vh;
    background-color: $c-pageBg2;

    #{&}--view {
        padding: $margin $padding $padding;

        .empty {
            text-align: center;
            color: $c-info;
            font-size: $font_320;
            padding: $padding;
        }
    }

    #{&}--list {
        overflow: hidden;
        border-radius: $radius_xs;
        background-color: white;

        .card {
            padding: $padding $padding * .8;
            &:not(:first-child) {
                box-shadow: inset $padding * - 0.8 0 white, inset $padding * .8 0 white, inset 0 1px $c-line;
            }

            &.hidden {
                display: none;
            }
        }
    }

    #{&}--slogan {
        position: relative;
        width: 100%;
        height: 40vw;
        box-shadow: 0 .5vw 2vw rgba(black, .25);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 3.6vw;
            @include font( white, 4.54vw, bold );
            @include limitLine( 2 );
            line-height: 6.4vw;
            box-sizing: border-box;
        }
    }
}
</style>
