<template>
  <div class="searchBar">
    <div class="mask s-radius__xs">
      <form action="" @submit="validate" class="searchBar--form">
        <van-popover
          v-model="showPopover"
          trigger="click"
          :get-container="getContainer"
        >
          <div class="popover-container">
            <div class="popover-item" v-for="(item, index) in filterTabs" :key="index"
                 @click="selectPop(index)" :class="{'active': popoverIndex===index}"
            >{{ item }}</div>
          </div>
          <template #reference>
            <div class="filter-popover">
              <span>{{ popoverValue }}</span>
              <span class="triangle"></span>
<!--              <img src="/static/discovery/arrow-down-gray.png" />-->
            </div>
          </template>
        </van-popover>

        <input ref="search" v-model="keyword" type="search" :placeholder="placeholder" class="searchBar--input" @input="input" />
        <i v-show="showClear" class="searchBar--clear" @click="clear" />
      </form>
    </div>
    <div class="view view--r">
      <span class="right-title" @click="search">搜索</span>
    </div>
  </div>
</template>

<script>
  export default {
    name   : 'search-bar-filter',
    props: {
      keyValue: String,
      popoverActiveIndex: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        keyword: this.keyValue,
        popoverIndex: this.popoverActiveIndex,
        showPopover: false,
        filterTabs: ['搜品牌', '搜商场'],
        showClear: false,
      };
    },
    computed: {
      popoverValue() {
        return this.filterTabs[this.popoverIndex]
      },
      placeholder() {
        return this.popoverIndex === 0 ? '请输入品牌名称' : '请输入商场名称'
      }
    },
    watch: {
      keyword(nVal, oVal) {
        const val = nVal.trim();
        this.showClear = val && val.length;
      },
      back: {
        immediate: true,
        handler(val) {
          this.JSBridge.callHandler("switchBackBtn", val);
        },
      },
    },
    methods: {
      // 返回一个特定的 DOM 节点，作为挂载的父节点
      getContainer() {
        return document.querySelector('.searchBar');
      },

      selectPop(index) {
        this.popoverIndex = index
        this.showPopover = false
        this.$emit('selectPop', index)
      },
      search() {
        this.$emit('search', this.keyword ? this.keyword.trim() : '')
      },
      validate(e) {
        e.preventDefault();
        this.search()
      },
      clear() {
        this.keyword = "";
        this.$emit('search', this.keyword)
      },
      input() {
        this.$emit('input', this.keyword ? this.keyword.trim() : '')
      }
    },
  };
</script>
<style lang="scss">
  .van-popover {
    left: 14.6vw !important;
    top: 14vw !important;
  }
  .van-popover__action {
    width: 22.93vw;
  }
  .popover-container {
    /*width: 22.93vw;*/
    /*height: 24.933vw;*/
    background: #FFFFFF;
    box-shadow: 0 0 2.267vw 0 rgba(0, 0, 0, 0.2);
    padding: 0 3.333vw;
    .popover-item {
      width: 16.267vw;
      font-weight: 400;
      color: #666666;
      font-size: 3.733vw;
      padding: 3vw 0;
      text-align: center;
      &:first-child {
        border-bottom: .333vw solid #E4E7ED;
      }
      &.active {
        font-weight: 600;
        color: #347FFF;
      }
    }
  }
</style>
<style lang="scss" scoped>
  @import "@/styles/index.scss";

  $searchIcon: $headerHeight - $headerMargin * 2;

  .searchBar {
    @include flexColumn;
    position: relative;
    height: 16.5vw;
    padding: 0 $headerHeight;
    background-color: $c-searchBarBg;
    box-sizing: border-box;
    // box-shadow: inset 0 -1px 0 #ebeaee, 0 -1px 0 white;

    &.full {
      padding: 0;
    }
    .view {
      &--r {
        overflow: hidden;
        @include flexColumn;
        position: absolute;
        width: $headerHeight;
        height: $headerHeight;
        top: 0;
        right: 0;
      }
    }

    #{&}--form {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: $margin;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // &.icon {
      //   padding-left: $padding * 1.4;
      //   background: url(/static/icon/search@64_gray.png) no-repeat 1.6vw center;
      //   background-size: 6vw;
      // }
      .search-icon {
        width: 3.47vw;
        height: 3.47vw;
        margin-right: 2.13vw;
      }
      .filter-popover {
        display: flex;
        align-items: center;
        font-weight: 400;
        color: #333333;
        font-size: 3.733vw;
        border-right: .133vw solid #E4E7ED;
        padding-right: 2.933vw;
        img {
          width: 2.133vw;
          height: 1.333vw;
          margin-left: 1.333vw;
        }
        span {
          color: #333333;
        }
        .triangle {
          width: 0;
          height: 0;
          border-left: 1.333vw solid transparent;
          border-right: 1.333vw solid transparent;
          border-top: 1.6vw solid #b2b3b9;
          transition: all 0.5s;
          margin-left: 1.333vw;
          border-radius: 1.333vw;
        }
      }
    }
    #{&}--input {
      background-color: transparent;
      border: none;
      flex: 1;
      height: 100%;
      width: 100%;
      padding: 0;
      font-weight: normal;
      padding-left: 2.933vw;
      // &.on {
      //     font-weight: bold;
      // }
    }
    #{&}--clear {
      position: relative;
      right: 2vw;
      display: block;
      width: $searchIcon;
      height: 100%;
      background: url(/static/icon/close.png) no-repeat center center;
      background-size: 4.27vw;
    }
    #{&}--cancel {
      width: 11.2vw;
      height: 3.4vw;
      line-height: 3.4vw;
      font-size: $font_320;
      text-align: right;
      box-shadow: inset 1px 0 0 $c-gray;
    }

    .mask {
      @extend .l-flex;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 10.67vw;
      background-color: $c-pageBg;
      font-size: 4vw;
      font-weight: bold;
      box-shadow: inset 0 0 0 0.5px $c-border;

      span {
        color: $c-main;
      }
    }
    .right-title {
      font-size: 3.73vw;
      color: #347fff;
    }
  }
</style>
