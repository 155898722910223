<template>
    <div class="brand">
        <div class="header">
            <template v-if="isClient">
                <StateBar :bgcolor="bgColor" />
                <TitleHeader :title="title" :back="showBack" :bgcolor="bgColor" ></TitleHeader>
            </template>
            <div v-else class="share">
                <p class="f-fw">上上参谋</p>
                <p>生意人的数据军师</p>
                <a ref="openApp" :href="link" target="_blank" class="app">APP查看</a>
            </div>
        </div>
        <AutoView ref="view" :header="true" :footer="false" class="brand--view">
            <div class="form">
                <div class="form-item">
                    <div class="item-label"><span class="item-label-symbol">*</span><span class="item-label-text">联系人</span></div>
                    <van-field v-model="form.userName" class="item-field" placeholder="请输入联系人姓名" />
                </div>
                <div class="form-item">
                    <div class="item-label"><span class="item-label-symbol">*</span><span class="item-label-text">手机号</span></div>
                    <van-field v-model="form.phone" class="item-field" type="tel" maxlength="11" placeholder="请输入联系人手机号" />
                </div>
                <div class="form-item">
                    <div class="item-label"><span class="item-label-symbol">*</span><span class="item-label-text">品牌名称</span></div>
                    <van-field v-model="form.brandName" class="item-field" placeholder="请填写完整品牌名称" />
                </div>
            </div>
            <div class="submit" @click="onTap">提交</div>
            <div class="tips">
                <span>注意：</span><br />
                <span>1、加盟品牌信息将在品牌专区上展示</span><br />
                <span>2、确认提交品牌信息，后台上架对应品牌信息开始计算套餐生效期</span>
            </div>
        </AutoView>
    </div>
</template>

<script>
import { isPhoneNo } from '@/utils/index.js';
export default {
    components: {

    },
    data () {
        return {
            loading : false,
            isClient: false,
            link    : this.HTTP.getAppStore(),
            showBack: true,
            bgColor : 'white',
            title   : '填写加盟品牌信息',
            form    : {
                brandName: '',
                phone    : '',
                userName : ''
            }
        };
    },
    beforeMount () {
        this.id = this.$route.params.id;
        this.isClient = window.isClient;
    },
    mounted () {
        this.getTemp();
    },
    methods: {
        onTap () {
            if ( !this.validate() ) {
                return;
            }
            this.loading = true;
            //生成业务Id
            this.HTTP.brandJoin( this.form ).then( ( res ) => {
                 if ( res ) {
                     this.setTemp();
                    const businessId = res;
                    this.swRouter( `/pay/${businessId}` );
                    this.loading = false;
                 }
            })
        },
        validate () {
            if ( ! this.form.userName ) {
                this.$toast( '请输入联系人姓名' );
                return false;
            }
            if ( ! isPhoneNo( this.form.phone ) ) {
                this.$toast( '手机号码格式不正确' );
                return false;
            }
            if ( ! this.form.brandName ) {
                this.$toast( '请填写完整品牌名称' );
                return false;
            }
            return true;
        },
        setTemp() {
            const temp = {
                userName: this.form.userName,
                phone: this.form.phone,
                brandName: this.form.brandName
            };
            this.$store.commit( 'setTemp', temp );
            // console.log( 'setTemp:', temp );
        },
        getTemp() {
            const temp = this.$store.getters.temp;
            // console.log( 'getTemp:', temp );
            if ( temp ) {
                this.form = {
                    brandName: temp.brandName,
                    phone    : temp.phone,
                    userName : temp.userName
                }
            }
        },

    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.brand {
    height: 100vh;
    .header {
        position: relative;
        top: 0;
        width: 100%;
        z-index: 2;
        ::v-deep {
            .stateBar, .titleHeader {
                @include ani(.25s, background-color);
            }
        }
        .share {
            position: relative;
            height: $headerHeight;
            background: rgba(black, .8) url(/static/icon/logo@96.png) no-repeat $margin center;
            background-size: $headerHeight * 0.7;
            color: white;
            padding-left: $headerHeight * 1.1;
            p {
                font-size: $font_320;
                padding-top: $margin * 0.8;
            }
            .f-fw {
                font-size: $font_400;
            }
            .app {
                position: absolute;
                right: $margin;
                top: 25%;
                height: 50%;
                color: white;
                background-color: $c-main;
                padding: 0 $margin;
                overflow: hidden;
                border-radius: 10vw;
                font-size: $font_320;
                @include flexColumn;
            }
        }
    }
    #{&}--view {
        scroll-behavior: smooth;
        padding: 3.2vw 4vw;
        background-color: #F8F8F9;
        .form {
            position: relative;
            border-radius: 1.33vw;
            background-color: #FFFFFF;
            padding: 4.8vw 4vw 5.33vw 4vw;
            box-sizing: border-box;
            .form-item {
                position: relative;
                margin-top: 4vw;
                &:first-child {
                    margin-top: 0;
                }
                .item-label {
                    display: flex;
                    align-items: center;
                    .item-label-symbol {
                        color: red;
                        line-height: 4.53vw;
                    }
                    .item-label-text {
                        color: #1D2233;
                        font-size: 3.2vw;
                        line-height: 4.53vw;
                        text-align: left;
                        margin-left: 1.33vw;
                    }
                }
                .item-field {
                    background-color: #F8F8F9;
                    border-radius: 1.33vw;
                    margin-top: 2.4vw;
                }
            }
        }
        .submit {
            position: relative;
            margin-top: 8vw;
            background-color: #518CDB;
            padding: 2.67vw 0;
            box-sizing: border-box;
            border-radius: 1.33vw;
            color: #FFFFFF;
            font-size: 4vw;
            line-height: 5.6vw;
            text-align: center;
            cursor: pointer;
        }
        .tips {
            position: relative;
            margin-top: 8vw;
            color: #AEB1BD;
            font-size: 2.93vw;
            line-height: 4.8vw;
            text-align: left;
        }
    }
}
</style>
