<template>
	<div>
		<StateBar />
		<TitleHeader bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
		</TitleHeader>
		<AutoView :footer="false">
			<div class="intentionsubmitSuccessful">
				<img src="../../../../static/shopEcology/paySuccessful.png" alt="">
				<div class="title">求租信息提交成功</div>
				<div class="info">提交后，将显示在「需求广场」，求租信息默认90天后自动关闭</div>
				<div class="bottom-box">
					<div class="button-left" @click="goSquare">
						<div class="bl-container">
							<div class="icon-image" />
							<div>返回</div>
						</div>
					</div>
					<div class="button-right" @click="goDetails">查看详情</div>
				</div>
			</div>
		</AutoView>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {
		goSquare() {
			//去广场
			// if (window.isIOS) this.JSBridge.callHandler("closeCurPage");
			// else this.JSBridge.callHandler("closePage");
			// this.JSBridge.callHandler("onNormalizingCall", {
			// 	type: 5,
			// 	url: "SHOP_SQUARE_HOME",
			// 	params: "{\"isSelectRentSquare\":true}"
			// });

			this.JSBridge.callHandler("onBackPressed");
		},
		goDetails() {
			if (window.isIOS) this.JSBridge.callHandler("closeCurPage");
			else this.JSBridge.callHandler("closePage");
			this.swRouter({
				path: '/shopEcology/askingRentDetails',
				query: {
					id: this.$route.query.id,
					name: this.$route.query.name ? this.$route.query.name : ''
				}
			});
		}
	}
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

@function vw($args) {
	@return $args/750*100+vw;
}

.intentionsubmitSuccessful {
	padding-top: vw(112);
	text-align: center;

	img {
		width: vw(174);
		height: vw(174);
	}

	.title {
		margin-top: vw(40);
		height: vw(42);
		font-size: vw(30);
		font-weight: 600;
		color: #333333;
		line-height: vw(42);
	}

	.info {
		margin-top: vw(6);
		font-size: vw(24);
		line-height: vw(36);
		color: #999999;
		padding: 0 vw(152);
	}

	.bottom-box {
		margin-top: vw(100);
		display: flex;
		justify-content: space-between;
		text-align: center;
		border-radius: vw(10);
		font-size: vw(32);
		font-weight: 600;
		padding: 0 vw(106);
		box-sizing: border-box;

		.button-left {
			width: vw(239);
			height: vw(95);
			color: #347FFF;
			line-height: vw(95);
			border-radius: vw(8);
			box-sizing: border-box;
			background: #F5F8FF;

			.bl-container {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.icon-image {
				width: vw(37);
				height: vw(37);
				margin-right: vw(4);
				align-items: center;
				background-size: vw(37) vw(37);
				background-image: url(/static/shopEcology/lease/left-arr.png);
			}
		}

		.button-right {
			width: vw(239);
			height: vw(95);
			color: #347FFF;
			line-height: vw(95);
			border-radius: vw(8);
			box-sizing: border-box;
			background: #F5F8FF;
		}
	}

}
</style>
