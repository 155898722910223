<template>
  <div class="icon-title">
    <div class="cell">
      <div class="cell-hd">
        <img class="icon" :src="data.icon" />
        <img class="mark" v-if="data.mark" :src="data.mark" />
      </div>
      <div class="cell-bd">
        <p class="title">{{data.title}}</p>
        <p class="desc">{{data.desc}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          icon: '',
          title: '',
          desc: '',
          mark: '',
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import '@/styles/_cell.scss';

.icon-title{
  display: inline-block;
}
.cell{
  padding-right: vw(18);
  margin: vw(14) 0;
}
.cell-hd{
  position: relative;
  .icon{
    display: block;
    width: vw(68);
  }
  .mark{
    height: vw(28);
    position: absolute;
    top: vw(-14);
    left: 50%;
    transform: translateX(-50%);
  }
}
.cell-bd{
  padding-left: vw(15);
  .title{
    font-size: vw(28);
    font-family: PingFangSC-Semibold, PingFang SC;
    //font-weight: bold;
    color: #1D2233;
    line-height: vw(37);
  }
  .desc{
    margin-top: vw(0);
    font-size: vw(22);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: vw(30);
    min-width: vw(223);
  }
}

</style>