<template>
  <div class="feedback">
    <StateBar />
    <TitleHeader title="品牌反馈" bgcolor="transparent"></TitleHeader>

    <AutoView :footer="true" class="feedback--view">
      <div class="l-panel s-radius__s">
        <label require class="s-label help_text">帮助与反馈</label>
        <div class="pro_desc">
          <p :class="tabIndex === 1 ? 'p_active' : ''" @click="toggleTab(1)">
            品牌意见反馈
          </p>
          <p :class="tabIndex === 2 ? 'p_active' : ''" @click="toggleTab(2)">
            品牌加盟入驻
          </p>
        </div>
        <van-field
          v-model="message"
          class="s-text s-radius__s f-vmt"
          type="textarea"
          maxlength="200"
          :placeholder="textareaPlace"
          show-word-limit
          :disabled="submitting"
        />
        <label require class="s-label help_text">联系人姓名或公司名称</label>
        <van-field
          style="margin-bottom: 5.33vw"
          class="s-input-and"
          v-model="name"
          placeholder="请输入你的姓名"
        />
        <label require class="s-label help_text">联系方式</label>
        <van-field
          type="number"
          maxlength="11"
          class="s-input-and"
          v-model="phone"
          placeholder="请输入手机号"
        />
      </div>

      <!-- <van-button type="info" block :disabled="disabled" :loading="submitting" to="feedback" class="s-radius__s f-vmt" @click="submit">提交</van-button> -->
    </AutoView>

    <!-- <div class="footer">
            <p>客服电话：<a href="tel:400-698-8881">400-698-8881</a></p>
            <SafeHolder />
        </div> -->
    <!-- 底栏提交按钮 -->
    <SoltFooter>
      <!-- v-show="entity"  -->
      <div class="feedback--footer">
        <van-button
          type="info"
          block
          class="s-radius__s btn1"
          @click="submit"
          :disabled="disabled"
          :loading="submitting"
          >立即提交</van-button
        >
      </div>
    </SoltFooter>
  </div>
</template>

<script>
import SafeHolder from "@/components/layout/safeHolder";
import { Toast } from "vant";
export default {
  name: "feedback",
  components: {
    SafeHolder,
  },
  data() {
    return {
      tabIndex: 1,
      textareaPlace: "描述你要反馈的品牌名称以及对应建议",
      message: "",
      phone: "",
      name: "",
      submitting: false, // 提交中
      disabled: false
    };
  },
  // watch: {
  //     message ( val ) {
  //         this.disabled = ! val.trim().length;
  //     },
  // },
  methods: {
    // 切换
    toggleTab(num) {
      this.tabIndex = num;
      this.message = "";
      if (num === 1) this.textareaPlace = "描述你要反馈的品牌名称以及对应建议";
      else this.textareaPlace = "描述你要反馈的品牌加盟以及对应建议";
    },
    submit() {
      Toast.resetDefaultOptions();
      if (this.message.trim() == "") {
        Toast({ message: "请描述你的问题", icon: "warning" });
        return false;
      }
      if (this.name.trim() == "") {
        Toast({ message: "请输入你的姓名", icon: "warning" });
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone.trim())) {
        Toast({ message: "请输入正确的手机号", icon: "warning" });
        return false;
      }
      this.submitting = true;
      // this.$toast( '正在提交...' );
      Toast({ message: "正在提交...", icon: "weapp-nav" });
      this.disabled = true;
      let paramse = {
        suggest: this.message,
        phone: this.phone,
        name: this.name,
        type: this.tabIndex === 1 ? "品牌意见反馈" : "品牌加盟入驻",
      };
      this.HTTP.goodsFeedback(paramse)
        .then((res) => {
          // this.$toast( '已收到您的问题，我们会尽快处理！' );
          Toast({
            message: "已收到您的问题，我们会尽快处理！",
            icon: "success",
          });
          setTimeout(() => {
            this.message = "";
            this.phone = "";
            this.name = "";
            this.submitting = false;
            this.disabled = false;
          }, 2000);
          // this.$router.push( '/success?view=feedback' );
          // 埋点上报
          // this.JSBridge.report( this.JSBridge.REPORT_EVENT.feedback.TAP_SUBMIT_SUC );
        })
        .catch((e) => {
          Toast({ message: "网络不佳T.T请稍候重试", icon: "warning" });
          setTimeout(() => {
            this.submitting = false;
            this.disabled = false;
          }, 2000);
          // 埋点上报
          // this.JSBridge.report( this.JSBridge.REPORT_EVENT.feedback.TAP_SUBMIT_FAL );
        });
    },
  },
  mounted() {
    this.JSBridge.callHandler("setStatebarTheme", "black");
    this.JSBridge.callHandler("switchBackBtn", true);
    this.JSBridge.callHandler("switchNavbar", false);
    window.addEventListener("resize", function () {
      if (
        document.activeElement.tagName === "INPUT" ||
        document.activeElement.tagName === "TEXTAREA"
      ) {
        window.setTimeout(function () {
          if ("scrollIntoView" in document.activeElement) {
            document.activeElement.scrollIntoView();
          } else {
            document.activeElement.scrollIntoViewIfNeeded();
          }
        }, 30);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.feedback {
  width: 100%;
  padding-bottom: 15.4vw;
  height: 100vh;
  background-color: #f7f7f7;
  box-sizing: border-box;
  #{&}--view {
    padding: 4vw;
    .l-panel {
      padding: 3.73334vw 4vw;
    }
    .s-text {
      background: #f7f7f7;
      border-radius: 1.06667vw;
      padding: 2.67vw;
      border: none;
      box-shadow: none;
      margin-bottom: 5.33vw;
      box-sizing: border-box;
      // height: 23.2vw;
    }
    .s-text ::v-deep .van-field__control {
      height: 2vw;
      font-size: $font_373;
      line-height: 5.33334vw;
      &::-webkit-input-placeholder {
        font-size: $font_373;
        line-height: 5.33334vw;
      }
    }
    .s-input-and {
      height: 10.667vw;
      padding: 2.67vw $padding2;
      background: #f7f7f7;
      border-radius: 1.0667vw;
      line-height: 5.333vw;
      font-size: $font_373;
    }
    .help_text {
      font-size: 4.267vw;
      color: #333300;
      font-weight: bold;

      line-height: 6vw;
      margin-bottom: 2.13334vw;
    }
    .help_text::before {
      line-height: 6vw;
      color: #ff5900;
    }
  }
  .pro_desc {
    margin-bottom: 3.2vw;
    font-size: 0;
    line-height: 0;
    p {
      font-size: 3.7334vw;
      font-weight: 400;
      color: #666666;
      line-height: 5.333vw;
      display: inline-block;
      padding: 1.3334vw 4.533vw;
      border: 1px solid #999999;
      &:first-child() {
        border-top-left-radius: 1.0667vw;
        border-bottom-left-radius: 1.0667vw;
        border-right: none;
      }
      &:last-child() {
        border-top-right-radius: 1.0667vw;
        border-bottom-right-radius: 1.0667vw;
        border-left: none;
      }
    }
    p.p_active {
      color: #518cdb;
      background-color: #dfedff;
      border: 1px solid #518cdb;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    p {
      @include flex;
      justify-content: center;
      font-size: $font_320;
      line-height: $font_440;
      padding-bottom: $padding_4;
      color: #aeb1bd;
    }
    a {
      color: #aeb1bd;
    }
  }
  #{&}--footer {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    box-shadow: 0 -1px 0 #ebeaee;
    ::v-deep .van-button {
      height: 100%;
      padding: 0;
      font-size: $font_400;
      line-height: normal;
    }
    .btn1 {
      // float: left;
      width: 100%;
      background-color: #518cdb;
      color: #fff;
    }
  }
}
</style>
