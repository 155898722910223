<template>
	<div>
		<StateBar />
		<TitleHeader title="商铺转让案例" bgcolor="transparent" color="black" :back="true"
			style="border-bottom: 1px solid #F3F4F7;" />
		<AutoView :footer="true">
			<div class="siteSelectionCase">
				<div class="big-title">社区商铺转让</div>
				<div class="small-title"><img src="../../../../static/shopEcology/address-icon.png" alt="">深圳市宝安区西乡大仟里购物中心</div>
				<!-- 	<div class="video-box">
					<PlayVideo src="https://media.swguancha.com/pc-videos/1.mp4" />
				</div> -->
				<img class="img" src="../../../../static/shopEcology/case1-2-1.png" alt="">
				<img class="img" src="../../../../static/shopEcology/case1-2-2.png" alt="">
				<img class="img" src="../../../../static/shopEcology/case1-2-3.png" alt="">

			</div>
		</AutoView>
		<div class="button-bottom-box">
			<div class="right-box" @click="submitMaskClick">
				帮我转让铺
			</div>
		</div>
		<!-- 转让意向 -->
		<transfer-intention-mask v-if="transferIntentionMaskType" @submit="transferIntentionSubmit"
			@close="transferIntentionMaskType = false"></transfer-intention-mask>
	</div>
</template>

<script>
import PlayVideo from '@/components/VideoPlay/index'
import intentionMask from "../components/intention-mask.vue"
import transferIntentionMask from "../components/transfer-intention-mask"
import {
	saveSitingIntention
} from "@/request/shopEcology"
export default {
	data() {
		return {
			isSubmit: false,
			transferIntentionMaskType: false,
		}
	},
	mounted() {

	},
	components: {
		PlayVideo,
		transferIntentionMask
	},
	methods: {
		transferIntentionSubmit(form) {
			if (this.isSubmit) return;
			this.isSubmit = true;
			//转让意向
			saveSitingIntention(form).then(res => {
				this.transferIntentionMaskType = false
				this.swRouter({
					path: '/shopEcology/intentionsubmitSuccessful',
					query: {
						id: res
					}
				})
			}).finally(e => {
				this.isSubmit = false;
			});
		},
		submitMaskClick() {
			// 帮我选址
			this.transferIntentionMaskType = true
		},
	}
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

@function vw($args) {
	@return $args/750*100+vw;
}

.siteSelectionCase {
	padding: vw(42) vw(28) vw(20);
	background: #F2F3F8 url('../../../../static/shopEcology/case2.png') no-repeat;
	background-size: 100% vw(562);
	box-sizing: border-box;

	.big-title {
		height: vw(38);
		font-size: vw(38);
		font-weight: 600;
		line-height: vw(38);
		color: #FFFFFF;
	}

	.small-title {
		height: vw(33);
		font-size: vw(24);
		font-weight: 600;
		line-height: vw(33);
		color: #FFFFFF;
		margin-top: vw(18);
		display: flex;
		margin-bottom: vw(10);

		img {
			width: vw(21);
			height: vw(25);
			margin-right: vw(14);
		}
	}

	// .video-box {
	// 	margin-top: vw(40);
	// }

	.img {
		width: 100%;
		margin-top: vw(20);
	}


}

.button-bottom-box {
	width: 100%;
	padding: vw(15) vw(30) vw(22);
	box-sizing: border-box;
	background: #fff;
	z-index: 300;
	box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);

	.right-box {
		background: #347FFF;
		border-radius: vw(10);
		height: vw(80);
		font-size: vw(30);
		font-weight: 600;
		color: #FFFFFF;
		line-height: vw(80);
		text-align: center;
	}
}
</style>
