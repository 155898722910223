<template>
    <div class="brand-float">
        <div class="lock-vip">
            <ul class="flex-ul">
                <li class="left">
                    <img class="lock-img" src="/static/icon/svg/icon_vip.svg" alt="" />
                </li>
                <li class="center">
                    <p class="tips">开通会员，解锁限制</p>
                    <p class="more">{{ tips }}</p>
                </li>
                <li class="right" @click="unlockVip">
                    <div class="btn">解锁会员</div>
                </li>
            </ul>
            <img class="float-logo" src="/static/icon/svg/icon_vip_bg.svg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tips: {
            type: String,
            default: "查看剩余品牌",
        },
    },
    methods: {
        unlockVip() {
            this.swRouter("/h5/#/member");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.brand-float {
    @include ani;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -1.5vw 2vw rgba(#a7a7a7, 0.2);
    font-size: $font_400;
    font-weight: bold;
    overflow: hidden;
    box-sizing: border-box;

    .lock-vip {
        position: relative;
        bottom: 0;
        width: 100%;
        background: #fff linear-gradient(90deg, rgba(246, 174, 90, 0.3) 0%, rgba(255, 177, 86, 0.72) 100%);
        box-shadow: 0 -1.6vw 2.13vw 0 rgba(167, 167, 167, 0.19);
        padding: 3.2vw 5.33vw;
        box-sizing: border-box;
        .flex-ul {
            display: flex;
            .left {
                flex: 0 0 13.87vw;
                .lock-img {
                    width: 11.2vw;
                    height: 11.2vw;
                }
            }
            .center {
                flex: 1;
                .tips {
                    height: 5.87vw;
                    line-height: 5.87vw;
                    font-size: 4vw;
                    font-weight: bold;
                    color: #333;
                }
                .more {
                    height: 4.8vw;
                    line-height: 4.8vw;
                    font-size: 3.47vw;
                    color: #999;
                    margin-top: 1vw;
                }
            }
            .right {
                flex: 0 0 21.33vw;
                .btn {
                    width: 21.33vw;
                    height: 9.07vw;
                    line-height: 9.07vw;
                    font-size: 3.73vw;
                    font-weight: 500;
                    color: #fff;
                    background: #333;
                    border-radius: 4.27vw;
                    text-align: center;
                }
            }
        }
        .float-logo {
            position: absolute;
            top: 5.33vw;
            left: 46.67vw;
            width: 21.87vw;
            height: 9.6vw;
            opacity: 0.9;
        }
    }
}
</style>
