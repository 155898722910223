<template>
<div class="hot-banner">
    <van-swipe @change="onChange" class="van_swipe_content">
        <!-- v-for="(item,index) in entity.cardInfo.proImage" :key="index" -->
        <!-- <van-swipe-item >
            <div class="mse" id="mse"></div>
        </van-swipe-item> -->
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img class="banner-img" :src="item" alt="">
        </van-swipe-item>
        <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{ bannerList.length }}</div>
        </template>
    </van-swipe>
</div>

</template>


<script>
// import Player from "xgplayer";

    export default {
        data() {
            return {
                current: 0,
            }
        },
        props: {
            bannerList: {
                type: Array
            }
        },
        methods: {
            onChange(index) {
                this.current = index;
            },
        },
        mounted() {
            // this.$nextTick(() => {
            //     new Player({
            //     id: "mse",
            //     // poster: "", // 封面图
            //     url: "https://outin-c48994ae314011ea95a300163e024c6a.oss-cn-shanghai.aliyuncs.com/sv/49d5581a-17a76157afe/49d5581a-17a76157afe.mp4?Expires=1625801344&OSSAccessKeyId=LTAI3DkxtsbUyNYV&Signature=F7PblBuYM3qbcYIK5MnrHcABgNw%3D",
            //     playsinline: true,
            //     "x5-video-player-type": "h5",
            //     "x5-video-player-fullscreen": "true",
            //     closeVideoClick: true,
            //     closeVideoTouch: true,
            //     autoplay: false, // 自动播放
            //     videoInit: true, // 显示首帧
            //     fluid: true, // 流式布局
            //     fullscreenToggle : false, //全屏按钮
            //     // fitVideoSize: "fixWidth",
            //     ignores: ["volume"],
            //   });
            // })
        }
    }

</script>


<style lang="scss" scoped>
@import "@/styles/index.scss";
.hot-banner{
    width: 100%;
    height: 52.27vw;
    .van_swipe_content{
        width: 100%;
        height: 100%;
        .mse{
            height: 100%;
            width: 100%;
        }
        .banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .custom-indicator{
            position: absolute;
            right: 2.67vw;
            bottom: 7.06vw;
            padding: 0.27vw 2.4vw;
            line-height: 4vw;
            font-size: 2.93vw;
            color: #fff;
            border-radius: 0.67vw;
            background: rgba(29, 34, 51, 0.6);
        }
    }
}


</style>