<template>
  <div class="wrapper" v-show="firstLoading">
    <div class="part part-1">
      <VanImage @load="imageLoadDone" class="bg" src="/static/firstOrderActivity/bg.png" />
<!--      <img class="bg" src="/static/firstOrderActivity/bg.png" alt=""/>-->
    </div>
    <div class="part middle-part">
      <VanImage @load="imageLoadDone" class="bg" src="/static/firstOrderActivity/part-1.png" @click="gotoVipShop(89001)" />
      <VanImage @load="imageLoadDone" class="bg" src="/static/firstOrderActivity/part-2.png" @click="gotoDetail(10001, 89002)" />
      <!-- <VanImage @load="imageLoadDone" class="bg" src="/static/firstOrderActivity/part-3.png" @click="gotoDetail(10026, 89003)" /> -->
<!--      <img class="bg" src="/static/firstOrderActivity/part-1.png" alt="" @click="gotoVipShop(89001)" />-->
<!--      <img class="bg" src="/static/firstOrderActivity/part-2.png" alt="" @click="gotoDetail(10001, 89002)" />-->
<!--      <img class="bg" src="/static/firstOrderActivity/part-3.png" alt="" @click="gotoDetail(10026, 89003)" />-->
    </div>
  </div>
</template>

<script>
  let picNum = 0
  export default {
    name: 'first-order-discount-activity',
    data () {
      return {
        firstLoading: false
      };
    },
    created() {
      this.activeId = this.$route.params.bannerid;
    },
    mounted() {
      // 新人领取优惠券
      this.HTTP.getActiveInfo(this.activeId).then((res) => {
        console.log(res);
        const list = res.data.activeItemResList.map(el => el.couponId) || [];
        if (list.length) {
          this.HTTP.batchReceive({
            activeId: this.activeId,
            couponIdList: list,
          })
        }
      }).catch(() => {
        this.$toast( '无效活动id，即将返回首页！' );
        setTimeout(() => {
          // 返回app首页
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            id: "home",
            url: "CHANGE_MAIN_TAB",
            params: null,
            trackData: null,
            isClear: true,
          });
        }, 1000)
      });
    },
    methods: {
      imageLoadDone() {
        picNum++
        this.firstLoading = picNum === 3
      },
      gotoDetail(id, btnId, type = "v2") {
        this.JSBridge.BTNCLICK(10201, "", 89000, btnId);
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: window._appCode >= 300 ? 'BRAND_INTRODUCTION_V3' : "BRAND_INTRODUCTION_V2",
          id: id,
          isClear: null,
        });
      },
      gotoVipShop(btnId) {
        this.JSBridge.BTNCLICK(10201, "", 89000, btnId);
        this.swRouter({
          path: "/h5/#/member",
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    background: url("/static/newUserActivity/bg.png") repeat;
    background-size: 100% auto;
    .bg {
      width: 100%;
    }
    .middle-part {
      padding: 2.667vw 1.6vw;
    }
  }
</style>
