<template>
  <div
    class="coupon-warp"
    :style="{
      backgroundImage: 'url(' + activeBodyImage + ')',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }"
  >
    <ul
      class="coupon-card"
      :style="{
        backgroundImage: 'url(' + (item.receiveStatus == 1 ? couponBgImage[0] : couponBgImage[1]) + ')',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginBottom: '4.53vw',
      }"
      v-for="item in activeItemResList"
      :key="item.couponId"
      @click="getCoupon(item)"
    >
      <li class="li-top">
        <i>{{ item.couponName }}</i>
      </li>
      <li class="li-bottom">{{ item.validDateDesc }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    activeBodyImage: {
      type: String,
      default: null,
    },
    couponBgImage: {
      type: Array,
      default: () => {},
    },
    activeItemResList: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    getCoupon(data) {
      this.$emit("getCoupon", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.coupon-warp {
  margin: 0 auto;
  padding: 2.5vw 0 6vw 0;
  .coupon-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 20.27vw;
    text-align: center;
    li {
      width: 66vw;
    }
    .li-top {
      font-size: 3.73vw;
      color: #fff;
      padding-left: 12.8vw;
      font-weight: bold;
      text-align: left;
      line-height: 5.33vw;
      i {
        font-size: 3.8vw;
        font-weight: bold;
        font-style: normal;
      }
    }
    .li-bottom {
      color: #fff;
      margin-top: 2.13vw;
      padding-left: 12.8vw;
      text-align: left;
      font-size: 2.93vw;
      color: #FFFFFF;
      line-height: 4vw;
    }
  }
}
</style>
