<template>
  <div class="packages">
    <StateBar></StateBar>
    <TitleHeader title="套餐使用明细" bgcolor="transparent" :back="true"></TitleHeader>

    <AutoView :header="true" :footer="false" class="packages--view l-view">
      <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__s">
        <EmptyCard v-if="list.length === 0" type="wallets"></EmptyCard>
        <template v-else>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" @load="loadData">
            <div class="packages--list">
              <UseDetail v-for="(item, index) of list" :item="item" :key="index" />
            </div>
          </van-list>
        </template>
      </van-skeleton>
    </AutoView>
  </div>
</template>

<script>
import UseDetail from "./components/use-detail.vue";

export default {
  name: "packages",
  components: { UseDetail },
  data() {
    return {
      firstLoading: true,
      loading: true,
      dataLoading: false,
      finished: false,
      remote: null,
      list: []
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      let params = {
        // current: 1,
        gmtCreate: this.remote,
        size: 10
      };
      this.HTTP.listOrderPayWithPackage(params)
        .then(res => {
          console.log(res);
          this.firstLoading = false;
          this.loading = false;
          let dataSize = res.data.data && res.data.data.length;
          if (dataSize) {
            this.list = this.list.concat(res.data.data);
          }
          if (this.list.length) {
            this.remote = this.list[this.list.length - 1].payTime;
          }
          this.finished = dataSize < 10;
        })
        .catch(e => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
          this.finished = true;
        });
    }
  },
  created() {
    this.loadData();
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.packages {
  height: 100vh;
  background-color: $c-pageBg;
}
</style>
