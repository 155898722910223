<template> 
    <div class="wrapper">
        <div class="positionContent">
            <div class="left"  @click="selectCityHandle">
                <div class="icon"></div>
                <div class="city">{{ city }}</div>
                <div class="moreIcon"></div>
            </div>
            <div class="right">
                <van-field style="background-color: #fff" @input="searchAddress" clearable v-model="value" type="text" placeholder="搜索商圈或区域" />
            </div>
        </div>
        <div class="list-wrapper">
            <template v-if="value">
                <LoadMore :onLoadMore="onLoadMore" :enableLoadMore="enableLoadMore" v-if="positionList.length">
                    <div class="item" v-for="(item, index) in positionList" :key="index" @click="selectAddress(item)">
                        <div class="left">
                            <div class="name">
                                {{item.name}}
                            </div>
                            <div class="address">
                                {{item.pname + item.cityname + item.adname +  item.address}}
                            </div>
                        </div>
                    </div>
                </LoadMore>
                <div class="history-empty" v-else>
                    支持搜索住宅、写字楼、交通站点等位置信息
                </div>
            </template>
            <template v-else>
                <template v-if="history.length">
                    <div class="title">
                        搜索历史
                    </div>
                    <div class="item" v-for="(item, index) in history" :key="index" @click="selectAddress(item)">
                        <div class="left">
                            <div class="name">
                                {{item.name}}
                            </div>
                            <div class="address">
                                {{item.pname + item.cityname + item.adname +  item.address}}
                            </div>
                        </div>
                        <div class="right">
                            <img src="/static/element/icon_go.png" alt="">
                        </div>
                    </div>
                    <div class="clean" @click="cleanHistory">
                        <img src="/static/element/icon_clean.png" alt="">
                        <div>清空历史记录</div>
                    </div>
                </template>
                <div class="history-empty" v-else>
                    支持搜索住宅、写字楼、交通站点等位置信息
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import LoadMap from "@/components/baseMap/loadMap";
import LoadMore from './components/loadMore.vue';
import { setLocalStorage, getLocalStorage, removeLocalStorage } from "@/utils/index";
export default {
    data() {
        return {
            city: "",
            value: "",
            positionList: [],
            enableLoadMore: true,
            searchOpt: {
                pageIndex: 1,
                pageSize: 10
            },
            autoComplete: null,
            keyWord: "",
            history: [],
            item: null
        }
    },
    components: {
        LoadMore
    },
    mounted() {
        this.HTTP.elemeSubmit({
            platform: sessionStorage.getItem("platform"),
            track: JSON.stringify([{
                pageId: "10185",
                timestamp: new Date().getTime()
            }])
        })
        this.city = this.$route.params.city
        if(this.$route.query.city) {
            this.city = this.$route.query.city
        }
        this.history = getLocalStorage("history") || [] 
    },
    methods: {
        selectCityHandle() {
            this.swRouter("/element/selectCity")
        },
        searchAddress(e) {
            this.keyWord = e
            this.positionList = []
            LoadMap().then(() => {
                this.AMapInit();
            });
        },
        
        onLoadMore(done) {
            setTimeout(()=>{
                if(!this.enableLoadMore) {
                    return
                }
                this.autoComplete.setPageIndex(this.searchOpt.pageIndex + 1);
                this.searchPOI();
                done();
            }, 200)
        },
        selectAddress(item) {
            this.item = item
            const result = this.history.some(items => items.address === item.address)
            // reslut 为 true 说明历史数组里有这条数据，直接跳转
            if(!result) {
                this.history.push(item)
                setLocalStorage("history", this.history)
            }
            this.$router.back(-1)
        },
        AMapInit() { 
            let that = this
            console.log(that.searchOpt.pageIndex)
            let auto = new AMap.plugin('AMap.PlaceSearch', function(){
                // 实例化Autocomplete
                let autoOptions = {
                    city: that.city,
                    extensions: "all",
                    ...that.searchOpt
                }
                that.autoComplete = new AMap.PlaceSearch(autoOptions);
                that.searchPOI()
            })
        },
        searchPOI() {
            let that = this
            that.autoComplete.search(that.keyWord, function(status, result) {
                // 搜索成功时，result即是对应的匹配数据
                console.log(result)
                if(result.poiList.pois.length < that.searchOpt.pageSize) {
                    that.enableLoadMore = false
                }
                if(that.positionList.length === 0) {
                    that.positionList = result.poiList.pois
                } else {
                    that.positionList = that.positionList.concat(result.poiList.pois)
                }
            })
        },
        cleanHistory() {
            removeLocalStorage("history")
            this.history = []
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name == 'search') {
            to.query.item = this.item;
        }
        next();
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    padding: 4vw;
    background-color: #F8F8F9;
    overflow-y: hidden;
}
.positionContent {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10vw;
    background: #fff;
    border-radius: 1.333vw;
    border: 0.133vw solid #ebeaee;
    padding: 2vw;
    margin-bottom: 4vw;
    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5.467vw;
        padding-right: 2.8vw;
        border-right: 0.267vw solid #dadada;
        .icon {
            width: 4.533vw;
            height: 4.533vw;
            background-image: url("/static/icon/location_icon.png");
            background-size: 100% 100%;
            margin-right: 1.067vw;
        }
        .city {
            font-size: 3.467vw;
            color: #666666;
            margin-right: 2.133vw;
        }
        .moreIcon {
            width: 2.267vw;
            height: 1.733vw;
            background-image: url("/static/icon/choice_icon.png");
            background-size: 100% 100%;
        }
    }
    .right {
        flex: 1;
        font-size: 3.467vw;
        font-weight: bold;
        color: #333333;
        background-color: #fff;
        padding-left: 2.8vw;
    }
}
.list-wrapper {
    height: calc(100vh - 26.5vw);
    background-color: #fff;
    padding: 0 4vw;
    overflow: auto;
    .title {
        font-size: 3.2vw; 
        padding: 4vw 0;
        color: #8E9099;
        border-bottom: 0.0133vw solid #EDF1FB;
    }
    .item {
        border-bottom: 0.0133vw solid #EDF1FB;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 4vw;
        color: #1D2233;
        font-weight: bold;
        line-height: 5.6vw;
        padding: 4vw 0;
        .address {
            font-size: 3.2vw; 
            color: #8E9099;
            line-height: 4.4vw;
            margin-top: 1.067vw;
            font-weight: normal;
            max-width: 79vw;
        }
        .right {
            width: 4.667vw;
            height: 4.667vw;
            img {
                width: 100%;
            }
        }
    }
    .clean {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8E9099;
        font-size: 3.2vw;
        line-height: 3.2vw;
        margin-top: 4.667vw;
        img {
            width: 4vw;
            height: 4vw;
            margin-right: 0.133vw;
        }
    }
    .history-empty {
        padding-top: 6vw;
        text-align: center;
        font-size: 3.733vw;
        color: #8E9099;
    }
}
</style>