<template>
    <div class="header">
        <van-row style="height: 100%">
            <van-col span="16" class="header--title l-flex">{{ title }}</van-col>
            <!-- <van-col span="8" class="header--city l-flex l-flex__r">{{ city }}<van-icon class-prefix="@fa" name="angle-down" class="f-ml05" style="opacity: .6" /></van-col> -->
        </van-row>
        <van-row style="height: 50%">
            <van-col span="12" class="header--subtitle"><em/>{{ subtitle }}</van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'IndexHeader',
    data () {
        return {
            title   : this.JSBridge.appName(),
            subtitle: '生意人的数据军师',
            city    : '深圳',
        };
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.header {
    height: $headerHeight;
    // background-color: rgba(black, .1);
    #{&}--title {
        height: 100%;
        padding-left: 6.8vw;
        color: white;
        font-size: $font_587;
        font-weight: bold;
    }
    #{&}--subtitle {
        height: 100%;
        padding-left: 6.8vw;
        // opacity: .8;

        // em {
        //     display: block;
        //     width: 7.73vw;
        //     height: 60%;
        //     box-shadow: inset 0 1px white;
        // }
        font-size: $font_320;
        // line-height: $font_440;
        text-align: left;
        color: white;
        opacity: .66;
    }
    #{&}--city {
        height: 100%;
        padding-right: 1rem;
        color: white;
        font-size: .8rem;
    }
}
</style>
