<template>
    <div class="dialog-wrap">
        <div class="dialog-content">
            <van-icon class="close-icon" name="cross" @click="close"/>
            <p class="title">如何使用权益</p>
            <div class="form-wrap">
                <slot>sdfasf</slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dialog',
    props: ['visible'],
    methods: {
        close () {
            this.$emit('update:visible', false)
        },
    }
};
</script>

<style lang="scss" scoped>
.dialog-wrap{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    *{
        font-size: vw(28);
    }
    .dialog-content{
        width: vw(670);
        background: #fff;
        border-radius: vw(20);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 vw(30);
        .close-icon{
            position: absolute;
            top: vw(20);
            right: vw(20);
            font-weight: bold;
        }
        .title{
            padding: vw(30);
            font-size: vw(34);
            font-weight: 600;
            color: #333333;
            line-height: vw(48);
            text-align: center;
        }
        .input-wrap{
            position: relative;
            height: vw(100);
            background: #F7F7F7;
            border-radius: vw(10);
            border: 1px solid #EBEAEE;
            margin-bottom: vw(20);
            input{
                height: 100%;
                width: 100%;
                background: none;
                padding: vw(30) vw(200) vw(30) vw(30);
            }
            .vcode{
                font-size: vw(28);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF5900;
                line-height: vw(100);
                position: absolute;
                right: vw(30);
                background: none;
                height: vw(100);
            }
        }
        .btn-wrap{
            padding: vw(14) 0 vw(34) 0;
            button{
                width: 100%;
                height: vw(90);
                background: linear-gradient(90deg, #FF5900 0%, #FF8000 100%);
                border-radius: vw(45);
                font-size: vw(30);
                font-weight: 500;
                color: #FFFFFF;
                line-height: 42px;
                text-align: center;
            }
        }
    }
}
</style>