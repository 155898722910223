<template>
    <div>
        <div class="element-content" v-if="loading">
            <div class="user-info">
                <img class="customer" @click="serviceClick" src="/static/element/customer-service.png" alt="" />
                <div class="user-wrapper">
                    <div class="left">
                        <img class="user_logo" :src="userInfo.avatar !== 'null' ? userInfo.avatar : defaultAvatar" alt="" />
                        <img v-if="isVip" class="vip_icon" src="/static/icon/vip_icon.png" alt="" />
                    </div>
                    <div class="right">
                        <h3>{{ userInfo.userName }}</h3>
                        <p v-if="type === '1'">{{ isVip ? `${userInfo.member.endTime}` : "当前未开通VIP会员" }}</p>
                        <p v-else>{{ `您已购买开店选址评估服务剩余次数：${packageInfo ? packageInfo.restExchangeTimes + 1 : 0}次` }}</p>
                    </div>
                </div>
                <div class="tabs">
                    <div class="my-report line" @click="jumpToReport">
                        <div class="tabs-left">
                            <div class="num">{{ userInfo.report ? userInfo.report.value : 0 }}</div>
                            <div>我的报告</div>
                        </div>
                        <img class="tabs-right" src="/static/element/arrow.png" alt="" />
                    </div>
                    <div class="my-order" @click="jumpToOrder">
                        <div class="tabs-left">
                            <div class="num">{{ orderNum }}</div>
                            <div>我的订单</div>
                        </div>
                        <img class="tabs-right" src="/static/element/arrow.png" alt="" />
                    </div>
                </div>
            </div>
            <div class="card-wrapper">
                <img
                    class="img"
                    @click="gotoSKUDetail"
                    :src="packageInfo ? '/static/element/siting.png' : '/static/element/siting_disable.png'"
                    alt=""
                    v-if="type === '2'"
                />
                <div class="item" v-else>
                    <h3>数据查询</h3>
                    <div class="label label_vip" :class="isVip ? 'label_success' : 'label_expire'">
                        <img class="label-icon" :src="isVip ? '/static/element/success.png' : '/static/element/expire.png'" alt="">
                        <div>{{isVip ? "您已购买此服务" : "会员已到期"}}</div>
                    </div>
                    <div class="ele-content">
                        <div class="ele-item" v-for="item in searchList" :key="item.goodsId">
                            <div class="left">
                                <div class="title">{{ item.title }}</div>
                                <div class="btn" :class="isVip ? '' : 'btn_not_vip'" @click="gotoSearchDetail(item.goodsId)">
                                    {{ isVip ? "去查询" : "去开启" }}
                                </div>
                            </div>
                            <img class="right" :src="item.url" alt="" />
                        </div>
                    </div>
                </div>
                <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
                    <van-swipe-item @click="gotoDetail" v-if="type === '1'">
                        <img class="swiper-item" src="/static/element/swiper_siting.png" alt="" />
                    </van-swipe-item>
                    <van-swipe-item @click="gotoDetail" v-if="type === '2'">
                        <img class="swiper-item" src="/static/element/swiper_vip.png" alt="" />
                    </van-swipe-item>
                    <van-swipe-item v-for="item in swiperList" :key="item.url" @click="bannerClick">
                        <img class="swiper-item" :src="item.url" alt="" />
                    </van-swipe-item>
                </van-swipe>
                <img class="img" @click="JumpToCustom" src="/static/element/custom.png" alt="" />
                <div class="item" @click="serviceClick">
                    <h3>客户服务</h3>
                    <div class="label label_service">
                        <img class="label-icon" src="/static/element/customer-service.png" alt="">
                        <div>咨询客服</div>
                    </div>
                    <div class="custom-service">
                        <div class="custom-item" v-for="item in customList" :key="item.icon">
                            <img class="icon" :src="item.icon" alt="" />
                            <div class="title">{{ item.title }}</div>
                            <div class="tip">{{ item.tip }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <van-popup v-model="show" closeable>
                <div class="service">
                    <div class="service-top">
                        <img class="service-icon" src="/static/element/service-icon.png" alt="" />
                        <div class="title">联系客服</div>
                        <div class="tip">如有疑问，请联系客服</div>
                    </div>
                    <div class="service-label" v-for="item in serviceInfo" :key="item.url">
                        <img class="" :src="item.url" alt="" />
                        <div class="des">
                            <div class="label">{{ item.label }}</div>
                            <div class="value">{{ item.value }}</div>
                        </div>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import { setCookie, getCookie } from "@/utils/index.js"

export default {
    data() {
        return {
            defaultAvatar: "/static/element/default.png",
            searchList: [
                { goodsId: "10032", title: "周边查询", url: "/static/element/search_icon1.png" },
                { goodsId: "10018", title: "行业热力", url: "/static/element/search_icon2.png" },
                { goodsId: "10019", title: "人口热力", url: "/static/element/search_icon3.png" },
                { goodsId: "10030", title: "商场查询", url: "/static/element/search_icon4.png" },
            ],
            customList: [
                { icon: "/static/element/service_icon1.png", title: "疑难解答", tip: "解决产品使用问题" },
                { icon: "/static/element/service_icon2.png", title: "选址交流", tip: "解决产品使用问题" },
                { icon: "/static/element/service_icon3.png", title: "选址学习", tip: "免费选址课程" },
            ],
            swiperList: [{ url: "/static/element/swiper.png" }],
            serviceInfo: [
                { url: "/static/element/time-icon.png", label: "服务时间：", value: "8:30-22:00" },
                { url: "/static/element/tel-icon.png", label: "联系电话：", value: "0755-28796279" },
            ],
            userInfo: {
                userName: "",
                avatar: "",
                member: null,
                report: null,
            },
            show: false,
            loading: false,
            isVip: false,
            packageInfo: null,
            type: "",
            orderNum: 0,
        };
    },
    async mounted() {
        sessionStorage.setItem("platform", "eleme");
        sessionStorage.setItem("type", this.$route.params.id);
        this.buildPoint(this.$route.params.id);
        let code = window.location.href.split("#")[1].indexOf("?") > -1 ? window.location.href.split("?")[1].split("&")[0].split("=")[1] : "";
        let state = window.location.href.split("#")[1].indexOf("?") > -1 ? window.location.href.split("?")[1].split("&")[1].split("=")[1] : "";
        this.type = this.$route.params.id;
        if (code && !getCookie(`${this.type}_token`)) {
            const res = await this.HTTP.handleLogin({
                code: code,
                state: state,
                type: this.type,
            });
            console.log(res);
            if (res.code === 0) {
                setCookie(`${this.type}_token`, res.data.session);
                setCookie("userName", res.data.username);
                setCookie("avatar", res.data.avatar);
            }
        }
        if (!getCookie(`${this.type}_token`)) {
            if (this.type === "1") {
                location.href = `https://open-api.shop.ele.me/authorize?response_type=code&scope=all&state=sscm&client_id=zJW53JAukO&redirect_uri=https%3A%2F%2Fwebclient.sscanmou.com%2F%23%2Felement%2Fhome%2F1`;
            } else {
                location.href = `https://open-api.shop.ele.me/authorize?response_type=code&scope=all&state=sscm&client_id=MskKMDxHHy&redirect_uri=https%3A%2F%2Fwebclient.sscanmou.com%2F%23%2Felement%2Fhome%2F2`;
            }
            return
        }
        this.loading = true;
        this.HTTP.setAuth(getCookie(`${this.type}_token`));
        this.userInfo.userName = getCookie("userName");
        this.userInfo.avatar = getCookie("avatar");
        this.loadData();
        this.getReportInfo();
        this.getPackageInfo("10001");
        this.getOrderList(this.type);
    },
    methods: {
        buildPoint(type) {
            // 埋点
            this.HTTP.elemeSubmit({
                platform: sessionStorage.getItem("platform"),
                track: JSON.stringify([
                    {
                        pageId: "10180",
                        timestamp: new Date().getTime(),
                        refId: type
                    },
                ]),
            });
        },
        getReportInfo() {
            this.HTTP.getReportInfo().then((res) => {
                if (res.data.code == 0) {
                    this.userInfo.report = res.data.data.userDataLinkList && res.data.data.userDataLinkList[0];
                }
            });
        },
        getPackageInfo(goodsId) {
            this.HTTP.getPackageInfo(goodsId).then((res) => {
                if (res.data.code == 0) {
                    this.packageInfo = res.data.data.pkgShowResp;
                }
            });
        },
        getOrderList(type) {
            this.HTTP.elePackageList({
                current: 1,
                size: 10,
                type: type === "2" ? "3" : type,
            }).then((res) => {
                this.orderNum = res.total;
            });
        },
        loadData() {
            this.HTTP.getVipInfo().then((res) => {
                this.isVip = res.userDetail ? res.userDetail.member : false;
                this.userInfo.member = res.userDetail ? res.userDetail.memberInfo : null;
            });
        },
        gotoSKUDetail() {
            if (!this.packageInfo) {
                location.href = "https://r.ele.me/alsc-m-service-market/detail/31267f843bdf768d632c4a9f56f17f0d";
                return;
            }
            this.swRouter({
                path: "/element/search",
                query: {
                    goodsId: "10001",
                },
            });
        },

        gotoSearchDetail(goodsId) {
            if (!this.isVip) {
                location.href = "https://r.ele.me/alsc-m-service-market/detail/6da10edf3afb7ff44e49018b9b1b54d0";
                return;
            }
            this.swRouter({
                path: goodsId === "10030" ? "/element/shopSearch" : "/element/search",
                query: {
                    goodsId: goodsId,
                },
            });
        },
        jumpToReport() {
            const linkUrl = this.userInfo.report && this.userInfo.report.link.url.split("#")[1];
            linkUrl && this.swRouter(linkUrl);
        },
        jumpToOrder() {
            this.swRouter("/element/order");
        },
        JumpToCustom() {
            this.swRouter("/element/customLanding");
        },
        bannerClick() {
            this.swRouter("/community/storeMap");
        },
        gotoDetail() {
            if(this.type === "1") {
                location.href = "https://r.ele.me/alsc-m-service-market/detail/31267f843bdf768d632c4a9f56f17f0d";
            } else {
                location.href = "https://r.ele.me/alsc-m-service-market/detail/6da10edf3afb7ff44e49018b9b1b54d0";
            }
        },
        serviceClick() {
            this.show = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.element-content {
    background-color: #f8f8f9;
    height: 100vh;
}
.user-info {
    background: url("/static/element/user_bg.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 41.667vw;
    position: relative;
    display: inline-block;
    padding: 0 6.67vw;
    box-sizing: border-box;
    .customer {
        position: absolute;
        right: 4vw;
        top: 4vw;
        width: 5.867vw;
        height: 5.867vw;
    }
    .user-wrapper {
        margin-top: 8.533vw;
        display: flex;
        align-items: center;
        .left {
            width: 15.47vw;
            height: 15.47vw;
            border-radius: 7.73vw;
            position: relative;
            margin-right: 4.67vw;
            .user_logo {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 0.533vw solid #ffae63;
            }
            .vip_icon {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 2;
                width: 5.6vw;
                height: 5.6vw;
            }
        }
        .right {
            flex: 1;
            h3 {
                font-size: 5.33vw;
                font-weight: bold;
                color: #fff;
                line-height: 6vw;
            }
            p {
                margin-top: 1.2vw;
                font-size: 3.47vw;
                color: rgba(255, 255, 255, 0.6);
                line-height: 4.93vw;
            }
        }
    }
    .tabs {
        position: absolute;
        bottom: -10vw;
        left: 4vw;
        width: 92vw;
        height: 21.33vw;
        background-color: #fff;
        box-shadow: 0 0.226vw 0.24vw rgba(29, 34, 51, 0.08);
        border-radius: 1.33vw;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            height: 10.133vw;
            width: 50%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            .tabs-left {
                text-align: center;
                font-size: 3.733vw;
                .num {
                    font-size: 5.33vw;
                    font-weight: bold;
                    line-height: 7.46vw;
                }
            }
            .tabs-right {
                width: 10.133vw;
                height: 10.133vw;
                margin-left: 5vw;
            }
        }

        .line {
            border-right: 0.133vw solid rgba(142, 144, 153, 0.3);
        }
    }
}
.item{
    background: #fff;
    border-radius: 2.67vw;
    width: 84vw;
    padding: 4vw;
    height: auto;
    margin-bottom: 3.2vw;
    position: relative;
    box-shadow: 0 0.226vw 0.24vw rgba(29, 34, 51, 0.08);
    h3{
        font-weight: bold;
        color: #333333;
        font-size: 4.267vw;
        line-height: 5.6vw;
        position: relative;
        text-indent: 2.5vw;
        &::before {
            content: "";
            width: 1.067vw;
            height: 4.133vw;
            background-image: linear-gradient(#59B7FF, #347FFF);
            position: absolute;
            left: -1vw;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 0.667vw;
        }
    }
    .label_vip {
        top: 4.5vw !important;
        right: 2.667vw !important;
        img {
            width: 3.33vw;
            height: 3.33vw;
            margin-right: 1.5vw;
        }
    }
    .label_success {
        color:#216DEF;

    }
    .label_expire {
        color: #4D5C77;
    }
    .label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 3.5vw;
        font-weight: bold;
        font-size: 3.2vw;
        line-height: 4.4vw;
    }
    .label_service {
        color: #fff;
        background-color: #347FFF;
        width: 23.867vw;
        height: 7.067vw;
        border-top-left-radius: 13.33vw;
        border-bottom-left-radius: 13.33vw;
        .label-icon {
            width: 4.4vw;
            height: 4.4vw;
            margin-top: 0.5vw;
            margin-right: 1.5vw;
        }
    }
}
.card-wrapper {
    margin-top: 10vw;
    padding: 4vw;
    .img {
        width: 100%;
        margin-bottom: 2.667vw;
    }
    .ele-content {
        display: flex;
        margin-top: 4vw;
        flex-wrap: wrap;
        .ele-item {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .left {
                margin-right: 5.33vw;
                .title {
                    color: #1d2233;
                    font-size: 3.733vw;
                    margin-bottom: 1.6vw;
                }
                .btn {
                    background-color: #347fff;
                    border-radius: 2.4vw;
                    color: #fff;
                    font-size: 2.667vw;
                    text-align: center;
                    line-height: 4.8vw;
                    width: 13.33vw;
                    height: 4.8vw;
                }
                .btn_not_vip {
                    background-color: rgba(52, 127, 255, 0.1);
                    color: #347fff;
                }
            }
            .right {
                width: 13.33vw;
                height: 11.733vw;
            }
            &:nth-child(-n + 2) {
                margin-bottom: 8.8vw;
            }
        }
    }
    .swipe {
        width: 100%;
        margin-bottom: 3.2vw;
        .swiper-item {
            width: 100%;
        }
    }
    .custom-service {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .custom-item {
            width: 33%;
            height: 22.667vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon {
                width: 6.133vw;
                height: 6.133vw;
                margin-top: 3.467vw;
                margin-bottom: 1.33vw;
            }
            .title {
                font-size: 3.733vw;
                font-weight: bold;
                color: #1d2233;
                line-height: 5.33vw;
            }
            .tip {
                font-size: 2.667vw;
                color: #8e9099;
                line-height: 3.733vw;
            }
        }
    }
}
::v-deep .van-popup {
    border-radius: 3.2vw;
}

::v-deep .van-button {
    background-color: #347fff !important;
}
::v-deep .van-goods-action-button--danger {
    background: none;
}
.service {
    width: 73.33vw;
    height: 89.06vw;
    background-image: linear-gradient(#e8f0ff, #ffffff);
    .service-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6.533vw;
        .service-icon {
            width: 16vw;
            height: 16vw;
            margin-top: 5.33vw;
        }
        .title {
            color: #1d2233;
            font-weight: bold;
            font-size: 5.33vw;
            line-height: 7.467vw;
            margin: 3.2vw 0 1.067vw 0;
        }
        .tip {
            color: #8e9099;
            font-size: 3.733vw;
        }
    }
    .service-label {
        display: flex;
        align-items: center;
        margin-bottom: 4.533vw;
        margin-left: 10.8vw;
        img {
            width: 13.6vw;
            height: 13.6vw;
            margin-right: 3.33vw;
        }
        .des {
            .label {
                color: #8e9099;
                font-size: 3.733vw;
            }
            .value {
                color: #1d2233;
                line-height: 6.4vw;
                font-size: 4.533vw;
            }
        }
    }
}
</style>
