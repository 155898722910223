<template>
    <div class="page-container">
        <StateBar />
        <TitleHeader title="品牌信息" bgcolor="transparent" color="black" :back="true">
          <div v-if="bgmBase.joinBrandCode" class="right" slot="right" @click="swRouter('/h5/#/brand/detail/' + bgmBase.joinBrandCode)">
            品牌咨询
          </div>
        </TitleHeader>
        <div ref="content" style="height: 0px"></div>

        <AutoView ref="view" :header="false" :footer="true">
            <div class="body-container" v-if="!loading">
                <BrandHeader :bgmData="bgmBase" />
                <ShopDistribute :brandCode="reportId" :brandName="bgmBase.name" :bgmData="brandDistribute" :provinceData="provinceDataList" />
                <CityRatio :bgmData="cityLevelDataList" :cityDataList="cityDataList" />
                <SequenceChart :bgmData="brandDpShopMonthOpenList" />
                <!-- <OrderAnalysis /> -->
                <CustomerScore :bgmData="comment" />
                <CustomerEvaluate :bgmData="wordCloudList" />
                <RecommendFood :bgmData="userMenuCommandList" />
            </div>
        </AutoView>
        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import BrandHeader from "./components/brandHeader";
import ShopDistribute from "./develop/shop-distribute.vue";
import CityRatio from "./develop/city-ratio.vue";
import SequenceChart from "./develop/sequence-chart.vue";

import OrderAnalysis from "./analysis/order-analysis.vue";
import CustomerScore from "./analysis/customer-score.vue";
import CustomerEvaluate from "./analysis/customer-evaluate.vue";
import RecommendFood from "./analysis/recommend-food.vue";

export default {
    components: {
        BrandHeader,
        ShopDistribute,
        CityRatio,
        SequenceChart,
        OrderAnalysis,
        CustomerScore,
        CustomerEvaluate,
        RecommendFood,
    },
    data() {
        return {
            loading: true,
            // 基础信息
            bgmBase: {},
            brandDistribute: {},
            provinceDataList: [],
            cityLevelDataList: [],
            cityDataList: [],
            brandDpShopMonthOpenList: [],
            comment: {},
            wordCloudList: [],
            userMenuCommandList: [],
            // 用户权益
            reportId: "",
        };
    },
    mounted() {
        let reportId = this.$route.query.reportId;
        if (reportId) {
            this.reportId = reportId;
            this.getReportData(reportId);
        }
    },
    methods: {
        getReportData(reportId) {
            this.HTTP.searchBrandReport(reportId)
                .then((data) => {
                    console.log(data);
                    let reportData = data.data;
                    this.loadReportData(reportData);
                })
                .catch((e) => {
                    console.log("error:", e);
                });
        },
        loadReportData(reportData) {
            if (reportData == undefined) {
                console.error("loadReportData error. reportData is undefined.");
                return;
            }
            let {
                brandBase,
                brandDistribute,
                provinceDataList,
                cityLevelDataList,
                cityDataList,
                brandDpShopMonthOpenList,
                comment,
                wordCloudList,
                userMenuCommandList,
            } = reportData;
            this.bgmBase = brandBase;
            this.brandDistribute = brandDistribute;
            this.provinceDataList = provinceDataList || [];
            this.cityLevelDataList = cityLevelDataList || [];
            this.cityDataList = cityDataList || [];
            this.brandDpShopMonthOpenList = brandDpShopMonthOpenList || [];
            if (comment && comment.feedbackRate) {
              comment.feedbackRate = Number((comment.feedbackRate * 100).toFixed(2))
            }
            if (comment && comment.badReviewRate) {
              comment.badReviewRate = Number((comment.badReviewRate * 100).toFixed(2))
            }
            this.comment = comment;
            this.wordCloudList = wordCloudList || [];
            this.userMenuCommandList = userMenuCommandList || [];

            this.JSBridge.callHandler(
                "putString",
                JSON.stringify({
                    key: "isMember",
                    value: reportData.isMember,
                })
            );
            this.loading = false;
        },
        loadInputData(inputData) {
            let inputObj = inputData;
            if (typeof inputData == "string") {
                inputObj = JSON.parse(inputObj);
            }
            for (let i = 0; i < inputObj.length; ++i) {
                let item = inputObj[i];
                let itemKey = item.code;
                let itemValue = item.value;
                this.input[itemKey] = itemValue;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page-container {
    height: 100vh;
    overflow: hidden;
    .right {
      position: relative;
      @include flexColumn;
      width: 100%;
      height: $headerHeight;
      align-items: flex-end;
      color: #347FFF;
      font-size: vw(26);
    }
    .body-container {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        background: #f8f8f9;
    }
}
</style>
