<template>
    <div class="card_banner">
        <div class="card_banner_list">
            <div class="wrap" :style="widthUnitParams" v-for="(val , index) in imgArr" :key="index">
                <img :style="heightUnitParams" @click="HandleclickImg(index)" class="scroll-img" :src="val" alt="">
                <!-- <p v-show="type === 3">2021-10-12 13:44</p> -->
            </div>
        </div>
    </div>
</template>


<script>
    import { ImagePreview } from "vant";
    export default {
        name: "scrollNewSwiper",
        props: {
            widthParams: {
                type: [Number, String],
                default: 0
            },
            heightParams: {
                type: [Number, String],
                default: 0
            },
            type: {
                type: Number,
                default: 0
            },
            imgArr: {
                type: Array
            }
        },
        computed: {
            widthUnitParams() {
                return {
                    'width': this.widthParams + 'vw',
                }
            },
            heightUnitParams() {
                if(this.type === 3) {
                    return {
                        // 'height': (this.heightParams - 6.53) + 'vw',
                        'height': this.heightParams + 'vw',
                    }
                } else {
                    return {
                        'height': this.heightParams + 'vw',
                    }
                }
                
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            HandleclickImg(index) {
                ImagePreview({
                    images: this.imgArr,
                    startPosition: index,
                    onClose() {
                    // do something
                    }
                });
            }  
        },
    }

</script>

<style lang="scss" scoped>
    @import "@/styles/index.scss";
    // 门店/产品照片
    .card_banner{
        width: 100%;
        height: auto;
        overflow-x: auto;
        margin-top: 2.67vw;
        .card_banner_list{
            // width: auto;
            // height: val(height);
            font-size: 0;
            white-space: nowrap;
            .wrap{
                width: val(width);
                height: auto;
                display: inline-block;
                overflow: hidden;
                box-sizing: border-box;
                margin-right: 2.67vw;
                &:last-child{
                    margin-right: 0;
                }
                .scroll-img{
                    height: val(height);
                    border-radius: 2px;
                    box-shadow: 0px 0px 2.13vw 0px rgba(0, 0, 0, 0.1);
                    width: 100%;
                    object-fit: cover;
                }
                p{
                    font-size: 3.47vw;
                    margin-top: 1.6vw;
                    text-align: left;
                    line-height: 4.93vw;
                    color: #1D2233;
                }
            }
            
        }
    }
</style>