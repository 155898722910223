<template>
    <div class="page">
        <StateBar bgcolor="white"></StateBar>
        <!-- <SearchBar ref="compSearchBar" :back="true" :icon="true" :placeholder="placeholder" :on="false" @change="change" @entry="search" @clear="clear">
            <div slot="right" class="r" @click="showRegionPicker = true"><i class="icon icon-location"></i><p class="f-tof">{{ field.city }}</p></div>
        </SearchBar> -->
        <TitleHeader title="对手监测" bgcolor="white" :back="true">
        </TitleHeader>

        <AutoView :header="true" :footer="false" class="page--view l-view">
            <CityPickBar :city="field.city" @input-change="handleInputChange" @city-change="handleCityChange" />
            <!-- <van-skeleton :loading="firstLoading" title :animate="true" :row="4" class="s-skeleton s-radius__s">
                <EmptyCard v-if="! isError && ( ! list || list.length === 0 )" type="location"></EmptyCard>
                <EmptyCard v-else-if="isError" type="hot"></EmptyCard>
                <template v-else>
                    <p v-show="! searched" class="s-label">-&nbsp;附近店铺&nbsp;-</p>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <ShopCard v-for="( item, index ) in list" :key="item.id" :class="{ 'f-vmt' : index > 0 }"
                            mode="card"
                            :item="item"
                            :showSub="true"
                            @tap="onTap"
                            @tapSub="onTap" />
                    </van-list>
                </template>
            </van-skeleton> -->
            <van-skeleton :loading="firstLoading" title :animate="true" :row="4" class="s-skeleton s-radius__s">
                <EmptyCard v-if="! isError && ( field.city === '定位中' )" type="location"></EmptyCard>
                <EmptyCard v-if="isError" type="hot"></EmptyCard>
                <template v-else>
                    <!-- <ShopNearby v-if="! form.keyword" :list="recommendShopList" @shop-error="handleShopError" /> -->
                    <ShopNearbyStatic v-if="! form.keyword"  />
                    <van-list v-else v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <ShopCard v-for="( item, index ) in list" :key="item.id" :class="{ 'f-vmt' : index > 0 }"
                            mode="card"
                            :item="item"
                            :showSub="true"
                            @tap="onTap"
                            @tapSub="onTap" />
                    </van-list>
                </template>

            </van-skeleton>
        </AutoView>

        <!-- 省市区选择 -->
        <OpenCityPicker type="v2all" v-model="showRegionPicker" @confirm="getCityZone"/>
    </div>
</template>

<script>
import OpenCityPicker from '../home/components/openCityPicker';
import SearchBar from '@/components/header/search';
import ShopCard from '@/components/card/shop';
import ShopNearbyStatic from '@/components/card/shopNearbyStatic';
// import CityData from '~/data/city-data.json';
import CityData from '/static/data/100000_center_sub_3.json';

import CityPickBar from '@/components/widget/cityPickBar.vue';
import ShopNearby from '@/components/card/shopNearby.vue';

export default {
    name      : 'page',
    components: {
        OpenCityPicker,
        SearchBar,
        ShopCard,
        ShopNearbyStatic,
        CityPickBar,
        ShopNearby
    },
    data () {
        return {
            firstLoading: true,
            loading     : false,
            placeholder : '搜索你想分析的店铺',
            searched    : false,
            submitting  : false,
            isError     : false,

            showRegionPicker: false,

            page    : 1,
            list    : [],
            finished: false,

            timer: 0,
            field: {
                city: '定位中',
            },
            form: {
                keyword  : null,
                longitude: 0,
                latitude : 0,
            },
            tempKeyword: '',
            recommendShopList: []
        };
    },
    mounted () {
        console.log( 'shop list app code:', window._appCode );
    },
    methods: {
        getRecommendShop (  ) {
            this.HTTP.recommendShops( this.form, 5 ).then( ( res ) => {
                this.recommendShopList = res;
                this.firstLoading = false;
                this.isError = false;
            } ).catch( ( e ) => {
                this.firstLoading = false;
            } );
        },
        handleShopError () {
            this.isError = true;
        },
        loadData ( clear ) {
            if ( this.form.keyword == null || this.form.keyword.length === 0 ) {
                return;
            }
            this.loading  = true;
            if ( clear ) {
                this.page = 1;
                this.finished = false;
            }
            this.tempKeyword = this.form.keyword;
            // console.log( 'load data temp:', this.tempKeyword, ', keyword:', this.form.keyword );
            this.HTTP.shopList( this.form, this.page ).then( ( res ) => {
                this.searched = !! this.form.keyword;
                if ( clear ) this.list = [];
                this.firstLoading = false;
                this.loading = false;
                if ( res.data !== null ) {
                    this.page ++;
                    this.total = res.total;
                    if ( this.list.length ) {
                        this.list = this.list.concat( res.data );
                    } else {
                        this.list = res.data;
                    }
                    if ( res.data.length < res.size || this.list.length >= this.total ) {
                        this.finished = true;
                    }
                } else {
                    this.finished = true;
                }
                if ( this.tempKeyword !== this.form.keyword ) {
                    // console.log( 'diff temp:', this.tempKeyword, ', keyword:', this.form.keyword );
                    this.loadData( true );
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.isError = true;
                this.loading = false;
                this.firstLoading = false;
            } );
        },
        onTap ( item ) {
            if ( [ 1, 2, 3 ].includes( item.status ) ) {
                this.swRouter( '/views/' + item.openShopId );
            }
        },
        payGood ( item ) {
            if ( [ 2, 4 ].includes( item.status ) || this.submitting ) return;
            this.submitting = true;
            this.HTTP.createShopSub( item.shopId, item.openShopId ).then( ( res ) => {
                // this.$router.push( `/pay/${res.businessId}` );
                this.swRouter( `/pay/${res}` );
            } ).catch( ( res ) => {
                // console.log( res );
                this.$toast( '订阅失败，请稍候重试！' );
            } ).finally( () => {
                this.submitting = false;
            } );
        },
        change () {},
        search ( e ) {
            this.form.keyword = e.target === this.placeholder ? null : e.target || null;
        },
        clear () {
            this.form.keyword = null;
        },
        getCityZone ( picker, code ) {
            this.field.city = picker[ 1 ];
            const center = CityData[ '' + code[ 2 ] ] || CityData[ '' + code[1] ] || CityData[ '' + code[0] ] || CityData[ '100000' ];
            // console.log( 'getCityZone picker:', picker, ', code:', code, ', center:', center );
            this.form.longitude = center.split( ',' )[0];
            this.form.latitude  = center.split( ',' )[1];
        },
        handleInputChange ( input ) {
            // console.log( 'handle input:', input );
            this.form.keyword = input;
        },
        handleCityChange ( obj ) {
            if ( obj == undefined ) {
                // 老版本，不支持selectCity
                this.showRegionPicker = true;
            } else {
                const cityName = obj.name;
                const adcode = obj.code;
                const center = CityData[ '' + adcode ] || CityData[ '100000' ];
                // console.log( 'handleCityChange. name:', cityName, ', adcode:', adcode, ', center:', center );
                this.field.city = cityName;
                this.form.longitude = center.split( ',' )[0];
                this.form.latitude  = center.split( ',' )[1];
            }
        },

    },
    watch: {
        'field.city' ( val ) {
            if ( ! this.loading ) {
                this.loadData( true );
            }
        },
        'form.keyword' ( val ) {
            if ( ! this.loading ) {
                this.loadData( true );
            }
        },
        'form.longitude' ( val ) {
            // this.getRecommendShop();
        },
        // 'form.latitude' ( val ) {
        //     this.getRecommendShop();
        // }
    },
    beforeMount () {
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );

        // 获取定位信息
        if ( window.isClient ) {
            // console.log( 'window is client' );
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                if ( data ) {
                    this.field.city = data.city;
                    // console.log( 'field city:', this.field.city );
                    this.form.longitude = data.longitude;
                    this.form.latitude  = data.latitude;
                    this.firstLoading = false;
                    clearTimeout( this.timer );
                }
            } );
            this.timer = setTimeout( () => {
                this.firstLoading = false;
                this.$toast( '获取定位信息失败' );
            }, 10000 );
        } else {
            // console.log( 'window is not client' );
            this.field.city = '北京市';
            this.form.longitude = 116.397451;
            this.form.latitude  = 39.909187;
            this.firstLoading = false;
        }
    },
    beforeDestroy () {
        clearTimeout( this.timer );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.page {
    height: 100vh;
    background-color: #3154A7;

    .r {
        @include flexColumn;
        padding: 0 2vw;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .icon {
            width: 100%;
            height: 4.4vw;
            margin-bottom: 1vw;
            background: url( /static/icon/location_gray.png) no-repeat center top;
            background-size: contain;
        }
        p {
            overflow: hidden;
            padding-top: .5vw;
            height: 4.4vw;
            font-size: $font_320;
            line-height: normal;
            font-weight: bold;
            opacity: .7;
        }
    }

    #{&}--view {
        .s-label {
            color: $c-info;
            text-align: center;
            margin-bottom: $padding_3;
        }
    }
}
</style>
