<!--
搜索框

Props:
  v-model: 输入值
  disabled: 是否禁用输入框
  clearable: 是否启用清除控件
  placeholder
  showCancelBtn: 是否显示取消按钮
Events:
  search: 确定搜索时触发
  input: 输入框内容变化时触发
  focus: 输入框获得焦点时触发
  blur: 输入框失去焦点时触发
  clear: 点击清除按钮后触发
  cancel: 点击取消按钮时触发
Methods:
  focus: 获取焦点
  blur: 取消焦点
-->

<template>
  <div class="search-bar">
<!--    <Icon name="search" :width="5.6" :height="5.73" class="search-icon"></Icon>-->
    <!-- form 标签用来解决ios浏览器没有搜索按钮的问题 -->
    <form action="" @submit="submitForm" class="search-form">
      <label class="input-box" for="search-input">
        <input id="search-input" class="input"
             :type="type"
             autocomplete="off"
             ref="input"
             :placeholder="placeholder"
             :disabled="disabled"
             :value="value"
             @input="inputHandle"
             @focus="focusHandle"
             @blur="blurHandle" />
        <div v-show="value.length && clearable" class="clear-btn" @click="clearHandle">
            <Icon name="clear" :size="4.27"></Icon>
        </div>
      </label>
    </form>
    <div class="fake-btn" v-show="showFakeBtn" @click="clickFakeBtn"></div>
    <div v-show="showCancelBtn" class="divider-line"></div>
    <span v-show="showCancelBtn" class="cancel-btn" @click="$emit('cancel')">取消</span>
  </div>
</template>

<script>
import Icon from '@/components/Icon/Icon';

export default {
    name      : 'SearchBar',
    components: {
        Icon,
    },
    props: {
        value      : String,
        placeholder: {
            type   : String,
            default: '搜索地点'
        },
        type       : {
            type   : String,
            default: 'search'
        },
        disabled: {
            type   : Boolean,
            default: false
        },
        clearable: {
            type   : Boolean,
            default: false
        },
        showCancelBtn: {
            type   : Boolean,
            default: false
        },
        showFakeBtn: {
            type   : Boolean,
            default: false
        }
    },
    methods: {
        inputHandle ( $event ) {
            this.$emit( 'input', $event.target.value );
            this.$emit( 'change', $event.target.value );
        },
        clickFakeBtn () {
            this.focus();
            this.$emit( 'clickFakeBtn' );
        },
        // 解决ios输入框光标错乱bug
        handleIosPop () {
            clearInterval( this.iosTimer );
            const scrollTop = document.documentElement.scrollTop;
            this.iosTimer = setInterval( () => {
                document.documentElement.scrollTop = scrollTop;
            }, 60 );
        },
        submitForm ( e ) {
            e.preventDefault();
            this.searchHandle();
            return false;
        },
        searchHandle ( e ) {
            this.blur();
            this.$emit( 'search', this.value );
        },
        clearHandle () {
            this.$emit( 'input', '' );
            this.$emit( 'clear' );
        },
        focusHandle () {
            this.$emit( 'focus' );
            this.handleIosPop();
        },
        blurHandle ( e ) {
            clearInterval( this.iosTimer );
            this.iosTimer = null;
            this.$emit( 'blur' );
        },
        focus () {
            // 在ios上不能手动触发focus
            this.$refs.input.focus();
        },
        blur () {
            this.$refs.input.blur();
        }
    },
};
</script>

<style lang="scss" scoped>
  // @import '@/styles/index.scss';

  .search-bar {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 10.67vw;
    background: #F7F6F9;
    border-radius: 2.67vw;
    border: 0.13vw solid #EBEAEE;
    display: flex;
    padding-left: 5.6vw;
    .search-icon {
      flex-shrink: 0;
      align-self: center;
    }
    .search-form{
      flex-grow: 1;
      display: flex;
    }
    .input-box {
      flex-grow: 1;
      display: flex;
      .input {
        @include font($c-text, $font_373, $weight_500);
        width: 1px;
        flex-grow: 1;
        padding-left: 2vw;
        padding-right: 4vw;
        border: none;
        background-color: transparent;
        &:disabled {
          cursor: not-allowed;
        }
        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }
      }
      .clear-btn {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 0 3.33vw;
      }
    }
    .divider-line {
      width: 1px;
      height: 4.13vw;
      background-color: #D8D8D8;
      align-self: center;
    }
    .cancel-btn {
      flex-shrink: 0;
      @include font($c-text, $font_320, $weight_400);
      padding: 0 5.07vw 0 4.67vw;
      line-height: 10.67vw;
    }
    .fake-btn{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background:transparent;
    }
  }
</style>
