<template>
    <div class="page">
        <StateBar bgcolor="white" />
        <TitleHeader title="品牌对比" :back="true"></TitleHeader>
        <van-skeleton :row="7" :loading="loading">
          <div class="content">
          <ul class="header">
              <li class="tips">当前为演示示例，此功能可直观对比品牌的基本信息和加盟费用，对不同品牌的盈利情况进行预估，进而选出最赚钱的品牌商机！</li>
              <li class="wait">敬请 <br />期待</li>
          </ul>
          <img class="temporary-img" src="/static/img/temporary_1.png">
        </div>
        </van-skeleton>
        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
export default {
    name: 'page',
    data () {
        return {
            loading   : true
        };
    },
    created () {
        setTimeout( () => {
            this.loading = false;
        }, 500 );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.page {
    position: relative;
    min-height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    .content {
      width: 100%;
      height: 100%;
      .header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        background: #2A2930;
        padding: 3.2vw 5.33vw;
        .tips {
            flex: 1;
            font-size: 3.47vw;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #FFE4C0;
            line-height: 5.33vw;
        }
        .wait {
            width: 10.13vw;
            height: 10.13vw;
            font-size: 4.8vw;
            font-family: YouSheBiaoTiHei;
            color: #2A2930;
            font-weight: bold;
            text-align: center;
            background: linear-gradient(90deg, #FAE7CD 0%, #FFCE8F 100%);
            border-radius: 4px;
            padding: 2.67vw;
            margin-left: 3.2vw;
        }
      }
      .temporary-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
}
</style>
