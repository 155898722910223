<template>
    <Wraper title="周边基础信息">
        <div class="box-wrap">
            <div class="box-item">
                <div class="box-info">
                    <span class="box-number">{{ boxData.aroundPeople }}</span>
                    <span class="box-unit">{{ boxData.aroundPeopleUnit }}</span>
                </div>
                <div class="box-name">周边人口规模</div>
                <div class="box-percent">
                    <span>{{ boxData.aroundPeopleRate }}</span>
                </div>
            </div>
            <div class="box-item">
                <div class="box-info">
                    <span class="box-number">{{ boxData.aroundHouseAvgPrice }}</span>
                    <span class="box-unit">{{ boxData.aroundHouseAvgPriceUnit }}</span>
                </div>
                <div class="box-name">周边房屋均价</div>
                <div class="box-percent">
                    <span>{{ boxData.aroundHouseAvgPriceRate }}</span>
                </div>
            </div>
            <div class="box-item">
                <div class="box-info">
                    <span class="box-number">{{ boxData.aroundShopAvgPrice }}</span>
                    <span class="box-unit">{{ boxData.aroundShopAvgPriceUnit }}</span>
                </div>
                <div class="box-name">周边店铺平均租金</div>
                <div class="box-percent">
                    <span>{{ boxData.aroundShopAvgPriceRate }}</span>
                </div>
            </div>
        </div>
        <div class="box-container region-box-container">
            <div v-for="item in boxItems" :key="item.name" class="box-panel">
                <div class="box-name">{{ item.name }}</div>
                <div class="box-count">{{ item.count }}</div>
                <div class="box-content" v-if="item.count !== '-'">
                    <template v-if="item.compare > 0">
                        <div class="box-compare compare-more">
                            <div>{{ item.conclusion }}</div>
                            <div>{{ item.conclusion2 }}</div>
                        </div>
                        <div class="box-image-container">
                            <img src="/static/shopEcology/report/icon_good@2x.png" class="box-image" />
                        </div>
                    </template>
                    <template v-else-if="item.compare < 0">
                        <div class="box-compare compare-less">
                            <div>{{ item.conclusion }}</div>
                            <div>{{ item.conclusion2 }}</div>
                        </div>
                        <div class="box-image-container">
                            <img src="/static/shopEcology/report/icon_not_good@2x.png" class="box-image" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="box-compare compare-equal">{{ item.conclusion }}</div>
                    </template>
                </div>
                <div class="box-content" v-else>
                    <div class="box-compare compare-equal">暂无数据</div>
                </div>
            </div>
        </div>
    </Wraper>
</template>

<script>
import Wraper from "./wraper";
import { valueCheck, getCompareValue } from "../valueUtils";
export default {
    components: { Wraper },
    props: {
        bgmData: {
            type: Object,
            required: true,
        },
        dataObj: {
            type: Object,
            required: true,
        },
        // userBenefitResp: {
        //     type: Object,
        //     default: {},
        // },
        showVisit: {
          type: Boolean,
          default: true
        }
    },
    data() {
        return {
            boxData: {
                aroundPeople: 16.65,
                aroundPeopleUnit: "万人",
                aroundPeopleRate: "占全市1.24%",
                aroundHouseAvgPrice: 138838.8,
                aroundHouseAvgPriceUnit: "元/m²",
                aroundHouseAvgPriceRate: "高于全市84.5%",
                aroundShopAvgPrice: 280.4,
                aroundShopAvgPriceUnit: "元/月/m²",
                aroundShopAvgPriceRate: "高于全市36.6%",
            },
            boxItems: [],
        };
    },
    mounted() {
        this.load(this.bgmData);
        this.dellData(this.dataObj);
    },
    methods: {
        addBoxItemNormal(name, count, countUnit, compare) {
            let conclusion = "";
            let conclusion2 = "";
            if (name == "到访客流") {
                if (compare >= 50) {
                    conclusion = "大幅高于周边";
                } else if (compare > 5 && compare < 50) {
                    conclusion = "高于周边";
                } else if (compare >= -5 && compare <= 5) {
                    conclusion = "与周边持平";
                } else if (compare > -50 && compare < -5) {
                    conclusion = "低于周边";
                } else {
                    conclusion = "大幅低于周边";
                }
            } else {
                if (compare > 5) {
                    if (compare >= 100) {
                        compare = (compare / 100).toFixed(1);
                        conclusion = "密度高于周边";
                        conclusion2 = compare + "倍";
                    } else {
                        conclusion = "密度高于周边";
                        conclusion2 = compare.toFixed(1) + "%";
                    }
                } else if (compare < -5) {
                    conclusion = "密度低于周边";
                    conclusion2 = Math.abs(compare).toFixed(1) + "%";
                } else {
                    compare = 0;
                    conclusion = "密度与周边持平";
                }
            }
            this.boxItems.push({
                name: name,
                count: count + countUnit,
                compare: compare,
                conclusion: conclusion,
                conclusion2: conclusion2,
            });
        },
        addBoxItemNoCurrent(name, aroundCnt) {
            if (name == "住宅区" || name == "写字楼" || name == "商业区") {
                this.boxItems.push({
                    name: name,
                    count: "0个",
                    compare: -1,
                    conclusion: "低于周边" + Math.round(aroundCnt) + "个",
                });
            } else {
                this.boxItems.push({
                    name: name,
                    count: "0人",
                    compare: -1,
                    conclusion: "大幅低于周边",
                });
            }
        },
        addBoxItemNoAround(name, currentCnt, countUnit) {
            if (name == "住宅区" || name == "写字楼" || name == "商业区") {
                this.boxItems.push({
                    name: name,
                    count: currentCnt + "个",
                    compare: 1,
                    conclusion: "高于周边" + currentCnt + "个",
                });
            } else {
                this.boxItems.push({
                    name: name,
                    count: currentCnt + countUnit,
                    compare: 1,
                    conclusion: "大幅高于周边",
                });
            }
        },
        addBoxItemNoBoth(name) {
            if (name == "住宅区" || name == "写字楼" || name == "商业区") {
                this.boxItems.push({
                    name: name,
                    count: "0个",
                    compare: 0,
                    conclusion: "该区域无" + name,
                });
            } else if (name == "居住人数" || name == "办公人数") {
                this.boxItems.push({
                    name: name,
                    count: "0人",
                    compare: 0,
                    conclusion: "该区域无" + name.slice(0, -1) + "员",
                });
            } else {
                // 到访客流
                this.boxItems.push({
                    name: name,
                    count: "0人",
                    compare: 0,
                    conclusion: "该区域无到访客流",
                });
            }
        },
        addBoxItem(name, count, around, compare) {
            if (count == undefined) {
                count = 0;
            }
            if (around == undefined) {
                around = 0;
            }
            if (compare == undefined) {
                compare = 0;
            }
            let countUnit = "个";
            if (name == "居住人数" || name == "办公人数") {
                countUnit = "人";
                if (count >= 10000) {
                    count = (count / 10000).toFixed(1);
                    countUnit = "万人";
                }
            } else if (name == "到访客流") {
                countUnit = "人次";
                if (count >= 10000) {
                    count = (count / 10000).toFixed(1);
                    countUnit = "万人次";
                } else if (!count) {
                  count = '-'
                  countUnit = ''
                }
            }
            if (typeof compare == "string") {
                compare = parseFloat(compare);
            }
            if (count == 0 && around == 0) {
                this.addBoxItemNoBoth(name);
            } else if (count == 0) {
                this.addBoxItemNoCurrent(name, around);
            } else if (around == 0) {
                this.addBoxItemNoAround(name, count, countUnit);
            } else {
                this.addBoxItemNormal(name, count, countUnit, compare);
            }
        },
        dellData(output) {
            this.addBoxItem(
                "住宅区",
                output.residenceCnt,
                output.residenceCntAround,
                getCompareValue(output.residenceCnt, output.residenceCntAround / 3, true)
            );
            this.addBoxItem("写字楼", output.officeCnt, output.officeCntAround, getCompareValue(output.officeCnt, output.officeCntAround / 3, true));
            this.addBoxItem("商业区", output.mallCnt, output.mallCntAround, getCompareValue(output.mallCnt, output.mallCntAround / 3, true));
            // if(this.userBenefitResp.member) {
            //     this.addBoxItem(
            //         "居住人数",
            //         output.livePeopleCnt,
            //         output.livePeopleCntAround,
            //         getCompareValue(output.livePeopleCnt, output.livePeopleCntAround / 3, true)
            //     );
            //     this.addBoxItem(
            //         "办公人数",
            //         output.officeStaffCnt,
            //         output.officeStaffCntAround,
            //         getCompareValue(output.officeStaffCnt, output.officeStaffCntAround / 3, true)
            //     );
            //     // 到访客流为0，不显示
            //   if (this.showVisit) {
            //     this.addBoxItem(
            //       "到访客流",
            //       output.mallUserCnt,
            //       output.mallUserCntAround,
            //       getCompareValue(output.mallUserCnt, output.mallUserCntAround / 3, true)
            //     );
            //   }
            // }
        },
        load(data) {
            if (data == undefined) {
                console.log("nearby base data is undefined, use default data");
                return;
            }
            Object.assign(this.boxData, data);

            this.boxData.aroundPeople = valueCheck(this.boxData.aroundPeople, -1);
            if (this.boxData.aroundPeople != "-") {
                if (this.boxData.aroundPeople >= 10000) {
                    this.boxData.aroundPeople = (this.boxData.aroundPeople / 10000).toFixed(2);
                    this.boxData.aroundPeopleUnit = "万人";
                } else {
                    this.boxData.aroundPeopleUnit = "人";
                }
            }
            this.boxData.aroundPeopleRate = valueCheck(this.boxData.aroundPeopleRate, 1, "-");
            if (this.boxData.aroundPeopleRate == "-") {
                this.boxData.aroundPeopleRate = "暂无数据";
            } else {
                this.boxData.aroundPeopleRate = "占全市" + this.boxData.aroundPeopleRate + "%";
            }

            this.boxData.aroundHouseAvgPrice = valueCheck(this.boxData.aroundHouseAvgPrice, 1);
            this.boxData.aroundHouseAvgPriceRate = valueCheck(this.boxData.aroundHouseAvgPriceRate, -1);
            if (this.boxData.aroundHouseAvgPriceRate == "-") {
                this.boxData.aroundHouseAvgPriceRate = "暂无数据";
            } else {
                if (this.boxData.aroundHouseAvgPriceRate > 100) {
                    this.boxData.aroundHouseAvgPriceRate = "高于全市" + (this.boxData.aroundHouseAvgPriceRate / 100).toFixed(1) + "倍";
                } else if (this.boxData.aroundHouseAvgPriceRate > 0) {
                    this.boxData.aroundHouseAvgPriceRate = "高于全市" + this.boxData.aroundHouseAvgPriceRate.toFixed(1) + "%";
                } else if (this.boxData.aroundHouseAvgPriceRate < 0) {
                    this.boxData.aroundHouseAvgPriceRate = "低于全市" + Math.abs(this.boxData.aroundHouseAvgPriceRate).toFixed(1) + "%";
                } else {
                    this.boxData.aroundHouseAvgPriceRate = "与全市持平";
                }
            }

            this.boxData.aroundShopAvgPrice = valueCheck(this.boxData.aroundShopAvgPrice, 1);
            this.boxData.aroundShopAvgPriceRate = valueCheck(this.boxData.aroundShopAvgPriceRate, -1);
            if (this.boxData.aroundShopAvgPriceRate == "-") {
                this.boxData.aroundShopAvgPriceRate = "暂无数据";
            } else {
                if (this.boxData.aroundShopAvgPriceRate > 100) {
                    this.boxData.aroundShopAvgPriceRate = "高于全市" + (this.boxData.aroundShopAvgPriceRate / 100).toFixed(1) + "倍";
                } else if (this.boxData.aroundShopAvgPriceRate > 0) {
                    this.boxData.aroundShopAvgPriceRate = "高于全市" + this.boxData.aroundShopAvgPriceRate.toFixed(1) + "%";
                } else if (this.boxData.aroundShopAvgPriceRate < 0) {
                    this.boxData.aroundShopAvgPriceRate = "低于全市" + Math.abs(this.boxData.aroundShopAvgPriceRate).toFixed(1) + "%";
                } else {
                    this.boxData.aroundShopAvgPriceRate = "与全市持平";
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

.box-image-container {
  display: flex;
  align-items: center;
  margin-left :vw2(3);
}
.box-container {
  position: relative;
  padding: 0 0 vw2(5) 0;
  display :flex;
  //flex-wrap:wrap;
  width :100%;
  justify-content:flex-start;
}
.region-box-container {
  .box-panel {
    &:nth-child(4) {
      flex: 1;
    }
    &:nth-child(5) {
      flex: 1;
      margin-right: 0;
    }
  }
}
.box-wrap {
    position: relative;
    width: 100%;
    display: flex;
    flex: 1;

    .box-item {
        position: relative;
        border-radius: 5px;
        background-color: #FAFAFB;
        padding-top: vw2(10);
        box-sizing: border-box;
        width: 33%;
        margin-right: 1%;

        &:last-child {
            margin-right: 0;
        }

        .box-info {
            position: relative;
            text-align: center;

            .box-number {
                color: #1D2233;
                font-size: vw2(13);
                line-height: vw2(19);
                font-weight: bold;
            }

            .box-unit {
                color: #1D2233;
                font-size: vw2(10);
                line-height: vw2(14);
                margin-left: vw2(2);
            }
        }

        .box-name {
            padding: vw2(3) 0 vw2(8) 0;
            color: #676C84;
            font-size: vw2(11);
            line-height: vw2(15);
            text-align: center;
            font-weight: 400;
        }

        .box-percent {
            padding: vw2(5) 0;
            border-radius: 0 0 5px 5px;
            color: #518CDB;
            font-size: vw2(11);
            line-height: vw2(15);
            font-weight: bold;
            text-align: center;
            background-color: #E4EFFF;
        }
    }
}

.box-container {
    margin-top: 5px;
}

.explain-wrap {
    position: relative;
    background-color: #FAFAFB;
    border-radius: 5px;
    padding: vw2(10);
    display: flex;

    .explain-left {
        position: relative;
        width: 100%;
        margin-right: vw2(10);

        .explain-item {
            color: #8E9099;
            font-size: vw2(9);
            line-height: vw2(15);
            text-align: left;

            >span {
                color: #676C84;
            }
        }

        .tips {
            font-size: vw2(10);
            color: #999;
            text-align: left;
            margin-top: vw2(4);
        }
    }

    .explain-right {
        position: relative;
        display: flex;
        align-items: center;

        .explain-img {
            width: vw2(53);
            height: vw2(53);
        }
    }
}
</style>
