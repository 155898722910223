<template>
  <div class="ask">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" bgcolor="white" :back="true"></TitleHeader>

    <StackView ref="view" class="ask--view">
      <van-skeleton :loading="!questionAnswerList || firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__xs">
        <EmptyCard v-if="!questionAnswerList || questionAnswerList.length === 0" type="messages"></EmptyCard>
        <template v-else>
          <van-pull-refresh v-model="dataLoading" @refresh="loadNewData">
            <van-list v-model="loading" :finished="finished" finished-text="我是有底线的" @load="loadData">
              <ul class="ask-wrap">
                <li class="left-img">
                  <van-image radius="3" class="ask-img" fit="cover" :src="trackData.icon" />
                </li>
                <li class="right-name">{{ trackData.name }}</li>
              </ul>
              <AskItem v-for="item of questionAnswerList" :key="item.key" :dataInfo="item" :icon="true" />
            </van-list>
          </van-pull-refresh>
        </template>
      </van-skeleton>
    </StackView>
  </div>
</template>

<script>
import AskItem from "./components/ask-item";

export default {
  name: "queAsk",
  components: {
    AskItem,
  },
  data() {
    return {
      title: "我的问答",
      firstLoading: true,
      loading: true,
      finished: false,
      dataLoading: false,
      current: 1,
      trackData: {},
      questionAnswerList: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.HTTP.myQuestionAnswerPage(this.current, null)
        .then((res) => {
          this.loading = false;
          this.trackData = res.data;
          if (res.data.questionAnswer.length) {
            this.questionAnswerList = this.questionAnswerList.concat(res.data.questionAnswer);
          }
          this.current++;
          this.dataLoading = false;
          this.firstLoading = false;
          this.finished = res.data.questionAnswer.length < 10;
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
          this.dataLoading = false;
        });
    },
    loadNewData() {
      this.dataLoading = true;
      this.loading = true;
      this.current = 1;
      // 重新获取
      this.HTTP.myQuestionAnswerPage(this.current, null)
        .then((res) => {
          this.loading = false;
          this.trackData = res.data;
          this.questionAnswerList = res.data.questionAnswer || [];
          this.current++;
          this.finished = res.data.questionAnswer.length < 10;
          setTimeout(() => {
            this.dataLoading = false;
          }, 1000);
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
          this.dataLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.ask {
  height: 100vh;
  overflow: hidden;
  background: #f7f6f9;

  .btnColor {
    color: $c-main;
  }

  #{&}--view {
    padding: $padding2 $padding2 25vw $padding2;
  }

  .ask-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.67vw;
    margin-bottom: 3.73vw;
    .left-img {
      flex: 0 0 8.53vw;
      text-align: center;
      .ask-img {
        width: 4vw;
        height: 4vw;
      }
    }
    .right-name {
      flex: 1;
      font-size: 3.73vw;
      color: #666;
      font-weight: bold;
      line-height: 5.33vw;
      @include limitLine(2);
    }
  }
}
</style>
