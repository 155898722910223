<template>
    <div class="cashout" @click="onTap">
        <div class="cashout--content">
            <h1>￥{{ item.amount || '---' }}</h1>
            <p class="time">{{ item.applyTime || '刚刚' }}</p>
            <div class="status">
                <p class="text" v-html="statusText">---</p>
                <p class="desc">{{ statusDesc }}</p>
            </div>
        </div>
    </div>
</template>

<script>
// import { toTimeStr } from '@/utils/filters';

export default {
    name : 'cashout',
    props: {
        item: Object, // 报告对象
    },
    computed: {
        statusText () {
            const map = {
                0: '<font color="#3BD6A7">审核通过</font>',
                1: '<i class="icon-ok">已转账</i>',
                2: '<font color="#F58958">审核中...</fotn>',
                3: '<font color="#3BD6A7">审核通过</font>',
                4: '<font color="#FF504E">审核不通过</font>',
            };
            return map[ this.item.status ] || map[ 2 ];
        },
        statusDesc () {
            const map = {
                0: '不结算',
                1: '请注意查收',
                3: '待转账',
                4: this.item.remark || '未知原因',
            };
            return map[ this.item.status ] || '';
        },
    },
    methods: {
        onTap ( event ) {
            if ( this.completed === false ) return;
            this.$emit( 'tap', this.item.id );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.cashout {
    @extend .l-panel;
    position: relative;
    margin-bottom: $margin;
    padding: 4vw 5.33vw;

    #{&}--content {
        position: relative;
        height: 100%;
        h1 {
            color: $c-text;
            font-size: $font_400;
            line-height: $font_560;
        }
        .time, .desc {
            margin-top: 1.6vw;
            font-size: $font_293;
            line-height: $font_400;
            color: $c-info;
            @include singleLine;
        }
        .desc {
            width: 40vw;
            text-align: right;
        }

        .status {
            @include flexColumn;
            align-items: flex-end;
            align-content: flex-end;
            position: absolute;
            top: 0;
            right: 0;
            max-width: 50%;
            height: 100%;

            .text {
                font-size: $font_400;
                line-height: $font_560;
                font-weight: bold;
            }
            ::v-deep .icon-ok {
                font-style: normal;
                padding-left: $padding;
                background: url(/static/icon/msg_success_green.png) no-repeat left center;
                background-size: 4vw 4vw;
            }
        }
    }
}
</style>
