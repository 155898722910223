<template>
  <div class="evaluate">
    <h4>
      留言 <span class="num" v-if="evaluateInfo.total">({{ evaluateInfo.total }})</span>
    </h4>
    <van-field class="input" v-model="message" readonly type="text" placeholder="我有话说..." @click="inputClick" />
    <one-comment :hasBorder="i !== evaluateInfo.data.length - 1" v-for="(item, i) in evaluateInfo.data" :key="i" :itemObj="item" :hasReply="!!item.appReplyRes">
        <div  v-if="item.appReplyRes">
            <one-comment v-for="(rep ,idx) in item.appReplyRes" :key="idx" :itemObj="rep"></one-comment>
        </div>
    </one-comment>
    <div class="end" v-if="evaluateInfo.data && evaluateInfo.data.length">- end -</div>

    <van-popup
      v-model="alertShow"
      position="bottom"
      closeable
      close-icon-position="top-left"
      :style="{ height: '66%', borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }"
    >
      <div class="popup-wrap">
        <ul class="ul-flex">
          <li class="left"></li>
          <li class="mid">写留言</li>
          <li class="right">
            <van-button type="info" :disabled="!message" size="mini" :loading="loading" @click="submit">提交</van-button>
          </li>
        </ul>
        <van-field
          class="input"
          ref="inputbox"
          v-model.trim="message"
          autosize
          maxlength="150"
          type="textarea"
          placeholder="留言被平台审核后，将对所有人可见"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import OneComment from '@/views/hotSearch/components/OneComment';
export default {
  props: {
    evaluateInfo: {
      type: Object,
      default: () => {},
    },
    id: { type: String },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
    },
  },
  components: {
      OneComment
  },
  data() {
    return {
      message: "",
      alertShow: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.HTTP.addEvaluate({
        refId: this.id,
        content: this.message,
        refName: this.title,
        type: this.type,
      })
        .then((res) => {
          this.$toast("留言成功，评审通过后即可展示");
          this.message = "";
          this.alertShow = false;
          this.loading = false;
        })
        .catch((err) => {
          this.$toast("网络不稳定，请稍后再试！");
          this.loading = false;
        });
    },
    inputClick() {
      this.alertShow = true;
      setTimeout(() => {
        this.$refs.inputbox.focus();
      }, 120);
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluate {
  padding: 4vw 4vw 12vw 4vw;
  h4 {
    line-height: 6.4vw;
    font-size: 4.53vw;
    color: #1d2233;
    font-weight: bold;
    .num {
      font-size: 3.73vw;
      color: #999;
      font-weight: 400;
    }
  }
  .input {
    margin: 2.67vw 0 4vw 0;
    border-radius: 1.33vw;
  }
  .flex-user {
    display: flex;
    margin-bottom: 5.33vw;
    .left {
      flex: 0 0 9.6vw;
      img {
        width: 8vw;
        height: 8vw;
        border-radius: 50%;
      }
    }
    .right {
      flex: 1;
      .user-info {
        line-height: 4.27vw;
        font-size: 3.2vw;
        color: #666;
        i {
          float: right;
          font-style: normal;
          color: #999;
        }
      }
      .content {
        font-size: 3.73vw;
        color: #333;
        line-height: 5.87vw;
        margin-top: 1vw;
        word-break: break-all;
      }
    }
  }
  .end {
    width: 100%;
    text-align: center;
    font-size: 3.2vw;
    color: #999;
  }

  ::v-deep .van-popup__close-icon {
      color: #c5c3cA;
  }
}
.popup-wrap {
  width: 100%;
  .ul-flex {
    display: flex;
    flex-flow: row nowrap;
    padding-right: 5.33vw;
    .left {
      flex: 0 0 10.13vw;
    }
    .mid {
      flex: 1;
      font-size: 3.73vw;
      color: #000;
      font-weight: bold;
      margin-top: 4.8vw;
      text-align: center;
    }
    .right {
      flex: 0 0 15.47vw;
      margin-top: 1.6vw;
      text-align: right;
    }
  }
}
</style>
