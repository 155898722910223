/**
 * 我的栏目界面
 */

const bills              = () => import( /* webpackChunkName: "module-me" */ '@/views/me/bills' );
const billHistory        = () => import( /* webpackChunkName: "module-me" */ '@/views/me/billHistory' );
const coupons            = () => import( /* webpackChunkName: "module-me" */ '@/views/me/coupons' );
const couponInstructions = () => import( /* webpackChunkName: "module-me" */ '@/views/me/couponInstructions' );
const invitations        = () => import( /* webpackChunkName: "module-me" */ '@/views/me/invitations' );
const invoice            = () => import( /* webpackChunkName: "module-me" */ '@/views/me/invoice' );
const messages           = () => import( /* webpackChunkName: "single-message" */ '@/views/me/messages' );
const orders             = () => import( /* webpackChunkName: "module-me" */ '@/views/me/orders' );
const course             = () => import( /* webpackChunkName: "module-me" */ '@/views/me/course' );
const packages           = () => import( /* webpackChunkName: "module-me" */ '@/views/me/packages' );
const packagesDetail     = () => import( /* webpackChunkName: "module-me" */ '@/views/me/packagesDetail' );
const questions          = () => import( /* webpackChunkName: "module-me" */ '@/views/me/questions' );
const reports            = () => import( /* webpackChunkName: "module-me" */ '@/views/me/reports' );
const reportComment      = () => import( /* webpackChunkName: "module-me" */ '@/views/me/reportComment' );
const shops              = () => import( /* webpackChunkName: "module-me" */ '@/views/me/shops' );
const vip                = () => import( /* webpackChunkName: "module-vip" */ '@/views/vip/index' );
const vipBuy             = () => import( /* webpackChunkName: "module-vip" */ '@/views/vip/buy' );
const wallet             = () => import( /* webpackChunkName: "module-sscoins" */ '@/views/me/wallet' );
const walletHistory      = () => import( /* webpackChunkName: "module-sscoins" */ '@/views/me/walletHistory' );
const queAsk             = () => import( /* webpackChunkName: "module-ask" */ '@/views/me/queAsk' );
const myAsk              = () => import( /* webpackChunkName: "module-ask" */ '@/views/me/myAsk' );
const vipShop              = () => import( /* webpackChunkName: "module-ask" */ '@/views/me/vipShop' );
const vipShop1             = () => import( /* webpackChunkName: "module-ask" */ '@/views/me/vip-shop/vip-upgrade/index' );
const iframeAgreement = () => import(/* webpackChunkName: "module-ask" */ "@/views/me/iframeAgreement");
const surveyedShops      = () => import('@/views/me/surveyedShops/index.vue');
const surveyedShopsDetails      = () => import('@/views/me/surveyedShops/details/index.vue');
const enterpriseForm      = () => import('@/views/me/vip-shop/enterprise-form/index.vue');
const enterprise      = () => import('@/views/me/vip-shop/enterprise-vip/index.vue');
const paySuccesseComplete      = () => import('@/views/me/vip-shop/pay-success-complete/index.vue');
const paySuccesseIncomplete      = () => import('@/views/me/vip-shop/pay-success-incomplete/index.vue');
const enterpriseDetails      = () => import('@/views/me/vip-shop/enterprise-details/index.vue');
const CompanyPay = () => import(/* webpackChunkName: brand-report" */ "@/views/me/vip-shop/company-pay/index.vue");
const messageNotification  = () => import(/* webpackChunkName: "module-me" */ "@/views/me/message-notification")
const information  = () => import(/* webpackChunkName: "module-me" */ "@/views/me/information")

export default [
    {
        path: '/message-notification',
        component: messageNotification,
        meta     : { id: 10300 },
    },
    {
        path: '/information',
        component: information,
        meta     : { id: 10324 },
    },
    {
        path     : '/bills',
        name     : 'bills',
        component: bills,
        meta     : { id: 10047 },
    }, {
        path     : '/billHistory',
        name     : 'billHistory',
        component: billHistory,
        meta     : { id: 10049 },
    }, {
        path     : '/coupons',
        name     : 'coupons',
        component: coupons,
        meta     : { id: 10052 },
    }, {
        path     : '/couponInstructions',
        name     : 'couponInstructions',
        component: couponInstructions,
        meta     : { id: 10053 },
    }, {
        path     : '/invitations',
        name     : 'invitations',
        component: invitations,
        meta     : { id: 10041 },
    }, {
        path     : '/invoice',
        name     : 'invoice',
        component: invoice,
        meta     : { id: 10048 },
    }, {
        // 我的消息列表(站内信)
        path     : '/messages',
        name     : 'messages',
        component: messages,
        meta     : { id: 10011 },
    }, {
        path     : '/my/reports',
        name     : 'myReport',
        component: reports,
        meta     : { id: 10042, clear: true },
    }, {
        path     : '/orders',
        name     : 'orders',
        component: orders,
        meta     : { id: 10046 },
    }, {
        path     : '/my/course',
        name     : 'course',
        component: course,
        meta     : { id: 10076 },
    }, {
        path     : '/my/packages',
        name     : 'packages',
        component: packages,
        meta     : { id: 10050, clear: true },
    }, {
        path     : '/my/packages/history',
        name     : 'packagesDetail',
        component: packagesDetail,
        meta     : { id: 10051 },
    }, {
        path     : '/questions',
        name     : 'questions',
        component: questions,
    },  {
        path     : '/reports',
        name     : 'reports',
        component: reports,
        meta     : { id: 10042 },
    },  {
        path     : '/my/shops',
        name     : 'shops',
        component: shops,
        meta     : { id: 10066, clear: true },
    }, {
        path     : '/vip',
        name     : 'vip',
        component: vip,
        meta     : { id: 10038 },
    },
    {
        path     : '/vip/enterprise-form/:payStatus', // 0未购买 1 已购买
        name     : 'enterpriseForm',
        component: enterpriseForm,
        meta     : { id: 0 }, // 需要同步到石墨文档
    },
    {
        path     : '/vip/enterprise',
        name     : 'enterprise',
        component: enterprise,
        meta     : { id: 0 }, // 需要同步到石墨文档
    },
    {
        path     : '/vip/enterprise-details',
        name     : 'enterpriseDetails',
        component: enterpriseDetails,
        meta     : { id: 0 }, // 需要同步到石墨文档
    },
    {
        path     : '/vip/enterprise/pay-successe-complete',
        name     : 'paySuccesseComplete',
        component: paySuccesseComplete,
        meta     : { id: 0 }, // 需要同步到石墨文档
    },
    {
        path     : '/vip/enterprise/pay-successe-incomplete',
        name     : 'paySuccesseIncomplete',
        component: paySuccesseIncomplete,
        meta     : { id: 0 }, // 需要同步到石墨文档
    },
    {
        path     : '/vip/enterprise/company-pay',
        name     : 'CompanyPay',
        component: CompanyPay,
        meta     : { id: 0 }, // 需要同步到石墨文档
    },
    {
        path     : '/vip/buy',
        name     : 'vipBuy',
        component: vipBuy,
        meta     : { id: 10039 },
    }, {
        path     : '/wallet',
        name     : 'wallet',
        component: wallet,
        meta     : { id: 10044 },
    }, {
        path     : '/walletHistory',
        name     : 'walletHistory',
        component: walletHistory,
        meta     : { id: 10045 },
    }, {
        path     : '/queAsk/:id',
        name     : 'queAsk',
        component: queAsk,
        meta     : { id: 10101 },
    }, {
        path     : '/myAsk',
        name     : 'myAsk',
        component: myAsk,
        meta     : { id: 10102 },
    },
    // {
    //     path     : '/vipShop',
    //     name     : 'vipShop',
    //     component: vipShop,
    //     meta     : { id: 10150 },
    // },
    {
        path     : '/vipShop',
        name     : 'vipShop',
        component: vipShop1,
        meta     : { id: 10150 },
    },
    {
      path     : '/dual-payment',
      name     : 'vipShop',
      component: vipShop1,
      meta     : { id: 10150 },
    },
    {
        path: "/iframeAgreement",
        name: "iframeAgreement",
        component: iframeAgreement,
        meta: { id: 10392 }
    },
    {
        path     : '/report/comment/:id',
        name     : 'reportComment',
        component: reportComment,
        meta     : { id: 10194 },
    },
    {
      path     : '/me/surveyedShops',
      name     : 'surveyedShops',
      component: surveyedShops,
      meta     : { id: 10218 },
    },
    {
      path     : '/me/surveyedShops/details/:id',
      name     : 'surveyedShopsDetails',
      component: surveyedShopsDetails,
      meta     : { id: 10219 },
    },
];
