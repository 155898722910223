<template>
  <div class="brand">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" :back="true">
      <span slot="right" class="brand-join" @click="swRouter('/brand/application_for')">品牌合作</span>
    </TitleHeader>
    <div class="brand--search">
      <SearchBar
        ref="compSearchBar"
        :icon="true"
        :placeholder="placeholder"
        :maxlength="32"
        :value="form.brandName"
        @searchClick="swRouter('/brand/search')"
        @change="change"
        @entry="search"
        @clear="clear"
      />
    </div>
    <div class="brand--option">
      <div v-for="(item, index) of types" :key="index" :class="{ on: newOpend === index }" @click="tabClick(item, index)">
        <span>{{ item }}</span>
      </div>
    </div>

    <StackView
      ref="view"
      class="brand--view"
      :class="{
        'brand--androidPosition': isAndroidOne,
        'brand--iosPosition': isIOSOne,
        'brand--ipxPosition': isIPXOne,
        lock: opened,
        on: list && list.length,
      }"
    >
      <!-- 展示列表 -->
      <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
        <EmptyCard v-if="list.length === 0" type="search"></EmptyCard>
        <template v-else>
          <van-list v-model="loading" class="brand-wrap" :finished="finished" finished-text="没有更多了" @load="loadData">
            <BrandCard
              v-for="(item, index) of list"
              :key="index"
              :item="item"
              :top="isSearch ? 0 : index + 1"
              @click="onTap"
              class="item"
              ref="brandItemRef"
            />
          </van-list>
        </template>
      </van-skeleton>

      <!-- 选项视图 -->
      <transition name="slide">
        <div v-show="opened" class="mask" @click.self="menuShow">
          <div class="option-view">
            <div class="panel">
              <!-- Index 0 品牌分类-->
              <div v-show="newOpend === 0" class="f-clear">
                <div class="f-fl" style="width: 30%; min-height: 55vw; background-color: #f7f7f7">
                  <label
                    v-for="(item, index) of options.category"
                    :key="index"
                    class="radio"
                    :class="{ on: form.newCategoryCodeL1 === item.code }"
                    @click="form.newCategoryCodeL1 = item.code"
                    >{{ item.name }}</label
                  >
                </div>
                <div class="f-fr" style="width: 70%; padding-left: 3vw; box-sizing: border-box">
                  <p v-if="!category" class="f-tac f-vmtb s-tips">请从左侧选择分类</p>
                  <SelectTabs v-else :option="category" :value="form.category" :icon="false" @change="selectCategory"></SelectTabs>
                </div>
              </div>
              <!-- Index 1 投资费用-->
              <div v-show="newOpend === 1" style="padding: 0 1vw 0 4vw">
                <SelectTabs
                  :option="
                    options.cost.map((item) => {
                      return item.text;
                    })
                  "
                  :value="form.cost"
                  :icon="false"
                  @change="select"
                ></SelectTabs>
              </div>
              <!-- Index 2 开放地区-->
              <div v-show="newOpend === 2" style="padding: 0 4vw 0">
                <div class="open_area">
                  <div class="open_area_list">
                    <li
                      v-for="(item, index) in options.addressArray"
                      @click="cityClick(item.name, index)"
                      :class="{ on: cityActive == index }"
                      :key="index"
                    >
                      {{ item.name }}
                    </li>
                  </div>
                </div>
              </div>
              <!-- Index 3 热门推荐-->
              <div v-show="newOpend === 3" style="padding: 0 1vw 0 4vw">
                <label class="radio checkbox" :class="{ on: form.isRecord }" @click="copyBrand">严选品牌</label>
                <label
                  v-for="(value, key) in options.recommend"
                  :key="key"
                  class="radio_new"
                  :class="{ on: form.recommend == key }"
                  @click="sort(key)"
                  >{{ value }}</label
                >
              </div>
            </div>
            <div class="btnBar">
              <van-button type="info" plain class="sort" @click="onReset">重置</van-button>
              <van-button type="info" class="long" @click="onSubmit">确认</van-button>
            </div>
          </div>
        </div>
      </transition>
    </StackView>

    <!-- 悬浮窗口 -->
    <div v-show="!firstLoading" class="brand--float" :class="{ hide: isHide }">
      <div class="view">
        <p>找不到商机？试试智能匹配吧</p>
        <van-button type="info" class="btn" @click="onNext">开始匹配</van-button>
      </div>
      <SafeHolder />
    </div>
  </div>
</template>

<script>
import BrandCard from "./components/card";
import SafeHolder from "@/components/layout/safeHolder";
import SearchBar from "@/components/widget/search";
import SelectTabs from "@/components/widget/selectTabs";
import { setLocalStorage } from "@/utils/index";
export default {
  name: "brand",
  components: {
    BrandCard,
    SafeHolder,
    SearchBar,
    SelectTabs,
  },
  data() {
    return {
      isAndroidOne: false,
      isIOSOne: false,
      isIPXOne: false,

      title: "品牌大全",
      placeholder: "搜品牌", // v1.6.2：搜商机->搜品牌
      opened: null, // 展开选项
      newOpend: null,
      isHide: true, // 隐藏浮窗
      isSearch: false,
      // 选项表
      // types      : [ '热门推荐', '品牌分类', '投资费用' ],
      cityActive: 0, //
      types: ["品牌分类", "投资费用", "开放地区", "智能排序"],
      options: {
        recommend: {
          1: "热门推荐",
          2: "投资费用由低到高",
          3: "投资费用由高到低",
        },
        category: [],
        cost: [
          { text: "不限", min: null, max: null },
          { text: "0-10万", min: 0, max: 10 },
          { text: "10-20万", min: 10, max: 20 },
          { text: "20-30万", min: 20, max: 30 },
          { text: "30-50万", min: 30, max: 50 },
          { text: "50-100万", min: 50, max: 100 },
          { text: "100万以上", min: 100, max: null },
        ],
        addressArray: [], //开放地区
      },

      firstLoading: true,
      loading: true,
      finished: false,
      page: 1,
      total: 0,
      list: [],
      resetForm: null, // 重置表单
      form: {
        brandName: null,
        category: null,
        newCategoryCodeL1: null,
        newCategoryCodeL2: null,
        cost: null,
        costMax: null,
        costMin: null,
        isRecord: null,
        recommend: 1,
        address: null, //开放地区code
      },
      brandCode: "", // 查看的品牌code
      needLoadTemp: false, // 是否需要加载temp数据
      meneParams: {
        zeroClick: null,
        oneClick: null,
        twoClick: null,
        threeClick: null,
        fourClick: null,
      },
    };
  },
  methods: {
    loadData(clear) {
      this.opened = null;
      if (clear) {
        this.page = 1;
        this.finished = false;
      }
      this.loading = true;
      this.HTTP.brandList2(this.form, this.page)
        .then((res) => {
          if (clear) this.list = [];
          if (res.data === null) res.data = [];
          this.firstLoading = false;
          this.loading = false;
          this.page++;
          this.total = res.total;
          if (this.list.length) {
            this.list = this.list.concat(res.data);
          } else {
            this.list = res.data;
          }
          if (res.data.length < res.size || this.list.length >= this.total) {
            this.finished = true; // 完成加载
          }
          if (this.page > 3 && this.isHide) this.isHide = false;
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍候重试！");
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },
    //
    menuShow() {
      this.opened = null;
      this.form.recommend = this.meneParams.fourClick;
      this.cityActive = this.meneParams.threeClick;
      this.form.cost = this.meneParams.twoClick;
      this.form.newCategoryCodeL1 = this.meneParams.zeroClick;
      // this.form.category = this.meneParams.oneClick;
    },
    copyBrand() {
      this.form.isRecord = ~~!this.form.isRecord;
      if (this.form.isRecord == 0) {
        this.form.isRecord = null;
      }
    },
    // tab切换
    tabClick(item, index) {
      this.opened = this.opened === item ? null : item;
      this.newOpend = index;
    },
    // 智能排序
    sort(key) {
      this.form.recommend = key;
      // if(key > 1) {
      //     this.types[3] = this.options.recommend[key]
      // } else {
      //     this.types[3] = "智能排序";
      // }
    },
    // 点击开放地区
    cityClick(code, index) {
      if (code == "不限") {
        this.form.address = "";
      } else {
        this.form.address = code;
      }
      this.cityActive = index;
    },
    change() {},
    search(e) {
      this.form.brandName = e.value && e.value !== this.placeholder ? e.value : null;
      this.onSubmit();
    },
    clear() {
      this.form.brandName = null;
      this.onSubmit();
    },
    select(res) {
      this.form.cost = res.index;
      this.form.costMin = this.options.cost[res.index].min;
      this.form.costMax = this.options.cost[res.index].max;
    },
    selectCategory(res) {
      const value = res.index;
      const index = this.form.category.indexOf(value);
      if (index > -1) {
        this.form.category.splice(index, 1);
      } else {
        this.form.category.push(value);
      }
      // console.log(this.meneParams.oneClick)
    },

    onReset() {
      this.form = JSON.parse(JSON.stringify(this.resetForm));
      this.$refs.compSearchBar.clear();
      this.isSearch = false;
      this.cityActive = 0;
      this.newOpend = null;
      this.types = ["品牌分类", "投资费用", "开放地区", "智能排序"];
      this.meneParams = { oneClick: null, twoClick: null, threeClick: null, fourClick: null, zeroClick: null };
    },
    onSubmit() {
      if (this.loading) return;
      this.$refs.compSearchBar.setBlur();
      // 转换分类数据
      if (this.currentCategory) {
        const arr = [];
        for (const index of this.form.category) {
          arr.push(this.currentCategory[index].code);
        }
        this.form.newCategoryCodeL2 = arr.join(",");
      } else {
        this.form.newCategoryCodeL2 = null;
      }
      // 搜索条件检测
      this.isSearch = false;
      for (const key in this.form) {
        if (key === "isRecord") {
          continue;
        } else if (key === "recommend" && this.form[key] === 1) {
          continue;
        } else if (this.form[key]) {
          this.isSearch = true;
          break;
        }
      }
      // 查询数据
      let textName = "";
      this.options.category.map((item) => {
        if (item.code == this.form.newCategoryCodeL1) {
          return (textName = item.name);
        }
      });
      if (this.form.recommend == 2) this.types[3] = "投资费用由低到高";
      else if (this.form.recommend == 3) this.types[3] = "投资费用由高到低";
      else this.types[3] = "智能排序";

      if (this.form.address == "" || this.form.address == null) this.types[2] = "开放地区";
      else this.types[2] = this.form.address;

      if (this.form.cost == null || this.form.cost == "") this.types[1] = "投资费用";
      else this.types[1] = this.options.cost[this.form.cost].text;

      if (this.form.category == null || this.form.category == "") this.types[0] = "品牌分类";
      else if (this.form.category.length > 1) this.types[0] = textName + "·" + this.form.category.length;
      else {
        this.options.category.map((item) => {
          if (item.code == this.form.newCategoryCodeL1) {
            this.types[0] = item.children[this.form.category].name;
          }
        });
      }
      // let newArray = this.form.category;
      this.meneParams.fourClick = this.form.recommend;
      this.meneParams.threeClick = this.cityActive;
      this.meneParams.twoClick = this.form.cost;
      this.meneParams.zeroClick = this.form.newCategoryCodeL1;
      // this.meneParams.oneClick = newArray;
      // console.log(this.meneParams)
      this.loadData(true);
    },
    onNext() {
      this.swRouter("/brand/");
    },
    onTap(item) {
      setLocalStorage("versionCode", window._appCode);
      this.brandCode = item.brandCode;
      this.swRouter("/brand/details/" + item.brandCode);
    },
    saveTemp() {
      const temp = {
        firstLoading: this.firstLoading,
        loading: this.loading,
        finished: this.finished,
        page: this.page,
        total: this.total,
        brandCode: this.brandCode,
        list: this.list.slice(0),
        form: {
          brandName: this.form.brandName,
          category: this.form.category,
          newCategoryCodeL1: this.form.newCategoryCodeL1,
          newCategoryCodeL2: this.form.newCategoryCodeL2,
          cost: this.form.cost,
          costMax: this.form.costMax,
          costMin: this.form.costMin,
          isRecord: this.form.isRecord,
          recommend: this.form.recommend,
          address: this.form.address,
        },
      };
      this.$store.commit("setTemp", temp);
    },
    loadTemp() {
      const temp = this.$store.getters.temp;
      // console.log( 'brand discovery temp:', temp );
      this.firstLoading = temp.firstLoading;
      this.loading = temp.loading;
      this.finished = temp.finished;
      this.page = temp.page;
      this.total = temp.total;
      this.brandCode = temp.brandCode;
      this.list = temp.list.slice(0);
      this.form = Object.assign({}, temp.form);
      let selectIndex = -1;
      for (let i = 0; i < this.list.length; ++i) {
        const item = this.list[i];
        if (item.brandCode === this.brandCode) {
          selectIndex = i;
          break;
        }
      }
      if (selectIndex !== -1) {
        this.$nextTick(() => {
          this.locationBrandItem(selectIndex);
        });
      }
    },
    locationBrandItem(index) {
      const brandItemRefList = this.$refs["brandItemRef"];
      if (Array.isArray(brandItemRefList)) {
        if (index >= brandItemRefList.length) {
          return;
        }
        const brandItemRef = brandItemRefList[index];
        if (brandItemRef) {
          brandItemRef.$el.scrollIntoView();
        }
      }
    },
  },
  watch: {
    opened(val) {
      if (val) {
        this.$refs.view.$el.scrollTop = 0;
      }
    },
    "form.newCategoryCodeL1"(nv, ov) {
      if (nv !== ov) this.form.category = [];
    },
  },
  computed: {
    currentCategory() {
      if (!this.form.newCategoryCodeL1) return null;
      const category = this.options.category.find((item) => item.code === this.form.newCategoryCodeL1);
      return category ? category.children : [];
    },
    category() {
      if (!this.currentCategory) return null;
      return this.currentCategory.map((item) => {
        return item.name;
      });
    },
    categoryCode1() {
      return this.$route.query.categoryCode1 || "";
    },
    categoryCode2() {
      return this.$route.query.categoryCode2 || "";
    },
    brandName() {
      return this.$route.query.brandName || "";
    },
  },
  created() {
    this.resetForm = JSON.parse(JSON.stringify(this.form));
    // 获取行业列表
    this.HTTP.brandCategory()
      .then((res) => {
        this.options.category = res;
        let categoryCode1 = this.categoryCode1.toString();
        let categoryCode2 = this.categoryCode2.toString();
        // 初始化默认带参查询
        if (categoryCode1 && categoryCode2) {
          let arr = [];
          for (let item of res) {
            if (item.code == categoryCode1) {
              item.children &&
                item.children.forEach((ele, idx) => {
                  if (categoryCode2.includes(ele.code)) {
                    arr.push(idx);
                  }
                });
            }
          }
          this.form.category = arr;
        }
      })
      .catch((e) => {
        this.$toast("获取行业信息时发生错误！");
      });
    this.HTTP.cityList()
      .then((res) => {
        const firstCity = { name: "不限", code: "" };
        let newCity = res.map((item) => {
          return {
            name: item.name,
            code: item.code,
          };
        });
        this.options.addressArray = [firstCity, ...newCity];
      })
      .catch((e) => {
        this.$toast("获取开放地区发生错误！");
      });
  },
  mounted() {
    this.$nextTick(() => {
      if (this.needLoadTemp) {
        this.loadTemp();
      } else {
        // 初始化默认带参查询
        if (this.categoryCode1 || this.categoryCode2) {
          this.form.newCategoryCodeL1 = this.categoryCode1 || null;
          this.form.newCategoryCodeL2 = this.categoryCode2 || null;
        }
        this.form.brandName = this.brandName;
        // 加载数据
        this.loadData();
      }
    });
  },
  beforeMount() {
    this.JSBridge.callHandler("setStatebarTheme", "black");
    this.isAndroidOne = window.isClient && window.isAndroid;
    if (window.isClient && !window.isAndroid) {
      this.isIOSOne = window.isIOS;
      this.isIPXOne = window.isIPX;
    }
  },
  beforeDestroy() {
    this.saveTemp();
  },
  beforeRouteEnter(to, from, next) {
    // console.log( 'brand discovery beforeRouteEnter' );
    // console.log( 'charts to:', to, ', from:', from );
    if (from.path.indexOf("/brand/details") !== -1) {
      next((vm) => {
        vm.needLoadTemp = true;
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.brand {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100vh;

  .brand-join {
    font-size: 3.47vw;
    color: #347fff;
  }

  #{&}--search {
    padding: 1vw $padding_4;
    background-color: white;
  }

  #{&}--option {
    @include flex;
    height: 10vw;
    padding: 0 $padding_4;
    background-color: white;
    color: $c-text;
    line-height: normal;
    div {
      @include flexColumn;
      height: 100%;
      font-style: normal;
      font-size: $font_373;
      padding: 0 4vw;
      background: url(/static/icon/arrow_down_2.png) no-repeat right 0.5vw center;
      background-size: 2.5vw auto;
      flex: 1;
      box-sizing: border-box;
      overflow: hidden;
      text-align: center;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
      }
      &.on {
        color: $c-main;
      }
    }
  }

  #{&}--view {
    position: relative;
    // height: auto!important;
    flex: auto;
    background-color: $c-pageBg2;
    padding: $padding_4;
    //  $padding_4 19.73vw
    height: calc(100% - 24vw - 15.5vw);
    &.on {
      background: linear-gradient(to bottom, #eaf3fe, white);
    }
    &.lock {
      overflow: hidden;
    }
    .item {
      margin-bottom: $padding_3;
      border-radius: $radius_xs;
      box-shadow: 0 0.8vw 0.8vw 0 rgba(#5fa9e6, 0.08);
    }
    .brand-wrap {
      background-image: url("/static/img/brand_list_bg@2x.png");
      background-repeat: repeat-y;
      background-position-x: right;
      background-position-y: 107px;
      background-size: 14.93vw 68.27vw;
    }

    .mask {
      @include ani;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      background-color: rgba(black, 0.7);

      .option-view {
        background-color: white;

        .on {
          color: $c-main;
        }
        .panel {
          min-height: 55vw;
        }
        .radio {
          @include ani;
          display: block;
          width: 100%;
          height: 12vw;
          line-height: 12vw;
          font-size: $font_373;
          text-align: center;
        }
        .radio_new {
          @include ani;
          display: block;
          // width: calc(33.33% - 3vw);inline-
          width: 100%;
          height: 8vw;
          line-height: 8vw;
          font-size: $font_373;
          // background: #f5f5f5 url(/static/icon/checked_tr_blue@64.png) no-repeat right top;
          background-size: 0;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 0vw 3vw 0;
          &:last-child {
            margin-right: 0;
          }
        }
        .checkbox {
          background: url(/static/icon/unselect@2x.png) no-repeat 36% center;
          background-size: auto 4vw;

          &.on {
            background-image: url(/static/icon/success.png);
            color: $c-text;
          }
        }
        .btnBar {
          @include flex;
          padding: $padding_3 $padding_4;

          .sort {
            width: 28vw;
            color: #347fff;
          }
          .long {
            width: 61vw;
          }
        }
      }
      .open_area {
        width: 100%;
        height: 55vw;
        overflow-y: auto;
        .open_area_list {
          width: 100%;
          height: auto;
          li {
            height: 8vw;
            line-height: 8vw;
            list-style: none;
            margin: 0;
            font-size: $font_373;
            text-align: center;
          }
        }
      }
    }
  }
  #{&}--androidPosition {
    height: calc(100vh - #{$androidStateBar} - #{$headerHeight} - 24vw);
  }
  #{&}--ipxPosition {
    height: calc(100vh - #{$iPXStateBar} - #{$headerHeight} - #{$iPXBottomBar} - 24vw);
  }
  #{&}--iosPosition {
    height: calc(100vh - #{$iOSStateBar} - #{$headerHeight} - 24vw);
  }
  #{&}--float {
    @include ani;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -1.5vw 2vw rgba(#a7a7a7, 0.2);
    font-size: $font_400;
    font-weight: bold;
    overflow: hidden;
    box-sizing: border-box;

    &.hide {
      transform: translateY(150%);
      max-height: 0;
    }
    .view {
      @include flex;
      padding: $padding_4;
    }

    .btn {
      width: 30vw;
      background: #347fff;
      border-color: #347fff;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    max-height: 100vh;
    opacity: 1;
  }
  .slide-enter,
  .slide-leave-to {
    max-height: 0;
    opacity: 0;
  }
}
</style>
