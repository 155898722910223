<template>
    <div class="tokeout-list__wrap">
        <van-sticky :offset-top="headerHeight">
            <div class="select-box">
                <span class="sel-city" @click="handleClick">
                    {{ currentCity }}
                </span>
                <span class="sel-city indust" @click="selIntustry">
                    {{ searchOpt.categoryName }}
                </span>
                <div class="industry-tabs-wrap" :swipe-threshold="4">
                    <van-tabs v-model="searchOpt.sort" @change="changeType">
                        <van-tab :title="tab.name" :name="tab.id" v-for="(tab, i) in timeList" :key="i"></van-tab>
                    </van-tabs>
                </div>
            </div>
        </van-sticky>
        <div class="content">
            <van-skeleton :loading="firstLoading || loading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <one-item v-for="(item, i) in dataList" :key="i" :topNum="i" :itemObj="item">
                    <van-image
                        v-if="i === 1"
                        class="box-shadow"
                        src="/static/img/hot-search/more-tokeout.png"
                        @click="deeplink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '5' }, id: '83000', btnId: '83001' })"
                    />
                </one-item>
            </van-list>
        </div>
    </div>
</template>

<script>
import OneItem from './OneItem.vue';
import { IndustryList } from '../const';
export default {
    components: { OneItem },
    name      : 'tokeoutList',
    props     : {
        headerHeight: {
            type   : Number,
            default: 0
        }
    },
    data () {
        return {
            firstLoading: false,
            loading     : false,
            finished    : false,
            industryType: 0,
            IndustryList,
            currentCity : '定位中',
            searchOpt   : {
                cityCode    : '',
                // regionCode: '',
                current     : 0,
                size        : 20,
                sort        : 1,
                categoryName: '全部品类'
            },
            dataList: [],
            timeList: [
                {
                    name: '按月销量排行',
                    id  : 1
                },
                {
                    name: '按月销售额排行',
                    id  : 2
                }
            ],
            selType: false // 选择类型
        };
    },
    activated () {
        if ( this.industryType != this.$store.getters.tokeoutType ) {
            this.industryType = this.$store.getters.tokeoutType || 0;
            this.searchOpt.categoryName = IndustryList[this.industryType];
            this.changeType();
        }
    },
    mounted () {
        this.firstLoading = true;
        const city = this.$store.getters.locaCity;
        console.log( 'city', city );
        if ( city.name ) {
            this.currentCity = city.name;
            this.searchOpt.cityCode = city.code;
            this.firstLoading = false;
            return;
        }
        try {
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                this.currentCity = data.city;
                this.searchOpt.cityCode = data.adCode.slice( 0, 4 ) + '00';
                const storeCity = {
                    name: data.city,
                    code: data.adCode.slice( 0, 4 ) + '00'
                };
                this.$store.commit( 'setLocaCity', storeCity );
                this.firstLoading = false;
            } );
        } catch ( e ) {
            console.log( e );
            const storeCity = {
                name: '深圳市',
                code: '440300'
            };
            this.currentCity = storeCity.name;
            this.searchOpt.cityCode = storeCity.code;
            this.$store.commit( 'setLocaCity', storeCity );
            this.firstLoading = false;
        }
    },
    methods: {
        // 更改类别，城市，品类
        changeType () {
            this.dataList = [];
            this.searchOpt.current = 0;
            this.finished = false;
            this.onLoad();
        },
        // 加载
        onLoad () {
            this.searchOpt.current ++;
            this.loading = true;
            this.fetchList();
        },
        // 获取列表
        fetchList () {
            const param = {
                ...this.searchOpt
            };
            if ( param.categoryName === '全部品类' ) {
                param.categoryName = null;
            }
            this.HTTP.waiMaiPage( param ).then( ( res ) => {
                console.log( '列表：', res );
                this.dataList = this.dataList.concat( res.data.data );
                // 加载状态结束
                this.loading = false;
                // 数据全部加载完成
                if ( res.data.data.length !== res.data.size ) {
                    this.finished = true;
                }
            } );
        },
        // 选择城市
        handleClick () {
            this.JSBridge.callHandler( 'selectCity', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                this.currentCity = data.name;
                this.searchOpt.cityCode = data.code;
                this.changeType();
            } );
        },
        // 选择品类
        selIntustry () {
            this.$router.push( '/hot/selectIntustry/' + this.industryType + '?type=tokeout' );
        },
        // 跳转
        deeplink ( { type, url, params, id, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            btnId && this.JSBridge.BTNCLICK( this.$route.meta.id, '', 83000, btnId );
            if (window._appCode && window._appCode >= 300) {
              this.JSBridge.callHandler( 'onNormalizingCall', {
                type: 5,
                url: "CHANGE_MAIN_TAB",
                id: "queryData",
                isClear: true,
              } );
            } else {
              this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                id,
                params: JSON.stringify( params )
              } );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.tokeout-list__wrap {
    ::v-deep .van-sticky--fixed {
        background: #f7f7f7;
    }
    .select-box {
        display: flex;
        align-items: center;
        height: 5.6vw;
        padding-top: 2vw;
        padding-bottom: 3vw;
        padding-left: 4vw;
        .sel-city {
            width: 18.26667vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 7.5vw;
            line-height: 7.5vw;
            margin-right: 0.86vw;
            font-size: 4vw;
            text-align: center;
            position: relative;
            &::after {
                position: absolute;
                content: '';
                border: 1.0667vw solid transparent;
                border-top: 1.3334vw solid #999;
                top: 2.8vw;
            }
            &.indust {
                width: 21.26667vw;
            }
        }
        .industry-tabs-wrap {
            min-width: 58.13vw;
            height: 7.5vw;
            line-height: 100%;
            white-space: nowrap;
            ::v-deep .van-tabs__wrap {
                height: 12.533vw;
            }
            ::v-deep .van-tabs__nav {
                background: transparent;
                padding: 0;
                .van-tab {
                    min-width: 9.26667vw;
                    height: 7.5vw;
                    margin-right: 1.06vw;
                    font-size: 3.733vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    background: transparent;
                    box-shadow: none;
                    color: #666;
                    &--active {
                        color: #333333;
                        font-weight: 700;
                        position: relative;
                        &::after {
                            display: none;
                        }
                    }
                }
                .van-tabs__line {
                    display: block;
                    height: 1.33vw;
                    background: url('/static/icon/hot_icon.png') center no-repeat;
                    background-size: 7.47vw 1.07vw;
                }
            }
        }
    }
    .content {
        padding: 0 4vw;
        .box-shadow {
            box-shadow: 0 0 2.266vw 0 rgba(0, 0, 0, 0.09);
        }
    }
}
</style>
