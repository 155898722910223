<template>
    <Wraper title="历史门店开店时序图" :showIcon="true" @clickTips="clickTips" v-if="bgmData.length > 0">
        <div class="search-chart-city" v-if="isMember">
            <SequenceBar :dataObject="bgmData" />
        </div>
        <Lock v-else :lockImg="lockImg"/>
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";
import SequenceBar from "../../echarts/sequenceBar.vue";
import Lock from "@/components/openVip/lock.vue"

export default {
    components: { Wraper, SequenceBar, Lock },
    props: {
        bgmData: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            isMember: true,
            lockImg: 'static/img/brand-not-vip.png'
        }
    },
    mounted() {
        this.JSBridge.callHandler("getString", "isMember", (data) => {
            if (data) {
                if (typeof data === "string") {
                    data = JSON.parse(data);
                }
                this.isMember = data;
            }
        });
    },
    methods: {
        clickTips() {
            this.swRouter("/reportView/dataExplain");
        },
    },
};
</script>
<style lang="scss" scoped>
.search-chart-city {
    position: relative;
    width: 100%;
    height: 220px;
}
</style>
