<template>
    <div class="orders">
        <StateBar></StateBar>
        <TitleHeader :title="title" bgcolor="transparent" :back="showBack">
            <!-- <div slot="right" @click="$router.push( '/bills' )" class="btnColor">开发票</div> -->
        </TitleHeader>

        <AutoView :header="true" :footer="false" class="orders--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="orders"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="我是有底线的"
                        @load="loadData" >
                        <CourseCard v-for="( item ) in list" :key="item.id" @tap="onTap"
                            :item="item" />
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import CourseCard from '@/components/card/course';

export default {
    name      : 'orders',
    components: {
        CourseCard,
    },
    data () {
        return {
            title       : '我的课程',
            showBack    : true,
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            page        : 1,
            total       : 0,
            list        : [],
        };
    },
    methods: {
        loadData () {
            this.loading  = true;
            this.HTTP.courseList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        // onTap ( item ) {
        //     this.openPage( item.businessId, item.orderNo );
        // },
        // 跳转到原生
        onTap ( item ) {
            if ( window._appCode && window._appCode >= 170 ) {
                this.JSBridge.callHandler( 'onNormalizingCall', {
                    type  : 5,
                    url   : 'COURSE_PAGE',
                    id    : item.courseId,
                    params: null
                } );
            } else {
                this.$toast( 'APP版本过低，请升级以获取更好的体验！' );
            }
        },
        openPage ( id, orderNo ) {
            const url = '/pay/' + id + '?orderNo=' + orderNo;
            this.swRouter( url );
        },
        goto () {
            this.swRouter( '/' );
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        // this.JSBridge.callHandler( 'clearHistory', true );
        //  this.JSBridge.callHandler( 'clearHistory', 'my' );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.orders {
    height: 100vh;
    background-color: $c-pageBg;

    .btnColor {
        color: $c-main;
    }

    #{&}--view {
        .l-panel {
            padding: $padding2;
            padding-top: 3.5vw;
            padding-bottom: 3.5vw;
        }
    }
}
</style>
