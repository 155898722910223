<template>
    <div class="wrapper">
        <StateBar />
        <TitleHeader title="我的商铺" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #f3f4f7">
            <div slot="right" class="right-title" @click="uploadShop">上传商铺</div>
        </TitleHeader>
        <AutoView ref="view" :footer="false" class="list-view">
            <TabBar :option="tabs" :value="current" @change="change"></TabBar>
            <StackView ref="view" class="list--view">
                <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
                    <van-empty style="position: absolute;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);width: 100%;"
                    v-if="!dataList.length" description="暂无数据" />
                    <template v-else>
                        <!-- <van-pull-refresh v-model="dataLoading" @refresh="loadNewData"> -->
                            <van-list v-model="loading" :finished="finished" finished-text="" @load="loadData">
                                <div class="list">
                                    <div class="shop-card" v-for="(item, index) in dataList" :key="index" @click="shopDetailClick(item)">
                                        <p class="title">{{item.title}}</p>
                                        <div class="cell">
                                            <div class="cell-hd doorUrl">
                                                <van-image class="img" v-if="item.frontDoorUrl" :src="formatUrl(item.frontDoorUrl) + '?x-oss-process=image/resize,m_fill,w_100'">
                                                    <template v-slot:loading>
                                                        <img class="img" src="/static/shopEcology/img-default.png" alt="">
                                                    </template>
                                                </van-image>
                                                <img class="img" v-else src="/static/shopEcology/img-default.png" alt="">
                                            </div>
                                            <div class="cell-bd info">
                                                <p class="params">
                                                    <!-- <span v-for="params in item.params" :key="params">{{params}}</span> -->
                                                    <span v-if="item.subtitle">{{item.subtitle}}</span>
                                                </p>
                                                <div class="flag">
                                                    <div class="flag-wrapper">
                                                        <span v-for="flag in item.tagList" :key="flag">{{flag}}</span>
                                                    </div>
                                                    <img class="flag-mask" src="static/shopEcology/my/mask.png" alt="">
                                                </div>
                                                <p class="price">
                                                    <span >¥<b>{{item.monthlyRent || '-'}}</b>/月</span>
                                                    <span >¥<b>{{item.transferFee || '-'}}</b>转让费</span>
                                                </p>
                                            </div>
                                            <div class="status-bg">
                                                <img v-if="item.auditStatus === 3" src="static/shopEcology/my/image-pass@2x.png" alt="" />
                                                <img v-if="item.auditStatus === 2" src="static/shopEcology/my/image-off@2x.png" alt="" />
                                                <img v-if="item.status === 0" src="static/shopEcology/my/image-off-shelf@2x.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="status" :class="statusClass(item)">
                                            <div class="status-info">
                                                <img :src="statusIcon(item)" alt="" />
                                                <span>{{statusTitle(item)}}</span>
                                                <div class="reason" v-if="item.failedReason">{{item.failedReason}}</div>
                                            </div>
                                            <div class="btn-warpper">
                                                <div class="btn" v-if="item.auditStatus !== 1 && item.status !== 0" @click="modifyInfo(item)">
                                                    <img src="static/shopEcology/my/icon-modify@2x.png" alt="" />
                                                    <span>修改信息</span>
                                                </div>
                                                <div class="btn cancel" v-if="item.auditStatus === 1" @click="withdraw(item)">
                                                    <img src="static/shopEcology/my/icon-withdraw@2x.png" alt="" />
                                                    <span>撤回审核</span>
                                                </div>
                                                <div class="btn push" v-if="current === 2 && item.status !== 0 && !item.deployed" @click="cancelOrPush(item)">
                                                    <img src="static/shopEcology/my/icon-push@2x.png" alt="" />
                                                    <span>发布</span>
                                                </div>
                                                <div class="btn cancel" v-if="current === 2 && item.status !== 0 && item.deployed" @click="cancelOrPush(item)">
                                                    <img src="static/shopEcology/my/icon-close@2x.png" alt="" />
                                                    <span>取消发布</span>
                                                </div>
                                                <div class="btn represent" v-if="current === 2 && item.status === 0" @click="representations(item)">
                                                    <img src="static/shopEcology/my/icon-representations@2x.png" alt="" />
                                                    <span>申述</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="warn-info">
                                        <img src="static/shopEcology/my/icon_warn@2x.png" alt="">
                                        <p v-if="current === 1">修改信息后，需要再次提交审核才能更新到广场</p>
                                        <p v-else>通过审核的铺，可直接修改租赁状态和租赁条件</p>
                                    </div>
                                </div>
                            </van-list>
                        <!-- </van-pull-refresh> -->
                    </template>
                </van-skeleton>
            </StackView>

        </AutoView>
    </div>
</template>

<script>
import TabBar from "@/components/widget/tabBar";
import { getMyList, deployShop, cancelAudit } from '@/request/shopEcology'
const param = {
    current: 1,
    size: 10
}
export default {
    data() {
        return {
            searchOpts: { ...param },
            dataLoading: false,
            finished: false,
            firstLoading: true,
            loading: true,
            current: 1,
            tabs: [
                { key: 1, text: "草稿箱" },
                { key: 2, text: "已上架" },
            ],
            dataList: []
        };
    },
    components: {
        TabBar,
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            // if(window.isIOS) {
            //     this.loadData();
            // }
            this.JSBridge.registerHandler("onAppResume", (data) => {
                console.log('app 调用 onAppResume 函数成功');
                this.searchOpts.current = 1
                this.dataList = []
                this.loadData();
            })
        },
        loadData() {
            getMyList({
                ...this.searchOpts,
                type: this.current
            }).then(res => {
                this.dataList = this.dataList.concat(res.data || [])
                // 加载状态结束
                this.loading = false;
                this.firstLoading = false;
                // 数据全部加载完成
                if (!res.data || res.data?.length < this.searchOpts.size) {
                    this.finished = true;
                } else {
                    this.searchOpts.current ++
                }
            })
        },
        shopDetailClick(item) {
            if(this.current === 1) {
                this.JSBridge.BTNCLICK(this.$route.meta.id, item.shopId, 2440200, 2440203);
            } else {
                this.JSBridge.BTNCLICK(this.$route.meta.id, item.shopId, 2440300, 2440304);
            }
        },
        uploadShop() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 2440100, 2440101);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "UPLOAD_SHOP_HOME",
                id: null,
                params: null,
            });
        },
        change(res) {
            let currentId = this.current === 1 ? 2440401 : 2440402
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 2440400, currentId);
            if (this.loading) {
                this.$toast("数据加载中，请勿过快切换！");
                return;
            }
            console.log('res--------')
            console.log(res)
            this.current = res.key;
            this.searchOpts = Object.assign({}, param)
            this.finished = false;
            this.firstLoading = true
            this.loading = true
            this.dataList = []
            this.loadData()
        },
        representations() {
            this.swRouter('/h5/#/shop/qrcode?status=1')
        },
        cancelOrPush(item) {
            let pushId = item.deployed ? 2440303 : 2440303
            this.JSBridge.BTNCLICK(this.$route.meta.id, item.shopId, 2440100, pushId);
            this.$dialog.confirm({
                title: item.deployed ? "确定取消发布吗？" : "确定发布吗？",
                message: item.deployed ? "取消发布后，商铺将不再展示在商铺广场" : "发布后，商铺将在商铺广场可见",
                confirmButtonText: item.deployed ? "取消发布" : "发布",
                confirmButtonColor: "#347FFF",
                cancelButtonText: "取消",
            })
            .then(() => {
                // on confirm
                deployShop({
                    deployStatus: item.deployed ? 0 : 1,
                    shopId: item.shopId
                }).then(res => {
                    console.log(res)
                    this.dataList = []
                    this.loadData()
                })
            })
            .catch(() => {
                // on cancel
            });
        },
        modifyInfo(item) {
            // 埋点
            if(this.current === 1) {
                this.JSBridge.BTNCLICK(this.$route.meta.id, item.shopId, 2440200, 2440201);
            } else {
                this.JSBridge.BTNCLICK(this.$route.meta.id, item.shopId, 2440300, 2440301);
            }

            if(item.hasPlatformShop && !item.isLessor) {
                this.swRouter('/h5/#/shop/qrcode?status=0')
            } else {
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "UPLOAD_SHOP_HOME",
                    id: item.shopId,
                    params: null
                });
            }
        },
        withdraw(item) {
            this.JSBridge.BTNCLICK(this.$route.meta.id, item.shopId, 2440200, 2440202);
            this.$dialog.confirm({
                title: "确定撤回审核吗？",
                message: "你可以修改信息后再重新提交审核",
                confirmButtonText: "撤回审核",
                confirmButtonColor: "#347FFF",
                cancelButtonText: "取消",
            })
            .then(() => {
                // on confirm
                cancelAudit({ shopId: item.shopId }).then(res => {
                    console.log(res)
                    this.dataList = []
                    this.searchOpts.current = 1
                    this.loadData()
                })
            })
            .catch(() => {
                // on cancel
            });
        },
        statusIcon(item) {
            if(this.current === 1) {
                // 草稿箱
                // 0 - 未提审 1 - 审核中 2 - 审核未通过 3 - 审核已通过
                switch(item.auditStatus) {
                    case 0:
                        return 'static/shopEcology/my/icon-unBrought@2x.png'
                    case 1:
                        return 'static/shopEcology/my/icon-broughting@2x.png'
                    case 2:
                        return 'static/shopEcology/my/icon-brought-error@2x.png'
                    case 3:
                        return 'static/shopEcology/my/icon-broughted@2x.png'
                }
            } else {
                // 是否上架
                if(!item.status) {
                    // 已下架
                    return 'static/shopEcology/my/icon-off-shelf@2x.png'
                }
                if(item.deployed) {
                    // 已发布
                    return 'static/shopEcology/my/icon-broughted@2x.png'
                } else {
                    // 未发布
                    return 'static/shopEcology/my/icon-unBrought@2x.png'
                }
            }
        },
        statusTitle(item) {
            if(this.current === 1) {
                switch(item.auditStatus) {
                    case 0:
                        return '未提审'
                    case 1:
                        return '审核中'
                    case 2:
                        return '审核未通过'
                    case 3:
                        return '审核已通过'
                }
            } else{
                if(!item.status) {
                    return '已下架'
                }
                if(item.deployed) {
                    return '已发布'
                } else {
                    return '未发布'
                }
            }
        },
        statusClass(item) {
            if(this.current === 1) {
                switch(item.auditStatus) {
                    case 0:
                        return ''
                    case 1:
                        return 'reviewing'
                    case 2:
                        return 'unReviewed'
                    case 3:
                        return 'reviewed'
                }
            } else {
                if(!item.status) {
                    return 'off-shelf'
                }
                if(item.deployed) {
                    return 'reviewed'
                } else {
                    return ''
                }
            }
        },
        formatUrl(url){
            if (!url) {
                return '';
            }
            return url.replace(/\\/g, '');
        }
    },
};
</script>
<style lang='scss' scoped>
@import "@/styles/px2vw.scss";
@import "@/styles/index.scss";
.wrapper {
  height: 100vh;
  overflow: hidden;
}
.right-title {
    font-size: vw(26);
    color: #347fff;
}
.list-view {
    display: flex;
    flex-direction: column;
}
.list--view {
    background-color: #f8f8f9;
}
.list {
    background-color: #f8f8f9;
    padding: vw(20);
    flex-grow: 1;
}
.shop-card {
    padding: vw(30);
    background-color: #fff;
    margin-bottom: vw(20);
    border-radius: vw(10);
    &:last-child {
        border-bottom: 0;
    }
    .cell {
        margin-top: vw(12);
        position: relative;
    }
    .title {
        font-size: vw(28);
        font-weight: bold;
        color: #1d2233;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: vw(38);
    }
    .doorUrl {
        width: vw(158);
        height: vw(120);
        border-radius: vw(8);
        overflow: hidden;
        background: #eee;
        img {
            width: 100%;
            border-radius: vw(8);
        }
    }
    .info {
        padding-left: vw(18);
        .params {
            font-size: 0;
            span {
                font-size: vw(24);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #676c84;
                line-height: vw(30);
                padding: vw(0) vw(20);
                border-right: vw(2) solid #676c84;
                &:first-child {
                    padding-left: vw(0);
                }
                &:last-child {
                    border-right: 0;
                }
            }
        }
        .flag {
            font-size: vw(20);
            font-weight: 400;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #676c84;
            position: relative;
            line-height: vw(26);
            margin-top: vw(10);
            .flag-wrapper {
                max-width: vw(470);
                overflow: auto;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                span {
                    border-radius: vw(4);
                    flex-shrink: 0;
                    line-height: vw(26);
                    border: vw(2) solid #abb3c1;
                    padding: vw(4) vw(12);
                    margin-right: vw(10);
                }
            }
            .flag-mask {
                position: absolute;
                width: vw(90);
                height: vw(36);
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .price {
            font-size: vw(20);
            // font-family: HarmonyOS_Sans_TC_Bold;
            color: #ff8047;
            line-height: vw(38);
            margin-top: vw(10);
            span {
                padding: 0 vw(20);
                border-right: vw(2) solid #abb3c1;
                &:first-child {
                    padding-left: vw(0);
                }
                &:last-child {
                    border-right: 0;
                }
                b {
                    font-size: vw(28);
                    margin: 0 vw(4);
                }
            }
        }
    }
    .status-bg {
        position: absolute;
        right: vw(-30);
        top: 50%;
        transform: translateY(-50%);
        width: vw(100);
        height: vw(120);
        img {
            width: 100%;
            height: 100%;
        }
    }
}
.status {
    margin-top: vw(10);
    background: linear-gradient(to right, rgba(51, 147, 221, 0.1), rgba(51, 147, 221, 0));
    border-radius: vw(4);
    height: vw(53);
    line-height: vw(53);
    font-size: vw(24);
    font-weight: 600;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .status-info {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;
        margin-left: vw(20);
        color: #3393dd;
        img {
            width: vw(25);
            height: vw(25);
            margin-right: vw(10);
        }
        span {
            font-family: PingFangSC-Regular, PingFang SC;
        }
        .reason {
            color: #676C84;
            max-width: vw(240);
            line-height: vw(30);
            position: relative;
            font-weight: normal;
            margin: vw(10) vw(20) vw(10) vw(40);
            &::after {
                content: "";
                width: vw(2);
                height: vw(29);
                position: absolute;
                background-color: #DEDEE7;
                left: vw(-20);
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .btn-warpper {
        display: flex;
        .btn {
            min-width: vw(135);
            height: vw(49);
            border: vw(1) solid #347fff;
            color: #347fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: vw(4);
            padding: 0 vw(16);
            margin-left: vw(20);
            &:first-child {
                margin-left: vw(0);
            }
            img {
                width: vw(33);
                height: vw(33);
                margin-right: vw(10);
            }
            span {
                flex-shrink: 0;
                font-family: PingFangSC-Regular, PingFang SC;
            }
        }
        .cancel {
            border: vw(1) solid #5a5e66;
            color: #5a5e66;
        }
        .represent {
            border: vw(1) solid #ff504e;
            color: #ff504e;
        }
        .push {
            border: vw(1) solid #347fff;
            background-color: #347fff;
            color: #fff;
        }
    }
}
.reviewing {
    background: linear-gradient(to right, rgba(251, 145, 96, 0.1), rgba(251, 145, 96, 0));
    .status-info {
        color: #fb9160;
    }
    .btn {
        border: vw(1) solid #5a5e66;
        color: #5a5e66;
    }
}
.reviewed {
    background: linear-gradient(to right, rgba(32, 197, 147, 0.1), rgba(32, 197, 147, 0));
    .status-info {
        color: #20c593;
    }
}
.unReviewed {
    background: linear-gradient(to right, rgba(255, 80, 78, 0.1), rgba(255, 80, 78, 0));
    .status-info {
        color: #ff504e;
    }
}
.off-shelf {
    background: linear-gradient(to right, rgba(103, 108, 132, 0.1), rgba(103, 108, 132, 0));
    .status-info {
        color: #676c84;
    }
}
.warn-info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: vw(22);
    font-family: PingFangSC-Regular, PingFang SC;
    color: #8E9099;
    line-height: vw(30);
    margin-top: vw(30);
    img {
        width: vw(22);
        height: vw(22);
        margin-right: vw(5);
    }
}
</style>
