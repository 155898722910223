<template>
    <div class="operation-detail">
        <StateBar />
        <TitleHeader title="活动详情" bgcolor="transparent" :back="true"></TitleHeader>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
        <VanImage @load="imageLoadDone" src="/static/img/operation-plan/detail.png"></VanImage>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstLoading: true
        }
    },
    methods: {
        imageLoadDone() {
            this.firstLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.operation-detail {
    height: 100vh;
}
::v-deep .van-image {
    width: 100vw;
}
</style>
