<template>
    <div class="intention-form">
        <div class="header">
            <StateBar />
            <TitleHeader title="提交选址意向" bgcolor="transparent" color="black" :back="true"
                style="border-bottom: 1px solid #f3f4f7">
                <span slot="right" class="detail" @click="submit">提交</span>
            </TitleHeader>
        </div>
        <AutoView :header="true" :footer="false">
            <div class="body-box">
                <div class="select-box">
                    <!-- <div class="left-title">需要服务</div> -->
                    <div class="right-body">
                        <div class="right-body-item" @click="selectClick(item, key)"
                            :class="{ active: key != form.type }" v-for="(item, key) in selectArr" :key="key">
                            <div class="left-box">
                                <div class="title">{{ item.title }}</div>
                                <div class="content">{{ item.content }}</div>
                            </div>
                            <div class="right-box">
                                <img v-if="key != form.type" :src="item.icon1" alt="" />
                                <img v-else :src="item.icon" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <van-cell-group>
                <van-field v-model="form.name" name="姓名" label="姓名" placeholder="请填写" :rules="[{ required: true }]"
                    class="link-right" />
                <van-field v-model="form.phone" name="联系手机号" label="联系手机号" placeholder="请填写"
                    :rules="[{ required: true }]" class="link-right" />
            </van-cell-group>
            <van-cell-group>
                <van-cell title="开店意向区域" label="描述信息" class="link-right link-blue">
                    <template #label>
                        <div class="loadingLocation" @click="openMap">
                            <div class="is-get-loading" v-if="isGetLocation">
                                <div class="location-icon" />
                                <div class="location-load-title">正在获取定位…</div>
                                <div class="select-go" />
                            </div>
                            <div class="has-lngLat" v-else-if="hasLnglat">
                                <div class="has-item-content">
                                    <div class="ht-title">
                                        <div class="location-icon" />
                                        <div class="poi-name">{{ form.poiName }}</div>
                                        <div class="poi-radius">｜半径{{ form.radius }}km</div>
                                    </div>
                                    <div class="ht-addr">{{ form.address }}</div>
                                </div>
                                <div class="select-go" />
                            </div>
                            <div class="is-get-loading" v-else>
                                <div class="location_error" />
                                <div class="location-load-title">定位未授权，无法获取定位</div>
                                <div class="manual-title">手动选择位置</div>
                                <div class="select-go" />
                            </div>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
            <van-cell-group v-if="form.type">
                <van-field readonly clickable name="picker" :value="formatCategory" label="经营行业" placeholder="请选择"
                    @click="openIndustries" class="link-right link-blue">
                    <template #right-icon>
                        <div class="select-go" />
                    </template>
                </van-field>
                <van-field readonly clickable name="picker" :value="businessCategory" label="商铺类型" placeholder="请选择"
                    @click="openFormats" class="link-right link-blue">
                    <template #right-icon>
                        <div class="select-go" />
                    </template>
                </van-field>
                <van-field readonly clickable name="picker" :value="areaText" label="期望面积" placeholder="请选择"
                    @click="openArea" class="link-right link-blue">
                    <template #right-icon>
                        <div class="select-go" />
                    </template>
                </van-field>
                <van-field readonly clickable name="picker" :value="rentText" label="期望月租" placeholder="请选择"
                    @click="openRent" class="link-right link-blue">
                    <template #right-icon>
                        <div class="select-go" />
                    </template>
                </van-field>
                <van-field readonly clickable name="picker" :value="budgetText" label="投资预算" placeholder="请选择"
                    @click="openBudget" class="link-right link-blue">
                    <template #right-icon>
                        <div class="select-go" />
                    </template>
                </van-field>
            </van-cell-group>
            <van-popup v-model="showArea" round position="bottom" :style="{ height: '21%' }"
                :close-on-click-overlay="false">
                <div class="popup-container">
                    <div class="popup-header">
                        <div class="header-cannel" @click="cancelArea">取消</div>
                        <div class="header-title">期望面积</div>
                        <div class="header-submit" @click="submitArea">完成</div>
                    </div>
                    <div class="popup-content">
                        <van-field v-model="form.areaMin" type="digit" placeholder="最小面积" input-align="center"
                            ref="areaMinInput">
                            <template #extra>
                                <span> ㎡</span>
                            </template>
                        </van-field>
                        <div class="split">
                            <div class="split-bar" />
                        </div>
                        <van-field v-model="form.areaMax" type="digit" placeholder="最大面积" input-align="center"
                            ref="areaMaxInput">
                            <template #extra>
                                <span> ㎡</span>
                            </template>
                        </van-field>
                    </div>
                </div>
            </van-popup>
            <van-popup v-model="showRent" round position="bottom" :style="{ height: '21%' }"
                :close-on-click-overlay="false">
                <div class="popup-container">
                    <div class="popup-header">
                        <div class="header-cannel" @click="cancelRent">取消</div>
                        <div class="header-title">期望月租</div>
                        <div class="header-submit" @click="submitRent">完成</div>
                    </div>
                    <div class="popup-content">
                        <van-field v-model="form.rentMin" type="digit" placeholder="自定义最低价" input-align="center"
                            ref="rentMinInput" :formatter="formatter" />
                        <div class="split">
                            <div class="split-bar" />
                        </div>
                        <van-field v-model="form.rentMax" type="digit" placeholder="自定义最高价" input-align="center"
                            ref="rentMaxInput" :formatter="formatter" />
                    </div>
                </div>
            </van-popup>
            <van-popup v-model="showBudget" round position="bottom" :style="{ height: '21%' }"
                :close-on-click-overlay="false">
                <div class="popup-container">
                    <div class="popup-header">
                        <div class="header-cannel" @click="cancelBudget">取消</div>
                        <div class="header-title">投资预算</div>
                        <div class="header-submit" @click="submitBudget">完成</div>
                    </div>
                    <div class="popup-content">
                        <van-field v-model="form.budgetMin" type="digit" placeholder="自定义最低价" input-align="center"
                            ref="budgetMinInput" :formatter="formatter" />
                        <div class="split">
                            <div class="split-bar" />
                        </div>
                        <van-field v-model="form.budgetMax" type="digit" placeholder="自定义最高价" input-align="center"
                            ref="budgetMaxInput" :formatter="formatter" />
                    </div>
                </div>
            </van-popup>
            <van-overlay :show="showLoading">
                <div class="wrapper">
                    <div class="block">
                        <van-loading color="#fff" vertical>提交中... </van-loading>
                    </div>
                </div>
            </van-overlay>
        </AutoView>
    </div>
</template>

<script>
import { getIntentionEchoByUserId, saveLocationIntentionRecomm, saveLocationIntentionAssess } from "@/request/shopEcology";
export default {
    data() {
        return {
            form: {
                type: 1,
                name: "",
                phone: "",
                address: "",
                intentionLongitude: null, //经度
                intentionLatitude: null, //纬度
                radius: 3, //半径
                poiName: null, //POI名称
                formatCategory: [],
                businessCategory: [],
                rentMin: "",
                rentMax: "",
                areaMin: "",
                areaMax: "",
                budgetMin: "",
                budgetMax: "",
            },
            selectArr: [
                {
                    title: "铺位推荐",
                    content: "根据你的开店诉求，选址参谋进行铺位推荐",
                    icon: require("../../../../static/shopEcology/icon1.png"),
                    icon1: require("../../../../static/shopEcology/icon1-active.png"),
                    id: 1,
                },
                {
                    title: "商铺评估",
                    content: "选址参谋线下采集商铺信息，并给出评估分析",
                    icon: require("../../../../static/shopEcology/icon0.png"),
                    icon1: require("../../../../static/shopEcology/icon0-active.png"),
                    id: 0,
                },
            ],
            showRent: false,
            showArea: false,
            showBudget: false,
            showLoading: false,
            isGetLocation: true,
        };
    },
    created() {
        this.JSBridge.callHandler(
            "putString",
            JSON.stringify({
                key: "sscm-muti-formats",
                value: JSON.stringify([]),
            })
        );
        this.JSBridge.callHandler(
            "putString",
            JSON.stringify({
                key: "sscm-muti-Industries",
                value: JSON.stringify([]),
            })
        );
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            getIntentionEchoByUserId().then((res) => {
                this.form.name = res.name || "";
                this.form.phone = res.phone || "";
            });
            this.JSBridge.callHandler("getLocation", null, (data) => {
                this.isGetLocation = false;
                if (typeof data === "string") data = JSON.parse(data);
                if (data) {
                    this.form.locationLatitude = data.latitude;
                    this.form.locationLongitude = data.longitude;

                    this.form.address = data.address;
                    this.form.poiName = data.poiName;
                    this.form.intentionLatitude = data.latitude;
                    this.form.intentionLongitude = data.longitude;
                }
            });
            this.JSBridge.registerHandler("onAppResume", (data) => {
                console.log("app 调用 onAppResume 函数成功");
                this.JSBridge.callHandler("getString", "sscm-muti-formats", (data) => {
                    console.log('formats', data)
                    let temp = typeof data == 'string' ? JSON.parse(data) : data;
                    this.form.formatCategory = temp || [];
                });
                this.JSBridge.callHandler("getString", "sscm-muti-Industries", (data) => {
                    console.log('Industries', data)
                    let temp = typeof data == 'string' ? JSON.parse(data) : data;
                    this.form.businessCategory = temp || [];
                });
            });
        },
        submit() {
            if (this.showLoading) return;
            this.showLoading = true;

            const filterData = (data) => {
                return data.filter((val) => {
                    if (val.children) val.children = filterData(val.children);
                    return val.num > 0 || val.is_select == true;
                });
            };
            const data = JSON.parse(JSON.stringify(this.form));
            if (this.checkBase(data)) {
                this.showLoading = false;
                return;
            }

            if (data.type) {
                if (this.checkOther(data)) {
                    this.showLoading = false;
                    return;
                }
                const params = {
                    ...data,
                    brokerUserId: this.$route.query.id || "",
                    formatCategory: JSON.stringify(filterData(data.formatCategory)),
                    businessCategory: JSON.stringify(filterData(data.businessCategory)),
                    rentMin: data.rentMin.replace(/￥|\,/g, "") * 1,
                    rentMax: data.rentMax.replace(/￥|\,/g, "") * 1,
                    budgetMin: data.budgetMin.replace(/￥|\,/g, "") * 1,
                    budgetMax: data.budgetMax.replace(/￥|\,/g, "") * 1,
                };
                saveLocationIntentionRecomm(params)
                    .then((res) => {
                        if (res) if (res) this.toSuccessful(res);
                        else this.$toast.fail("提交失败");
                    })
                    .finally((e) => {
                        this.showLoading = false;
                    });
            } else {
                const params = {
                    type: 0,
                    name: data.name,
                    radius: data.radius,
                    phone: data.phone,
                    address: data.address,
                    poiName: data.poiName,
                    locationLatitude: data.locationLatitude,
                    locationLongitude: data.locationLongitude,
                    intentionLatitude: data.intentionLatitude,
                    intentionLongitude: data.intentionLongitude,
                    brokerUserId: this.$route.query.id || "",
                    platformShopId: this.$route.query.shopId || "",
                };
                saveLocationIntentionAssess(params)
                    .then((res) => {
                        if (res) this.toSuccessful(res);
                        else this.$toast.fail("提交失败");
                    })
                    .finally((e) => {
                        this.showLoading = false;
                    });
            }

            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 2510100, 2510101);
        },
        openMap() {
            this.JSBridge.callHandler(
                "callLocationPicker",
                {
                    component: {
                        poi: true, // 位置选择（扎针）
                        now: true, // 展示当前位置
                        radius: true, // 半径选择
                        search: true, // 搜索栏
                    },
                    value: {
                        lat: this.form.intentionLatitude,
                        lng: this.form.intentionLongitude,
                        radius: this.form.radius * 1000, // 单位m
                        search: this.form.poiName,
                    },
                },
                (data) => {
                    // 将数据转换后发送到处理方法
                    if (data) {
                        if (typeof data === "string") {
                            data = JSON.parse(data); // 兼容安卓数据解析
                        }
                        this.selectedLocation([data.lng, data.lat, data.search, data.address || data.cityName], data.radius);
                    }
                }
            );
        },
        formatter(value) {
            function format(num) {
                var reg = /\d{1,3}(?=(\d{3})+$)/g;
                return (num + "").replace(reg, "$&,");
            }
            if (value) return "￥" + format(value);
            else return "";
        },
        formatterM2(value) {
            if (value) return value + "㎡";
            else return "";
        },
        checkData(v1, v2) {
            let n1 = v1.replace(/￥|\,/g, "");
            let n2 = v2.replace(/￥|\,/g, "");
            if (n2 - n1 > 0) return true;
            else return false;
        },
        openArea() {
            this.showArea = true;
            this.$nextTick(res => {
                this.$refs['areaMinInput'].focus();
            })
        },
        openRent() {
            this.showRent = true;
            this.$nextTick(res => {
                this.$refs['rentMinInput'].focus();
            })
        },
        openBudget() {
            this.showBudget = true;
            this.$nextTick(res => {
                this.$refs['budgetMinInput'].focus();
            })
        },
        cancelRent() {
            this.form.rentMin = "";
            this.form.rentMax = "";
            this.showRent = false;
        },
        cancelArea() {
            this.form.areaMin = "";
            this.form.areaMax = "";
            this.showArea = false;
        },
        cancelBudget() {
            this.form.budgetMin = "";
            this.form.budgetMax = "";
            this.showBudget = false;
        },
        submitRent() {
            if (this.checkData(this.form.rentMin, this.form.rentMax)) {
                this.showRent = false;
            }
            else if (!this.form.rentMin) {
                this.$toast({
                    message: "请填写最低价",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['rentMinInput'].focus();
            }
            else if (!this.form.rentMax) {
                this.$toast({
                    message: "请填写最高价",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['rentMaxInput'].focus();
            }
            else {
                this.$toast({
                    message: "最高价不能低于最低价",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['rentMaxInput'].focus();
            }
        },
        submitArea() {
            if (this.checkData(this.form.areaMin, this.form.areaMax)) {
                this.showArea = false;
            }
            else if (!this.form.areaMin) {
                this.$toast({
                    message: "请填写最小面积",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['areaMinInput'].focus();
            }
            else if (!this.form.areaMax) {
                this.$toast({
                    message: "请填写最大面积",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['areaMaxInput'].focus();
            } else {
                this.$toast({
                    message: "最大面积不能小于最小面积",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['areaMaxInput'].focus();
            }
        },
        submitBudget() {
            if (this.checkData(this.form.budgetMin, this.form.budgetMax)) {
                this.showBudget = false;
            } else if (!this.form.budgetMin) {
                this.$toast({
                    message: "请填写最低价",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['budgetMinInput'].focus();
            }
            else if (!this.form.budgetMax) {
                this.$toast({
                    message: "请填写最高价",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['budgetMaxInput'].focus();
            } else {
                this.$toast({
                    message: "最高价不能低于最低价",
                    icon: "/static/icon/toast_wrong.png",
                });
                this.$refs['budgetMaxInput'].focus();
            }
        },
        openFormats() {
            this.swRouter({
                path: "/shopEcology/multiChoiceFormats",
            });
        },
        openIndustries() {
            this.swRouter({
                path: "/shopEcology/multiChoiceIndustries",
            });
        },
        selectClick(item, key) {
            this.form.type = item.id;
        },
        selectedLocation(e, radius) {
            this.isGetLocation = false;
            this.showPoiPicker = false;
            this.form.intentionLongitude = e[0];
            this.form.intentionLatitude = e[1];
            this.form.poiName = e[2];
            this.form.address = e[3];
            this.form.radius = radius / 1000;
        },
        getByteLength(str = "") {
            let len = 0;
            for (let i = 0; i < str.length; i++) {
                if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
                    len += 2;
                } else {
                    len++;
                }
            }
            return len;
        },
        checkBase(data) {
            const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!data.name) {
                this.$toast({
                    message: "请填写姓名",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (this.getByteLength(data.name) > 14) {
                this.$toast({
                    message: "名称太长，请重新输入",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (!data.phone) {
                this.$toast({
                    message: "请填写联系手机号",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (!phoneReg.test(data.phone)) {
                this.$toast({
                    message: "请填写正确手机号码",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (!data.intentionLongitude || !data.intentionLatitude) {
                this.$toast({
                    message: "请选择意向区域",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else return false
        },
        checkOther(data) {
            if (this.computedListNumb(data.formatCategory) == 0) {
                this.$toast({
                    message: "请选择经营行业",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (this.computedListNumb(data.businessCategory) == 0) {
                this.$toast({
                    message: "请选择商铺类型",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (!data.areaMin || !data.areaMax) {
                this.$toast({
                    message: "请填写期望面积",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (!data.rentMin || !data.rentMax) {
                this.$toast({
                    message: "请填写期望月租",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else if (!data.budgetMin || !data.budgetMax) {
                this.$toast({
                    message: "请填写投资预算",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            } else return false
        },
        toSuccessful(res) {
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 134900, 134901);
            this.swRouter({
                path: "/shopEcology/intentionsubmitSuccessful",
                query: {
                    id: res,
                },
            });
        },
        computedListNumb(data) {
            let num = 0;
            if (data && data.length) {
                data.map((v) => (num += v.num || 0));
                return num;
            } else return num;
        },
    },
    computed: {
        regon() {
            if (this.form.poiName && this.form.radius) return `${this.form.poiName} 半径${this.form.radius}km`;
            else return "";
        },
        areaText() {
            let v1 = this.form.areaMin;
            let v2 = this.form.areaMax;
            if (v1 && v2 && this.checkData(v1, v2) && !this.showArea) {
                return `${v1}㎡～${v2}㎡`;
            } else return "";
        },
        rentText() {
            let v1 = this.form.rentMin;
            let v2 = this.form.rentMax;
            if (v1 && v2 && this.checkData(v1, v2) && !this.showRent) {
                return `${v1}～${v2}`;
            } else return "";
        },
        hasLnglat() {
            return this.form.intentionLongitude && this.form.intentionLatitude && this.form.poiName;
        },
        budgetText() {
            let v1 = this.form.budgetMin;
            let v2 = this.form.budgetMax;
            if (v1 && v2 && this.checkData(v1, v2) && !this.showBudget) {
                return `${v1}～${v2}`;
            } else return "";
        },
        formatCategory() {
            let data = this.form.formatCategory;
            if (data && data.length) {
                let num = this.computedListNumb(data);
                return num ? `已选${num}类` : "";
            } else return "";
        },
        businessCategory() {
            let data = this.form.businessCategory;
            if (data && data.length) {
                let num = this.computedListNumb(data);
                return num ? `已选${num}类` : "";
            } else return "";
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
    @return $args/750 * 100+vw;
}

.intention-form {
    .header {
        .detail {
            font-size: vw(28);
            font-weight: 600;
            color: #347fff;
            line-height: vw(40);
        }

        .loading {
            font-size: vw(28);
            font-weight: 600;
            line-height: vw(40);

            ::v-deep .van-loading__spinner {
                width: vw(28);
                height: vw(28);
            }
        }
    }

    .autoView {
        background: #f2f2f2;
    }

    .wrapper {
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;

        .block {
            width: 120px;
            height: 120px;
        }
    }

    .body-box {
        padding: vw(20) vw(20) vw(0);
        box-sizing: border-box;

        .title {
            height: vw(48);
            font-size: vw(34);
            font-weight: 600;
            line-height: vw(48);
            text-align: center;
            color: #1d2233;
        }

        .form-box {
            .form-item {
                margin-bottom: vw(10);

                .label {
                    height: vw(72);
                    font-size: vw(30);
                    font-weight: 600;
                    color: #121622;
                    line-height: vw(72);
                }

                .value {
                    height: vw(80);
                    border-radius: vw(10);

                    input {
                        border: none;
                        height: 100%;
                        width: 100%;
                        background: #f7f8fb;
                        font-size: vw(28);
                        color: #1d2233;
                        padding: 0 vw(30);
                        box-sizing: border-box;
                    }
                }
            }
        }

        .select-box {
            margin-bottom: 10px;

            .left-title {
                height: vw(82);
                font-size: vw(30);
                font-weight: 600;
                color: #121622;
                line-height: vw(82);
            }

            .right-body {
                display: flex;
                justify-content: space-between;

                .right-body-item {
                    height: vw(174);
                    border-radius: vw(10);
                    border: vw(2.5) solid #e4e7ed;
                    box-sizing: border-box;
                    padding: vw(20) vw(10) vw(20) vw(30);
                    width: vw(345);
                    background: #fff;
                    display: flex;

                    .left-box {
                        .title {
                            font-size: vw(28);
                            font-weight: 600;
                            height: vw(40);
                            line-height: vw(40);
                            text-align: left;
                            color: #1d2233;
                        }

                        .content {
                            margin-top: vw(4);
                            font-size: vw(22);
                            height: vw(28);
                            line-height: vw(30);
                            color: #676c84;
                        }
                    }

                    .right-box {
                        img {
                            width: vw(100);
                            height: vw(100);
                        }
                    }
                }

                .active {
                    background: #F4F9FF;
                    border-color: #347fff;

                    .title {
                        color: #347fff !important;
                    }
                }
            }
        }

        .mask-button {
            padding-top: vw(30);
            box-sizing: border-box;

            .button {
                height: vw(80);
                background: #347fff;
                border-radius: vw(10);
                font-size: vw(30);
                font-weight: 600;
                color: #ffffff;
                line-height: vw(80);
                text-align: center;
            }
        }
    }

    ::v-deep .van-cell-group {
        border-radius: vw(10);
        margin: vw(20) vw(20);
    }

    .link-right {
        padding: vw(30) vw(20) vw(30) vw(30);
        background-color: transparent;

        ::v-deep .van-cell__title,
        ::v-deep .van-field__label {
            font-size: vw(28);
            font-weight: 600;
            color: #1d2233;
        }

        ::v-deep .van-field__control {
            font-size: vw(26);
            text-align: right;
            color: #676c84;
        }
    }

    .link-blue {
        ::v-deep .van-field__control {
            &::-webkit-input-placeholder {
                color: #347fff;
            }
        }

        ::v-deep .van-cell__right-icon {
            color: #347fff;
        }
    }

    .loadingLocation {
        width: 100%;
        min-height: vw(126);
        background: linear-gradient(90deg, #f7f8fb 0%, rgba(247, 248, 251, 0) 100%);
        border-radius: vw(10) vw(0) vw(0) vw(10);

        .has-lngLat {
            min-height: vw(75);
            padding: vw(27) vw(5) vw(24) vw(30);
            display: flex;
            align-items: center;

            .has-item-content {
                flex: 1;
                min-height: vw(75);
                overflow: hidden;
                margin-right: vw(30);

                .ht-title {
                    display: flex;

                    .poi-name {
                        height: vw(37);
                        font-size: vw(26);
                        color: #1d2233;
                        line-height: vw(37);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: calc(100% - 150 / 750 * 100vw);
                    }

                    .poi-radius {
                        height: vw(33);
                        font-size: vw(24);
                        font-weight: 400;
                        color: #676c84;
                        line-height: vw(33);
                    }
                }

                .ht-addr {
                    min-height: vw(33);
                    font-size: vw(24);
                    font-weight: 400;
                    color: #676c84;
                    line-height: vw(33);
                    // overflow: hidden;
                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                    margin-top: vw(9);
                }
            }
        }

        .is-get-loading {
            display: flex;
            padding: vw(45) vw(5) vw(44) vw(30);
        }
    }

    .select-go {
        width: vw(34);
        height: vw(34);
        background: url(/static/icon/list_icon_go.png) no-repeat center;
        background-size: vw(34) vw(34);
    }

    .location-icon {
        width: vw(33);
        height: vw(33);
        margin-right: vw(2);
        background: url(/static/icon/location.png) no-repeat center;
        background-size: vw(33) vw(33);
    }

    .location_error {
        width: vw(33);
        height: vw(33);
        margin-right: vw(2);
        background: url(/static/icon/location_error.png) no-repeat center;
        background-size: vw(33) vw(33);
    }

    .manual-title {
        height: vw(37);
        font-size: vw(26);
        color: #347fff;
        line-height: vw(37);
        font-weight: 400;
    }

    .location-load-title {
        height: vw(37);
        font-size: vw(26);
        color: #676c84;
        line-height: vw(37);
        font-weight: 400;
        flex: 1;
    }

    .popup-container {
        padding: vw(30) vw(30) vw(0);

        .popup-header {
            display: flex;
            text-align: center;
            margin-bottom: vw(40);

            .header-title {
                flex: 1;
                font-size: vw(30);
                font-weight: 600;
                color: #1d2233;
                line-height: vw(42);
            }

            .header-submit {
                // width: vw(70);
                font-size: vw(30);
                font-weight: 600;
                line-height: vw(42);
                color: #347fff;
            }

            .header-cannel {
                // width: vw(70);
                font-size: vw(30);
                font-weight: 600;
                line-height: vw(42);
                color: #676c84;
            }
        }

        .popup-content {
            display: flex;

            .split {
                margin: vw(39) vw(30);

                .split-bar {
                    width: vw(30);
                    height: vw(2);
                    background: #abb3c1;
                }
            }

            .van-field {
                height: vw(80);
                background: #f7f8fb;
                border-radius: vw(10);
                border: vw(2) solid #e4e7ed;
            }
        }
    }
}
</style>
