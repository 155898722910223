<template>
  <div>
    <StateBar :bgcolor="bgColor" />
    <TitleHeader :title="'支付成功'" :bgcolor="bgColor" color="black" :back="true"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="false"
    >
      <div class="module">
        <p class="title">上上参谋连锁品牌VIP支付成功!</p>
        <div class="container app">
          <img class="title-img" src="/static/vip/img-app-title.png">
          <p class="desc">已成功为您开通<span class="orange">上上参谋App</span>端连锁品牌VIP权益</p>
        </div>
        <div class="container saas">
          <img class="title-img" src="/static/vip/img-saas-title.png">
          <p class="desc">已为您开通上上参谋<span class="orange">企业版-数位拓店云</span>服务，但需要您填写资料后才能正式使用企业版</p>
          <div class="btn-wrap">
            <van-button @click="toInput(1)">去填写资料</van-button>
          </div>
        </div>
      </div>
    </AutoView>

  </div>
</template>

<script>
import { areaList } from '@vant/area-data';

export default {
  name: 'index',
  data () {
    return {
      username: '',
      password: '',
      cityVisible: false,
      confirmDialogVisible: false,
      areaList,
      bgColor: '#fff',
      form: {
        city: '',
      }
    };
  },
  methods: {
    onSubmit (values) {
      console.log('submit', values);
    },
    hideCitySelector () {
      this.cityVisible = false;
    },
    showCitySelector () {
      this.cityVisible = true;
    },
    handleCityCheck (data) {
      this.form.city = data.map(item => item.name).join('-');
      this.hideCitySelector();
    },
    showConfirmDialog () {
      this.confirmDialogVisible = true;
    },
    /**
     * 去填写资料
     * @param status 支付状态，0：未支付 1：已支付
     */
    toInput (status) {
      this.JSBridge.callHandler("onBackPressed");
      this.swRouter({
        path: `/vip/enterprise-form/${status}`,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import '@/styles/_cell.scss';
*{
  box-sizing: border-box;
}
.module{
  background: url("/static/vip/img-pay-success-top.png") no-repeat;
  background-size: 100% auto;
  padding: vw(80) vw(30) vw(30) vw(30);
  >.title{
    font-size: vw(32);
    font-weight: 500;
    color: #FF8700;
    line-height: vw(45);
    padding-left: vw(8);
  }
  .container{
    position: relative;
    padding: vw(46) vw(30) vw(30) vw(30);
    background: #FFFFFF;
    box-shadow: 0px 0px vw(11) vw(3) rgba(13, 13, 13, 0.06);
    border-radius: vw(10);
    border: vw(3) solid #FFCE8F;
    .title-img{
      position: absolute;
      left: 0;
      top: vw(-30);
      height: vw(60);
    }
    .desc{
      font-size: vw(28);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: vw(40);
      text-align: center;
      .orange{
        color: #FF8700;
      }
    }
    &.app{
      margin-top: vw(75);
    }
    &.saas{
      margin-top: vw(84);
      .desc{
        text-align: left;
      }
      .btn-wrap{
        margin-top: vw(30);
        .van-button{
          width: 100%;
          height: vw(80);
          background: linear-gradient(90deg, #FFAC42 0%, #FFCE8F 100%);
          border-radius: 10px;
        }
      }
      .form{
        width: 100%;
        background: #F7F7F7;
        border-radius: vw(10);
        padding: vw(18) vw(30);
        margin-top: vw(30);
        p{
          font-size: vw(28);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: vw(37);
          margin: vw(20) 0;
          span{
            color: #333;
          }
        }
      }
      .form-view{
        display: inline-block;
        width: 100%;
        margin-top: vw(30);
      }
    }
  }
}


</style>
