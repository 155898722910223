<template>
    <div class="brand">
        <StateBar bgcolor="white" />
        <TitleHeader title="推荐" :back="true"></TitleHeader>

         <AutoView :header="true" :footer="true" class="brand--view">
            <div class="title">
                <h3>请填写以下信息，免费发布加盟需求</h3>
                <h5>我们将根据你的偏好，为你推荐多个精选优质品牌，以供选择</h5>
            </div>

            <div class="view">
                <div class="item">
                    <label class="s-label">
                        <span><small>*</small>加盟地区</span>
                        <input ref="lat" type="text" readonly placeholder="请选择" :value="field.city || '请选择城市区域'" class="inp icon icon-geo" @click="showRegionPicker = true" />
                    </label>
                </div>
                <div class="item">
                    <label class="s-label">
                        <span><small>*</small>意向行业</span>
                        <input ref="industCode" type="text" readonly placeholder="请选择" :value="field.indust || '请选择意向行业'" @click="showSelect = true" class="inp sel" />
                    </label>
                </div>
                <div class="item">
                    <label class="s-label">
                        <span><small>*</small>联系人姓名</span>
                        <input type="text" v-model="form.liaison" class="inp" placeholder="请填写你的姓名">
                    </label>
                </div>
                <div class="item">
                    <label class="s-label">
                        <span><small>*</small>联系方式</span>
                        <input type="text" v-if="typeMobile == 1" maxlength="11" @keyup.delete="delectPhone" v-model.number="form.mobile" class="inp" placeholder="请填写你的联系方式">
                        <input type="Number" ref="mobile" v-else v-model.number="form.mobile" oninput="if(value.length>11) value = value.slice(0,11)" class="inp" placeholder="请填写你的联系方式">
                    </label>
                </div>
                <div class="item">
                    <label class="s-label">
                        <span>意向品牌</span>
                        <input type="text" class="inp" v-model="form.brandName" placeholder="可选填你已有意向的品牌">
                    </label>
                </div>
            </div>
            <div class="tip_succ">提交成功后，我们会根据你的需要，致电联系，为你定制化推荐品牌！</div>
            <div class="reading_text" :class="isShowClass == 1 ? 'showHeight': 'hideHeight'">
                <input type="checkbox" v-model="form.isChecked" id="join_box">
                <label for="join_box">我已阅读并同意</label><span @click="openAgreement">《上上参谋品牌合作服务协议》</span>
            </div>
        </AutoView>

        <SoltFooter>
            <div class="brand--footer">
                <van-button type="info" block :disabled="disabled" :loading="submitting" @click="onSubmit">提交</van-button>
            </div>
        </SoltFooter>
        <!-- 意向行业 -->
        <van-popup v-model="showSelect" position="bottom">
            <van-picker show-toolbar title="选择意向行业" :columns="newIndust" @cancel="showSelect = false" @confirm="confirmFirst" />
        </van-popup>

        <!-- 省市区选择 -->
        <OpenCityPicker type="v2open" v-model="showRegionPicker" @confirm="getCityZone"/>
    </div>
</template>

<script>
import SelectTabs from '@/components/widget/selectTabs';
import OpenCityPicker from '../home/components/openCityPicker';
import { Toast } from "vant";
export default {
    components: {
        SelectTabs,
        OpenCityPicker,
    },
    name: "join",
    data () {
        return {
            loading   : true,
            submitting: false, // 提交状态
            disabled  : true, // 提交按钮禁用状态
            showRegionPicker  : false, // 行政区
            showBudgetPicker  : false, // 预算
            showSelect        : false,  //意向行业

            newIndustryTypes: [], //获取意向品牌列表
            newIndust    : [],  //获取意向行业列表

            field: {
                city    : null,
                category: null,
                indust  : null,
            },
            form: {
                newCategoryCodeL1: null,
                newCategoryNameL1: null,
                newCategoryCodeL2: null,
                newCategoryNameL2: null,
                brandName: null,
                mobile     : null,   //手机号码
                liaison      : '', //姓名
                zone     : null,
                code     : null,
                city     : null,
                type     : 2,
                isChecked : true
            },
            getModile: null,
            typeMobile: 1,
            docmHeight: document.documentElement.clientHeight ||document.body.clientHeight, // 默认屏幕可视高度
            showHeight: document.documentElement.clientHeight ||document.body.clientHeight, //body元素高度
            isShowClass: 1,
        };
    },
    methods: {
        // 删除键
        delectPhone() {
            this.form.mobile = null;
            this.typeMobile = 2;
            this.$nextTick(() => {
                this.$refs.mobile.focus();
            })
        },
        openAgreement(){
            this.swRouter("/join/agreement")
        },
        onSubmit () {
            Toast.resetDefaultOptions();
            if (this.form.liaison.trim() == "") {
                Toast({ message: "请填写你的姓名", icon: "warning" });
                return false;
            }
            if(!/^1[3-9]\d{9}$/.test(this.form.mobile) && this.typeMobile == 2) {
                Toast({ message: "请输入有效的手机号码", icon: "warning" });
                return false;
            }
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.createBrand( this.form ).then( ( res ) => {
                const mess = "你的需求已提交，请耐心等待";
                Toast({ message: mess, icon: "success" });
                this.submitting = false;
                setTimeout( () => {
                    this.form.liaison = '';
                    this.form.newCategoryCodeL1 = null;
                    this.form.newCategoryNameL1 = null;
                    this.form.newCategoryCodeL2 = null;
                    this.form.newCategoryNameL2 = null;
                    this.field.indust = null;
                    this.form.brandName = null;
                    this.typeMobile = 1;
                    this.form.mobile = this.getModile;
                }, 100 );
            }).catch( ( e ) => {
                // this.$toast( '网络不稳定，请稍候重试！' );
                setTimeout( () => {
                    this.submitting  = false;
                }, 2000 );
            } );
        },
        getCityZone ( picker, code ) {
            this.field.city = picker.join( '' );
            this.form.zone  = picker.join( '' );
            this.form.city  = picker.join( '-' );
            this.form.code  = code[ 2 ] || code[ 1 ] || code[ 0 ];
        },
        // 意向行业
        confirmFirst( type, index ) {
            this.showSelect = false;
            this.form.newCategoryNameL1 = type[0];
            this.form.newCategoryNameL2 = type[1];
            this.form.newCategoryCodeL1 = this.newIndust[index[0]].code;
            this.form.newCategoryCodeL2 = this.newIndust[index[0]].children[index[1]].code;
            this.field.indust = type[0]+"-"+type[1];
        },
    },
    watch: {
        form: {
            deep: true,
            handler ( form ) {
                for ( const k in form ) {
                    if ( [ 'brandName' ].includes( k ) ) continue;
                    if ( ! form[ k ] && form[ k ] !== 0) {
                        this.disabled = true;
                        return;
                    }
                }
                this.disabled = false;
            },
        },
        showHeight() {
            if(this.showHeight >= this.docmHeight) {
                this.isShowClass = 1;
            } else {
                this.isShowClass = 2;
            }
        }
    },
    created () {
        this.submitting  = false;
        // 获取行业列表
        this.HTTP.brandCategory().then( ( res ) => {
            this.newIndust = res.map(item => {
                // if(item.children == undefined) {
                //     return {
                //         text: item.name,
                //         code: item.code,
                //     };
                // } else {
                return {
                    text: item.name,
                    code: item.code,
                    children: item.children.map(citem => {
                        return {
                            text: citem.name,
                            code: citem.code,
                        };
                    })
                    // };
                }
            })
        } ).catch( ( e ) => {
            this.$toast( '获取行业信息时发生错误！' );
        } );
        // 获取用户手机号
        this.HTTP.getUserInfo().then(res => {
            this.form.mobile = res.phone;
            this.getModile = res.phone;
        })
        // 获取定位信息
        if ( window.isClient ) {
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                if ( data ) {
                    this.form.code  = data.adCode;
                    this.form.zone  = data.city + data.district;
                    this.form.city  = [ data.city, data.district ].join( '-' );
                    this.field.city = data.city + data.district;
                }
            } );
        }
    },
    mounted() {
        window.onresize = () => {
            return(()=>{
                this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
            })();
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.brand {
    min-height: 100vh;
    background-color: #F8F8F9;

    #{&}--view {
        padding: $padding_3 $padding_4;
        background: url(/static/img/brand_bg.jpg) no-repeat;
        background-size: contain;

        .title {
            overflow: hidden;
            height: auto;
            h3 {
                @include font(white, $font_453, bold);
                line-height: 6vw;
                margin-top: 5.8vw;
            }
            h5 {
                @include font(white, $font_320, normal);
                line-height: 6vw;
                margin: 1vw 0 4vw;
            }
        }

        .view {
            padding: 2vw $padding_4;
            background-color: white;
            box-sizing: border-box;
            border-radius: $radius_xs;
            box-shadow: 0 1vw 3.6vw 0.4vw rgba(13, 13, 13, 0.06);

            .item {
                @include flex;
                height: 13vw;
            }
            .item:not(:last-child) {
                box-shadow: inset 0 -1px 0 #F3F3F3;
            }
            .item.nob {
                box-shadow: none;
            }
            .s-label {
                @include flex;
                width: 100%;
                height: 100%;
                font-size: $font_400;
                color: #333333;
                span {
                    width: 24vw;
                    font-weight: bold;
                    text-align: right;
                    small{
                        color: #FF5900;
                        font-size: $font_400;
                    }
                }
                .inp {
                    width: calc( 100% - 24vw );
                    height: $font_560;
                    padding: 0 0 0 4.53vw;
                    margin: 0;
                    border: none;
                    box-sizing: border-box;
                    font-size: $font_373;
                    &::placeholder {
                        color: #C4C4C6;
                    }
                    &.warn::placeholder {
                        color: $c-require;
                    }
                }
                .sel.lock {
                    background: none;
                }
                .sel, .icon {
                    padding-right: 6vw;
                    background: url(/static/icon/arrow_down_2.png) no-repeat right center;
                    background-size: 3vw;
                }
                .icon {
                    background: url(/static/icon/arrow_down_2.png) no-repeat right center;
                    background-size: 4vw 4vw;

                    &.icon-geo {
                        background-image: url(/static/icon/location_line_deep.png);
                        background-size: 5vw;
                    }
                }
            }
        }
        .tip_succ{
            width: 100%;
            padding-top: 3.2vw;
            font-size: $font_320;
            color: #999999;
            line-height: 4.4vw;
        }
        .reading_text{
            // padding: 3.2vw 0;
            font-size: 0;
            input{
                display: inline-block;
                vertical-align: middle;
                margin-left: 0;
                margin-right: 1.33vw;
            }
            label{
                color: #333;
                font-size: $font_320;
                line-height: 4.4vw;
                display: inline-block;
                vertical-align: middle;

            }
            span{
                font-size: $font_320;
                line-height: 4.4vw;
                display: inline-block;
                vertical-align: middle;
                color: #518CDB;
            }
        }
        .showHeight{
            position: absolute;
            left: $padding_4;
            bottom: 0;
        }
        .hideHeight{
            padding: 3.2vw 0;
        }
    }

    #{&}--footer {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding_4;
        box-sizing: border-box;
        background-color: #F8F8F9;

        ::v-deep .van-button {
            height: 100%;
        }
    }
}
</style>
