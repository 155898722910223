<template>
    <div class="join" :class="{ loaded: !loading }">
        <StateBar bgcolor="white" />
        <!-- <TitleHeader title="合伙人招募" bgcolor="white" color="black" :back="true">
        </TitleHeader> -->

        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <div class="autoView join--view" v-if="!loading" :header="false" :footer="true" :class="[ { android: isAndroid, ios: isIOS, ipx: isIPX }]" @scroll="handleScroll">
            <van-image class="join-bkg" fit="contain" src="/static/img/partner/join-bkg.png" />
            <div class="body-container">
                <div class="coupon">
                    <div class="inviter">
                        <div class="img-wrap">
                            <img class="inviter-i" :src="userInfo.icon" />
                        </div>
                        <span class="inviter-n">Hi, 我是 {{userInfo.userName}}</span>
                        <span class="inviter-l">合伙人</span>
                    </div>
                    <div class="inviter-b" @click="openRegister">
                        <div class="label">立即领取</div>
                        <img class="image" src="/static/icon/partner/inviter-bkg.png" />
                    </div>
                </div>
                <div class="body">
                    <van-image src="/static/img/partner/join-body.png" lazy-load></van-image>
                </div>
            </div>
            <div class="join--footerBar" v-show="showBottom">
                <img class="image-foot" src="/static/img/partner/register.png" @click="openRegister" />
            </div>
        </div>

        <!-- <SoltFooter class="join--footerBar" v-show="showBottom">
            <img class="image-foot" src="/static/img/partner/register.png"  @click="openRegister" />
        </SoltFooter> -->

        <!-- 注册界面 -->
        <transition name="fade">
            <!-- @click.self="showRegister = false" -->
            <div v-show="showRegister" class="join--mask" @click.self="showRegister = false">
                <div v-if="!isRegistered" class="panel">
                    <form class="join--form">
                        <h3 class="f-tac">上上参谋</h3>
                        <input type="phone" ref="phone" v-model="form.phone" :disabled="submitting" placeholder="请输入手机号" maxlength="11" />
                        <div class="nc-wrap">
                            <div id="sscm_share_join_nc"></div>
                        </div>
                        <div class="f-clear">
                            <input type="text" ref="code" v-model="form.code" :disabled="submitting" placeholder="请输入验证码" maxlength="6" class="f-fl w_1" />
                            <input type="button" :disabled="submitting || isSendCode" :value="isSendCode ? waitingTime + '秒' : '获取验证码'" class="f-fr w_2" @click="onGetCode" />
                        </div>
                        <button type="button" class="f-btn" :disabled="submitting" @click="onSubmit">{{ submitting ? '请稍候' : '注册' }}</button>
                    </form>
                </div>
                <div v-else class="panel">
                    <form class="join--form">
                        <img src="/static/icon/success_light.png" />
                        <h3 class="f-tac">注册成功</h3>
                        <p class="info f-tac">前往上上参谋使用大数据开店利器</p>
                        <button type="button" class="f-btn" @click="download">立即下载上上参谋</button>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
const BASE_NUM = 60; // 默认等待时间
export default {
    data() {
        return {
            title: '上上参谋，生意人的数据军师',
            loading: false,
            showRegister: false,
            isRegistered: false,
            submitting  : false,
            isSendCode  : false,
            waitingTime : 60,
            form: {
                code      : '',
                phone     : '',
                fromType  : '',
                inviteCode: '',
                inviteType: 2, // 1:普通邀请 2:合伙人邀请
            },
            // 是否进行了滑块验证
            hasVerification: false,
            verification   : {
                phone    : '',
                scene    : 'nc_login',
                sessionId: '',
                sig      : '',
                token    : ''
            },
            userInfo: {
                icon: '/static/icon/default.png',
                userName: '上上参谋'
            },
            isAndroid: false,
            isIOS    : false,
            isIPX    : false,
            showBottom: false,
        }
    },
    computed: {
        // mode () {
        //     return 'only-header';
        // },
    },
    methods: {
        openRegister () {
            this.showRegister = true;
        },
        download () {
            top.location.href = this.HTTP.getAppStore();
        },
        validate ( justPhone ) {
            if ( ! ( /^1[3456789]\d{9}$/.test( this.form.phone ) ) ) {
                this.$toast( '请输入正确的手机号' );
                this.$refs.phone.focus();
                return false;
            }
            if ( justPhone ) return true;
            if ( ! ( /^\d{6}$/.test( this.form.code ) ) ) {
                this.$toast( '请输入6位数验证码' );
                this.$refs.code.focus();
                return false;
            }
            return true;
        },
        onGetCode () {
            if ( this.isSendCode || this.validate( true ) === false ) return;
            this.verification.phone = this.form.phone;
            if ( ! this.hasVerification ) {
                this.$toast( '请拖动滑块进行验证' );
                return;
            }
            this.verificationCode();
        },
        onSubmit () {
            if ( this.submitting || this.validate() === false ) return;
            this.submitting = true;
            this.HTTP.inviteRegister( this.form ).then( ( res ) => {
                this.isRegistered = true;
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg );
                } else {
                    this.$toast( '网络不稳定，请稍候重试！' );
                }
                setTimeout( () => { this.submitting = false; }, 2000 );
            } );
        },
        initAWSC () {
            const appkey = 'FFFF0N2N0000000069E7';
            const scene = this.verification.scene;
            window.AWSC.use( 'nc', ( state, module ) => {
                // console.log( 'awsc loaded' );
                // 初始化
                // const ncToken = [ appkey, ( new Date() ).getTime(), Math.random() ].join( ':' );
                window.nc = module.init( {
                    // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                    appkey  : appkey,
                    // 使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
                    scene   : scene,
                    // 声明滑动验证需要渲染的目标ID。
                    renderTo: 'sscm_share_join_nc',
                    // 前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                    success : ( data ) => {
                        // window.console && console.log( 'data:', data );
                        this.hasVerification = true;
                        this.verification.sessionId = data.sessionId;
                        this.verification.sig = data.sig;
                        this.verification.token = data.token;
                        // this.verification.token = ncToken;
                    },
                    // 滑动验证失败时触发该回调参数。
                    fail: function ( failCode ) {
                        window.console && console.error( failCode );
                    },
                    // 验证码加载出现异常时触发该回调参数。
                    error: function ( errorCode ) {
                        window.console && console.error( errorCode );
                    }
                } );
            } );
        },
        verificationCode () {
            this.HTTP.verification( this.verification ).then( ( res ) => {
                // console.log( 'res:', res );
                if ( res.code === 0 ) {
                    this.isSendCode = true;
                    this.$toast( '验证码发送成功' );
                    clearInterval( this.timer );
                    this.timer = setInterval( () => {
                        if ( this.waitingTime === 1 ) {
                            clearInterval( this.timer );
                            this.isSendCode  = false;
                            this.waitingTime = BASE_NUM;
                        } else {
                            this.waitingTime --;
                        }
                    }, 1000 );
                } else {
                    this.resetVerification();
                }
            } ).catch( ( e ) => {
                this.resetVerification();
            } );
        },
        resetVerification () {
            this.$toast( '滑块验证失败，请检查手机号，重新滑动验证' );
            window.nc.reset();
            this.hasVerification = false;
        },
        handleScroll(e) {
            const offsetHeight = e.srcElement.offsetHeight;
            const scrollTop = e.srcElement.scrollTop;
            const scrollHeight = e.srcElement.scrollHeight;
            // 一些浏览器可能有自己的滑动头
            const bodyOffsetHeight = document.body.offsetHeight;
            const bodyScrollHeight = document.body.scrollHeight;
            const span = bodyScrollHeight - bodyOffsetHeight;
            // console.log('offsetHeight:', offsetHeight, ', scroll top:', scrollTop, ', scroll height:', scrollHeight, ', span:', span);
            if (scrollTop + span >= 520) {
                this.showBottom = true;
            } else {
                this.showBottom = false;
            }
        },
    },
    created () {
        const shareCode = this.$route.params.id;
        console.log( 'shareCode:', shareCode );
        this.HTTP.inviteUserInfo( shareCode ).then( ( res ) => {
            const { code, data } = res;
            if ( code == 0 ) {
                if ( data.userName ) {
                    this.userInfo.userName = data.userName;
                }
                if ( data.icon ) {
                    this.userInfo.icon = data.icon;
                }
                this.form.inviteCode = shareCode;
            }
        } )
    },
    beforeMount () {
        // this.JSBridge.callHandler( 'switchNavbar', false );
        // this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        // console.log( 'invite before mount' );
        this.isAndroid = window.isClient && window.isAndroid;
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
        }
        // 滑动验证
        const script = document.createElement( 'script' );
        script.type = 'text/javascript';
        script.src = 'https://g.alicdn.com/AWSC/AWSC/awsc.js';
        if ( script.readyState ) {
            script.onreadystatechange = () => {
                if ( script.readyState === 'loaded' || script.readyState === 'complete' ) {
                    script.onreadystatechange = null;
                    // console.log( 'script has loaded by onreadystatechange' );
                    this.initAWSC();
                }
            };
        } else {
            script.onload = () => {
                // console.log( 'script has loaded by on onload' );
                this.initAWSC();
            };
        }
        document.getElementsByTagName( 'head' )[ 0 ].appendChild( script );
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.join {
    position: relative;
    height: 100vh;
    &.loaded {
        background-color: #FFCF5A;
    }
    .join-bkg {
        position: absolute;
        width: 100%;
    }
    .autoView {
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        height: 100vh;
        // height: calc( 100vh - #{$headerHeight} - #{$footerHeight} );
        &.android {
            height: calc( 100vh - #{$androidStateBar} - #{$headerHeight} - #{$footerHeight} );
        }
        &.ios {
            height: calc( 100vh - #{$iOSStateBar} - #{$headerHeight} - #{$footerHeight} );
        }
        &.ipx {
            height: calc( 100vh - #{$iPXStateBar} - #{$headerHeight} - #{$footerHeight} - #{$iPXBottomBar} );
        }

        &.only-header {
            height: calc( 100vh - #{$headerHeight} );
            &.android {
                height: calc( 100vh - #{$androidStateBar} - #{$headerHeight} );
            }
            &.ios {
                height: calc( 100vh - #{$iOSStateBar} - #{$headerHeight} );
            }
            &.ipx {
                height: calc( 100vh - #{$iPXStateBar} - #{$headerHeight} );
            }
        }
    }
    .join--view {
        .body-container {
            position: relative;
            margin-top: 70vw;
            padding: 4vw 4vw 22vw 4vw;
            .coupon {
                position: relative;
                background-image: url(/static/img/partner/coupon.png);
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 70vw;
                .inviter {
                    position: relative;
                    top: -6vw;
                    margin: 0 4vw;
                    background: linear-gradient(180deg, #FFF2C8 0%, #FFFFFF 100%);
                    border-radius: 6.4vw;
                    display: flex;
                    align-items: center;
                    // z-index: 1;
                    .img-wrap {
                        position: relative;
                        border: 1px solid #ffffff;
                        border-radius: 6vw;
                        .inviter-i {
                            width: 12vw;
                            height: 12vw;
                            border-radius: 6vw;
                        }
                    }
                    .inviter-n {
                        color: #C65000;
                        font-size: 4vw;
                        font-weight: bold;
                        line-height: 6.13vw;
                        text-align: center;
                        margin-left: 2.67vw;
                        margin-right: 2.13vw;
                    }
                    .inviter-l {
                        position: relative;
                        border-radius: 2.4vw 2.4vw 2.4vw 0;
                        background: linear-gradient(90deg, #FFA02B 0%, #FF6605 100%);
                        padding: 0.8vw 1.07vw;
                        color: #FFF5D4;
                        font-size: 2.4vw;
                        font-weight: bold;
                        line-height: 3.47vw;
                        text-align: center;
                    }
                }
                .inviter-b {
                    position: relative;
                    margin: 38vw 8vw 0;
                    border-radius: 6.67vw;
                    // width: 100%;
                    box-sizing: border-box;
                    background: linear-gradient(90deg, #FFA02B 0%, #FF6605 100%);
                    box-shadow: 0 1.07vw 2.93vw 0 rgba(255,84,38,0.2);
                    overflow: hidden;
                    .label {
                        position: relative;
                        padding: 4vw 0;
                        color: #FFF5D4;
                        font-size: 4vw;
                        font-weight: bold;
                        line-height: 5.6vw;
                        text-align: center;
                    }
                    .image {
                        position: absolute;
                        bottom: -2.4vw;
                        right: 3.2vw;
                        width: 12.8vw;
                        height: 13.07vw;
                    }
                }
            }
            .body {
                position: relative;
                margin-top: 2.67vw;
            }
        }
    }

    .join--footerBar {
        position: fixed;
        bottom: 0;
        z-index: 10;
        background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);
        box-shadow: 0px -3px 15px 0px rgba(255,207,90,0.8);
        height: 21vw;
        .image-foot {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }

    #{&}--form {
        position: absolute;
        top: $padding;
        width: calc( 84vw - #{ $padding * 1.6 } );
        z-index: 2;

        .f-btn {
            background: linear-gradient(90deg, #FFA02B 0%, #FF6605 100%);
            color: #FFFFFF;
        }

        input, button {
            @include ani;
            display: block;
            width: 100%;
            height: 13vw;
            font-size: $font_400;
            line-height: normal;
            padding: 0 4.8vw;
            margin-bottom: 3.6vw;
            border-radius: $radius_xs;
            border: none;
            box-sizing: border-box;
            -webkit-appearance: none;

            &::-webkit-input-placeholder {
                color: #AEB1BD;
            }
            &[disabled] {
                color: #D2D2D2!important;
                opacity: .8;
            }
        }

        .w_1 {
            width: 60%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .w_2 {
            width: 40%;
            // @include font( #451DB4, 2.93vw, bold );
            @include font( #C65000, 2.93vw, bold );
            background-color: white;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .nc-wrap {
            position: block;
            width: 100%;
            height: 34px;
            margin-bottom: 3.6vw;
        }
    }
    #{&}--mask {
        @include flexColumn;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, .7);

        .panel {
            position: absolute;
            width: 84vw;
            height: 80vw;
            top: 25%;
            padding: $padding $padding * .8;
            box-sizing: border-box;
            border-radius: $radius;
            // background: linear-gradient(to bottom, #9C67FF, #6334E3);
            background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                width: calc( 100% - 2px );
                height: calc( 100% - 2px );
                border-radius: $radius;
                // background: linear-gradient(to bottom, #7335E6, #451DB4);
                background: linear-gradient(to bottom, #FFEEB5, #FFE798);
            }

            &:after {
                content: '';
                position: absolute;
                top: 1vw;
                left: 0;
                width: 90vw;
                height: 18vw;
                margin-left: -3vw;
                background-size: contain;
            }

            img {
                display: block;
                width: 17.6vw;
                height: 17.6vw;
                margin: 0 auto 6.1vw;
            }

            h3 {
                @include font( #C65000, $font_560, bold );
                margin-bottom: $margin * 2;
            }

            .info {
                color: #C65000;
                font-size: 2.93vw;
                margin-bottom: $padding * 1;
                opacity: .8;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        @include ani(.5s);
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
}
</style>
<style lang="scss">
.nc-wrap {
    .nc-container #nc_1_wrapper {
        width: 100%;
    }
}
</style>
