<template>
  <div class="charts">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" color="#347fff" :back="showBack" :class="{ sub: showSubTitle }">
      <h6 v-if="showSubTitle" slot="right" class="subTitle">参谋告诉你答案</h6>
      <!-- 定制内容Begin -->
      <div v-if="canSaveOrDownload" slot="right" class="right">
        <p v-if="showDownLoad && stdId != '10124'" class="save" @click="gotoMyReport">去下载</p>
        <template v-if="!showDownLoad && !entity.saveStatus">
          <p @click="onAdd" class="save">保存</p>
          <i class="tips hid_delay_35"><!-- 保存至我的报告右上角提示 --></i>
        </template>
        <!-- <p v-if="entity.payed" class="save dis">已保存</p> -->

      </div>
      <!-- 定制内容End -->
    </TitleHeader>

    <AutoView :footer="!unlock" class="charts--view">
      <iframe ref="view" :src="url" frameborder="0" class="innerView">不支持内嵌窗口</iframe>
    </AutoView>

    <!-- 目录侧栏 -->
    <template v-if="isClient && showMenu && menus && ctrMenu">
      <div class="charts--icon" @click="openCatalog"></div>
      <van-popup v-model="showCatalog" position="right">
        <div class="charts--menu">
          <div class="header">
            <h1>{{ title }}</h1>
            <!-- <p>分析维度</p> -->
          </div>
          <ul class="list">
            <li
              v-for="(item, index) of menus"
              :key="index"
              :data-page="index + 1"
              :class="{ on: lastClick === item.id, lock: item.lock, sub: item.isBgm }"
              @click="jumpTo(item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </van-popup>
    </template>
    <!-- 专家解读 -->
    <div v-if="livechat && shouExpert" class="charts--icon icon2" @click="tapInterpret"></div>
    <!-- 在线咨询 -->
    <div v-if="livechat2" class="charts--livechat" @click="onLivechat"></div>
    <feedbackBox v-if="!loading && showFeedback" @click.native="goFeedBack" />

    <!-- <ButtonFooter v-if="! unlock" :text="text" @click="onTap"></ButtonFooter> -->
    <SoltFooter v-if="!loading && !unlock">
      <div class="charts--footer">
        <transition name="fade">
          <van-button v-show="showContinue" type="info" block class="s-radius__s" @click="onTap">{{ text }}</van-button>
        </transition>
      </div>
    </SoltFooter>
    <Coupon @cancel="showCoupon=false" @success="paySuccess" v-if="showCoupon" :showCoupon="showCoupon" :memberGoodsId="memberGoodsId"></Coupon>
    <LoadingOverlay :show="loading" />
    <van-popup
        v-model="popupVisible"
        round
    >
      <div class="popup-box">
        <div class="close">
          <div class="close-icon" @click='popupVisible = false'>
            <van-image class="icon" fit="cover" src="/static/icon/close-icon.png" />
          </div>
        </div>
        <div class="footer-left">
          <van-button
            plain
            type="info"
            size="normal"
            @click.stop="expertsAnswer"
          >
            在线咨询
          </van-button>
        </div>
        <div class="footer-right">
          <van-button
            type="info"
            size="normal"
            @click.stop="goAdd"
          >
            去预约
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import UniUtils from '@/utils/uni'
import feedbackBox from './components/feedback-box'
import Coupon from './components/coupon.vue';
import { getCrowd, queryAdConfig, reportAdConfig } from '../../request/order.js';
// import wx from "weixin-js-sdk";
import { setLocalStorage } from '../../utils';
import { formatDate2 } from '@/utils/filters';
let _timer = 0;

const adDramaUrls = [
  'pages/index/index',
  'pages/index/index?path=player&tv_id=9bf3df1366b6b851063bbc7b5beb3ca5&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=8df0e92f66bc524b06dc98f746b6d0ce&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=f380561066b617b3062c87f2042a01fe&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=33c5161d66bc520206df0dea6b717ede&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=33c5161d66b4cabd0605b8e50dbfb3c6&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=33c5161d66bc51d406df04c722c41452&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=f380561066afbf6605a7fde70928dc06&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=2ad666ce66bc517c06e26e410b0eda24&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=f380561066aef536059b34802c5d27ee&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=9bf3df1366bc512b06df5f8d2e49ebff&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=8df0e92f66ac577d0563db0938caf5af&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=9bf3df1366bc50e506df50a83eaf5829&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=25e993b766ab60db05589ae6342fbd37&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=2ad666ce66bc50c406e238f70d7a1b47&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
  'pages/index/index?path=player&tv_id=b751f280659b64ef062b8ecd0d753b4e&series=1&invite_code=2ad666ce66bc4d4906e184f87cd294a9&tfid=8df0e92f66bc4db706dba4763ae37aad&middleman_id=def1da456530e4f607cc78ff3aa7ce7d',
]

export default {
  name: "charts",
  components: {
    feedbackBox,
    Coupon
  },
  data() {
    return {
      goodsId: 10001,
      title: "正在加载",
      loading: true, // 数据加载
      subTitle: "---",
      text: "解锁深度报告",
      showBack: true,
      showMenu: true,
      shouExpert: true, // 显示专家解读
      showSubTitle: false,
      showCatalog: false,
      showContinue: true, // 是否显示解锁按钮（特殊报告）
      isReady: false, // 界面已就绪
      isSended: false, // 已下发数据
      isClient: false,
      lastClick: "cover", // 当前页码
      needLocation: false, // 是否需要定位

      id: 0, // 报告ID
      stdId: 0, // 类型ID
      menus: [], // 菜单列表
      pageMap: "一,二,三,四,五,六,七,八,九,十,十一,十二,十三,十四,十五,十六,十七,十八,十九,二十".split(","), // 序号表
      first: false, // 首次访问
      host: "/report/?id=",
      benefit: null, // 用户权益
      entity: null, // 报告实体
      isFirstOpenReportInV2_0_0: false, // 首次进入页面标识
      showSaveBtn: true,
      showCoupon:false,//是否展示优惠卷弹框
      memberGoodsId:14004,//特惠商品id
      adWatchStartTime: '', // 广告开始时间
      adConfig: undefined,
      iosShowAd: false,
      popupVisible: false
    };
  },
  methods: {
    goAdd () {
      this.popupVisible = false
      this.JSBridge.callHandler('onNormalizingCall', {
        id: 'gh_a80747334fea', // 小程序原始id
        params: '/pages_adver/wechatLandPage/index?code=app',
        url: 'MINI_PROGRAM',
        type: 5
      })
    },
    expertsAnswer () {
      if (!window.isClient) {
        debugger
        this.$toast("非客户端环境！");
        return;
      }
      this.popupVisible = false
      if (window.isMini) {
        UniUtils.goKefuPage()
        return
      }
      if (window._appCode >= 175) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: "ONLINE_SERVICE",
          params: JSON.stringify({
            cardCode: "CUSTOMER_REPORT",
            cardId: this.id,
            title: "专家解读",
            type: 4,
          }),
        });
      } else {
        this.JSBridge.callHandler(
          "callLiveChat",
          {
            type: "report",
            data: {
              id: this.id,
            },
          },
          (st) => {
            // console.log( st );
          }
        );
      }
    },
    //优惠卷支付成功
    paySuccess(){
      console.log('优惠卷支付成功');
      location.reload();
    },
    goFeedBack() {
      this.swRouter('/h5/#/report/data-feedback?id='+this.id);
    },
    onTap() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.REPORT.TAP_UNLOCK);
      this.swRouter("/pay/" + this.id);
    },
    JumpToCustomer() {
      if (this.entity == null) {
        return;
      }
      // 需要带上当前的地址参数
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "PICK_LOCATION",
        id: 10005,
        params: this.entity.input,
      });
    },
    onAdd() {
      // this.HTTP.reportSave(this.id).finally((res) => {
      //   this.entity.payed = 1;
      //   this.$toast("保存成功！前往“我的报告”查看。");
      // });
      if (this.stdId != '10124' && !this.entity.userBenefitResp.member) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "开通会员可下载PDF文件",
            confirmButtonText: "查看会员权益",
            confirmButtonColor: "#347FFF",
            cancelButtonText: "取消",
          })
          .then(() => {
            // on confirm
            if (window.isClient) {
              this.swRouter("/h5/#/member");
            } else if (window.isMini) {
              UniUtils.goMiniVipPage()
            }  else {
              this.$toast("请下载APP使用该功能！");
            }
          })
          .catch(() => {
            // on cancel
          });
        return
      }
      this.HTTP.saveReport(this.id)
        .finally((res) => {
          if(this.showSaveBtn) {
            this.entity.saveStatus = 1;
            this.showSaveBtn = false
            this.$toast("保存成功！前往“我的报告”查看。");
          }
        })
        .catch((e) => {
          this.$toast("保存失败，请检查网络状态。");
        });
    },
    gotoMyReport() {
      // 先检查是否已经生成了pdf
      if (!this.entity) return;
      // this.swRouter("/h5/#/my/reports?index=6");
      if (this.entity.userBenefitResp.member == true) {
        this.$dialog
          .alert({
            title: "提示",
            message: "PDF文件生成中，请耐心等待。注意查收消息推送及短信提醒。文件生成成功，将出现“下载”按钮，点击“下载”按钮，完成下载。",
            confirmButtonColor: "#347FFF",
          })
          .then(() => {
            // on confirm
            this.HTTP.downloadCheck(this.entity.id)
              .then((res) => {
                // console.log('download-check:', res);
                this.swRouter("/h5/#/my/reports?index=6");
              })
              .catch((e) => {
                this.swRouter("/h5/#/my/reports?index=6");
              });
          });
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "开通会员可下载PDF文件",
            confirmButtonText: "查看会员权益",
            confirmButtonColor: "#347FFF",
            cancelButtonText: "取消",
          })
          .then(() => {
            // on confirm
            if (window.isClient) {
              this.swRouter("/h5/#/member");
            } else if (window.isMini) {
              UniUtils.goMiniVipPage()
            } else {
              this.$toast("请下载APP使用该功能！");
            }
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    jumpTo(id) {
      this.showCatalog = false;
      this.lastClick = id;
      this.viewTarget.postMessage({ type: "scroll", content: id }, "*");
    },
    onDataQuery(params) {
      if(sessionStorage.getItem("platform")) {
        return
      }
      if (window.isClient) {
        if (window._appCode && window._appCode >= 160) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "SURROUNDING_DATA",
            id: this.id,
            params: JSON.stringify(params),
          });
        } else {
          this.$toast("APP版本过低，请升级以获取更好的体验！");
        }
      } else {
        this.$toast("请下载APP进行查看！");
      }
    },
    onAroundSearch() {
      this.HTTP.createDataQuary()
        .then((res) => {
          this.swRouter({ path: `/pay/${res}`, query: { id: this.id } });
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍候重试。");
        });
    },
    switchShare(st) {
      // console.log( 'switchShare:', st );
      // 排除 10002市调报告 10014查周边
      if (this.entity && ![10002, 10014, 10018, 10019].includes(this.stdId)) {
        this.JSBridge.callHandler(
          "switchShareBtnV2",
          st
            ? {
              code: "REPORT",
              id: this.id,
            }
            : false
        );
      }
      // 市调及解锁报告显示分享按钮 排除10014查周边
      if (this.entity && ([10002].includes(this.stdId) || this.unlock) && ![10014, 10018, 10019].includes(this.stdId)) {
        this.JSBridge.callHandler(
          "switchShareBtn",
          st
            ? {
              type: "normal",
              id: this.id,
              check: false,
            }
            : false
        );
      }
      // 市调/品牌洼地 报告不显示分析按钮
      // if ( ! this.entity || [ 10002, 10004 ].includes( this.entity.stdQuestionId ) ) return;
      // this.JSBridge.callHandler( 'switchShareBtn', st ? { id: this.id, type: 'normal', check: [ 10003 ].includes( this.entity.stdQuestionId ) !== true } : false );
    },
    openCatalog() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.REPORT.TAP_CATALOG);
      this.showCatalog = true;
    },
    jumpToApp(idx, params) {
      if(sessionStorage.getItem("platform")) {
        return
      }
      if (window.isClient) {
        if (window._appCode && window._appCode >= 203) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "BRAND_INTRODUCTION",
            id: idx,
            params: params ? params : this.entity.input,
            trackData: { moduleId: "20400", btnId: "20401" },
          });
        } else {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "PICK_LOCATION",
            id: idx,
            params: params ? params : this.entity.input,
          });
        }
      } else {
        this.$toast("请下载APP进行查看！");
      }
    },
    addListener(e) {
      switch (e.data.type) {
        // 广告上报
        case "reportAd": {
          console.log('e.data',e.data.content)
          const { adCode, btnId } = e.data.content

          // this.JSBridge.callHandler("onNormalizingCall", {
          //   type: 5,
          //   url: "CALL_AD",
          //   params: JSON.stringify({
          //     "adType" : 1,
          //     "adCodeAndroid" : adCode,
          //     "adCodeIos": adCode,
          //     "extra" : JSON.stringify({
          //       btnId
          //     })
          //   })
          // })
          this.JSBridge.BTNCLICK(10063, this.id, '1006300', btnId)
          // this.adWatchStartTime = formatDate2(new Date(), 'yyyy-MM-dd hh:mm:ss')

          const randomNum = Math.round(Math.random() * adDramaUrls.length) - 1

          this.JSBridge.callHandler("onNormalizingCall", {
            id: 'gh_cf7cf4164bd3', // 小程序原始id
            params: adDramaUrls[randomNum],
            url: 'MINI_PROGRAM',
            type: 5
          })

          setTimeout(() => {
            this.adConfig.open = false
            this.viewTarget.postMessage({ type: "setAdConfig", content: this.adConfig }, "*");
          }, 100)

          break;
        }
        case "saasChannel": {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "ONLINE_SERVICE",
            params: JSON.stringify({"sendText":"我要咨询数位拓店云"}),
          });
          break;
        }
        case "ready": {
          this.isReady = true;
          this.sendData();
          break;
        }
        case "switchFullscreen": {
          this.showMenu = !e.data.content;
          this.switchShare(this.showMenu);
          break;
        }
        case "scrollToLock": {
          this.showContinue = !e.data.content;
          break;
        }
        case "tapSearch": {
          // console.log( 'tapSearch:', e.data.content );
          this.onDataQuery(e.data.content);
          break;
        }
        case "openVip": {
          if(sessionStorage.getItem("platform")) {
            return
          }
          if (window.isClient) {
            if (window._appCode >= 401) {
              this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,//6 H5  5原生页面
                url: 'DIALOG',
                id: 'h5_' + this.stdId,
                params: JSON.stringify({"type":4})
              });
            } else {
              this.swRouter("/h5/#/member?source=report_unlock");
            }
          } else if (window.isMini) {
            UniUtils.goMiniVipPage()
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        }
        case "tapContinue": {
          if(sessionStorage.getItem("platform")) {
            return
          }
          if (this.stdId === 10014 || this.stdId === 10030 || this.stdId === 10032) {
            if (e.data.content === "btn_1") {
              this.onDataQuery(null);
            } else if (e.data.content === "btn_2") {
              this.onTap(); // 购买报告
              // 跳转到客群条件输入页
              // this.JumpToCustomer();
            } else if (e.data.content == "btn_3") {
              this.onAroundSearch();
            } else if (e.data.content === "example") {
              if (window.isClient) {
                this.JSBridge.callHandler("callFileViewer", {
                  type: "application/pdf",
                  id: 47179077493335,
                  name: "报告示例",
                  url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20210127/1611741976671.pdf",
                });
              } else {
                this.$toast("请下载APP进行查看！");
              }
            } else if (e.data.content === "unlock") {
              if (window.isClient) {
                if (window._appCode && window._appCode >= 203) {
                  this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "BRAND_INTRODUCTION",
                    id: 10001,
                    params: null,
                    trackData: { moduleId: "20400", btnId: "20401" },
                  });
                } else if (window._appCode && window._appCode >= 200) {
                  this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "PICK_LOCATION",
                    id: 10001,
                    params: this.entity.input || null,
                  });
                } else if (window._appCode && window._appCode >= 160) {
                  this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CARD",
                    id: 10001,
                    params: this.entity.input || null,
                  });
                } else {
                  this.JSBridge.callHandler("gotoPage", "card_10001");
                }
              }
            } else if (e.data.content == "business") {
              // 商圈
              this.jumpToApp("10011");
            } else if (e.data.content == "customer") {
              // 客群
              this.jumpToApp("10005");
            } else if (e.data.content == "compete") {
              // 竞争
              this.jumpToApp("10006");
            } else if (e.data.content == "hideGuide") {
              this.JSBridge.callHandler(
                "putString",
                JSON.stringify({
                  key: "isFirstOpenReportInV2_0_0",
                  value: "1",
                })
              );
            }
          } else if (this.stdId === 10015) {
            // 找商机-直接跳转到找品牌列表
            this.swRouter("/brand/discovery");
          } else if (!this.unlock) {
            this.onTap();
          } else {
            this.$toast("发生未知错误！");
          }
          break;
        }
        case "tapUnlock": {
          this.onTap();
          break;
        }
        // 跳转至选址分析
        case "jumpTo-siting": {
          if (window.isClient) this.JSBridge.callHandler("gotoPage", "card_10001");
          break;
        }
        // 跳转至
        case "jumpTo-brandjoin": {
          const brand = e.data.content[1];
          if (brand) {
            // 保存数据到本地存储
            this.$store.commit("setTemp", this.entity);
            this.swRouter({ path: "/card/D", query: { brandCode: brand.value.code, brandName: brand.value.text } });
          }
          break;
        }
        // 跳转至数据反馈特定tabs栏
        case "data-feedback": {
          this.swRouter({ path: "/h5/#/report/data-feedback", query: { id: this.id, active: e.data.content } });
          break;
        }
        // 跳转至企业微信
        case "goWeChat": {
          // 打开小程序
          this.JSBridge.callHandler('onNormalizingCall', {
            id: 'gh_a80747334fea', // 小程序原始id
            params: '/pages_adver/wechatLandPage/index?code=app',
            url: 'MINI_PROGRAM',
            type: 5
          })
          break;
        }
        // 热力图
        case "hotChart": {
          const result = e.data.content;
          switch (result.type) {
            case "business":
              // 商圈数据
              this.jumpToApp("10011", result.input);
              break;
            case "compete":
              // 竞争分析
              this.jumpToApp("10006", result.input);
              break;
            case "customer":
              // 客群数据
              this.jumpToApp("10005", result.input);
              break;
            case "siteAssessment":
              // 选址综合评估
              if (window._appCode && window._appCode >= 200) {
                this.jumpToApp("10001", result.input);
              } else {
                this.JSBridge.callHandler("gotoPage", "card_10001");
              }
              break;
            case "switchBackBtn":
              // 隐藏、显示返回按钮
              this.showBack = result.input;
              // 隐藏、显示菜单按钮
              this.showMenu = result.input;
              break;
            case "switchBackAndMenuBtn":
              // 隐藏、显示返回按钮
              this.showBack = result.input;
              // 隐藏、显示菜单按钮
              this.showMenu = result.input;
              // 隐藏、显示专家解读
              this.shouExpert = result.input;
              break;
          }
          break;
        }
        //外卖 数据说明
        case "takeData":
          this.swRouter("/take/data?date=" + this.entity.gmtCreateFormat.replace(/\./g, '-'));
          break;
        case "JumpCard":
          if (window.isClient) {
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              id: "10092",
              url: "SKU_FILL_INFO_PAGE",
            });
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        case "jumpToEle": {
          if (window.isClient) {
            this.JSBridge.BTNCLICK(10063, "", 85000, 85001);
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 6,
              id: null,
              url: "https://kaidian.ele.me/?origin=sscm",
              params: JSON.stringify({
                title: "饿了么商家入驻",
              }),
            });
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        }
        //外卖品类查看更多
        case "category": {
          if (window.isClient) {
            let params = e.data.content.content;
            this.JSBridge.callHandler(
              "putString",
              JSON.stringify({
                key: "cateSession",
                value: params,
              })
            );
            this.swRouter("/take/varieties");
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        }
        // 品类热度-品类商家月销排行查看更多
        case "rankCategoryName": {
          if (window.isClient) {
            let params = e.data.content.content;
            console.log(params, 'data')
            this.JSBridge.callHandler(
              "putString",
              JSON.stringify({
                key: "categoryNums",
                value: params,
              })
            );
            this.swRouter("/take/rank?type=categoryNums");
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        }
        // 品类热度-品类商家月订单排行查看更多
        case "orderRankList": {
          if (window.isClient) {
            let params = e.data.content.content;
            console.log(params, "orderRankList")
            this.JSBridge.callHandler(
              "putString",
              JSON.stringify({
                key: "orderRankList",
                value: params,
              })
            );
            this.swRouter("/take/rank?type=orderRankList");
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        }
        //外卖门店查看更多
        case "ranking":
          this.swRouter(`/take/sales?reportId=${this.id}`);
          break;
        // 外卖选址评估
        case "JumpTake":
          if (window.isClient) {
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              id: "10084",
              url: "SKU_FILL_INFO_PAGE",
            });
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break
        // 商场查询-查看更多店铺
        case 'lookMoreMall':
          if (window.isClient) {
            let params = e.data.content.content;
            console.log('params', params)
            setLocalStorage('mallList', params)
            this.swRouter("/rank/mall?type=mallList");
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break
        // 通用的查看更多页面
        case 'lookMorePage':
          let params = e.data.content.content;
          setLocalStorage('tableData', params)
          this.swRouter("/rank/page");
          break
        // 判断手机是否安装过某个app
        case 'judgeInstallApp':
          let val = e.data.content;
          this.JSBridge.callHandler( 'isAppInstalled', val, ( data ) => {
            // console.log(data)
            this.viewTarget.postMessage({ type: "judgeInstallApp", content: data }, "*");
          })
          break
        // 获取当前位置
        case 'getLocation':
          this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
            if ( typeof data === 'string' ) data = JSON.parse( data );
            // console.log('data', data)
            let locationInfo = ''
            if ( data ) {
              locationInfo = {
                poiName: data.poiName,
                longitude: data.longitude,
                latitude: data.latitude
              }
            }
            this.viewTarget.postMessage({ type: "getLocation", content: locationInfo }, "*");
          })
          break
        case "brandHome":
          this.swRouter(`/h5/#/brand/home`);
          break;
      }
    },
    sendData() {
      // 已发送或未就绪或未得到数据将不调用下发
      if (this.isSended || !this.isReady || !this.entity) return;
      this.isSended = true; // 标记为已下发
      clearTimeout(_timer); // 清理超时提醒
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.viewTarget.postMessage({ type: "data", content: this.entity }, "*");

      // 发送广告
      console.log('this.adConfig',this.adConfig)
      this.viewTarget.postMessage({ type: "setAdConfig", content: this.adConfig }, "*");
    },
    tapInterpret() {
      this.popupVisible = true
      // if (window.isMini) {
      //   UniUtils.goKefuPage()
      //   return;
      // }
      // if (!window.isClient) {
      //   this.$toast("非客户端环境！");
      //   return;
      // }
      // if (window._appCode >= 175) {
      //   this.JSBridge.callHandler("onNormalizingCall", {
      //     type: 5,
      //     url: "ONLINE_SERVICE",
      //     params: JSON.stringify({
      //       cardCode: "CUSTOMER_REPORT",
      //       cardId: this.id,
      //       title: "专家解读",
      //       type: 4,
      //     }),
      //   });
      // } else {
      //   this.JSBridge.callHandler(
      //     "callLiveChat",
      //     {
      //       type: "report",
      //       data: {
      //         id: this.id,
      //       },
      //     },
      //     (st) => {
      //       // console.log( st );
      //     }
      //   );
      // }
    },
    onLivechat() {
      if (!this.isClient) {
        this.$toast("非客户端环境！");
        return;
      }
      if (window._appCode >= 175) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: "ONLINE_SERVICE",
          params: JSON.stringify({
            cardCode: "CUSTOMER_REPORT",
            cardId: this.id,
            title: "在线客服",
            type: 3,
          }),
        });
      } else {
        this.JSBridge.callHandler(
          "callLiveChat",
          {
            type: "normal",
            data: {},
          },
          (st) => {
            // console.log( st );
          }
        );
      }
    },
  },
  watch: {
    showCatalog(val) {
      this.switchShare(!val);
    },
    entity(e) {
      // 组装菜单数据
      const list = [];
      if (e.config.showCover) {
        list.push({
          id: "cover",
          name: "封面",
        });
      }
      if (e.config.showCatalog) {
        list.push({
          id: "catalog",
          name: "目录",
        });
      }
      if (e.config.showConclusion) {
        list.push({
          id: "conclusion",
          name: "概览",
        });
      }
      let index = 0;
      for (const model of e.reportModelInstanceFullList) {
        const lock = !e.payed && model.type > 1;
        list.push({
          id: model.id,
          name: `${this.pageMap[index]}. ${model.name}`,
          isBgm: false,
          lock,
        });
        index++;
        if (model.hasOwnProperty("bgmInstanceFullList") === false) continue;
        for (const bgm of model.bgmInstanceFullList) {
          let name = bgm.name.split("-");
          name = name[name.length - 1];
          list.push({
            id: bgm.bgmId,
            name,
            isBgm: true,
            lock,
          });
        }
      }
      this.menus = list;
      this.benefit = e.userBenefitResp;
      // console.log( 'switchShare entity' );
      this.switchShare(true);
    },
  },
  computed: {
    showFeedback() {
      return [10001, 10026, 10084, 10032, 10019, 10018, 10037, 10092].includes(this.entity.stdQuestionId);
    },
    unlock() {
      return this.entity && (this.entity.payed || this.entity.priceFen === 0);
    },
    ctrMenu() {
      return this.entity && [10001].includes(this.entity.stdQuestionId);
    },
    livechat() {
      return this.unlock && [10001, 10005, 10006, 10011].includes(this.entity.stdQuestionId);
    },
    livechat2() {
      return this.entity && [10014].includes(this.entity.stdQuestionId);
    },
    url() {
      if (!this.entity) return "about:blank";
      console.log(this.host + this.id)
      return this.host + this.id;
      // return 'http://localhost:9002/' + this.id;
    },
    viewTarget() {
      return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
    },
    canSaveOrDownload() {
      const ids = [10030, 10031, 10032, 10037, 10018, 10019, 10124];
      if (!this.entity) {
        return false;
      }
      if (ids.includes(this.entity.stdQuestionId)) {
        return true;
      }
      return false;
    },
    showDownLoad() {
      if(sessionStorage.getItem("platform")) {
        return false
      } else {
        return this.entity.saveStatus
      }
    }
  },
  created() {
    // 获取报告ID
    const id = this.$route.params.id;
    if (!id || id == 'null') {
      this.$toast("无效报告信息，即将返回首页！");
      this.$router.push("/");
      return;
    }
    if (id=='searchBrand') {
      this.$router.replace('/reportView/searchBrand')
      return;
    }
    this.first = this.$route.query.first || false;

    // 第三方平台埋点
    if(sessionStorage.getItem('platform')) {
      this.HTTP.elemeSubmit({
        platform: sessionStorage.getItem("platform"),
        track: JSON.stringify([{
          pageId: "10063",
          timestamp: new Date().getTime()
        }])
      })
    }

    // 获取报告地址
    this.host = this.HTTP.getReportUrl();
    const entityHandler = async (res) => {
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      console.log("entity:", res);
      // 数据提取
      this.id = res.id;
      this.stdId = res.stdQuestionId;
      const stdId = this.stdId;

      if(window.isClient){
        //判断是否分发优惠卷
        if([10032,10037,10018,10019,10031,10124].indexOf(stdId)!==-1){
          console.log('属于分发报告-----------',stdId);
          getCrowd().then((res)=>{
            if(res){
              //有特惠商品
              setTimeout(()=>{
                //报告加载需要一定时间
                this.showCoupon=true;
                this.memberGoodsId=res;
                this.JSBridge.BTNCLICK(this.$route.meta.id, null, 5950100, 5950101)
              },1000)
            }
            console.log('特惠返回---',res);
          }).catch(()=>{})
        }
      }
      // 获取广告配置
      console.log('this.iosShowAd',this.iosShowAd)
      if (window.isClient && window._appCode >= 40600 && !this.entity?.userBenefitResp?.member && ((window.isIOS && this.iosShowAd)||(!window.isIOS))) {
        if ([10032,10037,10018,10019].indexOf(stdId)!==-1) {
          this.adConfig = await queryAdConfig({ deviceType: window.isIOS ? 2 : 1 })
          console.log('adConfig',this.adConfig)
          if (this.adConfig?.open) {
            console.log('registerHandler onAdClose')
            this.JSBridge.registerHandler("onAdClose", (data) => {
              console.log('onAdClose',data)
              if (typeof data === 'string') {
                data = JSON.parse(data)
              }
              const extra = JSON.parse(data.extra)
              const form = {
                stdQuestionId: this.stdId,
                refId: this.id,
                btnId: extra.btnId,
                adId: data.adCode,
                deviceType: window.isIOS ? 2 : 1,
                isLock: data.rewardVerify ? 1 : 0,
                skip: data.skip ? 1 : 0,
                playComplete: data.adPlayComplete ? 1 : 0,
                watchStartTime: this.adWatchStartTime,
                watchEndTime:  formatDate2(new Date(), 'yyyy-MM-dd hh:mm:ss')
              }
              reportAdConfig(form)
              // 领取完奖励，将会员蒙层解锁
              if (data.rewardVerify) {
                this.adConfig.open = false
                this.viewTarget.postMessage({ type: "setAdConfig", content: this.adConfig }, "*");
                // this.viewTarget.postMessage({ type: "lockAd", content: extra.btnId }, "*");
              }
            })
          }
        }
      }

      if (stdId === 10001) this.title = "选址分析报告";
      else if (stdId === 10004) this.title = "加盟品牌位置分析报告";
      else if (stdId === 10124) {
        let bgmOutput = res.reportModelInstanceFullList[0].bgmInstanceFullList[0].output
        if (typeof bgmOutput === 'string') {
          bgmOutput = JSON.parse(bgmOutput)
        }
        if (bgmOutput.output) {
          bgmOutput.output = JSON.parse(bgmOutput.output)
        }
        const stdQuestionId = bgmOutput.output[0].stdQuestionId
        const bgmConfigs = {
          10032: '查周边',
          10037: '查外卖',
          10018: '行业热力',
          10019: '人口热力'
        }
        this.title = bgmConfigs[stdQuestionId] + "数据对比"
      }
      else this.title = res.name || "阅读报告";

      // 是否需要定位
      const location = this.needLocation;
      // 是否定制报告
      const isCustom = [10001, 10002, 10003, 10004, 10005, 10006, 10011, 10014, 10015, 10018, 10019, 10020, 10021, 10022,10124].includes(stdId);
      this.showSubTitle = !isCustom;
      // 扩展字段
      res["config"] = {
        isCustom: isCustom, // 是否定制报告
        isShare: false, // 是否来自分享
        showTitle: false, // 是否显示报告标题
        showHeader: this.first, // 是否显示报告头
        showConclusion: stdId !== 10002, // 是否显示报告结论
        showCover: isCustom ? `//${window.location.host}/static/img/${stdId}.jpg` : false, // 是否显示报告封面 支持url
        showInputs: !this.first && stdId !== 10002, // 是否显示报告参数
        showCatalog: isCustom && stdId !== 10002, // 是否显示报告目录
        isShowEle: true, // 是否显示饿了么广告位
        templateId: stdId,
        tokenId: null,
        isNewNeay: window._appCode >= 200,
        isFirstGuide: stdId === 10014 && window._appCode >= 200 && !this.isFirstOpenReportInV2_0_0, // 首次打开
        location: location,
        showWm: window._appCode >= 302, // 显示外卖sku广告位
        showCommunity: window._appCode >= 305, // 显示社区sku广告位
      };
      // 保存修改后的数据实体
      this.entity = res;
      // 设置超时并下发数据
      _timer = setTimeout(() => {
        this.$toast("无法获取报告，请稍候重试！");
        this.loading = false;
      }, 30000);
      this.sendData();
    };

    const entity = this.$store.getters.temp;
    if (entity && id === "" + entity.id) {
      this.$store.commit("setTemp", null);
      entityHandler(entity);
    } else {
      // 加载网络数据
      // this.HTTP.getReportData(446604181177399)
      this.HTTP.getReportData(id)
        .then((res) => {
          entityHandler(res);
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍候重试！");
        });
    }
    // 添加通信监听
    window.addEventListener("message", this.addListener);
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 设置客户端类型
    this.isClient = window.isClient || true;
    this.JSBridge.callHandler('getIDFA', null, (res) => {
      console.log('getIDFA',res)
      this.iosShowAd = !!res.isGetIDFA
    })
    this.JSBridge.callHandler("getString", "isFirstOpenReportInV2_0_0", (data) => {
      // 将数据转换后发送到处理方法
      if (data) {
        if (typeof data === "string") {
          data = JSON.parse(data); // 兼容安卓数据解析
        }
        this.isFirstOpenReportInV2_0_0 = !!data;
      }
    });

    // 开通会员后更新数据
    this.JSBridge.registerHandler("onAppResume", (data) => {
      if (localStorage.getItem('updateVipInfo')) {
        localStorage.setItem('updateVipInfo','')
        location.reload()
      }
    })
  },
  beforeDestroy() {
    // 隐藏分享按钮
    this.JSBridge.callHandler("switchShareBtnV2", false);
    this.JSBridge.callHandler("switchShareBtn", false);
  },
  destroyed() {
    // 移除通信监听
    window.removeEventListener("message", this.addListener);
    // 移除定时监听
    clearTimeout(_timer);
  },
  beforeRouteEnter(to, from, next) {
    // console.log( 'charts to:', to, ', from:', from );
    if (from.path === "/success") {
      // console.log( 'enter from success' );
      next((vm) => {
        vm.needLocation = true;
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.charts {
  overflow: hidden;
  height: 100vh;
  background-color: $c-pageBg2;
  ::v-deep .titleHeader {
    .view {
      &--r {
        width: 100%;
        top: 0;
        left: 0;
      }
      .subTitle {
        position: absolute;
        bottom: 2.5vw;
        left: 50%;
        color: #9aa9be;
        transform: translateX(-50%);
        font-weight: normal;
        font-size: $font_320;
      }
      .right {
        position: relative;
        @include flexColumn;
        width: 100%;
        height: $headerHeight;
        align-items: flex-end;

        .save {
          @include flexColumn;
          width: $headerHeight;
          height: 100%;
          color: $c-main;

          &.dis {
            color: $c-info;
          }
        }

        .tips {
          position: absolute;
          bottom: 0;
          right: 1.5vw;
          width: 30vw;
          height: 10vw;
          background: url(/static/img/tips_save.png) no-repeat left top;
          background-size: cover;
          transform: translateY(50%);
          z-index: 99;
        }
      }
    }
    &.sub {
      .title {
        padding-bottom: 5.75vw;
      }
    }
  }

  #{&}--view {
    overflow-y: hidden;
    .innerView {
      width: 100%;
      height: 100%;
    }
  }

  #{&}--icon {
    position: fixed;
    right: 0;
    bottom: 13vh;
    width: 14.4vw;
    height: 12vw;
    border-top-left-radius: 6vw;
    border-bottom-left-radius: 6vw;
    background-color: white;
    box-shadow: 0rem 1.33vw 4vw rgba(#33465f, 0.11);
    &::before {
      content: "";
      display: block;
      width: 10vw;
      height: 10vw;
      background: #283950 url(/static/icon/menu.png) no-repeat center center;
      background-size: cover;
      border-radius: 50%;
      margin: 1vw 0 0 1vw;
    }

    &.icon2 {
      bottom: 20vh;
      &::before {
        background: url(/static/icon/livechat.png) no-repeat center center, linear-gradient(#f4b66a, #fcca8d);
        background-size: cover;
      }
    }
  }
  #{&}--livechat {
    position: fixed;
    right: 0;
    bottom: 12vh;
    height: 12vw;
    width: 24vw;
    background: url(/static/img/report/livechat.png) no-repeat right top;
    background-size: cover;
  }
  #{&}--menu {
    position: relative;
    overflow: hidden;
    width: 65vw;
    height: 100vh;
    background-color: white;
    padding: 24.8vw 0 $padding;
    box-sizing: border-box;

    .header {
      position: absolute;
      top: 13.2vw;
      left: 0;
      width: 100%;
      padding: 0 $padding;
      box-sizing: border-box;
      box-shadow: 0 calc(5vw - 1px) 0 white, 0 5vw 0 $c-line;

      h1 {
        @include singleLine;
        font-size: $font_453;
        line-height: $font_653;
        font-weight: bold;
      }
      p {
        font-size: $font_320;
        line-height: $font_440;
        color: $c-info;
        margin-top: 1vw;
      }
    }
    .list {
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      li {
        position: relative;
        @include singleLine;
        padding: 0 $padding 0 $padding;
        height: 14.53vw;
        line-height: 14.53vw;
        font-size: $font_373;
        font-weight: bold;
        &.on {
          color: $c-main;
          background-color: #f8f8f9;
        }
        &.sub {
          padding-left: 12.4vw;
          font-weight: normal;
          &.lock {
            background: url(/static/icon/lock.png) no-repeat left $padding center;
            background-size: 5vw;
            color: $c-info;
          }
        }
        // &:after {
        //     content: attr( data-page );
        //     position: absolute;
        //     right: $margin;
        //     top: 0;
        //     color: $c-info;
        //     font-weight: normal;
        //     font-size: $font_320;
        // }
      }
    }
  }
  #{&}--footer {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;

    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      line-height: normal;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity transform 0.25s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(-75%);
    }
  }

  // 延迟隐藏动画
  .hid_delay_35 {
    animation: fadeOut 1s forwards 4s;
  }
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  ::v-deep .van-popup--round {
    border-radius: 5px;
  }

  .popup-box {
    width: 291px;
    height: 349px;
    background: url(/static/report/popup_bg.png) no-repeat center center;
    background-size: contain;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
    }

    .footer-left > ::v-deep .van-button {
      position: absolute;
      bottom: 30px;
      left: 15px;
      width: 100px;
      height: 45px;
      background-color: transparent;
      border-radius: 23px;
      font-weight: bold;
      border: 1px solid #347FFF;
    }

    .footer-right > ::v-deep .van-button {
      position: absolute;
      bottom: 30px;
      right: 15px;
      width: 151px;
      height: 45px;
      border-radius: 23px;
      font-weight: bold;
      border: 1px solid #347FFF;
    }
  }
}
</style>
