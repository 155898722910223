<template>
  <div class="chose-city">
    <!-- 城市搜索 -->
    <div class="city-search-wrap">
      <div class="search">
        <div class="l-search">
          <div class="icon-search">
            <div class="cuIcon-search"></div>
          </div>
          <input
            class="input-search"
            type="text"
            v-model="inputValue"
            placeholder="请输入"
            @input="searchChange"
          />
          <span class="clear-input iconfont icon-icon-test" v-if="isClearBtn" @click="inputValue = ''"></span>
        </div>
      </div>
      <!-- 搜索列表  -->
      <div class="reach-content" v-show="inputValue">
        <div v-show="searchData.length">
          <div class="li" v-for="item in searchData" :key="item.citycode" @click="selectCity(item)">
            {{ item.name }}
          </div>
        </div>
        <div class="has-no-data" v-show="hasNoData">没有找到匹配数据~</div>
      </div>
    </div>
    <!-- 城市列表 -->
    <div
      class="list-box"
      v-if="!inputValue"
      @scroll="scrollHandle"
    >
      <div class="container">
        <!-- 您所在的地区 -->
        <div class="area list-item" id="area">
          <div class="title-wrapp">
            <div class="c-title">
              <span class="l">您所在的地区</span>
            </div>
          </div>
          <div class="ul">
            <div class="li now" @click="selectCity(myCityObj, 'refresh')">
              <img src="/static/dataQuery/icon_location.png" class="icon" v-if="myCityObj.name"></img>
              <!--              <img src="/static/icon/icon_update.png" class="icon" v-else></img>-->
              <span class="span">{{ myCityObj.name ? myCityObj.name : "定位失败" }}</span>
            </div>
          </div>
        </div>
        <!-- 热门城市 -->
        <div class="area list-item" id="record" v-if="recordList.length">
          <div class="title-wrapp">
            <div class="c-title">
              <span class="l">热门城市</span>
            </div>
          </div>
          <div class="ul">
            <div class="li" v-for="item in recordList" :key="item.citycode" @click="selectCity(item)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 城市列表  -->
      <div class="city-list">
        <div class="list list-item" v-for="(item, key) of cityList" :key="key" :id="item.nameType">
          <div class="c-title">{{ item.nameType }}</div>
          <div class="item" v-for="innerItem in item.list" :key="innerItem.citycode" @click="selectCity(innerItem)">
            {{ innerItem.name }}
          </div>
        </div>
      </div>
    </div>

    <!-- 字母列表 -->
    <div class="alphabet" @touchstart="touchStart" @touchend="touchEnd" @touchmove.stop="touchMove">
      <div v-for="(item, index) in alphabet" :key="index" @touchstart="getLetter" @touchend="setLetter" :id="item">
        <div class="item" :class="{ active: currentLetter == item }">
          {{ item == "area" ? "当前" : item == "record" ? "热门" : item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { gd_map_key } from "@/utils/config"
export default {
  data() {
    return {
      inputValue: "",
      searchData: [], // 搜索的数据
      isClearBtn: false,
      toIndex: "", // 跳转的索引的字母
      tipsLetter: "", // 滑动显示字母
      timer: null,
      hasNoData: false,
      letterDetails: [],
      currentLetter: "area", //默认选择
      recordList: [],
      cityList: [],
      hasLocation: false,
      myCityObj: {},
      alphabet: ["area", "record", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "W", "X", "Y", "Z"],
      hasScroll: false
    };
  },
  mounted() {
    if (window.mn) {
      this.myCityObj.name = window.mn
    }
    if (window.mc) {
      this.myCityObj.citycode = window.mc
    }
    this.HTTP.getCityList().then((res) => {
      if(res.data.code == 0) {
        const data = res.data.data
        let hotCity = data.hostCities;
        hotCity.forEach((city) => {
          city.citycode = city.code;
        });
        this.recordList = hotCity;
        let cityList = data.openCityWordList;
        cityList.forEach((item) => {
          item.nameType = item.word;
          let arr = item.list;
          arr.forEach((val) => {
            val.citycode = val.code;
            val.pinyin = item.word;
            val.nameType = item.word;
          });
        });
        this.cityList = cityList;
      }
    });
    // this.getLocation();
  },
  watch: {
    // 城市搜索输入框
    inputValue(newVal) {
      this.isClearBtn = newVal ? true : false;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.inputValue) {
        this.searchData = [];
        return;
      }
      this.timer = setTimeout(() => {
        const result = [];
        this.cityList.map((v) => {
          v.list.forEach((item) => {
            if (
              (/^[a-zA-Z]+$/.test(item.pinyin) && item.pinyin.toLowerCase().includes(this.inputValue.toLowerCase())) ||
              item.name.includes(this.inputValue)
            ) {
              result.push(item);
            }
          });
        });
        this.searchData = result;
        if (this.searchData.length === 0) {
          this.hasNoData = true;
        } else {
          this.hasNoData = false;
        }
      }, 500);
    },
  },
  methods: {
    selectCity(item, type) {
      if (item.name) {
        this.$store.commit('setCityInfo', item)
        this.$router.back()
      }
    },
    //列表滚动，和右边字母表对应
    scrollHandle(e) {
      if (this.hasScroll) {
        this.hasScroll = false
        return
      }
      let div = document.querySelectorAll(".list-item");
      let top = div[0].getBoundingClientRect().top;
      const letterDetails = []
      div.forEach(item => {
        const o = item.getBoundingClientRect()
        const t = o.top - top;
        const b = o.bottom - top;
        letterDetails.push({
          id: item.id,
          top: t,
          bottom: b,
        });
      })

      const scrollTop = e.target.scrollTop;
      letterDetails.some((item) => {
        if (scrollTop >= item.top && scrollTop <= item.bottom - 20) {
          this.currentLetter = item.id;
          //当前固定用的是粘性定位，如果不用粘性定位，在这里设置
          return true;
        }
      });
    },

    //搜索
    searchChange(e) {
      // this.inputValue = e.data;
    },
    // 触发开始
    touchStart(e) {
      // console.log(e);
    },
    //移动时
    touchMove(e) {
      console.log(e)
      e.preventDefault()
      // uni.vibrateShort();
      let y = e.touches[0].clientY;
      let offsettop = e.currentTarget.offsetTop;

      //判断选择区域,只在选择区才会生效
      if (y > offsettop) {
        let num = parseInt((y - offsettop) / 15); //右边每个字母元素的高度
        let letter = this.alphabet[num];
        this.tipsLetter = letter;

        let curentLetter = this.letterTransform(letter);
        if (curentLetter) {
          this.$toast({
            message: curentLetter,
            className: 'myToast'
          })
        }
      }
    },
    //触发结束
    touchEnd() {
      this.toIndex = this.tipsLetter;
      this.hasScroll = true
      this.currentLetter = this.tipsLetter;
      const dom = document.querySelector( '#' + this.toIndex )
      if (dom) {
        dom.scrollIntoView();
      }
    },
    //移动开始获取字母，并放大提示
    getLetter(e) {
      e.preventDefault()
      // uni.vibrateShort();
      console.log(e)
      let { id } = e.currentTarget;
      this.tipsLetter = id;

      let curentLetter = this.letterTransform(id);
      if(curentLetter) {
        this.$toast({
          message: curentLetter,
          className: 'myToast'
        })
      }
    },
    //移动结束设置字母，赋值到toIndex
    setLetter() {
      this.toIndex = this.tipsLetter;
    },

    //提示字母转换
    letterTransform(letter) {
      let str = "";
      if (letter == "area") {
        str = "当前";
      } else if (letter == "record") {
        str = "热门";
      } else {
        str = letter;
      }
      return str;
    },
  },
};
</script>

<style lang="scss">
.myToast {
  height: 2rem;
}
</style>
<style lang="scss" scoped>
.chose-city {
  position: relative;
  z-index: 99;
  background: #fff;
  height: 100vh;
  overflow: hidden;
}

.city-search-wrap {
  width: 100%;
  box-sizing: border-box;
  .search {
    width: 100%;
    height: 15.5vw;
    display: flex;
    align-items: center;
    font-size: vw2(14);
    color: #222;
    padding: 0 vw2(18);
    box-sizing: border-box;
    background: #fff;

    .l-search {
      width: 100%;
      position: relative;
      height: vw2(36);
      line-height: vw2(36);
      .icon-search {
        position: absolute;
        left: vw2(15);
        top: 0;
        color: #8e8f97;
        font-weight: 700;
        height: vw2(36);
        line-height: vw2(36);
        .cuIcon-search {
          width: vw2(18);
          height: vw2(18);
          background: url("/static/dataQuery/map_icon_search.png") no-repeat;
          background-size: cover;
          margin-top: vw2(10);
        }
      }
      .input-search {
        height: vw2(36);
        width: 100%;
        box-sizing: border-box;
        padding: 0 vw2(10) 0 vw2(40);
        text-align: left;
        background: #f4f5f9;
        border-radius: vw2(6);
        border: 0;
        font-size: vw2(14);
      }

      .clear-input {
        font-size: vw2(15);
        position: absolute;
        right: vw2(5);
        top: 50%;
        transform: translateY(-50%);
        padding: vw2(5);
        color: #8e8f97;
      }
    }
  }
}

.reach-content {
  margin: 0 vw2(18) 0 vw2(26);
  box-sizing: border-box;
  .li {
    font-size: vw2(14);
    height: vw2(50);
    line-height: vw2(50);
    color: #333;
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
  }
}

.list-box {
  height: calc(100vh - 15.5vw);
  box-sizing: border-box;
  overflow-y: auto;
}

.top-search {
  line-height: vw2(36);
  padding: vw2(7) vw2(15) 0;
  box-sizing: border-box;
  margin-bottom: vw2(13);

  .item {
    background: #f5f5f5;
    border-radius: vw2(6);
    font-size: vw2(14);
    text-align: center;
    color: #999999;
    span {
      padding-left: vw2(10);
      color: #c1c2cd;
      vertical-align: middle;
      position: relative;
      top: vw2(-2);
    }
  }
}

.container {
  width: 100%;
  padding: 0 vw2(18);
  //height: calc(100vh - 110px);
  box-sizing: border-box;
}

.area {
  margin-bottom: vw2(4);

  .title-wrapp {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
  }

  .c-title {
    width: 100%;
    box-sizing: border-box;
    font-size: vw2(14);
    color: #999999;
    margin-bottom: vw2(12);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .r {
      font-size: vw2(12);
      color: #8e8f97;
      display: inline-block;
      align-items: center;

      .iconfont {
        font-size: vw2(12);
      }
    }
  }

  .ul {
    display: flex;
    flex-wrap: wrap;

    .li {
      width: 100px;
      box-sizing: border-box;
      height: vw2(37);
      line-height: vw2(34);
      text-align: center;
      font-size: vw2(14);
      color: #333;
      border-radius: vw2(3);
      border: 1px solid #f4f4f4;
      margin: 0 vw2(10) vw2(10) 0;

      &.now {
        width: auto;
        padding: 0 vw2(16) 0 vw2(11);

        .icon {
          width: vw2(12);
          height: vw2(12);
          background-size: 100%;
          vertical-align: middle;
          position: relative;
        }

        .span {
          padding-left: vw2(5);
          font-size: vw2(14);
          color: #347fff;
          vertical-align: middle;
        }
      }

      &.active {
        font-weight: 500;
        background: #ffde45;
      }
    }

    .hover {
      background: #ffde45;
    }
  }
}

.city-list {
  width: 100%;
  padding-bottom: vw2(25);

  .c-title {
    height: vw2(30);
    line-height: vw2(30);
    font-size: vw2(15);
    font-weight: 500;
    color: #272636;
    background: #fff;
    box-sizing: border-box;
    padding-left: vw2(18);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .item {
    margin: 0 vw2(26) 0 vw2(18);
    height: vw2(50);
    line-height: vw2(50);
    color: #333;
    font-size: vw2(14);
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
  }
}

.alphabet {
  position: fixed;
  right: 0;
  top: 15%;
  width: vw2(37);
  text-align: center;
  font-size: vw2(10);
  font-weight: 700;
  color: #8e8f97;
  z-index: 99;

  .item {
    height: vw2(26);
    line-height: vw2(26);
  }

  .active {
    color: #222;
  }
}

.has-no-data {
  font-size: vw2(12);
  text-align: center;
  color: #8e8f97;
  margin-top: vw2(25);
}
</style>
