<template>
    <div class="filling">
        <StateBar></StateBar>
        <TitleHeader title="地图标注-店铺上传至地图" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView :header="true" :footer="true" class="filling--view">
            <div class="tips">
                <img src="/static/icon/icon_information.png" alt="" />
                <div>请完善相关标注信息</div>
            </div>
            <div class="content-item">
                <div class="top">
                    <div class="title required">地点名称</div>
                    <div class="error" v-if="error.shopNameErr">{{error.shopNameErr}}</div>
                </div>
                <van-field v-model.trim="goodsInfo.placeName" placeholder="点击填写店铺/公司名称，需与门头招牌一致" />
            </div>
            <div class="content-item">
                <div class="top">
                    <div class="title required">地图位置</div>
                    <div class="error" v-if="error.mapErr">{{error.mapErr}}</div>
                </div>
                <div class="desc">位置有误会影响导航，请仔细确认</div>
                <template v-if="showMap" >
                    <div id="map-container" @click="editPosition">
                        <div class="btn">
                            <img src="/static/icon/map_icon_needle.png" alt="">
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="container" @click="editPosition">
                        <div class="edit-btn">
                            <div class="icon"></div>
                            点击编辑位置
                        </div>
                    </div>
                </template>
            </div>
            <div class="content-item">
                <div class="top">
                    <div class="title required">门头照片</div>
                    <div class="error" v-if="error.imgErr">{{error.imgErr}}</div>
                    <div class="example" @click="showExample">
                        <img src="/static/icon/examples.png" alt="">
                        <span>照片示例</span>
                    </div>
                </div>
                <div class="desc">拍照上传正面入口及招牌照片（至多3张），尽量白天拍摄</div>
                <UploadImg @getImgUploadUrl="getImgUploadUrl" @deleteImg="deleteImg" :maxCount="3" />
            </div>
            <div class="content-item">
              <div class="top">
                <div class="title required">营业执照照片</div>
                <div class="error" v-if="error.licensePhotoErr">{{error.licensePhotoErr}}</div>
                <div class="example" @click="showExamplePopup2=true">
                  <img src="/static/icon/examples.png" alt="">
                  <span>照片示例</span>
                </div>
              </div>
              <div class="desc">拍照上传营业执照竖版或横版照片，照片需方向正确，无倾斜，无抖动，保证文字清晰可见，露出国徽</div>
              <UploadImg @getImgUploadUrl="getImgUploadUrl2" @deleteImg="goodsInfo.licensePhoto = ''" :maxCount="1" />
            </div>
            <div class="content-item">
                <div class="top">
                    <div class="title required">详细位置</div>
                    <div class="error" v-if="error.addressErr">{{error.addressErr}}</div>
                </div>
                <div class="detail-address">
                    <div class="currentRegion">
                        <div class="region" :class="{ regionEmpty: !goodsInfo.AdministrativeRegion }">{{ showRegion }}</div>
                    </div>
                    <van-field v-model.trim="goodsInfo.address" placeholder="点击填写详细地址" />
                </div>
            </div>
            <div class="content-item">
                <div class="top">
                    <div class="title">营业时间<span>(选填)</span></div>
                </div>
                <van-field v-model.trim="goodsInfo.businessHours" placeholder="正确的营业时间，可提升地点的可靠性" />
            </div>
            <div class="content-item">
                <div class="top">
                    <div class="title">营业电话<span>(选填)</span></div>
                    <div class="error" v-if="error.phoneErr">{{error.phoneErr}}</div>
                </div>
                <van-field v-model="goodsInfo.businessPhone" placeholder="可填写手机号或座机号（座机需带区号）" />
            </div>
        </AutoView>
        <SoltFooter>
            <div class="submitFooter">
                <van-button type="info" block class="s-radius__s" @click="clickSubmitHandle">提交</van-button>
            </div>
        </SoltFooter>
        <!-- 地图 -->
        <div class="showMap" v-if="showMapPopup">
            <mapMarkLocation :lat="goodsInfo.lnglat[1]" :lng="goodsInfo.lnglat[0]" @goBack="goBack" @return="returnHandle"/>
        </div>
        <!-- 支付选择界面 -->
        <van-popup
            v-model="showPayment"
            round
            position="bottom"
            :safe-area-inset-bottom="false"
            :close-on-click-overlay="false"
            @click-overlay="onTapClose"
        >
            <div class="l-panel f-vp subview">
                <h3>购买地图标注服务</h3>
                <h2>￥{{price}}</h2>
                <div class="view">
                    <!-- 支付方式选择 -->
                    <PayTypes :payList="payList" @setPayType="setPayType" />
                </div>
                <van-button type="info" block :loading="waiting" class="s-radius__s" @click="onTap">确认支付</van-button>
            </div>
        </van-popup>
        <van-popup
          style="width: 92vw"
          v-model="showExamplePopup2"
          round
          closeable
          @click-overlay="this.showExamplePopup2 = false"
        >
          <div class="l-panel f-vp subview">
            <h3>照片示例</h3>
            <van-image class="exampleImg"
                       fit="contain"
                       src="/static/img/license-example-photo.png"
            />
          </div>
        </van-popup>
        <van-popup
            style="width: 92vw"
            v-model="showExamplePopup"
            round
            closeable
            @click-overlay="this.showExamplePopup = false"
        >
            <div class="l-panel f-vp subview">
                <h3>照片示例</h3>
                <van-image class="exampleImg"
                    fit="contain"
                    src="/static/img/example.png"
                />
            </div>
        </van-popup>
        <!-- 购买结果提醒 -->
        <van-overlay :show="showPayTips">
            <div class="l-wrapper">
                <div class="tips l-panel s-radius__s">
                    <van-image class="icon" fit="cover" :src="payTips.icon" />
                    <h3>{{ payTips.title }}</h3>
                    <p>{{ payTips.text }}</p>
                    <van-button type="info" class="s-radius__s" @click="onConfirm">确定</van-button>
                </div>
            </div>
        </van-overlay>
        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
import LoadMap from "@/components/baseMap/loadMap";
import { areaList } from "@vant/area-data";
import UploadImg from "@/components/uploadImg/index.vue";
import { isPhoneNo } from "@/utils/index";
import mapMarkLocation from "./mapMarkLocation.vue";
import PayTypes from "@/components/payTypes";
export default {
    data() {
        return {
            isIOS: false,
            client: 3, // 1iOS 2Android 3WEB
            goodsInfo: {
                goodsId: 0,
                refId: "",
                lnglat: [],
                placeName: "",
                AdministrativeRegion: "",
                businessHours: "",
                businessPhone: "",
                address: "",
                photo: "",
                province: "",
                city: "",
                region: "",
                licensePhoto: ''
            },
            price: "",
            areaList: [],
            showMap: false,
            showMapPopup: false,
            showTimePicker: false,
            showExamplePopup: false,
            showExamplePopup2: false,
            error: {
                shopNameErr: "",
                addressErr: "",
                mapErr: "",
                imgErr: "",
                phoneErr: "",
                licensePhotoErr: ""
            },
            payList: ["支付宝", "微信"],
            payType: null,
            payMode: null,
            payCall: null,
            payMap: {
                支付宝: {
                    mode: "ALIPAY_MOBILE",
                    call: "aliPay",
                    unit: "￥",
                },
                微信: {
                    mode: "WX_APP",
                    call: "wxPay",
                    unit: "￥",
                },
                苹果: {
                    mode: "APPLE_IN",
                    call: "applePay",
                    unit: "￥",
                },
            },
            payTips: {
                icon: "",
                title: "",
                text: "",
            },
            payTipsMap: {
                success: {
                    icon: "/static/icon/msg_success_orange.png",
                    title: "操作成功",
                    text: "您已成功完成支付",
                },
                failed: {
                    icon: "/static/icon/more.png",
                    title: "操作失败",
                    text: "未能完成支付操作",
                },
            },
            map: null,
            geocoder: null,
            waiting: false,
            showPayment: false,
            showPayTips: false,
            confirming: false, // 正在提交中
        };
    },
    components: { UploadImg, mapMarkLocation, PayTypes },
    computed: {
        showRegion() {
            return this.goodsInfo.AdministrativeRegion || "请选择省市区";
        },
        showTime() {
            return this.goodsInfo.businessHours || "正确的营业时间，可提升地点的可靠性"
        }
    },
    mounted() {
        this.areaList = areaList;
        this.price = this.$route.query.price
        this.goodsInfo.goodsId = this.$route.query.goodsId
    },
    beforeMount() {
        // 注册支付回调
        this.JSBridge.registerHandler("paymentResult", (data) => {
            // 安卓端需要进行转义
            if (typeof data === "string") {
                data = JSON.parse(data);
            }
            if (typeof data === "object") {
                console.log(data.state, "state")
                this.waiting = false;
                switch (data.state) {
                    case 1:
                        // 支付成功
                        this.showPayment = false;
                        this.payTips = this.payTipsMap.success;
                        this.payTips.state = true;
                        this.showPayTips = true;
                        break;
                    case 5: // 兼容微信
                        break;
                    case 7: // 超时
                    case 8: // 取消
                        this.$toast("已取消支付");
                        break;
                    default:
                        // 支付失败
                        this.showPayment = false;
                        this.payTips = this.payTipsMap.failed;
                        this.payTips.state = false;
                        this.showPayTips = true;
                        console.log("支付失败", data.state, this.payTips)
                }
            } else {
                this.$toast("支付时发生错误！");
                this.waiting = false;
            }
        });
        // 设置客户端类型
        if (window.isIOS) {
            this.isIOS  = true;
            this.client = 1;
        } else if (window.isAndroid) {
            this.client = 2;
        }
    },
    methods: {
        getLocation(value) {
            let that = this;
            that.map = new AMap.Map("map-container", {
                zoomEnable: false,
                dragEnable: false,
                zoom: 14, //初始化地图层级
                center: that.goodsInfo.lnglat, //初始化地图中心点
            });
            that.geocoder = new AMap.Geocoder({
                city: "010", //城市设为北京，默认：“全国”
                radius: 1000, //范围，默认：500
            });
            that.getAddress(value)
        },
        getAddress(value) {
            this.geocoder.getAddress(new AMap.LngLat(value.lng, value.lat), (res, value) => {
                if (res === "complete") {
                    const res = value.regeocode.addressComponent;
                    this.goodsInfo.AdministrativeRegion = `${res.province}${res.city}${res.district}`;
                    this.goodsInfo.province = res.province
                    this.goodsInfo.city = res.city
                    this.goodsInfo.region = res.district
                } else {
                    this.$toast.fail("根据经纬度查询地址失败");
                }
            });
        },
        goBack(value) {
            this.JSBridge.callHandler( 'switchBackBtn', true );
            this.showMapPopup = false;
            this.goodsInfo.lnglat = [value.lng, value.lat];
            this.showMap = true;
            if(this.map) {
                this.map.panTo( this.goodsInfo.lnglat)
                this.getAddress(value)
            } else {
                LoadMap().then(() => {
                    this.getLocation(value);
                });
            }
        },
        returnHandle() {
            this.JSBridge.callHandler( 'switchBackBtn', true );
            this.showMapPopup = false;
        },
        showExample() {
            this.showExamplePopup = true
        },
        clickSubmitHandle() {
            if (this.confirming) return
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 114000, 114001);
            if(this.verificateHandle()) {
                this.confirming = true
                this.HTTP.mapLabelingSave({
                    ...this.goodsInfo,
                    lat: this.goodsInfo.lnglat && this.goodsInfo.lnglat[1],
                    lon: this.goodsInfo.lnglat && this.goodsInfo.lnglat[0],
                }).then(res => {
                    this.goodsInfo.refId = res
                    if(res) {
                        if ( this.isIOS && window._appCode >= 301 && window._appCode < 309) {
                            this.setPayType("苹果")
                            this.onTap();
                        } else {
                            this.showPayment = true;
                        }
                    }
                    this.confirming = false
                }).catch(() => {
                  this.confirming = false
                })
            };
        },
        onTap() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 114000, 114002);
            //业务流程
            this.waiting = true;
            console.log("创建订单..." + this.payMode + "====" + this.refId + "=====" + this.client);
            this.HTTP.createMap(this.goodsInfo.goodsId, this.payMode, this.goodsInfo.refId, this.client, this.$route.meta.id)
                .then((res) => {
                    console.log(res, "打印payResult")
                    console.log("创建订单...end");
                    console.log("调起支付...");
                    this.orderNo = res.orderNo;
                    const data = {
                        id: res.orderNo,
                        data: res.payResult,
                    };
                    if (window.isClient) {
                        this.JSBridge.callHandler(this.payCall, data, (st) => {
                            if (!st) {
                                this.$toast("未安装客户端，调起支付失败！");
                                setTimeout(() => {
                                    this.waiting = false;
                                }, 1000);
                                console.log(`调起支付...失败`);
                                return;
                            }
                            console.log(`调起支付...end`);
                        });
                    } else {
                        this.$toast("暂不支持网页版支付");
                        setTimeout(() => {
                            this.waiting = false;
                        }, 1000);
                    }
                })
                .catch((e) => {
                    this.$toast("创建订单时发生错误！");
                    setTimeout(() => {
                        this.waiting = false;
                    }, 1000);
                });
        },
        onTapClose() {
            if (this.payLoading) return;
            this.showPayment = false;
        },
        setPayType(value) {
            const map = this.payMap[value];
            this.payType = value;
            this.payMode = map.mode;
            this.payCall = map.call;
        },
        onConfirm() {
            if (this.payTips.state) {
                this.showPayTips = false;
                this.swRouter("/mapMark/record")
            } else {
                this.showPayTips = false;
            }
        },
        onCancelStartDate() {
            this.showTimePicker = false
        },
        verificateHandle() {
            this.error.shopNameErr = this.goodsInfo.placeName ? "" : "未填写地点名称";
            this.error.addressErr = this.goodsInfo.address ? "" : "未填写详细地址";
            this.error.mapErr = this.showMap ? "" : "未选择地图位置";
            this.error.imgErr = this.goodsInfo.photo ? "" : "未上传门头照片";
            this.error.licensePhotoErr = this.goodsInfo.licensePhoto ? "" : "未上传营业执照照片"
            const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/
            if (this.goodsInfo.businessPhone) {
                if(isPhoneNo(this.goodsInfo.businessPhone) || reg.test(this.goodsInfo.businessPhone)) {
                    this.error.phoneErr = ""
                } else {
                    this.error.phoneErr = "未填写正确的手机号码"
                }
            }
            return !this.error.shopNameErr && !this.error.addressErr && !this.error.mapErr && !this.error.imgErr && !this.error.phoneErr && !this.error.licensePhotoErr;
        },
        editPosition() {
            this.JSBridge.callHandler( 'switchBackBtn', false );
            this.showMapPopup = true;
        },
        selectTimePick() {
            this.showTimePicker = true
        },
        getImgUploadUrl2(cover) {
          const photo = cover.join(";")
          if(this.goodsInfo.licensePhoto) {
            this.goodsInfo.licensePhoto = this.goodsInfo.licensePhoto + ";" + photo
          } else {
            this.goodsInfo.licensePhoto = photo
          }
        },
        getImgUploadUrl(cover) {
            console.log(cover)
            const photo = cover.join(";")
            if(this.goodsInfo.photo) {
                this.goodsInfo.photo = this.goodsInfo.photo + ";" + photo
            } else {
                this.goodsInfo.photo = photo
            }
        },
        deleteImg(index) {
            const res = this.goodsInfo.photo.split(";")
            res.splice(index, 1)
            this.goodsInfo.photo = res.join(";")
        }
    },
};
</script>

<style  lang="scss" scoped>
@import "@/styles/index.scss";
.filling {
    width: 100vw;
    height: 100vh;
}
.filling--view {
    padding: 0 4vw;
    position: relative;
    .tips {
        background: linear-gradient(to right, rgba(234, 242, 255, 0), rgba(234, 242, 255, 1), rgba(234, 242, 255, 0));
        font-size: $font_320;
        @include flex;
        justify-content: center;
        line-height: $labelHeight;
        padding: 1.5vw 0;
        img {
            width: 2.933vw;
            height: 2.667vw;
            margin-right: 1.5vw;
        }
    }
    .content-item {
        .top {
            display: flex;
            align-items: center;
            position: relative;
            .error {
                color: #ff504e;
                font-size: $font_320;
                line-height: 4.4vw;
                margin: 2vw 0 0 4vw;
            }
            .example {
                position: absolute;
                height: 8vw;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                font-size: $font_320;
                color: $c-main;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 3.33vw;
                    height: 3.33vw;
                    margin-right: 2vw;
                }
            }
            .title {
                color: #121622;
                font-weight: bold;
                font-size: $font_400;
                line-height: 5.73vw;
                display: flex;
                align-items: center;
                margin-top: 2vw;
                span {
                    color: #8893a7;
                    font-size: $font_347;
                    margin-left: 1vw;
                }
            }
            .required {
                position: relative;
                &::after {
                    content: "*";
                    position: absolute;
                    font-size: $font_400;
                    right: -3vw;
                    color: rgba(255, 89, 0, 1);
                }
            }
        }

        .desc {
            color: #8893a7;
            font-size: $font_320;
            margin-bottom: 2.667vw;
            line-height: 4.4vw;
        }
        #map-container {
            width: 92vw;
            height: 25.33vw;
            position: relative;
            .btn {
                position: absolute;
                z-index: 2;
                width: 9.33vw;
                height: 9.33vw;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -100%);
                img {
                    width: 6.933vw;
                    height: 9.6vw;
                }
            }
        }
        .container {
            position: relative;
            width: 92vw;
            height: 25.33vw;
            background: url("/static/img/input_img_map.png");
            background-size: contain;
            background-repeat: no-repeat;
            .edit-btn {
                width: 42.667vw;
                height: 8.533vw;
                background-color: $c-main;
                position: absolute;
                @include flex;
                justify-content: center;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: $radius-xs;
                color: #fff;
                font-size: $font_293;
                .icon {
                    width: 4vw;
                    height: 4vw;
                    background-image: url("/static/icon/input_icon_location.png");
                    background-size: contain;
                    margin-right: 2vw;
                }
            }
        }
        .detail-address {
            border-radius: 1.333vw;
            border: 0.133vw solid #ebeaee;
            background: #f7f8fb;
            padding-top: 2.667vw;
            margin: 2vw 0;
            .currentRegion {
                height: 10.667vw;
                margin: 0 4vw;
                border-bottom: 0.133vw solid #e4e7ed;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .region {
                    font-size: $font_347;
                    color: #333333;
                }
                .regionEmpty {
                    color: #abb3c1;
                }
            }
            ::v-deep .van-cell {
                margin-top: 0;
                border: 0;
                padding: 0 4vw;
            }
        }
        .timepicker {
            height: 10.667vw;
            margin: 2vw 0;
            border: 0.133vw solid #e4e7ed;
            border-radius: 1.333vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #F7F8FB;
            font-size: $font_347;
            padding-left: 2vw;
            .time {
                color: #333333;
            }
            .timeEmpty {
                color: #abb3c1;
            }
        }

        ::v-deep .van-cell {
            background-color: #f7f8fb;
            padding: 0 2vw;
            margin: 2.667vw 0;
            border-radius: 1.333vw;
            border: 0.133vw solid #e4e7ed;
            .van-field__control {
                height: 10.667vw;
            }
        }
        ::v-deep .van-field__control::-webkit-input-placeholder {
            color: #abb3c1;
        }
    }
}
.subview {
    h3 {
        text-align: center;
        font-size: $font_400;
    }
    h2 {
        text-align: center;
        font-size: $font_533;
        color: #FF504E;
        margin-top: 3.2vw;
    }
    .view {
        padding: $margin 0;
    }
    ::v-deep .van-button {
        font-size: $font_400;
        line-height: normal;
    }
    .exampleImg {
        margin-top: 3vw;
    }
}
.tips {
    width: 87.2vw;
    padding: $padding;
    background-color: white;
    text-align: center;

    .icon {
        width: $iconSize;
        height: $iconSize;
        margin-top: 4.3vw;
    }
    h3 {
        font-size: $font_373;
        line-height: $font_533;
        padding-top: 7.47vw;
    }
    p {
        font-size: $font_293;
        color: $c-info;
        padding-top: 0.8vw;
    }
    .van-button--info {
        width: 59.2vw;
        margin-top: 10.93vw;
    }
    .link {
        font-size: $font_400;
        margin-top: 4.8vw;
    }
}
::v-deep .amap-icon {
    width: 6.933vw;
    height: 9.6vw;
}
::v-deep .van-overlay {
    z-index: 9999;
}
.showMap {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000000;
}
.submitFooter {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    border: 0.133vw solid #ebeaee;
    box-shadow: 0 -0.267vw 0.8vw 0 #ebeaee;
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
</style>
