<template>
    <div class="enter">
        <StateBar bgcolor="white" />
        <TitleHeader title="合伙人招募" bgcolor="white" color="black" :back="true">
        </TitleHeader>

        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <AutoView v-if="!loading" :header="true" :footer="true" class="enter--view">
            <div class="enter-bkg">
                <van-image fit="contain" src="/static/img/partner/enter-bkg.png" />
            </div>
            <div class="body">
                <van-image src="/static/img/partner/enter-body.png" lazy-load></van-image>
            </div>
            <div class="enter--footerBar">
                <img class="image-foot" src="/static/img/partner/enter-btn.png" @click="onTap" />
            </div>
        </AutoView>

        <!-- <SoltFooter class="enter--footerBar">
            <img class="image-foot" src="/static/img/partner/enter-btn.png" @click="onTap" />
        </SoltFooter> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            submitting: false
        }
    },
    methods: {
        onTap() {
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.registerPantner().then( () => {
                this.$toast( '恭喜您成为' + this.JSBridge.appName() + '合伙人！' );
                setTimeout( () => {
                    this.$router.replace( '/partner' );
                }, 2000 );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg || '未知错误' );
                    if ( e.code === 189020 ) {
                        setTimeout( () => {
                            this.$router.replace( '/partner' );
                        }, 2000 );
                    }
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        }
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.enter {
    position: relative;
    height: 100vh;
    // background-image: url(/static/img/partner/enter-bkg.png);
    // background-repeat: no-repeat;
    // background-size: contain;
    background-color: #FFCF5A;
    .enter--view {
        position: relative;
        height: calc(100% - #{$headerHeight} - 21vw);
        .enter-bkg {
            position: absolute;
            width: 100%;
            top: -15vw;
        }
        .body {
            position: relative;
            margin-top: 75vw;
            padding: 0 4vw 8vw 4vw;
        }
    }
    .enter--footerBar {
        position: fixed;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(255, 238, 181, 0) 0%, #FFE798 100%);
        box-shadow: 0px -3px 15px 0px rgba(255,207,90,0.8);
        height: 21vw;
        .image-foot {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }
}
</style>
