<template>
    <div class="course">
        <!-- <StateBar bgcolor="white" /> -->
        <!-- <TitleHeader :title="title" color="#1D2233" :back="false"></TitleHeader> -->
        <AutoView :header="false" :footer="true" class="course--view">
            <div class="course-header">
                <div class="header-left">
                    <img class="header-img" :src="courseInfo.cover" />
                </div>
                <div class="header-right">
                    <div class="header-line1">{{courseInfo.name}}</div>
                    <div class="header-line2">
                        <span class="new-price">{{courseInfo.showPrice}}</span>
                        <span class="old-price">{{courseInfo.salePrice}}</span>
                    </div>
                </div>
            </div>
            <div class="division-line"></div>
            <div class="tab-wrap">
                <div class="tab-item" v-for="item in options.tabs" :key="item.key" @click="tabChange(item.key)">
                    <div class="tab-title" :class="{active:tabIndex==item.key}">
                        <span>{{item.text}}</span>
                        <div v-show="tabIndex==item.key" class="tab-item-underline"></div>
                    </div>
                </div>
            </div>
            <div class="course-body">
                <div class="course-item" v-show="tabIndex==1">
                    <div class="course-content" v-html="courseInfo.introduce"></div>
                </div>
                <div class="course-item" v-show="tabIndex==2">
                    <div class="catalog" v-for="item in courseInfo.itemList" :key="item.id">
                        <div class="catalog-title">{{item.title}}</div>
                        <div class="catalog-content">
                            <span class="catalog-time">时长 {{item.time}}</span>
                            <template v-if="item.isFree==1">
                                <span class="catalog-free">可试看</span>
                            </template>
                            <template v-else>
                                <img class="catalog-lock" src="/static/icon/icon_lock@2x.png" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </AutoView>
        <!-- 定制底栏 -->
        <SoltFooter class="course--footerBar">
            <van-row class="view">
                <!-- <van-col span="11">
                    <van-button type="warning" block plain class="icon icon-detail" @click="onExample()">&emsp;示例报告</van-button>
                </van-col> -->
                <!-- <van-col span="2"></van-col> -->
                <van-col span="24" style="position: relative">
                    <van-button type="warning" block @click="onTap">立即购买</van-button>
                    <p class="float-label">已有{{ courseInfo.getNum }}人购买</p>
                </van-col>
            </van-row>
        </SoltFooter>
    </div>
</template>

<script>
import TabBar from '@/components/widget/tabBar';
export default {
    components: {
        TabBar
    },
    props: {

    },
    data () {
        return {
            options: {
                tabs: [
                    { key: 1, text: '课程介绍' },
                    { key: 2, text: '课程目录' },
                ]
            },
            tabIndex  : 1,
            title     : '',
            courseInfo: {
                name     : '',
                introduce: '',
                cover    : '',
                oldPrice : 0,
                newPrice : 0,
                getNum   : 2008,
                itemList : [
                    {
                        id       : 75424473305872,
                        title    : '对对对',
                        time     : '00:12',
                        isFree   : true,
                        gmtCreate: '2021-01-14 15:02:58'
                    },
                    {
                        id       : 75424473305873,
                        title    : '好好好',
                        time     : '00:15',
                        isFree   : null,
                        gmtCreate: '2021-01-14 15:02:58'
                    },
                    {
                        id       : 75424473305874,
                        title    : '哈哈哈哈啊哈哈',
                        time     : '00:22',
                        isFree   : false,
                        gmtCreate: '2021-01-14 15:02:58'
                    }
                ],
            },
        };
    },
    created () {
        const id = this.$route.params.id;
        if ( ! id ) {
            this.$toast( '无效课程信息，即将返回首页！' );
            this.$router.push( '/' );
            return;
        }
        this.HTTP.shareCourse( id ).then( ( res ) => {
            this.load( res );
        } ).catch( ( e ) => {
            this.$toast( '网络不稳定，请稍候重试！' );
        } );
    },
    methods: {
        tabChange ( key ) {
            console.log( 'tabChange:', key );
            this.tabIndex = key;
        },
        onTap () {
            top.location.href = this.HTTP.getAppStore();
        },
        load ( data ) {
            console.log( 'load data:', data );
            if ( typeof res === 'string' ) {
                data = JSON.parse( data );
            }
            this.title = data.title || '参谋精品课';
            this.courseInfo.name = data.name;
            this.courseInfo.cover = data.cover;
            this.courseInfo.salePrice = data.salePrice;
            this.courseInfo.showPrice = data.showPrice;
            this.courseInfo.introduce = data.introduce;
            this.courseInfo.itemList = data.itemList;
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.course {
    position: relative;
    width: 100%;
    .course-header {
        position: relative;
        padding: 4.8vw 6.4vw ;
        display: flex;
        align-items: center;
        .header-left {
            position: relative;
            .header-img {
                width: 43.2vw;
                height: 24.53vw;
            }
        }
        .header-right {
            position: relative;
            margin-left: 3.47vw;
            .header-line1 {
                color: #1F2435;
                font-size: 4.27vw;
                font-weight: bold;
                line-height: 6.13vw;
                text-align: left;
            }
            .header-line2 {
                position: relative;
                margin-top: 4.8vw;
                .new-price {
                    color: #F28756;
                    font-size: 4.27vw;
                    font-weight: bold;
                    line-height: 6.13vw;
                    text-align: left;
                }
                .old-price {
                    color: #B4B4B4;
                    font-size: 2.93vw;
                    font-weight: normal;
                    line-height: 4vw;
                    text-align: left;
                    margin-left: 1.6vw;
                    text-decoration: line-through;
                }
            }
        }
    }
    .division-line {
        position: relative;
        width: 100%;
        height: 2.93vw;
        background-color: #F8F8F8;
    }
    .tab-wrap {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 12.8vw;
        border-bottom: 1px solid #E8E8E8;
        .tab-item {
            position: relative;
            .tab-title {
                position: relative;
                padding: 3.47vw 4vw;
                color: #6D7289;
                font-size: 3.73vw;
                font-weight: normal;
                line-height: 5.33vw;
                white-space: nowrap;
                cursor: pointer;
                &.active {
                    color: #518CDB;
                    font-weight: bold;
                }
            }
            .tab-item-underline {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                border-radius: 1px;
                background-color: #518CDB;
            }
        }
    }
    .course-body {
        position: relative;
        width: 100%;
        .course-item {
            position: relative;
            width: 100%;
            .course-content {
                position: relative;
                padding: 6.4vw 4.8vw;
                width: 100%;
                box-sizing: border-box;
            }
            .catalog {
                position: relative;
                padding: 4.8vw 8vw;
                border-bottom: 1px solid #E8E8E8;
                background-color: #FEFEFF;
                .catalog-title {
                    color: #1F2435;
                    font-size: 3.73vw;
                    font-weight: bold;
                    line-height: 5.33vw;
                    text-align: left;
                }
                .catalog-content {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 1.87vw;
                    .catalog-time {
                        color: #9F9FA0;
                        font-size: 3.2vw;
                        line-height: 4.53vw;
                        text-align: left;
                    }
                    .catalog-free {
                        color: #5C93DC;
                        font-size: 3.2vw;
                        line-height: 4.53vw;
                        text-align: left;
                    }
                    .catalog-lock {
                        width: 2.93vw;
                        height: 3.2vw;
                    }
                }
            }
        }
    }
    .course--footerBar {
        z-index: 3;
        box-shadow: 0 -.5vw 2vw rgba(black, .05);
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-col {
                height: 100%;
            }
        }
        .label {
            color: $c-info;
            font-size: $font_267;
            left: $font_373;
            padding-top: .5vw;
        }
        .price {
            font-size: $font_480;
            line-height: $font_653;
            font-weight: bold;
            color: #C46321;

            sub {
                vertical-align: bottom;
                font-size: $font_320;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            font-weight: bold;
            line-height: normal;
        }
        .icon-detail {
            background: url(/static/icon/detail_orange.png) no-repeat left 25% center;
            background-size: $buttonSize $buttonSize;
        }
        .float-label {
            position: absolute;
            @include flexColumn;
            line-height: normal;
            background: linear-gradient(to right, #2989EF, #61C7FE);
            height: 4.5vw;
            padding: 0 2vw;
            color: white;
            font-size: $font_267;
            top: 0;
            left: 50%;
            border-radius: $radius-xs $radius-xs $radius-xs 0;
            margin-top: -2.5vw;
            margin-left: 4vw;
        }
    }
}
</style>
<style lang="scss">
.course-content {
    >p {
        color: #303133;
        font-size: 13px;
        line-height: 1.42;
        text-align: left;
        white-space: pre-wrap;
        tab-size: 4;
        box-sizing: border-box;
        >img {
            border: 0;
            outline: none;
            max-width: 100%;
            box-sizing: border-box;
            cursor: text;
        }
    }
}
</style>
