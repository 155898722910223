<template>
  <div class="container">
    <template v-if="[2, 3].includes(status) || oldFlag">
      <div class="coupon" :class="['st-' + status, { checked: showCheck && checked }]" @click="selected">
        <h3 class="f-tof">{{ item.couponName }}</h3>
        <p class="f-tof">有效期 {{ item.validityStartTime | convDate }} - {{ item.validityEndTime | convDate }}</p>
        <div v-if="item.couponDiscount" class="num">
          <sub v-if="item.showType === 2">¥</sub>{{ item.couponDiscount }}<sub v-if="item.showType === 1">折</sub>
        </div>
      </div>
    </template>
    <template v-if="status == 1 && !oldFlag">
      <div class="new-coupon" :class="{ notTips: !item.rule }" @click="goToApp">
        <ul class="coupon-flex">
          <li class="li-left">
            <h4 class="h4 f-tof">{{ item.couponName }}</h4>
            <p class="p-tips">有效期 {{ item.validityStartTime | convDate }} - {{ item.validityEndTime | convDate }}</p>
          </li>
          <li class="li-right">
            <sub v-if="item.showType === 2">¥</sub>
            <span>{{ item.couponDiscount }}</span>
            <sub v-if="item.showType === 1">折</sub>
            <p class="bg-btn">去使用</p>
          </li>
        </ul>
      </div>
      <div class="collapse-wrap" v-if="item.rule">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="使用规则" :border="false">
            {{ item.rule }}
          </van-collapse-item>
        </van-collapse>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "coupon",
  props: {
    item: Object,
    status: {
      type: Number,
      default: 1,
    },
    checked: Boolean,
    showCheck: {
      type: Boolean,
      default: true,
    },
    oldFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeNames: [],
    };
  },
  filters: {
    convDate: function (value) {
      if (!value) return "???";
      return value.split(" ")[0].split("-").join(".");
    },
  },
  methods: {
    selected() {
      this.$emit("selected", this.item);
    },
    goToApp() {
      console.log(this.item.link);
      if (window._appCode && window._appCode < 205) {
        return this.$toast("您的版本过低，请升级至最新版本");
      }
      this.JSBridge.callHandler("onNormalizingCall", this.item.link);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.container {
  width: 100%;
  .new-coupon {
    height: 100%;
    background: #ffe1d1;
    padding: 5.33vw;
    border-left: 1px solid #ffe1d1;
    border-right: 1px solid #ffe1d1;
    border-top: 1px solid #ffe1d1;
    border-top-left-radius: 0.8vw;
    border-top-right-radius: 0.8vw;
    .coupon-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .li-left {
        flex: 1;
        .h4 {
          height: 6.4vw;
          line-height: 6.4vw;
          font-size: 4.53vw;
          color: #333;
          font-weight: bold;
          max-width: 58.67vw;
        }
        .p-tips {
          font-size: 3.2vw;
          color: #666;
          margin-top: 2.67vw;
        }
      }
      .li-right {
        flex: 0 0 20.8vw;
        font-size: 3.73vw;
        color: #ff5900;
        font-weight: bold;
        text-align: center;
        sub {
          vertical-align: baseline;
        }
        span {
          font-size: 5.33vw;
          font-weight: bold;
        }
        .bg-btn {
          width: 15.47vw;
          border: 0.27vw solid #ff5900;
          padding: 1.07vw 0;
          border-radius: 3.2vw;
          margin: 2.13vw auto 0;
        }
      }
    }
  }
  .collapse-wrap {
    border-left: 1px solid #ffe1d1;
    border-right: 1px solid #ffe1d1;
    border-bottom: 1px solid #ffe1d1;
    border-bottom-left-radius: 0.8vw;
    border-bottom-right-radius: 0.8vw;
  }
  .notTips {
    border-bottom-left-radius: 0.8vw;
    border-bottom-right-radius: 0.8vw;
    border-bottom: 1px solid #ffe1d1;
  }

  .coupon {
    position: relative;
    width: 100%;
    min-width: 87.2vw;
    height: 25vw;
    background: url(/static/img/coupon.png) no-repeat left top;
    background-size: 100% 100%;

    &.st-2 {
      background-image: url(/static/img/coupon_used.png);
      h3,
      p,
      .num {
        color: rgba($c-text, 0.4);
      }
    }
    &.st-3 {
      background-image: url(/static/img/coupon_dis.png);
      h3,
      p,
      .num {
        color: rgba($c-text, 0.4);
      }
    }
    &.checked {
      background-image: url(/static/img/coupon_checked.png);
    }

    h3 {
      position: absolute;
      top: 6.8vw;
      width: 76%;
      padding: 0 7.47vw;
      box-sizing: border-box;
      font-size: 4.53vw;
      line-height: 6vw;
    }
    p {
      position: absolute;
      bottom: 6.8vw;
      width: 100%;
      padding: 0 7.47vw;
      box-sizing: border-box;
      font-size: $font_320;
      line-height: $font_400;
      color: $c-info;
      opacity: 0.8;
    }
    .num {
      position: absolute;
      top: 9vw;
      right: 0;
      width: 26vw;
      color: #ea5336;
      text-align: center;
      font-size: 6.4vw;
      font-weight: bold;

      sup {
        font-size: $font_320;
        vertical-align: top;
      }
      sub {
        font-size: $font_320;
        vertical-align: bottom;
      }
    }
  }
  ::v-deep .van-cell {
    background: #fff3ee;
  }
  ::v-deep .van-collapse-item__content {
    background: #fff3ee;
  }
}
</style>
