<template>
  <div class="float-img">
    <ul class="sign-flex">
      <li class="sign-flex-left">价值<strong>1128</strong>元<br />限量福利</li>
      <li class="sign-flex-right">累计7天签到，前50名免费送神秘大礼包</li>
    </ul>
    <div class="component-main">
      <div class="line-day">
        已累计签到 <span class="border-input">{{ userActiveRes.total }}</span> 天
      </div>
      <p class="line-explain" v-if="userActiveRes.next">
        累计签到{{ userActiveRes.total }}日，再签到<span class="i">{{ userActiveRes.next }}天</span>可领取福利
      </p>
      <p class="line-explain" v-else>恭喜您已完成所有签到，快去使用奖励吧！</p>

      <div class="line-wrap">
        <div class="base-wire"></div>
        <div class="base_progress" :style="{ width: computedWid + '%' }"></div>
        <div
          :class="{
            'day-gold': idx % 2 == 0,
            'day-small': idx % 2 == 1 && userActiveRes.total > idx,
            'day-big': idx % 2 == 1 && userActiveRes.total < idx + 1,
          }"
          v-for="(item, idx) in userActiveRes.activeStatusResList"
          :key="item.key"
        >
          <template v-if="idx % 2 == 0">
            <div
              :class="{
                ingot: item,
                'first-poit': idx == 0,
                'third-poit': idx == 2,
                'fifth-poit': idx == 4,
                'seventh-poit': idx == 6,
              }"
              :style="{
                'background-image':
                  userActiveRes.total > idx
                    ? 'url(' + '/static/img/signin/signin_ingot.png' + ')'
                    : 'url(' + '/static/img/signin/signin_ingot_has.png' + ')',
              }"
              @click="confirmPopup(idx, item)"
            >
              <span>{{ item.limit }}日</span>
            </div>
          </template>

          <template v-if="idx % 2 == 1">
            <div
              :class="{
                radius: item,
                'second-poit': idx == 1,
                'four-poit': idx == 3,
                'sixth-poit': idx == 5,
              }"
              :style="{
                width: userActiveRes.total > idx ? '4.27vw' : '1.33vw',
                height: userActiveRes.total > idx ? '4.27vw' : '1.33vw',
              }"
            ></div>
          </template>
        </div>
      </div>
      <div class="btn-shade" :class="{ gay: status == 3 }" @click="clockSubmit">
        <span v-if="!submitLoading">{{ computedBtnName }}</span>
        <span v-else class="btn-loading"><van-loading color="#ffbf78" /></span>
      </div>
    </div>

    <!-- 领券弹框 -->
    <van-popup v-model="signinSuccess" round closeable>
      <div class="signin-popup-wrap">
        <template v-if="couponInfo.goodsRespList && couponInfo.goodsRespList.length">
          <div class="type-prize">
            <ul class="prize-flex">
              <li class="prize-li-left"><img src="/static/img/signin/signin-checked.png" alt="" /></li>
              <li class="prize-li-right">
                <p class="title-prize">签到成功</p>
                <p class="explain-prize">{{ couponInfo.title }}</p>
              </li>
            </ul>
            <div class="flex-card">
              <ul class="coupon-card" v-for="item in couponInfo.goodsRespList" :key="item.key">
                <li class="coupon-card-left">
                  <p class="top-title">{{ item.goodsName }}</p>
                  <!-- <p class="bottom-time">有效期：2021.06.10-2021.06.14</p> -->
                </li>
                <li class="coupon-card-right">
                  <p class="coupon-type">{{ item.couponDesc }}</p>
                  <p class="coupon-btn" @click="swRouter('/coupons?keepAlive=1')">
                    <span>去查看</span>
                  </p>
                </li>
              </ul>
            </div>
            <p class="prize-success">{{ couponInfo.tips }}</p>
            <p class="prize-fail" v-if="couponInfo.awardDesc">{{ couponInfo.awardDesc }}</p>
          </div>
        </template>
        <template v-else>
          <div class="type-noprize">
            <img src="/static/img/signin/signin-checked.png" alt="" />
            <p class="title-noprize">签到成功</p>
            <p class="explain-noprize">记得明日继续签到领取奖励哦！</p>
            <p class="prize-fail" v-if="couponInfo.awardDesc">{{ couponInfo.awardDesc }}</p>
          </div>
        </template>
      </div>
    </van-popup>

    <van-popup v-model="couponPopup" round closeable>
      <div class="signin-popup-wrap signin-popup-margin">
        <div class="type-prize">
          <div class="flex-card">
            <ul class="coupon-card" v-for="item in goodsRespList" :key="item.key">
              <li class="coupon-card-left">
                <p class="top-title">{{ item.goodsName }}</p>
              </li>
              <li class="coupon-card-right">
                <p class="coupon-type">{{ item.couponDesc }}</p>
                <p class="coupon-btn" @click="swRouter('/coupons?keepAlive=1')">
                  <span>去查看</span>
                </p>
              </li>
            </ul>
          </div>
          <p class="prize-success">可至我的-优惠券查看已领取的优惠券</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    userActiveRes: {
      type: Object,
      default: () => {},
    },
    goodsList: {
      type: Array,
      default: () => [],
    },
    status: {
      //活动状态 1：正常 2：未开始 3：已结束
      type: [Number, String],
      default: 1,
    },
    computedWid: {
      type: Number,
      default: 0,
    },
    activeId: {
      type: [Number, String],
      default: 2,
    },
  },
  data() {
    return {
      submitLoading: false,
      signinSuccess: false, // 签到弹框
      couponInfo: {},
      couponPopup: false,
      flagIdx: 0,
    };
  },
  computed: {
    computedBtnName() {
      if (this.status == 2) {
        return "活动未开始";
      } else if (this.status == 3) {
        return "活动已结束";
      } else if (this.userActiveRes.total == 7) {
        return "您已完成所有签到";
      } else if (this.userActiveRes.total == 6) {
        return "距大礼仅剩一次签到";
      } else {
        return this.userActiveRes.userStatus == 0 ? "签到打卡" : "今日已签到 明日再来";
      }
    },
    goodsRespList() {
      return this.goodsList[this.flagIdx] && this.goodsList[this.flagIdx].goodsRespList;
    },
  },
  methods: {
    confirmPopup(idx, data) {
      if (data.userFlag) {
        idx == 0 && (this.flagIdx = 0);
        idx == 2 && (this.flagIdx = 1);
        idx == 4 && (this.flagIdx = 2);
        idx == 6 && (this.flagIdx = 3);
        this.couponPopup = true;
      }
    },
    clockSubmit() {
      if (this.submitLoading) {
        return;
      }
      if (this.status == 2) {
        return this.$toast("活动未开始，敬请期待");
      }
      if (this.status == 3) {
        return this.$toast("活动已结束");
      }
      if (this.userActiveRes.total > 6) {
        return this.$toast("您已完成所有签到了");
      }
      if (this.userActiveRes.userStatus != 0) {
        return this.$toast("您今天已经签到了");
      }
      this.submitLoading = true;
      this.HTTP.sign(this.activeId)
        .then((res) => {
          console.log(res);
          this.submitLoading = false;
          this.couponInfo = res.data || {};
          this.signinSuccess = true;
          this.$emit("loadData");
        })
        .catch((e) => {
          this.submitLoading = false;
          this.$toast("网络不稳定，请稍候重试！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.float-img {
  position: relative;
  top: -9.07vw;
  left: 0;
  height: 69.87vw;
  background: url("/static/img/signin/signin_top.png") no-repeat center;
  background-size: cover;
  .sign-flex {
    display: flex;
    align-items: center;
    height: 18.13vw;
    &-left {
      flex: 0 0 31.47vw;
      font-size: 3.73vw;
      font-weight: bold;
      color: #ff5900;
      line-height: 4.8vw;
      text-align: center;
    }
    &-right {
      flex: 1;
      font-size: 3.2vw;
      font-weight: 500;
      color: #ffe289;
      margin-left: 2.13vw;
    }
  }
  .component-main {
    padding: 0 3.2vw;
    text-align: center;
    .line-day {
      height: 9.6vw;
      line-height: 9.07vw;
      font-size: 4.27vw;
      color: #333;
      font-weight: bold;
      letter-spacing: 1px;

      .border-input {
        display: inline-block;
        width: 8vw;
        height: 9.07vw;
        font-size: 6.13vw;
        color: #ff5900;
        background: rgba(255, 206, 143, 0.16);
        border-radius: 0.8vw;
        border: 0.53vw solid #ff9f6b;
      }
    }
    .line-explain {
      font-size: 3.73vw;
      color: #666;
      margin-top: 3.2vw;
      font-weight: bold;
      .i {
        color: #ff5900;
      }
    }
    .line-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 80.8vw;
      height: 13.33vw;
      margin: 0 auto;
      .base-wire {
        width: 80.8vw;
        height: 2.13vw;
        background: linear-gradient(180deg, #fef0b4 0%, #fff2c3 100%);
        border-radius: 3px;
      }
      .base_progress {
        position: absolute;
        left: 0;
        // width: 10%;
        height: 2.13vw;
        background: url("/static/img/signin/signin_progress.png") no-repeat;
        background-size: cover;
        border-radius: 0.8vw;
        transition: width 3s;
      }
      .day-gold {
        position: absolute;
        left: 0;
        top: 12px;
      }
      .day-small {
        position: absolute;
        left: 0;
        top: 18px;
      }
      .day-big {
        position: absolute;
        left: 0;
        top: 23px;
      }
      .ingot {
        position: absolute;
        width: 6.93vw;
        height: 6.93vw;
        background-size: cover;
        span {
          position: relative;
          top: 5.33vw;
          font-size: $font_267;
          color: #999;
        }
      }
      .radius {
        position: absolute;
        background: #ffbf78;
        border-radius: 50%;
      }
      .first-poit {
        left: 5.07vw;
      }
      .second-poit {
        left: 18.67vw;
      }
      .third-poit {
        left: 29.07vw;
      }
      .four-poit {
        left: 43.2vw;
      }
      .fifth-poit {
        left: 53.07vw;
      }
      .sixth-poit {
        left: 67.2vw;
      }
      .seventh-poit {
        left: 77.07vw;
      }
    }
    .btn-shade {
      width: 58.67vw;
      height: 10.67vw;
      line-height: 10.67vw;
      background: linear-gradient(90deg, #fdb824 0%, #ff5900 100%);
      border-radius: 5.33vw;
      font-size: 4vw;
      color: #fff;
      font-weight: bold;
      margin: 4.27vw auto 0;
      letter-spacing: 1px;
      animation: breath-profile 1.5s ease-in-out infinite alternate;
      .btn-loading {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .gay {
      background: #999 !important;
    }
  }
  .signin-popup-margin {
    margin: 12vw 0 !important;
  }
  .signin-popup-wrap {
    position: relative;
    padding: 0 8vw;
    margin: 4vw 0;
    text-align: center;
    width: 72vw;
    .type-noprize {
      text-align: center;
      padding: 46px 0;
      img {
        width: 70px;
        height: 70px;
      }
      .title-noprize {
        font-size: 17px;
        color: #ff5900;
        font-weight: bold;
        margin-top: 12px;
      }
      .explain-noprize {
        font-size: 13px;
        color: #ff8d3f;
        margin-top: 8px;
      }
      .prize-fail {
        font-size: 3.2vw;
        color: #ff5900;
        margin-top: 3.2vw;
        text-align: left;
        line-height: 4.8vw;
        padding: 0 3.2vw;
      }
    }
    .type-prize {
      padding: 2.67vw 0;
      .prize-flex {
        display: flex;
        align-items: center;
        margin-bottom: 2.13vw;
        .prize-li-left {
          flex: 0 0 18vw;
          text-align: center;
          img {
            width: 12vw;
            height: 12vw;
          }
        }
        .prize-li-right {
          flex: 1;
          text-align: left;
          .title-prize {
            font-size: 4.53vw;
            color: #ff5900;
            font-weight: bold;
          }
          .explain-prize {
            font-size: 3.2vw;
            color: #ff8d3f;
            margin-top: 2.13vw;
          }
        }
      }
      .flex-card {
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        .coupon-card {
          display: flex;
          align-items: center;
          font-size: $font_293;
          color: #fff;
          height: 18.13vw;
          background: url("/static/img/signin/signin_coupon.png") no-repeat;
          background-size: 100% 100%;
          margin: 1.33vw 0;
          padding-left: 3.73vw;

          &-left {
            flex: 1;
            text-align: left;
            .top-title {
              font-size: 3.73vw;
              font-weight: bold;
            }
            .bottom-time {
              margin-top: 2.13vw;
            }
          }
          &-right {
            flex: 0 0 20.8vw;
            .coupon-type {
              font-size: 3.73vw;
              color: #fff;
              font-weight: bold;
            }
            .coupon-btn {
              margin-top: 1.6vw;
              span {
                display: inline-block;
                width: 12.8vw;
                height: 5.87vw;
                line-height: 5.87vw;
                font-size: $font_293;
                color: #c65000;
                background: #ffdb2f;
                border-radius: 2.67vw;
              }
            }
          }
        }
      }
      .prize-success {
        font-size: 3.73vw;
        color: #c65000;
        font-weight: bold;
        margin-top: 4vw;
      }
      .prize-fail {
        font-size: 3.2vw;
        color: #ff5900;
        margin-top: 3.2vw;
        text-align: left;
        line-height: 4.8vw;
        padding: 0 3.2vw;
      }
    }
  }

  @keyframes breath-profile {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  ::v-deep .van-popup {
    background: linear-gradient(180deg, #ffeeb5 0%, #ffe798 100%);
  }
}
</style>
