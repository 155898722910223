<template>
	<div class="transfer-list">
		<StateBar />
		<TitleHeader :title="title" bgcolor="transparent" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;"></TitleHeader>
		<AutoView :footer="false">
			<div class="list-box">
				<van-list v-model="loading" :finished="finished" finished-text="" @load="getList">
					<div class="list-item" @click="gotoDetails(item)" v-for="(item,key) in dataList" :key="key">
						<div class="list-item-left">
							<div class="item-title">{{item.serveTypeName}}</div>
							<div class="item-time">提交时间：{{item.gmtCreate}}</div>
						</div>
						<div class="list-item-right">
							{{item.status}}
						</div>
					</div>
				</van-list>
			</div>
			<van-empty
				style="position: absolute;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);width: 100%;"
				v-if="!dataList.length" description="暂无数据" />
		</AutoView>
	</div>
</template>

<script>
	import {
		getIntentionListPage
	} from "@/request/shopEcology"
	export default {
		data() {
			return {
				title: '我的转让意向',
				form: {
					current: 1,
					size: 20,
					types: [
						2
					]
				},
				dataList: [],
				loading: false,
				finished: false,
			}
		},
		methods: {
			getList() {
				getIntentionListPage(this.form).then(res => {
					this.dataList = this.dataList.concat(res.data||[])
					// 加载状态结束
					this.loading = false;
					// 数据全部加载完成
					if (this.dataList.length == res.total) {
						this.finished = true;
					} else {
						++this.form.current
					}
				})
			},
			gotoDetails(item) {
				this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 134200, 134201);
				this.swRouter({
					 path: '/shopEcology/transferDetails',
					query: {
						id: item.id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.transfer-list {
		background: #fff;
		min-height: 100vh;

		.list-box {
			padding: vw(30);
			box-sizing: border-box;

			.list-item {
				border-radius: vw(8);
				padding: vw(24) vw(30);
				box-sizing: border-box;
				display: flex;
				height: vw(134);
				margin-top: vw(20);
				background: #F7F8FB;
				border-radius: vw(10);

				.list-item-left {
					flex: 1;

					.item-title {
						height: vw(40);
						font-size: vw(30);
						font-weight: 600;
						line-height: vw(40);
						color: #1D2233;
					}

					.item-time {
						height: vw(36);
						font-size: vw(24);
						line-height: vw(36);
						color: #8893A7;
						margin-top: vw(8);
					}
				}

				.list-item-right {
					font-size: vw(28);
					font-weight: 600;
					color: #347FFF;
					line-height: vw(40);
				}
			}
		}
	}
</style>
