<template>
    <div class="wrapper">
        <div  class="content">
            <img src="/static/sem/state_img_success.png" alt="">
            <div class="desc" v-if="isReceived == 0">领取成功</div>
            <div class="desc" v-else>该手机号领取过啦～</div>
            <div class="bold">快来下载体验吧</div>
            <div class="btn" @click="downLoad">立即下载</div>
            <div class="copy">
                <p>开发者:深圳市数位汇聚科技有限公司 <span @click="agreementClick('privacypolicy')">隐私协议</span> <span @click="agreementClick('useragreement')">用户协议</span></p>
                <p>版本号:{{versionInfo.versionNumber}} 更新时间:{{versionInfo.gmtModify}}</p>
            </div>
        </div>
        <div class="mask" v-if="showMask" @click="cancleMask">
            <img src="/static/sem/mask.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isReceived: 0,
            isWX: false,
            showMask: false,
            versionInfo: {
                gmtModify: '',
                downAddress: '',
                privacyAgreement: '',
                userAgreement: '',
                versionNumber: ''
            }
        }
    },
    mounted() {
        this.isReceived = this.$route.query.isReceived
        this.getVersionInfo()
        this.getEnviroment()
    },
    methods: {
        getEnviroment() {
            const environmental = window.navigator.userAgent.toLowerCase();
            this.isWX = environmental.match(/MicroMessenger/i)[0] === 'micromessenger'
        },
        getVersionInfo() {
            this.HTTP.getVersionInfo({
                launchChannel: 'sem'
            }).then(res => {
                this.versionInfo = res.data
                this.versionInfo.gmtModify = res.data.gmtModify.split(' ')[0]
            })
        },
        downLoad() {
            if(this.isWX) {
                this.showMask = true
                return
            }
            if(window.isIOS) {
                window.location.href = this.HTTP.getAppStore()
            } else {
                window.location.href = this.versionInfo.downAddress
            }
        },
        cancleMask() {
            this.showMask = false
        },
        agreementClick(type) {
            if(type == 'privacypolicy') {
                window.location.href = this.versionInfo.privacyAgreement
            } else {
                window.location.href = this.versionInfo.userAgreement
            }
        },
    }
}
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import "@/styles/index.scss";
.wrapper {
    height: 100vh;
}
.content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    &::before {
        content: "";
        width: vw(92);
        height: vw(208);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-image: url('/static/sem/left-circle.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    &::after {
        content: "";
        width: vw(160);
        height: vw(342);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-image: url('/static/sem/right-circle.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    img {
        width: vw(200);
        height: vw(200);
        margin-top: vw(300);
    }
    .desc {
        font-size: $font_373;
        font-weight: bold;
        color: $c-text;
        margin: vw(36) 0 vw(54);
    }
    .bold {
        font-weight: vw(48);
        color: #2C92FE;
        font-weight: bold;
        position: relative;
        &::before {
            content: "";
            width: vw(74);
            height: vw(12);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: vw(-100);
            background-image: url('/static/sem/before.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
        &::after {
            content: "";
            width: vw(74);
            height: vw(12);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: vw(-100);
            background-image: url('/static/sem/after.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .btn {
        width: vw(590);
        height: vw(104);
        font-family: 'CN Heavy Bold';
        border-radius: vw(52);
        color: #fff;
        background-image: linear-gradient(#1177FE, #4EB5FF);
        box-shadow: 0 vw(8) vw(18) 0 #B0DAFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: vw(298);
    }
    .copy {
        bottom: vw(32);
        position: absolute;
        p {
            font-size: vw(20);
            line-height: vw(28);
            text-align: center;
            color: #666666;
            span {
                color: $c-main;
            }
        }
    }
}
.mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.8);
    img {
        width: vw(600);
        height: vw(490);
        position: absolute;
        right: vw(24);
        top: vw(80);
    }
}
</style>
