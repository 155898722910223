<template>
  <div class="titleHeader" :style="{ color: color, backgroundColor: bgcolor }">
    <div class="view view--l">
      <slot name="left" />
    </div>
    <div class="view">
      <p class="title" v-if="title">{{ title }}</p>
      <slot name="title" />
    </div>
    <div class="view view--r">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "titleHeader",
  props: {
    title: String,
    color: String,
    bgcolor: {
      type: String,
      default: "white",
    },
    back: {
      // 是否显示后退按钮
      type: Boolean,
      default: false,
    },
  },
  watch: {
    back: {
      immediate: true,
      handler(val) {
        this.JSBridge.callHandler("switchBackBtn", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.titleHeader {
  position: relative;
  @extend .l-header;
  padding: 0 18vw;
  // flex-shrink: 0;
  // box-shadow: inset 0 calc( #{$headerHeight * 0.5} - 1px ) 0 1px white, inset 0 $headerHeight * 0.5 0 1px red;
  // background-color: pink!important;
  .search {
    // display: flex;
    // justify-content: center;
    // align-self: center;
    width: 100%;
    height: 15.5vw;
  }

  .view {
    @extend .l-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    height: 100%;

    .title {
      @include singleLine;
      font-weight: bold;
      font-size: $titleSize;
      padding: 0.5vw 0;
    }
    &--l {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      margin-left: $padding;
      width: auto;
      font-size: $font_347;
      color: $c-info;
    }
    &--r {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      margin-right: $padding;
      width: auto;
      font-size: $font_347;
      color: $c-info;
    }
  }

  &.no-mr {
    .view--r {
      margin-right: 0;
    }
  }
}
</style>
