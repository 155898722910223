<template>
    <div class="wraper-box">
        <li class="line">
            <span class="wrap-legend"></span>
            <span class="wrap-label">{{ title }}</span>
        </li>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'wraper',
    props: {
        title: {
            type: String,
            default: "",
        }
    },
    data () {
        return {

        };
    },

};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.wraper-box{
    width: 100%;
    height: auto;
    padding: 4vw;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 1.33vw;
    margin-bottom: 2.67vw;
    font-size: 0;
    .line{
        display: inline-flex;
        align-items: center;
        .wrap-legend {
            position: relative;
            width: 1.07vw;
            height: 3.73vw;
            border-radius: 0.53vw;
            background-color: #347fff;
        }
        .wrap-label {
            position: relative;
            margin-left: 1.33vw;
            color: #1D2233;
            font-size: 4vw;
            font-weight: bold;
            text-align: left;
            word-break: keep-all;
            line-height: normal;
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
}
</style>
