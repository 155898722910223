<template>
    <div class="widget" :class="theme">
        <i class="icon icon-share" />
    </div>
</template>

<script>
export default {
    name : 'shareMarker',
    props: {
        theme: {
            type   : String,
            default: 'black',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_value.scss';

.widget {
    width: $headerHeight;
    height: $headerHeight;
    box-sizing: border-box;
    padding: $padding / 2;

    .icon {
        display: block;
        width: 100%;
        height: 100%;

        background: url() no-repeat center center;
        background-size: $padding $padding;
    }
    .icon-share {
        background-image: url(/static/icon/share_black.png);
    }

    &.white {
        .icon-share {
            background-image: url(/static/icon/share.png);
        }
    }
}
</style>
