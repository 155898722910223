<template>
  <div class="coupons">
    <StateBar></StateBar>
    <TitleHeader title="我的优惠券" bgcolor="transparent" :back="showBack">
      <div slot="right" class="l-header--r" @click="swRouter('/couponInstructions')">规则说明</div>
    </TitleHeader>

    <div class="s-tabs s-radius__s">
      <span
        v-for="(name, key) in stateMapping"
        :key="key"
        class="s-tab s-radius__xs"
        :class="{ on: status == key }"
        @click="status = parseInt(key)"
        >{{ name }}</span
      >
    </div>

    <StackView ref="view" class="coupons--view l-view">
      <!-- <AutoView :header="true" :footer="false" class="coupons--view l-view"> -->
      <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <EmptyCard v-if="list[status].list.length === 0" type="coupons"></EmptyCard>
        <template v-else>
          <van-list v-model="loading" :finished="list[status].finished" finished-text="没有更多了" @load="loadData">
            <CouponCard
              v-for="(item, index) in list[status].list"
              :key="item.id"
              :status="status"
              :class="{ 'f-vmt': index > 0 }"
              :showCheck="true"
              :item="item"
              :oldFlag="false"
            />
          </van-list>
        </template>
      </van-skeleton>
    </StackView>
  </div>
</template>

<script>
import CouponCard from "@/components/card/coupon";

export default {
  name: "coupons",
  components: {
    CouponCard,
  },
  data() {
    return {
      showBack: true,
      firstLoading: true,
      loading: true,
      dataLoading: false,
      stateMapping: {
        1: "未使用",
        2: "已使用",
        3: "已过期",
      },
      status: 1,
      list: {
        1: {
          page: 1,
          total: 0,
          finished: false,
          list: [],
        },
        2: {
          page: 1,
          total: 0,
          finished: false,
          list: [],
        },
        3: {
          page: 1,
          total: 0,
          finished: false,
          list: [],
        },
      },
    };
  },
  watch: {
    status(val) {
      // if ( ! this.list[ val ].finished ) this.loadData();
      this.list[val].page = 1;
      this.list[val].total = 0;
      this.list[val].finished = false;
      this.list[val].list = [];
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      this.HTTP.couponList(this.list[this.status].page, this.status)
        .then((res) => {
          this.firstLoading = false;
          this.loading = false;
          this.list[this.status].page++;
          this.list[this.status].total = res.total;
          if (this.list[this.status].list.length) {
            this.list[this.status].list = this.list[this.status].list.concat(res.data);
          } else {
            this.list[this.status].list = res.data;
          }
          if (res.data.length < res.size || this.list[this.status].list.length >= this.list[this.status].total) {
            this.list[this.status].finished = true; // 完成加载
          }
          console.log("res:", res);
        })
        .catch((e) => {
          // console.log( e );
          this.$toast("网络连接不稳定，请稍候重试！");
          this.loading = false;
        });
    }
  },
  created() {
    this.loadData();
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    if (!this.$route.query.keepAlive) {
      this.JSBridge.callHandler("clearHistory", "my");
    }
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.coupons {
  height: 100vh;
  overflow: hidden;
  background-color: $c-pageBg;

  .s-tabs {
    display: flex;
    width: 92%;
    margin: 0 auto;

    .s-tab {
      flex: 1;
    }
  }

  #{&}--view {
    padding-bottom: 36vw;
    .l-panel {
      padding: $padding2;
    }
  }
}
</style>
