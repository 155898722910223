<template>
    <div>
        <van-dialog v-model="showCoupon" class="coupon-dialog-container" :showConfirmButton="false">
            <div class="coupon-dialog">
                <img class="close-icon" src="/static/vip/vip/close-icon.png" alt="" @click="clickCancel" />
                <div class="agreement-container">
                    <img src="/static/vip/vip/checked.png" v-if="selected" @click="selected = false" />
                    <img src="/static/vip/vip/uncheck.png" v-else @click="selected = true" />
                    <span
                        >购买前请仔细阅读：<b @click.stop="onSeeAgreement('memberservice')">会员协议 </b> |
                        <b @click.stop="onSeeAgreement('paymentagreement')"> 支付协议</b></span
                    >
                </div>
                <div class="pay-box" @click="clickPay">
                    <span class="left">立即支付 ¥</span>
                    <span class="right">288</span>
                </div>
            </div>
        </van-dialog>
        <van-dialog v-model="showDialog" :showConfirmButton="false">
            <div class="confirm-wrap">
                <h4 class="h4">确认开通</h4>
                <p class="t-color">我已阅读并同意《会员协议》，《支付协议》</p>
                <div class="btn-wrap">
                    <span class="cancel" @click="showDialog = false">取消</span>
                    <span class="info" @click="confirmPay">确认开通</span>
                </div>
            </div>
        </van-dialog>
        <!-- 支付组件-->
        <base-pay
            ref="basePay"
            :payList="payList"
            :extParams="{
                memberGoodsId: memberGoodsId,
                ext: JSON.stringify(pointParam)
            }"
            title="开通会员"
            @success="paySuccess"
            @payError="payError"
            @cancel="payCancel"
        />
    </div>
</template>

<script>
import BasePay from "@/components/base-pay/pay-frame.vue";
export default {
    name: "coupon",
    components: {
        BasePay
    },
    props: {
        showCoupon: {
            type: Boolean,
            default: false
        },
        memberGoodsId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            showDialog: false,
            selected: false,
            payList: ["支付宝", "微信"],
            pointParam: {
                source: "report",
                pageId: "10063",
                moduleId: "",
                btnId: "",
                refId: ""
            }
        };
    },
    methods: {
        // 点击支付
        clickPay() {
            if (!this.selected) {
                this.showDialog = true;
                console.log("未勾选");
                return;
            }
            // this.$emit("cancel");
            this.payList = ["支付宝", "微信"];
            // basePay.value?.pay();
            this.$refs.basePay.pay();
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 5950100, 5950102)
        },

        //点击取消
        clickCancel() {
            this.$emit("cancel");
        },

        //支付成功
        paySuccess() {
            this.$emit("cancel");
            this.$emit("success");
        },

        //支付失败
        payError() {
            this.$emit("cancel");
        },

        //取消支付
        payCancel() {
            this.$emit("cancel");
        },

        //确认开通
        confirmPay() {
            this.showDialog = false;
            this.selected = true;
            this.clickPay();
        },

        onSeeAgreement(type) {
            const query = {
                queryPath: "",
                title: ""
            };
            switch (type) {
                case "subscription":
                    query.queryPath = "/subscription.html";
                    query.title = "连续订阅服务协议";
                    break;
                case "memberservice":
                    query.queryPath = "/memberservice.html";
                    query.title = "上上参谋会员服务协议";
                    break;
                case "paymentagreement":
                    query.queryPath = "/paymentagreement.html";
                    query.title = "上上参谋支付协议";
                    break;
                case "enterprise":
                    query.queryPath = "/enterprise.html";
                    query.title = "上上参谋企业版-数位拓店云服务协议";
                    break;
                case "enterpriseSvip":
                    query.queryPath = "/SSCM_VIP.html";
                    query.title = "上上参谋企业版-数位拓店云服务协议";
                    break;
            }
            this.swRouter({
                path: "/iframeAgreement",
                query
            });
        }
    }
};
</script>

<style lang="scss">
.coupon-dialog-container {
    width: vw2(285) !important;
    overflow: initial;
    .van-dialog__content {
        width: vw2(285) !important;
    }
}
</style>

<style lang="scss" scoped>
.coupon-dialog {
    width: vw2(285);
    height: vw2(293);
    background: url("/static/vip/vip/coupon-img.png") no-repeat;
    background-size: cover;
    position: relative;
    .close-icon {
        position: absolute;
        width: vw2(20);
        height: vw2(20);
        right: vw2(10);
        top: vw2(10);
    }
    .agreement-container {
        font-size: vw2(11);
        font-weight: 400;
        color: #676c84;
        line-height: vw2(15);
        padding: vw2(202) 0 0 vw2(30);
        span {
            line-height: vw2(15);
            vertical-align: middle;
            margin-left: vw2(4);
            b {
                font-weight: 400;
                color: #347fff;
            }
        }
        img {
            position: relative;
            vertical-align: middle;
            width: vw2(14);
        }
    }
    .pay-box {
        width: vw2(245);
        height: vw2(45);
        background: linear-gradient(133deg, #fb8b3a 0%, #fe5637 100%);
        border-radius: vw2(23);
        margin: vw2(11) vw2(20) 0 vw2(20);
        color: #ffffff;
        text-align: center;
        .left {
            font-size: vw2(15);
            font-weight: 500;
            line-height: vw2(45);
            margin-right: vw2(5);
        }
        .right {
            font-size: vw2(17);
            font-weight: bold;

            line-height: vw2(45);
        }
    }
}
.confirm-wrap {
    padding: 4.8vw;
    text-align: center;
    .vip-icon {
        width: 37.6vw;
        height: 4vw;
        margin-top: 2.667vw;
    }
    .wrap-img {
        width: 21.33vw;
        height: 21.33vw;
        padding: 4vw 0;
    }
    .h4 {
        font-size: 4.53vw;
        color: #1d2233;
        font-weight: bold;
        margin-bottom: 5.87vw;
    }
    .t-color {
        font-size: 4vw;
        color: #8e9099;
        line-height: vw2(25);
    }
    .btn-wrap {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-content: center;
        margin-top: 20px;
        .cancel {
            display: inline-block;
            width: 34.13vw;
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 4vw;
            color: #8e9099;
            background: #f7f6f9;
            border-radius: 1.6vw;
            text-align: center;
        }
        .info {
            display: inline-block;
            width: 34.13vw;
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 4vw;
            color: #fff;
            background: #347fff;
            border-radius: 1.6vw;
            text-align: center;
        }
    }
}
</style>
