<template>
    <div class="assess">
        <div class="header">
            <StateBar />
            <TitleHeader title="" color="white" bgcolor="transparent" :back="true" />
        </div>

        <div class="assess--view">
            <img src="/static/img/assess/s3_01.png" class="bg" />
            <div class="last-view">
              <template v-if="id === '10009'">
                <img src="/static/img/assess/s5_02.png" class="bg" />
                <img src="/static/img/assess/s5_03.png" class="bg" />
              </template>
              <template v-else>
                <img src="/static/img/assess/s3_02.png" class="bg" />
              </template>
              <!-- 底栏 -->
              <div v-if="id === '10009'" class="assess--footerBar sp">
                <a href="javascript:;" :disabled="disabled" class="btn" @click="onSubmit">
                  <img src="/static/img/assess/text/55s.png" class="btn-text" />
                </a>
              </div>
              <div v-else class="assess--footerBar">
                <a href="javascript:;" :disabled="disabled" class="btn" @click="onSubmit">
                  <img src="/static/img/assess/text/33s.png" class="btn-text" />
                </a>
              </div>
            </div>

            <div v-if="params" class="info">
                <p class="item">名称：{{ params.storeName || '---' }}</p>
                <p class="item">行业：{{ params.categoryText || '---' }}</p>
                <p class="item">类型：{{ params.storeTypeText || '---' }}</p>
                <p class="item">位置：{{ params.address || '---' }}</p>
            </div>

            <!-- 指标列表 -->
            <template v-if="entity">
                <template v-for="( item, index ) in entity.modelList">
                    <div v-if="template[id][index]" :key="item.modelId" class="target" :class="{ long: template[id][index].long, on: ! item.paramId }" :style="`top: ${template[id][index].y}vw; left: ${template[id][index].x}vw`">
                        <img :src="`/static/img/assess/text/${item.moduleCode}.png`" class="btn-text" @click.self="onTap(item.modelId, item.paramId)" />
                        <div v-if="item.paramId" class="result">
                            <div class="content" :class="{ only: ! item.conclusion }">
                                <em>{{ item.socre }}分</em>{{ item.conclusion }}
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'assessPoster',
    data () {
        return {
            title   : '正在加载',
            loading : true,
            disabled: true,

            storeTypes: [ '街边店', '商场店' ],
            template  : {
                10007: [ { x: 27, y: 147 }, { x: 10, y: 221 }, { x: 20, y: 292 } ],
                10008: [ { x: 27, y: 147 }, { x: 10, y: 221 }, { x: 20, y: 292 } ],
                10009: [ {
                    x: 28,
                    y: 150,
                }, {
                    x: 10,
                    y: 222,
                }, {
                    x   : 8,
                    y   : 294,
                    long: true,
                }, {
                    x   : 6,
                    y   : 380,
                    long: true,
                }, {
                    x: 18,
                    y: 461,
                } ],
            },

            id     : null,
            history: null,
            entity : null,
            params : null,
        };
    },
    methods: {
        loadData () {
            this.loading = true;
            this.id      = this.$route.params.id;
            this.history = this.$route.params.history;
            this.HTTP.getAssessInfo( this.id, this.history ).then( ( res ) => {
                this.entity = res;
                // console.log(this.entity)
                this.$nextTick(() => {
                  const i = this.entity.modelList.findIndex(el => !el.paramId)
                  if (i >= 0) {
                    const ele = document.getElementsByClassName('target')[0]
                    document.getElementsByClassName('assess--view')[0].scrollTop = ele.offsetTop - 250
                  }
                })
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        onTap ( code, pid ) {
          if (window.isMini) {
            this.$router.push(`/assess/${this.id}/poster/${this.history}/${code}/${pid}`)
          } else {
            this.swRouter( `/assess/${this.id}/poster/${this.history}/${code}/${pid}` );
          }
        },
        onSubmit () {
            if ( this.loading || this.disabled ) return;
            this.loading = true;
            this.HTTP.GET( this.entity.history.url ).then( ( res ) => {
              if (window.isMini) {
                this.$router.push( `/assess/${this.id}/summary/${this.history}` )
                window.localStorage.setItem('isSavedShop', '1')
              } else {
                this.swRouter( `/assess/${this.id}/summary/${this.history}` );
              }
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
    },
    watch: {
        entity ( res ) {
            this.loading = false;
            // 参数提取
            this.params  = res.history;
            this.params.storeTypeText = this.storeTypes[ this.params.storeType - 1 ];
            // 结论检查&提取路由链
            this.disabled = false;
            const links   = [];
            for ( const item of res.modelList ) {
                links.push( {
                    mid: item.modelId,
                    pid: item.paramId,
                    socre: item.socre
                } );
                if ( ! item.paramId ) {
                    this.disabled = true;
                }
            }
            this.$store.commit( 'setAssessInfo', { id: this.id, links } );
        },
    },
    activated () {
      if (this.params) {
        this.loadData();
      }
    },
    beforeMount () {
        this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
        this.loadData();
        this.JSBridge.registerHandler("onAppResume", (data) => {
          this.loadData();
        })
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.assess {
    height: 100vh;
    background-color: #6AC4FC;

    .header {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
    .last-view {
      position: relative;
    }
    #{&}--view {
        position: absolute;
        top: 0;
        height: 100vh;
        overflow-y: auto;

        .bg {
            display: block;
            width: 100%;
        }

        .info {
            position: absolute;
            top: 59.5vw;
            left: 20vw;
            width: 65vw;
            overflow: hidden;

            .item {
                @include font( $c-text, $font_400, bold );
                margin-top: 3vw;
                letter-spacing: .25vw;
                opacity: .8;
            }
            .item:first-child {
                @include singleLine;
                margin-top: 0;
                padding-top: $margin * 1.5;
            }
        }

        .target {
            position: absolute;
            min-width: 52vw;
            min-height: 13vw;
            background: url(/static/img/assess/btn_short.png) no-repeat left top;
            background-size: 100% 13vw;
            top: 0;
            left: 0;

            &.long {
                min-width: 73vw;
            }

            @include flexColumn;
            .btn-text {
                height: 6vw;
                margin-top: 1.3vw;
            }

            &.on:after {
                @include ani;
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 13.5vw;
                height: 13.5vw;
                background: url(/static/img/assess/tips.png) no-repeat top left;
                background-size: contain;
                transform: translate(50%, 70%);
                animation: tips 0.5s infinite 1s ease-out alternate;
            }

            .result {
                position: absolute;
                top: 100%;
                left: 5vw;
                margin-top: -1px;
                width: 42vw;
                box-sizing: border-box;
                padding: $padding 0;
                font-size: 3.73vw;
                line-height: 5vw;
                color: #2B4AA7;
                font-weight: bold;
                background: url(/static/img/assess/bg_result_t.png) no-repeat;
                background-size: 100% $padding;

                .content {
                    padding: 0 $padding * 0.5;
                    background: url(/static/img/assess/bg_result_c.png) repeat-y;
                    background-size: 100% auto;

                    &.only em {
                        display: block;
                        width: 100%;
                        text-align: center;
                        margin-right: 0;
                        font-size: 4.8vw;
                    }
                }

                em {
                    color: #EE395B;
                    font-style: normal;
                    margin-right: $margin * 0.5;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: $padding;
                    width: 100%;
                    background: url(/static/img/assess/bg_result_b.png) no-repeat;
                    background-size: 100% auto;
                }
            }
        }
    }

    #{&}--footerBar {
        position: absolute;
        left: 19vw;
        bottom: 3.5vw;

        &.sp {
            left: 18vw;
            bottom: 9.8vw;
        }

        .btn {
            display: block;
            width: 65.5vw;
            height: 34vw;
            text-align: center;

            .btn-text {
                margin-top: 4.8vw;
                height: 12vw;
            }

            &[disabled=disabled] {
                background: url(/static/img/assess/btn_dis.png) no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    @keyframes tips {
        from { transform: translate(50%, 70%) scale(1); opacity: 1; }
        to   { transform: translate(55%, 65%) scale(1.1); opacity: .9; }
    }
}
</style>
