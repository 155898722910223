<template>
  <div class="privilege">
    <p class="module-title">{{data.title}}</p>
    <div class="icons-swiper-contails">
      <div class="icons-wrap">
        <div class="cell">
          <div class="cell-hd avatar">
            <div class="text">
              <p class="title" :style="`color: ${data.head.titleColor}`">{{data.head.title}}</p>
              <p class="sub-title" :style="`color: ${data.head.descColor}`">{{data.head.desc}}</p>
            </div>
            <img class='bg' :src="data.head.bg" />
            <img class='mark' v-if="data.head.mark" :src="data.head.mark" />
          </div>
          <div class="cell-bd" :style="{minWidth: iconWrapWidth}">
            <div v-for="(icon, index) in data.icons" :style="{'display': 'inline-block',width: iconWidth + 'vw'}">
              <template v-if="index == parseInt(data.icons.length/2)">
                <icon-title :data="icon" :key="index"/>
                <!--<br />-->
              </template>
              <template v-else>
                <icon-title :data="icon" :key="index"/>
              </template>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTitle from "../icon-title/index";
export default {
  name: "index",
  components: {
    IconTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: '',
          head:{
            title: '',
            desc: '',
            bg: '',
            mark: '',
          },
          icons: [],
        }
      }
    }
  },
  data(){
    return {
      iconWidth: (350/750)*100,
    }
  },
  computed: {
    iconWrapWidth(){
      let width = (parseInt(this.$props.data.icons.length/2) + this.$props.data.icons.length%2)*this.iconWidth;// + 390/750;
      return width + 'vw';
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_cell.scss';
@import '@/styles/px2vw.scss';
.icons-swiper-contails{
  width: 100%;
  overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}
.privilege {
    padding-bottom: vw(28);
    //border-bottom: vw(1) solid #E7E7E7;
  //&:first-child{
  //  border-bottom: vw(1) solid #E7E7E7;
  //}
}
.module-title{
  font-size: vw(28);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #1D2233;
  line-height: vw(40);
  margin-bottom: vw(22);
}
.icons-wrap{
  min-width: vw(100);
}
.cell-bd{
  padding-left: vw(26);
}
.avatar{
  width: vw(208);
  height: vw(170);
  //overflow: hidden;
  position: relative;
  vertical-align: middle;
  .bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    z-index: 0;
  }
  .text{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: 0 vw(16);
  }
  .title{
    font-size: vw(28);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #012D7A;
    line-height: vw(40);
  }
  .sub-title{
    font-size: vw(22);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #588EEC;
    line-height: vw(29);
    margin-top: vw(12);
  }
  .mark{
    position: absolute;
    left: 0;
    top: vw(0);
    height: vw(35);
  }
}

</style>
