<template>
    <div class="application">
        <div class="application--container">
            <div class="application--head">
                <h3>10万+创业者正在关注</h3>
                <p>0元入驻 百万客源 高效对接</p>
            </div>
            <div class="application--main">
                <div class="content">
                    <div class="top_img">
                        <img src="/static/img/applicationLogo.png" alt="">
                    </div>
                    <div class="input_box">
                        <van-field v-model="params.brandName" class="input_lable" label="品牌名称" maxlength="15" placeholder="请输入品牌名称" />

                        <van-field label="公司名称" class="input_lable" v-model="params.companyName" maxlength="15" placeholder="请输入公司名称" />

                        <van-field label="真实姓名" class="input_lable" v-model="params.realName" maxlength="15" placeholder="请输入您的姓名" />

                        <van-field label="所在城市" class="input_lable address" v-model="city" readonly @click="showRegionPicker = true" placeholder="请选择所在城市" />

                        <van-field label="手机号码" class="input_lable" v-model="params.phone" type="number" maxlength="11" placeholder="请输入手机号" />

                        <!-- <van-field label="验证码" type="number" placeholder="请输入验证码" class="code_input">
                            <template #button>
                                <span class="code">发送验证码</span>
                            </template>
                        </van-field> -->


                        <van-button class="btn" color="#2D7EEC" block :loading="submitting" @click="onSubmit">提交申请</van-button>

                        <p class="tip">有疑问——<span @click="onLivechat">联系品牌合作经理</span></p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 省市区选择 -->
        <OpenCityPicker type="v2open" v-model="showRegionPicker" @confirm="getCityZone"/>

        <popInfo ref="forPop" title="申请成功" popText="您已成功发送合作申请，届时将会有专员联系您，请静候佳音哦~"></popInfo>
        <!-- 全局遮罩层 -->
        <!-- <LoadingOverlay :show="loading" /> -->
    </div>
</template>

<script>
import OpenCityPicker from '../../home/components/openCityPicker';
import popInfo from "../components/newBrandDetail/popInfo"
import { Toast } from "vant";
export default {
    name: "brandApplicationFor",
    components: {
        OpenCityPicker,popInfo
    },
    data() {
        return {
            showRegionPicker    : false,
            isShow: true,
            params: {
                brandName: null,
                companyName: null,
                realName: null,
                phone: null,
                provinceCode: null ,//省份code
                province: null,
                cityCode: null, //城市code
                city: null,
                regionCode: null, //区code
                region: null,
                type: 1
            },
            city: null,
            submitting: false,
        };
    },
    methods: {
        // 提交
        onSubmit() {
            Toast.resetDefaultOptions();
            if (this.params.brandName.trim() == '') {
                Toast({ message: "请输入品牌名称", icon: "warning",className: 'forToast' });
                return false;
            }
            if (this.params.companyName.trim() == '') {
                Toast({ message: "请输入公司名称", icon: "warning",className: 'forToast' });
                return false;
            }
            if (this.params.realName.trim() == '') {
                Toast({ message: "请输入您的姓名", icon: "warning",className: 'forToast' });
                return false;
            }
            if (!/^1[3-9]\d{9}$/.test(this.params.phone)) {
                Toast({ message: "请输入有效的手机号码", icon: "warning",className: 'forToast' });
                return false;
            }
            if ( this.submitting ) return;
            this.submitting = true;
            this.HTTP.brandJoinApply( this.params ).then( ( res ) => {
                this.$refs.forPop.getParams();
                setTimeout( () => {
                    this.params = {
                        brandName: null,
                        companyName: null,
                        realName: null,
                        phone: null,
                        provinceCode: null ,//省份code
                        province: null,
                        cityCode: null, //城市code
                        city: null,
                        regionCode: null, //区code
                        region: null,
                        type: 1
                    };
                    this.city = null;
                }, 100 );
                this.submitting = false;
            }).catch( ( e ) => {
                if(e.code == 168041) {
                    Toast({ message: e.msg, icon: "warning",className: 'forToast' });
                } else {
                    Toast({ message: "网络不稳定，请稍候重试！", icon: "warning",className: 'forToast' });
                }
                setTimeout( () => {
                    this.submitting  = false;
                }, 2000 );
            } );
        },
        // 选择区域
        getCityZone(picker, code) {
            this.city = picker.join( '-' );
            this.params.provinceCode = code[ 0 ] || null;
            this.params.province = picker[0] || null;
            this.params.cityCode = code[ 1 ] || null;
            this.params.city = picker[1] || null;
            this.params.regionCode = code[ 2 ] || null;
            this.params.region = picker[2] || null;
            console.log(code)
        },
        // 联系
        onLivechat() {
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.BRAND.TAP_COOPERATION_SERVICE );
            if (window._appCode >= 175) {
                this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "ONLINE_SERVICE",
                params: null,
                });
            } else {
                this.JSBridge.callHandler(
                "callLiveChat",
                {
                    type: "normal",
                    data: {},
                },
                (st) => {}
                );
            }
        },
    },
    mounted() {
        // if(window.isClient) {
        //     this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
        //         if ( typeof data === 'string' ) data = JSON.parse( data );
        //         if(data) {
        //             this.city  = [ data.province, data.city, data.district ].join( '-' );
        //             console.log(data.province + data.city + data.district)
        //         }
        //     })
        // }
    },
    beforeMount() {
        this.JSBridge.callHandler("setStatebarTheme", "white");
    },
    beforeDestroy() {
        // 隐藏分享按钮
        this.JSBridge.callHandler("switchShareBtn", false);
        this.JSBridge.callHandler("switchShareBtnV2", false);
    },
};
</script>

<style lang="scss" scoped>
    @import "@/styles/index.scss";
    .application{
        height: 100vh;
        overflow: hidden;
        background: url(/static/img/applicationBg.png) no-repeat;
        background-size: contain;
        #{&}--container{
            box-sizing: border-box;
            height: 100%;
        }
        #{&}--head{
            width: 100%;
            height: 50.4vw;
            line-height: 0;
            box-sizing: border-box;
            padding: 24.8vw 6vw 12.4vw 6vw;
            h3{
                font-size: 5.33vw;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 7.47vw;
            }
            p{
                font-size: 3.2vw;
                margin-top: 1.33vw;
                color: #FFFFFF;
                line-height: 4.4vw;
            }
        }
        #{&}--main {
            height: calc(100% - 45.87vw);
            width: 100%;
            box-sizing: border-box;
            padding: 4.53vw;
            .content{
                width: 100%;
                height: 100%;
                background: #FFFFFF;
                box-shadow: 0vw 1.07vw 3.6vw 0.4vw rgba(13, 13, 13, 0.06);
                border-radius: 1.33vw;
                position: relative;
                box-sizing: border-box;
                .top_img{
                    width: 100%;
                    height: 12.27vw;
                    text-align: center;
                    img{
                        width: 39.6vw;
                        height: 14.67vw;
                        position: absolute;
                        top: -2.13vw;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .input_box{
                    padding: 4.27vw;
                    height: calc(100% - 20.81vw);
                    overflow-y: auto;
                    ::v-deep .van-cell{
                        padding: 4vw 0;
                        &:first-child{
                            padding-top: 0px;
                        }
                    }
                    ::v-deep .van-cell::after{
                        left: 0;
                        right: 0;
                        border: 1px solid #F3F3F3;
                        transform: scaleY(.5);
                    }
                    ::v-deep .van-field__label{
                        margin-right: 0;
                        width: 19.33vw;
                        font-size: 3.73vw;
                        font-weight: bold;
                        color: #333333;
                        line-height: 6.4vw;
                    }
                    ::v-deep .van-field__control{
                        font-size: 3.73vw;
                        color: #333333;
                        line-height: 6.4vw;
                    }
                    .input_lable{
                        ::v-deep span{
                            position: relative;
                            padding-left: 1.8vw;
                            &::after{
                                position: absolute;
                                content: "*";
                                left: 0;
                                top: 0;
                                line-height: normal;
                                color: #FF5900;
                                font-size: 3.73vw;
                                font-weight: bold;
                            }
                        }
                    }
                    .address {
                        ::v-deep span{
                            &::after{
                                content: "";
                            }
                        }
                    }
                    .code_input{
                        ::v-deep .van-field__button{
                            padding-left: 0;
                            border-left: 1px solid #E7E7E7;
                        }
                        .code{
                            color: #347FFF;
                            font-size: 3.73vw;
                            font-weight: bold;
                            padding: 0 3.07vw 0 4.93vw;
                        }
                    }
                    .btn{
                        margin-top: 10.13vw;
                        font-size: 4.8vw;
                        font-weight: bold;
                        height: 13.33vw;
                        border-radius: 1.6vw;
                    }
                    .tip{
                        font-size: 2.93vw;
                        color: #999999;
                        text-align: center;
                        line-height: 4vw;
                        margin-top: 4vw;
                        span{
                            color: #347FFF;
                        }
                    }
                }
            }
        }
        .van_dia{
            padding: 5.07vw;
            text-align: center;
            box-sizing: border-box;
            img{
                width: 21.33vw;
                height: 21.33vw;
                padding: 4.53vw 0 4vw;
            }
            h3{
                font-size: 4.27vw;
                font-weight: bold;
                color: #333333;
                line-height: 5.87vw;
            }
            p{
                margin-top: 2.13vw;
                font-size: 3.47vw;
                color: #8E9099;
                line-height: 5.2vw;
                margin-bottom: 3.73vw;
            }
            ::v-deep .van-button{
                height: 12vw;
                border-radius: 1.33vw;
                background: #518CDB;
                color: #fff;
                font-size: 4vw;
                font-weight: bold;
            }
            ::v-deep .van-hairline--top::after{
                border-top-width: 0px;
            }
        }
    }
</style>
<style >
.forToast{
    min-width: 26.67vw;
    width: auto;
    min-height: 26.67vw;
    font-size: 3.73vw;
    padding: 2.13vw;
}
.forToast .van-toast__icon{
    font-size: 9.6vw;
}
.forToast .van-toast__text{
    line-height: normal;
}
</style>
