<template>
    <div class="invitations">
        <StateBar></StateBar>
        <TitleHeader title="邀请记录" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="invitations--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <template v-if="list.length === 0">
                    <EmptyCard v-if="type === 2" type="invitations_10002"></EmptyCard>
                    <EmptyCard v-else type="invitations"></EmptyCard>
                </template>
                <template v-else>
                    <van-pull-refresh v-model="dataLoading" @refresh="loadNewData">
                        <van-list v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="loadData" >

                            <template v-if="type === 2">
                            <div class="invitations--list">
                                <div v-for="( item, index ) in list" :key="index" @tap="onTap" class="account">
                                    <Profile
                                        :photo="item.avatar || '/static/icon/default.png'"
                                        :name="item.phone"
                                    />
                                    <span class="desc">邀请成功</span>
                                </div>
                            </div>
                            </template>
                            <template v-else>
                            <InviteCard v-for="( item, index ) in list" :key="index" @tap="onTap"
                                :item="item" />
                            </template>
                        </van-list>
                    </van-pull-refresh>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import InviteCard from '@/components/card/invite';
import Profile from '@/components/card/profile';

export default {
    name      : 'invitations',
    components: {
        InviteCard,
        Profile,
    },
    data () {
        return {
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            recent      : null,
            remote      : null,
            list        : [],

            type: 1, // 邀请类型 1邀请注册 2市调分享
        };
    },
    methods: {
        loadData () {
            this.loading = true;
            this.type    = ~ ~ this.$route.query.type || 1; // 获取查询类型
            this.HTTP.inviteList( this.remote, 'before', this.type ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                if ( res.list.length ) {
                    this.list = this.list.concat( res.list );
                }
                if ( this.list.length ) {
                    this.recent = this.list[ 0 ].gmtCreate;
                    this.remote = this.list[ this.list.length - 1 ].gmtCreate;
                }
                this.finished = ! res.more;
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading  = false;
                this.finished = true;
            } );
        },
        loadNewData () {
            this.dataLoading = true;
            this.HTTP.inviteList( this.recent, 'after' ).then( ( res ) => {
                if ( res.list.length ) {
                    this.list = res.list.concat( this.list );
                }
                if ( this.list.length ) {
                    this.recent = this.list[ 0 ].gmtCreate;
                }
                setTimeout( () => { this.dataLoading = false; }, 1000 );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                setTimeout( () => { this.dataLoading = false; }, 1000 );
            } );
        },
        onTap ( item ) {
            console.log( item );
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'clearHistory', true );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.invitations {
    height: 100vh;
    background-color: $c-pageBg;

    .btnColor {
        color: $c-main;
    }

    #{&}--view {
        .l-panel {
            padding: $padding2;
        }
    }

    #{&}--list {
        padding: $padding;
        background-color: white;
        border-radius: $radius_s;
        .account {
            position: relative;
            height: 9.725vw;
            .desc {
                position: absolute;
                top: 2.75vw;
                right: 0;
                height: 4vw;
                @include font( $c-notice, $font_347, bold );
            }
        }
        .account:not(:last-child) {
            // box-shadow: inset 0 -1px 0 $c-line;
            margin-bottom: $margin;
        }
    }
}
</style>
