<template>
    <div class="brand_pecommended" v-if="recommendList && recommendList.length">
        <div class="head-title">相似推荐</div>
        <div class="recommend-flex">
            <div class="item-card" v-for="(val, index) in recommendList" :key="index" @click="openNewBrand(val.brandCode)">
                <div>
                    <img :src="val.logoUrl" alt="">
                </div>
                <h3>{{val.brandName}}</h3>
                <p>{{val.joinTotalCost}}/总投资额</p>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "brandPecommended",
        props: {
            recommendList: {
                type: Array
            }
        },
        data() {
            return {
                current: 0,
            }
        },
        methods: {
            // 点击
            openNewBrand(code) {
                this.$emit('brandDetailCommend', code)
            }
        },
    }
</script>


<style lang="scss" scoped>
    @import "@/styles/index.scss";
    .brand_pecommended{
        background: #fff;
        width: 100%;
        height: auto;
        padding: 4.53vw 4vw 0;
        box-sizing: border-box;
        .head-title{
            font-size: 4.53vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 6.4vw;
        }
        .recommend-flex {
            margin-top: 2.67vw;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .item-card {
                width: calc(33.33% - 1.95vw);
                color: #333;
                margin-bottom: 5.33vw;
                div{
                    line-height: 0;
                    width: auto;
                    height: auto;
                    img {
                        width: 28.8vw;
                        height: 28.8vw;
                        object-fit: scale-down;
                        border-radius: 1.33vw;
                        box-shadow: 0px 0px 2.13vw 0px rgba(0, 0, 0, 0.1);
                    }
                }
                h3{
                    font-size: 3.73vw;
                    font-weight: bold;
                    color: #333333;
                    line-height: 5.33vw;
                    margin-top: 1.6vw;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                p{
                    margin-top: 0.53vw;
                    font-size: 2.93vw;
                    color: #666666;
                    line-height: 4vw;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }


</style>