<template>
    <ul class="pay-card">
        <li v-for="(item) in Types" :key="item.type" class="pay-item" @click="clickPayItem(item)">
            <i class="icon" :class="'icon-paytype' + item.type"></i>
            <span class="pay-content" v-if="item.type!=1">{{item.name}}支付</span>
            <span class="pay-content" v-else>
                <span>{{item.name}}兑换</span>
                <span>（剩余<i class="balance">{{balance}}</i>）</span>
            </span>
            <span class="checked-item" :class="{ checked: innerType === item.type }"></span>
        </li>
    </ul>
</template>
<script>
const Types = [
    { name: '上上贝', type: '1' },
    { name: '支付宝', type: '3' },
    { name: '微信', type: '2' },
    { name: '苹果', type: '4' },
];
const CheckMap = {};
export default {
    model: {
        prop : 'selectType',
        event: 'change'
    },
    props: {
        selectType: {
            default: 1,
        },
        payList: {
            type: Array,
            default () {
                return [];
            }
        },
        payType: {
            type   : String,
            default: null,
        },
        balance: {
            default: 0,
        },
    },
    data () {
        return {
            innerType: this.selectType || 1
        };
    },
    computed: {
        Types () {
            let list = Types.filter( ( item ) => {
                const nameStr = item.name;
                CheckMap[ item.name ] = item.type;
                return this.payList.indexOf( nameStr ) > - 1;
            } );
            if ( ! list.length ) {
                list = Types;
            }
            return list;
        },
    },
    watch: {
        selectType ( nv ) {
            this.innerType = nv;
        },
        payType ( nv ) {
            this.innerType = CheckMap[ nv ] || 0;
        },
    },
    methods: {
        clickPayItem ( item ) {
            this.innerType = item.type;
            this.$emit( 'change', item.type );
            this.$emit( 'setPayType', item.name );
        },
    },
    mounted () {
        // 默认选中第一个
        if ( ! this.payType ) {
            this.clickPayItem( this.Types[0] );
        } else {
            this.innerType = CheckMap[ this.payType ];
        }
    }
};
</script>
<style lang="scss" scoped>
// @import '@/styles/index.scss';

.pay-card {
    border-radius: $radius_s $radius_s 0 0;
    background:rgba(255,255,255,1);

    .pay-item {
        display: flex;
        align-items: center;
        padding: 0 $padding_3 * 2 0 $padding_3;
        line-height: 14vw;
        font-weight: bold;
        font-size: $font_373;
        color: $c-text;
        box-shadow: 0 -1px 0 rgba(#EDF1FB, .5);

        &:last-child {
            // box-shadow: none;
            overflow: hidden;
        }
        .icon {
            display: block;
            width: 5vw;
            height: 5vw;
            background: url(/static/icon/svg/ssb.svg) no-repeat;
            background-size: contain;

            &.icon-paytype1 {
                background-image: url(/static/icon/svg/ssb.svg);
            }
            &.icon-paytype3 {
                background-image: url(/static/icon/svg/ali.svg);
            }
            &.icon-paytype2 {
                background-image: url(/static/icon/svg/wx.svg);
            }
            &.icon-paytype4 {
                background-image: url(/static/icon/svg/apple.svg);
            }
        }
    }
    .checked-item {
        width: 4vw;
        height: 4vw;
        font-size: 0;
        border-radius: 50%;
        background: white url(/static/icon/unselect@2x.png) no-repeat center center;
        background-size: 3.8vw 3.8vw;

        &.checked {
            background: url(/static/icon/success.png) no-repeat center center;
            background-size: 3.8vw 3.8vw;
        }
    }
    .pay-content {
        flex: 1;
        padding-left: $padding_3;
    }
    .balance {
        color: #F58958;
        font-style: normal;
        margin: 0 2px;
    }
}
</style>
