<template>
    <div class="brand">
        <div class="header">
            <template v-if="isClient">
                <StateBar :bgcolor="bgColor" />
                <TitleHeader :title="title"   :back="showBack" :bgcolor="bgColor" ></TitleHeader>
            </template>
            <div v-else class="share">
                <p class="f-fw">上上参谋</p>
                <p>生意人的数据军师</p>
                <a ref="openApp" :href="link" target="_blank" class="app">APP查看</a>
            </div>
        </div>

        <!-- 骨架屏占位图 -->
        <!-- <img v-if="loading" src="/static/skt/introduce.png" width="100%" /> -->
        <AutoView ref="view" :header="true" :footer="true" class="brand--view">
            <template v-for="( item, index ) of list">
                <van-image class="img" :key="index"  fit="cover"  :src="item" />
            </template>
        </AutoView>
        <SoltFooter  class="brand--footerBar">
            <div class="view">
                <van-button type="warning" block @click="onTap">立即入驻</van-button>
            </div>
        </SoltFooter>
        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>



export default {
    name      : 'introduceIndex',
    components: {
    },
    data () {
        return {
            loading   : false,
            isClient  : false,
            link      : this.HTTP.getAppStore(),
            showBack  : true,
            bgColor   : 'white',
            title: '诚邀加盟品牌商入驻',
            list:['/static/img/brand/a.png','/static/img/brand/b.png','/static/img/brand/c.png']

        };
    },
    methods: {
        onTap () {
            if (this.isClient) {
                this.swRouter( '/brand/enterInfo' );
            } else {
                top.location.href = this.HTTP.getAppStore();
            }
        },
    },
    computed: {
    },
    watch: {
    },
    mounted(){
        if ( this.isClient) {
            const share =  {
                title: '上上参谋“拍了拍”您并邀请您入驻！',
                desc : '10w+精准客户正寻找加盟品牌商',
                cover: '/static/img/brand/cover.jpg',
                };
             this.JSBridge.callHandler( 'switchShareBtn', {
                type  : 'poster',
                id    : this.id,
                title : share.title,
                desc  : share.desc,
                cover : document.location.protocol + '//' + document.location.host + share.cover,
                url   : location.href,
                target: window._appCode === 15 ? 'wxmp_tools' : 'wxmp_report',
            } );
        }
        if ( ! this.isClient ) document.title = this.title;
        setTimeout( () => {
            this.loading = false;
        }, 500 );
    },
    beforeMount () {
        this.id = this.$route.params.id;
        this.isClient = window.isClient;
    },
    beforeDestroy () {
        // 隐藏分享按钮
        this.JSBridge.callHandler( 'switchShareBtn', false );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.brand {
    height: 100vh;
    .header {
        position: relative;
        top: 0;
        width: 100%;
        z-index: 2;
        ::v-deep {
            .stateBar, .titleHeader {
                @include ani(.25s, background-color);
            }
        }
        .share {
            position: relative;
            height: $headerHeight;
            background: rgba(black, .8) url(/static/icon/logo@96.png) no-repeat $margin center;
            background-size: $headerHeight * 0.7;
            color: white;
            padding-left: $headerHeight * 1.1;
            p {
                font-size: $font_320;
                padding-top: $margin * 0.8;
            }
            .f-fw {
                font-size: $font_400;
            }
            .app {
                position: absolute;
                right: $margin;
                top: 25%;
                height: 50%;
                color: white;
                background-color: $c-main;
                padding: 0 $margin;
                overflow: hidden;
                border-radius: 10vw;
                font-size: $font_320;
                @include flexColumn;
            }
        }
    }
    #{&}--view {
        scroll-behavior: smooth;
         .img {
            display: block;
            min-height: 10vw;
        }
    }
    #{&}--footerBar {
        z-index: 3;
        box-shadow: 0 -.5vw 2vw rgba(black, .05);
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-button.van-button--warning{
                border-color:transparent;
                background: linear-gradient(90deg, #FFC03D 0%, #FF9C2E 100%);
            }
        }
    }
}
</style>
