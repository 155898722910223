<template>
    <div class="wrapper">
        <StateBar :bgcolor="bgColor" />
        <TitleHeader :title="title" :color="titleColor" :bgcolor="bgColor"  :back="true"></TitleHeader>
        <AutoView :header="true" :footer="false">
            <iframe ref="view" :src="url" frameborder="0" class="innerView"></iframe>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bgColor: "white",
            titleColor: "#000",
            title: '',
            url: 'https://webclient.sscanmou.com/useragreement.html'
        }
    },
    mounted() {
        this.title = this.$route.query.title
        let base = 'https://huawei-test-webclient.sscanmou.com'
        if(process.env.NODE_ENV === "production") {
            base = 'https://webclient.sscanmou.com'
        }
        this.url = `${base}${this.$route.query.queryPath}`
    }
} 
</script>

<style lang="scss" scoped>
.innerView {
    overflow-y: hidden;
    width: 100vw;
    height: 100%;
}
</style>