<template>
    <div class="hotspot">
        <StateBar></StateBar>
        <TitleHeader title="行业报告" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="hotspot--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="hotspot"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <HotspotCard v-for="( item, index ) in list" :key="item.id" @tap="onTap"
                            :item="item" :class="{ 'f-vmt' : index > 0 }" />
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import HotspotCard from '@/components/card/hotspot';

export default {
    name      : 'hotspot',
    components: {
        HotspotCard,
    },
    data () {
        return {
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            page        : 1,
            total       : 0,
            list        : [],
        };
    },
    methods: {
        loadData () {
            this.loading = true;
            this.HTTP.hotspotList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        onTap ( item ) {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'callFileViewer', {
                    type: 'application/pdf',
                    id  : item.id,
                    name: item.title || '行业报告 ',
                    url : item.reportUrl,
                } );
            } else {
                window.open( item.reportUrl );
            }
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.hotspot {
    height: 100vh;
    background-color: $c-pageBg;

    #{&}--view {
        .l-panel {
            padding: $padding2;
        }
    }
}
</style>
