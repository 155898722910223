<template>
  <div class="brand-search-result">
    <StateBar />
    <!--搜索框-->
    <searchBarFilter :popoverActiveIndex="tabIndex" :keyValue="keyword" @input="searchInput" @search="searchData" @selectPop="selectPop" />
    <!-- 骨架屏占位图 -->
<!--    <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />-->
    <!--商场筛选框-->
    <div class="mall-filter-box" v-if="tabIndex===1">
      <div class="mall-filter-item" v-for="(item, index) in mallFilterOption" :key="index"
           @click="selectFilterBox(index)"
            :class="{'active': (index===1 && leftActive) || (index===2 && rightActive)}"
      >
        <span v-if="index===0">{{ cityName ? cityName : item.name }}</span>
        <span v-else>{{ item.name }}</span>
        <span class="triangle"></span>
      </div>
    </div>
    <AutoView ref="view" :header="true" :footer="false" class="search--view">
      <div class="search--main" id="searchMain">
        <!--品牌搜索结果-->
        <van-list class="brand-search-list" v-if="tabIndex===0 && brandSearchList.length" v-model="loading" :finished="enableLoadMore" :immediate-check="false" finished-text="没有更多了" @load="loadMoreData">
          <div class="brand-search-card" v-for="(item, index) in brandSearchList" :key="index" @click="goDetail(item)">
            <div class="brand-icon">
              <van-image class="card--img s-radius__xs" fit="cover" lazy-load :src="item.logo">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <div class="brand-info-right">
              <div class="brand-info-top">
                <div class="brand-name">{{ item.name }}</div>
                <div class="brand-category" v-if="item.categoryTag2Name">{{ item.categoryTag2Name }}</div>
              </div>
              <div class="brand-info-middle">人均：<span>{{ item.avgPrice || '-' }}元</span></div>
              <div class="brand-info-bottom">现有门店：<span>{{ item.openningNumber || '-' }}家</span></div>
            </div>
          </div>
        </van-list>
        <!--商场搜索结果-->
        <van-list class="mall-search-list" v-if="tabIndex===1 && mallSearchList.length" v-model="loading" :finished="enableLoadMore" :immediate-check="false" finished-text="没有更多了" @load="loadMoreData">
          <div class="mall-search-card" v-for="(item, index) in mallSearchList" :key="index" @click="goDetail(item)">
            <div class="brand-icon">
              <van-image class="card--img s-radius__xs" fit="cover" lazy-load :src="item.url">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <div class="mall-info-right">
              <div class="mall-info-top">
                <div class="mall-name">{{ item.name }}</div>
                <div class="mall-city" v-if="item.city">{{ item.city }}</div>
              </div>
              <div class="mall-info-middle">{{ item.openingDate }}</div>
              <div class="mall-info-bottom">{{ item.builtArea }}，{{ item.shopCount }}</div>
            </div>
          </div>
        </van-list>

        <EmptyCard v-if="((!brandSearchList.length && tabIndex===0 && brandRequest) || (!mallSearchList.length && tabIndex===1 && mallRequest))" :type="tabIndex===0 ? 'search' : 'searchNoRecommend'"></EmptyCard>
      </div>
      <!--遮罩层-->
      <div class="mask" v-if="leftActive || rightActive" @click="closeMask"></div>
      <!-- 商场筛选-->
      <div class="display" v-if="leftActive || rightActive">
        <template v-if="leftActive">
          <div class="item" v-for="(item, index) in search.optionList" :key="index">
            <div class="name">{{item.column.name}}</div>
            <div class="tips">
              <div class="tip-item" :class="(items.value === isAreaActive || items.value === isYearActive || items.value === isShopCountActive) ? 'item-active' : ''" @click="leftSortClick(items.value )" v-for="items in item.nameValues" :key="items.value">{{items.name}}</div>
            </div>
          </div>
          <div class="btn" @click="leftSumbitClick">确定</div>
        </template>
        <template v-if="rightActive">
          <div class="item right-item" v-for="(item, index) in order.optionList" :key="index">
            <div class="name">{{item.column.name}}</div>
            <div class="tips">
              <div class="tip-item" :class="items.value === isOrderActive ? 'item-active' : ''" @click="rightSortClick(items.value)" v-for="items in item.nameValues" :key="items.value">{{items.name}}</div>
            </div>
          </div>
        </template>
      </div>
    </AutoView>
    <!-- 开通会员弹框 -->
    <OpenVip :showDialog="showDialog" @cancel="showDialog = false" />
  </div>
</template>

<script>
  import searchBarFilter from './components/search-bar-filter'
  import OpenVip from "@/components/openVip/index.vue";
  import { setLocalStorage, throttle } from '../../utils';
  export default {
    name: 'brandSearchResult',
    components: {
      searchBarFilter,
      OpenVip
    },
    data () {
      return {
        showDialog: false,
        dataLoading: false,
        enableLoadMore: false,
        mallFilterOption: [
          {
            name: '所在城市',
            type: 0
          },
          {
            name: '筛选条件',
            type: 1
          },
          {
            name: '排序规则',
            type: 2
          }
        ],
        loading: true,
        keyword: '',
        tabIndex: 0, // 0 查品牌 1 查商场
        brandSearchList: [], // 品牌搜索结果
        mallSearchList: [], // 商场搜索结果
        showMask: false, //
        order: {}, // 排序规则
        search: {}, // 筛选条件
        leftActive: false,
        rightActive: false,
        isAreaActive: "",
        isYearActive: "",
        isShopCountActive: "",
        isOrderActive: "",
        searchOptions: {
          current: 1,
          size: 10,
          name: '',
          cityCode: null
        },
        cityName: '',
        brandRequest: false,
        mallRequest: false,
      };
    },
    created () {
      const { keyword, index } = this.$route.query
      this.keyword = keyword || ''
      this.searchOptions.name = keyword || ''
      if (index == 0) {
        this.tabIndex = 0
      } else if (index == 1) {
        this.tabIndex = 1
      }
    },
    mounted () {
      this.refreshData()
      this.getShopMarketSearch()
    },
    methods: {
      scrollViewTop() {
        const ele = document.getElementById('searchMain')
        // console.log(ele)
        if (ele) {
          // ele.scrollTo(0, 0)
          // ele.scroll(0, 0)
          ele.scrollTop = 0
        }
      },
      handleSelectCity () {
        this.JSBridge.callHandler( 'selectCity', null, ( res ) => {
          console.log(res)
          if ( res ) {
            let cityObj = res;
            if ( typeof cityObj === 'string' ) {
              cityObj = JSON.parse( cityObj );
            }
            // console.log(cityObj)
            this.cityName = cityObj.name;
            this.searchOptions.cityCode = cityObj.code;
            this.refreshData()
          }
        } );
      },
      goDetail(item) {
        if (this.tabIndex === 0) {
          setLocalStorage("versionCode", window._appCode);
          this.HTTP.checkBrandStatus(item.code)
            .then((res) => {
              this.swRouter({
                path: "/reportView/brandLoader",
                query: {
                  reportId: item.code,
                },
              });
            })
            .catch((e) => {
              if (e.code == 201022) {
                this.showDialog = true;
              } else {
                this.$toast("网络不稳定，请稍候重试！");
              }
            });
          // this.swRouter("/brand/details/" + item.code);
        } else {
          this.HTTP.reportSubmit({
            goodsId: '10030',
            market: {
              code: item.code,
              text: item.name
            }
          }).then(res => {
            setLocalStorage("versionCode", window._appCode);
            this.swRouter(res.data.link.url.split("#")[1]);
          }).catch((e) => {
            if (e.code == 201022) {
              this.showDialog = true;
            } else {
              this.$toast("网络不稳定，请稍候重试！");
            }
          })
        }
      },
      commonQueryData() {
        let requestFunc;
        if (this.tabIndex===0) {
          requestFunc = throttle(this.brandSearch, 500)
        } else if (this.tabIndex===1) {
          requestFunc = throttle(this.getListData, 500)
        }
        requestFunc()
      },
      refreshData() {
        this.searchOptions.current = 1
        this.commonQueryData()
      },
      searchInput(val) {
        this.searchOptions.name = val
      },
      // 搜索
      searchData(val) {
        this.closeMask()
        this.searchOptions.name = val
        this.searchOptions.current = 1
        this.commonQueryData()
      },
      // 加载更多数据
      loadMoreData() {
        this.searchOptions.current++
        this.commonQueryData()
      },
      closeMask() {
        this.leftActive = false
        this.rightActive = false
      },
      rightSortClick(value) {
        this.isOrderActive = value
        this.rightActive = false
        this.refreshData()
      },
      leftSortClick(value) {
        if(value < 6) {
          this.isAreaActive = value
        } else if(value >= 6 && value < 13) {
          this.isYearActive = value
        } else {
          this.isShopCountActive = value
        }
      },
      selectFilterBox(index) {
        if (index === 1) {
          this.rightActive = false
          this.leftActive = !this.leftActive
        } else if (index === 2) {
          this.leftActive = false
          this.rightActive = !this.rightActive
        } else {
          this.leftActive = false
          this.rightActive = false
          this.handleSelectCity()
        }
      },
      leftSumbitClick() {
        this.leftActive = false
        this.refreshData()
      },
      selectPop(index) {
        if (index !== this.tabIndex) {
          if (index === 0) {
            this.brandRequest = false
            this.mallSearchList = []
          } else {
            this.mallRequest = false
            this.brandSearchList = []
          }
        }
        this.tabIndex = index
        this.rightActive = false
        this.leftActive = false
        this.refreshData()
      },
      // 获取品牌数据接口
      brandSearch() {
        this.loading = true
        this.HTTP.queryBrandAndMall({
          name: this.searchOptions.name,
          size: this.searchOptions.size,
          current: this.searchOptions.current,
          type: 1
        }).then(res => {
          console.log(res)
          if(res) {
            const { data, total } = res
            if(data.length < this.searchOptions.size) {
              this.enableLoadMore = true
            } else {
              this.enableLoadMore = false
            }
            if(this.searchOptions.current===1) {
              this.scrollViewTop()
              this.brandSearchList = data
            } else {
              this.brandSearchList = this.brandSearchList.concat(data)
            }
          }
        }).finally(() => {
          this.loading = false
          this.brandRequest = true
        })
      },
      // 获取商场数据接口
      getListData() {
        let searchOpt = {}
        let orderOpt = {}
        if(this.isAreaActive) {
          searchOpt.area = this.isAreaActive
        }
        if(this.isYearActive) {
          searchOpt.year = this.isYearActive
        }
        if(this.isShopCountActive) {
          searchOpt.shopCount = this.isShopCountActive
        }
        if(!this.isAreaActive && !this.isYearActive && !this.isShopCountActive) {
            searchOpt = null
        }
        if(this.isOrderActive) {
          orderOpt = {
            order: this.isOrderActive
          }
        } else {
            orderOpt = null
        }
        this.loading = true;
        const param = {
          ...this.searchOptions,
          condition: searchOpt ? JSON.stringify(searchOpt) : searchOpt,
          sort: orderOpt ? JSON.stringify(orderOpt) : orderOpt,
          type: 2
        }
        this.HTTP.queryBrandAndMall(param).then(res => {
          // console.log(res)
          if(res) {
            const { data, total } = res
            if(data.length < this.searchOptions.size) {
              this.enableLoadMore = true
            } else {
              this.enableLoadMore = false
            }
            if(this.searchOptions.current===1) {
              this.scrollViewTop()
              this.mallSearchList = data || []
            } else {
              this.mallSearchList = this.mallSearchList.concat(data)
            }
          }
        }).finally(() => {
          this.loading = false
          this.mallRequest = true
        })
      },
      // 获取商场筛选条件
      getShopMarketSearch() {
        this.HTTP.getShopMarketSearch().then(res => {
          if(res.code == 0) {
            this.search = res.data.search
            this.order = res.data.order
            // 初始化数据
            this.isAreaActive = this.search.optionList.find(el => el.column.value === 'area').nameValues[0].value
            this.isYearActive = this.search.optionList.find(el => el.column.value === 'year').nameValues[0].value
            this.isShopCountActive = this.search.optionList.find(el => el.column.value === 'shopCount').nameValues[0].value

          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .brand-search-result {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .mall-filter-box {
      display: flex;
      align-items: center;
      padding: 1.733vw 4vw 3.733vw 4vw;
      height: 10.2vw;
      box-sizing: border-box;
      .mall-filter-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        color: #666666;
        font-size: 3.733vw;
        img {
          width: 2.133vw;
          height: 1.333vw;
          margin-left: 1.066vw;
        }
        .triangle {
          width: 0;
          height: 0;
          border-left: 1.333vw solid transparent;
          border-right: 1.333vw solid transparent;
          border-top: 1.6vw solid #b2b3b9;
          transition: all 0.5s;
          margin-left: 1.066vw;
          border-radius: 1.333vw;
        }
        &.active {
          font-weight: 600;
          color: #347FFF;
          .triangle {
            border-top: 1.6vw solid #347fff;
            transform: rotate(180deg);
          }
        }
      }
    }
    .search--view {
      scroll-behavior: smooth;
      position: relative;
      background: #F7F7F7;
      flex: 1;
      .van-pull-refresh {
        height: 100%;
      }
      .display {
        position: absolute;
        top: 0;
        left: 0;
        width: 92vw;
        font-size: 3.733vw;
        background-color: #fff;
        // position: absolute;
        padding: 0 4vw 4vw 4vw;
        z-index: 10;
        .item {
          margin-top: 4.8vw;
          &:first-child {
            margin-top: 0;
          }
          &.right-item {
            .tips {
              .tip-item {
                /*width: 37.867vw;*/
                flex: 1;
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }

          }
          .name {
            font-weight: 600;
            color: #1D2233;
            font-size: 4vw;
          }
          .tips {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .tip-item {
              width: 28.66vw;
              height: 7.73vw;
              margin-top: 2.93vw;
              border-radius: 1.067vw;
              background: #F5F5F5;
              text-align: center;
              line-height: 7.73vw;
              margin-right: 2.93vw;
              font-weight: 400;
              color: #333333;
              font-size: 3.733vw;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-break: break-all;
              &:nth-child(3n) {
                margin-right: 0;
              }
            }
            .item-active {
              background: #DFEDFF;
              color: #347FFF;
            }
          }
        }
        .btn {
          /*width: 92vw;*/
          height: 10.667vw;
          border-radius: 1.333vw;
          background: #347FFF;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 4.8vw;
        }
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        z-index: 1;
      }
      .search--main {
        position: relative;
        padding: 4vw;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        .mall-search-list {
          .mall-search-card {
            display: flex;
            align-items: center;
            padding: 3.333vw;
            box-shadow: 0 0 2.667vw 0 rgba(0, 0, 0, 0.09);
            background: #ffffff;
            border-radius: 1.333vw;
            &:nth-child(n+2) {
              margin-top: 2.667vw;
            }
            .mall-info-right {
              color: #666666;
              font-size: 3.2vw;
              margin-left: 2.667vw;
              flex: 1;
              overflow: hidden;
              .mall-info-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2.867vw;
                overflow: hidden;
                .mall-name {
                  font-weight: 600;
                  color: #1D2233;
                  font-size: 4vw;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  flex: 1;
                  line-height: 5.6vw;
                }
                .mall-city {
                  font-size: 3.333vw;
                }
              }
              .mall-info-middle {
                margin-bottom: 2.4vw;
              }
            }
            .brand-icon {
              width: 17.333vw;
              height: 17.333vw;
              border-radius: 0.667vw;
              .card--img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .brand-search-list {
          .brand-search-card {
            display: flex;
            align-items: center;
            padding: 3.333vw;
            box-shadow: 0 0 2.667vw 0 rgba(0, 0, 0, 0.09);
            background: #ffffff;
            border-radius: 1.333vw;
            &:nth-child(n+2) {
              margin-top: 2.667vw;
            }
            .brand-info-right {
              margin-left: 2.667vw;
              flex: 1;
              overflow: hidden;
              .brand-info-top {
                display: flex;
                align-items: center;
                margin-bottom: 2.867vw;
                overflow: hidden;
                .brand-name {
                  font-weight: 600;
                  color: #1D2233;
                  font-size: 4vw;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  flex: initial;
                  line-height: 5.6vw;
                }
                .brand-category {
                  background: #FFF1E9;
                  border-radius: 0.667vw;
                  border: 0.133vw solid #FF9F6B;
                  font-size: 3.2vw;
                  font-weight: 400;
                  color: #FF5900;
                  margin-left: 1.6vw;
                  padding: 0 1.6vw;
                  line-height: 4.8vw;
                  flex: none;
                }
              }
              .brand-info-middle, .brand-info-bottom  {
                font-weight: 400;
                color: #8E9099;
                font-size: 3.467vw;
                span {
                  color: #666666;
                }
              }
              .brand-info-middle {
                margin-bottom: 2.4vw;
              }
            }
            .brand-icon {
              width: 20vw;
              height: 20vw;
              .card--img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>
