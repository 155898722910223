<template>
  <div class="customization">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" :back="true"></TitleHeader>

    <!-- 骨架屏占位图 -->
    <van-skeleton :loading="loading" :animate="false" :row="3" class="s-skeleton" />

    <StackView ref="view" class="customization--view" :style="{ opacity: loading ? 0 : 1 }">
      <!-- 顶部banner -->
      <div class="top-banner" v-if="entity.bannerImages && entity.bannerImages.length > 0">
        <template v-if="entity.bannerImages.length > 1">
          <van-swipe :show-indicators="false" :autoplay="5000">
            <van-swipe-item v-for="(img, i) of entity.bannerImages" :key="i">
              <van-image class="img" fit="cover" :src="img" />
            </van-swipe-item>
          </van-swipe>
        </template>
        <template v-else>
          <van-image class="img" fit="cover" :src="entity.bannerImages[0]" />
        </template>
      </div>
      <!-- 中间背景 -->
      <div class="mid-bg" v-if="entity.activeItemResList && entity.activeItemResList.length">
        <CouponCard
          :activeItemResList="entity.activeItemResList"
          :activeBodyImage="entity.activeBodyImage"
          :couponBgImage="entity.couponBgImage"
          @getCoupon="getCoupon"
        />
      </div>
      <!-- 底部图 -->
      <template v-if="entity.bottomBanners && entity.bottomBanners.length > 0">
        <van-image v-for="(img, i) in entity.bottomBanners" :key="i" class="img" fit="cover" lazy-load :src="img" />
      </template>
    </StackView>

    <!-- 领取成功 -->
    <van-popup v-model="couponPopup" round closeable>
      <div class="signin-popup-wrap">
        <div class="type-noprize">
          <img src="/static/img/signin/signin-checked.png" alt="" />
          <p class="title-noprize">领取成功</p>
        </div>
        <div class="uesr-btn" @click="toUse">去使用</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import CouponCard from "./components/coupon-card.vue";

export default {
  components: { CouponCard },
  data() {
    return {
      loading: true,
      activeId: "",
      title: "",
      couponPopup: false,
      btnLoading: false,
      entity: {
        bannerImages: [],
        bottomBanners: [],
        activeItemResList: [],
        activeBodyImage: "",
        couponBgImage: ["/static/icon/coupon_bg_has.png", "/static/icon/coupon_bg.png"],
      },
    };
  },
  created() {
    const id = this.$route.params.id;
    if (!id) {
      this.$toast("无效活动id，即将返回首页！");
      this.$router.push("/");
      return;
    }
    this.activeId = id;
    this.loadData();
  },
  methods: {
    toUse() {
      this.couponPopup = false;
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "CHANGE_MAIN_TAB",
        id: "home",
        params: null,
        trackData: null,
        isClear: true,
      });
    },
    loadData() {
      this.loading = true;
      this.HTTP.getActiveCoupon(this.activeId)
        .then((res) => {
          this.title = res.data.activeName;
          this.entity.bannerImages = res.data.bannerImages || [];
          this.entity.activeBodyImage = res.data.activeBodyImage;
          this.entity.activeItemResList = res.data.activeItemResList || [];
          this.entity.bottomBanners = res.data.bottomBanners || [];
          if (res.data.couponBgImage && res.data.couponReceivedBgImage) {
            this.entity.couponBgImage = [res.data.couponReceivedBgImage, res.data.couponBgImage];
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          if (e.code == 260014 || e.code == 260016) {
            this.$toast(e.msg);
            setTimeout(() => {
              this.$router.push("/");
            }, 1500);
          } else {
            this.$toast("网络不稳定，请稍候重试！");
          }
        });
    },
    getCoupon(item) {
      if (item.receiveStatus == 1 || this.btnLoading) {
        return;
      }
      if (item.receiveStatus == 2) {
        return this.$toast("无法领取");
      }
      this.btnLoading = true;
      this.HTTP.receiveCoupon(this.activeId, item.couponId)
        .then((res) => {
          console.log(res);
          this.btnLoading = false;
          if (res.code == 0) {
            this.couponPopup = true;
            this.loadData();
          }
        })
        .catch((e) => {
          this.btnLoading = false;
          console.log(e);
          if (e.code == 260014) {
            this.$toast("不在活动期间内！");
          } else {
            this.$toast(e.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.customization {
  height: 100vh;
  overflow: hidden;

  #{&}--view {
    scroll-behavior: smooth;
    background: #fff;
    padding-bottom: 15.5vw;
    .top-banner {
      min-height: 50vw;
    }
    .img {
      width: 100%;
      vertical-align: middle;
      ::v-deep img {
        text-align: center;
      }
    }
    .mid-bg {
      width: 100%;
    }
  }
  .signin-popup-wrap {
    position: relative;
    padding: 8vw;
    text-align: center;
    width: 72vw;
    background: linear-gradient(100deg, #fcecc4 0%, #fde4b4 46%, #fee7bd 100%);
    .type-noprize {
      text-align: center;
      padding: 46px 0 20px 0;
      img {
        width: 18.67vw;
        height: 18.67vw;
      }
      .title-noprize {
        font-size: 4.53vw;
        color: #ff5900;
        font-weight: bold;
        margin-top: 3.2vw;
      }

      .prize-fail {
        font-size: 3.2vw;
        color: #ff5900;
        margin-top: 3.2vw;
        text-align: left;
        line-height: 4.8vw;
        padding: 0 3.2vw;
      }
    }
    .uesr-btn {
      width: 56vw;
      height: 10.67vw;
      line-height: 10.67vw;
      font-size: 4vw;
      font-weight: bold;
      color: #fff;
      margin: 0 auto;
      background: #ff5900 linear-gradient(90deg, #ff8000 0%, #ff5900 100%);
      border-radius: 20px;
    }
  }
}
</style>
