<template>
    <div class="wapper">
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
        <div v-show="!firstLoading">
            <div class="head">
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img1.png"></VanImage>
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img2.png"></VanImage>
              <img class="btn" src="/static/sem/newH5/btn1.png" @click="btnClick" />
            </div>
            <div class="head">
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img3.png"></VanImage>
              <div class="btn2" @click="btnClick">立即体验</div>
            </div>
            <div class="head">
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img4.png"></VanImage>
              <div class="btn2 btn3" @click="btnClick">免费体验</div>
            </div>
            <div class="head">
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img5.png"></VanImage>
              <div class="btn4" @click="btnClick">查看更多</div>
            </div>
            <div class="head">
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img6.png"></VanImage>
              <div class="btn2 btn5" @click="btnClick">免费体验</div>
            </div>
            <div class="head">
              <VanImage @load="imageLoadDone" src="/static/sem/newH5/img7.png"></VanImage>
            </div>
            <div class="company-des">Copyright © 深圳市数位汇聚科技有限公司 All Rights Reserved 粤ICP备15041214号</div>
            <div v-if="isShow" @click="closeMask">
                <div class="mask"></div>
                <div class="popup" @click.stop="">
                    <img class="close" src="/static/sem/close.png" alt="" @click="closeMask">
                    <div class="head">
                        <div class="init-content">
                            <img class="head-img" src="/static/sem/font_head.png" />
                            <div class="desc">选址评估更快更专业</div>
                        </div>
                    </div>
                    <div class="from">
                        <img src="/static/sem/submit_content.png" alt="">
                        <div class="form-wrap">
                            <div class="input-wrap">
                                <div class="requred">手机号<span v-if="phoneErr">{{phoneErr}}</span></div>
                                <input class="phone-input" maxlength="11" v-model="phone" placeholder="请输入手机号"/>
                            </div>
                            <div class="input-wrap">
                                <div class="requred">验证码<span v-if="vcodeErr">验证码错误或过期</span></div>
                                <div class="vcode-wrapper">
                                   <div class="vcode-input">
                                     <input placeholder="请输入验证码" v-model="vcode" maxlength="10"/>
                                   </div>
                                    <div class="vcode" :class="{disable : time !== 60}">
                                        <span v-if="time === 60" @click="sendCode">{{vcodeText}}</span>
                                        <span v-else>{{time}}s</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="click-btn" @click="submitClick">立即领取</div>
                    </div>
                </div>
            </div>
            <div v-if="isError" @click="closeMask">
                <div class="mask"></div>
                <div class="popup" @click.stop="">
                    <img class="close" src="/static/sem/close.png" alt="" @click="closeMask">
                    <div class="head">
                        <div class="init-content">
                          <img class="head-img" src="/static/sem/font_head.png" />
                            <div class="desc">选址评估更快更专业</div>
                        </div>
                    </div>
                    <div class="from">
                        <img src="/static/sem/submit_content.png" alt="">
                        <div class="from-error-wrap">
                        <div class="header-box">
                          <img class="sorry-icon" src="static/sem/sorry-icon.png" alt="">
                          <div class="error-tip">
                            <p class="error-size1">抱歉！您已注册过上上参谋备份</p>
                            <p class="error-size2">无法领取该权益</p>
                          </div>
                        </div>
                        <img class="wx-qrCode-img" src="static/sem/wx-qrCode-img.png" alt="" @click="goWeChat">
                        <img class="add-text" src="static/sem/newH5/add-text.png" alt="">
                        <img class="add-btn" src="static/sem/newH5/add-btn.png" alt="" @click="goWeChat">
                    </div>
                    </div>
                </div>
            </div>
            <div v-if="showCodePopup" @click="closeMask2">
                <div class="mask"></div>
                <div class="popup" @click.stop="">
                    <img class="close" src="/static/sem/close.png" alt="" @click="closeMask2">
                    <div class="head2">
                        <div class="init-content">
                            <div class="from form2">
                                <div class="from-error-wrap">
                                    <img class="wx-qrCode-img" src="static/sem/wx-qrCode-img.png" alt="">
                                    <img class="add-text" src="static/sem/newH5/add-text.png" alt="">
<!--                                    <img class="add-btn" src="static/sem/newH5/add-btn.png" alt="" @click="goWeChat">-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img v-show="!firstLoading" class="entrance-icon" src="/static/sem/newH5/entrance-icon.png" @click="handlePopup" alt="">
    </div>
</template>

<script>
let picNum = 0
import { isPhoneNo } from '@/utils/index.js'
import md5 from 'md5';
export default {
    data() {
        return {
            firstLoading: true,
            sendLoading:false,
            timer: null,
            vcodeText: '发送验证码',
            keyWordId: '',
            sourceChannelType: null,
            phone: '',
            phoneErr: '',
            vcode: '',
            vcodeErr: '',
            time: 60,
            isShow: false,
            isError:false,
            showCodePopup:false,
            isReceived: 0,
            contentList: [
                { level: '供不应求', type: '粥/粉/面', nums: '217家', proposal: '适合再开2家' },
                { level: '供不应求', type: '快餐厅', nums: '259家', proposal: '适合再开2家' },
                { level: '供不应求', type: '烧烤', nums: '46家', proposal: '适合再开2家' },
                { level: '供不应求', type: '奶茶饮品', nums: '80家', proposal: '适合再开2家' },
                { level: '供不应求', type: '包子', nums: '18家', proposal: '适合再开2家' },
            ],
            submitting: false
        }
    },
    mounted() {
        this.keyWordId = this.$route.query.promote
        this.sourceChannelType = this.$route.query.type
        this.timer = setTimeout(() => {
            this.isShow = true
        }, 5000)
    },
    beforeDestroy() {
        clearTimeout(this.timer)
    },
    methods: {
        imageLoadDone() {
            picNum++;
            if (picNum >= 1) {
                this.firstLoading = false;
            }
        },
        handlePopup(){
            this.showCodePopup=true
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 6310100, 6310101)
        },
        btnClick() {
            this.isShow = true
        },
        submitClick() {
            if (this.submitting) return
            this.phoneErr = this.phone ? isPhoneNo(this.phone) ? '' : '手机号错误' : '请填写手机号'
            this.vcodeErr = this.vcode.length !== 6
            if(this.phone && this.vcode && !this.phoneErr && !this.vcodeErr) {
                this.submitting = true
                this.HTTP.saveSemAd({
                    phone: this.phone,
                    keywordId: this.keyWordId,
                    loginUrl: location.href,
                    sourceType: 2,
                    sourceChannelType : this.sourceChannelType,
                    authCode: this.vcode
                }).then(res => {
                    this.submitting = false
                    if(this.sourceChannelType == 4) {
                        // 神马的联调代码
                        utq('track', 'CompleteRegistration', '11231833');
                    }
                    if(res.data){
                        this.swRouter({
                        path: '/sem_success',
                        query: {
                            isReceived: res.data ? 0 : 1
                        }
                    })
                    }else{
                        this.isError=true
                    }

                }).catch(err => {
                    this.submitting = false
                    if(err.code == 189002) {
                        this.vcodeErr = err.msg
                    } else {
                        this.$toast.fail('系统错误')
                    }
                })
            } else {
              this.submitting = false
            }
        },
        sendCode() {
            let timer = null;
            const fun = () => {
                if (this.time > 0) {
                    this.time -= 1;
                    timer = setTimeout(() => {
                        fun();
                    }, 1000);
                } else {
                    this.time = 60;
                    this.vcodeText = '重新发送'
                    timer && clearTimeout(timer);
                }
            }
            this.phoneErr = this.phone ? isPhoneNo(this.phone) ? '' : '手机号错误' : '请填写手机号'
            if(this.phoneErr) return
            if(this.sendLoading) return;
            this.sendLoading=true;
            this.sendMsg().then(() => {
                this.$toast({
                    message: '发送成功',
                    position: 'middle',
                });
                fun();
            }).catch((e) => {
                if (e.msg) {
                    this.$toast({
                        message: e.msg,
                        position: 'middle',
                    });
                }
            }).finally(()=>{
                this.sendLoading=false;
            });
        },
        sendMsg () {
            let data = {
                phone: this.phone,
            };
            let sign = md5(JSON.stringify(data) + '51cbb163-2214-48f0-8d4a-c0ed217f40a7');
            data.sign = sign;
            return this.HTTP.sendLoginSmsV2Bd(data);
        },
        closeMask() {
            this.isShow = false
            this.isError=false
        },
        closeMask2() {
            this.showCodePopup = false
        },
        goWeChat(){
            window.location.href='https://work.weixin.qq.com/ca/cawcde6ffd7c63eb32'
            // this.JSBridge.callHandler( 'onNormalizingCall', {
            //     type  : 7,
            //     url   : 'https://work.weixin.qq.com/ca/cawcde6ffd7c63eb32',
            // } );
            this.JSBridge.BTNCLICK(this.$route.meta.id, null, 6310100, 6310102)
        }
    }
}
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import "@/styles/index.scss";
.wrapper {
    height: 100vh;
}
.entrance-icon{
    width: vw2(101);
    height: vw2(40);
    position: fixed;
    right: 0;
    bottom: vw2(80);
}
.company-des {
  width: 100%;
  height: vw(65);
  background: #181E2B;
  font-weight: 400;
  color: rgba(255,255,255,0.42);
  font-size: vw(18);
  line-height: vw(65);
  text-align: center;
}
.head {
    position: relative;
    .vip-btn {
      position: absolute;
      height: vw(42);
      right: vw(42);
      bottom: vw(94);
    }
    .btn2 {
      position: absolute;
      width: vw(650);
      height: vw(80);
      background: #347FFF;
      border-radius: vw(8);
      bottom: vw(50);
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      color: #FFFFFF;
      font-size: vw(28);
      text-align: center;
      line-height: vw(80);
    }
    .btn4 {
      position: absolute;
      width: vw(420);
      height: vw(80);
      bottom: vw(50);
      left: 50%;
      transform: translateX(-50%);
      border-radius: vw(8);
      border: vw(1) solid #347FFF;
      font-weight: 500;
      color: #347FFF;
      font-size: vw(28);
      text-align: center;
      line-height: vw(80);
    }
    .btn3 {
      bottom: vw(40);
    }
    .btn5 {
      bottom: vw(70);
    }
    .btn {
      position: absolute;
      height: vw(80);
      bottom: vw(50);
      left: 50%;
      transform: translateX(-50%);
    }
}
.bg_2 {
    position: relative;
    .bg-btn {
        width: vw(273);
        height: vw(95);
        position: absolute;
        left: vw(95);
        top: vw(780)
    }
    .bg-btn2 {
        left: vw(380);
    }
    .example-btn {
        width: vw(590);
        height: vw(145);
        position: absolute;
        left: vw(80);
        top: vw(890)
    }
    .example-btn2 {
        top: vw(1050)
    }
}
.bg_3 {
    position: relative;
    .check {
        width: vw(110);
        height: vw(40);
        position: absolute;
        right: vw(60);
        bottom: vw(46);
    }
    .swipe {
        position: absolute;
        left: vw(50);
        bottom: vw(46);
        width: vw(600);
        height: vw(40);
    }
    .content {
        height: vw(40);
        font-size: vw(28);
        color:  rgba(157, 159, 166, 1);
        line-height: vw(40);
        display: flex;
        span {
            margin-right: vw(10);
            height: vw(40);
            text-align: center;
            display: block;
        }
        .type {
            width: vw(120);
        }
        .nums {
            width: vw(80);
        }
    }
}
.bg_4 {
    position: relative;
    .big-btn {
        width: vw(645);
        height: vw(140);
        position: absolute;
        left: vw(55);
        top: vw(155)
    }
    .normal-btn {
        width: vw(315);
        height: vw(140);
        position: absolute;
        left: vw(50);
        top: vw(310)
    }
    .normal-btn2 {
        left: vw(380);
    }
    .normal-btn3 {
        top: vw(480);
    }
    .normal-btn4 {
        left: vw(380);
        top: vw(480);
    }
    .custom-btn {
        width: vw(125);
        height: vw(45);
        position: absolute;
        right: vw(60);
        bottom: vw(255)
    }
}
 .van-image {
    display: block;
    position: relative;
}
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);
    .close {
        width: vw(64);
        height: vw(64);
        position: absolute;
        right: vw(10);
        top: vw(-94);
    }
    .head {
        width: vw(586);
        height: vw(180);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: url('/static/sem/popup_head.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
        }
        .init-content {
            position: absolute;
            top: vw(42);
            left: vw(32);
          .head-img {
            display: block;
            width: vw2(140);
            height: auto;
          }
            .app {
                width: vw(354);
                height: vw(60);
                background-image: url('/static/sem/font_app.png');
                background-repeat: no-repeat;
                background-size: cover;
            }
            .desc {
                font-size: 14px;
                color: #fff;
                line-height: vw2(20);
                margin-top: 9px;
            }
            .init-title {
                background-image: url('/static/sem/font_title.png');
            }
        }
    }
    .head2{
        width: vw2(303);
        height: vw2(408);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: url('/static/sem/newH5/wx-qrcode-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        z-index: -1;
        .from {
        position: relative;
        top: vw(-40);
        img {
            width: vw(606);
            height: vw(638);
        }
        .form-wrap {
            position: absolute;
            top: vw(40);
            left: vw(60);
            .input-wrap{
                position: relative;
                font-size: $font_373;
                height: vw(152);
                border-radius: vw(8);
                width: vw(484);
            }
        }
        .from-error-wrap{
            margin-top: vw2(100);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          left: 50%;
          transform: translateX(-50%);

          .wx-qrCode-img{
            width: vw2(180);
            height: vw2(180);
          }
          .add-text{
            margin-top: vw2(8);
            width: vw2(130);
            height: vw2(37);
          }
          .add-btn{
            margin-top: vw2(10);
            width: vw2(219);
            height: vw2(40);
          }
        }
    }
    }
    .from {
        position: relative;
        top: vw(-40);
        img {
            width: vw(606);
            height: vw(638);
        }
        .form-wrap {
            position: absolute;
            top: vw(40);
            left: vw(60);
            .input-wrap{
                position: relative;
                font-size: $font_373;
                height: vw(152);
                border-radius: vw(8);
                width: vw(484);
                .requred {
                    position: relative;
                    font-size: $font_373;
                    padding-left: vw(16);
                    margin-top: vw(40);
                    &::after {
                        content: "*";
                        position: absolute;
                        left: 0;
                        color: rgba(255, 89, 0, 1);
                    }
                    span {
                        margin-left: vw(40);
                        font-size: $font_320;
                        color: rgba(255, 89, 0, 1);
                    }
                }
                input{
                    height: vw(100);
                    background: #E7EFFC;
                    border: none;
                    margin-top: vw(32);
                    padding-left: vw(20);
                }
                .phone-input {
                  width: 100%;
                }
                .vcode-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .vcode-input {
                    flex: 1;
                    input {
                      width: 100%;
                      box-sizing: border-box;
                    }
                }
                .vcode{
                    width: vw(178);
                    height: vw(104);
                    font-size: $font_320;
                    color: #fff;
                    line-height: vw(104);
                    border: none;
                    cursor: pointer;
                    box-sizing: border-box;
                    margin-top: vw(32);
                    background-image: linear-gradient(to right, #1177FE, #4EB5FF);
                    text-align: center;
                }
                .disable {
                    background: #AEB9CB;
                }
            }
        }
        .from-error-wrap{
            position: absolute;
            left: vw2(25);
            top: vw2(20);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .header-box{
            display: flex;
            justify-content: center;
            align-items: center;
            .sorry-icon{
              width: vw2(65);
              height: vw2(64);
            }
            .error-tip{
                font-weight: bold;
                font-size: vw2(13);
                color: #101E3D;
                .error-size2{
                  font-size: vw2(15);
                  line-height: 26px;
                }
            }

          }
          .wx-qrCode-img{
            width: vw2(125);
            height: vw2(125);
          }
          .add-text{
            margin-top: vw2(8);
            width: vw2(130);
            height: vw2(37);
          }
          .add-btn{
            margin-top: vw2(10);
            width: vw2(219);
            height: vw2(40);
          }
        }
    }
    .click-btn {
        position: absolute;
        bottom: vw(60);
        left: 50%;
        font-size: $font_373;
        transform: translateX(-50%);
        width: vw(546);
        height: vw(104);
        border-radius: vw(54);
        font-weight: normal;
        cursor: pointer;
        color: #fff;
        background-image: linear-gradient(to right, #1177FE, #4EB5FF);
        text-align: center;
        line-height: vw(104);
    }

}
.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(0,0,0,.5);
}
</style>
