<template>
    <div></div>
</template>

<script>
export default {
    data() {
        return {
            userId: '',
            id: '',
        }
    },
    mounted() {
        const query = this.$route.query
        this.userId = query.userId
        this.Id = query.platformShopCode
        this.getUrlLink()
    },
    methods: {
        getUrlLink() {
            this.HTTP.getUrlLink({
                platformShopCode: this.Id,
                userId: this.userId   
            }).then(res => {
                if(res.code == 0 && res.data) {
                    window.location.href = res.data.link

                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
