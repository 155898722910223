<template>
    <Wraper title="城市占比" :getMore="true" moreTips="查看城市分布" @openMoreData="openMoreData" v-if="bgmData.length > 0">
        <div class="search-chart-city">
            <CityRatioPie :dataObject="bgmData" />
        </div>
    </Wraper>
</template>

<script>
import Wraper from "../../echarts/wraper.vue";
import CityRatioPie from "../../echarts/cityRatioPie.vue";

export default {
    components: { Wraper, CityRatioPie },
    props: {
        bgmData: {
            type: Array,
            default: [],
        },
        cityDataList: {
            type: Array,
            default: [],
        },
    },
    methods: {
        openMoreData() {
            const data = JSON.stringify({
                key: "setReportInfo",
                value: JSON.stringify({
                    ratioPieInfo: this.bgmData,
                    cityDataList: this.cityDataList,
                }),
            });
            this.JSBridge.callHandler("putString", data);
            this.swRouter("/reportView/shopDistribute");
        },
    },
};
</script>
<style lang="scss" scoped>
.search-chart-city {
    position: relative;
    width: 100%;
    height: 200px;
}
</style>
