<template>
    <div class="coupon-container">
        <StateBar />
        <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true">
            <div slot="right" v-show="!loading" class="coupon--my" @click="$router.push( '/coupons' )">我的优惠券</div>
        </TitleHeader>

        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <AutoView v-if="!loading" :header="true" :footer="false" class="coupon--view">
            <van-image class="coupon-banner" fit="contain" :src="imgUrl" />
            <div class="coupon-wrap">
                <div class="coupon-items" v-for="(item,index) in list" :key="index">
                    <div class="coupon-l">
                        <div class="coupon-price">
                            <div class="price" :class="{'get': item.receiveStatus==1}">{{item.discountAmount}}</div>
                            <div class="condition">{{item.discountAmountDesc}}</div>
                        </div>
                        <div class="cut-line"></div>
                        <div class="coupon-time">
                            <div class="label">有效期</div>
                            <div class="label">{{item.validDateDesc}}</div>
                            <div class="left">限量{{item.totalStock}}张</div>
                        </div>
                    </div>
                    <div class="coupon-r" :class="{'none':item.stockRate>=100}">
                        <div style="padding:1vw;">
                            <!-- <div class="loop-pie">
                                <div class="loop-pie-line loop-pie-r">
                                    <div class="loop-pie-c loop-pie-rm" :class="{'get': item.receiveStatus==1, 'none': item.stockRate>=100}" :ref="getLoopRcRef(index)"></div>
                                </div>
                                <div class="loop-pie-line loop-pie-l">
                                    <div class="loop-pie-c loop-pie-lm" :class="{'get': item.receiveStatus==1, 'none': item.stockRate>=100}" :ref="getLoopLcRef(index)"></div>
                                </div>
                                <div class="loop-label">
                                    <span class="label1" v-if="item.stockRate>=100">已抢完</span>
                                    <span class="label2" v-else-if="item.receiveStatus==1">已领取</span>
                                    <span class="label1" v-else>已抢<br/>{{item.stockRate}}%</span>
                                </div>
                            </div> -->
                            <van-circle class="loop-pie" v-model="item.model" :rate="item.stockRate" :speed="100" :text="getCircleText(item)" color="#FF5050" layer-color="#AAAAAA" stroke-width="60" style="width:13vw;height:13vw;" />
                            <div class="coupon-btn" v-if="item.receiveStatus==0&&item.stockRate<100" @click="getCoupon(item)">立即领取</div>
                        </div>
                    </div>
                </div>
                <div class="s-tips">
                    活动时间：<br />
                    2021年4月23日-4月25日 23:59:59<br />
                </div>
                <div class="s-tips">
                    活动规则：<br />
                    1.以上优惠券适用于除课程以外的全场商品<br />
                    2.优惠券应在券面标明有效期内使用，过期作废<br />
                    3.优惠券不可叠加使用，不可兑换现金<br />
                    4.平台有权回收涉及利用BUG等不正当手段获取的优惠券，如优惠券已生成订单，则订单将被取消，优惠券不予返还
                </div>
            </div>
        </AutoView>
    </div>
</template>

<script>
import index from '../../components/AreaPicker/index.vue';
export default {
    components: { index },
    data() {
        return {
            loading: false,
            activeId: '',
            title: '活动中心',
            imgUrl: '',
            list: [
                {couponId: 840, couponName: '五一活动专享（满2000-500）', discountAmount: '满2000减500', receiveStatus: 0, stock: 100, stockRate: 0, validDateDesc: '2021-04-23-2021-04-23'},
                {couponId: 841, couponName: '五一活动专享（满2000-500）', discountAmount: '满2000减500', receiveStatus: 0, stock: 200, stockRate: 17, validDateDesc: '2021-04-23-2021-04-23'},
                {couponId: 842, couponName: '五一活动专享（满2000-500）', discountAmount: '满2000减500', receiveStatus: 0, stock: 300, stockRate: 34, validDateDesc: '2021-04-23-2021-04-23'},
                {couponId: 843, couponName: '五一活动专享（满2000-500）', discountAmount: '满2000减500', receiveStatus: 0, stock: 400, stockRate: 76, validDateDesc: '2021-04-23-2021-04-23'},
            ]
        }
    },
    created() {
        const id = this.$route.params.id;
        if ( ! id ) {
            this.$toast( '无效活动id，即将返回首页！' );
            this.$router.push( '/' );
            return;
        }
        this.activeId = id;
        this.HTTP.getActiveCoupon( id ).then( ( res ) => {
            this.loadActive( res.data );
        } ).catch( ( e ) => {
            this.$toast( '网络不稳定，请稍候重试！' );
        } );
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
    mounted() {
        // setTimeout( () => {
        //     this.setCouponPercent();
        // }, 200 )
    },
    methods: {
        loadActive( data ) {
            if ( data == undefined ) {
                return;
            }
            console.log( 'active data:', data );
            const { activeItemResList, activeName, bannerImages } = data;
            // this.list = [];
            // activeItemResList.forEach( item => {
            //     this.list.push( {
            //         ...item
            //     } )
            // });
            this.list = activeItemResList;
            // this.setCouponPercent();
            if ( bannerImages ) {
                this.imgUrl = bannerImages[0];
            }
            console.log( 'load active list:', this.list );
            this.title = activeName;
        },
        getLoopRcRef( index ) {
            return 'loop_rc_ref_' + index;
        },
        getLoopLcRef( index ) {
            return 'loop_lc_ref_' + index;
        },
        setCouponPercent() {
            for ( let i = 0; i < this.list.length; ++i ) {
                const item = this.list[i];
                this.setLoopPercent( i, item.stockRate, 100 );
            }
        },
        setLoopPercent( index, value, max ) {
            let rotate = (value / max) * 360
            let rotateRight = 0
            let rotateLeft = 0
            if (rotate < 180) {
                rotateRight = rotate + (-45)
            } else {
                rotateRight = 135
                rotateLeft = (rotate - 180 - 45)
                const lcRefName = this.getLoopLcRef( index );
                const lcRefArray = this.$refs[lcRefName];
                if ( lcRefArray ) {
                    if ( Array.isArray( lcRefArray ) ) {
                        const lcRef = lcRefArray[0];
                        lcRef.setAttribute( 'style', `transform:rotate(${rotateLeft}deg)` )
                    } else {
                        lcRefArray.setAttribute( 'style', `transform:rotate(${rotateLeft}deg)` )
                    }
                }
            }
            const rcRefName = this.getLoopRcRef( index );
            const rcRefArray = this.$refs[rcRefName];
            if ( rcRefArray ) {
                if ( Array.isArray( rcRefArray ) ) {
                    const rcRef = rcRefArray[0];
                    rcRef.setAttribute( 'style', `transform:rotate(${rotateRight}deg)` )
                } else {
                    rcRefArray.setAttribute( 'style', `transform:rotate(${rotateRight}deg)` )
                }
            }
        },
        getCoupon( item ) {
            this.HTTP.receiveCoupon( this.activeId, item.couponId ).then( res => {
                // console.log( 'getCoupon:', res );
                if ( res.code == 0 ) {
                    item.receiveStatus = 1;
                } else if ( res.code == 260018 ) {
                    this.$toast( '库存不足' );
                    item.stockRate = 100;
                } else {
                    this.$toast( res.msg );
                }
            } ).catch( (e) => {
                // console.log( 'exception:', e );
                if (e.code == 260018) {
                    this.$toast( '库存不足' );
                    item.stockRate = 100;
                } else {
                    this.$toast( '网络不稳定，请稍候重试！' );
                }
            } )
        },
        getCircleText( item ) {
            // <span class="label1" v-if="item.stockRate>=100">已抢完</span>
            // <span class="label2" v-else-if="item.receiveStatus==1">已领取</span>
            // <span class="label1" v-else>已抢<br/>{{item.stockRate}}%</span>
            if ( item.stockRate>=100 ) {
                return '已抢完';
            } else if ( item.receiveStatus == 1 ) {
                return '已领取';
            }
            return '已抢' + item.stockRate + '%';
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.coupon-container {
    height: 100vh;
    .coupon--view {
        position: relative;
        width: 100%;
        padding-bottom: 5vw;
        box-sizing: border-box;
        background-color: #AE3325;

        .coupon-banner {
            position: relative;
            width: 100%;
            // height: 30.67vw;
        }

        .coupon-wrap {
            position: relative;
            padding: 0 3.2vw;
        }

        .coupon-items {
            position: relative;
            width: 100%;
            height: 25vw;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
            margin-top: 4vw;

            display: flex;
            align-items: center;

            .coupon-l {
                position: relative;
                width: 75%;
                height: 100%;
                border-radius: 1.33vw 0 0 1.33vw;
                border-right: 1px dashed #EEEEEE;
                background-color: #FFFFFF;
                display: flex;
                align-items: center;

                .coupon-price {
                    width: 38%;
                    .price {
                        color: #FF5050;
                        font-size: 5.33vw;
                        font-weight: normal;
                        line-height: 7.2vw;
                        text-align: center;
                    }
                    .condition {
                        margin-top: 1vw;
                        color: #AAAAAA;
                        font-size: 3.2vw;
                        font-weight: normal;
                        line-height: 4.27vw;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
                .cut-line {
                    width: 1px;
                    height: 20vw;
                    background-color: #F2F2F2;
                }

                .coupon-time {
                    margin-left: 4.8vw;
                    width: 61%;
                    color: #9E9E9E;
                    font-size: 3.2vw;
                    font-weight: normal;
                    line-height: 4.27vw;
                    text-align: left;
                    .label {
                        margin-top: 1vw;
                    }
                    .left {
                        font-size: 2.67vw;
                        line-height: 3.73vw;
                        margin-top: 1vw;
                    }
                }
            }

            .coupon-r {
                position: relative;
                width: 25%;
                height: 100%;
                background-color: #FFFBEE;
                border-radius: 0 1.33vw 1.33vw 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &.none {
                    background-color: #E5E5E5;
                }
                .loop-pie {
                    position:relative;
                    width:14vw;
                    height:14vw;
                    margin: 0 auto;

                    .loop-label {
                        position: relative;
                        z-index: 3;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-size: 2.67vw;
                        font-weight: normal;
                        line-height: 3.2vw;
                        text-align: center;
                        .label1 {
                            color: #AAAAAA;
                        }
                        .label2 {
                            color: #FF5050;
                            white-space: nowrap;
                        }
                    }
                    .loop-pie-line {
                        position:absolute;
                        width:50%;
                        height:100%;
                        top:0;
                        overflow:hidden;
                    }
                    .loop-pie-l {
                        top:0px;
                        left:0px;
                    }
                    .loop-pie-r {
                        top:0px;
                        transform:rotate(180deg);
                        right:0px;
                    }
                    .loop-pie-c {
                        width:200%;
                        height:100%;
                        border:4px solid transparent;
                        border-radius:50%;
                        position:absolute;
                        box-sizing:border-box;
                        top:0;
                        transform:rotate(-45deg);
                        transition: all 1s;
                    }
                    .loop-pie-rm {
                        border-top:4px solid rgba(170,170,170,0.6);
                        border-left:4px solid rgba(170,170,170,0.6);
                        border-bottom:4px solid #FF5050;
                        border-right:4px solid #FF5050;
                        &.get {
                            border-top:4px solid rgba(255,80,80,0.6);
                            border-left:4px solid rgba(255,80,80,0.6);
                            border-bottom:4px solid rgba(255,80,80,0.6);
                            border-right:4px solid rgba(255,80,80,0.6);
                        }
                        &.none {
                            border-top:4px solid rgba(170,170,170,0.6);
                            border-left:4px solid rgba(170,170,170,0.6);
                            border-bottom:4px solid rgba(170,170,170,0.6);
                            border-right:4px solid rgba(170,170,170,0.6);
                        }
                    }
                    .loop-pie-lm {
                        border-top:4px solid rgba(170,170,170,0.6);
                        border-left:4px solid rgba(170,170,170,0.6);
                        border-bottom:4px solid #FF5050;
                        border-right:4px solid #FF5050;
                        &.get {
                            border-top:4px solid rgba(255,80,80,0.6);
                            border-left:4px solid rgba(255,80,80,0.6);
                            border-bottom:4px solid rgba(255,80,80,0.6);
                            border-right:4px solid rgba(255,80,80,0.6);
                        }
                        &.none {
                            border-top:4px solid rgba(170,170,170,0.6);
                            border-left:4px solid rgba(170,170,170,0.6);
                            border-bottom:4px solid rgba(170,170,170,0.6);
                            border-right:4px solid rgba(170,170,170,0.6);
                        }
                    }
                }
                .coupon-btn {
                    position: relative;
                    padding: 2px 5px;
                    background-color: #FF5050;
                    color: #FFFFFF;
                    font-size: 2.67vw;
                    font-weight: normal;
                    line-height: 3.73vw;
                    text-align: center;
                    border-radius: 2.13vw;
                    cursor: pointer;
                }
            }
        }

        .s-tips {
            margin-top: 5.87vw;
            font-size: $font_320;
            // color: $c-gray1;
            // color: $c-text;
            color: $c-line;
            &.s-tips_inline {
                margin-top: 0;
                padding: 0 $padding_3 $padding_3;
            }
        }
    }
}
</style>
