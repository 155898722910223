<template>
    <div class="report" :class="{ ready: completed }" @click="onTap">
        <div v-if="showDownload" class="download" @click.stop="onDownload">
            <img class="download-icon" src="/static/icon/download@2x.png" />
            <span class="download-label">下载</span>
        </div>
        <div class="report--content">
            <div class="time-wrap">
                <span class="time">{{ item.gmtCreateFormat || "刚刚" }}</span>
                <!-- <span class="time" v-if="showCreating" style="float:right">文件生成中，请耐心等待</span> -->
            </div>
            <h1>
                <span class="card-tag">
                    {{ reportType[item.stdQuestionId] || "其他" }}
                </span>
                {{ item.name || "---" }}
                <span v-if="item.status === 6 && !isRead" class="circle"></span>
            </h1>
            <!-- <div v-if="item.reportModelInstanceOverviewList" class="label">
                <span v-for="( label, index ) in item.reportModelInstanceOverviewList" :key="index">{{ label.name }}</span>
            </div> -->
            <div v-if="showInputs" class="desc s-radius__s">
                <p v-for="(field, index) in item.inputList" :key="index">
                    <span v-if="field.name == '商铺室内照片'">
                        <strong>{{ field.name }}：</strong>
                        <br />
                        <img :src="field.value" alt="" />
                    </span>
                    <span v-else
                        ><strong>{{ field.name }}：</strong>{{ field.value || "-" }}</span
                    >
                </p>
            </div>
            <van-divider v-else />
            <van-cell v-if="showIM" :title="title" :value="aboutTime" class="info" @click.stop="onTapIM">
                <template #extra v-if="showEvaluate">
                    <div class="evaluate" @click.stop="goEvaluate"><span>评价</span></div>
                </template>
            </van-cell>
            <van-cell v-else :title="title" :value="getDesc" class="text">
                <template #extra v-if="showEvaluate">
                    <div class="evaluate" @click.stop="goEvaluate"><span>评价</span></div>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
// import { toTimeStr } from '@/utils/filters';
const reportType = {
    10001: "开店选址评估",
    10003: "加盟找品牌",
    10004: "加盟找位置",
    10005: "客群洞察",
    10006: "竞争分析",
    10011: "商圈数据",
    10012: "店铺经营分析",
    10013: "周边调研",
    10014: "周边数据",
    10015: "选品类",
    10018: "行业热力图",
    10019: "人口规模热力图",
    10026: "开店位置推荐",
    10030: "商场查询",
    10031: "城市查询",
    10032: "周边查询",
    10037: "外卖查询",
    10084: "外卖店选址评估",
    10092: "社区店选址评估",
    10106: "商铺评估报告",
    10124: "数据对比",
    10119: "AI店铺设计-AI全店设计",
    10130: "AI店铺设计-AI效果图"
};

export default {
    name: "report",
    props: {
        item: Object // 报告对象
    },
    data: function() {
        return {
            // isDownload: false,
            reportType,
            isRead: false
        };
    },
    computed: {
        title() {
            const map = {
                1: "报告生成中",
                2: "报告已就绪",
                3: "报告已就绪"
            };
            return map[this.item.displayStatus];
        },
        aboutTime() {
            if (this.item.stdQuestionId == 10119) {
                return !this.completed ? "预计1-3分钟内推送" : "专家答疑";
            } else {
                return !this.completed ? "预计24个小时内推送(周末、节假日除外)" : "专家答疑";
            }
        },
        getDesc() {
            return !this.completed ? "预计24个小时内推送(周末、节假日除外)" : "";
        },
        completed() {
            return this.item.displayStatus !== 1;
        },
        showInputs() {
            // 非洼地报告展示输入参数列表
            return this.item && this.item.inputList && this.item.stdQuestionId !== 10124; // && this.item.stdQuestionId !== 10004;
        },
        showIM() {
            return [10002, 10004, 10014].includes(this.item.stdQuestionId) === false;
        },
        showEvaluate() {
            return this.completed && !sessionStorage.getItem("platform") && this.item.reportCommentStatus;
        },
        showDownload() {
            return !sessionStorage.getItem("platform") && this.item.reportFileInfo;
        },
        showCreating() {
            return !this.item.reportFileInfo && [18, 19, 20].includes(this.item.type);
        }
    },
    methods: {
        onTap(event) {
            if (this.completed === false) return;
            this.isRead = true;
            this.$emit("tap", this.item.id, this.item.stdQuestionId);
        },
        goEvaluate() {
            console.log(this.item);
            const name = this.reportType[this.item.stdQuestionId] || "";
            const { id } = this.item;
            const item = this.item.inputList.find(el => el.name === "位置范围");
            const item2 = this.item.inputList.find(el => el.name === "行政区");
            let title = "";
            if (item) {
                title = item.value;
            } else if (item2) {
                title = item2.value;
            }
            this.swRouter(`/report/comment/${id}?name=${name}&title=${title || ""}`);
        },
        onTapIM() {
            this.$emit("expertsAnswer", this.item.id);
            //   if (this.completed === false) return;
            //   if (!window.isClient) {
            //     this.$toast("非客户端环境！");
            //     return;
            //   }
            //   if (window._appCode >= 175) {
            //     this.JSBridge.callHandler("onNormalizingCall", {
            //       type: 5,
            //       url: "ONLINE_SERVICE",
            //       params: JSON.stringify({
            //         cardCode: "CUSTOMER_REPORT",
            //         cardId: this.item.id,
            //         title: "专家解读",
            //         type: 4
            //       })
            //     });
            //   } else {
            //     this.JSBridge.callHandler(
            //       "callLiveChat",
            //       {
            //         type: "report",
            //         data: {
            //           id: this.item.id
            //         }
            //       },
            //       st => {
            //         if (typeof st === "string") st = st === "true";
            //         if (st) {
            //           this.$emit("tap", this.item.id);
            //         }
            //       }
            //     );
            //   }
        },
        onDownload() {
            // if (this.isDownload) {
            //   return;
            // }
            this.HTTP.createDownload(this.item.id)
                .then(res => {
                    if (window.isClient) {
                        // this.isDownload = true;
                        this.$toast("已创建下载！");
                        this.gotoDownload();
                    } else {
                        this.$toast("非客户端环境！");
                    }
                })
                .catch(res => {
                    this.$toast("发生错误，请稍候重试！");
                });
        },
        gotoDownload() {
            if (window.isClient) {
                if (this.item.stdQuestionId === 10014) {
                    this.JSBridge.callHandler("gotoPage", "my-download_tab1");
                } else {
                    this.JSBridge.callHandler("gotoPage", "my-download");
                }
            } else {
                this.$toast("非客户端环境！");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.report {
    @extend .l-panel;
    position: relative;
    margin-bottom: $margin;
    padding: 4vw;

    #{&}--content {
        position: relative;
        height: 100%;
        h1 {
            color: $c-text;
            width: 100%;
            font-size: $font_400;
            line-height: $font_560;
            margin: 2.13vw 0 0 0;
            padding-right: 7.73vw;
            box-sizing: border-box;
            vertical-align: middle;
            position: relative;
            .card-tag {
                position: relative;
                top: -0.8vw;
                display: inline-flex;
                align-items: center;
                padding: 0.53vw 1.33vw;
                line-height: normal;
                font-size: $font_267;
                color: #8d5d22;
                background: #fdd6a4;
                border-radius: 1px 5px 1px 5px;
                margin-right: 1.07vw;
            }
            .circle {
                width: 1.867vw;
                height: 1.867vw;
                position: absolute;
                right: 5vw;
                top: 1.5vw;
                display: inline-block;
                border-radius: 100%;
                background-color: #fe3f00;
            }
        }
        .label {
            overflow: hidden;
            margin-top: 2.13vw;
            width: 100%;
            height: 5.6vw;

            span {
                float: left;
                border-radius: $radius-xs;
                background-color: $c-pageBg;
                color: $c-info;
                font-size: $font_240;
                line-height: $font_560;
                height: $font_560;
                padding: 0 2.67vw;
                margin-right: 2.13vw;
            }
        }
        .desc {
            margin: $margin 0;
            padding: $margin;
            background-color: $c-pageBg;
            font-size: $font_293;
            line-height: $font_400;
            img {
                margin-top: 3px;
                width: 35px;
                height: 35px;
                border-radius: 2px;
                border: 1px solid #abb3c1;
                object-fit: cover;
            }
        }
        .time-wrap {
            display: flex;
            justify-content: space-between;
        }
        .time {
            font-size: $font_293;
            line-height: $font_400;
            color: $c-info;
        }
        .info,
        .text {
            padding: 0;
            font-size: $font_320;
            line-height: $font_440;
            color: $c-warn;
            font-weight: bold;

            ::v-deep .van-cell__title {
                flex: none;
                span {
                    display: inline-block;
                    padding-left: $padding;
                    background: url(/static/icon/clock.png) no-repeat left center;
                    background-size: 3.68vw;
                }
            }

            ::v-deep .van-cell__value {
                /*flex: 2;*/
                flex: 1;
                font-size: $font_293;
                line-height: $font_440;
                font-weight: normal;
            }
        }

        ::v-deep .van-divider {
            margin: $margin 0;
        }
    }

    &.ready {
        h1 {
            background: url(/static/icon/arrow_right_black.png) no-repeat right center;
            background-size: 4vw;
        }
        ::v-deep .van-cell {
            overflow: visible;
            .van-cell__value {
                overflow: inherit;
            }
            .evaluate {
                position: relative;
                span {
                    display: block;
                    min-width: 17.067vw;
                    height: 6.4vw;
                    top: 0;
                    right: 0;
                    margin-top: -0.5vw;
                    padding: 0 3.46vw 0 7.2vw;
                    font-size: 3.2vw;
                    line-height: 6.4vw;
                    color: #dc8c29;
                    box-shadow: 0 0 0 1px #fbe5c9;
                    border-radius: 1.3vw;
                    background: url(/static/icon/pj.png) no-repeat 1.87vw center;
                    background-size: 3.2vw;
                    font-weight: 400;
                    box-sizing: border-box;
                    margin-left: 2vw;
                }
            }
        }
        .info ::v-deep .van-cell__title span,
        .text ::v-deep .van-cell__title span {
            color: $c-main;
            background-image: url(/static/icon/success.png);
        }
        .info ::v-deep .van-cell__value {
            position: relative;
            span {
                position: absolute;
                width: auto;
                height: 6.4vw;
                top: 0;
                right: 0;
                margin-top: -0.5vw;
                padding: 0 3vw 0 6vw;
                font-size: $font_320;
                line-height: 6.4vw;
                color: $c-DC8C29;
                box-shadow: 0 0 0 1px $c-FBE5C9;
                border-radius: $radius-xs;
                background: url(/static/icon/custom.png) no-repeat 1.87vw center;
                background-size: 3.2vw;
            }
        }
    }

    .download {
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: #f8f8f9;
        padding: 1.6vw 2.4vw;
        display: flex;
        align-items: center;
        border-radius: 0 $radius_xs 0 $radius_s;
        z-index: 10;
        .download-icon {
            width: 2.93vw;
            height: 2.93vw;
        }
        .download-label {
            color: #518cdb;
            font-size: $font_320;
            font-weight: $weight_600;
            line-height: $font_400;
            text-align: left;
            margin-left: 0.53vw;
        }
    }
}
</style>
