<template>
    <div class="assess" :class="{ result }">
        <StateBar bgcolor="transparent" />
        <TitleHeader :title="title" color="white" bgcolor="transparent" :back="true" />

        <AutoView ref="view" :header="true" :footer="false" class="assess--view">
            <div v-if="id === '10009' && entity && entity.description" class="descView">
                {{ entity.description }}
                <a href="javascript:;" class="btn" @click="onShowDesc"></a>
            </div>
            <img v-else src="/static/img/assess/title.png" class="bg" />

            <div class="view">
                <div v-if="id !== '10009'" class="tabs">
                    <p>项目</p>
                    <p>指标</p>
                </div>
                <template v-if="code === 'basic'">
                    <div class="item sp">
                        <label class="s-label">
                            <span>租金日商比</span>
                            <p class="inp">{{ getDayRate }}</p>
                        </label>
                    </div>
                    <div class="item">
                        <label class="s-label normal">
                            <span>月租金</span>
                            <input type="tel" v-model="params.rentMonth" placeholder="请输入" maxlength="8" class="inp icon icon-unit" />
                        </label>
                    </div>
                    <div class="item">
                        <label class="s-label normal">
                            <span>日营业额</span>
                            <input type="tel" v-model="params.saleDay" placeholder="请输入" maxlength="8" class="inp icon icon-unit" />
                        </label>
                    </div>
                </template>

                <div v-for="(field, index) of list" :key="index" class="item" :class="{ sp: code !== 'basic' && index === 0 }">
                    <label v-if="id === '10009'" class="s-label ckmod">
                        <span>{{ index + 1 }}.{{ field.itemName }}<i class="icon icon-question" @click="onShowDesc( index )" /></span>
                        <p class="desc" v-if="field.remarks">{{ field.remarks }}</p>
                        <div class="group">
                            <div v-for="(item, ix) of field.indexList" :key="index+'-'+ix" class="checkbox" :class="{ on: form[ index ] === item.indexId }" @click="onChecked( index, item.indexId )"><em/>{{ item.indexName }}</div>
                        </div>
                    </label>
                    <label v-else class="s-label">
                        <span v-html="stringFormat( field.itemName )"></span>
                        <input type="text" placeholder="请选择" readonly :data-ix="index" :value="getValue( field, index )" @click="onTap( field, index )" class="inp icon icon-geo" />
                    </label>
                </div>

                <p v-if="id !== '10009'" class="s-link f-vmt icon icon-question" @click="onShowDesc">项目指标说明</p>
            </div>

            <div class="result">
                <div v-if="result" class="content">
                    <p class="text"><em>评估结论</em>{{ result.text || '' }}</p>
                    <div class="score">
                        <h3 class="value">{{ result.score || '' }}</h3>
                        <p class="label">基础评估得分</p>
                    </div>
                </div>
            </div>

            <div class="assess--footerBar">
                <van-button type="info" block :loading="submitting" @click="onSubmit">
                    <img src="/static/img/assess/text/score.png" class="btn-text" />
                </van-button>
            </div>
        </AutoView>

        <!-- 选项列表 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="columns" :default-index="defaultIndex" @cancel="showPicker = false" @confirm="setValue" />
        </van-popup>

        <!-- 指标说明 -->
        <transition name="fade">
            <div v-show="showDesc" @click.self="showDesc = false" class="assess--desc">
                <div class="l-panel">
                    <div v-if="entity && descs && descs[ entity.moduleCode ]" class="list">
                        <h3 id="desc-title">指标评估说明</h3>
                        <template v-for="( item, index ) of descs[ entity.moduleCode ]">
                            <h4 :id="`desc-${index}`" :key="index">{{index + 1}}.{{ item.name }}</h4>
                            <p :key="'content_' + index" v-html="item.description"></p>
                        </template>
                    </div>
                </div>
            </div>
        </transition>

        <!-- 评分结果 -->
        <van-overlay :show="showResult" :lock-scroll="false" @click="showResult = false" class="assess--result">
            <div class="panel" @click.stop>
                <template v-if="result">
                    <p class="score">{{ result.score }}</p>
                    <p class="title">{{ title }}得分</p>
                    <div v-if="result.text" class="block">
                        <p class="title">评估结论</p>
                        <p class="content">{{ result.text }}</p>
                    </div>
                </template>

                <a href="javascript:;" v-show="next" @click="$router.replace(`/assess/${id}/poster/${hid}/${next.mid}/${next.pid}`)" class="btn red" style="top: 82vw">下一评估项</a>
                <a href="javascript:;" v-show="!next" @click="onGotoSummary" class="btn red" style="top: 82vw">计算评估指数</a>
                <a href="javascript:;" @click="back" class="btn" style="top: 96vw">返回评估列表</a>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'assessIndex',
    data () {
        return {
            title     : '正在加载',
            next      : null,
            showPicker: false,
            showResult: false,
            showDesc  : false,
            disabled  : true,
            submitting: false,

            options     : {},
            columns     : [],
            current     : 0,
            defaultIndex: 0,

            id    : null,
            hid   : null,
            mid   : null,
            code  : null,
            pid   : null,
            entity: null,
            params: {
                historyId  : 0,
                indexIdList: [],
                modelId    : '',
                paramId    : 0,
                rentMonth  : '',
                saleDay    : '',
            },
            descs : {}, // 备注信息
            links : null,
            result: null, // { score: 0, text : '' }
            list  : [],
            form  : [], // 3, 12
        };
    },
    methods: {
        back() {
          if (window.isMini) {
            this.$router.back()
          } else {
            this.commonApi.pageBack()
          }
        },
        loadData () {
            this.loading = true;
            this.id      = this.$route.params.id;
            this.hid     = this.$route.params.history;
            this.mid     = this.$route.params.modelId;
            this.pid     = this.$route.params.paramId || 0;
            const store  = this.$store.getters.assessInfo;
            console.log('store',store)
            if ( store && store.id === this.id && store.links instanceof Array && store.links.length ) {
                this.links = store.links;
                const ix   = this.links.findIndex( ( item ) => !item.socre );
                this.next  = ix >= 0 ? this.links[ ix ] : null;
            }
            this.links   = this.$store.getters.assessInfo;
            this.params.historyId = this.hid;
            this.params.modelId   = this.mid;
            this.params.paramId   = this.pid;
            this.HTTP.getAssessTarget( this.mid, this.pid ).then( ( res ) => {
                this.entity = res;
            } ).catch( ( e ) => {
                this.loading = false;
                this.$toast( '网络连接不稳定，请稍候重试！' );
            } );
        },
        onSubmit () {
            if ( this.disabled ) {
                this.$toast( '请填写/选择必填项！' );
                return;
            }
            if ( this.submitting ) return;
            this.submitting = true;
            this.params.indexIdList = this.form;
            // 发送请求
            this.HTTP.POST( this.entity.url, this.params ).then( ( res ) => {
              console.log('结果',res)
                this.result = {
                    score: res.score,
                    text : res.conclusion,
                };
                this.submitting = false;
                if (res.flag==1) {
                  this.next = {
                    mid: res.nextModuleId,
                    pid: res.nextParamId,
                    socre: 0
                  }
                } else {
                  this.next = null
                }
                this.showResult = true;
            } ).catch( ( e ) => {
                this.$toast( '网络不稳定，请稍候重试！' );
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
        onGotoSummary () {
            this.HTTP.GET( `/v2/storeEvaluate/calculateTotalScore/${this.hid}` ).then( ( res ) => {
              if (window.isMini) {
                this.$router.replace( `/assess/${this.id}/summary/${this.hid}`  )
                window.localStorage.setItem('isSavedShop', '1')
              } else {
                this.$router.replace( `/assess/${this.id}/summary/${this.hid}` );
              }
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        onTap ( field, index ) {
            const opts = {};
            const list = [];
            const userEntry = this.form[ index ] || null;
            let ix = 0;
            for ( let i = 0; i < field.indexList.length; ++ i ) {
                const item = field.indexList[ i ];
                opts[ item.indexName ] = item.indexId;
                list.push( item.indexName );
                if ( item.indexId === userEntry ) ix = i;
            }
            this.current      = index;
            this.defaultIndex = ix;
            this.options      = opts;
            this.columns      = list;
            this.showPicker   = true;
        },
        onChecked ( index, value ) {
            this.$set( this.form, index, value );
        },
        setValue ( value ) {
            this.form.splice( this.current, 1, this.options[ value ] );
            this.showPicker = false;
        },
        getValue ( field, index ) {
            const userEntry = this.form[ index ] || null;
            try {
                return userEntry ? field.indexList.find( ( item ) => { return item.indexId === userEntry; } ).indexName : null;
            } catch ( e ) {
                return null;
            }
        },
        validate () {
            this.disabled = false;
            if ( this.code === 'basic' ) {
                if ( ! /^[1-9]\d*$/.test( this.params.rentMonth ) ) {
                    this.disabled = true;
                } else if ( ! /^[1-9]\d*$/.test( this.params.saleDay ) ) {
                    this.disabled = true;
                }
            }
            if ( ! this.disabled && this.form instanceof Array ) {
                for ( const value of this.form ) {
                    if ( ! value && value !== 0 ) {
                        this.disabled = true;
                        break;
                    }
                }
            }
            return ! this.disabled;
        },
        onShowDesc ( ix ) {
            const code = this.entity.moduleCode;
            const show = () => {
                this.showDesc = true;
                this.$nextTick( () => {
                    document.querySelector( `#desc-${typeof ix === 'number' ? ix : 'title'}` ).scrollIntoView();
                } );
            };
            if ( this.descs[ code ] ) {
                show();
            } else {
                this.HTTP.getAssessDesc( code ).then( ( res ) => {
                    this.descs[ code ] = res;
                    show();
                } ).catch( ( e ) => {
                    this.$toast( '网络不稳定，请稍候重试！' );
                } );
            }
        },
        stringFormat ( text ) {
            if ( text.length > 10 ) {
                const chars = text.split( '' );
                chars.splice( 8, 0, '<br>' );
                text = chars.join( '' );
            }
            return text;
        },
    },
    computed: {
        getDayRate () {
            if ( this.params.rentMonth && this.params.saleDay ) {
                const val = this.params.rentMonth / this.params.saleDay;
                return val && val > 0 ? val.toFixed( 1 ) : 0;
            } else {
                return 0;
            }
        },
    },
    watch: {
        '$route.params' ( res ) {
            this.showResult = false;
            this.disabled   = true;
            this.code       = null;
            this.entity     = null;
            this.result     = null;
            this.params.indexIdList = [];
            this.form       = [];
            this.next       = null;
            this.$refs.view.$el.scrollTop = 0;
            this.loadData();
        },
        entity ( res ) {
            if ( ! res ) return;
            this.loading  = false;
            this.title    = res.modelName;
            this.code     = res.moduleCode;
            this.disabled = true;
            // 提取列表
            if ( res.itemList ) {
                const option = res.itemList;
                if ( option[ 0 ].itemName === '租金日商比' ) option.splice( 0, 1 );
                this.list = option;
            }
            // 提取选项
            if ( res.indexIdList ) {
                this.form = res.indexIdList;
            }
            this.params.rentMonth = res.rentMonth || null;
            this.params.saleDay   = res.saleDay || null;
            if ( this.list && this.form.length !== this.list.length ) {
                this.form.length = this.list.length;
            }
            // 提取结论
            if ( res.socre || res.conclusion ) {
                this.result = {
                    score: res.socre,
                    text : res.conclusion,
                };
            }
        },
        form ( val ) {
            this.validate();
        },
        params: {
            deep: true,
            handler ( val ) {
                this.validate();
            },
        },
    },
    beforeMount () {
        this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
        this.loadData();
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.assess {
    min-height: 100vh;
    background-color: #2148AD;

    #{&}--view {
        padding: $padding;

        .descView {
            position: relative;
            background: url(/static/img/assess/title_box.png) no-repeat;
            background-size: 100% 100%;
            height: 38vw;
            box-sizing: border-box;
            padding: $margin $padding $padding $margin;
            font-size: $font_320;
            line-height: $font_560;
            font-weight: bold;

            .btn {
                position: absolute;
                right: $padding;
                bottom: $padding;
                width: 34vw;
                height: 7vw;
                background: url(/static/img/assess/title_btn.png) no-repeat;
                background-size: 100% 100%;
            }
        }

        .bg {
            display: block;
            width: 86vw;
            height: 13.5vw;
            margin: 0 auto;
        }

        .subTitle {
            @include font( white, $font_320, normal );
            line-height: $font_440;
            margin-top: $margin * 0.5;
            letter-spacing: .25vw;
            opacity: .8;
        }

        .view {
            position: relative;
            background-color: white;
            margin-top: $margin * 2;
            padding: 2px $padding 14vw;
            box-shadow: inset 0 0 0 1px $c-text;

            &:before {
                content: '';
                position: absolute;
                width: 13vw;
                height: 13vw;
                bottom: 0;
                right: 0;
                background: url(/static/img/assess/dogear.png) no-repeat;
                background-size: contain;
            }
            &:after {
                content: '';
                position: absolute;
                width: 4vw;
                height: calc( 100% - 5vw );
                right: 0;
                top: 5vw;
                background-color: #D2E2EC;
                transform: translateX(100%);
                box-shadow: inset -1px 1px 0 $c-text;
            }

            .tabs {
                @include flex;

                p {
                    width: 50%;
                    text-align: center;
                    padding: 5vw 0;
                    @include font( #2148AD, 4.54vw, bold );

                    &:not(:last-child) {
                        box-shadow: inset 0 4vw white, inset 0 -4vw white, inset -1px 0 0 rgba(black, .05);
                    }
                }
            }

            .item {
                @include flex;
                min-height: 13vw;
                box-shadow: inset 0 -1px 0 #F3F3F3;

                &.sp {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 4vw;
                        width: 100%;
                        background: url(/static/img/separate.png) no-repeat left top;
                        background-size: cover;
                    }
                }
            }
            .s-label {
                @include flex;
                width: 100%;
                height: 100%;
                font-size: $font_320;
                line-height: $font_560;

                &.ckmod {
                    @include flexColumn;
                    padding: 4vw 0;

                    span {
                        width: 100%;
                    }

                    .desc {
                        width: 100%;
                        font-size: $font_320;
                        color: $c-info;
                        text-align: left;
                        margin-top: 1vw;
                    }

                    .icon-question {
                        vertical-align: bottom;
                        margin-left: $margin * 0.5;
                        background: url(/static/icon/question.png) no-repeat center center;
                        background-size: contain;
                    }

                    .group {
                        @include flex;
                        width: 100%;

                        .checkbox {
                            @include flex;
                            justify-content: center;
                            width: 16%;
                            font-size: $font_320;
                            margin-top: $margin;

                            em {
                                width: 4vw;
                                height: 4vw;
                                background: white;
                                box-shadow: inset 0 0 0 2px #BBBDC2;
                                border-radius: 50%;
                                margin-right: 1vw;
                                overflow: hidden;
                            }

                            &.on {
                                em {
                                    background: white url(/static/icon/success.png) no-repeat;
                                    background-size: contain;
                                    box-shadow: inset 0 0 0 2px transparent;
                                }
                            }
                        }
                    }
                }

                &.normal {
                    span {
                        text-indent: 4vw;
                        font-weight: normal;
                    }
                }

                span {
                    width: 50%;
                    font-weight: bold;
                }
                .inp {
                    position: relative;
                    width: 50%;
                    height: $font_560;
                    padding: 0;
                    margin: 0;
                    border: none;
                    box-sizing: border-box;
                    text-align: right;
                    font-size: $font_320;
                    &::placeholder {
                        color: #C4C4C6;
                    }
                }
                .sel, .icon {
                    padding-right: 4vw;
                    background: url(/static/icon/arrow_down_2.png) no-repeat right .7vw center;
                    background-size: 2.6vw;
                }
                .icon-unit {
                    background-image: url(/static/icon/rmb_yuan.png);
                    background-position: right -0.2vw center;
                    background-size: 4vw 4vw;
                }
            }
        }

        .result {
            width: 100%;
            min-height: 5vw;
            background-color: #D2E2EC;
            transform: translateX(4vw);
            box-shadow: inset 1px 0 0 $c-text, inset 0 -1px 0 $c-text, inset -1px 0 0 $c-text;

            .content {
                @include flex;
                padding: $padding * 0.5 $padding * 0.5;

                .text {
                    width: 70%;
                    box-sizing: border-box;
                    padding-right: $margin;
                    font-size: $font_400;
                    line-height: 5.6vw;

                    em {
                        display: block;
                        font-weight: bold;
                        font-style: normal;
                        margin-bottom: 1.6vw;
                    }
                }
                .score {
                    width: 30%;
                    color: #08BCCA;
                    font-size: 3.2vw;
                    line-height: 5.6vw;
                    text-align: center;
                    .value {
                        font-size: 9.6vw;
                        margin-bottom: 1.6vw;
                    }
                }
            }
        }
    }

    #{&}--footerBar {
        width: 100%;
        padding: $padding $padding * 0.5;
        box-sizing: border-box;

        ::v-deep .van-button {
            height: 17vw;
            font-size: 7.5vw;
            font-weight: bold;
            line-height: normal;
            background: url(/static/img/assess/btn.png) no-repeat;
            background-size: 100% 100%;
            border: none;

            .van-button__text {
                @include flexColumn;
                .btn-text {
                    height: 8vw;
                    margin-top: 1.3vw;
                }
            }
        }
    }

    #{&}--desc {
        @include flexColumn;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(black, .7);

        .l-panel {
            width: 87.2vw;
            height: 60vh;
            padding: $padding $margin;
            border-radius: 0;
            box-sizing: border-box;

            .list {
                width: 100%;
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                scroll-behavior: smooth;
            }

            h3 {
                text-align: center;
                color: #2148AD;
                font-size: 4.53vw;
                font-weight: bold;
                margin-bottom: $padding;
            }
            h4 {
                font-size: $font_400;
                font-weight: bold;
                line-height: 5.6vw;
                margin-bottom: 2vw;
            }
            p {
                padding: $margin;
                background-color: #F4F9FC;
                font-size: $font_320;
                line-height: $font_480;
                color: #666;
                border-radius: $radius_xs;
            }
            p:not(:last-child) {
                margin-bottom: $margin;
            }
        }
    }

    #{&}--result {
        @include flexColumn;
        height: 100%;

        .panel {
            position: relative;
            width: 100%;
            height: 125vw;
            background: url(/static/img/assess/result.png) no-repeat right top;
            background-size: contain;
            border-radius: $radius_s;

            .score {
                margin-top: 22vw;
                @include font( #35BAC4, 18.67vw, bold );
                text-align: center;
            }
            .title {
                margin-top: $margin;
                @include font( #35BAC4, 4vw, bold );
                text-align: center;
            }
            .block {
                position: absolute;
                top: 55vw;
                left: 21vw;
                width: 64vw;
                font-size: $font_320;
                line-height: $font_440;
                .title {
                    color: $c-text;
                    margin-top: 0;
                    text-align: left;
                }
                .content {
                    margin-top: 2vw;
                }
            }

            .btn {
                position: absolute;
                left: 26vw;
                width: 48vw;
                height: 12vw;
                background: url(/static/img/assess/btn_a.png) no-repeat;
                background-size: contain;
                font-size: $font_400;
                font-weight: bold;
                text-align: center;
                line-height: 10vw;

                &.red {
                    background-image: url(/static/img/assess/btn_b.png);
                }
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .25s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
}
</style>
