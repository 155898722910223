<template>
    <div class="vedioDetail">
        <StateBar />
        <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true">
            <span slot="right" v-if="isClient && info">
                <!-- 已收藏 -->
                <img class="collect-img" src="/static/icon/collect_has_icon.png" alt="" @click="cancelCollect" v-if="isCollect" />
                <!-- 未收藏 -->
                <img class="collect-img" src="/static/icon/collect_icon.png" alt="" @click="collect" v-else />
                <!--        <img class="share-img" src="/static/icon/share_icon.png" alt="" @click="callShare" v-if="info && info.share" />-->
            </span>
        </TitleHeader>
        <div ref="contentRef" style="height: 0px"></div>
        <div class="share" v-if="!isClient">
            <p class="f-fw">上上参谋</p>
            <p>生意人的数据军师</p>
            <a ref="openApp" :href="link" target="_blank" class="app">APP下载</a>
        </div>
        <!-- 骨架屏占位图 -->
        <van-skeleton :loading="loading" title :animate="false" :row="4" class="s-skeleton" />
        <AutoView
            ref="view"
            id="view"
            :header="true"
            :footer="false"
            class="vedioDetail--view"
            :class="{ 'hidden-view': info && info.isPay && !isVip && !info.canReadBySubject }"
            :style="{ opacity: loading ? 0 : 1 }"
            @scroll.native="pageScrollHandle"
        >
            <div
                class="control-height"
                :style="{ height: info && info.isPay && !isVip && !info.canReadBySubject ? `calc(100vh - ${titleHeight}px)` : 'auto' }"
            >
                <!-- 播放器 -->
                <div class="mse" id="mse"></div>
                <div class="content-wrap" v-if="info">
                    <div class="logo" v-if="info.videoUrl">
                        <img src="/static/icon/logo_icon.png" alt="" />
                        上上参谋
                    </div>
                    <h1 class="title" ref="title">{{ info.title || "---" }}</h1>
                    <div class="info">
                        <span class="highlight" v-if="info.clickCountFormat">{{ info.clickCountFormat }} • </span>
                        <span>{{ info.publishTimeFormat }}</span>
                    </div>
                    <div class="topic" v-if="subjectResp" @click="goTopic(0)">
                        <div class="cover">
                            <img class="cover-img" :src="subjectResp.cover" alt="" />
                        </div>
                        <div class="message">
                            <p class="message-title">{{ subjectResp.name }}</p>
                            <!-- <p class="goDetail">专题详情<img class="goDetail-icon" src="/static/icon/click-right-icon.png" alt="" /></p> -->
                            <p class="number">
                                共 <span class="strong">{{ subjectResp.sectionCount }}</span> 篇
                            </p>
                        </div>
                    </div>
                    <div class="content" @click="onClick" v-html="info.content"></div>
                </div>
            </div>
            <!-- 相关推荐 -->
            <div class="mid-wrap" v-if="info && info.recommendList && info.recommendList.length && isClient">
                <van-divider />
                <div class="recommend">
                    <h4>相关推荐</h4>
                    <div class="scroll-wrap">
                        <div class="wrap" v-for="item in info.recommendList" :key="item.key" @click="swRouter('/community/article/' + item.id)">
                            <div class="img-wrap">
                                <van-image class="scroll-img" fit="fill" :src="item.image" />
                                <!-- type 1-资讯 2-文章 3-视频 -->
                                <img class="icon" src="/static/icon/video_icon.png" alt="" v-if="item.type == 3" />
                            </div>
                            <p class="float-title">{{ item.title }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 品牌 -->
            <div v-if="brandData.length">
                <brandVerticalList :brandData="brandData" title="相关品牌" />
            </div>
            <!-- 评价 -->
            <div class="bottom-evaluate" v-if="info && isClient">
                <EvaluateCard :evaluateInfo="evaluateInfo" :id="id" :title="info.title" :type="info.type" />
            </div>
            <!-- 回到顶部 -->
            <div class="go-top" v-if="isTop" @click="goTop">
                <img src="/static/icon/top_icon.png" alt="" />
            </div>
            <div class="vip-mask" v-if="info && info.isPay && !isVip && !info.canReadBySubject">
                <!-- <img class="collect-vip-btn" src="/static/icon/collect_vip_btn.png" alt="" @click="goVip" /> -->
                <img class="vip-equity" src="/static/icon/new-vip-mask/vip-equity.png" alt="" />
                <div class="vip-btn" @click="goVip">
                    <img class="vip-icon" src="/static/icon/new-vip-mask/vip-icon.png" alt="" />
                    <span class="text">开通会员，查看完整内容</span>
                </div>
            </div>
            <div class="catalogue" v-if="subjectResp" @click="goTopic(1)">
                <img class="catalogue-img" src="/static/icon/catalogue-img.png" alt="" />
            </div>
        </AutoView>
    </div>
</template>
<script>
import { ImagePreview } from "vant";
import Player from "xgplayer";
import EvaluateCard from "./components/evaluate-card.vue";
const brandVerticalList = () => import("@/views/brandAlliance/components/brand-vertical-list");

export default {
    components: { EvaluateCard, brandVerticalList },
    data() {
        return {
            loading: true,
            id: "",
            info: null,
            isClient: false,
            showTitle: false,
            isCollect: false, // 收藏标识
            evaluateInfo: {}, // 评价信息
            link: this.HTTP.getAppStore(),
            isTop: false, // 回到顶部标识
            brandData: [],
            isVip: false,
            subjectResp: null,
            titleHeight: null
        };
    },
    computed: {
        title() {
            return this.showTitle && !this.loading ? this.info.title : "";
        }
    },
    async created() {
        this.isClient = window.isClient;
        // this.isClient = true; //测试
        this.id = this.$route.params.id;
        if (!this.id) {
            this.$router.push("/");
            return;
        }
        const res = await this.HTTP.getVipInfo();
        this.isVip = res.userDetail ? res.userDetail.member : false;
        this.loadData();
    },
    mounted() {
        this.titleHeight = this.$refs.contentRef.getBoundingClientRect().top;
    },
    methods: {
        goTopic(index) {
            this.swRouter(`/h5/#/reference/special-topic-detail/${this.subjectResp.id}?index=${index}`);
        },
        goTop() {
            document.getElementById("view").scrollTop = 0;
        },
        callShare() {
            this.JSBridge.callHandler("callShareV2", {
                code: this.info.share.code,
                id: this.info.share.id
            });
        },
        collect() {
            this.HTTP.addCollect({
                refId: this.id,
                refName: this.info.title,
                type: this.info.type
            }).then(res => {
                this.isCollect = true;
                this.$toast("收藏成功");
            });
        },
        cancelCollect() {
            this.HTTP.cancelCollect({
                refId: this.id,
                refName: this.info.title,
                type: this.info.type
            }).then(res => {
                this.isCollect = false;
                this.$toast("已取消收藏");
            });
        },
        pageScrollHandle() {
            this.showTitle = this.$refs.title.getBoundingClientRect().top < 0;
            this.isTop = this.$refs.title.getBoundingClientRect().top < -667;
        },
        onClick(e) {
            if (e.target && e.target.nodeName === "IMG") {
                ImagePreview({
                    images: [e.target.src],
                    loop: false,
                    showIndex: false,
                    closeable: false,
                    onClose: () => {}
                });
            }
        },
        loadData() {
            this.HTTP.getResourceById(this.id)
                .then(res => {
                    console.log("getArticle:", res);
                    this.info = res.data;
                    this.isCollect = res.data.isCollect;
                    this.brandData = res.data.brandList || [];
                    this.subjectResp = res.data.subjectResp;
                    console.log(this.subjectResp, "新数据");
                    this.loading = false;
                    // 评价接口需要权限
                    this.isClient && this.getComment(this.info.type);
                    this.$nextTick(() => {
                        // 处理超链接跳转
                        let aTagBox = window.document.getElementsByClassName("content");
                        let aTag = aTagBox[0].getElementsByTagName("a");
                        for (let i = 0; i < aTag.length; i++) {
                            const href = aTag[i].attributes["href"].value; // 拿到a标签的href
                            console.log(href);
                            aTag[i].onclick = event => {
                                // console.log(event)
                                // 阻止a标签跳转
                                event.preventDefault();
                                // 阻止图片预览
                                event.stopPropagation();
                                // 解析参数
                                if (href) {
                                    const arr = href.split("|");
                                    const params = {};
                                    arr.forEach(el => {
                                        let v, key;
                                        if (el.indexOf("type=") === 0) {
                                            v = Number(el.substring(5));
                                            key = "type";
                                        }
                                        if (el.indexOf("url=") === 0) {
                                            v = el.substring(4);
                                            key = "url";
                                        }
                                        if (el.indexOf("id=") === 0) {
                                            v = el.substring(3);
                                            key = "id";
                                        }
                                        params[key] = v;
                                    });
                                    this.JSBridge.callHandler("onNormalizingCall", params);
                                }
                            };
                        }
                        if (this.info.type == 3 && this.info.videoUrl) {
                            // 横屏
                            if (this.info.videoStyle == 1) {
                                new Player({
                                    id: "mse",
                                    // poster: "", // 封面图
                                    url: this.info.videoUrl,
                                    playsinline: true,
                                    "x5-video-player-type": "h5",
                                    "x5-video-player-fullscreen": "false",
                                    closeVideoClick: true,
                                    closeVideoTouch: true,
                                    autoplay: true, // 自动播放
                                    videoInit: true, // 显示首帧
                                    fluid: true, // 流式布局
                                    // fitVideoSize: "fixWidth",
                                    ignores: ["volume"]
                                });
                            } else {
                                // 竖屏
                                new Player({
                                    id: "mse",
                                    url: this.info.videoUrl,
                                    playsinline: true,
                                    "x5-video-player-type": "h5",
                                    "x5-video-player-fullscreen": "false",
                                    closeVideoClick: true,
                                    closeVideoTouch: true,
                                    width: "100vw",
                                    height: 510,
                                    autoplay: true, // 自动播放
                                    videoInit: true, // 显示首帧
                                    ignores: ["volume"]
                                });
                            }
                        }
                    });
                })
                .catch(err => {
                    if (err.code == "201021") {
                        this.$router.replace("/community/empty");
                        return;
                    }
                    this.$toast("网络不稳定，正在重试！");
                });
        },
        getComment(type) {
            this.HTTP.getReplys({
                current: 1,
                size: 1000,
                type,
                refId: this.id
            })
                .then(res => {
                    this.evaluateInfo = res;
                })
                .catch(err => {
                    this.$toast("评价列表获取失败，请稍后再试");
                });
        },
        goVip() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, this.id, "30400", "30409");
            this.swRouter(`/h5/#/member?source=article&refId=${this.id}&pageId=${this.$route.meta.id}&moduleId=30400&btnId=30409`);
        }
    }
};
</script>
<style lang="scss">
.vedioDetail {
    a {
        text-decoration: underline;
    }
}
</style>
<style lang="scss" scoped>
//@import "@/styles/index.scss";
//@import "@/styles/px2vw.scss";

.vip-mask {
    position: absolute;
    bottom: 0;
    left: 0;
    // z-index: 100;
    width: vw2(375);
    height: vw2(307);
    background: url("/static/icon/new-vip-mask/vip-mask.png") no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;

    .vip-equity {
        width: vw2(335);
        height: vw2(135);
        left: vw2(20);
        bottom: vw2(20);
        position: absolute;
    }
    .vip-btn {
        box-sizing: border-box;
        position: absolute;
        width: vw2(203);
        height: vw2(38);
        top: vw2(105);
        left: vw2(86);
        background: linear-gradient(90deg, #faa150 0%, #ffbe6c 100%);
        box-shadow: 0px vw2(3) vw2(8) 0px rgba(250, 161, 80, 0.3);
        border-radius: vw2(19);
        text-align: center;
        line-height: vw2(33);

        .vip-icon {
            width: vw2(18);
            height: vw2(15);
            vertical-align: vw2(-3);
        }
        .text {
            font-size: vw2(13);
            font-weight: 500;
            color: #ffffff;
            // line-height: vw2(19);
        }
    }
}
.hidden-view {
    overflow-y: hidden;
}

.control-height {
    overflow: hidden;
}
.vedioDetail {
    height: 100vh;
    overflow: hidden;

    .collect-img {
        width: 4.8vw;
        height: 4.8vw;
        margin-right: 2.13vw;
    }
    .share-img {
        width: 4.8vw;
        height: 4.8vw;
    }

    .mse {
        width: 100vw;
    }

    #{&}--view {
        scroll-behavior: smooth;
        background: #fff;

        .content-wrap {
            padding: $margin;
            .logo {
                padding: 3.2vw 0;
                font-size: 3.47vw;
                color: #333;
                font-weight: bold;
                img {
                    width: 6.4vw;
                    height: 6.4vw;
                    vertical-align: middle;
                }
            }
            .title {
                @include font($c-text, $font_560, $weight_500);
                line-height: 8vw;
                padding-bottom: 4vw;
                font-weight: bold;
            }
            .info {
                @include font($c-gray3, $font_347, $weight_300);
                line-height: 4.93vw;
                margin-bottom: 6.13vw;
                .highlight {
                    color: #347fff;
                }
            }
            .content {
                font-size: $font_373;
                line-height: normal;
                letter-spacing: 0.13vw;
                ::v-deep img {
                    width: 100%;
                    height: auto !important;
                    @extend .s-radius__s;
                }
                ::v-deep p {
                    font-size: $font_400;
                    line-height: normal;
                    letter-spacing: 0.13vw;
                    word-break: break-all;
                    &.ql-align-center {
                        text-align: center;
                    }
                    &.ql-align-justify {
                        text-align: justify;
                    }
                    &.ql-align-right {
                        text-align: right;
                    }
                }
            }
        }

        .bottom-evaluate {
            background: #f7f7f7;
        }

        .go-top {
            position: fixed;
            right: 5.33vw;
            bottom: 11.2vw;
            z-index: 3;
            img {
                width: 12.8vw;
                height: 12.8vw;
            }
        }
    }

    .mid-wrap {
        padding: 0 $margin $margin $margin;
        // margin:  0 5.33vw;
        margin-bottom: 5.33vw;
        .recommend {
            margin-top: 4.8vw;
            h4 {
                font-size: 4.53vw;
                color: #1d2233;
                margin-bottom: 3.2vw;
            }
            .scroll-wrap {
                position: relative;
                overflow: hidden;
                overflow-x: auto;
                white-space: nowrap;
                background: #fff;
                border-bottom: 1px solid #fff;
                .wrap {
                    display: inline-block;
                    width: 37.33vw;
                    overflow: hidden;
                    vertical-align: top;
                    margin-right: 2.13vw;
                    .img-wrap {
                        position: relative;
                        height: 21.33vw;
                        .scroll-img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            ::v-deep img {
                                border-radius: 1.07vw;
                            }
                        }
                        .icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 6.67vw;
                            height: 6.67vw;
                        }
                    }
                    .float-title {
                        width: 37.33vw;
                        font-size: 3.47vw;
                        line-height: 4.8vw;
                        color: #333;
                        white-space: pre-line;
                        word-break: break-all;
                        margin-top: 2.13vw;
                        @include limitLine(2);
                    }
                }
            }
        }
    }

    .share {
        position: fixed;
        top: 0;
        width: 100%;
        height: $headerHeight;
        background: rgba(black, 0.8) url(/static/icon/logo@96.png) no-repeat $margin center;
        background-size: $headerHeight * 0.7;
        box-sizing: border-box;
        color: white;
        padding-left: $headerHeight * 1.1;
        p {
            font-size: $font_320;
            padding-top: $margin * 0.8;
        }
        .f-fw {
            font-size: $font_400;
        }
        .app {
            position: absolute;
            right: $margin;
            top: 25%;
            height: 50%;
            color: white;
            background-color: $c-main;
            padding: 0 $margin;
            overflow: hidden;
            border-radius: 10vw;
            font-size: $font_320;
            @include flexColumn;
        }
    }
    ::v-deep .xgplayer-skin-default .xgplayer-enter .xgplayer-enter-spinner {
        height: 60px;
        width: 60px;
    }
}

.topic {
    padding: vw2(10);
    background: #f7f7fa;
    display: flex;
    border-radius: vw2(5);
    margin-bottom: vw2(15);
    .cover {
        width: vw2(38);
        height: vw2(50);
        border-radius: vw2(2);
        margin-right: vw2(10);
        .cover-img {
            width: vw2(38);
            height: vw2(50);
            border-radius: vw2(2);
            object-fit: cover;
            margin-right: vw2(10);
        }
    }
    .message {
        width: 100%;
        .message-title {
            font-size: vw2(15);
            font-weight: 500;
            color: #1f2435;
            line-height: vw2(21);
            margin-bottom: vw2(14);
        }
        .goDetail {
            text-align: right;
            font-size: vw2(12);
            font-weight: 400;
            color: #676c84;
            line-height: vw2(17);
            .goDetail-icon {
                width: vw2(13);
                height: vw2(13);
                vertical-align: vw2(-2);
            }
        }
        .number {
            font-size: vw2(12);
            font-weight: 400;
            color: #676c84;
            line-height: vw2(17);
            margin-bottom: vw2(-5);
            .strong {
                color: #1d2233;
            }
        }
    }
}
.catalogue {
    width: vw2(44);
    height: vw2(44);
    position: fixed;
    right: 5.9vw;
    bottom: vw2(100);
    .catalogue-img {
        width: 12vw;
        height: 12vw;
    }
}
</style>
