import Aegis from 'aegis-web-sdk';

let aegis, host
if (process.env.NODE_ENV === "production") {
    host = "//appv2.sscanmou.com/app";
} else {
    host = "https://huawei-test-appv2.sscanmou.com/app";
}

if(process.env.NODE_ENV === "production") {
    aegis = (() => {
        return new Aegis({
            id: '75VYphQqvrZRg6qY89', // 上报 id
            uin: 'sscm-prod', // 用户唯一 ID（可选）
            reportApiSpeed: true, // 接口测速
            reportAssetSpeed: true, // 静态资源测速
            spa: true // spa 应用页面跳转的时候开启 pv 计算
        });
    })();
} else {
    aegis = (() => {
        return new Aegis({
            id: 'yrz8ptl3GEnydEdJL0', // 上报 id
            uin: 'sscm-test', // 用户唯一 ID（可选）
            reportApiSpeed: true, // 接口测速
            reportAssetSpeed: true, // 静态资源测速
            spa: true, // spa 应用页面跳转的时候开启 pv 计算
        });
    })();
}

export function reportEvent(data) {
    aegis.reportEvent({
        name: data.name,
        ext1: data.ext1 && data.ext1,
        ext2: data.ext2 && data.ext2,
        ext3: data.ext3 && data.ext3,
    })
}

export default aegis