<template>
    <div class="details">
        <StateBar></StateBar>
        <TitleHeader title="门店详情" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="bills--view l-view">
            <!-- :loading="firstLoading" -->
            <van-skeleton :loading="firstLoading"  title :animate="false" :row="3" class="s-skeleton s-radius__s">
                <div class="box-details">
                    <!-- head -->
                    <div class="ranking-box">
                        <img class="ranking-logo" src="" alt="">
                        <div class="ranking-content">
                            <div class="content-title" :class="imgSrc ? '': 'no-img-padding'">
                                <h3 class="title">
                                    <span>费大厨辣椒炒肉</span>
                                </h3>
                                <van-tag size="medium" class="cataory" textColor="#FF5900">
                                    外卖类目
                                </van-tag>
                            </div>
                            <div class="cata-type">
                                <img src="/static/icon/take-location.png" alt="">
                                <div>深圳市宝安区宝安大道和海城路交汇处</div>
                            </div>
                        </div>
                    </div>
                    <!-- update -->
                    <div class="update-box">
                        <p class="time">2021年07月05日更新</p>
                        <div class="box">
                            <div class="line-1">
                                <div class="text">评分：4.8分</div>
                                <div class="text">月销：12345单</div>
                            </div>
                            <div class="line-1">
                                <div class="text">评分：4.8分</div>
                                <div class="text">月销：12345单</div>
                            </div>
                        </div>
                    </div>
                    <!-- Wraper 基础信息-->
                    <Wraper :title="`基础信息`">
                        <div class="text-info">
                            <div class="first">
                                <span>店铺公告：</span>
                                <p>面对疫情，九锅一堂承诺做到：严控食材，烹饪有道；服务为先，消毒杀菌；严于律己，勇于担当，保障一日三餐的安全。</p>
                            </div>
                            <div class="first">
                                <span>营业时间：</span>
                                <p>12:00-20:00</p>
                            </div>
                            <div class="first">
                                <span>起送金额：</span>
                                <p>38元</p>
                            </div>
                        </div>
                    </Wraper>
                    <!-- 促销信息 -->
                    <Wraper :title="`促销信息`">
                        <div class="two-text">
                            <van-tag size="medium" class="tabs-text" textColor="#FF5900" color="transparent">
                                新用户立减10元（本优惠与新人首单红包不可同享）
                            </van-tag>
                            <van-tag size="medium" class="tabs-text" textColor="#FF5900" color="transparent">
                                首次使用美团支付最高再减3元
                            </van-tag>
                            <van-tag size="medium" class="tabs-text" textColor="#FF5900" color="transparent">
                                满30减2
                            </van-tag>
                            <van-tag size="medium" class="tabs-text" textColor="#FF5900" color="transparent">
                                满40减4
                            </van-tag>
                        </div>
                    </Wraper>
                    <!-- 本店外卖热销品类 -->
                    <Wraper :title="`本店外卖热销品类`">
                        <div class="three-text">
                            <div class="li" v-for="(val, index) in 10" :Key="index">
                                <template v-if="(index+1) < 4">
                                    <img v-if="(index+1) == 1" class="left-content" src="/static/icon/hot-take-1.png" alt="">
                                    <img v-else-if="(index+1) == 2" class="left-content" src="/static/icon/hot-take-2.png" alt="">
                                    <img v-else class="left-content" src="/static/icon/hot-take-3.png" alt="">
                                </template>
                                <template v-else>
                                    <div class="left-content-text">{{index+1}}.</div>
                                </template>
                                <div class="right-content">
                                    <h3>藤椒肥牛饭</h3>
                                    <div class="tabs-li">
                                        <span>月销：347865单</span>
                                        <span>价格：28.9元</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wraper>
                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import Wraper from "./components/wraper";
export default {
    name: 'varieties',
    components: {
        Wraper
    },
    data () {
        return {
            firstLoading: false,
            imgSrc: true,
        };
    },
    methods: {
        loadData () {

        }
    },

    created () {
        this.loadData();
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.details {
   height: 100vh;
   .box-details{
        width: 100%;
        height: auto;
        .ranking-box{
            background: transparent;
            border-radius: 1.33vw;
            margin-bottom: 1.33vw;
            box-sizing : content-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 4.4vw;
            border-bottom: 1px solid #E7E7E7;
            &:last-child {
                margin-bottom: 0;
            }
            .ranking-logo{
                width : 18.67vw;
                height : 18.67vw;
                object-fit: cover;
                margin-right : 3.33vw;
                border-radius : 1.07vw
            }
            .ranking-content{
                flex: 1;
                text-align: left;
                .content-title{
                    display: flex;
                    align-items: center;
                    .title {
                        font-size: 4.53vw;
                        font-weight: bold;
                        color: #1D2233;
                        line-height: 5.6vw;
                        margin: 0;
                        padding: 0;
                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display : block;
                            margin-right: 1.87vw;
                        }
                    }
                    .cataory{
                        font-size: 3.2vw;
                        color: #FF5900;
                        background: #FFF1E9;
                        border-radius: 5px;
                        border: 1px solid #FF9F6B;
                        box-sizing: border-box;
                        line-height: 1;
                    }
                }
                .no-img-padding{
                    padding-top: 1.87vw;
                }
                .cata-type{
                    display: flex;
                    align-items: center;
                    margin-top : 3.2vw;
                    img {
                        width: 3.47vw;
                        height: 3.73vw;
                        margin-right: 1.07vw;
                    }
                    div {
                        flex: 1;
                        font-size: 3.47vw;
                        color: #666666;
                        line-height: normal;
                    }
                }
            }
        }
        .update-box{
            width: 100%;
            padding: 1.33vw 0 3.33vw;
            .time{
                font-size: 2.93vw;
                color: #8E9099;
                line-height: 4vw;
            }
            .box{
                .line-1{
                    display: flex;
                    align-items: center;
                    margin-top: 2.13vw;
                    .text{
                        flex: 1;
                        font-size: 3.2vw;
                        color: #333333;
                        line-height: 4.4vw;
                        position: relative;
                        padding-left: 4.27vw;
                        &::after{
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 3.73vw;
                            height: 3.73vw;
                            background: url("/static/icon/take-icon1.png") no-repeat;
                            background-size: 100%;
                        }
                        &:last-child{
                            &::after{
                                background: url("/static/icon/take-icon2.png") no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                    &:last-child{
                        .text{
                            &::after{
                                background: url("/static/icon/take-icon3.png") no-repeat;
                                background-size: 100%;
                            }
                            &:last-child{
                                &::after{
                                    background: url("/static/icon/take-icon4.png") no-repeat;
                                    background-size: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .text-info{
            margin-top: 2.67vw;
            .first{
                display: flex;
                align-items: top;
                margin-bottom: 1.6vw;
                font-size: 3.2vw;
                color: #666666;
                line-height: 4.4vw;
                p{
                    flex: 1;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .two-text{
            padding-top: 0.67vw;
            .tabs-text{
                font-size: 2.93vw;
                line-height: 4vw;
                border: 1px solid rgba(255, 89, 0, 0.53);
                border-radius: 0.8vw;
                margin: 2vw 1.33vw 0 0;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .three-text{
            .li{
                margin-top: 2.67vw;
                border-radius: 1.33vw;
                border: 1px solid #E7E7E7;
                padding: 2.67vw;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                .left-content{
                    width: 5.87vw;
                    height: 7.73vw;
                    margin-right: 3.07vw;
                }
                .left-content-text{
                    width: 5.87vw;
                    margin-right: 3.07vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3.47vw;
                    font-weight: bold;
                    color: #FF5900;
                }
                .right-content{
                    flex: 1;
                    h3{
                        font-size: 4vw;
                        font-weight: bold;
                        color: #1D2233;
                        line-height: 5.6vw;
                    }
                    .tabs-li{
                        margin-top: 1.87vw;
                        display: flex;
                        align-items: center;
                        span {
                            flex: 1;
                            font-size: 3.47vw;
                            color: #8E9099;
                            line-height: 4.93vw;
                        }
                    }
                }
            }
        }
   }
}
</style>
