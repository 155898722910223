<template>
  <div class="report l-panel">
    <div class="report--content">
      <p class="time">套餐包使用时间: {{ item.payTime || "刚刚" }}</p>
      <h1>
        <span class="card-tag">
          {{ reportType[item.reportQuestionId] || "其他" }}
        </span>
        {{ item.reportInstanceName || "---" }}
      </h1>
      <div v-if="item.inputList" class="desc s-radius__xs">
        <p v-for="(field, index) in item.inputList" :key="index">
          <strong>{{ field.name }}：</strong>{{ field.value }}
        </p>
      </div>
      <p class="package-type"><strong>套餐包名称：</strong>{{ item.questionName }}</p>
    </div>
  </div>
</template>

<script>
const reportType = {
  10001: "开店选址评估",
  10003: "加盟找品牌",
  10004: "加盟找位置",
  10005: "客群画像",
  10006: "竞争分析",
  10011: "商圈数据",
  10012: "店铺经营分析",
  10013: "周边调研",
  10014: "周边数据",
  10015: "选品类",
  10018: "行业热力",
  10019: "人口热力",
  10026: "开店位置推荐"
};

export default {
  name: "report",
  props: {
    item: Object // 订单对象
  },
  data: function() {
    return {
      reportType
    };
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.report {
  position: relative;
  padding: $padding1;
  margin-bottom: $margin;

  #{&}--content {
    position: relative;
    height: 100%;
    h1 {
      color: $c-text;
      width: 100%;
      font-size: $font_400;
      line-height: $font_560;
      margin: 0;
      margin-top: 2.13vw;
      .card-tag {
        position: relative;
        top: -0.8vw;
        display: inline-flex;
        align-items: center;
        padding: 0.53vw 1.33vw;
        line-height: normal;
        font-size: $font_267;
        color: #8d5d22;
        background: #fdd6a4;
        border-radius: 1px 5px 1px 5px;
        margin-right: 1.07vw;
      }
    }
    .time {
      font-size: $font_293;
      line-height: $font_400;
      color: $c-info;
    }
    .desc,
    .light {
      margin: $margin 0;
      padding: $margin;
      background-color: $c-pageBg;
      font-size: $font_293;
      line-height: $font_400;
    }
    .light {
      background-color: #fff1e1;
      color: #8d5d22;
      font-weight: bold;
    }
    .package-type {
      font-size: $font_293;
      line-height: $font_400;
      color: #333;
    }
  }
}
</style>
