<template>
  <div class="ranking">
    <ul class="ranking-flex tableBg">
      <li class="tableTitle" :class="{'num':index===0,'info':index>0}" v-for="(prop, index) in tableProps" :key="index">{{ prop }}</li>
    </ul>
    <div :class="{'ranking-overflow': isOverflow}">
      <ul class="ranking-flex" :class="{ autoBg: idx % 2 != 0 }" v-for="(item, idx) in tableData" :key="item.key" @click="clickTable(item)">
        <li class="num" :class="{ oneColor: idx == 0, twoColor: idx == 1, threeColor: idx == 2 }">
          <i>{{ idx + 1 }}.</i>
        </li>
        <li class="info">
          {{ item.name }}
        </li>
        <li class="info">
          {{ item.count || "-" }}
        </li>
        <li class="info">
          {{ item.radio || "-" }}
        </li>
        <img class="arrow-icon" src="/static/icon/comments_arrow.png" alt="" v-if="more" />
      </ul>
    </div>
    <p class="no-thing" v-if="tableData && tableData.length === 0">暂无数据</p>
  </div>
</template>

<script>
export default {
  props: {
    tableProps: {
      type: Array,
      default: () => [],
      required: true
    },
    tableData: {
      type: Array,
      default: () => [],
      required: true
    },
    // 是否限制表格高度，超出滚动
    isOverflow: {
      type: Boolean,
      default: false
    },
    more: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickTable(data) {
      if (!this.more) return
      this.$emit('clickTable',data)
    }
  }
};
</script>

<style lang="scss" scoped>
.ranking {
  background: #fff;
  border-radius: 1.33vw;
  border: 1px solid #e7e7e7;
  margin-top: 1.07vw;

  .tableTitle {
    font-size: 3.47vw !important;
    color: #8893a7 !important;
    font-weight: 400 !important;
  }
  .tableBg {
    background: #eeeef2 !important;
  }
  .autoBg {
    background: #f7f7f7 !important;
  }
  .ranking-overflow {
    height: 70vw;
    overflow-y: auto;
  }
  .ranking-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 5.33vw;
    width: 100%;
    font-size: 3.47vw;
    color: #0d1525;
    text-align: center;
    padding: 3.2vw;
    background: #fff;
    box-sizing: border-box;
    position: relative;
    .num {
      flex: 0 0 8.53vw;
      font-weight: bold;
    }
    .oneColor {
      color: #ff3627;
    }
    .twoColor {
      color: #ff8702;
    }
    .threeColor {
      color: #ffbb26;
    }
    .info {
      flex: 1;
    }
    .arrow-icon {
      width: 6px;
      position: absolute;
      right: 15px;
    }
  }
  .no-thing {
    font-size: 3.2vw;
    color: #999;
    text-align: center;
    padding: 4.27vw 0;
  }
}
</style>
