<template>
    <div>
        <van-uploader
            class="upload_upload"
            :after-read="afterRead"
            accept="image/*"
            :before-read="beforeAvatarUpload"
            v-model="fileList"
            @delete="deleteHandle"
            :multiple="multiple"
            :max-count="maxCount"
        >
        </van-uploader>
    </div>
</template>

<script>
import { uploadImgToAliyun } from "@/request/uploadImgAliyun.js";
export default {
    name : 'uploadImg',
    data() {
        return {
            fileList: [],
            coverList: []
        };
    },
    props: {
        maxCount: Number,
        multiple: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        async afterRead(file) {
            let _this = this;
            let fileArr = []
            if(file instanceof Array) {
                fileArr = file
            } else {
                fileArr.push(file)
            }
            const promiseAll = []
            fileArr.forEach((item, index) => {
                item.status = 'uploading';
                item.message = '上传中...';
                const upload = new Promise((resolve, reject) => {
                        uploadImgToAliyun(item).then(res => {
                        item.status = 'done';
                        item.message = '上传成功';
                        _this.coverList.push(res)
                        resolve()
                    }).catch(err => {
                        console.log("failed error msg：", err)
                        item.status = 'failed';
                        item.message = '上传失败';
                        reject()
                    })
                })
                promiseAll.push(upload)
            })
            Promise.all(promiseAll).then(res => {
                _this.$emit("getImgUploadUrl", _this.coverList);
                this.$toast.success("上传成功");
            }).catch(err => {
                this.$toast.fail("上传失败，请压缩图片后重新上传");
            })
        },
        deleteHandle(e, detail) {
            this.coverList.splice(detail.index, 1)
            this.$emit("deleteImg", detail.index)
        },
        beforeAvatarUpload(file) {
            let _this = this;
            const types = ["image/jpeg", "image/gif", "image/png"];
            let isPic
            if(file instanceof Array) {
                isPic = file.every(el => {
                    return types.indexOf(el.type) > -1
                })
            } else {
                isPic = types.indexOf(file.type) > -1;
            }
            if (!isPic) {
                _this.$toast.fail("上传图片只能是 JPG,PNG,GIF 格式!");
            }
            return isPic;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .van-uploader__upload,
::v-deep .van-image,
::v-deep .van-uploader__preview-image{
  width:  28vw;
  height:  28vw;
}
</style>
