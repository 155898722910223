<template>
  <div class="map-container">
    <div class="hot-map-container">
      <baseMap ref="baseMapRef"
               :id="mapId"
               @map-complete="complete"
               :mapOpts="{
                       mapStyle: 'amap://styles/whitesmoke',
                       zoom: 14,
                       zooms: [3, 19]
                     }"
      ></baseMap>
      <div class="hot-map-container-mask"></div>
    </div>
    <div class="left-explain" v-if="showHotText">
      <span>少</span>
      <div class="legend-color">
        <span v-for="color in colors" :style="{'background': color}"></span>
      </div>
      <span>多</span>
    </div>
    <div class="right-zoom" @click="popupChange">
      <p class="tips">全屏缩放</p>
      <img src="/static/shopEcology/report/icon_zoom.png" alt="" />
    </div>
    <div class="legend-box" v-if="legendList.length">
      <div class="legend-box-item" v-for="(item, index) in legendList" :key="index">
        <div class="legend-box-item-grid" :style="{'background': item.color}"></div>
        <div class="legend-box-item-name">{{ item.name }}</div>
      </div>
    </div>
    <van-popup
        v-model="showChartReportPopup"
        get-container="#app"
        position="center"
        :style="{ width: '100%', height: '100%', border: 0 }"
        class="grid-hot-map-popup"
    >
      <div class="popup-wrap">
        <div class="center-loading" v-show="popupLoading">
          <van-loading />
        </div>
        <baseMap
            ref="baseMapPopupRef"
            :id="`popup_${mapId}`"
            @map-complete="popupMapComplete"
            :mapOpts="{
                 mapStyle: 'amap://styles/whitesmoke',
                 zoom: 15,
                 zooms: [3, 19]
              }"
        ></baseMap>
        <div class="legend-box" v-if="legendList.length">
          <div class="legend-box-item" v-for="(item, index) in legendList" :key="index">
            <div class="legend-box-item-grid" :style="{'background': item.color}"></div>
            <div class="legend-box-item-name">{{ item.name }}</div>
          </div>
        </div>
        <div class="top-back">
          <template>
            <img @click="hidePopup" src="/static/shopEcology/report/icon_back_entity.png" alt="" />
            <div class="text-right">
              <p class="back-tips">颜色越红，热力越旺越集中</p>
              <p class="back-tips">地图支持单指滑动，双指缩放</p>
            </div>
          </template>
        </div>
        <div class="bottom-location">
          <div class="new-icon-location"><img @click="panToCenter" src="/static/shopEcology/report/icon_location_hot.png" alt="" /></div>
          <p class="tips skewing">点击按钮回到原点</p>
          <p class="tips float-r">{{ hotText }}</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
let defaultIndex = 0
const baseMap = () => import('./HotMap.vue')
import loadLoca from '@/components/baseMap/loadLoca';
export default {
  name: "grid-hot-map",
  components: {
    baseMap
  },
  props: {
    styleType: {
      type: Number, // 0 需要自定义区间 1 不需要自定义区间
      default: 0
    },
    modeType: {
      type: String, // 聚合模式，可选值: sum(值求和)、max(最大值)、min(最小值)、mean(平均值)、median(中位数)、count(个数)
      default: 'count'
    },
    radius: {
      type: Number,
      default: 150
    },
    geohashData: Array,
    mode: { // 聚合类型，按照哪个字段聚合
      type: String,
      default: ''
    },
    legendList: {
      type: Array,
      default: () => { return [] }
    },
    legendMarginBottom: {
      type: String,
      default: ''
    },
    showHotText: Boolean,
    hotText: {
      type: String,
      default: '颜色越红，热力越旺越集中'
    },
    inputData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showChartReportPopup: false,
      popupLoading: false,
      mapId: '',
      /**
       * 存储加工后的准备添加到地图中的数据
       */
      geohash: [],
      layer: undefined,
      popupLayer: undefined,
      colors: [
        '#C2E668',
        '#FFE239',
        '#FFAF39',
        '#FF7B39',
        '#FF5739',
      ],
      refType: 'baseMapRef'
    }
  },
  created() {
    this.mapId = 'grid-hot-map-' + defaultIndex++
  },
  watch: {
    geohashData() {
      this.addGridLayer()
    }
  },
  mounted() {
    // this.handleHash()
  },
  methods: {
    initComplete() {
      this.$refs[this.refType].initMap()
    },
    popupChange() {
      // 隐藏title返回按钮
      this.JSBridge.callHandler("switchBackBtn", false);
      this.showChartReportPopup = true;
      this.$nextTick(() => {
        if (!this.$refs.baseMapPopupRef.map) {
          this.$refs.baseMapPopupRef.initMap()
        } else {
          this.panToCenter()
        }
      })
    },
    complete() {
      console.log('map complete')
      loadLoca().then(() => {
        this.setCenter()
        this.addGridLayer()
      })
    },
    addGridLayer() {
      // console.log('this.geohashData', this.geohashData)
      // 过滤经纬度缺失，或者 没有count的数据
      let geohash = []
      if (this.geohashData && this.geohashData.length) {
        geohash = this.geohashData.filter(el => el.lng && el.lat && ((this.mode && el[this.mode] || !this.mode))) || []
      }
      // console.log('geohash', geohash)
      if (geohash.length == 0) {
        return;
      }
      const layer = new Loca.GridLayer({
        fitView: false
      });
      layer.setData(geohash, {
        type: 'json',
        lnglat: function(obj) {
          let val = obj.value;
          return [val.lng, val.lat]
        },
        value: this.mode
      });
      // layer初始化完成后，针对不同的地图，设置不同的样式
      // this.$emit('initLayerComplete')

      if (this.styleType === 0) {
        let list = JSON.parse(JSON.stringify(this.legendList))
        list = list.reverse()
        layer.setOptions({
          'mode': this.modeType, // 聚合模式，可选值: sum(值求和)、max(最大值)、min(最小值)、mean(平均值)、median(中位数)、count(个数)
          'style': {
            color: (data) => {
              let color = '#C2E668'
              const count = data[this.modeType]
              const item = list.find(el => count >= el.min)
              return item.color || color
            },
            radius: this.radius,
            opacity: 0.8,
            gap: 10, // 网格间距
            height: [0, 0]
          }
        });
      } else {
        layer.setOptions({
          'mode': this.modeType, // 聚合模式，可选值: sum(值求和)、max(最大值)、min(最小值)、mean(平均值)、median(中位数)、count(个数)
          'style': {
            color: [
              '#C2E668',
              '#FFE239',
              '#FFAF39',
              '#FF7B39',
              '#FF5739',
            ],
            radius: this.radius,
            opacity: 0.7,
            gap: 10, // 网格间距
            height: [0, 0]
          }
        });
      }

      layer.setMap(this.$refs[this.refType].map);
      layer.render();

      if (this.refType === 'baseMapRef') {
        this.layer = layer
      } else {
        this.popupLayer = layer
      }
    },
    panToCenter() {
      this.setCenter()
    },
    // 设置定位地点
    setCenter() {
      if (!this.inputData || !this.inputData.hasOwnProperty("GPS_R")) {
        return;
      }
      let lnglat = [this.inputData.GPS_R.lng, this.inputData.GPS_R.lat];
      this.$refs[this.refType].map.setCenter(lnglat);
    },
    popupMapComplete() {
      this.refType = 'baseMapPopupRef'
      this.panToCenter()
      this.addGridLayer()
    },
    hidePopup() {
      this.JSBridge.callHandler("switchBackBtn", true);
      this.showChartReportPopup = false;
    },
  }
}
</script>
<style lang="scss">
@import '@/styles/px2vw.scss';
.grid-hot-map-popup {
  .legend-box {
    top: 20.666667vw !important;
    left: 4.333333vw !important;
  }
  .popup-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: left;

    .center-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .top-back {
      position: absolute;
      top: vw2(28);
      left: 0;
      width: 100%;
      height: vw2(65);

      .location-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: vw2(11);
        color: #1D2233;

        .location-left {
          flex: 5;

          img {
            width: vw2(60);
            height: vw2(60);
            vertical-align: top;
          }

          .tips {
            display: inline-block;
            height: vw2(25);
            line-height: vw2(25);
            background: rgba(255, 255, 255, 0.8);
            border-radius: 3px;
            padding: 0 5px;
            border: 1px solid #f1f1f1;
            margin-top: vw2(8);
          }

          .skewing {
            position: relative;
            right: vw2(8);
          }

        }

        .location-right {
          flex: 2;
          text-align: center;

          .jump {
            width: vw2(88);
            height: vw2(22);
            line-height: vw2(22);
            background: rgba(255, 255, 255, 0.8);
            border-radius: 3px;
            padding: 0 vw2(5);
            margin-bottom: vw2(10);
            cursor: pointer;
          }
        }
      }

      img {
        width: vw2(65);
        height: vw2(65);
        vertical-align: top;
      }

      .text-right{
        float: right;
        margin-right: vw2(10);
        .back-tips {
          height: vw2(25);
          line-height: vw2(25);
          font-size: vw2(11);
          color: #1D2233;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 3px;
          padding: 0 vw2(5);
          margin-top: 1.33vw;
        }
      }

      .back-skewing {
        position: relative;
        left: -2.13vw;
      }

      .back-float-r {
        float: right;
        margin-right: vw2(10);
      }
    }

    .bottom-location {
      position: absolute;
      bottom: vw2(4);
      left: 0;
      width: 100%;
      height: vw2(60);
      font-size: 0;
      color: #1D2233;

      .new-icon-location{
        display: inline-block;
        width: vw2(60);
        height: vw2(60);
        vertical-align: top;
        img {
          width: vw2(35);
          height: vw2(35);
          margin-left: vw2(15);
          box-shadow: 0px 10px 15px 0px rgba(136, 141, 152, 0.27);
        }
      }
      .tips {
        display: inline-block;
        height: vw2(25);
        line-height: vw2(25);
        background: rgba(255, 255, 255, 0.8);
        border-radius: 3px;
        padding: 0 5px;
        border: 1px solid #f1f1f1;
        margin-top: vw2(5);
        font-size: vw2(11);
      }

      .skewing {
        position: relative;
        right: vw2(8);
      }

      .float-r {
        float: right;
        margin-right: vw2(10);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.legend-box {
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 0px vw2(20) vw2(30) 0px rgba(136, 141, 152, 0.16);
  border-radius: vw2(10);
  opacity: 0.9;
  position: absolute;
  top: vw2(10);
  left: vw2(10);
  padding: vw2(10) vw2(14);
  max-width: vw2(567);
  box-sizing: border-box;
  .legend-box-item {
    display: flex;
    align-items: center;
    margin-right: vw2(20);
    height: vw2(34);
    &:last-child {
      margin-right: 0;
    }
    .legend-box-item-grid {
      width: vw2(16);
      height: vw2(16);
      border: vw2(1) solid rgba(255, 255, 255, 0.5);
    }
    .legend-box-item-name {
      font-weight: 400;
      color: #1D2233;
      font-size: vw2(18);
      margin-left: vw2(6);
    }
  }
}
.map-container {
  width: 100%;
  height: 100%;
  position: relative;

  .hot-map-container {
    width: 100%;
    height: 100%;
    position: relative;
    .hot-map-container-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .left-explain {
    position: absolute;
    bottom: vw2(5);
    left: vw2(5);
    height: vw2(25);
    line-height: vw2(25);
    border-radius: vw2(5);
    font-size: vw2(11);
    color: #73788E;
    background: #fff;
    padding: 0 5px;
    display: flex;
    align-items: center;
    z-index: 1000;
    .legend-color {
      display: flex;
      margin: 0 vw2(5);
      span {
        width: vw2(8);
        height: vw2(8);
      }
    }
  }

  .right-zoom {
    position: absolute;
    bottom: 0;
    right: 0;
    height: vw2(46);
    line-height: vw2(46);
    font-size: vw2(11);
    color: #1D2233;

    img {
      width: vw2(62);
      height: vw2(46);
      vertical-align: middle;
    }

    .tips {
      position: relative;
      left: vw2(10);
      display: inline-block;
      height: vw2(25);
      line-height: vw2(25);
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      padding: 0 vw2(5);
    }
  }


}
</style>
