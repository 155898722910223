<!-- 品牌洼地 -->
<template>
    <div class="additional">
        <StateBar :bgcolor="custom ? 'white' : 'transparent'" />
        <TitleHeader :title="title" :back="showBack" />

        <AutoView :footer="true" class="additional--view">
            <div v-if="entity">
                <img v-if="entity.cover" :src="entity.cover" class="cover" />
                <h3 v-if="entity.data" class="title">所选品牌：{{ entity.data.brandName }}</h3>
            </div>
        </AutoView>

        <!-- 底栏提交按钮 -->
        <SoltFooter>
            <div class="additional--footer">
                <van-button type="info" block class="s-radius__s" @click="showInputs = true">{{ text }}</van-button>
            </div>
        </SoltFooter>

        <!-- 报告提交 -->
        <van-popup v-model="showInputs" position="bottom">
            <div class="additional--popup">
                <div class="head-title txt-center">请确认意向加盟地区</div>
                <div class="l-panel s-radius__s">
                    <div class="l-panel s-radius__s f-vmt regionPicker" :class="{ on: field.city }" @click="showRegionPicker = true">
                        {{ field.city || '请选择加盟意向地区' }}
                    </div>
                </div>
                <SoltFooter class="btnView">
                    <van-button type="info" block :close-on-click-overlay="false" :disabled="!field.city" :loading="submitting" class="s-radius__s" @click="onTap" @click-overlay="onTapClose">去支付</van-button>
                </SoltFooter>
            </div>
        </van-popup>

        <!-- 省市区选择 -->
        <OpenCityPicker type="v2open" v-model="showRegionPicker" @confirm="getCityZone"/>

        <!-- 全局遮罩层 -->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import OpenCityPicker from './components/openCityPicker';

export default {
    name      : 'additionalSP10004',
    components: {
        OpenCityPicker,
    },
    data () {
        return {
            title     : '请稍候',
            text      : '立即评估',
            showBack  : true,
            loading   : false, // 数据加载状态
            submitting: false, // 提交状态

            showInputs      : false,
            showRegionPicker: false, // 行政区选择

            custom: false, // 特别报告（首页栏目入口）
            entity: {}, // 模型实体（数据加载）
            inputs: {
                REGION: true,
            },
            field: {
                city: null,
            },
            form: {
                REGION: {
                    code: null,
                    text: '请选择城市区域',
                },
            },
        };
    },
    methods: {
        loadData ( entity ) {
            this.entity = entity;
            this.custom = !! entity.custom;
            this.title  = entity.title || entity.showQuestion || '补充信息';
        },
        onTap () {
            if ( this.submitting ) return;
            // 埋点上报
            // this.JSBridge.report( this.JSBridge.REPORT_EVENT.CREATE.TAP_MK_GENERATE );
            this.submitting = true;
            const ent       = this.entity;
            const input     = [];
            for ( const key in this.inputs ) {
                input.push( {
                    code : key,
                    value: this.form[ key ],
                } );
            }
            input.push( {
                code : 'BRAND',
                value: {
                    code: ent.data.brandCode,
                    text: ent.data.brandName,
                },
            } );
            if ( ! ent.originalQuestion  ) ent.originalQuestion = ent.showQuestion;
            ent.showQuestion = `【${ent.data.brandName}】${ent.originalQuestion}报告-${this.field.city}`;
            this.HTTP.createReport( ent.showTemplateCnfId, input, ent.showQuestion, ent.originalQuestion ).then( ( res ) => {
                if ( res ) {
                    this.submitting = false;
                    this.onTapClose();
                }
                this.goto( res.reportInstanceId, res.waitTime, res.type );
            } ).catch( ( e ) => {
                if ( e.code ) {
                    if ( e.code === 201004 ) {
                        this.backHome();
                        return;
                    }
                } else {
                    this.$toast( '网络连接不佳，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
        onTapClose () {
            if ( this.submitting ) return;
            this.showInputs = false;
        },
        getCityZone ( picker, code ) {
            this.$set( this.form, 'REGION', {
                text: picker.join( '-' ),
                code: code[ 2 ] || code[ 1 ] || code[ 0 ],
            } );
            this.field.city = picker.join( '' );
        },
        backHome () {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'gotoPage', 'home' );
            } else {
                this.$router.push( '/' );
            }
        },
        goto ( id, seconds, type ) {
            const ms = typeof seconds === 'number' ? seconds * 1000 : 0;
            if ( ms ) {
                this.loading = true;
            }
            setTimeout( () => {
                switch ( type ) {
                    case 2:
                        this.swRouter( '/pay/' + id );
                        break;
                    case 3:
                        this.swRouter( '/h5/#/my/reports' );
                        break;
                    default:
                        this.swRouter( { path: '/charts/' + id, query: { first: true } } ); // first 为首次打开
                }
            }, ms );
        },
    },
    created () {
        // 直接传递提问信息
        let entity = this.$route.params;
        if ( ! entity || ! Object.keys( entity ).length ) {
            entity = this.$store.getters.currentQst;
        } else {
            this.$store.commit( 'setCurrentQst', entity );
        }
        if ( ! entity || ! Object.keys( entity ).length ) {
            this.backHome();
            return;
        }
        this.loadData( entity );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.additional {
    min-height: 100vh;
    background-color: #3C466A;

    #{&}--view {
        position: relative;
        .cover {
            width: 100%;
            max-width: 981px;
        }
        .title {
            @include flexColumn;
            position: absolute;
            top: 0;
            left: 50%;
            height: 7.34vw;
            padding: 0 9vw;
            @include font( white, $font_320, bold );
            background:linear-gradient(90deg,rgba(81,140,219,0.52) 0%,rgba(81,140,219,1) 100%);
            border-radius: 0 0 $padding $padding;
            transform: translateX(-50%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    #{&}--footer {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;

        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
    }

    #{&}--popup {
        border-top-left-radius: $radius-s;
        h3 {
            padding: $padding2 $padding;
            font-size: $font_480;
            line-height: $font_667;
        }
        .tips {
            color: $c-info;
            font-size: $font_320;
            line-height: $font_440;
            margin-top: $margin;
            &.sty2 {
                display: inline-block;
                margin: 12vw 0 3vw;
                color: $c-main;
                font-size: $font_347;
                padding-left: 5.5vw;
                background: url( /static/icon/mark.png ) no-repeat left center;
                background-size: contain;
            }
        }
        .head-title {
            text-align: center;
            padding: 5.87vw 0 4.4vw;
            font-size: 4.5vw;
            font-weight: 800;
            color: rgba(29,34,51,1);
        }
        .btnView {
            position: static;
            // height: $footerHeight;
            box-sizing: border-box;
            padding: $headerMargin $padding;
            margin-top: 2vw;
            ::v-deep .soltFooter--view {
                height: 10.67vw;
            }
            ::v-deep .van-col {
                height: 100%;
            }
        }
        .regionPicker {
            position: relative;
            background-color: #F7F6F9;
            color: #AEB1BD;
            height: 13.3vw;
            font-size: 4.27vw;
            line-height: 13.3vw;
            box-shadow: inset 0 0 0 1px #EBEAEE;
            margin-bottom: 24vw;

            &.on {
                color: $c-text;
            }

            &:after {
                position: absolute;
                content: '切换';
                right: $padding1;
                color: $c-main;
                font-weight: normal;
                font-size: $font_320;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
        .l-panel {
            padding: 0 $padding;
        }
    }
}
</style>
