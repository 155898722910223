<template>
  <div>
    <StateBar />
    <TitleHeader title="提交订单" bgcolor="transparent" color="black" :back="true"
                 style="border-bottom: 1px solid #F3F4F7;">
    </TitleHeader>
    <AutoView :header="true" :footer="true">
      <div class="cell-group">
        <div class="cell cell-group-title">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-4.png" />
          </div>
          <div class="cell-bd">购买服务</div>
        </div>
        <div class="cell">
          <div class="cell-hd key">{{payInfo && payInfo.name}}</div>
          <div class="cell-ft val price">
            <s>¥{{payInfo && payInfo.salePrice}}</s>
            <b>¥</b>
            <ins>{{payInfo && payInfo.price}}</ins>
          </div>
        </div>
      </div>

      <div class="cell-group">
        <div class="cell cell-group-title">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-3.png" />
          </div>
          <div class="cell-bd">服务内容</div>
        </div>
        <div class="cell">
          <div class="cell-hd">置顶广告位</div>
          <div class="cell-ft"><i class="word-icon">赠</i>90天</div>
        </div>
        <div class="cell">
          <div class="cell-hd">流量曝光</div>
          <div class="cell-ft">展示在铺广场</div>
        </div>
        <div class="cell">
          <div class="cell-hd">买家匹配</div>
          <div class="cell-ft">根据大数据推荐</div>
        </div>
        <div class="cell">
          <div class="cell-hd">1对1服务</div>
          <div class="cell-ft">全流程跟进</div>
        </div>
      </div>


      <div class="cell-group" @click="gotoAgreement">
        <div class="cell cell-group-title agreement no-border no-margin" :class="{'agree': isAgree}">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-1.png" />
          </div>
          <div class="cell-bd">商铺租赁代理协议</div>
          <div class="cell-ft check" v-if="isAgree">
            <img src="static/shopEcology/lease/ag-uncheck.png" />
          </div>
          <div class="cell-ft">{{ isAgree == true ? '已签署' : '未签署' }}</div>
          <div class="cell-ft arrow">
            <img src="static/shopEcology/lease/arr-right.png" />
          </div>
        </div>
      </div>


      <div class="cell-group">
        <div class="cell cell-group-title no-margin">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-2.png" />
          </div>
          <div class="cell-bd">支付方式</div>
        </div>
        <div class="cell pay-cell" v-for="(item, index) in paymentMethod" @click="checkPay(item)">
          <div class="cell-hd">
            <img :src="item.icon" />
          </div>
          <div class="cell-bd">{{item.name}}</div>
          <div class="cell-ft check">
            <img src="static/shopEcology/lease/checked.png" v-if="item.mode == checkedMethod.mode"/>
            <img src="static/shopEcology/lease/uncheck.png" v-else/>
          </div>
        </div>
        <!--<div class="cell pay-cell" @click="checkPay(PAY_ENUM.WECHAT)">-->
        <!--  <div class="cell-hd">-->
        <!--    <img src="static/shopEcology/lease/pay_icon_wechat.png" />-->
        <!--  </div>-->
        <!--  <div class="cell-bd">微信支付</div>-->
        <!--  <div class="cell-ft check">-->
        <!--    <img src="static/shopEcology/lease/checked.png" v-if="payType == PAY_ENUM.WECHAT"/>-->
        <!--    <img src="static/shopEcology/lease/uncheck.png" v-else/>-->
        <!--  </div>-->
        <!--</div>-->
      </div>

    </AutoView>

    <div class="vipShop--footer">
      <div class="footer">
        <van-button class="pay-btn" @click="onTap">
          立即支付 ¥{{payInfo && payInfo.price}}
        </van-button>
        <!--<XsthButton />-->
      </div>
    </div>

    <!-- 支付等待遮罩 -->
    <van-overlay :show="waiting">
      <div class="s-overlay">
        <van-loading color="#518CDB" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    let paymentMethod = [
      {
        name: '支付宝',
        mode: 'ALIPAY_MOBILE',
        call: 'aliPay',
        unit: '￥',
        icon: 'static/shopEcology/lease/pay_icon_alix.png',
      },
      {
        name: '微信',
        mode: 'WX_APP',
        call: 'wxPay',
        unit: '￥',
        icon: 'static/shopEcology/lease/pay_icon_wechat.png'
      }
    ];
    return {
      isAgree: false,
      payInfo: null,
      checkedMethod: paymentMethod[0],
      paymentMethod: paymentMethod,
      orderNo: null,
      waiting: false, // 等待支付
      client: 3,
    }
  },
  mounted() {
    this.defaultAgree();
    this.isAgree = this.$route.query.isAgree || false;
    this.JSBridge.registerHandler("onAppResume", (data) => {
      console.log('app 调用 onAppResume 函数成功');
      this.JSBridge.callHandler("getString", 'agreement', (data) => {
        this.isAgree = (data == 'true');
        console.log(typeof this.isAgree, this.isAgree);
      });
    })
    this.HTTP.getPayOrderInfo().then((res) => {
      this.payInfo = res.data || null;
      console.log(this.payInfo, 99)
    });
    // 设置客户端类型
    if (window.isIOS) {
      this.isIOS  = true;
      this.client = 1;
      // if(window._appCode >= 301 ) {
      //     this.vipList.splice(1,1)
      // }
    } else if (window.isAndroid) {
      this.isAndroid = true
      this.client = 2;
    }
    this.registerPay();
  },
  methods: {
    defaultAgree(){
      this.JSBridge.callHandler('putString', JSON.stringify({
        key: 'agreement',
        value: 'false',
      }));
    },
    registerPay(){
      // 注册支付回调
      this.JSBridge.registerHandler("paymentResult", (data) => {
        this.waiting = false;
        // 安卓端需要进行转义
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        if (typeof data === "object") {
          switch (data.state) {
            // 支付成功
            case 1:
              // 通知客户端更新用户信息
              this.JSBridge.callHandler("noticeUpdate", "userInfo");
              console.log('id',this.orderNo);
              this.swRouter({
                path: '/shopEcology/shopTransferSuccess?id=' + this.orderNo,
              })
              this.JSBridge.callHandler("closePage");
              break;
            case 5: // 兼容微信
              break;
            case 7: // 超时
            case 8: // 取消
              this.$toast("已取消支付");
              break;
            default:
              // 支付失败
              // this.showPayment = false;
              // this.payTips = this.payTipsMap.failed;
              // this.payTips.state = false;
              // this.showPayTips = true;
              console.log("支付失败", data.status, this.payTips)
          }
        } else {
          this.$toast("支付时发生错误！");
        }
      });
    },
    gotoAgreement() {
        this.swRouter(`/shopEcology/agreement?isAgree=${this.isAgree}&isPayed=false`)
    },
    onTap() {
      // 埋点
      this.JSBridge.BTNCLICK( this.$route.meta.id, this.payInfo.id, '2480100','2480101');
      if(!this.payInfo) {
        this.$toast('商品详情未获取到，请稍后再试，或重新进入页面');
        return;
      }
      if(!this.isAgree) {
        this.$toast({
            message: '请先签署协议',
            icon: 'static/shopEcology/lease/toast_wrong.png'
        })
        return
      }
      //业务流程
      this.waiting = true;
      console.log("创建订单..." + this.checkedMethod.mode + "====" + this.payInfo.id + "=====" + this.client);
      this.HTTP.createMap(this.payInfo.id, this.checkedMethod.mode, this.payInfo.id,  this.client, this.$route.meta.id)
        .then((res) => {
          console.log(res, "打印payResult")
          console.log("创建订单...end");
          console.log("调起支付...");
          console.log(res, 88)
          this.orderNo = res.orderNo;
          const data = {
            id: res.orderNo,
            data: res.payResult,
          };
          if (window.isClient) {
            this.JSBridge.callHandler(this.checkedMethod.call, data, (st) => {
              if (!st) {
                this.$toast("未安装客户端，调起支付失败！");
                setTimeout(() => {
                  this.waiting = false;
                }, 1000);
                console.debug(`调起支付...失败`);
                return;
              }
              console.debug(`调起支付...end`);
            });
          } else {
            this.$toast("暂不支持网页版支付");
            setTimeout(() => {
              this.waiting = false;
            }, 1000);
          }
        })
        .catch((e) => {
          this.$toast("创建订单时发生错误！");
          setTimeout(() => {
            this.waiting = false;
          }, 1000);
        });
    },
    checkPay(method){
      this.checkedMethod = method;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
@import '@/styles/_cell.scss';
*{
  box-sizing: border-box;
}
.autoView{
  padding: vw(20);
  background: #F8F8F9 ;
  .cell-group{
    background: #FFFFFF;
    border-radius: vw(10);
    padding: vw(20) vw(25);
    margin-bottom: vw(20);
    .cell-group-title{
      padding-bottom: vw(20);
      border-bottom: 1px solid #E4E7ED;
      margin-bottom: vw(20);
      .cell-hd{
        img{
          width: vw(40);
          display: inline-block;
        }
      }
      .cell-bd{
        font-size: vw(28);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #1D2233;
        padding-left: vw(10);
      }
    }
    .cell{
      padding: vw(10) 0;
    }
    .cell-hd{
      font-size: vw(26);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #1D2233;
      padding: vw(5) 0;
    }
    .cell-ft{

      font-size: vw(26);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #676C84;
    }
    .pay-cell{
      border-bottom: 1px solid #F3F4F7;
      padding: vw(30) 0;
      &:last-child{
        border: none;
      }
      .cell-hd{
        width: vw(36);
        img{
          width: 100%;
        }
      }
      .cell-bd{
        font-size: vw(26);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #1D2233;
        padding-left: vw(20);
      }
      .check{
        img{
          width: vw(30);
        }
      }
    }
  }
  .price{
    font-size: 0;
    s{
      font-size: vw(22);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #8893A7;
      line-height: vw(30);
    }
    b{
      font-size: vw(22);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2233;
      line-height: vw(30);
      margin-left: vw(18);
    }
    ins{
      font-size: vw(30);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1D2233;
      line-height: vw(42);
      font-style: normal;
      text-decoration: none;
    }
  }
  .agreement{
    &.agree {
      .cell-ft{
        color: #676C84 !important;
      }
    }
    .cell-ft{
      font-size: vw(26);
      padding-left: vw(10);
      color: #347FFF;
    }
    .check{
      padding-left: vw(10);
      font-size: 0;
      img{
        width: vw(30);
        display: block;
      }
    }
    .arrow{
      font-size: 0;
      padding-left: 0;
      img{
        width: vw(34);
      }
    }
  }
  .word-icon{
    background: #FFF1E1;
    border-radius: vw(4);
    border: 1px solid #8D5D22;
    font-size: vw(22);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #8D5D22;
    line-height: vw(25);
    font-style: normal;
    padding: 0 vw(8);
    margin-right: vw(10);
  }
}
.footer{
  width: 100%;
  padding: vw(20) vw(16) 0 vw(16);
  .pay-btn{
    width: 100%;
    height: vw(80);
    background: #FF8047;
    border-radius: vw(10);
    border: none;

    font-size: vw(30);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: vw(42);
  }
}
.no-margin{
  margin: 0 !important;
}
.no-border{
  border: 0 !important;
}
</style>
