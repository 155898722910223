<template>
    <div class="invite">
        <!-- 介绍界面 -->
        <img src="/static/img/invite_210107.png" class="invite--bg">
        <div class="invite--footer">
            <button type="button" @click="download">立即领取福利</button>
        </div>
    </div>
</template>

<script>
// import AWSC from 'AWSC';

const BASE_NUM = 60; // 默认等待时间

export default {
    name: 'invite',
    data () {
        return {
            title: '上上参谋，生意人的数据军师',

            loading     : true,
            showRegister: false,
            submitting  : false,
            isRegistered: false,
            isSendCode  : false,
            timer       : 0,
            waitingTime : BASE_NUM,

            inviteDetail: {
                avatar            : '',
                discount          : '',
                username          : '',
                invitePic         : '',
                couponExist       : false,
                haveRegisterCoupon: false,
                inviteDescribe    : '',
            },
            form: {
                code      : '',
                phone     : '',
                fromType  : '',
                inviteCode: '',
                inviteType: 1, // 普通邀请 定值
            },

            // 是否进行了滑块验证
            hasVerification: false,
            verification   : {
                phone    : '',
                scene    : 'nc_login',
                sessionId: '',
                sig      : '',
                token    : ''
            }
        };
    },
    methods: {
        download () {
            top.location.href = this.HTTP.getAppStore();
        },
    },
    created () {
        // 设置标题
        document.title = this.title;
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.invite {
    position: relative;
    min-height: 100vh;
    background-color: #451DB2;
    padding-bottom: 20vw;
    box-sizing: border-box;
    // 25vw

    #{&}--bg {
        width: 100%;
    }

    #{&}--footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 20vw;
        background: linear-gradient(to bottom, #7335E6, #451DB4);
        border-radius: $radius_s $radius_s 0 0;
        text-align: center;
        padding: $margin $padding;
        box-sizing: border-box;
        box-shadow: 0 -2vw 5vw rgba(#451DB4, .3);

        .info {
            @include font( #FFBB00, 2.66vw, normal );
            margin-top: $margin * 0.5;
        }
    }

    #{&}--form {
        position: absolute;
        top: $padding;
        width: calc( 84vw - #{ $padding * 1.6 } );
        z-index: 2;

        input, button {
            @include ani;
            display: block;
            width: 100%;
            height: 13vw;
            font-size: $font_400;
            line-height: normal;
            padding: 0 4.8vw;
            margin-bottom: 3.6vw;
            border-radius: $radius_xs;
            border: none;
            box-sizing: border-box;
            -webkit-appearance: none;

            &::-webkit-input-placeholder {
                color: #AEB1BD;
            }
            &[disabled] {
                color: #D2D2D2!important;
                opacity: .8;
            }
        }

        .w_1 {
            width: 60%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .w_2 {
            width: 40%;
            @include font( #451DB4, 2.93vw, bold );
            background-color: white;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .nc-wrap {
            position: block;
            width: 100%;
            height: 34px;
            margin-bottom: 3.6vw;
        }
    }

    button {
        background: linear-gradient(to right, #FFA943, #FE6D40);
        color: white;
        font-size: $font_373;
        font-weight: bold;
        width: 100%;
        height: 13vw;
        border-radius: $radius_xs;
        border: none;
    }

    #{&}--mask {
        @include flexColumn;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, .7);

        .panel {
            position: absolute;
            width: 84vw;
            height: 80vw;
            top: 25%;
            padding: $padding $padding * .8;
            box-sizing: border-box;
            border-radius: $radius;
            background: linear-gradient(to bottom, #9C67FF, #6334E3);

            &:before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                width: calc( 100% - 2px );
                height: calc( 100% - 2px );
                border-radius: $radius;
                background: linear-gradient(to bottom, #7335E6, #451DB4);
            }

            &:after {
                content: '';
                position: absolute;
                top: 1vw;
                left: 0;
                width: 90vw;
                height: 18vw;
                margin-left: -3vw;
                background-size: contain;
            }

            img {
                display: block;
                width: 17.6vw;
                height: 17.6vw;
                margin: 0 auto 6.1vw;
            }

            h3 {
                @include font( #FFDB00, $font_560, bold );
                margin-bottom: $margin * 2;
            }

            .info {
                color: white;
                font-size: 2.93vw;
                margin-bottom: $padding * 1;
                opacity: .8;
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        @include ani(.5s);
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
}
</style>
<style lang="scss">
.nc-wrap {
    .nc-container #nc_1_wrapper {
        width: 100%;
    }
}
</style>
