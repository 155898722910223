export const IndustryList = [
    '全部品类',
    '烧烤',
    '其他饭类套餐',
    '米粉/米线',
    '炸物类小吃',
    '其他地方菜',
    '卤味类小吃',
    '其他面馆',
    '川菜',
    '汉堡',
    '麻辣烫/关东煮',
    '地方菜系',
    '包子/生煎',
    '火锅串串',
    '快食简餐',
    '饺子馄饨',
    '湘菜',
    '香锅干锅',
    '粥店',
    '烤物类小吃',
    '龙虾烧烤',
    '日韩料理',
    '米粉面馆',
    '其他小吃'
];