const asking4Rent = () => import('@/views/shopEcology/asking4Rent'); //发布求租信息页
const asking4RentList = () => import('@/views/shopEcology/asking4Rent/list'); //求租信息列表页
const askingRentDetails = () => import('@/views/shopEcology/asking4Rent/details'); //发布求租信息页
const asking4RentSuccessful = () => import('@/views/shopEcology/asking4RentSuccessful'); //发布求租信息成功页
const multiCommunitySelect = () => import('@/views/shopEcology/asking4Rent/multiCommunitySelect'); //求租区域多选页
const asking4RentPersonalInfo = () => import('@/views/shopEcology/asking4Rent/personalInfo'); //求租信息综合页
export default [{
    path: '/shopEcology/asking4Rent',
    name: 'asking4Rent', //发布求租信息页
    component: asking4Rent,
    meta: {
      id: 10318
    },
  }, {
    path: '/shopEcology/asking4RentList',
    name: 'asking4RentList', //发布求租列表页
    component: asking4RentList,
    meta: {
      id: 10321
    },
  }, {
    path: '/shopEcology/askingRentDetails',
    name: 'askingRentDetails', //求租信息详情页
    component: askingRentDetails,
    meta: {
      id: 10317
    },
  }, {
    path: '/shopEcology/asking4RentSuccessful',
    name: 'asking4RentSuccessful', //求租信息成功页
    component: asking4RentSuccessful,
    meta: {
      id: 10320
    },
  }, {
    path: '/shopEcology/multiCommunitySelect',
    name: 'multiCommunitySelect', //城市区域选择页
    component: multiCommunitySelect,
    meta: {
      id: 10325
    },
  },
  {
    path: '/shopEcology/asking4RentPersonalInfo',
    name: 'asking4RentPersonalInfo', //综合列表页
    component: asking4RentPersonalInfo,
    meta: {
      id: 10323
    },
  },
]
