import { render, staticRenderFns } from "./College.vue?vue&type=template&id=51d9c17f&scoped=true"
import script from "./College.vue?vue&type=script&lang=js"
export * from "./College.vue?vue&type=script&lang=js"
import style0 from "./College.vue?vue&type=style&index=0&id=51d9c17f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d9c17f",
  null
  
)

export default component.exports