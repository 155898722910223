<template>
  <div class="guide">
    <StateBar bgcolor="transparent" />
    <TitleHeader bgcolor="white" :back="false"></TitleHeader>

    <AutoView ref="view" :header="false" :footer="false" class="guide--view">
      <img class="logo-img" src="/static/icon/guide_logo.png" alt="" />
      <p class="title">欢迎使用上上参谋</p>
      <p class="explain">为了给您提供更好的服务，建议您进行基础测评，仅需1-2分钟就可以完成。</p>
      <van-button type="info" block @click="swRouter('/guideOne')" class="btn_btn">
        <span>完成即赠50元优惠券，GO </span>
        <img src="/static/icon/right_arrow.png" alt="">
      </van-button>
    </AutoView>
  </div>
</template>

<script>
export default {
  beforeMount() {
    this.JSBridge.callHandler("interceptBack", true);
  },
};
</script>

<style lang="scss" scoped>
.guide {
  height: 100vh;

  #{&}--view {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 8vw;
    .logo-img {
      width: 79.47vw;
      height: 67.2vw;
    }
    .title {
      font-size: 6.13vw;
      color: #1d2233;
      font-weight: bold;
    }
    .explain {
      font-size: 4vw;
      color: #999;
      line-height: 5.87vw;
      margin: 11.2vw 0 26.67vw 0;
    }
    .btn_btn{
      img {
        width: 3.73vw;
        height: 3.07vw;
      }
    }
  }
  ::v-deep .van-button {
    width: 92%;
  }
  ::v-deep .van-button.van-button--info{
    background: #347FFF;
    border-color: #347FFF;
    box-shadow: 0vw 4vw 13.33vw 0vw rgba(75, 142, 234, 0.3), 0vw 0.53vw 0.8vw 0vw rgba(255, 255, 255, 0.4)
  }
}
</style>
