<template>
    <div class="tokeout-list__wrap">
        <van-sticky :offset-top="headerHeight">
            <div class="select-box">
                <div class="sel-city">
                    <span @click="handleClick" v-if="searchOpt.tabName === 1">
                        {{ currentCity }}
                    </span>
                </div>
                <div class="industry-tabs-wrap" :swipe-threshold="4">
                    <van-tabs v-model="searchOpt.tabName">
                        <van-tab :title="tab.name" :name="tab.id" v-for="(tab, i) in tabList" :key="i"></van-tab>
                    </van-tabs>
                </div>
            </div>
        </van-sticky>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div class="content" v-if="!firstLoading">
            <div class="common hot-list" v-if="searchOpt.tabName === 1">
                <p class="title">全国什么行业最火？</p>
                <p class="txt">
                    全国开店创业者首选咨询行业是<span>{{ pieIndustryRatioData[0] ? pieIndustryRatioData[0].name : '' }}</span
                    >，在开店用户中占比高达{{ pieIndustryRatioData[0] ? pieIndustryRatioData[0].value : '' }}%
                </p>
                <div class="chart-con">
                    <PieChart :seriesData="pieIndustryRatioData" />
                </div>
                <h4 class="bd-txt">查询周边行业分布</h4>
                <p class="txt" style="text-align:center">
                    周边概况、业态分布、经营数据，了解这些再开店！
                </p>
                <div
                    class="btn"
                    @click="deeplink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '1' }, id: '83100', btnId: '83101' })"
                >
                    免费查周边
                </div>
                <div class="line"></div>
                <p class="title">{{ currentCity }}餐饮行业热度</p>
                <p class="txt">
                    餐饮行业中最受欢迎的是<span>{{ xAxisNamesEatRatio.slice(-1)[0] }}</span> ，热度占比为{{ seriesDataEatRatio[0].slice(-1)[0] }}%
                </p>
                <div class="chart-con" style="height: 70vw">
                    <van-skeleton :loading="!xAxisNamesEatRatio.length" animate :row="7" class="s-skeleton s-radius__s"> </van-skeleton>
                    <VerticalBar v-if="xAxisNamesEatRatio.length" :xAxisNames="xAxisNamesEatRatio" :seriesData="seriesDataEatRatio" />
                </div>
                <div class="line"></div>
                <p class="title">{{ currentCity }}零售行业热度</p>
                <p class="txt">
                    零售行业中最受欢迎的是<span>{{ xAxisNamesRetailRatio.slice(-1)[0] }}</span
                    >,热度占比为{{ seriesDataRetailRatio[0].slice(-1)[0] }}%
                </p>
                <div class="chart-con" style="height: 100vw">
                    <van-skeleton :loading="!xAxisNamesRetailRatio.length" animate :row="7" class="s-skeleton s-radius__s"> </van-skeleton>
                    <VerticalBar v-if="xAxisNamesRetailRatio.length" :xAxisNames="xAxisNamesRetailRatio" :seriesData="seriesDataRetailRatio" />
                </div>
            </div>
            <div class="common hot-area" v-show="searchOpt.tabName === 2">
                <p class="title">查看开店最热区域</p>
                <setupMap @complete="complete" />
                <h4 class="bd-txt">查询城市开店热区</h4>
                <p class="txt" style="text-align:center">
                    什么商圈火爆？哪个位置开店更赚钱？
                </p>
                <div class="btn" @click="deeplink({ type: '5', url: 'SEARCH_SURROUNDING_V2', id: '83100', btnId: '83102' })">免费查区域</div>
                <div class="line"></div>
                <p class="title">Top8开店热区以及偏好占比</p>
                <div class="chart-con" style="height: 70vw" v-if="searchOpt.tabName === 2">
                    <van-skeleton :loading="!xAxisNamesAreaRatio.length" animate :row="7" class="s-skeleton s-radius__s"> </van-skeleton>
                    <VerticalBar v-if="xAxisNamesAreaRatio.length" :xAxisNames="xAxisNamesAreaRatio" :seriesData="seriesDataAreaRatio" />
                </div>
                <div class="line"></div>
                <p class="title">查看开店最热功能区</p>
                <div class="chart-con" v-if="searchOpt.tabName === 2">
                    <PieChart :seriesData="pieAreaRatioData" :otherOption="{title: '功能区\n占比'}" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from './echarts/echarts-pie.vue';
import VerticalBar from './echarts/VerticalBar.vue';
import setupMap from '@/views/hotSearch/components/echarts/setupMap';
export default {
    name      : 'setupList',
    components: { PieChart, VerticalBar, setupMap },
    props     : {
        headerHeight: {
            type   : Number,
            default: 0
        }
    },
    data () {
        return {
            firstLoading: false,
            currentCity : '当前城市',
            tabList     : [
                {
                    name: '最热排行',
                    id  : 1
                },
                {
                    name: '最热区域',
                    id  : 2
                }
            ],
            searchOpt: {
                tabName : 0,
                cityCode: ''
            },
            pieIndustryRatioData : [], // 全国行业
            pieAreaRatioData     : [], //
            xAxisNamesEatRatio   : [],
            seriesDataEatRatio   : [[]],
            xAxisNamesRetailRatio: [],
            seriesDataRetailRatio: [[]],
            xAxisNamesAreaRatio  : ['安徽', '四川', '湖北', '浙江', '河南', '江苏', '山东', '广东'],
            seriesDataAreaRatio  : [[4.17,4.66,5.38,6.33,7.25,7.43,8.39,9.22]],
            GDPSpeed: null
        };
    },
    beforeDestroy () {
        if ( this.map ) {
            this.map.destroy();
            this.map = null;
        }
    },
    mounted () {
        this.firstLoading = true;
        // 地图数据
        this.getAllCityData()
        const city = this.$store.getters.locaCity;
        if ( city && city.name ) {
            this.currentCity = city.name;
            this.searchOpt.cityCode = city.code;
            this.fetchData();
            return;
        }
        this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
          // console.log('data',data)
          let storeCity = {}
          if (data && data !=='null') {
            if ( typeof data === 'string' ) data = JSON.parse( data );
            storeCity = {
              name: data.city,
              code: data.adCode.slice( 0, 4 ) + '00'
            };
            this.currentCity = data.city;
            this.searchOpt.cityCode = data.adCode.slice( 0, 4 ) + '00';
          } else {
            storeCity = {
              name: '深圳市',
              code: '440300'
            };
            this.currentCity = '深圳市';
            this.searchOpt.cityCode = '440300';
          }
          this.$store.commit( 'setLocaCity', storeCity );
          // 获取数据
          this.fetchData();
      } );
    },
    methods: {
        // 地图
        complete ( map ) {
            this.map = map;
            var colors = {};
            var GDPSpeed = {
                '520000': 1.2,// 贵州
                '540000': 0.6,// 西藏
                '530000': 2.1, // 云南
                '500000': 3.95, // 重庆
                '360000': 3.63, // 江西
                '340000': 4.17, // 安徽
                '510000': 4.66, // 四川
                '350000': 3.5, // 福建
                '430000': 4.06, // 湖南
                '420000': 5.38, // 湖北
                '410000': 7.25, // 河南
                '330000': 6.33, // 浙江
                '640000': 0.78, // 宁夏
                '650000': 1, // 新疆
                '440000': 9.22, // 广东
                '370000': 8.39, // 山东
                '450000': 2.1, // 广西
                '630000': 1, // 青海
                '320000': 7.43, // 江苏
                '140000': 1.2, // 山西
                '460000': 2.3,// 海南
                '310000': 5.69, // 上海
                '110000': 7.8, // 北京
                '130000': 4.25, // 河北
                '230000': 2.3,// 黑龙江
                '220000': 2.5,// 吉林
                '210000': 2.7, // 辽宁
                '150000': 1.5, // 内蒙古
                '120000': 2.86,// 天津
                '620000': 1.2,// 甘肃
                '610000': 1.3, // 陕西
                '710000': 2.3, // 台湾
                '810000': 4.56, // 香港
                '820000': 2.78 // 澳门
            };
            if (!this.GDPSpeed) {
                this.GDPSpeed = GDPSpeed // 没有数据据的时候展示
            }
            const that = this
            var getColorByDGP = function ( adcode ) {
                if ( ! colors[adcode] ) {
                    var gdp = that.GDPSpeed[adcode];
                    if ( ! gdp ) {
                        colors[adcode] = 'rgba(33,115,255, 0.1)';
                    } else {
                        var r = 33;
                        var g = 115;
                        var b = 255;
                        var a = gdp / 10;
                        colors[adcode] = 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
                    }
                }
                return colors[adcode];
            };
            var disCountry = new AMap.DistrictLayer.Country( {
                zIndex: 10,
                SOC   : 'CHN',
                depth : 1,
                styles: {
                    'nation-stroke'   : '#619BFF',
                    'coastline-stroke': '#0088ff',
                    'province-stroke' : '#8E9099',
                    fill              : function ( props ) {
                        return getColorByDGP( props.adcode_pro );
                    }
                }
            } );
            this.map.setLayers( [disCountry] );
        },
        // 选择城市
        handleClick () {
            this.JSBridge.callHandler( 'selectCity', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                this.currentCity = data.name;
                this.searchOpt.cityCode = data.code;
                this.fetchData();
            } );
        },
        // 获取地图数据
        getAllCityData() {
            this.HTTP.allCityCount().then(res => {
                console.log('全国地图', res);
                if (res.data.length) {
                    const arr = []
                    this.xAxisNamesAreaRatio = res.data.slice(0, 8).reverse().map(el => {
                        arr.push(el.value)
                        return el.name
                    })
                    this.seriesDataAreaRatio = [arr]

                    this.GDPSpeed = {}
                    res.data.forEach(el => {
                        this.GDPSpeed[el.id] = el.value
                    });
                }
            })
        },
        // 获取数据
        fetchData () {
            this.xAxisNamesEatRatio = [];
            this.xAxisNamesRetailRatio = [];
            this.HTTP.hotIndustry( this.searchOpt.cityCode ).then( ( res ) => {
                console.log( '创业热搜：', res );
                const arr = [];
                const arr1 = [];
                this.pieIndustryRatioData = res.data.industryRatio;
                this.pieAreaRatioData = res.data.areaRatio;
                this.xAxisNamesEatRatio = res.data.eatRatio.reverse().map( ( el ) => {
                    arr.push( el.value );
                    return el.name;
                } );
                this.seriesDataEatRatio = [arr];
                this.xAxisNamesRetailRatio = res.data.retailRatio.reverse().map( ( el ) => {
                    arr1.push( el.value );
                    return el.name;
                } );
                this.seriesDataRetailRatio = [arr1];
                this.firstLoading = false;
            } );
        },
        // 跳转
        deeplink ( { type, url, params, id, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            btnId && this.JSBridge.BTNCLICK( this.$route.meta.id, '', 83100, btnId );
            if (window._appCode && window._appCode >= 300) {
              this.JSBridge.callHandler( 'onNormalizingCall', {
                type: 5,
                url: "CHANGE_MAIN_TAB",
                id: "queryData",
                isClear: true,
              } );
            } else {
              this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                id,
                params: JSON.stringify( params )
              } );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.tokeout-list__wrap {
    ::v-deep .van-sticky--fixed {
        background: #f7f7f7;
    }
    .select-box {
        display: flex;
        align-items: center;
        // justify-content: center;
        height: 5.6vw;
        padding-top: 2vw;
        padding-bottom: 3vw;
        padding-left: 4vw;
        .sel-city {
            width: 23.26667vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 7.5vw;
            line-height: 7.5vw;
            margin-right: 4.86vw;
            font-size: 4vw;
            text-align: center;
            position: relative;
            span::after {
                position: absolute;
                content: '';
                border: 1.0667vw solid transparent;
                border-top: 1.3334vw solid #999;
                top: 2.8vw;
            }
        }
        .industry-tabs-wrap {
            min-width: 38.13vw;
            height: 7.5vw;
            line-height: 100%;
            white-space: nowrap;
            ::v-deep .van-tabs__wrap {
                height: 12.533vw;
            }
            ::v-deep .van-tabs__nav {
                background: transparent;
                padding: 0;
                .van-tab {
                    min-width: 9.26667vw;
                    height: 7.5vw;
                    margin-right: 1.06vw;
                    font-size: 3.733vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    background: transparent;
                    box-shadow: none;
                    color: #666;
                    &--active {
                        color: #333333;
                        font-weight: 700;
                        position: relative;
                        &::after {
                            display: none;
                        }
                    }
                }
                .van-tabs__line {
                    display: block;
                    height: 1.33vw;
                    background: url('/static/icon/hot_icon.png') center no-repeat;
                    background-size: 7.47vw 1.07vw;
                }
            }
        }
    }
    .content {
        padding: 0 2.666vw;
        margin-top: 3.333vw;
        .common {
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 2.666vw;
            padding: 3.333vw 0;
        }
        .title {
            height: 5.333vw;
            font-size: 3.733vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #1d2233;
            line-height: 5.333vw;
            position: relative;
            padding-left: 6.8vw;
            margin-bottom: 1.6vw;
            &::before {
                position: absolute;
                content: '';
                width: 2.4vw;
                height: 2.4vw;
                background: #ffa54f;
                border-radius: 50%;
                top: 1.2vw;
                left: 3.33vw;
            }
        }
        .bd-txt {
            font-size: 4.266vw;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 700;
            color: #1d2233;
            line-height: 6vw;
            text-align: center;
        }
        .btn {
            width: 77.33vw;
            height: 10.66vw;
            line-height: 10.66vw;
            text-align: center;
            background: linear-gradient(90deg, #ff5900 0%, #ff8000 100%);
            border-radius: 1.066vw;
            color: #fff;
            font-weight: bold;
            font-size: 4vw;
            margin: 2.133vw auto;
        }
        .txt {
            font-size: 3.466vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 4.93vw;
            padding-left: 3.46vw;
            > span {
                color: #347fff;
            }
        }
        .chart-con {
            width: 100%;
            height: 54.6vw;
        }
        .line {
            height: 0.2333vw;
            background: #ffe8b7;
            margin: 4.8vw 0 4vw;
        }
    }
}
</style>
