<!--
Name:
    选项标签卡
Props:
    option      : 选项列表
    value       : 默认选项下标
    icon        : 选中时展示图标 默认:true
    column      : 展示列数 默认:3
    multiple    : 开启多选 默认:false 暂不支持
    max         : 最大可选 默认:1 暂不支持
Events:
    change  : 选择触发
-->
<template>
    <div class="seltab">
        <p
            v-for="(item, index) of option"
            :key="index"
            class="item"
            :class="{ on: checked(item, index), noicon: !icon }"
            :style="style"
            @click="onTap(item, index)"
        >
            {{ item }}
        </p>
    </div>
</template>

<script>
export default {
    name: "seltab",
    props: {
        option: Array,
        value: null,
        icon: true,
        column: {
            type: Number,
            default: 3,
        },
        multiple: false,
        max: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        onTap(item, index) {
            this.$emit("change", { index, value: item });
        },
        checked(item, index) {
            let checked = false;
            if (!this.value && !Number.isInteger(this.value)) {
                checked = false;
            } else if (this.value === index || this.value === item) {
                checked = true;
            } else if (Array.isArray(this.value) && (this.value.includes(index) || this.value.includes(item))) {
                checked = true;
            }
            return checked;
        },
    },
    computed: {
        style() {
            return `width: calc( ${100 / this.column}% - 3vw )`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

$compHeight: 4vw;

.seltab {
    @include flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;

    .item {
        @include flexColumn;
        height: 8vw;
        border-radius: $radius_xs;
        margin: 0 $padding_3 $padding_3 0;
        font-size: $font_320;
        color: #333333;
        @include ani;
        background: #f5f5f5 url(/static/icon/checked_tr_blue@64.png) no-repeat right top;
        background-size: 0;

        &.on {
            color: $c-main;
            background-color: #d5e6ff;
            background-size: 6vw auto;
        }
        &.noicon {
            background-image: none;
        }

        &.disabled {
            opacity: 0.6;
            color: #999;
        }
    }
}
</style>
