<template>
   <div class='wrapper'>
       <StateBar/>
       <TitleHeader title="消息中心" bgcolor="transparent" :back="true">
            <div class="right-icon" slot="right">
                <img src="/static/me/clear.png" alt="" @click="readAllNotice" />
                <img src="/static/me/setting.png" alt="" @click="goSetting" />
            </div>
        </TitleHeader>
       <AutoView ref='view' :footer='false' class="information-view" :class="{emptyView: !(informationList && informationList.length)}">
            <template v-if="informationList.length || list.length">
                <div class="module-wrap" v-if="informationList && informationList.length">
                    <div class="cell type-list">
                        <div class="cell-bd cell-item" style="text-align: center;" v-for="(item, index) in informationList" :key="index" @click="lookMore(item)">
                            <div class="image" :class="{redPoint: item.status == 0}">
                                <img :src="item.icon">
                            </div>
                            <p>{{item.name}}</p>
                        </div>
                    </div>
                    <div class="msg-content-wrapper" v-if="informationList[0]">
                        <div class="msg-content" @click="lookDetail(informationList[0])">
                            <div class="cell">
                                <div class="cell-hd">
                                    <img :src="informationList[0].icon" />
                                </div>
                                <div class="cell-bd">{{informationList[0].name}}</div>
                                <div class="cell-ft">{{getShowTime(informationList[0].gmtCreate)}}<span class="spot" v-if="informationList[0].status == 0"></span></div>
                            </div>
                            <p>{{informationList[0].content}}</p>
                        </div>
                    </div>
                </div>
                <van-list v-if="list && list.length" v-model="loading" :finished="finished" finished-text="" @load="getInformationData">
                    <div class="user-list">
                        <div class="cell" v-for="(item, index) in list" :key="index" @click="startChat(item)">
                            <div class="cell-hd avatar">
                                <img :src="getFaceUrl(item.iconUrlList)" />
                            </div>
                            <div class="cell-bd content">
                                <p class="name">{{getShowName(item)}}</p>
                                <p class="msg"><span v-if="item.draft">[草稿]</span>{{item.displayMsgContent}}</p>
                            </div>
                            <div class="cell-hd">
                                <p class="time">{{getShowTime(item.lastMessageTime)}}</p>
                                <p class="red-point" v-if="item.unRead">{{item.unRead}}</p>
                            </div>
                        </div>
                    </div>
                </van-list>
            </template>
            <template v-else>
                <div class="empty">
                    <img src="static/shopEcology/information/empty.png" alt="">
                    暂无消息
                </div>
            </template>
       </AutoView>
   </div>
</template>

<script>
export default {
   data() {
       return {
           searchOps: {
                nextSeq: 0,
                count: 100
           },
           loading: true,
           firstLoading: true, // 首次加载
           finished: false,
           list: [], // 会话消息
           informationList: [], // 系统消息
       }
   },
   mounted() {
        this.getInformationData()
        this.loadData()
        // 注册会话监听
        this.JSBridge.callHandler("registerIMConversationNewOrChangeListener", null, (data) => {
            if(data) {
                this.onIMConversationChanged()
                this.onIMNewConversation()
            }
        })
   },
   methods: {
        // 获取会话列表
        getInformationData() {
            this.JSBridge.callHandler("getIMConversationList", JSON.stringify(this.searchOps), res => {
                const result = JSON.parse(res)
                console.log(result, 'result')
                if(result) {
                    this.firstLoading = false;
                    this.loading = false;
                    this.searchOps.nextSeq = result.nextSeq
                    if(result.nextSeq === 0) {
                        this.list = []
                        this.finished = true
                        return
                    }
                    if(this.list.length) {
                        this.list = this.list.concat(result.conversationList)
                    } else {
                        this.list = result.conversationList
                    }
                    if (result.conversationList.length < this.searchOps.count) {
                        this.finished = true; // 完成加载
                    }
                }
            });
        },
        // 会话变化回调
        onIMConversationChanged() {
            this.JSBridge.registerHandler("onIMConversationChanged", (data) => {
                console.log('app 调用 onIMConversationChanged 函数成功');
                let res = data
                if(typeof data === 'string') {
                    res = JSON.parse(data)
                }
                this.list.forEach((item, index) => {
                    console.log(item.conversationId)
                    res.forEach(items => {
                        if(item.conversationId === items.conversationId) {
                            // this.$set(this.list, index, items)
                            this.list.splice(index, 1)
                            this.list.unshift(items)
                        }
                    })
                })
            })
        },
        // 创建会话回调
        onIMNewConversation() {
            this.JSBridge.registerHandler("onIMNewConversation", (data) => {
                console.log('app 调用 onIMNewConversation 函数成功');
                let res = data
                if(typeof data === 'string') {
                    res = JSON.parse(data)
                }
                this.list.unshift(...res)
            })
        },
        // 开启会话
        startChat(item) {
            this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 3240400, 3240401);
            this.JSBridge.callHandler("startIMConversationChat", JSON.stringify(item), res => {
                const result = JSON.parse(res)
                if(!result) {
                    this.$toast.fail('开启会话失败')
                }
            })
        },
        goSetting() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 3240100, 3240102);
            this.swRouter('/setting?msgType=0')
        },
        lookMore(item) {
            let btnId
            switch(item.name) {
                case '最新资讯' :
                    btnId = 3240201;
                    break
                case '优惠福利' :
                    btnId = 3240202;
                    break
                case '开店助手' :
                    btnId = 3240203;
                    break
                case '报告通知' :
                    btnId = 3240204;
                    break
                case '精选品牌' :
                    btnId = 3240205;
                    break
            }
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 3240200, btnId);
            this.JSBridge.callHandler("onNormalizingCall", item.link);
        },
        lookDetail(item) {
            this.HTTP.readUserMsg(item.id).then(res => {
                item.status = 1;
            });
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 3240300, 3240301);
            this.JSBridge.callHandler("onNormalizingCall", item.contentLink);
        },
        // 一键已读
        readAllNotice() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 3240100, 3240101);
            if(this.list.every(el => el.unRead === 0) && (!this.informationList.length || this.informationList.every(el => el.status == 1))) {
                this.$toast({
                    message: '暂无未读通知',
                    position: 'middle'
                })
                return
            }
            this.JSBridge.callHandler('markIMAllMessageAsRead', '', (data) => {})
            this.HTTP.readAllMsg().then(res => {
                if (res) {
                    this.loadData()
                }
            })
        },
        loadData() {
            this.HTTP.queryMessageNotice().then(res => {
                console.log(res.msgList, '-------------------')
                if(res.msgList.length <= 5) {
                    this.informationList = res.msgList || []
                } else {
                    this.informationList = res.msgList.slice(0, 5)
                }
            })
        },
        getShowName(item) {
            return item.title ? item.title : item.id
        },
        getLastMessage(item) {
            return item.lastMessage.message.messageBaseElements[0].textContent
        },
        getFaceUrl(item) {
            const reg = /(http|https):\/\/\S*/
            if(item && item[0] && reg.test(item[0])) {
                return item[0]
            }
            return 'static/shopEcology/information/avatar.png'
        },
        getShowTime(e) {
            let value = e
            // 安卓传的时间戳为10位
            if(String(e).length === 10) {
                value = e * 1000
            }
            // 兼容ios系统 在ios系统中 new Date()格式要求为'YYYY/MM/DD HH:MM:SS' 或'YYYY-MM-DDTHH:MM:SS'
            if(window.isIOS && String(e).length === 19) {
                value = value.toString().replace(/\s/, "T")
            }
            const currnetDate = new Date().getDate()
            const currnetMounth = new Date().getMonth() + 1
            const currrentYear = new Date().getFullYear()
            let time = new Date(value)
            const year = time.getFullYear();
            let month = time.getMonth() + 1;
            if ( month < 10 ) {
                month = '0' + month;
            }
            let day = time.getDate();
            if ( day < 10 ) {
                day = '0' + day;
            }
            let hour = time.getHours();
            if ( hour < 10 ) {
                hour = '0' + hour;
            }
            let minute = time.getMinutes();
            if ( minute < 10 ) {
                minute = '0' + minute;
            }
            if(Number(year) < Number(currrentYear)) {
                return `${year}-${month}-${day}`
            } else if (Number(month) == Number(currnetMounth) && Number(day) == Number(currnetDate)) {
                return `${hour}:${minute}`
            } else {
                return `${month}-${day}`
            }
        }
   },
}
</script>
<style lang='scss' scoped>
@import '@/styles/px2vw.scss';
// @import '@/styles/index.scss';
.cell{
    display: flex;
    /*横向排列*/
    flex-direction: row ;
    /*不换行*/
    flex-wrap: nowrap;
    /*两端对齐*/
    justify-content:space-between;
    /*垂直居中*/
    align-items: center;
    .cell-hd {
        flex-grow: 0;
        flex-shrink:0;
        text-align: center;
        position: relative;
    }
    .cell-bd {
        flex-grow: 1;
        flex-shrink:1;
    }
    .cell-ft {
        flex-grow: 0;
        flex-shrink:0;
    }
}
.module-wrap {
    border-top: vw(2) solid #E7E7E7;
    .triangle {
        width: vw(24);
        height: vw(12);
        position: absolute;
        bottom: vw(-24);
        left: 50%;
        margin-left: vw(-12);
    }
    p{
        text-align: center;
        font-size: vw(24);
        margin-top: vw(16);
        color: #676C84;
    }
    .image {
        width: vw(90);
        height: vw(90);
        display: inline-block;
        img{
            width: vw(90);
            height: vw(90);
        }
    }
    .redPoint {
        position: relative;
        &::after {
            content: ' ';
            position: absolute;
            width: vw(20);
            height: vw(20);
            background: #FF504E;
            right: 0;
            top: 0;
            border-radius: 50%;
            border: vw(2) solid #FFFFFF;
        }
    }
    .msg-content-wrapper {
        background: #F7F7F7;
        padding-bottom: vw(20);
        position: relative;
        .msg-content {
            background: linear-gradient(rgba(247, 248, 251, 1), rgba(247, 248, 251, 0));
            background-color: #fff;
            padding: vw(28) vw(40) vw(32) vw(40);
            font-size: vw(24);
            img{
                width: vw(36);
                height: vw(36);
            }
            .cell-bd{
                padding-left: vw(12);
                font-size: vw(28);
                font-weight: bold;
            }
            .cell-ft{
                font-size: vw(22);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8893A7;
                line-height: vw(30);
                .spot {
                    background: #FF504E;
                    width: vw(16);
                    height: vw(16);
                    border-radius: 50%;
                    margin-left: vw(10);
                    display: inline-block;
                }
            }
            p{
                text-align: left;
                font-size: vw(24);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #8893A7;
                line-height: vw(33);
            }
            p span{
                color: #347FFF;
                margin-left: vw(5);
            }
        }
    }
}
.type-list{
    background-color: #fff;
    position: relative;
    padding: vw(40) vw(40) vw(24) vw(40);
    .cell-hd{
        position: relative;
    }
    .cell-item {
        &:first-child::after {
            content: ' ';
            position: absolute;
            width: vw(24);
            height: vw(12);
            bottom: vw(0);
            margin-left: vw(-12);
            background: url('/static/shopEcology/information/triangle.png') no-repeat left top;
            background-size: cover;
        }
    }
    i{
        position: absolute;
        right: vw(2);
        top: 0;
        width: vw(16);
        height: vw(16);
        background: #FF504E;
        border: vw(3) solid #FFFFFF;
        border-radius: 50%;
    }
}
.user-list{
    padding: 0 vw(30);
    background-color: #fff;
    .cell{
        padding: vw(30) 0;
        border-bottom: vw(1) solid #E4E7ED;
    }
    .avatar{
        width: vw(84);
        height: vw(84);
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .content{
        padding-left: vw(20);
        .name{
            font-size: vw(28);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1D2233;
            margin-bottom: vw(10);
        }
        .msg{
            font-size: vw(24);
            width: vw(500);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8893A7;
            line-height: vw(33);
            span {
                color: #FF504E;
            }
        }
    }
    .time{
        font-size: vw(22);
        margin-bottom: vw(10);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: vw(30);
    }
    .red-point{
        font-size: vw(20);
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        background-color: #FF504E;
        border-radius: vw(15);
        line-height: vw(28);
        display: inline-block;
        min-width: vw(28);
        height: vw(28);
        padding: 0 vw(8);
        box-sizing: border-box;
    }
}
.right-icon {
    img {
        width: 5.33vw;
        &:first-child {
            margin-right: 4.2vw;
        }
    }
}
.emptyView {
    background-color: #F7F7F7;
}
.empty {
    width: vw(710);
    height: vw(500);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: vw(20) auto;
    font-size: vw(22);
    color: #8893A7;
    border-radius: vw(20);
    img {
        width: vw(174);
        height: vw(174);
        margin: vw(100) 0 vw(40);
    }
}
</style>
