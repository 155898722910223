<template>
	<div style="padding-bottom: 60px;">
		<StateBar />
		<TitleHeader title="铺参谋选址服务" bgcolor="transparent" color="black" :back="true"
			style="border-bottom: 1px solid #F3F4F7;">
			<div slot="right" class="right-title" @click="goList">我的意向</div>
		</TitleHeader>
		<AutoView :footer="true">
			<div class="brokerServices">
				<div class="background"></div>
				<vear-carousel v-if="show3D" @goDetails="goDetails"></vear-carousel>
				<div class="body">
					<div class="first-box">
						<PlayVideo :poster="poster" :src="src" @onceClick="videoOnceClick" />
					</div>
					<div class="title-box">
						<div class="big-title">运用连锁店开店方法</div>
						<div class="small-title">结合大数据全面分析</div>
					</div>
					<div class="secound-box">
						<div class="icon-box"></div>
					</div>
					<div class="title-box">
						<div class="big-title">铺参谋1对1服务</div>
						<div class="small-title">选址诉求定制化</div>
					</div>
					<div class="third-box">
					</div>
					<div class="title-box">
						<div class="big-title">周边业态分析</div>
						<div class="small-title">解读选址逻辑</div>
					</div>
					<div class="fifth-box">
						<img src="../../../../static/shopEcology/agent5-1.png" alt="">
						<img class="img2" src="../../../../static/shopEcology/agent5-2.png" alt="">
					</div>
					<div class="title-box">
						<div class="big-title">商圈人群画像</div>
						<div class="small-title">男女比例 年龄分布</div>
					</div>
					<div class="sixth-box">
						<img src="../../../../static/shopEcology/agent6.png" alt="">
					</div>
					<div class="title-box">
						<div class="big-title">商铺选址评估</div>
						<div class="small-title">交付内容</div>
					</div>
					<div class="seventh-box">
						<img src="../../../../static/shopEcology/agent7.png" alt="">
					</div>
					<div class="title-box">
						<div class="big-title">商铺评估案例</div>
						<div class="small-title">深度剖析优劣</div>
					</div>
					<div class="fourth-box">
						<img src="../../../../static/shopEcology/agent8.png" alt="">
					</div>
					<div class="button-bottom-box">
						<div class="right-box" @click="pageSubmit">
							提交选址意向
						</div>
					</div>
				</div>
			</div>
		</AutoView>
		<!-- 提交选址意向 -->
		<intention-mask v-if="intentionMaskType" @submit="intentionSubmit" @close="intentionMaskType = false">
		</intention-mask>
	</div>
</template>

<script>
import PlayVideo from '../components/VideoPlay'
import intentionMask from "../components/intention-mask.vue"
import vearCarousel from "./components/vear-carousel"
import {
	saveSitingIntention
} from "@/request/shopEcology"
export default {
	data() {
		return {
			show3D: false,
			isSubmit: false,
			intentionMaskType: false,
			poster: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/wuzhuxuanzhijieshao.mp4?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600',
			src: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/pst/wuzhuxuanzhijieshao.mp4'
		};
	},
	mounted() {
		setTimeout(() => {
			this.show3D = true;
		}, 1000)
	},
	methods: {
		goDetails(item) {
			// 点击个人主页
			this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, 133200, 134201);
			this.swRouter({
				path: '/shopEcology/brokerIntroduction',
				query: {
					id: item.userId
				}
			})
		},
		goList() {
			//按钮点击_我的意向
			this.JSBridge.BTNCLICK(this.$route.meta.id, '', 133500, 133501);
			this.swRouter({
				path: '/shopEcology/siteSelectionList'
			})
		},
		videoOnceClick() {
			// 视频播放埋点
			this.JSBridge.BTNCLICK(this.$route.meta.id, '', 133100, 133101);
		},
		pageSubmit() {
			//点击提交选址意向按钮
			if (window._appCode && window._appCode >= 307) {
				this.swRouter({
					path: "/shopEcology/intentionSubmit",
				});
			} else {
				this.intentionMaskType = true;
			}
			this.JSBridge.BTNCLICK(this.$route.meta.id, "", 133300, 135301);
		},
		intentionSubmit(form) {
			// 按钮点击_提交选址意向成功
			if (this.isSubmit) return;
			this.isSubmit = true;
			saveSitingIntention(form).then(res => {
				this.intentionMaskType = false
				this.JSBridge.BTNCLICK(this.$route.meta.id, '', 133400, 133401);
				this.swRouter({
					path: '/shopEcology/intentionsubmitSuccessful',
					query: {
						id: res
					}
				})
			}).finally(e => {
				this.isSubmit = false;
			});
		},
	},
	components: {
		PlayVideo,
		intentionMask,
		vearCarousel
	}
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

@function vw($args) {
	@return $args/750*100+vw;
}

.right-title {
	font-size: vw(28);
	color: #347FFF;
}

.brokerServices {
	padding: 0 vw(30);
	background: linear-gradient(180deg, #157CF9 0%, #C7E0FE 41%, #FFFFFF 100%);
	background-size: 100% 100%;
	box-sizing: border-box;

	.background {
		width: 100%;
		height: vw(518);
		background-image: url('../../../../static/shopEcology/agent1.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: absolute;
		left: 0%;
		top: 0;
		z-index: 0;
	}

	.body {
		position: relative;
		top: vw(290);
		left: 0;
		width: 100%;
		box-sizing: border-box;
		background: #FFFFFF;
		border-radius: vw(16);

		.title-box {
			text-align: center;

			.big-title {
				width: vw(560);
				height: vw(64);
				background: linear-gradient(90deg, #FFFFFF 0%, #DFF1FF 48%, #FFFFFF 100%);
				font-size: vw(40);
				font-weight: 600;
				color: #2F2F36;
				line-height: vw(64);
				margin: 0 auto;
			}

			.small-title {
				margin-top: vw(16);
				height: vw(44);
				font-size: vw(32);
				font-weight: 600;
				color: #9F9FAE;
				line-height: vw(44);
			}
		}

		.first-box {
			padding: vw(48) vw(36);
			box-sizing: border-box;
		}

		.secound-box {
			padding: vw(40) vw(30) vw(48);
			box-sizing: border-box;

			.icon-box {
				height: vw(412);
				background: url('../../../../static/shopEcology/agent2.png') no-repeat;
				background-size: 100% 100%;
			}
		}

		.third-box {
			height: vw(960);
		}


		.fourth-box {
			padding: vw(50) vw(40) vw(0);
			box-sizing: border-box;
			text-align: center;

			img {
				width: 100%;
			}

		}

		.fifth-box {
			padding: vw(40);
			box-sizing: border-box;
			text-align: center;

			img {
				width: 100%;
			}

			.img2 {
				margin-top: vw(36);
			}
		}

		.sixth-box {
			padding: vw(30) vw(40) vw(40);
			box-sizing: border-box;
			text-align: center;

			img {
				width: 100%;
			}
		}

		.seventh-box {
			padding: vw(40);
			box-sizing: border-box;
			text-align: center;

			img {
				width: 100%;
			}
		}

	}

	.button-bottom-box {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: vw(16) vw(30) vw(20);
		box-sizing: border-box;
		background: #fff;
		z-index: 300;
		box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);

		.right-box {
			background: #347FFF;
			border-radius: vw(10);
			height: vw(80);
			font-size: vw(32);
			font-weight: 600;
			color: #FFFFFF;
			line-height: vw(80);
			text-align: center;
		}
	}
}
</style>
