/**
 * 热搜
 */
//  const selling = () => import(/* webpackChunkName: "module-community" */ "@/views/hotSearch/selling"); //old
 const selling = () => import(/* webpackChunkName: "module-community" */ "@/views/hotSearch");
 const selectIntustry = () => import(/* webpackChunkName: "module-community" */ "@/views/hotSearch/selectIntustry");
//  const openShop = () => import(/* webpackChunkName: "module-community" */ "@/views/hotSearch/openShop");
//  const hotShop = () => import(/* webpackChunkName: "module-community" */ "@/views/hotSearch/hotShop");

export default [
    {
        path: "/hot/selling/:type",
        name: "selling",
        component: selling,
        meta: { id: 10144, keepAlive: true }
    },
    {
        path: "/hot/selectIntustry/:id",
        name: "selectIntustry",
        component: selectIntustry
    },
    // {
    //     path: "/hot/open_shop",
    //     name: "openShop",
    //     component: openShop,
    //     meta: { id: 10143 }
    // },
    // {
    //     path: "/hot/hot_shop",
    //     name: "hotShop",
    //     component: hotShop,
    //     meta: { id: 10145 }
    // },
]