<template>
	<div id="mask" @click="close">
    <div class="img-wrap">
      <img :src="src" class="img" alt=""  onerror="this.src = this.src.split('?')[0]"/>
      <p class="title">{{title}}</p>
    </div>
	</div>
</template>

<script>
	export default {
		props: {
			src: {
				type: String
			},
      title: {
        type: String,
        default: '',
      }
		},
		methods:{
			close(){
				this.$emit('close')
			}
		}
	}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

	#mask {
		position: fixed;
		left: 0%;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, 1);
		z-index: 999;
    overflow: auto;
		.img-wrap {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 100%;
			//height: 53.3vw;
      img{
        width: 100%;
      }
		}
    .title{
      text-align: center;
      color: #fff;
      font-size: vw(24) !important;
      padding: vw(15) !important;
    }
	}
</style>
