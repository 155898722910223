<template>
    <div class="comments_content">
        <div class="comments_title">
            <h3>品牌评价（{{commontsList.total}}）</h3>
            <div class="arrow_right" @click="openAllCommonts">
                <img class="comments_arrow"  src="/static/icon/comments_arrow.png" alt="">
            </div>
        </div>
        <template v-if="commontsList.commentList && commontsList.commentList.length > 0" >
            <div class="comments_btn">
                <div class="btn_list" v-for="(index, val, cindex) in commontsList.tagMap" :class="val == '需谨慎加盟' ? 'noClassColor':''" :key="val" @click="openNewCom(cindex)">
                    <p>{{val}}<span>({{index}})</span></p>
                </div>
            </div>
            <div class="comments_swrap" @click="openAllCommonts">
                <div class="comments_list" v-for="(item, key) in commontsList.commentList" :key="key">
                    <div class="list_title">
                        <div class="comments_img">
                            <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="item.avatar">
                                <template v-slot:loading>
                                    <van-loading type="spinner" size="20" />
                                </template>
                            </van-image>
                        </div>
                        <div class="comments_text">
                            <div class="text_top">
                                <div class="name">{{item.username}}</div>
                                <div class="time">{{item.time}}</div>
                            </div>
                            <div class="text_rate">
                                <span>打分</span>
                                <van-rate readonly v-model="item.score" gutter="0.53vw" size="3.2vw" void-icon="star" color="#FFCB00" void-color="#D8D8D8" />
                            </div>
                        </div>
                    </div>
                    <div class="the_label">
                        <template v-if="item.tags != null">
                            <template v-if="item.tags.length > 0">
                                <p v-for="(citem, cindx) in item.tags" :class="citem == '需谨慎加盟' ? 'noColor': ''" :key="cindx">{{citem}}</p>
                            </template>
                        </template>

                    </div>
                    <p class="comments_fab">{{item.content}}</p>
                    <img class="top_select" v-if="item.top == 1" src="/static/img/select_top.png" alt="">
                </div>
            </div>
        </template>
        <template v-else>
            <div class="no_commonts">
                <div style="line-height: 0;">
                    <img src="/static/icon/wallets.png" alt="">
                </div>
                <p>暂无评论，快来一起讨论下吧</p>
            </div>
        </template>
        <div class="open_comments_box" v-if="isCommontsClient">
            <button class="open_comments"  @click="openCommonts">去评价品牌</button>
        </div>
    </div>
</template>


<script>
export default {
    name: "comments",
    props: {
        commontsList: {
            type: Object
        },
        brandId: {
            type: [String, Number]
        },
        nameBrand: {
            type: String
        },
        codeBrand: {
            type: String
        },
        isCommontsClient: {
            type: Boolean
        }
    },
    data() {
        return {

        }
    },
    methods: {
        // 点击评分跳转
        openNewCom(index) {
            if(this.isCommontsClient) {
                this.swRouter({
                    path: '/brand/all_commonts/'+ this.brandId,
                    query: {
                        name: this.nameBrand,
                        cindex: index
                    }
                });
            } else {
                window.location.href = this.HTTP.getAppStore();
            }
        },
        openCommonts() {
            if(this.isCommontsClient) {
                this.swRouter({
                    path: '/brand/input_commonts/'+ this.brandId,
                    query: {
                        name: this.nameBrand,
                        code: this.codeBrand,
                    }
                });
            } else {
                window.location.href = this.HTTP.getAppStore();
            }
        },
        // 跳转到所有评价
        openAllCommonts() {
            if(this.isCommontsClient) {
                this.swRouter({
                    path: '/brand/all_commonts/'+ this.brandId,
                    query: {
                        name: this.nameBrand
                    }
                });
            } else {
                window.location.href = this.HTTP.getAppStore();
            }
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.comments_content{
    width: 100%;
    height: auto;
    background: #fff;
    margin-bottom: 2.67vw;
    padding: 4.53vw 4vw;
    box-sizing: border-box;
    .comments_title{
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 4.53vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 6.4vw;
            flex: 1;
        }
        .arrow_right{
            width: 20vw;
            text-align: right;
            .comments_arrow{
                width: 1.6vw;
                height: 3.73vw;
            }
        }
    }
    .comments_btn{
        margin: 2.4vw 0 0;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        font-size: 0;
        vertical-align: middle;
        line-height: normal;
        .btn_list{
            width: calc(33.33% - 1.33vw);
            height: 7.2vw;
            border: 1px solid #FF9F6B;
            border-radius: 0.67vw;
            box-sizing: border-box;
            color: #FF5900;
            font-size: 3.47vw;
            text-align: center;
            display: inline-block;
            margin: 0 2vw 2.4vw 0;
            p{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                span{
                    font-size: 3.2vw;
                }
            }
            &:nth-child(3n) {
                margin-right: 0px;
            }
        }
        .noClassColor{
            border: 1px solid #D8D9DF;
            color: #999999;
            background: transparent;
        }
    }
    .comments_swrap{
        width: 100%;
        height: auto;
        .comments_list{
            width: 100%;
            height: auto;
            margin-top: 3.33vw;
            padding-bottom: 2.67vw;
            border-bottom: 1px solid #E7E7E7;
            position: relative;
            .list_title{
                display: flex;
                justify-content: center;
                align-items: center;
                .comments_img{
                    width: 9.06vw;
                    height: 9.06vw;
                    border-radius: 9.06vw;
                    overflow: hidden;
                    margin-right: 1.33vw;
                }
                .comments_text{
                    flex: 1;
                    .text_top{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0.53vw;
                        .name{
                            font-size: 3.73vw;
                            font-weight: bold;
                            color: #333333;
                            line-height: 5.33vw;
                            flex: 1;
                        }
                        .time{
                            font-size: 2.93vw;
                            color: #999999;
                        }
                    }
                    .text_rate{
                        width: 100%;
                        height: auto;
                        font-size: 0;
                        span{
                            display: inline-block;
                            font-size: 2.93vw;
                            color: #333333;
                            line-height: 4vw;
                            margin-right: 0.8vw;
                            vertical-align: middle;
                            line-height: normal;

                        }
                        ::v-deep .van-rate--readonly{
                            vertical-align: middle;
                        }
                    }
                }
            }
            .the_label{
                margin: 2.13vw 0 2.53vw;
                overflow-y: auto;
                white-space: nowrap;
                line-height: 1;
                box-sizing: border-box;
                p{
                    display: inline-block;
                    // background: #FFF1E9;
                    border-radius: 0.67vw;
                    line-height: normal;
                    border: 1px solid #FF9F6B;
                    padding: 0.8vw 2.27vw;
                    font-size: 2.93vw;
                    color: #FF5900;
                    box-sizing: border-box;
                    margin-right: 1.33vw;
                    &:last-child{
                        margin-right: 0px;
                    }
                }
                .noColor{
                    border: 1px solid #D8D9DF;
                    color: #999999;
                    background: transparent;
                }
            }
            .comments_fab{
                font-size: 3.47vw;
                color: #333333;
                line-height: 4.93vw;
            }
            .top_select{
                position: absolute;
                width: 10.8vw;
                height: 8.93vw;
                top: 7.73vw;
                right: 0.4vw;
                z-index: 1;
            }
            &:last-child{
                border: none;
            }
        }

    }
    .open_comments_box{
        text-align: center;
        margin: 2.67vw auto 0;
        .open_comments{
            height: 7.73vw;
            background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
            border-radius: 3.73vw;
            font-size: 3.2vw;
            font-weight: bold;
            line-height: normal;
            color: #FFFFFF;
            border: none;
            padding: 0 6.67vw;
        }
    }
    .no_commonts{
        padding: 2.67vw 0 1.87vw;
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        text-align: center;
        img {
            width: 23.2vw;
            height: 23.2vw;
            border-radius: 23.2vw;
        }
        p{
            font-size: 3.2vw;
            color: #999999;
            line-height: 4.4vw;
            margin-top: 2.67vw;
        }
    }
}
</style>
