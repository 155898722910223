<template>
  <div class="wrapper">
    <StateBar />
    <div class="positionContent">
      <SearchBar
        placeholder="搜索商场、购物中心"
        :value="value"
        type=""
        @change="searchHandle"/>
    </div>
    <div class="mall-filter-box">
      <div class="mall-filter-item" v-for="(item, index) in mallFilterOption" :key="index"
           @click="selectFilterBox(index)"
           :class="{'active': (index===1 && leftActive) || (index===2 && rightActive)}"
      >
        <span v-if="index===0">{{ city || item.name }}</span>
        <span v-else>{{ item.name }}</span>
        <span class="triangle"></span>
      </div>
    </div>
    <AutoView ref="view" :header="true" :footer="false" class="search--view">
      <div class="shopList">
        <van-list class="mall-search-list" v-if="shopList && shopList.length" v-model="loading" :finished="enableLoadMore" :immediate-check="false" finished-text="没有更多了" @load="onLoadMore">
          <div class="item mall-search-card" v-for="item in shopList" :key="item.id" @click="getReport(item.code, item.name)">
            <div class="brand-icon">
              <van-image class="card--img s-radius__xs" fit="cover" lazy-load :src="item.url">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <div class="mall-info-right">
              <div class="mall-info-top">
                <div class="mall-name">{{ item.name }}</div>
                <div class="mall-city" v-if="item.city">{{ item.city }}</div>
              </div>
              <div class="mall-info-middle">{{ item.openingDate }}</div>
              <div class="mall-info-bottom">{{ item.builtArea }}，{{ item.shopCount }}</div>
            </div>
          </div>
        </van-list>
        <div class="empty" v-else>
          <img src="/static/element/empty.png" alt="">
          <div class="empty_desc">
            <div class="title">未搜到商场？</div>
            <div class="tip">商场数据正在持续补充中</div>
          </div>
        </div>
      </div>
      <div class="mask" v-if="leftActive || rightActive" @click="closeMask"></div>
      <div class="display" v-if="leftActive || rightActive">
        <template v-if="leftActive">
          <div class="item" v-for="(item, index) in search.optionList" :key="index">
            <div class="name">{{item.column.name}}</div>
            <div class="tips">
              <div class="tip-item" :class="(items.value === isAreaActive || items.value === isYearActive || items.value === isShopCountActive) ? 'item-active' : ''" @click="leftSortClick(items.value )" v-for="items in item.nameValues" :key="items.value">{{items.name}}</div>
            </div>
          </div>
          <div class="btn" @click="leftSumbitClick">确定</div>
        </template>
        <template v-if="rightActive">
          <div class="item right-item" v-for="(item, index) in order.optionList" :key="index">
            <div class="name">{{item.column.name}}</div>
            <div class="tips">
              <div class="tip-item" :class="items.value === isOrderActive ? 'item-active' : ''" @click="rightSortClick(items.value)" v-for="items in item.nameValues" :key="items.value">{{items.name}}</div>
            </div>
          </div>
        </template>
      </div>
    </AutoView>
    <!-- 开通会员弹框 -->
    <OpenVip :showDialog="showDialog" @cancel="showDialog = false" />
  </div>
</template>

<script>
  import OpenVip from "@/components/openVip/index.vue";
  import SearchBar from "@/components/SearchBar/index.vue"
  import LoadMore from '@/views/element/components/loadMore';
  export default {
    data() {
      return {
        loading: false,
        mallFilterOption: [
          {
            name: '所在城市',
            type: 0
          },
          {
            name: '筛选条件',
            type: 1
          },
          {
            name: '排序规则',
            type: 2
          }
        ],
        city: "",
        value: "",
        leftActive: false,
        rightActive: false,
        isAreaActive: "",
        isYearActive: "",
        isShopCountActive: "",
        isOrderActive: "",
        timer: null,
        search: {},
        order: {},
        searchOptions: {
          current: 1,
          size: 10,
          name: '',
          cityCode: null
        },
        shopList: [],
        enableLoadMore: true,
        showDialog: false,
      };
    },
    mounted() {
      this.getListData()
      this.getShopMarketSearch()
    },
    components: {
      SearchBar,
      LoadMore,
      OpenVip
    },
    methods: {
      selectFilterBox(index) {
        if (index === 1) {
          this.rightActive = false
          this.leftActive = !this.leftActive
        } else if (index === 2) {
          this.leftActive = false
          this.rightActive = !this.rightActive
        } else {
          this.leftActive = false
          this.rightActive = false
          this.handleSelectCity()
        }
      },
      handleSelectCity () {
        this.JSBridge.callHandler( 'selectCity', null, ( res ) => {
          console.log(res)
          if ( res ) {
            let cityObj = res;
            if ( typeof cityObj === 'string' ) {
              cityObj = JSON.parse( cityObj );
            }
            // console.log(cityObj)
            this.city = cityObj.name;
            this.searchOptions.cityCode = cityObj.code;
            this.searchOptions.current = 1
            this.getListData()
          }
        } );
      },
      getShopMarketSearch() {
        this.HTTP.getShopMarketSearch().then(res => {
          console.log(res)
          if(res.code == 0) {
            this.search = res.data.search
            this.order = res.data.order

            // 初始化数据
            this.isAreaActive = this.search.optionList.find(el => el.column.value === 'area').nameValues[0].value
            this.isYearActive = this.search.optionList.find(el => el.column.value === 'year').nameValues[0].value
            this.isShopCountActive = this.search.optionList.find(el => el.column.value === 'shopCount').nameValues[0].value
          }
        })
      },
      getReport(code, text) {
        this.HTTP.reportSubmit({
          goodsId: '10030',
          market: {
            code,
            text,
          }
        }).then(res => {
          if(res.code == 0) {
            const linkUrl = res.data.link.url.split("#")[1]
            this.swRouter(linkUrl)
          }
        }).catch((e) => {
          if (e.code == 201022) {
            this.showDialog = true;
          } else {
            this.$toast("网络不稳定，请稍候重试！");
          }
        })
      },
      getListData() {
        let searchOpt = {}
        let orderOpt = {}
        if(this.isAreaActive) {
          searchOpt.area = this.isAreaActive
        }
        if(this.isYearActive) {
          searchOpt.year = this.isYearActive
        }
        if(this.isShopCountActive) {
          searchOpt.shopCount = this.isShopCountActive
        }
        if(!this.isAreaActive && !this.isYearActive && !this.isShopCountActive) {
            searchOpt = null
        }
        if(this.isOrderActive) {
          orderOpt = {
            order: this.isOrderActive
          }
        } else {
            orderOpt = null
        }
        this.loading = true;
        this.HTTP.getShopMarketList({
          ...this.searchOptions,
          condition: searchOpt ? JSON.stringify(searchOpt) : searchOpt,
          sort: orderOpt ? JSON.stringify(orderOpt) : orderOpt
        }).then(res => {
          if(res.code == 0) {
            const { data, total } = res.data
            if(data.length < this.searchOptions.size) {
                this.enableLoadMore = true
                } else {
                this.enableLoadMore = false
            }
            if(this.searchOptions.current===1) {
              this.shopList = data
            } else {
              this.shopList = this.shopList.concat(data)
            }
          }
        }).finally(() => {
          this.loading = false;
        })
      },
      onLoadMore() {
        setTimeout(()=>{
          this.searchOptions.current = this.searchOptions.current + 1
          this.getListData();
        }, 500)
      },
      searchHandle(e) {
        if(this.timeout){
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchOptions.name = e
          this.initListData()
        }, 500);
      },
      closeMask() {
        this.leftActive = false
        this.rightActive = false
      },
      leftSortClick(value) {
        if(value < 6) {
          this.isAreaActive = value
        } else if(value >= 6 && value < 13) {
          this.isYearActive = value
        } else {
          this.isShopCountActive = value
        }
      },
      initListData() {
        this.searchOptions.current = 1
        this.shopList = []
        this.getListData()
      },
      leftSumbitClick() {
        this.leftActive = false
        this.initListData()
      },
      rightSortClick(value) {
        this.isOrderActive = value
        this.rightActive = false
        this.initListData()
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wrapper {
    // padding: 4vw;
    // -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .triangle {
    width: 0;
    height: 0;
    border-left: 1.333vw solid transparent;
    border-right: 1.333vw solid transparent;
    border-top: 1.6vw solid #b2b3b9;
    transition: all 0.5s;
    margin-left: 1.066vw;
    border-radius: 1.333vw;
  }
  .search--view {
    scroll-behavior: smooth;
    position: relative;
    /*background: #F7F7F7;*/
    flex: 1;
  }
  .mall-filter-box {
    display: flex;
    align-items: center;
    padding: 3.733vw 4vw;
    height: 10.2vw;
    box-sizing: border-box;
    .mall-filter-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      color: #666666;
      font-size: 3.733vw;
      img {
        width: 2.133vw;
        height: 1.333vw;
        margin-left: 1.066vw;
      }
      &.active {
        font-weight: 600;
        color: #347FFF;
        .triangle {
          border-top: 1.6vw solid #347fff;
          transform: rotate(180deg);
        }
      }
    }
  }
  .positionContent {
    margin: 0 15.5vw;
    padding: 2vw 0;
    .search-bar {
      background: #f7f6f9;
      border-radius: 1.333vw;
      border: 0.133vw solid #ebeaee;
    }
  }
  .shopList {
    position: relative;
    overflow-y: auto;
    background-color: #f7f7f7;
    padding: 4vw;
    height: 100%;
    box-sizing: border-box;
    .mall-search-card {
      display: flex;
      align-items: center;
      padding: 3.333vw;
      box-shadow: 0 0 2.667vw 0 rgba(0, 0, 0, 0.09);
      background: #ffffff;
      border-radius: 1.333vw;
      &:nth-child(n+2) {
        margin-top: 2.667vw;
      }
      .mall-info-right {
        color: #666666;
        font-size: 3.2vw;
        margin-left: 2.667vw;
        flex: 1;
        overflow: hidden;
        .mall-info-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2.867vw;
          overflow: hidden;
          .mall-name {
            font-weight: 600;
            color: #1D2233;
            font-size: 4vw;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            flex: 1;
            line-height: 5.6vw;
          }
          .mall-city {
            font-size: 3.333vw;
          }
        }
        .mall-info-middle {
          margin-bottom: 2.4vw;
        }
      }
      .brand-icon {
        width: 17.333vw;
        height: 17.333vw;
        border-radius: 0.667vw;
        .card--img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .shop-item {
      width: 85vw;
      background-color: #ffffff;
      padding: 3.5vw;
      margin: 0 4vw 4vw 4vw;
      display: flex;
      box-shadow: 0 0 2.267vw 0 rgba(0,0,0,0.09);
      border-radius: 1.33vw;
      &:nth-child(1) {
        margin-top: 4vw;
      }
      .left {
        width: 20vw;
        height: 20vw;
        margin-right: 3vw;
      }
      .right {
        min-width: 63vw;
        font-size: 3.467vw;
        height: 20vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 4vw;
            font-weight: bold;
          }
        }
      }
    }
    .empty {
      width: 92vw;
      height: 85vw;
      background: #ffffff;
      border-radius: 1.33vw;
      display: flex;
    //   margin: 4vw;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 23.2vw;
        height: 23.2vw;
      }
      .empty_desc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 4.5vw;
        font-size: 3.2vw;
        line-height: 4.5vw;
        color: #999999;
        .title {
          color: #1D2233;
          font-size: 3.733vw;
          font-weight: bold;
          line-height: 5.33vw;
        }
      }
    }
  }
  .display {
    position: absolute;
    top: 0;
    left: 0;
    width: 92vw;
    font-size: 3.733vw;
    background-color: #fff;
    // position: absolute;
    padding: 4vw;
    z-index: 10;
    .item {
      margin-top: 4.8vw;
      &:first-child {
        margin-top: 0;
      }
      &.right-item {
        .tips {
          .tip-item {
            /*width: 37.867vw;*/
            flex: 1;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }

      }
      .name {
        font-weight: 600;
        color: #1D2233;
        font-size: 4vw;
      }
      .tips {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .tip-item {
          width: 28.66vw;
          height: 7.73vw;
          margin-top: 2.93vw;
          border-radius: 1.067vw;
          background: #F5F5F5;
          text-align: center;
          line-height: 7.73vw;
          margin-right: 2.93vw;
          font-weight: 400;
          color: #333333;
          font-size: 3.733vw;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        .item-active {
          background: #DFEDFF;
          color: #347FFF;
        }
      }
    }
    .btn {
      /*width: 92vw;*/
      height: 10.667vw;
      border-radius: 1.333vw;
      background: #347FFF;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4.8vw;
    }
  }

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
    height: 100%;
  }
  .active {
    color: #518CDB;
  }
</style>
