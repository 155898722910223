<template>
  <div class="varieties">
    <StateBar></StateBar>
    <TitleHeader title="入驻商户品牌店铺" bgcolor="transparent" :back="true"></TitleHeader>

    <AutoView :header="true" :footer="false" class="bills--view l-view">
      <!-- :loading="firstLoading" -->
      <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__s">
        <!-- <EmptyCard v-if="list.length === 0" type="bills"></EmptyCard> -->
        <!-- <template v-else> -->
        <div v-if="bgmData" class="box-table">
          <div class="floor-box">
            <div class="floor-list">
              <div class="floor-item" v-for="(item, index) in floorList" :key="index" :class="{'active': floorIndex===index}" @click="selectFloor(index)">
                <div class="floor-name"><span>{{ item.name }}</span></div>
                <div class="floor-count">{{`(${item.count})`}}家</div>
              </div>
            </div>
            <div class="big-title">
              <span class="current-name">{{ currentName }}</span>入驻商户品牌店铺
            </div>
          </div>
          <van-grid :column-num="3" border class="table-grid">
            <template>
              <template>
                <van-grid-item text="图片"></van-grid-item>
                <van-grid-item text="店铺名称"></van-grid-item>
                <van-grid-item text="类型"></van-grid-item>
              </template>
              <template v-for="(item, ix) in brandData">
                <van-grid-item :key="'c1' + ix">
                  <van-image class="brand-icon" :src="item.logo" fit="cover">
                    <template #error>
                      <img class="default-icon" src="/static/icon/mall-default-logo.png" />
                    </template>
                    <template #loading>
                      <img class="default-icon" src="/static/icon/mall-default-logo.png" />
                    </template>
                  </van-image>
                </van-grid-item>
                <van-grid-item :key="'c2' + ix" :text="item.name ? item.name : '-'"></van-grid-item>
                <van-grid-item :key="'c3' + ix" :text="item.brandCategory ? String(item.brandCategory) : '-'"></van-grid-item>
              </template>
            </template>
          </van-grid>
        </div>
        <!-- </template> -->
      </van-skeleton>
    </AutoView>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage } from '../../utils';

export default {
  name: "mall-rank",
  data() {
    return {
      floorIndex: 0,
      floorList: [],
      firstLoading: false,
      bgmData: [],
      title: "",
    };
  },
  created() {
    const data = getLocalStorage('mallList')
    const { bgmData = [], floorIndex = 0 } = data
    this.bgmData = bgmData
    this.floorIndex = floorIndex
    if (this.bgmData) {
      this.floorList = this.bgmData.map(el => {
        return {
          name: el.floor,
          count: el.brandShopList.length || 0
        }
      })
    }
  },
  computed: {
    currentName() {
      if (!this.floorList.length) {
        return ''
      }
      return this.floorList[this.floorIndex].name
    },
    brandData() {
      if (!this.bgmData.length) {
        return []
      }
      return this.bgmData[this.floorIndex].brandShopList
    }
  },
  methods: {
    selectFloor(index) {
      this.floorIndex = index
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.varieties {
  width: 100%;
  .floor-box {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #ffffff;
    padding: 2.666667vw 0;
    .floor-list {
      display: flex;
      flex-wrap: wrap;
      font-size: 3.2vw;
      text-align: center;
      .floor-item {
        width: calc(20% + 0.8px);
        height: 10.933333vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        border: 1px solid #e4e7ed;
        margin-left: -1px;
        margin-top: -1px;
        padding: 1.6vw 0;
        overflow: hidden;
        &.active {
          background: #347FFF;
          //border: 1px solid #347FFF;
          color: #fff;
          .floor-count {

            color: #fff;
          }
          .floor-name {
            color: #fff;
          }
        }
        .floor-count {
          font-size: 2.4vw;
          color: #8893a7;
        }
        .floor-name {
          font-weight: bold;
          color: #0D1525;
        }
      }
    }
    .big-title {
      font-weight: bold;
      color: #1D2233;
      font-size: 3.466667vw;
      text-align: left;
      margin-top: 3vw;
      .current-name {
        color: #347FFF;
        font-size: 4vw;
        font-weight: bold;
        margin-right: 1.333333vw;
      }
    }
  }
  .bills--view {
    padding: 0 4vw 3vw 4vw;
    background: #fff;
  }
  .table-grid {
  }
  .box-table {
    width: 100%;
    height: auto;
    .brand-icon {
      width: 9.866667vw;
      height: 9.866667vw;
      vertical-align: middle;
      .default-icon {
        width: 100%;
        height: 100%;
      }
    }
    ::v-deep .van-grid-item__content {
      padding: 2.93vw 1.33vw;
    }
    ::v-deep [class*="van-hairline"]::after {
      border: 0 solid #dedee7;
      border-width: 0 0.13vw 0.13vw 0;
    }
    ::v-deep .van-hairline--top::after {
      border-top-width: 0.13vw;
      border-left-width: 0.13vw;
      border-bottom-width: 0;
    }
    ::v-deep .van-grid-item__text {
      color: #1d2233;
      line-height: 4.4vw;
    }
    ::v-deep .van-grid-item:nth-child(1),
    ::v-deep .van-grid-item:nth-child(2),
    ::v-deep .van-grid-item:nth-child(3), {
      .van-grid-item__content {
        background: #f8f8f9;
        .van-grid-item__text {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
