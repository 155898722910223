<template>
    <div class="wallet">
        <StateBar></StateBar>
        <TitleHeader title="上上贝" bgcolor="transparent" :back="showBack">
            <div slot="right" class="detail" @click="swRouter( '/walletHistory' )">明细</div>
        </TitleHeader>

        <AutoView :header="true" :footer="false" class="wallet--view l-view">
            <div class="wallet--amount s-radius">
                <h3>我的上上贝</h3>
                <h1>{{ info.balance }}</h1>
                <p>用于解锁报告</p>
            </div>

            <h3 class="s-subtitle">购买上上贝</h3>
            <div class="wallet--grid">
                <van-row>
                    <van-col v-for="( item, index ) of goods" :key="index" span="8" class="prod s-ani" :class="{ on: info.select && item.id === info.select.id }" @click="onSelect( item )">
                        <h2>{{ item.sscmCoin }}<em>枚</em></h2>
                        <p>售价{{ parseFloat( item.amount ) | toFixed }}元</p>
                        <i v-if="item.presentSscmCoin && item.presentSscmCoin !== '0'">送{{ item.presentSscmCoin }}枚</i>
                    </van-col>
                </van-row>
            </div>

            <template v-if="!isIOS || openByIOS">
                <h3 class="s-subtitle">支付方式</h3>
                <PayTypes @setPayType="setPayType" :payList="payList"  />
            </template>

            <van-button type="info" block @click="onTap" class="s-radius__s" style="margin: 5.33vw 0">购买</van-button>

            <h3 v-if="isIOS&&openByIOS" class="how-to-pay" @click="gotoPayment">如何使用支付宝和微信支付充值教程</h3>

            <!-- 温馨提示 -->
            <div class="s-tips f-vpb">
                温馨提示：<br/>
                1.充值成功后，上上贝即可到账，仅限上上参谋APP内使用；<br/>
                2.每位用户不限充值次数，充值后的金额不设使用期限；<br/>
                3.上上贝为虚拟货币，充值后不支持退款，且无法提现或转赠他人；<br/>
                4.本次活动最终解释权归上上参谋所有，购买如遇到问题，请<span @click="swRouter( '/feedback' )" class="link" style="text-decoration: underline">提交反馈</span>
            </div>
        </AutoView>

        <!-- 充值结果提醒 -->
        <van-overlay :show="showPayTips">
            <div class="l-wrapper">
                <div class="wallet--tips l-panel s-radius__s">
                    <van-image class="icon"
                        fit="cover"
                        :src="payTips.icon" />
                    <h3>{{ payTips.title }}</h3>
                    <p>{{ payTips.text  }}</p>
                    <van-button type="info" class="s-radius__s" @click="onConfirm">确定</van-button>
                </div>
            </div>
        </van-overlay>

        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>

        <!-- 全局加载遮罩 -->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import PayTypes from '@/components/payTypes';

export default {
    name      : 'wallet',
    components: {
        PayTypes,
    },
    data () {
        return {
            title            : '',
            loading          : true,
            waiting          : false,
            showBack         : true,
            showPayTips      : false,
            showPaymentPicker: false,

            openByIOS: true, // 开放iOS微信和支付宝支付
            isAndroid: false,
            isIOS    : false,
            client   : 3, // 1iOS 2Android 3WEB
            payList  : [ '支付宝', '微信' ],
            payType  : '支付宝',
            payMode  : 'ALIPAY_MOBILE',
            payCall  : 'aliPay',
            payMap   : {
                '支付宝': {
                    mode: 'ALIPAY_MOBILE',
                    call: 'aliPay',
                    unit: '￥',
                },
                '微信': {
                    mode: 'WX_APP',
                    call: 'wxPay',
                    unit: '￥',
                },
                'Apple Pay': {
                    mode: 'APPLE_IN',
                    call: 'applePay',
                    unit: '￥',
                },
            },
            payTips: {
                icon : '',
                title: '',
                text : '',
            },
            payTipsMap: {
                success: {
                    icon : '/static/icon/msg_success_orange.png',
                    title: '操作成功',
                    text : '您已成功的完成购买',
                },
                failed: {
                    icon : '/static/icon/more.png',
                    title: '操作失败',
                    text : '未能完成购买操作',
                },
            },
            goods  : [],
            orderNo: null, // 充值订单号 下单时生成
            info   : {
                balance : 0,
                select  : null,
                reportId: null, // 原报告ID
                orderNo : null, // 原支付订单
            },
        };
    },
    methods: {
        loadBalance () {
            this.HTTP.getBalance().then( ( res ) => {
                if ( this.isIOS ) {
                    this.info.balance = res.iosBalance;
                } else {
                    this.info.balance = res.androidBalance;
                }
            } );
        },
        setPayType ( value ) {
            const map      = this.payMap[ value ];
            this.payType   = value;
            this.payMode   = map.mode;
            this.payCall   = map.call;
            this.info.unit = map.unit;
            this.showPaymentPicker = false;
        },
        onSelect ( item ) {
            this.info.select = item;
        },
        onTap () {
            this.waiting = true;
            console.log( '创建订单...' );
            this.HTTP.createCharge( this.payMode, this.info.select.id, this.client ).then( ( res ) => {
                console.log( '创建订单...end' );
                console.log( '调起支付...' );
                this.orderNo = res.orderNo;
                const data = {
                    id  : res.orderNo,
                    data: res.payResult,
                };
                if ( window.isClient ) {
                    this.JSBridge.callHandler( this.payCall, data, ( st ) => {
                        if ( ! st ) {
                            this.$toast( '未安装客户端，调起支付失败！' );
                            setTimeout( () => { this.waiting = false; }, 1000 );
                            console.log( `调起支付...失败` );
                            return;
                        }
                        console.log( `调起支付...end` );
                    } );
                } else {
                    this.$toast( '暂不支持网页版支付' );
                    setTimeout( () => { this.waiting = false; }, 1000 );
                }
            } ).catch( ( e ) => {
                console.error( e );
                this.$toast( '创建订单时发生错误！' );
                setTimeout( () => { this.waiting = false; }, 1000 );
            } );
        },
        onConfirm () {
            if ( this.payTips.state ) {
                // 检测订单
                if ( this.info.reportId && this.info.orderNo ) {
                    // 跳转至解锁界面
                    // this.$router.push( { path: '/pay/' + this.info.reportId, query: { orderNo: this.info.orderNo } } );
                } else {
                    // 占位
                }
                // 立即刷新金额
                this.loadBalance();
                // 再添加一个10秒延迟刷新金额
                setTimeout( this.loadBalance, 10000 );
                this.showPayTips = false;
            } else {
                this.showPayTips = false;
            }
        },
        gotoPayment () {
            this.swRouter( '/payment' );
        }
    },
    watch: {
        isIOS ( val ) {
            if ( val && ! this.openByIOS ) {
                const type   = 'Apple Pay';
                this.payList = [ type ];
                this.setPayType( type );
            }
        },
        openByIOS ( val ) {
            if ( this.isIOS ) {
                if ( val ) {
                    this.payList = [ '支付宝', '微信' ];
                    this.setPayType( '支付宝' );
                } else {
                    const type   = 'Apple Pay';
                    this.payList = [ type ];
                    this.setPayType( type );
                }
            }
        },
    },
    created () {
        // 初始化数据（来自支付界面 充值后需返回）
        this.info.reportId = this.$route.query.id;
        this.info.orderNo  = this.$route.query.orderNo;
        // 查询充值列表
        this.HTTP.goodsList().then( ( res ) => {
            this.loading     = false;
            this.goods       = res;
            this.info.select = this.goods && this.goods.length ? this.goods[ 0 ] : null;
        } ).catch( ( e ) => {
            this.$toast( '网络连接不稳定，请稍候重试！' );
            this.loading = false;
        } );
    },
    beforeMount () {
        // 版本兼容性检测(延迟)
        setTimeout( () => {
            if ( this.isIOS && window._appCode >= 162 ) {
                this.openByIOS = true;
            }
            console.log( 'wallet appCode:', window._appCode, 'typeof appcode:', typeof ( window._appCode ) );
        }, 500 );
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        // 注册支付回调
        this.JSBridge.registerHandler( 'paymentResult', ( data ) => {
            // console.log( data );
            // 安卓端需要进行转义
            if ( typeof data === 'string' ) {
                data = JSON.parse( data );
            }
            if ( typeof data === 'object' ) {
                this.waiting = false;
                switch ( data.state ) {
                    case 1:
                        // 支付成功
                        this.payTips       = this.payTipsMap.success;
                        this.payTips.state = true;
                        this.showPayTips   = true;
                        break;
                    case 5: // 兼容微信
                        break;
                    case 7: // 超时
                    case 8: // 取消
                        this.$toast( '已取消支付' );
                        break;
                    default:
                        // 支付失败
                        this.payTips       = this.payTipsMap.failed;
                        this.payTips.state = false;
                        this.showPayTips   = true;
                }
            } else {
                this.$toast( '支付时发生错误！' );
                this.waiting = false;
            }
        } );

        // 设置客户端类型
        if ( window.isIOS ) {
            this.isIOS  = true;
            this.client = 1;
        } else if ( window.isAndroid ) {
            this.isAndroid = true;
            this.client    = 2;
        }
        // 获取账户余额（必须在获取到客户端类型后）
        this.loadBalance();
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$iconSize: 19.73vw;

.wallet {
    height: 100vh;
    background-color: $c-pageBg2;

    .detail {
        color: $c-main;
    }

    #{&}--view {
        .s-subtitle {
            margin-top: 6.67vw;
        }

        .van-button__text {
            font-size: $font_400;
        }
    }

    #{&}--amount {
        background: $c-main url(/static/img/wallet.jpg) no-repeat left top;
        background-size: 100%;
        // width: 92vw;
        width: 100%;
        height: 36vw;
        color: white;
        text-align: center;
        box-shadow: 0 2.67vw 5.33vw rgba(#518CDB, .39);

        h3 {
            padding-top: 6.3vw;
            font-size: $font_373;
            line-height: $font_467;
        }
        h1 {
            font-size: $font_880;
            padding: 3.2vw 0;
        }
        p {
            font-size: $font_293;
            line-height: $font_467;
        }
    }

    #{&}--grid {
        // overflow: hidden;
        position: relative;
        // height: 49.87vw;

        .van-row {
            display: flex;
            flex-wrap: wrap;
        }

        .prod:nth-child(3n) {
            margin-right: 0;
        }

        .prod {
            position: relative;
            @extend .s-radius__s;
            overflow: inherit;
            // width: 29.33vw;
            width: 32%;
            height: 24vw;
            background-color: white;
            text-align: center;
            padding-top: 6.5vw;
            margin: 0 2% 2vw 0;

            &.on {
                background-color: $c-mainLight;
                color: $c-main;
                box-shadow: inset 0 0 0 2px $c-main;
                p {
                    color: $c-main;
                }
            }

            h2 {
                font-size: $font_587;
                em {
                    font-size: $font_373;
                    line-height: $font_587;
                    vertical-align: top;
                    font-style: normal;
                    font-weight: normal;
                    margin-left: .8vw;
                }
            }
            p {
                padding: 1.5vw;
                font-size: $font_293;
                color: $c-info;
            }
            i {
                @include flexColumn;
                position: absolute;
                top: 0;
                right: 0;
                height: 5vw;
                min-width: 16vw;
                margin-top: -1.5vw;
                color: white;
                font-size: $font_267;
                font-style: normal;
                line-height: normal;
                background: linear-gradient( to right, #FCA445, #FDB849 );
                border-radius: 3px $radius_s 3px $radius_s;
            }
        }
    }

    #{&}--tips {
        width: 87.2vw;
        padding: $padding;
        background-color: white;
        text-align: center;

        .icon {
            width: $iconSize;
            height: $iconSize;
            margin-top: 4.3vw;
        }
        h3 {
            font-size: $font_373;
            line-height: $font_533;
            padding-top: 7.47vw;
        }
        p {
            font-size: $font_293;
            color: $c-info;
            padding-top: .8vw;
        }
        .van-button--info {
            width: 59.2vw;
            margin-top: 10.93vw;
        }
        .link {
            font-size: $font_400;
            margin-top: 4.8vw;
        }
    }

    .how-to-pay {
        position: relative;
        display: block;
        color: $c-main;
        font-size: 3.2vw;
        font-weight: normal;
        text-align: center;
        padding: 1vw 0;
        margin-bottom: 3vw;
        cursor: pointer;
    }
}
</style>
