<template>
  <div class="charts">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" color="#1D2233" :back="showBack">
        <div slot="right" class="header-box" v-show="!title">
          <p class="sup-title1">
            <img class="point-icon" src="/static/pointRadar/point.png" alt="" />
            雷达扫描完成
          </p>
          <p class="sup-title2">根据所选条件，已为您扫描出以下点位(至多30个)</p>
        </div>
    </TitleHeader>
    <AutoView :footer="false" class="charts--view">
      <iframe ref="view" :src="url" frameborder="0" class="innerView">不支持内嵌窗口</iframe>
    </AutoView>
    <LoadingOverlay :show="loading" />
  </div>
</template>

<script>
let _timer = 0;
export default {
  name: 'index',
  data() {
    return {
      title: '正在加载',
      showBack: true,
      loading: true, // 数据加载
      host: "/report/?id=",
      id: '',
      entity: null,
      isReady: false, // 报告界面已就绪
      isSended: false, // 已下发数据
      isClient: false,
      firstBack: true, // 在报告详情页时，是不是第一次按返回按钮
      pointLength: 0,
      isExample: false, // 是否是示例报告
    }
  },
  computed: {
    url() {
      if(!this.entity) return
      return this.host + this.id;
      // return 'http://10.1.2.33:9002';
      // return 'http://10.1.2.33:9002/#/pointRadar';
    },
    viewTarget() {
      return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
    },
  },
  methods: {
    sendData() {
      // 已发送或未就绪或未得到数据将不调用下发
      if (this.isSended || !this.isReady || !this.entity) return;
      this.isSended = true; // 标记为已下发
      clearTimeout(_timer); // 清理超时提醒
      setTimeout(() => {
        this.loading = false;
        this.title = ''
      }, 1000);
      this.viewTarget.postMessage({ type: "data", content: this.entity }, "*");
    },
    addListener(e) {
      switch (e.data.type) {
        case "ready":
          this.isReady = true;
          this.sendData();
          break;
          // 判断手机是否安装过某个app
        case 'judgeInstallApp':
          let val = e.data.content;
          this.JSBridge.callHandler( 'isAppInstalled', val, ( data ) => {
            // console.log(data)
            this.viewTarget.postMessage({ type: "judgeInstallApp", content: data }, "*");
          })
          break
          // 获取当前位置
        case 'getLocation':
          this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
            if ( typeof data === 'string' ) data = JSON.parse( data );
            // console.log('data', data)
            let locationInfo = ''
            if ( data ) {
              locationInfo = {
                poiName: data.poiName,
                longitude: data.longitude,
                latitude: data.latitude
              }
            }
            this.viewTarget.postMessage({ type: "getLocation", content: locationInfo }, "*");
          })
          break
          // 雷达查看详情页
          case 'lookDetailPage':
            let title = e.data.content;
            this.title = title
            this.firstBack = true
            this.JSBridge.callHandler("interceptBack", false);
            this.JSBridge.registerHandler("onBackPressed", (data) => {
              console.log('app 调用 onBackPressed 函数成功', this.firstBack);
              // this.JSBridge.callHandler("interceptBack", false);
              if(this.firstBack) {
                this.viewTarget.postMessage({ type: "pageBack" }, "*");
              }
            })
            break
          // 埋点上报
          case 'pointReport':
            const { mid, bid } = e.data.content
            this.JSBridge.BTNCLICK(10314, "", mid, bid);
            break
          // 页面返回成功
          case 'pageBackComplete':
            this.title = ''
            this.firstBack = false
            break
        // 下载点位清单
          case 'download':
            this.HTTP.getDownLoadUrl(this.id).then(res => {
              // console.log('load url ', res)
              this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "MY_DOWNLOAD",
                id: "3",
              });
            })
            break;
          case 'goPointPage':
            this.JSBridge.callHandler("onNormalizingCall", {
              type: 5,
              url: "POINT_RADAR",
              id: "",
              params: null,
              trackData: null,
              isClear: true,
            });
            break;
      }
    }
  },
  mounted() {
    // 获取报告ID
    const id = this.$route.params.id;
    this.id = id
    if (!id) {
      this.$toast("无效报告信息，即将返回首页！");
      this.$router.push("/");
      return;
    }

    this.isExample = this.$route.query.type == 'example'

    // 获取报告地址
    this.host = this.HTTP.getReportUrl();
    const entityHandler = (res) => {
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      // 数据提取
      // 保存修改后的数据实体
      res['config'] = {
        templateId: res.stdQuestionId,
        isExample: this.isExample
      }
      this.entity = res;
      console.log("entity:", res);
      // 设置超时并下发数据
      _timer = setTimeout(() => {
        this.$toast("无法获取报告，请稍候重试！");
        this.loading = false;
        this.title = ''
      }, 30000);
      this.sendData();
    };

    // 加载网络数据
    this.HTTP.getReportData(id)
      .then((res) => {
        entityHandler(res);
      })
      .catch((e) => {
        this.$toast("网络不稳定，请稍候重试！");
        this.loading = false
      });

    // 添加通信监听
    window.addEventListener("message", this.addListener);
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 设置客户端类型
    this.isClient = window.isClient || true;
  },
  beforeDestroy() {
  },
  destroyed() {
    // 移除通信监听
    window.removeEventListener("message", this.addListener);
    // 移除定时监听
    clearTimeout(_timer);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.charts {
  overflow: hidden;
  height: 100vh;
  ::v-deep .titleHeader {
    .view {
      &--r {
        width: 100%;
        top: 0;
        left: 0;
      }

      .subTitle {
        position: absolute;
        bottom: 2.5vw;
        left: 50%;
        color: #9aa9be;
        transform: translateX(-50%);
        font-weight: normal;
      }
    }
  }
  #{&}--view {
    overflow-y: hidden;
    .innerView {
      width: 100%;
      height: 100%;
    }
  }
  .header-box {
    color: #1D2233;
    text-align: center;
    .sup-title1 {
      font-size: vw(28);
      font-weight: bold;
      line-height: vw(40);
      display: flex;
      align-items: center;
      justify-content: center;
      .point-icon {
        width: vw(30);
        height: vw(30);
        margin-right: vw(8);
      }
    }
    .sup-title2 {
      font-weight: 400;
      font-size: vw(26);
      line-height: vw(37);
      .count {
        color: #347FFF;
        margin: 0 vw(10);
      }
    }
  }
}
</style>
