<template>
    <div class="vip">
        <StateBar></StateBar>
        <TitleHeader title="VIP会员" bgcolor="transparent" :back="true" />

        <!-- 骨架屏占位图 -->
        <img v-if="loading" src="/static/skt/vip.png" width="100%" />

        <AutoView v-show="!loading" :header="true" :footer="true" class="vip--view">
            <div class="l-view" style="padding-bottom: 0">
                <div class="vip--card s-radius" :class="{ vip: isVip }">
                    <van-image class="photo"
                        fit="cover"
                        lazy-load
                        round
                        :src="info.photo"
                        error-icon="/static/icon/default.png">
                        <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                        </template>
                    </van-image>
                    <h3>{{ info.nickname }}</h3>
                    <p>{{ isVip ? `${info.member.endTime}到期` : '尚未开通' }}</p>
                </div>
                <h3 class="s-subtitle" style="margin-bottom: 0">购买会员服务</h3>
            </div>

            <div class="vip--grid">
                <div class="prod-list">
                    <div v-for="( item, index ) of goods" :key="index" class="prod s-ani" :class="{ on: info.select && item.id === info.select.id }" @click="onSelect( item )">
                        <i v-show="! isAndroid">送{{ item.sscmCoin }}上上贝</i>
                        <h1>{{ item.name }}</h1>
                        <h2><sub>￥</sub>{{ item.price }}</h2>
                        <p>{{ item.days }}天</p>
                    </div>
                </div>
            </div>

            <div class="vip--feature l-view f-vmt">
                <h3 class="s-subtitle">五大权益<i class="icon_inst" @click="showRule = true" /></h3>
                <van-divider />
                <ul class="l-flex vip-list">
                    <li class="vip_r1"><i>{{ info.select ? info.select.showDiscount : '7折'  }}</i>购买报告<br>享受优惠</li>
                    <li class="vip_r2">查看市场<br>调研报告</li>
                    <li class="vip_r3">免费解锁<br>指定报告</li>
                    <li class="vip_r4">会员专属<br>外显</li>
                    <li class="vip_r5">赠送报告<br>给好友</li>
                    <li class="vip_r6" style="visibility: hidden">占位</li>
                </ul>
            </div>

            <div class="s-tips f-tac">
                阅读<a :href="url" class="link">《会员权益》</a>
            </div>
        </AutoView>

        <SoltFooter class="vip--footerBar">
            <div class="view">
                <van-button type="info" block class="s-radius__s vip--btn" @click="onTapPay">立即开通</van-button>
            </div>
        </SoltFooter>

        <!-- 支付选择界面 -->
        <van-popup v-model="showPayment" round position="bottom" :safe-area-inset-bottom="false" :close-on-click-overlay="false" @click-overlay="onTapClose">
            <div class="l-panel f-vp vip--subview">
                <h3>开通会员<i class="icon_vip" /></h3>
                <div class="view">
                    <!-- 支付方式选择 -->
                    <PayTypes :payList="payList" @setPayType="setPayType" />
                </div>
                <van-button type="info" block :loading="waiting" class="s-radius__s" @click="onTap">确认支付</van-button>
            </div>
        </van-popup>

        <!-- 购买结果提醒 -->
        <van-overlay :show="showPayTips">
            <div class="l-wrapper">
                <div class="vip--tips l-panel s-radius__s">
                    <van-image class="icon"
                        fit="cover"
                        :src="payTips.icon" />
                    <h3>{{ payTips.title }}</h3>
                    <p>{{ payTips.text  }}</p>
                    <van-button type="info" class="s-radius__s" @click="onConfirm">确定</van-button>
                </div>
            </div>
        </van-overlay>

        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>

        <!-- 规则说明 -->
        <van-dialog v-model="showRule" confirmButtonText="关闭" get-container="#app">
            <h1>权益说明</h1>
            <div class="content">
                <p><strong>1.</strong>开通会员，免费解锁任意付费报告1次</p>
                <p><strong>2.</strong>每续费1次会员，增加1次免费解锁机会</p>
                <p><strong>3.</strong>会员到期后，失去相关会员权益</p>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import PayTypes from '@/components/payTypes';

export default {
    name      : 'vip',
    components: {
        PayTypes,
    },
    data () {
        return {
            loading    : true,
            waiting    : false,
            showPayment: false,
            showPayTips: false,
            showRule   : false,

            isAndroid: false,
            isIOS    : false,
            client   : 3, // 1iOS 2Android 3WEB
            payList  : [ '支付宝', '微信' ],
            payType  : null,
            payMode  : null,
            payCall  : null,
            payMap   : {
                '支付宝': {
                    mode: 'ALIPAY_MOBILE',
                    call: 'aliPay',
                    unit: '￥',
                },
                '微信': {
                    mode: 'WX_APP',
                    call: 'wxPay',
                    unit: '￥',
                },
                'Apple Pay': {
                    mode: 'APPLE_IN',
                    call: 'applePay',
                    unit: '￥',
                },
            },
            payTips: {
                icon : '',
                title: '',
                text : '',
            },
            payTipsMap: {
                success: {
                    icon : '/static/icon/msg_success_orange.png',
                    title: '操作成功',
                    text : '您已成功完成支付',
                },
                failed: {
                    icon : '/static/icon/more.png',
                    title: '操作失败',
                    text : '未能完成支付操作',
                },
            },
            goods  : [],
            orderNo: null, // 订单号 下单时生成
            isVip  : false, // 是否会员
            url    : null,
            info   : {
                nickname: '',
                photo   : '',
                member  : null,
                select  : null,
            },
        };
    },
    methods: {
        loadData ( res ) {
            this.loading       = false;
            this.isVip         = res.userDetail ? res.userDetail.member : false;
            this.goods         = res.memberGoodsList;
            this.info.select   = this.goods && this.goods.length ? this.goods[ 0 ] : null;
            this.info.nickname = res.userDetail ? res.userDetail.username : null;
            this.info.photo    = res.userDetail ? res.userDetail.avatar : '';
            this.info.member   = res.userDetail ? res.userDetail.memberInfo : null;
        },
        setPayType ( value ) {
            const map      = this.payMap[ value ];
            this.payType   = value;
            this.payMode   = map.mode;
            this.payCall   = map.call;
            this.info.unit = map.unit;
        },
        onSelect ( item ) {
            this.info.select = item;
        },
        onTapPay () {
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.VIP.TAP_PAY );
            if ( this.isIOS ) {
                this.onTap();
            } else {
                this.showPayment = true;
            }
        },
        onTap () {
            // 业务流程
            this.waiting = true;
            console.log( '创建订单...' );
            this.HTTP.createVip( this.payMode, this.info.select.id, this.client ).then( ( res ) => {
                console.log( '创建订单...end' );
                console.log( '调起支付...' );
                this.orderNo = res.orderNo;
                const data = {
                    id  : res.orderNo,
                    data: res.payResult,
                };
                if ( window.isClient ) {
                    this.JSBridge.callHandler( this.payCall, data, ( st ) => {
                        if ( ! st ) {
                            this.$toast( '未安装客户端，调起支付失败！' );
                            setTimeout( () => { this.waiting = false; }, 1000 );
                            console.log( `调起支付...失败` );
                            return;
                        }
                        console.log( `调起支付...end` );
                    } );
                } else {
                    this.$toast( '暂不支持网页版支付' );
                    setTimeout( () => { this.waiting = false; }, 1000 );
                }
            } ).catch( ( e ) => {
                console.error( e );
                this.$toast( '创建订单时发生错误！' );
                setTimeout( () => { this.waiting = false; }, 1000 );
            } );
        },
        onTapClose () {
            if ( this.payLoading ) return;
            this.showPayment = false;
        },
        onConfirm () {
            if ( this.payTips.state ) {
                this.$router.replace( '/vip' );
                this.showPayTips = false;
            } else {
                this.showPayTips = false;
            }
        },
    },
    watch: {
        isIOS ( val ) {
            if ( val ) {
                const type   = 'Apple Pay';
                this.payList = [ type ];
                this.setPayType( type );
            }
        },
    },
    created () {
        // 查询会员信息
        if ( this.$route.params && JSON.stringify( this.$route.params ) !== '{}' ) {
            this.loadData( this.$route.params );
        } else {
            this.HTTP.getVipInfo().then( ( res ) => {
                this.loadData( res );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
            } );
        }
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        // 注册支付回调
        this.JSBridge.registerHandler( 'paymentResult', ( data ) => {
            // console.log( data );
            // 安卓端需要进行转义
            if ( typeof data === 'string' ) {
                data = JSON.parse( data );
            }
            if ( typeof data === 'object' ) {
                this.waiting = false;
                switch ( data.state ) {
                    case 1:
                        // 支付成功
                        this.showPayment   = false;
                        this.payTips       = this.payTipsMap.success;
                        this.payTips.state = true;
                        this.showPayTips   = true;
                        // 通知客户端更新用户信息
                        this.JSBridge.callHandler( 'noticeUpdate', 'userInfo' );
                        break;
                    case 5: // 兼容微信
                        break;
                    case 7: // 超时
                    case 8: // 取消
                        this.$toast( '已取消支付' );
                        break;
                    default:
                        // 支付失败
                        this.showPayment   = false;
                        this.payTips       = this.payTipsMap.failed;
                        this.payTips.state = false;
                        this.showPayTips   = true;
                }
            } else {
                this.$toast( '支付时发生错误！' );
                this.waiting = false;
            }
        } );

        // 设置客户端类型
        if ( window.isIOS ) {
            this.isIOS  = true;
            this.client = 1;
        } else if ( window.isAndroid ) {
            this.isAndroid = true;
            this.client    = 2;
        }

        // 设置协议链接
        this.url = `/static/page/${window._appId || 'SSCM'}/memberbenefits.html`;
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$iconSize: 19.73vw;

.vip {
    height: 100vh;
    background-color: $c-pageBg2;

    .detail {
        color: $c-main;
    }

    #{&}--view {
        .s-subtitle {
            font-size: $font_427;
            margin-top: $padding;
        }

        .s-tips {
            padding: $margin 0;
        }
    }

    #{&}--card {
        position: relative;;
        background: url(/static/img/vip.jpg) no-repeat left top;
        background-size: 100%;
        width: 87.2vw;
        height: 38.67vw;
        color: white;
        text-align: center;
        // box-shadow: 0 2.67vw 5.33vw rgba(#518CDB, .39);

        .photo {
            position: absolute;
            top: 24vw;
            left: 7.47vw;
            width: 8vw;
            height: 8vw;
            box-shadow: 0 0 0 2px white;

            ::v-deep .van-icon__image {
                width: 100%;
                height: 100%;
            }
        }

        h3 {
            position: absolute;
            top: 23.07vw;
            left: 18.53vw;
            font-size: $font_400;
            line-height: $font_560;
            font-weight: bold;
        }
        p {
            position: absolute;
            top: 28.53vw;
            left: 18.53vw;
            font-size: $font_320;
            line-height: $font_440;
            color: $c-gray2;
        }

        &.vip {
            .photo {
                box-shadow: 0 0 0 2px $c-vip2;
            }
            &:after {
                content: '';
                position: absolute;
                width: 3.73vw;
                height: 3.73vw;
                top: 28.8vw;
                left: 12.3vw;
                background: url(/static/icon/vip.png) no-repeat;
                background-size: 100%;
            }
        }
    }

    #{&}--grid {
        overflow-y: hidden;
        width: 100%;
        &::-webkit-scrollbar {
            display: none;
        }

        .prod-list {
            @extend .l-flex;
            padding: $padding $padding 0;
            width: 120vw;
            box-sizing: border-box;
        }

        .prod {
            position: relative;
            overflow: inherit;
            @extend .s-radius__s;
            width: 24vw;
            height: 28vw;
            background-color: white;
            text-align: center;

            &.on {
                box-shadow: inset 0 0 0 3px $c-vip1;
                h1 {
                    color: #8D5D22;
                }
            }

            i {
                @include flexColumn;
                position: absolute;
                top: 0;
                right: 0;
                height: 4.67vw;
                padding: 0 1.5vw;
                font-size: $font_320;
                color: white;
                font-style: normal;
                background-color: #FBAA53;
                transform: translateY( -40% );
                border-radius: 0 $radius_s 0 $radius_s;
            }
            h1 {
                margin-top: 5.33vw;
                font-size: $font_400;
                line-height: $font_560;
            }
            h2 {
                margin-top: 2vw;
                color: #F46958;
                font-size: $font_587;
                font-weight: bold;
                sub {
                    vertical-align: bottom;
                    font-size: $font_373;
                    font-style: normal;
                    margin-right: .8vw;
                }
            }
            p {
                margin-top: 1.5vw;
                font-size: $font_320;
                line-height: $font_440;
                color: $c-info;
            }
        }
    }

    .vip-list {
        flex-wrap: wrap;
        padding-top: $margin;

        li {
            position: relative;
            background: url(/static/icon/vip_r1.jpg) no-repeat center 0;
            background-size: 12.93vw 12.93vw;
            box-sizing: border-box;
            padding: 16vw 0 $padding;
            width: 33.333%;
            font-size: $font_320;
            line-height: $font_440;
            text-align: center;

            &.vip_r2 {
                background-image: url(/static/icon/vip_r2.jpg);
            }
            &.vip_r3 {
                background-image: url(/static/icon/vip_r3.jpg);
            }
            &.vip_r4 {
                background-image: url(/static/icon/vip_r4.jpg);
            }
            &.vip_r5 {
                background-image: url(/static/icon/vip_r5.jpg);
            }

            i {
                position: absolute;
                top: 0;
                left: 50%;
                padding: .5vw 1.5vw;
                background: linear-gradient(213deg, #FB9491, #F56958);
                transform: translateY(-50%);
                opacity: .9;
                @include font( white, $font_267, normal );
                font-style: normal;
                border-radius: $radius $radius $radius 0;
            }
        }
    }

    #{&}--feature {
        border-radius: $radius;
        // padding-bottom: $padding;
        background-color: white;

        .s-subtitle {
            margin: $margin 0 $padding;
        }

        .van-divider {
            margin: 5.2vw 0;
        }

        .icon_inst {
            float: right;
            width: 4.67vw;
            height: 4.67vw;
            background: url(/static/icon/inst.png) no-repeat;
            background-size: contain;
        }
    }

    #{&}--footerBar {
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-col {
                height: 100%;
            }
        }

        ::v-deep .van-button {
            height: 100%;
            @include font( #8D5D22, $font_400, bold );
            background: linear-gradient( to right, #FCCA8D, #F4B66A );
            border-color: transparent;
        }
    }

    #{&}--subview {
        h3 {
            text-align: center;
            font-size: $font_400;
            .icon_vip {
                display: inline-block;
                width: 7.86vw;
                height: 4vw;
                background: url(/static/icon/vip_card.png) no-repeat left center;
                background-size: cover;
                vertical-align: bottom;
                margin-left: 1.5vw;
            }
        }
        .view {
            padding: $margin 0;
        }
        ::v-deep .van-button {
            font-size: $font_400;
            line-height: normal;
        }
    }

    #{&}--tips {
        width: 87.2vw;
        padding: $padding;
        background-color: white;
        text-align: center;

        .icon {
            width: $iconSize;
            height: $iconSize;
            margin-top: 4.3vw;
        }
        h3 {
            font-size: $font_373;
            line-height: $font_533;
            padding-top: 7.47vw;
        }
        p {
            font-size: $font_293;
            color: $c-info;
            padding-top: .8vw;
        }
        .van-button--info {
            width: 59.2vw;
            margin-top: 10.93vw;
        }
        .link {
            font-size: $font_400;
            margin-top: 4.8vw;
        }
    }
}

::v-deep .van-dialog__content {
    background: url(/static/img/vip_rule.jpg) no-repeat center top;
    background-size: contain;
    padding: $padding;
    h1 {
        font-size: 6.4vw;
        text-align: center;
        padding: 8vw 0;
    }
    .content {
        margin-top: $padding;
        p {
            color: $c-info;
            font-size: $font_347;
            line-height: $font_587;
            margin-top: $margin;
        }
        strong {
            color: $c-main;
        }
    }
}
</style>
