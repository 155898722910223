<template>
  <div class="cell pay-btn-wrap">
    <!-- <div class="cell-hd company-pay">
      <van-button @click="companyPay">对公转账</van-button>
    </div> -->
    <div class="cell-bd pay-btn">
      <!-- <van-button type="info" @click="onTapPay">
        支付<span>￥</span>{{btnText}}
      </van-button> -->
      <van-button type="info" @click="onTapPay">
        <span>咨询企业顾问</span>
      </van-button>

    </div>
  </div>
</template>

<script>
export default {
  name: "PayButtonGroup",
  props: ['btnText'],
  methods: {
    companyPay(){
      this.swRouter({
        path: "/vip/enterprise/company-pay",
      })
    },
    onTapPay(){
      this.$emit('onTapPay')
    },
  }
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import '@/styles/_cell.scss';
//@import "@/styles/index.scss";

.pay-btn-wrap{
  .company-pay{
  //border: none;
    .van-button{
      font-weight: 500;
      color: #347FFF;
      line-height: 40px;
      border-radius: vw(10);
      border: vw(2) solid #347FFF;
    }
  }
  .pay-btn{
    // padding-left: vw(24);
    span {
      color: #fff;
      position: relative;
      &::before{
        position: absolute;
        top: 50%;
        left: vw(-50);
        transform: translateY(-50%);
        content: ' ';
        display: inline-block;
        width: vw(42);
        height: vw(42);
        background: url("/static/vip/enterprise/svip/ask-icon.png") no-repeat;
        background-size: 100% auto;
      }
    }
    .van-button{
      background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
      border-radius: vw(10);
      border: 0;
      width: 100%;
    }
  }
}

</style>
