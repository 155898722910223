<template>
    <div class="lock-wrap" @click="handleClick()">
        <p class="tips">{{ tips }}</p>
        <img class="lock-img" :src="lockImg" alt="" />
    </div>
</template>

<script>

export default {
    props: {
        tips: {
            type: String,
            default: "会员VIP可见",
        },
        lockImg: {
            type: String,
            require: true,
        },
    },
    methods: {
        handleClick() {
            this.swRouter("/h5/#/member")
        },
    },
};
</script>

<style lang="scss" scoped>

.lock-wrap {
    position: relative;
    height: 100%;

    .tips {
        position: absolute;
        top: 25%;
        left: 0;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        z-index: 12;
    }

    .lock-img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        // object-fit : cover;
    }
}</style>
