<template>
    <div class="wrapper">
        <AutoView :header="true" :footer="false" class="coupon--view">
            <div id="container"></div>
            <div class="positionWrapper">
                <div class="positionTitle">
                    <div class="left">输入位置</div>
                </div>
                <div class="positionContent" @click="selectCityHandle">
                    <div class="left">
                        <div class="icon"></div>
                        <div class="city">{{ city }}</div>
                        <div class="moreIcon"></div>
                    </div>
                    <div class="right">
                        <van-field style="background-color: #f6f7f9" v-model="gpsInfo.poiName" type="text" readonly :placeholder="placeholder" />
                    </div>
                </div>
                <div class="positionTitle">
                    <div class="left">分析范围</div>
                </div>
                <div class="distanceLimit">
                    <van-slider
                        v-model="distanceLimit"
                        bar-height="6px"
                        @change="sliderMove"
                        step="33"
                        block-size="16"
                        activeColor="#4544FC"
                        max="99"
                        style="margin: 2vw 0"
                    >
                        <template #button>
                            <img class="custom-button" src="/static/icon/slider_control.png" alt="" />
                        </template>
                    </van-slider>
                    <div class="scale">
                        <span @click="distanceClick(0)">0.5km</span>
                        <span @click="distanceClick(33)">1km</span>
                        <span @click="distanceClick(66)">2km</span>
                        <span @click="distanceClick(99)">3km</span>
                    </div>
                </div>
                <div class="industryPicker" v-if="goodsId === '10018' || goodsId === '10001'">
                    <div class="positionTitle">
                        <div class="left">准备开店经营行业</div>
                    </div>
                    <div class="currentIndustry" @click="selectIndustry">
                        <van-field style="background-color: #f6f7f9" v-model="industryName" type="text" readonly placeholder="请选择意向行业" />
                        <div class="pickerIcon"></div>
                    </div>
                </div>
                <div class="pricePicker" v-if="goodsId === '10001'">
                    <div class="positionTitle">
                        <div class="left">人均消费价格范围</div>
                    </div>
                    <div class="price">
                        <van-field v-model="min" placeholder="最低价" />
                        <span>-</span>
                        <van-field v-model="max" placeholder="最高价" />
                    </div>
                </div>
                <div class="package" v-if="goodsId === '10001'">已选择 <span>{{packageInfo.firstText.substr(3)}}</span> 剩余 <span>{{ packageInfo.restExchangeTimes + 1 }}</span> 次</div>
            </div>
        </AutoView>
        <SoltFooter>
            <div class="submitFooter">
                <van-button type="info" block class="s-radius__s" @click="clickSubmitHandle">立即评估</van-button>
            </div>
        </SoltFooter>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker title="选择行业" show-toolbar :columns="columns" :default-index="0" @cancel="showPicker = false" @confirm="onConfirm" />
        </van-popup>
        <van-popup v-model="showCityPicker" round position="bottom">
            <van-area title="" :area-list="areaList" :columns-num="2" value="110101" @cancel="showCityPicker = false" @confirm="onCityConfirm" />
        </van-popup>
        <van-popup v-model="show" round position="bottom">
            <van-cascader
                active-color="#347FFF"
                v-model="cascaderValue"
                title="请选择意向行业"
                :options="options"
                @close="show = false"
                @change="onChange"
                @finish="onFinish"
            />
        </van-popup>
        <van-popup v-model="isError" closeable style="border-radius: 3.2vw;">
                <div class="isError">
                    <img src="/static/element/action_icon.png" alt="">
                    <div class="tips">
                        <div class="title">所选区域内的数据量不足</div>
                        <div class="value">请调整输入条件</div>
                    </div>
                </div>
            </van-popup>
        <div class="loading" v-if="loading">
            <div class="isLoading">
                <van-loading type="spinner" color="#1989fa" />
                <div class="title">{{ text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import LoadMap from "@/components/baseMap/loadMap";
export default {
    data() {
        return {
            gpsInfo: {
                lat: "",
                lng: "",
                poiName: "",
                radiusKm: 1,
            },
            city: "定位中",
            placeholder: "搜索商圈或区域",
            industryName: "",
            industryValue: [],
            columns: ["餐饮", "零售", "教育", "休闲娱乐", "医疗健康", "丽人", "运动健身"],
            cityCode: "",
            areaList: [],
            distanceLimit: 33,
            showCityPicker: false,
            showPicker: false,
            show: false,
            goodsId: "",
            min: "",
            max: "",
            cascaderValue: "",
            options: [],
            linkUrl: "",
            reportInstanceId: "",
            currentRate: 0,
            loading: false,
            packageInfo: null,
            circle: null,
            item: null,
            isError: false
        };
    },
    watch: {
        min: function () {
            this.min = this.min.replace(/[^0-9]/g, "");
        },
        max: function () {
            this.max = this.max.replace(/[^0-9]/g, "");
        },
    },
    computed: {
        text() {
            return "报告生成中：" + this.currentRate.toFixed(0) + "%";
        },
    },
    mounted() {
        this.goodsId = this.$route.query.goodsId;
        this.HTTP.elemeSubmit({
            platform: sessionStorage.getItem("platform"),
            track: JSON.stringify([
                {
                    pageId: "10181",
                    timestamp: new Date().getTime(),
                    refId: this.goodsId
                },
            ]),
        });
        this.areaList = areaList;
        this.getPackageInfo('10001')
        this.item = this.$route.query.item;
        this.getIndustry();
        LoadMap().then(() => {
            this.getLocation();
        });
    },
    methods: {
        getPackageInfo(goodsId) {
            this.HTTP.getPackageInfo(goodsId).then(res => {
                if(res.data.code == 0) {
                    this.packageInfo = res.data.data.pkgShowResp
                }
            })
        },
        getIndustry() {
            this.HTTP.formatCategory().then((res) => {
                if (res.code == 0) {
                    let option;
                    if (this.goodsId === "10001") {
                        option = res.data.map((item) => {
                            const industry1 = item.children.map((items) => {
                                const industry2 = items.children.map((el) => {
                                    return {
                                        value: el.code,
                                        text: el.name,
                                    };
                                });
                                return {
                                    value: items.code,
                                    text: items.name,
                                    children: industry2,
                                };
                            });
                            return {
                                value: item.code,
                                text: item.name,
                                children: industry1,
                            };
                        });
                    }
                    if (this.goodsId === "10018") {
                        option = res.data.map((item) => {
                            return {
                                value: item.code,
                                text: item.name,
                            };
                        });
                    }
                    this.options = option;
                }
            });
        },
        getLocation() {
            let that = this;
            let map = new AMap.Map("container", {
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 14, //初始化地图层级
                center: [116.397428, 39.90923], //初始化地图中心点
            });
            let geocoder = new AMap.Geocoder({
                city: this.$route.query.city || "", //城市设为北京，默认：“全国”
                radius: 3000, //范围，默认：500
            });

            this.circle = new AMap.Circle({
                center: this.lnglat,
                radius: 1000, //半径
                strokeOpacity: 1,
                strokeWeight: 0,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                strokeDasharray: [10, 10],
                fillColor: "#1791fc",
                zIndex: 50,
            });
            let marker = new AMap.Marker();
            map.plugin("AMap.Geolocation", function () {
                let geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    timeout: 10000, //超过10秒后停止定位，默认：无穷大
                    buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonPosition: "LB",
                    buttonDom: `<div class="btn" style="background-color: #fff; width: 9.33vw; height: 9.33vw;box-shadow: 0 2.667vw 4vw 0 rgba(136, 141, 152, 0.27); border-radius: 2.667vw; display: flex; justify-content: center; align-items: center;">
                        <img src="/static/element/map_icon_location.png" style="width: 5.067vw; height: 5.067vw;" alt="">
                    </div>`,
                    showMarker: false,
                });
                map.addControl(geolocation);
                if(that.item) {
                    map.panTo(that.item.location);
                    map.on("moveend", logMapinfo);
                    map.on("zoomend", logMapinfo);
                    setTimeout(() => {
                        pantoMap()
                    }, 0)
                } else {
                    geolocation.getCurrentPosition();
                    AMap.event.addListener(geolocation, "complete", onComplete); //返回定位信息
                    AMap.event.addListener(geolocation, "error", onError); //返回定位出错信息
                }
            });
            function pantoMap() {
                let center = map.getCenter(); //获取当前地图中心位置
                let lnglat = [center.lng, center.lat];
                console.log(lnglat, "getCenter")
                map.add(marker);
                marker.setPosition(lnglat);
                that.gpsInfo.lat = center.lat;
                that.gpsInfo.lng = center.lng;
                that.circle.setCenter(lnglat);
                that.circle.setRadius(that.gpsInfo.radiusKm * 1000);
                that.gpsInfo.poiName = that.item.name
                that.city = that.item.cityname
            }
            //解析定位结果
            function onComplete(data) {
                logMapinfo()
                map.on("moveend", logMapinfo);
                map.on("zoomend", logMapinfo);
            }

            this.circle.setMap(map);
            // 缩放地图到合适的视野级别
            map.setFitView([this.circle]);

            // 解析定位错误信息
            function onError(data) {
                that.$toast.fail("定位失败");
            }
            function logMapinfo() {
                let center = map.getCenter(); //获取当前地图中心位置
                let lnglat = [center.lng, center.lat];
                console.log(lnglat, "logMapinfo")
                that.circle.setCenter(center);
                that.circle.setRadius(that.gpsInfo.radiusKm * 1000);

                map.add(marker);
                marker.setPosition(lnglat);
                geocoder.getAddress(lnglat, function (status, result) {
                    if (status === "complete" && result.regeocode) {
                        let city = result.regeocode.addressComponent.city || result.regeocode.addressComponent.province;
                        let citycode = result.regeocode.formattedAddress.citycode;
                        that.gpsInfo.lat = center.lat;
                        that.gpsInfo.lng = center.lng;
                        that.city = city;
                        that.citycode = citycode;
                    } else {
                        console.log("根据经纬度查询地址失败");
                    }
                });
                setTimeout(() => {
                    AMap.service(["AMap.PlaceSearch"], function () {
                        let placeSearch = new AMap.PlaceSearch({
                            pageSize: 10, // 单页显示结果条数
                            pageIndex: 1, // 页码
                            city: that.city, // 兴趣点城市
                            citylimit: true, //是否强制限制在设置的城市内搜索
                            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                        });
                        placeSearch.searchNearBy("", lnglat, that.gpsInfo.radiusKm * 1000, function (status, result) {
                            if (status === "complete") {
                                that.gpsInfo.poiName = result.poiList.pois[0].name
                            } else {
                                console.log("查询失败")
                                that.gpsInfo.poiName = "";
                            }
                        });
                    });
                }, 300);
            }
        },
        getRidius(value) {
            switch (value) {
                case 0:
                    this.gpsInfo.radiusKm = 0.5;
                    break;
                case 33:
                    this.gpsInfo.radiusKm = 1;
                    break;
                case 66:
                    this.gpsInfo.radiusKm = 2;
                    break;
                default:
                    this.gpsInfo.radiusKm = 3;
            }
        },
        distanceClick(value) {
            this.distanceLimit = value;
            this.getRidius(this.distanceLimit);
            this.circle.setRadius(this.gpsInfo.radiusKm * 1000);
        },
        sliderMove(e) {
            this.getRidius(e);
            this.circle.setRadius(this.gpsInfo.radiusKm * 1000);
        },
        selectIndustry() {
            if (this.goodsId === "10001") {
                this.show = true;
            }
            if (this.goodsId === "10018") {
                this.showPicker = true;
            }
        },
        selectCityHandle() {
            this.swRouter({
                name: "selectPOI",
                path: "/element/selectPOI",
                params: {
                    city: this.city,
                },
            });
        },
        onConfirm(value) {
            this.industryName = value;
            this.showPicker = false;
        },
        onCityConfirm(e) {
            this.showCityPicker = false;
            this.city = e[1].name;
            this.cityCode = e[1].code;
        },
        async clickSubmitHandle() {
            const params = {
                goodsId: this.goodsId,
            };
            if(!this.gpsInfo.poiName) {
                this.$toast.fail("请选择区域");
                return
            }
            if (this.goodsId === "10019" || this.goodsId === "10032") {
                try {
                    const res = await this.HTTP.reportSubmit({
                        ...params,
                        gps: this.gpsInfo,
                    });
                    this.getProcess(res);
                } catch(e) {
                    this.isError = true
                }
            }
            if (this.goodsId === "10018") {
                if (!this.industryName) {
                    this.$toast.fail("请选择意向行业");
                    return;
                }
                let formatCategoryCode;
                this.options.forEach((item) => {
                    if (this.industryName === item.text) {
                        formatCategoryCode = item.value;
                    }
                });
                try {
                    const res = await this.HTTP.reportSubmit({
                        ...params,
                        gps: this.gpsInfo,
                        industry: {
                            formatCategoryName: this.industryName,
                            formatCategoryCode: formatCategoryCode,
                        },
                    });
                    this.getProcess(res);
                } catch(e) {
                    this.isError = true
                }
            }
            if (this.goodsId === "10001") {
                if (!this.industryName) {
                    this.$toast.fail("请选择意向行业");
                    return;
                }
                if (!this.min) {
                    this.$toast.fail("请选择最低价");
                    return;
                }
                if (!this.max) {
                    this.$toast.fail("请选择最高价");
                    return;
                }
                if (Number(this.max) <= Number(this.min)) {
                    this.$toast.fail("最高价必须高于最低价");
                    return;
                }
                const industryNameArr = this.industryName.split("-");
                const industryName = industryNameArr[industryNameArr.length - 1];
                this.HTTP.addSitingReport({
                    ...params,
                    gps: this.gpsInfo,
                    industry: {
                        formatCategoryName: industryName,
                        formatCategoryCode: this.industryValue[this.industryValue.length - 1],
                    },
                    userQuestionPkgItemId: this.packageInfo.questionId,
                    atv: {
                        min: this.min,
                        max: this.max,
                    },
                }).then((res) => {
                    console.log(res);
                    if (res.data.code == 0) {
                        const linkUrl = res.data.data.link.url.split("#")[1];
                        console.log(linkUrl);
                        this.swRouter(linkUrl);
                    }
                });
            }
        },
        getProcess(res) {
            this.linkUrl = res.data.link.url.split("#")[1];
            this.reportInstanceId = res.data.reportInstanceId;
            this.loading = true;
            const timer = setInterval(() => {
                this.HTTP.getProcess(this.reportInstanceId).then((res) => {
                    console.log(res.data.data.progress);
                    this.currentRate = res.data.data.progress;
                    if (res.data.data.progress == 100) {
                        clearInterval(timer);
                        this.loading = false;
                        this.swRouter(this.linkUrl);
                    }
                });
            }, 100);
        },
        onChange({ selectedOptions }) {
            this.industryName = selectedOptions.map((option) => option.text).join("-");
            this.industryValue = selectedOptions.map((option) => option.value);
            console.log(this.industryName, this.industryValue);
        },
        onFinish({ selectedOptions }) {
            this.show = false;
            this.industryName = selectedOptions.map((option) => option.text).join("-");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
#container {
    width: 100%;
    height: 82.667vw;
}
.positionWrapper {
    padding: 4vw;
}
.positionTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vw;
    .left {
        height: 5.6vw;
        font-size: 4vw;
        font-weight: bold;
        color: #333333;
        line-height: 5.6vw;
    }
}
.positionContent {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10.667vw;
    background: #f7f8fb;
    border-radius: 1.333vw;
    border: 0.133vw solid #ebeaee;
    padding: 2vw;
    margin-bottom: 4vw;
    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5.467vw;
        padding-right: 2.8vw;
        border-right: 0.267vw solid #dadada;
        .icon {
            width: 4.533vw;
            height: 4.533vw;
            background-image: url("/static/icon/location_icon.png");
            background-size: 100% 100%;
            margin-right: 1.067vw;
        }
        .city {
            font-size: 3.467vw;
            color: #666666;
            margin-right: 2.133vw;
        }
        .moreIcon {
            width: 2.267vw;
            height: 1.733vw;
            background-image: url("/static/icon/choice_icon.png");
            background-size: 100% 100%;
        }
    }
    .right {
        flex: 1;
        font-size: 3.467vw;
        font-weight: bold;
        color: #333333;
        padding-left: 2.8vw;
    }
}
.distanceLimit {
    margin-bottom: 4vw;
    padding: 0 2vw;
    .scale {
        margin-bottom: 4vw;
    }
    span {
        font-size: 3.5vw;
        color: #333333;
        &:nth-child(1) {
            margin-right: 15vw;
        }
        &:nth-child(2) {
            margin-right: 19vw;
        }
        &:nth-child(3) {
            margin-right: 17vw;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .custom-button {
        width: 7vw;
        height: 7vw;
    }
}
.industryPicker {
    margin-bottom: 4vw;
}
.currentIndustry {
    height: 10.667vw;
    background: #f7f8fb;
    border-radius: 1.333vw;
    border: 0.133vw solid #ebeaee;
    padding: 0 2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .industryName {
        font-size: 3.467vw;
        color: #999999;
    }
    .pickerIcon {
        width: 2.4vw;
        height: 1.733vw;
        background-image: url("/static/icon/input_icon-choice.png");
        background-size: 100% 100%;
    }
}
.price {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #f7f6f9;
    ::v-deep .van-cell {
        border-radius: 1.333vw;
        border: 0.133vw solid #ebeaee;
        background: #f7f8fb;
    }
    span {
        margin: 0 2vw;
    }
}
.package {
    width: 100vw;
    margin: 4vw 0 0 -4vw;
    line-height: 4.4vw;
    text-align: center;
    font-size: 3.2vw;
    color: #1d2233;
    background-color: #fff1e1;
    padding: 1.6vw 0;
    span {
        color: #8d5d22;
    }
}
.isError {
    width: 73.33vw;
    height: 44.5vw;
    background-image: linear-gradient(#E8F0FF, #FFFFFF);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 16vw;
        height: 16vw;
        margin: 5.33vw 0 3.2vw 0;
    }
    .tips {
        color: #1D2233;
        font-size: 3.733vw;
        text-align: center;
        line-height: 5.33vw;
        .title {
            font-weight: bold;
        }
    }
}
.submitFooter {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    border: 0.133vw solid #ebeaee;
    box-shadow: 0 -0.267vw 0.8vw 0 #ebeaee;
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
.loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    .isLoading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        width: 50vw;
        height: 50vw;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .title {
            margin-top: 20px;
        }
    }
}
::v-deep .van-overlay {
    z-index: 10000 !important;
}
::v-deep .van-popup {
    z-index: 10001 !important;
}
::v-deep .van-cascader {
    height: 60vh;
}
</style>
