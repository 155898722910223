<template>
  <div class="pay">
    <StateBar bgcolor="white" />
    <TitleHeader title="订单支付" :back="showBack"></TitleHeader>

    <AutoView :header="true" :footer="true" class="pay--view">
      <van-skeleton :loading="loading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <!-- 套餐包 -->
        <div class="l-panel">
          <ul class="flex-ul">
            <li class="li-left">
              <van-image class="goods-img" fit="cover" :src="reportData.questionPkg.cover" />
            </li>
            <li class="li-right">
              <p class="right-top f-tof">{{ reportData.questionPkg.stdQuestionName }}</p>
              <p class="right-mid">
                ￥{{ reportData.questionPkg.price }}<span class="i" v-if="reportData.questionPkg.tag">{{ reportData.questionPkg.tag }}</span>
              </p>
              <p class="right-bottom">{{ reportData.questionPkg.desc }}</p>
            </li>
          </ul>
          <template v-if="reportData.inputList && reportData.inputList.length">
            <p class="box-explain">{{ businessId ? "支付后可直接生成一份报告 ，剩余套餐次数可在“我的-我的购买”中使用。" : "" }}</p>
            <div class="box-card">
              <p class="card-title">{{ reportData.reportName }}</p>
              <p class="card-flex" v-for="item in reportData.inputList" :key="item.name">
                <span class="left">{{ item.name }}：</span>
                <span class="right">{{ item.value }}</span>
              </p>
            </div>
          </template>
        </div>
        <!-- 优惠券 -->
        <div class="l-panel f-vmt" v-if="isShowCoupon">
          <div class="coupon__detail" :class="{ on: enabledCoupon, disabled: lockCoupon }" @click="showCoupon">
            <div class="coupon__detail_left">
              <span class="s-subtitle">优惠券</span>
              <span class="coupon__name" v-if="getCouponName.name"
                ><em>{{ getCouponName.name }}</em></span
              >
            </div>
            <div class="coupon__w">
              <span v-if="getCouponName.alias" :class="{ on: getCouponName.on, dis: getCouponName.dis }">{{ getCouponName.alias }}</span>
              <template v-else>
                <span class="coupon__num">- {{ getPrice(info.discount).value }}</span>
                <span class="coupon__unit">{{ getPrice(info.discount).unit }}</span>
              </template>
            </div>
          </div>
        </div>
        <!-- 支付方式选择区域 -->
        <div class="l-panel nop f-vmt" style="padding-bottom: 0" v-show="showPayMode">
          <h3 class="s-subtitle">支付方式</h3>
          <!-- 支付方式列表 -->
          <PayTypes :balance="getBalance" :payList="payList" :payType="payType" @setPayType="setPayType" />
        </div>

        <!-- 警示信息区域 -->
        <div class="s-tips f-vpb">
          温馨提示：<br />1、您将解锁的商品为虚拟内容产品，确认支付后不支持退换、转让，请仔细确认。<br />
          2、大数据产品服务是基于一定样本量的算法结果，上上参谋不保证用户在平台获得的任何信息、内容（包括但不限于模型结论等）将是完整和没有错误的。由于数据产品服务的特殊性和时效性，在报告完成交付后，用户不得以前述理由要求平台进行退款。
        </div>
      </van-skeleton>
    </AutoView>

    <SoltFooter class="pay--footerBar">
      <van-row class="view">
        <!-- 一元换购 -->
        <div class="change-buy" @click="selectChangeActivity" v-if="entity && entity.exchangeActivity && showExchange && getAmount > 0">
          <p>
            <span class="tag">限时</span>
            <span>加9.9元换购价值<em>198元</em>上上参谋会员（1个月）</span>
          </p>
          <span class="checked-item" :class="{ checked: exchangeActivity, disabled: orderNo }"></span>
        </div>
        <van-col span="12">
          <p class="label">应付：</p>
          <p class="price">{{getAmount}}<sub>{{info.unit}}</sub></p>
        </van-col>
        <van-col span="12">
          <van-button type="warning" block :disabled="disabled" @click="onTapClick">确认支付</van-button>
        </van-col>
      </van-row>
    </SoltFooter>

    <!-- 优惠券选择 -->
    <van-popup v-model="showCouponPicker" position="bottom" round closeable>
      <div class="l-view pay--coupon">
        <h3 class="f-tac">我的优惠券</h3>
        <div class="coupons">
          <CouponCard
            v-for="(item, index) in couponList"
            :key="index"
            :class="{ 'f-vmt': index > 0 }"
            :item="item"
            :checked="info.coupon && info.coupon.voucherId === item.voucherId"
            @selected="setCoupon"
          />
        </div>
        <van-button type="info" block @click="showCouponPicker = false">确定</van-button>
      </div>
    </van-popup>

    <!-- 支付等待遮罩 -->
    <van-overlay :show="waiting">
      <div class="s-overlay">
        <van-loading color="#518CDB" />
      </div>
    </van-overlay>

    <!-- 全局加载遮罩-->
    <!-- <LoadingOverlay :show="loading" /> -->


  </div>
</template>

<script>
import CouponCard from "@/components/card/coupon";
import PackageCard from "@/components/card/package";
import PayTypes from "@/components/payTypes";
import { toFixed } from "@/utils/filters";

const DELAY_TIME = 2000;

export default {
  name: "pay",
  components: {
    CouponCard,
    PackageCard,
    PayTypes,
  },
  data() {
    return {
      isAndroid: false,
      isIOS: false,
      showBack: true,
      loading: true, // 数据加载
      waiting: false, // 等待支付
      disabled: false, // 禁用支付按钮
      lockCoupon: false, // 禁用优惠券
      tipsIcon: "/static/icon/insufficient_balance.png", // 购买提醒图标
      showPayMode: true, // 是否展示支付方式
      canClick: true, // 支付按钮是否可点击
      showCouponPicker: false, // 展示优惠券列表
      timer: 0, // 轮询定时器
      client: 3, // 1 iOS 2 Android 3 WEB 4 WX
      buyType: "套餐包", // 购买方式 v1.6.0
      payList: ["支付宝", "微信"],
      payType: "微信", // 默认支付方式
      payMode: "WX_APP",
      payCall: "wxPay",
      payMap: {
        支付宝: {
          mode: "ALIPAY_MOBILE",
          call: "aliPay",
          unit: "元",
        },
        微信: {
          mode: "WX_APP",
          call: "wxPay",
          unit: "元",
        },
        苹果: {
          mode: "APPLE_IN",
          call: "applePay",
          unit: "元",
        }
      },

      couponList: [], // 优惠券列表
      reportData: {
        questionPkg: {}, // 套餐包
        inputList: [],
        reportName: "",
      },
      entity: null, // 订单信息
      goods: {}, // 套餐包信息
      info: {
        price: 0.0, // 原价
        packPrice: 0.0, // 套餐包价格
        orderNo: null, // 订单号 原始订单
        discount: 0.0, // 优惠
        balance: 0.0, // 上上贝余额
        unit: "元", // 展示单位
        coupon: null, // 已选优惠券
      },
      exchangeActivity: false
    };
  },
  computed: {
    getPrice() {
      return (num) => {
        return {
          value: toFixed(num),
          unit: this.info.unit,
        };
      };
    },
    showExchange() {
      return ['支付宝', '微信'].includes(this.payType) && !this.loading
    },
    isShowCoupon() {
        let isShow = true
        if(this.isIOS) {
            isShow = window._appCode < 301
        }
        return isShow
    },
    getAmount() {
      let sum = this.info.packPrice - this.info.discount;
      if (this.payType === "苹果") {
        sum = this.info.packPrice;
      }
      if (sum > 0 && this.exchangeActivity && this.showExchange) {
        sum += 9.9
      }
      sum = sum > 0 ? toFixed(sum) : "0.00";
      return sum;
    },
    getCouponName() {
      const obj = { name: "", alias: "", on: false, dis: false };
      if (this.loading) {
        obj.alias = "未选择";
      } else if (this.lockCoupon) {
        obj.alias = "不可用";
        obj.dis = true;
      } else if (this.info.coupon) {
        obj.name = this.info.coupon.couponName;
      } else if (this.couponList && this.couponList.length) {
        obj.alias = this.couponList.length + "个可用";
        obj.on = true;
      } else {
        obj.alias = "无可用";
        obj.dis = true;
      }
      return obj;
    },
    getBalance() {
      return this.info.balance;
    },
    couponId() {
      return this.info.coupon ? this.info.coupon.voucherId : null;
    },
    enabledCoupon() {
      return this.couponList && this.couponList.length;
    },
    id() {
      return this.$route.params.id;
    },
    businessId() {
      return this.$route.query.id;
    },
    brandId() {
      return this.$route.query.brandId;
    },
    orderNo() {
      return this.$route.query.orderNo;
    },
  },
  watch: {
    entity(res) {
      // 常规信息
      this.couponList = res.unconsumeVoucherResList || [];
      this.info.orderNo = this.orderNo;
      this.info.price = parseFloat(res.price);
      this.info.packPrice = parseFloat(res.price);
      // 优惠券信息
      if (this.couponList && this.couponList.length) {
        const coupon = this.couponList[0];
        this.info.coupon = coupon;
        this.info.discount = parseFloat(this.info.coupon.discountAmount);
      }
      if (this.isIOS) {
        // 2022-03-18新增逻辑：IOS 171及以上版本 课程只支持苹果原生支付，并且不能显示支付方式，IOS301及以上版本，所有支付方式只指出苹果原生支付，并且不能显示支付方式
        if(window._appCode < 301 || window._appCode >= 310) {
            this.payList = ["支付宝", "微信"];
            this.payType = "支付宝";
            this.showPayMode = true;
        } else {
            this.payList = ["苹果"];
            this.payType = "苹果";
            this.showPayMode = false;
        }
      }
      // 套餐包信息
      this.reportData.questionPkg = res.questionPkg || {};
      this.reportData.inputList = res.inputList || {};
      this.reportData.reportName = res.reportName;
      // 设置默认支付方式
      this.$nextTick(() => {
        this.info.packPrice = parseFloat(res.questionPkg.price); // 设置价格
        this.setPayType(this.payType); // 默认支付宝
      });
    },
  },
  created() {
    // 设置客户端类型
    if (window.isIOS) {
      this.isIOS = true;
      this.client = 1;
    } else if (window.isAndroid) {
      this.isAndroid = true;
      this.client = 2;
    }
    // 业务ID
    if (!this.id) {
      this.$router.push("/");
      return;
    }

    // 查询支付信息
    this.HTTP.checkout2(this.businessId, this.orderNo, this.id, this.$route.meta.id)
      .then((res) => {
        console.log("pay res:", res);
        this.entity = res;
        this.loading = false;
        // 从我的订单里面进入不允许切换 换购状态
        if (this.$route.query.orderNo && res.exchangeActivity) {
          this.exchangeActivity = true
        }
      })
      .catch((e) => {
        this.$toast("网络不稳定，正在重试！");
        this.timer = setTimeout(() => {
          location.reload();
        }, DELAY_TIME * 2.5);
      });
  },
  beforeMount() {
    // 新增回退跳出确认弹窗的接口
    const backMsg = {
      msg: "订单已生成，确认放弃付款？",
      leftButtonText: "暂时放弃",
      rightButtonText: "再想想",
    };
    this.JSBridge.callHandler("backDoubleCheck", JSON.stringify(backMsg));
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 注册支付回调
    this.JSBridge.registerHandler("paymentResult", (data) => {
      // 安卓端需要进行转义
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
      if (typeof data === "object") {
          console.log(data.state, "state")
        switch (data.state) {
          case 1: {
            // 支付成功
            if (this.businessId) {
              // 购买套餐后再解锁报告
              let timer = 0;
              const checkPolling = () => {
                const errorHandler = function (e) {
                  clearTimeout(timer);
                  this.$toast("网络错误，正在重试！");
                  setTimeout(() => {
                    location.reload();
                  }, DELAY_TIME);
                };
                this.HTTP.checkPackage(this.businessId, this.goods.order)
                  .then((res) => {
                    console.log("套餐状态", res);
                    if (res) {
                      this.goods.id = res.userStdQuestionPkgItemId;
                      clearTimeout(timer);
                      this.createBusiness(); // 发起解锁
                    } else {
                      timer = setTimeout(checkPolling, DELAY_TIME);
                    }
                  })
                  .catch(errorHandler);
              };
              this.$toast("正在完成支付，请稍候...");
              checkPolling();
            } else {
              // 购买套餐下次再用
              this.nextPage();
            }
            break;
          }

          case 8:
            // 取消
            {
            this.$toast("已取消支付，您可以在订单界面重新进行支付！");
            setTimeout(() => {
              this.waiting = false;
              this.canClick = true
              this.swRouter("/h5/#/orders");
            }, DELAY_TIME);
            break;
          }

          case 5: // 兼容微信
          case 6: // 余额不足
          case 7: // 超时
          default:
            // 支付失败
            this.$toast("支付失败，请稍候重试！");
            setTimeout(() => {
              this.waiting = false;
              this.canClick = true
            }, DELAY_TIME);
        }
        return; // 终止
      }
      this.$toast("发生错误，请稍候重试！");
      this.waiting = false;
      this.canClick = true
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 是否换购
    selectChangeActivity() {
      if (this.orderNo) {
        return
      }
      this.exchangeActivity = !this.exchangeActivity
    },
    onTapClick() {
        if(this.canClick) {
            this.onTap()
            this.canClick = false
        }
    },
    onTap() {
      this.waiting = true;
      if (!window.isClient) {
        this.$toast("暂不支持网页版支付");
        setTimeout(() => {
          this.waiting = false;
        }, DELAY_TIME);
        return;
      }
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.TAP_PAY);
      const handler = (res) => {
        console.log("套餐包: ", res);
        // 埋点上报 Begin
        // 优惠券
        if (this.couponId) {
          this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_COUPON);
          // 订单生成后会占用已使用的优惠券 - hooks
          // this.couponList = this.couponList.filter(item => {
          //   return item.voucherId !== this.couponId;
          // });
          // this.info.coupon = null;
          // this.info.discount = 0;
        } else {
          this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_NO_COUPON);
        }
        // 支付方式
        if (this.payMode === "ALIPAY_MOBILE") {
          this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_ALI);
        } else if (this.payMode === "WX_APP") {
          this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_WX);
        } else if (this.payMode === "APPLE_IN") {
          this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_APPLE);
        }
        // 埋点上报 End

        if (res.status === 2) {
          // 抵扣订单
          console.log("完成抵扣...");
          if (this.businessId) {
            this.HTTP.checkPackage(this.businessId, res.orderNo)
              .then((response) => {
                if (response) {
                  this.goods.id = response.userStdQuestionPkgItemId;
                  this.goods.order = res.orderNo;
                  this.createBusiness(); // 发起解锁
                }
              })
              .catch(() => {
                this.$toast("网络错误，正在重试！");
                setTimeout(() => {
                  location.reload();
                }, DELAY_TIME);
              });
          } else {
            this.waiting = false;
            this.nextPage();
          }
        } else {
          this.goods.order = res.orderNo;
          console.log("payCall", this.payCall)
          this.callPayment(res.orderNo, res.payResult, this.payCall);
        }
      };
      const errorHandler = (e) => {
        console.error(e);
        this.$toast("支付时发生错误，请稍候重试！");
        setTimeout(() => {
          this.waiting = false;
        }, DELAY_TIME);
      };
      this.HTTP.createPackage(this.payMode, this.id, this.client, this.couponId, this.businessId, this.orderNo, this.exchangeActivity).then(handler).catch(errorHandler);
    },
    // 套餐包解锁报告
    createBusiness() {
      this.waiting = true;
      this.HTTP.createBusiness(this.goods.order, this.businessId, this.couponId, this.client, this.goods.id, this.businessId)
        .then((res) => {
          console.log("完成解锁", res);
          if (res.status === 2) {
            this.waiting = false;
            // 解锁报告
            this.nextPage();
          }
        })
        .catch((e) => {
          setTimeout(() => {
            this.waiting = false;
          }, DELAY_TIME);
        });
    },
    callPayment(id, data, payCall) {
      if (window.isClient) {
        this.JSBridge.callHandler(
          payCall || this.payCall,
          {
            id,
            data,
          },
          (st) => {
            if (!st) {
              this.$toast("未安装客户端，调起支付失败！");
              setTimeout(() => {
                this.waiting = false;
              }, DELAY_TIME);
            }
            console.log("调起支付...end");
          }
        );
      } else {
        this.$toast("暂不支持网页版支付");
        setTimeout(() => {
          this.waiting = false;
        }, DELAY_TIME);
      }
    },
    showCoupon() {
      if (this.lockCoupon || this.info.orderNo || !this.couponList.length) return;
      this.showCouponPicker = true;
    },
    setCoupon(item) {
      if (this.info.coupon === item) {
        this.info.coupon = null;
        this.info.discount = 0;
      } else {
        this.info.coupon = item;
        this.info.discount = parseFloat(item.discountAmount);
      }
    },
    setPayType(type) {
      const map = this.payMap[type];
      this.payType = type;
      if (!this.showExchange) {
        // 状态不是微信支付宝的时，取消勾选换购
        this.exchangeActivity = false
      }
      this.payMode = map.mode;
      this.payCall = map.call;
      this.info.unit = map.unit;
    },
    nextPage() {
      if (this.businessId) {
        // 电商详情版-使用一次
        this.swRouter("/newSuccess?orderNo=" + this.goods.order);
      } else {
        // 电商详情版-以后再用
        if (this.brandId) {
          switch (parseInt(this.brandId)) {
            case 10001:
              this.swRouter("/success?view=newPayment&id=" + this.brandId);
              break;
            case 10026:
              this.swRouter("/success?view=sitePayment&id=" + this.brandId);
              break;
          }
        } else {
          // 套餐包购买
          this.swRouter("/success?view=package&stdId=" + this.id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.pay {
  position: relative;
  min-height: 100vh;
  background-color: $c-pageBg2;

  .change-buy {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 4vw;
    left: 0;
    right: 0;
    // bottom: $footerHeight;
    top: -9.33vw;
    height: 9.33vw;
    background: url('/static/img/double-eleven/pica_bg.png') no-repeat;
    font-size: 3.467vw;
    color: #333;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
    > p {
      display: flex;
      align-items: center;
      line-height: 5.8vw;
      em {
        font-style: normal;
        color: #FF5900;
      }
    }
    .tag {
      height: 4.8vw;
      line-height: 5.2vw;
      margin-left: 3.33vw;
      margin-right: 2.13vw;
      font-size: 2.66vw;
      display: inline-block;
      background: #333333;
      padding: 0 1.6vw;
      color: #FFE6C5;
    }
  }
  .checked-item {
      width: 4vw;
      height: 4vw;
      font-size: 0;
      border-radius: 50%;
      background: white url(/static/icon/unselect@2x.png) no-repeat center center;
      background-size: 4vw 4vw;

      &.checked {
          background: url(/static/icon/success.png) no-repeat center center;
          background-size: 3.8vw 3.8vw;
      }
      &.disabled {
        opacity: 0.5;
      }
  }

  #{&}--view {
    padding: $padding_4;

    .flex-ul {
      display: flex;
      flex-flow: row nowrap;
      .li-left {
        flex: 0 0 22.67vw;
        .goods-img {
          width: 20vw;
          height: 20vw;
        }
      }
      .li-right {
        flex: 1;
        .right-top {
          max-width: 63vw;
          height: 5.87vw;
          line-height: 5.87vw;
          font-size: 4.27vw;
          color: #1d2233;
          font-weight: bold;
          margin-top: 0.53vw;
        }
        .right-mid {
          height: 6.4vw;
          line-height: 6.4vw;
          font-size: 3.73vw;
          color: #ff5900;
          font-weight: bold;
          margin-top: 1.33vw;
          .i {
            display: inline-block;
            height: 4.27vw;
            line-height: 4.27vw;
            font-size: $font_267;
            color: #fff;
            padding: 0 1.33vw;
            background: #ff5900;
            border-radius: 1px 6px 1px 6px;
            margin-left: 1.07vw;
            vertical-align: middle;
          }
        }
        .right-bottom {
          height: 4.8vw;
          line-height: 4.8vw;
          font-size: 3.47vw;
          color: #333;
          font-weight: bold;
          margin-top: 0.8vw;
        }
      }
    }

    .box-explain {
      font-size: 3.2vw;
      color: #999;
      margin: 3.2vw 0;
      line-height: 4.8vw;
    }

    .box-card {
      border-radius: 1.33vw;
      padding: 3.2vw;
      background: #f8f8f9;
      .card-title {
        line-height: 4.8vw;
        font-size: 3.73vw;
        color: #1d2233;
        font-weight: bold;
      }
      .card-flex {
        display: flex;
        align-items: center;
        font-size: 3.2vw;
        color: #1d2233;
        margin-top: 2.13vw;
        .left {
          flex: 1;
          color: #8e9099;
          margin-left: 1.07vw;
        }
        .right {
          flex: 3;
        }
      }
    }

    .s-subtitle {
      margin: 0;
      padding: $padding_3;
    }
    .l-panel {
      position: relative;
      padding: $padding_3;

      &.nop {
        padding: 0;
      }
      h3 {
        font-size: $font_400;
        line-height: $font_560;
      }
      .line {
        margin: $padding1 0 $margin;
      }
      .info {
        color: $c-info;
        font-size: 2.93vw;
      }
    }
  }

  #{&}--coupon {
    h3 {
      font-size: $font_480;
      line-height: $font_667;
    }
    .coupons {
      height: 70vw;
      overflow: auto;
      margin: $margin1 0;
    }
  }

  #{&}--coupon-view {
    @include flexColumn;
    padding: 0 $padding * 0.75 0 $padding;
    height: 12vw;
    background-color: #fdfdfd;
    box-shadow: inset 0 -1px 0 0 rgba(#edf1fb, 0.5);
  }
  .coupon__detail {
    width: 100%;
    height: 5.33vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @include font($c-text, $font_320, normal);

    &.on {
      background: url(/static/icon/arrow_right_gray.png) no-repeat right center;
      background-size: 5.33vw auto;
      padding-right: 5.33vw;
    }
    &.disabled {
      padding-right: 0;
      background: none;

      .coupon__name {
        display: none;
      }
      .s-subtitle,
      .coupon__num,
      .coupon__unit {
        color: #ccc;
      }
    }
    .s-subtitle {
      padding: 0;
    }
    .coupon__detail_left {
      display: flex;
      align-items: center;
    }
    .coupon__name {
      @include flexColumn;
      height: 4vw;
      max-width: 32vw;
      margin-left: 2.2vw;
      padding: 0 1vw;
      line-height: normal;
      font-size: 2.93vw;
      border-radius: 2px;
      color: $c-notice;
      box-shadow: 0 0 0 1px $c-notice;
      box-sizing: border-box;
      em {
        display: block;
        width: 100%;
        font-style: normal;
        @include singleLine;
      }
    }
    .coupon__w {
      text-align: right;
      display: flex;
      align-items: center;
      .on {
        color: $c-main;
      }
      .dis {
        color: #cccccc;
      }
    }
    .coupon__num {
      font-size: 3.4vw;
      font-weight: 600;
      color: $c-notice;
    }
    .coupon__unit {
      font-size: 3vw;
      color: $c-notice;
    }
  }

  #{&}--footerBar {
    .view {
      box-sizing: border-box;
      padding: $headerMargin $padding;
      width: 100%;
      height: $footerHeight;
      ::v-deep .van-col {
        height: 100%;
      }
    }
    .label {
      color: $c-info;
      font-size: $font_267;
      left: $font_373;
      padding-top: 0.5vw;
    }
    .price {
      font-size: $font_480;
      line-height: $font_653;
      font-weight: bold;
      color: $c-notice;
    }
    ::v-deep sub {
      font-size: $font_320;
      vertical-align: bottom;
    }
    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      line-height: normal;
    }
  }

  .s-tips {
    margin-top: 5.87vw;
    font-size: $font_320;
    color: $c-gray1;
    &.s-tips_inline {
      margin-top: 0;
      padding: 0 $padding_3 $padding_3;
    }
  }
}

::v-deep .van-dialog__content {
  background: url(/static/img/coupon_rule.jpg) no-repeat center top;
  background-size: contain;
  padding: $padding;

  h1 {
    font-size: 6.4vw;
    text-align: center;
    padding: 8vw 0;
  }
  .content {
    margin-top: $padding;
    margin-bottom: $padding;

    p {
      color: $c-info;
      font-size: $font_347;
      line-height: $font_587;
      margin-top: $margin;
      text-align: center;
    }
  }
}
</style>
