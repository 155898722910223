<template>
  <div class="charts">
<!--    <StateBar bgcolor="white" />-->
<!--    <TitleHeader :title="title" color="#347fff" :back="showBack" :class="{ sub: showSubTitle }">-->
<!--      <h6 v-if="showSubTitle" slot="right" class="subTitle">参谋告诉你答案</h6>-->
<!--    </TitleHeader>-->

    <div class="charts--view">
      <iframe ref="view" :src="url" frameborder="0" class="innerView">不支持内嵌窗口</iframe>
    </div>

    <LoadingOverlay :show="loading" />
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import { setLocalStorage } from '../../utils';
import UniUtils from '@/utils/uni';
let _timer = 0;

export default {
  name: "mall-report",
  components: {},
  data() {
    return {
      title: "正在加载",
      loading: true, // 数据加载
      subTitle: "---",
      text: "解锁深度报告",
      showBack: true,
      showMenu: true,
      showSubTitle: false,
      showCatalog: false,
      showContinue: true, // 是否显示解锁按钮（特殊报告）
      isReady: false, // 界面已就绪
      isSended: false, // 已下发数据
      isClient: false,
      lastClick: "cover", // 当前页码
      needLocation: false, // 是否需要定位

      id: 0, // 报告ID
      stdId: 10030, // 类型ID
      menus: [], // 菜单列表
      pageMap: "一,二,三,四,五,六,七,八,九,十,十一,十二,十三,十四,十五,十六,十七,十八,十九,二十".split(","), // 序号表
      first: false, // 首次访问
      host: "/report/?id=",
      benefit: null, // 用户权益
      entity: null, // 报告实体
      isFirstOpenReportInV2_0_0: false, // 首次进入页面标识
      showSaveBtn: true
    };
  },
  methods: {
    onTap() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.REPORT.TAP_UNLOCK);
      this.swRouter("/pay/" + this.id);
    },
    JumpToCustomer() {
      if (this.entity == null) {
        return;
      }
      // 需要带上当前的地址参数
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "PICK_LOCATION",
        id: 10005,
        params: this.entity.input,
      });
    },
    onAdd() {
      // this.HTTP.reportSave(this.id).finally((res) => {
      //   this.entity.payed = 1;
      //   this.$toast("保存成功！前往“我的报告”查看。");
      // });
      if (!this.entity.userBenefitResp.member) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "开通会员可下载PDF文件",
            confirmButtonText: "查看会员权益",
            confirmButtonColor: "#347FFF",
            cancelButtonText: "取消",
          })
          .then(() => {
            // on confirm
            if (window.isClient) {
              this.swRouter("/h5/#/member");
            } else if (window.isMini) {
              UniUtils.goMiniVipPage()
            }   else {
              this.$toast("请下载APP使用该功能！");
            }
          })
          .catch(() => {
            // on cancel
          });
        return
      }
      this.HTTP.saveReport(this.id)
        .finally((res) => {
          if(this.showSaveBtn) {
            this.entity.saveStatus = 1;
            this.showSaveBtn = false
            this.$toast("保存成功！前往“我的报告”查看。");
          }
        })
        .catch((e) => {
          this.$toast("保存失败，请检查网络状态。");
        });
    },
    gotoMyReport() {
      // 先检查是否已经生成了pdf
      if (!this.entity) return;
      // this.swRouter("/h5/#/my/reports?index=6");
      if (this.entity.userBenefitResp.member == true) {
        this.$dialog
          .alert({
            title: "提示",
            message: "PDF文件生成中，请耐心等待。注意查收消息推送及短信提醒。文件生成成功，将出现“下载”按钮，点击“下载”按钮，完成下载。",
            confirmButtonColor: "#347FFF",
          })
          .then(() => {
            // on confirm
            this.HTTP.downloadCheck(this.entity.id)
              .then((res) => {
                // console.log('download-check:', res);
                this.swRouter("/h5/#/my/reports?index=6");
              })
              .catch((e) => {
                this.swRouter("/h5/#/my/reports?index=6");
              });
          });
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "开通会员可下载PDF文件",
            confirmButtonText: "查看会员权益",
            confirmButtonColor: "#347FFF",
            cancelButtonText: "取消",
          })
          .then(() => {
            // on confirm
            if (window.isClient) {
              this.swRouter("/h5/#/member");
            } else if (window.isMini) {
              UniUtils.goMiniVipPage()
            } else {
              this.$toast("请下载APP使用该功能！");
            }
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    jumpTo(id) {
      this.showCatalog = false;
      this.lastClick = id;
      this.viewTarget.postMessage({ type: "scroll", content: id }, "*");
    },
    onDataQuery(params) {
      if(sessionStorage.getItem("platform")) {
        return
      }
      if (window.isClient) {
        if (window._appCode && window._appCode >= 160) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "SURROUNDING_DATA",
            id: this.id,
            params: JSON.stringify(params),
          });
        } else {
          this.$toast("APP版本过低，请升级以获取更好的体验！");
        }
      } else {
        this.$toast("请下载APP进行查看！");
      }
    },
    onAroundSearch() {
      this.HTTP.createDataQuary()
        .then((res) => {
          this.swRouter({ path: `/pay/${res}`, query: { id: this.id } });
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍候重试。");
        });
    },
    switchShare(st) {
      // console.log( 'switchShare:', st );
      // 排除 10002市调报告 10014查周边
      // if (this.entity && ![10002, 10014].includes(this.stdId)) {
      //   this.JSBridge.callHandler(
      //     "switchShareBtnV2",
      //     st
      //       ? {
      //         code: "REPORT",
      //         id: this.id,
      //       }
      //       : false
      //   );
      // }
      // 市调及解锁报告显示分享按钮 排除10014查周边
      if (this.entity && ([10002].includes(this.stdId) || this.unlock) && ![10014].includes(this.stdId)) {
        this.JSBridge.callHandler(
          "switchShareBtn",
          st
            ? {
              type: "normal",
              id: this.id,
              check: false,
            }
            : false
        );
      }
      // 市调/品牌洼地 报告不显示分析按钮
      // if ( ! this.entity || [ 10002, 10004 ].includes( this.entity.stdQuestionId ) ) return;
      // this.JSBridge.callHandler( 'switchShareBtn', st ? { id: this.id, type: 'normal', check: [ 10003 ].includes( this.entity.stdQuestionId ) !== true } : false );
    },
    openCatalog() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.REPORT.TAP_CATALOG);
      this.showCatalog = true;
    },
    jumpToApp(idx, params) {
      if(sessionStorage.getItem("platform")) {
        return
      }
      if (window.isClient) {
        if (window._appCode && window._appCode >= 203) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "BRAND_INTRODUCTION",
            id: idx,
            params: params ? params : this.entity.input,
            trackData: { moduleId: "20400", btnId: "20401" },
          });
        } else {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "PICK_LOCATION",
            id: idx,
            params: params ? params : this.entity.input,
          });
        }
      } else {
        this.$toast("请下载APP进行查看！");
      }
    },
    addListener(e) {
      switch (e.data.type) {
        case "ready": {
          this.isReady = true;
          this.sendData();
          break;
        }
        case "switchFullscreen": {
          this.showMenu = !e.data.content;
          this.switchShare(this.showMenu);
          break;
        }
        case "scrollToLock": {
          this.showContinue = !e.data.content;
          break;
        }
        case "tapSearch": {
          // console.log( 'tapSearch:', e.data.content );
          this.onDataQuery(e.data.content);
          break;
        }
        case "openVip": {
          if(sessionStorage.getItem("platform")) {
            return
          }
          if (window.isClient) {
            if (window._appCode >= 401) {
              this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,//6 H5  5原生页面
                url: 'DIALOG',
                id: 'h5_' + this.stdId,
                params: JSON.stringify({"type":4})
              });
            } else {
              this.swRouter("/h5/#/member");
            }
          } else if (window.isMini) {
            UniUtils.goMiniVipPage()
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break;
        }
        case "tapContinue": {
          if(sessionStorage.getItem("platform")) {
            return
          }
          if (this.stdId === 10014 || this.stdId === 10030 || this.stdId === 10032) {
            if (e.data.content === "btn_1") {
              this.onDataQuery(null);
            } else if (e.data.content === "btn_2") {
              this.onTap(); // 购买报告
              // 跳转到客群条件输入页
              // this.JumpToCustomer();
            } else if (e.data.content == "btn_3") {
              this.onAroundSearch();
            } else if (e.data.content === "example") {
              if (window.isClient) {
                this.JSBridge.callHandler("callFileViewer", {
                  type: "application/pdf",
                  id: 47179077493335,
                  name: "报告示例",
                  url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20210127/1611741976671.pdf",
                });
              } else {
                this.$toast("请下载APP进行查看！");
              }
            } else if (e.data.content === "unlock") {
              if (window.isClient) {
                if (window._appCode && window._appCode >= 203) {
                  this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "BRAND_INTRODUCTION",
                    id: 10001,
                    params: null,
                    trackData: { moduleId: "20400", btnId: "20401" },
                  });
                } else if (window._appCode && window._appCode >= 200) {
                  this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "PICK_LOCATION",
                    id: 10001,
                    params: this.entity.input || null,
                  });
                } else if (window._appCode && window._appCode >= 160) {
                  this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CARD",
                    id: 10001,
                    params: this.entity.input || null,
                  });
                } else {
                  this.JSBridge.callHandler("gotoPage", "card_10001");
                }
              }
            } else if (e.data.content == "business") {
              // 商圈
              this.jumpToApp("10011");
            } else if (e.data.content == "customer") {
              // 客群
              this.jumpToApp("10005");
            } else if (e.data.content == "compete") {
              // 竞争
              this.jumpToApp("10006");
            } else if (e.data.content == "hideGuide") {
              this.JSBridge.callHandler(
                "putString",
                JSON.stringify({
                  key: "isFirstOpenReportInV2_0_0",
                  value: "1",
                })
              );
            }
          } else if (this.stdId === 10015) {
            // 找商机-直接跳转到找品牌列表
            this.swRouter("/brand/discovery");
          } else if (!this.unlock) {
            this.onTap();
          } else {
            this.$toast("发生未知错误！");
          }
          break;
        }
        case "tapUnlock": {
          this.onTap();
          break;
        }
        // 跳转至选址分析
        case "jumpTo-siting": {
          if (window.isClient) this.JSBridge.callHandler("gotoPage", "card_10001");
          break;
        }
        // 跳转至
        case "jumpTo-brandjoin": {
          const brand = e.data.content[1];
          if (brand) {
            // 保存数据到本地存储
            this.$store.commit("setTemp", this.entity);
            this.swRouter({ path: "/card/D", query: { brandCode: brand.value.code, brandName: brand.value.text } });
          }
          break;
        }
        // 热力图
        case "hotChart": {
          const result = e.data.content;
          switch (result.type) {
            case "business":
              // 商圈数据
              this.jumpToApp("10011", result.input);
              break;
            case "compete":
              // 竞争分析
              this.jumpToApp("10006", result.input);
              break;
            case "customer":
              // 客群数据
              this.jumpToApp("10005", result.input);
              break;
            case "siteAssessment":
              // 选址综合评估
              if (window._appCode && window._appCode >= 200) {
                this.jumpToApp("10001", result.input);
              } else {
                this.JSBridge.callHandler("gotoPage", "card_10001");
              }
              break;
            case "switchBackBtn":
              // 隐藏、显示返回按钮
              this.showBack = result.input;
              // 隐藏、显示菜单按钮
              this.showMenu = result.input;
              break;
          }
          break;
        }
        // 商场查询-查看更多店铺
        case 'lookMoreMall':
          if (window.isClient) {
            let params = e.data.content.content;
            console.log('params', params)
            setLocalStorage('mallList', params)
            this.swRouter("/rank/mall?type=mallList");
          } else {
            this.$toast("请下载APP进行查看！");
          }
          break
        // 通用的查看更多页面
        case 'lookMorePage':
          let params = e.data.content.content;
          setLocalStorage('tableData', params)
          this.swRouter("/rank/page");
          break
      }
    },
    sendData() {
      // 已发送或未就绪或未得到数据将不调用下发
      if (this.isSended || !this.isReady || !this.entity) return;
      this.isSended = true; // 标记为已下发
      clearTimeout(_timer); // 清理超时提醒
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.viewTarget.postMessage({ type: "data", content: this.entity }, "*");
    },
    tapInterpret() {
      if (!window.isClient) {
        this.$toast("非客户端环境！");
        return;
      }
      if (window._appCode >= 175) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: "ONLINE_SERVICE",
          params: JSON.stringify({
            cardCode: "CUSTOMER_REPORT",
            cardId: this.id,
            title: "专家解读",
            type: 4,
          }),
        });
      } else {
        this.JSBridge.callHandler(
          "callLiveChat",
          {
            type: "report",
            data: {
              id: this.id,
            },
          },
          (st) => {
            // console.log( st );
          }
        );
      }
    },
    onLivechat() {
      if (!this.isClient) {
        this.$toast("非客户端环境！");
        return;
      }
      if (window._appCode >= 175) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: "ONLINE_SERVICE",
          params: JSON.stringify({
            cardCode: "CUSTOMER_REPORT",
            cardId: this.id,
            title: "在线客服",
            type: 3,
          }),
        });
      } else {
        this.JSBridge.callHandler(
          "callLiveChat",
          {
            type: "normal",
            data: {},
          },
          (st) => {
            // console.log( st );
          }
        );
      }
    },
  },
  watch: {
    showCatalog(val) {
      this.switchShare(!val);
    },
    entity(e) {
      // 组装菜单数据
      // const list = [];
      // if (e.config.showCover) {
      //   list.push({
      //     id: "cover",
      //     name: "封面",
      //   });
      // }
      // if (e.config.showCatalog) {
      //   list.push({
      //     id: "catalog",
      //     name: "目录",
      //   });
      // }
      // if (e.config.showConclusion) {
      //   list.push({
      //     id: "conclusion",
      //     name: "概览",
      //   });
      // }
      // let index = 0;
      // for (const model of e.reportModelInstanceFullList) {
      //   const lock = !e.payed && model.type > 1;
      //   list.push({
      //     id: model.id,
      //     name: `${this.pageMap[index]}. ${model.name}`,
      //     isBgm: false,
      //     lock,
      //   });
      //   index++;
      //   if (model.hasOwnProperty("bgmInstanceFullList") === false) continue;
      //   for (const bgm of model.bgmInstanceFullList) {
      //     let name = bgm.name.split("-");
      //     name = name[name.length - 1];
      //     list.push({
      //       id: bgm.bgmId,
      //       name,
      //       isBgm: true,
      //       lock,
      //     });
      //   }
      // }
      // this.menus = list;
      this.benefit = e.userBenefitResp;
      // console.log( 'switchShare entity' );
      this.switchShare(true);
    },
  },
  computed: {
    unlock() {
      return this.entity && (this.entity.payed || this.entity.priceFen === 0);
    },
    ctrMenu() {
      return this.entity && [10001].includes(this.entity.stdQuestionId);
    },
    livechat() {
      return this.unlock && [10001, 10005, 10006, 10011].includes(this.entity.stdQuestionId);
    },
    livechat2() {
      return this.entity && [10014].includes(this.entity.stdQuestionId);
    },
    url() {
      if (!this.entity) return "about:blank";
      return this.host + this.id;
      // return 'http://10.1.1.69:9002/' + this.id;
    },
    viewTarget() {
      return this.$refs.view.contentWindow || this.$refs.view.contentDocument;
    },
    canSaveOrDownload() {
      const ids = [10030, 10031, 10032, 10037];
      if (!this.entity) {
        return false;
      }
      if (ids.includes(this.entity.stdQuestionId)) {
        return true;
      }
      return false;
    },
    showDownLoad() {
      if(sessionStorage.getItem("platform")) {
        return false
      } else {
        return this.entity.saveStatus
      }
    }
  },
  created() {
    // 获取报告ID
    const id = this.$route.params.id;
    if (!id) {
      this.$toast("无效报告信息，即将返回首页！");
      this.$router.push("/");
      return;
    }
    this.first = this.$route.query.first || false;

    // 获取报告地址
    this.host = this.HTTP.getReportUrl();
    const entityHandler = (res) => {
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      console.log("entity:", res);
      let { aroundBgm, basicBgm, featureBgm, passengerFlowBgm } = res
      res.aroundBgm = JSON.parse(aroundBgm)
      res.basicBgm = JSON.parse(basicBgm)
      res.featureBgm = JSON.parse(featureBgm)
      res.passengerFlowBgm = JSON.parse(passengerFlowBgm)
      this.title = res.basicBgm.marketInfoData.name || "阅读报告";
      // 是否需要定位
      const location = this.needLocation;
      // 是否定制报告
      // const isCustom = [10001, 10002, 10003, 10004, 10005, 10006, 10011, 10014, 10015, 10018, 10019, 10020, 10021, 10022].includes(stdId);
      this.showSubTitle = true;
      // 扩展字段
      res["config"] = {
        isCustom: false, // 是否定制报告
        isShare: false, // 是否来自分享
        showTitle: false, // 是否显示报告标题
        showHeader: this.first, // 是否显示报告头
        showConclusion: true, // 是否显示报告结论
        showCover: false, // 是否显示报告封面 支持url
        showInputs: false, // 是否显示报告参数
        showCatalog: false, // 是否显示报告目录
        isShowEle: true, // 是否显示饿了么广告位
        templateId: 10030,
        tokenId: null,
        isNewNeay: window._appCode >= 200,
        isFirstGuide: false, // 首次打开
        location: location,
        showWm: window._appCode >= 302, // 显示外卖sku广告位
        platformEnum: {
          isClient: !!window.isClient,
          isAndroid: !!window.isAndroid,
          isIOS: !!window.isIOS,
          isIPX: !!window.isIPX,
          isIPMX: !!window.isIPMX
        }
      };
      // 保存修改后的数据实体
      this.entity = res;
      // 设置超时并下发数据
      _timer = setTimeout(() => {
        this.$toast("无法获取报告，请稍候重试！");
        this.loading = false;
      }, 30000);
      this.sendData();
    };
    const entity = this.$store.getters.temp;
    if (entity && id === "" + entity.id) {
      this.$store.commit("setTemp", null);
      entityHandler(entity);
    } else {
      // 加载网络数据
      this.HTTP.getMallReportDetail(id)
        .then((res) => {
          console.log(res,'res')
          entityHandler(res);
        })
        .catch((e) => {
          console.log('e', e)
          this.$toast("暂无数据");
          setTimeout(() => {
            this.JSBridge.callHandler("onBackPressed");
          }, 2000)
        });
    }
    // 添加通信监听
    window.addEventListener("message", this.addListener);
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 设置客户端类型
    this.isClient = window.isClient || true;
    this.JSBridge.callHandler("getString", "isFirstOpenReportInV2_0_0", (data) => {
      // 将数据转换后发送到处理方法
      if (data) {
        if (typeof data === "string") {
          data = JSON.parse(data); // 兼容安卓数据解析
        }
        this.isFirstOpenReportInV2_0_0 = !!data;
      }
    });

    // 开通会员后更新数据
    this.JSBridge.registerHandler("onAppResume", (data) => {
      if (localStorage.getItem('updateVipInfo')) {
        localStorage.setItem('updateVipInfo','')
        location.reload()
      }
    })
  },
  beforeDestroy() {
    // 隐藏分享按钮
    // this.JSBridge.callHandler("switchShareBtnV2", false);
    this.JSBridge.callHandler("switchShareBtn", false);
  },
  destroyed() {
    // 移除通信监听
    window.removeEventListener("message", this.addListener);
    // 移除定时监听
    clearTimeout(_timer);
  },
  beforeRouteEnter(to, from, next) {
    // console.log( 'charts to:', to, ', from:', from );
    if (from.path === "/success") {
      // console.log( 'enter from success' );
      next((vm) => {
        vm.needLocation = true;
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.charts {
  overflow: hidden;
  height: 100vh;
  background-color: $c-pageBg2;

  ::v-deep .titleHeader {
    .view {
      &--r {
        width: 100%;
        top: 0;
        left: 0;
      }
      .subTitle {
        position: absolute;
        bottom: 2.5vw;
        left: 50%;
        color: #9aa9be;
        transform: translateX(-50%);
        font-weight: normal;
        font-size: $font_320;
      }
      .right {
        position: relative;
        @include flexColumn;
        width: 100%;
        height: $headerHeight;
        align-items: flex-end;

        .save {
          @include flexColumn;
          width: $headerHeight;
          height: 100%;
          color: $c-main;

          &.dis {
            color: $c-info;
          }
        }

        .tips {
          position: absolute;
          bottom: 0;
          right: 1.5vw;
          width: 30vw;
          height: 10vw;
          background: url(/static/img/tips_save.png) no-repeat left top;
          background-size: cover;
          transform: translateY(50%);
          z-index: 99;
        }
      }
    }
    &.sub {
      .title {
        padding-bottom: 5.75vw;
      }
    }
  }

  #{&}--view {
    overflow-y: hidden;
    height: 100%;
    .innerView {
      width: 100%;
      height: 100%;
    }
  }

  #{&}--icon {
    position: fixed;
    right: 0;
    bottom: 13vh;
    width: 14.4vw;
    height: 12vw;
    border-top-left-radius: 6vw;
    border-bottom-left-radius: 6vw;
    background-color: white;
    box-shadow: 0rem 1.33vw 4vw rgba(#33465f, 0.11);
    &::before {
      content: "";
      display: block;
      width: 10vw;
      height: 10vw;
      background: #283950 url(/static/icon/menu.png) no-repeat center center;
      background-size: cover;
      border-radius: 50%;
      margin: 1vw 0 0 1vw;
    }

    &.icon2 {
      bottom: 20vh;
      &::before {
        background: url(/static/icon/livechat.jpg) no-repeat center center, linear-gradient(#f4b66a, #fcca8d);
        background-size: cover;
      }
    }
  }
  #{&}--livechat {
    position: fixed;
    right: 0;
    bottom: 12vh;
    height: 12vw;
    width: 24vw;
    background: url(/static/img/report/livechat.png) no-repeat right top;
    background-size: cover;
  }
  #{&}--menu {
    position: relative;
    overflow: hidden;
    width: 65vw;
    height: 100vh;
    background-color: white;
    padding: 24.8vw 0 $padding;
    box-sizing: border-box;

    .header {
      position: absolute;
      top: 13.2vw;
      left: 0;
      width: 100%;
      padding: 0 $padding;
      box-sizing: border-box;
      box-shadow: 0 calc(5vw - 1px) 0 white, 0 5vw 0 $c-line;

      h1 {
        @include singleLine;
        font-size: $font_453;
        line-height: $font_653;
        font-weight: bold;
      }
      p {
        font-size: $font_320;
        line-height: $font_440;
        color: $c-info;
        margin-top: 1vw;
      }
    }
    .list {
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      li {
        position: relative;
        @include singleLine;
        padding: 0 $padding 0 $padding;
        height: 14.53vw;
        line-height: 14.53vw;
        font-size: $font_373;
        font-weight: bold;
        &.on {
          color: $c-main;
          background-color: #f8f8f9;
        }
        &.sub {
          padding-left: 12.4vw;
          font-weight: normal;
          &.lock {
            background: url(/static/icon/lock.png) no-repeat left $padding center;
            background-size: 5vw;
            color: $c-info;
          }
        }
        // &:after {
        //     content: attr( data-page );
        //     position: absolute;
        //     right: $margin;
        //     top: 0;
        //     color: $c-info;
        //     font-weight: normal;
        //     font-size: $font_320;
        // }
      }
    }
  }
  #{&}--footer {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;

    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      line-height: normal;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity transform 0.25s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(-75%);
    }
  }

  // 延迟隐藏动画
  .hid_delay_35 {
    animation: fadeOut 1s forwards 4s;
  }
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
}
</style>
