/**
 * 静态数据配置文件，区分不同的环境
 */
const env = process.env.NODE_ENV === 'production' ? 'production' : 'test'

const config = {
  'production': {
    pointRadarReportId: '261655554737213', // 点位雷达示例报告id（报告id）
    locationRecommendReportId: '137119887968002', // 位置推荐示例报告id（非报告id）
    openStoreReportId: '127568427276126', // 开店选址示例报告id
  },
  'test': {
    pointRadarReportId: '261505758507056', // 点位雷达示例报告id（报告id）
    locationRecommendReportId: '137108671165726', // 位置推荐示例报告id（非报告id）
    openStoreReportId: '5101493082348717', // 开店选址示例报告id
  }
}

export default config[env]
