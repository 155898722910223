<template>
  <div class="bars-chart">
    <baseMap @complete="complete"/>
  </div>
</template>

<script>
import { chartsFontSize } from '@/utils'
import baseMap from '@/components/baseMap'
export default {
  name: 'map',
  components: {
    baseMap
  },
  data() {
    return {
      map: null
    }
  },
  methods: {
    complete(map) {
      this.map = map
    }
  }
}
</script>

<style scoped>
</style>
