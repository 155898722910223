<template>
  <div class="introduce">
    <div class="header">
      <template v-if="isClient">
        <StateBar :bgcolor="bgColor" />
        <TitleHeader :title="title" :color="titleColor" :bgcolor="bgColor" :back="showBack" class="no-mr"> </TitleHeader>
      </template>
      <div v-else-if="!isMini" class="share">
        <p class="f-fw">上上参谋</p>
        <p>生意人的数据军师</p>
        <div class="app-wrap">
          <a ref="openApp" v-callapp="callappInfo" target="_blank" class="app">APP查看</a>
          <a ref="downloadApp" :href="downloadLink" target="_blank" class="app">APP下载</a>
        </div>
      </div>
    </div>

    <!-- 骨架屏占位图 -->
    <img v-if="loading" src="/static/skt/introduce.png" width="100%" />

    <AutoView
      ref="view"
      :header="false"
      :footer="true"
      class="introduce--view"
      @scroll.native="pageScrollHandle"
      :style="{ opacity: loading ? 0 : 1 }"
    >
      <div ref="top" class="introduce--banner">
        <template v-if="entity.bannerResList && entity.bannerResList.length > 0">
          <template v-if="entity.bannerResList.length > 1">
            <van-swipe class="swipe" :show-indicators="false" :loop="false">
              <van-swipe-item v-for="(img, i) of entity.bannerResList" :key="i">
                <van-image v-if="img.type == 'image'" class="img" fit="cover" :src="img.url" />
              </van-swipe-item>
            </van-swipe>
          </template>
          <template v-else>
            <van-image class="img" fit="cover" :src="entity.bannerResList[0].url" />
          </template>
        </template>
        <template v-if="entity.introduceImageList && entity.introduceImageList.length > 0">
          <div>
            <van-image v-for="(img, i) in entity.introduceImageList" :key="i" class="img" fit="cover" :lazy-load="i != 0" :src="img.url" />
          </div>
        </template>
        <template v-if="entity.bottomBannerList && entity.bottomBannerList.length > 0">
          <van-swipe class="swipe" :show-indicators="true" autoplay="3000" :loop="true">
            <van-swipe-item v-for="(img, i) of entity.bottomBannerList" :key="i">
              <van-image class="img" fit="cover" lazy-load :src="img.url" />
            </van-swipe-item>
          </van-swipe>
        </template>
        <template v-if="entity.companyImageList && entity.companyImageList.length > 0">
          <div>
            <van-image v-for="(img, i) in entity.companyImageList" :key="i" class="img" fit="cover" lazy-load :src="img.url" />
          </div>
        </template>
      </div>
    </AutoView>

    <!-- <div class="introduce--livechat" @click="onLivechat"></div> -->

    <!-- 定制底栏 -->
    <SoltFooter v-if="content && content.example" class="introduce--footerBar">
      <van-row class="view">
        <van-col span="11" v-if="!isMini">
          <van-button type="warning" block plain class="icon icon-detail" @click="onExample()">&emsp;报告示例</van-button>
        </van-col>
        <van-col span="2" v-if="!isMini"></van-col>
        <van-col :span="!isMini ? 11 : 24" style="position: relative">
          <van-button type="warning" block @click="onTap">立即评估</van-button>
          <p class="float-label">已有{{ getNum }}评估过</p>
        </van-col>
      </van-row>
    </SoltFooter>
    <SoltFooter v-else-if="id == 10009" class="introduce--footerBar">
      <van-row class="view">
        <van-col span="12">
          <p class="price" style="padding-top: 2.4vw">免费</p>
        </van-col>
        <van-col span="12">
          <van-button type="warning" block @click="showPicker = true">立即评估</van-button>
        </van-col>
      </van-row>
    </SoltFooter>
    <!-- 10012店铺订阅 -->
    <SoltFooter v-else-if="id == 10012" class="introduce--footerBar">
      <div class="view"></div>
    </SoltFooter>
    <!-- 10026开店位置推荐 -->
    <SoltFooter v-else-if="id == 10026" class="introduce--footerBar">
      <van-row class="view">
        <van-button type="warning" block @click="onTap">立即评估</van-button>
      </van-row>
    </SoltFooter>
    <!-- 通用底栏 -->
    <SoltFooter v-else class="introduce--footerBar">
      <van-row class="view">
        <van-col span="12">
          <p class="price">{{ getAmount }}<sub>/次</sub></p>
          <p class="label">已有{{ getNum }}人评估过</p>
        </van-col>
        <van-col span="12">
          <van-button type="warning" block @click="onTap">立即评估</van-button>
        </van-col>
      </van-row>
    </SoltFooter>

    <!-- 铺位测评-选项卡 -->
    <van-popup v-model="showPicker" round closeable position="bottom">
      <div class="l-panel f-vp introduce--subview">
        <h3>选择测评工具</h3>
<!--        <img src="/static/img/report/10009/enter_a.jpg" class="block" @click="swRouter('/assess/10009')" />-->
        <img src="/static/img/report/10009/enter_b.jpg" class="block" @click="goPage('#/assess/10007')" />
        <img src="/static/img/report/10009/enter_c.jpg" class="block" @click="goPage('#/assess/10008')" />
      </div>
    </van-popup>

    <!-- 全局加载遮罩-->
    <LoadingOverlay :show="loading" />

    <!-- mock -->
    <div id="openBowerTip">
      <span class="tip-icon"></span>
      <div class="tip-text">
        <h4>请点击右上角</h4>
        <p>选择"在浏览器或者Safari中打开"</p>
      </div>
    </div>
  </div>
</template>

<script>
import ShareMarker from "@/components/share/marker";
import { ImagePreview } from "vant";
import UniUtils from '@/utils/uni';

const REPORT_MAPPING = {
  10001: {
    banner: "",
    notitle: true,
    // labels: [ '市场理想性', '销售推动', '位置点', '区域竞争', '区域客群' ],
    list: [
      "/static/img/report/10001/banner.png",
      "/static/img/report/10001/a.png",
      "/static/img/report/10001/b.png",
      "/static/img/report/10001/c.png",
      "/static/img/report/10001/d.png",
      ["/static/img/report/10001/01.png", "/static/img/report/10001/02.png", "/static/img/report/10001/03.png"],
    ],
    example: {
      id: 47179077493335,
      url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20210127/1611741976671.pdf",
    },
    share: {
      title: "你身边的开店创业者都在用的选址利器",
      desc: "在线实时大数据，教你如何开好一家赚钱的店",
      cover: "/static/img/report/10001/cover.jpg",
    },
  },

  10003: {
    banner: "/static/img/report/10003/banner.jpg",
    labels: ["地域分布", "曝光率", "品牌评分", "客户反馈"],
    tabs: [
      { id: "a", name: "产品介绍" },
      { id: "b", name: "服务内容" },
      { id: "c", name: "案例展示" },
    ],
    list: [
      { id: "a", img: "/static/img/report/10003/a.jpg" },
      { id: "b", img: "/static/img/report/10003/b.jpg" },
      { id: "c", img: "/static/img/report/example.jpg" },
      ["/static/img/report/10003/1.jpg"],
      "/static/img/report/10003/c.jpg",
    ],
    share: {
      title: "防坑宝典：招商加盟必看！",
      desc: "选择上上参谋，小白也能大胆加盟",
      cover: "/static/img/report/10003/cover.jpg",
    },
  },

  10004: {
    list: [
      { id: "a", img: "/static/img/report/10004/a.png" },
      { id: "b", img: "/static/img/report/10004/b.png" },
      { id: "c", img: "/static/img/report/10004/c.png" },
    ],
    example: {
      id: 74385385124421,
      url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20210111/1610354080839.pdf",
    },
    share: {
      title: "数据告诉你，加盟店开在哪里更赚钱",
      desc: "为你推荐最适合开店的TOP3位置",
      cover: "/static/img/report/10004/cover.jpg",
    },
  },

  10005: {
    banner: "/static/img/report/10005/banner.jpg",
    notitle: true,
    list: [
      "/static/img/report/10005/a.jpg",
      { img: "/static/img/report/10005/b.jpg", preview: "/static/img/report/10005/demo.png" },
      "/static/img/report/10005/c.jpg",
      ["/static/img/report/10005/1.jpg", "/static/img/report/10005/2.jpg"],
    ],
    example: {
      id: 54846578627138,
      url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20201117/1605583857268.pdf",
    },
    share: {
      title: "智能AI算法告诉你，你的客源在何方？",
      desc: "3分钟知道周边客群的消费能力和偏好",
      cover: "/static/img/report/10005/cover.jpg",
    },
  },

  10006: {
    banner: "/static/img/report/10006/banner.jpg",
    notitle: true,
    list: [
      "/static/img/report/10006/a.jpg",
      { img: "/static/img/report/10006/b.jpg", preview: "/static/img/report/10006/demo.png" },
      "/static/img/report/10006/c.jpg",
      ["/static/img/report/10006/1.jpg", "/static/img/report/10006/2.jpg"],
    ],
    example: {
      id: 54846763287086,
      url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20201117/1605583908674.pdf",
    },
    share: {
      title: "看看500米内，谁是你最大的竞争对手",
      desc: "3分钟了解竞争环境，让你不再心慌慌",
      cover: "/static/img/report/10006/cover.jpg",
    },
  },

  10009: {
    title: "铺位测评",
    banner: "/static/img/report/10009/banner.jpg",
    labels: ["街边店铺", "商场店铺", "商业选址评估"],
    tabs: [
      { id: "a", name: "产品介绍" },
      { id: "b", name: "使用流程" },
    ],
    list: [
      { id: "a", img: "/static/img/report/10009/a_01.jpg" },
      "/static/img/report/10009/a_02.jpg",
      "/static/img/report/10009/a_03.jpg",
      { id: "b", img: "/static/img/report/10009/b.jpg" },
    ],
    share: {
      title: "店铺评估助手，大数据结合选址经验量化评估指标，选址不用愁！",
      desc: "店铺智能评估助手",
      cover: "/static/img/report/10009/cover.jpg",
    },
  },

  10011: {
    banner: "/static/img/report/10011/banner.jpg",
    notitle: true,
    list: [
      "/static/img/report/10011/a.jpg",
      { img: "/static/img/report/10011/b.jpg", preview: "/static/img/report/10011/demo.png" },
      "/static/img/report/10011/c.jpg",
      ["/static/img/report/10011/1.jpg", "/static/img/report/10011/2.jpg"],
    ],
    example: {
      id: 54847062262342,
      url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20210127/1611737775562.pdf",
    },
    share: {
      title: "实时线下大数据，精准洞察市场环境",
      desc: "高效分析商圈成熟度，多快好省",
      cover: "/static/img/report/10011/cover.jpg",
    },
  },

  10012: {
    list: ["/static/img/report/10012/a.png", "/static/img/report/10012/b.png", "/static/img/report/10012/c.png"],
    share: {
      title: "实时监测竞争对手动向，掌握同行最新动态。",
      desc: "洞察竞争对手的营销策略，为开店助力",
      cover: "/static/img/report/10012/cover.jpg",
    },
  },
  //找商机
  10015: {
    banner: "/static/img/report/10015/banner.jpg",
    notitle: true,
    list: [
      "/static/img/report/10015/a.png",
      "/static/img/report/10015/b.jpg",
      "/static/img/report/10015/c.jpg",
      ["/static/img/report/10015/1.jpg", "/static/img/report/10015/2.jpg", "/static/img/report/10015/3.jpg"],
    ],
    example: {
      id: 72604386456145,
      url: "https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/article/images/20210127/1611714288926.pdf",
    },
    share: {
      title: "10000+新锐创业者正在寻找商机",
      desc: "1天获得1年难求的商机",
      cover: "/static/img/report/10015/cover.jpg",
    },
  },
};

const _vw = document.body.clientWidth / 100;

export default {
  name: "introduceIndex",
  components: {
    ShareMarker,
    ImagePreview,
  },
  data() {
    return {
      loading: true,
      title: "正在加载",
      bgColor: "transparent",
      titleColor: "white",
      isTop: true,
      isClient: false,
      showTabs: false,
      downloadLink: this.HTTP.getAppStore(),
      showPicker: false,
      showBack: true,
      id: null,
      current: null,
      entity: {},
      content: null,
      tabsMap: {},
      callappInfo: {
        deeplinkurl: "",
        landurl: this.HTTP.getAppStore(),
      },
      isMini: false
    };
  },
  methods: {
    goPage(url) {
      if (window.isMini) {
        UniUtils.goPageLogin(url);
      } else {
        this.swRouter(url)
      }
    },
    onTap() {
      if (this.isClient) {
        this.JSBridge.callHandler("gotoPage", `card_${this.id}`);
      } else if (window.isMini) {
        UniUtils.miniRouter({ url: '/pages_home/searchPoi?goodsId=10001' })
      } else {
        this.$refs.openApp.click();
      }
    },
    // onLivechat() {
    //   if (this.isClient) {
    //     if (window._appCode >= 175) {
    //       this.JSBridge.callHandler("onNormalizingCall", {
    //         type: 5,
    //         url: "ONLINE_SERVICE",
    //         params: JSON.stringify({
    //           cardCode: "CUSTOMER_GOODS",
    //           cardId: this.id,
    //           title: "在线客服",
    //           type: 2,
    //         }),
    //       });
    //     } else {
    //       this.JSBridge.callHandler(
    //         "callLiveChat",
    //         {
    //           type: "normal",
    //           data: {},
    //         },
    //         (st) => {}
    //       );
    //     }
    //   } else {
    //     this.$refs.openApp.click();
    //   }
    // },
    onJump(id) {
      // document.querySelector( '#' + id ).scrollIntoView();
      this.$refs.view.$el.scrollTop = this.tabsMap[id] + 5;
    },
    countTop() {
      if (!this.content.tabs) return;
      for (const item of this.content.tabs) {
        this.tabsMap[item.id] = document.querySelector(`#${item.id}`).offsetTop - _vw * 27.5 - (this.isClient ? 35 : 0);
      }
    },
    pageScrollHandle() {
      if (!this.content) return;
      let tg = this.$refs.standard;
      let sv = 120;
      if (!tg) {
        tg = this.$refs.top;
        sv = -100;
      }
      const stdTop = tg.getBoundingClientRect().top;
      const check = stdTop > sv;
      if (this.isTop !== check) {
        this.isTop = check;
        this.bgColor = check ? "transparent" : "white";
        this.titleColor = check ? "white" : "#1D2233";
        this.JSBridge.callHandler("setStatebarTheme", check ? "white" : "black");
      }
      const checkTabs = stdTop < (this.isClient ? 42 : 58);
      if (this.showTabs !== checkTabs) {
        this.showTabs = checkTabs;
      }
      const top = this.$refs.view.$el.scrollTop;
      for (const id in this.tabsMap) {
        if (this.tabsMap[id] <= top) {
          this.current = id;
        }
      }
    },
    onExample() {
      if (this.content && this.content.example) {
        if (this.isClient) {
          this.JSBridge.callHandler("callFileViewer", {
            type: "application/pdf",
            id: this.content.example.id,
            name: "报告示例",
            url: this.content.example.url,
          });
        } else {
          this.$refs.openApp.click();
        }
      }
    },
    onPreview(item) {
      // 预览
      if (item.preview) {
        this.showBack = false;
        ImagePreview({
          images: [item.preview],
          loop: false,
          showIndex: false,
          closeable: false,
          onClose: () => {
            this.showBack = true;
            // this.switchShare( true );
          },
        });
      }
    },
    getQuestionInfo(id) {
      // 埋点上报
      // this.JSBridge.report( this.JSBridge.REPORT_EVENT.INTRODUCE.ENTRY + id );
      this.HTTP.getQuestionInfo(id)
        .then((res) => {
          console.log("res:", res);
          this.entity = res;
          this.loadInfo(res);
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
        });
    },
    loadInfo(data) {
      console.log("introduce appCode:", window._appCode);
      if (this.entity.bannerResList) {
        let videoIndex = this.entity.bannerResList.findIndex((item) => {
          if (item.type == "video") {
            return true;
          }
          return false;
        });
        if (videoIndex != -1) {
          this.entity.bannerResList.splice(videoIndex, 1);
        }
      }

      // this.content = data;
      this.content = REPORT_MAPPING[this.id] || {};

      if (this.isClient) {
        this.title = this.content.title || data.name;
        if (this.content && this.content.share) {
          const share = this.content.share;
          if (this.id === "10009") {
            this.JSBridge.callHandler("switchShareBtn", {
              type: "assess",
              id: this.id,
              title: share.title,
              desc: share.desc,
              cover: document.location.protocol + "//" + document.location.host + share.cover,
              url: document.location.protocol + "//" + document.location.host + "/#/introduce/" + this.id,
              page: "/pages/index/index",
              target: window._appCode === 15 ? "wxmp_report" : "wxmp_tools",
            });
          } else {
            this.JSBridge.callHandler("switchShareBtnV2", {
              code: "GOODS",
              id: this.id,
            });
            this.JSBridge.callHandler("switchShareBtn", {
              type: "poster",
              id: this.id,
              title: share.title,
              desc: share.desc,
              cover: document.location.protocol + "//" + document.location.host + share.cover,
              url: location.href,
              target: window._appCode === 15 ? "wxmp_tools" : "wxmp_report",
            });
          }
        }
      } else {
        document.title = this.content.title || data.name;
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
  computed: {
    getAmount() {
      return this.entity ? this.entity.showPrice : "---";
    },
    getNum() {
      return this.entity ? this.entity.buyNumber : 0;
    },
  },
  watch: {
    id(id) {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.INTRODUCE.ENTRY + id);
    },
  },
  beforeMount() {
    this.JSBridge.callHandler("setStatebarTheme", "white");

    this.id = this.$route.params.id;
    this.isClient = window.isClient;
    this.isMini = window.isMini
    this.getQuestionInfo(this.id);

    if (window.isIOS) {
      // ios
      this.callappInfo.deeplinkurl = "https://webclient.sscanmou.com/SSCM/type=5&url=BRAND_INTRODUCTION&id=" + this.id;
    } else if (window.isAndroid) {
      this.callappInfo.deeplinkurl = "androidsscm://link/params?type=5&url=BRAND_INTRODUCTION&id=" + this.id;
    } else {
      this.callappInfo.deeplinkurl = "";
    }
  },
  beforeDestroy() {
    // 隐藏分享按钮
    this.JSBridge.callHandler("switchShareBtn", false);
    this.JSBridge.callHandler("switchShareBtnV2", false);
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.introduce {
  height: 100vh;

  .header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    ::v-deep {
      .stateBar,
      .titleHeader {
        @include ani(0.25s, background-color);
      }
    }
    .tabs {
      @include ani(0.25s);
      @include flex;
      overflow: hidden;
      width: 100%;
      height: auto;
      background-color: white;
      max-height: 0;
      opacity: 0;

      &.show {
        max-height: 50vw;
        opacity: 1;
      }

      li {
        position: relative;
        overflow: hidden;
        flex: 1;
        width: auto;
        height: 12vw;
        text-align: center;
        font-size: $font_373;
        padding-top: 5vw;
        box-sizing: border-box;

        &:before {
          @include ani;
          content: "";
          position: absolute;
          left: calc(50% - 7.5vw);
          bottom: 0.6vw;
          width: 15vw;
          height: 1vw;
          background-color: $c-main;
          border-radius: 2vw;
          opacity: 0;
        }

        &.on {
          font-weight: bold;

          &:before {
            opacity: 1;
          }
        }
      }
    }

    .share {
      position: relative;
      height: $headerHeight;
      background: rgba(black, 0.8) url(/static/icon/logo@96.png) no-repeat $margin center;
      background-size: $headerHeight * 0.7;
      color: white;
      padding-left: $headerHeight * 1.1;
      p {
        font-size: $font_320;
        padding-top: $margin * 0.8;
      }
      .f-fw {
        font-size: $font_400;
      }
      .app-wrap {
        position: absolute;
        right: $margin;
        top: 25%;
        height: 50%;
        font-size: $font_320;
        display: flex;
        .app {
          color: white;
          background-color: $c-main;
          padding: 0 $margin;
          overflow: hidden;
          border-radius: 10vw;
          font-size: $font_320;
          @include flexColumn;
          margin-left: $margin;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      // .download {
      //     position: absolute;
      //     right: 6.4vw;
      //     top: 25%;
      //     height: 50%;
      //     color: white;
      //     background-color: $c-main;
      //     padding: 0 $margin;
      //     overflow: hidden;
      //     border-radius: 10vw;
      //     font-size: $font_320;
      //     @include flexColumn;
      // }
    }
  }

  #{&}--view {
    scroll-behavior: smooth;
  }

  #{&}--banner {
    // height: 75vw;
    background-color: $c-pageBg2;

    .img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  #{&}--main {
    background-color: white;
    position: relative;
    margin-top: -1rem;
    overflow: hidden;
    border-radius: $radius;
    .title {
      padding: $margin $padding;
      font-size: 6.4vw;
      line-height: 8.94vw;
      font-weight: bold;

      .labels {
        overflow: hidden;
        margin-top: $margin;

        span {
          float: left;
          padding: 0.5vw 1.5vw;
          font-size: $font_320;
          font-weight: normal;
          line-height: normal;
          color: white;
          background-color: $c-main;
          margin-right: 1vw;
          border-radius: $radius_xs;
        }
        span:nth-child(1),
        span:nth-child(5) {
          color: #2f61ce;
          background-color: #d5dff8;
        }
        span:nth-child(2),
        span:nth-child(4) {
          color: #7642e5;
          background-color: #e3d9ff;
        }
        span:nth-child(3) {
          color: #3fb7dc;
          background-color: #dcf3fd;
        }
      }
    }
  }

  #{&}--list {
    .img {
      display: block;
      min-height: 10vw;
    }
    .swipe {
      // min-height: 87vw;
      // padding: $padding 0;
      padding-top: 2.6vw;
      padding-bottom: 8vw;
      background: linear-gradient(to bottom, white, #fdfeff);
      ::v-deep .van-swipe__track {
        .van-swipe-item {
          .img {
            overflow: hidden;
            width: 90vw;
            margin: 0 auto;
            border-radius: $radius_xs;
            // box-shadow: 0 0 4vw .5vw rgba($c-main, .1);
          }
        }
      }
      &.bot {
        background: #f5f7fd;
        ::v-deep .van-swipe__indicators {
          bottom: 9.3vw;
        }
      }
      ::v-deep .van-swipe__indicator {
        background-color: $c-main;
      }
    }
  }

  #{&}--livechat {
    position: fixed;
    right: 0;
    bottom: 12vh;
    height: 12vw;
    width: 24vw;
    background: url(/static/img/report/livechat.png) no-repeat right top;
    background-size: cover;
  }

  #{&}--footerBar {
    z-index: 3;
    box-shadow: 0 -0.5vw 2vw rgba(black, 0.05);
    .view {
      box-sizing: border-box;
      padding: $headerMargin $padding;
      width: 100%;
      height: $footerHeight;
      ::v-deep .van-col {
        height: 100%;
      }
    }
    .label {
      color: $c-info;
      font-size: $font_267;
      left: $font_373;
      padding-top: 0.5vw;
    }
    .price {
      font-size: $font_480;
      line-height: $font_653;
      font-weight: bold;
      color: #ff5900;

      sub {
        vertical-align: bottom;
        font-size: $font_320;
      }
    }
    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      font-weight: bold;
      line-height: normal;
      background: linear-gradient(to right, #ff8000, #ff5900);
    }
    .icon-detail {
      background: url(/static/icon/detail_orange.png) no-repeat left 25% center;
      background-size: $buttonSize $buttonSize;
    }
    .float-label {
      position: absolute;
      @include flexColumn;
      line-height: normal;
      background: linear-gradient(to right, #2989ef, #61c7fe);
      height: 4.5vw;
      padding: 0 2vw;
      color: white;
      font-size: $font_267;
      top: 0;
      right: 0;
      border-radius: $radius-xs $radius-xs $radius-xs 0;
      margin-top: -2.5vw;
      margin-right: -2vw;
    }
  }

  #{&}--subview {
    padding: $padding $margin;
    h3 {
      text-align: center;
      font-size: $font_400;
      margin-bottom: $margin * 2;
    }
    .block {
      width: 100%;
    }
  }

  #openBowerTip {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;
    align-content: center;
    align-items: center;
    opacity: 0.9;
    .tip-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 139px;
      height: 184px;
      background: url("/static/icon/goToClick.png") no-repeat;
      background-size: contain;
    }
    .tip-text {
      position: absolute;
      top: 184px;
      width: 100%;
      color: #222;
      text-align: center;
      h4 {
        font-size: 17px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
</style>
