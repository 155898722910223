<template>
    <div class="poi-bar" @click="$emit('click')">
      <p class="poi-name">
        <span v-for="(segment, index) in formatName"
              :key="index"
              :class="{ highlight: segment.highlight }">{{ segment.str }}</span>
      </p>
      <p class="poi-location">
        <span>{{ distance }}</span>
        <span v-show="!!distance && !! address">|</span>
        <span class="addres">{{ address }}</span>
      </p>
      <Icon v-if="checked" class="checked-icon" name="checked" :size="6.13"></Icon>
    </div>
</template>

<script>
import Icon from '@/components/Icon/Icon';

export default {
    name      : 'POIBar',
    components: {
        Icon,
    },
    props: {
        formatName: {
            type   : Array,
            default: () => [{ str: '', highlight: false }]
        },
        distance: String,
        address : String,
        checked : {
            type   : Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
  // @import '@/styles/index.scss';

  .poi-bar {
    box-sizing: border-box;
    height: 22.4vw;
    padding-top: 5.07vw;
    padding-right: 6.13vw;
    position: relative;
    .poi-name {
      margin: 0;
      @include font($c-text, $font_453, $weight_500);
      line-height: 6.4vw;
      @include singleLine;
      .highlight {
        color: $c_518BDB;
      }
    }
    .poi-location {
      margin: 0.8vw 0 0 0;
      @include font($c-info, $font_347, $weight_400);
      line-height: 4.93vw;
      @include limitLine(2)
    }
    .checked-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
