<template>
  <div class="guide">
    <StateBar bgcolor="white" />
    <TitleHeader :title="title" bgcolor="white" :back="true">
      <!-- isFrom == 'me' -->
      <!-- <span slot="left" class="backTop" @click="openOne" v-if="isFrom != 'me'">上一步</span> -->
      <span slot="right" class="jump" @click="jumpGuide" v-if="isFrom != 'me'">跳过</span>
    </TitleHeader>
    <div class="coupon_text" v-if="isCoupon && isSkip != 1 && isFrom != 'me'">跳过则无法领取50元优惠券哦~</div>
    <!-- 进度条 -->
    <ProgressBar :guide1Val="100" :guide2Val="guideBar" v-if="isFrom != 'me'" />

    <StackView ref="view" class="guide--view">
      <van-skeleton :loading="pageLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
        <div class="content-wrap">
          <div class="pro-sets">
            <h4>您的性别是？</h4>
            <ul class="from-flex">
              <li v-for="(item, index) in sixType" :key="item" @click="sexSelected(index)">
                <p :class="{ isSelect: guideVal.sex == index }">{{ item }}</p>
              </li>
            </ul>
          </div>
          <div class="pro-sets">
            <h4>您的年龄范围是？</h4>
            <div class="select-wrap" @click="showSex = true">
              <input class="select-input" v-model="guideVal.age" readonly="readonly" placeholder="请选择您的年龄" />
              <img class="icon-img" src="/static/icon/input_icon-select.png" alt="" />
            </div>
          </div>
          <div class="pro-sets">
            <h4>您的所在地？</h4>
            <div class="select-wrap" @click="showAddr = true">
              <input class="select-input" v-model="guideVal.addr" readonly="readonly" placeholder="请选择您的所在地" />
              <img class="icon-img" src="/static/icon/input_icon-select.png" alt="" />
            </div>
          </div>
        </div>
      </van-skeleton>
    </StackView>

    <SoltFooter class="guide--footerBar">
      <div class="confirm-btn">
        <van-button :color="colorVal" block :loading="loading" :disabled="!isOk" @click="onTap">{{ isFrom ? "保存" : "进入首页" }}</van-button>
        <img v-if="isCoupon && isSkip == 1 && isOk" @click="openOne" src="/static/img/guide_btn.png" alt="">
      </div>
    </SoltFooter>

    <!-- 年龄范围弹框 -->
    <van-popup v-model="showSex" position="bottom">
      <van-picker visible-item-count="5" show-toolbar :columns="columnsAge" @confirm="sexConfirm" @cancel="showSex = false" />
    </van-popup>

    <!-- 所在地弹框 -->
    <van-popup v-model="showAddr" position="bottom">
      <van-picker value-key="name" visible-item-count="5" show-toolbar :columns="columnsAddr" @confirm="addrConfirm" @cancel="showAddr = false" />
    </van-popup>

    <!-- 领取优惠券  -->
    <van-dialog v-if="isCoupon" v-model="couponShow" class="coupon_dialog" :showConfirmButton="false">
      <img src="/static/img/coupon_lq.png" />
      <div class="coupon_btn" @click="openCouponPage"></div>
      <div class="coupon_colse" @click="openClose"><img src="/static/icon/icon_close_circle.png" alt=""></div>
    </van-dialog>
  </div>
</template>

<script>
import ProgressBar from "./components/progress_bar";

export default {
  components: { ProgressBar },
  data() {
    return {
      couponShow: false,
      title: "",
      guideBar: 0, // 进度条
      showSex: false,
      showAddr: false,
      isOk: false,
      loading: false,
      pageLoading: true,
      isEdit: false, // 编辑状态
      isCoupon: null, //是否显示领取优惠券文案
      guideVal: {
        sex: -1, // 性别
        age: "", // 年龄范围
        addr: "", // 地址
        fullAddr: [],
      },
      sixType: ["男", "女"],
      columnsAge: ["18岁以下", "18-25", "26-30", "31-40", "41-50", "51-60", "60岁以上"],
      columnsAddr: [],
      isSkip: null, //点击下一步还是跳过
    };
  },
  computed: {
    colorVal() {
      return this.isOk ? "#347FFF" : "#D5D3DA";
    },
    isFrom() {
      return this.$route.query.from;
    },
  },
  created() {
    this.isSkip = this.$route.query.type;
    console.log(this.$route)
    this.HTTP.v2openCity().then((res) => {
      this.columnsAddr = res.data || [];
    });
    this.HTTP.getPersonalInfo().then((res) => {

      this.loadData(res.data);
    });
  },
  beforeMount() {
    // this.JSBridge.callHandler("setStatebarTheme", "white");
    // if (!this.isFrom) {
    //   // 禁止返回操作
    //   this.JSBridge.callHandler("interceptBack", false);
    // }
    // setTimeout(() => {
    //   if(window._appCode == 207) {
    //     this.isBack = true;
    //     console.log(11)
    //   } else {
    //     this.isBack = false
    //   }
    //   console.log(window._appCode)
    // },1500)
  },
  methods: {
    // 跳转到上一页
    openOne() {
      if(window._appCode == 208) {
        this.JSBridge.callHandler("onBackPressed");
      }
    },
    // 关闭弹出框
    openClose() {
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "CHANGE_MAIN_TAB",
        id: "home",
        params: null,
        trackData: null,
        isClear: true,
      });
    },
    // 跳转到优惠券页面
    openCouponPage() {
      this.swRouter("/coupons");
    },
    loadData(data) {
      this.isCoupon = data.couponStatus;
      console.log(data);
      this.pageLoading = false;
      if (data && data.sex) {
        this.guideVal.sex = this.sixType.indexOf(data.sex);
        this.guideVal.age = data.age;
        let addr = null;
        if (data.province == data.city) {
          addr = `${data.province}-${data.region}`;
        } else {
          addr = `${data.province}-${data.city}-${data.region}`;
        }
        this.guideVal.addr = addr;
        this.guideVal.fullAddr.push(data.province);
        this.guideVal.fullAddr.push(data.city);
        this.guideVal.fullAddr.push(data.region);
        this.isOk = true;
        this.isEdit = true;
        this.guideBar = 100;
      }
    },
    jumpGuide() {
      let params = {
        age: "",
        city: "",
        province: "",
        region: "",
        sex: "",
      };
      this.HTTP.updatePersonalInfo(params)
        .then((res) => {
          if (res.data) {
            this.goAppHome();
          }
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍后重试！");
        });
    },
    goAppHome() {
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "CHANGE_MAIN_TAB",
        id: this.isFrom == "me" ? "my" : "home",
        params: null,
        trackData: null,
        isClear: true,
      });
    },
    sexSelected(idx) {
      if (this.guideVal.sex == -1) {
        this.guideBar += 33.33;
      }
      this.guideVal.sex = idx;
      if (this.guideBar > 98) {
        this.isOk = true;
      }
    },
    sexConfirm(value) {
      if (!this.guideVal.age) {
        this.guideBar += 33.33;
      }
      this.showSex = false;
      this.guideVal.age = value;
      if (this.guideBar > 98) {
        this.isOk = true;
      }
    },
    addrConfirm(value) {
      this.guideVal.fullAddr = value;
      if (!this.guideVal.addr) {
        this.guideBar += 33.33;
      }
      this.showAddr = false;
      let newArr = value.slice();
      if (newArr[0] == newArr[1]) {
        newArr.splice(1, 1);
      }
      this.guideVal.addr = newArr.join("-");
      if (this.guideBar > 98) {
        this.isOk = true;
      }
    },
    onTap() {
      // if(this.isCoupon) {
      //   this.couponShow = true;
      // } else {}
      this.loading = true;
      let params = {
        age: this.guideVal.age,
        city: this.guideVal.fullAddr[1],
        province: this.guideVal.fullAddr[0],
        region: this.guideVal.fullAddr[2],
        sex: this.sixType[this.guideVal.sex],
      };
      this.HTTP.updatePersonalInfo(params).then((res) => {
        this.loading = false;
        if(res.code == 0) {
          if ((res.data  && this.isSkip == 1) || (res.data  && !this.isCoupon)) {
            this.goAppHome();
          } else {
            this.couponShow = true;
          }
        }
      }).catch((e) => {
        this.$toast("网络不稳定，请稍后重试！");
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.guide {
  height: 100vh;
  overflow: hidden;
  background: #f7f6f9;
  .coupon_text{
    width: 100%;
    height: 9.33vw;
    margin-bottom: 4.8vw;
    background: url('/static/img/guide_bg.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 0 6.67vw;
    box-sizing: border-box;
    font-size: 3.73vw;
    font-weight: bold;
    color: #347FFF;
    line-height: normal;
  }
  .backTop{
    font-size: 3.73vw;
    color: #1D2233;
    line-height: normal;
  }
  .jump {
    width: 12.27vw;
    height: 5.87vw;
    line-height: normal;
    font-size: 3.73vw;
    color: #8e9099;
    border-radius: 1.6vw;
    text-align: center;
    border: 1px solid #d9dbe3;
  }

  #{&}--view {
    padding: $padding2 $padding2 54.16vw $padding2;
    .content-wrap {
      width: 100%;
      .pro-sets {
        margin-bottom: 6.67vw;
        h4 {
          font-size: 4.53vw;
          color: #1d2233;
          line-height: 6.4vw;
          font-weight: bold;
          padding-left: 1.6vw;
        }
        .from-flex {
          display: flex;
          flex-flow: row wrap;
          text-align: center;
          li {
            flex: 0 0 33.33%;
            margin-top: 3.2vw;
            p {
              width: 27.2vw;
              height: 10.67vw;
              line-height: 10.67vw;
              font-size: 3.73vw;
              color: #666;
              background: #fff;
              border-radius: 1.33vw;
              border: 1px solid #ebeaee;
              margin: 0 auto;
            }
          }
          .isSelect {
            color: #fff;
            background: #347fff;
          }
        }
        .select-wrap {
          position: relative;
          margin: 2.13vw 1.6vw 0;
          .select-input {
            width: 80vw;
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 3.73vw;
            color: #1d2233;
            background: #fff;
            border-radius: 1.33vw;
            border: 1px solid #ebeaee;
            padding: 0 4.27vw;
            outline: none;
            -webkit-appearance: none;
          }
          .icon-img {
            position: absolute;
            top: 4.8vw;
            right: 4.27vw;
            width: 2.4vw;
            height: 1.6vw;
          }
        }
      }
    }
  }

  #{&}--footerBar {
    .confirm-btn {
      width: 100%;
      padding: 0 4vw;
      position: relative;
      img{
        position: absolute;
        left: 9.07vw;
        right: 9.07vw;
        top: -9.47vw;
        z-index: 2;
        width: calc(100% - 18.13vw);
        height: 8.53vw;
      }
    }
  }
  .coupon_dialog{
    width: 100%;
    background: transparent;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ::v-deep .van-dialog__content{
      position: relative;
    }
    .coupon_btn{
      position: absolute;
      top: 42.5vw;
      left: 22.5vw;
      width: 55vw;
      height: 12vw;
      z-index: 2;
      background: transparent;
      border-color: transparent;
    }
    img{
      width: 100%;
      height: 120vw;
    }
    .coupon_colse{
      position: fixed;
      top: 11vw;
      right: 4vw;
      z-index: 5;
      background: transparent;
      width: 8vw;
      height: 8vw;
      border-radius: 4vw;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
