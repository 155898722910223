<template>
    <div class="data">
        <StateBar></StateBar>
        <TitleHeader title="数据说明" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="data--view">
            <p class="time">数据更新周期：1个月</p>
            <p class="tips">数据来源于互联网，包括但不限于各品牌官网，公开的本地生活平台，部分数据已经过整理清洗。</p>
            <div class="data-box" v-for="(val, index) in list" :key="index">
                <h5 class="title">{{ val.title }}</h5>
                <div class="text" v-for="(citem, cIndex) in val.childrent" :key="cIndex">
                    <span class="i-icon"></span>
                    <p class="explain">{{ citem }}</p>
                </div>
            </div>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    title: "品牌门店分布",
                    childrent: [
                        "品牌门店数：根据本地生活平台整理地全国在营门店数，考虑到品牌类型的个体差异，与实际情况可能存在少许误差。",
                        "开通外卖：此处表示相关门店在美团外卖开设的数量。",
                    ],
                },
                {
                    title: "历史门店开店时序图",
                    childrent: ["部分数据来源品牌官方开店时间，部分数据以每家门店用户最早评论时间作为开店时间。包含已关闭歇业门店数据。"],
                },
                // {
                //     title: "销量订单分析",
                //     childrent: ["统计店铺数：此部分仅统计正常营业的店铺，且月销大于零。", "店均数据：基于品牌为单位的平均数。"],
                // },
                {
                    title: "消费者评分",
                    childrent: ["统计店铺数：此部分仅统计正常营业的店铺，且有用户评论的门店数量。", "店均数据：基于本地生活平台门店数据平均计算。"],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.data {
    width: 100%;
    height: 100vh;
    background: #fff;

    #{&}--view {
        scroll-behavior: smooth;
        position: relative;
        padding: 4vw;
    }
    .time {
        font-size: 2.93vw;
        color: #8e9099;
        line-height: 4vw;
    }
    .tips {
        line-height: 5.87vw;
        font-size: 3.47vw;
        color: #666;
        margin-top: 2.13vw;
    }
    .data-box {
        width: 100%;
        height: auto;
        .title {
            height: 10.67vw;
            line-height: 10.67vw;
            font-size: 4vw;
            font-weight: bold;
            color: #333;
        }
        .text {
            display: flex;
            font-size: 3.47vw;
            color: #666;
            line-height: 5.87vw;

            .i-icon {
                position: relative;
                top: 1.9vw;
                width: 2.13vw;
                height: 2.13vw;
                margin-right: 2.13vw;
                border-radius: 50%;
                background: #347fff;
            }
            .explain {
                flex: 1;
                padding-bottom: 2vw;
            }
        }
    }
}
</style>
