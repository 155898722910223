<template>
    <div>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-show="!firstLoading">
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab3-1.png">
                <div class="btn-group">
                    <div class="nor-btn arrow" @click="deeplink({ type: '6', url: '#/community/goodsList?tag=商铺租赁', btnId: '80202' })">
                        查看租赁干货
                    </div>
                    <div class="nor-btn arrow" @click="deeplink({ type: '5', url: 'OPEN_SHOP', btnId: '80203' })">下载租赁合同</div>
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab3-2.png"></VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab3-3.png"></VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab3-4.png"></VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab3-5.png">
                <div
                    class="nor-btn arrow"
                    style="bottom: 16vw"
                    @click="deeplink({ type: '6', url: '#/community/drgGoods?tab=开店筹备', btnId: '80201' })"
                >
                    点击查看更多开店筹备干货
                </div>
            </VanImage>
        </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
    name: 'StorePreparation',
    data () {
        return {
            firstLoading: true
        };
    },
    methods: {
        imageLoadDone () {
            picNum ++;
            if ( picNum >= 4 ) {
                this.firstLoading = false;
            }
        },
        deeplink ( { type, url, params, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80200, btnId );
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                params: JSON.stringify( params )
            } );
        }
    }
};
</script>

<style lang="scss" scoped>
.van-image {
    width: 100%;
    margin-bottom: -3px;
    position: relative;
    ::v-deep .nor-btn {
        position: absolute;
        width: 78.66vw;
        height: 12.26vw;
        line-height: 12.26vw;
        border-radius: 1.06vw;
        background: linear-gradient(270deg, #2f6ef8 0%, #3f87ff 100%);
        box-shadow: 0 0.66vw 2.66vw 0 rgba(47, 110, 248, 0.4);

        font-size: 4vw;
        color: #fff;
        bottom: 7.33vw;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: bold;
        &.mul-line {
            line-height: 3.5vw;
            font-size: 3.46vw;
            p {
                font-size: 2.93vw;
                padding-top: 2.13vw;
                padding-bottom: 1.13vw;
                font-weight: normal;
            }
        }
        &.free {
            &::before {
                position: absolute;
                content: '';
                width: 8.53vw;
                height: 4.8vw;
                right: -1.33vw;
                top: -2.66vw;
                background: url('/static/icon/icon_free.png') center / 100% 100% no-repeat;
            }
        }
        &.arrow {
            text-indent: -2.5vw;
            &::after {
                position: absolute;
                content: '';
                width: 4vw;
                height: 4vw;
                transform: translateY(-2vw);
                top: 50%;
                margin-left: 1vw;
                background: url('/static/icon/icon_arrow_right2.png') center / 4vw 4vw no-repeat;
            }
        }
    }
    ::v-deep .btn-group {
        position: absolute;
        bottom: 10.6vw;
        left: 50%;
        transform: translateX(-50%);
        width: 84vw;
        display: flex;
        justify-content: space-between;
        .nor-btn {
            position: unset;
            width: 40.66vw;
            transform: translateX(0%);
        }
        .left {
            box-sizing: border-box;
            background: #fff;
            color: #4262fb;
            border: 0.26vw solid #4262fb;
        }
    }
}
</style>
