/**
 * 饿了么商家版路由
 */

const shopSearch = () => import(/* webpackChunkName: element" */ "@/views/element/shopSearch.vue");
const home = () => import(/* webpackChunkName: element" */ "@/views/element/home.vue");
const search = () => import(/* webpackChunkName: element" */ "@/views/element/search.vue");
const customLanding = () => import(/* webpackChunkName: element" */ "@/views/element/customLanding.vue");
const selectCity = () => import(/* webpackChunkName: element" */ "@/views/element/selectCity.vue");
const selectPOI = () => import(/* webpackChunkName: element" */ "@/views/element/selectPOI.vue");
const order = () => import(/* webpackChunkName: element" */ "@/views/element/order.vue");

export default [
    {
        path: "/element",
        component: {
            render: c => c("router-view")
        },
        children: [
            {
                path: "home/:id",
                name: "home",
                component: home,
                meta: { id: 10180 }
            },
            {
                path: "search",
                name: "search",
                component: search,
            },
            {
                path: "shopSearch",
                name: "shopSearch",
                component: shopSearch
            },
            {
              path: "customLanding",
              name: "customLanding",
              component: customLanding
            },
            {
              path: "selectCity",
              name: "selectCity",
              component: selectCity
            },
            {
              path: "selectPOI",
              name: "selectPOI",
              component: selectPOI
            },
            {
              path: "order",
              name: "order",
              component: order
            },
        ]
    }
];
