<template>
  <div class="base-shop-needs">
    <van-grid :border="false" :column-num="row">
      <template v-for="(item, index) in data">
        <van-grid-item :key="index" :icon="dealIcon(item)" :text="dealName(item)" :class="{ active: item.value != 0 }"
          v-if="item.value >= 1 || showAll" @click="handlerClick(item)" />
      </template>
    </van-grid>
    <van-share-sheet v-model="showOptions" :options="options" @select="handlerSelect" @cancel="handlerCannel" />
  </div>
</template>

<script>
import { defaultDecoration, defaultGateWidth } from './default-shops-needs.js';
const baseUrl = '/static/shopEcology/shopRequirements';
const dealSelectIcon = (data) => {
  let temp = JSON.parse(JSON.stringify(data));
  temp.map((v) => {
    v.icon = `${baseUrl}/${v.code}-${v.value}.png`
  });
  return temp;
};
export default {
  data() {
    return {
      code: null,
      options: [],
      showOptions: false,
      gateWidth: dealSelectIcon(defaultGateWidth),
      decoration: dealSelectIcon(defaultDecoration)
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: () => false
    },
    row: {
      type: Number,
      default: 4
    },
    showAll: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    handlerClick(item) {
      if (!this.edit) return;
      switch (item.code) {
        case 'gate_width':
          this.code = item.code;
          this.options = this.gateWidth;
          this.$nextTick(v => {
            this.showOptions = true;
          })

          break;
        case 'decoration':
          this.code = item.code;
          this.options = this.decoration;
          this.showOptions = true;
          break;
        default:
          item.value = !item.value ? 1 : 0;
          this.$emit('update:data', this.data);
          break;
      };
    },
    handlerSelect(option) {
      let temp = JSON.parse(JSON.stringify(option));
      let index = this.data.findIndex(v => v.code == this.code);
      delete temp.icon
      if (temp.value == 0 && temp.code == 'gate_width') temp.name = '展面要求';
      if (temp.value == 0 && temp.code == 'decoration') temp.name = '装修程度';
      this.data[index] = Object.assign(this.data[index], temp);
      this.$emit('update:data', this.data);
      this.handlerCannel();
    },
    handlerCannel() {
      this.showOptions = false;
      this.$nextTick(() => {
        this.code = null;
        this.options = [];
      })
    },
    dealIcon(item) {
      return `${baseUrl}/${item.code}-${item.value}.png`
    },
    dealName(item) {
      const data = JSON.parse(JSON.stringify(item));
      if (item.value != 0) {
        return data.name.replace(/需要/, '');
      }
      else return data.name
    }
  },

}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
  @return $args/750 * 100+vw;
}

.base-shop-needs {
  ::v-deep .van-share-sheet__option {
    flex: 1;
    padding: 16px 8px 16px 8px;
  }

  ::v-deep .van-grid-item__text {
    color: #999999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ::v-deep .van-grid-item__content {
    padding: 8px 0px;
    font-size: vw(24);
    line-height: vw(33);

    .van-icon__image {
      width: vw(79);
      height: vw(79);
    }
  }

  .active {
    ::v-deep .van-grid-item__text {
      color: #666666;
    }
  }
}
</style>
