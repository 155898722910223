<template>
    <div class="selectTree">
        <StateBar />
        <TitleHeader title="选择求租区域" bgcolor="transparent" color="black" :back="true"
            style="border-bottom: 1px solid #f3f4f7">
        </TitleHeader>
        <AutoView :header="true" :footer="true">
            <van-row class="muti_select_tree">
                <van-col span="8">
                    <template v-if="columns">
                        <template v-for="(item, index) in columns">
                            <div :key="index" class="muti_select_item is_root" :class="{ active: index == activeId }"
                                @click="activeId = index">
                                <div class="item_title">{{ item.name }}</div>
                                <div class="item_numb" v-if="item.num">{{ item.num }}</div>
                            </div>
                        </template>
                    </template>
                </van-col>
                <van-col span="16" class="is_leaf">
                    <template v-if="columns[activeId] && columns[activeId].children">
                        <template v-for="(item, index) in columns[activeId].children">
                            <div :key="index" class="muti_select_item is_leaf" :class="{ active: item.is_select }"
                                @click="leafClick(index)">
                                <div class="item_title">{{ item.name }}</div>
                                <div class="select-icon" v-if="item.is_select" />
                            </div>
                        </template>
                    </template>
                </van-col>
            </van-row>
        </AutoView>
        <div class="button-bottom-box">
            <div class="left-box" @click="reset">重置</div>
            <div class="right-box" @click="submit">确认</div>
        </div>
    </div>
</template>

<script>
const text = [
    {
        "name": "全市",
        "code": 440300,
        "children": [
            {
                "name": "不限",
                "code": 440300,
                "num": 0,
                "is_select": true
            }
        ],
        "num": 1,
        "is_select": false
    }
];
import { getCommunity } from "@/request/asking4Rent";
export default {
    data() {
        return {
            activeId: 0,
            activeAl: null,
            columns: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getData();
        },
        getData() {
            let cityName = this.$route.query.cityName;
            if (!cityName) {
                this.$toast({
                    message: "缺少城市字段",
                    icon: "/static/icon/toast_wrong.png",
                });
                return
            }
            getCommunity(cityName).then((res) => {
              res.children.forEach(item => {
                if (!item.hasOwnProperty('children')){
                  item.children = []
                }
              })

                // 处理数据
                res.children.unshift({
                    name: '全市',
                    code: res.code,
                    children: []
                })
                this.activeAl = res.code;
                this.columns = this.normalizeData(res.children);
                if (window.isClient) {
                    this.JSBridge.callHandler("getString", "sscm-muti-community", (data) => {
                        let temp = typeof data === "string" ? JSON.parse(data) : data;
                        if (temp && temp.length) this.columns = this.dealCloudData(temp);
                    });
                }
            });
        },
        reset() {
            this.columns = this.normalizeData(this.columns);
        },
        submit() {
            let data = JSON.parse(JSON.stringify(this.columns));
            // 存数据
            this.JSBridge.callHandler(
                "putString",
                JSON.stringify({
                    key: "sscm-muti-community",
                    value: JSON.stringify(data),
                })
            );
            // 关掉页面
            this.JSBridge.callHandler("onBackPressed");
        },
        leafClick(index) {
            let temp = JSON.parse(JSON.stringify(this.columns));

            let arr1 = temp[this.activeId];
            let rowItem = arr1.children[index];

            if (rowItem.code == this.activeAl) {
                temp = this.normalizeData(temp);
                arr1 = temp[this.activeId];
                rowItem = arr1.children[index];
            }
            else {
                temp[0].num = 0;
                temp[0].children = this.normalizeData(temp[0].children);
            }

            if (arr1.code == rowItem.code) {
                arr1.num = 0;
                arr1.children = this.normalizeData(arr1.children);
            }
            else {
                if (arr1.children.find(v => v.code == arr1.code).is_select) {
                    arr1.num = 0;
                }
                arr1.children[0].is_select = false;
            }
            if (this.computedListNumb(temp) >= 5 && !rowItem.is_select) {
                this.$toast({
                    message: "选择区域不能大于5个",
                    icon: "/static/icon/toast_wrong.png",
                });
                return true;
            }
            // 勾选状态
            rowItem.is_select = !rowItem.is_select;
            // 一二级计数
            arr1.num = arr1.num ? (rowItem.is_select ? ++arr1.num : --arr1.num) : 1;
            this.columns = temp;
        },
        normalizeData(data) {
            // 处理数据
            for (let i = 0; i < data.length; i++) {
                data[i].num = 0;
                data[i].is_select = false;

                if (data[i].children) {
                  if (!data[i].children.find(v => v.name == '不限')) {
                    data[i].children.unshift({
                      name: '不限',
                      code: data[i].code,
                    });
                  }
                  data[i].children = this.normalizeData(data[i].children);
                }

                // if (data[i].children) {
                //     if (!data[i].children.find(v => v.name == '不限')) {
                //         data[i].children.unshift({
                //             name: '不限',
                //             code: data[i].code,
                //         });
                //     }
                //     data[i].children = this.normalizeData(data[i].children);
                // }
            }
            return data
        },
        dealCloudData(temp, index, index1) {
            if (temp) {
                temp.map((v1) => {
                    let t1 = index == null ? this.columns : this.columns[index].children;
                    t1 = index1 == null ? t1 : t1[index1].children;

                    let item = t1.find(c1 => c1.code == v1.code);
                    let i1 = t1.findIndex(c1 => c1.code == v1.code);

                    if (item) {
                        item.num = v1.num;
                        item.is_select = v1.is_select;
                        item.is_selectAll = v1.is_selectAll;
                    }

                    if (v1.children && index == null) {
                        this.dealCloudData(v1.children, i1)
                    } else if (v1.children) {
                        this.dealCloudData(v1.children, index, i1)
                    }
                })
                return this.columns;
            }
            else return this.columns;
        },
        computedListNumb(data) {
            let num = 0;
            if (data && data.length) {
                data.map((v) => (num += v.num || 0));
                return num;
            } else return num;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
    @return $args/750 * 100+vw;
}

.selectTree {
    height: 100%;

    .muti_select_tree {
        height: 100%;
        overflow: hidden;

        .van-col {
            height: 100%;
            overflow: hidden auto;
        }

        .muti_select_item {
            display: flex;
            height: vw(37);
            font-size: vw(26);
            font-weight: 400;
            color: #121622;
            line-height: vw(37);
            padding: vw(30) vw(30) vw(30);
            justify-content: space-between;

            .item_title {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100% - vw(40);
            }

            .item_numb {
                min-width: vw(30);
                height: vw(26);
                color: #fff;
                text-align: center;
                padding: vw(2) vw(8);
                line-height: vw(30);
                font-weight: 400;
                font-size: vw(22);
                background: #347fff;
                border-radius: vw(15);
            }

            .select-icon {
                width: vw(34);
                height: vw(35);
                background: url(/static/icon/icon_select.png) no-repeat center;
                background-size: vw(34) vw(35);
            }
        }

        .is_root {
            font-weight: 600;
        }

        .is_root.active {
            color: #347fff;
            background: #fafafb;
        }

        .is_tree {
            background: #fafafb;
        }

        .is_tree.active {
            color: #347fff;
            background: #f3f4f7;
        }

        .is_leaf {
            background: #f3f4f7;
        }

        .is_leaf.active {
            color: #347fff;
        }
    }
}

.button-bottom-box {
    width: 100%;
    padding: vw(16) vw(30) vw(30);
    box-sizing: border-box;
    background: #fff;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -4px 10px 0px rgba(29, 34, 51, 0.06);

    .left-box {
        width: vw(250);
        height: vw(76);
        text-align: center;
        line-height: vw(80);
        border-radius: vw(5);
        color: #347fff;
        font-weight: 600;
        font-size: vw(30);
        border: vw(2) solid #347fff;
    }

    .right-box {
        flex: 1;
        color: #fff;
        height: vw(80);
        text-align: center;
        margin-left: vw(20);
        line-height: vw(80);
        border-radius: vw(5);
        font-weight: 600;
        font-size: vw(30);
        background: #347fff;
    }
}
</style>
