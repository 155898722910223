<template>
    <div class="input_content">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true"></TitleHeader>

        <AutoView ref="view" :header="true" :footer="true" class="input_content--view">
            <van-skeleton :loading="loading" :animate="false" :row="4" class="s-skeleton">
                <div class="input_content--main">
                    <p class="tip">- 写下品牌真实看法，帮助万千用户选择品牌 -</p>
                    <div class="head">
                        <h3 @click="openDetail">{{currentTitle}}</h3>
                        <div class="text_rate">
                            <span>打分</span>
                            <van-rate  v-model="value" gutter="1.47vw" size="4.8vw" void-icon="star" color="#FFCB00" void-color="#D8D8D8" />
                            <small>{{rateVal[value-1]}}</small>
                        </div>
                        <h4>品牌的初印象（可多选）</h4>
                        <div class="comments_btn">
                            <div class="btn_list" :class="{checked: arr.includes(index)}" v-for="(val, index) in brandBtn" :key="index" @click="brandText(index,val)">
                                <p>{{val}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="content">
                        <h3>请描述您对品牌的评价</h3>
                        <van-field
                            class="textarea_input"
                            v-model="brandContent"
                            type="textarea"
                            maxlength="200"
                            show-word-limit
                            placeholder="大家都在问：这个品牌靠不靠谱，什么时间能回本，对这个品牌印象怎么样？"
                        />
                    </div>
                </div>
            </van-skeleton>
        </AutoView>

        <!-- 底栏提交按钮 -->
        <SoltFooter>
            <div class="input_content--footer">
                <van-button type="info" block class="s-radius__s" :disabled="isDisabled" @click="openSuccess">发布评价</van-button>
            </div>
        </SoltFooter>
    </div>
</template>


<script>
export default {
    name: "commontsInput",
    data() {
        return {
            title: "品牌评价",
            loading: false,
            value: 0,
            rateVal: [ '非常糟糕','有些糟糕','可以尝试','推荐尝试','极力推荐' ],
            brandBtn: [ '服务好','回本周期短','靠谱可信','投入少','值得依赖','需谨慎加盟' ],
            arr: [],
            brandContent: null,
            paramsText: [],
            currentTitle: '',
            isDisabled: false,
        }
    },
    methods: {
        openSuccess() {
            if(this.value == 0) {
                this.$toast("请给品牌打分");
                return false;
            }
            if(this.arr.length == 0) {
                this.$toast("请选择品牌的初印象");
                return false;
            }
            if(!this.brandContent) {
                this.$toast("请填写品牌评价");
                return false;
            }
            let thods = {
                content: this.brandContent,
                oneParentId: null,
                refId: this.$route.params.id,
                refName: this.$route.query.name,
                score: this.value,
                tag: this.paramsText.join(","),
                type: 1
            }
            this.isDisabled = true;
            this.HTTP.addCommonts(thods).then(res => {
                if(res.code == 0) {
                    this.$toast({
                        message: "评论成功，评审通过后即可展示",
                        duration: 2000,
                    });
                    setTimeout(() => {
                        if(window.isClient && window.isAndroid) {
                            if(window._appCode == 208) {
                                this.swRouter({
                                    path: '/brand/commonts_success',
                                    query: {
                                        id: this.$route.params.id,
                                        code: this.$route.query.code,
                                        name: this.currentTitle,
                                        detailId: res.data.data
                                    }
                                });
                                this.JSBridge.callHandler("onBackPressed");
                                this.isDisabled = false;
                            } else {
                                this.value = 0;
                                this.brandContent = null;
                                this.paramsText = [];
                                this.arr = [];
                                this.isDisabled = false;
                            }
                        } else {
                            this.value = 0;
                            this.brandContent = null;
                            this.paramsText = [];
                            this.arr = [];
                            this.isDisabled = false;
                        }
                    }, 1000)
                }
            }).catch((e) => {
                // this.$toast("网络连接不稳定，请稍候重试！");
                this.isDisabled = false;
            });
        },
        //
        openDetail() {
            this.swRouter( '/brand/details/'+this.$route.query.code );
        },
        // 选择品牌
        brandText(index,val) {
            if(this.arr.includes(index)){
                this.arr = this.arr.filter((ele) => {return ele != index;});
                this.paramsText = this.paramsText.filter((ele) => {return ele != val;});
            }else{
                this.arr.push(index);
                this.paramsText.push(val);
            }
        },
    },
    mounted() {
        this.currentTitle = this.$route.query.name;
    }
}
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";
.input_content{
    height: 100vh;
    background: #F7F7F7;
    overflow: hidden;
    line-height: normal;
    #{&}--main{
        height: 100%;
        padding: 2.4vw 4vw ;
        box-sizing: border-box;
        .tip{
            text-align: center;
            padding: 0 0 2.4vw 0;
            font-size: 3.2vw;
            color: #999999;
            line-height: normal;
        }
        .head{
            width: 100%;
            height: auto;
            padding: 4vw;
            box-sizing: border-box;
            background: #fff;
            border-radius: 1.33vw;
            margin-bottom: 4vw;
            h3{
                font-size: 4.53vw;
                font-weight: bold;
                color: #333300;
                line-height: 6.4vw;
            }
            .text_rate{
                width: 100%;
                height: auto;
                margin-top: 2.67vw;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    width: auto;
                    font-size: 3.73vw;
                    color: #333333;
                    margin-right: 1.33vw;
                    line-height: normal;
                    font-weight: bold;
                }
                ::v-deep .van-rate{
                    vertical-align: middle;
                }
                small {
                    flex: 1;
                    text-align: right;
                    font-size: 3.47vw;
                    color: #666666;
                }
            }
            h4{
                font-size: 3.73vw;
                font-weight: bold;
                color: #333333;
                line-height: 5.33vw;
                margin-top: 4vw;
            }
            .comments_btn{
                width: 100%;
                height: auto;
                box-sizing: border-box;
                font-size: 0;
                vertical-align: middle;
                line-height: normal;
                .btn_list{
                    width: calc(33.33% - 1.33vw);
                    height: 7.2vw;
                    border: 1px solid #999999;
                    border-radius: 0.67vw;
                    color: #999999;
                    font-size: 3.47vw;
                    text-align: center;
                    display: inline-block;
                    margin: 2.4vw 2vw 0 0;
                    box-sizing: border-box;
                    p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        span{
                            font-size: 3.2vw;
                        }
                    }
                    &:nth-child(3n) {
                        margin-right: 0px;
                    }
                }
                .checked{
                    background: #DFEDFF;
                    border: 1px solid #347fff;
                    color: #347fff;
                }
            }
        }
        .content{
            width: 100%;
            height: auto;
            padding: 4vw;
            box-sizing: border-box;
            background: #fff;
            border-radius: 1.33vw;
            h3{
                font-size: 4.27vw;
                font-weight: bold;
                color: #333300;
                line-height: 6vw;
            }
            .textarea_input{
                margin-top: 2.13vw;
                height: 60.13vw;
                background: #F7F7F7;
                border-radius: 1.07vw;
                padding: 2.67vw 4vw;
                box-sizing: border-box;
                font-size: 3.73vw;
                color: #999999;
                line-height: 5.33vw;
                ::v-deep .van-field__body{
                    height: calc(100% - 4vw - 2.67vw);
                }
                ::v-deep .van-field__control{
                    height: 100%;
                }
                ::v-deep .van-field__word-limit{
                    font-size: 2.93vw;
                    color: #999999;
                    line-height: 4vw;
                }
            }
        }
    }
    #{&}--footer{
        width: calc(100% - 8vw);
        margin: 0 auto;
        height: auto;
        background: #518CDB;
        box-shadow: 0vw -0.27vw 0.8vw 0vw rgba(34, 34, 34, 0.12);
        border-radius: 1.07vw;
        font-size: 4vw;
        font-weight: bold;
    }
}
</style>
