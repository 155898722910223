<template>
  <div class="tab-item" @click="openDetail">
    <div class="brand-icon">
      <van-image class="card--img s-radius__xs" fit="cover" lazy-load :src="brandItem.logoUrl || ''">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <!--                        <div class="brand-count"><span>{{ brandItem.shopNum }}家门店</span></div>-->
    </div>
    <div class="brand-name">{{ brandItem.brandName || "-" }}</div>
    <div class="recommend-box" v-if="brandItem.isRecord">推荐</div>
  </div>
</template>

<script>
  import { setLocalStorage } from '../../../utils';

  export default {
    name : 'brandItem',
    props: {
      brandItem: null
    },
    methods: {
      openDetail() {
        if (!this.brandItem) {
          return
        }
        setLocalStorage("versionCode", window._appCode);
        this.swRouter("/brand/details/" + this.brandItem.brandCode);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .tab-item {
    margin-top: 4vw;
    margin-right: 2.667vw;
    width: 28.8vw;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .recommend-box {
      width: 9.6vw;
      height: 5.733vw;
      background: url("/static/discovery/tj.png") no-repeat;
      background-size: cover;
      font-size: 3.2vw;
      text-align: center;
      line-height: 5.733vw;
      color: #ffffff;
      font-weight: 500;
      position: absolute;
      top: 0.667vw;
      right: -0.667vw
    }
    .brand-name {
      font-size: 3.733vw;
      margin-top: 1.6vw;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      line-height: 5.333vw;
    }
    .brand-icon {
      position: relative;
      width: 100%;
      height: 28.8vw;
      .van-image {
        width: 100%;
        height: 100%;
      }
      .brand-count {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #000000;
        border-radius: 0 1.333vw 0 1.333vw;
        opacity: 0.43;
        font-size: 3.2vw;
        color: #fff;
        line-height: 4.2vw;
        padding: 0 3px;
        span {
          transform: scale(0.9);
          display: inline-block;
        }
      }
    }
  }
</style>
