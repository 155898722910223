<template>
    <div class="swiper-wrap" v-if="visible" @click="close">
        <div id="shopDetailsVideo" class="video-wrap"></div>
    </div>
</template>

<script>
import Player from 'xgplayer';

export default {
    name: 'index',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            url: '',
        }
    },
    methods: {
        init (url) {
            this.$nextTick(() => {
                let sscmPlayer = new Player({
                    id: 'shopDetailsVideo',
                    // poster: "", // 封面图
                    url: url,
                    playsinline: true,
                    "x5-video-player-type": "h5",
                    "x5-video-player-fullscreen": "false",
                    closeVideoClick: true,
                    closeVideoTouch: true,
                    autoplay: true, // 自动播放
                    videoInit: true, // 显示首帧
                    fluid: true, // 流式布局
                    // fitVideoSize: "fixWidth",
                    ignores: ["volume"],
                });
            })
        },
        close () {
            this.$emit('update:visible', false);
        },
        isVideo (url) {
            if (url.split('.').pop() === 'mp4') {
                return true;
            }
            return false;
        },
        url2Id (url) {
            return url.replaceAll('.', '').replaceAll(':', '').replaceAll('/', '')
        }
    }
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import "@/styles/index.scss";

.swiper-wrap{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.7) !important;
    z-index: 99;
    .m-swipe{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
.video-wrap,.img-wrap{
    width: 100%;
    height: 40vh;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    img{
        width: 100%;
        display: block;
    }
}
</style>
