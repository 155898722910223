<!-- 市调报告 -->
<template>
    <div class="additionalSP">
        <StateBar :bgcolor="custom ? 'white' : 'transparent'" />
        <TitleHeader :title="title" :back="showBack" />

        <AutoView :footer="true" class="additionalSP--view" @scroll.native="pageScrollHandle">
            <div v-if="entity.cover" class="f-tac">
                <img v-if="entity.cover" :src="entity.cover" class="cover f-vmb" />
            </div>

            <!-- 行政区选择 REGION -->
            <div v-if="inputs[ 'REGION' ]" class="l-panel s-radius__s regionPicker" @click="onShowPicker">
                {{ form.REGION.text }}
            </div>
        </AutoView>

        <!-- 底栏提交按钮 -->
        <SoltFooter>
            <div class="additionalSP--footer">
                <van-button type="info" block class="s-radius__s" :disabled="disabled" :loading="submitting" @click="onCheck">{{ text }}</van-button>
            </div>
        </SoltFooter>

        <!-- 省市区选择 -->
        <OpenCityPicker type="openMarket" v-model="showRegionPicker" @confirm="getCityZone"/>

        <!-- 生成报告提示 -->
        <van-popup v-model="showPanel" position="bottom" :round="true">
            <!-- 非会员计次 -->
            <div v-if="! isVip" class="l-panel f-vp additionalSP--panel">
                <h3>市场调研报告查询次数</h3>
                <div class="card s-radius__s">
                    <h2>剩余{{ info.num }}次</h2>
                    <p>成功邀请1位好友，获得1次查询机会</p>
                </div>
                <template v-if="! enabled">
                    <div class="tips">开通会员随意查看！</div>
                    <van-button type="info" block class="s-radius__s vip" to="/vip">购买会员</van-button>
                    <van-button type="info" block class="s-radius__s" :to="{ name: 'inviteSP' }">分享领机会</van-button>
                </template>
                <template v-else>
                    <div class="tips full">开通会员随意查看</div>
                    <van-button type="info" block class="s-radius__s full" @click="onTap">生成报告</van-button>
                </template>
            </div>
            <!-- 会员 -->
            <div v-else class="l-panel f-vp additionalSP--panel">
                <div class="card s-radius__s vip">
                    <h2>参谋会员特权</h2>
                    <p>随时随地在线查看市调报告</p>
                </div>
                <van-button type="info" block class="s-radius__s full vip" @click="onTap">生成报告</van-button>
            </div>
        </van-popup>

        <!-- 全局遮罩层 -->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import OpenCityPicker from './components/openCityPicker';

export default {
    name      : 'additionalSP',
    components: {
        OpenCityPicker,
    },
    data () {
        return {
            title     : '请稍候',
            text      : '生成市场调研报告',
            showBack  : true,
            showPanel : false, // 状态面板
            loading   : false, // 数据加载状态
            submitting: false, // 提交状态
            disabled  : true, // 提交按钮禁用状态

            showRegionPicker: false, // 行政区选择

            custom: false, // 特别报告（首页栏目入口）
            entity: {}, // 模型实体（数据加载）
            inputs: {
                // GPS,
                // GPS_R,
                // INDUSTRY,
                // ATV,
                REGION: true,
            }, // 输入项标识
            // 数据结构
            form: {
                REGION: {
                    code: null,
                    text: '请选择城市区域',
                },
            },
            // 附加信息
            info: {
                isVip: false,
                num  : 0,
            },
        };
    },
    methods: {
        loadData ( entity ) {
            this.entity    = entity;
            let inputTypes = entity.inputTypes;
            if ( inputTypes ) {
                this.loading = false;
                if ( inputTypes.slice( inputTypes.length - 1 ) === ';' ) {
                    inputTypes = inputTypes.slice( 0, - 1 );
                }
                inputTypes.split( ';' ).forEach( ( key ) => {
                    this.inputs[ key ] = true;
                } );
            } else {
                this.onTap();
            }
            this.custom = !! entity.custom;
            this.title  = entity.title || entity.showQuestion || '补充信息';
        },
        validate ( form, needToast ) {
            const rule = {
                'REGION.code': '未选择城市区域',
            };
            for ( const key in rule ) {
                const keyArr = key.split( '.' );
                if ( ( keyArr[ 0 ] in this.inputs ) ) {
                    const val = keyArr.reduce( ( reduce, v ) => {
                        return reduce[v];
                    }, form );
                    if ( ! val ) {
                        if ( needToast ) {
                            this.$toast( rule[key] );
                        }
                        return  true;
                    }
                }
            }
            return false;
        },
        onShowPicker () {
            this.showRegionPicker = true;
        },
        onCheck () {
            // 微信环境直接触发报告生成
            if ( window.isMini ) {
                this.onTap( 4 ); // sourceType 4 = WX
                return;
            }
            this.HTTP.checkViewInfo().then( ( res ) => {
                this.info.isVip = res.isMember;
                this.info.num   = res.reportViewCnt;
                this.showPanel = true;
            } ).catch( ( e ) => {
                this.showPanel = true;
            } );
        },
        onTap ( sourceType ) {
            if ( this.submitting ) return;
            if ( typeof sourceType !== 'number' ) sourceType = 1;
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.CREATE.TAP_MK_GENERATE );
            this.showPanel  = false;
            this.submitting = true;
            const input     = [];
            for ( const key in this.inputs ) {
                input.push( {
                    code : key,
                    value: this.form[ key ],
                } );
            }
            const ent = this.entity;
            this.HTTP.createReport( ent.showTemplateCnfId, input, ent.showQuestion, ent.originalQuestion, sourceType ).then( ( res ) => {
                if ( res ) {
                    this.submitting = false;
                    this.$toast( '正在生成，请稍候...' );
                }
                this.goto( res.reportInstanceId, res.waitTime, res.type );
            } ).catch( ( e ) => {
                if ( e.code ) {
                    if ( e.code === 201004 ) {
                        this.backHome();
                        return;
                    } else {
                        this.$toast( e.msg || '网络连接不佳，请稍候重试！' );
                    }
                } else {
                    this.$toast( '网络连接不佳，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
        getCityZone ( picker, code ) {
            this.$set( this.form, 'REGION', {
                text: picker.join( '' ),
                code: code[ 2 ] || code[ 1 ] || code[ 0 ],
            } );
        },
        backHome () {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'gotoPage', 'home' );
            } else {
                this.$router.push( '/' );
            }
        },
        goto ( id, seconds, type ) {
            const ms = typeof seconds === 'number' ? seconds * 1000 : 0;
            if ( ms ) {
                this.loading = true;
            }
            setTimeout( () => {
                switch ( type ) {
                    case 2:
                        this.swRouter( '/pay/' + id );
                        break;
                    case 3:
                        this.swRouter( '/h5/#/my/reports' );
                        break;
                    default:
                        this.swRouter( { path: '/charts/' + id, query: { first: true } } ); // first 为首次打开
                }
            }, ms );
        },
    },
    computed: {
        enabled () {
            return this.info.num && this.info.num > 0;
        },
        isVip () {
            return this.info.isVip;
        },
    },
    watch: {
        // 监听数据变更
        form: {
            // immediate: true,
            deep: true,
            handler ( nv ) {
                // 进行数据校验
                this.disabled = this.validate( nv, false );
            },
        },
    },
    created () {
        // 直接传递提问信息
        let entity = this.$route.params;
        if ( ! entity || ! Object.keys( entity ).length ) {
            entity = this.$store.getters.currentQst;
        } else {
            this.$store.commit( 'setCurrentQst', entity );
        }
        if ( ! entity || ! Object.keys( entity ).length ) {
            this.backHome();
            return;
        }
        this.loadData( entity );
        // 获取定位信息
        if ( window.isClient ) {
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                if ( data ) {
                    this.$set( this.form, 'REGION', {
                        code: data.adCode,
                        text: data.province + data.city + data.district,
                    } );
                }
            } );
        } else {
            this.form.REGION.text = '请选择城市区域';
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.additionalSP {
    min-height: 100vh;
    background-color: $c-pageBg2;

    #{&}--view {
        padding: $margin $padding;

        .cover {
            width: 100%;
            max-width: 981px;
        }

        .l-panel {
            padding: $margin1;
        }

        .regionPicker {
            position: relative;
            font-size: $font_347;
            line-height: 4.93vw;
            font-weight: bold;
            padding-left: $padding * 1.75;
            background: white url(/static/icon/location.png) no-repeat $padding2 center;
            background-size: 5vw;

            &:after {
                position: absolute;
                content: '切换';
                right: $padding1;
                color: $c-main;
                font-weight: normal;
                font-size: $font_320;
            }
        }
    }

    #{&}--footer {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;

        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
    }

    #{&}--panel {
        h3 {
            font-size: $font_400;
            line-height: $font_560;
            text-align: center;
        }
        .card {
            height: 25.33vw;
            margin: $padding1 0 $padding2 0;
            background: linear-gradient(138deg,#F7F6FA,#EDEBF3);
            box-shadow: 0 0 0 1px #E1E2E4;
            text-align: center;

            h2 {
                font-size: $font_533;
                line-height: $font_747;
                margin-top: 6vw;
            }
            p {
                font-size: $font_320;
                line-height: $font_440;
                color: #676C84;
                margin-top: 1vw;
            }

            &.vip {
                background: url(/static/img/vip_card_clean.jpg) no-repeat;
                background-size: 100%;
                height: 38.67vw;
                box-shadow: none;
                margin-top: 0;
                h2 {
                    color: $c-vip3;
                    margin-top: 12.4vw;
                }
                p {
                    color: $c-gray2;
                    margin-top: 2vw;
                }
            }
        }
        .tips {
            position: relative;
            width: 42.27vw;
            height: 7vw;
            border-radius: 5vw;
            margin-bottom: 3vw;
            color: white;
            font-size: $font_293;
            line-height: 7vw;
            text-align: center;
            background: linear-gradient(to right, #F56958, #FB9491);
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: $padding;
                width: 3vw;
                height: 3vw;
                background-color: #F66E5F;
                border-radius: 2px;
                transform: rotate(45deg) translate(0, 50%);
            }
            &.full {
                width: 100%;
                color: #F58958;
                background: transparent;
                font-size: $font_320;
                &:after {
                    display: none;
                }
            }
        }
        ::v-deep .van-button {
            float: right;
            width: 42.27vw;
            font-size: $font_400;
            font-weight: bold;
            &.full {
                width: 100%;
            }
            &.vip {
                float: left;
                background: linear-gradient(to right, #FFF0DE, #FFE2BE);
                color: $c-notice;
                border-color: transparent;
            }
        }
    }
}
</style>
