<template>
    <div class="additional">
        <StateBar :bgcolor="custom ? 'white' : 'transparent'" />
        <TitleHeader v-if="custom" :title="title" :back="showBack" />
        <TitleHeader v-else bgcolor="transparent" :title="getTitle" :back="showBack" />

        <AutoView :footer="true" class="additional--view" @scroll.native="pageScrollHandle">
            <h1 v-if="! custom && title !== '补充信息'" class="title" ref="title">{{ title || '正在获取您的问题' }}</h1>

            <template v-if="custom">
                <div class="f-tac">
                    <img v-if="entity.cover" :src="entity.cover" class="cover" />
                </div>
                <label class="s-label" style="padding-left: 3.2vw">填写信息，大数据Ai选址快人一步。</label>
            </template>
            <label v-else class="s-label">请提供以下信息，以便我们更好的为您分析。</label>

            <!-- 地图交互 GPS GPS_R GPS_POI GPS_AREA_FENCE -->
            <div class="additional--container l-panel s-radius__s f-vmt">
                <div v-if="! custom && entity.inputTypes && entity.inputTypes.includes( 'GPS' )" class="l-panel s-radius__s">
                    <label require class="s-label">位置范围</label>
                    <!-- <div class="additional--map f-vmt" @click="showPoiPicker = true"> -->
                    <div class="additional--map f-vmt" @click="tapPoiPicker">
                        <template v-if="form.GPS_R.lng && form.GPS_R.lat">
                            <p class="addr-line1">已选位置</p>
                            <p class="addr-line2 fontbold">{{GPSInfo.name}}</p>
                            <p class="addr-line3">半径: {{GPSInfo.radius}}米</p>
                        </template>
                        <div v-else class="addr-empty">
                            <Icon name="input-locate" :width="4" :height="4" class="search-icon"></Icon>
                            <span>点击选择要参谋的位置</span>
                        </div>
                    </div>
                </div>

                <!-- 行政区选择 REGION -->
                <div v-if="inputs[ 'REGION' ]" class="l-panel s-radius__s">
                    <label require class="s-label">行政区</label>
                    <van-cell is-link arrow-direction="down" @click="showAreaPicker = true" value="选择行政区" :class="{fontbold:false}" class="s-select s-radius__s f-vmt" />
                </div>

                <!-- 行业选择 INDUSTRY -->
                <div v-if="inputs[ 'INDUSTRY' ]" class="l-panel s-radius__s">
                    <label require class="s-label">行业</label>
                    <van-cell is-link arrow-direction="down" @click="showIndustryPicker=true" :value="industryName||'选择行业'" :class="{fontbold:industryName}" class="s-select s-radius__s f-vmt" />
                </div>

                <!-- 人均消费选择 ATV -->
                <div v-if="inputs[ 'ATV' ]" class="l-panel s-radius__s">
                    <label require class="s-label">人均消费范围<span class="info">（元）</span></label>
                    <van-row class="f-vmt">
                        <van-col span="11">
                            <van-field v-model="form.ATV.min" type="digit" :class="{fontbold:form.ATV.min}" placeholder="最低价" maxlength="6" class="s-input s-radius__s" />
                        </van-col>
                        <van-col span="2" class="s-height f-fw">-</van-col>
                        <van-col span="11">
                            <van-field v-model="form.ATV.max" type="digit" :class="{fontbold:form.ATV.max}" placeholder="最高价" maxlength="6" class="s-input s-radius__s" />
                        </van-col>
                    </van-row>
                </div>

                <!-- 地图交互 应用于CUSTOM报告 仅仅展现位置不一样 -->
                <div v-if="custom && entity.inputTypes && entity.inputTypes.includes( 'GPS' )" class="l-panel s-radius__s">
                    <label require class="s-label">{{ entity.pickerTitle || '位置范围' }}</label>
                    <div class="additional--map f-vmt" @click="tapPoiPicker">
                        <template v-if="form.GPS_R.lng && form.GPS_R.lat">
                            <p class="addr-line1">已选位置</p>
                            <p class="addr-line2 fontbold">{{GPSInfo.name}}</p>
                            <p class="addr-line3">半径: {{GPSInfo.radius}}米</p>
                        </template>
                        <div v-else class="addr-empty">
                            <Icon name="input-locate" :width="4" :height="4" class="search-icon"></Icon>
                            <span>点击选择要参谋的位置</span>
                        </div>
                    </div>
                </div>
            </div>
        </AutoView>

        <!-- 底栏提交按钮 -->
        <SoltFooter>
            <div class="additional--footer">
                <van-button type="info" block class="s-radius__s" :disabled="disabled" :loading="submitting" @click="onTap">{{ text }}</van-button>
            </div>
        </SoltFooter>
        <!-- <ButtonFooter :text="text" :disabled="disabled" :loading="submitting" @click="onTap"></ButtonFooter> -->

        <!-- POI选择组件 -->
        <van-popup v-model="showPoiPicker" style="overflow-y:hidden">
            <POIPicker
                @confirm="selectedLocation"
                @cancel="showPoiPicker = false" />
        </van-popup>
        <!-- 省市区选择 TODO,待完成 -->
        <!-- <AreaPicker v-model="showAreaPicker"></AreaPicker> -->

        <!-- 行业选择 -->
        <IndustryPicker v-model="showIndustryPicker" @confirm="getIndustry"></IndustryPicker>
        <!-- 全局遮罩层 -->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
import Icon from '@/components/Icon/Icon';
import POIPicker from '@/components/POIPicker/index';
import AreaPicker from '@/components/AreaPicker/index';
import IndustryPicker from './components/IndustryPicker';

export default {
    name      : 'additional',
    components: {
        Icon,
        POIPicker,
        AreaPicker,
        IndustryPicker
    },
    data () {
        return {
            title     : '请稍候',
            text      : '参谋一下',
            showTitle : false,
            showBack  : true,
            disabled  : true, // 提交按钮禁用状态
            submitting: false, // 提交状态
            loading   : false, // 数据加载状态
            entity    : {}, // 模型实体（数据加载）
            custom    : false, // 特别报告（首页栏目入口）
            inputs    : {
                // GPS,
                // GPS_R,
                // INDUSTRY: true,
                // ATV     : true,
            }, // 输入项标识

            showPoiPicker     : false,
            showIndustryPicker: false,
            descIndustry      : '', // 子行业名称
            industryName      : '', // 行业名称组合
            // 数据结构
            form              : {
                GPS: {
                    lat: null,
                    lng: null,
                },
                GPS_R: {
                    lat     : null,
                    lng     : null,
                    radiusKm: 0.5,
                    poiName : null,
                },
                INDUSTRY: {
                    formatCategoryCode: null,
                },
                ATV: {
                    min: null,
                    max: null,
                },
            },
            GPSInfo: {
                name  : '',
                radius: 0
            },
        };
    },
    computed: {
        getLocation () {
            if ( this.form.GPS_R.lng && this.form.GPS_R.lat && this.GPSname ) {
                return this.GPSInfo;
            }
            return null;
        },
        getTitle () {
            return this.title === '补充信息' ? this.title : this.showTitle ? this.title : '';
        },
    },
    methods: {
        tapPoiPicker () {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'callLocationPicker', {
                    component: {
                        poi   : true, // 位置选择（扎针）
                        now   : true, // 展示当前位置
                        radius: true, // 半径选择
                        search: true, // 搜索栏
                    },
                    value: {
                        lat   : this.form.GPS_R.lat,
                        lng   : this.form.GPS_R.lng,
                        radius: this.form.GPS_R.radiusKm * 1000, // 单位米
                        search: this.form.GPS_R.poiName,
                    },
                }, ( data ) => {
                    // 将数据转换后发送到处理方法
                    if ( data ) {
                        if ( typeof data === 'string' ) {
                            data = JSON.parse( data ); // 兼容安卓数据解析
                        }
                        this.selectedLocation( [ data.lng, data.lat, data.search ], data.radius );
                    }
                } );
            } else {
                // WEB调用本地控件
                this.showPoiPicker = true;
            }
        },
        selectedLocation ( e, radius ) {
            this.form.GPS_R.lng      = e[ 0 ];
            this.form.GPS_R.lat      = e[ 1 ];
            this.form.GPS_R.poiName  = e[ 2 ];
            this.form.GPS_R.radiusKm = radius / 1000;
            this.GPSInfo             = { name: e[2], radius };
            this.showPoiPicker       = false;
        },
        pageScrollHandle () {
            if ( this.custom || this.loading || this.title === '补充信息' ) return;
            this.showTitle = this.$refs.title.getBoundingClientRect().top < 0;
        },
        getIndustry ( picker, code ) {
            this.descIndustry = picker[ 2 ] || picker[ 1 ] || picker[ 0 ];
            this.industryName = picker.filter( ( item ) => !! item ).join( '-' );
            this.form.INDUSTRY.formatCategoryCode = code[code.length - 1];
        },
        loadData ( entity ) {
            this.entity    = entity;
            let inputTypes = entity.inputTypes;
            if ( inputTypes ) {
                this.loading = false;
                if ( inputTypes.slice( inputTypes.length - 1 ) === ';' ) {
                    inputTypes = inputTypes.slice( 0, - 1 );
                }
                inputTypes.split( ';' ).forEach( ( key ) => {
                    this.inputs[ key ] = true;
                } );
            } else {
                this.onTap();
            }
            this.custom = !! entity.custom;
            this.title  = entity.title || entity.showQuestion || '补充信息';
        },
        validate ( form, needToast ) {
            const rule = {
                'GPS.lat'                    : '未选择位置范围',
                'GPS_R.lat'                  : '未选择位置范围',
                'INDUSTRY.formatCategoryCode': '未选择行业',
                'ATV.min'                    : '人均消费范围',
                'ATV.max'                    : '人均消费范围',
            };
            for ( const key in rule ) {
                const keyArr = key.split( '.' );
                if ( ( keyArr[ 0 ] in this.inputs ) ) {
                    const val = keyArr.reduce( ( reduce, v ) => {
                        return reduce[v];
                    }, form );
                    if ( ! val ) {
                        if ( needToast ) {
                            this.$toast( rule[key] );
                        }
                        return  true;
                    }
                }
            }
            return false;
        },
        onTap () {
            // TODO
            // 1校验并提交数据
            // 2获取报告ID
            // 3等待报告生成
            // 4跳转至报告查看
            if ( this.inputs.ATV ) {
                if ( Number( this.form.ATV.min ) >  Number( this.form.ATV.max ) ) {
                    this.$toast( '正确填写人均消费' );
                    return;
                }
            }
            if ( this.submitting ) {
                this.$toast( '正在提交中...' );
                return;
            }
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.CREATE.TAP_ADVISE );
            this.submitting = true;
            const input = [];
            for ( const key in this.inputs ) {
                input.push( {
                    code : key,
                    value: this.form[ key ],
                } );
            }
            const ent = this.entity;
            // 选址分析报告标题处理
            if ( this.custom && ent.showTemplateCnfId === 10001 ) {
                // {POI}周边{半径}米的{行业}行业选址分析
                ent.showQuestion = `${this.form.GPS_R.poiName}周边${this.form.GPS_R.radiusKm * 1000}米的${this.descIndustry}行业选址分析报告`;
            }
            this.HTTP.createReport( ent.showTemplateCnfId, input, ent.showQuestion, ent.originalQuestion ).then( ( res ) => {
                const id     = res.reportInstanceId;
                const report = {
                    id,
                    title : this.title,
                    labels: this.entity.modelNames || [],
                };
                this.$store.commit( 'setReport', report );
                this.submitting = false;
                this.$toast( '正在生成，请稍候...' );
                this.goto( res.reportInstanceId, res.waitTime, res.type );
            } ).catch( ( e ) => {
                if ( e.code ) {
                    if ( e.code === 201004 ) {
                        this.backHome();
                        return;
                    }
                } else {
                    this.$toast( '网络连接不佳，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
        backHome () {
            if ( window.isClient ) {
                this.JSBridge.callHandler( 'gotoPage', 'home' );
            } else {
                this.$router.push( '/' );
            }
        },
        goto ( id, seconds, type ) {
            const ms = typeof seconds === 'number' ? seconds * 1000 : 0;
            if ( ms ) {
                this.loading = true;
            }
            setTimeout( () => {
                switch ( type ) {
                    case 2:
                        this.swRouter( '/pay/' + id );
                        break;
                    case 3:
                        this.swRouter( '/h5/#/my/reports' );
                        break;
                    default:
                        this.swRouter( { path: '/charts/' + id, query: { first: true } } ); // first 为首次打开
                }
            }, ms );
        },
    },
    watch: {
        // 监听数据变更
        form: {
            deep: true,
            handler ( nv ) {
                // 进行数据校验
                this.disabled = this.validate( nv, false );
            },
        },
        showPoiPicker ( val ) {
            this.showBack = ! val;
        },
    },
    created () {
        // 根据ID获取提问信息
        if ( this.$route.params.id ) {
            this.loading = true;
            this.HTTP.getQuestion( this.$route.params.id ).then( ( res ) => {
                if ( res ) {
                    if ( ! res.hasOwnProperty( 'showQuestion' ) && this.$route.query.title ) {
                        res.showQuestion = this.$route.query.title;
                    }
                    this.loadData( res );
                } else {
                    this.$toast( '该内容已无法查看，去首页找找类似的问题吧！' );
                    setTimeout( () => {
                        this.backHome();
                    }, 2000 );
                }
            } );
            return;
        }
        // 直接传递提问信息
        const entity = this.$route.params;
        if ( ! entity || ! Object.keys( entity ).length ) {
            this.backHome();
        }
        this.loadData( entity );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.additional {
    min-height: 100vh;
    background-color: $c-pageBg2;

    #{&}--view {
        padding: $margin $padding;

        .cover {
            width: 100%;
            max-width: 981px;
        }

        h1 {
            font-size: $font_533;
            line-height: $font_747;
        }
        .l-panel {
            padding: $margin1;

            .info {
                color: $c-info;
                font-size: 2.93vw;
            }
            .s-label {
                color: $c-text;
                font-size: $font_320;
                margin: .4vw 0 3.6vw;
            }
        }
        .s-label {
            color: $c-info;
            margin-top: $margin;
        }
        .s-height {
            line-height: $cellHeight;
            text-align: center;
        }
    }

    #{&}--container {
        padding-bottom: 0!important;
        .l-panel {
            padding: 0;
            margin-bottom: $margin;
        }
    }

    #{&}--map {
        background: url(/static/img/map.jpg) no-repeat left top;
        background-size: 100% 100%;
        width: 78.93vw;
        min-height: 25.33vw;
        font-size: 3vw;
        color: $c-main;
        text-align: center;
        padding-top: 4vw;
        padding-bottom: 4vw;
        box-sizing: border-box;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        p{
            margin: 0;
            padding: 0;
        }
        .addr-line1{
          line-height: 5vw;
          font-size:3vw;
          font-weight:normal;
          color:rgba(142,144,153,1);
        }
        .addr-line2{
            line-height: 7vw;
        }
        .addr-line3{
          line-height: 5vw;
        }
        .addr-empty{
            margin:4.5vw auto;
            width:43vw;
            height:9vw;
            line-height: 9vw;
            font-size:3vw;
            border-radius: 2vw;
            background:rgba(81,140,219,1);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .fontbold{
        font-size: $font_427;
        font-weight:700;
        color:rgba(29,34,51,1) !important;
        ::v-deep .van-cell__value--alone{
           font-size: $font_427;
            font-weight:700;
            color:rgba(29,34,51,1) !important;
        }
    }

    #{&}--footer {
        width: 100%;
        height: $footerHeight;
        padding: $headerMargin $padding;
        box-sizing: border-box;

        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
        }
    }
}
</style>
