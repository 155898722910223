/**
 * 寻址
 */

const LocatingAd = () => import(/* webpackChunkName: "module-locating" */ "@/views/locating/ad");

export default [
  {
    path: "/locating/ad",
    name: "LocatingAd",
    component: LocatingAd,
    meta: { id: 10330, keepAlive: true }
  },
]
