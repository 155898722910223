<!--
省市区选择 TODO 暂时未完成，后期迭代完成
Props:

Events:

Methods:

-->
<template>
    <van-popup position="bottom" v-model="showPop">
        <van-area
            title="标题"
            :area-list="areaList"
            :columns-placeholder="['请选择', '请选择', '请选择']"
        />
    </van-popup>
</template>
<script>
/* eslint-disable camelcase */
const list = {
    province_list: {
        110000: '北京市',
        120000: '天津市'
    },
    city_list: {
        110100: '北京市',
        110200: '县',
        120100: '天津市',
        120200: '县'
    },
    county_list: {
        110101: '东城区',
        110102: '西城区',
        110105: '朝阳区',
        110106: '丰台区',
        120101: '和平区',
        120102: '河东区',
        120103: '河西区',
        120104: '南开区',
        120105: '河北区',
        // ....
    }
};
export default {
    model: {
        prop : 'show',
        event: 'showArea'
    },
    props: {
        show: {
            type   : Boolean,
            default: false,
        }
    },
    data () {
        return {
            areaList: list
        };
    },
    computed: {
        showPop: {
            get () {
                return this.show;
            },
            set ( nv ) {
                this.$emit( 'showArea', nv );
            }
        }
    }
};
</script>
