import { Toast } from "vant";

function getBrowser() {
  var ua = window.navigator.userAgent || "";
  var isAndroid = /android/i.test(ua);
  var isIos = /iphone|ipad|ipod/i.test(ua);
  var isWechat = /micromessenger\/([\d.]+)/i.test(ua);
  var isWeibo = /(weibo).*weibo__([\d.]+)/i.test(ua);
  var isQQ = /qq\/([\d.]+)/i.test(ua);
  var isQQBrowser = /(qqbrowser)\/([\d.]+)/i.test(ua);
  var isQzone = /qzone\/.*_qz_([\d.]+)/i.test(ua);
  var isOriginalChrome = /chrome\/[\d.]+ Mobile Safari\/[\d.]+/i.test(ua) && isAndroid;

  var isSafari = /safari\/([\d.]+)$/i.test(ua) && isIos && ua.indexOf("Crios") < 0 && ua.indexOf("Mozilla") === 0;

  return {
    isAndroid: isAndroid,
    isIos: isIos,
    isWechat: isWechat,
    isWeibo: isWeibo,
    isQQ: isQQ,
    isQQBrowser: isQQBrowser,
    isQzone: isQzone,
    isOriginalChrome: isOriginalChrome,
    isSafari: isSafari
  };
}
var browser = getBrowser();
/**
 * 获取页面隐藏属性的前缀
 * 如果页面支持 hidden 属性，返回 '' 就行
 * 如果不支持，各个浏览器对 hidden 属性，有自己的实现，不同浏览器不同前缀，遍历看支持哪个
 */
function getPagePropertyPrefix() {
  var prefixes = ["webkit", "moz", "ms", "o"];
  var correctPrefix = void 0;

  if ("hidden" in document) return "";

  prefixes.forEach(function(prefix) {
    if (prefix + "Hidden" in document) {
      correctPrefix = prefix;
    }
  });

  return correctPrefix || false;
}
/**
 * 判断页面是否隐藏（进入后台）
 */
function isPageHidden() {
  var prefix = getPagePropertyPrefix();
  if (prefix === false) return false;

  var hiddenProperty = prefix ? prefix + "Hidden" : "hidden";
  return document[hiddenProperty];
}
/**
 * 获取判断页面 显示|隐藏 状态改变的属性
 */
function getVisibilityChangeProperty() {
  var prefix = getPagePropertyPrefix();
  if (prefix === false) return false;

  return prefix + "visibilitychange";
}
/**
 * 检测是否唤端成功
 * @param {function} cb - 唤端失败回调函数
 */
function checkOpen(cb, timeout) {
  var visibilityChangeProperty = getVisibilityChangeProperty();
  var timer = setTimeout(function() {
    var hidden = isPageHidden();
    if (!hidden) {
      cb();
    }
  }, timeout);

  if (visibilityChangeProperty) {
    document.addEventListener(visibilityChangeProperty, function() {
      clearTimeout(timer);
    });
    return;
  }

  window.addEventListener("pagehide", function() {
    clearTimeout(timer);
  });
}
/**
 * 获取 ios 大版本号
 */
function getIOSVersion() {
  var verion = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return parseInt(verion[1], 10);
}

/**
 * 通过 A 标签唤起
 * @param {string} uri - 需要打开的地址
 */
function evokeByTagA(uri) {
  const tagA = document.createElement("a");
  tagA.setAttribute("href", uri);
  tagA.style.display = "none";
  document.body.appendChild(tagA);
  tagA.click();
}

/**
 * 通过 iframe 唤起-ios9以上版本不支持
 * @param {string}} [uri] - 需要打开的地址
 */
function evokeByIFrame(uri) {
  let iframe = null;
  if (!iframe) {
    iframe = document.createElement("iframe");
    iframe.frameborder = "0";
    iframe.style.cssText = "display:none;border:0;width:0;height:0;";
    document.body.appendChild(iframe);
  }
  iframe.src = uri;
}

/**
 * 微信环境提示浏览器打开
 */
function createBowerTip() {
  let openBowerTip = document.getElementById("openBowerTip");
  if (openBowerTip) {
    openBowerTip.style.display = "block";
    openBowerTip.onclick = function() {
      openBowerTip.style.display = "none";
    };
  } else {
    Toast('请点击右上角，选择"在浏览器或者Safari中打开"');
  }
}

/**
 *
 * @param {*} deeplinkurl //唤起APP链接
 * @param {*} landurl //唤起失败 跳到落地页
 */
var callApp = function(deeplinkurl, landurl) {
  if (browser.isWechat) {
    return createBowerTip();
  }
  Toast.loading({
    message: "加载中...",
    position: "middle",
    forbidClick: true,
    loadingType: "circular",
    duration: "3500"
  });

  checkOpen(() => {
    var imgId = "e_landurl_" + new Date().getTime();
    var img = new Image();
    window[imgId] = img;
    img.onload = img.onerror = function() {
      window[imgId] = null;
      window.location.href = landurl;
    };
    img.src = landurl; //下载落地页
  }, 2000);

  // 吊起app
  if (/:\/\//.test(deeplinkurl)) {
    if (browser.isIos) {
      if (getIOSVersion() < 9) {
        evokeByIFrame(deeplinkurl);
      } else {
        window.location.href = deeplinkurl;
      }
    } else {
      evokeByTagA(deeplinkurl);
      // window.location.href = deeplinkurl;
    }
  }
};
/***下方为主文件 */
var vcallapp = {
  install: function(Vue, options) {
    Vue.directive("callapp", {
      // 绑定的元素插入到 DOM 中
      inserted(el) {
        console.log("vcallapp shoud work fun");
      },
      // 初始化设置
      bind(el, binding, vnode) {
        let _value = binding.value,
          deeplinkurl = _value.deeplinkurl,
          landurl = _value.landurl;
        if (deeplinkurl && landurl) {
          el.__callAppClick__ = clickHandler;
          el.addEventListener("click", clickHandler);
        } else {
          console.error("v-callapp确少参数");
        }
        function clickHandler(e) {
          callApp(deeplinkurl, landurl);
        }
      },
      //指令与元素解绑时使用
      unbind(el, binding) {
        //解除事件监听
        el.removeEventListener("click", el.__callAppClick__);
        el.__callAppClick__ = null;
      }
    });
  }
};

export default vcallapp;
