<template>
  <div class="unlock-wrap">
    <h3>报告解锁成功</h3>
    <div class="unlock-card">
      <h4>{{ reportName }}</h4>
      <ul class="tips-flex">
        <li class="li-poit"></li>
        <li class="li-right">因大数据分析、专家审核需要，报告将在24小时内发送，请在“我的-我的报告”中查看！（节日、周末顺延至工作日）</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reportName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.unlock-wrap {
  background: #fff;
  h3 {
    font-size: 4vw;
    color: #ff5900;
  }
  .unlock-card {
    margin-top: 4vw;
    padding: 3.2vw;
    background: #f8f8f9;
    border-radius: 0.8vw;
    text-align: left;
    h4 {
      font-size: 3.73vw;
      color: #1d2233;
      line-height: 4.8vw;
    }
    .tips-flex {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 2.67vw;

      .li-poit {
        width: 1.87vw;
        height: 1.87vw;
        background: #ffab7e;
        border-radius: 50%;
        margin: 1vw 1vw 0 0;
      }
      .li-right {
        flex: 1;
        font-size: 3.2vw;
        color: #666;
        line-height: 4.8vw;
      }
    }
  }
}
</style>
