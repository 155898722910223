<template>
    <div class="brand">
        <StateBar bgcolor="white" />
        <TitleHeader title="品牌对比" :back="true"></TitleHeader>

        <!-- 骨架屏占位图 -->
        <!-- <img v-if="loading" src="/static/skt/introduce.png" width="100%" /> -->
        <AutoView :header="true" :footer="true" class="brand--view">
            <van-skeleton :loading="! tabList" title avatar :animate="true" :row="10" class="s-skeleton">
                <div class="brand--contentHeight" ref="contrastContent">
                    <div class="brand--headerCon">
                        <div class="tip-title">请选择对比的品牌（至少2个）</div>
                        <!-- 标题栏目 -->
                        <ul class="tab-contaner">
                            <li v-for="(item, index) in tabIndexList" :key="index" :data-bool="''+item.isCheck" :class="keyIndex === index ? 'activeClass':''" @click="clickTab($event,index,item.categoryCode)">{{item.categoryName}}</li>
                        </ul>
                    </div>
                    <!-- :index-list="item.key" -->
                    <div class="brand-content" >
                        <div v-for="(item, ins) in tabList" :key="ins" >
                            <div v-if="keyIndex === ins">
                                <van-index-bar  class="index_bar_list">
                                    <div v-for="(label,i) in item.brands" :key="i" >
                                        <van-index-anchor :index="label.key" class="active_item">{{label.key}}</van-index-anchor>
                                        <div class="cell_list">
                                            <div class="cell_list_item" v-for="(citem, key) in label.brandLists" :key="key">
                                                <input type="checkbox" class="list_check" v-model="citem.checked" @change="clickCheck(citem.code,key,i)">
                                                <van-image fit="cover" class="list_img" lazy-load :src="citem.logo" />
                                                <div class="lsit_name">
                                                    <span>{{citem.name}}</span>
                                                    <span v-if="citem.categoryCode == 'W01'">餐饮</span>
                                                    <span v-else-if="citem.categoryCode == 'W02'">零售</span>
                                                    <span v-else>教育</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </van-index-bar>
                            </div>
                        </div>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>
        <SoltFooter  class="brand--footerBar">
            <div class="view" v-show="tabList">
                <van-button type="info" block class="btn1" :loading="submitting" @click="onTap">立即对比</van-button>
            </div>
        </SoltFooter>
        <!-- 全局加载遮罩-->
        <LoadingOverlay :show="loading" />
    </div>
</template>

<script>
export default {
    name: 'contrast',
    data () {
        return {
            loading  : false, // 数据加载状态
            submitting      : false, // 提交状态
            disabled        : false, // 提交按钮禁用状态
            tabList: null,
            keyIndex: 0,
            indexList: [],
            tabIndexList: [],
            selectCode: [],   //选中的值
            form: {
                brandCodes: [],  //品牌code
                categoryCode: "",  // 行业
                cityCode: '',  // 城市
                cityName    : '', // 城市名称
                regionCode: '',   // 行政区
                costMax: null,
                costMin: null,
                isIndex: 1,
                title  : "品牌对比"
            },
        };
    },
    methods: {
        scrollTop() {
          const comRef = this.$refs["contrastContent"];
          if (comRef) {
            comRef.scrollTo(0, 0);
          }
        },
        // 立即对比
        onTap() {
            if(this.selectCode.length < 2 || this.selectCode.length > 3) {
                this.$toast( '请选择2-3个对比的品牌' );
                return false;
            }
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.BRAND.TAP_CONTRAST );
            // this.submitting  = true;
            this.form.brandCodes = this.selectCode;
            this.swRouter( {
                path : '/brand/recommend',
                query: this.form,
            } );
        },
        // tab切换
        clickTab(e,index, code) {
            this.scrollTop();
            this.keyIndex = index;
            this.selectCode = [];
            this.tabList[index].brands.map(item => {
                item.brandLists.map(citem => {
                    if(citem.checked == true) {
                        return this.selectCode.push(citem.code)
                    }
                })
            })
        },
        // 点击复选框
        clickCheck(code,i,index){
            this.indexList[index].brandLists[i].checked = this.indexList[index].brandLists[i].checked;
            if(this.indexList[index].brandLists[i].checked === false) { //非选中
                for(let i=0; i<this.selectCode.length; i++) {
                    if(this.selectCode[i] === code) {
                        this.selectCode.splice(i, 1)
                    }
                }
            } else {  //选中
                this.selectCode.push(code)
            }
        },
    },
    created () {
        // 获取定位信息
        if ( window.isClient ) {
            this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
                if ( typeof data === 'string' ) data = JSON.parse( data );
                if ( data ) {
                    this.form.cityCode   = data.adCode.substr( 0, 4 ) + '00';
                    this.form.regionCode = data.adCode;
                    this.form.cityName   = data.city + data.district;
                }
            } );
        }
        this.HTTP.contrastList().then( res => {
            this.tabList = res;
            this.tabIndexList = res.map((item , index) => {
                if(index == 0) {
                    return {
                        categoryCode: item.categoryCode,
                        categoryName: item.categoryName,
                        isCheck     : true,
                    }
                } else {
                    return {
                        categoryCode: item.categoryCode,
                        categoryName: item.categoryName,
                        isCheck     : false,
                    }
                }

            })
            this.indexList = res[0].brands;
            this.form.categoryCode = res[0].categoryCode;
        }).catch(err => {})
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
$titleSize: 4.267vw; //32px
.brand {
    height: 100vh;
    background: #F8F8F9;
    #{&}--headerCon{
        position: sticky;
        background-color: #fff;
        z-index: 2;
        top: 0;
        .tip-title{
            width: 100%;
            padding: 3.33vw 6.53vw 2.933vw;
            box-sizing: border-box;
            font-size: $titleSize;
            font-weight: bold;
            color: #1D2233;
            line-height: 6vw;
        }
        .tab-contaner{
            width: 100%;
            padding: 3.33vw 6.8vw;
            box-sizing: border-box;
            background: #fff;
            height: 12vw;
            font-size: 0;
            line-height: 0;
            overflow-x: auto;
            white-space: nowrap;
            li{
                display: inline-block;
                font-size: 3.733vw;
                color: #8E9099;
                line-height: 5.333vw;
                margin-right: 8vw;
                transition: all .3s;
                &:last-child{
                    margin-right: 0;
                }
            }
            li.activeClass{
                font-size: 4.267vw;
                font-weight: bold;
                color: #518CDB;
                position: relative;
            }
            li.activeClass::before{
                position: absolute;
                bottom: -3.33vw;
                left: 50%;
                margin-left: -4vw;
                content: " ";
                width: 8vw;
                height: 0.8vw;
                background: #518CDB;
                border-radius: 0.4vw;
            }
        }
    }
    #{&}--contentHeight{
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
    #{&}--view {
        scroll-behavior: smooth;
        .brand-content {
            width: 100%;
            .index_bar_list{
                .active_item{
                    height: 7.33vw;
                }
                .active_item ::v-deep.van-index-anchor{
                    line-height: 7.33vw;
                    padding: 0 6.67vw;
                    box-sizing: border-box;
                    color: #8E9099;
                    font-size: 3.467vw;
                }
                .active_item ::v-deep.van-index-anchor--sticky{
                    color: #518CDB;
                    display: none;
                }
                .cell_list{
                    padding: 0 4vw;
                    background: #fff;
                    width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    .cell_list_item{
                        border-bottom: 0.267vw solid #E7E7E7;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2.13vw 2.67vw;
                        &.cell_list_item:last-child{
                            border-bottom: none;
                        }
                        .list_check{
                            width: 4vw;
                            height: 4vw;
                            margin: 0;
                            border: 0.267vw solid #999999;
                        }
                        .list_img{
                            width: 10.667vw;
                            height: 10.667vw;
                            margin: 0 2.4vw 0 2.93vw;
                            border-radius: 1vw;
                            overflow: hidden;
                        }
                        .lsit_name{
                            flex: 1;
                            line-height: 5.33vw;
                            span:first-child{
                                font-size: 3.733vw;
                                color: #1D2233;
                            }
                            span:last-child{
                                background: #FFF1E9;
                                border-radius: 0.667vw;
                                border: 0.267vw solid #FF9F6B;
                                font-size: 3.2vw;
                                font-weight: 400;
                                color: #FF5900;
                                line-height: 4.4vw;
                                padding: 0.267vw 2.4vw;
                                box-sizing: border-box;
                            }
                        }
                    }
                    // .cell_list_item ::v-deep.van-cell,
                    // .cell_list_item ::v-deep.van-checkbox__icon .van-icon{
                    //     padding: 0;
                    //     width: 4vw;
                    //     height: 4vw;
                    //     box-sizing: border-box;
                    //     line-height: 0;
                    // }
                    // .cell_list_item ::v-deep.van-checkbox__icon{
                    //     height: 4vw;
                    //     line-height: 4vw;
                    // }
                    // .cell_list_item ::v-deep.van-field__control--custom{
                    //     display: inline;min-height: 4vw;
                    // }
                    // .cell_list_item ::v-deep.van-checkbox__icon .van-icon{
                    //     border: 0.267vw solid #999999;
                    //     border-radius: 0.8vw;
                    //     line-height: 4vw;
                    //     font-size: 3.2vw;
                    // }
                    // .cell_list_item ::v-deep.van-checkbox__icon--checked .van-icon{
                    //     background: #518CDB;
                    //     font-size: 3.2vw;
                    //     line-height: 4vw;
                    //     border: 0.267vw solid #518CDB;
                    // }
                }
            }
            .index_bar_list ::v-deep.van-index-bar__index--active{
                color: #518CDB;
            }
            .index_bar_list ::v-deep.van-index-bar__index{
                padding: 0 3.067vw 0.67vw 0;
                font-size: 2.934vw;
            }
        }
    }
    #{&}--footerBar {
        z-index: 3;
        box-shadow: 0 -.5vw 2vw rgba(black, .05);
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            // height: $footerHeight;
        }
    }
}
</style>
