<template>
    <div class="card">
        <div class="title">
            <div :class="isRequired ? 'required' : 'f-label'" class="label">{{ label }}</div>
            <div v-if="remark" class="remark">{{ remark }}</div>
            <div v-if="error" class="error">{{ error }}</div>
        </div>
        <div v-if="type === 'card'" class="item_wrapper">
            <template v-if="!multiple">
                <div class="item" :class="{ actived: value === item.name }" v-for="item in list" :key="item.name" @click="selectValue(item.name)">
                    {{ item.name }}
                </div>
            </template>
            <template v-else>
                <div
                    class="item"
                    :class="{ actived: multipleList2.includes(item.name) }"
                    v-for="item in list"
                    :key="item.name"
                    @click="selectValue(item.name)"
                >
                    {{ item.name }}
                </div>
            </template>
        </div>
        <div v-if="type === 'input'" class="input">
            <van-field v-model="inputValue" :maxlength="maxlength" :disabled="isdisabled" :placeholder="placeholder" />
        </div>
        <div v-if="type === 'number'" class="input">
            <van-field v-model="inputValue" type="number" :maxlength="maxlength" :placeholder="placeholder" />
        </div>
        <div v-if="type === 'select'" class="select">
            <div class="select-wrapper" @click="clickSelect">
                <div class="select-value" :class="{ 'select-empty': !value }">{{ value || "请选择" }}</div>
                <div class="pickerIcon"></div>
            </div>
            <van-popup v-model="showSelect" round position="bottom">
                <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="this.showSelect = false" />
            </van-popup>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isdisabled: {
            type: Boolean,
            default: false
        },
        label: String,
        error: String,
        remark: String,
        placeholder: String,
        value: String,
        name: String,
        maxlength: {
            type: Number,
            default: 200
        },
        errname: String,
        type: {
            type: String,
            default: "card"
        },
        multiple: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: function() {
                return [];
            }
        },
        multipleList: {
            type: Array,
            default: function() {
                return [];
            }
        },
        columns: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {
            showSelect: false,
            multipleList2: []
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val, this.name, this.errname);
            }
        }
    },
  watch: {
    multipleList(nv) {
      this.multipleList2 = nv || []
    }
  },
    methods: {
        selectValue(value) {
            console.log(value);
            if (!this.multiple) {
                this.$emit("selectValue", value, this.name, this.errname);
            } else {
                if (this.multipleList2.indexOf(value) > -1) {
                    this.multipleList2.splice(this.multipleList2.indexOf(value), 1);
                } else {
                    this.multipleList2.push(value);
                }
                this.$emit("multipleSelect", this.multipleList2, this.name, this.errname);
            }
        },
        clickSelect() {
            this.showSelect = true;
        },
        onConfirm(e) {
            this.showSelect = false;
            this.$emit("onConfirm", e, this.name, this.errname);
        }
    }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";
.card {
    color: $c-text;
    font-size: $font_347;
    margin-top: 2vw;
    .title {
        display: flex;
        margin-bottom: 2.667vw;
        .label {
            font-weight: bold;
            margin-right: 4vw;
            color: #121622;
        }
        .f-label {
            margin-right: 2vw;
        }
        .remark {
            margin-right: 2vw;
            color: #8893a7;
            font-size: 3.467vw;
        }
        .error {
            color: #ff504e;
        }
        .required {
            position: relative;
            &::after {
                content: "*";
                position: absolute;
                right: -2vw;
                color: #ff504e;
            }
        }
    }
    .item_wrapper {
        margin-top: 2vw;
        font-size: 3.33vw;
        display: flex;
        flex-wrap: wrap;
        .item {
            width: 29vw;
            height: 9.33vw;
            line-height: $font_440;
            background-color: #f7f8fb;
            border: 0.133vw solid #e4e7ed;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.667vw;
            margin-right: 2.4vw;
            border-radius: $radius-xs;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        .actived {
            background-color: rgba(52, 127, 255, 0.1);
            border: 0.133vw solid $c-main;
            color: $c-main;
        }
    }
    ::v-deep .van-cell {
        border: 0.133vw solid #e4e7ed;
        background-color: #f7f8fb;
        padding: 1.33vw 2.66vw;
        margin-bottom: 2.667vw;
        border-radius: $radius-xs;
        ::-webkit-input-placeholder {
            color: #abb3c1;
        }
    }
    .select-wrapper {
        height: 10.667vw;
        background: #f7f8fb;
        padding: 0 2vw;
        margin: 2.667vw 0;
        border-radius: $radius-xs;
        border: 0.133vw solid #ebeaee;
        @include flex;
        .select-value {
            font-size: $font_347;
            color: #1d2233;
        }
        .select-empty {
            color: #abb3c1;
        }
        .pickerIcon {
            width: 2.4vw;
            height: 1.733vw;
            background-image: url("/static/icon/input_icon-choice.png");
            background-size: 100% 100%;
        }
    }
}
</style>
