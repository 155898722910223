<!--
滑动弹出层

Props:
  v-model: 是否展开弹出层
  duration: 动画时长(ms)
  closeOnClickMask: 点击遮罩层关闭
Events:
  change: 开关状态改变时触发
-->

<template>
  <div class="slide-popup">
    <div class="mask"
         v-show="isShowMask"
         :style="{ opacity: opacity, transition: noTransition ? 'none' : `opacity ${duration}ms` }"
         @touchend="maskClick"></div>
    <div class="body" :style="{transform: `translateY(${translateDistance}px)`, transition: noTransition ? 'none' : `transform ${duration}ms`,}">
      <div class="sticky-box">
        <div class="header"
             @touchstart="touchStart"
             @touchmove.prevent="touchMove"
             @touchend="touchEnd">
          <div class="slide-btn"></div>
        </div>
        <slot name="sticky"></slot>
      </div>
      <div class="content" ref="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
const MASK_OPACITY = 0.79;

let startY = 0;
let prevY = 0;
let startTime = 0;
let contentHeight = 0;
let maskTimer;

export default {
    name : 'SlidePopup',
    model: {
        prop : 'open',
        event: 'change'
    },
    props: {
        open: {
            type   : Boolean,
            default: false
        },
        duration: {
            type   : Number,
            default: 200 // 0 200
        },
        closeOnClickMask: {
            type   : Boolean,
            default: true
        },
        bottomDis: {
            default: 0 // 单位为vh,离底部的距离
        }
    },
    data () {
        return {
            initialized      : false,
            isOpen           : this.open,
            isShowMask       : this.open,
            translateDistance: 0,
            opacity          : this.open ? MASK_OPACITY : 0,
            isDragging       : false
        };
    },
    computed: {
        noTransition () {
            return this.isDragging || ! this.initialized;
        }
    },
    watch: {
        open ( isOpen ) {
            if ( isOpen === this.isOpen ) return;
            isOpen ? this.openPopup() : this.closePopup();
        }
    },
    mounted () {
        this.timerDis = setTimeout( () => {
            // if ( contentHeight === 0 ) {

            // }
            contentHeight = this.$refs.content.getBoundingClientRect().height;
            if ( this.bottomDis > 0 ) { // 是否离底部有距离
                const vh = document.documentElement.clientHeight / 100;
                contentHeight = contentHeight - ( this.bottomDis * vh );
            }
            this.translateDistance = this.open ? 0 : contentHeight;
            clearTimeout(  this.timerDis );
            this.timerDis = null;
        }, 350 );
    },
    methods: {
        touchStart ( e ) {
            this.isDragging = true;
            this.isShowMask = true;
            startY = prevY = e.targetTouches[0].clientY;
            startTime = Date.now();
        },
        touchMove ( e ) {
            if ( ! this.isDragging ) { return; }
            const currentY = e.targetTouches[0].clientY;
            const newTranslate = currentY - prevY + this.translateDistance;
            this.translateDistance = newTranslate < 0 ? 0 : newTranslate > contentHeight ? contentHeight : newTranslate;
            this.opacity = ( 1 - this.translateDistance / contentHeight ) * MASK_OPACITY;
            prevY = currentY;
        },
        touchEnd ( e ) {
            this.isDragging = false;
            if ( prevY === startY && Date.now() - startTime <= 300 ) {
                this.isOpen ? this.closePopup() : this.openPopup();
            } else if ( prevY - startY > 0 ) {
                this.closePopup();
            } else {
                this.openPopup();
            }
        },
        openPopup () {
            if ( ! this.initialized ) this.initialized = true;
            this.isOpen = true;
            this.isShowMask = true;
            this.translateDistance = 0;
            this.opacity = MASK_OPACITY;
            this.$emit( 'change', this.isOpen );
            maskTimer && clearTimeout( maskTimer );
        },
        closePopup () {
            if ( ! this.initialized ) this.initialized = true;
            this.isOpen = false;
            this.translateDistance = contentHeight;
            this.opacity = 0;
            this.$emit( 'change', this.isOpen );
            maskTimer = setTimeout( () => {
                this.isShowMask = false;
                maskTimer = null;
            }, this.duration );
        },
        maskClick () {
            if ( this.closeOnClickMask ) this.closePopup();
        }
    },
    beforeDestroy () {
        clearTimeout( maskTimer );
    }
};
</script>

<style lang="scss" scoped>
  .slide-popup {
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      z-index: 900;
      &.no-transition {
        transition: none;
      }
    }
    .body {
      background-color: #FFF;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      height: 70vh;
      border-radius: 5.33vw 5.33vw 0 0;
      box-shadow: 0 4rem 8rem 0 rgba(32,34,37,0.51);
      &.no-transition {
        transition: none;
      }
      .sticky-box {
        .header {
          height: 6.8vw;
          display: flex;
          justify-content: center;
          align-items: center;
          .slide-btn {
            width: 8.8vw;
            height: 1.33vw;
            background: rgba(241,240,245,1);
            border-radius: 1.8vw;
          }
        }
      }
      .content {
        flex-grow: 1;
        // height: 80vw; // 设置固定高度
        overflow-y: scroll;
      }
    }
  }
</style>
