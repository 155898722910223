<template>
  <div>
    <StateBar :bgcolor="bgColor" />
    <TitleHeader :title="'连锁品牌VIP-拓店云商户信息'" :bgcolor="bgColor" color="black" :back="true"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="false"
    >
      <div class="module">
        <div class="form" >
          <p>商户账号：<span>{{info.account}}</span></p>
          <p>商户码：<span>{{info.merchantCode || '-'}}</span></p>
          <!--<p>开放城市：<span>深圳</span></p>-->
          <!--<p>开放品牌：<span>茶颜悦色</span></p>-->
          <p>有效期：<span>{{info.expireDate}}</span></p>
          <p>系统登录网址：<span>{{info.url}}</span><b><img @click="copyUrl(info.url)" src="static/vip/icon-link.png"></b></p>
        </div>
      </div>
      <div class="img-wrap">
        <img class="form-view" src="/static/vip/enterprise/img-form-view.png" />
        <p class="tip">更多增值服务，可联系客服！</p>
      </div>
    </AutoView>
    <!-- <SoltFooter v-if="editable"> -->
    <!--<div class="submitFooter">-->
    <!--  &lt;!&ndash;已支付&ndash;&gt;-->
    <!--  <div class="btn-wrap">-->
    <!--    <van-button  class="pay-btn" @click="toVip">确认</van-button>-->
    <!--  </div>-->
    <!--</div>-->
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Toast } from "vant";

export default {
  name: 'index',
  data () {
    return {
      bgColor: '#fff',
      info: {
        account: '',
        brandName: '',
        companyName: '',
        expireDate: '',
        merchantCode: '',
        status: '',
        url: '',
      },
    };
  },
  mounted () {
    this.getSaasInfo();
    this.JSBridge.callHandler("setStatebarTheme", 'black');
  },
  methods: {
    toVip () {
      this.$router.back();
    },
    getSaasInfo() {
      this.HTTP.getSaasInfo().then(res => {
        console.log(res, '商户信息');
        this.info = res.data.data;
      })
    },
    copyUrl(str) {
      let input = str + '';
      const el = document.createElement('input');
      el.value = input;
      el.setAttribute('readonly', '');
      el.style.contain = 'strict';
      el.style.position = 'absolute';
      el.style.left = '-100vw';
      el.style.fontSize = '12px'; // Prevent zooming on iOS
      document.body.appendChild(el);
      el.select();
      el.selectionStart = 0;
      el.selectionEnd = input.length;
      let success = false;
      try {
        success = document.execCommand('copy');
        Toast('复制成功');
      } catch (err) {
        Toast('复制失败');
      }
      document.body.removeChild(el);
      return success;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import '@/styles/_cell.scss';
*{
  box-sizing: border-box;
}
.module{
  background: url('/static/vip/img-details-header.png') no-repeat;
  background-size: 100% auto;
  background-color: #F8F8F9;
  padding: vw(222) vw(30) vw(0) vw(30);
}
.img-wrap{
  background-color: #F8F8F9;
  padding: vw(20) vw(16) vw(100) vw(16);
}
.form{
  width: 100%;
  background: #fff;
  border-radius: vw(10);
  padding: vw(18) vw(30);
  margin-top: vw(30);
  box-shadow: 0px 0px vw(11) vw(3) rgba(13, 13, 13, 0.06);
  p{
    font-size: vw(28);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: vw(37);
    margin: vw(20) 0;
    span{
      color: #333;
    }
  }
  img{
    display: inline-block;
    width: vw(28);
    margin-left: vw(10);
  }
}
.form-view{
  display: inline-block;
  width: 100%;
  //margin-top: vw(30);
}

.submitFooter{
  .btn-wrap{
    padding: vw(15) vw(20);
    background: #FFFFFF;
    box-shadow: 0px -2px 6px 0px rgba(34, 34, 34, 0.12);
    .cell-bd{
      padding: 0 vw(10);
    }
    .comfirm-btn{
      width: 100%;
      box-shadow: 0px vw(-2) vw(6) 0 rgba(34, 34, 34, 0.12);
      border-radius: vw(10);
      border: 0; //vw(2) solid #347FFF;
      font-size: vw(30);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #347FFF;
      line-height: vw(42);
      //text-shadow: 0px vw(-2) vw(6) rgba(34, 34, 34, 0.12);
    }
    .pay-btn{
      width: 100%;
      background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
      box-shadow: 0px vw(-2) vw(6) 0px rgba(34, 34, 34, 0.12);
      border-radius: vw(10);
      font-size: vw(30);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: vw(42);
      border: 0;
      //text-shadow: 0px vw(-2) vw(6) rgba(34, 34, 34, 0.12);
    }
  }
}
.tip{
  font-size: vw(24);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: vw(33);
  padding: vw(10) vw(14);
}
</style>
