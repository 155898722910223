<template>
    <div class="wraper-com">
        <ul v-if="title.length > 0" class="wrap-title">
            <li class="line">
                <span class="wrap-legend"></span>
                <span class="wrap-label">{{ title }}</span>
                <span v-if="tips" class="wrap-tips">{{ tips }}</span>
            </li>
            <slot name="other"></slot>
        </ul>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        tips: {
            type: String,
            default: "",
        },
        longTips: {
            type: String,
            default: "",
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        getMore: {
            type: Boolean,
            default: false,
        },
        isType: {
            type: [Number,String],
            default: 0,
        }
    },
    methods: {
        dataInstructions() {
            this.$emit("dataInstructions");
        },
        openMore() {
            this.$emit("openMoreData");
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

.wraper-com {
    position: relative;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: vw2(10);

    .wrap-title {
        position: relative;
        padding-bottom: vw2(10);
        text-align: left;
        display: flex;
        align-items: center;
        .line {
            display: inline-flex;
            align-items: center;

            .wrap-legend {
                position: relative;
                width: vw2(4);
                height: vw2(14);
                border-radius: vw2(2);
                background-color: #347fff;
            }

            .wrap-label {
                position: relative;
                margin-left: vw2(5);
                color: #1D2233;
                font-size: vw2(15);
                font-weight: bold;
                text-align: left;
                word-break: keep-all;
            }

            .wrap-icon {
                width: vw2(14);
                height: vw2(14);
                margin-left: vw2(4);
            }
        }

        .wrap-tips {
            font-size: vw2(10);
            color: #999;
            margin-left: vw2(6);
            // limitLine();
        }

        .more-new {
            // flex: 1;
            text-align: right;
            color: #347FFF;
            font-size: vw2(12);
            position : relative;
            padding-right: vw2(8);
            &::after{
                position: absolute;
                content : "";
                top: 50%;
                right : 0;
                width : vw2(5);
                height : vw2(8);
                transform: translateY(-50%);
                background-size : 100%;
            }
        }
        .dataState{
            color: #666666;
            padding-right: vw2(18);
            &::after{
                width : vw2(15);
                height : vw2(15);
                background-size : 100%;
            }
        }

        .more {
            flex: 1;
            text-align: right;
            font-size: vw2(12);
            color: #999;
        }
    }
}
</style>
