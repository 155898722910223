<template>
  <div class="page">
    <StateBar bgcolor="#fff" />
    <TitleHeader :title="'开店找位置专区'" bgcolor="#fff" color="black" :back="true"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="false"
    >
      <div class="part-item part-item-1">
        <div class="part-item-header">
          <span class="part-item-header-spot"></span>
          <span class="part-item-header-title">还没有意向选址，为您推荐</span>
        </div>
        <div class="part-content">
          <img class="icon-bg" src="/static/business-map/icon1.png" alt="" />
          <div class="part-content-header">
            <div class="part-content-header-left">开店位置推荐</div>
          </div>
          <div class="part-content-middle">
            <div class="part-content-middle-left">大数据锁定全城最适合你的3个好位置！</div>
          </div>
          <div class="part-content-bottom">
            <div class="part-content-bottom-left">
              最低<span class="unit">¥</span><span class="price">24.9</span>起/次
            </div>
            <div class="part-content-bottom-right">
              <div class="part-btn part-btn1" @click="example('149448018847800')">示例报告</div>
              <div class="part-btn part-btn2" @click="goPage('SKU_FILL_INFO_PAGE', '10026', '3350100', '3350101')">立即推荐</div>
            </div>
          </div>
        </div>
      </div>
      <div class="part-item part-item-2">
        <div class="part-item-header">
          <span class="part-item-header-spot"></span>
          <span class="part-item-header-title">还没有意向店址，但清楚想找什么类型的店址，可为您推荐</span>
        </div>
        <div class="part-content">
          <img class="icon-bg" src="/static/business-map/icon2.png" alt="" />
          <div class="part-content-header">
            <div class="part-content-header-left">点位雷达</div>
          </div>
          <div class="part-content-middle">
            <div class="part-content-middle-left">搜索符合您要求的合适店址</div>
          </div>
          <div class="part-content-bottom">
            <div class="part-content-bottom-left">
              最低<span class="unit">¥</span><span class="price">12.8</span>起/次
            </div>
            <div class="part-content-bottom-right">
              <div class="part-btn part-btn1" @click="example2">示例报告</div>
              <div class="part-btn part-btn2" @click="goPage('POINT_RADAR', null, '3350100', '3350102')">立即使用</div>
            </div>
          </div>
        </div>
      </div>
      <div class="part-item part-item-3">
        <div class="part-item-header">
          <span class="part-item-header-spot"></span>
          <span class="part-item-header-title">意向开店位置在小区，要筛查小区</span>
        </div>
        <div class="part-content">
          <img class="icon-bg" src="/static/business-map/icon3.png" alt="" />
          <div class="part-content-header">
            <div class="part-content-header-left">小区筛查</div>
          </div>
          <div class="part-content-middle">
            <div class="part-content-middle-left">筛选出有开店潜力的好小区</div>
          </div>
          <div class="part-content-bottom">
            <div class="part-content-bottom-left">
              会员无限查
            </div>
            <div class="part-content-bottom-right">
              <div class="part-btn part-btn2" @click="goPage2">立即使用</div>
            </div>
          </div>
        </div>
      </div>
      <div class="part-item part-item-4">
        <div class="part-item-header">
          <span class="part-item-header-spot"></span>
          <span class="part-item-header-title">想直接找空铺</span>
        </div>
        <div class="part-content">
          <img class="icon-bg" src="/static/business-map/icon4.png" alt="" />
          <div class="part-content-header">
            <div class="part-content-header-left">铺位广场</div>
          </div>
          <div class="part-content-middle">
            <div class="part-content-middle-left">知名品牌多年选址模型，AI数据智能评估</div>
          </div>
          <div class="part-content-bottom">
            <div class="part-content-bottom-left">
              免费查看
            </div>
            <div class="part-content-bottom-right">
              <div class="part-btn part-btn2" @click="goPage('SHOP_SQUARE_HOME_V2', null, '3350100', '3350104')">立即查看</div>
            </div>
          </div>
        </div>
      </div>
    </AutoView>
  </div>
</template>

<script>
import reportConfig from '@/utils/config'
export default {
  name: 'openStoreArea',
  data() {
    return {}
  },
  methods: {
    goPage(url, id = null, moduleId = null, btnId = null) {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, moduleId, btnId)
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: url,
        id: id,
        params: null,
        isClear: false,
      });
    },
    example(){
      let id = reportConfig.locationRecommendReportId
      // console.log('id', id)
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 4,
        id: id,
        params: null,
        isClear: false,
      });
    },
    example2(){
      this.swRouter(`/pointRadar/report/${reportConfig.pointRadarReportId}?type=example`);
    },
    async goPage2() {
      const vipRes = await this.HTTP.checkBenefit()
      if (vipRes === undefined) {
        return
      }
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, '3350100', '3350103')
      if (vipRes) {
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: 'COMMUNITY_SKU_HOME',
          id: null,
          params: null,
          isClear: false,
        });
      } else {
        this.swRouter('/h5/#/member')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
 .titleHeader {
   border-bottom: 1px solid #E7E7E7;
 }
.page-view {
  padding: vw(30);
  .part-item {
    margin-top: vw(34);
    &:first-child {
      margin-top: 0;
    }
    .part-item-header {
      display: flex;
      .part-item-header-spot {
        width: vw(14);
        height: vw(14);
        border-radius: 50%;
        margin-right: vw(8);
        opacity: 0.45;
        margin-top: vw(16);
      }
      .part-item-header-title {
        font-weight: bold;
        color: #1D2233;
        font-size: vw(30);
        line-height: vw(45);
        flex: 1;
      }
    }
    .part-content {
      border-radius: vw(12);
      margin-top: vw(25);
      padding: vw(25) vw(30) vw(30) vw(30);
      box-sizing: border-box;
      position: relative;
      .icon-bg {
        width: vw(100);
        height: vw(104);
        position: absolute;
        top: vw(30);
        right: vw(30);
      }
      .part-content-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: vw(32);
        .part-content-bottom-right {
          display: flex;
          justify-content: flex-end;
          .part-btn {
            width: vw(160);
            height: vw(70);
            line-height: vw(70);
            text-align: center;
            box-sizing: border-box;
            font-size: vw(28);
            border-radius: vw(10);
            &.part-btn2 {
              color: #ffffff;
              margin-left: vw(26);
            }
          }
        }
        .part-content-bottom-left {
          font-weight: bold;
          .price {
            font-size: vw(36);
            margin-right: vw(4);
          }
          .unit {
            font-size: vw(24);
            margin-left: vw(4);
          }
        }
      }
      .part-content-middle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: vw(22);
        .part-content-middle-left {
          font-weight: 400;
          font-size: vw(22);
          line-height: vw(46);
          border-radius: vw(5) vw(100) vw(100) vw(100);
          padding: 0 vw(16) 0 vw(16);
          box-sizing: border-box;
        }
      }
      .part-content-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .part-content-header-left {
          font-weight: bold;
          color: #333333;
          font-size: vw(32);
          line-height: vw(45);
        }
      }
    }
    &.part-item-4 {
      color: #FF5900;
      .part-item-header-spot {
        background: #FF5900;
      }
      .part-content {
        border: vw(2) solid #FFDFC9;
        background: #FFFFFF linear-gradient(180deg, rgba(255,89,0,0.10) 0%, rgba(255,209,186,0.1200) 100%);
      }
      .part-content-middle-left {
        background: rgba(255, 89, 0, .1);
      }
      .part-btn2 {
        background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
      }
      .part-content-bottom-left {
        font-size: vw(24);
        letter-spacing: vw(1);
      }
    }
    &.part-item-3 {
      color: #FF504E;
      .part-item-header-spot {
        background: #FF4D4B;
      }
      .part-content {
        border: vw(2) solid #FFD7D7;
        background: #FFFFFF linear-gradient(180deg, rgba(255,80,78,0.1) 0%, rgba(255,80,78,0.0300) 100%);
      }
      .part-content-middle-left {
        background: rgba(255, 80, 78, .1);
      }
      .part-btn1 {
        border: vw(2) solid #FF504E;
      }
      .part-btn2 {
        background: linear-gradient(90deg, #FF7C6E 0%, #FF504E 100%);
      }
      .part-content-bottom-left {
        font-size: vw(24);
        letter-spacing: vw(1);
      }
    }
    &.part-item-2 {
      color: #785EFF;
      .part-item-header-spot {
        background: #8972FF;
      }
      .part-content {
        border: vw(2) solid rgba(137,114,255,0.1500);
        background: #FFFFFF linear-gradient(180deg, rgba(137,114,255,0.1) 0%, rgba(137,114,255,0.0500) 100%)
      }
      .part-content-middle-left {
        background: rgba(137, 114, 255, .1);
      }
      .part-btn1 {
        border: vw(2) solid #8972FF;
      }
      .part-btn2 {
        background: linear-gradient(90deg, #8EA0FF 0%, #785EFF 100%);
      }
      .part-content-bottom-left {
        font-size: vw(20);
      }
    }
    &.part-item-1 {
      color: #347FFF;
      .part-item-header-spot {
        background: #347FFF;
      }
      .part-content {
        border: vw(2) solid #D5E8FE;
        background: #FFFFFF linear-gradient(180deg, rgba(135,182,247,0.2) 0%, rgba(135,182,247,0.0700) 100%);
      }
      .part-content-middle-left {
        background: rgba(52, 127, 255, .1);
      }
      .part-btn1 {
        border: vw(2) solid #347FFF;
      }
      .part-btn2 {
        background: linear-gradient(90deg, #58B7FF 0%, #347FFF 100%);
      }
      .part-content-bottom-left {
        font-size: vw(20);
      }
    }
  }
}
</style>
