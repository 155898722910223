<template>
    <div class="brand">
        <div class="header">
            <StateBar :bgcolor="bgColor" />
            <TitleHeader :title="title" :back="showBack" :bgcolor="bgColor"></TitleHeader>
        </div>

        <AutoView ref="view" :header="true" :footer="true" class="brand--view">
            <van-image class="banner-img" fit="cover" :src="banner" />
            <div class="brand-content">
                <!-- 方案一 -->
                <template v-if="bannerResList && bannerResList.length > 0">
                    <div class="title">方案一</div>
                    <template v-for="item of bannerResList">
                        <van-image class="one-img margin-15" :key="item.key" fit="cover" :src="item.url" />
                    </template>
                </template>
                <!-- 方案二 -->
                <template v-if="introduceImageList && introduceImageList.length > 0">
                    <div class="title">方案二</div>
                    <template v-for="item of introduceImageList">
                        <van-image class="two-img margin-15" :key="item.key" fit="cover" :src="item.url" />
                    </template>
                </template>
                <!-- 方案三 -->
                <template v-if="bottomBannerList && bottomBannerList.length > 0">
                    <div class="title">方案三</div>
                    <template v-for="item of bottomBannerList">
                        <van-image class="img margin-15" :key="item.key" fit="cover" :src="item.url" />
                    </template>
                </template>
                <div class="title">使用规则</div>
                <p class="tips" style="margin-bottom: 12px">
                    购买后请添加小助手微信：sscmfxs01<br />
                    1、套餐仅限个人使用，购买成功后可在【我的订单】查看记录；<br />
                    2、本套餐不能与其他折扣券、抵用券同时使用；<br />
                    3、限选址定制套餐使用，其他报告不可用；<br />
                    4、本套餐提供定制服务，用户在使用的过程中必须遵守本APP的使用规则。<br />
                </p>
            </div>
        </AutoView>
        <SoltFooter class="brand--footerBar">
            <van-row class="view" gutter="25">
                <van-col span="24">
                    <van-button color="#FF5900" plain block @click="onLivechat">咨询专属分析师</van-button>
                </van-col>
            </van-row>
        </SoltFooter>
        <van-popup v-model="show" closeable>
            <div class="service">
                <div class="service-top">
                    <img class="service-icon" src="/static/element/service-icon.png" alt="" />
                    <div class="title">联系客服</div>
                    <div class="tip">如有疑问，请联系客服</div>
                </div>
                <div class="service-label" v-for="item in serviceInfo" :key="item.url">
                    <img class="" :src="item.url" alt="" />
                    <div class="des">
                        <div class="label">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "customLanding",
    data() {
        return {
            loading: true,
            showBack: true,
            bgColor: "white",
            title: "1对1定制化数据服务",
            banner: "/static/element/custom_cover.png", // 首张banner图
            introduceImageList: [{ url: "static/element/introduceImage.png" }], //方案一
            bannerResList: [{ url: "static/element/bannerRes.png" }], // 方案二
            bottomBannerList: [{ url: "static/element/bottomBanner1.png" }, { url: "static/element/bottomBanner2.png" }], // 方案三
            show: false,
            serviceInfo: [
                { url: "/static/element/time-icon.png", label: "服务时间：", value: "8:30-22:00" },
                { url: "/static/element/tel-icon.png", label: "联系电话：", value: "0755-28796279" },
            ],
        };
    },
    created() {
        this.HTTP.elemeSubmit({
            platform: sessionStorage.getItem("platform"),
            track: JSON.stringify([
                {
                    pageId: "10186",
                    timestamp: new Date().getTime(),
                },
            ]),
        });
    },
    methods: {
        onLivechat() {
            this.show = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.brand {
    height: 100vh;
    .header {
        position: relative;
        top: 0;
        width: 100%;
        z-index: 2;
        ::v-deep {
            .stateBar,
            .titleHeader {
                @include ani(0.25s, background-color);
            }
        }
        .share {
            position: relative;
            height: $headerHeight;
            background: rgba(black, 0.8) url(/static/icon/logo@96.png) no-repeat $margin center;
            background-size: $headerHeight * 0.7;
            color: white;
            padding-left: $headerHeight * 1.1;
            p {
                font-size: $font_320;
                padding-top: $margin * 0.8;
            }
            .f-fw {
                font-size: $font_400;
            }
            .app {
                position: absolute;
                right: $margin;
                top: 25%;
                height: 50%;
                color: white;
                background-color: $c-main;
                padding: 0 $margin;
                overflow: hidden;
                border-radius: 10vw;
                font-size: $font_320;
                @include flexColumn;
            }
        }
    }
    #{&}--view {
        scroll-behavior: smooth;
        .banner-img {
            width: 100%;
            height: 76.53vw;
        }
        .one-img {
            width: 100%;
            height: 38.13vw;
        }
        .two-img {
            width: 100%;
            height: 64vw;
        }
        .img {
            display: block;
            min-height: 10vw;
        }
        .margin-15 {
            margin-bottom: 4vw;
        }
        .brand-content {
            padding: 0 4vw;
            .title {
                height: 12vw;
                line-height: 12vw;
                font-size: 4vw;
                color: #1d2233;
            }
            .tips {
                font-size: 3.47vw;
                color: #999;
                line-height: 5.87vw;
            }
        }
    }
    #{&}--footerBar {
        z-index: 3;
        box-shadow: 0 -0.5vw 2vw rgba(black, 0.05);
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-button.van-button--warning {
                border-color: transparent;
                background: linear-gradient(90deg, #ff8000 0%, #ff5900 100%);
            }
        }
    }
    .introduce-subview {
        padding: $padding $margin;
        h3 {
            text-align: center;
            font-size: $font_400;
            margin-bottom: $margin * 2;
        }
        .card-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 24.53vw;
            .card-left {
                flex: 3;
                .top-tips {
                    font-size: 3.73vw;
                    color: #1d2233;
                    font-weight: 500;
                }
                .price {
                    height: 7.47vw;
                    font-size: 5.33vw;
                    color: #8d5d22;
                    font-weight: bold;
                    margin-top: 1.07vw;
                    .i {
                        font-size: 3.47vw;
                    }
                    .original {
                        font-size: 2.67vw;
                        color: #999;
                        margin-left: 2.67vw;
                        text-decoration: line-through;
                    }
                }
            }
            .card-right {
                flex: 2;
            }
        }
    }
}

.service {
    width: 73.33vw;
    height: 89.06vw;
    background-image: linear-gradient(#e8f0ff, #ffffff);
    .service-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6.533vw;
        .service-icon {
            width: 16vw;
            height: 16vw;
            margin-top: 5.33vw;
        }
        .title {
            color: #1d2233;
            font-weight: bold;
            font-size: 5.33vw;
            line-height: 7.467vw;
            margin: 3.2vw 0 1.067vw 0;
        }
        .tip {
            color: #8e9099;
            font-size: 3.733vw;
        }
    }
    .service-label {
        display: flex;
        align-items: center;
        margin-bottom: 4.533vw;
        margin-left: 10.8vw;
        img {
            width: 13.6vw;
            height: 13.6vw;
            margin-right: 3.33vw;
        }
        .des {
            .label {
                color: #8e9099;
                font-size: 3.733vw;
            }
            .value {
                color: #1d2233;
                line-height: 6.4vw;
                font-size: 4.533vw;
            }
        }
    }
}
</style>
