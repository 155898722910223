<template>
  <div class="asking4Rent-list">
    <div class="header">
      <StateBar />
      <TitleHeader bgcolor="white" color="black" :back="true" style="border-bottom: 1px solid #f3f4f7">
        <div slot="title">
          <van-tabs v-model="active" color="#347FFF" title-active-color="#347FFF" :line-width="`${(33 / 750 * 100)}vw`"
            :line-height="`${(6 / 750 * 100)}vw`" @click="onClick" v-if="!showOther">
            <van-tab title="商铺信息" name="platformShops" v-if="lists.platformShops && lists.platformShops.length" />
            <van-tab title="求租信息" name="intentionRents" v-if="lists.intentionRents && lists.intentionRents.length" />
          </van-tabs>
          <div v-else class="van-tab" style="color: #333333">暂无信息</div>
        </div>
      </TitleHeader>
    </div>
    <AutoView :header="true" :footer="false">
      <template v-if="active == 'intentionRents'">
        <template v-if="lists.intentionRents && lists.intentionRents.length">
          <van-cell-group v-for="(item, index) in lists.intentionRents" :key="index">
            <van-cell>
              <template #title>
                <div class="card-item-header">
                  <div class="card-item-icon">
                    <img :src="item.iconUrl || '/static/shopEcology/information/avatar.png'" alt="">
                  </div>
                  <div class="card-item-title">
                    {{ item.businessTitle }}
                  </div>
                </div>
              </template>
              <template #label>
                <div class="card-item-container" @click="handlerClick(item)">
                  <div class="base-info">
                    <div class="base-info-item">
                      <div class="bii-value">{{ item.areaContent }}</div>
                      <div class="bii-title">{{ item.areaTitle }}</div>
                    </div>
                    <div class="split-line" />
                    <div class="base-info-item">
                      <div class="bii-value">{{ item.rentContent }}</div>
                      <div class="bii-title">{{ item.rentTitle }}</div>
                    </div>
                    <div class="split-line" />
                    <div class="base-info-item">
                      <div class="bii-value">{{ item.formatContent }}</div>
                      <div class="bii-title">{{ item.formatTitle }}</div>
                    </div>
                  </div>
                  <div class="location-info">
                    <div class="location-item">
                      <div class="small-icon" />
                      <div class="small-title">求租区域</div>
                    </div>
                    <div class="city-item">
                      <div class="small-title">
                        <div class="cityName">{{ item.rentCity }}</div>
                      </div>
                    </div>
                    <div class="city-item" v-for="(region, regionIndex) in item.rentRegionList || []"
                      :key="regionIndex">
                      <div class="small-title">
                        <div class="regionName">
                          <div class="small-region">{{ region }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="mask" />
                  </div>
                  <div class="isClose" v-if="!item.isOpen" />
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </template>
        <template v-else>
          <van-cell-group>
            <div class="closeView">
              <img src="/static/shopEcology/shopRequirements/icon/state_img_rent.png" alt="">
              <div class="closeTitle">暂无求租信息</div>
            </div>
          </van-cell-group>
        </template>
      </template>
      <template v-else-if="active == 'platformShops'">
        <template v-if="lists.platformShops && lists.platformShops.length">
          <van-cell-group v-for="(item, index) in lists.platformShops" :key="index">
            <van-cell>
              <template #title />
              <template #label>
                <div class="shop-card-item" @click="toShopDetails(item)">
                  <img :src="`${item.shopPhoto}?x-oss-process=image/resize,m_fill,w_300`" alt=""
                    onerror="onerror=null;src='/static/shopEcology/placeholder.png'" />
                  <div class="content">
                    <div class="content-title">{{ item.platformShopName }}</div>
                    <div class="content-base">{{ item.area }} | {{ item.businessStatus }}</div>
                    <div class="content-tips">
                      <div>{{item.otherPriceTitle}}：</div>
                      <div class="tips-item">{{ item.otherPrice }}</div>
                    </div>
                    <div class="content-area">
                      <div>房租</div>
                      <div class="area-text">{{ item.monthlyRent || "-" }}</div>
                    </div>
                  </div>
                </div>
                <div class="shop-card-item-addr">
                  <div class="icon" />
                  <div class="address">{{ item.address }}</div>
                </div>
                <div class="isShopClose" v-if="!item.status" />
              </template>
            </van-cell>
          </van-cell-group>
        </template>
        <template v-else>
          <van-cell-group>
            <div class="closeView">
              <img src="/static/shopEcology/shopRequirements/icon/state_img_null.png" alt="">
              <div class="closeTitle">暂无商铺信息</div>
            </div>
          </van-cell-group>
        </template>
      </template>
      <template v-else>
        <van-cell-group>
          <div class="closeView">
            <img src="/static/img/me/surveyedShops/img-null.png" alt="">
            <div class="closeTitle">当前用户暂无商铺/求租信息</div>
          </div>
        </van-cell-group>
      </template>
    </AutoView>
  </div>
</template>

<script>
import { getIMPersonalDetailsList } from '@/request/asking4Rent';
export default {
  data() {
    return {
      lists: {},
      active: ''
    }
  },
  mounted() {
    let uid = this.$route.query.uid;
    getIMPersonalDetailsList(uid).then(res => {
      this.lists = res;
      console.log(res);
      if (res.platformShops && res.platformShops.length) {
        this.active = 'platformShops';
      }
      else if (res.intentionRents && res.intentionRents.length) {
        this.active = 'intentionRents';
      }
    });
  },
  methods: {
    onClick(name) {
      switch (name) {
        case 'platformShops':
          this.JSBridge.BTNCLICK(this.$route.meta.id, null, '3230100', '3230101');
          break;
        case 'intentionRents':
          this.JSBridge.BTNCLICK(this.$route.meta.id, null, '3230100', '3230102');
          break;
      }
    },
    handlerClick(item) {
      this.JSBridge.BTNCLICK(this.$route.meta.id, item.id, '3230300', '3230301');
      this.swRouter('/shopEcology/askingRentDetails?id=' + item.id);
    },
    toShopDetails(item) {
      this.JSBridge.BTNCLICK(this.$route.meta.id, item.platfromShopCode, '3230200', '3230201');
      this.swRouter('/h5/#/shop/details/' + item.platfromShopCode);
    }
  },
  computed: {
    showOther() {
      const lists = this.lists;
      return !(lists.platformShops && lists.platformShops.length) && !(lists.intentionRents && lists.intentionRents.length);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
  @return $args/750 * 100+vw;
}

.asking4Rent-list {
  .autoView {
    background: #f2f2f2;

    ::v-deep .van-cell-group {
      min-width: 0;
      overflow: hidden;
      border-radius: vw(10);
      margin: vw(20) vw(20);
      position: relative;
    }
  }

  ::v-deep .van-tab {
    padding: 0 vw(22);
  }

  .closeView {
    text-align: center;

    img {
      width: vw(174);
      height: vw(174);
      margin-top: vw(100);
      margin-bottom: vw(42);
    }

    .closeTitle {
      height: vw(30);
      font-size: vw(22);
      color: #8893A7;
      line-height: vw(30);
      margin-bottom: vw(156);
    }
  }

  ::v-deep .van-tab {
    font-size: vw(30);
    line-height: vw(42);
    color: #999999;
  }

  ::v-deep .van-tab--active {
    font-weight: 600;
  }

  .card-item-header {
    display: flex;
    padding-top: vw(10);
    padding-bottom: vw(20);
    border-bottom: #DEDEE7 vw(1) solid;

    .card-item-icon {
      width: vw(40);
      height: vw(40);
      margin-right: vw(10);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-item-title {
      flex: 1;
      font-size: vw(30);
      font-weight: 600;
      line-height: vw(42);
    }

    .card-item-opts {
      flex-shrink: 0;
      height: vw(37);
      color: #347FFF;
      font-size: vw(26);
      font-weight: 600;
      line-height: vw(37);
    }
  }

  ::v-deep .van-cell__title {
    overflow: hidden;
  }

  .isShopClose {
    right: vw(0);
    z-index: 999;
    width: vw(100);
    height: vw(120);
    bottom: vw(79);
    position: absolute;
    background: url(/static/shopEcology/shopRequirements/icon/shop_close.png);
    background-size: vw(100) vw(120);
  }

  .card-item-container {
    .isClose {
      right: 0;
      z-index: 999;
      width: vw(100);
      height: vw(120);
      bottom: vw(46);
      position: absolute;
      background: url(/static/shopEcology/shopRequirements/icon/rent_close.png);
      background-size: vw(100) vw(120);
    }

    .base-info {
      display: flex;
      align-items: center;
      margin-top: vw(30);
      margin-bottom: vw(30);

      .split-line {
        width: vw(1);
        height: vw(60);
        opacity: 0.8;
        background: #DEDEE7;
      }

      .base-info-item {
        flex: 1;
        text-align: center;

        .bii-value {
          font-size: vw(28);
          color: #FF8047;
          line-height: vw(40);
          margin-bottom: vw(4);
        }

        .bii-title {
          font-size: vw(24);
          color: #676C84;
          line-height: vw(33);
        }
      }
    }

    .location-info {
      display: flex;
      position: relative;
      align-items: center;

      .mask {
        top: 0;
        right: vw(-15);
        width: vw(105);
        height: vw(50);
        position: absolute;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }

      .location-item {
        display: flex;
        height: vw(30);
        flex-shrink: 0;
        align-items: center;
        padding: vw(10) vw(18);
        background: linear-gradient(270deg, #F2F5FC 0%, #E5EFFF 100%);

        .small-icon {
          width: vw(18);
          height: vw(21);
          background-size: vw(18) vw(21);
          background-position: center;
          background-image: url(/static/shopEcology/shopRequirements/icon/location-small.png);
        }

        .small-title {
          flex: 1;
          height: vw(30);
          font-size: vw(22);
          font-weight: 600;
          color: #347FFF;
          line-height: vw(30);
          margin-left: vw(10);
        }
      }

      .city-item {
        display: flex;
        margin-left: vw(4);
        padding: vw(10) vw(18);
        background: #F7F8FB;

        .small-title {
          display: flex;
          font-size: vw(22);
          font-weight: 400;
          color: #1D2233;
          line-height: vw(30);
          overflow: hidden;
          white-space: nowrap;

          .regionName {
            overflow: hidden;
            color: #676C84;
          }
        }
      }
    }
  }

  .shop-card-item {
    display: flex;
    overflow: hidden;
    margin-top: vw(10);

    img {
      width: vw(210);
      height: vw(158);
      flex-shrink: 0;
      border-radius: vw(8);
    }

    .content {
      overflow: hidden;
      margin-left: vw(20);

      .content-title {
        height: vw(40);
        font-size: vw(28);
        font-weight: 600;
        color: #1D2233;
        line-height: vw(40);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: vw(6);
      }

      .content-base {
        height: vw(30);
        font-size: vw(22);
        color: #676C84;
        line-height: vw(30);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: vw(6);
      }

      .content-tips {
        display: flex;
        height: vw(30);
        color: #676C84;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: vw(13);
        align-items: baseline;

        .tips-item {
          height: vw(30);
          line-height: vw(30);
          color: #347FFF;
        }
      }

      .content-area {
        display: flex;
        font-size: vw(22);
        color: #676C84;
        align-items: baseline;

        .area-text {
          height: vw(40);
          font-size: vw(28);
          color: #FF8047;
          line-height: vw(40);
          margin-left: vw(10);
        }
      }
    }
  }

  .shop-card-item-addr {
    display: flex;
    height: vw(30);
    margin-top: vw(10);
    border-radius: vw(2);
    background: #F7F8FB;
    padding: vw(10) vw(10);
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .icon {
      width: vw(18);
      height: vw(21);
      background: url(/static/shopEcology/shopRequirements/icon/location-gray.png);
      background-size: vw(18) vw(21);
      margin-right: vw(10);
    }

    .address {
      height: vw(30);
      font-size: vw(22);
      line-height: vw(30);
      color: #676C84;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
