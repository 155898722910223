<template>
    <div class="safeHolder" :class="{ ios: isIOS, ipx: isIPX }"></div>
</template>


<script>
export default {
    name: 'safeHolder',
    data () {
        return {
            isAndroid: false,
            isIOS    : false,
            isIPX    : false,
        };
    },
    beforeMount () {
        this.isAndroid = window.isClient && window.isAndroid;
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.safeHolder {
    height: 0;

    &.android {
        display: none;
    }
    // &.ios {
    //     display: none;
    // }
    &.ipx {
        height: $iPXBottomBar;
    }
}
</style>
