<template>
  <div v-if="hasLoadData" class="report-page">
    <template v-if="aroundReportInstanceId">
      <div class="model-item">
        <div class="model-item-header">
          <div class="model-item-header-left">周边查询</div>
          <div class="model-item-header-right">周边500米数据，含客群画像等</div>
        </div>
        <!-- 周边基础信息 -->
        <Region :bgmData="bgmAround.aroundBasicData" :dataObj="bgmAround.regionalFeatureData" />
        <!-- 周边配套信息 -->
        <Maturity
          :bgmData="bgmAround.businessMaturityData"
          :dataObj="bgmAround.aroundMatchingData"
        />
        <div class="model-item-btn" @click="goReport(aroundReportInstanceId)">
          查看详细周边分析
          <img class="btn-arrow" src="/static/shopEcology/report/btn-arrow.png" />
        </div>
        <div class="model-item-tip" v-if="toast">
          <img class="icon-tip" src="/static/shopEcology/report/icon-tip.png" />
          <span>{{ toast }}</span>
        </div>
      </div>
    </template>
    <!--    人口热力-->
    <template v-if="populationHeatData.reportInstanceId">
      <div class="model-item">
        <div class="model-item-header">
          <div class="model-item-header-left">人口热力</div>
          <div class="model-item-header-right">周边500米数据</div>
        </div>
        <p class="hot-explain">热力图的颜色深浅与覆盖范围大小，反映着人口总规模的分布情况</p>
        <div class="hot-map">
          <gridHotPopupMap
            :geohashData="populationHeatData.oneHeatData.heatDataList"
            showHotText
            modeType="sum"
            :styleType="1"
            :radius="75"
            mode="value"
            :inputData="populationHeatData.input"
          />
        </div>
        <div class="model-item-btn" @click="goReport(populationHeatData.reportInstanceId)">
          查看详细人口热力
          <img class="btn-arrow" src="/static/shopEcology/report/btn-arrow.png" />
        </div>
        <div class="model-item-tip" v-if="toast">
          <img class="icon-tip" src="/static/shopEcology/report/icon-tip.png" />
          <span>{{ toast }}</span>
        </div>
      </div>
    </template>
    <!--    行业热力-->
    <template v-if="hasIndustryHeatmapData">
      <div class="model-item">
        <div class="model-item-header">
          <div class="model-item-header-left">行业热力</div>
          <div class="model-item-header-right">周边500米数据</div>
        </div>
        <p class="hot-explain">热力图的颜色深浅与覆盖范围大小，反映着店铺的分布密集程度</p>
        <van-tabs v-model="active" color="#347FFF" background="#FAFAFB" swipeable class="my-tabs">
          <van-tab v-for="(item, index) in tabsList" :title="item.name" :key="index">
            <div class="hot-map">
              <gridHotPopupMap
                :geohashData="item.data.oneHeatData.heatDataList"
                showHotText
                modeType="sum"
                :styleType="1"
                :radius="75"
                mode="value"
                :inputData="item.data.input"
              />
            </div>
          </van-tab>
        </van-tabs>
        <div class="model-item-btn" @click="goReport(tabsList[active].data.reportInstanceId)">
          查看详细行业热力
          <img class="btn-arrow" src="/static/shopEcology/report/btn-arrow.png" />
        </div>
        <div class="model-item-tip" v-if="toast">
          <img class="icon-tip" src="/static/shopEcology/report/icon-tip.png" />
          <span>{{ toast }}</span>
        </div>
      </div>
    </template>
    <!-- 开通会员弹框 -->
    <OpenVip :showDialog="showVip" @cancel="showVip = false" />
  </div>
</template>

<script>
import { getShopReport } from '@/request/shopEcology';

const Region = () => import('./components/region')
const Maturity = () => import('./components/maturity')
import OpenVip from "@/components/openVip/index.vue";
const gridHotPopupMap = () => import('./components/grid-hot-popup-map')
export default {
  name: 'index',
  props: {
    toast: String,
    lastCheck: {
      type: Number,
      default: -1
    },
    free: {
      type: Boolean,
      default: false
    },
    currentCheck: null
  },
  components: {
    Region,
    Maturity,
    OpenVip,
    gridHotPopupMap
  },
  data() {
    return {
      // 商圈数据
      aroundReportInstanceId: null,
      bgmAround: {},
      hasLoadData: false,
      populationHeatData: {
        oneHeatData: null, // 餐饮热力分布
        input: {}, // 输入参数
        reportInstanceId: null
      },
      active: 0,
      tabsList: [
        { name: '餐饮', data: {
            oneHeatData: null,
            input: {},
            reportInstanceId: null
          } },
        { name: '零售', data: {
            oneHeatData: null,
            input: {},
            reportInstanceId: null
          } },
      ],
      showVip: false,
      hasIndustryHeatmapData: false
    }
  },
  mounted () {
    getShopReport(this.$route.query.id).then(res => {
      console.log(res)

      try {
        res.forEach(el => {
          if (el.name.indexOf('周边查询') > -1) {
            if (typeof el.output == 'string') {
              el.output = JSON.parse(el.output)
            }
            this.bgmAround = el.output
            this.aroundReportInstanceId = el.reportInstanceId
          }
          if (el.name.indexOf('人口规模热力') > -1) {
            this.loadInputData(el.input, this.populationHeatData)
            if (typeof el.output == 'string') {
              el.output = JSON.parse(el.output)
            }
            this.populationHeatData.oneHeatData = el.output.oneHeatData
            this.populationHeatData.reportInstanceId = el.reportInstanceId
          }
          if (el.name.indexOf('行业热力') > -1 && el.name.indexOf('餐饮') > -1) {
            this.loadInputData(el.input, this.tabsList[0].data)
            if (typeof el.output == 'string') {
              el.output = JSON.parse(el.output)
            }
            this.tabsList[0].data.oneHeatData = el.output.oneHeatData
            this.tabsList[0].data.reportInstanceId = el.reportInstanceId
            this.hasIndustryHeatmapData = true
          }
          if (el.name.indexOf('行业热力') > -1 && el.name.indexOf('零售') > -1) {
            this.loadInputData(el.input, this.tabsList[1].data)
            if (typeof el.output == 'string') {
              el.output = JSON.parse(el.output)
            }
            this.tabsList[1].data.oneHeatData = el.output.oneHeatData
            this.tabsList[1].data.reportInstanceId = el.reportInstanceId
            this.hasIndustryHeatmapData = true
          }
        })
      } catch (err) {
        console.error(err)
      }
      console.log('populationHeatData.reportInstanceId', this.populationHeatData.reportInstanceId)
      this.hasLoadData = res.length > 0
    })
  },
  methods: {
    loadInputData(inputData, reportData) {
      let inputObj = inputData;
      if (typeof inputData == "string") {
        inputObj = JSON.parse(inputObj);
      }
      for (let i = 0; i < inputObj.length; ++i) {
        let item = inputObj[i];
        let itemKey = item.code;
        let itemValue = item.value;
        reportData.input[itemKey] = itemValue;
      }
    },
    goReport(id) {
      if(this.lastCheck === 0 && !this.free && this.currentCheck !== 1) {
        this.showVip = true
        return
      }
      if (!id) return;
      this.swRouter('/shopEcology/report/' + id)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.report-page {
  background: #FFFFFF;
  border-radius: vw(10);
  margin-top: vw(20);
  padding: 4vw;
}
.my-tabs {
  background: #FAFAFB;
  border-radius: vw(10);
  border: vw(1) solid #347FFF;
  margin-top: vw(20);
  .hot-map {
    margin-top: 0;
  }
  ::v-deep .van-tabs__wrap {
    padding: 0 vw(80);
    .van-tab {
      font-size: vw(26);
    }
    .van-tab--active {
      color: #347FFF;
      font-size: vw(28);
      font-weight: bold;
    }
  }
}
.model-item {
  border-bottom: vw(1) solid #E7E7E7;
  padding: 4vw 0;
  &:first-child {
    padding: 0 0 4vw 0;
    .model-item-header {
      margin-bottom: vw(20);
    }
  }
  &:last-child {
    border-bottom: 0;
    padding: 4vw 0 0 0;
  }
  .model-item-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: vw(15);
    font-weight: 400;
    color: #999999;
    font-size: vw(24);
    .icon-tip {
      width: vw(26);
      height: vw(26);
      margin-right: vw(6);
    }
  }
  .model-item-btn {
    height: vw(80);
    background: #347FFF;
    border-radius: vw(10);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #FFFFFF;
    font-size: vw(30);
    margin-top: vw(25);
    .btn-arrow {
      width: vw(28);
      height: vw(24);
      margin-left: vw(8);
    }
  }
  .model-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .model-item-header-left {
      font-weight: bold;
      color: #333333;
      font-size: vw(32);
      line-height: vw(45);
    }
    .model-item-header-right {
      font-weight: 400;
      color: #999999;
      font-size: vw(24);
      line-height: vw(33);
    }
  }
}
.hot-map {
  height: vw(410);
  margin-top: vw(20);
}
.hot-explain {
  font-weight: 400;
  color: #666666;
  font-size: vw(26);
  line-height: vw(37);
  margin-top: vw(20);
}
::v-deep {
  .box-panel {
    position: relative;
    background-color: #F8F8F9;
    border-radius: vw(10);
    padding: vw(20);
    width: 33%;
    //  width :vw2(90);
    //  height :vw2(70);
    // margin: vw2(5) 1% vw2(5) 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    display: block;
    &:nth-child(3n){
      margin-right:0;
    }
  }
  .box-image {
    width: vw2(8);
    height: vw2(9);
  }
  .box-name {
    color: #2F354B;
    font-size: vw2(12);
    line-height: vw2(17);
    font-weight: 600;
    text-align: left;
  }
  .box-count {
    color: #73788E;
    font-size: vw2(10);
    line-height: vw2(14);
    font-weight: 500;
    text-align: left;
    margin-top: vw2(3);
  }
  .box-content {
    display: flex;
  }
  .box-compare {
    font-size: vw2(10);
    font-weight: 600;
    line-height: vw2(14);
    text-align: left;
    margin-top: vw2(3);
    // display: flex;
  }
  .compare-more {
    color: #F58958;
  }
  .compare-less {
    color: #00B77E;
  }
  .compare-equal{
    color: #73788E;
  }
}
</style>
