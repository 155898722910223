<template>
    <div class="basic-map-wrap">
        <div class="map_container" :id="`baseMap${mapElId}`"></div>
        <van-image src="/static/img/hot-search/image_map.png" />
        <div class="map-legend">
            <span class="more">多<br><i>10%</i></span>
            <div class="ico"></div>
            <span class="less"><i>0%</i><br>少</span>
        </div>
    </div>
</template>
<script>
import LoadMap from '@/components/baseMap/loadMap';
import { chartsFontSize } from '@/utils'
let id = 0;
const listener = [];
// @@@ 释放地图，释放页面，释放监听事件，防止内存泄漏
export default {
    props: {
        center: {
            type: Array
        },
        zoom: {
            zoom   : Number,
            default: 2
        },
        radius: {
            default: 500
        }
    },
    data () {
        return {
            /**
             * 基础地图
             */
            mapElId     : id,
            map         : undefined,
            questionList: []
        };
    },
    watch: {
        center ( nv ) {
            if ( nv.length ) {
                this.setMapCenter( nv );
            }
        },
        zoom ( nv ) {
            if ( nv !== undefined ) {
                this.setMapZoom( nv );
            }
        },
        radius ( nv ) {
            if ( this.circleMarkerIns && nv ) {
                this.circleMarkerIns.setRadius( Number( nv ) );
            }
        }
    },
    methods: {
        onloadListener () {
            this.initMap();
        },
        /**
         * 初始化插件
         */
        initMap ( id ) {
            const baseMapConfig = {
                zooms        : [2, 10],
                center       : [104.322082, 36.519192],
                zoom         : 2,
                isHotspot    : false,
                defaultCursor: 'pointer',
                // layers       : [],
                viewMode     : '2D',
                resizeEnable : false,
                resizeEnable: false, // 监听地图缩放事件
                dragEnable  : false, // 不可拖拽。和报告的翻页冲突
            };

            id = id || `baseMap${this.mapElId}`;
            const config = { ...baseMapConfig };
            this.map = new AMap.Map( id, config );
            this.map.on( 'complete', this.mapCompleteListener );
            this.map.on( 'mapmove', this.mapMoveListener );
            this.map.on( 'moveend', this.mapMoveendListener );
            // 初始化完成，把事件队列清空
            listener.forEach( ( fn ) => {
                fn();
            } );
            listener.length = 0;
        },
        mapCompleteListener () {
            // var layer = new AMap.LabelsLayer( {
            //     // 开启标注避让，默认为开启，v1.4.15 新增属性
            //     collision: false,
            //     // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
            //     animation: true
            // } );
            // for ( var i = 0; i < LabelsData.length; i ++ ) {
            //     var labelsMarker = new AMap.LabelMarker( LabelsData[i] );
            //     layer.add( labelsMarker );
            // }
            // map.add( layer );
            this.$emit( 'complete', this.map );
        },
        mapMoveListener () {},
        getMap () {
            return this.map;
        },
        getMapBounds () {
            return this.map.getBounds();
        },
        setMapCenter ( center ) {
            if ( this.map ) {
                this.map.setCenter( center );
            }
        },
        setMapZoom ( zoom ) {
            if ( this.map ) {
                this.map.setZoomAndCenter( zoom, this.center );
            }
        }
    },
    beforeCreate () {
        id ++;
    },
    created () {
        this.mapElId = id;
    },
    mounted () {
        // 加载SDK库
        LoadMap()
            .then( () => {
                this.initMap();
            } )
            .catch( () => {
                window.addEventListener( 'load', this.onloadListener );
            } );
    },
    beforeDestroy () {
        // 销毁事件和实例
        window.removeEventListener( 'load', this.onloadListener );
        if ( this.map ) {
            this.map.off( 'complete', this.mapCompleteListener );
            this.map.off( 'mapmove', this.mapMoveListener );
            this.map.off( 'moveend', this.mapMoveendListener );
            this.map.destroy();
        }
    }
};
</script>

<style scoped lang="scss">
.basic-map-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    &::after{
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
    }
    .van-image {
        position: absolute;
        width: 12.53vw;
        height: 18.66vw;
        bottom: 8vw;
        right: 5vw;
    }
    .map-legend {
        position: absolute;
        left: 3.33vw;
        bottom: 12vw;
        line-height: 1.13vw;
        display: flex;
        flex-direction: column;
        .ico {
            width: 1.6vw;
            height: 11.6vw;
            background: linear-gradient(180deg, #347FFF 0%, #E7F2FF 100%);
            border-radius: 0.133vw;
        }
        span {
            color: #666666;
            font-size: 2.133vw;
            > i {
                padding-left: 4.6vw;
                position: relative;
                font-style: normal;
                    &::before{
                        position: absolute;
                        top: 0;
                        left: 2vw;
                        content: '';
                        border-width: 1.066vw;
                        border-style: solid;
                        border-bottom-width: 0.933vw;
                        border-color: transparent;
                        border-top-color: #DAE8FF;
                        border-right-color: #DAE8FF;
                    }
                &:nth-last-child(1) {
                    &::before{
                        border-color: transparent;
                        border-bottom-color: #347FFF;
                        border-right-color: #347FFF;
                    }
                }
            }
        }
    }
}
.map_container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #fff !important;
    transform-origin: 10% 50%;
    min-width: 390px;
    min-height: 300px;
    @media screen and (max-width: 375px) {
        transform: scale(0.9);
        margin: -4% 0;
    }
    @media screen and (max-width: 350px) {
        transform: scale(0.78);
        margin: -5.1% 0;
    }
    ::v-deep .amap-copyright {
        display: none !important;
    }
    ::v-deep .amap-logo {
        display: none !important;
    }
}
.text-markr {
    display: none;
}
</style>
