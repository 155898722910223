<template>
  <div>
    <StateBar />
    <TitleHeader title="订单详情" bgcolor="transparent" color="black" :back="true"
                 style="border-bottom: 1px solid #F3F4F7;">
      <img slot="right" class="title-icon service" src="static/shopEcology/icon-service.png"
           @click="toService" alt="">
    </TitleHeader>
    <AutoView :header="true" :footer="false">
      <div class="cell-group">
        <div class="cell cell-group-title">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-4.png" />
          </div>
          <div class="cell-bd">购买服务</div>
        </div>
        <div class="cell">
          <div class="cell-hd key">{{payInfo && payInfo.goodsName}}</div>
          <div class="cell-ft val price">
            <b>¥</b>
            <ins>{{payInfo && payInfo.totalAmount}}</ins>
          </div>
        </div>
      </div>

      <div class="cell-group">
        <div class="cell cell-group-title">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-3.png" />
          </div>
          <div class="cell-bd">服务内容</div>
        </div>
        <div class="cell">
          <div class="cell-hd">置顶广告位</div>
          <div class="cell-ft"><i class="word-icon">赠</i>90天</div>
        </div>
        <div class="cell">
          <div class="cell-hd">流量曝光</div>
          <div class="cell-ft">展示在铺广场</div>
        </div>
        <div class="cell">
          <div class="cell-hd">买家匹配</div>
          <div class="cell-ft">根据大数据推荐</div>
        </div>
        <div class="cell">
          <div class="cell-hd">1对1服务</div>
          <div class="cell-ft">全流程跟进</div>
        </div>
      </div>


      <div class="cell-group" @click="gotoAgreement">
        <div class="cell cell-group-title agreement no-border no-margin" :class="{'agree': isAgree}">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-1.png" />
          </div>
          <div class="cell-bd">商铺租赁代理协议</div>
          <div class="cell-ft check" v-if="isAgree">
            <img src="static/shopEcology/lease/ag-uncheck.png" />
          </div>
          <div class="cell-ft">已签署</div>
          <div class="cell-ft arrow">
            <img src="static/shopEcology/lease/arr-right.png" />
          </div>
        </div>
      </div>


      <div class="cell-group">
        <div class="cell cell-group-title">
          <div class="cell-hd">
            <img src="static/shopEcology/lease/icon-3.png" />
          </div>
          <div class="cell-bd">订单信息</div>
        </div>
        <div class="cell">
          <div class="cell-hd">下单时间</div>
          <div class="cell-ft">{{payInfo && payInfo.placeTime}}</div>
        </div>
        <div class="cell">
          <div class="cell-hd">支付时间</div>
          <div class="cell-ft">{{payInfo && payInfo.payTime}}</div>
        </div>
        <div class="cell">
          <div class="cell-hd">支付方式</div>
          <div class="cell-ft">{{payInfo && PAYMENT_METHOD_ENUME[payInfo.payType]}}</div>
        </div>
      </div>

    </AutoView>

  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    let PAYMENT_METHOD_ENUME = {
      WX: '微信支付',
      ALIPAY: '支付宝支付',
    };
    return {
      isAgree: true,
      payInfo: null,
      PAYMENT_METHOD_ENUME: PAYMENT_METHOD_ENUME,
      orderNo: null,
      waiting: false,
      client: 3,
    }
  },
  mounted() {
    // 设置客户端类型
    if (window.isIOS) {
      this.isIOS  = true;
      this.client = 1;
    } else if (window.isAndroid) {
      this.isAndroid = true
      this.client = 2;
    }
    this.HTTP.getOrderDetail(this.$route.query.id).then((res) => {
      this.payInfo = res.data || null;
    });
  },
  methods: {
    gotoAgreement() {
        this.swRouter('/shopEcology/agreement?isPayed=true')
    },
    toService(){
      this.JSBridge.BTNCLICK( this.$route.meta.id, this.$route.query.id, '2500100','2500101');
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "ONLINE_SERVICE",
        params: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
@import '@/styles/_cell.scss';
*{
  box-sizing: border-box;
}
.autoView{
  padding: vw(20);
  background: #F8F8F9 ;
  .cell-group{
    background: #FFFFFF;
    border-radius: vw(10);
    padding: vw(30) vw(25);
    margin-bottom: vw(20);
    .cell-group-title{
      padding-bottom: vw(20);
      border-bottom: 1px solid #E4E7ED;
      margin-bottom: vw(20);
      .cell-hd{
        img{
          width: vw(40);
          display: inline-block;
        }
      }
      .cell-bd{
        font-size: vw(28);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #1D2233;
        padding-left: vw(10);
      }
    }
    .cell{
      padding: vw(10) 0;
    }
    .cell-hd{
      font-size: vw(26);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #1D2233;
      padding: vw(5) 0;
    }
    .cell-ft{

      font-size: vw(26);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #676C84;
    }
    .pay-cell{
      border-bottom: 1px solid #F3F4F7;
      padding: vw(30) 0;
      &:last-child{
        border: none;
      }
      .cell-hd{
        width: vw(36);
        img{
          width: 100%;
        }
      }
      .cell-bd{
        font-size: vw(26);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #1D2233;
        padding-left: vw(20);
      }
      .check{
        img{
          width: vw(30);
        }
      }
    }
  }
  .price{
    font-size: 0;
    s{
      font-size: vw(22);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #8893A7;
      line-height: vw(30);
    }
    b{
      font-size: vw(22);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2233;
      line-height: vw(30);
      margin-left: vw(18);
    }
    ins{
      font-size: vw(30);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1D2233;
      line-height: vw(42);
      font-style: normal;
      text-decoration: none;
    }
  }
  .agreement{
    &.agree {
      .cell-ft{
        color: #676C84 !important;
      }
    }
    .cell-ft{
      font-size: vw(26);
      padding-left: vw(10);
      color: #347FFF;
    }
    .check{
      padding-left: vw(10);
      font-size: 0;
      img{
        width: vw(30);
        display: block;
      }
    }
    .arrow{
      font-size: 0;
      padding-left: 0;
      img{
        width: vw(34);
      }
    }
  }
  .word-icon{
    background: #FFF1E1;
    border-radius: vw(4);
    border: 1px solid #8D5D22;
    font-size: vw(22);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #8D5D22;
    line-height: vw(25);
    font-style: normal;
    padding: 0 vw(8);
    margin-right: vw(10);
  }
}
.footer{
  width: 100%;
  padding: vw(20) vw(16) 0 vw(16);
  .pay-btn{
    width: 100%;
    height: vw(80);
    background: #FF8047;
    border-radius: vw(10);
    border: none;
    font-size: vw(30);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: vw(42);
  }
}
.service{
  width: vw(44);
}
.no-margin{
  margin: 0 !important;
}
.no-border{
  border: 0 !important;
}
</style>
