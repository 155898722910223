<template>
<div class="hot-project">
    <!-- 门店/产品照片 -->
    <div class="card-box" v-if="projectData.proImage && projectData.proImage.length">
        <div class="head-title">门店/产品照片</div>
        <scroll-new-swiper :imgArr="projectData.proImage" :widthParams="28.53" :heightParams="17.33"></scroll-new-swiper>
    </div>

    <!-- 品牌详情介绍 -->
    <detailPro :content="content" :titlePro="titleNamePro" :isNum="2" />

    <!-- 消费者评价 -->
    <div class="card-box" v-if="projectData.evaluateList && projectData.evaluateList.length > 0">
        <div class="head-title">消费者评价</div>
        <div class="brand_consume" id="cloudEl">
            
        </div>
    </div>
</div>

</template>


<script>
    import scrollNewSwiper from './scrollNewSwiper'
    import detailPro from './detail_pro'

    import * as echarts from 'echarts'
    import 'echarts-wordcloud';
    export default {
        name: "projectIntroduce",
        components: {
            scrollNewSwiper,detailPro
        },
        props: {
            projectData: {
                type: Object
            },
            content: {
                type: String
            }
        },
        data() {
            return {
                cloudData: [
                    { value: Math.random(0.1,10)*100, name: "商圈附近" },
                    { value: Math.random(0.1,10)*100, name: "机场附近" },
                    { value: Math.random(0.1,10)*100, name: "性价比高" },
                    { value: Math.random(0.1,10)*100, name: "环境不错" },
                    { value: Math.random(0.1,10)*100, name: "生意爆棚" },
                    { value: Math.random(0.1,10)*100, name: "人气爆棚" },
                    { value: Math.random(0.1,10)*100, name: "位置好找" },
                    { value: Math.random(0.1,10)*100, name: "回头客" },
                    { value: Math.random(0.1,10)*100, name: "顾客多" },
                    { value: Math.random(0.1,10)*100, name: "环境吵闹" },
                    { value: Math.random(0.1,10)*100, name: "交通便利" },
                    { value: Math.random(0.1,10)*100, name: "不好停车" },
                    { value: Math.random(0.1,10)*100, name: "停车免费" },
                    { value: Math.random(0.1,10)*100, name: "带娃同乐" },
                    { value: Math.random(0.1,10)*100, name: "服务热情" },
                    { value: Math.random(0.1,10)*100, name: "态度差了一点" },
                ],
                chartItem: undefined,
                titleNamePro: '品牌详情介绍'
            }
        },
        methods: {
            load() {
                let listCopy = this.cloudData;
                let list = this.addRandomList2(listCopy);
                this.finalDataCloud = list;
                this.intiChart();
            },
            addRandomList2(list) {
                const count = 100;
                if(list.length >= count ){
                    return list;
                }
                const listNames = list.map(function(value) {
                    return value.name;
                });
                let res = list.slice(0);
                res.sort(function(a, b) {
                    return b.value - a.value;
                });
                for (let i = 0; i < count - list.length; ++i) {
                    let nameIndex = i % listNames.length;
                    let nameItem = listNames[nameIndex];
                    res.push({
                        'name': nameItem,
                        'value': 0
                    })
                }
                res = res.map(function(item, index) {
                    if (index == 0) {item.value = 80;}
                    else if (index == 1) {item.value = 60;}
                    else if (index == 2) {item.value = 50;}
                    else if (index>=3&&index<=5) {item.value = 40;}
                    else if (index>=6&&index<=9) {item.value = 30;}
                    else {item.value = Math.random()*20};
                    return item;
                });
                res.sort(function(a, b) {
                    return b.value - a.value;
                });
                return res;
            },
            intiChart() {
                let element = document.getElementById('cloudEl');
                if (element == undefined) {
                    return;
                }
                var _this = this;
                if (_this.chartItem == undefined) {
                    _this.chartItem = echarts.init(element);
                }
                var maskImage = new Image();
                maskImage.onload = function() {
                    _this.chartItem.setOption({
                        series: [{
                            type: 'wordCloud',
                            // shape:'triangle',  //circle cardioid diamond triangle-forward triangle
                            sizeRange: [6, 30],
                            rotationRange: [-45, 90],
                            maskImage: maskImage,
                            textPadding: 0,
                            //gridSize: 12,   //字体分布的密集程度
                            width: '100%',
                            height: '100%',
                            left: 'center',
                            top: 'center',
                            right: null,
                            bottom: null,
                            drawOutOfBound: false,
                            autoSize: {
                                enable: true,
                                minSize: 6
                            },
                            textStyle: {
                                normal: {
                                    // fontFamily: '思源黑体',
                                    // color:'#347FFF','#3BD6A7''#FF5900', ,
                                    color: function () {
                                        var colors = ['#99BFFF', '#3BD6A7','#347FFF'];
                                        return colors[parseInt(Math.random() * 10) % 3];
                                    }
                                },
                            },
                            //  鼠标移入的特效样式
                            // emphasis : {
                            //     shadowBlur : 10,
                            //     shadowColor : '#333'
                            // },
                            data:  _this.finalDataCloud
                        }]
                    })
                }
                maskImage.src = '/static/img/pic_shop.png';
            }
        },
        mounted() {
            if(this.projectData.evaluateList && this.projectData.evaluateList.length > 0) {
                if(this.projectData.evaluateList.length < 100) {
                    this.cloudData = [...this.projectData.evaluateList,...this.cloudData];
                }
            }
            this.load();
        }
    }

</script>


<style lang="scss" scoped>
@import "@/styles/index.scss";
.hot-project{
    width: 100%;
    height: auto;
    .card-box{
        padding: 4.53vw 4vw;
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 2.67vw;
        .head-title{
            font-size: 4.53vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 6.4vw;
        }
        
        // 品牌消费者评价词云
        .brand_consume{
            width: 100%;
            margin: 2.67vw auto 0;
            height: 57.33vw;
            background: url("/static/img/pic_shop.png") no-repeat;
            background-size: 100% auto;
            // border-radius: 1.87vw;
        }
    }
}
</style>