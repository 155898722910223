<template>
  <div class="page">
    <StateBar :bgcolor="bgColor" />
    <TitleHeader :title="'连锁品牌VIP-拓店云信息录入'" :bgcolor="bgColor" color="black" :back="!typeDialogVisible"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="true"
    >
      <div class="module">
        <!--form-->
        <van-form ref="form" validate-trigger="onSubmit">
          <van-field
            :value="saasInfo.account"
            class="register"
            required
            disabled
            name="注册账号"
            label="注册账号"
            placeholder="请输入注册账号"
            :rules="[{ required: true, message: '' }]"
          />
          <!--<van-field-->
          <!--  v-model="form.code"-->
          <!--  required-->
          <!--  name="验证码"-->
          <!--  label="验证码"-->
          <!--  placeholder="请输入验证码"-->
          <!--  :rules="[{ required: true, message: '' }]"-->
          <!--/>-->
          <van-field
            v-model="form.companyName"
            required
            :disabled="payStatus == '3'"
            name="公司名称"
            label="公司名称"
            placeholder="请输入公司名称"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="form.brand"
            required
            :disabled="payStatus == '3'"
            name="品牌名称"
            label="品牌名称"
            placeholder="请输入品牌名称"
            :rules="[{ required: true, message: '' }]"
          />
          <div class="select">
            <van-field
              :value="type"
              clickable
              required
              readonly
              :disabled="payStatus == '3'"
              name="品牌所属行业"
              label="品牌所属行业"
              placeholder="请输入品牌所属行业"
              :rules="[{ required: true, message: '' }]"
              @click="showTypeDialog"
            />
          </div>

          <!--<van-field-->
          <!--  v-model="form.city"-->
          <!--  name="权益城市"-->
          <!--  label="权益城市"-->
          <!--  placeholder="请输入权益城市"-->
          <!--  @click="showCitySelector"-->
          <!--/>-->
          <!--<van-field-->
          <!--  class=""-->
          <!--  v-model="username"-->
          <!--  name="权益品牌"-->
          <!--  label="权益品牌"-->
          <!--  placeholder="请选择权益品牌"-->
          <!--&gt;-->
          <!--  <template #input>-->
          <!--    <p class="branch-wrap">-->
          <!--      <span>-->
          <!--        <b>哈尼路亚</b>-->
          <!--        <van-icon name="cross" />-->
          <!--      </span>-->

          <!--      <span>-->
          <!--        <b>哈尼路亚</b>-->
          <!--        <van-icon name="cross" />-->
          <!--      </span>-->

          <!--        <span>-->
          <!--          <b>哈尼路亚</b>-->
          <!--          <van-icon name="cross" />-->
          <!--        </span>-->
          <!--    </p>-->
          <!--  </template>-->
          <!--</van-field>-->
          <!--<div style="margin: 16px;">-->
          <!--  <van-button round block type="info" native-type="submit">提交</van-button>-->
          <!--</div>-->
        </van-form>
        <p class="remarks">
          注：<br />
          公司名称、品牌名称、品牌所属行业注册后不可修改，请谨慎填写。<br />
          任何疑问可直接联系客服进行答疑！
        </p>
      </div>
    </AutoView>
    <!-- <SoltFooter v-if="editable"> -->
    <div class="submitFooter">
      <!--未支付-->
      <div class="btn-wrap" v-if="payStatus == '0'">
        <div class="cell">
          <div class="cell-hd" v-if="!isSaved">
            <van-button class="comfirm-btn" @click="showConfirmDialog">确认信息</van-button>
          </div>
          <div class="cell-bd">
            <van-button  class="pay-btn" @click="pay">支付{{memberEnterprise.price}}开通连锁品牌VIP</van-button>
          </div>
        </div>
      </div>
      <!--已支付-->
      <div class="btn-wrap" v-if="payStatus == '1'">
        <van-button  class="pay-btn" @click="showConfirmDialog">确认</van-button>
      </div>
      <!--查看详情-->
      <div class="btn-wrap" v-if="payStatus == '3'">
        <van-button  class="pay-btn" @click="toVip">确认</van-button>
      </div>
    </div>
    <van-popup v-model="cityVisible" round position="bottom" :style="{ height: '60%' }">
      <van-area
        title="选择城市"
        :area-list="areaList"
        @confirm="handleCityCheck"
        @cancel="hideCitySelector"
      />
    </van-popup>
    <!--confirm dialog-->
    <van-popup class="confirm-dialog" v-model="confirmDialogVisible">
      <div class="img-wrap">
        <img src="/static/vip/pay_img_success.png" />
        <p>确认信息</p>
      </div>
      <p class="tip">企业信息确认后将生成专属系统，请确认信息无误后提交</p>
      <div class="foot-btn-wrap">
        <van-button class="reset-btn" @click="cancel">重新填写</van-button>
        <van-button  class="pay-btn" @click="confirm">确认提交</van-button>
      </div>
    </van-popup>

    <van-popup v-model="typeDialogVisible" v-if="category.length > 0">
      <!--type dialog-->
      <div class="tree-select">
        <div class="c-bd">
          <div class="cell three">
            <div class="cell-hd  three-one">
              <div
                class="item"
                :class="{'active': indexs[0] === index}"
                v-for="(item, index) in category"
                :key="index"
                @click="setIndex(0, index)"
              >{{item.name}}</div>
            </div>
            <!--判断待优化-->
            <div class="cell-hd three-two"
                 v-if="category[indexs[0]].children && category[indexs[0]].children.length > 0"
            >
              <div
                class="item"
                :class="{'active': indexs[1] === index}"
                v-for="(item, index) in category[indexs[0]].children"
                :key="index"
                @click="setIndex(1, index)"
              >{{item.name}}</div>
            </div>
            <!--判断待优化-->
            <div class="cell-bd three-tree"
                 v-if="category[indexs[0]].children[indexs[1]].children &&  category[indexs[0]].children[indexs[1]].children.length > 0"
            >
              <div
                class="item"
                :class="{'active': indexs[2] === index}"
                v-for="(item, index) in category[indexs[0]].children[indexs[1]].children"
                :key="index"
                @click="setIndex(2, index)"
              >
                {{item.name}}
                <van-icon name="success" v-if="indexs[2] === index"/>
              </div>
            </div>
          </div>
        </div>
        <div class="c-ft footer">
          <van-button class="cancel" @click="hideTypeDialog">取消</van-button>
          <van-button class="confirm" @click="confirmType">确定</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 支付选择界面 -->
    <van-popup
      v-model="showPayment"
      round
      position="bottom"
      :safe-area-inset-bottom="false"
      :close-on-click-overlay="false"
      @click-overlay="onTapClose"
    >
      <div class="l-panel f-vp vipShop--subview">
        <h3>开通连锁品牌VIP<i class="icon_vip" /></h3>
        <div class="view">
          <!-- 支付方式选择 -->
          <PayTypes :payList="payList" @setPayType="setPayType" />
        </div>
        <van-button type="info" block :loading="waiting" class="s-radius__s" @click="onTap">确认支付</van-button>
      </div>
    </van-popup>

    <!-- 购买结果提醒 -->
    <van-overlay :show="showPayTips">
      <div class="l-wrapper">
        <div class="vipShop--tips l-panel s-radius__s">
          <van-image class="icon" fit="cover" :src="payTips.icon" />
          <h3>{{ payTips.title }}</h3>
          <p>{{ payTips.text }}</p>
          <van-button type="info" class="s-radius__s" @click="onConfirm">确定</van-button>
        </div>
      </div>
    </van-overlay>

    <!-- 支付等待遮罩 -->
    <van-overlay :show="waiting">
      <div class="s-overlay">
        <van-loading color="#518CDB" />
      </div>
    </van-overlay>

  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Toast } from "vant";
import PayTypes from "@/components/payTypes";

export default {
  name: 'index',
  components: {
    PayTypes,
  },
  data () {
    return {
      username: '',
      password: '',
      cityVisible: false,
      confirmDialogVisible: false,
      typeDialogVisible: false,
      areaList: areaList,
      bgColor: '#fff',
      category: [],
      indexs: [],
      payStatus: '0', // 支付状态 0: 未支付 1: 已支付 3：未支付已填写
      showPayment: false,
      showPayTips: false,
      isIOS: false,
      isAndroid: false,
      orderNo: null, // 订单号 下单时生成
      payList: ["支付宝", "微信"],
      isSaved: false,
      payTipsMap: {
        success: {
          icon: "/static/icon/msg_success_orange.png",
          title: "操作成功",
          text: "您已成功完成支付",
        },
        failed: {
          icon: "/static/icon/more.png",
          title: "操作失败",
          text: "未能完成支付操作",
        },
      },
      payMap: {
        支付宝: {
          mode: "ALIPAY_MOBILE",
          call: "aliPay",
          unit: "￥",
        },
        微信: {
          mode: "WX_APP",
          call: "wxPay",
          unit: "￥",
        },
        "Apple Pay": {
          mode: "APPLE_IN",
          call: "applePay",
          unit: "￥",
        },
      },
      payTips: {
        icon: "",
        title: "",
        text: "",
      },
      info: {
        nickname: "",
        member: null,
        select: null,
        avatar: null,
      },
      waiting: false,
      payMode: null,
      payCall: null,
      client: 3, // 1iOS 2Android 3WEB
      memberEnterprise: {
        avgDayPrice: '',
        id: '',
        iosGoodsCode: '',
        name: '',
        price: '',
        tag: ''
      },
      form: {
        brand: '',
        companyName: '',
        industry: [],
        userId: '',
      },
      type: '',
      saasInfo: {
        account: null,
        brand: null,
        companyName: null,
        expireDate: null,
        merchantCode: null,
        status: 1,
        industry: [],
        url: null,
      },
      tipOrigin: 'save', // save: 填写后提交 pay: 支付按钮提交
    };
  },
  beforeMount() {
    // 注册支付回调
    this.JSBridge.registerHandler("paymentResult", (data) => {

      // 安卓端需要进行转义
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
      if (typeof data === "object") {
        this.waiting = false;
        switch (data.state) {
          case 1:
            // 通知客户端更新用户信息
            this.JSBridge.callHandler("noticeUpdate", "userInfo");
            this.toComplete();
            // this.toVip();
            // 支付成功
            // this.showPayment = false;
            // this.payTips = this.payTipsMap.success;
            // this.payTips.state = true;
            // this.showPayTips = true;

            break;
          case 5: // 兼容微信
            break;
          case 7: // 超时
          case 8: // 取消
            this.$toast("已取消支付");
            break;
          default:
            // 支付失败
            this.showPayment = false;
            this.payTips = this.payTipsMap.failed;
            this.payTips.state = false;
            this.showPayTips = true;
            console.log("支付失败", data.status, this.payTips)
        }
      } else {
        this.$toast("支付时发生错误！");
        this.waiting = false;
      }
    });
    // 设置客户端类型
    if (window.isIOS) {
      this.isIOS  = true;
      this.client = 1;
      // if(window._appCode >= 301 ) {
      //     this.vipList.splice(1,1)
      // }
    } else if (window.isAndroid) {
      this.isAndroid = true
      this.client = 2;
    }
  },
  mounted () {
    this.JSBridge.callHandler("setStatebarTheme", 'black');
    this.indexs = new Array(3).fill(0);
    this.getTypeCategory();
    this.getVipInfo();
    this.handlePayStatus();
  },
  methods: {
    handlePayStatus(){
      if(this.$route.params.payStatus) {
        this.payStatus = this.$route.params.payStatus;
      }
    },
    getVipInfo(){
      // 查询会员信息
      this.HTTP.getVipInfo()
        .then((res) => {
          console.log(res, 77)
          this.saasInfo = Object.assign(this.saasInfo, res.saasRes || {});
          this.memberEnterprise =  Object.assign(this.memberEnterprise, res.memberEnterprise || {});
          this.info.select = this.memberEnterprise;
          this.form.companyName = this.saasInfo.companyName;
          this.form.brand = this.saasInfo.brand;
          try {
            this.industry = JSON.parse(this.saasInfo.industry) || [];
            this.type = this.industry.map(item => item.value).join('-')
            console.log(this.type)
          } catch (e) {
            console.log(e)
            // this.industry = [];
            // this.type = '';
          }
        })
        .catch((e) => {
          this.$toast("网络连接不稳定，请稍候重试！");
        });
    },
    setIndex (level, index) {
      this.indexs[level] = index;
      this.indexs = [].concat( this.indexs);
      if (level === 0) {
        this.indexs[1] = 0;
        this.indexs[2] = 0;
      }
      if (level === 1) {
        this.indexs[2] = 0;
      }
    },
    getChild (children, index) {
      return children[index].children;
    },
    getLevel (level) {
      for (let i = 0; i < level; i++) {
        this.getChild()
      }
    },
    getTypeCategory () {
      // 获取行业列表
      this.HTTP.formatCategory()
        .then(res => {
          this.category = res.data;
         console.log(res);
        })
        .catch(e => {
          this.$toast('获取行业信息时发生错误！');
        });
    },
    onSubmit (values) {
      console.log('submit', values);
    },
    hideCitySelector () {
      this.cityVisible = false;
    },
    showCitySelector () {
      this.cityVisible = true;
    },
    handleCityCheck (data) {
      this.form.city = data.map(item => item.name).join('-');
     this.hideCitySelector();
    },
    hideConfirmDialog () {
      this.confirmDialogVisible = false;
    },
    toVip(){
      this.JSBridge.callHandler("onBackPressed", {
        type: 5,
        url: "ONLINE_SERVICE",
        params: null,
      });
    },
    async showConfirmDialog () {
      await this.$refs.form.validate();
      this.tipOrigin = 'save';
      this.confirmDialogVisible = true;
    },
    cancel () {
      this.hideConfirmDialog();
    },
    confirm () {
      this.HTTP.saveSaasInfo(this.form).then((res) => {
        if (res.data.code !== 0) {
          Toast(res.msg || '服务器繁忙');
          return;
        }
        this.confirmDialogVisible = false;
        this.isSaved = true;
        if (this.tipOrigin === 'pay') {
          this.onTapPay();
        } else if (this.payStatus == '1') {
          this.toDetails();
        } else {
          this.JSBridge.callHandler("onBackPressed");
        }
      }).catch((e) => {
        if (e.msg) {
          Toast(e.msg || '服务器繁忙');
        }
      });
    },
    async pay () {
      await this.$refs.form.validate();
      this.tipOrigin = 'pay';
      if (!this.isSaved) {
        this.confirmDialogVisible = true;
      } else {
        this.onTapPay();
      }
      // this.onTapPay();
    },
    toDetails () {
      this.JSBridge.callHandler("onBackPressed");
      this.swRouter({
        path: "/vip/enterprise-details",
      })
    },
    toComplete () {
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 6,
        url: '#/vip/enterprise/pay-successe-complete',
        isClear: true,
      });
      // this.swRouter({
      //   path: "/vip/enterprise/pay-successe-complete",
      // })
    },
    showTypeDialog () {
      if (this.payStatus == '3') return;
      this.typeDialogVisible = true;
    },
    hideTypeDialog () {
      this.typeDialogVisible = false;
    },
    confirmType () {
      this.setType();
      this.hideTypeDialog();
    },
    setType () {
      let levelOne = this.category[this.indexs[0]];
      let levelTwo = this.category[this.indexs[0]].children[this.indexs[1]]
      let levelTree = this.category[this.indexs[0]].children[this.indexs[1]].children[this.indexs[2]];
      this.type =  `${levelOne.name}-${levelTwo.name}-${levelTree.name}`;
      this.form.industry = [
        {
          key: levelOne.code,
          selected: true,
          value: levelOne.name,
        },
        {
          key: levelTwo.code,
          selected: true,
          value: levelTwo.name,
        },
        {
          key: levelTree.code,
          selected: true,
          value: levelTree.name,
        }
      ]
    },
    // 立即开通
    onTapPay() {
      // 按钮埋点上报
      // this.JSBridge.BTNCLICK(this.$route.meta.id, "", 60003, "");
      if ( this.isIOS && window._appCode >= 301 ) {
        this.setPayType("Apple Pay")
        this.onTap();
      } else {
        this.showPayment = true;
        // if(this.info.select.type == 2) {
        //   this.payList = ['支付宝']
        // } else {
          this.payList = ['支付宝', '微信']
        // }
      }
    },
    onTap() {
      //业务流程
      this.waiting = true;
      console.log("创建订单..." + this.payMode + "====" + this.info.select.id + "=====" + this.client);
      this.HTTP.createVip(this.payMode, this.info.select.id, this.client)
        .then((res) => {
          console.log(res, "打印payResult")
          console.log("创建订单...end");
          console.log("调起支付...");
          this.orderNo = res.orderNo;
          const data = {
            id: res.orderNo,
            data: res.payResult,
          };
          if (window.isClient) {
            this.JSBridge.callHandler(this.payCall, data, (st) => {
              if (!st) {
                this.$toast("未安装客户端，调起支付失败！");
                setTimeout(() => {
                  this.waiting = false;
                }, 1000);
                console.log(`调起支付...失败`);
                return;
              }
              console.log(`调起支付...end`);
            });
          } else {
            this.$toast("暂不支持网页版支付");
            setTimeout(() => {
              this.waiting = false;
            }, 1000);
          }
        })
        .catch((e) => {
          this.$toast("创建订单时发生错误！");
          setTimeout(() => {
            this.waiting = false;
          }, 1000);
        });
    },
    onTapClose() {
      this.waiting = false;
      // if (this.payLoading) return;
      this.showPayment = false;
    },
    setPayType(value) {
      const map = this.payMap[value];
      this.payType = value;
      this.payMode = map.mode;
      this.payCall = map.call;
      // this.info.unit = map.unit;
    },
    onConfirm() {
      if (this.payTips.state) {
        this.showPayTips = false;
        this.JSBridge.callHandler("onNormalizingCall", {
          type: 5,
          url: "CHANGE_MAIN_TAB",
          id: "me",
          params: null,
          trackData: null,
          isClear: true,
        });
      } else {
        this.showPayTips = false;
      }
    },
    pageScrollHandle() {
      let tg = this.$refs.standard;
      let sv = 20;
      if (!tg) {
        tg = this.$refs.top;
        sv = -10;
      }
      const stdTop = tg.getBoundingClientRect().top;
      const check = stdTop > sv;
      if (this.isTop !== check) {
        this.isTop = check;
        this.bgColor = check ? "transparent" : "white";
        this.titleColor = check ? "white" : "#1D2233";
        this.JSBridge.callHandler("setStatebarTheme", check ? "white" : "black");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import '@/styles/_cell.scss';
*{
  box-sizing: border-box;
}
.page{
  background-color: #F8F8F9;
}
.module{
  background: url('/static/vip/img-form-header.png') no-repeat;
  background-size: 100% auto;
  padding: vw(215) vw(30) vw(100) vw(30);
  .van-form{
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px vw(11) vw(3) rgba(13, 13, 13, 0.06);
    border-radius: vw(10);
    overflow: hidden;
    padding: vw(15) 0;
    ::v-deep .van-field__label{
      color: #333;
      font-weight: bold;
    }
    .van-field{
      font-size: vw(28);
      &.register{
        color: #999999;
      }
    }
  }
  .select{
    position: relative;
    &::after{
      content: ' ';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: vw(27);
      width: vw(14);
      height: vw(29);
      background: url('/static/vip/icon-right-arrow.png') no-repeat;
      background-size: 100% auto;
    }
  }
  .branch-wrap{
    vertical-align: top;
    font-size: 0;
    span{
      display: inline-block;
      background: #DFEDFF;
      border-radius: vw(10);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #347FFF;
      padding: vw(14) vw(14);
      line-height: 100%;
      vertical-align: top;
      font-size: 0;
      margin: vw(6);
      b{
        font-weight: 400;
        vertical-align: top;
        line-height: vw(24);
        font-size: vw(24);
        display: inline-block;
      }
      i{
        margin-left: vw(8);
        display: inline-block;
        font-size: vw(24);
        vertical-align: top;
        display: inline-block;
      }
    }
  }
  .remarks{
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: vw(33);
    margin-top: vw(25);
  }
  .register *{
    color: #999999;
  }
}

.confirm-dialog{
  width: vw(630);
  padding: vw(30) vw(38);
  border-radius: vw(20);
  .img-wrap{
    text-align: center;
    img{
      display: inline-block;
      width: vw(120);
      height: vw(120);
    }
    p{
      font-size: vw(32);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: vw(44);
      margin-top: vw(14);
    }
  }
  .tip{
    font-size: vw(26);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8E9099;
    line-height: vw(44);
    padding: 0 vw(10) ;
    margin-top: vw(18);
    text-align: center;
  }
  .foot-btn-wrap{
    margin-top: vw(30);
    text-align: center;
    font-size: 0;
    .van-button{
      min-width: vw(255);
      border-radius: vw(20);
      font-size: vw(30);
      font-weight: 500;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    .reset-btn{
      background: #F7F6F9;
      color: #8E9099;
    }
    .pay-btn{
      background: #347FFF;
      color: #FFFFFF;
      margin-left: vw(24);
    }
  }
}
.submitFooter{
  .btn-wrap{
    padding: vw(15) vw(20);
    background: #FFFFFF;
    box-shadow: 0px -2px 6px 0px rgba(34, 34, 34, 0.12);
    .cell-bd{
      padding: 0 vw(10);
    }
    .comfirm-btn{
      width: 100%;
      //box-shadow: 0px vw(-2) vw(6) 0 rgba(34, 34, 34, 0.12);
      border-radius: vw(10);
      border: vw(2) solid #347FFF;
      font-size: vw(30);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #347FFF;
      line-height: vw(42);
      //text-shadow: 0px vw(-2) vw(6) rgba(34, 34, 34, 0.12);
    }
    .pay-btn{
      width: 100%;
      background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
      //box-shadow: 0px vw(-2) vw(6) 0px rgba(34, 34, 34, 0.12);
      border-radius: vw(10);
      border: 0;
      font-size: vw(30);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: vw(42);
      //text-shadow: 0px vw(-2) vw(6) rgba(34, 34, 34, 0.12);
    }
  }
}

.tree-select{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction:column;
  padding-top: vw(100);
  background: #f7f7f7;
  .c-bd{
    flex-grow: 1;
    flex-shrink:1;
    position: relative;
  }
  .c-ft{
    flex-grow: 0;
    flex-shrink:0;
  }
  .three{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: start;
    padding: vw(20);
    font-size: vw(24);
    color: #323233;
    text-align: center;
    height: 100%;
    overflow: auto;
    >div{
      height: 100%;
      overflow: auto;
    }
    .item{
      position: relative;
      line-height: vw(80);
      border-radius: vw(10) vw(0) vw(0) vw(10);
      &.active{
        color: #3c7dd4;
        background: #fff;
      }
      .van-icon{
        position: absolute;
        top: 50%;
        right: vw(20);
        transform: translateY(-50%);
      }
    }
    .three-one{
      .item{
        padding: 0 vw(30);
      }
    }
    .three-two{
      background: #fff;
      width: 40%;
    }
    .three-tree{
      background: #fff;
      .van-icon{
        margin-left: vw(20);
      }
    }
  }
  .footer{
    padding: vw(20) 0 vw(50) 0;
    text-align: center;
    font-size: 0;
    .van-button{
      width: 45%;
      height: vw(80);
      margin: 0 vw(10);
      border-radius: vw(10);
      font-size: vw(30);
      font-weight: 500;
      line-height: vw(42);
    }
    .cancel{
      border: vw(2) solid #347FFF;
      color: #347FFF;
    }
    .confirm{
      border: 0;
      background: linear-gradient(270deg, #62AFFF 0%, #347FFF 100%);
      color: #FFFFFF;
    }
  }
}

.popup{
  z-index: 999;
}

</style>
