<template>
    <div class="brand_consume" id="cloudEl"></div>
</template>

<script>
import "echarts-wordcloud";
import echarts from "echarts";

export default {
    props: {
        bgmData: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            chartItem: undefined,
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            let data = this.bgmData;
            if (!data) {
                return;
            }
            let deelData = data.map((item) => {
                return {
                    name: item.code,
                    value: item.count,
                };
            });
            this.intiChart(deelData);
        },
        intiChart(deelData) {
            let element = document.getElementById("cloudEl");
            if (!element) {
                return;
            }
            if (!this.chartItem) {
                this.chartItem = echarts.init(element);
            }
            let option = {
                series: [
                    {
                        type: "wordCloud",
                        sizeRange: [8, 22],
                        rotationRange: [0, 0],
                        // maskImage: maskImage,
                        textPadding: 0,
                        gridSize: 8, //字体分布的密集程度
                        width: "100%",
                        height: "100%",
                        left: "center",
                        top: "center",
                        right: null,
                        bottom: null,
                        drawOutOfBound: false,
                        autoSize: {
                            enable: true,
                            minSize: 6,
                        },
                        textStyle: {
                            normal: {
                                color: function () {
                                    var colors = ["#99BFFF", "#3BD6A7", "#347FFF"];
                                    return colors[parseInt(Math.random() * 10) % 3];
                                },
                            },
                        },
                        data: deelData,
                    },
                ],
            };
            this.chartItem.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
.brand_consume {
    width: 100%;
    margin: 2.67vw auto 0;
    height: 57.33vw;
    background: #fff;
    background-size: 100% auto;
}
</style>
