<template>
    <div class="special-list">
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-show="!firstLoading">
            <VanImage @load="imageLoadDone" :src="item.name" v-for="(item, i) in contentList" :key="i">
            </VanImage>
        </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
    name: 'specialList',
    data () {
        return {
            firstLoading: true,
            contentList: ''
        };
    },
    mounted () {
        this.HTTP.openShop( 5 ).then( ( res ) => {
            console.log( '特殊榜单：', res );
            this.contentList = res.data
        } );
    },
    methods: {
        imageLoadDone () {
            picNum ++;
            if ( picNum >= 1 ) {
                this.firstLoading = false;
            }
        },
        deeplink ( { type, url, params, id, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            btnId && this.JSBridge.BTNCLICK( this.$route.meta.id, '', 81000, btnId ),
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                id,
                params: JSON.stringify( params )
            } );
        }
    }
};
</script>

<style lang="scss" scoped>
.special-list {
    .van-image {
        width: 100vw;
        margin-bottom: -4px;
    }
}
</style>
