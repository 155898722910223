<template>
    <div class="page">
        <!-- 介绍界面 -->
        <!-- <img src="/static/img/share_201205.jpg" class="page--bg"> -->
        <img src="/static/img/share_201231.jpg" class="page--bg">
        <div class="page--footer">
            <button type="button" @click="download">立即领取福利</button>
            <!-- <p class="info">本活动截至11月6日0时</p> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'page',
    data () {
        return {
            title: '上上参谋，生意人的数据军师',
        };
    },
    methods: {
        download () {
            top.location.href = this.HTTP.getAppStore();
        },
    },
    created () {
        // 设置标题
        document.title = this.title;
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.page {
    position: relative;
    min-height: 100vh;
    // background-color: #4B20B6;
    background-color: #f8656d;
    padding-bottom: 20vw;
    box-sizing: border-box;

    #{&}--bg {
        width: 100%;
    }

    #{&}--footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 20vw;
        // background: linear-gradient(to bottom, #7335E6, #451DB4);
        // box-shadow: 0 -2vw 5vw rgba(#451DB4, .3);
        background-color: white;
        box-shadow: 0 -2vw 5vw rgba(black, .25);
        border-radius: $radius_s $radius_s 0 0;
        text-align: center;
        padding: $margin $padding;
        box-sizing: border-box;

        .info {
            @include font( #FFBB00, 2.66vw, normal );
            margin-top: $margin * 0.5;
        }
    }

    button {
        background: linear-gradient(to right, #FFA943, #FE6D40);
        color: white;
        font-size: $font_373;
        font-weight: bold;
        width: 100%;
        height: 13vw;
        border-radius: $radius_xs;
        border: none;
    }
}
</style>
