<template>
    <div class="basic-map-wrap">
        <div class="map_container" :id="`baseMap${mapElId}`"></div>
        <TextMarker ref="textMarkerVm" class="text-markr" v-for="marker in questionList" :key="marker.id"  :data="marker" @click="questionMarkerClick(marker)"/>
    </div>
</template>
<script>
import LoadMap from './loadMap';
import TextMarker from './TextMarker';
import { random } from '@/utils/index';
// html hader中加载高德地图
// 导入配置文件 116.38, 39.90 北京，彩讯：113.946411, 22.531741
const baseMapConfig = {
    center      : [116.407387, 39.904179], // 中心点 深圳
    zoom        : 12, // 地图层级
    zooms       : [5, 19], // 地图缩放范围
    rotateEnable: false, // 不可旋转
    resizeEnable: true, // 监听地图缩放事件
    dragEnable  : true, // 不可拖拽。和报告的翻页冲突
    features    : ['bg', 'point'] // 'point' 地图上显示的元素
};
let id = 0; const listener = []; let currentAddr = null;
const searchRadius = 10000;
// @@@ 释放地图，释放页面，释放监听事件，防止内存泄漏
export default {
    components: {
        TextMarker
    },
    props: {
        mapType: {
            default: 1
        },
        center: {
            type: Array
        },
        zoom: {
            zoom   : Number,
            default: 17
        },
        radius: {
            default: 500
        }
    },
    data () {
        return {
            /**
             * 基础地图
             */
            mapElId     : id,
            map         : undefined,
            mapCenter   : [114.085947, 22.547],
            questionList: [],
        };
    },
    watch: {
        center ( nv ) {
            if ( nv.length ) {
                this.setMapCenter( nv );
            }
        },
        zoom ( nv ) {
            if ( nv !== undefined ) {
                this.setMapZoom( nv );
            }
        },
        radius ( nv ) {
            if ( this.circleMarkerIns && nv ) {
                this.circleMarkerIns.setRadius( Number( nv ) );
            }
        }
    },
    methods: {
        onloadListener () {
            this.initMap();
        },
        /**
         * 初始化插件
         */
        initMap ( id ) {
            id = id || `baseMap${this.mapElId}`;
            const config = { ...baseMapConfig, center: this.center, zoom: this.zoom };
            this.map = new AMap.Map( id, config );
            this.map.on( 'complete', this.mapCompleteListener );
            this.map.on( 'mapmove', this.mapMoveListener  );
            this.map.on( 'moveend', this.mapMoveendListener );
            // 初始化完成，把事件队列清空
            listener.forEach( ( fn ) => {
                fn();
            } );
            listener.length = 0;
        },
        mapCompleteListener () {
            this.addDotMarker( this.center );
            if ( this.mapType === 2 ) {
                this.addCircle( this.center, this.radius );
            }
            this.addCurrentNeedleMarker();
            this.$emit( 'complete', this.map );
        },
        mapMoveListener () {
            if ( this.mapType === 2 ) {
                const poi = this.map.getCenter();
                if ( ! poi ) { return; }
                this.currNeedleMarkerIns && this.currNeedleMarkerIns.setPosition( poi );
                this.circleMarkerIns && this.circleMarkerIns.setCenter( poi );
            }
        },
        mapMoveendListener () {
            const cObj = this.map.getCenter();
            const mapCenter = [cObj.lng, cObj.lat];
            this.$emit( 'mapMoveend', mapCenter, this.map );
        },
        /// /////////////////////////////////////////////////////////////////////
        // 地图操控基本函数
        getMap () {
            return this.map;
        },
        getMapBounds () {
            return this.map.getBounds();
        },
        setMapCenter ( center ) {
            if ( this.map ) {
                this.map.setCenter( center );
            }
        },
        setMapZoom ( zoom ) {
            if ( this.map ) {
                this.map.setZoomAndCenter( zoom, this.center );
            }
        },
        // 当前位置点的marker
        addDotMarker ( position ) {
            const iconConfig = {
                image    : '/static/img/map-dot.svg',
                imageSize: new AMap.Size( 33, 33 ),
                size     : new AMap.Size( 33, 33 ),
            };
            this.currDotMarkerIns = this.addMarker( position, iconConfig, );
        },
        // 移动当前位置点
        currDotSetCenter ( lng, lat ) {
            const poi = new AMap.LngLat( lng, lat );
            this.currDotMarkerIns && this.currDotMarkerIns.setPosition( poi );
        },
        // 蓝色选点针，表示选择的位置
        addCurrentNeedleMarker ( position ) {
            const offset = new AMap.Pixel( - 16, - 30 );
            const iconConfig = {
                image    : '/static/img/map_needle2.svg',
                imageSize: new AMap.Size( 33, 33 ),
                size     : new AMap.Size( 33, 33 )
            };
            this.currNeedleMarkerIns = this.addMarker( position, iconConfig, { zIndex: 2, anchor: 'center-left', offset: offset } );
        },
        // 移动蓝色针
        currNeedleSetCenter ( lng, lat ) {
            const poi = new AMap.LngLat( lng, lat );
            this.currNeedleMarkerIns && this.currNeedleMarkerIns.setPosition( poi );
        },
        // 添加marker通用方法
        addMarker ( position, iconConfig, markerConfig ) {
            const marker = new AMap.Marker( {
                icon     : new AMap.Icon( iconConfig ),
                position, // [lng, lat],
                offset   : new AMap.Pixel( - 16, - 16 ),
                zIndex   : 1,
                clickable: false,
                ...markerConfig
            } );
            marker.setMap( this.map );
            return marker;
        },
        // 添加html字符串自定义内容
        addQuestHtmlMarker ( position, content ) {
            const marker = new AMap.Marker( {
                position, // [lng, lat],
                zIndex : 10,
                content: content
            } );
            marker.setMap( this.map );
        },
        // 添加圆形遮罩层通用方法
        addCircle ( position, radius ) {
            const circle = new AMap.Circle( {
                center       : position,
                radius       : radius, // 半径 米
                borderWeight : 1,
                strokeColor  : '#518CDB',
                strokeWeight : 2,
                strokeOpacity: 0.17,
                strokeStyle  : 'solid',
                fillOpacity  : 0.3,
                // 线样式还支持 'dashed'
                fillColor    : '#518CDB',
                bubble       : true,
                zIndex       : 2
            } );
            circle.setMap( this.map );
            this.circleMarkerIns = circle;
            return circle;
        },
        // 添加问题列表的marker
        addQuestListMarker ( list ) {
            if ( ! window.AMap || ! this.map ) {
                // 动态加载，等待sdk完成
                return this.addListener( () => {
                    this.addQuetionMarker( list );
                } );
            }
            this.addQuetionMarker( list );
        },
        // @@@生成问题列表的marker,需要性能优化
        addQuetionMarker ( list ) {
            this.questionList = list;
            // 获取边界
            const bounds = this.getMapBounds();
            const lng    = bounds.northeast.lng - bounds.southwest.lng;
            const lat    = bounds.northeast.lat - bounds.southwest.lat;
            const maxA   = 10;
            const maxB   = 15;
            const mLng   = lng / maxA;
            const mLat   = lat / maxB;
            // const center = this.map.getCenter();
            // 计算位置
            const pos    = [];
            const used   = {};
            // let count    = 0;
            for ( let i = list.length || 0; -- i >= 0; ) {
                let lngR = 0;
                let latR = 0;
                while ( true ) {
                    // count ++;
                    lngR = random( 1, maxA - 1 );
                    latR = random( 2, maxB - 2 );
                    if ( ! used.hasOwnProperty( lngR.toString() + latR.toString() ) &&
                        ! used.hasOwnProperty( ( lngR - 1 ).toString() + latR.toString() ) &&
                        ! used.hasOwnProperty( ( lngR + 1 ).toString() + latR.toString() ) ) {
                        break;
                    }
                }
                used[ lngR.toString() + latR.toString() ] = 1;
                pos.push( { lng: mLng * lngR, lat: mLat * latR } );
            }
            // console.log( count ); // 计算次数测试
            this.$nextTick( () => {
                for ( let i = 0; i < list.length; i ++ ) {
                    const { $el } = this.$refs.textMarkerVm[i];
                    if ( ! $el ) { continue; }
                    list[i].lng = bounds.northeast.lng - pos[i].lng;
                    list[i].lat = bounds.northeast.lat - pos[i].lat;
                    this.addQuestHtmlMarker( [ list[i].lng, list[i].lat ], $el );
                }
            } );
        },
        questionMarkerClick ( item ) {
            this.$emit( 'questionMarkerClick', item );
        },
        /**
         * 如果是外部调用方法，可能存在高德sdk加载未完成的情况
         */
        addListener ( fn ) {
            listener.push( fn );
        },
        /**
         * 根据经纬度，调用高德地图获取所属城市,并保存至store
         */
        getCurrentCity ( lnglat, callbackFn ) {
            let geocoder = new AMap.Geocoder( { city: '全国' } );
            geocoder.getAddress( lnglat, ( status, result ) => {
                if ( status === 'complete' && result.info === 'OK' ) {
                    // result为对应的地理位置详细信息
                    const { addressComponent:{ province, city } } = result.regeocode;
                    currentAddr = { province, city: city || province };
                    callbackFn && callbackFn( currentAddr );
                } else {
                    console.log( '地址解析失败，重新选择' );
                }
            } );
            geocoder = null;
        },
        /**
         * 搜索附近的
         */
        searchNearBy ( { keyword, center, radius = searchRadius, citylimit,
            city, pageSize = 20, pageIndex = 1, success } ) {
            if ( ! success ) {
                throw new Error( '请传入回调函数' );
            }
            if ( ! city ) { console.error( '请输入poi搜索的城市' ); }
            const placeSearch = new AMap.PlaceSearch( { // 构造地点查询类
                pageSize, // 默认获取前二十个结果
                pageIndex,
                city     : city || '全国',
                citylimit: citylimit || true,
                // extensions: 'all',
            } );
            placeSearch.searchNearBy( keyword, center, radius, ( status, res ) => {
                // calllbackFn 第一个参数:搜索是否有结果,
                // 第二个参数:搜索结果,
                // console.log( status, res );
                if ( status === 'complete' ) {
                    return success( true, res, status );
                }
                console.log( `${city}高德地图搜索POI失败` );
                // status === 'error' || status === 'no_data' 搜索当前城市失败，搜索全国
                success( false, res, status );
            } );
        },
        /**
         * 输入提示后补全
        */
        searchAutoComplete ( { city, keyword, success, citylimit } ) {
            if ( ! success ) {
                throw new Error( '请传入回调函数' );
            }
            console.log( city );
            if ( ! city ) { console.error( '请输入poi搜索的城市' ); }
            const auto = new AMap.Autocomplete( {
                city     : city || '全国',
                citylimit: citylimit || true,
            } );
            auto.search( keyword, function ( status, res ) {
                if ( status === 'complete' ) {
                    return success( true, res, status );
                }
                console.log( '高德地图全国搜索POI失败' );
                // status === 'error' || status === 'no_data'
                success( false, res, status );
            } );
        },
        /**
         * 关键字搜索，调用AMap.PlaceSearch,AMap.Geocoder插件服务
         * {
         * keyword:关键词，center:原点，radius:搜索半径，city:搜索的城市，callbackFn：回调函数
         * }
        */
        searchPoiByKeyword ( { keyword, center, radius = searchRadius,
            city = '全国', pageSize = 20, pageIndex = 1, success } ) {
            /**
           * 搜索策略：
           * 1.根据当前的经纬度获取当前所在城市，
           * 2.在当前位置10km范围内搜索关键词
           * 3.如果在当前城市10km搜索不到关键词对应的poi，搜索全国。
           */
            if ( ! success ) {
                throw new Error( '请传入回调函数' );
            }
            if ( ! city ) { console.error( '请输入poi搜索的城市' ); }
            let placeSearch = new AMap.PlaceSearch( { // 构造地点查询类
                pageSize, // 默认获取前二十个结果
                pageIndex,
                city     : city || '全国',
                citylimit: true,
                // extensions: 'all',
            } );
            placeSearch.searchNearBy( keyword, center, radius, ( status, res ) => {
                // calllbackFn 第一个参数:搜索是否有结果,
                // 第二个参数:搜索结果,
                console.log( status, res );
                if ( status === 'complete' ) {
                    return success( true, res, status );
                }
                console.log( `${city}高德地图搜索POI失败` );
                // status === 'error' || status === 'no_data' 搜索当前城市失败，搜索全国
                placeSearch.setCity( '全国' );
                placeSearch.search( keyword,  ( status, res ) => {
                // calllbackFn 第一个参数:搜索是否有结果,
                // 第二个参数:搜索结果
                    placeSearch = null;
                    if ( status === 'complete' ) {
                        return success( true, res, status );
                    }
                    console.log( '高德地图全国搜索POI失败' );
                    if ( status === 'error' || status === 'no_data' ) {
                        return success( false, res, status );
                    }
                } );
            } );
        },
    },
    beforeCreate () {
        id ++;
    },
    created () {
        this.mapElId = id;
    },
    mounted () {
        // 加载SDK库
        LoadMap().then( () => {
            this.initMap();
        } ).catch( () => {
            window.addEventListener( 'load', this.onloadListener );
        } );
    },
    beforeDestroy () {
        // 销毁事件和实例
        window.removeEventListener( 'load', this.onloadListener );
        if ( this.map ) {
            this.map.off( 'complete', this.mapCompleteListener );
            this.map.off( 'mapmove', this.mapMoveListener );
            this.map.off( 'moveend', this.mapMoveendListener );
            this.map.destroy();
        }
    },
};
</script>

<style scoped lang="scss">
.map_container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    z-index: 0;
}
.text-markr{
    display: none;
}
</style>
