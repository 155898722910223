<template>
    <div class="wrapper">
        <StateBar></StateBar>
        <TitleHeader title="上上参谋连锁企业版" :back="true"> </TitleHeader>
        <AutoView :header="true" :footer="true" ref="list">
            <van-skeleton :loading="!firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
            <div v-show="firstLoading">
                <VanImage @load="imageLoadDone" src="/static/img/image_enterprise.png"></VanImage>
                <VanImage @load="imageLoadDone" src="/static/vip/vip-brand.png"></VanImage>
                <div class="input-wrapper" ref="inputWrapper">
                    <template>
                        <InformationCard
                            label="品牌名称"
                            :value="submitOpt.brandName"
                            type="input"
                            name="brandName"
                            errname="brandErr"
                            :error="errorInfo.brandErr"
                            placeholder="请填写品牌名称、公司名称或者项目名称"
                            @input="changeInput"
                        />
                        <InformationCard
                            label="身份"
                            :value="submitOpt.identity"
                            :list="identityList"
                            :error="errorInfo.identityErr"
                            name="identity"
                            errname="identityErr"
                            @selectValue="selectHandle"
                        />
                        <InformationCard
                            label="在营门店"
                            :value="submitOpt.openShopCount"
                            :list="onStoreList"
                            name="openShopCount"
                            :error="errorInfo.openShopErr"
                            errname="openShopErr"
                            @selectValue="selectHandle"
                        />
                        <InformationCard
                            label="你的行业"
                            :value="submitOpt.industryName"
                            :list="industryList"
                            :error="errorInfo.industryErr"
                            name="industryName"
                            errname="industryErr"
                            @selectValue="selectHandle"
                        />

                        <InformationCard
                            label="你的职位"
                            :value="submitOpt.position"
                            name="position"
                            errname="positionErr"
                            :list="postList"
                            :error="errorInfo.positionErr"
                            @selectValue="selectHandle"
                        />
                        <!-- 省市区联动 -->
                        <div @click="changeInput">
                            <InformationCard
                                label="所在城市"
                                :value="submitOpt.openShopCity"
                                type="input"
                                name="openShopCity "
                                errname="openCityErr"
                                :isdisabled="true"
                                :error="errorInfo.openCityErr"
                                placeholder="请选择主要经营城市"
                            />
                        </div>
                        <!-- <van-area title="请选择" :area-list="areaList" :columns-num="3"/> -->
                        <!-- 解决问题可多选 -->
                        <div class="slove_cstyle">
                            <InformationCard
                                label="想解决的问题 (可多选)"
                                :multipleList="submitOpt.solveProblem || []"
                                multiple
                                name="solveProblem"
                                errname="solveProblemErr"
                                :list="solveProblemList"
                                :error="errorInfo.solveProblemErr"
                                @multipleSelect="multipleSelect"
                            />
                            <van-cell-group class="bgcStyle" v-if="isClickDefine">
                                <van-field
                                    v-model="solveMsg"
                                    rows="1"
                                    autosize
                                    type="textarea"
                                    maxlength="50"
                                    placeholder="请详细输入您的要求"
                                    show-word-limit
                                />
                            </van-cell-group>
                        </div>
                        <!--  -->
                        <InformationCard
                            label="姓名"
                            type="input"
                            :value="submitOpt.username"
                            placeholder="怎么称呼您？"
                            name="username"
                            errname="usernameErr"
                            :error="errorInfo.usernameErr"
                            @input="changeInput"
                        />
                    </template>

                    <InformationCard
                        label="手机号"
                        type="input"
                        :maxlength="11"
                        name="phone"
                        :value="submitOpt.phone"
                        placeholder="请输入11位手机号码"
                        :error="errorInfo.phoneErr"
                        @input="changeInput"
                    />
                </div>
            </div>
        </AutoView>
        <SoltFooter class="footerBar">
            <van-button type="info" block class="s-radius__s btn1" @click="sumbitHandle">
                {{btnText}}
            </van-button>
        </SoltFooter>

        <van-popup v-model="sussessPopup" @click-overlay="confirmfHandle" style="border-radius: 3.2vw;">
            <div class="success">
                <img src="/static/icon/loan_success.png" alt="" />
                <div class="desc">
                    <template v-if="isEdit">
                        <div class="title">修改成功</div>
                    </template>
                    <template v-else>
                        <div class="title">提交成功</div>
                        <div>请耐心等待</div>
                    </template>
                </div>
                <div class="btn" @click="confirmfHandle">
                    <van-button type="info" block>确认</van-button>
                </div>
            </div>
        </van-popup>
        <!-- 省市区弹框 -->
        <van-popup position="bottom" v-model="showRegionPicker">
            <van-picker
                value-key="name"
                @cancel="showRegionPicker = false"
                @confirm="confirmRegion"
                show-toolbar
                title="选择开店地区"
                :columns="columns"
            />
        </van-popup>
    </div>
</template>

<script>
// let picNum = 0;
import InformationCard from "./components/informationCard.vue";
import { isPhoneNo } from "@/utils/index";

export default {
    data() {
        return {
            // 是否点击了自定义解决问题狂
            isClickDefine: false,
            // 解决问题输入框
            solveMsg: "",
            // 骨架屏
            picNum: 0,
            // 选择省市区  弹框
            showRegionPicker: false,
            // 省市区 弹框数据columns
            columns: [],
            firstLoading: false,
            sussessPopup: false,
            isEdit: false,
            isIOS: false,
            type: 2,
            submitOpt: {
                brandName: "",
                // 新加字段 在营门店
                openShopCount: "",
                industryName: "",
                position: "",
                // 所在城市
                openShopCity: "",
                username: "",
                phone: "",
                // 新增身份
                identity: "",
                // 新增解决方案 多选
                solveProblem: []
            },
            errorInfo: {
                brandErr: "",
                // 在营门店错误
                openShopErr: "",
                industryErr: "",
                positionErr: "",
                // 所在城市错误
                openCityErr: "",
                usernameErr: "",
                phoneErr: "",
                // 新增报错信息
                identityErr: "",
                solveProblemErr: ""
            },
            industryList: [
                { name: "餐饮" },
                { name: "零售" },
                { name: "地产" },
                { name: "教育" },
                { name: "娱乐" },
                { name: "广告" },
                { name: "丽人" },
                { name: "其他" }
            ],
            // 想解决的问题
            solveProblemList: [{ name: "加盟招商" }, { name: "网规选址" }, { name: "拓展开店" }, { name: "自定义需求" }],
            // 静态在营门店
            onStoreList: [
                { name: "暂无门店" },
                { name: "0-10家" },
                { name: "10-50家" },
                { name: "50-100家" },
                { name: "100-500家" },
                { name: "500家以上" }
            ],
            // 静态身份
            identityList: [{ name: "品牌方" }, { name: "加盟商" }, { name: "其他" }],
            postList: [
                { name: "市场调研人员" },
                { name: "网规拓店人员" },
                { name: "咨询顾问" },
                { name: "数据分析师" },
                { name: "产品运营" },
                { name: "老板" },
                { name: "招商人员" },
                { name: "其他" }
            ],
            originPhone: {
                encodePhone: "",
                decodePhone: ""
            }
        };
    },
    components: {
        InformationCard
    },
    async mounted() {
        this.isIOS = window.isIOS;
        await this.getInformation();
        if (!this.submitOpt.phone) {
            await this.getUserInfo();
        }
        await this.getCityInforms();
        // console.log(this.submitOpt);
    },
    watch: {
        "submitOpt.phone"(val, oldVal) {
            if (val && val.indexOf("*") > -1 && oldVal == this.originPhone.encodePhone) {
                this.submitOpt.phone = "";
            }
        },
        "submitOpt.solveProblem": {
            handler(newVal, old) {
                if (newVal && newVal.includes("自定义需求")) {
                    this.isClickDefine = true;
                } else {
                    this.isClickDefine = false;
                    this.solveMsg = "";
                }
            },
            immediate: true
        }
    },
    computed: {
        btnText() {
            if (!this.isIOS) {
                return "提交";
            }
            const keys = Object.keys(this.submitOpt);
            keys.splice(keys.length - 1, 1);
            const res = keys.some(item => {
                return this.submitOpt[item];
            });
            return this.isEdit ? "修改" : res ? "提交" : "填写信息，了解详情";
        }
    },
    methods: {
        // 编写输入框
        // 确定城市
        confirmRegion(type, index) {
            let regions = [];
            if (type[1] !== "不限") {
                regions = type;
            } else {
                regions = [type[0]];
            }
            if (regions.length > 1) {
                this.submitOpt.openShopCity = regions.join("-");
            } else {
                this.submitOpt.openShopCity = regions[0];
            }
            this.showRegionPicker = false;
        },
        // 封装请求方法 省市区
        getCityInforms() {
            const whiteList = [120000, 500000, 110000, 310000];
            this.HTTP.getCityZone("v2open").then(res => {
                res.data.forEach(el => {
                    if (whiteList.indexOf(el.code) > -1) {
                        el.children = [];
                    } else {
                        el.children.forEach(e => {
                            e.children = null;
                        });
                    }

                    el.children.unshift({
                        name: "不限",
                        code: null
                    });
                });
                res.data.unshift({
                    name: "全国",
                    code: null,
                    children: [
                        {
                            name: "不限",
                            code: null
                        }
                    ]
                });
                // console.log(res.data);
                this.columns = res.data;
                // console.log(this.columns)
            });
        },
        imageLoadDone() {
            this.picNum += 1;
            console.log(this.picNum);
            this.firstLoading = this.picNum >= 2;
        },
        getEncodePhone(str) {
            if (str && str.length == 11) {
                return str.slice(0, 3) + "****" + str.slice(-4);
            }
            return;
        },
        getUserInfo() {
            return this.HTTP.getUserInfo().then(res => {
                // console.log(res);
                this.originPhone.encodePhone = this.getEncodePhone(res.decodePhone);
                this.originPhone.decodePhone = res.decodePhone;
                this.submitOpt.phone = this.originPhone.encodePhone;
            });
        },
        getInformation() {
            return this.HTTP.getInformation(this.type).then(res => {
                if (res.data) {
                    console.log(res.data);
                    this.isEdit = true;
                    let filterdata = [];
                    if (res.data.solveProblem) {
                        res.data.solveProblem = JSON.parse(res.data.solveProblem);
                        filterdata = res.data.solveProblem.filter(item => {
                            return item !== "加盟招商" && item !== "网规选址" && item !== "拓展开店";
                        });
                    }
                    this.submitOpt = res.data;
                    if (filterdata && filterdata.length) {
                        this.solveMsg = filterdata[0];
                        this.submitOpt.solveProblem.push("自定义需求");
                    }
                    console.log(this.submitOpt.solveProblem);
                    this.originPhone.encodePhone = this.getEncodePhone(this.submitOpt.phone);
                    this.originPhone.decodePhone = this.submitOpt.phone;
                    this.submitOpt.phone = this.originPhone.encodePhone;
                    console.log(this.submitOpt.phone);
                }
            });
        },
        sumbitHandle() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, "", 165000, 165001);
            this.scrollToBottom();
            this.verificationFunc();
            const canSubmit = Object.values(this.errorInfo).every(item => !item);
            if (canSubmit) {
                let solveProblem = [];
                this.solveProblemList.slice(0, this.solveProblemList.length - 1).forEach(el => {
                    if (this.submitOpt.solveProblem.includes(el.name)) {
                        solveProblem.push(el.name);
                    }
                });
                if (this.solveMsg && this.submitOpt.solveProblem.includes("自定义需求")) {
                    solveProblem.push(this.solveMsg);
                }
                let params = {
                    type: this.type,
                    ...this.submitOpt,
                    solveProblem: JSON.stringify(solveProblem)
                };
                console.log(params.phone, this.originPhone.encodePhone);
                if (params.phone == this.originPhone.encodePhone) {
                    params.phone = this.originPhone.decodePhone;
                }
                if (params.phone.length !== 11 || !isPhoneNo(params.phone)) {
                    this.errorInfo.phoneErr = "请填写正确的手机号";
                    return;
                }
                // let params = {
                //     brandName: "test",
                //     phone: "13324756263",
                //     username: "test"
                // };
                this.HTTP.perfectInformation(params)
                    .then(res => {
                        if (res.code == 0) {
                            this.sussessPopup = true;
                        }
                    })
                    .catch(() => {
                        // this.$toast.fail("网络错误，请稍后再试") // 后端要求直接跳转 不用处理异常情况
                    });
                // 跳转·1
                // this.confirmfHandle();
            }
        },
        scrollToBottom() {
            this.$refs.inputWrapper.scrollIntoView();
        },
        verificationFunc() {
            this.errorInfo.industryErr = this.submitOpt.industryName ? "" : "请选择你的行业";
            this.errorInfo.positionErr = this.submitOpt.position ? "" : "请选择你的职位";
            this.errorInfo.brandErr = this.submitOpt.brandName ? "" : "请选择你的品牌名称";
            // 新增在营门店错误信息
            this.errorInfo.openShopErr = this.submitOpt.openShopCount ? "" : "请选择您的在营门店";
            // 新增所在城市校验
            // console.log(this.submitOpt.openShopCity);
            // console.log(this.errorInfo.openCityErr);
            this.errorInfo.identityErr = this.submitOpt.identity ? "" : "请选择您的身份";
            this.errorInfo.openCityErr = this.submitOpt.openShopCity ? "" : "请选择您所在的城市";
            this.errorInfo.usernameErr = this.submitOpt.username ? "" : "请填写您的姓名";

            if (this.submitOpt.phone != this.originPhone.encodePhone) {
                this.errorInfo.phoneErr = isPhoneNo(this.submitOpt.phone) ? "" : "请填写正确的手机号";
            }
            // if (this.submitOpt.phone.length !== 11) {
            //   this.errorInfo.phoneErr = isPhoneNo(this.submitOpt.phone) ? '' : '请填写正确的手机号'
            // }
        },
        confirmfHandle() {
            this.sussessPopup = false;
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "CHANGE_MAIN_TAB",
                id: "home",
                params: null,
                trackData: null,
                isClear: true
            });
            this.swRouter("/h5/#/member?source=saas");
        },
        changeInput(value, type, err) {
            if (type === undefined) {
                this.showRegionPicker = true;
            }
            this.submitOpt[type] = value;
        },
        selectHandle(value, type, err) {
            console.log(value);
            this.submitOpt[type] = value;
        },
        // 多选打印测试
        multipleSelect(list, name, errName) {
            console.log(list);
            // console.log(JSON.stringify(list));
            this.submitOpt[name] = list;
        }
    }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/px2vw.scss";
//@import "@/styles/index.scss";
// 增加样式
::v-deep.slove_cstyle {
    .van-cell.van-field {
        background-color: #f7f8fb;
        border-radius: 5px;
    }
    .van-cell__value.van-cell__value--alone.van-field__value {
        display: flex;
        justify-content: space-between;
        color: #1d2233;
    }
}
.wrapper {
    width: 100%;
    height: 100vh;
}
::v-deep .van-image {
    display: block;
    // position: relative;
}
.input-wrapper {
    padding: 3.2vw;
}
.footerBar {
    width: 100%;
    padding: 0 4vw;
    box-sizing: border-box;
}
.success {
    width: 80vw;
    height: 75.467vw;
    border-radius: $radius-s;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
        width: 19.73vw;
        height: 19.73vw;
        margin: 10.667vw 0 5.33vw;
    }
    .desc {
        @include font(inherit, $font_347, normal);
        color: $c-text;
        line-height: 5.2vw;
        margin-bottom: 4vw;
        .title {
            @include font(inherit, $font_427, bold);
            line-height: 5.867vw;
            color: #333333;
            text-align: center;
        }
        span {
            color: $c-main;
        }
    }
    .btn {
        position: absolute;
        bottom: 5.33vw;
        width: 72vw;
    }
}
</style>
