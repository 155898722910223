<template>
  <div class="page-container">
    <StateBar />
    <div class="page-header" :class="{'mini-page-header': isMini}">
      <!--搜索框-->
      <searchHeader
        :canSearch="false"
        :content="keyWord"
        @search="search"
        @goMapPage="goMapPage"
        :isVip="isVip"
        @lockVip="lockVip"
        @goSearchPage="goSearchPage"
      />
<!--      筛选组件-->
      <searchFilterBox
        ref="searchFilterRef"
        :contentHeight="contentHeight"
        :isVip="isVip"
        @setVip="setVip"
        @lockVip="lockVip"
        @submit="submit"
        @resetSearch="resetSearch"
      />
    </div>

    <div ref="content" style="height: 0"></div>

    <AutoView ref="view" :header="true" :footer="false" class="search--view">
      <div class="search--main" ref="main" :class="{'filter-mask': !isVip && firstRequest}">
        <van-skeleton :loading="!firstRequest" :animate="false" :row="3" class="s-skeleton s-radius__xs">
          <van-list class="community-list"
                    finished-text="—— 无更多小区 ——"
                    :finished="enableLoadMore"
                    :immediate-check="false"
                    v-model="loading"
                    @load="loadMoreData"
                    v-if="communityList.length"
          >
            <communityCard v-for="(community,index) in communityList" :key="index" :communityObj="community" />
          </van-list>
          <div class="empty-card" v-else-if="isVip && firstLoading">
            <img class="empty-icon" src="/static/cellScreen/empty2.png" />
            <img class="empty-text-icon" src="/static/cellScreen/no-data-text.png" />
            <!--            <p class="no-data-text">可以换个词重新试试</p>-->
          </div>
        </van-skeleton>
      </div>
      <div class="vip-mask" v-if="!isVip && firstRequest">
        <div class="vip-lock-box" @click="clickViPBox">
          <img class="vip-lock" src="/static/cellScreen/vip-lock.png" alt="" />
          <div class="vip-lock-box-middle">
            <div class="lock-text1">开通VIP，解锁限制</div>
            <div class="lock-text2">海量小区无限查</div>
          </div>
          <div class="vip-lock-btn">开通VIP</div>
          <img class="vip-icon" src="/static/cellScreen/vip.png" alt=""  />
        </div>
      </div>
      <!--    收藏按钮-->
      <div class="collection-btn" v-if="firstRequest && requestCount >= 1" :class="{'filter-mask': !isVip}" @click="goCollection">
        <img class="store-icon" src="/static/cellScreen/store-icon.png" alt="" />
        <div>收藏</div>
      </div>
    </AutoView>
    <!-- 开通会员弹框 -->
    <OpenVip :showDialog="showDialog" @cancel="cancelOpenVip" @confirm="confirmOpenVip" />
    <div class="overlay-wrap" v-if="!firstLoading || (firstLoading && this.loading && this.current === 1)">
      <div class="overlay-box">
        <div class="outer-box"><div class="inner-box"></div></div>
        查找中
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '../../utils';

const communityCard = () => import('./components/communityCard')
const searchHeader = () => import('./components/searchHeader')
const searchFilterBox = () => import('./components/searchFilterBox')
import OpenVip from "@/components/openVip/index.vue";
import { EventBus } from './eventBus'
export default {
  name: 'index',
  components: {
    communityCard,
    searchHeader,
    searchFilterBox,
    OpenVip
  },
  data() {
    return {
      contentHeight: 0,
      isVip: false, // 是不是会员
      showDialog: false,
      form: null,
      communityList: [],
      current: 1,
      size: 10,
      keyWord: '',
      enableLoadMore: false,
      loading: true,
      firstRequest: false, // 第一次请求
      firstLoading: false, // 第一次加载数据
      requestCount: 0,// 请求次数
      isIos: false,
      isMini: false
    }
  },
  async mounted () {
    // 避免键盘弹出时，偏好页不能下滑的问题
    // window.onresize = () => {
    //   this.contentHeight = this.$refs.main.offsetHeight
    // }

    this.isIos = window.isIOS
    this.isMini = window.isMini

    this.keyWord = this.$route.query.keyword || ''

    setTimeout(() => {
      this.contentHeight = this.$refs.main.offsetHeight
    }, 500)
    this.JSBridge.registerHandler('onAppResume', (data) => {
      const communitySearch = localStorage.getItem('communitySearch')
      if (communitySearch) {
        this.keyWord = communitySearch
        this.$refs.searchFilterRef.resetForm()
        localStorage.setItem('communitySearch','')
      }
    })
    // EventBus.$on('communitySearch', (data) => {
    //   console.log('communitySearch', data)
    //   this.keyWord = data.name
    //   this.$refs.searchFilterRef.resetForm()
    // })
  },
  methods: {
    clickViPBox() {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3060200, 3060201)
      this.swRouter("/h5/#/member");
    },
    cancelOpenVip() {
      this.showDialog = false
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3060100, 3060101)
    },
    confirmOpenVip() {
      this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3060100, 3060102)
    },
    goSearchPage() {
      const cityCode = this.$refs.searchFilterRef.cityObj2.cityCode || ''
      const cityName = this.$refs.searchFilterRef.cityObj2.cityName
      this.swRouter(`/cellScreen/Search?cityCode=${cityCode}&cityName=${cityName}&source=list`)
      // this.$router.push(`/cellScreen/Search?cityCode=${cityCode}&cityName=${cityName}&source=list`)
    },
    setVip(val) {
      this.isVip = val
      this.firstRequest = true
    },
    openVip() {
      this.swRouter( '/h5/#/member' );
    },
    resetSearch(param) {
      this.form = { ...param }
      this.current = 1
      this.queryData()
    },
    submit(param, type) {
      this.form = { ...param }
      this.current = 1
      if (!type) {
        this.keyWord = ''
      }
      this.queryData()
    },
    lockVip() {
      if (!this.firstRequest) return
      this.showDialog = true
    },
    goCollection() {
      this.swRouter('/cellScreen/collection')
    },
    // 搜索
    search(val) {
      this.keyWord = val
      this.current = 1
      this.queryData()
    },
    loadMoreData() {
      this.current++
      const requestFunc = throttle(this.queryData, 500)
      requestFunc()
    },
   async queryData() {

     const vipRes = await this.HTTP.checkBenefit().catch((e) => {
       console.error('e', e)
       this.enableLoadMore = true
       this.firstLoading = true
       this.firstRequest = false
       this.$toast("网络不稳定，请稍后重试！");
     })
     if (vipRes === undefined) {
       return
     }
     this.isVip = vipRes

     if (!this.isVip) {
       this.enableLoadMore = true
       this.firstLoading = true
       this.firstRequest = true
       this.lockVip()
       return
     }

      if (!this.form) return
      // console.log(this.form)
      if (this.form.price === 'price' && this.form.houseSpecial !== 'house_special') {
        this.form.price = this.form.houseSpecial
      }
      const param = {
        ...this.form,
        current: this.current,
        size: this.size,
        keyWord: this.keyWord
      }
      this.loading = true
      this.HTTP.queryScreenPage(param).then(res => {
        console.log(res)
        this.requestCount++
        if (res.code == 0) {
          if (res.data) {
            let { total, data } = res.data
            data = data || []
            if(data.length < this.size) {
              this.enableLoadMore = true
            } else {
              this.enableLoadMore = false
            }
            let communityList = []
            if (data.length) {
              communityList = data.map(el => {
                return {
                  ...el,
                  year: el.buildingYears ? el.buildingYears.substring(0,4) : ''
                }
              })
            }
            if (this.current > 1) {
              this.communityList = this.communityList.concat(communityList)
            } else {
              this.communityList = communityList
              this.$refs.main.scrollTop = 0
            }
          } else {
            this.communityList = []
            this.enableLoadMore = true
          }
        } else {
          if (res.code == 1022) {
            this.isVip = false
          }
        }

        this.loading = false
      }).catch(() => {
        this.$toast("网络不稳定，请稍后重试！");
        this.loading = false
        this.enableLoadMore = true
      }).finally(() => {
        this.firstLoading = true
        this.firstRequest = true
      })
    },
    // 去地图筛选页
    goMapPage() {
      if (!this.isVip) {
        this.lockVip()
        return
      }
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: 'COMMUNITY_SKU_HOME'
      });

      if(this.isIos) {
        this.JSBridge.callHandler("HandlePageBackPressed");
      }
    }
  },
  beforeDestroy () {
    // EventBus.$off('communitySearch')
  }
};
</script>

<style lang="scss" scoped>
::v-deep.van-list {
  .van-list__finished-text {
    font-size: 3.2vw;
    color: #BDBDBD;
  }
}
  .page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    .overlay-wrap {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .overlay-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34.67vw;
        height: 13.333vw;
        background: #101E32;
        border-radius: 2.67vw;
        opacity: 0.8;
        color: #FFFFFF;
        font-size: 4vw;
        .outer-box {
          width: 4.533vw;
          height: 4.533vw;
          border: .8vw solid rgba(255, 255, 255, 0.45);
          border-radius: 50%;
          position: relative;
          margin-right: 2vw;
          //transition: all 0.4s ease-in-out;
          -moz-animation: rotate 1.5s infinite linear;
          -webkit-animation: rotate 1.5s infinite linear;
          animation: rotate 1.5s infinite linear;
          .inner-box {
            width: 1.5vw;
            height: .8vw;
            border-radius: .8vw;
            position: absolute;
            background: #fff;
            top: -1px;
            right: 0;
            transform: rotate(42deg);
          }
        }
        @-moz-keyframes rotate {

          0% {

            -moz-transform: rotate(0deg);

            //transform-origin:50% 50%;
          }

          50% {

            -moz-transform: rotate(180deg);

            //transform-origin:50% 50%;

          }

          100% {

            -moz-transform: rotate(360deg);

            //transform-origin:50% 50%;

          }

        }

        @-webkit-keyframes rotate {

          0% {

            -webkit-transform: rotate(0deg);

            //transform-origin:50% 50%;

          }

          50% {

            -webkit-transform: rotate(180deg);

            //transform-origin:50% 50%;

          }

          100% {

            -webkit-transform: rotate(360deg);

            //transform-origin:50% 50%;

          }

        }

        @keyframes rotate {

          0% {

            transform: rotate(0deg);

            //transform-origin:50% 50%;

          }

          50% {

            -webkit-transform: rotate(180deg);

            //transform-origin:50% 50%;

          }

          100% {

            transform: rotate(360deg);

            //transform-origin:50% 50%;

          }

        }
      }
    }
    .filter-mask {
      filter: blur(2px);
    }
    .vip-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      //background: url("/static/cellScreen/vip-mask.png") no-repeat;
      background: rgba(255,255,255,0.5);
      background-size: cover;
      z-index: 1;
      .vip-lock-box {
        height: 20vw;
        background: #FFFFFF linear-gradient(90deg, rgba(246, 174, 90, 0.1) 0%, rgba(255, 177, 86, 0.3) 100%);
        box-shadow: 0 -1.6vw 2.133vw 0 rgba(167, 167, 167, 0.19);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4vw 5.333vw 4vw 6.4vw;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        .vip-icon {
          position: absolute;
          width: 21.867vw;
          height: 9.333vw;
          left: 50vw;
        }
        .vip-lock-btn {
          width: 21.333vw;
          line-height: 8.8vw;
          background: #333333;
          border-radius: 4.67vw;
          text-align: center;
          font-weight: 500;
          color: #FFFFFF;
          font-size: 3.733vw;
        }
        .vip-lock {
          width: 11.2vw;
          height: 11.2vw;
          margin-right: 2.67vw;
        }
        .vip-lock-box-middle {
          flex: 1;
          margin-right: 2.67vw;
          z-index: 1;
          .lock-text1 {
            font-weight: 500;
            color: #333333;
            font-size: 4vw;
            line-height: 5.6vw;
          }
          .lock-text2 {
            font-weight: 400;
            color: #999999;
            font-size: 3.47vw;
            margin-top: 1.067vw;
          }
        }
      }
    }
    .empty-card {
      background: #fff;
      border-radius: 1.87vw;
      height: 106.67vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      .empty-icon {
        width: 23.2vw;
        height: 23.2vw;
        margin-top: 32.67vw;
      }
      .empty-text-icon {
        width: 19.733vw;
        height: 4vw;
        margin-top: 4vw;
      }
      .no-data-text {
        font-weight: 400;
        color: #8E9099;
        font-size: 3.47vw;
        margin-top: 2.4vw;
      }
    }
    .collection-btn {
      position: fixed;
      width: 9.333vw;
      height: 12.533vw;
      background: #FFFFFF;
      box-shadow: 0 0 1.067vw 0 rgba(0, 0, 0, 0.09);
      border-radius: 1.333vw;
      border: .27vw solid rgba(189, 189, 189, 0.35);
      font-weight: 500;
      color: #1D2233;
      font-size: 2.67vw;
      padding: 2.27vw 0;
      box-sizing: border-box;
      text-align: center;
      right: 4vw;
      bottom: 36.67vw;
      .store-icon {
        width: 4.8vw;
        height: 4vw;
        margin-bottom: 0.6vw;
      }
    }
    .page-header {
      background: #fff;
      padding: 10px 0 0 0;
      &.mini-page-header {
        .searchBar {
          padding-left: 10px;
        }
      }
      .searchBar {
        padding-left: 15.5vw;
      }
    }
    .search--view {
      scroll-behavior: smooth;
      position: relative;
      background: #F7F7F7;
      flex: 1;
    }
    .search--main {
      position: relative;
      padding: 4vw;
      height: 100%;
      box-sizing: border-box;
      overflow-y: auto;
      .community-list {
      }
    }
  }
</style>
