<template>
  <ul class="bar-flex">
    <li class="left">
      <van-slider disabled v-model="guide1Val" bar-height="4" button-size="0" inactive-color="rgba(189,189,189,0.38)" active-color="rgba(52, 127, 255, 1)">
      </van-slider>
    </li>
    <li class="mid"></li>
    <li class="right">
      <van-slider disabled v-model="guide2Val" bar-height="4" button-size="0" inactive-color="rgba(189,189,189,0.38)" active-color="rgba(52, 127, 255, 1)">
      </van-slider>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    guide1Val: {
      type: Number,
      default: 0,
    },
    guide2Val: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-flex {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  padding: 0 4vw;
  .left {
    flex: 1;
  }
  .mid {
    flex: 0 0 1.6vw;
  }
  .right {
    flex: 1;
  }
  ::v-deep .van-slider--disabled {
    opacity: 1;
  }
}
</style>
