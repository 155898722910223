<template>
  <div class="page-content">
    <StateBar />
    <TitleHeader title="配套详情" bgcolor="transparent" color="black" :back="true"></TitleHeader>
    <div ref="content" style="height: 0"></div>
    <div class="page-container">
      <van-tabs v-model="active" color="#347FFF" title-active-color="#347FFF" swipeable sticky offset-top="15.5vw" >
        <van-tab v-for="(table,index) in tableData" :title="table.name" :key="index">
          <el-table
            :data="table.data"
            :border="false"
            width="100%"
            height="calc(100vh - 15.5vw - 44px - 60px)"
            :header-cell-style="tableHeaderCellStyle"
            :cell-style="tableCellStyle"
            ref="tableRef"
            class="my-table"
          >
            <el-table-column label="序号" type="index" width="50px"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="配套类型" type="type" width="60px" prop="type"></el-table-column>
            <el-table-column label="距离" type="distance" width="60px" prop="distance"></el-table-column>
            <el-table-column label="步行时间" type="time" width="60px" prop="time"></el-table-column>
          </el-table>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import 'element-ui/lib/theme-chalk/table.css';
import 'element-ui/lib/theme-chalk/table-column.css';
import { Table, TableColumn } from 'element-ui';
import { getLocalStorage, setLocalStorage } from '../../utils';
Vue.use(Table)
Vue.use(TableColumn)
export default {
  name: 'match-detail',
  data() {
    return {
      tableData: [
        { name: '交通', data: [] },
        { name: '教育', data: [] },
        { name: '医疗', data: [] },
        { name: '购物', data: [] },
        { name: '生活', data: [] },
        { name: '娱乐', data: [] },
      ],
      active: 0,
      offsetTop: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.offsetTop = this.$refs.content.getBoundingClientRect().top;
    });
    const tableData = getLocalStorage('tableData')
    if (tableData) {
      const { data, index } = tableData
      console.log('data', data)
      this.tableData = data
      this.active = index
    }
    // setLocalStorage('tableData', '')
  },
  methods: {
    tableHeaderCellStyle() {
      return {
        "background-color": "#F8F8F9",
        color: "#1D2233",
        "font-size": "12px",
        "line-height": "15px",
        "font-weight": "bold",
        "text-align": "center",
        padding: "5px 0",
      };
    },
    tableCellStyle() {
      return {
        "background-color": "#FFFFFF",
        color: "#1D2233",
        "font-size": "12px",
        "line-height": "15px",
        "font-weight": "400",
        "text-align": "center",
        padding: "5px 0",
      };
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-table tr th {
  &:first-child {
    border-left: 1px solid #EBEEF5
  }
  border-right: 1px solid #EBEEF5
}
::v-deep.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  &:first-child {
    border-left: 1px solid #EBEEF5
  }
  border-right: 1px solid #EBEEF5
}
::v-deep .titleHeader {
  border-bottom: .1333vw solid #E7E7E7;
  box-sizing: border-box;
}
.el-table::before {
  height: 0;
}
.my-table {
  //height: calc(100vh - 15.5vw - 44px);
  //max-height: calc(100vh - 15.5vw - 44px - 60px);
}
::v-deep.el-table {
  margin-top: 10px;
  th.el-table__cell>.cell {
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
  }
  .cell {
    padding-left: 0;
    padding-right: 0;
    display: block;
    min-width: 0;
  }
}
::v-deep.el-table--border .el-table__cell:first-child .cell {
  padding-left: 0;
}
 .page-content {
   //padding: 0 4vw;
    overflow: hidden;
   height: 100vh;
   .page-container {
     padding: 0 4vw;
   }
 }
</style>
