<template>
  <div class="main-container">
    <div class="loading" v-show="isLoading">
      数据加载中...
    </div>
    <div class="shadow" v-show="!isLoading && isOptionAbnormal">
      数据为空
    </div>
    <div class="wrap-container" v-show="!isLoading && !isOptionAbnormal">
      <div class="echarts" :id="randomId"></div>
    </div>
  </div>
</template>
<script>
  import echarts from 'echarts'

  export default {
    name: 'baseCharts',
    props: {
      option: {
        default () {
          return {}
        }
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        myEcharts: null,
        isOptionAbnormal: false,
        // elementResizeDetectorMaker: require('element-resize-detector'),
        randomId: 'echarts-dom' + Date.now() + Math.random()
      }
    },
    created () {

    },
    beforeDestroy () {
      this.dispose()
    },
    mounted () {
      this.init()
      this.checkAndSetOption()
    },
    watch: {
      option: {
        handler: function (newVal, oldVal) {
          this.option = newVal
          this.checkAndSetOption()
        },
        deep: true
      }
    },
    methods: {
      init () {
        let $echartsDOM = document.getElementById(this.randomId)
        if (!$echartsDOM) return
        let myEcharts = echarts.init($echartsDOM)
        this.myEcharts = myEcharts
        this.$emit('getElement', this.myEcharts)
        // const erd = this.elementResizeDetectorMaker()
        // erd.listenTo($echartsDOM, ele => {
        //   if (this.myEcharts) {
        //     this.myEcharts.resize()
        //   }
        // })
      },
      isValidOption (option) {
        return this.isObject(option) && !this.isEmptyObject(option) &&
          this.hasSeriesKey(option) &&
          this.isSeriesArray(option) && !this.isSeriesEmpty(option)
      },

      isObject (option) {
        return Object.prototype.isPrototypeOf(option)
      },

      isEmptyObject (option) {
        return Object.keys(option).length === 0
      },

      hasSeriesKey (option) {
        return !!option['series']
      },

      isSeriesArray (option) {
        return Array.isArray(option['series'])
      },

      isSeriesEmpty (option) {
        return option['series'].length === 0
      },
      checkAndSetOption () {
        let option = this.option
        if (this.isValidOption(option)) {
          // console.log(option)
          this.myEcharts.setOption(option, true)
          this.isOptionAbnormal = false
        } else {
          this.isOptionAbnormal = true
        }
      },
      dispose () {
        if (!this.myEcharts) {
          return
        }
        this.myEcharts.dispose()
        this.myEcharts = null
      },
    }
  }
</script>
<style scoped>
  .main-container {
    position: relative;
    height: 100% !important;
  }

  .wrap-container,
  .loading,
  .shadow {
    position: absolute;
  }

  .wrap-container,
  .echarts {
    width: 100%;
    height: 100%;
  }

  .shadow,
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #8590a6;
  }
</style>
