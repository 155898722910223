<template>
  <div class="page">
    <StateBar />
    <TitleHeader title="品牌监测详细数据" bgcolor="transparent" color="black" :back="true"></TitleHeader>
    <AutoView ref="view" id="view" :header="true" :footer="false">
      <!-- 展示列表 -->
      <van-skeleton :loading="firstLoading" title :animate="false" :row="3" class="s-skeleton s-radius__xs">
        <div class="content">
          <div class="map-wrap">
            <h5 class="title">省份分布占比</h5>
            <RankTable :tableData="provinceData" :tableProps="['排名','省份','门店数（家）','占比（%）']" isOverflow more @clickTable="clickTable" />
          </div>
          <div class="map-wrap">
            <h5 class="title">城市层级门店占比</h5>
            <RankTable :tableData="cityLevelData" :tableProps="['排名','省份','门店数（家）','占比（%）']" />
          </div>
          <div class="map-wrap">
            <h5 class="title">门店占比最高的城市前十</h5>
            <RankTable :tableData="cityData" :tableProps="['排名','省份','门店数（家）','占比（%）']" />
          </div>
          <SequenceChart :bgmData="brandDpShopMonthOpenList" />
          <div class="shop-wrap">
            <div class="head-tips">
              全国在营门店 <i class="i">{{ total }}</i> 家
            </div>
            <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="loadData">
              <ShopItem v-for="(item, index) in list" :bgmData="item" :idx="index" :key="item.key" />
            </van-list>
          </div>
        </div>
      </van-skeleton>
    </AutoView>
  </div>
</template>

<script>
import RankTable from "./components/rankTable.vue";
import SequenceChart from "./develop/sequence-chart.vue";
import ShopItem from "./components/shopItem.vue";
export default {
  components: {
    RankTable,
    SequenceChart,
    ShopItem
  },
  data () {
    return {
      firstLoading: false,
      provinceData: [],
      cityData: [],
      cityLevelData: [],
      brandDpShopMonthOpenList: [],

      loading: true,
      finished: false,
      current: 1,
      finishedText: "我是有底线的",
      list: [],
      brandCode: '',
      total: 0,
      brandName: '',
    };
  },
  mounted () {
    const brandInfo = localStorage.getItem('BrandDetailInfo')
    if (brandInfo) {
      const {distribution} = JSON.parse(brandInfo)
      this.provinceData = distribution.provinceDataList;
      this.cityData = distribution.cityDataList;
      this.cityLevelData = distribution.cityLevelDataList;
      this.brandDpShopMonthOpenList = distribution.brandDpShopMonthOpenList;
    }
    this.brandCode = this.$route.query.code
    this.brandName = this.$route.query.name
    this.loadData()
  },
  methods: {
    clickTable(data) {
      this.swRouter({
        path: "/reportView/shopDetail",
        query: {
          code: data.code,
          brandCode: this.brandCode,
          brandName: this.brandName,
        },
      });
    },
    loadData() {
      this.loading = true;
      this.HTTP.allDpPage({
        brandCode: this.brandCode,
        current: this.current,
        size: 10,
      })
        .then((res) => {
          this.firstLoading = false;
          this.loading = false;
          this.current++;
          this.total = res.total;
          if (this.list.length) {
            this.list = this.list.concat(res.data);
          } else {
            this.list = res.data;
          }
          if (res.data.length < res.size || this.list.length >= this.total) {
            this.finished = true; // 完成加载
          }
        })
        .catch((e) => {
          if (e.code == 201022) {
            this.isLock = true;
            this.finishedText = "";
            this.finished = true; // 完成加载
            this.loading = false;
          } else {
            this.$toast("网络不稳定，请稍候重试！");
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  padding: 4vw 3.2vw;
  .map-wrap {
    background: #fff;
    .title {
      height: 10.13vw;
      line-height: 10.13vw;
      font-size: 4vw;
      font-weight: bold;
      color: #333;
    }
  }
  .shop-wrap {
    margin-top: 2.67vw;
    .head-tips {
      height: 10.13vw;
      line-height: 10.13vw;
      font-size: 4vw;
      font-weight: bold;
      color: #1d2233;
      .i {
        color: #347fff;
        font-style: normal;
      }
    }
  }
}
</style>
