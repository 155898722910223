<template>
    <div class="varieties">
        <StateBar></StateBar>
        <TitleHeader title="外卖品类" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="bills--view l-view">
            <!-- :loading="firstLoading" -->
            <van-skeleton :loading="firstLoading"  title :animate="false" :row="3" class="s-skeleton s-radius__s">
                <!-- <EmptyCard v-if="list.length === 0" type="bills"></EmptyCard> -->
                <!-- <template v-else> -->
                    <div v-if="varietiesTable" class="box-table">
                        <van-grid :column-num="4" border>
                            <template v-for="( item, ix ) in varietiesTable">
                                <van-grid-item :key="'c1' + ix" :text="item.categoryName ? item.categoryName: '-'"></van-grid-item>
                                <van-grid-item :key="'c2' + ix" :text="item.shopNumber ? item.shopNumber: '-'"></van-grid-item>
                                <van-grid-item :key="'c3' + ix" :text="item.avgOrderNumber ? item.avgOrderNumber: '-'"></van-grid-item>
                                <van-grid-item :key="'c4' + ix" :text="item.avgPrice ? item.avgPrice: '-'"></van-grid-item>
                            </template>
                        </van-grid>
                    </div>
                <!-- </template> -->
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import { getSessionStorage } from "@/utils/index"
export default {
    name: 'varieties',
    data () {
        return {
            firstLoading: false,
            varietiesTable: undefined,
        };
    },
    methods: {

    },

    created () {
        this.JSBridge.callHandler("getString", "cateSession", (data) => {
            // 将数据转换后发送到处理方法
            if (data) {
                if (typeof data === "string") {
                    this.varietiesTable = JSON.parse(data); // 兼容安卓数据解析
                    this.varietiesTable.unshift({
                        categoryName: "品类",
                        shopNumber: "门店数量",
                        avgOrderNumber: "店均订单数",
                        avgPrice: "客单价"
                    })
                    console.log(this.varietiesTable)
                }
            }
        });
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.varieties {
   width: 100%;
   .box-table{
        width: 100%;
        height: auto;
        ::v-deep .van-grid-item__content{
            padding: 2.93vw 1.33vw;
        }
        ::v-deep [class*=van-hairline]::after{
            border: 0 solid #DEDEE7;
            border-width: 0 0.13vw 0.13vw 0;
        }
        ::v-deep .van-hairline--top::after{
            border-top-width: 0.13vw;
            border-left-width: 0.13vw;
            border-bottom-width: 0;
        }
        ::v-deep .van-grid-item__text{
            color: #1D2233;
            line-height: 4.4vw;
        }
        ::v-deep .van-grid-item:nth-child(1),
        ::v-deep .van-grid-item:nth-child(2),
        ::v-deep .van-grid-item:nth-child(3),
        ::v-deep .van-grid-item:nth-child(4) {
            .van-grid-item__content{
                background: #F8F8F9;
                .van-grid-item__text{
                    font-weight: bold;
                }
            }
        }
   }
}
</style>
