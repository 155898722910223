<template>
  <div class="report-comment">
    <StateBar></StateBar>
    <TitleHeader title="评价" bgcolor="transparent" back></TitleHeader>
    <AutoView :footer="true">
      <div class="report-comment-main">
        <div class="report-info">
          <div class="report-image">
            <van-image class="scroll-img" fit="cover" src="/static/discovery/comment-bg.png" />
          </div>
          <div class="report-other-info">
            <div class="report-name">{{ name }}</div>
            <div class="report-address" v-if="title">{{ title }}</div>
          </div>
        </div>
        <div class="comment-main">
          <div class="comment-item">
            <span class="comment-item-label">{{stdQuestionId=='10130'?'生成效果满意度':'报告满意度'}}</span>
            <van-rate v-model="reportRate" size="4.8vw" gutter="1.6vw" icon="/static/report/star-active.png" void-icon="/static/report/star.png" />
          </div>
          <div class="comment-item">
            <span class="comment-item-label">{{stdQuestionId=='10130'?'与开店需求的贴合度':'顾问服务'}}</span>
            <van-rate v-model="serviceRate" size="4.8vw" gutter="1.6vw" icon="/static/report/star-active.png" void-icon="/static/report/star.png" />
          </div>
          <div class="comment-field">
            <van-field type="textarea" v-model="value" label="" placeholder="其它意见或建议" maxlength="200" show-word-limit />
          </div>
        </div>
      </div>
    </AutoView>

    <SoltFooter>
      <div class="commit-btn-box">
        <van-button type="info" block class="s-radius__s" @click="commit" :disabled="submitting" :loading="submitting">提交评价</van-button>
      </div>
    </SoltFooter>
  </div>
</template>

<script>
  import { Toast } from 'vant';
  import UniUtils, { UniRouteType, UniPages } from '@/utils/uni';
  import JSBridge from '@/utils/jsbridge';

  export default {
    name: 'reportComment',
    data () {
      return {
        reportRate: 5,
        serviceRate: 5,
        value: '',
        id: '',
        title: '',
        name: '',
        submitting: false,
        stdQuestionId:'',
      };
    },
    mounted () {
      this.id = this.$route.params.id || ''
      const { title, name,stdQuestionId } = this.$route.query
      this.title = title || ''
      this.name = name || ''
      this.stdQuestionId=stdQuestionId||''
    },
    methods: {
      commit() {
        if (!this.id) {
          return
        }
        this.submitting = true
        this.HTTP.saveReportComment({
          reportInstanceId: this.id,
          reportScore: this.reportRate,
          serviceScore: this.serviceRate,
          suggest: this.value
        }).then(res => {
          if (res) {
            Toast({
              message: '感谢反馈',
              icon: '/static/report/commit-success.png',
              position: 'middle',
              className: 'report-comment-icon',
              duration: 1000
            });
            setTimeout(() => {
              // 返回我的报告
              if (window.isMini) {
                UniUtils.miniRouter({ url: UniPages.MePage }, UniRouteType.reLaunch)
              } else {
                this.commonApi.pageBack()
              }
            }, 1000)
          }
        }).finally(() => {
          this.submitting = false
        }).catch(() => {
          // this.$toast("网络不稳定，请稍候重试。");
        })
      }
    }
  };
</script>
<style lang="scss">
  .van-toast.report-comment-icon {
    .van-toast__icon {
      font-size: inherit;
      .van-icon__image {
        width: 5.333vw;
        height: 5.333vw;
      }
    }
  }
</style>
<style lang="scss" scoped>
  //@import "@/styles/index.scss";
 .report-comment {
   width: 100%;
   height: 100vh;
   padding-bottom: 15.4vw;
   box-sizing: border-box;
   .commit-btn-box {
     width: 100%;
     height: $footerHeight;
     padding: $headerMargin $padding;
     box-sizing: border-box;
     box-shadow: 0 -1px 0 #ebeaee;
     ::v-deep .van-button {
       height: 100%;
       padding: 0;
       font-size: $font_400;
       line-height: normal;
     }
   }
   .report-comment-main {
     background: #F7F7F7;
     padding: 4vw;
     box-sizing: border-box;
     height: 100%;
     .comment-main {
       background: #ffffff;
       padding: 3.333vw 4vw 4vw 4vw;
       margin-top: 4vw;
       border-radius: 1.333vw;
       .comment-field {
         margin-top: 3.733vw;
         .van-field {
           background: #F7F7F7;
           padding: 2.667vw 4vw;
           height: 34.93vw;
           ::v-deep {
             .van-cell__value {
               display: flex;
               flex-direction: column;
               .van-field__body {
                 flex: 1;
                 textarea {
                   height: 100%;
                   font-size: 3.733vw;
                   ::-webkit-input-placeholder {
                     color: #999999;
                   }
                 }
                 .van-field__word-limit {
                   color: #999999;
                   .van-field__word-num {
                     color: #999999;
                   }
                 }
               }
             }
           }
         }
       }
       .comment-item {
         display: flex;
         align-items: center;
         justify-content: space-between;
         &:nth-child(2) {
           margin-top: 2.667vw;
         }
         .comment-item-label {
           font-weight: 500;
           color: #333333;
           font-size: 3.733vw;
           display: inline-block;
          //  width: 20.5vw;
         }
         ::v-deep .van-icon__image {
           width: 4.8vw;
           height: 4.4vw;
         }
       }
     }
     .report-info {
       height: 22.667vw;
       background: #FFFFFF;
       border-radius: 1.333vw;
       display: flex;
       align-items: center;
       padding: 3.333vw 4vw;
       box-sizing: border-box;
       .report-image {
         width: 16vw;
         height: 16vw;
         margin-right: 2.667vw;
         .scroll-img {
           width: 100%;
           height: 100%;
         }
       }
       .report-other-info {
         flex: 1;
         overflow: hidden;
         .report-name {
           font-size: 4vw;
           font-weight: 500;
           color: #1D2233;
           line-height: 5.6vw;
           white-space: nowrap;
           text-overflow: ellipsis;
           overflow: hidden;
           word-break: break-all;
         }
         .report-address {
           font-weight: 400;
           color: #666666;
           font-size: 3.467vw;
           margin-top: 1.867vw;
           line-height: 4.933vw;
           overflow: hidden;
           display: -webkit-box;//将元素设为盒子伸缩模型显示
           -webkit-box-orient: vertical;//伸缩方向设为垂直方向
           -webkit-line-clamp: 2;//超出3行隐藏，并显示省略号
         }
       }
     }
   }
 }
</style>
