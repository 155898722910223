<template>
    <div class="contain">
        <div class="header">
            <div class="center">
                <img class="logo" src="https://image.szshuwei.com/Fg-CGxd9dhL9Nrv8CMCBPWp2DsNN" alt="">
            </div>
        </div>
        <div class="banner">
          <img class="banner-img" src="/static/sem/pc2/sem_pc_bg2.png" />
          <div class="banner-center banner-center2">
            <img class="img1" src="/static/sem/pc2/sem_pc_bg5.png" />
            <img class="img2" src="/static/sem/pc2/btn1.png" @click="showSumbit" />
          </div>
        </div>
      <div class="banner2">
        <img class="banner-img" src="/static/sem/pc2/sem_pc_bg3.png" />
        <div class="banner-center banner-center2">
          <div class="title">
            <img src="/static/sem/pc2/icon1.png" />
            <span>选位置</span>
            好位置才能有好生意
          </div>
          <div class="banner-container">
            <div class="container-title">选址专区</div>
            <div class="container-intro"><span>没有</span>意向选址，为您推荐合适的开店位置</div>
            <div class="container-des">通过大数据筛选模型在整个行政区中商圈，推荐匹配用户业态条件，区域客群以及交通竞争环境地域条件等需求中评分最高的开店位置</div>
            <div class="container-intro" style="margin-top: 30px"><span>已有</span>意向选址，帮您评估开店位置是否合适</div>
            <div class="container-des">产品从市场评估、销售推动分析、位置点评估、区域竞争、区域客群画像等15个细分维度,100+数据来源,用先进的选止模型,评估分析计划开店位置</div>
            <div class="btn" @click="showSumbit">立即体验</div>
          </div>
        </div>
      </div>

        <div class="banner3">
          <div class="banner-center banner-center2">
            <div class="list">
              <div class="item">
                <div class="value">8000w+</div>
                <div class="key">室内场景信息数据</div>
              </div>
              <div class="item">
                <div class="value">324+</div>
                <div class="key">覆盖城市</div>
              </div>
              <div class="item">
                <div class="value">35000+</div>
                <div class="key">连锁品牌数据</div>
              </div>
              <div class="item">
                <div class="value">6500+</div>
                <div class="key">商场数据</div>
              </div>
              <div class="item">
                <div class="value">100000</div>
                <div class="key">商圈电子围栏数据库</div>
              </div>
              <div class="item">
                <div class="value">60w+</div>
                <div class="key">客群画像数据库</div>
              </div>
              <div class="item">
                <div class="value">30w+</div>
                <div class="key">社区数据库</div>
              </div>
              <div class="item">
                <div class="value">400w+</div>
                <div class="key">外卖门店数据</div>
              </div>
              <div class="item">
                <div class="value">98+</div>
                <div class="key">业态数据库</div>
              </div>
              <div class="item" style="line-height: 68px">
                <span class="spot"></span>
                <span class="spot"></span>
                <span class="spot"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="query-data-container">
          <div class="banner-center">
            <div class="query-data-box">
              <div class="box-header">
                <img class="icon" src="/static/sem/pc2/icon2.png" />
                <span class="c2">查数据</span>
                一张商业地图线下开店实景全知晓
              </div>
              <div class="box-container">
                <img class="left-container-bg" src="/static/sem/pc2/sem_pc_bg6.png" />
                <div class="right-container">
                  <div class="container-title">商业数据地图</div>
                  <div class="container-des">基于上上参谋多年的线下商业数据沉淀，获取多维度线下商业数据，并凭借大数据挖掘与数据建模，提供周边查询、人口热力、行业热力、外卖查询、小区筛查、城市查询、品牌查询、商场查询等查询服务</div>
                  <div class="container-des">通过多源数据整合，全量级商业位置大数据与线下实地开店场景深度融合，实现开店市场洞察，经营决策</div>
                  <div class="btn" @click="showSumbit">
                    免费体验
                  </div>
                </div>
              </div>
            </div>
            <div class="box-header box-header2">
              <img class="icon" src="/static/sem/pc2/icon4.png" />
              <span class="c1">挑品牌</span>
              好项目是赚钱的前提
            </div>
            <img class="bg7" src="/static/sem/pc2/sem_pc_bg7.png" />
            <img class="bg8" src="/static/sem/pc2/sem_pc_bg8.png" />
            <div class="more-btn" @click="showSumbit">查看更多</div>
          </div>
        </div>
        <div class="query-data-container2">
          <div class="banner-center">
            <div class="query-data-box">
              <div class="box-header">
                <img class="icon" src="/static/sem/pc2/icon5.png" />
                <span class="c3">挑商铺</span>
                优质铺位胜过一切
              </div>
              <div class="box-container">
                <img class="left-container-bg" src="/static/sem/pc2/sem_pc_bg9.png" />
                <div class="right-container">
                  <div class="container-title">商铺广场</div>
                  <div class="container-des">海量真实铺位信息，专业铺参谋一对一服务 根据铺位配套设施、求租人租赁需求、周围商圈分布、客流量测算结果等，快速匹配最优选择，助力每一位开店者成功</div>
                  <div class="shop-box">
                    <div class="shop-left-box">
                      <div class="shop-status">空铺</div>
                      <div class="shop-left-title2">地铁站附近</div>
                    </div>
                    <div class="shop-right-box">
                      <div class="shop-right-title1">可餐饮 居民聚集店 临近地铁站 繁华地段 民水…</div>
                      <div class="shop-right-title2">面积：XXXXm² 租金：？？？？元</div>
                    </div>
                  </div>
                  <div class="keywords">
                    <span>帮我找铺</span>
                    <span>帮我转租</span>
                    <span>空铺招租</span>
                    <span>免租转让</span>
                  </div>
                  <div class="btn" @click="showSumbit">免费体验</div>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="query_data">-->
<!--            <div class="center">-->
<!--                <h3>全国线下数据查询系统</h3>-->
<!--                <div class="wrapper">-->
<!--                    <div class="item" v-for="item in queryData"  :key="item.icon" @click="showSumbit">-->
<!--                        <img class="icon" :src="item.icon" alt="">-->
<!--                        <div class="title">{{item.title}}</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="btn" @click="showSumbit">更多数据</div>-->
<!--            </div>-->
<!--            <div class="nav_list" @click="navClick" v-if="isShowNavList">-->
<!--                <div class="hot" :class="`nav_${item}`" v-for="item in 3" :key="`nav_${item}`"></div>-->
<!--                <div class="primary" :class="`nav_${item + 3}`" v-for="item in 7"  :key="`nav_${item + 3}`"></div>-->
<!--                <img class="close" src="static/sem/pc/close.png" alt="" @click="isShowNavList = false">-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="big-img">-->
<!--            <div class="center">-->
<!--                <div class="phone"></div>-->
<!--                <div class="big-circle"></div>-->
<!--                <div class="circle"></div>-->
<!--                <div class="icon-wrapper" :class="`icon-wrapper${index + 1}`" v-for="(item, index) in iconList" :key="item.icon"  @click="showSumbit">-->
<!--                    <img :src="item.icon" alt="" class="icon">-->
<!--                    <div class="name">{{item.name}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="content part1">-->
<!--            <div class="center flex">-->
<!--                <div class="image"></div>-->
<!--                <div class="text">-->
<!--                    <h3 class="title">店铺广场</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <div class="p-content">-->
<!--                        合适的商铺四配合适的店主，提供线上看铺，客流量测算，商铺数据和租赁洽谈服务-->
<!--                        <div class="part_btn_wrapper">-->
<!--                            <div class="part_btn" v-for="item in part1BtnText" :key="item.name" @click="showSumbit">-->
<!--                                <img class="hot_icon" v-if="item.icon" :src="item.icon" alt="">-->
<!--                                {{item.name}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="primary_btn" @click="showSumbit">-->
<!--                            免费领取会员-->
<!--                            <img src="/static/sem/pc/primary_icon.png" alt="" class="primary_icon">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="content part2">-->
<!--            <div class="center flex">-->
<!--                <div class="text">-->
<!--                    <h3 class="title">开店位置推荐</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <div class="p-content">-->
<!--                        通过大数据筛选模型在整个行政区中筛选商圈，推荐四配用户需求的、评分最高的3个开店位置-->
<!--                        <div class="part_btn_wrapper">-->
<!--                            <div class="part_btn" v-for="item in part2BtnText" :key="item" @click="showSumbit">{{item}}</div>-->
<!--                        </div>-->
<!--                        <div class="primary_btn" @click="showSumbit">免费领取会员</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="image"></div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="content part3">-->
<!--            <div class="center flex">-->
<!--                <div class="image"></div>-->
<!--                <div class="text">-->
<!--                    <h3 class="title">开店选址评估</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <div class="p-content">-->
<!--                        产品从市场评估、销售推动分析、位置点评估、区域竞争、区域客群画像5人维度，用先进的选址模型，评估分析计划开店位置-->
<!--                        <div class="part_btn_wrapper">-->
<!--                            <div class="part_btn" v-for="item in part3BtnText" :key="item" @click="showSumbit">{{item}}</div>-->
<!--                        </div>-->
<!--                        <div class="primary_btn" @click="showSumbit">免费领取会员</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="part4">-->
<!--                <h3>商业数据查询服务</h3>-->
<!--                <div class="tab">-->
<!--                    <div class="tab-item" v-for="(item, index) in tabList" :key="item" :class="{actived: index === currentIndex}" @click="showSumbit">{{item}}</div>-->
<!--                </div>-->
<!--				<vear-carousel :slides="slidesList" :currentIndex.sync="currentIndex" @click="showSumbit"></vear-carousel>-->
<!--        </div>-->
        <div class="b-banner">
          <img src="/static/sem/pc2/sem_pc_bg10.png" />
        </div>
        <div class="footer-bottom">
            <div class="center copyright">Copyright @ 深圳市数位汇聚科技有限公司 All Rights Reserved
                <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备18040659号-3</a>
            </div>
        </div>
        <div class="backTop" @mouseenter="onEnter" @mouseleave="onLeave" @click="goBack">
            <img class="icon" :src="icon" alt="">
            <div class="back">返回顶部</div>
        </div>
        <div class="popup screen">
            <!-- <div class="head">
                <div class="init-content">
                    <div class="app"></div>
                    <div class="desc">选址评估更快更专业</div>
                </div>
            </div>
            <div class="from">
                <div class="i-icon">
                    <div class="flex-start first">
                        <img src="/static/sem/fire.png" alt="">
                        一键定位火爆区域
                    </div>
                    <div class="flex-start second">
                        <img src="/static/sem/location.png" alt="">
                        数字化开店选址用户产品
                    </div>
                </div>
                <div class="click-btn" @click="showSumbit">免费试用</div>
            </div>
            <img class="close" src="static/sem/pc/close.png" alt="" @click="closePopup"> -->
            <img class="qr-code-img" src="/static/sem/qr-code-img.png" alt="">
        </div>
        <!-- <div class="free" v-else @click="showSumbit"></div> -->
        <div v-if="isShow" @click="closeMask">
            <div class="mask"></div>
            <div class="popup submit" @click.stop="">
                <div class="head">
                    <div class="init-content">
                        <img class="head-img" src="/static/sem/font_head.png" />
                        <div class="desc">选址评估更快更专业</div>
                    </div>
                </div>
                <div class="from">
                    <div class="form-wrap">
                        <div class="input-wrap">
                            <div class="requred">手机号<span v-if="phoneErr">{{phoneErr}}</span></div>
                            <input maxlength="11" v-model="phone" placeholder="请输入手机号"/>
                        </div>
                        <div class="input-wrap">
                            <div class="requred">验证码<span v-if="vcodeErr">验证码错误或过期</span></div>
                            <input class="vcode-input" placeholder="请输入验证码" v-model="vcode" maxlength="10"/>
                            <div class="vcode" :class="{disable : time !== 60}">
                                <span v-if="time === 60" @click="sendCode">{{vcodeText}}</span>
                                <span v-else>{{time}}s</span>
                            </div>
                        </div>
                    </div>
                    <div class="click-btn" @click="submitClick">立即领取</div>
                </div>
                <img class="close" src="static/sem/close.png" alt="" @click="closeMask">
            </div>
        </div>
        <div v-if="isSuccess" @click="closeMask">
            <div class="mask"></div>
            <div class="popup submit" @click.stop="">
                <div class="head">
                    <div class="init-content">
                        <img class="head-img" src="/static/sem/font_head.png" />
                        <div class="desc">选址评估更快更专业</div>
                    </div>
                </div>
                <div class="from">
                    <div class="form-wrap">
                        <div class="qrcode-wrapper">
                            <template v-if="isReceived == 1">
                                <div>该手机号领取过啦</div>
                            </template>
                            <template v-else>
                                <div>领取成功</div>
                            </template>
                            <div style="margin: 4px 0 22px 0">扫码下载体验吧</div>
                            <img class="qrcode" :src="qrcode" alt="">
                        </div>
                    </div>
                </div>
                <img class="close" src="static/sem/close.png" alt="" @click="closeMask">
            </div>
        </div>
        <div v-if="isError" @click="closeMask">
            <div class="mask"></div>
            <div class="popup submit" @click.stop="">
                <div class="head">
                    <div class="init-content">
                        <img class="head-img" src="/static/sem/font_head.png" />
                        <div class="desc">选址评估更快更专业</div>
                    </div>
                </div>
                <div class="from">
                    <div class="from-error-wrap">
                        <div class="header-box">
                          <img class="sorry-icon" src="static/sem/sorry-icon.png" alt="">
                          <div class="error-tip">
                            <p class="error-size1">抱歉！您已注册过上上参谋备份</p>
                            <p class="error-size2">无法领取该权益</p>
                          </div>
                        </div>
                        <img class="wx-qrCode-img" src="static/sem/wx-qrCode-img.png" alt="">
                        <p class="add-tip">
                          <img class="add-icon" src="static/sem/add-icon.png" alt="">
                          <span class="add-text">可扫码添加上上参谋企业微信</span>
                        </p>
                        <p class="discounts-text">领取优惠福利</p>
                    </div>
                </div>
                <img class="close" src="static/sem/close.png" alt="" @click="closeMask">
            </div>
        </div>
    </div>
</template>

<script>
import md5 from 'md5';
import { isPhoneNo } from '@/utils/index.js'
import vearCarousel from "./components/vear-carousel"
import { commonConfigObj } from '@/constant'
export default {
    data() {
        return {
            isShow: false,
            sendLoading:false,
            isSuccess: false,
            isError:false,
            isShowNavList: true,
            timer: null,
            sourceChannelType: null,
            currentIndex: 0,
            time: 60,
            vcodeText: '发送验证码',
            phone: '',
            qrcode: '',
            vcode: '',
            phoneErr: '',
            keyWordId: '',
            vcodeErr: false,
            isReceived: 0,
            icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAaVBMVEUAAACIk6eIlaeIk6eIk6eLlaqIlKiJlqmJk6eIk6eHlKiHkaWDlKmIk6eLlqeIlaWChqiHiaqIk6eJk6iJk6eIk6eIkqeIlKeIlKiHlKeIk6aIk6eIk6eIk6eGj6aIk6eJlKiIkqeIk6fAnDj8AAAAInRSTlMA+13PwTZjPX6kTxkL1i0iBg723KmgS8WWkYdo7LcT47VzMakgcgAAAedJREFUWMPtV+uSgiAYLRWE8G6laZeN93/IlU93S2QCYsZtdjh/mhE4fZfDATYeHh4e/xZJjLuW0rbDceLOlpNmy3+xbUjuRHeM+ALR8W26OORKhPFbdOWBT0gp6YOgJzTlEw6lPR+qpwwxe3xkeKpBjWz5dhUs3GfyQLaHgWpnxxdAa0OkjD2EhgdW8QHfqVCPFidgtIgRVRCCJoEKGfe3hgC0KdSmvQa9BJoig3oM9Qz1082COpopPBT9LXSzCphmtH/5AGTQOTHPZF9HQs8bAwiFRwZ+xQdkJoSZmKl3M6L+X4ZyZS5ES9gMs/AimEjlWnj42Gj9XiiWyfU/c9X2ZeJbYiDCVI5P8AGj1NRUL0VIg0ptAj5ANV9OoTyv0S0Knd+A6zIyIrmBnYawHeb0s0KNzt1uevg9Pyuqh4HXoJIvXFP+YwN3mREcgtoRliGw7KdyCNxyidAm5SQa61c8OQyvmZSyRVO+gCFKZk7J00RqirFsjrC+KR+eNR55d0k2xsIGWw6vzy4IMRNJ2MZbr0wHdjb31cvQaCZtPXNzSHCwWLDDV8kcLOxLb8VkXYN1PwLcD6lVj1H3g979KrLqZcn9Oud+4VzzSux+aXd/Vvz9w+fl0+xTHo/q5+2nPcA9PDw8PhTf+31YCdEcAX4AAAAASUVORK5CYII=',
            queryData: [
                { icon: '/static/sem/pc/query-data-icon_1.png', title: '开店选址评估' },
                { icon: '/static/sem/pc/query-data-icon_2.png', title: '开店位置推荐' },
                { icon: '/static/sem/pc/query-data-icon_3.png', title: '周边业态查询' },
                { icon: '/static/sem/pc/query-data-icon_4.png', title: '周边人口热力' },
                { icon: '/static/sem/pc/query-data-icon_5.png', title: '商场数据查询' },
                { icon: '/static/sem/pc/query-data-icon_6.png', title: '品牌门店查询' },
                { icon: '/static/sem/pc/query-data-icon_7.png', title: '品牌经营数据' },
                { icon: '/static/sem/pc/query-data-icon_8.png', title: '外卖数据查询' },
            ],
            part1BtnText: [
                { name: '帮我找铺', icon: '/static/sem/pc/hot_icon.png' },
                { name: '帮我转租', icon: '/static/sem/pc/hot_icon.png' },
                { name: '空置招租', icon: '' },
                { name: '免租转让', icon: '' },
            ],
            tabList: ['查城市', '查周边', '查商场'],
            part2BtnText: ['业态条件', '区域客群', '竞争关系', '交通条件', '地域条件'],
            part3BtnText: ['15个细分维度', '100+数据库来源'],
            startIndex: 0,
            slidesList: [
                'https://image.szshuwei.com/FkJ20Gwho6-CiTxX4a8KEwC1p9bg',
                'https://image.szshuwei.com/FpoA-iLnlIrwHDYTXubkp9UTIU-d',
                'https://image.szshuwei.com/FkauTQT2yGUk-tFwQNBs26GGU451'
            ],
            iconList: [
                { icon: '/static/sem/pc/icon_1@2x.png', name: '人流动线分析' },
                { icon: '/static/sem/pc/icon_2@2x.png', name: '区域竞争分析' },
                { icon: '/static/sem/pc/icon_3@2x.png', name: '区域店铺租金' },
                { icon: '/static/sem/pc/icon_4@2x.png', name: '企业定制数据' },
                { icon: '/static/sem/pc/icon_5@2x.png', name: '聚客点分析' },
                { icon: '/static/sem/pc/icon_6@2x.png', name: '区域交通便利性' },
                { icon: '/static/sem/pc/icon_7@2x.png', name: '区域商业分布' },
                { icon: '/static/sem/pc/icon_8@2x.png', name: '区域人口画像' },
            ],
            submitting: false
        }
    },
    components: {
        vearCarousel
    },
    mounted() {
        this.keyWordId = this.$route.query.promote
        this.sourceChannelType = this.$route.query.type
        this.timer = setTimeout(() => {
            this.isShow = true
        }, 5000)
    },
    beforeDestroy() {
        clearTimeout(this.timer)
    },
    methods: {
        onEnter() {
            this.$nextTick(() => {
                this.icon = 'https://www.sscanmou.com/_nuxt/img/top-blue.aed067b.png'
            })
        },
        onLeave() {
            this.$nextTick(() => {
                this.icon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAaVBMVEUAAACIk6eIlaeIk6eIk6eLlaqIlKiJlqmJk6eIk6eHlKiHkaWDlKmIk6eLlqeIlaWChqiHiaqIk6eJk6iJk6eIk6eIkqeIlKeIlKiHlKeIk6aIk6eIk6eIk6eGj6aIk6eJlKiIkqeIk6fAnDj8AAAAInRSTlMA+13PwTZjPX6kTxkL1i0iBg723KmgS8WWkYdo7LcT47VzMakgcgAAAedJREFUWMPtV+uSgiAYLRWE8G6laZeN93/IlU93S2QCYsZtdjh/mhE4fZfDATYeHh4e/xZJjLuW0rbDceLOlpNmy3+xbUjuRHeM+ALR8W26OORKhPFbdOWBT0gp6YOgJzTlEw6lPR+qpwwxe3xkeKpBjWz5dhUs3GfyQLaHgWpnxxdAa0OkjD2EhgdW8QHfqVCPFidgtIgRVRCCJoEKGfe3hgC0KdSmvQa9BJoig3oM9Qz1082COpopPBT9LXSzCphmtH/5AGTQOTHPZF9HQs8bAwiFRwZ+xQdkJoSZmKl3M6L+X4ZyZS5ES9gMs/AimEjlWnj42Gj9XiiWyfU/c9X2ZeJbYiDCVI5P8AGj1NRUL0VIg0ptAj5ANV9OoTyv0S0Knd+A6zIyIrmBnYawHeb0s0KNzt1uevg9Pyuqh4HXoJIvXFP+YwN3mREcgtoRliGw7KdyCNxyidAm5SQa61c8OQyvmZSyRVO+gCFKZk7J00RqirFsjrC+KR+eNR55d0k2xsIGWw6vzy4IMRNJ2MZbr0wHdjb31cvQaCZtPXNzSHCwWLDDV8kcLOxLb8VkXYN1PwLcD6lVj1H3g979KrLqZcn9Oud+4VzzSux+aXd/Vvz9w+fl0+xTHo/q5+2nPcA9PDw8PhTf+31YCdEcAX4AAAAASUVORK5CYII='
            })
        },
        showSumbit() {
            this.isShow = true
        },
        navClick(e) {
            let isHot = e.target.classList.value.indexOf('hot') > -1
            let isPrimary = e.target.classList.value.indexOf('primary') > -1
            if(isHot || isPrimary) {
                this.isShow = true
            }
        },
        closeMask() {
            this.isShow = false
            this.isSuccess = false
            this.isError=false
        },
        // closePopup() {
        //     this.isShowPupop = false
        // },
        goBack() {
            let top = document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 200
                if (top <= 0) {
                    clearInterval(timeTop)
                }
            }, 10)
        },
        sendCode() {
            let timer = null;
            const fun = () => {
                if (this.time > 0) {
                    this.time -= 1;
                    timer = setTimeout(() => {
                        fun();
                    }, 1000);
                } else {
                    this.time = 60;
                    this.vcodeText = '重新发送'
                    timer && clearTimeout(timer);
                }
            }
            this.phoneErr = this.phone ? isPhoneNo(this.phone) ? '' : '手机号错误' : '请填写手机号'
            if(this.phoneErr) return
            if(this.sendLoading) return;
            this.sendLoading=true;
            this.sendMsg().then(() => {
                this.$toast({
                    message: '发送成功',
                    position: 'middle',
                });
                fun();
            }).catch((e) => {
                if (e.msg) {
                    this.$toast({
                        message: e.msg,
                        position: 'middle',
                    });
                }
            }).finally(()=>{
                this.sendLoading=false;
            });
        },
        sendMsg () {
            let data = {
                phone: this.phone,
            };
            let sign = md5(JSON.stringify(data) + '51cbb163-2214-48f0-8d4a-c0ed217f40a7');
            data.sign = sign;
            return this.HTTP.sendLoginSmsV2Bd(data);
        },
        changeStartIndex() {
            this.startIndex = 1
            console.log(this.startIndex)
        },
        async submitClick() {
            if (this.submitting) return
            this.phoneErr = this.phone ? isPhoneNo(this.phone) ? '' : '手机号错误' : '请填写手机号'
            this.vcodeErr = this.vcode.length !== 6
            if(this.phone && this.vcode && !this.phoneErr && !this.vcodeErr) {
                this.submitting = true
                this.HTTP.semLogin({
                    adSemRecordReq: {
                      phone: this.phone,
                      keywordId: this.keyWordId,
                      loginUrl: location.href,
                      sourceType: 1,
                      sourceChannelType : this.sourceChannelType,
                      authCode: this.vcode
                    },
                    userLoginReq: {
                      "channelCode": "sem_pc_normal",
                      "childChannel": "",
                      "childVersion": "",
                      "code": this.vcode,
                      "deviceId": "",
                      "deviceType": 4,
                      "inviteCode": "",
                      "phone": this.phone,
                      "platform": "sscm",
                      "versionCode": 100,
                      "versionName": commonConfigObj.version_name
                    }
                }).then(res => {
                    this.HTTP.getQRCode({
                        launchChannel: 'sem'
                    }).then(qrcode => {
                        this.qrcode = 'data:image/png;base64,' + qrcode.data.imageString
                        this.isReceived = res.data ? 0 : 1
                        this.isSuccess = true
                        this.phone = ''
                        this.vcode = ''
                        if(this.sourceChannelType == 4) {
                            // 神马的联调代码
                            utq('track', 'CompleteRegistration', '11231833');
                        }
                      this.submitting = false
                    }).catch(() => {
                      this.submitting = false
                    })
                }).catch(err => {
                    this.submitting = false
                    if(err.code == 1044){
                      this.isError=true
                    }else if(err.code == 189002) {
                        this.vcodeErr = err.msg
                    } else {
                      this.$toast.fail(err.msg || '系统错误')
                    }
                })
            } else {
              this.submitting = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.c1 {
  color: #347FFF;
}
.c2 {
  color: #FF821C;
}
.c3 {
  color: #298DF7;
}
.query-data-container {
  background: #F1F3FC;
  padding-bottom: 50px;
  .query-data-box {
    margin-top: -94px;
  }
  .more-btn {
    width: 232px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #347FFF;
    text-align: center;
    line-height: 50px;
    font-weight: 500;
    color: #347FFF;
    font-size: 16px;
    margin: 40px auto 0 auto;
    cursor: pointer;
  }
  .bg7 {
    margin-top: 40px;
    width: 100%;
  }
  .bg8 {
    margin-top: 60px;
    width: 100%;
  }
}
.query-data-container2 {
  background: #ffffff;
}
.box-header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #131638;
  font-size: 32px;
  font-weight: bold;
  .icon {
    width: 34px;
    height: 34px;
  }
  span {
    margin: 0 14px;
  }
  &.box-header2 {
    margin-top: 60px;
  }
}
.query-data-box {
  background: #FFFFFF;
  border-radius: 10px;
  padding-top: 40px;
  .box-container {
    height: 630px;
    display: flex;
    margin-top: 20px;
    .left-container-bg {
      width: 760px;
      height: 100%;
    }
    .right-container {
      flex: 1;
      .shop-box {
        width: 525px;
        background: linear-gradient(90deg, #EEF3FC 0%, #F7F7FA 100%);
        border-radius: 4px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        padding: 20px 0;
        .shop-left-box {
          padding: 0 18px 0 30px;
          border-right: 1px solid rgba(136,147,167,0.2);
          font-weight: 400;
          color: #6A6D82;
          font-size: 14px;
          margin-right: 18px;
          .shop-status {
            font-weight: 600;
            color: #131638;
            line-height: 33px;
            font-size: 24px;
          }
          .shop-left-title2 {
           line-height: 20px;
          }
        }
        .shop-right-box {
          font-weight: 400;
          color: #6A6D82;
          font-size: 16px;
          .shop-right-title1 {
            color: #5171AA;
            line-height: 22px;
          }
          .shop-right-title2 {
            line-height: 22px;
            margin-top: 9px;
          }
        }
      }
      .keywords {
        display: flex;
        margin-top: 10px;
        span {
          width: 122px;
          height: 42px;
          border: 1px solid rgba(81,113,170,0.4);
          font-weight: 500;
          color: #5171AA;
          font-size: 16px;
          text-align: center;
          line-height: 42px;
          margin-right: 10px;
        }
      }
      .container-title {
        font-weight: 600;
        color: #131638;
        line-height: 40px;
        font-size: 28px;
        margin-top: 100px;
      }
      .container-des {
        font-weight: 400;
        color: #6A6D82;
        line-height: 22px;
        font-size: 16px;
        margin-top: 20px;
      }
      .btn {
        width: 224px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background: #347FFF;
        border-radius: 4px;
        position: relative;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 16px;
        margin-top: 100px;
        cursor: pointer;
        .icon3 {
          width: 92px;
          position: absolute;
          right: -4px;
          top: -10px;
        }
      }
    }
  }
}
.header {
    height: 60px;
    display: flex;
    align-items: center;
}
.center {
    width: 1280px;
    margin: 0 auto;
}
.flex {
    display: flex;
    justify-content: space-between
}
.logo {
    height: 30px;
}
.banner-center {
  height: 100%;
  width: 1280px;
  margin: 0 auto;
  position: relative;
  color: #fff;
  &.banner-center2 {
    padding: 0 60px;
    box-sizing: border-box;
  }
}
.banner3 {
  position: relative;
  height: 450px;
  background: url("/static/sem/pc2/sem_pc_bg4.png") no-repeat center center;
  background-size: cover;
  .banner-center {
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 20%;
        margin-top: 50px;
        .spot {
          width: 6px;
          height: 6px;
          background-color: #ffffff;
          margin-right: 5px;
          display: inline-block;
        }
        .value {
          font-size: 34px;
          font-weight: bold;
          line-height: 48px;
        }
        .key {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}
.banner2 {
  position: relative;
  //height: 610px;
  //background: url("/static/sem/pc2/sem_pc_bg3.png") no-repeat center center;
  //background-size: cover;
  //min-width: 1280px;
  //max-width: 1920px;
  //margin: 0 auto;
  .banner-img {
    width: 100%;
  }
  .banner-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
  }
  .banner-container {
    width: 380px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .btn {
      width: 224px;
      height: 50px;
      background: #347FFF;
      border-radius: 4px;
      line-height: 50px;
      text-align: center;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 16px;
      margin-top: 30px;
      cursor: pointer;
    }
    .container-des {
      font-weight: 300;
      color: #6A6D82;
      font-size: 14px;
      margin-top: 14px;
      line-height: 20px;
    }
    .container-title {
      font-weight: 600;
      color: #131638;
      line-height: 40px;
      font-size: 28px;
    }
    .container-intro {
      font-weight: bold;
      color: #131638;
      font-size: 18px;
      margin-top: 20px;
      span {
        color: #347FFF;
      }
    }
  }
  .title {
    text-align: center;
    display: flex;
    font-size: 32px;
    color: #131638;
    font-weight: 600;
    justify-content: center;
    padding-top: 4%;
    img {
      width: 34px;
      height: 34px;
    }
    span {
      color: #F35650;
      margin: 0 14px;
    }
  }
}
.banner {
    position: relative;
    //height: 568px;
    //background: url("/static/sem/pc2/sem_pc_bg2.png") no-repeat center center;
    //background-size: cover;
    min-width: 1280px;
    .banner-img {
      width: 100%;
    }
    .banner-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      .img1 {
        width: 460px;
        height: auto;
        display: block;
      }
      .img2 {
        width: 182px;
        height: auto;
        margin-top: 60px;
        display: block;
        cursor: pointer;
      }
        //.iamge {
        //    width: 517px;
        //    height: 448px;
        //    position: absolute;
        //    right: 0;
        //    top: 11px;
        //}
        //.banner-content {
        //    position: absolute;
        //    left: 0;
        //    margin-top: 60px;
        //    font-size: 58px;
        //    h3 {
        //        margin: 20px 0 24px;
        //        font-size: 42px;
        //    }
        //    div {
        //        line-height: 27px;
        //        font-size: 18px;
        //        margin-bottom: 12px;
        //    }
        //}
        //.btn {
        //    position: absolute;
        //    left: 0;
        //    background-image: url('/static/sem/banner-btn.png');
        //    background-repeat: no-repeat;
        //    background-size: cover;
        //    bottom: 50px;
        //    width: 196px;
        //    height: 50px;
        //    cursor: pointer;
        //}
    }

}
.query_data {
    height: 640px;
    position: relative;
    padding-top: 80px;
    h3 {
        margin-bottom: 80px;
        text-align: center;
    }
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .item {
            width: 300px;
            height: 157px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .icon {
                width: 72px;
                height: 72px;
            }
            .title {
                font-weight: bold;
                color: #121826;
                font-size: 24px;
                margin-top: 20px;
            }
        }
    }
    .btn {
        width: 240px;
        height: 50px;
        border: 1px solid #4C93FC;
        color: #4C93FC;
        cursor: pointer;
        font-size: 20px;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        margin-top: 60px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .btn:hover {
        border: none;
        color: #fff;
        background-color: #0A79FC;
    }
    .nav_list {
        width: 320px;
        height: 820px;
        background: url("/static/sem/pc/nav_bg.png") no-repeat center center;
        background-size: cover;
        position: fixed;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        .nav_1 {
            background: url("/static/sem/pc/nav_1@2x.png") no-repeat center center;
            background-size: cover;
            top: 137px;
        }
        .nav_2 {
            background: url("/static/sem/pc/nav_2@2x.png") no-repeat center center;
            background-size: cover;
            top: 197px;
        }
        .nav_3 {
            background: url("/static/sem/pc/nav_3@2x.png") no-repeat center center;
            background-size: cover;
            top: 257px;
        }
        .nav_4 {
            background: url("/static/sem/pc/nav_4@2x.png") no-repeat center center;
            background-size: cover;
            top: 326px;
        }
        .nav_5 {
            background: url("/static/sem/pc/nav_5@2x.png") no-repeat center center;
            background-size: cover;
            top: 386px;
        }
        .nav_6 {
            background: url("/static/sem/pc/nav_6@2x.png") no-repeat center center;
            background-size: cover;
            top: 446px;
        }
        .nav_7 {
            background: url("/static/sem/pc/nav_7@2x.png") no-repeat center center;
            background-size: cover;
            top: 506px;
        }
        .nav_8 {
            background: url("/static/sem/pc/nav_8@2x.png") no-repeat center center;
            background-size: cover;
            top: 566px;
        }
        .nav_9 {
            background: url("/static/sem/pc/nav_9@2x.png") no-repeat center center;
            background-size: cover;
            top: 626px;
        }
        .nav_10 {
            background: url("/static/sem/pc/nav_10@2x.png") no-repeat center center;
            background-size: cover;
            top: 686px;
        }
        .hot {
            position: absolute;
            cursor: pointer;
            left: 57px;
            width: 206px;
            height: 78px;
        }
        .primary {
            position: absolute;
            cursor: pointer;
            left: 64px;
            width: 192px;
            height: 64px;
        }
        .close {
            width: 36px;
            height: 36px;
            position: absolute;
            cursor: pointer;
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.big-img {
    height: 968px;
    background: url("/static/sem/pc/big_img.png") no-repeat center center;
    background-size: cover;
    .center {
        height: 100%;
        position: relative;
        .phone {
            width: 603px;
            height: 968px;
            background: url("/static/sem/pc/phone.png") no-repeat center center;
            background-size: cover;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
        }
        .big-circle {
            width: 688px;
            height: 688px;
            border-radius: 50%;
            border: 6px solid #E5E8F9;
            position: absolute;
            top: 137px;
            left: 50%;
            transform: translateX(-50%);
        }
        .circle {
            width: 500px;
            height: 500px;
            border-radius: 50%;
            background-color: #E5E8F9;
            position: absolute;
            top: 237px;
            left: 50%;
            transform: translateX(-50%);
        }
        .icon-wrapper {
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            cursor: pointer;
            z-index: 3;
            .icon {
                width: 80px;
                height: 80px;
            }
            .icon:hover {
                transform: scale(1.2);
            }
            .name {
                font-size: 24px;
                color: #1D2233;
                font-weight: bold;
            }
        }
        .icon-wrapper1 {
            top: 207px;
            right: 170px;
            .name {
                margin-left: 30px;
            }
        }
        .icon-wrapper2 {
            top: 347px;
            right: 90px;
            .name {
                margin-left: 30px;
            }
        }
        .icon-wrapper3 {
            top: 527px;
            right: 90px;
            .name {
                margin-left: 30px;
            }
        }
        .icon-wrapper4 {
            bottom: 207px;
            right: 170px;
            .name {
                margin-left: 30px;
            }
        }
        .icon-wrapper5 {
            bottom: 207px;
            left: 190px;
            flex-direction: row-reverse;
            .name {
                margin-right: 30px;
            }
        }
        .icon-wrapper6 {
            top: 527px;
            left: 65px;
            flex-direction: row-reverse;
            .name {
                margin-right: 30px;
            }
        }
        .icon-wrapper7 {
            top: 347px;
            left: 90px;
            flex-direction: row-reverse;
            .name {
                margin-right: 30px;
            }
        }
        .icon-wrapper8 {
            top: 207px;
            left: 170px;
            flex-direction: row-reverse;
            .name {
                margin-right: 30px;
            }
        }
    }
}
.content {
    display: flex;
    align-items: center;
    height: 550px;
}
.image {
    background-image: url('/static/sem/pc/sku_bg_2.png');
    width: 433px;
    height: 376Px;
    background-size: contain;
    background-repeat: no-repeat;
}
.part1 {
    .image {
        background-image: url('/static/sem/pc/sku_bg_1.png');
    }
}
.part2 {
    background-color: #F9FAFE;
}
.part3 {
    background-color: #fff;
}
.part4 {
        background-color: #F9FAFE;
        h3 {
            font-size: 28px;
            padding: 60px 0 16px;
            text-align: center;
        }
        .tab {
            display: flex;
            align-items: center;
            position: relative;
            margin: 0 auto;
            width: 840px;
            cursor: pointer;
            .tab-item {
                width: 280px;
                height: 60px;
                font-weight: bold;
                font-size: 18px;
                background-color: #fff;
                text-align: center;
                line-height: 60px;
            }
            .actived {
                color: #fff;
                background-color: #0479fd;
            }
        }
        .images {
            display: flex;
            justify-content: center;
            margin-top: 60px;
            .image {
                width: 342px;
                height: 640px;
            }
        }
}
.b-banner {
    //height: 216px;
    //background: url("/static/sem/pc2/sem_pc_bg10.png") no-repeat center center;
    //background-size: cover;
  min-width: 1280px;
  img {
    width: 100%;
    display: block;
  }
}
.footer-bottom {
    height: 65px;
    background-color: #0f1417;
    display: flex;
    align-items: center;
    .copyright {
        text-align: center;
        font-size: 14px;
        color: #aaa;
    }
    a {
        color: #aaa;
    }
}
.show {
    opacity: 1;
    transition: all .3s;
}
.backTop {
    position: fixed;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    right: 10px;
    bottom: 100px;
    color: #8893a7;
    cursor: pointer;
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icon {
        width: 40px;
        height: 40px;
    }
    .back {
        font-size: 12px;
    }
    &:hover {
        color: #347fff;
    }
}
.text {
    width: 600px;
    .title {
        font-size: 24px;
    }
    .bar {
        width: 67px;
        height: 4px;
        background: #347fff;
        margin: 10px 0 30px 0;
    }
    .p-content {
        font-size: 18px;
        color: #86909c;
        margin-top: 60px;
        line-height: 25px;
        .part_btn_wrapper {
            display: flex;
            margin: 24px 0 60px 0;
            .part_btn {
                padding: 9px 17px;
                border: 1px solid #0A79FC;
                cursor: pointer;
                color: #0A79FC;
                border-radius: 6px;
                font-size: 16px;
                margin-right: 24px;
                position: relative;
            }
            .part_btn:hover {
                color: #fff;
                background-color: #0A79FC;
            }
            .hot_icon {
                position: absolute;
                width: 35px;
                height: 19px;
                left: -1px;
                top: -50%;
                transform: translateY(50%);
            }
            .part_btn:last-child {
                margin-right: 0;
            }
        }
        .primary_btn {
            width: 280px;
            height: 50px;
            cursor: pointer;
            background-color: #0A79FC;
            box-shadow:  0 2px 10px 0 #A4CEFF;
            font-size: 18px;
            color: #fff;
            margin-top: 66px;
            text-align: center;
            line-height: 50px;
            border-radius: 4px;
            position: relative;
            .primary_icon {
                position: absolute;
                width: 48px;
                height: 22px;
                left: 0;
                top: -50%;
                transform: translateY(50%);
            }
        }
    }
}
.popup {
    position: fixed;
    right: 10px;
    z-index: 100;
    .head {
        width: 371px;
        height: 138px;
        position: absolute;
        right: 5px;
        top: -92px;
        background-image: url('/static/sem/popup_head.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
        }
        .init-content {
            margin: 33px 0 0 22px;
            .head-img {
              display: block;
              width: 177px;
              height: auto;
            }
            .app {
                width: 177px;
                height: 27px;
                background-image: url('/static/sem/font_app.png');
                background-repeat: no-repeat;
                background-size: cover;
            }
            .desc {
                font-size: 14px;
                color: #fff;
                line-height: 20px;
                margin-top: 9px;
            }
            .init-title {
                background-image: url('/static/sem/font_title.png');
            }
        }
    }
    .from {
        position: relative;
        width: 381px;
        height: 400px;
        background-image: url('/static/sem/submit_content.png');
        background-repeat: no-repeat;
        background-size: cover;
        .i-icon {
            width: 200px;
            position: absolute;
            top: 30px;
            color: #101E3D;
            font-weight: bold;
            font-size: 16px;
            left: 50%;
            transform: translateX(-50%);
            .flex-start {
                display: flex;
                align-items: center;
            }
            .first {
                img {
                    width: 13px;
                    height: 18px;
                    margin-right: 3px;
                }
            }
            .second {
                margin-top: 15px;
                img {
                    width: 15px;
                    height: 17px;
                    margin-right: 3px;
                }
            }
        }
        .click-btn {
            position: absolute;
            bottom: 45px;
            left: 50%;
            transform: translateX(-50%);
            width: 273px;
            height: 52px;
            border-radius: 26px;
            cursor: pointer;
            color: #fff;
            background-image: linear-gradient(to right, #1177FE, #4EB5FF);
            text-align: center;
            line-height: 52px;
        }
        .form-wrap {
            position: absolute;
            top: 70px;
            left: 30px;
            .input-wrap{
                position: relative;
                height: 86px;
                border-radius: 4px;
                .requred {
                    position: relative;
                    font-size: 14px;
                    padding-left: 8px;
                    margin-top: 5px;
                    &::after {
                        content: "*";
                        position: absolute;
                        left: 0;
                        color: rgba(255, 89, 0, 1);
                    }
                    span {
                        margin-left: 20px;
                        font-size: 12px;
                        color: rgba(255, 89, 0, 1);
                    }
                }
                input{
                    height: 46px;
                    width: 317px;
                    font-size: 14px;
                    background: #E7EFFC;
                    border: none;
                    margin-top: 16px;
                    padding-left: 10px;
                }
                .vcode-input {
                    width: 196px;
                }
                .vcode{
                    width: 106px;
                    font-size: 14px;
                    color: #fff;
                    cursor: pointer;
                    line-height: 46px;
                    border: none;
                    position: absolute;
                    box-sizing: border-box;
                    bottom: 10px;
                    right: 10px;
                    background-image: linear-gradient(to right, #1177FE, #4EB5FF);
                    height: 46px;
                    text-align: center;
                }
                .disable {
                    background: #AEB9CB;
                }
            }
            .qrcode-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 60px;
                div {
                    color: #101E3D;
                    font-size: 14px;
                    font-weight: bold;
                }
                .qrcode {
                    width: 200px;
                    height: 200px;
                    display: inline-block;
                }
            }
        }
        .from-error-wrap{
          padding-top: 34px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .header-box{
            display: flex;
            justify-content: center;
            align-items: center;
            .sorry-icon{
              width: 82px;
              height: 80px;
            }
            .error-tip{
                font-weight: bold;
                font-size: 16px;
                color: #101E3D;
                .error-size2{
                  font-size: 18px;
                }
            }

          }
          .wx-qrCode-img{
            margin-top: 12px;
            width: 202px;
            height: 200px;
          }
          .add-tip{
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 14px;
              .add-icon{
                width: 16px;
                height: 16px;
              }
              .add-text{
                font-weight: bold;
                font-size: 14px;
                color: #101E3D;
              }
            }
            .discounts-text{
              font-weight: bold;
              font-size: 14px;
              color: #FF7E00;
            }
        }
    }
    .close {
        width: 36px;
        height: 36px;
        position: absolute;
        cursor: pointer;
        bottom: -55px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.free {
    position: fixed;
    right: -15px;
    cursor: pointer;
    top: 30%;
    width: 118px;
    height: 239px;
    background-image: url('/static/sem/pc/free_img.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.screen {
    z-index: 98;
    top: 50%;
    transform: translateY(-50%);
     .from {
        background-image: url('/static/sem/popup_content.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    font-size: 22px;
    font-weight: bold;
    .qr-code-img{
      width: 289px;
      height: 389px;
    }
}
.contain {
  min-width: 1280px;
}
@media screen and (max-width: 1440px) {
    .screen, .nav_list {
        zoom: 0.8;
    }
    .contain {
        zoom: 0.8;
    }
}
@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .screen, .nav_list {
        zoom: 0.85;
    }
    .contain {
        zoom: 0.85;
    }
}
@media screen and (min-width: 1921px) {
    .screen, .nav_list {
        zoom: 0.9;
    }
    .contain {
        zoom: 0.9;
    }
}
.submit {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}
.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,.5);
}
</style>
