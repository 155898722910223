<template>
    <div class="one-comment">
        <van-image round :src="itemObj.avatar" />
        <div class="content">
            <div class="top">
                <div class="name">{{itemObj.username}}</div>
                <div :class="['good', itemObj.isNice ? 'good--active' : 'good--normal']" @click="clickNice(itemObj)">
                    {{ itemObj.nice ? itemObj.nice : '赞' }}
                </div>
            </div>
            <div class="bottom-part">
                {{itemObj.content}}
            </div>
            <div :class="{'reply': hasReply}">
                <slot></slot>
            </div>
            <div :class="{ 'has-border': hasBorder }"></div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'OneComment',
    props: {
        hasBorder: {
            type   : Boolean,
            default: false
        },
        hasReply: {
            type   : Boolean,
            default: false
        },
        itemObj: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        // 点赞
        clickNice({ isNice, id }) {
            this.HTTP.niceComment({
                status: isNice ? 0 : 1,
                id,
            }).then(res => {
                if (res.data) {
                    let ceng = 0
                    let par = this.$parent
                    // 嵌套组件调用父组件方法 最多3层
                    while (!par.getComment && ceng < 3) {
                        ceng++
                        par = par.$parent
                    }
                    par.getComment()
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.one-comment {
    display: flex;
    .van-image {
        width: 9.066vw;
        height: 9.066vw;
        margin-right: 1.733vw;
    }
    .content {
        flex: 1;
    }
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 9.066vw;
        .name {
            font-size: 3.733vw;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #333333;
            // line-height: 5.333vw;
        }
        .good {
            width: 8.533vw;
            height: 4vw;
            line-height: 4vw;
            text-align: right;
            color: #666666;
            font-size: 3.2vw;
            &--active {
                background: url('/static/img/hot-search/good-active.png') top left / 4vw 4vw no-repeat;
            }
            &--normal {
                background: url('/static/img/hot-search/good-normal.png') top left / 4vw 4vw no-repeat;
            }
        }
    }
    .bottom-part {
        max-width: 80vw;
        word-break: break-all;
        color: #333;
        font-size: 3.466vw;
        line-height: 4.933vw;
        padding-bottom: 3.2vw;
    }
    .has-border {
        padding-top: 4vw;
        border-bottom: 0.133vw solid #e7e7e7;
    }
    .reply {
        background: #f7f7f7;
        border-radius: 6px;
        padding: 3.2vw;
    }
}
</style>
