<!--
图标

Props:
  name: 图标名称(使用文件名)
  size: 尺寸大小(宽高一致时), 默认4vw
  width: 单独指定宽度
  height: 单独指定高度
  unit: 尺寸单位, 默认vw
-->

<template>
    <!-- <svg class="svg-icon" :style="styles" aria-hidden="true">
        <use :xlink:href="iconName"></use>
    </svg> -->
    <i class="svg-icon" :style="styles"></i>
</template>

<script>
export default {
    name : 'Icon',
    props: {
        name: {
            type    : String,
            required: true
        },
        size: {
            type   : Number,
            default: 4
        },
        color: {
            type   : String,
            default: 'currentColor'
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        unit: {
            type   : String,
            default: 'vw'
        }
    },
    computed: {
        iconName () {
            return `#icon-${this.name}`;
        },
        styles () {
            return {
                width          : `${this.width || this.size}${this.unit}`,
                height         : `${this.height || this.size}${this.unit}`,
                fill           : this.color,
                stroke         : this.color,
                backgroundImage: `url(/static/icon/svg/icons/${this.name}.svg)`,
            };
        }
    }
};
</script>

<style scoped>
.svg-icon {
    display: block;
    overflow: hidden;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: contain;
}
</style>
