<template>
  <div class="asking4Rent-details">
    <div class="header">
      <StateBar />
      <TitleHeader title="详情" bgcolor="transparent" color="black" :back="true"
        style="border-bottom: 1px solid #f3f4f7">
      </TitleHeader>
      <AutoView :header="true" :footer="showFooter" v-if="info.isOpen">
        <van-cell-group>
          <van-cell class="van-cell2">
            <template #title />
            <template #label>
              <div class="card-item-container">
                <div class="card-box">
                  <span class="card-label">意向城市</span>
                  <span class="card-value">{{ info.rentProvince }} | {{ info.rentCity }}</span>
                </div>
                <div class="card-box">
                  <span class="card-label">意向区域</span>
                  <span class="card-value">{{ info.rentRegionList.join('、') }}</span>
                </div>
              </div>
<!--              <div class="card-item-container">-->
<!--                <div class="base-info">-->
<!--                  <div class="base-info-item">-->
<!--                    <div class="bii-value">{{ info.areaContent }}</div>-->
<!--                    <div class="bii-title">{{ info.areaTitle }}</div>-->
<!--                  </div>-->
<!--                  <div class="split-line" />-->
<!--                  <div class="base-info-item">-->
<!--                    <div class="bii-value">{{ info.rentContent }}</div>-->
<!--                    <div class="bii-title">{{ info.rentTitle }}</div>-->
<!--                  </div>-->
<!--                  <div class="split-line" />-->
<!--                  <div class="base-info-item">-->
<!--                    <div class="bii-value">{{ info.formatContent }}</div>-->
<!--                    <div class="bii-title">{{ info.formatTitle }}</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="location-info">-->
<!--                  <div class="location-item">-->
<!--                    <div class="small-icon" />-->
<!--                    <div class="small-title">求租区域</div>-->
<!--                  </div>-->
<!--                  <div class="location-item">-->
<!--                    <div class="small-title">{{ info.rentCity }}</div>-->
<!--                  </div>-->
<!--                  <div class="region-item" v-for="(region, index) in info.rentRegionList" :key="index">-->
<!--                    {{ region }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell class="van-cell2">
            <template #title />
            <template #label>
              <div class="card-item-container">
                <div class="card-box">
                  <span class="card-label">期望实用面积</span>
                  <span class="card-value">{{ info.areaContent }}</span>
                </div>
                <div class="card-box">
                  <span class="card-label">期望月租金</span>
                  <span class="card-value">{{ info.rentContent }}</span>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell class="van-cell2">
            <template #title />
            <template #label>
              <div class="card-item-container">
                <div class="card-box">
                  <span class="card-label">转让费</span>
                  <span class="card-value">{{ info.transferType === 1 ? '接受' : info.transferType === 2 ? '不接受' : '无要求'}}</span>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group v-if="info.formatCategory || info.businessCategory || (!showOtherInfo && info.budgetMax)">
          <van-cell>
            <template #title />
            <template #label>
              <div v-if="info.formatCategory">
                <div class="card-item-header ">
                  <div class="pinglei-item-icon" />
                  <div class="card-item-title">
                    经营品类
                  </div>
                </div>
                <div class="card-item-container">
                  <div class="type-list-item">
                    <template v-for="(item, index) in dealFormatCategory(info.formatCategory)">
                      <div class="item-split-line" v-if="index && index % 2 != 0" :key="`${index}-line`" />
                      <div class="item-text" :key="`${index}-text`">
                        {{ item }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-if="info.businessCategory">
                <div class="card-item-header ">
                  <div class="leixing-item-icon" />
                  <div class="card-item-title">
                    求租商铺类型
                  </div>
                </div>
                <div class="card-item-container">
                  <div class="type-list-item">
                    <template v-for="(item, index) in dealBusinessCategory(info.businessCategory)">
                      <div class="item-split-line" v-if="index && index % 2 != 0" :key="`${index}-line`" />
                      <div class="item-text" :key="`${index}-text`">
                        {{ item }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="card-item-container" v-if="!showOtherInfo && info.budgetMax">
                <div class="type-list-item">
                  <div class="item-info">
                    <div class="touzhi-icon" />
                    <div>投资预算</div>
                    <div v-if="info.budgetContent && info.budgetMax && info.budgetMax != 0"
                      style="text-align: center; flex: 1">
                      {{ info.budgetContent }}
                    </div>
                    <div v-else style="text-align: center; flex: 1">无</div>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <!-- 商铺要求 -->
        <van-cell-group v-if="showShopRequire">
          <van-cell>
            <template #title>
              <div class="card-item-header ">
                <div class="yaoqiu-item-icon" />
                <div class="card-item-title">
                  商铺要求
                </div>
              </div>
            </template>
            <template #label>
              <shop-requirements :edit="false" :data="dealShopRequire(info.intentionShopRequire)" />
            </template>
          </van-cell>
        </van-cell-group>
        <!--  其他要求  -->
        <van-cell-group v-if="info.otherRequire">
          <van-cell>
            <template #title>
              <div class="card-item-header ">
                <div class="qita-item-icon" />
                <div class="card-item-title">
                  其他要求
                </div>
              </div>
            </template>
            <template #label>
              <div class="card-item-container ">
                <div class="info-text">{{ info.otherRequire }}</div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <div class="info-tips" v-if="false">
          <div class="info-tips-icon" />
          <div class="info-tips-text" @click="jumpAsking">
            我也要发布求租信息
          </div>
        </div>
      </AutoView>
      <AutoView :header="true" :footer="false" v-else>
        <div class="closeView" v-if="!loading">
          <img src="/static/shopEcology/shopRequirements/icon/close_page_image.png" alt="">
          <div class="closeTitle">此求租信息已关闭</div>
        </div>
      </AutoView>
      <div class="button-bottom-box" v-if="showFooter && !isMini">
        <div class="button-box" @click="jumpIM">
          <div class="button-text">联系Ta</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shopRequirements from './shopRequirements.vue';
import { getIntentionRentInfo } from '@/request/asking4Rent';
export default {
  data() {
    return {
      info: {},
      loading: true,
      isMini: false
    }
  },
  mounted() {
    this.isMini = window.isMini
    this.init();
  },
  methods: {
    init() {
      let id = this.$route.query.id;
      getIntentionRentInfo(id).then(res => {
        this.info = res || {};
        console.log(res)
      }).finally(e => {
        this.loading = false
      })
    },
    jumpIM() {
      let a = `求租区域: ${this.info.rentCity}`;
      this.info.rentRegionList.map(v => {
        a += (`|${v}`)
      });
      let obj = {
        obj: JSON.stringify({
          bottomTags: a,
          icon: this.info.iconUrl,
          leftContent: this.info.areaContent,
          leftContentTitle: this.info.areaTitle,
          messageContent: this.info.businessTitle,
          middleContent: this.info.rentContent,
          middleContentTitle: this.info.rentTitle,
          rightContent: this.info.formatContent,
          rightContentTitle: this.info.formatTitle,
          title: this.info.businessTitle,
          link: { isClear: false, type: 6, url: '#/shopEcology/askingRentDetails?id=' + this.info.id }
        }),
        type: 1
      }
      this.JSBridge.BTNCLICK(this.$route.meta.id, this.info.id, '3170100', '3170101');
      this.JSBridge.callHandler("startIMChat", {
        chatId: this.info.imUserID,
        chatName: this.info.imUserName,
        type: "3", // 新增求租会话type类型
      });
    },
    jumpAsking() {
      this.JSBridge.BTNCLICK(this.$route.meta.id, this.info.id, '3170100', '3170102');
      this.swRouter('/h5/#/shop/rent');
    },
    dealShopRequire(data) {
      if (!data) return [];
      return typeof (data) == "string" ? JSON.parse(data) : data;
    },
    dealFormatCategory(data) {
      let a = [];
      if (!data) return [];
      let temp = typeof (data) == "string" ? JSON.parse(data) : data;
      for (let idx1 = 0; idx1 < temp.length; idx1++) {
        const item1 = temp[idx1];
        if (!item1.children.length) {
          return [item1.name];
        }
        for (let idx2 = 0; idx2 < item1.children.length; idx2++) {
          const item2 = item1.children[idx2];
          if (!item2.children.length && !item2.is_selectAll) {
            return [item1.name + "-" + item2.name];
          }
          try {
            if (item2.is_selectAll) a.push(`${item1.name}·${item2.name}·不限`);
            else {
              item2.children.map(v => {
                v.is_select && a.push(`${item1.name}·${item2.name}·${v.name}`);
              })
            }
          } catch (error) {
            console.log(item2);
          }
        }
      }
      return a
    },
    dealBusinessCategory(data) {
      let a = [];
      if (!data) return [];
      let temp = typeof (data) == "string" ? JSON.parse(data) : data;

      for (let idx1 = 0; idx1 < temp.length; idx1++) {
        const item1 = temp[idx1];
        for (let idx2 = 0; idx2 < item1.children.length; idx2++) {
          const item2 = item1.children[idx2];
          if (item2.is_select) a.push(`${item1.businessName}·${item2.businessName}`);
        }
      }
      return a

    }
  },
  computed: {
    showOtherInfo() {
      return (this.info.budgetMin == null || this.info.budgetMax == null || this.info.budgetMax <= 0) && !this.info.transferType;
    },
    showRentTitle() {
      return this.info.isOpen ? this.info.businessTitle : this.loading ? '' : '求租信息'
    },
    showShopRequire() {
      let data = this.dealShopRequire(this.info.intentionShopRequire) || [];
      let temp = data.filter(v => { return v.value >= 1 });
      return temp.length ? true : false;
    },
    showFooter() {
      return !this.info.isSelf && this.info.isShowContactButton
    }
  },
  components: { shopRequirements }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

@function vw($args) {
  @return $args/750 * 100+vw;
}

.asking4Rent-details {
  .autoView {
    background: #F8F8F9;

    ::v-deep .van-cell-group {
      border-radius: vw(10);
      margin: vw(20) vw(20);
    }
  }

  ::v-deep .van-cell {
    padding: vw(30) vw(30);
    &.van-cell2 {
      padding: 0 vw(30);
    }
    .van-cell__label {
      margin-top: 0;
    }
  }

  .closeView {
    text-align: center;

    img {
      width: vw(174);
      height: vw(174);
      margin-top: vw(209);
      margin-bottom: vw(36);
    }

    .closeTitle {
      height: vw(42);
      font-size: vw(30);
      color: #333333;
      line-height: vw(42);
    }
  }

  .card-item-header {
    display: flex;
    align-items: center;
    padding-bottom: vw(17);

    .qita-item-icon {
      width: vw(31);
      height: vw(32);
      margin-right: vw(5);
      background: url(/static/shopEcology/shopRequirements/icon/qita.png);
      background-size: vw(31) vw(32);
    }

    .yaoqiu-item-icon {
      width: vw(35);
      height: vw(31);
      margin-right: vw(5);
      background: url(/static/shopEcology/shopRequirements/icon/yaoqiu.png);
      background-size: vw(35) vw(31);
    }

    .pinglei-item-icon {
      width: vw(26);
      height: vw(26);
      margin-right: vw(5);
      background: url(/static/shopEcology/shopRequirements/icon/pinglei.png);
      background-size: vw(26) vw(26);
    }

    .leixing-item-icon {
      width: vw(28);
      height: vw(28);
      margin-right: vw(5);
      background: url(/static/shopEcology/shopRequirements/icon/leixing.png);
      background-size: vw(28) vw(28);
    }

    .card-item-title {
      flex: 1;
      height: vw(42);
      font-size: vw(30);
      line-height: vw(42);
      font-weight: 600;
      color: #333333;
    }

    .card-item-opts {
      flex-shrink: 0;
    }
  }

  .card-item-container {
    .card-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: vw(30) 0;
      &:nth-child(n+2) {
        border-top: 1px solid #E4E7ED;
      }
      .card-label {
        font-weight: bold;
        color: #1D2233;
        font-size: vw(30);
        margin-right: vw(30);
      }
      .card-value {
        font-weight: 400;
        color: #1D2233;
        font-size: vw(28);
        flex: 1;
        text-align: right;
      }
    }
    .base-info {
      display: flex;
      padding: vw(25);
      align-items: center;
      border-radius: vw(10);
      background: #FFF4E8;

      .split-line {
        width: vw(1);
        height: vw(60);
        opacity: 0.8;
        background: #DEDEE7;
      }

      .base-info-item {
        flex: 1;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .bii-value {
          font-size: vw(28);
          color: #FF8047;
          line-height: vw(45);
          margin-bottom: vw(4);
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .bii-title {
          font-size: vw(22);
          color: #676C84;
          line-height: vw(30);
        }
      }
    }

    .location-info {
      display: flex;
      align-items: center;
      flex-flow: row wrap;

      .location-item {
        display: flex;
        height: vw(30);
        flex-shrink: 0;
        margin-top: vw(20);
        margin-right: vw(20);
        align-items: center;
        padding: vw(6) vw(12);
        background: #F5F8FF;

        .small-icon {
          width: vw(28);
          height: vw(28);
          margin-right: vw(10);
          background-size: vw(28) vw(28);
          background-position: center;
          background-image: url(/static/shopEcology/shopRequirements/icon/location-small1.png);
        }

        .small-title {
          flex: 1;
          height: vw(36);
          font-size: vw(24);
          font-weight: 400;
          color: #347FFF;
          line-height: vw(36);
        }
      }

      .region-item {
        height: vw(30);
        flex-shrink: 0;
        margin-top: vw(20);
        margin-right: vw(20);
        padding: vw(6) vw(12);
        background: #F5F8FF;
      }
    }

    .info-text {
      color: #999999;
      height: vw(36);
      font-size: vw(26);
      line-height: vw(36);
    }

    .type-list-item {
      display: flex;
      flex-flow: wrap;
      align-items: top;

      .item-text {
        width: 48%;
        min-height: vw(36);
        font-size: vw(24);
        line-height: vw(36);
        color: #666666;
        margin-bottom: vw(15);
      }

      .item-info {
        width: 48%;
        display: flex;
        height: vw(36);
        font-size: vw(24);
        line-height: vw(36);
        color: #666666;
        margin-top: vw(31);

        .touzhi-icon {
          width: vw(28);
          height: vw(28);
          margin-right: vw(10);
          background: url(/static/shopEcology/shopRequirements/icon/touzhi.png);
          background-size: vw(28) vw(28);

        }

        .zhuanrang-icon {
          width: vw(34);
          height: vw(36);
          margin-right: vw(10);
          background: url(/static/shopEcology/shopRequirements/icon/zhuanrang.png);
          background-size: vw(34) vw(36);

        }
      }

      .item-split-line {
        width: vw(1);
        height: vw(29);
        margin: vw(4) vw(12) vw(15) vw(12);
        background: #D8D8D8;
      }

      .item-split-line-1 {
        width: vw(1);
        height: vw(29);
        margin: 0 vw(12);
        margin-top: vw(31);
        background: #D8D8D8;
      }
    }
  }

  .info-tips {
    display: flex;
    align-items: center;
    margin-top: vw(60);
    margin-bottom: vw(21);
    justify-content: center;

    .info-tips-icon {
      width: vw(28);
      height: vw(28);
      margin-right: vw(4);
      background: url(/static/shopEcology/shopRequirements/icon/feiji.png);
      background-size: vw(28) vw(28);
    }

    .info-tips-text {
      height: vw(36);
      font-size: vw(26);
      color: #999999;
      line-height: vw(36);

    }
  }

  .button-bottom-box {
    padding: vw(21) vw(30) 0 vw(30);

    .button-box {
      height: vw(80);
      border-radius: vw(10);
      background: linear-gradient(319deg, #3888F8 0%, #86C6FF 100%);

      .button-text {
        height: vw(80);
        font-size: vw(28);
        font-weight: 600;
        color: #FFFFFF;
        line-height: vw(80);
        text-align: center;
      }
    }

  }
}
</style>
