<template>
  <div class="store-item" @click="openDetail">
    <div class="course-cover">
      <van-image class="brand-icon" :src="store.image" fit="cover" />
    </div>
    <div class="course-title">{{ store.title }}</div>
  </div>
</template>

<script>
export default {
  name: 'storeItem',
  props: {
    store: null
  },
  methods: {
    openDetail() {
      this.swRouter( '/h5/#/community/article/'+ this.store.id );
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
@import '@/styles/px2vw.scss';
.store-item {
  background: #FFFFFF;
  border-radius: vw(8);
  margin-top: 3.467vw;
  .course-cover {
    height: 25.067vw;
    background: #ccc;
    border-radius: vw(8);
    .brand-icon {
      width: 100%;
      height: 100%;
      border-radius: vw(8);
      ::v-deep img {
        border-radius: vw(8);
      }
    }
  }
  .course-title {
    font-weight: 400;
    color: #333333;
    font-size: 3.467vw;
    height: 10vw;
    line-height: 4.933vw;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 2.133vw 0 1.2vw;
  }
}
</style>
