/**
 * 小区筛选路由
 */
const cellScreen = () => import(/* webpackChunkName: "cell-screen" */ '@/views/cellScreen') // 小区列表页
const communityCollection = () => import(/* webpackChunkName: "cell-screen" */ '@/views/cellScreen/communityCollection') // 小区收藏页
const matchDetail = () => import(/* webpackChunkName: "cell-screen" */ '@/views/cellScreen/match-detail') // 配套详情
const cellScreenSearch = () => import(/* webpackChunkName: "cell-screen" */ '@/views/cellScreen/search') // 小区搜索
const cellScreenReport  =() => import(/* webpackChunkName: "cell-screen" */ '@/views/cellScreen/report') // 小区筛选报告页

export default [
  {
    path: "/cellScreen",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "/",
        name: "cellScreen",
        component: cellScreen,
        meta: { id: 10306, keepAlive: true }
      },
      {
        path: "collection",
        name: "communityCollection",
        component: communityCollection,
        meta: { id: 10304 }
      },
      {
        path: "search",
        name: "cellScreenSearch",
        component: cellScreenSearch,
        meta: { id: 10303 }
      },
      {
        path: "report/:id",
        name: "cellScreenReport",
        component: cellScreenReport,
        meta: { id: 10315 }
      }
    ]
  },
  {
    path: "/matchDetail",
    name: "matchDetail",
    component: matchDetail,
    meta: { id: 10308 }
  }
]
