<template>
<div>
    <div class="item-one">
        <div class="main">
            <!-- <van-image :src="itemObj.logo" /> -->
            <div class="data">
                <p class="name">{{ itemObj.name }}</p>
                <p v-if="itemObj.monthSaleCount"><span class="label">外卖月售：</span>{{itemObj.monthSaleCount}}</p>
                <p v-else><span class="label">好评率：</span>96.37%</p>
                <p><span class="label">口碑评分：</span>{{ itemObj.rating }} <span class="label avg">人均：</span>{{ itemObj.avgPrice }}元</p>
            </div>
        </div>
        <div class="footer" v-if="hasFooter">
            <div class="type">123</div>
            <div class="shopNum">现有门店：<span>{{ 36 }}</span>家</div>
        </div>
        <div :class="['sort', {'sort--top': topNum < 3}, 'sort--' + (topNum+1)]">{{ topNum+1 > 3 ? topNum+1 : '' }}</div>
    </div>
    <slot></slot>
</div>
</template>

<script>
export default {
    name: 'OneItem',
    props: {
        itemObj: {
            type: Object,
            default: () => ({})
        },
        hasFooter: {
            type: Boolean,
            default: false
        },
        topNum: {
            type: Number,
            default: 1
        }
    }
};
</script>

<style lang="scss" scoped>
.item-one {
    * {
        box-sizing: border-box;
    }
    margin: 3.333vw auto;
    width: 92vw;
    background: #ffffff;
    box-shadow: 0 0 2.266vw 0 rgba(0, 0, 0, 0.09);
    border-radius: 1.33vw;
    position: relative;
    .sort {
        position: absolute;
        top: 3.067vw;
        right: 3.4677vw;
        background: #FFDDCA;
        width: 6vw;
        height: 6vw;
        line-height: 6.2vw;
        text-align: center;
        border-radius: 50%;
        font-size: 3.2vw;
        color: #FF5900;
        &--top {
            width: 5.867vw;
            height: 7.733vw;
            top: -1.06vw;
        }
        &--1{
            background: url('/static/img/hot-search/sort-1.png') 0 0 / 100% 100% no-repeat;
        }
        &--2{
            background: url('/static/img/hot-search/sort-2.png') 0 0 / 100% 100% no-repeat;
        }
        &--3{
            background: url('/static/img/hot-search/sort-3.png') 0 0 / 100% 100% no-repeat;
        }
    }
    .main {
        height: 26.677vw;
        padding: 3.33vw;
        display: flex;
        .van-image {
            width: 20vw;
            margin-right: 2.667vw;
        }
        .data {
            flex: 1;
            color: #666;
            font-size: 3.4666vw;
            line-height: 4.933vw;
            font-family: PingFangSC-Regular, PingFang SC;
            > p {
                margin-bottom: 1.86vw;
            }
            .name {
                width: 58vw;
                color: #1d2233;
                line-height: 5.6vw;
                font-size: 4vw;
                font-weight: bold;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .label {
                color: #8e9099;
                &.avg {
                    margin-left: 5.33vw;
                }
            }
        }
    }
    .footer {
        height: 9.06777vw;
        background: #fbfbfb;
        padding-left: 3.333vw;
        padding-right: 3.2vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .type {
            padding: 0 1.6vw;
            height: 4.8vw;
            line-height: 5.2vw;
            text-align: center;
            background: #FFE6D8;
            border-radius: 0.6667vw;
            opacity: 0.58;
            border: 0.1333vw solid #FF5900;
            color: #FF5900;
            font-size: 3.2vw;
        }
        .shopNum {
            color: #666;
            font-size: 3.4667vw;
            span {
                color: #FF5900;
            }
        }
    }
}
</style>
