<template>
    <div class="container">
        <StateBar />
		<TitleHeader title="订单支付" bgcolor="white" color="black" :back="true" style="border-bottom: 1px solid #F3F4F7;">
		</TitleHeader>
		<AutoView class="wrapper">
			<div class="goods-wrapper">
                <img class="goods-icon" :src="formatUrl(shopInfo.image) + '?x-oss-process=image/resize,m_fill,h_100'" alt=""/>
                <div class="goods-info">
                    <div class="subText">{{shopInfo.goodsName}}</div>
                    <div class="address">{{shopInfo.addressName}}</div>
                    <div class="price">{{shopInfo.money}}元</div>
                </div>
			</div>
            <!-- 支付方式选择区域 -->
            <div class="payment-wrapper" style="padding-bottom: 0" v-if="showPayMode">
                <h3 class="s-subtitle">支付方式</h3>
                <!-- 支付方式列表 -->
                <PayTypes :payList="payList" :payType="payType" @setPayType="setPayType" />
            </div>
            <!-- 描述区域 -->
            <div class="tips">
                温馨提示：<br />
                <p>1、您将解锁的商品为虚拟内容产品，确认支付后不支持退换、转让，请仔细确认。</p>
                <p>2、大数据产品服务是基于一定样本量的算法结果，上上参谋不保证用户在平台获得的任何信息、内容(包括但不限于模型结论等)将是完整和没有错误的。由于数据产品服务的特殊性和失效性，在报告完成交付后，用户不得以前述理由要求平台进行退款。</p>
            </div>
		</AutoView>
        <SoltFooter class="pay-footer">
            <van-row class="view">
                <van-col span="12">
                    <p class="label">应付：</p>
                    <p class="price">{{shopInfo.money}}<span>元</span></p>
                </van-col>
                <van-col span="12">
                    <van-button type="warning" block @click="onTapClick">确认支付</van-button>
                </van-col>
            </van-row>
        </SoltFooter>


        <!-- 支付等待遮罩 -->
        <van-overlay :show="waiting">
            <div class="s-overlay">
                <van-loading color="#518CDB" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
const GOODS_ID = 10098 // 解锁铺位商品Id
const DELAY_TIME = 2000 // 超时时间
import PayTypes from "@/components/payTypes";
import { getShopOrderInfo } from "@/request/shopEcology"
export default {
    data() {
        return {
            payList: ["支付宝", "微信"],
            payType: "支付宝", // 默认支付方式
            payMode: "ALIPAY_MOBILE",
            payCall: "aliPay",
            payMap: {
                支付宝: {
                    mode: "ALIPAY_MOBILE",
                    call: "aliPay",
                    unit: "元",
                },
                微信: {
                    mode: "WX_APP",
                    call: "wxPay",
                    unit: "元",
                },
                苹果: {
                    mode: "APPLE_IN",
                    call: "applePay",
                    unit: "元",
                },
            },
            shopInfo: {
                addressName: '',
                goodsName: '',
                image: '',
                money: '',
            },
            waiting: false,
            showPayMode: true,
            isAndroid: false,
            isIOS: false,
            client: 3, // 1 iOS 2 Android 3 WEB 4 WX
        }
    },
    components: {
        PayTypes
    },
    created() {
        if (window.isIOS) {
            this.isIOS = true;
            this.client = 1;
            this.showPayMode = false
            this.setPayType('苹果')
        } else if (window.isAndroid) {
            this.isAndroid = true;
            this.client = 2;
        }
        this.getShopOrderInfo()
    },
    beforeMount() {
        // 注册支付回调
        this.JSBridge.registerHandler("paymentResult", (data) => {
            // 安卓端需要进行转义
            if (typeof data === "string") {
                data = JSON.parse(data);
            }
            if (typeof data === "object") {
                console.log(data.state, "state")
                this.waiting = false;
                switch (data.state) {
                    case 1:
                        // 支付成功
                        this.swRouter({
                            path: '/shopEcology/paySuccessful',
                            query: {
                                addressName: this.shopInfo.addressName,
                                id: this.$route.query.id
                            }
                        });
                        break;
                    case 8: // 取消
                        this.$toast("已取消支付");
                        setTimeout(() => {
                            this.waiting = false;
                        }, DELAY_TIME);
                        break;
                    case 5: // 兼容微信
                    case 6: // 余额不足
                    case 7: // 超时
                    default:
                        // 支付失败
                        this.$toast("支付失败，请稍候重试！");
                        setTimeout(() => {
                            this.waiting = false;
                        }, DELAY_TIME);
                    }
            } else {
                this.$toast("支付时发生错误！");
                this.waiting = false;
            }
        });
    },
    watch: {
        shopInfo(res) {
            console.log(this.isIOS,'this.isIOS')
            if (this.isIOS) {
                if(window._appCode < 301) {
                    this.payType = "支付宝";
                    this.showPayMode = true;

                } else {
                    this.setPayType('苹果')
                    this.showPayMode = false;
                }
            }
        }
    },
    methods: {
        setPayType(type) {
            const map = this.payMap[type];
            this.payType = type;
            this.payMode = map.mode;
            this.payCall = map.call;
        },
        getShopOrderInfo() {
            getShopOrderInfo(this.$route.query.id).then(res => {
                console.log('支付信息：', res)
                // this.shopInfo = Object.assign(this.shopInfo, res || {})
                this.shopInfo = res
                this.shopInfo.image = res.image && JSON.parse(res.image)[0]
            })
        },
        onTapClick() {
			this.JSBridge.BTNCLICK(this.$route.meta.id, GOODS_ID, 154000, 154001);
            this.waiting = true;
            this.HTTP.createMap(GOODS_ID, this.payMode, this.$route.query.id, this.client, this.$route.meta.id)
            .then(res => {
                this.orderNo = res.orderNo;
                const data = {
                    id: res.orderNo,
                    data: res.payResult,
                };
                if (window.isClient) {
                    this.JSBridge.callHandler(this.payCall, data, (st) => {
                        if (!st) {
                            this.$toast("未安装客户端，调起支付失败！");
                            setTimeout(() => {
                                this.waiting = false;
                            }, 1000);
                            console.log(`调起支付...失败`);
                            return;
                        }
                        console.log(`调起支付...end`);
                    });
                } else {
                    this.$toast("暂不支持网页版支付");
                    setTimeout(() => {
                        this.waiting = false;
                    }, 1000);
                }
            })
        },
        formatUrl(url){
          if (!url) {
            return '';
          }
          return url.replace(/\\/g, '');
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

@function vw($args) {
    @return $args/750*100+vw;
}
.wrapper {
    background-color: #F8F8F8;
    padding: vw(10) vw(25);
}
.goods-wrapper {
    background-color: #fff;
    padding: vw(30);
    display: flex;
    border-radius: vw(8);
    margin-top: vw(10);
    .goods-icon {
        width: vw(155);
        height: vw(118);
        display: inline-block;
    }
    .goods-info {
        padding: 0 vw(30);
        .subText {
            font-weight: bold;
            font-size: vw(30);
            line-height: vw(42);
        }
        .address {
            color: #999;
            font-size: vw(26);
            margin: vw(10) 0 vw(30);
            line-height: vw(36);
        }
        .price {
            font-size: vw(32);
            font-weight: bold;
            color: #F46958;
        }
    }
}
.payment-wrapper {
    background-color: #fff;
    margin-top: vw(20);
    padding: vw(32);
}
.pay-footer {
    .view {
      box-sizing: border-box;
      padding: $headerMargin $padding;
      width: 100%;
      color: #F46958;
      height: $footerHeight;
      ::v-deep .van-col {
        height: 100%;
      }
    }
    .label {
      color: #999;
      font-size: vw(26);
      left: $font_373;
      padding-top: 0.5vw;
      line-height: vw(40);
    }
    .price {
      font-size: vw(32);
      line-height: vw(36);
      font-weight: bold;
    }
}
.tips {
    font-size: vw(26);
    margin-top: vw(32);
    line-height: vw(36);
    color: #666;
    p {
        color: #999;
        margin-top: vw(12) !important;
    }
}
</style>
