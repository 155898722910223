<template>
    <div class="sales">
        <StateBar></StateBar>
        <TitleHeader title="门店月销售排行" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="sales--view">
            <van-skeleton :loading="loading" title :animate="false" :row="3" class="s-skeleton s-radius__s">
                <div class="ranking-box" v-for="(val, index) in list" :key="index">
                    <template v-if="val.shopLogo">
                        <img class="ranking-logo" :src="val.shopLogo" alt="" />
                    </template>
                    <div class="ranking-content">
                        <div class="content-title">
                            <h3 class="title">
                                <span>{{ val.shopName }}</span>
                            </h3>
                            <span class="cataory" v-if="val.categoryName!=='None'">{{ val.categoryName }}</span>
                        </div>
                        <p>月订单指数：{{ val.saleCount ? val.saleCount : "-" }}</p>
                        <div class="cata-type">
                            <div>口碑评分：{{ val.rating ? `${val.rating} / 100` : "-" }}</div>
                            <div>客单价：{{ val.avgPrice ? `${val.avgPrice}元` : "-" }}</div>
                        </div>
                    </div>
                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            list: undefined,
        };
    },
    mounted() {
        let reportId = this.$route.query.reportId;
        console.log(reportId);
        if (!reportId) {
            return this.$toast("参数错误");
        }
        this.loadData(reportId);
    },
    methods: {
        loadData(reportId) {
            this.loading = true;
            this.HTTP.shopSaleList(reportId)
                .then((res) => {
                    this.loading = false;
                    this.list = res.data || [];
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";
.sales {
    width: 100%;
    height: 100vh;

    #{&}--view {
        scroll-behavior: smooth;
        position: relative;
        background: #f7f7f7;
        padding: 3.2vw;
    }

    .ranking-box {
        background: #fff;
        border-radius: 1.33vw;
        border: 1px solid #e7e7e7;
        margin-bottom: 2.67vw;
        padding: 2.67vw;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
            margin-bottom: 0;
        }
        .ranking-logo {
            width: 20vw;
            height: 20vw;
            object-fit: cover;
            margin-right: 2.67vw;
            border-radius: 1.07vw;
        }
        .ranking-content {
            flex: 1;
            text-align: left;
            .content-title {
                display: flex;
                align-items: center;
                justify-content: center;
                .title {
                    font-size: 4vw;
                    font-weight: bold;
                    color: #1d2233;
                    line-height: 5.6vw;
                    margin: 0;
                    padding: 0;
                    flex: 1;
                    width: 0;
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                }
                .cataory {
                    font-size: 3.2vw;
                    color: #ff5900;
                    line-height: 3.2vw;
                    padding: 0.53vw 1.6vw;
                    background: #fff1e9;
                    border-radius: 5px;
                    border: 1px solid #ff9f6b;
                }
            }
            p {
                font-size: 3.47vw;
                color: #8e9099;
                line-height: 4vw;
                margin-top: 2.13vw;
            }
            .cata-type {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2.13vw;
                div {
                    flex: 1;
                    font-size: 3.47vw;
                    color: #8e9099;
                    line-height: 4vw;
                }
            }
        }
    }
}
</style>
