<template>
    <div class="all_content">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true" style="border-bottom: 1px solid #EBEAEE;box-sizing: content-box;"></TitleHeader>

        <AutoView ref="view" :header="true" :footer="false" class="all_content--view">
            <van-skeleton :loading="loading" :animate="false" :row="4" class="s-skeleton">
                <div class="all_content--main" v-if="newList">
                    <div class="comments_swrap">
                        <div class="list">
                            <div class="time">
                                <div class="comments_img">
                                    <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="newList.comment.avatar">
                                        <template v-slot:loading>
                                            <van-loading type="spinner" size="20" />
                                        </template>
                                    </van-image>
                                </div>
                                <h3>{{newList.comment.username}}</h3>
                                <p>{{newList.comment.time}}</p>
                            </div>
                            <div class="box" @click="openBrandDetail(newList.cardInfo.brandCode)">
                                <div class="comments_img">
                                    <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="newList.cardInfo.logoUrl">
                                        <template v-slot:loading>
                                            <van-loading type="spinner" size="20" />
                                        </template>
                                    </van-image>
                                </div>
                                <div class="title">
                                    <h3>{{newList.cardInfo.brandName}}</h3>
                                    <p>预估总投资额：<span>{{newList.cardInfo.joinTotalCost}}</span></p>
                                </div>
                                <div class="label_tab" v-show="newList.cardInfo.newCategoryNameL2">{{newList.cardInfo.newCategoryNameL2}}</div>
                            </div>
                            <div class="rate_jx">
                                <div class="left">
                                    <div class="text_rate">
                                        <span>打分</span>
                                        <van-rate readonly v-model="newList.comment.score" gutter="0.53vw" size="3.47vw" void-icon="star" color="#FFCB00" void-color="#D8D8D8" />
                                    </div>
                                    <div class="tabs_box" v-if="newList.comment.tags && newList.comment.tags.length > 0">
                                        <p v-for="(item, index) in newList.comment.tags" :key="index" :class="item == '需谨慎加盟' ? 'noColor': ''">{{item}}</p>
                                    </div>
                                </div>
                                <img class="top_select" v-show="newList.comment.top == 1" src="/static/img/select_top.png" alt="">
                            </div>
                            <p class="text_p">{{newList.comment.content}}</p>
                            <div class="click_com">
                                <div class="click_com_item" @click.stop="giveLike(newList.comment.id, newList.comment.isNice)">
                                    <img v-if="newList.comment.isNice == false" src="/static/icon/zan-4.png" alt="">
                                    <img v-else src="/static/icon/zan2.png" alt="">
                                    <span v-if="newList.comment.nice == 0">赞</span>
                                    <span v-else>{{newList.comment.nice}}</span>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="com_num">
                                <div>
                                    <span class="text">评价</span>
                                    <template v-if="newList.comment.commentCount == null">
                                        <span class="num" >（0）</span>
                                    </template>
                                    <span class="num" v-else>（{{newList.comment.commentCount}}）</span>
                                </div>
                            </div>
                            <div class="input" @click="openComonts">一起讨论下…</div>
                            <template v-if="newList.comment.commentList.length > 0">
                                <!-- 用户回复 user_commonts -->
                                <div class="brand_commonts" :class="val.type == 4 ? '' : 'user_commonts'" v-for="(val, index) in newList.comment.commentList" :key="index">
                                    <div class="brand_commonts_img">
                                        <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="val.avatar">
                                            <template v-slot:loading>
                                                <van-loading type="spinner" size="20" />
                                            </template>
                                        </van-image>
                                    </div>
                                    <div class="brand_commonts_text">
                                        <div class="brand_commonts_title">
                                            <h3>{{val.username}}</h3>
                                            <van-image class="img_pl " @click="twoCommonts(val.id)" fit="cover" lazy-load src="/static/icon/pinglun.png"></van-image>
                                        </div>
                                        <p>{{val.time}}</p>
                                        <div class="text">{{val.content}}</div>
                                        <!-- 二三级评论 v-if="val.commentList.length > 0"-->
                                        <template >
                                            <div class="two_three" v-for="(citem, key) in val.commentList" :key="key">
                                                <div class="brand_commonts_img">
                                                    <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="citem.avatar">
                                                        <template v-slot:loading>
                                                            <van-loading type="spinner" size="20" />
                                                        </template>
                                                    </van-image>
                                                </div>
                                                <div class="brand_commonts_text">
                                                    <div class="brand_commonts_title">
                                                        <h3>{{citem.username}}</h3>
                                                        <van-image class="img_pl " fit="cover" @click="threeCommonts(val.id, citem.username)" lazy-load src="/static/icon/pinglun.png"></van-image>
                                                    </div>
                                                    <p>{{citem.time}}</p>
                                                    <div class="text"><span v-show="citem.toUsername != null">回复 <strong>{{citem.toUsername}}</strong>：</span>{{citem.content}}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <EmptyCard v-else type="community"></EmptyCard>
                        </div>
                    </div>

                </div>
                <!-- 评论区域 -->
                <van-popup
                    v-model="alertShow"
                    position="bottom"
                    closeable
                    close-icon-position="top-left"
                    :style="{ height: '40%', borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }">
                    <div class="popup-wrap">
                        <ul class="ul-flex">
                            <li class="left"></li>
                            <li class="mid"></li>
                            <li class="right">
                                <van-button type="info" :disabled="!brandContent" :loading="commontsLoading" size="mini" @click="submit">提交</van-button>
                            </li>
                        </ul>
                        <van-field
                            class="textarea_input"
                            v-model.trim="brandContent"
                            type="textarea"
                            maxlength="200"
                            show-word-limit
                            placeholder="大家都在问：这个品牌靠不靠谱，什么时间能回本，对这个品牌印象怎么样？"
                        />
                    </div>
                </van-popup>
            </van-skeleton>
        </AutoView>
    </div>
</template>


<script>
export default {
    name: "commontsDetail",
    data() {
        return {
            title: "",
            loading: true,
            newList: null,

            // 弹出框
            alertShow: false,
            brandContent: null,
            commontsLoading: false,

            isType: 0,
            twoParentId: null,
            toUsername: null
        }
    },
    methods: {
        // 跳转详情
        openBrandDetail(code) {
            this.swRouter( '/brand/details/' + code );
        },
        // 点赞
        giveLike(id, bool) {
            let status = '';
            if(bool) {
                status = 0;
            } else {
                status = 1;
            }
            this.HTTP.giveLike(id,status).then(res => {
                this.newList.comment.isNice = !this.newList.comment.isNice;
                if(status == 1) {
                    this.newList.comment.nice = ++this.newList.comment.nice;
                } else {
                    this.newList.comment.nice = --this.newList.comment.nice;
                }
                console.log(res)
            }).catch(err => {
                this.$toast("网络不稳定，请稍后再试！");
            })
        },
        submit() {
            let meth = {
                content: this.brandContent,
                oneParentId: this.newList.comment.id,
                refId: this.newList.cardInfo.id,
                refName: this.newList.cardInfo.brandName,
            };
            let more = {};
            switch (this.isType) {
                case 2: {
                    more = {
                        type: 2,
                    }
                }
                break;
                case 3 : {
                    more = {
                        type: 3,
                        twoParentId: this.twoParentId
                    }
                }
                break;
                case 4 : {
                    more = {
                        type: 3,
                        twoParentId: this.twoParentId,
                        toUsername: this.toUsername
                    }
                }
                break;
            }
            this.HTTP.addCommonts(Object.assign(meth,more)).then(res => {
                this.$toast("评论成功，评审通过后即可展示");
                this.alertShow = false;
                this.commontsLoading = false;
                this.brandContent = null;
            }).catch((err) => {
                // this.$toast("网络不稳定，请稍后再试！");
                this.commontsLoading = false;
            });
        },
        // 二级盖楼
        twoCommonts(id) {
            console.log(id)
            this.isType = 3;
            this.alertShow = true;
            this.twoParentId = id;
        },
        // 三级盖楼
        threeCommonts(id,name) {
            this.isType = 4;
            this.alertShow = true;
            this.twoParentId = id;
            this.toUsername = name;
        },
        openComonts() {
            this.alertShow = true;
            this.isType = 2;
        }
    },
    mounted() {
        this.HTTP.commontsDetail(this.$route.params.id).then(res => {
            this.loading = false;
            this.newList = res.data;
            if(this.$route.query.num == 1) {
                this.alertShow = false;
                this.isType = 0;
            }
            if(this.$route.query.num == 2) {
                this.alertShow = true;
                this.isType = 2;
            }
        }).catch((e) => {
            this.$toast("网络连接不稳定，请稍候重试！");
        });
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.all_content{
    height: 100vh;
    overflow: hidden;
    background: #fff;
    line-height: normal;
    #{&}--main{
       width: 100%;
       height: auto;
        .comments_swrap{
            width: 100%;
            height: auto;
            .list{
                width: 100%;
                height: auto;
                background: #fff;
                padding: 4vw;
                box-sizing: border-box;
                .time{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .comments_img{
                        width: 9.87vw;
                        height: 9.87vw;
                        border-radius: 9.87vw;
                        margin-right: 1.33vw;
                        overflow: hidden;
                        ::v-deep .s-radius__xs{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    h3{
                        flex: 1;
                        font-size: 3.73vw;
                        font-weight: bold;
                        color: #333333;
                    }
                    p{
                        font-size: 2.93vw;
                        color: #999999;
                    }
                }
                .box{
                    width: 100%;
                    height: auto;
                    margin-top: 2.67vw;
                    padding: 3.33vw;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    box-shadow: 0vw 0vw 2.27vw 0vw rgba(0, 0, 0, 0.06);
                    border-radius: 1.33vw;
                    border: 1px solid #E7E7E7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .comments_img{
                        width: 13.33vw;
                        height: 13.33vw;
                        background: #DBDBDB;
                        border-radius: 0.67vw;
                        margin-right: 2.13vw;
                        ::v-deep .s-radius__xs{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title{
                        flex: 1;
                        h3{
                            font-size: 4vw;
                            font-weight: bold;
                            color: #1D2233;
                            line-height: 5.6vw;
                        }
                        p{
                            font-size: 3.47vw;
                            color: #999999;
                            line-height: 4.93vw;
                            margin-top: 2.13vw;
                            span{
                                color: #666666;
                            }
                        }
                    }
                    .label_tab{
                        width: auto;
                        line-height: normal;
                        // background: #FFF1E9;
                        border-radius: 0.67vw;
                        border: 1px solid #FF9F6B;
                        padding: 1px 1.6vw;
                        font-size: 3.2vw;
                        color: #FF5900;
                    }
                }
                .rate_jx{
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    margin-top: 3.33vw;
                    position: relative;
                    .left{
                        flex: 1;
                        .text_rate{
                            width: 100%;
                            height: auto;
                            font-size: 0;
                            display: flex;
                            align-items: center;
                            span{
                                font-size: 2.93vw;
                                color: #333333;
                                margin-right: 0.8vw;
                                font-weight: bold;
                                line-height: 1;
                            }
                            // ::v-deep .van-rate--readonly{
                            //     position: relative;
                            //     top: -0.07vw;
                            // }
                        }
                        .tabs_box{
                            margin: 2.13vw 0 0 0;
                            font-size: 0;
                            overflow-y: auto;
                            white-space: nowrap;
                            line-height: normal;
                            p{
                                display: inline-block;
                                // background: #FFF1E9;
                                border-radius: 0.67vw;
                                line-height: normal;
                                border: 1px solid #FF9F6B;
                                padding: 0.8vw 2.27vw;
                                font-size: 2.93vw;
                                color: #FF5900;
                                margin-right: 1.33vw;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            .noColor{
                                border: 1px solid #D8D9DF;
                                color: #999999;
                                background: transparent;
                            }
                        }
                    }
                    .top_select{
                        width: 10.8vw;
                        height: 8.93vw;
                        position: absolute;
                        top: 1.87vw;
                        right: 0;
                        z-index: 0;
                    }
                }
                .text_p{
                    margin-top: 2.67vw;
                    font-size: 3.47vw;
                    color: #333333;
                    line-height: 4.93vw;
                }
                .click_com{
                    width: 100%;
                    height: auto;
                    text-align: right;
                    font-size: 0;
                    margin-top: 2.27vw;
                    .click_com_item{
                        display: flex;
                        align-items: center;
                        line-height: normal;
                        width: 20vw;
                        img {
                            width: 4vw;
                            height: 4vw;
                            vertical-align: middle;
                            margin-right: 1.33vw;
                        }
                        span{
                            font-size: 3.2vw;
                            color: #666666;
                            line-height: normal;
                            vertical-align: middle;
                        }
                    }
                    .click_com_last{
                        margin-left: 7.47vw;
                    }
                }
                .line{
                    margin-top: 4vw;
                    height: 1px;
                    width: 100%;
                    background: #E7E7E7;
                }
                .com_num{
                    margin-top: 4vw;
                    width: 100%;
                    height: auto;
                    div{
                        line-height: 1;
                        font-size: 0;
                        .text{
                            font-size: 4.53vw;
                            font-weight: bold;
                            color: #1D2233;
                            line-height: normal;
                            display: inline-block;
                            line-height: 6.4vw;
                        }
                        .num{
                            font-size: 3.73vw;
                            color: #999999;
                        }
                    }
                }
                .input{
                    margin-top: 2.67vw;
                    width: 100%;
                    background: #F7F7F7;
                    border-radius: 1.33vw;
                    border: 1px solid #E7E7E7;
                    padding: 2.67vw 3.73vw;
                    box-sizing: border-box;
                    line-height: normal;
                    font-size: 3.73vw;
                    color: #999999;
                    // line-height: 5.33vw;
                }
                .brand_commonts{
                    width: 100%;
                    height: auto;
                    background: #F7F7F7;
                    border-radius: 0.8vw;
                    padding: 3.33vw;
                    margin-top: 4vw;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    .brand_commonts_img{
                        width: 9.07vw;
                        margin-right: 1.33vw;
                        border-radius: 9.07vw;
                        overflow: hidden;
                        height: 9.07vw;
                        ::v-deep .s-radius__xs{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .brand_commonts_text{
                        flex: 1;
                        .brand_commonts_title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h3{
                                flex: 1;
                                font-size: 3.47vw;
                                font-weight: bold;
                                color: #333333;
                                line-height: 4.93vw;
                            }
                            .img_pl{
                                width: 4vw;
                                height: 4vw;
                            }
                        }
                        p{
                            margin-top: 0.27vw;
                            font-size: 2.93vw;
                            color: #999999;
                            line-height: 4vw;
                        }
                        .text{
                            margin-top: 1.33vw;
                            font-size: 3.47vw;
                            color: #333333;
                            line-height: 4.93vw;
                        }
                        // 二三级评论
                        .two_three{
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            height: auto;
                            margin-top: 4vw;
                        }
                    }
                }
                .user_commonts{
                    padding: 0;
                    position: relative;
                    background: #fff;
                    padding-bottom: 4vw;
                    &::after{
                        content: "";
                        position: absolute;
                        width: calc(100% - 10.4vw);
                        right: 0;
                        height: 1px;
                        background: #E7E7E7;
                        bottom: 0;
                    }
                    &:last-child::after{
                        height: 0px
                    }
                }
            }
        }
    }

    .popup-wrap {
        width: 100%;
        .ul-flex {
            display: flex;
            flex-flow: row nowrap;
            padding-right: 5.33vw;
            .left {
                flex: 0 0 10.13vw;
            }
            .mid {
                flex: 1;
                font-size: 3.73vw;
                color: #000;
                font-weight: bold;
                margin-top: 4.8vw;
                text-align: center;
            }
            .right {
                flex: 0 0 15.47vw;
                margin-top: 1.6vw;
                text-align: right;
            }
        }
        .textarea_input{
            width: calc(100% - 10.66vw);
            margin: 5.33vw auto;
            height: 51vw;
            background: #F7F7F7;
            border-radius: 1.07vw;
            padding: 2.67vw 4vw;
            box-sizing: border-box;
            font-size: 3.73vw;
            color: #999999;
            line-height: 5.33vw;
            ::v-deep .van-field__body{
                height: calc(100% - 4vw - 2.67vw);
            }
            ::v-deep .van-field__control{
                height: 100%;
            }
            ::v-deep .van-field__word-limit{
                font-size: 2.93vw;
                color: #999999;
                line-height: 4vw;
            }
        }
    }
}
</style>
