<template>
    <div class="coming">
        <StateBar></StateBar>
        <TitleHeader title="功能建设中" color="#518CDB" :back="true" class="s-line__b"></TitleHeader>

        <AutoView :header="true" :footer="false" class="coming--view">
            <div class="f-vp">
                <van-image class="icon"
                    fit="cover"
                    :src="img" />
            </div>
        </AutoView>
    </div>
</template>

<script>
export default {
    name      : 'coming',
    components: {

    },
    data () {
        return {
            img: '/static/img/default_cover.jpg',
        };
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.coming {
    height: 100vh;
}
</style>
