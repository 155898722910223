<template>
  <div class="map-container">
    <div id="base-map"></div>
    <van-button class="relocate-btn icon-btn s-radius__s" @click="relocate">
      <Icon name="aim" :size="5.07"></Icon>
    </van-button>
  </div>
</template>

<script>
import LoadMap from '@/components/baseMap/loadMap'
import Icon from '@/components/Icon/Icon';

let mapIns = undefined
export default {
  name: 'base-map',
  components: { Icon },
  data() {
    return {
      center: {},
      locaInfo: {
        lng: '',
        lat: ''
      },
      isUpdate: false,
      mI: undefined,
      cI: undefined
    }
  },
  mounted () {
    if (this.$route.query.lng && this.$route.query.lat) {
      this.locaInfo.lng = this.$route.query.lng
      this.locaInfo.lat = this.$route.query.lat
    } else {
      if (window.isMini) {
        if (window.mlg && window.mlt) {
          this.locaInfo.lng = window.mlg
          this.locaInfo.lat = window.mlt
        }
      }
    }
    this.initMap()
  },
  methods: {
    initMap() {
      console.log('this.locaInfo',this.locaInfo)
      LoadMap().then(res => {
        mapIns = new AMap.Map("base-map", {
          zoomEnable: true,
          dragEnable: true,
          zoom: 14, //初始化地图层级
          center: (this.locaInfo.lng && this.locaInfo.lat) ? [this.locaInfo.lng,this.locaInfo.lat] : undefined
          // center: that.goodsInfo.lnglat, //初始化地图中心点
        });
        mapIns.on( 'complete', this.mapCompleteListener );
        // mapIns.on( 'mapmove', this.mapMoveListener  );
        mapIns.on( 'moveend', this.mapMoveendListener );
      })
    },
    mapCompleteListener() {
      this.center =  mapIns.getCenter()
      console.log(this.center)
      this.locaInfo.lng = this.center.lng
      this.locaInfo.lat = this.center.lat
      this.addMarker()
      this.addCircle()
      this.$emit('updateLocation', { lng: this.center.lng, lat: this.center.lat })
    },
    mapMoveListener() {},
    mapMoveendListener(e) {
      console.log('move end',e)
      if (this.isUpdate) {
        this.isUpdate = false
        return
      }
      this.center =  mapIns.getCenter()
      this.addMarker()
      this.addCircle()
      this.$emit('updateLocation', { lng: this.center.lng, lat: this.center.lat })
    },
    addMarker(lnglat) {
      if (!lnglat) {
        lnglat = this.center
      }
      if (this.mI) {
        this.mI.setPosition(lnglat)
      } else {
        this.mI = new AMap.Marker( {
          icon     : new AMap.Icon({
            image    : '/static/shopEcology/report/map_icon_needle.png',
            imageSize: new AMap.Size(28, 36),
            size: new AMap.Size(28, 36)
          }),
          position: lnglat, // [lng, lat],
          offset: new AMap.Pixel(-14, -18),
          zIndex   : 1,
          clickable: false
        } );
        this.mI.setMap( mapIns );
      }
    },
    addCircle(lnglat) {
      if (!lnglat) {
        lnglat = this.center
      }
      if (this.cI) {
        this.cI.setCenter(lnglat)
      } else {
        this.cI = new AMap.Circle( {
          center       : lnglat,
          radius       : 1000, // 半径 米
          borderWeight : 1,
          strokeColor  : '#518CDB',
          strokeWeight : 2,
          strokeOpacity: 0.17,
          strokeStyle  : 'solid',
          fillOpacity  : 0.3,
          // 线样式还支持 'dashed'
          fillColor    : '#518CDB',
          bubble       : true,
          zIndex       : 2
        } );
        this.cI.setMap( mapIns );
      }
    },
    updateMapCenter(lng,lat) {
      this.isUpdate = true
      mapIns.setCenter([lng,lat])
      this.addMarker([lng,lat])
      this.addCircle([lng,lat])
    },
    relocate() {
      this.updateMapCenter(this.locaInfo.lng,this.locaInfo.lat)
      this.$emit('updateLocation', { lng: this.locaInfo.lng, lat: this.locaInfo.lat })
    }
  }
};
</script>

<style scoped lang="scss">
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  #base-map {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .relocate-btn {
    position: absolute;
    left: 10px;
    bottom: 30px;
    z-index: 999;
  }
}
</style>
