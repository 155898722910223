<script>
import '/public/cdn/js/ct4.js'
import { Toast } from 'vant';
import { commonConfigObj } from '@/constant'
import store from '@/store';
const whileApiList = ['/v6/report/full']
let captchaInstance = undefined
  export default {
    data() {
      return {

      }
    },
    mounted() {
      // console.log('Request',http.request())
      this.initAliCaptcha()
    },
    beforeDestroy () {
      captchaInstance?.destroy()
      captchaInstance = null
    },
    methods: {
      initAliCaptcha() {
        const that = this
        initAlicom4({
          captchaId: "36adeb216e6283e70598b7681cac4235",
          product: 'bind'
        },function (captcha) {
          captchaInstance = captcha
          // captcha为验证码实例
          captcha.onReady(function(){
            captcha.showCaptcha();
          }).onSuccess(function(){

            const result = captcha.getValidate();
            console.log(result)
            if (result) {
              const apiConfigList = that.$store.getters.apiConfigList || []
              if (!apiConfigList.length) {
                captcha.destroy()
                captcha = null
                return
              }
              const firstApi = apiConfigList[0]
              const apiItem = whileApiList.find(e => firstApi.url.indexOf(e) > -1)
              that.HTTP.captchaValidate({
                apiUrl: '/app' + firstApi.url,
                param: firstApi.data,
                deviceType: 3,
                version: commonConfigObj.version_name,
                captchaOutput: result.captcha_output,
                genTime: result.gen_time,
                lotNumber: result.lot_number,
                passToken: result.pass_token,
                url: apiItem ? '/app' + apiItem : undefined
              }).then(res => {
                if (res.data) {
                  captcha.destroy()
                  captcha = null
                  that.$store.commit('setApiValidate', false)
                  that.$store.commit('setApiConfigList', null)
                }
              }).catch(() => {
                captcha.reset();
                captcha.showCaptcha();
              })
            } else {
              Toast('验证失败，请重新验证！')
              captcha.reset();
              captcha.showCaptcha();
            }

          }).onError(function(){
            //your code
          })
          captcha.onClose(function () {
            console.log('關閉')
            captcha.destroy()
            captcha = null
            that.$store.commit('setApiConfigList', null)
            that.$store.commit('setValidatePopup', true)
          })
        });
      }
    }
  }
</script>

<template>
  <div class="alicaptcha-wrap"></div>
</template>

<style lang="scss">
.captcha4_feedback, .captcha4_popup_ghost, .captcha4_box_logo {
  display: none !important;
}
.alicaptcha-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  z-index: 10000;
}
</style>
