<!--
Name:
    套餐包信息卡片
Props:
    item        : (必填)数据对象
    mode        : 展示模式 normal:普通(默认) base:基础 card:卡片 user:用户
    showCheck   : 是否展示单选
    checked     : 是否选中
    status      : 展示状态 1(默认)
Events:
    tap     : 点击控件主体
    check   : 点击详情按钮
-->

<template>
  <div class="package" :class="[`st-${status}`, mode, { chkmod: showCheck, on: showCheck && checked }]" @click="onTap">
    <h3 class="title f-tof">{{ item.stdQuestionName || "{TITLE}" }}</h3>

    <!-- 基础样式 -->
    <template v-if="mode === 'base'">
      <h4 class="subTitle f-tof">使用{{ item.stdQuestionName }}{{ item.exchangeTimes }}次</h4>
    </template>
    <!-- 卡片样式 -->
    <template v-else-if="mode === 'card'">
      <span class="tips">本次可直接抵扣</span>
      <h4 v-if="item.remark" class="subTitle f-tof">{{ item.remark }}</h4>
      <h4 v-else class="subTitle f-tof">使用{{ item.stdQuestionName }}{{ item.exchangeTimes }}次</h4>
      <p class="price"><sub>￥</sub>{{ item.price || "?" }}</p>
      <a href="javascript:;" class="rule" @click.stop="onDetails">套餐详情</a>
    </template>
    <!-- 用户样式 -->
    <template v-else-if="mode === 'user'">
      <!-- <p class="datetime f-tof">有效期 {{ item.expireStartTime | convDate }} - {{ item.expireEndTime | convDate }}</p> -->
      <p class="num normal">剩余{{ item.restExchangeTimes || "?" }}次</p>
      <a href="javascript:;" class="rule" @click.stop="showRule = true">使用规则</a>
    </template>
    <!-- 默认样式 -->
    <template v-else>
      <p class="datetime f-tof">有效期 {{ item.expireStartTime | convDate }} - {{ item.expireEndTime | convDate }}</p>
      <p v-if="status === 2" class="num">{{ item.exchangeTimes - item.restExchangeTimes || "?" }}<sub>次</sub></p>
      <p v-else-if="item.restExchangeTimes==-1" class="num"><span>无限次</span></p>
      <p v-else class="num">{{ item.restExchangeTimes || "?" }}<sub>次</sub></p>
      <p class="marker">{{ stateText }}</p>
      <hr class="van-divider" />
      <!-- 10002 包君成定制服务 -->
      <van-button type="warning" round class="btn" @click="onBtnTap" v-if="item.stdQuestionId != 10002">去使用</van-button>
      <div class="info" :class="{ on: showMore }">
        <p class="more" @click.stop="showMore = !showMore">使用规则</p>
        <div class="content" v-html="convRule(item.usageRule || '无')"></div>
      </div>
    </template>

    <!-- 选中按钮 -->
    <span v-if="showCheck" class="checked-item" :class="{ checked: showCheck && checked }"></span>

    <!-- 规则说明 -->
    <van-dialog v-model="showRule" confirmButtonText="知道了" get-container="#app">
      <h1>使用规则</h1>
      <div class="content">
        <p v-html="convRule(item.usageRule || '无')"></p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "package",
  props: {
    mode: {
      type: String,
      default: "normal",
    },
    item: Object,
    status: {
      type: Number,
      default: 1,
    },
    checked: Boolean,
    showCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: false,
      showRule: false,
    };
  },
  filters: {
    convDate(value) {
      if (!value) return "---";
      return value.split(" ")[0].split("-").join(".");
    },
  },
  methods: {
    onTap() {
      this.$emit("selected", this.item);
    },
    onBtnTap() {
      if (!window.isClient) {
        this.$toast("非客户端环境！");
      } else {
          if(this.item.type == 2) {
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "CHANGE_MAIN_TAB",
                id: "home",
                params: null,
                trackData: null,
                isClear: true,
            });
          } else {
            switch (this.item.stdQuestionId) {
                case 10010: {
                    // 查周边
                    if (window._appCode && window._appCode >= 205) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SEARCH_SURROUNDING_V2",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SEARCH_SURROUNDING",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    }
                    break;
                }

                case 10012: {
                    this.$router.push("/introduce/10012");
                    break;
                }
                // 点位雷达
                case 10102: {
                    if (window._appCode && window._appCode >= 308) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "POINT_RADAR",
                            id: null,
                            params: null
                        });
                    } else {
                        this.$toast.fail('版本过低，请升级版本')
                    }
                    break;
                }

                case 10014: {
                    const tempStdId = 10010;
                    if (window._appCode && window._appCode >= 200) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "PICK_LOCATION",
                            id: tempStdId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("gotoPage", "card_" + tempStdId);
                    }
                    break;
                }
                case 10092:
                case 10001: {
                    // AI智能选址
                    if(window._appCode && window._appCode >= 300) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SKU_FILL_INFO_PAGE",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else if (window._appCode && window._appCode >= 207) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "CONDITIONS_INPUT",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else if (window._appCode && window._appCode >= 200) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "PICK_LOCATION",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("gotoPage", "card_" + this.item.stdQuestionId);
                    }
                    break;
                }
                case 10084: {
                    if(window._appCode && window._appCode >= 302) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SKU_FILL_INFO_PAGE",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    }
                    break;
                }
                case 10006: {
                    // 竞争
                    if(window._appCode && window._appCode >= 300) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "PICK_LOCATION",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else if (window._appCode && window._appCode >= 205) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            // url: "SEARCH_SURROUNDING_V2",
                            url: "BRAND_INTRODUCTION",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else if (window._appCode && window._appCode >= 200) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "PICK_LOCATION",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("gotoPage", "card_" + this.item.stdQuestionId);
                    }
                    break;
                }
                case 10005:
                case 10011: {
                    // 客群、竞争、商圈
                    if (window._appCode && window._appCode >= 205) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SEARCH_SURROUNDING_V2",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else if (window._appCode && window._appCode >= 200) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "PICK_LOCATION",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("gotoPage", "card_" + this.item.stdQuestionId);
                    }
                    break;
                }

                case 10018:
                case 10019: {
                    // 热力图
                    if (window._appCode && window._appCode >= 205) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SEARCH_SURROUNDING_V2",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "HEAT_MAP_CUSTOMIZATION_V2",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    }
                    break;
                }

                case 10026: {
                    // AI店铺选址
                    if(window._appCode && window._appCode >= 300) {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "SKU_FILL_INFO_PAGE",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    } else {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "CONDITIONS_INPUT",
                            id: this.item.stdQuestionId,
                            params: null,
                        });
                    }

                    break;
                }
                // ai效果图
                case 10119:
                  this.swRouter('/h5/#/design/input-base-info?tab=1')
                  break;
                case 10130:
                  this.swRouter('/h5/#/design/input-base-info')
                  break;

                default:
                    this.JSBridge.callHandler("gotoPage", "card_" + this.item.stdQuestionId);
                    break;
                }
            }
      }
    },
    onDetails() {
      this.$emit("check", this.item);
    },
    convRule(value) {
      return value ? value.replace(/\r\n|\n/g, "<br>") : null;
    },
  },
  computed: {
    stateText() {
      const mppping = { 1: "还可使用", 2: "已使用", 3: "未使用" };
      return mppping[this.status] || "---";
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

$xMargin: 5.2vw;
$yMargin: 4.67vw;
$compColor: #89572c;

.package {
  @include ani;
  position: relative;
  overflow: hidden; // @@@
  width: 100%;
  min-height: 32vw;
  background: url(/static/img/bg_package.jpg) no-repeat, linear-gradient(to right, #feeac7, #ffdbb1);
  background-size: 100% auto;
  border-radius: $radius_xs;
  padding: $yMargin $xMargin;
  box-sizing: border-box;
  color: $compColor;

  &.on {
    box-shadow: inset 0 0 0 1px #b27439;
    // &:after {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 6.8vw;
    //     height: 6.8vw;
    //     background: url(/static/icon/checked_tr_brown@64.png) no-repeat;
    //     background-size: contain;
    // }
  }

  &.chkmod {
    .price,
    .rule {
      right: $xMargin * 2.25;
    }
    .num.normal {
      top: 12.3vw;
      left: $xMargin;
      right: auto;
      text-align: left;
      line-height: normal;
    }
  }

  &.base {
    min-height: 20vw;
    background: url(/static/img/bg_package_base.jpg) no-repeat, linear-gradient(to right, #feeac7, #ffdbb1);
    background-size: cover;

    .title {
      font-size: $font_453;
    }
  }
  &.card,
  &.user {
    min-height: 20vw;
    background: url(/static/img/bg_package_base.jpg) no-repeat center center, linear-gradient(to right, #feeac7, #ffdbb1);
    background-size: cover;

    .title,
    .subTitle {
      width: 55%;
    }
  }
  &.card {
    .title,
    .price {
      top: 7vw;
    }
    .subTitle,
    .rule {
      top: 13vw;
    }
  }

  &.st-2,
  &.st-3 {
    background-color: white;
    color: #8e9099;
    .btn {
      display: none;
    }
    .info {
      .more {
        &:after {
          background-image: url(/static/icon/arrow_down_gray.png);
        }
      }
    }
  }
  &.st-2 {
    background-image: url(/static/img/bg_package_st2.jpg);
  }
  &.st-3 {
    background-image: url(/static/img/bg_package_st3.jpg);
  }

  .tips {
    @include flexColumn;
    position: absolute;
    top: 0;
    left: 0;
    width: 26vw;
    height: 4.8vw;
    background-color: #b27439;
    color: white;
    font-size: $font_293;
    border-radius: $radius_xs 0 $radius_xs 0;
  }
  .title {
    position: absolute;
    top: $yMargin;
    left: $xMargin;
    width: 48vw;
    @include font(inherit, $font_400, bold);
    line-height: $font_560;
  }
  .datetime {
    position: absolute;
    top: 12.3vw;
    left: $xMargin;
    width: 48vw;
    @include font(inherit, $font_320, normal);
    line-height: $font_400;
  }
  .subTitle {
    position: absolute;
    top: 12.3vw;
    left: $xMargin;
    width: 48vw;
    @include font(inherit, $font_320, normal);
    line-height: $font_400;
  }
  .price {
    position: absolute;
    top: $yMargin;
    right: $xMargin;
    width: 13vw;
    color: inherit;
    text-align: center;
    font-size: 4.8vw;
    font-weight: bold;

    sub {
      font-size: 2.93vw;
      vertical-align: bottom;
    }
  }
  .num {
    position: absolute;
    top: $yMargin;
    right: 5.87vw;
    width: 20vw;
    color: inherit;
    text-align: right;
    font-size: 6.4vw;
    font-weight: bold;

    sup {
      font-size: $font_320;
      vertical-align: top;
    }
    sub {
      font-size: $font_320;
      vertical-align: bottom;
    }
    span {
      font-size: $font_320;
      vertical-align: middle;
    }
    &.normal {
      width: 50%;
      font-size: $font_320;
      line-height: $padding;
      font-weight: normal;
      text-align: right;
    }
  }
  .rule {
    @include flexColumn;
    position: absolute;
    top: 12vw;
    right: $xMargin;
    width: 14vw;
    height: 4vw;
    color: inherit;
    font-size: $font_320;
    // font-weight: bold;
    line-height: normal;
    // box-shadow: inset 0 0 0 1px $compColor;
    // border-radius: 999px;
    padding-right: 3vw;
    background: url(/static/icon/arrow_right_brown.png) no-repeat right -0.8vw center;
    background-size: contain;
  }
  .marker {
    position: absolute;
    top: 12.93vw;
    right: 6.5vw;
    width: 14vw;
    text-align: right;
    @include font(inherit, $font_320, bold);
  }
  .van-divider {
    border-color: inherit;
    margin-top: 16.8vw;
    opacity: 0.1;
  }
  .btn {
    position: absolute;
    top: 23.07vw;
    right: $xMargin;
    width: 15.73vw;
    height: 6.67vw;
    border-radius: 999px;
    padding: 0;
    font-size: 2.93vw;
    line-height: normal;
    background: linear-gradient(to bottom, #fc6f59, #f77457);
  }
  .info {
    @include font(inherit, $font_320, normal);

    .more {
      @include ani;
      position: relative;
      display: inline-block;
      width: auto;
      height: 4vw;
      padding-right: 5vw;
      line-height: normal;
      margin-bottom: 0;
      cursor: pointer;

      &:after {
        @include ani;
        content: "";
        position: absolute;
        right: 0;
        width: 4vw;
        height: 4vw;
        background: url(/static/icon/arrow_down_brown.png) no-repeat center center;
        background-size: cover;
      }
    }
    .content {
      @include ani;
      overflow: hidden;
      max-height: 0;
      line-height: $font_440;
    }

    &.on {
      .more {
        margin-bottom: $margin * 1.5;

        &:after {
          transform: rotate(180deg);
        }
      }
      .content {
        max-height: 999px;
      }
    }
  }

  .checked-item {
    position: absolute;
    top: 50%;
    right: $padding_3;
    width: 4vw;
    height: 4vw;
    box-shadow: inset 0 0 0 2px #936c12;
    border-radius: 50%;
    background-color: white;
    transform: translateY(-50%);
    box-sizing: border-box;

    &.checked {
      background: url(/static/icon/success.png) no-repeat center center;
      background-size: cover;
      box-shadow: none;
      border: none;
    }
  }
}

::v-deep .van-dialog__content {
  background: url(/static/img/vip_rule.jpg) no-repeat center top;
  background-size: contain;
  padding: $padding;
  h1 {
    font-size: 6.4vw;
    text-align: center;
    padding: 8vw 0;
  }
  .content {
    margin-top: $padding;
    p {
      color: $c-info;
      font-size: $font_347;
      line-height: $font_587;
      margin-top: $margin;
    }
    strong {
      color: $c-main;
    }
  }
}
</style>
