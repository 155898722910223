<template>
  <div class="setting">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>

    <AutoView ref="view" :header="true" :footer="false" class="setting--view">
      <van-skeleton :loading="loading" :animate="false" :row="2" class="s-skeleton s-radius__xs">
        <ul class="setting--main">
          <li class="left">
            <p class="title">{{ cTitle[msgType] }}</p>
            <p class="explain">{{ cContent[msgType] }}</p>
          </li>
          <li class="right">
            <van-switch v-model="checked" size="24px" inactive-color="#e7e7e7" @change="checkChange" />
          </li>
        </ul>
      </van-skeleton>
    </AutoView>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      title: "消息提醒设置",
      checked: true,
      cTitle: {
        "0": "活动通知提醒",
        "1": "报告通知提醒",
        "3": "活动通知提醒",
        "4": "活动通知提醒",
        "5": "活动通知提醒",
        "6": "活动通知提醒",
        "7": "活动通知提醒",
        "8": "活动通知提醒",
      },
      cContent: {
        "0": "该对象若有活动或促销信息，将发送手机系统通知",
        "1": "该对象若有报告生成，将发送手机系统通知",
        "3": "该对象若有活动或促销信息，将发送手机系统通知",
        "4": "该对象若有活动或促销信息，将发送手机系统通知",
        "5": "该对象若有活动或促销信息，将发送手机系统通知",
        "6": "该对象若有活动或促销信息，将发送手机系统通知",
        "7": "该对象若有活动或促销信息，将发送手机系统通知",
        "8": "该对象若有活动或促销信息，将发送手机系统通知"
      },
      msgType: ""
    };
  },
  created() {
    // msgType
    this.msgType = this.$route.query.msgType;
    if (!this.msgType || !["0", "1", "3", "4", "5", "6", "7","8"].includes(this.msgType)) {
      this.$router.push("/");
      return;
    }
    this.HTTP.getSettingByType({ type: this.msgType }).then(res => {
      console.log(res);
      this.checked = res.data;
      this.loading = false;
    });
  },
  methods: {
    checkChange() {
      this.HTTP.updateSetting({
        status: this.checked ? 1 : 0, // 0关 1开
        type: this.msgType
      })
        .then(res => {})
        .catch(e => {
          this.$toast("网络不稳定，正在重试！");
          this.timer = setTimeout(() => {
            location.reload();
          }, 2500);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.setting {
  height: 100vh;
  overflow: hidden;

  #{&}--view {
    scroll-behavior: smooth;
    background: #f7f7f7;
    padding-top: 1px;
  }

  #{&}--main {
    display: flex;
    align-items: center;
    padding: 4vw;
    background: #fff;
    .left {
      flex: 1;
      .title {
        font-size: 3.73vw;
        color: #333;
        font-weight: bold;
      }
      .explain {
        font-size: 3.2vw;
        color: #999;
        margin-top: 2.13vw;
      }
    }
    .right {
      flex: 0 0 21.33vw;
      text-align: center;
    }
  }
}
</style>
