<template>
    <div class="dialog-wrap">
        <div class="dialog-content">
            <van-icon class="close-icon" name="cross" />
            <p class="title">免费领取</p>
<!--            <div class="form-wrap">-->
<!--                <div class="input-wrap">-->
<!--                    <input placeholder="请输入手机号"/>-->
<!--                </div>-->
<!--                <div class="input-wrap">-->
<!--                    <input placeholder="请输入验证码"/>-->
<!--                    <button>发送验证码</button>-->
<!--                </div>-->
<!--                <div class="btn-wrap">-->
<!--                    <button>免费领取</button>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'index'
};
</script>

<style lang="scss" scoped>
.dialog-wrap{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: vw(28);
    background: rgba(0,0,0,0.3);
    *{
        font-size: vw(28);
    }
    .dialog-content{
        width: vw(670);
        background: #fff;
        border-radius: vw(20);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 vw(30);
        .close-icon{
            position: absolute;
            top: vw(20);
            right: vw(20);
            font-weight: bold;
        }
        .title{
            padding: vw(30);
            font-size: vw(34);
            font-weight: 600;
            color: #333333;
            line-height: vw(48);
            text-align: center;
        }
        .input-wrap{
            height: vw(100);
            background: #F7F7F7;
            border-radius: vw(10);
            border: 1px solid #EBEAEE;
            margin-bottom: vw(20);
            input{
                height: 100%;
                width: 100%;
                background: none;
                padding: vw(30);
            }
        }
        .btn-wrap{
            padding: vw(14) 0 vw(34) 0;
            button{
                width: 100%;
                height: vw(90);
                background: linear-gradient(90deg, #FF5900 0%, #FF8000 100%);
                border-radius: vw(45);
                font-size: vw(30);
                font-weight: 500;
                color: #FFFFFF;
                line-height: 42px;
                text-align: center;
            }
        }
    }
}
</style>