<template>
  <div class="business-map-container">
    <StateBar ref="stateBar"></StateBar>
    <TitleHeader :title="`${id ? '编辑地图' : '创建地图'}`" bgcolor="transparent" color="black" :back="true" />
    <AutoView :header="true" :footer="true" class="view">
      <van-skeleton title :loading="loading" :animate="false" :row="3" avatar class="s-skeleton s-radius__s">
        <div class="map-form">
          <div>
            <div class="form-title">1、自定义地图名称</div>
            <div class="form-input">
              <input placeholder="请输入自定义地图名称" maxlength="6" v-model="mapName" />
              <span class="count-label">{{ mapName.length }}/6</span>
            </div>
          </div>
          <div class="form-part2">
            <div class="form-title">2、需地图所需的数据项</div>
<!--            热力-->
            <div class="form-data-item">
              <div class="form-data-item-header" @click="showHot=!showHot">
                <div class="form-data-item-header-left">热力</div>
                <img class="arrow-icon" :class="{'arrow-icon-active': !showHot}" src="/static/business-map/arrow-icon.png" alt="" />
              </div>
              <div class="form-data-list" v-show="showHot">
                <div class="form-data-list-item" v-for="item in hotList" :key="item.code">
                  <div class="form-data-list-item-left">
                    <img class="item-icon" src="/static/business-map/hot.png" alt="" />
                    {{ item.text }}
                    <img v-if="item.needVip" class="vip-icon" src="/static/business-map/vip2.png" alt="">
                  </div>
                  <van-switch v-model="item.checked" :disabled="!isVip && item.needVip" size="24px" active-color="#347fff" inactive-color="#e7e7e7" @click="selectHot(item)" />
                </div>
              </div>
            </div>
<!--            铺位-->
            <div class="form-data-item">
              <div class="form-data-item-header" @click="showShop=!showShop">
                <div class="form-data-item-header-left">铺位数据</div>
                <img class="arrow-icon" :class="{'arrow-icon-active': !showShop}" src="/static/business-map/arrow-icon.png" alt="" />
              </div>
              <div class="form-data-list" v-show="showShop">
                <div class="form-data-list-item" v-for="item in berth" :key="item.code">
                  <div class="form-data-list-item-left">
                    <img class="item-icon" :src="item.icon" alt="" />
                    {{ item.text }}
                  </div>
                  <van-switch v-model="item.checked" size="24px" active-color="#347fff" inactive-color="#e7e7e7" />
                </div>
              </div>
            </div>
<!--            品牌-->
            <div class="form-data-item">
              <div class="form-data-item-header" @click="showBrand=!showBrand">
                <div class="form-data-item-header-left">品牌数据<img class="vip-icon" src="/static/business-map/vip2.png" alt=""></div>
                <img class="arrow-icon" :class="{'arrow-icon-active': !showBrand}" src="/static/business-map/arrow-icon.png" alt="" />
              </div>
              <div class="form-data-list" v-show="showBrand">
                <div class="form-data-list-item">
                  <div class="form-data-list-item-left" @click="selectBrand">
                    <img class="item-icon" src="/static/business-map/brand.png" alt="" />
                    <div class="brand-input">
                      <div class="brand-input-box">
                        <span v-if="!brandResult" class="input-default-text">请选择品牌</span>
                        <span v-else>{{ brandResult }}</span>
                      </div>
                      <img class="arrow-icon2" src="/static/business-map/arrow-icon.png" alt="" />
                    </div>
                  </div>
                  <van-switch v-model="brandChecked" size="24px" active-color="#347fff" inactive-color="#e7e7e7" :disabled="!isVip" @click="selectBrand3" @change="selectBrand2" />
                </div>
              </div>
            </div>
<!--            业态-->
            <div class="form-data-item">
              <div class="form-data-item-header" @click="showFormat=!showFormat">
                <div class="form-data-item-header-left">业态数据</div>
                <img class="arrow-icon" :class="{'arrow-icon-active': !showFormat}" src="/static/business-map/arrow-icon.png" alt="" />
              </div>
              <div class="form-data-list" v-show="showFormat">
                <div class="form-data-list-item" v-for="item in formatData" :key="item.code">
                  <div class="form-data-list-item-left">
                    <img class="item-icon" :src="item.icon" alt="" />
                    {{ item.text }}
                  </div>
                  <van-switch v-model="item.checked" size="24px" active-color="#347fff" inactive-color="#e7e7e7" />
                </div>
              </div>
            </div>
<!--            自我数据-->
            <div class="form-data-item">
              <div class="form-data-item-header" @click="showOwn=!showOwn">
                <div class="form-data-item-header-left">数位大数据</div>
                <img class="arrow-icon" :class="{'arrow-icon-active': !showOwn}" src="/static/business-map/arrow-icon.png" alt="" />
              </div>
              <div class="form-data-list" v-show="showOwn">
                <div class="form-data-list-item" v-for="item in ownData" :key="item.code">
                  <div class="form-data-list-item-left">
                    <img class="item-icon" :src="item.icon" alt="" />
                    {{ item.text }}
                    <img v-if="item.needVip" class="vip-icon" src="/static/business-map/vip2.png" alt="">
                  </div>
                  <van-switch v-model="item.checked" :disabled="!isVip && item.needVip" size="24px" active-color="#347fff" inactive-color="#e7e7e7" @click="selectOwnData(item)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-skeleton>
    </AutoView>
    <div class="footer-box">
      <div class="commit-btn-box">
        <van-button type="info" block class="commit-btn" @click="commit">确认{{ id ? '编辑' : '创建' }}</van-button>
      </div>
    </div>
    <!-- 开通会员弹框 -->
    <van-dialog v-model="showDialog" :showConfirmButton="false">
      <div class="wrap">
        <h4 class="h4">提示</h4>
        <p class="success-tips">解锁会员，选择更多数据</p>
        <div class="btn-wrap">
          <span class="cancle" @click="showDialog=false">取消</span>
          <span class="info" @click="openSvip">开通VIP</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
let requestMapParams = {
  "id": null,
  "brand": [],
  "hospital": {
    "categoryCodeL2": "",
    "categoryNameL2": "",
    "ext": "",
    "categoryL3List": [],
    "choose": false
  },
  "icon": "",
  "mall": {
    "categoryCodeL2": "",
    "categoryNameL2": "",
    "ext": "",
    "categoryL3List": [],
    "choose": false
  },
  "name": "",
  "office": {
    "categoryCodeL2": "",
    "categoryNameL2": "",
    "ext": "",
    "categoryL3List": [],
    "choose": false
  },
  "partType": null, // 热力
  "property": null,
  "residence": {
    "categoryCodeL2": "",
    "categoryNameL2": "",
    "ext": "",
    "categoryL3List": [],
    "choose": false
  },
  "school": {
    "categoryCodeL2": "",
    "categoryNameL2": "学校",
    "ext": "",
    "categoryL3List": [],
    "choose": false
  },
  "shopInput": {
    "ext": "",
    "stateList": []
  },
  "trafficList": [],
  "waiMai": {
    "categoryCodeL2": "",
    "categoryNameL2": "",
    "ext": "",
    "categoryL3List": [],
    "choose": false
  },
  "beauty": {
    "categoryCodeL1": "",
    "categoryCodeL2": "",
    "categoryL3List": [],
    "categoryNameL1": "",
    "categoryNameL2": "",
    "choose": false,
    "ext": ""
  },
  "eat": {
    "categoryCodeL1": "",
    "categoryCodeL2": "",
    "categoryL3List": [],
    "categoryNameL1": "",
    "categoryNameL2": "",
    "choose": false,
    "ext": ""
  },
  "play": {
    "categoryCodeL1": "",
    "categoryCodeL2": "",
    "categoryL3List": [],
    "categoryNameL1": "",
    "categoryNameL2": "",
    "choose": false,
    "ext": ""
  },
  "retail": {
    "categoryCodeL1": "",
    "categoryCodeL2": "",
    "categoryL3List": [],
    "categoryNameL1": "",
    "categoryNameL2": "",
    "choose": false,
    "ext": ""
  },
  "area": false
}
import OpenVip from "@/components/openVip/index.vue";
export default {
  name: 'createBusinessMap',
  components: {
    OpenVip
  },
  data() {
    return {
      showOwn: true,
      showDialog: false,
      brandChecked: false,
      showBrand: true,
      showFormat: true,
      showShop: true,
      showHot: true,
      checked: false,
      loading: false,
      mapName: '',
      hotList: [
        { text: '总人口热力', code: 0, needVip: false, checked: false },
        { text: '居住人口热力', code: 1, needVip: true, checked: false },
        { text: '办公人口热力', code: 2, needVip: true, checked: false },
        { text: '到访人口热力', code: 3, needVip: true, checked: false },
      ],
      berth: [
        { text: '空铺数据', code: "1", icon: '/static/business-map/shop1.png', checked: false },
        { text: '营业中铺位', code: "2", icon: '/static/business-map/shop2.png', checked: false },
        { text: '停业中铺位', code: "3", icon: '/static/business-map/shop3.png', checked: false },
      ],
      ownData: [
        { text: '商圈片区', code: '商圈片区', icon: '/static/business-map/area.png', checked: false },
        { text: '金角', code: '金角', icon: '/static/business-map/jin.png', checked: false, needVip: true },
        { text: '银边', code: '银边', icon: '/static/business-map/yin.png', checked: false, needVip: true },
      ],
      formatData: [
        { text: '小区', code: 'W030201', icon: '/static/business-map/format1.png', checked: false, type: 'residence' },
        { text: '写字楼', code: 'W040301', icon: '/static/business-map/format2.png', checked: false, type: 'office' },
        { text: '购物中心', code: 'W021602', icon: '/static/business-map/format3.png', checked: false, type: 'mall' },
        { text: '餐饮', code: 'W0101', icon: '/static/business-map/format4.png', checked: false, type: 'eat' },
        { text: '零售', code: 'W0203', icon: '/static/business-map/format5.png', checked: false, type: 'retail' },
        { text: '休闲娱乐', code: 'W0801', icon: '/static/business-map/format6.png', checked: false, type: 'play' },
        { text: '丽人', code: 'W1201', icon: '/static/business-map/format7.png', checked: false, type: 'beauty' },
        { text: '外卖', code: '', icon: '/static/business-map/format8.png', checked: false, type: 'waiMai' },
        { text: '医院', code: 'W1001', icon: '/static/business-map/format9.png', checked: false, type: 'hospital' },
        { text: '学校', code: 'W060102,W060109,W060110,W060111', icon: '/static/business-map/format10.png', checked: false, type: 'school' },
        { text: '地铁站', code: 'W0902', icon: '/static/business-map/format11.png', checked: false, type: 'traffic' },
        { text: '公交站', code: 'W0901', icon: '/static/business-map/format12.png', checked: false, type: 'traffic' },
      ],
      brand: [],
      brandResult: '',
      isVip: false, // 用户是否开通svip
      adding: false, // 创建地图中
      id: null
    }
  },
  created () {
    this.HTTP.getVipInfo().then(res => {
      // console.log(res)
      this.isVip = res.userDetail ? res.userDetail.member : false;
    })
    this.id = this.$route.query.id
    if (this.id) {
      this.HTTP.getBusinessMapDetail(this.id).then(res => {
        console.log('res', res)
        this.handleMapDetail(res)
      })
    }
  },
  methods: {
    handleMapDetail(res) {
      requestMapParams = res
      requestMapParams.id = Number(this.id) || null
      this.mapName = requestMapParams.name
      // 品牌
      this.brand = requestMapParams.brand.map(el => {
         return {
           name: el.text,
           code: el.code
         }
      }) || []
      this.brandChecked = this.brand.length > 0
      this.brandResult = this.brand.map(el => el.name).join('、')
      // 热力
      const hotItem = this.hotList.find(el => el.code === requestMapParams.partType)
      if (hotItem) {
        hotItem.checked = true
      }
      // 铺
      requestMapParams.shopInput.stateList.forEach(el => {
        const item = this.berth.find(e => e.code == el)
        if (item) {
          item.checked = true
        }
      })
      //自我数据
      this.ownData[0].checked = requestMapParams.area
      this.ownData[1].checked = requestMapParams.property === 0 || requestMapParams.property === 1
      this.ownData[2].checked = requestMapParams.property === 0 || requestMapParams.property === 2
      // 业态
      this.formatData.forEach(el => {
        if (el.type !== 'traffic') {
          el.checked = requestMapParams[el.type].choose
        } else {
          el.checked = requestMapParams.trafficList.findIndex(e => e.text === el.text) > -1
        }
      })
    },
    openSvip() {
      this.swRouter("/h5/#/member?source=create_business_map");
    },
    commit() {
      if (!this.mapName) {
        this.$toast( '请填写自定义地图名称' );
        return
      }
      // 判断是否选择数据项
      if (
        !this.hotList.some(el => el.checked)
        && !this.berth.some(el => el.checked)
        && !this.formatData.some(el => el.checked)
        && !this.ownData.some(el => el.checked)
        && ((this.brandChecked && !this.brand.length) || !this.brandChecked)
      ) {
        this.$toast( '至少选择一个数据项' );
        return;
      }
      if (this.adding) {
        return;
      }
      this.adding = true
      // 处理请求数据
      const params = JSON.parse(JSON.stringify(requestMapParams))
      params.name = this.mapName
      // 热力
      const hotItem = this.hotList.find(el => el.checked)
      if (hotItem) {
        params.partType = hotItem.code
      } else {
        params.partType = null
      }
      // 铺
      const stateList = []
      this.berth.forEach(el => {
        if (el.checked) {
          stateList.push(el.code)
        }
      })
      params.shopInput.stateList = stateList
      // 品牌
      if (this.brandChecked) {
        params.brand = this.brand.map(el => {
          return {
            text: el.name,
            code: el.code
          }
        }) || []
      } else {
        params.brand = []
      }

      // 业态
      params.trafficList = []
      this.formatData.forEach(item => {
        const code = item.checked ? item.code : ''
        const text = item.checked ? item.text : ''
        switch (item.text) {
          case '餐饮':
            params.eat.categoryCodeL2 = code
            params.eat.categoryNameL2 = text
            params.eat.choose = item.checked
            break
          case '零售':
            params.retail.categoryCodeL2 = code
            params.retail.categoryNameL2 = text
            params.retail.choose = item.checked
            break
          case '休闲娱乐':
            params.play.categoryCodeL2 = code
            params.play.categoryNameL2 = text
            params.play.choose = item.checked
            break
          case '丽人':
            params.beauty.categoryCodeL2 = code
            params.beauty.categoryNameL2 = text
            params.beauty.choose = item.checked
            break
          case '小区':
            params.residence.categoryL3List = item.checked ? [ { code: item.code, text: '' } ] : []
            params.residence.choose = item.checked
            break
          case '购物中心':
            params.mall.categoryL3List = item.checked ? [ { code: item.code, text: '' } ] : []
            params.mall.choose = item.checked
            break
          case '写字楼':
            params.office.categoryL3List = item.checked ? [ { code: item.code, text: '' } ] : []
            params.office.choose = item.checked
            break
          case '医院':
            params.hospital.categoryCodeL2 = code
            params.hospital.categoryNameL2 = text
            params.hospital.choose = item.checked
            break
          case '学校':
            const codes = item.code.split(',')
            params.school.categoryL3List = item.checked ? codes.map(c => {
              return {
                code: c,
                text: ''
              }
            }) : []
            params.school.choose = item.checked
            break
          case '外卖':
            params.waiMai.choose = item.checked
            break
          case '公交站':
          case '地铁站':
            if (item.checked) {
              params.trafficList.push({
                text: item.text,
                code: item.code
              })
            }
            break
        }
      })

      //自我数据
      params.area = this.ownData[0].checked
      let flagIndex = 0
      params.property = null
      this.ownData.forEach(item => {
          if (item.checked) {
            switch (item.text) {
              case '商圈片区':
                params.area = true
                break
              case '金角':
                params.property = 1
                flagIndex++
                break
              case '银边':
                params.property = 2
                flagIndex++
                break
            }
          }
      })
      if (flagIndex === 2) {
        params.property = 0
      }
      if (!flagIndex) {
        params.property = null
      }
      console.log('params', params)
      this.HTTP.createBusinessMap(params).then(res => {
        console.log(res)
        this.$toast(this.id ? '编辑完成' : '创建完成')
        this.JSBridge.callHandler("onBackPressed");
      }).catch((e) => {
        this.$toast("网络不稳定，请稍候重试！");
      }).finally(() => {
        this.adding = false
      })
    },
    // 自有数据选择
    selectOwnData(item) {
      if (item.needVip && !this.isVip) {
        this.showDialog = true
      }
    },
    // 热力选择
    selectHot(item) {
      if (item.needVip && !this.isVip) {
        this.showDialog = true
        return
      }
      this.hotList.forEach(el => el.checked = false)
    },
    selectBrand3() {
      if (!this.isVip) {
        this.showDialog = true
        return
      }
    },
    selectBrand2() {
      if (this.brandChecked && !this.brand.length) {
        this.selectBrand()
        this.brandChecked = false
      }
    },
    // 选择品牌
    selectBrand() {
      if (!this.isVip) {
        this.showDialog = true
        return
      }
      const param = JSON.stringify({
        title: `选择品牌`,
        "maxSize": 3,
        "type": 1,
        prefer: this.brand || [],
        avoid: []
      })
      this.JSBridge.callHandler('selectBrand', param, (res) => {
        console.log('选择品牌', res)
        let data = null
        if (typeof res === 'string') {
          const _data = JSON.parse(res)
          if (typeof _data === 'string') {
            data = JSON.parse(_data)
          } else {
            data = JSON.parse(res)
          }
        }
        this.brand = JSON.parse(JSON.stringify(data)) || []
        this.brandResult = this.brand.map(el => el.name).join('、')
        this.brandChecked = this.brand.length > 0
      } )
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
.titleHeader {
  border-bottom: 1px solid #E7E7E7;
}
.wrap {
  padding: 4.8vw;
  text-align: center;
  .vip-icon {
    width: 37.6vw;
    height: 4vw;
    margin-top: 2.667vw;
  }
  .wrap-img {
    width: 21.33vw;
    height: 21.33vw;
    padding: 4vw 0;
  }
  .h4 {
    font-size: 4.53vw;
    color: #1d2233;
    font-weight: bold;
    margin-bottom: 5.87vw;
  }
  .success-tips {
    font-size: 3.73vw;
    color: #8e9099;
    line-height: 7.47vw;
  }
  .t-color {
    font-size: 4vw;
    color: #fdae37;
    line-height: 8.53vw;
  }
  .btn-wrap {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
    margin-top: vw(82);
    .cancle {
      display: inline-block;
      width: 34.13vw;
      height: 10.67vw;
      line-height: 10.67vw;
      font-size: 4vw;
      color: #8e9099;
      background: #f7f6f9;
      border-radius: 1.6vw;
      text-align: center;
    }
    .info {
      display: inline-block;
      width: 34.13vw;
      height: 10.67vw;
      line-height: 10.67vw;
      font-size: 4vw;
      color: #fff;
      background: #347fff;
      border-radius: 1.6vw;
      text-align: center;
    }
  }
}
.van-switch--disabled {
  opacity: 1;
}
  .business-map-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 15.5vw;
    box-sizing: border-box;
    .commit-btn-box {
      width: 100%;
      padding: 0 vw(30);
      .commit-btn {
        font-size: vw(30);
      }
    }
    .footer-box {
      width: 100%;
      height: 15.5vw;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      align-items: center;
    }
    .view {
      flex: 1;
      background: #F7F7F7;
      padding: vw(30) vw(20);
      .map-form {
        .form-part2 {
          margin-top: vw(40);
          .form-data-list {
            border-top: vw(1) solid #E7E7E7;
            .form-data-list-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: vw(28) vw(30) vw(28) vw(26);
              .form-data-list-item-left {
                display: flex;
                align-items: center;
                font-weight: 400;
                color: #333333;
                font-size: vw(28);
                flex: 1;
                margin-right: vw(20);
                overflow: hidden;
                .brand-input {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  height: vw(80);
                  background: #F7F7F7;
                  border-radius: vw(14);
                  border: vw(1) solid #E7E7E7;
                  flex: 1;
                  padding: 0 vw(23) 0 vw(25);
                  font-size: vw(28);
                  overflow: hidden;
                  .brand-input-box {
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    .input-default-text {
                      color: #999999;
                    }
                  }
                  .arrow-icon2 {
                    transform: rotate(90deg);
                    width: vw(28);
                    height: vw(14);
                    margin-left: vw(10);
                  }
                }
                .item-icon {
                  width: vw(46);
                  height: vw(46);
                  margin-right: vw(14);
                }
                .vip-icon {
                  width: vw(65);
                  height: vw(34);
                  margin-left: vw(10);
                }
              }
            }
          }
          .form-data-item {
            background: #FFFFFF;
            box-shadow: 0 0 vw(8) 0 rgba(0,0,0,0.0700);
            border-radius: vw(14);
            margin-top: vw(20);
            .form-data-item-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: vw(82);
              padding: 0 vw(30);
              .vip-icon {
                width: vw(65);
                height: vw(34);
                margin-left: vw(10);
              }
              .form-data-item-header-left {
                font-weight: 400;
                color: #333333;
                font-size: vw(28);
                display: flex;
                align-items: center;
              }
              .arrow-icon {
                width: vw(28);
                height: vw(14);
                transition: all .2s;
                &.arrow-icon-active {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
        .form-input {
          background: #ffffff;
          box-shadow: 0 0 vw(8) 0 rgba(0,0,0,0.0700);
          border-radius: vw(14);
          height: vw(90);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 vw(30);
          font-size: vw(28);
          margin-top: vw(20);
          .count-label {
            color: #999999;
            margin-left: vw(20);
          }
          input {
            height: 100%;
            border: 0;
            padding: 0;
            flex: 1;
          }
        }
        .form-title {
          font-weight: 400;
          color: #121622;
          font-size: vw(26);
          line-height: vw(37);
        }
      }
    }
  }
</style>
