<template>
    <div class="page-wrap">
        <StateBar></StateBar>
        <TitleHeader title="充值引导流程" bgcolor="transparent" :back="showBack">
        </TitleHeader>
        <AutoView :header="true" :footer="false" ref="autoviewRef">
            <div class="page-body">
                <div class="item-header">
                    <div class="item-title">01</div>
                    <div class="item-content">打开App Store, 在App页面底部找到“绑定支付宝、微信支付或银联卡”。</div>
                </div>
                <div class="item-body">
                    <img src="/static/img/how_to_pay_01@2x.png" class="item-img" />
                </div>
                <div class="item-foot">
                    <img src="/static/icon/how_to_pay_icon@2x.png" class="item-icon" />
                </div>
            </div>
            <div class="page-body">
                <div class="item-header">
                    <div class="item-title">02</div>
                    <div class="item-content">在“管理付款方式”页，点击“添加付款方式”进入“添加付款方式”。</div>
                </div>
                <div class="item-body">
                    <img src="/static/img/how_to_pay_02@2x.png" class="item-img" />
                </div>
                <div class="item-foot">
                    <img src="/static/icon/how_to_pay_icon@2x.png" class="item-icon" />
                </div>
            </div>
            <div class="page-body">
                <div class="item-header">
                    <div class="item-title">03</div>
                    <div class="item-content">选择“支付宝”或“微信支付”，点击“请前往支付验证”或“请前往微信验证”，开通免密支付。</div>
                </div>
                <div class="item-body">
                    <img src="/static/img/how_to_pay_03@2x.png" class="item-img" />
                </div>
                <div class="item-foot">
                    <img src="/static/icon/how_to_pay_icon@2x.png" class="item-icon" />
                </div>
            </div>
            <div class="page-body">
                <div class="item-header">
                    <div class="item-title">04</div>
                    <div class="item-content">在支付宝或微信免密支付页面，点击“同意协议并开通”或“下一步”，身份验证后绑定成功。</div>
                </div>
                <div class="item-body">
                    <img src="/static/img/how_to_pay_04@2x.png" class="item-img" />
                </div>
                <div class="item-foot">
                    <img src="/static/icon/how_to_pay_icon@2x.png" class="item-icon" />
                </div>
            </div>
            <div class="page-body">
                <div class="item-header">
                    <div class="item-title">05</div>
                    <div class="item-content">在“上上贝”充值页选择金额充值，点击“购买”按钮。</div>
                </div>
                <div class="item-body">
                    <img src="/static/img/how_to_pay_05@2x.png" class="item-img" />
                </div>
                <div class="item-foot">
                    <img src="/static/icon/how_to_pay_icon@2x.png" class="item-icon" />
                </div>
            </div>
            <div class="page-body">
                <div class="item-header">
                    <div class="item-title">06</div>
                    <div class="item-content">输入Apple ID密码、使用Touch ID或者Face ID完成购买。</div>
                </div>
                <div class="item-body">
                    <img src="/static/img/how_to_pay_06@2x.png" class="item-img" />
                </div>
                <div class="item-foot">
                    <!-- <img src="/static/icon/how_to_pay_icon@2x.png" class="item-icon" /> -->
                    <span class="foot-label" @click="backToTop">返回顶部重新查看</span>
                </div>
            </div>
        </AutoView>
    </div>
</template>

<script>
export default {
    data () {
        return {
            showBack: true
        };
    },
    methods: {
        backToTop () {
            const ref = this.$refs['autoviewRef'];
            if ( ref ) {
                ref.$el.scrollTo( {
                    top     : 0,
                    left    : 0,
                    behavior: 'smooth'
                } );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.page-wrap {
    position: relative;
    width: 100%;
    height: 100%;

}
.page-body {
    padding: 5.9vw 9vw 5.9vw 5.9vw;
    background: url('/static/img/how_to_pay_bj@2x.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .item-header {
        position: relative;
        display: flex;
        align-items: center;
        .item-title {
            color: #FFFFFF;
            font-size: 9.6vw;
            font-weight: bold;
            text-align: left;
        }
        .item-content {
            margin-left: 0.8vw;
            color: #FFFFFF;
            font-size: 4vw;
            line-height: 5.6vw;
            font-weight: bold;
            text-align: left;
        }
    }
    .item-body {
        position: relative;
        margin-top: 7.2vw;
        display: flex;
        justify-content: center;

        .item-img {
            width: 81vw;
            height: 144vw;
        }
    }
    .item-foot {
        position: relative;
        margin-top: 5.9vw;
        display: flex;
        justify-content: center;
        .item-icon {
            width: 7.5vw;
            height: 6.1vw;
        }
        .foot-label {
            color: #ffffff;
            font-size: 4vw;
            font-weight: bold;
            line-height: 5.6vw;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
