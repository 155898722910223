<template>
  <div>
    <StateBar :bgcolor="bgColor" />
    <TitleHeader :title="'支付成功'" :bgcolor="bgColor" color="black" :back="true"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="true"
    >
      <div class="module">
        <p class="title">上上参谋连锁品牌VIP支付成功!</p>
        <div class="container app">
          <img class="title-img" src="/static/vip/img-app-title.png">
          <p class="desc">已成功为您开通<span class="orange">上上参谋App</span>端连锁品牌VIP权益</p>
        </div>
        <div class="container saas">
          <img class="title-img" src="/static/vip/img-saas-title.png">
          <p class="desc">已成功为您开通上上参谋企业版服务，恭喜您开通<span class="orange">数位拓店云</span>！商户码稍后将同步通过短信的方式发送至填写的手机号内，请注意查收！</p>
          <div class="form" >
            <p>商户账号：<span>{{info.account}}</span></p>
            <!--<p>商户码：<span>10个</span></p>-->
            <!--<p>开放城市：<span>深圳</span></p>-->
            <!--<p>开放品牌：<span>茶颜悦色</span></p>-->
            <p>有效期：<span>{{info.expireDate}}</span></p>
            <p>
              系统登录网址：
              <span>{{info.url}}</span>
              <b><img @click="copyUrl(info.url)" src="static/vip/icon-link.png"></b>
            </p>
          </div>
          <img class="form-view" src="/static/vip/img-form-view.png" />
        </div>
      </div>
    </AutoView>
    <!-- <SoltFooter v-if="editable"> -->
    <div class="submitFooter">
      <van-button @click="toVip">确定</van-button>
    </div>

  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  name: 'index',
  data () {
    return {
      bgColor: '#fff',
      info: {
        account: '',
        brandName: '',
        companyName: '',
        expireDate: '',
        merchantCode: '',
        status: '',
        url: '',
      },
    };
  },
  mounted() {
    this.getSaasInfo();
    this.JSBridge.callHandler("setStatebarTheme", 'black');
  },
  methods: {
    getSaasInfo() {
      this.HTTP.getSaasInfo().then(res => {
        console.log(res, '商户信息');
        this.info = res.data.data;
      })
    },
    toVip () {
      // this.JSBridge.callHandler("onBackPressed");
      // this.swRouter({
      //   path: `/h5/#/member`,
      // })
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "CHANGE_MAIN_TAB",
        id: "me",
        params: null,
        trackData: null,
        isClear: true,
      });
    },
    copyUrl(str) {
      let input = str + '';
      const el = document.createElement('input');
      el.value = input;
      el.setAttribute('readonly', '');
      el.style.contain = 'strict';
      el.style.position = 'absolute';
      el.style.left = '-100vw';
      el.style.fontSize = '12px'; // Prevent zooming on iOS
      document.body.appendChild(el);
      el.select();
      el.selectionStart = 0;
      el.selectionEnd = input.length;
      let success = false;
      try {
        success = document.execCommand('copy');
        Toast('复制成功');
      } catch (err) {
        Toast('复制失败');
      }
      document.body.removeChild(el);
      return success;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';
@import '@/styles/_cell.scss';
*{
  box-sizing: border-box;
}
.module{
  background: url("/static/vip/img-pay-success-top.png") no-repeat;
  background-size: 100% auto;
  padding: vw(80) vw(30) vw(30) vw(30);
  >.title{
    font-size: vw(32);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF8700;
    line-height: vw(45);
    padding-left: vw(8);
  }
  .container{
    position: relative;
    padding: vw(46) vw(30) vw(30) vw(30);
    background: #FFFFFF;
    box-shadow: 0px 0px vw(11) vw(3) rgba(13, 13, 13, 0.06);
    border-radius: vw(10);
    border: vw(3) solid #FFCE8F;
    .title-img{
      position: absolute;
      left: 0;
      top: vw(-30);
      height: vw(60);
    }
    .desc{
      font-size: vw(28);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: vw(40);
      text-align: center;
      .orange{
        color: #FF8700;
      }
    }
    &.app{
      margin-top: vw(75);
    }
    &.saas{
      margin-top: vw(84);
      .desc{
        text-align: left;
      }
      .form{
        width: 100%;
        background: #F7F7F7;
        border-radius: vw(10);
        padding: vw(18) vw(30);
        margin-top: vw(30);
        p{
          font-size: vw(28);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: vw(37);
          margin: vw(20) 0;
          span{
            color: #333;
          }
        }
        img{
          display: inline-block;
          width: vw(28);
          margin-left: vw(10);
        }
      }
      .form-view{
        display: inline-block;
        width: 100%;
        margin-top: vw(30);
      }
    }
  }
}
.submitFooter{
  padding: vw(16) vw(30);
  box-shadow: 0px -2px 6px 0px rgba(34, 34, 34, 0.12);
  .van-button{
    width: 100%;
    height: vw(80);
    background: linear-gradient(90deg, #FFAC42 0%, #FFCE8F 100%);
    border-radius: 10px;
  }
}

</style>
