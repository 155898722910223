<template>
    <div class="shops">
        <StateBar></StateBar>
        <TitleHeader title="订阅的店" bgcolor="transparent" :back="true">
            <div slot="right" @click="gotoIntroduce" class="btnColor">订阅介绍</div>
        </TitleHeader>
        <NoticeBar jumpUrl="enableNotification" :show="!enableNotice" />

        <AutoView :header="true" :footer="true" class="shops--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="shops"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <ShopCard v-for="( item, index ) in list" :key="item.id" :class="{ 'f-vmt' : index > 0 }"
                            mode="normal"
                            :item="item"
                            @tap="onTap( item )"
                            @tapBtn="onTapBtn( item )" />
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>

<!--        <SoltFooter class="shops&#45;&#45;footerBar">-->
<!--            <div class="view">-->
<!--                <van-button type="info" block class="icon icon-add" @click="$router.push( '/shop' )">&emsp;添加订阅</van-button>-->
<!--            </div>-->
<!--        </SoltFooter>-->
    </div>
</template>

<script>
import ShopCard from '@/components/card/shop';
import NoticeBar from '@/components/widget/noticeBar.vue';

export default {
    name      : 'shops',
    components: {
        ShopCard,
        NoticeBar
    },
    data () {
        return {
            firstLoading: true,
            loading     : true,
            submitting  : false,
            dataLoading : false,
            finished    : false,
            page        : 1,
            total       : 0,
            list        : [],
            enableNotice: true,
        };
    },
    methods: {
        loadData () {
            this.loading  = true;
            this.HTTP.subShopList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        onTap ( item ) {
            if ( item.status === 2 || item.status == 3 ) {
                this.swRouter( '/views/' + item.shopId );
            }
        },
        onTapBtn( item ) {
            if ( item.status === 2 ) {
                this.swRouter( '/views/' + item.shopId );
            } else if ( item.status === 3 ) {
                this.submitting = true;
                this.HTTP.createShopSub( item.shopId, item.openShopId, 2 ).then( ( res ) => {
                    // this.$router.push( `/pay/${res.businessId}` );
                    this.swRouter( `/pay/${res}` );
                } ).catch( ( res ) => {
                    this.$toast( '订阅失败，请稍候重试！' );
                } ).finally( () => {
                    this.submitting = false;
                } );
            }
        },
        gotoIntroduce() {
            this.swRouter( '/introduce/10012' );
            // this.JSBridge.callHandler( 'gotoPage', 'card_10012' );
            // this.JSBridge.callHandler( 'onNormalizingCall', {
            //     type  : 5,
            //     url   : 'BRAND_INTRODUCTION',
            //     id    : 10012,
            //     params: null,
            // } )
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'clearHistory', true );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        this.JSBridge.callHandler( 'areNotificationsEnabled', undefined, ( res ) => {
            // console.log( 'areNotificationsEnabled:', res );
            if ( typeof res === 'string' ) {
                if ( res === 'false' ) {
                    this.enableNotice = false;
                }
            } else if ( typeof res === 'boolean' ) {
                if ( res === false ) {
                    this.enableNotice = false;
                }
            }
        } );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.shops {
    height: 100vh;
    background-color: $c-pageBg;

    .btnColor {
        color: $c-main;
    }

    #{&}--view {
        .l-panel {
            padding: $padding2;
        }
    }

    #{&}--footerBar {
        box-shadow: 0 -.5vw 2vw rgba(black, .05);
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-button {
                height: 100%;
                line-height: normal;

                background-image: url(/static/icon/add.png);
                background-repeat: no-repeat;
                background-position: 38% center;
                background-size: 4.4vw 4.4vw;
            }
        }
    }
}
</style>
