<template>
    <div class="double-eleven">
        <StateBar />
        <TitleHeader title="双十一狂欢" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView ref="view" class="double-eleven--view l-view">
            <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s">
            </van-skeleton>
            <div v-show="!firstLoading">
                <VanImage @load="imageLoadDone" src="/static/img/double-eleven/db-11-1.png">
                    <div class="def-button no-arraw" @click="receiveAward" v-if="receiveStatus === 0">
                        一键领取
                    </div>
                    <div class="def-button no-arraw isReceive" v-else>
                        {{ receiveStatus === 1 ? '已领取' : '已领完'}}
                    </div>
                </VanImage>
                <VanImage @load="imageLoadDone" src="/static/img/double-eleven/db-11-2.png">
                    <div class="double-btn">
                        <div class="def-button" @click="gotoDetail({ type: '5', url: 'BRAND_INTRODUCTION_V2', id:'10001', btnId: '81002' })">
                            ¥56/次<span>起</span>
                        </div>
                        <div class="def-button" @click="gotoDetail({ type: '5', url: 'BRAND_INTRODUCTION_V2', id: '10026', btnId:'81003' })">
                            ¥123/次<span>起</span>
                        </div>
                    </div>
                </VanImage>
                <VanImage @load="imageLoadDone" src="/static/img/double-eleven/db-11-3.png">
                    <div class="def-button" style="bottom: 2.8vw; width:61.86vw;" @click="deeplink({ type: '6', url: '/h5/#/member', btnId: '81004' })">
                        到手仅需2元/天，去购买
                    </div>
                </VanImage>
                <VanImage @load="imageLoadDone" src="/static/img/double-eleven/db-11-4.png">
                    <div>
                        <div class="def-button small" @click="deeplink({ type: '6', url: '/h5/#/member', btnId: '81009' })">
                            去购买
                        </div>
                        <div class="def-button small" style="top: 79vw;" @click="deeplink({ type: '6', url: '/h5/#/member', btnId: '81005' })">
                            去购买
                        </div>
                    </div>
                </VanImage>
                <VanImage @load="imageLoadDone" src="/static/img/double-eleven/db-11-5.png">
                    <div>
                        <div class="double-btn three">
                            <div class="def-button" @click="deeplink({ type: '5', url: 'BRAND_INTRODUCTION', id: '10006', btnId: '81006' })">
                                去秒杀
                            </div>
                            <div class="def-button" @click="deeplink({ type: '5', url: 'BRAND_INTRODUCTION', id: '10015', btnId: '81007' })">
                                去秒杀
                            </div>
                            <div class="def-button" @click="deeplink({ type: '5', url: 'BRAND_INTRODUCTION', id: '10004', btnId: '81008' })">
                                去秒杀
                            </div>
                        </div>
                    </div>
                </VanImage>
            </div>
        </AutoView>
    </div>
</template>

<script>
let picNum = 0
export default {
    name: 'DoubleEleven',
    data() {
        return {
            firstLoading: true,
            activeId: '', // 活动ID
            couponId: '', // 优惠券ID
            receiveStatus: 0, // 领取状态 0：未领取 1：已领取 2: 已达上限
        }
    },
    created() {
        this.activeId = this.$route.params.id
        this.HTTP.getActiveCoupon(this.activeId).then(res => {
            console.log('双十一：', res);
            const { couponId, receiveStatus } = res.data.activeItemResList[0]
            this.couponId = couponId
            this.receiveStatus = receiveStatus
        })
    },
    methods: {
        imageLoadDone() {
            picNum++
            if (picNum >= 3) {
                this.firstLoading = false
            }
        },
        gotoDetail( { type, url, params, id, btnId } ) {
          console.log( type, url, params, btnId, this.$route.meta.id );
          btnId && this.JSBridge.BTNCLICK(this.$route.meta.id, '', 81000, btnId);
          this.JSBridge.callHandler( 'onNormalizingCall', {
            type,
            url: window._appCode >= 300 ? 'BRAND_INTRODUCTION_V3' : url,
            id,
            params: JSON.stringify(params)
          } );
        },
        deeplink ( { type, url, params, id, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            btnId && this.JSBridge.BTNCLICK(this.$route.meta.id, '', 81000, btnId);
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                id,
                params: JSON.stringify(params)
            } );
        },
        // 一键领取
        receiveAward() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, '', 81000, '81001')
            this.HTTP.receiveCoupon(this.activeId, this.couponId).then(res => {
                if (res.data) {
                    this.receiveStatus = 1
                }
            }).catch(e => {
                this.$toast(e.msg)
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.double-eleven {
    height: 100vh;
    overflow: hidden;

    #{&}--view {
        scroll-behavior: smooth;
        background: #f7f7f7;
        padding-bottom: 35vw;
    }
    .l-view {
        padding: 0;
        height: 100%;
        // padding-bottom: 10vw;
    }
    .van-image {
        width: 100%;
        margin-bottom: -4px;
        position: relative;
        ::v-deep .def-button {
            position: absolute;
            width: 40vw;
            height: 8.8vw;
            line-height: 8.8vw;
            background: linear-gradient(90deg, #FF9801 0%, #FF5900 100%);
            box-shadow: 0 0.266vw 0.533vw 0 #AC5218, 0 0.133vw 0.4vw 0 #FFB003;
            border-radius: 4.4vw;
            bottom: 4.8vw;
            left: 50%;
            transform: translateX(-50%);
            font-size: 4.26vw;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFF6E0;
            letter-spacing: 0.266vw;
            text-shadow: 0px 0.266vw 0.266vw #A04600;
            text-align: center;
            text-indent: -2.5vw;

            &::after {
                position: absolute;
                content: '';
                width: 4vw;
                height: 4vw;
                transform: translateY(-1.8vw);
                top: 50%;
                background: url('/static/icon/arrow_right.png') center / 4vw 4vw no-repeat;
            }
            &.no-arraw {
                text-indent: 0;
                &::after {
                    display: none;
                }
            }
            &.isReceive {
                background: #BDBDBD;
                box-shadow: 0px 2px 4px 0px #A9A9A9;
                color: #fff;
                text-shadow: 0px 2px 2px #6F6F6F;
            }
            &.small {
                width: 26.4vw;
                top: 39vw;
                transform: translateX(calc(-50% - 1vw));
            }
            span {
                font-size: 2.93vw;
            }
        }
        ::v-deep .double-btn {
            position: absolute;
            display: flex;
            justify-content: space-around;
            bottom: 2.8vw;
            left: 0;
            right: 0;
            .def-button {
                position: unset;
                transform: none;
                width: 26.4vw;
            }
            &.three {
                bottom: 19vw;
                justify-content: center;
                .def-button:nth-child(2) {
                    margin: 0 4.4vw;
                }
            }
        }
    }
}
</style>
