/**
 * 主要界面
 * 由APP引入或WEB直接提供访问的界面
 */

const additional = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/additional");
const additionalSP1000x = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/additionalSP");
const additionalSP10004 = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/additionalSP10004");
const charts = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/charts");
const reportFree = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/reportFree");
const cardHandler = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/cards");
const custom = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/custom");
const feedback = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/feedback");
const pay = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/pay");
const payGoods = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/payGoods");
const payment = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/payment");
const search = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/search");
const success = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/success");
const views = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/views");
const about = () => import("@/views/about");
const newPay = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/newPay");
const aiPay = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/aiPay");
const newSuccess = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/newSuccess");
const guide = () => import(/* webpackChunkName: "module-guide" */ "@/views/home/guide");
const guideOne = () => import(/* webpackChunkName: "module-guide" */ "@/views/home/guideOne");
const guideTwo = () => import(/* webpackChunkName: "module-guide" */ "@/views/home/guideTwo");
const information = () => import(/* webpackChunkName: "module-information" */ "@/views/home/information");
const setting = () => import(/* webpackChunkName: "module-information" */ "@/views/home/setting");
const update = () => import(/* webpackChunkName: "module-information" */ "@/views/home/updateInstructions");
const perfectInformation = () => import(/* webpackChunkName: "module-information" */ "@/views/home/perfectInformation");
const enterpriseInformation = () => import(/* webpackChunkName: "module-information" */ "@/views/home/enterpriseInformation");
const service = () => import(/* webpackChunkName: "module-information" */ "@/views/home/service");

// 商场报告页
const mallReport = () => import(/* webpackChunkName: "module-sscm" */ "@/views/home/mall-report");

// 创建商业地图页
const createBusinessMap = () => import(/* webpackChunkName: "business-map" */ "@/views/businessMap/createBusinessMap")
// 开店位置专区
const openStoreArea = () => import(/* webpackChunkName: "business-map" */ "@/views/businessMap/openStoreArea")
// 商业地图反馈
const businessMapFeedBack = () => import(/* webpackChunkName: "business-map" */ "@/views/businessMap/feedback")

export default [
  {
    path: "/business-map/feedback",
    name: "businessMapFeedBack",
    component: businessMapFeedBack,
    meta: { id: 10345 }
  },
  {
    path: "/business-map",
    name: "createBusinessMap",
    component: createBusinessMap,
    meta: { id: 10334 }
  },
  {
    path: "/open-store-area",
    name: "openStoreArea",
    component: openStoreArea,
    meta: { id: 10335 }
  },
  {
    // 参数 报告实例id
    path: "/mall-report/:id",
    name: "mallReport",
    component: mallReport,
    meta: { id: 10220 }
  },
  {
    // 创建报告
    path: "/additional",
    name: "additional",
    component: additional,
    meta: { id: 10031 }
  },
  {
    // 参数 模板id
    path: "/additional/:id",
    name: "additionalFromId",
    component: additional,
    meta: { id: 10031 }
  },
  {
    // 创建特殊报告
    path: "/additionalSP",
    name: "additionalSP",
    component: additionalSP1000x,
    meta: { id: 10031 }
  },
  {
    // 品牌洼地报告
    path: "/additionalSP10004",
    name: "additionalSP10004",
    component: additionalSP10004,
    meta: { id: 10015 }
  },
  {
    // 首页卡片前置处理界面
    path: "/card/:section",
    name: "card",
    component: cardHandler
  },
  {
    // 参数 报告实例id
    path: "/charts/:id",
    name: "charts",
    component: charts,
    meta: { id: 10063 }
  },
  {
    // 参数 报告实例id
    path: "/report/:id",
    name: "reportFree",
    component: reportFree,
    meta: { id: 10613 }
  },
  {
    path: "/custom",
    name: "custom",
    component: custom,
    meta: { id: 10059 }
  },
  {
    path: "/feedback",
    name: "feedback",
    component: feedback,
    meta: { id: 10059 }
  },
  {
    // 参数 报告实例id
    path: "/pay/:id",
    name: "pay",
    component: pay,
    meta: { id: 10032 }
  },
  {
    // 参数 商品id
    path: "/payGoods/:id",
    name: "payGoods",
    component: payGoods,
    meta: { id: 10027 }
  },
  {
    // 参数 商品id
    path: "/payment",
    name: "payment",
    component: payment
    // meta     : { id: 10028 },
  },
  {
    path: "/search",
    name: "search",
    component: search
  },
  {
    path: "/success",
    name: "success",
    component: success,
    meta: { id: 10033 }
  },
  {
    // 参数 数据主体id
    path: "/views/:id",
    name: "views",
    component: views,
    meta: { id: 10067 }
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: { title: "关于" }
  },
  {
    path: "/newPay/:id",
    name: "newPay",
    component: newPay,
    meta: { id: 10094 }
  },
  {
    path: "/aiPay/:id",
    name: "aiPay",
    component: aiPay,
    meta: { id: 10104 }
  },
  {
    path: "/newSuccess",
    name: "newSuccess",
    component: newSuccess,
    meta: { id: 10105 }
  },
  {
    path: "/guide",
    name: "guide",
    component: guide,
    meta: { id: 10109 }
  },
  {
    path: "/guideOne",
    name: "guideOne",
    component: guideOne,
    meta: { id: 10107 }
  },
  {
    path: "/guideTwo",
    name: "guideTwo",
    component: guideTwo,
    meta: { id: 10108 }
  },
  {
    path: "/information/:id",
    name: "information",
    component: information,
    meta: { id: 10134 }
  },
  {
    path: "/setting",
    name: "setting",
    component: setting,
    meta: { id: 10135 }
  },
  {
    path: "/update",
    name: "update",
    component: update,
    meta: { id: 10152 }
  },
  {
    path: "/perfectInformation",
    name: "perfectInformation",
    component: perfectInformation,
    meta: { id: 10199 }
  },
  {
    path: "/enterpriseInformation",
    name: "enterpriseInformation",
    component: enterpriseInformation,
    meta: { id: 10246 }
  },
  {
    path: "/service",
    name: "service",
    component: service,
    meta: { id: 10202 }
  }
];
