<template>
    <div class="autoView" :class="[mode, { android: isAndroid, ios: isIOS, ipx: isIPX, noIPXBar: noIPXBar, mini: isWX, ipmx: isIPMX }]">
        <slot />
    </div>
</template>

<script>
export default {
    name : 'autoView',
    props: {
        header: {
            type   : Boolean,
            default: true,
        },
        footer: {
            type   : Boolean,
            default: false,
        },
        noIPXBar: {
          type   : Boolean,
          default: false,
        },
    },
    data () {
        return {
            isAndroid: false,
            isIOS    : false,
            isIPX    : false,
            isWX     : false,
            isIPMX: false
        };
    },
    computed: {
        mode () {
            if ( this.header && this.footer ) {
                return 'normal';
            } else if ( this.header ) {
                return 'only-header';
            } else if ( this.footer ) {
                return 'only-footer';
            } else {
                return 'full-screen'
            }
        },
    },
    beforeMount () {
        this.isAndroid = window.isClient && window.isAndroid;
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
            this.isIPMX = window.isIPMX
        }
        this.isWX = window.isMini
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.autoView {
    // background-color: pink;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    height: 100vh;
    height: calc( 100vh - #{$headerHeight} - #{$footerHeight} );
    &.android {
        height: calc( 100vh - #{$androidStateBar} - #{$headerHeight} - #{$footerHeight} );
    }
    &.ios {
        height: calc( 100vh - #{$iOSStateBar} - #{$headerHeight} - #{$footerHeight} );
    }
    &.ipx {
        height: calc( 100vh - #{$iPXStateBar} - #{$headerHeight} - #{$footerHeight} - #{$iPXBottomBar} );
    }
    &.ipmx {
      height: calc( 100vh - #{$IPMXStateBar} - #{$headerHeight} - #{$footerHeight} - #{$iPXBottomBar} );
    }

    &.only-header {
        height: calc( 100vh - #{$headerHeight} );
        &.android {
            height: calc( 100vh - #{$androidStateBar} - #{$headerHeight} );
        }
        &.ios {
            height: calc( 100vh - #{$iOSStateBar} - #{$headerHeight} );
        }
        &.ipx {
            height: calc( 100vh - #{$iPXStateBar} - #{$headerHeight} );
        }
        &.ipmx {
          height: calc( 100vh - #{$IPMXStateBar} - #{$headerHeight} );
        }
    }

    &.only-footer {
        height: calc( 100vh - #{$footerHeight} );
        &.ios {
            height: calc( 100vh - #{$footerHeight} );
        }
        &.ipx {
            height: calc( 100vh - #{$footerHeight} - #{$iPXBottomBar} );
        }
        &.mini {
            height: calc( 100vh - #{$footerHeight} - #{$iPXBottomBar} );
        }
        &.ipmx {
          height: calc( 100vh - #{$footerHeight} - #{$iPXBottomBar} );
        }
    }

    &.full-screen {
        height: calc( 100vh );
        &.ios {
            height: calc( 100vh );
        }
        &.ipx {
            height: calc( 100vh - #{$iPXBottomBar} );
        }
        &.noIPXBar {
          height: calc( 100vh ) !important;
        }
        &.ipmx {
          height: calc( 100vh - #{$iPXBottomBar} );
        }
    }
}
</style>
