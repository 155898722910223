<template>
    <van-dialog
        className="testPop"
        v-model="show"
        :title="titleName"
        :showConfirmButton="false"
        :showCancelButton="false"
        :overlay-style="{backgroundColor: 'rgba(255,255,255,0)'}"
        :closeOnClickOverlay="true">
        <template v-if="paramsNum == 1">
            <p v-for="(val, index) in testContent" :key="index">
                {{val}}
            </p>
        </template>
        <template v-else>
            <p>
                {{ yxTest.content }}
            </p>
        </template>
    </van-dialog>
</template>
<script>
    export default {
        name: "pop",
        props: {
            paramsNum: {
                type: Number,
                default: 1
            },
            brandTitle: {
                type: String
            }
        },
        data() {
            return {
                show: false,
                testContent: [
                    "门店数据概况：",
                    "相关数据来源于数位自有大数据和第三方互联网数据，部分数据经过整理纠正，不确保数据的绝对正确性。",
                    "考虑到品牌类型的不同，与实际会有不同程度的误差。门店信息显示的poi数据仅统计正常营业、有用户评论的门店数据，往往小于品牌披露的开业门店数。"
                ],
                yxTest: {
                    title: "- 郑重承诺 -",
                    content: "经上上参谋严选加盟认证中心认证审核，"+this.brandTitle+"符合上上参谋准入机制，具备一定经营能力，存活率高，但加盟有风险，投资需谨慎，建议加盟者实地考察。"
                },
                titleName: null,
            }
        },
        methods: {
            getIsShow() {
                this.show = !this.show;
            }
        },
        created() {
            if(this.paramsNum == 2) this.titleName = this.yxTest.title;
        }
    }

</script>
<style lang="scss" scoped>
    @import "@/styles/index.scss";
    .testPop{
        padding: 3.33vw;
        box-sizing: border-box;
        border-radius: 2.67vw;
        background-color: rgba(16, 30, 50, .88);
        text-align: left;
        p{
            margin-bottom: 1.87vw;
            color: #FFFFFF;
            line-height: 4.4vw;
            font-size: 3.2vw;
            &:last-child{
                margin-bottom: 0;
            }
        }
        ::v-deep .van-dialog__header{
            padding-top: 0;
            font-size: 3.73vw;
            color: #FFFFFF;
            line-height: 5.33vw;
            margin-bottom: 2.93vw;
        }
    }
</style>
