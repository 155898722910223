<template>
    <div>
        <StateBar />
        <TitleHeader title="全部品类" color="#1D2233" :back="true"></TitleHeader>
        <div class="content-list">
            <span :class="{ active: i === actIdx }" v-for="(name, i) in list" :key="i" @click="selIndust(i)">{{ name }}</span>
        </div>
    </div>
</template>

<script>
import { IndustryList } from './const.js'
export default {
    name: 'selectIntustry',
    data () {
        return {
            actIdx  : 0,
            list    : IndustryList,
            fromType: ''
        };
    },
    created () {
        this.actIdx = + this.$route.params.id;
        this.fromType = this.$route.query.type;
    },
    methods: {
        selIndust ( i ) {
            this.actIdx = i;
            if ( this.fromType === 'tokeout' ) {
                this.$store.commit( 'setTokeoutType', i );
            } else {
                this.$store.commit( 'setIndustryType', i );
            }
            this.commonApi.pageBack()
        }
    }
};
</script>

<style lang="scss" scoped>
.content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 4vw;
    border-top: 0.133vw solid #ebeaee;
    > span {
        width: 28.53vw;
        height: 9.33vw;
        line-height: 9.33vw;
        text-align: center;
        background: #f2f2f2;
        border-radius: 1.33vw;
        margin-bottom: 3.33vw;
        color: #333333;
        font-size: 3.73vw;
        &.active {
            box-sizing: border-box;
            background: #ffece2;
            border-radius: 1.33vw;
            border: 0.2667vw solid #ff5900;
        }
    }
}
</style>
