<template>
  <div class="index" :class="{ noscroll: noscroll }">
    <StateBar></StateBar>
    <Header class="index--header"></Header>

    <div class="index--effect">
      <span v-for="(item, index) in words" :key="index" class="card" :class="getAni(index)" :style="getStyle(index)">{{ item }}</span>
    </div>

    <div class="index--search">
      <div class="panel">
        <StateBar v-show="locked"></StateBar>
        <div class="bgblock"></div>
        <div class="mask s-radius__s" @click="displaySearch">
          <div class="keyword" :class="searchAni">
            <code v-if="key">{{ key }}</code>
          </div>
          <i class="fa-search fixed" />
          <i class="fa-search search f-vmr s-radius__s" />
        </div>
      </div>

      <transition name="fade" v-on:after-enter="afterEnter" v-on:before-leave="beforeLeave">
        空空如也
        <!-- <Search v-if="showSearch" :defaultPlaceholder="key" :autoFocus="true" :hotSearch="hots" @cancel="cancelSearch"></Search> -->
      </transition>
    </div>

    <div class="index--content s-radius">
      <van-tabs class="index--tabs">
        <van-tab v-for="(item, index) in topics" :key="index" :swipeable="false" :title="item.title" :title-style="'flex-basis: 25%'">
          <!-- <div class="f-vp">
                        <van-skeleton :loading="loading" title avatar avatar-shape="square" :animate="false" :row="3">
                            <template v-for="( item, index ) in news">
                                <van-divider v-if="index > 0" :key="index" />
                                <NewsCard :key="index"
                                    :item="item"
                                    @click="viewNews" />
                            </template>
                        </van-skeleton>
                    </div> -->
          <div class="topic">
            <img src="/static/img/index.jpg" />
            <router-link to="/card/B" />
            <router-link to="/card/C" style="left: 50%" />
            <router-link to="/card/A" style="top: 50%; width: 100%" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { random } from "@/utils/index";
import Header from "@/components/header/index";

const vw = window.innerWidth / 100;
let step1 = 28.17 * vw; // 上划阈值
const step2 = 10 * vw;
const step3 = (12.8 - 2.4) * vw;
const step4 = 18.67 * vw;
const step5 = (18.67 - 10.67) * vw;
let _reqCount = 0;
let _reqAniTimer = 0;
let _dombg, _domic, _domrt, _domtg;

export default {
  name: "index",
  components: {
    Header
  },
  data() {
    return {
      key: "",
      index: 0, // 热搜下标
      keys: [],
      words: [],
      hots: [],
      loading: true,
      noscroll: false, // 界面滚动条隐藏
      locked: false, // 顶部搜索栏锁定状态
      showSearch: false, // 顶部搜索栏显示
      homeData: null, // 首页数据
      searchAni: "",
      aniMap: [],
      styMap: [],
      topics: [
        {
          title: "参谋推荐",
          list: []
        }
      ],
      news: []
    };
  },
  methods: {
    loadData() {
      const data = this.$store.getters.home;
      if (data) {
        this.homeData = data;
      } else {
        this.HTTP.getHomeData().then(res => {
          this.$store.commit("initHomeData", res);
          this.homeData = this.$store.getters.home;
        });
      }
    },
    afterEnter() {
      this.noscroll = true;
    },
    beforeLeave() {
      this.noscroll = false;
      this.JSBridge.callHandler("switchNavbar", true);
    },
    displaySearch() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.HOME.TAP_SEARCH);
      this.JSBridge.callHandler("switchNavbar", false);
      this.showSearch = true;
      // this.JSBridge.callHandler( 'switchNavbar', false );
      // this.$router.push( 'search' );
    },
    cancelSearch() {
      this.showSearch = false;
    },
    updateLayout() {
      _reqAniTimer = requestAnimationFrame(this.updateLayout);
      // 词条切换
      if (this.keys.length) {
        if (_reqCount === 0) {
          this.key = this.keys[0];
        } else if (_reqCount === 300) {
          this.searchAni = "ani-rollup";
        } else if (_reqCount === 360) {
          _reqCount = 0;
          this.index = this.index < this.keys.length - 1 ? this.index + 1 : 0;
          this.key = this.keys[this.index];
          this.searchAni = "ani-rollup2"; // 'ani-fadein';
        }
        _reqCount++;
      }
      // 搜索栏响应
      const sy = window.scrollY;
      if (sy > step1) {
        const cval = sy - step1;
        if (!_domtg) {
          _dombg = document.querySelector(".index--effect");
          _domrt = document.querySelector(".index--search");
          _domtg = document.querySelector(".mask");
          _domic = document.querySelector(".search");
        }
        if (cval < step3) {
          const opacity = 1 - cval / step2;
          _domtg.style.cssText = `transform: translateY( ${cval}px )`;
          _dombg.style.opacity = opacity;
          _domic.style.opacity = opacity;
        } else {
          _domtg.style.cssText = `transform: translateY( ${step3}px );`;
          const height = cval - step3;
          if (height < step5) {
            _domtg.style.cssText += `height: ${step4 - height}px`;
            if (this.locked) {
              _domrt.className = _domrt.className.substr(0, _domrt.className.indexOf(" fixed"));
              this.locked = false;
            }
          } else {
            _domtg.style.cssText += "height: 0";
            if (!this.locked) {
              _domrt.className += " fixed";
              this.locked = true;
            }
          }
          _dombg.style.opacity = 0;
          _domic.style.opacity = 0;
        }
      } else if (_domtg) {
        _domtg.style.cssText = "";
        _dombg.style.opacity = 1;
        _domic.style.opacity = 1;
        if (this.locked) {
          _domrt.className = _domrt.className.substr(0, _domrt.className.indexOf(" fixed"));
          this.locked = false;
        }
      }
    },
    viewNews(item) {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.HOME.TAP_ARTICLE);
      this.$router.push("/article/" + item.id);
    },
    getAni(i) {
      if (!this.aniMap[i]) {
        this.aniMap[i] = "ani-" + random(1, 6);
      }
      return this.aniMap[i];
    },
    getStyle(i) {
      if (!this.styMap[i]) {
        const x = random(400, 600);
        const y = random(400, 600);
        this.styMap[i] = {
          transform: `translate( ${x & 1 ? x : -x}%, ${y & 1 ? y : -y}% )`, // scale( ${random( 5, 10 ) / 10} )
          opacity: 1 // random( 10, 10 ) / 10
        };
      }
      return this.styMap[i];
    }
  },
  watch: {
    homeData(data) {
      this.keys = data.keys;
      this.words = data.words;
      this.hots = data.hots;
      this.news = data.news;
      this.loading = false;
      this.updateLayout();
      // 通知页面已就绪
      this.JSBridge.callHandler("ready", true);
    },
    showSearch(val) {
      if (!val) this.JSBridge.callHandler("setStatebarTheme", "white");
    }
  },
  created() {
    if (window.location.host.includes('https://webclient.sscanmou.com') && !window.isClient) {
      window.location.href = '/drainage.html?channelId=baidu_shoulu'
      // location.replace('/drainage.html?channelId=baidu_shoulu')
      return
    }
    this.loadData();
  },
  mounted() {
    this.JSBridge.callHandler("switchNavbar", true);
    this.JSBridge.callHandler("switchBackBtn", false);
    this.JSBridge.callHandler("switchShareBtn", false);
    this.JSBridge.callHandler("setStatebarTheme", "white");
    // 高度计算
    step1 += window.isClient ? 0 : 30;
  },
  beforeDestroy() {
    cancelAnimationFrame(_reqAniTimer);
    _reqCount = 0;
    _domtg = null;
    step1 -= window.isClient ? 0 : 30;
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.JSBridge.callHandler("switchNavbar", true);
      vm.JSBridge.callHandler("switchBackBtn", false);
      vm.JSBridge.callHandler("switchShareBtn", false);
      vm.JSBridge.callHandler("setStatebarTheme", "white");
    });
  }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

$panelHeight: 70vw;
$searchIcon: 11.47vw;
$opacity: 0.6;

.index {
  position: relative;
  height: 100vh;
  background: linear-gradient(to bottom, #3c7dd4, #75b0ff 40%) fixed;
  &.noscroll {
    overflow: hidden !important;
  }

  #{&}--header {
    opacity: 1;
  }

  #{&}--effect {
    position: absolute;
    overflow: hidden;
    top: 0;
    width: 100%;
    height: $panelHeight;
    -webkit-pointer-events: none;
    pointer-events: none;

    .card {
      position: absolute;
      padding: 4.8vw 4.8vw 4.8vw 9.2vw;
      font-size: $font_293;
      border-radius: $radius-s;
      color: white;
      background: rgba(white, 0.25) url(/static/icon/symbol.png) no-repeat 4.8vw center;
      background-size: $font_347;
      opacity: 0;
    }
  }

  #{&}--content {
    position: absolute;
    top: $panelHeight;
    width: 100%;
    // min-height: calc( 100vh - #{ $panelHeight } );
    min-height: 100vh;
    // height: calc( 100vh - #{ $panelHeight } + #{ $headerHeight } );
    // background-color: white;
    background-color: #f7f6fb;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  #{&}--tabs {
    // height: 100%;

    ::v-deep .van-tab--active {
      display: block;
      width: 100%;
      text-align: left;
      padding-left: $padding * 2;
      background: url(/static/icon/hot.png) no-repeat $padding center;
      background-size: 4.53vw 4.53vw;
    }

    ::v-deep .van-tabs__content {
      overflow: hidden;
      overflow-y: auto;
      box-shadow: inset 0 1px $c-line;
      // height: calc( 100vh - #{ $panelHeight } - #{ $tabHeight } );
    }

    ::v-deep .van-hairline--top-bottom::after {
      border-width: 1px 0 0 0;
      border-color: $c-line;
    }

    ::v-deep .van-tab__text--ellipsis {
      line-height: 44px;
    }

    .topic {
      position: relative;
      img {
        width: 100%;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 50%;
      }
    }
  }

  #{&}--search {
    position: absolute;
    // background-color: black;
    top: 0;
    width: 100%;
    z-index: 99;

    &.fixed {
      .panel {
        position: fixed;
        height: auto;
        background-color: #5391e5;
        // box-shadow: 0 1vw 1vw rgba($c-main, .24);
      }
      .mask {
        position: static;
        bottom: auto;
        transform: translateY(0) !important;
        margin-top: $headerMargin;
        margin-bottom: $headerMargin;
      }
    }

    .panel {
      position: relative;
      width: 100%;
      height: $panelHeight;
      transition: box-shadow, background-color 0.5s, 0.25s;
    }

    .mask {
      @extend .l-flex;
      position: absolute;
      overflow: hidden;
      width: 87.2vw;
      height: 18.67vw;
      min-height: 10.67vw;
      bottom: 12.8vw;
      margin-left: 6.4vw;
      background-color: white;
      font-size: $font_373;
      font-weight: bold;
      transition: translateY 0.02s;

      .fa-search {
        &::before {
          content: "";
          display: block;
          height: 100%;
          background: url(/static/icon/search@64.png) no-repeat center center;
          background-size: 6.5vw;
        }
        width: $searchIcon;
        height: $searchIcon;
        background: linear-gradient(to bottom, $c-main, lighten($c-main, 15%));
        box-shadow: 0 4vw 4vw rgba($c-main, 0.12);

        &.fixed {
          position: absolute;
          right: $margin;
          box-shadow: none;
          background: transparent;
          &::before {
            content: "";
            display: block;
            height: 100%;
            background: url(/static/icon/search@64_blue.png) no-repeat center center;
            background-size: 6.5vw;
          }
        }
      }

      .keyword {
        padding-left: 6vw;
        code {
          position: relative;
          @include singleLine;
          display: block;
          width: 65vw;
          padding: 0.5vw 0 0.5vw 18vw;
          font-size: $font_373;
          line-height: $font_533;
          height: $font_533;
          color: #518cdb;
          box-sizing: border-box;
          &::before {
            content: "大家在搜";
            position: absolute;
            left: 0;
            top: 0.5vw;
            color: $c-text;
          }
        }
        &.ani-rollup {
          animation: rollup 1s 1 ease-out forwards;
        }
        &.ani-rollup2 {
          animation: rollup2 1s 1 ease-out forwards;
        }
        &.ani-fadein {
          opacity: 0;
          animation: fadein 0.5s 1 ease-out forwards;
        }
      }
    }
  }

  .ani-1 {
    animation: ani 10s infinite 0.5s ease-out; // alternate
  }
  .ani-2 {
    animation: ani 9s infinite 0.5s ease-out;
  }
  .ani-3 {
    animation: ani 8s infinite 0.5s ease-out;
  }
  .ani-4 {
    animation: ani 7s infinite 0.5s ease-out;
  }
  .ani-5 {
    animation: ani 6s infinite 0.5s ease-out;
  }
  .ani-6 {
    animation: ani 5s infinite 0.5s ease-out;
  }
  @keyframes ani {
    to {
      transform: translate(calc(50vw - 50%), calc(#{$panelHeight} / 2 + 25%));
      opacity: $opacity;
    }
  }
  @keyframes rollup {
    to {
      transform: translateY(-200%) scaleY(0.75);
      opacity: 0;
    }
  }
  @keyframes rollup2 {
    from {
      transform: translateY(200%) scaleY(0.75);
      opacity: 0;
    }
    to {
      transform: translateY(0) scaleY(1);
      opacity: 1;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  ::v-deep .van-tabs__line {
    opacity: 0;
  }
}
</style>
