<template>
    <div class="ranking">
        <ul class="ranking-flex tableBg">
            <li class="num tableTitle">序号</li>
            <li class="info tableTitle">行政区</li>
            <li class="info tableTitle">门店数(家)</li>
            <li class="info tableTitle">占比(%)</li>
            <li class="info tableTitle">人均(元)</li>
        </ul>
        <ul class="ranking-flex" :class="{ autoBg: idx % 2 != 0 }" v-for="(item, idx) in tableData" :key="item.key">
            <li class="num" :class="{ oneColor: idx == 0, twoColor: idx == 1, threeColor: idx == 2 }">
                <i>{{ idx + 1 }}.</i>
            </li>
            <li class="info">
                {{ item.name }}
            </li>
            <li class="info">
                {{ item.count || "-" }}
            </li>
            <li class="info">
                {{ item.radio || "-" }}
            </li>
            <li class="info">
                {{ item.ext1 || "-" }}
            </li>
        </ul>
        <p class="no-thing" v-if="tableData && tableData.length === 0">暂无数据</p>
    </div>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.ranking {
    background: #fff;
    border-radius: 1.33vw;
    border: 1px solid #e7e7e7;
    margin-top: 1.07vw;

    .tableTitle {
        font-size: 3.47vw !important;
        color: #8893a7 !important;
        font-weight: 400 !important;
    }
    .tableBg {
        background: #eeeef2 !important;
    }
    .autoBg {
        background: #f7f7f7 !important;
    }
    .ranking-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 5.33vw;
        width: 100%;
        font-size: 3.47vw;
        color: #0d1525;
        text-align: center;
        padding: 3.2vw;
        background: #fff;
        box-sizing: border-box;
        .num {
            flex: 0 0 8.53vw;
            font-weight: bold;
        }
        .oneColor {
            color: #ff3627;
        }
        .twoColor {
            color: #ff8702;
        }
        .threeColor {
            color: #ffbb26;
        }
        .info {
            flex: 1;
        }
    }
    .no-thing {
        font-size: 3.2vw;
        color: #999;
        text-align: center;
        padding: 4.27vw 0;
    }
}
</style>
