<template>
  <div class="wrapper">
        <StateBar bgcolor="white" />
        <TitleHeader title="华夏龙商贷" color="black" :back="true">
        </TitleHeader>
        <AutoView :footer="true">
            <div class="scrollWrapper">
                <div class="info">
                    <div class="top">
                        <div class="title">个人信息</div>
                    </div>
                    <div class="bottom">
                        <div class="fieldItem">
                            <div class="label required">姓名</div>
                            <van-field
                            v-model="userName"
                            placeholder="根据银行要求需填写真实姓名"
                            />
                        </div>
                        <div class="fieldItem">
                            <div class="label required">身份证号码</div>
                            <van-field
                            v-model="IDCard"
                            maxlength="18"
                            @input="validateIDCard"
                            placeholder="根据银行要求需填写真实身份证号"
                            />
                            <div class="errMsg" v-if="IDCardError">请填写正确的身份证号码</div>
                        </div>
                        <div class="fieldItem">
                            <div class="label required">手机号码</div>
                            <van-field
                            v-model="phone"
                            maxlength="11"
                            type="tel"
                            @input="validateTel"
                            placeholder="根据银行要求需填写真实手机号"
                            />
                            <div class="errMsg" v-if="telError">请填写正确的手机号码</div>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="top">
                        <div class="title">店铺信息</div>
                    </div>
                    <div class="bottom">
                        <div class="fieldItem">
                            <div class="label required">店铺名称/品牌</div>
                            <van-field
                            v-model="storeName"
                            placeholder="填写店面名称/品牌"
                            />
                        </div>
                        <div class="fieldItem">
                            <div class="label required">店铺类型</div>
                            <van-radio-group class="radioGroup" v-model="storeType">
                                <van-Radio class="radioItem" name="1" icon-size="13px">
                                    加盟
                                    <template #icon="props">
                                        <div :class="props.checked ? 'img-icon avtive' : 'img-icon'"/>
                                    </template>
                                </van-Radio>
                                <van-Radio class="radioItem" name="2" icon-size="13px">
                                    自营
                                    <template #icon="props">
                                        <div :class="props.checked ? 'img-icon avtive' : 'img-icon'"/>
                                    </template>
                                </van-Radio>
                            </van-radio-group>
                        </div>
                        <div class="fieldItem">
                            <div class="label required">经营行业</div>
                            <div class="currentIndustry" @click="selectIndustry">
                                <div class="industryName">{{industryName}}</div>
                                <div class="pickerIcon"></div>
                            </div>
                        </div>
                        <div class="fieldItem">
                            <div class="label required">店铺数量</div>
                            <van-field
                            type="tel"
                            v-model="storeNumber"
                            @input="validateStoreNumber"
                            placeholder="填写店铺数量"
                            />
                            <div class="errMsg" v-if="storeNumberErr">请填写正确的店铺数量</div>
                        </div>
                        <div class="fieldItem">
                            <div class="label required">日营业额</div>
                            <van-field
                            type="tel"
                            v-model="turnover"
                            @input="validateTurnover"
                            placeholder="填写日营业额"
                            />
                            <div class="errMsg" v-if="turnoverErr">请填写正确的日营业额</div>
                        </div>
                        <div class="fieldItem">
                            <div class="label required">店铺面积</div>
                            <van-field
                            type="tel"
                            v-model="storeArea"
                            @input="validateStoreArea"
                            placeholder="填写店铺面积"
                            />
                            <div class="errMsg" v-if="storeAreaErr">请填写正确的店铺面积</div>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="top">
                        <div class="title">企业工商信息</div>
                    </div>
                    <div class="bottom">
                        <div class="fieldItem">
                            <div class="label required">企业名称</div>
                            <van-field
                            v-model="enterpriseName"
                            placeholder="填写企业名称"
                            />
                        </div>
                        <div class="fieldItem">
                            <div class="label">统一社会信用代码</div>
                            <van-field
                            v-model="socialCreditCode"
                            maxlength="18"
                            placeholder="根据银行要求需填写统一社会信用代码"
                            />
                        </div>
                        <div class="fieldItem">
                            <div class="label required">经营类型</div>
                            <van-radio-group class="radioGroup" v-model="businessType">
                                <van-Radio class="radioItem" name="1" icon-size="13px">
                                    个体工商户
                                    <template #icon="props">
                                        <div :class="props.checked ? 'img-icon avtive' : 'img-icon'"/>
                                    </template>
                                </van-Radio>
                                <van-Radio class="radioItem" name="2" icon-size="13px">
                                    有限责任公司
                                    <template #icon="props">
                                        <div :class="props.checked ? 'img-icon avtive' : 'img-icon'"/>
                                    </template>
                                </van-Radio>
                            </van-radio-group>
                        </div>
                        <div class="fieldItem">
                            <div class="label">营业期限</div>
                            <div class="dataPicker">
                                <div :class="startTime ? 'dateItem selected' : 'dateItem'" @click="clickStartPickerDate">{{startTime || "填写营业期限"}}</div>
                                <span>-</span>
                                <div :class="endTime ? 'dateItem selected' : 'dateItem'" @click="clickEndPickerDate">{{endTime || "填写营业期限"}}</div>
                            </div>
                        </div>
                        <div class="fieldItem">
                            <div class="label">法定代表人</div>
                            <van-field
                            v-model="legalRepresentative"
                            placeholder="请填写法定代表人"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AutoView>
        <SoltFooter>
            <div class="submitFooter">
                <van-button type="info" block class="s-radius__s" :disabled="!disabled" @click="clickSubmitHandle">提交审核资料</van-button>
            </div>
        </SoltFooter>
        <van-popup v-model="showIndustryPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                :default-index="0"
                @cancel="showIndustryPicker = false"
                @confirm="onIndustryConfirm"
            />
        </van-popup>
        <van-popup v-model="showStartPickerDate" position="bottom">
            <van-datetime-picker v-model="currentTime" type="date" @cancel="onCancelStartDate" @confirm="onConfirmStartDate" :formatter="formatter"/>
        </van-popup>
        <van-popup v-model="showEndPickerDate" position="bottom">
            <van-datetime-picker v-model="currentTime" type="date" @cancel="onCancelEndDate" @confirm="onConfirmEndDate"  :formatter="formatter"/>
        </van-popup>
  </div>
</template>

<script>
import { formatDate } from '@/utils/filters';
import { isPhoneNo } from '@/utils/index';
export default {
    data() {
        return {
            type: "2", // 华夏银行
            userName: "",
            IDCard: "",
            IDCardError: false,
            phone: "",
            telError: false,
            storeNumberErr: false,
            turnoverErr: false,
            storeAreaErr: false,
            storeName: "",
            storeType: "1",
            industryName: "餐饮",
            storeNumber: "",
            turnover: "", // 日营业额
            storeArea: "",
            startTime: "",
            endTime: "",
            columns: ["餐饮", "零售", "教育", "休闲娱乐", "医疗健康", "丽人", "运动健身"],
            showIndustryPicker: false,
            showStartPickerDate: false,
            showEndPickerDate: false,
            currentTime: new Date(),
            enterpriseName: "",
            socialCreditCode: "",
            businessType: "1",
            legalRepresentative: ""
        }
    },
    computed: {
        disabled: function() {
            return this.userName && this.IDCard && !this.IDCardError && this.phone && !this.telError && this.storeName && this.industryName && this.storeNumber && this.turnover && this.storeArea && this.enterpriseName && !this.storeNumberErr && !this.turnoverErr && !this.storeAreaErr
        }
    },
    watch: {
        userName: function() {
            this.userName =  this.userName.replace(/[^\u4e00-\u9fa5]/g, '');
        },
        IDCard: function() {
            this.IDCard = this.IDCard.replace(/[\W]/g, '')
        },
        storeNumber: function() {
            this.storeNumber = this.storeNumber.replace(/[^0-9]/g, "")
        },
        turnover: function() {
            this.turnover = this.turnover.replace(/[^0-9]/g, "")
        },
        storeArea: function() {
            this.storeArea = this.storeArea.replace(/[^0-9]/g, "")
        },
        enterpriseName: function() {
            this.enterpriseName = this.enterpriseName.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "")
        },
        socialCreditCode: function() {
            this.socialCreditCode = this.socialCreditCode.replace(/[\W]/g, '')
        }
    },
    methods: {
         formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            return val;
        },
        validateTel(value) {
            this.phone = value;
            this.telError = !isPhoneNo(value)
        },
        validateIDCard(code){
            const city = {11:'北京',12:'天津',13:'河北',14:'山西',15:'内蒙古',21:'辽宁',22:'吉林',23:'黑龙江 ',31:'上海',32:'江苏',33:'浙江',34:'安徽',35:'福建',36:'江西',37:'山东',41:'河南',42:'湖北 ',43:'湖南',44:'广东',45:'广西',46:'海南',50:'重庆',51:'四川',52:'贵州',53:'云南',54:'西藏 ',61:'陕西',62:'甘肃',63:'青海',64:'宁夏',65:'新疆',71:'台湾',81:'香港',82:'澳门',91:'国外'};
            this.IDCardError = !(code && /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code) && city[code.substr(0,2)]);
        },
        validateStoreNumber(value) {
            const storeNumberErr = this.validateNumber(value)
            this.storeNumberErr = storeNumberErr
        },
        validateTurnover(value) {
            const turnoverErr = this.validateNumber(value)
            this.turnoverErr = turnoverErr
        },
        validateStoreArea(value) {
            const storeAreaErr = this.validateNumber(value)
            this.storeAreaErr = storeAreaErr
        },
        validateNumber(value) {
            return (Number(value) < 0 || Number(value) > Math.pow(2, 32))
        },
        selectIndustry() {
            this.showIndustryPicker = true;
        },
        onConfirmStartDate(e) {
            const startFormatDate = formatDate(e)
            this.startTime = startFormatDate.split(' ')[0]
            this.showStartPickerDate = false
        },
        onCancelStartDate() {
            this.showStartPickerDate = false
        },
        onConfirmEndDate(e) {
            const endFormatDate = formatDate(e)
            console.log(endFormatDate)
            this.endTime = endFormatDate.split(' ')[0]
            this.showEndPickerDate = false
        },
        onCancelEndDate() {
            this.showEndPickerDate = false
        },
        clickStartPickerDate() {
            this.showStartPickerDate = true
        },
        clickEndPickerDate() {
            this.showEndPickerDate = true
        },
        onIndustryConfirm(value) {
            this.industryName = value;
            this.showIndustryPicker = false;
        },
        clickSubmitHandle() {
            const params = {
                type: this.type,
                name: this.userName,
                idCardNo: this.IDCard,
                phone: this.phone,
                shopName: this.storeName,
                shopType: this.storeType,
                categoryName: this.industryName,
                shopCount: this.storeNumber,
                daySale: this.turnover,
                shopArea: this.storeArea,
                enterpriseName: this.enterpriseName,
                creditCode: this.socialCreditCode,
                businessType: this.businessType,
                startTime: this.startTime,
                endTime: this.endTime,
                legalPerson: this.legalRepresentative
            }
            // console.log(params)
            this.JSBridge.BTNCLICK( 10168, '', 82000, 82001 ),
            this.HTTP.huaxiaUpload(params).then(() => {
                this.$toast.success(
                    {
                    position: top,
                    message: "提交成功"
                })
                this.swRouter('/channel/huaxiaLoanSuccess')
            }).catch(() => {
                this.$toast.fail(
                    {
                    position: top,
                    message: "提交失败，请检查提交内容是否正确"
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.wrapper {
    height: 100vh;
    overflow-x: hidden;
    background-color: #fff;
}
.scrollWrapper {
    padding: 4vw;
    .info {
        padding: 4vw;
        .top {
            display: flex;
            justify-content: center;
            align-items: center;
            .title {
                color: #333333;
                margin: 0 2.667vw;
                position: relative;
                font-weight: 600;
                &::after {
                    content: "";
                    width: 17.3vw;
                    height: 0.4vw;
                    position: absolute;
                    top: 50%;
                    margin-left: 2.667vw;
                    background-image: linear-gradient(to right, rgba(241, 149, 154, 1), rgba(241, 149, 154, 0))
                }
                &::before {
                    content: "";
                    width: 17.3vw;
                    height: 0.4vw;
                    position: absolute;
                    top: 50%;
                    left: -2.667vw;
                    transform: translate(-100%, 0);
                    background-image: linear-gradient(to right, rgba(241, 149, 154, 0), rgba(241, 149, 154, 1))
                }
            }
        }
        .bottom {
            .fieldItem {
                .label {
                    font-size: 3.467vw;
                    font-weight: 600;
                    padding: 2vw;
                }
                .required {
                    position: relative;
                    &::after {
                        content: "*";
                        position: absolute;
                        left: 0;
                        color: rgba(255, 89, 0, 1);
                    }
                }
                ::v-deep .van-cell {
                    background-color: #f7f7f7;
                    padding: 0 2vw;
                    margin: 2.667vw 0;
                    border-radius: 1.333vw;
                    border: 0.133vw solid #ebeaee;
                    .van-field__control {
                        height: 10.667vw;
                    }
                }
                .radioGroup {
                    font-size: 3.2vw;
                    display: flex;
                    padding: 2.667vw 2vw;
                    height: 10.667vw;
                    .radioItem {
                        min-width: 30vw
                    }
                    .img-icon {
                        width: 3.467vw;
                        height: 3.467vw;
                        border-radius: 50%;
                        border: 0.267vw solid #BDBDBD;
                        box-sizing: border-box;
                    }
                    .avtive {
                        background-image: url("/static/icon/radio_active.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        border: none;
                    }
                }
                .dataPicker {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 10.667vw;
                    padding: 2.667vw 0;
                    font-weight: 600;
                    span {
                        margin: 0 1.33vw;
                        color: #1D2233;
                    }
                    .dateItem {
                        width: 40vw;
                        font-size: 3.733vw;
                        height: 10.667vw;
                        line-height: 10.667vw;
                        background-color: #f7f7f7;
                        border-radius: 1.333vw;
                        border: 0.133vw solid #ebeaee;
                        color: #BDBDBD;
                        font-weight: normal;
                        text-align: center;
                    }
                    .selected {
                        color: #333333;
                    }
                }
                .errMsg {
                    color: red;
                    font-size: 2.5vw;
                    margin-left: 2.667vw;
                }
                .currentIndustry {
                    height: 10.667vw;
                    background: #F7F7F7;
                    padding: 0 2vw;
                    margin: 2.667vw 0;
                    border-radius: 1.333vw;
                    border: 0.133vw solid #ebeaee;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .industryName {
                        font-size: 3.467vw;
                        color: #333333;
                    }
                    .pickerIcon {
                        width: 2.4vw;
                        height: 1.733vw;
                        background-image: url("/static/icon/input_icon-choice.png");
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
.submitFooter {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    border: 0.133vw solid #EBEAEE;
    box-shadow: 0 -0.267vw 0.8vw 0 #EBEAEE;
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
</style>
