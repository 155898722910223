<template>
    <div class="brand_num">
        <h3>品牌经营情况</h3>
        <div class="brand_num_box">
            <div class="num_swrap">
                <div class="num_swrap_title">
                    <span class="text">5</span><span class="unit">家</span>
                </div>
                <p>已调研门店数量</p>
            </div>
            <div class="num_swrap">
                <div class="num_swrap_title">
                    <span class="text">≈76</span><span class="unit">单</span>
                </div>
                <p>店均月销量</p>
            </div>
            <div class="num_swrap">
                <div class="num_swrap_title">
                    <span class="text">≈76</span><span class="unit">万+</span>
                </div>
                <p>店均月销售额</p>
            </div>

            <div class="num_swrap">
                <div class="num_swrap_title">
                    <span class="text">4.8</span><span class="unit"></span>
                </div>
                <p>平均评分</p>
            </div>
            <div class="num_swrap">
                <div class="num_swrap_title">
                    <span class="text">78.6</span><span class="unit">%</span>
                    <img class="praise" src="/static/icon/praise.png" alt="">
                </div>
                <p>平均好评率</p>
            </div>
            <div class="num_swrap">
                <div class="num_swrap_title">
                    <span class="text">3.1</span><span class="unit">%</span>
                </div>
                <p>差评率</p>
            </div>

        </div>
        <div class="brand_sales">
            <div>查看</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "brandSales",
        data() {
            return {
                
            }
        },
        methods: {
            // 跳转到公司审查概况
            openCompany() {
                this.swRouter( '/brand/brand_review' )
            }, 
        }
    }

</script>

<style lang="scss" scoped>
    @import "@/styles/index.scss";
    // 品牌销售情况
    .brand_num{
        background: #fff;
        width: 100%;
        height: auto;
        padding: 4.53vw 4vw;
        box-sizing: border-box;
        margin-bottom: 2.67vw;
        h3{
            font-size: 4.53vw;
            font-weight: bold;
            color: #1D2233;
            line-height: 6.4vw;
        }
        .brand_num_box{
            margin-top: 4vw;
            font-size: 0;
            vertical-align: middle;
            width: 100%;
            .num_swrap{
                width: calc(33.33% - 1.6vw);
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                border-radius: 1.87vw;
                padding: 2.67vw 1.33vw;
                box-sizing: border-box;
                background: #F4F8FF;
                .num_swrap_title{
                    font-size: 0;
                    vertical-align: middle;
                    .text{
                        font-size: 4.8vw;
                        font-weight: bold;
                        color: #333333;
                        line-height: 5.6vw;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .unit{
                        font-size: 3.47vw;
                        display: inline-block;
                        vertical-align: bottom;
                        position: relative;
                        top: -0.53vw;
                    }
                    .praise{
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 1.06vw;
                        width: 4vw;
                        height: 4vw;
                        position: relative;
                        top: -0.27vw;
                    }
                }
                p{
                    font-size: 2.93vw;
                    margin-top: 1.2vw;
                    color: #999999;
                    line-height: 4vw;
                }
                &:nth-child(1),&:nth-child(2),&:nth-child(3) {
                    background: #FFF8F5;
                    margin-bottom: 2.4vw;
                }
                &:nth-child(2) {
                    margin: 0 2.4vw 2.4vw;
                }
                &:nth-child(5) {
                    margin: 0 2.4vw;
                }
            }
        }
        .brand_sales{
            margin-top: 4.53vw;
            height: 42.67vw;
            background: url("/static/img/new_brandBo.png") no-repeat center;
            background-size: 100% 100%;
            width: 100%;
            position: relative;
            div{
                width: auto;height: auto;
                padding: 2.27vw 4.4vw;
                border-radius: 1.33vw;
                background: #347FFF;
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 3.73vw;
                line-height: normal;
            }
        }
    }
</style>