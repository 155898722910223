<template>
  <div class="pay">
    <StateBar bgcolor="white" />
    <TitleHeader title="套餐详情" :back="true"></TitleHeader>

    <AutoView :header="true" :footer="true" class="pay--view">
      <div v-if="entity" class="l-panel s-radius__xs">
        <h3 class="title">{{ entity.name }}</h3>
        <!-- 套餐介绍 -->
        <p class="s-tips">{{ entity.remark }}</p>
        <!-- 套餐价格 -->
        <div class="price">
          <span class="new"><sub>￥</sub>{{ entity.price }}</span>
          <strike class="old">{{ entity.salePrice }}</strike>
<!--          <i v-if="entity.tag" class="tag">&nbsp;{{ entity.tag }}&nbsp;</i>-->
        </div>
        <!-- 套餐内容 -->
        <h4 class="subTitle">套餐包含</h4>
        <div v-if="details && details.items" class="list">
          <PackageCard
            v-for="(item, index) in details.items"
            :key="item.id"
            :class="{ 'f-vmt': index > 0 }"
            @tap="onTap"
            mode="base"
            :showCheck="false"
            :item="item"
          />
        </div>
        <h4 v-if="entity.introduce" class="subTitle">套餐包介绍</h4>
        <div v-if="entity.introduce" @click="onClick" v-html="entity.introduce" class="introduce-wrap"></div>
        <!-- 有效期 -->
        <h4 class="subTitle">有效期</h4>
        <div class="s-tips">购买后立即生效，生效后{{ details.expireDays || "---" }}天过期。</div>
        <!-- 使用规则 -->
        <h4 class="subTitle">使用规则</h4>
        <p class="s-tips" v-html="convRule(details.usageRule || '无')"></p>
      </div>
    </AutoView>

    <SoltFooter class="pay--footerBar">
      <div class="pay--footer">
        <!-- 9.9元换购 -->
        <div class="exchange-tip" v-if="entity && entity.exchangeActivity"></div>
        <van-button type="warning" block @click="onTap">立即购买</van-button>
      </div>
    </SoltFooter>

    <!-- 全局加载遮罩-->
    <LoadingOverlay :show="loading" />
  </div>
</template>

<script>
import PackageCard from "@/components/card/package";
import { ImagePreview } from "vant";

export default {
  name: "pay",
  components: {
    PackageCard,
  },
  data() {
    return {
      loading: true, // 数据加载
      timer: 0,
      id: 0, // 商品ID
      entity: null, // 商品对象实体
    };
  },
  methods: {
    onClick(e) {
      if (e.target && e.target.nodeName === "IMG") {
        ImagePreview({
          images: [e.target.src],
          loop: false,
          showIndex: false,
          closeable: false,
          onClose: () => {},
        });
      }
    },
    loadData() {
      // 参数设置
      const id = this.$route.params.id;
      if (!id) {
        this.$router.push("/");
        return;
      }
      if (id === this.id) return;
      this.id = id;
      // 查询商品信息
      this.loading = true;
      this.HTTP.getQuestionInfo(this.id)
        .then((res) => {
          this.entity = res;
          this.loading = false;
          console.log('detail', res);
        })
        .catch((e) => {
          this.$toast("网络不稳定，正在重试！");
          setTimeout(() => {
            location.reload();
          }, 5000);
        });
    },
    onTap() {
      this.swRouter("/aiPay/" + this.id);
    },
    convRule(value) {
      return value ? value.replace(/\r\n|\n/g, "<br>") : null;
    },
  },
  computed: {
    details() {
      return this.entity ? this.entity.detail || {} : {};
    },
  },
  watch: {
    "$route.params"() {
      this.loadData();
    },
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
    // 加载商品数据
    this.loadData();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

.pay {
  min-height: 100vh;
  background-color: $c-pageBg2;

  #{&}--view {
    padding: $padding_4;

    .l-panel {
      position: relative;
      padding: $padding_3;

      .title {
        font-size: $font_453;
        line-height: 6.4vw;
      }

      .subTitle {
        margin-top: $padding;
        font-size: $font_347;
        line-height: 4.93vw;
      }

      .s-tips {
        margin-top: 2vw;
        color: $c-info;
        font-size: $font_320;
        line-height: $font_440;
      }

      .price {
        margin-top: $padding;
        @include font($c-price, $font_480, bold);
        line-height: $font_400;

        sub {
          vertical-align: bottom;
          font-size: $font_293;
        }
        strike {
          font-weight: normal;
          font-size: $font_293;
          margin-left: 2vw;
          color: $c-info;
        }
        .tag {
          box-shadow: 0 0 0 0.5px $c-warn;
          font-style: normal;
          font-size: $font_267;
          border-radius: 0.5vw;
          margin-left: 2vw;
        }
      }

      .list {
        margin-top: $margin;
      }

      .line {
        margin: $padding1 0 $margin;
      }

      .introduce-wrap {
        position: relative;
        margin-top: 2vw;
      }
    }
  }

  #{&}--footer {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    position: relative;

    .exchange-tip{
      top: -2.13vw;
      right: 10vw;
      position: absolute;
      width: 26.13vw;
      height: 6.13vw;
      z-index: 2;
      background: url('/static/img/exchange-tip.png') center / contain no-repeat;
    }

    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      font-weight: bold;
      line-height: normal;
      background: linear-gradient(to right, #ff8000, #ff5900);
    }
  }

  #{&}--popup {
    .title {
      text-align: center;
      font-size: $font_400;
    }
    .price {
      padding: $margin 0;
      text-align: center;
      @include font($c-notice, $font_533, bold);
    }
    .view {
      padding: $margin 0 $padding;
    }
    ::v-deep .van-button {
      font-size: $font_400;
      line-height: normal;
      font-weight: bold;
    }
  }
}
</style>
<style lang="scss">
.introduce-wrap img {
  max-width: 100%;
  border: 0;
  box-sizing: border-box;
}
.introduce-wrap p {
  word-wrap: break-word;
}
</style>
