<template>
    <div class="report-chart-container">
        <div class="report-chart-mask"></div>
        <div ref="chartItemRef" class="report-chart-item"></div>
    </div>
</template>

<script>
import echarts from "echarts";

export default {
    props: {
        dataObject: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartItem: undefined,
        };
    },
    mounted() {
        this.load(this.dataObject);
    },
    methods: {
        load(data) {
            if (data == undefined) {
                return;
            }
            let newArr = [];
            data.forEach((item) => {
                let obj = {
                    name: item.name.replace("城市", ""),
                    value: Number(item.count),
                };
                newArr.push(obj);
            });
            this.intiChart(newArr);
        },
        intiChart(arrObj) {
            let element = this.$refs.chartItemRef;
            if (element == undefined) {
                return;
            }
            if (this.chartItem == undefined) {
                this.chartItem = echarts.init(element);
            }
            let option = {
                color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"],
                gradientColor: ["#f6efa6", "#d88273", "#bf444c"],
                title: {
                    text: "行业\n占比",
                    left: "center",
                    top: "center",
                    textStyle: {
                        fontSize: 9,
                        color: "#676C84",
                    },
                },
                series: [
                    {
                        type: "pie",
                        radius: [20, 56],
                        left: "center",
                        roseType: "area",
                        itemStyle: {
                            borderColor: "#fff",
                            borderWidth: 1,
                        },
                        label: {
                            formatter: "{name|{b}} {size|{d}%}\n{size|{c}家}",
                            rich: {
                                name: {
                                    fontSize: 11,
                                    color: "#333",
                                    lineHeight: 22,
                                },
                                size: {
                                    fontSize: 11,
                                    color: "#999",
                                },
                            },
                        },
                        // { value: 30, name: "rose 1", size: "8家" },
                        data: arrObj,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
            this.chartItem.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
.report-chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.report-chart-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.report-chart-item {
    position: relative;
    width: 100%;
    height: 100%;
}
</style>
