<template>
    <div class="invite l-panel">
        <h3 class="f-tof">{{ item.username }}</h3>
        <p class="f-tof">{{ item.gmtCreate | convDate }}</p>
        <span class="f-tof">{{ item.inviteDescribe || '---' }}</span>
    </div>
</template>

<script>
export default {
    name : 'invite',
    props: {
        item: Object,
    },
    filters: {
        convDate: function ( value ) {
            if ( ! value ) return '---';
            const ts = value.split( ' ' );
            return ts[ 0 ].split( '-' ).join( '.' ) + ' ' + ts[ 1 ].substr( 0, 5 );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.invite {
    position: relative;
    padding: 5.33vw!important;
    margin-bottom: 3.2vw;

    h3{
        font-size: $font_400;
        line-height: $font_560;
    }
    p {
        color: $c-info;
        font-size: $font_320;
        line-height: $font_400;
        margin-top: 1.6vw;
    }
    span {
        position: absolute;
        top: 8.5vw;
        right: 5.33vw;
        color: $c-info;
        font-size: $font_400;
        line-height: $font_560;
        font-weight: bold;
        color: $c-EBAC5F;
    }
}
</style>
