<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
    </router-view>
    <alicaptcha v-if="apiValidating && !isCloseValidatePopup"/>
  </div>
</template>

<script>
import VConsole from "vconsole";
// import wx from "weixin-js-sdk";
import { delCookie } from "@/utils/index.js"
import { commonConfigObj } from '@/constant'
import alicaptcha from '@/components/alicaptcha/index.vue';
import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    alicaptcha
  },
  computed: {
    ...mapGetters(['apiValidating','isCloseValidatePopup'])
  },
  watch: {
    $route(val) {
      // 小程序写入登录信息
      const token = val.query.token;
      if (typeof token === "string" && token.length === 32) {
        this.HTTP.setAuth(token);
      }
    },
  },
  beforeCreate() {
    // 初始化
    this.JSBridge.init();
    this.JSBridge.registerHandler("toast", (data, responseHandler) => {
      this.$toast(data || "What up?");
      responseHandler("JS Callback");
    });
    // 注册消息监听
    this.$bus.on("sscm-relogin", (data) => {
      if (window.isClient) {
        this.JSBridge.callHandler("clearLogin");
      } else {
          if(sessionStorage.getItem("platform")) {
            delCookie(`${sessionStorage.getItem("type")}_token`);
            if(sessionStorage.getItem("type") === "1") {
                location.href = `https://open-api.shop.ele.me/authorize?response_type=code&scope=all&state=sscm&client_id=zJW53JAukO&redirect_uri=https%3A%2F%2Fwebclient.sscanmou.com%2F%23%2Felement%2Fhome%2F1`
            } else {
                location.href = `https://open-api.shop.ele.me/authorize?response_type=code&scope=all&state=sscm&client_id=MskKMDxHHy&redirect_uri=https%3A%2F%2Fwebclient.sscanmou.com%2F%23%2Felement%2Fhome%2F2`
            }
          } else {
              this.$router.push("/download");
          }
      }
    });
    // 客户端或小程序挂载开发者工具
    if (process.env.NODE_ENV !== "production" && (window.isClient || window.isMini)) {
      // eslint-disable-next-line no-new
      new VConsole({ disableLogScrolling: false, defaultPlugins: ["system", "network"], maxLogNumber: 200 });
    }
    // 检测是否客户端
    if (window.isClient) {
      console.log("window.isClient:", window.isClient);
      // 尝试获得版本号
      this.JSBridge.callHandler("getVersion", null, function (code) {
        window._appCode = ~~code;
        console.log("getVersion:", window._appCode);
      });
      this.JSBridge.callHandler("getVersionCode", null, function (code) {
        window._appCode = ~~code;
        console.log("getVersionCode:", window._appCode);
      });
      // 尝试获取并写入登录信息
      this.JSBridge.callHandler("getAuthorization", null, (token) => {
        console.log("app get token:", token);
        this.HTTP.setAuth(token);
      });
      if (window.isIOS) {
        // 兼容iOS 处理token获取
        const ua = navigator.userAgent;
        const token = ua.substr(ua.indexOf("TOKEN/") + 6, 32);
        this.HTTP.setAuth(token);
      } else if (window.isAndroid && window["sscm"]) {
        // 兼容安卓 提供同步函数 处理获取延迟问题
        this.HTTP.setAuth(window.sscm.getToken());
      }
    } else {
      console.log("window.isClient:", window.isClient);
      let token = this.$route.query.token;
      // 处理小程序环境token undefined
      if (window.isMini && (!token || token == 'undefined' || token == 'null')) {
        token = commonConfigObj.wx_default_token
      }
      if (typeof token === "string" && token.length === 32) {
        this.HTTP.setAuth(token);
      } else {
        // WEB写入登录信息
        let localToken = window.localStorage.getItem('token')
        this.HTTP.setAuth(localToken);
      }
    }
    // 双击刷新
    // let timer = 0;
    // window.addEventListener( 'touchend', ( e ) => {
    //     if ( this.$route.path === '/discovery' ) return;
    //     const now = Date.now();
    //     if ( timer > now ) {
    //         location.reload( true );
    //         e.preventDefault();
    //         return false;
    //     } else {
    //         timer = now + 300;
    //     }
    // }, true );
  },
};
</script>
