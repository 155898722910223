<template>
	<div class="intention-form">
		<van-popup v-model="submitMaskType" closeable position="bottom" :style="{ height: '45%' }" @close="close">
			<div class="body-box">
				<div class="title">提交转让意向</div>
				<div class="form-box">
					<div class="form-item">
						<div class="label">姓名</div>
						<div class="value"><input type="text" v-model="form.name" placeholder="请输入姓名"></div>
					</div>
					<div class="form-item">
						<div class="label">手机号</div>
						<div class="value"><input type="tel" maxlength="11" v-model="form.phone" placeholder="请输入手机号">
						</div>
					</div>
					<div class="form-item">
						<div class="label">我的身份</div>
						<div class="value"><input type="tel" @click="typeMask=true" readonly v-model="form.identity"
								placeholder="请选择"></div>
					</div>
				</div>
				<div class="mask-button">
					<div class="button" @click="maskSubmit">提交转让意向</div>
				</div>
			</div>
			<!-- 我的身份 -->
			<van-popup v-model="typeMask" position="bottom" :style="{ height: '45%' }">
				<van-picker title="我的身份" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" />
			</van-popup>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				submitMaskType: true,
				form: {
					name: '',
					phone: '',
					type: 2, //转让
					identity: '', //身份类型
					city:''
				},
				typeMask: false, //我的身份弹窗
				columns: ['业主', '承租人', '中介/经纪人'],
			}
		},
		mounted() {
			this.JSBridge.callHandler('getLocation', null, (data) => {
				if (typeof data === 'string') data = JSON.parse(data);
				this.form.city = data ? data.province + data.city : ''
				// console.log(data.adCode) //440305
				// console.log(data.city + data.district) //深圳市南山区
				// console.log([data.city, data.district].join('-')) //深圳市-南山区
				// console.log(data.address) //详细地址
			});
		},
		methods: {
			maskSubmit() {
				const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (!this.form.name) {
					this.$toast.fail('请填写姓名');
				} else if (this.getByteLength(this.form.name) > 14) {
					this.$toast.fail('名称太长，请重新输入');
				} else if (!this.form.phone) {
					this.$toast.fail('请填写手机号码');
				} else if (!phoneReg.test(this.form.phone)) {
					this.$toast.fail('请填写正确手机号码');
				} else if (!this.form.identity) {
					this.$toast.fail('请选择我的身份');
				} else {
					this.$emit('submit', this.form)
				}
			},
			close() {
				this.$emit('close')
			},
			onConfirm(value, index) {
				// this.$toast(`当前值：${value}, 当前索引：${index}`);
				this.form.identity = value
				this.typeMask = false
			},
			onCancel() {
				this.typeMask = false
			},
			getByteLength(str = '') {
				let len = 0;
				for (let i = 0; i < str.length; i++) {
					if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
						len += 2;
					} else {
						len++;
					}
				}
				return len;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.intention-form {
		// @import '@/styles/index.scss';

		@function vw($args) {
			@return $args/750*100+vw;
		}

		.body-box {
			padding: vw(30);
			box-sizing: border-box;

			.title {
				height: vw(48);
				font-size: vw(34);
				font-weight: 600;
				line-height: vw(48);
				text-align: center;
				color: #1D2233;
			}

			.form-box {
				.form-item {
					margin-bottom: vw(10);

					.label {
						height: vw(72);
						font-size: vw(30);
						font-weight: 600;
						color: #121622;
						line-height: vw(72);
					}

					.value {
						height: vw(80);
						border-radius: vw(10);

						input {
							border: none;
							height: 100%;
							width: 100%;
							background: #F7F8FB;
							font-size: vw(28);
							color: #1D2233;
							padding: 0 vw(30);
							box-sizing: border-box;
						}
					}
				}
			}

			.mask-button {
				padding-top: vw(30);
				box-sizing: border-box;

				.button {
					height: vw(80);
					background: #347FFF;
					border-radius: vw(10);
					font-size: vw(30);
					font-weight: 600;
					color: #FFFFFF;
					line-height: vw(80);
					text-align: center;
				}
			}

		}
	}
</style>
