<template>
  <div class="search-poi-container">
    <div class="map">
      <base-map @updateLocation="updateLocation" ref="searchPoiMapRef" ></base-map>
    </div>
    <!-- poi列表 -->
    <div class="poi-contaienr">
      <div class="input-box">
        <div class="input-box-left">
          <div class="city-box" @click="selectCity">
            <span>{{ poiInfo.city }}</span>
            <img src="/static/dataQuery/choice_icon.png" />
          </div>
          <img class="search-icon" src="/static/dataQuery/map_icon_search.png" />
          <div class="input-box-container">
            <input placeholder="搜索商圈或者区域" @input="search" v-model="inputVal" />
            <img v-if="inputVal" src="/static/dataQuery/icon3.png"  @click="clearInput" />
          </div>
        </div>
        <div class="input-box-right" @click="cancle">取消</div>
      </div>
      <!-- poi滚动区域 -->
      <div class="poi-list">
        <div class="poi-item" v-for="(item,index) in poiList" :key="index" @click="selectPoiItem(index)">
          <div class="poi-item-left">
            <div class="poi-name">{{ item.name }}</div>
            <div class="poi-info">
              <span class="poi-distance" v-if="item.distance >= 0">{{ item.distance }}m</span>
              <span class="poi-line" v-if="item.address && item.distance >= 0"></span>
              <span class="poi-address" v-if="item.address">{{ item.address }}</span>
            </div>
          </div>
          <div class="poi-item-right">
            <img v-if="selectPoiIndex===index" class="selected-icon" src="/static/dataQuery/selected.png" />
          </div>

        </div>
        <!-- 没有数据 -->
        <div class="no-data-box" v-if="!poiList.length && !hasData">
          <img src="/static/dataQuery/no-data.png" />
          <div class="text">暂无地点</div>
        </div>
        <div class="loading-box" v-if="page > 1 && loading && hasData">加载中...</div>
        <div class="loading-box no-data" v-if="page > 1 && !hasData">没有更多了</div>
      </div>

      <div class="submit-btn-box">
        <div class="submit-btn" @click="confirmSelect">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMap from './components/base-map.vue'
let mapPlaceSearch = undefined
import { mapGetters } from 'vuex';
export default {
  name: 'search-poi',
  components: { BaseMap },
  computed: {
    ...mapGetters(['myCityInfo'])
  },
  watch: {
    myCityInfo(nv) {
      console.log('选择的城市',nv)
      this.handleCityCheck(nv)
    }
  },
  data() {
    return {
      oldScrollTop: 0,
      scrollTop: 0,
      //
      poiInfo: {
        lng: '',
        lat: '',
        city: ''
      },
      page: 1,
      size: 10,
      poiList: [],
      loading: false,
      inputVal: '',
      mapObj: {
        lng: '',
        lat: ''
      },
      // 选中的poi索引
      selectPoiIndex: 0,
      hasData: true, // 是否有poi数据
      goodsId: '', // sku id
      radiusKm: 1,
      pageShow: false,
    }
  },
  created () {

  },
  methods: {
    handleCityCheck (data) {
      console.log('data',data)
      this.poiInfo.city = data.name
      this.cityVisible = false
      const district = new AMap.DistrictSearch({ level: 'city', showbiz: false })
      district.search(data.name, (status, result) => {
        console.log(status,result)
        if (result.info=='OK') {
          const citys = result.districtList[0]
          this.mapObj.lng = citys.center.lng
          this.mapObj.lat = citys.center.lat
          if (this.inputVal) {
            this.refresh()
          }
        }
      })
    },
    clearInput() {
      this.inputVal = ''
    },
    scroll(e) {
      this.oldScrollTop = e.detail.scrollTop
    },
    // 取消操作，
    cancle() {
      this.inputVal = ''
      this.poiList = []
    },
    // 确认选择poi
    confirmSelect() {
      if (!this.poiList.length) {
        this.$toast('无法获取数据')
        return
      }
      const item = this.poiList[this.selectPoiIndex]
      console.log(item)
      this.$store.commit('setShopPoiInfo', { lng: item.location[0], lat: item.location[1], address: item.address })
      this.$router.back()
    },
    // 地图移动，中心点更新
    updateLocation({ lng, lat }) {
      this.poiInfo.lng = lng
      this.poiInfo.lat = lat
      this.mapObj.lng = lng
      this.mapObj.lat = lat
      this.refresh()
    },
    // 选址poi
    selectPoiItem(index = 0) {
      if (!this.poiList.length) return
      this.selectPoiIndex = index
      const item = this.poiList[this.selectPoiIndex]
      this.poiInfo.lng = item.location[0]
      this.poiInfo.lat = item.location[1]
      this.$refs.searchPoiMapRef.updateMapCenter(this.poiInfo.lng, this.poiInfo.lat)
    },
    // 搜索商圈或区域
    search() {
      this.refresh()
    },
    selectCity() {
      this.$router.push(`/dataQuery/selectCity`)
    },
    getPoiInfo(param) {
      this.poiInfo = param
      console.log('poiInfo', this.poiInfo)
      this.loadData()
    },
    refresh() {
      this.page = 1
      this.loadData()
    },
    // 加载更多数据
    loadMore() {
      // if (!this.hasData) {
      // 	return
      // }
      // this.page++;
      // this.loadData()
    },
    // 加载数据
    loadData(type) {
      console.log(this.mapObj)
      this.HTTP.dataQueryHttp.openRegion(this.mapObj.lng, this.mapObj.lat)
        .then((res) => {
          console.log(res);
          if (res.data.open) {
            this.poiInfo.city = res.data.cityName
            this.loading = true
            this.searchNearPoi()
          } else {
            this.selectPoiIndex = 0
            this.poiList = []
            this.hasData = false
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast('网络不稳定，请稍后重试')
        });
    },
    searchNearPoi() {
      mapPlaceSearch = new AMap.PlaceSearch({ city: this.poiInfo.city, citylimit: true, pageIndex: 1, pageSize: 10, extensions: 'all' })
      console.log(mapPlaceSearch,'mapPlaceSearch')
      mapPlaceSearch.searchNearBy(this.inputVal, [this.mapObj.lng, this.mapObj.lat], 1000, (status, result) => {
        console.log(status,result)
        if (status === "complete") {
          let arr = []
          const pois = result.poiList.pois || []
          if (pois.length) {
            this.hasData = true
            arr = pois.map(el => {
              const { name, distance, pname, cityname, adname, address, location } = el
              let _address = `${pname}${cityname}`
              if (adname!==cityname) _address += `${adname}`
              if (address) _address += `${address}`
              return {
                name,
                distance,
                address: _address,
                location: [location.lng,location.lat]
              }
            })

          } else {
            this.hasData = false
          }
          if (this.page===1) {
            this.poiList = arr
            this.selectPoiIndex = 0
            this.scrollTop = this.oldScrollTop
            this.$nextTick(() => {
              this.scrollTop = 0
            });
            this.$refs.searchPoiMapRef.updateMapCenter(this.mapObj.lng, this.mapObj.lat)
          } else {
            this.poiList = this.poiList.concat(arr)
          }
        } else {
          this.poiList = []
          this.hasData = false
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.search-poi-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .map {
    width: 100%;
    height: 40vh;
    position: relative;
  }
  .no-data-box {
    text-align: center;
    .text {
      color: #82848E;
      font-size: vw(22);
      margin-top: vw(20);
    }
    img {
      width: vw(170);
      height: vw(170);
      margin-top: vw(80);
    }
  }
  .loading-box {
    text-align: center;
    font-size: vw(24);
    margin: vw(15) 0;
  }
  .no-data {
    color: #ccc;
  }
  .poi-contaienr {
    background: #fff;
    box-shadow: 0 vw(20) vw(30) 0 rgba(136, 141, 152, 0.16);
    border-radius: vw(20) vw(20) 0 0;
    height: 70vh;
    margin-top: vw(-20);
    z-index: 1000;
    padding: vw(50) vw(30) vw(116) vw(30);
    display: flex;
    flex-direction: column;
    .submit-btn-box {
      position: fixed;
      z-index: 2;
      width: 100%;
      background: #fff;
      bottom: 0;
      left: 0;
      .submit-btn {
        height: vw(80);
        background: #347FFF;
        border-radius: vw(10);
        margin: vw(18) vw(30);
        text-align: center;
        line-height: vw(80);
        font-weight: bold;
        color: #FFFFFF;
        font-size: vw(30);
      }
    }

    .poi-list {
      flex: 1;
      overflow-y: scroll;
      .poi-item {
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding: vw(30) 0;
        border-bottom: vw(1) solid #E4E7ED;
        &:last-child {
          border-bottom: 0;
        }
        .poi-item-right {
          width: vw(46);
          height: vw(46);
          margin-right: vw(10);
          .selected-icon {
            width: 100%;
            height: 100%;
          }
        }
        .poi-item-left {
          margin-right: vw(22);
          flex: 1;
          .poi-name {
            font-weight: 500;
            color: #1D2233;
            font-size: vw(34);
          }
          .poi-info {
            color: #8893A7;
            font-size: vw(26);
            margin-top: vw(10);
            line-height: vw(30);
          }
          .poi-line {
            width: vw(1);
            height: vw(22);
            display: inline-block;
            background: #8893A7;
            margin: 0 vw(15);
          }
        }
      }
    }
    .input-box {
      display:flex;
      justify-content: space-between;
      align-items: center;
      height: vw(80);
      background: #F7F8FB;
      border-radius: vw(10);
      border: vw(1) solid #E4E7ED;
      padding: vw(8);
      .input-box-right {
        color: #1D2233;
        font-size: vw(24);
        padding: 0 vw(15);
        line-height: vw(64);
      }
      .input-box-left {
        display:flex;
        align-items: center;
        flex: 1;
        .input-box-container {
          display:flex;
          align-items: center;
          img {
            width: vw(30);
            height: vw(30);
            margin-left: vw(10);
          }
          input {
            width: 100%;
            background-color: transparent;
            border: 0;
            font-size: 14px;
          }
        }
        .city-box {
          height: vw(64);
          background: #FFFFFF;
          border-radius: vw(10);
          display: flex;
          align-items: center;
          padding: 0 vw(22);
          min-width: vw(154);
          box-sizing: border-box;
          overflow: hidden;
          span {
            color: #1D2233;
            font-size: vw(26);
            font-weight: bold;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            width: vw(14);
            height: vw(8);
            margin-left: vw(18);
          }
        }
        input {
          margin-left: vw(16);
        }
        .search-icon {
          width: vw(40);
          height: vw(40);
          margin-left: vw(14);
        }
      }
    }
  }
}
</style>
