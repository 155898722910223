<template>
    <div class="buttonFooter" :class="{ ios: isIOS, ipx: isIPX, ipmx: isIPMX }">
        <van-button type="info" block :disabled="disabled" :loading="loading" @click="onTap" class="buttonFooter--button">{{ text }}</van-button>
    </div>
</template>

<script>
export default {
    name : 'buttonFooter',
    props: {
        text    : String,
        disabled: {
            type   : Boolean,
            default: false,
        },
        loading: {
            type   : Boolean,
            default: false,
        },
    },
    data () {
        return {
            isIOS: false,
            isIPX: false,
            isIPMX: false
        };
    },
    methods: {
        onTap () {
            this.$emit( 'click' );
        },
    },
    beforeMount () {
        if ( window.isClient && ! window.isAndroid ) {
            this.isIOS = window.isIOS;
            this.isIPX = window.isIPX;
            this.isIPMX = window.isIPMX
        }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.buttonFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: $footerHeight;

    &.ipx {
        height: calc(  #{$footerHeight} + #{$iPXBottomBar} );
    }
    &.ipmx {
      height: calc(  #{$footerHeight} + #{$iPXBottomBar} );
    }

    #{&}--button {
        height: 100%;
        padding: 0;
        border-radius: 0;
        transition: all .05s;

        .van-button__text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            font-size: $font_400;
            line-height: $footerHeight;
        }
    }
}
</style>
