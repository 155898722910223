<template>
  <div>
    <NotSubmitted v-if="isLoaded && !imageUrl" @submited="submited"/>
    <Submitted  v-if="imageUrl" :imageUrl="imageUrl"/>
  </div>
</template>

<script>
import NotSubmitted from "@/views/me/vip-shop/company-pay/components/not-submitted.vue";
import Submitted from "@/views/me/vip-shop/company-pay/components/submitted.vue";

export default {
  name: "index",
  components: {
    NotSubmitted,
    Submitted,
  },
  data(){
    return {
      imageUrl: null,
      isLoaded: false,
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    submited(){
      this.getDetail();
    },
    getDetail(){
      this.HTTP.transferLast().then(res => {
        try {
          this.imageUrl = res.image;
        } catch (e) {
          this.imageUrl = null;
        }
      }).finally(() => {
        this.isLoaded = true;
      })
    },
  },
};
</script>

<style scoped>

</style>
