<template>
    <div class="partner-invite" :class="{ loaded: !loading }">
        <StateBar bgcolor="white" />
        <TitleHeader title="合伙人详情" bgcolor="white" color="black" :back="true">
        </TitleHeader>

        <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

        <AutoView v-if="!loading" :header="true" :footer="true" class="partner--view">
            <van-image class="partner-bkg" fit="contain" src="/static/img/partner/invite-bkg.png" />
            <div class="body-container">
                <div class="body">
                    <div class="header-wrap">
                        <div class="label-1">每邀请一位好友</div>
                        <div class="line">
                            <img class="header-icon" src="/static/icon/partner/arrow-left-1.png" />
                            <div class="label-2">
                                <span class="label-n">豪享</span>
                                <span class="label-b">25%</span>
                                <span class="label-n">返佣</span>
                            </div>
                            <img class="header-icon" src="/static/icon/partner/arrow-right-1.png" />
                        </div>
                    </div>
                    <div class="body-wrap">
                        <div class="box-w">
                            <van-image class="example" fit="contain" src="/static/icon/partner/example.png" />
                            <div class="header">好友购买或推广商品，您至高狂享30%高佣收益</div>
                            <div class="separate"></div>
                            <div class="box-v">
                                <div class="box-l">
                                    <div class="box-label">
                                        好友购买<br />立享<span>{{getCommissionOne}}%</span>返佣
                                    </div>
                                    <div class="box-value">
                                        ¥<span>{{getCommissionOneValue}}</span>
                                    </div>
                                    <img class="box-image-1" src="/static/icon/partner/person.png" />
                                </div>
                                <div class="box-r">
                                    <div class="box-label">
                                        <div class="label-n">好友推广商品<br/>再享<span>{{getCommissionTwo}}%</span>返佣</div>
                                    </div>
                                    <div class="box-value">
                                        ¥<span>{{getCommissionTwoValue}}</span>
                                    </div>
                                    <img class="box-image-2" src="/static/icon/partner/persons.png" />
                                </div>
                            </div>
                            <div class="swiper-l">
                                <swiper :options="swiperOption" ref="swiperRef" @slideChange="slideChange">
                                    <swiper-slide v-for="(item,index) of swiperItems" :key="index">
                                        <div class="swiper-box">
                                            <div class="swiper-price">
                                                ¥{{item.salePrice}}
                                            </div>
                                            <div class="swiper-m">
                                                <img class="swiper-icon" :src="item.icon" />
                                            </div>
                                            <div class="swiper-name">{{item.goodsName}}</div>
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>
                            <div class="bottom">
                                <div class="spot"></div>
                                <div class="label">好友购买参与分销商品</div>
                                <div class="spot"></div>
                            </div>
                        </div>
                        <div class="notice">
                            <div class="line">
                                <img class="notice-icon" src="/static/icon/partner/notice.png" />
                                <span>以上仅为示范，具体返现标准以分销规则为准</span>
                            </div>
                            <div class="line">
                                你所得返现：商品价格*分销一级返佣比例+商品价格*分销二级返佣比例
                            </div>
                        </div>
                    </div>
                    <div class="partner-wrap">
                        <div class="title">
                            <img class="img" src="/static/icon/partner/arrow-left-2.png" />
                            <span class="label">你的好友可享</span>
                            <img class="img" src="/static/icon/partner/arrow-right-2.png" />
                        </div>
                        <div class="box-wrap">
                            <div class="box">
                                <div class="content">
                                    <div class="line-1">
                                        <span class="value">50</span>
                                        <span class="label">元优惠券</span>
                                    </div>
                                    <div class="line-2">
                                        平台数据报告类产品可享
                                    </div>
                                </div>
                                <div class="img-wrap">
                                    <img class="img" src="/static/icon/partner/gift.png" />
                                </div>
                            </div>
                            <div class="box">
                                <div class="content">
                                    <div class="line-1">
                                        <span class="label">豪享</span>
                                        <span class="value">25%</span>
                                        <span class="label">返佣</span>
                                    </div>
                                    <div class="line-2">
                                        好友成为合伙人并推广商品
                                    </div>
                                </div>
                                <div class="img-wrap">
                                    <img class="img" src="/static/icon/partner/gift.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-wrap">
                        <div class="title">
                            <img class="img" src="/static/icon/partner/arrow-left-2.png" />
                            <span class="label">获得奖励攻略</span>
                            <img class="img" src="/static/icon/partner/arrow-right-2.png" />
                        </div>
                        <div class="step">
                            <div class="line1">
                                <img class="step-box" src="/static/icon/partner/step1.png" />
                                <img class="step-line" src="/static/icon/partner/step-line.png" />
                                <img class="step-box" src="/static/icon/partner/step2.png" />
                                <img class="step-line" src="/static/icon/partner/step-line.png" />
                                <img class="step-box" src="/static/icon/partner/step3.png" />
                                <img class="step-line" src="/static/icon/partner/step-line.png" />
                                <img class="step-box" src="/static/icon/partner/step4.png" />
                            </div>
                            <div class="line2">
                                <div class="step-label">点击邀请好友<br/>按钮</div>
                                <div class="step-label">提醒好友<br/>领取优惠券</div>
                                <div class="step-label">好友下载APP<br/>并购买</div>
                                <div class="step-label">邀请越多<br/>佣金到账越多</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="invite--innerBar">
                <img class="image-foot" src="/static/img/partner/invite-btn.png" @click="gotoJoin" />
            </div>
        </AutoView>

        <!-- <SoltFooter class="invite--footerBar">
            <img class="image-foot" src="/static/img/partner/btn.png" @click="gotoJoin" />
        </SoltFooter> -->

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import List from '../shops/list.vue';
export default {
    components: {
        Swiper,
        SwiperSlide,
        List
    },
    data() {
        return {
            loading: false,
            swiperItems: [],
            activeIndex: 2,
            swiperOption: {
                effect: 'coverflow',
                centeredSlides: true,
                initialSlide: 5,
                slidesPerView: 3,
                spaceBetween: 25,
                loop: true,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 200,
                    modifier: 1,
                    slideShadows: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
        };
    },
    mounted() {
        // setTimeout( () => {
        //     this.setSwiperWidth();
        // }, 200 )
    },
    beforeMount() {
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
        this.getPartnerConfig();
    },
    computed: {
        getCommissionOne() {
            if ( this.swiperItems.length == 0 ) {
                return 0;
            }
            const item = this.swiperItems[ this.activeIndex ];
            return item.commissionPercent;
        },
        getCommissionOneValue() {
            // console.log( 'getCommissionOneValue activeIndex:', this.activeIndex );
            if ( this.swiperItems.length == 0 ) {
                return 0;
            }
            const item = this.swiperItems[ this.activeIndex ];
            // console.log( 'getCommissionOneValue item:', item );
            const price = parseFloat(item.salePrice);
            const percent = parseFloat(item.commissionPercent);
            const discount = price * percent / 100;
            return discount.toFixed(2);
        },
        getCommissionTwo() {
            if ( this.swiperItems.length == 0 ) {
                return 0;
            }
            const item = this.swiperItems[ this.activeIndex ];
            return item.commissionPercentTwo;
        },
        getCommissionTwoValue() {
            if ( this.swiperItems.length == 0 ) {
                return 0;
            }
            const item = this.swiperItems[ this.activeIndex ];
            const price = parseFloat(item.salePrice);
            const percent = parseFloat(item.commissionPercentTwo);
            const discount = price * percent / 100;
            return discount.toFixed(2);
        }
    },
    methods: {
        gotoJoin() {
            // this.$router.push( '/partner/join' );
            const { shareId, shareCode } = this.$route.query;
            if ( shareId == undefined || shareId.length == 0 ) {
                return;
            }
            if ( shareCode == undefined || shareCode.length == 0 ) {
                return;
            }
            this.JSBridge.callHandler( 'callShareV2', {
                code: shareCode,
                id: shareId
            } );
        },
        slideChange() {
            // console.log( 'slideChange' );
            const swiperRef = this.$refs.swiperRef;
            if ( swiperRef == undefined ) {
                return;
            }
            const activeIndex = swiperRef.$swiper.realIndex;
            if ( activeIndex >= this.swiperItems.length ) {
                return;
            }
            this.activeIndex = activeIndex;
            // console.log( 'activeIndex:', this.activeIndex );
        },
        getPartnerConfig() {
            this.HTTP.partnerConfig().then( ( res ) => {
                const { code, data } = res;
                if ( code == 0 ) {
                    this.swiperItems = data.partnerGoodsConfRespList || [];
                    // console.log( 'swiperItems:', this.swiperItems );
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
            } );
        },
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

@keyframes onActive {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: #FFFFFF;
    }
}

.partner-invite {
    height: 100vh;
    &.loaded {
        background-color: #FFCF5A;
    }
    .partner-bkg {
        position: absolute;
        width: 100%;
    }
    .partner--view {
        position: relative;
        width: 100%;
        // height: calc(100% - #{$headerHeight} - 21vw);

        .partner-img {
            position: relative;
            width: 100%;
        }
        .body-container {
            position: absolute;
            width: 100%;
            .body {
                position: relative;
                margin-top: 67.2vw;
                width: 100%;
                border-radius: 0 0 2.67vw 2.67vw;
                padding: 0 4vw 8vw 4vw;
                box-sizing: border-box;
                .header-wrap {
                    position: relative;
                    width: 100%;
                    background-image: url(/static/img/partner/header.png);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    padding: 2.6vw 0 2.4vw 0;
                    .label-1 {
                        color: #C65000;
                        font-size: 4vw;
                        font-weight: bold;
                        line-height: 6.13vw;
                        text-align: center;
                        position: relative;
                    }
                    .label-2 {
                        position: relative;
                        margin: 0 2.93vw;
                        display: flex;
                        align-items: center;
                        .label-n {
                            color: #C65000;
                            font-size: 4.53vw;
                            font-weight: bolder;
                            line-height: 6.93vw;
                            text-align: center;
                        }
                        .label-b {
                            color: #FF5300;
                            font-size: 6.67vw;
                            font-weight: bolder;
                            line-height: 10.13vw;
                            text-align: center;
                            margin: 0 2vw;
                        }
                    }
                    .line {
                        position: relative;
                        margin-top: 0.5vw;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .header-icon {
                            width: 5.6vw;
                            height: 2.93vw;
                        }
                    }
                }
                .body-wrap {
                    position: relative;
                    border-radius: 0 0 2.67vw 2.67vw;
                    background: linear-gradient(180deg, #FFECB0 0%, #FFE798 100%);
                    padding: 0 2.67vw 4vw 2.67vw;
                    margin-top: -2px;
                    .box-w {
                        position: relative;
                        border-radius: 2.67vw;
                        background-color: #ffffff;
                        padding: 4vw;
                        .example {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 16vw;
                            height: 16vw;
                        }
                        .header {
                            color: #8E9099;
                            font-size: 2.93vw;
                            font-weight: normal;
                            line-height: 4.53vw;
                            text-align: center;
                        }
                        .separate {
                            position: relative;
                            margin-top: 2.4vw;
                            width: 100%;
                            height: 1.07vw;
                            background-image: url(/static/icon/partner/separate.png);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position:center;
                        }
                        .box-v {
                            position: relative;
                            padding-top: 4vw;
                            display: flex;
                            justify-content: space-between;
                            .box-l {
                                position: relative;
                                background: linear-gradient(360deg, #FFFAF3 0%, #FFE9D6 100%);
                                border-radius: 1.33vw;
                                border: 1px solid #FFDFC2;
                                width: 55%;
                                padding: 2.67vw 0;
                                box-sizing: border-box;
                            }
                            .box-r {
                                position: relative;
                                background: linear-gradient(360deg, #FFFDF4 0%, #FFF7D8 100%);
                                border-radius: 1.33vw;
                                border: 1px solid #FFEFBA;
                                width: 42%;
                                padding: 2.67vw 0;
                                box-sizing: border-box;
                            }
                            .box-label {
                                color: #C65000;
                                font-size: 3.2vw;
                                font-weight: bold;
                                line-height: 4.27vw;
                                text-align: center;
                                .label-n {
                                    position: relative;
                                }
                            }
                            .box-value {
                                position: relative;
                                margin-top: 4vw;
                                color: #FF5300;
                                font-size: 4vw;
                                font-weight: bold;
                                line-height: 5.6vw;
                                text-align: center;
                            }
                            .box-image-1 {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 12.53vw;
                                height: 11.73vw;
                            }
                            .box-image-2 {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 14.13vw;
                                height: 11.73vw;
                            }
                        }
                        .swiper-l {
                            position: relative;
                            width: 100%;
                            // height: 28vw;
                            padding: 3vw 0;
                            background-color: #F5F5F7;
                            margin-top: 4.8vw;
                            &:after {
                                content:"";
                                border:7px solid #F5F5F7;
                                border-left-color:transparent;
                                border-right-color:transparent;
                                border-top-color:transparent;
                                width:0px;
                                height:0px;
                                position:absolute;
                                left: calc(50% - 6px);
                                top: -14px;
                            }
                            .swiper-slide {
                                &.swiper-slide-active {
                                    .swiper-box {
                                        position: relative;
                                        padding: 3vw 1vw 1vw;
                                        border-radius: 1.33vw;
                                        background-color: #fff;
                                        // animation-name: onActive;
                                        // animation-timing-function: ease;
                                        // animation-delay: 0.1s;
                                        // animation-duration: 0.5s;
                                        // animation-direction: normal;
                                        // animation-fill-mode: forwards;
                                        .swiper-price {
                                            position: absolute;
                                            top: 1.33vw;
                                            right: -7.5vw;
                                            padding: 0 1.87vw;
                                            background: linear-gradient(90deg, #FFA02B 0%, #FF6605 100%);
                                            border-radius: 10px 10px 10px 2px;
                                            color: #FFFFFF;
                                            font-size: 3.73vw;
                                            font-weight: bold;
                                            line-height: 5.07vw;
                                            text-align: center;
                                            display: block;
                                            z-index: 1;
                                        }
                                        .swiper-name {
                                            margin-top: 1.6vw;
                                            color: #1D2233;
                                            font-size: 3.47vw;
                                            font-weight: normal;
                                            line-height: 5.33vw;
                                            text-align: center;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        }
                                        .swiper-m {
                                            position: relative;
                                            display: flex;
                                            justify-content: center;
                                            .swiper-icon {
                                                width: 11.73vw;
                                                height: 11.73vw;
                                            }
                                        }
                                    }
                                }
                                .swiper-box {
                                    position: relative;
                                    padding: 5vw 1vw 0 1vw;
                                    .swiper-price {
                                        display: none;
                                    }
                                    .swiper-name {
                                        margin-top: 1.6vw;
                                        color: #8E9099;
                                        font-size: 2.4vw;
                                        font-weight: normal;
                                        line-height: 3.47vw;
                                        text-align: center;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                    }
                                    .swiper-m {
                                        position: relative;
                                        display: flex;
                                        justify-content: center;
                                        .swiper-icon {
                                            width: 8vw;
                                            height: 8vw;
                                        }
                                    }
                                }
                            }

                            .swiper-box {
                                position: relative;
                                padding: 2vw;
                                .swiper-price {
                                    position: absolute;
                                    top: 1.33vw;
                                    right: -7.5vw;
                                    padding: 0 1.87vw;
                                    background: linear-gradient(90deg, #FFA02B 0%, #FF6605 100%);
                                    border-radius: 10px 10px 10px 2px;
                                    color: #FFFFFF;
                                    font-size: 3.73vw;
                                    font-weight: bold;
                                    line-height: 5.07vw;
                                    text-align: center;
                                }
                                .swiper-name {
                                    color: #8E9099;
                                    font-size: 2.93vw;
                                    font-weight: normal;
                                    line-height: 4.53vw;
                                    text-align: center;
                                }
                                .swiper-m {
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    .swiper-icon {
                                        width: 9.6vw;
                                        height: 9.6vw;
                                    }
                                }
                            }
                        }
                        .bottom {
                            position: relative;
                            margin-top: 1.87vw;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .spot {
                                position: relative;
                                width: 4px;
                                height: 4px;
                                border-radius: 2px;
                                background-color: #FFB95E;
                            }
                            .label {
                                position: relative;
                                margin: 0 2.13vw;
                                color: #8E9099;
                                font-size: 2.67vw;
                                font-weight: normal;
                                line-height: 4vw;
                                text-align: center;
                            }
                        }
                    }
                    .notice {
                        position: relative;
                        margin-top: 2.67vw;
                        padding: 0 4vw;
                        .line {
                            color: #C65000;
                            font-size: 2.4vw;
                            font-weight: normal;
                            line-height: 3.47vw;
                            text-align: left;
                            .notice-icon {
                                width: 2.4vw;
                                height: 2.4vw;
                            }
                        }
                    }
                }
                .partner-wrap {
                    position: relative;
                    margin-top: 6.4vw;
                    .box-wrap {
                        position: relative;
                        margin-top: 4vw;
                        display: flex;
                        justify-content: space-evenly;
                        .box {
                            position: relative;
                            background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);
                            border-radius: 1.87vw;
                            padding: 1.33vw;
                            width: 48%;
                            box-sizing: border-box;
                            .content {
                                position: relative;
                                background: #FFFFFF;
                                border-radius: 1.33vw;
                                padding: 4vw 0;
                                .line-1 {
                                    position: relative;
                                    text-align: center;
                                    z-index: 1;
                                    .value {
                                        color: #FF5300;
                                        font-size: 5.33vw;
                                        font-weight: bolder;
                                        line-height: 8vw;
                                    }
                                    .label {
                                        color: #C65000;
                                        font-size: 4vw;
                                        font-weight: bold;
                                        line-height: 6.13vw;
                                    }
                                }
                                .line-2 {
                                    position: relative;
                                    margin-top: 3.2vw;
                                    color: #8E9099;
                                    font-size: 2.93vw;
                                    line-height: 4.53vw;
                                    text-align: center;
                                    z-index: 1;
                                }
                            }
                            .img-wrap {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                .img {
                                    width: 16vw;
                                    height: 14.13vw;
                                }
                            }
                        }
                    }
                }
                .step-wrap {
                    position: relative;
                    margin-top: 6.67vw;
                    .step {
                        position: relative;
                        margin-top: 4vw;
                        .step-box {
                            width: 12vw;
                            height: 12vw;
                        }
                        .step-line {
                            width: 8vw;
                            height: 1.33vw;
                        }
                        .step-label {
                            position: relative;
                            color: #C65000;
                            font-size: 2.93vw;
                            font-weight: normal;
                            line-height: 4vw;
                            text-align: center;
                            width: 22%;
                            white-space: nowrap;
                        }
                        .line1 {
                            position: relative;
                            padding: 0 4.8vw;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .line2 {
                            position: relative;
                            margin-top: 2.67vw;
                            // padding: 0 1.8vw;
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
                .title {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .img {
                        width: 5.6vw;
                        height: 2.93vw;
                    }
                    .label {
                        color: #C65000;
                        font-size: 4.27vw;
                        font-weight: bolder;
                        line-height: 6.4vw;
                        margin: 0 3.73vw;
                        text-align: center;
                    }
                }
            }
            .bottom-wrap {
                position: relative;
                background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);
                .img-wrap {
                    position: relative;
                    width: 100%;
                    height: 20.8vw;background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .invite--footerBar {
        z-index: 10;
        background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);
        // box-shadow: 0px -3px 15px 0px rgba(63, 61, 57, 0.8);
        box-shadow: 0px -3px 15px 0px rgba(255,207,90,0.8);
        // height: 21vw;
        .image-foot {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }
    .invite--innerBar {
        position: fixed;
        bottom: 0;
        z-index: 10;
        background: linear-gradient(180deg, #FFEEB5 0%, #FFE798 100%);
        // box-shadow: 0px -3px 15px 0px rgba(63, 61, 57, 0.8);
        box-shadow: 0px -3px 15px 0px rgba(255,207,90,0.8);
        height: 21vw;
        .image-foot {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }
}
</style>
