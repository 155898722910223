<template>
	<div class="transfer-details">
		<StateBar />
		<TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>
		<AutoView :footer="false">
			<div class="list-box">
				<div class="list-item">
					<div class="left-box">
						<img src="../../../../static/shopEcology/details-fw.png" alt="">
						<div class="title">需要服务</div>
					</div>
					<div class="right-box">{{form.serveTypeName}}</div>
				</div>
				<div class="list-item">
					<div class="left-box">
						<img src="../../../../static/shopEcology/details-name.png" alt="">
						<div class="title">姓名</div>
					</div>
					<div class="right-box">{{form.name}}</div>
				</div>
				<div class="list-item">
					<div class="left-box">
						<img src="../../../../static/shopEcology/details-phone.png" alt="">
						<div class="title">手机号</div>
					</div>
					<div class="right-box">{{form.phone}}</div>
				</div>
				<div class="list-item" v-if="form.brokerName">
					<div class="left-box">
						<img src="../../../../static/shopEcology/details-jjr.png" alt="">
						<div class="title">铺参谋</div>
					</div>
					<div class="right-box">{{form.brokerName}}</div>
				</div>
				<div class="list-item">
					<div class="left-box">
						<img src="../../../../static/shopEcology/details-sj.png" alt="">
						<div class="title">提交时间</div>
					</div>
					<div class="right-box">{{form.gmtCreate}}</div>
				</div>
			</div>
		</AutoView>
	</div>
</template>

<script>
	import {
		getIntentionDetailById
	} from "@/request/shopEcology"
	export default {
		data() {
			return {
				title: '选址意向详情',
				form: {

				}
			}
		},
		mounted() {
			if (this.$route.query.name) {
				this.title = this.$route.query.name
			}
			this.getDetails()
		},
		methods: {
			getDetails() {
				getIntentionDetailById(this.$route.query.id).then(res => {
					this.form = res
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.transfer-details {
		.list-box {
			padding: 0 vw(40);
			box-sizing: border-box;
			background: #fff;

			.list-item {
				height: vw(123);
				line-height: vw(123);
				border-bottom: vw(1) solid #EDF1FB;
				display: flex;
				justify-content: space-between;

				.left-box {
					display: flex;
					align-items: center;
					font-size: vw(30);
					font-weight: 600;
					color: #8893A7;

					img {
						width: vw(44);
						height: vw(44);
						vertical-align: middle;
						margin-right: vw(27);
					}
				}

				.right-box {
					font-size: vw(30);
					font-weight: 600;
					color: #121622;
				}
			}
		}
	}
</style>
