// 铺生态三期 商铺求租接口
import {
  Axios
} from './http'

const commonRequest = (config) => {
  return new Promise((resolve, reject) => {
    Axios.request(config).then(res => {
      resolve(res.data.data);
    }).catch(e => {
      reject(e);
    });
  })
}

// 全国城市
export const getAllCity = () => { 
  return commonRequest({
    url: '/v1/data/public/allCity',
    method: 'get'
  });
}

// 保存或新增求租意向
export const saveOrUpdateIntentionRent = (data) => {
  return commonRequest({
    url: '/v1/collection/intentionRent/saveOrUpdateIntentionRent',
    method: 'post',
    data
  });
}

// 上下架求租意向
export const openOrCloseIntentionRent = (data) => {
  return commonRequest({
    url: '/v1/collection/intentionRent/openOrCloseIntentionRent',
    method: 'post',
    data
  });
}

// 获得城市片区
export const getCommunity = (cityName) => { 
  return commonRequest({
    url: `/v1/data/public/community/origin/${cityName}`,
    method: 'get'
  });
}

// 我的求租信息列表
export const getIntentionRentListPage = (data) => {
  return commonRequest({
    url: '/v1/collection/intentionRent/getIntentionRentListPage',
    method: 'post',
    data
  });
}
// 求租信息详情
export const getIntentionRentInfo = (id) => {
  return commonRequest({
    url: `/v1/collection/intentionRent/getIntentionRentInfo/${id}`,
    method: 'get'
  });
}

// IM个人信息列表
export const getIMPersonalDetailsList = (id) => {
  return commonRequest({
    url: `/v1/collection/intentionRent/getIMPersonalDetailsList/${id}`,
    method: 'get'
  });
}

export const getIntentionRentInfoById = (id) => {
  return commonRequest({
    url: `/v1/collection/intentionRent/getIntentionRentInfoById/${id}`,
    method: 'get'
  });
}

export const getIntentionEchoV2ByUserId = () => {
  return commonRequest({
    url: `/v1/collection/intentionRent/getIntentionEchoV2ByUserId`,
    method: 'get'
  });
}
