<template>
    <div class="city-wrap">
        <div
            class="city-item"
            :class="{ isSelected: index == idx }"
            v-for="(item, index) in bgmData"
            :key="item.key"
            @click="selectItem(item, index)"
        >
            <p class="name">{{ item.name }}</p>
            <p class="num-wrap">{{ item.count }}</p>
        </div>
        <!-- flex justify-content: space-between 向左对齐-->
        <div class="city-item temp" v-for="val in 2" :key="val.key"></div>
    </div>
</template>

<script>
export default {
    props: {
        bgmData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            idx: 0,
        };
    },
    methods: {
        selectItem(item, index) {
            this.idx = index;
            this.$emit("selectItem", item);
        },
    },
};
</script>

<style lang="scss" scoped>
.city-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .city-item {
        width: 21.87vw;
        height: 15.47vw;
        font-size: 3.2vw;
        color: #676c84;
        background: #fafafb;
        text-align: center;
        border-radius: 1.33vw;
        margin-bottom: 1.33vw;
        padding: 2.13vw 0;
        box-sizing: border-box;
        .name {
            height: 4.8vw;
            line-height: 4.8vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .num-wrap {
            height: 4.8vw;
            line-height: 4.8vw;
            font-weight: bold;
            margin-top: 0.53vw;
        }
    }
    .isSelected {
        color: #347fff !important;
        background: #dfedff;
        border: 1px solid #97beff;
    }
    .temp {
        height: 0;
        margin-bottom: 0;
        border: none;
        padding: 0;
    }
}
</style>
