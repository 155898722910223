<template>
    <div class="report-chart-container">
        <div class="report-chart-mask"></div>
        <div ref="chartItemRef" class="report-chart-item"></div>
    </div>
</template>

<script>
import echarts from "echarts";

export default {
    props: {
        dataObject: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartItem: undefined,
            keyList: [],
            valList: [],
        };
    },
    mounted() {
        this.load(this.dataObject);
    },
    methods: {
        load(dataObject) {
            if (!dataObject) {
                return;
            }
            let key = [],
                val = [];
            dataObject.forEach((item) => {
                key.push(item.code);
                val.push(item.count);
            });
            this.keyList = key;
            this.valList = val;
            this.intiChart();
        },
        intiChart() {
            let element = this.$refs.chartItemRef;
            if (element == undefined) {
                return;
            }
            if (this.chartItem == undefined) {
                this.chartItem = echarts.init(element);
            }
            let option = {
                grid: {
                    width: "90%",
                    left: "8%",
                    bottom: "23%",
                    height: "86%",
                },
                xAxis: {
                    type: "category",
                    data: this.keyList,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#E3E0EE",
                            width: 1,
                        },
                    },
                    axisLabel: {
                        color: "#8E9099",
                        fontSize: 9,
                        // interval: 0, // 强制显示所有的label
                        rotate: -90,
                        showMaxLabel: true,
                        showMinLabel: true
                    },
                },
                yAxis: {
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "#8E9099",
                        fontSize: 10,
                        align: "right",
                        padding: [0, 3, 0, 0],
                    },
                    type: "value",
                    boundaryGap: [0, "30%"],
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#8E9099",
                        fontSize: 9,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#E3E0EE",
                            width: 1,
                            type: "dashed",
                        },
                    },
                },
                series: [
                    {
                        name: "Direct",
                        type: "bar",
                        barWidth: "60%",
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "#578FEB", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#CDE0FF", // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                            barBorderRadius: 3,
                        },
                        data: this.valList,
                    },
                ],
            };
            this.chartItem.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
.report-chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.report-chart-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.report-chart-item {
    position: relative;
    width: 100%;
    height: 100%;
}
</style>
