<template>
  <div class="brand">
    <div class="header">
      <template v-if="isClient">
        <StateBar :bgcolor="bgColor" />
        <TitleHeader :title="title" :back="showBack" :bgcolor="bgColor"></TitleHeader>
      </template>
      <div v-else class="share">
        <p class="f-fw">上上参谋</p>
        <p>生意人的数据军师</p>
        <a ref="openApp" :href="link" target="_blank" class="app">APP查看</a>
      </div>
    </div>

    <AutoView ref="view" :header="true" :footer="true" class="brand--view">
      <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton s-radius__xs">
        <van-image class="banner-img" fit="cover" :src="banner" />
        <div class="brand-content">
          <!-- 方案一 -->
          <template v-if="bannerResList && bannerResList.length > 0">
            <div class="title">方案一</div>
            <template v-for="item of bannerResList">
              <van-image class="one-img margin-15" :key="item.key" fit="cover" :src="item.url" @click="seeBigImg(item.url)" />
            </template>
          </template>
          <!-- 方案二 -->
          <template v-if="introduceImageList && introduceImageList.length > 0">
            <div class="title">方案二</div>
            <template v-for="item of introduceImageList">
              <van-image class="two-img margin-15" :key="item.key" fit="cover" :src="item.url" @click="seeBigImg(item.url)" />
            </template>
          </template>
          <!-- 方案三 -->
          <template v-if="bottomBannerList && bottomBannerList.length > 0">
            <div class="title">方案三</div>
            <template v-for="item of bottomBannerList">
              <van-image class="img margin-15" :key="item.key" fit="cover" :src="item.url" @click="seeBigImg(item.url)" />
            </template>
          </template>
          <div class="title">使用规则</div>
          <p class="tips" style="margin-bottom: 12px">
            购买后请添加小助手微信：sscmfxs01<br />
            1、套餐仅限个人使用，购买成功后可在【我的订单】查看记录；<br />
            2、本套餐不能与其他折扣券、抵用券同时使用；<br />
            3、限选址定制套餐使用，其他报告不可用；<br />
            4、本套餐提供定制服务，用户在使用的过程中必须遵守本APP的使用规则。<br />
          </p>
        </div>
      </van-skeleton>
    </AutoView>
    <SoltFooter class="brand--footerBar">
      <van-row class="view" gutter="25">
        <van-col span="12">
          <van-button color="#FF5900" plain block @click="onLivechat">咨询专属分析师</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="warning" block :loading="btnLoading" @click="getListAllQuestion">立刻购买</van-button>
        </van-col>
      </van-row>
    </SoltFooter>

    <!-- 立刻购买弹框 -->
    <van-popup v-model="showPicker" round closeable position="bottom">
      <div class="l-panel f-vp introduce-subview">
        <h3>套餐选择</h3>
        <ul class="card-item" v-for="(item, index) in buyTypeList" :key="item.goodsId">
          <li class="card-left">
            <p class="top-tips">{{ item.goodsName }}</p>
            <p class="price">
              <span class="i">¥</span>{{ item.price }} <span class="original">原价¥{{ item.salePrice }}</span>
            </p>
          </li>
          <li class="card-right">
            <van-button color="#F58958" block :loading="buyLoading && buyIdx === index" @click="getOrderServer(item.goodsId, index)">
              立刻购买
            </van-button>
          </li>
        </ul>
      </div>
    </van-popup>

    <!-- 全局加载遮罩-->
    <!-- <LoadingOverlay :show="loading" /> -->
  </div>
</template>

<script>
import { ImagePreview } from "vant";

export default {
  name: "introduceIndex",
  data() {
    return {
      loading: true,
      isClient: false,
      link: this.HTTP.getAppStore(),
      showBack: true,
      bgColor: "white",
      title: "1对1定制化数据服务",
      banner: "", // 首张banner图
      introduceImageList: [], //方案一
      bannerResList: [], // 方案二
      bottomBannerList: [], // 方案三
      showPicker: false, // 购买弹框
      buyTypeList: [], // 购买类型列表
      btnLoading: false,
      buyLoading: false, // 购买节流
      buyIdx: 0,
    };
  },
  created() {
    this.HTTP.getStdQuestionCnf(20004)
      .then((res) => {
        this.loading = false;
        this.banner = res.data.cover;
        this.introduceImageList = res.data.introduceImageList || [];
        this.bannerResList = res.data.bannerResList || [];
        this.bottomBannerList = res.data.bottomBannerList || [];
        console.log(res.data);
      })
      .catch((e) => {
        this.$toast("网络不稳定，请稍候重试。");
      });
  },
  beforeMount() {
    this.isClient = window.isClient;
  },
  methods: {
    getOrderServer(goodsId, idx) {
      this.buyIdx = idx;
      this.buyLoading = true;
      this.HTTP.getOrderServer(goodsId)
        .then((res) => {
          this.buyLoading = false;
          this.swRouter({ path: `/pay/${res.data}` });
        })
        .catch((e) => {
          this.buyLoading = false;
          this.$toast("网络不稳定，请稍候重试。");
        });
    },
    getListAllQuestion() {
      this.btnLoading = true;
      this.HTTP.getListAllQuestion()
        .then((res) => {
          // this.banners = res;
          console.log(res);
          this.buyTypeList = res.data || [];
          this.btnLoading = false;
          this.showPicker = true;
        })
        .catch((e) => {
          this.btnLoading = false;
          this.$toast("网络不稳定，请稍候重试。");
        });
    },
    seeBigImg(url) {
      ImagePreview({
        images: [url],
        loop: false,
        showIndex: false,
        closeable: false,
      });
    },
    onLivechat() {
      if (this.isClient) {
        if (window._appCode >= 175) {
          this.JSBridge.callHandler("onNormalizingCall", {
            type: 5,
            url: "ONLINE_SERVICE",
            params: JSON.stringify({
              cardCode: "CUSTOMER_GOODS",
              cardId: "20004",
              title: "在线客服",
              type: 2,
            }),
          });
        } else {
          this.JSBridge.callHandler(
            "callLiveChat",
            {
              type: "normal",
              data: {},
            },
            (st) => {
              // console.log( st );
            }
          );
        }
      } else {
        // this.$toast( '暂不支持网页版跳转' );
        this.$refs.openApp.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.brand {
  height: 100vh;
  .header {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
    ::v-deep {
      .stateBar,
      .titleHeader {
        @include ani(0.25s, background-color);
      }
    }
    .share {
      position: relative;
      height: $headerHeight;
      background: rgba(black, 0.8) url(/static/icon/logo@96.png) no-repeat $margin center;
      background-size: $headerHeight * 0.7;
      color: white;
      padding-left: $headerHeight * 1.1;
      p {
        font-size: $font_320;
        padding-top: $margin * 0.8;
      }
      .f-fw {
        font-size: $font_400;
      }
      .app {
        position: absolute;
        right: $margin;
        top: 25%;
        height: 50%;
        color: white;
        background-color: $c-main;
        padding: 0 $margin;
        overflow: hidden;
        border-radius: 10vw;
        font-size: $font_320;
        @include flexColumn;
      }
    }
  }
  #{&}--view {
    scroll-behavior: smooth;
    .banner-img {
      width: 100%;
      height: 76.53vw;
    }
    .one-img {
      width: 100%;
      height: 38.13vw;
    }
    .two-img {
      width: 100%;
      height: 64vw;
    }
    .img {
      display: block;
      min-height: 10vw;
    }
    .margin-15 {
      margin-bottom: 4vw;
    }
    .brand-content {
      padding: 0 4vw;
      .title {
        height: 12vw;
        line-height: 12vw;
        font-size: 4vw;
        color: #1d2233;
      }
      .tips {
        font-size: 3.47vw;
        color: #999;
        line-height: 5.87vw;
      }
    }
  }
  #{&}--footerBar {
    z-index: 3;
    box-shadow: 0 -0.5vw 2vw rgba(black, 0.05);
    .view {
      box-sizing: border-box;
      padding: $headerMargin $padding;
      width: 100%;
      height: $footerHeight;
      ::v-deep .van-button.van-button--warning {
        border-color: transparent;
        background: linear-gradient(90deg, #ff8000 0%, #ff5900 100%);
      }
    }
  }
  .introduce-subview {
    padding: $padding $margin;
    h3 {
      text-align: center;
      font-size: $font_400;
      margin-bottom: $margin * 2;
    }
    .card-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 24.53vw;
      .card-left {
        flex: 3;
        .top-tips {
          font-size: 3.73vw;
          color: #1d2233;
          font-weight: 500;
        }
        .price {
          height: 7.47vw;
          font-size: 5.33vw;
          color: #8d5d22;
          font-weight: bold;
          margin-top: 1.07vw;
          .i {
            font-size: 3.47vw;
          }
          .original {
            font-size: 2.67vw;
            color: #999;
            margin-left: 2.67vw;
            text-decoration: line-through;
          }
        }
      }
      .card-right {
        flex: 2;
      }
    }
  }
}
</style>
