<template>
	<div>
		<StateBar />
		<TitleHeader title="订单支付成功" bgcolor="white" color="black" :back="true">
		</TitleHeader>
		<AutoView :footer="false">
			<div class="pay-success">
				<img src="/static/shopEcology/state_img_success.png" alt="">
				<div class="title">支付成功</div>
				<div class="small-title">铺参谋会尽快与您联系，请您保持手机畅通</div>
                <div class="btn-wrapper">
				    <div class="back" @click="back">返回商铺广场</div>
				    <div class="detail" @click="look">查看订单详情</div>
                </div>
			</div>
		</AutoView>
	</div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        back() {
          this.JSBridge.callHandler("onBackPressed");
        },
        look() {
            this.swRouter({
                path: `/shopEcology/lease/order-details?id=${this.$route.query.id}`,
            })
          this.JSBridge.callHandler("closePage");
        }
    }
}
</script>

<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.pay-success {
        height: 100%;
		text-align: center;

		img {
			width: vw(174);
			height: vw(174);
		    margin-top: vw(112);
		}

		.title {
			margin-top: vw(40);
			height: vw(42);
			font-size: vw(30);
			font-weight: 600;
			color: #333333;
			line-height: vw(42);
		}

		.small-title {
			width: vw(650);
			font-size: vw(24);
			color: #999999;
			line-height: vw(40);
			margin: vw(6) auto 0;
		}

        .btn-wrapper {
            font-size: vw(30);
            margin-top: vw(64);
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            .back {
                width: vw(285);
                height: vw(80);
                line-height: vw(80);
                text-align: center;
                background: #fff;
                border-radius: vw(8);
                color: #347FFF;
                border: vw(1) solid #347FFF;
            }
            .detail {
                width: vw(285);
                height: vw(80);
                line-height: vw(80);
                text-align: center;
                background: #347FFF;
                border-radius: vw(8);
                color: #FFFFFF;
                margin-left: vw(20);
                // margin: vw(68) auto 0;
            }
        }

	}
</style>
