<!-- 该组件仅做首页栏目中转处理，无视图输出 -->
<template>
    <div>&nbsp;</div>
</template>
<script>
export default {
    name: 'cards',
    beforeCreate () {
        const section = this.$route.params.section;
        switch ( section ) {
            // 市调报告
            case '10002':
            case 'A': {
                const item = {
                    showTemplateCnfId: 10002,
                    showQuestion     : '市场调研报告',
                    inputTypes       : 'REGION',
                    pickerTitle      : '意向店址', // 选择控件标题
                    custom           : true,
                    cover            : '/static/img/custom_A.jpg',
                };
                this.$router.replace( { name: 'additionalSP', params: item } );
                break;
            }
            // 开店选址
            case '10001':
            case 'B': {
                const item = {
                    showTemplateCnfId: 10001,
                    showQuestion     : '选址分析报告',
                    inputTypes       : 'GPS_R;INDUSTRY;ATV',
                    custom           : true,
                    cover            : '/static/img/custom_B.jpg',
                };
                this.$router.replace( { name: 'additional', params: item } );
                break;
            }
            // 招商加盟
            case '10003':
            case 'C': {
                const item = {
                    showTemplateCnfId: 10003,
                    showQuestion     : '开店加盟分析',
                    inputTypes       : 'GPS_R;INDUSTRY;ATV',
                    custom           : true,
                    cover            : '/static/img/custom_C.jpg',
                };
                this.$router.replace( { name: 'brand', params: item } );
                break;
            }
            // 品牌洼地
            case '10004':
            case 'D': {
                const item = {
                    showTemplateCnfId: 10004,
                    showQuestion     : '加盟品牌位置分析',
                    inputTypes       : 'REGION',
                    custom           : true,
                    cover            : '/static/img/custom_D.png',
                    data             : this.$route.query,
                    id               : this.$route.query.brandCode || 0, // 埋点上报预留
                };
                this.$router.replace( { name: 'additionalSP10004', params: item } );
                break;
            }
            // 客群评估
            case '10005': {
                const item = {
                    showTemplateCnfId: 10005,
                    showQuestion     : '周边客群分析',
                };
                console.log( item );
                break;
            }
            // 周边对手分析
            case '10006': {
                const item = {
                    showTemplateCnfId: 10006,
                    showQuestion     : '周边对手分析',
                };
                console.log( item );
                break;
            }
            default:
                this.$router.replace( '/404' );
        }
    },
};
</script>
