<template>
	<div class="box" v-if="slides.length">
		<carousel-3d :autoplay="false" :autoplayTimeout="3000" :perspective="35" :display="5" :animationSpeed="1000"
			:width="vw(330)" :height="vw(490)" :controlsVisible="false" :controlsHeight="60">
			<slide v-for="(item, i) in slides" :index="i" :key="i">
				<template slot-scope="obj">
					<div class="banner-item">
						<img :src="item.avatar + '?x-oss-process=image/resize,m_fill,w_100'" alt="">
						<div class="name">{{item.name}}</div>
						<div class="content ell-4">{{item.introduction}}</div>
						<div class="number-box">
							<div class="number-item">
								<div class="number-item-content">
									<div class="number">{{item.recommendTimes}}</div>
									<div class="type">次</div>
								</div>
								<div class="number-item-title">选址服务</div>
							</div>
							<div class="number-item">
								<div class="number-item-content">
									<div class="number">{{item.transferTimes}}</div>
									<div class="type">个</div>
								</div>
								<div class="number-item-title">商铺转让</div>
							</div>
							<div class="number-item">
								<div class="number-item-content">
									<div class="number">{{item.score}}</div>
									<div class="type">分</div>
								</div>
								<div class="number-item-title">评分</div>
							</div>
						</div>
						<div class="button-box" @click="goDetails(item)">
							个人主页
						</div>
					</div>
				</template>
			</slide>
		</carousel-3d>
	</div>
</template>
<!-- https://blog.csdn.net/memory_zzz/article/details/121079528?spm=1001.2101.3001.6650.7&utm_medium=distribute.pc_relevant.none-task-blog-2%7Edefault%7EBlogCommendFromBaidu%7ERate-7-121079528-blog-120725357.pc_relevant_antiscanv2&depth_1-utm_source=distribute.pc_relevant.none-task-blog-2%7Edefault%7EBlogCommendFromBaidu%7ERate-7-121079528-blog-120725357.pc_relevant_antiscanv2&utm_relevant_index=12 -->
<script>
	import {
		Carousel3d,
		Slide
	} from 'vue-carousel-3d'
	import {
		brokerIntroductionList
	} from "@/request/shopEcology"
	export default {
		data() {
			return {
				slides: []
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				brokerIntroductionList().then(res => {
					this.slides = res
				})
			},
			goDetails(item) {
				this.$emit('goDetails', item)
			},
			vw(num) {
				let width = document.body.clientWidth
				return width / 375 * num
			}
		},
		components: {
			Carousel3d,
			Slide
		}
	}
</script>
<style lang="scss" scoped>
	// @import '@/styles/index.scss';

	@function vw($args) {
		@return $args/750*100+vw;
	}

	.carousel-3d-container {
		margin: 0 auto !important;
	}

	.carousel-3d-slide {
		border: none;
		border-width: 0;
		background: transparent;
	}

	.box {
		width: 100%;
		position: absolute;
		left: 0%;
		top: vw(1460);
		z-index: 20;

		.banner-item {
			width: 100%;
			height: 100%;
			background: #FFFFFF;
			border-radius: vw(6);
			padding: vw(120) vw(94);
			box-sizing: border-box;
			background: url('../../../../../static/shopEcology/agent3.png') no-repeat;
			background-size: 100% 100%;
			text-align: center;
			box-shadow: 0px vw(2) vw(20) 0px #B1B1B1;
			border-image: linear-gradient(180deg, rgba(246, 248, 255, 1), rgba(255, 255, 255, 1)) 2 2;
      border: none;
			img {
				width: vw(118);
				height: vw(118);
				border: vw(4) solid #E4C6B3;
				border-radius: 50%;
				box-sizing: border-box;
			}

			.name {
				height: vw(50);
				font-size: vw(36);
				font-weight: 600;
				line-height: vw(50);
				margin-top: vw(32);
				color: #E4C6B3;
			}

			.content {
				margin-top: vw(48);
				font-size: vw(28);
				line-height: vw(40);
				box-sizing: border-box;
				height: vw(160);
				color: #E4C6B3;
				text-align: left;
			}

			.number-box {
				display: flex;
				margin-top: vw(48);
				height: vw(136);
				background: rgba(#D2BDB0, 0.19);
				border-radius: vw(8);

				.number-item {
					flex: 1;

					.number-item-content {
						height: vw(54);
						line-height: vw(54);
						display: flex;
						margin-top: vw(20);
						justify-content: center;

						.number {
							font-size: vw(44);
							color: #C1A797;
							font-weight: 600;
						}

						.type {
							font-size: vw(24);
							color: #C1A797;
							line-height: vw(60);
							margin-left: vw(4);
						}
					}

					.number-item-title {
						margin-top: vw(8);
						font-size: vw(24);
						text-align: center;
						height: vw(34);
						color: #C1A797;
						line-height: vw(34);
					}
				}
			}

			.button-box {
				margin-top: vw(48);
				font-size: vw(32);
				font-weight: 600;
				height: vw(100);
				line-height: vw(100);
				text-align: center;
				background: linear-gradient(146deg, #F6D7C3 0%, #D9B096 100%);
				box-shadow: 0px vw(2) vw(31) 0px #6E5A4D, inset 0px - vw(3) - vw(5) 0px #DDA27E;
				border-radius: vw(8);
				color: #78470F;
				box-sizing: border-box;

			}
		}
	}
</style>
