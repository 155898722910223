<template>
    <div class="bars-chart">
        <baseChart :option="option" v-on="$listeners" />
    </div>
</template>

<script>
import baseChart from '@/components/baseCharts/echarts';
import { chartsFontSize } from '@/utils';
export default {
    name      : 'PieChart',
    components: {
        baseChart
    },
    props: {
        noData: {
            type   : Boolean,
            default: false
        },
        seriesData: {
            type   : Array,
            default: () => [
                { name: 'a', value: 58.42 },
                { name: 'b', value: 22.16 },
                { name: 'c', value: 10.39 },
                { name: 'd', value: 7.47 },
                { name: 'e', value: 1.01 },
                { name: 'f', value: 0.55 }
            ]
        },
        otherOption: {
            type   : Object,
            default: () => ( {} )
        }
    },
    computed: {
        chartOptions () {
            return {
                // 首页、查区域、首页客群详情多个页面引用过该组件。在不影响效果的前提下修改
                showLabel  : true,
                showLegend : false,
                center     : ['50%', '50%'],
                legendRight: '3%',
                nameWidth  : 90,
                colorList  : [
                    ['#347FFF', '#9EC1F9'],
                    ['#3BD6A7', '#BDFFEB'],
                    ['#FFA881', '#FFD3B7'],
                    ['#F366AA', '#Ff6fAf'],
                    ['#FFD481', '#FFDf8f'],
                    ['#F48A79', '#Ff8f7f'],
                    ['#8F81F5', '#8F88Fa'],
                ],
                valueIsRate     : false,
                legendNameLength: 100,
                silent          : true,
                title: '行业\n占比',
                ...this.otherOption
            };
        },
        option () {
            const that = this;
            return {
                title: {
                    text        : this.chartOptions.title,
                    left        : 'center',
                    top         : 'center',
                    borderRadius: 50,
                    borderWidth : chartsFontSize( 20 ),
                    borderColor : 'rgba(255,255,255,0.5)',
                    textStyle   : {
                        fontSize  : chartsFontSize( 22 ),
                        fontFamily: 'PingFangSC-Medium, PingFang SC',
                        fontweight: 600,
                        color     : '#676C84'
                    }
                },
                tooltip: {
                    show     : false,
                    trigger  : 'item',
                    formatter: this.chartOptions.valueIsRate ? '{b}: {c}%' : '{b}: {d}%',
                    textStyle: {
                        color     : '#000',
                        fontSize  : 14,
                        fontWeight: 600,
                        fontFamily: 'DIN-Medium'
                    }
                },
                legend: {
                    type      : 'scroll',
                    show      : this.chartOptions.showLegend,
                    orient    : 'vertical',
                    top       : this.seriesData.length > 9 ? 20 : 'middle',
                    right     : this.chartOptions.legendRight,
                    icon      : 'rect',
                    itemWidth : 10,
                    itemHeight: 10,
                    formatter ( val ) {
                        const item = that.seriesData.find( ( el ) => el.name === val );
                        const name =
                            item.name.length > that.chartOptions.legendNameLength
                                ? item.name.slice( 0, that.chartOptions.legendNameLength ) + '\n' + item.name.slice( that.chartOptions.legendNameLength )
                                : item.name;
                        return '{a|' + name + '}'; // {p|' + Number(item.value) + ' %}'
                    },
                    textStyle: {
                        padding: [3, 0, 0, 0],
                        rich   : {
                            a: {
                                width: chartsFontSize( this.chartOptions.nameWidth ),
                                color: '#0094ff'
                            },
                            p: {
                                width: chartsFontSize( 20 ),
                                color: '#0094ff'
                            }
                        }
                    }
                },
                series: [
                    {
                        silent: this.chartOptions.silent,
                        name  : '',
                        type  : 'pie',
                        roseType: 'radius',// 'area',
                        radius: ['15%', '65%'],
                        color : this.chartOptions.colorList[0],
                        data  : this.seriesData.map( ( el, i ) => ( {
                            ...el,
                            itemStyle: {
                                borderColor  : '#fff',
                                borderWidth  : chartsFontSize( 2 ),
                                // shadowBlur: 10,
                                shadowOffsetX: 0,
                                // shadowColor: 'rgba(0, 0, 0, 0.5)',
                                color        : {
                                    type      : 'linear',
                                    x         : 0,
                                    y         : 0,
                                    x2        : 0,
                                    y2        : 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color : this.chartOptions.colorList[i][0] // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color : this.chartOptions.colorList[i][1] // 100% 处的颜色
                                        }
                                    ],
                                    global: false // 缺省为 false
                                }
                            }
                        } ) ),
                        startAngle: 30,
                        labelLine : {
                            // length: chartsFontSize(5),
                            length2     : chartsFontSize( 145 ),
                            smooth      : false,
                            minTurnAngle: 120,
                            lineStyle   : {
                                width: chartsFontSize( 1 )
                            }
                        },
                        label: {
                            show               : this.chartOptions.showLabel,
                            position           : 'outside',
                            alignTo            : 'left',
                            formatter          : '{name|{b}}  {value|{c}%}\n',
                            distanceToLabelLine: - 1 * chartsFontSize( 135 ),
                            rich               : {
                                name: {
                                    color     : '#ff9400',
                                    fontSize  : chartsFontSize( 22 ),
                                    fontWeight: 600,
                                    color     : '#1D2233'
                                },
                                value: {
                                    color     : '#8E9099',
                                    fontSize  : chartsFontSize( 22 ),
                                    fontWeight: 600
                                }
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur   : chartsFontSize( 10 ),
                                shadowOffsetX: 0,
                                shadowColor  : 'rgba(0, 0, 0, 0.5)'
                            },
                            focus: 'self'
                        }
                    }
                ]
            };
        }
    }
};
</script>

<style scoped>
.bars-chart {
    margin: auto;
    /* min-width: 400px; */
    width: 100%;
    height: 100%;
}
</style>
