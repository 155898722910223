<template>
    <div class="wrapper">
        <StateBar bgcolor="transparent" />
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
        <div v-show="!firstLoading">
            <div class="head">
                <VanImage @load="imageLoadDone" src="/static/img/zhihuInformation/bg_1.png"></VanImage>
                <div class="downloadBtn" @click="downLoad">立即免费下载</div>
            </div>
            <VanImage @load="imageLoadDone" src="/static/img/zhihuInformation/bg_2.png"></VanImage>
            <van-swipe class="swipe" :autoplay="3000" ref="swipe1">
                <van-swipe-item style="width: 100vw" v-for="(item, index) of swipe1" :key="index">
                    <van-image class="img" fit="cover" :src="item" />
                </van-swipe-item>
            </van-swipe>
            <VanImage @load="imageLoadDone" src="/static/img/zhihuInformation/bg_3.png"></VanImage>
            <van-swipe class="swipe" :autoplay="3000" ref="swipe2">
                <van-swipe-item style="width: 100vw" v-for="(item, index) of swipe2" :key="index">
                    <van-image class="img" fit="cover" :src="item" />
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>

<script>
let picNum = 0;
export default {
    data() {
        return {
            firstLoading: true,
            swipe1: ["/static/img/zhihuInformation/swipe_1_1.png", "/static/img/zhihuInformation/swipe_1_2.png", "/static/img/zhihuInformation/swipe_1_3.png"],
            swipe2: ["/static/img/zhihuInformation/swipe_2_1.png", "/static/img/zhihuInformation/swipe_2_2.png"],
        };
    },
    methods: {
        imageLoadDone() {
            picNum++;
            if (picNum >= 1) {
                this.firstLoading = false;
                this.$refs.swipe1.resize();
                this.$refs.swipe2.resize();
            }
        },
        downLoad() {
            let u = navigator.userAgent
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            if(isiOS) {
                window.location.href = this.HTTP.getAppStore()
            } else {
                location.href = 'https://sscm-channel-android.oss-cn-hangzhou.aliyuncs.com/Android-apk/SSCM_normal_production_release_V3.0.0_1_20220308110136_300_2_%E7%9F%A5%E4%B9%8E%E7%9F%A5%2B_sign.apk'
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    height: 100vh;
    ::v-deep .van-image {
        display: block;
        position: relative;
    }
    .head {
        position: relative;
        width: 100vw;
        .downloadBtn {
            position: absolute;
            left: 50%;
            bottom: 33vw;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80vw;
            height: 13.33vw;
            border-radius: 1.33vw;
            transform: translateX(-50%);
            font-weight: bold;
            color: #fff;
            font-size: 4vw;
            line-height: 6vw;
            background: linear-gradient(#2f6ef8, #3f87ff);
        }
    }

}
</style>
