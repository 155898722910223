<template>
    <div class="brand-list__wrap">
        <van-sticky :offset-top="headerHeight">
            <div class="select-box">
                <span class="sel-city" @click="handleClick">
                    {{ currentCity }}
                </span>
                <div v-if="!firstLoading" class="industry-tabs-wrap" :swipe-threshold="4">
                    <!-- <span v-for="(tab, i) in industryList" :class="{ active: i === tabIndex }" :key="i" @click="changeTab(i, tab)">{{
                        tab.name
                    }}</span> -->
                    <van-tabs v-model="tabIndex" ref="tabs">
                        <van-tab :title="tab.name" v-for="(tab, i) in industryList" :key="i"></van-tab>
                    </van-tabs>
                </div>
                <van-icon class="icon_" name="arrow-down" @click="selIntustry"/>
            </div>
        </van-sticky>
        <div class="content">
            <one-item v-for="(index, i) in 4" :key="i" :topNum="index - 1" hasFooter>
                <van-image v-if="i === 1" class="box-shadow" src="/static/img/hot-search/more-brand.png" />
            </one-item>
        </div>
    </div>
</template>

<script>
import OneItem from './OneItem.vue';
export default {
    components: { OneItem },
    name      : 'brandList',
    props     : {
        headerHeight: {
            type   : Number,
            default: 0
        }
    },
    data () {
        return {
            firstLoading: false,
            tabIndex    : 0,
            currentCity: '定位中',
            industryList: [
                {
                    name: '全部品类',
                    id  : ''
                },
                {
                    name: '火锅',
                    id  : ''
                },
                {
                    name: '川菜',
                    id  : ''
                },
                {
                    name: '烤肉',
                    id  : ''
                },
                {
                    name: '咖啡厅',
                    id  : ''
                },
                {
                    name: '烤肉',
                    id  : ''
                },
                {
                    name: '咖啡厅',
                    id  : ''
                }
            ],
            selType: false, // 选择类型
        };
    },
    created() {
        this.$nextTick(() => {
            this.tabIndex = this.$store.getters.industryType
        })
    },
    activated() {
        this.$nextTick(() => {
            this.tabIndex = this.$store.getters.industryType
        })
    },
    mounted () {
        this.JSBridge.callHandler( 'getLocation', null, ( res ) => {
            console.log( JSON.parse( res ) );
            const data = JSON.parse( res )
            this.currentCity = data.city
        } );
    },
    methods: {
        handleClick () {
            this.JSBridge.callHandler( 'selectCity', null, ( res ) => {
                console.log( res );
                const data = JSON.parse( res )
                this.currentCity = data.name
            } );
        },
        changeTab ( i, tab ) {
            this.tabIndex = i;
        },
        selIntustry() {
            this.$router.push('/hot/selectIntustry/'+this.tabIndex)
        }
    }
};
</script>

<style lang="scss" scoped>
.brand-list__wrap {
    ::v-deep .van-sticky--fixed {
        padding: 0 4vw;
        background: #f7f7f7;
    }
    .select-box {
        display: flex;
        align-items: center;
        height: 5.6vw;
        padding-top: 2vw;
        padding-bottom: 2vw;
        padding-left: 4vw;
        .sel-city {
            min-width: 20.26667vw;
            height: 7.5vw;
            line-height: 7.5vw;
            margin-right: 1.86vw;
            font-size: 4vw;
            text-align: center;
            position: relative;
            &::after {
                position: absolute;
                content: '';
                border: 1.0667vw solid transparent;
                border-top: 1.3334vw solid #999;
                top: 2.8vw;
            }
        }
        .industry-tabs-wrap {
            min-width: 73.6vw;
            height: 7.5vw;
            line-height: 100%;
            white-space: nowrap;
            ::v-deep .van-tabs__wrap {
                height: 12.533vw;
            }
            ::v-deep .van-tabs__nav{
                background: transparent;
                padding: 0;
                .van-tab {
                    min-width: 10.26667vw;
                    height: 7.5vw;
                    margin-right: 1.86vw;
                    font-size: 3.733vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    background: transparent;
                    box-shadow: none;
                    color: #666;
                    &--active{
                        color: #333333;
                        font-weight: 600;
                        position: relative;
                        &::after{
                            display: none;
                            position: absolute;
                            content: '';
                            border: 1.33vw solid transparent;
                            border-top: 1.33vw solid #FF6D00;
                            bottom: -2.53vw;
                            filter: drop-shadow();
                            z-index: 1;
                        }
                    }
                }
                .van-tabs__line {
                    display: block;
                    height: 1.33vw;
                    background: url('/static/icon/hot_icon.png') center no-repeat;
                    background-size: 7.47vw 1.07vw;
                }
            }
        }
        .icon_ {
            background: linear-gradient(90deg, rgba(247, 247, 247, 0.5) 0%, rgba(247, 247, 247, 1) 100%);
            transform: translateX(-15vw);
            &::before {
                width: 13.6667vw;
                padding-left: 3vw;
            }
        }
    }
    .content {
        padding: 0 4vw;
        .box-shadow {
            box-shadow: 0 0 2.266vw 0 rgba(0, 0, 0, 0.09);
        }
    }
}
</style>
