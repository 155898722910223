<template>
    <div>
        <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"> </van-skeleton>
        <div v-show="!firstLoading">
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-1.png">
                <div class="btn-group">
                    <div class="nor-btn" @click="deeplink({ type: '6', url: '#/community/drgGoods?tab=开店选址', btnId: '80101' })">
                        查看更多选址干货
                    </div>
                    <div class="nor-btn" @click="sitingLink">立即进行选址评估</div>
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-2.png">
                <div class="btn-group">
                    <div
                        class="nor-btn free"
                        @click="deepDataLink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '0' }, btnId: '80102' })"
                    >
                        立即查询意向城市
                    </div>
                    <div class="nor-btn free" @click="mallDeepLink({ type: '5', url: 'SEARCH_MALL', btnId: '80103' })">立即查询意向商场</div>
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-3.png">
                <div class="btn-group">
                    <div
                        class="nor-btn free"
                        @click="deepDataLink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '1' }, btnId: '80104' })"
                    >
                        立即查询周边客群
                    </div>
                    <div
                        class="nor-btn free"
                        @click="deepDataLink({ type: '5', url: 'SEARCH_SURROUNDING_V2', params: { mockClickIndex: '3' }, btnId: '80105' })"
                    >
                        立即查询人口热力
                    </div>
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-4.png">
                <div class="nor-btn free arrow" @click="deeplink_debounce({ type: '6', url: '#/introduce/10009', btnId: '80106' })">
                    立即进行铺位测评
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-5.png"></VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-6.png">
                <div class="nor-btn arrow" @click="deeplink({ type: '5', url: 'OPEN_SHOP', btnId: '80107' })">
                    点击下载更多财务报表
                </div>
            </VanImage>
            <VanImage @load="imageLoadDone" src="/static/img/store-map/tab2-7.png">
                <div class="btn-group" style="bottom: 10.66vw">
                    <div class="nor-btn left" @click="deeplink({ type: '6', url: '#/community/goodsList?tag=商铺租赁', btnId: '80108' })">
                        查看更多租赁干货
                    </div>
                    <div class="nor-btn" @click="deeplink({ type: '5', url: 'OPEN_SHOP', btnId: '80109' })">立即下载租赁合同</div>
                </div>
            </VanImage>
        </div>
    </div>
</template>

<script>
let picNum = 0;
let timer = null;
export default {
    name: 'SiteSelection',
    data () {
        return {
            firstLoading: true
        };
    },
    methods: {
        imageLoadDone () {
            picNum ++;
            if ( picNum >= 6 ) {
                this.firstLoading = false;
            }
        },
        deeplink_debounce(data) {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                this.deeplink(data)
            }, 500);
        },
        deeplink ( { type, url, params, btnId } ) {
            console.log( type, url, params, btnId, this.$route.meta.id );
            this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80100, btnId );
            this.JSBridge.callHandler( 'onNormalizingCall', {
                type,
                url,
                params: JSON.stringify( params )
            } );
        },
        sitingLink() {
            this.JSBridge.BTNCLICK(this.$route.meta.id, '', 80100, 80110);
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: window._appCode >= 300 ? "SKU_FILL_INFO_PAGE" : "BRAND_INTRODUCTION_V2",
                id: "10001",
                isClear: null,
            });
        },
        // 跳转新的查数据页面
        deepDataLink( { type, url, params, btnId } ) {
          console.log( type, url, params, btnId, this.$route.meta.id );
          this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80100, btnId );
          if (window._appCode && window._appCode >= 300) {
            this.JSBridge.callHandler( 'onNormalizingCall', {
              type: 5,
              url: "CHANGE_MAIN_TAB",
              id: "queryData",
              isClear: true,
            } );
          } else {
            this.JSBridge.callHandler( 'onNormalizingCall', {
              type,
              url,
              params: JSON.stringify( params )
            } );
          }
        },
        // 跳转商场列表
        mallDeepLink ( { type, url, params, btnId } ) {
          console.log( type, url, params, btnId, this.$route.meta.id );
          this.JSBridge.BTNCLICK( this.$route.meta.id, '', 80100, btnId );
          if (window._appCode && window._appCode < 300) {
            this.JSBridge.callHandler( 'onNormalizingCall', {
              type,
              url,
              params: JSON.stringify( params )
            } );
          } else {
            this.swRouter('/mall/search')
          }
        }
    }
};
</script>

<style lang="scss" scoped>
.van-image {
    width: 100%;
    margin-bottom: -3px;
    position: relative;
    ::v-deep .nor-btn {
        position: absolute;
        width: 78.66vw;
        height: 12.26vw;
        line-height: 12.26vw;
        background: linear-gradient(270deg, #2c51f8 0%, #647eff 100%);
        border-radius: 1.06vw;
        font-size: 4vw;
        color: #fff;
        bottom: 7.33vw;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-weight: bold;
        &.mul-line {
            line-height: 3.5vw;
            font-size: 3.46vw;
            p {
                font-size: 2.93vw;
                padding-top: 2.13vw;
                padding-bottom: 1.13vw;
                font-weight: normal;
            }
        }
        &.free {
            &::before {
                position: absolute;
                content: '';
                width: 8.53vw;
                height: 4.8vw;
                right: -1.33vw;
                top: -2.66vw;
                background: url('/static/icon/icon_free.png') center / 100% 100% no-repeat;
            }
        }
        &.arrow {
            text-indent: -2.5vw;
            &::after {
                position: absolute;
                content: '';
                width: 4vw;
                height: 4vw;
                transform: translateY(-2vw);
                top: 50%;
                margin-left: 1vw;
                background: url('/static/icon/icon_arrow_right2.png') center / 4vw 4vw no-repeat;
            }
        }
    }
    ::v-deep .btn-group {
        position: absolute;
        bottom: 7.6vw;
        left: 50%;
        transform: translateX(-50%);
        width: 84vw;
        display: flex;
        justify-content: space-between;
        .nor-btn {
            position: unset;
            width: 40.66vw;
            transform: translateX(0%);
        }
        .left {
            box-sizing: border-box;
            background: #fff;
            color: #4262fb;
            border: 0.26vw solid #4262fb;
        }
    }
}
</style>
