<template>
    <div class="hotspot" :class="{ hot: item.hot }" @click="onTap">
        <div class="hotspot--img">
            <van-image
                fit="cover"
                lazy-load
                :src="item.coverImage">
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
            <!-- <h6>{{ item.title || '' }}</h6> -->
        </div>

        <div class="hotspot--content">
            <h1>{{ item.title || '---' }}</h1>
            <p class="info f-tof">{{ item.digest || '---' }}</p>
            <!-- <div v-if="item.tags" class="label">
                <span v-for="( label, index ) in item.tags.split( ',' )" :key="index">{{ label }}</span>
            </div> -->
            <div class="label">
                <span v-if="item.original">原创</span>
                <span class="no-border">{{ item.author | subFormat }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'hotspot',
    props: {
        item: Object,
    },
    methods: {
        onTap () {
            this.$emit( 'tap', this.item );
        },
    },
    filters: {
        subFormat ( val ) {
            return typeof val === 'string' && val.length < 10 ? val : ( val.substr( 0, 10 ) + '...' );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.hotspot {
    @extend .l-panel;
    height: 34.27vw;
    position: relative;
    box-sizing: border-box;
    padding: 4.53vw 3.73vw;
    padding-left: 27.87vw;
    box-shadow: inset 0 0 0 1px #EDEDED;

    &.hot {
        .hotspot--img {
            &::after {
                content: '热门';
                position: absolute;
                top: 1.87vw;
                left: 2.53vw;
                background: radial-gradient(right top, circle cover, #F7928F, #F56958);
                background: -webkit-radial-gradient(right top, circle cover, #F7928F, #F56958);
                border-radius: .8vw;
                color: white;
                font-size: $font_240;
                padding: .87vw;
            }
        }
    }

    #{&}--img {
        position: absolute;
        left: 3.73vw;
        width: 20.27vw;
        height: 25.2vw;
        overflow: hidden;
        border-radius: $radius-xs;

        ::v-deep .van-image {
            width: 100%;
            height: 100%;
        }

        h6 {
            position: absolute;
            width: 100%;
            bottom: 3.73vw;
            font-size: $font_240;
            line-height: $font_347;
            padding: 0 2.5vw;
            box-sizing: border-box;
            color: white;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    #{&}--content {
        position: relative;
        height: 100%;
        h1 {
            color: $c-text;
            width: 100%;
            min-height: $font_560;
            max-height: $font_560 * 2;
            font-size: $font_453;
            line-height: $font_560;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .info {
            margin-top: 1.33vw;
            color: $c-info;
            font-size: $font_320;
            line-height: $font_440;
        }
        .label {
            position: absolute;
            bottom: 0;
            // overflow: hidden;
            margin-top: 2.13vw;
            height: $font_453;
            line-height: normal;
            span {
                float: left;
                height: $font_453;
                font-size: $font_267;
                line-height: $font_267;
                color: $c-main;
                box-sizing: border-box;
                border-radius: $radius-xs;
                box-shadow: inset 0 0 0 1px rgba(#518CDB, .51);
                padding: .93vw 2vw 0 2vw;
                margin-right: 1.33vw;
            }
            .no-border {
                box-shadow: none;
                color: $c-info;
                font-size: $font_293;
                line-height: $font_293;
                padding: .8vw 0 0;
            }
        }
    }
}
</style>
