<template>
    <div class="bars-chart">
        <baseChart :option="option" v-on="$listeners" />
    </div>
</template>

<script>
import baseChart from '@/components/baseCharts/echarts';
import { chartsFontSize } from '@/utils';
export default {
    name      : 'VerticalBarChart',
    components: {
        baseChart
    },
    props: {
        xAxisNames: {
            type   : Array,
            default: () => [11, 27711, 37111, 47711, 57111]
        },
        seriesData: {
            type   : Array,
            default: () => [
                [10, 12, 12, 12, 12]
                // [34, 34, 34, 34, 34],
                // [10, 12, 12, 12, 12],
                // [34, 34, 34, 34, 34]
            ]
        },
        otherOption: {
            type   : Object,
            default: () => ( {} )
        }
    },
    computed: {
        chartOptions () {
            // 首页、查区域、首页客群详情多个页面引用过该组件。在不影响效果的前提下修改
            return {
                legendNames    : ['a', 'b'], // [xxx, xxx]
                showValue      : true,
                axisLabelRotate: false,
                showLegend     : false,
                yAxisName      : ['热度占比', ''],
                yAxisUnit      : ['%', ''],
                colorList      : [
                    ['#CDE0FF', '#347FFF'],
                    '#009DD9',
                    '#3CB5EA',
                    '#71A6EC',
                    '#767EE2',
                    '#9C67F7',
                    '#FF7BA1',
                    '#FFA881',
                    '#FFD179',
                    '#08D0D9',
                    '#D0CCBF'
                ],
                barGap        : '50%',
                barWidth      : chartsFontSize( 34 ),
                barRadius     : chartsFontSize( 7 ),
                barBorderWidth: 0,
                stackName     : '',
                gridBottom    : '5%',
                legendType    : 'scroll',
                showTooltip   : false,
                silent        : true,
                labelColor: '#347FFF',
                ...this.otherOption
            };
        },
        option () {
            const that = this;
            return {
                title: {
                    // text: "商业区人均消费分布",
                    textStyle: {
                        fontSize: chartsFontSize( 12 )
                    }
                },
                tooltip: {
                    show       : this.chartOptions.showTooltip,
                    trigger    : 'axis',
                    axisPointer: {
                        type : 'shadow',
                        label: {
                            show: false
                        }
                    },
                    formatter: function ( params ) {
                        // console.log(params)
                        let result = `<div style="font-weight: 500">${params[0].name}</div>`;
                        params.forEach( ( param, index ) => {
                            result += `<div style="display: flex;align-items: center;justify-content:space-between;padding: 0;margin: 5px 0 0 0;"><div><span style="width: 6px;height: 6px;display:inline-block;border-radius:50%;background: ${
                                param.color
                            }"></span><span style="margin-left: 10px;color:#8893A7">${
                                param.seriesName
                            }：</span></div><span style='margin-left: 10px;text-align: right;'>${param.value}${that.chartOptions.yAxisUnit[index] ||
                                ''}</span></div>`;
                        } );
                        return result;
                    },
                    textStyle: {
                        color   : '#121622',
                        fontSize: 12
                    }
                },
                calculable: true,
                legend    : {
                    show      : this.chartOptions.showLegend,
                    type      : this.chartOptions.legendType,
                    bottom    : '3%',
                    itemGap   : 5,
                    itemWidth : 12,
                    itemHeight: 12
                },
                grid: {
                    top         : '5%',
                    left        : '3%',
                    right       : '20%',
                    bottom      : this.chartOptions.gridBottom,
                    containLabel: true
                },
                yAxis: [
                    {
                        type    : 'category',
                        axisLine: {
                            show     : true,
                            lineStyle: {
                                color: '#E3E0EE'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        data     : this.xAxisNames,
                        axisLabel: {
                            rotate: this.chartOptions.axisLabelRotate ? - 45 : 0,
                            color : '#666'
                        }
                    }
                ],
                xAxis: [
                    {
                        type     : 'value',
                        name     : this.chartOptions.yAxisName[0],
                        axisLabel: {
                            formatter: '{value}',
                            color    : '#8E9099',
                            fontSize : chartsFontSize( 20 )
                        },
                        nameTextStyle: {
                            align   : 'left',
                            color   : '#1D2233',
                            fontSize: chartsFontSize( 20 )
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#E3E0EE'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                color: ['#E3E0EE'],
                                type : 'dashed'
                            }
                        }
                    }
                ],
                series: this.seriesData.map( ( data, i ) => ( {
                    silent    : this.chartOptions.silent,
                    name      : this.chartOptions.legendNames[i],
                    type      : 'bar',
                    stack     : this.chartOptions.stackName,
                    yAxisIndex: 0,
                    barWidth  : this.chartOptions.barWidth,
                    barGap    : this.chartOptions.barGap,
                    symbol    : 'circle',
                    symbolSize: 8,
                    itemStyle : {
                        color: {
                            type      : 'linear',
                            x         : 0,
                            y         : 0,
                            x2        : 1,
                            y2        : 0,
                            colorStops: [
                                {
                                    offset: 0,
                                    color : this.chartOptions.colorList[i][0] // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color : this.chartOptions.colorList[i][1] // 100% 处的颜色
                                }
                            ],
                            global: false // 缺省为 false
                        },
                        barBorderRadius: this.chartOptions.barRadius,
                        borderWidth    : this.chartOptions.barBorderWidth,
                        borderColor    : 'transparent'
                    },
                    label: {
                        show     : this.chartOptions.showValue,
                        position : 'right',
                        formatter(val) {
                          return '{value|' + val.value + that.chartOptions.yAxisUnit[0]+'}'
                        },
                        rich: {
                          value: {
                            color: that.chartOptions.labelColor
                          }
                        },
                        fontSize : chartsFontSize(20)
                    },
                    data
                } ) )
            };
        }
    }
};
</script>

<style scoped>
.bars-chart {
    width: 100%;
    height: 100%;
}
</style>
