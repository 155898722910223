<template>
    <div class="walletHistory">
        <StateBar></StateBar>
        <TitleHeader title="明细" bgcolor="transparent" :back="true"></TitleHeader>

        <AutoView :header="true" :footer="false" class="walletHistory--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="wallets"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <div v-for="( item, index ) of list" :key="index" class="walletHistory--item l-panel">
                            <h3 class="f-tof">{{ item.remark }}</h3>
                            <p class="f-tof">{{ item.tradeTime | convDate }}</p>
                            <span class="f-tof" :class="'st-'+item.type">{{ convAmountStr( item ) }}</span>
                        </div>
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
export default {
    name: 'walletHistory',
    data () {
        return {
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            page        : 1,
            total       : 0,
            list        : [],
        };
    },
    filters: {
        convDate ( value ) {
            const sp1 = value.split( ' ' );
            return sp1[ 0 ].split( '-' ).join( '.' ) + ' ' + sp1[ 1 ];
        },
    },
    methods: {
        loadData () {
            this.loading  = true;
            this.HTTP.chargeList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        convAmountStr ( item ) {
            return '上上贝 ' + ( item.type === 2 ? '-' : '+' ) + item.amount;
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.walletHistory {
    height: 100vh;
    background-color: $c-pageBg;

    #{&}--view {
        .s-subtitle {
            color: $c-info;
        }
        .l-panel {
            padding: 5.33vw;
        }
    }

    #{&}--item {
        position: relative;
        padding-bottom: 4.53vw;
        margin-bottom: 5.2vw;
        box-shadow: inset 0 -1px $c-line;

        h3{
            font-size: $font_400;
            line-height: $font_560;
        }
        p, span {
            color: $c-info;
            font-size: $font_320;
            line-height: $font_400;
            margin-top: 1.6vw;
        }
        span {
            position: absolute;
            top: 5.33vw;
            right: 5.33vw;
            color: $c-info;
            margin-top: .8vw;

            &.st-1 {
                color: $c-notice;
            }
        }
    }
}
</style>
