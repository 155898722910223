<template>
  <div class="signin">
    <StateBar />
    <TitleHeader :title="title" bgcolor="transparent" color="black" :back="true"></TitleHeader>

    <!-- 骨架屏占位图 -->
    <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

    <AutoView ref="view" :header="true" :footer="false" class="signin--view" :style="{ opacity: loading ? 0 : 1 }">
      <div class="signin--main">
        <van-image class="banner min-h" fit="contain" :src="bannerImages" />
        <div class="main-warp">
          <div class="content-warp">
            <ul
              class="coupon-card"
              :style="{
                backgroundImage: 'url(' + (item.receiveStatus ? '/static/icon/coupon_bg_has.png' : '/static/icon/coupon_bg.png') + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginBottom: index == activeItemResList.length - 1 ? '0' : '4vw',
              }"
              v-for="(item, index) in activeItemResList"
              :key="item.couponId"
              @click="getCoupon(item)"
            >
              <li class="li-top">
                <i>{{ item.couponName }}</i>
              </li>
              <li class="li-bottom">{{ item.remark }}</li>
            </ul>
          </div>
        </div>
        <template v-if="bottomBanner && bottomBanner.length > 0">
          <van-image class="banner" fit="contain" v-for="(val,index) in bottomBanner" :key="index" :src="val" />
        </template>
      </div>
    </AutoView>

    <van-popup v-model="couponPopup" round closeable>
      <div class="signin-popup-wrap">
        <div class="type-noprize">
          <img src="/static/img/signin/signin-checked.png" alt="" />
          <p class="title-noprize">领取成功</p>
        </div>
        <div class="uesr-btn" @click="goHome">去使用</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Award from "@/components/card/award.vue";
import Signin from "@/components/card/signin.vue";

export default {
  components: { Award, Signin },
  data() {
    return {
      loading: true,
      activeId: 3,
      ruleSuccess: false, // 规则弹框
      title: "",
      bannerImages: null,
      bottomBanner: null,
      activeBodyImage: null,
      activeItemResList: [],
      couponPopup: false,
      btnLoading: false,
    };
  },
  created() {
    const id = this.$route.params.id;
    if (!id) {
      this.$toast("无效活动id，即将返回首页！");
      this.$router.push("/");
      return;
    }
    this.activeId = id;
    this.loadData();
  },
  methods: {
    goHome() {
      this.couponPopup = false;
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "CHANGE_MAIN_TAB",
        id: "home",
        params: null,
        trackData: null,
        isClear: true,
      });
    },
    getCoupon(item) {
      if (item.receiveStatus || this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      this.HTTP.receiveCoupon(this.activeId, item.couponId)
        .then((res) => {
          console.log(res);
          this.btnLoading = false;
          if (res.code == 0) {
            this.couponPopup = true;
            this.loadData();
          } else if (res.code == 260014) {
            this.$toast("不在活动期间内");
          } else {
            this.$toast(res.msg);
          }
        })
        .catch((e) => {
          this.btnLoading = false;
          if (e.code == 260014) {
            this.$toast("不在活动期间内");
          } else {
            this.$toast("网络不稳定，请稍候重试！");
          }
        });
    },
    loadData() {
      this.loading = true;
      this.HTTP.getActiveCoupon(this.activeId)
        .then((res) => {
          console.log(res);
          this.title = res.data.activeName;
          this.bannerImages = res.data.bannerImages && res.data.bannerImages[0];
          this.bottomBanner = res.data.bottomBanners;
          this.activeBodyImage = res.data.activeBodyImage;
          this.activeItemResList = res.data.activeItemResList || [];
          this.loading = false;
        })
        .catch((e) => {
          this.$toast("网络不稳定，请稍候重试！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

.signin {
  height: 100vh;

  #{&}--view {
    scroll-behavior: smooth;
    background: linear-gradient(to right, #fbecc3 0%, #fde5b5 100%);
  }

  #{&}--main {
    .banner {
      width: 100%;
      height: 100%;
    }
    .min-h {
      min-height: 60vw;
    }

    .main-warp {
      position: relative;
      .content-warp {
        width: 92%;
        background: url("/static/img/pic_bg.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        padding: 4vw 0;
        .coupon-card {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          height: 20.27vw;
          text-align: center;
          li {
            width: 66vw;
          }
          .li-top {
            font-size: 3.73vw;
            color: #f74620;
            i {
              font-size: 4.33vw;
              font-weight: bold;
              font-style: normal;
            }
          }
          .li-bottom {
            font-size: $font_267;
            color: #999;
            margin-top: 2.13vw;
          }
        }
      }
    }
  }

  .signin-popup-wrap {
    position: relative;
    padding: 8vw;
    text-align: center;
    width: 72vw;
    background: linear-gradient(100deg, #fcecc4 0%, #fde4b4 46%, #fee7bd 100%);
    .type-noprize {
      text-align: center;
      padding: 46px 0 20px 0;
      img {
        width: 18.67vw;
        height: 18.67vw;
      }
      .title-noprize {
        font-size: 4.53vw;
        color: #ff5900;
        font-weight: bold;
        margin-top: 3.2vw;
      }

      .prize-fail {
        font-size: 3.2vw;
        color: #ff5900;
        margin-top: 3.2vw;
        text-align: left;
        line-height: 4.8vw;
        padding: 0 3.2vw;
      }
    }
    .uesr-btn {
      width: 56vw;
      height: 10.67vw;
      line-height: 10.67vw;
      font-size: 4vw;
      font-weight: bold;
      color: #fff;
      margin: 0 auto;
      background: #ff5900 linear-gradient(90deg, #ff8000 0%, #ff5900 100%);
      border-radius: 20px;
    }
  }
}
</style>
