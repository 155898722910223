<template>
    <div class="wrapper">
        <StateBar></StateBar>
        <TitleHeader :title="isSaas ? '连锁品牌解决方案' : '完善信息'" bgcolor="transparent" :back="true">
        </TitleHeader>
        <AutoView :header="true" :footer="true">
            <van-image v-if="isSaas" src="/static/img/image_saas.png"></van-image>
            <div class="content">
                <div class="tips">
                    <img src="/static/icon/icon_information.png" alt="">
                    <div v-if="isSaas">如有意向请填写信息，我们将有专人联系您</div>
                    <div v-else>完善资料，每日免费查询次数由<span>1</span>次增加至<span>3</span>次</div>
                </div>
                <InformationCard label="身份" :list="identityList" :value="submitOpt.identity" :error="errorInfo.identityError" name="identity" errname="identityError"  @selectValue="selectHandle"/>

                <InformationCard v-if="showIndustry" label="行业" :value="submitOpt.industryName" :list="industryList" :error="errorInfo.industryErr" name="industryName" errname="industryErr" @selectValue="selectHandle"/>

                <InformationCard v-if="showPost" label="职位" :value="submitOpt.position" name="position" errname="positionErr" :list="postList" :error="errorInfo.positionErr" @selectValue="selectHandle"/>

                <InformationCard v-if="identityType === '2'" label="项目名称" :value="submitOpt.projectName" type="input" name="projectName" errname="projectErr" placeholder="请填写项目名称" @input="changeInput" :error="errorInfo.projectErr"/>

                <InformationCard v-if="showBrand" label="品牌名称" :value="submitOpt.brandName" type="input" name="brandName" errname="brandErr" :error="errorInfo.brandErr" placeholder="请填写品牌名称、公司名称或者项目名称" @input="changeInput"/>

                <InformationCard v-if="identityType === '3'" name="companyName" errname="companyErr" :value="submitOpt.companyName" label="公司名称" type="input" placeholder="请填写公司名称" @input="changeInput" :error="errorInfo.companyErr"/>

                <InformationCard v-if="showShop" label="门店数量" :value="submitOpt.openShopCount" name="openShopCount" :list="rangeList" :error="errorInfo.shopErr"  @selectValue="selectHandle"/>

                <InformationCard v-if="identityType === '2'" name="openShopTime" errname="timeErr" :value="submitOpt.openShopTime" label="经营时长" :list="timeList" :error="errorInfo.timeErr"  @selectValue="selectHandle"/>

                <InformationCard label="想解决什么问题" remark="(多选)" name="solveProblem" errname="questionErr" :error="errorInfo.questionErr" :list="questionList" multiple :multipleList="submitOpt.solveProblem" @multipleSelect="selectHandle" />
                <van-field v-model="other" @input="changeHandle" placeholder="如需解决其他问题可填写" />

                <InformationCard label="性别" :list="sexList" :value="submitOpt.gender" name="gender" errname="sexErr" :error="errorInfo.sexErr" @selectValue="selectHandle"/>

                <InformationCard label="年龄" type="select" :value="submitOpt.age" name="age" errname="ageErr" :error="errorInfo.ageErr" placeholder="请选择" :columns="columns" @onConfirm="selectHandle"/>

                <template v-if="isSaas">
                    <InformationCard label="姓名" type="input" :value="submitOpt.username" placeholder="怎么称呼您？" name="username" errname="usernameErr" :error="errorInfo.usernameErr" @input="changeInput" />

                    <InformationCard label="手机号" type="number" name="phone" :value="submitOpt.phone" remark="(选填)" placeholder="请输入11位手机号码" :error="errorInfo.phoneErr" :isRequired="false" @input="changeInput" />
                </template>
            </div>
        </AutoView>
        <SoltFooter>
            <div class="submitFooter">
                <van-button type="info" :disabled="submitLoading" block @click="clickSubmitHandle">提交</van-button>
            </div>
        </SoltFooter>
        <van-popup v-model="sussessPopup" @click-overlay="confirmfHandle" style="border-radius: 3.2vw;">
            <div class="success">
                <img src="/static/icon/loan_success.png" alt="">
                <div class="desc">
                    <template v-if="isEdit">
                        <div class="title">修改成功</div>
                    </template>
                    <template v-else>
                        <template v-if="isSaas">
                            <div class="title">提交成功</div>
                            <div>请耐心等待</div>
                        </template>
                        <template v-else>
                                <div class="title">已完善信息</div>
                                <div>每日免费查询次数由<span> 1 </span>次增加至<span> 3 </span>次</div>
                        </template>
                    </template>
                </div>
                <div class="btn" @click="confirmfHandle">
                    <van-button type="info" block>确认</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import InformationCard from './components/informationCard.vue'
const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
const errorInfo =  {
    identityError: "",
    industryErr: "",
    positionErr: "",
    brandErr: "",
    questionErr: "",
    timeErr: "",
    sexErr: "",
    ageErr: "",
    projectErr: "",
    companyErr: "",
    usernameErr: "",
    phoneErr: "",
    shopErr: ''
}
const submitOpt = {
    identity: "",
    position: "",
    industryName: "",
    openShopTime: "",
    solveProblem: [],
    openShopCount: "",
    gender: "",
    brandName: "",
    age: "",
    projectName: "",
    companyName: "",
    username: "",
    phone: ""
}
export default {
    data() {
        return {
          submitLoading: false,
            identityList: [
                { value: "1", name: "连锁品牌方" },
                { value: "2", name: "个体经营者" },
                { value: "3", name: "公司职员" },
                { value: "4", name: "自由职业" },
                { value: "5", name: "教育工作者/学生" },
                { value: "6", name: "其他" },
            ],
            industryList: [
                { name: "餐饮" },
                { name: "零售" },
                { name: "地产" },
                { name: "教育" },
                { name: "娱乐" },
                { name: "广告" },
                { name: "丽人" },
                { name: "其他" },
            ],
            postList: [
                { name: "市场调研人员" },
                { name: "网规拓店人员" },
                { name: "咨询顾问" },
                { name: "数据分析师" },
                { name: "产品运营" },
                { name: "老板" },
                { name: "招商人员" },
                { name: "其他" },
            ],
            rangeList: [
                { name: "1-20家" },
                { name: "21-50家" },
                { name: "51-100家" },
                { name: "101-500家" },
                { name: "501-1000家" },
                { name: "大于1000家" },
            ],
            timeList: [
                { name: "小于1年" },
                { name: "1年-3年" },
                { name: "3年-5年" },
                { name: "5年以上" },
            ],
            questionList: [
                { name: "评估位置好坏" },
                { name: "找好的开店位置" },
                { name: "找品牌商机" },
                { name: "市场调研" },
                { name: "分析竞争对手/环境" },
                { name: "学术研究" },
                { name: "找商铺资源" },
                { name: "寻求合作" },
            ],
            sexList: [
                { name: "男" },
                { name: "女" },
            ],
            columns: ["18岁以下", "18-25", "26-30", "31-40", "41-50", "51-60", "60岁以上"],
            other: "",
            identityType: "",
            submitOpt: { ...submitOpt },
            errorInfo: { ...errorInfo },
            sussessPopup: false,
            type: "",
            isEdit: false,
        }
    },
    components: {
        InformationCard
    },
    computed: {
        showIndustry() {
            return ["1", "2", "3", "4"].includes(this.identityType)
        },
        showPost() {
            return ["1", "3"].includes(this.identityType)
        },
        showShop() {
            return ["1", "2"].includes(this.identityType)
        },
        showBrand() {
            return this.identityType === "1"
        },
        isSaas() {
            return this.type === "2"
        }
    },
    created() {
        this.type = this.$route.query.type
    },
    mounted() {
        this.HTTP.getInformation(this.type).then(res => {
            if(res.data) {
                this.isEdit = true
                this.submitOpt = res.data
                this.other = res.data.other
                this.submitOpt.solveProblem = JSON.parse(res.data.solveProblem)
                const result = this.identityList.filter(item => {
                    return item.name === res.data.identity
                })
                this.identityType = result[0].value
            }
        })
    },
    methods: {
        clickSubmitHandle() {
            this.verificationFunc()
            const canSubmit = Object.values(this.errorInfo).every(item => !item)
            if(canSubmit) {
                this.submitLoading = true;
                this.HTTP.perfectInformation({
                    type: Number(this.type),
                    ...this.submitOpt,
                    other: this.other,
                    solveProblem: this.submitOpt.solveProblem.length ? JSON.stringify(this.submitOpt.solveProblem) : null
                }).then(res => {
                    if(res.code == 0) {
                        this.sussessPopup = true
                    }
                    this.submitLoading = false;
                }).catch(() => {
                  this.submitLoading = false;
                    this.$toast.fail("网络错误，请稍后再试")
                })
              // this.confirmfHandle();
            }
        },
        selectHandle(value, type, err) {
            if(type === "identity") {
                this.errorInfo = Object.assign({}, errorInfo)
                this.submitOpt = Object.assign({}, submitOpt)
                this.submitOpt.solveProblem = []
                this.other = ""
                const res = this.identityList.filter(item => {
                    return item.name === value
                })
                this.identityType = res[0].value
            }
            if(value) {
                this.errorInfo[err] = ""
            }
            this.submitOpt[type] = value
        },
        changeHandle(e) {
            this.other = e
        },
        changeInput(value, type, err) {
            this.submitOpt[type] = value
            if(type === 'phone') {
                this.errorInfo.phoneErr = value ? reg.test(this.submitOpt.phone) ? "" : "请填写正确的手机号" : ""
            }
            if(value) {
                this.errorInfo[err] = ""
            }
        },
        confirmfHandle() {
            this.sussessPopup = false
            if(this.isSaas) {
                this.JSBridge.callHandler("onNormalizingCall", {
                    type: 5,
                    url: "CHANGE_MAIN_TAB",
                    id: "me",
                    isClear: true,
                })
            } else {
                if(window._appCode && window._appCode >= 300) {
                    if(this.isEdit) {
                        this.JSBridge.callHandler("onBackPressed");
                    } else {
                        this.JSBridge.callHandler("onNormalizingCall", {
                            type: 5,
                            url: "CHANGE_MAIN_TAB",
                            id: "queryData",
                            isClear: true,
                        })
                    }
                } else {
                    this.JSBridge.callHandler("onNormalizingCall", {
                        type: 5,
                        url: "SEARCH_SURROUNDING_V2",
                        isClear: true,
                    })
                }
            }
        },
        verificationFunc() {
            this.errorInfo.identityError = this.submitOpt.identity ? "" : "请选择你的身份"
            this.errorInfo.sexErr = this.submitOpt.gender ? "" : "请选择你的性别"
            this.errorInfo.ageErr = this.submitOpt.age ? "" : "请选择你的年龄"
            if(!this.submitOpt.solveProblem.length) {
                this.errorInfo.questionErr = this.other ? "" : "请选择你需要解决的问题"
            } else {
                this.errorInfo.questionErr = ""
            }
            if(this.showIndustry) {
                this.errorInfo.industryErr = this.submitOpt.industryName ? "" : "请选择你的行业"
            }
            if(this.showShop) {
                this.errorInfo.shopErr = this.submitOpt.openShopCount ? "" : "请选择你的门店数量"
            }
            if(this.showPost) {
                this.errorInfo.positionErr = this.submitOpt.position ? "" : "请选择你的职位"
            }
            if(this.showBrand) {
                this.errorInfo.brandErr = this.submitOpt.brandName ? "" : "请选择你的品牌名称"
            }
            if(this.identityType === '2') {
                this.errorInfo.projectErr = this.submitOpt.projectName ? "" : "请选择你的项目名称"
                this.errorInfo.timeErr = this.submitOpt.openShopTime ? "" : "请选择你的经营时长"
            }
            if(this.identityType === '3') {
                this.errorInfo.companyErr = this.submitOpt.companyName ? "" : "请选择你的公司名称"
            }
            if(this.isSaas) {
                this.errorInfo.usernameErr = this.submitOpt.username ? "" : "请填写你的姓名"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.wrapper {
    color: $c-text;
    height: 100vh;
    overflow-x: hidden;
}
.content {
    padding: 0 3.2vw;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.tips {
    background: linear-gradient(to right, rgba(234, 242, 255, 0), rgba(234, 242, 255, 1), rgba(234, 242, 255, 0));
    font-size: $font_320;
    @include flex;
    justify-content: center;
    line-height: $labelHeight;
    padding: 1.5vw 0;
    img {
        width: 2.933vw;
        height: 2.667vw;
        margin-right: 1.5vw;
    }
    span {
        color: $c-main;
    }
}
::v-deep .van-cell {
    border: 0.133vw solid #E4E7ED;
    background-color: #F7F8FB ;
    padding: 1.33vw 2.66vw;
    margin-bottom: 2.667vw;
    border-radius: $radius-xs;
    ::-webkit-input-placeholder {
        color: #ABB3C1;
    }
}
::v-deep .van-picker__confirm {
        color: $c-main;
}
.success {
    width: 80vw;
    height: 75.467vw;
    border-radius: $radius-s;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
        width: 19.73vw;
        height: 19.73vw;
        margin: 10.667vw 0 5.33vw;
    }
    .desc {
        @include font(inherit, $font_347, normal);
        color: $c-text;
        line-height: 5.2vw;
        margin-bottom: 4vw;
        .title {
            @include font(inherit, $font_427, bold);
            line-height: 5.867vw;
            color: #333333;
            text-align: center;
        }
        span {
            color: $c-main;
        }
    }
    .btn {
        position: absolute;
        bottom: 5.33vw;
        width: 72vw;
    }
}
.submitFooter {
    width: 100%;
    height: $footerHeight;
    padding: $headerMargin $padding;
    box-sizing: border-box;
    border: 0.133vw solid #EBEAEE;
    box-shadow: 0 -0.267vw 0.8vw 0 #EBEAEE;
    ::v-deep .van-button {
        height: 100%;
        font-size: $font_400;
        line-height: normal;
    }
}
</style>
