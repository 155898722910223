<template>
    <div class="marker" :style="{ backgroundColor: bgColor }" @click="clickHandle">
      <Icon class="marker--icon" name="double-quote" :with="2.93" :height="2.67"></Icon>
      <span class="marker--text">{{ data.title }}</span>
    </div>
</template>

<script>
import { random } from '@/utils';

const MARKER_COLORS = ['#518CDB', '#3274CD', '#73A8EE'];

export default {
    name : 'TextMarker',
    props: {
        data: Object
    },
    data () {
        return {
            bgColor: MARKER_COLORS[random( 0, 2 )]
        };
    },
    methods: {
        clickHandle () {
            this.$emit( 'click', this.data );
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.marker {
  transform: translate(-50%, -100%);
  box-sizing: border-box;
  width: max-content;
  max-width: 42.27vw;
  max-height: 14.93vw;
  display: flex;
  padding: 2.93vw 4.8vw 2.53vw 3.47vw;
  // border: 1px solid rgba(48,121,218,1);
  border-radius: 2.67vw;
  box-shadow: 0 1.33vw 2.67vw 0 rgba(81,140,219,0.39);
  #{&}--icon {
    flex-shrink: 0;
    margin-top: 0.67vw;
    margin-right: 3.33vw;
  }
  #{&}--text {
    @include font($c_FFFFFF, $font_320, $weight_500);
    line-height: 4.67vw;
    @include limitLine(2);
  }
}
</style>
