<template>
    <div class="all_content">
        <StateBar bgcolor="white" />
        <TitleHeader :title="title" :back="true" style="border-bottom: 1px solid #EBEAEE;box-sizing: content-box;"></TitleHeader>

        <AutoView ref="view" :header="true" :footer="false" class="all_content--view">
            <van-skeleton :loading="loadingData" :animate="false" :row="4" class="s-skeleton">
                <div class="all_content--main">
                    <div class="no_data" v-if="list.length <= 0 && !isFlag">
                        <EmptyCard  type="community"></EmptyCard>
                    </div>
                    <template v-else>
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            :immediate-check="false"
                            @load="loadData">
                            <div class="comments_swrap">
                                <div class="list" v-for="(val, index) in list" :key="index" @click="openCommontsInput(val.comment.id, 1)">
                                    <p class="time">{{val.comment.time}}</p>
                                    <div class="box">
                                        <div class="comments_img">
                                            <van-image class="card_info--img s-radius__xs" fit="cover" lazy-load :src="val.cardInfo.logoUrl">
                                                <template v-slot:loading>
                                                    <van-loading type="spinner" size="20" />
                                                </template>
                                            </van-image>
                                        </div>
                                        <div class="title">
                                            <h3>{{val.cardInfo.brandName}}</h3>
                                            <p>预估总投资额：<span>{{val.cardInfo.joinTotalCost}}</span></p>
                                        </div>
                                        <div class="label_tab" v-show="val.cardInfo.newCategoryNameL2">{{val.cardInfo.newCategoryNameL2}}</div>
                                    </div>
                                    <div class="rate_jx">
                                        <div class="left">
                                            <div class="text_rate">
                                                <span>打分</span>
                                                <van-rate readonly v-model="val.comment.score" gutter="0.53vw" size="3.47vw" void-icon="star" color="#FFCB00" void-color="#D8D8D8" />
                                                <small style="clear: both"></small>
                                            </div>
                                            <p style="clear: both"></p>
                                            <div class="tabs_box" v-if="val.comment.tags.length > 0">
                                                <p v-for="(item, index) in val.comment.tags" :class="item == '需谨慎加盟' ? 'noColor': ''" :key="index">{{item}}</p>
                                            </div>
                                        </div>
                                        <img class="top_select" v-if="val.comment.top == 1" src="/static/img/select_top.png" alt="">
                                    </div>
                                    <p class="text_p">{{val.comment.content}}</p>
                                    <div class="click_com">
                                        <div class="click_com_item" @click.stop="giveLike(val.comment.id, val.comment.isNice,index)">
                                            <img v-if="val.comment.isNice == false" src="/static/icon/zan-4.png" alt="">
                                            <img v-else src="/static/icon/zan2.png" alt="">
                                            <span v-if="val.comment.nice == 0">赞</span>
                                            <span v-else>{{val.comment.nice}}</span>
                                        </div>
                                        <div class="click_com_item click_com_last" @click.stop="openCommontsInput(val.comment.id, 2)">
                                            <img src="/static/icon/pinglun.png" alt="">
                                            <span v-if="val.comment.commentCount == 0">评价</span>
                                            <span v-else>{{val.comment.commentCount}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </template>
                </div>
            </van-skeleton>
        </AutoView>
    </div>
</template>


<script>
export default {
    name: "myCommonts",
    data() {
        return {
            title: "我的品牌评价",
            loadingData: true,
            loading: true,
            finished: false,
            isFlag: false,
            params: {
                current: 1,
                size: 10
            },
            // refId: this.$route.params.id,
            total: 0,
            list: [],
        }
    },
    methods: {
        // 点赞
        giveLike(id, bool, index) {
            let status = '';
            if(bool) {
                status = 0;
            } else {
                status = 1;
            }
            this.HTTP.giveLike(id, status).then(res => {
                this.list[index].comment.isNice = !this.list[index].comment.isNice;
                if(status == 1) {
                    this.list[index].comment.nice = ++this.list[index].comment.nice;
                } else {
                    this.list[index].comment.nice = --this.list[index].comment.nice;
                }
                console.log(res)
            }).catch(err => {
                this.$toast("网络不稳定，请稍后再试！");
            })
        },
        loadData() {
            this.loading = true;
            this.isFlag = true;
            this.HTTP.myCommonts(this.params).then(res => {
                console.log(res)
                this.loadingData = false;
                this.loading = false;
                this.isFlag = false;
                this.params.current++;
                this.total = res.data.total;
                if(this.list.length) {
                    this.list = this.list.concat(res.data.data);
                } else {
                    this.list = res.data.data;
                }
                if (res.data.data.length < res.data.size || this.list.length >= this.total) {
                    this.finished = true; // 完成加载
                }
            }).catch((e) => {
                this.$toast("网络连接不稳定，请稍候重试！");
                this.loading = false;
            });
        },
        // 跳转到详情
        openCommontsInput(id, num) {
            this.swRouter({
                path: '/brand/commonts_detail/'+id,
                query: { num: num }
            });
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.all_content{
    height: 100vh;
    overflow: hidden;
    background: #F7F7F7;
    #{&}--main{
       width: 100%;
       height: auto;
       .no_data{
           padding: 4vw;
       }
        .comments_swrap{
            width: 100%;
            height: auto;
            .list{
                width: 100%;
                height: auto;
                background: #fff;
                margin-bottom: 2.67vw;
                padding: 3.33vw 4vw;
                box-sizing: border-box;
                .time{
                    font-size: 2.93vw;
                    color: #999999;
                    line-height: 4vw;
                }
                .box{
                    width: 100%;
                    height: auto;
                    margin-top: 2.67vw;
                    padding: 3.33vw;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    box-shadow: 0vw 0vw 2.27vw 0vw rgba(0, 0, 0, 0.06);
                    border-radius: 1.33vw;
                    border: 1px solid #E7E7E7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .comments_img{
                        width: 13.33vw;
                        height: 13.33vw;
                        background: #DBDBDB;
                        border-radius: 0.67vw;
                        margin-right: 2.13vw;
                        ::v-deep .s-radius__xs{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .title{
                        flex: 1;
                        h3{
                            font-size: 4vw;
                            font-weight: bold;
                            color: #1D2233;
                            line-height: 5.6vw;
                        }
                        p{
                            font-size: 3.47vw;
                            color: #999999;
                            line-height: 4.93vw;
                            margin-top: 2.13vw;
                            span{
                                color: #666666;
                            }
                        }
                    }
                    .label_tab{
                        width: auto;
                        line-height: normal;
                        // background: #FFF1E9;
                        border-radius: 0.67vw;
                        border: 1px solid #FF9F6B;
                        padding: 1px 1.6vw;
                        font-size: 3.2vw;
                        color: #FF5900;
                    }
                }
                .rate_jx{
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    position: relative;
                    margin-top: 3.33vw;
                    .left{
                        flex: 1;
                        .text_rate{
                            width: 100%;
                            height: auto;
                            font-size: 0;
                            line-height: normal;
                            float: left;
                            span{
                                float: left;
                                font-size: 2.93vw;
                                color: #333333;
                                line-height: 4vw;
                                margin-right: 0.8vw;
                                line-height: normal;
                                font-weight: bold;
                            }
                            ::v-deep .van-rate--readonly{
                                position: relative;
                                top: 0.27vw;
                            }
                        }
                        .tabs_box{
                            margin: 2.13vw 0 0 0;
                            font-size: 0;
                            overflow-y: auto;
                            white-space: nowrap;
                            line-height: normal;
                            p{
                                display: inline-block;
                                // background: #FFF1E9;
                                border-radius: 0.67vw;
                                line-height: normal;
                                border: 1px solid #FF9F6B;
                                padding: 0.8vw 2.27vw;
                                font-size: 2.93vw;
                                color: #FF5900;
                                margin-right: 1.33vw;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            .noColor{
                                border: 1px solid #D8D9DF;
                                color: #999999;
                                background: transparent;
                            }
                        }
                    }
                    .top_select{
                        width: 10.8vw;
                        height: 8.93vw;
                        position: absolute;
                        top: 1.87vw;
                        right: 0;
                        z-index: 0;
                    }
                }
                .text_p{
                    margin-top: 2.67vw;
                    font-size: 3.47vw;
                    color: #333333;
                    line-height: 4.93vw;
                }
                .click_com{
                    width: 100%;
                    height: auto;
                    text-align: right;
                    font-size: 0;
                    margin-top: 2.27vw;
                    .click_com_item{
                        display: inline-block;
                        img {
                            width: 4vw;
                            height: 4vw;
                            vertical-align: middle;
                            margin-right: 1.33vw;
                        }
                        span{
                            font-size: 3.2vw;
                            color: #666666;
                            line-height: 4.4vw;
                            vertical-align: middle;
                        }
                    }
                    .click_com_last{
                        margin-left: 7.47vw;
                    }
                }
            }
        }
    }
}
</style>
