// uniapp
// import {loadScript} from "../utils";
import { commonConfigObj } from '@/constant'
import store from '@/store'

import router from '@/router'


/**
 * 小程序跳转方式
 */
export const UniRouteType = {
  navigateTo: 'navigateTo',
  redirectTo: 'redirectTo',
  reLaunch: 'reLaunch',
  switchTab: 'switchTab',
  navigateBack: 'navigateBack'
}

/**
 * 小程序页面路由
 */
export const UniPages =  {
  /**
   * 登录页
   */
  LoginPage: '/pages/authorize/index',
  /**
   * 会员页
   */
  VipPage: '/pages_me/me/vipShop/index',
  /**
   * 小程序缺省页
   */
  DefaultPage: '/pages_home/default',
  /**
   * 小程序客服页
   */
  KefuPage: '/pages_kefu/index',
  /**
   * 小程序我的页面
   */
  MePage: '/pages/me/index'
}

let beforeUrl = ''
/**
 * 提供小程序使用的api
 */
class UniUtils {
  constructor() {
    // loadScript('https://unpkg.com/@dcloudio/uni-webview-js@0.0.3/index.js', () => {
    //   console.log('加载uni环境完成')
    // })
  }

  /**
   * 跳转小程序原生页面
   * @param options
   * @param routeType
   */
  miniRouter(options, routeType = UniRouteType.navigateTo) {
    console.log('options',options)
    uni[routeType](options);
  }

  /**
   * 小程序webview跳转
   * @param url
   */
  webviewRouter(url, routeType = UniRouteType.navigateTo) {
    // 处理会员页跳转
    if (url.includes('/h5/#/member')) {
      this.goMiniVipPage()
      return
    }
    url = `${url.includes('#') ? '' : '#'}${url}`
    const _url = `/pages/webview/index?type=PARAMSLINK&link=${encodeURIComponent(url)}`
    beforeUrl = _url
    this.miniRouter({ url: _url }, routeType);
  }

  /**
   * 小程序登录页面
   */
  miniLogin(routeType= UniRouteType.navigateTo) {
    this.miniRouter({ url: UniPages.LoginPage }, routeType)
  }
  /**
   * 去小程序客服页
   */
  goKefuPage() {
    this.miniRouter({ url: UniPages.KefuPage })
  }

  /**
   * 去小程序缺省页
   */
  goDefaultPage() {
    this.miniRouter({ url: UniPages.DefaultPage })
  }

  /**
   * 处理登录过期
   */
  goLogin() {
    let hi = location.hash.indexOf('&token')
    if (hi === -1) {
      hi = location.hash.indexOf('?token')
    }
    const hash = location.hash.substring(0,hi)
    this.miniRouter({ url: UniPages.LoginPage + '?pageUrl=' + encodeURIComponent(`/pages/webview/index?type=PARAMSLINK&link=${encodeURIComponent(hash)}`) }, UniRouteType.redirectTo)
  }

  /**
   * 处理需要登录的页面
   */
  goPageLogin(url) {
    if (!this.validToken()) {
      this.webviewRouter(url)
    } else {
      this.miniRouter({ url: UniPages.LoginPage + '?pageUrl=' + encodeURIComponent(`/pages/webview/index?type=PARAMSLINK&link=${encodeURIComponent(url)}`) }, UniRouteType.redirectTo)
    }
  }

  /**
   * 与小程序通信
   * @param options
   */
  postMessage(options) {
    uni.postMessage(options)
  }

  /**
   * 判断小程序用户是否登录
   */
  validToken() {
    return router.currentRoute.query.token === commonConfigObj.wx_default_token
  }

  /**
   * 小程序页面返回
   */
  miniPageBack() {
    uni[UniRouteType.navigateBack]()
  }

  /**
   * 小程序会员页
   */
  goMiniVipPage() {
    if (window.isIOS && window.miniPlatform === 'WX') {
      this.miniRouter({ url: UniPages.DefaultPage })
    } else {
      this.miniRouter({ url: UniPages.VipPage })
    }
  }
}
export default new UniUtils();
