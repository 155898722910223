<template>
  <div class="pay">
    <StateBar bgcolor="white" />
    <TitleHeader title="订单支付" :back="showBack"></TitleHeader>

    <AutoView :header="true" :footer="true" class="pay--view">
      <!--非课程商品 start-->
      <div class="l-panel" v-if="info.businessType !== 13">
        <!-- 标题 -->
        <h3>{{ info.title }}</h3>
        <!-- 参数信息 -->
        <div v-if="entity && entity.inputList" class="desc s-radius__xs">
          <p v-for="(field, index) in entity.inputList" :key="index">
            <strong>{{ field.name }}：</strong><span style="color: #999">{{ field.value }}</span>
          </p>
        </div>
        <!-- 定制信息展示 -->
        <div v-if="[10013, 10016, 10017, 10024].includes(info.questionId)" class="desc-grid">
          <van-grid :column-num="3" class="s-radius__s">
            <van-grid-item
              v-for="(text, index) of ['商业配套', '公共配套', '娱乐配套', '住宅办公配套', '多级业态', '精准定位']"
              :key="index"
              :icon="getIcon(index)"
              :text="text"
              class="item"
            />
          </van-grid>
        </div>
        <!-- 扩展参数信息 -->
        <div v-if="entity && entity.otherInputList" class="light s-radius__xs">
          <p v-for="(field, index) in entity.otherInputList" :key="index">{{ field.name }}：{{ field.value }}</p>
          <p v-if="info.questionId === 10013" class="s-info">
            成功订阅后{{ entity.otherInputList[0].value }}内可查看区域配套详细数据，不限次数、不限区域
          </p>
        </div>
        <!-- 商品信息 -->
        <template v-if="packages && !hasPkg">
          <!-- 热门优选套餐 -->
          <div class="pkg-content">
            <div class="big-card" :class="{ isSelect: pkgSelectIndex == 0, isBg: pkgSelectIndex != 0 }" @click="selectPkg(firstPackages, 0)">
              <div class="icon-top" :class="iconTop" >
                <span class="icon-label"><img class="lightning" src="/static/icon/icon_lightning.png" alt="" /> 劲省¥</span>
                <span class="icon-value">{{ getSingleSave(firstPackages) }}</span>
              </div>
              <div class="icon-title">
                <span class="icon-label" v-if="isExpiration && !isPay && info.questionId == '10001'">爆款特惠|首单专享399</span>
                <span class="icon-label" v-else-if="isExpiration && !isPay && info.questionId == '10026'">爆款特惠|首单专享459</span>
                <span class="icon-label" v-else>热门优选套餐</span>
              </div>
              <div class="info-wrap">
                <img class="info-img" src="/static/icon/attention2.png" @click="swRouter(`/payGoods/${firstPackages.stdQuestionId}?order=${id}`)" />
              </div>
              <div class="box-line">
                <span class="line-label1"
                  ><span class="inline-title">{{ firstPackages.stdQuestionName }}</span> · {{ firstPackages.exchangeTimes }}次</span
                >
                <span class="line-label2">套餐</span>
              </div>
              <div class="box-line">
                <span class="line-label3">¥</span>
                <span class="line-label4">{{ firstPackages.price }}</span>
                <span class="line-label6">¥{{ firstPackages.salePrice }}</span>
              </div>
              <div class="box-line">
                <span class="line-label5">单次仅需¥{{ getSinglePrice(firstPackages) }}</span>
              </div>
            </div>
          </div>
          <ul class="scheme-flex">
            <!-- <li class="li-card" :class="{ isFlex: packages.length == 3, isSelect: pkgSelectIndex == -1 }" @click="unselectPkg">
                        <div class="box-line">
                            <span class="line-label1">单次</span>
                        </div>
                        <div class="box-line">
                            <span class="line-label3">¥</span>
                            <span class="line-label4">{{ info.price }}</span>
                        </div>
                        <div class="box-line">
                            <span class="line-label5">单次仅需¥{{ info.price }}</span>
                        </div>
                      </li> -->
            <li
              class="li-card"
              v-for="(item, index) in dealPackages"
              :key="item.key"
              :class="{ isFlex: isIOS && packages.length == 4, isSelect: pkgSelectIndex - 1 == index }"
              @click="selectPkg(item, index + 1)"
            >
              <div class="small-top" v-if="getSingleSave(item)">
                <span class="small-label"><img class="lightning" src="/static/icon/icon_lightning.png" alt="" /> 劲省¥</span>
                <span class="small-value">{{ getSingleSave(item) }}</span>
              </div>
              <div class="info-wrap">
                <img class="info-img" src="/static/icon/attention2.png" @click="swRouter(`/payGoods/${item.stdQuestionId}?order=${id}`)" />
              </div>
              <div class="box-line">
                <span class="line-label1">{{ item.exchangeTimes }}次</span>
                <span class="line-label2">套餐</span>
              </div>
              <div class="box-line">
                <span class="line-label3">¥</span>
                <span class="line-label4">{{ item.price }}</span>
                <span v-if="item.exchangeTimes !== 1" class="line-label6">¥{{ item.salePrice }}</span>
              </div>
              <div class="box-line">
                <span class="line-label5">单次仅需¥{{ getSinglePrice(item) }}</span>
              </div>
            </li>
          </ul>
          <div class="card-more" v-if="isAndroid && packages.length > 3" @click="MoreMealFlas = !MoreMealFlas">
            {{ getMealName(packages.length) }}<span class="arrow" :class="{ isRotate: !MoreMealFlas }"></span>
          </div>
          <div class="card-more" v-if="isIOS && packages.length > 5" @click="MoreMealFlas = !MoreMealFlas">
            {{ getMealName(packages.length) }}<span class="arrow" :class="{ isRotate: !MoreMealFlas }"></span>
          </div>
        </template>
        <template v-else>
          <div class="s-cell" v-if="info.businessType !== 8 && info.businessType !== 14">
            <span class="l">价格</span>
            <template v-if="info.businessType == 10">
              <span class="r f-fw c-orange">{{ `${getPrice(info.price).value}${getPrice(info.price).unit}` }}</span>
            </template>
            <template v-else>
              <span class="r f-fw c-orange">{{ `${getPrice(info.price).value}${getPrice(info.price).unit}` }}<sub>/次</sub></span>
            </template>
          </div>
        </template>
      </div>
      <!--其他商品 end-->
      <!--课程商品 start-->
      <div class="l-panel" v-if="info.businessType == 13">
        <div class="course-pannel">
          <img :src="entity.imageCover" />
          <div class="course-name">{{ info.title }}</div>
        </div>
        <div class="s-cell">
          <span class="l">价格</span>
          <span class="r f-fw c-orange">{{ `${getPrice(info.price).value}${getPrice(info.price).unit}` }}</span>
        </div>
      </div>
      <!--课程商品 end-->
      <!--店铺订阅 支付start-->
      <div class="l-panel subscribe-list" v-if="[8, 10, 14].includes(info.businessType) && subscribeList.length > 0 && !info.orderNo">
        <div
          class="list"
          :class="{ active: activeIndex == index }"
          v-for="(item, index) in subscribeList"
          :key="index"
          @click="selectAcPrice(item, index)"
        >
          <p class="name">{{ item.goodsName }}</p>
          <p class="price"><em>¥</em>{{ item.price }}</p>
          <p class="txt" :class="{ lineStr: info.businessType == 14 }">{{ item.remark }}</p>
        </div>
      </div>
      <template v-if="!isCourse">
        <!-- 优惠信息区域 -->
        <div class="l-panel f-vmt" v-if="isShowCoupon">
          <div class="coupon__detail" :class="{ on: enabledCoupon, disabled: lockCoupon }" @click="showCoupon">
            <div class="coupon__detail_left">
              <span class="s-subtitle">优惠券</span>
              <span class="coupon__name" v-if="getCouponName.name"
              ><em>{{ getCouponName.name }}</em></span
              >
            </div>
            <div class="coupon__w">
              <span v-if="getCouponName.alias" :class="{ on: getCouponName.on, dis: getCouponName.dis }">{{ getCouponName.alias }}</span>
              <template v-else>
                <span class="coupon__num">- {{ getPrice(info.discount).value }}</span>
                <span class="coupon__unit">{{ getPrice(info.discount).unit }}</span>
              </template>
            </div>
          </div>
        </div>
        <!-- 支付方式选择区域 -->
        <div class="l-panel nop f-vmt" style="padding-bottom: 0">
          <h3 class="s-subtitle" v-if="hasPkg || showPayMode">支付方式</h3>
          <!-- 套餐包列表 -->
          <div v-if="hasPkg" class="pay--packages">
            <van-swipe :loop="false" :show-indicators="false" :width="countPkgWidth">
              <van-swipe-item v-for="(item, index) in packages" :key="index">
                <PackageCard
                  mode="user"
                  :item="item"
                  :checked="goods && goods.id === item.userStdQuestionPkgItemId"
                  :showCheck="true"
                  @click.native="setPayType('套餐包', item.userStdQuestionPkgItemId)"
                />
              </van-swipe-item>
            </van-swipe>
          </div>
          <!-- 支付方式列表 -->
          <PayTypes :balance="getBalance" :payList="payList" :payType="payType" @setPayType="setPayType" v-show="showPayMode"/>
        </div>

        <div class="l-panel f-vmt" v-if="needComment">
          <div class="user-comment">
            <span class="comment-left">备注</span>
            <div class="comment-right" @click="showComment = true">
            <span class="comment-label">
              <template v-if="userComment.length == 0"> 未找到意向行业，可补充填写 </template>
              <template v-else>
                {{ userComment }}
              </template>
            </span>
              <img src="/static/icon/arrow_right_black.png" class="comment-icon" />
            </div>
          </div>
        </div>

        <!-- 警示信息区域 -->
        <div class="s-tips f-vpb">
          温馨提示：<br />1、您将解锁的商品为虚拟内容产品，确认支付后不支持退换、转让，请仔细确认。<br />
          2、大数据产品服务是基于一定样本量的算法结果，上上参谋不保证用户在平台获得的任何信息、内容（包括但不限于模型结论等）将是完整和没有错误的。由于数据产品服务的特殊性和时效性，在报告完成交付后，用户不得以前述理由要求平台进行退款。
        </div>
      </template>
    </AutoView>

    <SoltFooter class="pay--footerBar">
      <van-row class="view" v-if="!isCourse">
        <!-- 一元换购 -->
        <div class="change-buy" @click="selectChangeActivity" v-if="entity && entity.exchangeActivity && showExchange && this.getAmount > 0">
          <p>
            <span class="tag">限时</span>
            <span>加9.9元换购价值<em>198元</em>上上参谋会员（1个月）</span>
          </p>
          <span class="checked-item" :class="{ checked: exchangeActivity, disabled: disabledChange }"></span>
        </div>
        <van-col span="12">
          <p class="label">应付：</p>
          <p class="price">{{getAmount}}<sub>{{info.unit}}</sub></p>
        </van-col>
        <van-col span="12">
          <van-button type="warning" block :disabled="disabled" @click="onTapClick">{{ buttonText }}</van-button>
        </van-col>
      </van-row>
      <van-row v-else class="view">
        <van-col span="24">
          <van-button type="warning" block :disabled="disabled" @click="goMember">前往开通会员</van-button>
        </van-col>
      </van-row>
    </SoltFooter>

    <!-- 优惠券选择 -->
    <van-popup v-model="showCouponPicker" position="bottom" round closeable>
      <div class="l-view pay--coupon">
        <h3 class="f-tac">我的优惠券</h3>
        <div class="coupons">
          <CouponCard
            v-for="(item, index) in couponList"
            :key="index"
            :class="{ 'f-vmt': index > 0 }"
            :item="item"
            :checked="info.coupon && info.coupon.voucherId === item.voucherId"
            @selected="setCoupon"
          />
        </div>
        <van-button type="info" block @click="showCouponPicker = false">确定</van-button>
      </div>
    </van-popup>

    <!-- 余额不足提醒 -->
    <van-overlay :show="showPayTips">
      <div class="l-wrapper">
        <div class="pay--tips l-panel">
          <van-image class="icon" fit="cover" :src="tipsIcon" />
          <h3>上上贝余额不足，无法完成支付</h3>
          <p>请先购买上上贝</p>
          <van-button type="info" @click="swRouter({ path: '/wallet', query: { id: id, orderNo: info.orderNo } })">去购买上上贝</van-button>
          <div class="link" @click="closePayTip">{{ isIOS ? "取消" : "其他方式解锁" }}</div>
        </div>
      </div>
    </van-overlay>

    <!-- 支付等待遮罩 -->
    <van-overlay :show="waiting">
      <div class="s-overlay">
        <van-loading color="#518CDB" />
      </div>
    </van-overlay>

    <!-- 客户备注填写遮罩 -->
    <van-popup v-model="showComment" position="bottom">
      <div class="comment-wrap">
        <div class="comment-header">填写备注</div>
        <div class="comment-body">
          <van-field
            v-model="userComment"
            type="textarea"
            placeholder="请补充填写意向行业等需求，方便我们输出更精准的报告噢~"
            input-align="left"
            maxlength="200"
            :show-word-limit="true"
          />
        </div>
        <div class="comment-bottom" @click="handleComment">确认</div>
      </div>
    </van-popup>

    <!-- 全局加载遮罩-->
    <LoadingOverlay :show="loading" />


  </div>
</template>

<script>
// businessType类型 1:选址报告 2:充值 3:会员 4:尊享包 5:工具 6:数据导出 7:套餐包 8:店铺订阅 9:品牌报告 10:数据查询
// 11：查周边客群画像 12:找商机 13:课程 14:品牌加盟套餐 15:定制服务 16:热力图定制
import CouponCard from "@/components/card/coupon";
import PackageCard from "@/components/card/package";
import PayTypes from "@/components/payTypes";
import { toFixed } from "@/utils/filters";

const DELAY_TIME = 2000;

export default {
  name: "pay",
  components: {
    CouponCard,
    PackageCard,
    PayTypes,
  },
  data() {
    return {
      openByIOS: true, // 开放iOS微信和支付宝支付
      openSSB: true, // 是否开启上上贝支付(安卓与IOS都有)
      openApple: false, // 是否开启苹果支付(iOS 10012 店铺订阅)

      isAndroid: false,
      isIOS: false,
      showBack: true,
      loading: true, // 数据加载
      waiting: false, // 等待支付
      disabled: false, // 禁用支付按钮
      lockCoupon: false, // 禁用优惠券
      paySuccess: false, // 购买成功状态
      tipsIcon: "/static/icon/insufficient_balance.png", // 购买提醒图标

      canClick: true, // 支付按钮是否可点击
      showCouponPicker: false, // 展示优惠券列表
      showPayTips: false, // 展示充值提醒窗口
      showComment: false, // 展示用户备注窗口
      userComment: "",

      isPay: false,
      expirationTime: 1640966399000, // 年终活动到期时间 2021-12-31 23:59:59
      isExpiration: true,

      timer: 0, // 轮询定时器
      client: 3, // 1 iOS 2 Android 3 WEB 4 WX
      buyType: null, // 购买方式 v1.6.0
      showPayMode: true, // 是否显示支付方式
      payList: ["上上贝", "支付宝", "微信"],
      payType: "微信", // 默认支付方式
      payMode: "WX_APP",
      payCall: "wxPay",
      payMap: {
        上上贝: {
          mode: "SSCM",
          call: "sscmPay",
          unit: "上上贝",
        },
        支付宝: {
          mode: "ALIPAY_MOBILE",
          call: "aliPay",
          unit: "元",
        },
        微信: {
          mode: "WX_APP",
          call: "wxPay",
          unit: "元",
        },
        苹果: {
          mode: "APPLE_IN",
          call: "applePay",
          unit: "元",
        },
        套餐包: {
          mode: "STD_QUESTION_PKG",
          call: null,
          unit: "元",
        },
        会员特权: {
          // 停用
          mode: "COUPON_MEMBER",
          call: null,
          unit: "元",
        },
        尊享卡: {
          // 废弃
          mode: "COUPON_CARD",
          call: null,
          unit: "元",
        },
      },

      couponList: [], // 优惠券列表
      packageList: [], // 套餐包列表
      subscribeList: [], //店铺订阅商品
      activeIndex: -1,
      id: 0, // 业务ID
      orderNo: 0, // 订单ID 发起创建支付订单后生成
      isSimple: false, // 是否直达购买(新人券活动 已下架)
      hasPkg: false, // 是否有套餐包
      entity: null, // 订单信息
      goods: null, // 购买信息
      info: {
        businessType: null, // 业务类型
        reportId: null, // 报告ID 等同于业务ID
        questionId: null, // 标准问题ID
        orderNo: null, // 订单号 原始订单
        title: "正在加载", // 报告标题
        price: 0.0, // 原价
        discount: 0.0, // 优惠
        balance: 0.0, // 上上贝余额
        unit: "元", // 展示单位
        coupon: null, // 已选优惠券
      },
      pkgSelectIndex: -1, // 选中的套餐包的索引
      hasDrawSSB: false, // 是否因为套餐包的选择而丢弃了ssb（逻辑太多了，我无法在原有代码上修改了，只得新增了一个变量）
      MoreMealFlas: true, // 更多套餐显示标识
      exchangeActivity: false, // 是否换购
    };
  },
  methods: {
    goMember() {
      this.swRouter(`/h5/#/member?source=course_vip&refId=${this.id}&pageId=${this.$route.meta.id}&moduleId=320100&btnId=320101`)
    },
    closePayTip(){
      this.showPayTips = false;
      this.canClick=true;
    },
    getMealName(len) {
      if (this.MoreMealFlas) {
          if(this.isAndroid) {
              return `查看更多套餐(${len - 3}) `
          } else {
              return `查看更多套餐(${len - 5}) `;
          }
      } else {
        return "收起";
      }
    },
    getSinglePrice(item) {
      if (item.exchangeTimes <= 0) {
        return item.price;
      }
      const price = parseFloat(item.price);
      let single = price / item.exchangeTimes;
      return Math.round(single);
    },
    getSingleSave(item) {
      let price = this.info.price;
      if (item.exchangeTimes <= 0) {
        return price;
      }
      const price2 = parseFloat(item.price);
      let single = price * item.exchangeTimes;
      return Math.round(single - price2);
    },
    selectPkg(item, index) {
      if (this.pkgSelectIndex == index) {
        return;
      }
      this.pkgSelectIndex = index;
      if (this.payType == "上上贝") {
        this.payType = "支付宝";
        this.setPayType(this.payType);
      }
      const ssbIndex = this.payList.indexOf("上上贝");
      if (ssbIndex != -1) {
        this.payList.splice(ssbIndex, 1);
        this.hasDrawSSB = true;
      }
      this.setBuyType("套餐包", item.stdQuestionId, item);
      this.getlistVoucher(item.stdQuestionId);
    },
    // 切换tab查询优惠券
    getlistVoucher(stdQuestionId) {
      this.loading = true;
      this.HTTP.getlistVoucher(stdQuestionId)
        .then((res) => {
          console.log("pay res:", res);
          // this.$set(this.entity, 'unconsumeVoucherResList', res.data || []);
          this.couponList = res.data || [];
          // 优惠券信息
          if (this.couponList && this.couponList.length) {
            const coupon = this.couponList[0];
            this.info.coupon = coupon;
            this.info.discount = parseFloat(this.info.coupon.discountAmount);
          } else {
            this.info.coupon = null;
            this.info.discount = 0;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$toast("网络不稳定，正在重试！");
          setTimeout(() => {
            location.reload();
          }, DELAY_TIME);
        });
    },
    // 是否换购
    selectChangeActivity() {
      if (this.$route.query.orderNo) {
        return
      }
      this.exchangeActivity = !this.exchangeActivity
    },
    // unselectPkg () {
    //     this.pkgSelectIndex = -1;
    //     this.couponList = this.entity.unconsumeVoucherResList;
    //     if ( this.couponList && this.couponList.length ) {
    //         const coupon       = this.couponList[ 0 ];
    //         this.info.coupon   = coupon;
    //         this.info.discount = parseFloat( this.info.coupon.discountAmount );
    //     } else {
    //         this.info.coupon   = null;
    //         this.info.discount = 0;
    //     }
    //     if ( this.hasDrawSSB ) {
    //         this.payList.unshift( '上上贝' );
    //         this.hasDrawSSB = false;
    //     }
    //     this.setBuyType();
    // },
    getPkgWidth() {
      if (this.packages == null) {
        return 0;
      }
      const count = this.packages.length + 1;
      if (count == 2) {
        return "32%";
      } else if (count == 3) {
        return "32%";
      } else {
        return "30%";
      }
    },
    getPkgMargin(index) {
      if (this.packages == null) {
        return 0;
      }
      if (index == this.packages.length - 1) {
        return 0;
      }
      const count = this.packages.length + 1;
      if (count == 2) {
        return "2%";
      } else if (count == 3) {
        return "2%";
      } else {
        return "2%";
      }
    },
    onTapClick() {
        if(this.canClick) {
            this.onTap()
            this.canClick = false
        }
    },
    onTap() {
      this.waiting = true;
      // 前置检测
      if (this.buyType) {
        if (!this.paySuccess) {
          this.onPayment();
          return;
        } else {
          this.setPayType(this.buyType, this.goods.id);
        }
      }
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.TAP_PAY);
      console.log("检查订单...");
      // 上上贝支付且已创建订单则直接调起支付
      if (this.info.orderNo && this.payMode === "SSCM") {
        console.log("直接支付...");
        this.orderNo = this.info.orderNo;
        this.callPayment(this.orderNo, null);
        return;
      }
      console.log("创建订单...");
      const pkgId = this.goods ? this.goods.id : null;
      // const businessId = [ 10012, 10013 ].includes( this.info.questionId ) ? this.id : null;
      // 新businessType类型尽量走新接口，需在下面配置
      const businessId = [10, 8, 13, 14, 15, 16].includes(this.info.businessType) ? this.id : null;
      // console.log( 'businessId:', businessId, ', type:', this.info.businessType, ', id:', this.id );
      const goodsSkuId = [8, 10, 14].includes(this.info.businessType) ? this.subscribeList[this.activeIndex].goodsId : null;
      this.HTTP.createOrder(this.info.orderNo, this.payMode, this.id, this.couponId, this.client, 0, pkgId, businessId, this.userComment, goodsSkuId, this.exchangeActivity)
        .then((res) => {
        // console.log( 'createOrder:', res );
          console.log("创建订单...end");
          // 埋点上报 Begin
          // 优惠券
          if (this.couponId) {
            this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_COUPON);
            // 订单生成后会占用已使用的优惠券 - hooks
            // this.couponList = this.couponList.filter(item => {
            //   return item.voucherId !== this.couponId;
            // });
            // this.info.coupon = null;
            // this.info.discount = 0;
          } else {
            this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_NO_COUPON);
          }
          // 支付方式
          if (this.payMode === "ALIPAY_MOBILE") {
            this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_ALI);
          } else if (this.payMode === "WX_APP") {
            this.JSBridge.report(this.JSBridge.REPORT_EVENT.PAY.SUC_WX);
          } else {
            // Other
          }
          // 埋点上报 End
          // 业务流程
          if (res.status === 2) {
            // 抵扣订单
            console.log("完成抵扣...");
            this.nextPage();
          } else {
            // 正常支付
            this.orderNo = res.orderNo;
            console.log("调起支付...");
            this.callPayment(res.orderNo, res.payResult);
          }
        })
        .catch((e) => {
          if (typeof e === "object" && e.hasOwnProperty("code")) {
            if (e.code === 111003) {
              // 优惠过期
              this.$toast("优惠已过期，请重新选择！");
              setTimeout(() => {
                location.reload();
              }, DELAY_TIME);
              return;
            } else if (e.code === 111006) {
              // 重复提交
              this.$toast("请勿重复提交订单，即将前往我的订单，您可以继续完成支付！");
              setTimeout(() => {
                this.swRouter("/h5/#/orders");
              }, DELAY_TIME);
              return;
            } else if (e.code === 111008) {
              // 余额不足
              this.waiting = false;
              this.showPayTips = true;
              return;
            }
          } else {
            console.error("Unknow error: ", e);
            this.$toast("创建订单时发生错误！");
          }
          setTimeout(() => {
            this.waiting = false;
          }, DELAY_TIME);
        });
    },
    onPayment() {
      if (!window.isClient) {
        this.$toast("暂不支持网页版支付");
        setTimeout(() => {
          this.waiting = false;
        }, DELAY_TIME);
        return;
      }
      const handler = (res) => {
        console.log("套餐包: ", res);
        if (res.status === 2) {
          // 抵扣订单
          console.log("完成抵扣...");
          this.HTTP.checkPackage(this.id, res.orderNo)
            .then((res) => {
              if (res) {
                this.goods.id = res.userStdQuestionPkgItemId;
                this.paySuccess = true;
                this.onTap(); // 发起解锁
              }
            })
            .catch(() => {
              this.$toast("网络错误，正在重试！");
              setTimeout(() => {
                location.reload();
              }, DELAY_TIME);
            });
          // this.nextPage();
        } else {
          this.goods.order = res.orderNo;
          this.callPayment(res.orderNo, res.payResult, this.payCall);
        }
      };
      const errorHandler = (e) => {
        console.error(e);
        this.$toast("支付时发生错误，请稍候重试！");
        setTimeout(() => {
          this.waiting = false;
        }, DELAY_TIME);
      };
      if (this.buyType === "套餐包") {
        this.HTTP.createPackage(this.payMode, this.goods.id, this.client, this.couponId, this.info.reportId, this.info.orderNo, this.exchangeActivity).then(handler).catch(errorHandler);
      }
    },
    callPayment(id, data, payCall) {
      console.log(data, "data")
      if (window.isClient) {
        this.JSBridge.callHandler(
          payCall || this.payCall,
          {
            id,
            data,
          },
          (st) => {
            if (!st) {
              this.$toast("未安装客户端，调起支付失败！");
              setTimeout(() => {
                this.waiting = false;
              }, DELAY_TIME);
            }
            console.log("调起支付...end");
          }
        );
      } else {
        this.$toast("暂不支持网页版支付");
        setTimeout(() => {
          this.waiting = false;
        }, DELAY_TIME);
      }
    },
    showCoupon() {
      if (this.lockCoupon || this.info.orderNo || !this.couponList.length) return;
      this.showCouponPicker = true;
    },
    setCoupon(item) {
      if (this.info.coupon === item) {
        this.info.coupon = null;
        this.info.discount = 0;
      } else {
        this.info.coupon = item;
        this.info.discount = parseFloat(item.discountAmount);
      }
    },
    setBuyType(type, id, item) {
      if (this.openSSB && this.payType === "上上贝") {
        // 选择套餐包时自动选中非上上贝支付
        // this.$toast( '暂不支持上上贝兑换套餐包！' );
        const ssbIndex = this.payList.findIndex((payItem) => {
          if (payItem === "上上贝") {
            return true;
          }
          return false;
        });
        if (ssbIndex !== -1) {
          if (ssbIndex + 1 < this.payList.length) {
            this.payType = this.payList[ssbIndex + 1];
          }
        }
      }
      if (!type || (this.goods && this.goods.id === id)) {
        this.goods = null;
        this.buyType = null;

        if (this.isIOS && !this.openByIOS) this.setPayType(this.payList[0]);
        else this.setPayType(this.payType);
      } else {
        const goods = {
          id,
          name: "",
          order: null,
          price: 0,
          entity: item,
        };
        switch (type) {
          case "套餐包":
            goods.name = item.stdQuestionName;
            goods.price = item.price;
            break;
        }
        this.goods = goods;
        this.buyType = type;

        if (this.isIOS && !this.openByIOS) this.setPayType("苹果");
        else this.setPayType(this.payType);
      }
    },
    setPayType(type, id) {
      const map = this.payMap[type];
      this.payType = type;
      this.payMode = map.mode;
      this.payCall = map.call;
      this.info.unit = map.unit;
      if (!this.showExchange) {
        // 状态不是微信支付宝的时，取消勾选换购
        this.exchangeActivity = false
      }
      if (id) {
        if (this.goods) {
          this.goods.id = id;
        } else {
          this.goods = { id };
        }
      } else if ((this.isAndroid || this.openByIOS) && !this.buyType) {
        this.goods = null;
      }
      // 状态处理
      if (type === "上上贝") {
        this.buyType = null;
        this.goods = null;
        this.pkgSelectIndex = -1;
      } else if (type === "苹果" && this.info.questionId === 10012 && this.hasPkg) {
        this.goods = null;
      }
      this.lockCoupon = (this.goods !== null || type === "苹果") && !this.buyType;
    },
    nextPage() {
      // 检测解锁类型
      if (this.info.businessType === 8) {
        // 店铺订阅
        this.swRouter("/success?view=subscribe");
      } else if (this.info.questionId === 10010) {
        // 周边数据导出
        this.swRouter("/success?view=export");
      } else if (this.info.businessType === 13) {
        // 课程
        this.swRouter("/success?view=course");
      } else if (this.info.businessType === 14) {
        // 品牌
        this.swRouter("/success?view=brandJoin");
      } else if (this.info.businessType === 15) {
        // KA定制
        this.swRouter("/success?view=kaCustom");
      } else if (this.info.questionId === 10013) {
        // 查周边-周边分析
        this.swRouter("/success?view=paymentNearby");
      } else if (this.info.questionId === 10014) {
        // 查周边-周边客群
        this.swRouter("/success?view=paymentNearby");
      } else {
        // 其它的报告
        this.swRouter(`/success?view=payment&stdId=${this.info.questionId}&reportId=${this.id}`);
      }
    },
    getIcon(index) {
      return `/static/icon/10013/${index + 1}@64.png`;
    },
    handleComment() {
      this.showComment = false;
    },
    selectAcPrice(item, index) {
      if (this.info.orderNo) {
        return false;
      }
      if (this.activeIndex == index) {
        return;
      }
      this.activeIndex = index;
      this.info.price = item.price;
      this.entity.otherInputList[0].value = item.serverDays + "天";
      this.getlistVoucher(item.goodsId);
    },
    getInitPrice() {
      if (this.subscribeList.length > 0) {
        this.info.price = parseFloat(this.subscribeList[0].price);
        this.entity.otherInputList[0].value = this.subscribeList[0].serverDays + "天";
        // businessType=10主推季度包
        if (this.entity.businessType == 10 && this.subscribeList.length > 1) {
          this.activeIndex = 1;
          this.info.price = parseFloat(this.subscribeList[1].price);
          this.entity.otherInputList[0].value = this.subscribeList[1].serverDays + "天";
        } else {
          this.activeIndex = 0;
        }
        if (this.info.orderNo) {
          const checkIndex = this.subscribeList.findIndex((el) => {
            return el.goodsId == this.info.questionId;
          });
          this.activeIndex = checkIndex;
          if (checkIndex != -1) {
            this.info.price = parseFloat(this.subscribeList[checkIndex].price);
            this.entity.otherInputList[0].value = this.subscribeList[checkIndex].serverDays + "天";
          }
        }
      }
    },
  },
  computed: {
    getPrice() {
      return (num) => {
        return {
          value: toFixed(num),
          unit: this.info.unit,
        };
      };
    },
    iconTop() {
        if(!this.isExpiration || this.isPay) {
            return ''
        }
        if(this.info.questionId == '10026' || this.info.questionId == '10001') {
            return 'icon-top1'
        } else {
            return ''
        }
    },
    showExchange() {
      return ['支付宝', '微信'].includes(this.payType) && !this.loading
    },
    getAmount() {
      let sum = this.info.price - this.info.discount;
      if(this.isIOS) {
          if(this.goods) {
              sum = parseFloat(this.goods.price)
          } else {
              sum = parseFloat(this.info.price)
          }
      } else {
        if (this.isSimple) {
            sum = this.info.price - this.info.discount;
        } else if (this.goods) {
            sum = parseFloat(this.goods.price) - this.info.discount;
            // sum = this.goods.price ? parseFloat( this.goods.price ) : 0;
        } else if (this.payType === "苹果") {
            sum = this.info.price;
        }
        if (sum > 0 && this.exchangeActivity && this.showExchange) {
            sum += 9.9
        }
      }
      sum = sum > 0 ? toFixed(sum) : "0.00";
      return sum;
    },
    isShowCoupon() {
        if(this.isAndroid) {
            return this.info.businessType !== 14
        } else {
            return window._appCode < 301
        }
    },
    getCouponName() {
      const obj = { name: "", alias: "", on: false, dis: false };
      if (this.loading) {
        obj.alias = "未选择";
      } else if (this.lockCoupon) {
        obj.alias = "不可用";
        obj.dis = true;
      } else if (this.info.coupon) {
        obj.name = this.info.coupon.couponName;
      } else if (this.info.orderNo) {
        obj.alias = "未使用";
        obj.dis = true;
      } else if (this.couponList && this.couponList.length) {
        obj.alias = this.couponList.length + "个可用";
        obj.on = true;
      } else {
        obj.alias = "无可用";
        obj.dis = true;
      }
      return obj;
    },
    getBalance() {
      return this.info.balance;
    },
    couponId() {
      return this.info.coupon ? this.info.coupon.voucherId : null;
    },
    enabledCoupon() {
      return this.couponList && this.couponList.length;
    },
    buttonText() {
      if (!this.isSimple && this.entity) {
        if (this.buyType === "套餐包") {
          // return '立即购买';
          return "确认支付";
        } else if (this.payType === "套餐包") {
          return "立即使用";
        }
      }
      return "确认支付";
    },
    packages() {
      return this.packageList && this.packageList.length ? this.packageList : null;
    },
    // 第一个套餐包
    firstPackages() {
      return this.packageList && this.packageList.length ? this.packageList[0] : null;
    },
    // 去除第一个套餐包
    dealPackages() {
      if (this.packageList && this.packageList.length) {
        if (this.MoreMealFlas) {
            if(this.isAndroid) {
                return this.packageList.slice(1,3)
            } else {
                return this.packageList.slice(1, 5);
            }
        } else {
          return this.packageList.slice(1);
        }
      } else {
        return null;
      }
    },
    countPkgWidth() {
      return this.packages && this.packages.length > 1 ? document.body.clientWidth * 0.8 : "auto";
    },
    needComment() {
      //屏蔽版本202及以上版本备注功能
      const needStdIds = [10001, 10005, 10006, 10011, 10015];
      return needStdIds.includes(this.info.questionId) && window._appCode < 202;
    },
    disabledChange() {
      return this.$route.query.orderNo
    },
    // 支付商品是否课程
    isCourse() {
      return this.info.businessType === 13
    }
  },
  watch: {
    entity(res) {
      // 常规信息
      this.couponList = res.unconsumeVoucherResList;
      this.info.orderNo = res.orderNo;
      this.info.title = res.businessName;
      this.info.reportId = res.reportInstanceId;
      this.info.questionId = res.questionId;
      this.info.businessType = res.businessType;
      this.info.price = parseFloat(res.price);
      // 上上贝信息
      if (res.appAccountBalanceRes) {
        if (this.isAndroid) {
          this.info.balance = parseFloat(res.appAccountBalanceRes.androidBalance);
        } else if (this.isIOS) {
          this.info.balance = parseFloat(res.appAccountBalanceRes.iosBalance);
        }
      }
      // 优惠券信息
      if (this.couponList && this.couponList.length) {
        const coupon = this.couponList[0];
        this.info.coupon = coupon;
        this.info.discount = parseFloat(this.info.coupon.discountAmount);
      }
      // 套餐包信息
      this.packageList = res.questionPkgList;
      if (this.packageList instanceof Array) {
        for (const pkg of this.packageList) {
          if (pkg.restExchangeTimes) {
            this.hasPkg = true;
            break;
          }
        }
      }
      // 默认支付逻辑： IOS与Android 支付方式 上上贝、支付宝、微信，默认支付宝
      // 2021-02-24新增逻辑：IOS 171及以上版本 全面去除上上贝
      console.log("pay isIOS:", window.isIOS, ", app version:", window._appCode);
      if (this.isIOS) {
         // 2022-03-18新增逻辑：IOS 171及以上版本 课程只支持苹果原生支付，并且不能显示支付方式，IOS301及以上版本，所有支付方式只指出苹果原生支付，并且不能显示支付方式
        if(window._appCode < 301 || window._appCode >= 310) {
            this.payList = ["支付宝", "微信"];
            this.payType = "支付宝";
            this.showPayMode = true;
            if (res.businessType === 13 && window._appCode >= 171) {
                this.payList = ["苹果"];
                this.payType = "苹果";
                this.showPayMode = false;
            }
        } else {
            this.payList = ["苹果"];
            this.payType = "苹果";
            this.showPayMode = false;
        }
      }
      if (res.businessType == 8) {

        // 获取店铺订阅商品
        this.subscribeList = res.goodsList;
        this.getInitPrice();
      } else if (res.businessType == 14) {
        // 获取店铺订阅商品
        this.HTTP.listAllQuestionOfBrandJoin()
          .then((res) => {
            this.subscribeList = res;
            this.getInitPrice();
          })
          .catch((e) => {
            this.$toast("网络不稳定，正在重试！");
          });
      } else if (res.businessType == 10) {
        this.subscribeList = res.goodsList;
        this.getInitPrice();
      }
      // 设置默认支付方式
      if (this.hasPkg) {
        // 套餐包
        this.$nextTick(() => {
          this.setPayType("套餐包", this.packages[0].userStdQuestionPkgItemId);
        });
      } else {
        // 标准
        if (this.packageList && this.packageList.length > 0) {
          this.$nextTick(() => {
            // 有套餐包信息却还没有买，默认选中套餐包的第一个
            const firstItem = this.packageList[0];
            this.selectPkg(firstItem, 0);
          });
        } else {
          this.$nextTick(() => {
            this.setPayType(this.payType); // 默认支付宝
          });
        }
      }
    },
    activeIndex(nv, ov) {
      // console.log( 'activeIndex:', nv, ', businessType:', this.info.businessType, ', sub length:', this.subscribeList.length );
      if (this.info.businessType !== 14) {
        return;
      }
      if (nv >= this.subscribeList.length) {
        return;
      }
      const targetName = this.subscribeList[nv].goodsName;
      this.info.title = "加盟品牌商入驻" + targetName;
    },
  },
  created() {
    // 设置客户端类型
    if (window.isIOS) {
      this.isIOS = true;
      this.client = 1;
      this.payList = ["苹果"];
      this.payType = "苹果";
      this.showPayMode = false;
    } else if (window.isAndroid) {
      this.isAndroid = true;
      this.client = 2;
    }
    // 获取业务ID
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push("/");
      return;
    }
    // 获取订单ID（选填 来自我的订单或充值返回）
    const query = this.$route.query;
    const orderNo = query.orderNo || null;
    // 查询支付信息
    this.HTTP.checkout(this.id, orderNo, this.$route.meta.id, query)
      .then((res) => {
        console.log("pay res:", res);
        this.entity = res;
        this.loading = false;
        // 从我的订单里面进入不允许切换 换购状态
        if (this.$route.query.orderNo && res.exchangeActivity) {
          this.exchangeActivity = true
          this.payList.splice(0, 1); // 我的订单进入且订单有换购内容，不支持上上贝兑换
        } else if (res.questionId === 10010) {
          this.payList.splice(0, 1); // 我数据导出，不支持上上贝兑换
        }
        if (this.entity.businessType!=13) {
          // 新增回退跳出确认弹窗的接口
          const backMsg = {
            msg: "订单已生成，确认放弃付款？",
            leftButtonText: "暂时放弃",
            rightButtonText: "再想想",
          };
          this.JSBridge.callHandler("backDoubleCheck", JSON.stringify(backMsg));
          // 注册支付回调
          this.JSBridge.registerHandler("paymentResult", (data) => {
            console.log(data.state, "status")
            // 安卓端需要进行转义
            if (typeof data === "string") {
              data = JSON.parse(data);
            }
            if (typeof data === "object") {
              if (this.buyType) {
                // console.log( 'data.state:', data.state );
                // 购买
                switch (data.state) {
                  case 1: {
                    // 支付成功
                    if(this.info.businessType === 15) {
                      this.nextPage();
                    } else {
                      let timer = 0;
                      const checkPolling = () => {
                        const errorHandler = function (e) {
                          clearTimeout(timer);
                          this.$toast("网络错误，正在重试！");
                          setTimeout(() => {
                            location.reload();
                          }, DELAY_TIME);
                        };
                        if (this.buyType === "套餐包") {
                          this.HTTP.checkPackage(this.id, this.goods.order)
                            .then((res) => {
                              if (res) {
                                this.goods.id = res.userStdQuestionPkgItemId;
                                this.paySuccess = true;
                                clearTimeout(timer);
                                this.onTap(); // 发起解锁
                              } else {
                                timer = setTimeout(checkPolling, DELAY_TIME);
                              }
                            })
                            .catch(errorHandler);
                        } else {
                          this.HTTP.orderCheck(this.id, this.payMode)
                            .then((res) => {
                              if (res) {
                                this.paySuccess = true;
                                clearTimeout(timer);
                                this.onTap(); // 发起解锁
                              } else {
                                timer = setTimeout(checkPolling, DELAY_TIME);
                              }
                            })
                            .catch(errorHandler);
                        }
                      };
                      this.$toast("正在完成支付，请稍候...");
                      checkPolling();
                    }
                    break;
                  }
                  case 8: {
                    // 取消
                    this.$toast("已取消支付，您可以在订单界面重新进行支付！");
                    setTimeout(() => {
                      this.canClick = true
                      this.waiting = false;
                      this.swRouter("/h5/#/orders");
                    }, DELAY_TIME);
                    break;
                  }
                  case 5: // 兼容微信
                  case 6: // 余额不足
                  case 7: // 超时
                  default:
                    // 支付失败
                    this.$toast("支付失败，请稍候重试！");
                    setTimeout(() => {
                      this.canClick = true
                      this.waiting = false;
                    }, DELAY_TIME);
                }
                return; // 终止
              } else if (data.id === this.orderNo) {
                // 解锁
                switch (data.state) {
                  case 1: // 支付成功
                    this.nextPage();
                    break;
                  case 5: // 兼容微信
                    setTimeout(() => {
                      this.waiting = false;
                    }, DELAY_TIME);
                    break;
                  case 6: // 余额不足
                    this.waiting = false;
                    this.canClick = true
                    this.showPayTips = true;
                    break;
                  case 7: // 超时
                  case 8: // 取消
                    // 埋点上报
                    this.$toast("已取消支付，您可以在订单界面重新进行支付！");
                    setTimeout(() => {
                      this.waiting = false;
                      this.canClick = true
                      this.swRouter("/h5/#/orders");
                    }, DELAY_TIME);
                    break;
                  default:
                    // 支付失败
                    this.$toast("支付失败，请稍候重试！");
                    this.waiting = false;
                    this.canClick = true
                }
                return; // 终止
              }
            }
            this.$toast("发生错误，请稍候重试！");
            this.waiting = false;
          });
        }
      })
      .catch((e) => {
        this.$toast("网络不稳定，正在重试！");
        this.timer = setTimeout(() => {
          // location.reload();
        }, DELAY_TIME * 2.5);
      });
  },
  beforeMount() {
    this.JSBridge.callHandler("switchNavbar", false);
    this.JSBridge.callHandler("setStatebarTheme", "black");
  },
  mounted() {

    this.HTTP.getIsPayUser().then((res) => {
        this.isPay = res.data;
    });
    if(new Date().getTime() >= this.expirationTime) {
        this.isExpiration = false
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/index.scss";

$iconSize: 19.73vw;

.pay {
  position: relative;
  min-height: 100vh;
  background-color: $c-pageBg2;

  .change-buy {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 4vw;
    left: 0;
    right: 0;
    // bottom: $footerHeight;
    top: -9.33vw;
    height: 9.33vw;
    background: url('/static/img/double-eleven/pica_bg.png') no-repeat;
    font-size: 3.467vw;
    color: #333;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
    z-index: 2;
    > p {
      display: flex;
      align-items: center;
      line-height: 5.8vw;
      em {
        font-style: normal;
        color: #FF5900;
      }
    }
    .tag {
      height: 4.8vw;
      line-height: 5.2vw;
      margin-left: 3.33vw;
      margin-right: 2.13vw;
      font-size: 2.66vw;
      display: inline-block;
      background: #333333;
      padding: 0 1.6vw;
      color: #FFE6C5;
    }
  }

  .checked-item {
      width: 4vw;
      height: 4vw;
      font-size: 0;
      border-radius: 50%;
      background: white url(/static/icon/unselect@2x.png) no-repeat center center;
      background-size: 4vw 4vw;

      &.checked {
          background: url(/static/icon/success.png) no-repeat center center;
          background-size: 3.8vw 3.8vw;
      }
      &.disabled {
        opacity: 0.5;
      }
  }

  #{&}--view {
    padding: $padding_4;

    .s-subtitle {
      margin: 0;
      padding: $padding_3;
    }

    .pkg-content {
      position: relative;
      margin: 4vw 0 3.73vw 0;
      width: 100%;
      .big-card {
        height: 28.8vw;
        padding: 5.33vw 4.27vw;
        background: url("/static/img/vip_card_pack.png") no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        border-radius: 2.13vw;
      }
      .icon-top {
        position: absolute;
        top: -1.33vw;
        right: 22.4vw;
        padding: 0 2.13vw 0 0.8vw;
        border-radius: 0.27vw 2.67vw 0 1.87vw;
        background: #ff5900;
        display: flex;
        align-items: center;
        height: 4.8vw;
        line-height: 4.8vw;
        z-index: 1;
        .icon-label {
          position: relative;
          color: #fff;
          font-size: $font_267;
          font-family: cursive, "Avenir", Helvetica, Arial, sans-serif;
          .lightning {
            width: 2.67vw;
            height: 4.27vw;
            vertical-align: middle;
          }
        }
        .icon-value {
          position: relative;
          color: #fff;
          font-size: 3.2vw;
          font-weight: bold;
        }
      }
      .icon-top1 {
        right: 35.4vw;
      }
      .icon-title {
        position: absolute;
        top: -1.33vw;
        right: 0;
        height: 4.8vw;
        line-height: 4.8vw;
        padding: 0 2.13vw 0 4.27vw;
        border-top-right-radius: 2.67vw;
        background: #333;
        display: flex;
        align-items: center;
        z-index: 0;
        .icon-label {
          position: relative;
          color: #ffce8f;
          font-size: 3.2vw;
        }
      }
      .info-wrap {
        position: absolute;
        top: 4.8vw;
        right: 4.8vw;
        z-index: 1;
        .info-img {
          position: relative;
          width: 3.73vw;
          height: 3.73vw;
        }
      }
    }
    .scheme-flex {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      .li-card {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        position: relative;
        width: 49%;
        padding: 4.8vw 4vw;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        margin-bottom: 2.67vw;
      }
      .isFlex {
        width: 32.8%;
      }
      .small-top {
        position: absolute;
        top: -1.33vw;
        right: -0.27vw;
        padding: 0 2.13vw 0 0.8vw;
        border-radius: 0.27vw 2.67vw 0 1.87vw;
        background: #ff5900;
        display: flex;
        align-items: center;
        z-index: 1;
        height: 4.8vw;
        line-height: 4.8vw;
        .small-label {
          position: relative;
          color: #fff;
          font-size: $font_267;
          .lightning {
            width: 2.67vw;
            height: 4.27vw;
            vertical-align: middle;
          }
        }
        .small-value {
          position: relative;
          color: #fff;
          font-size: 3.2vw;
          font-weight: bold;
        }
      }
      .info-wrap {
        position: absolute;
        top: 4.8vw;
        right: 1.6vw;
        z-index: 1;
        .info-img {
          position: relative;
          width: 3.73vw;
          height: 3.73vw;
        }
      }
    }
    .card-more {
      font-size: 3.2vw;
      color: 666;
      margin-top: 1.33vw;
      text-align: center;
      .arrow {
        display: inline-block;
        width: 1.87vw;
        height: 1.87vw;
        border-top: 1px solid #666;
        border-right: 1px solid #666;
        transform: rotate(135deg);
        margin: 0 0 0.8vw 1.33vw;
      }
      .isRotate {
        transform: rotate(315deg);
        margin: 0.8vw 0 0 1.33vw;
      }
    }
    .isSelect {
      background-color: #fff1e1 !important;
      border: 1px #8d5d22 solid !important;
    }
    .isBg {
      background: url("/static/img/vip_card_pack_gray.png") no-repeat !important;
      background-size: 100% 100% !important;
    }
    .box-line {
      position: relative;
      white-space: nowrap;
      .line-label1 {
        color: #1d2233;
        font-size: 4.53vw;
        font-weight: bold;
        line-height: 6.4vw;
        .inline-title {
          font-weight: 400;
          font-size: 3.47vw;
          color: #333;
        }
      }
      .line-label2 {
        color: #1d2233;
        font-size: 3.47vw;
        line-height: 5.33vw;
      }
      .line-label3 {
        color: #ff5900;
        font-size: $font_253;
        font-weight: bold;
      }
      .line-label4 {
        color: #ff5900;
        font-size: 4.8vw;
        font-weight: bold;
        margin-left: -1.07vw;
      }
      .line-label5 {
        color: #8d5d22;
        font-size: 3.2vw;
        font-weight: bold;
        line-height: 4.27vw;
      }
      .line-label6 {
        font-size: 2.93vw;
        color: #666;
        text-decoration: line-through;
      }
    }

    .s-cell {
      margin-top: $padding_3;
      padding: 1vw 0;

      .c-orange {
        color: $c-notice;
      }
      sub {
        vertical-align: bottom;
        font-size: $font_347;
        // font-size: $font_293;
      }
    }
    .desc,
    .light {
      margin-top: $padding_3;
      padding: $padding_3;
      background-color: $c-pageBg;
      font-size: $font_293;
      line-height: $font_400;

      p:not(:first-child) {
        margin-top: 1vw;
      }
    }

    .light {
      margin-top: 1.5vw;
      background-color: #fff1e1;
      color: #8d5d22;
      font-weight: bold;

      .s-info {
        color: $c-info;
        font-weight: normal;
        font-size: $font_267;
      }
    }

    .desc-grid {
      overflow: hidden;
      margin-top: $padding_3;

      ::v-deep .van-grid-item__content {
        background-color: #f8f8f9;

        &:after {
          border-color: white;
          border-width: 2px;
        }
      }
    }

    .tips-grid {
      position: relative;
      margin-top: 1.33vw;
      background-color: #fff1e1;
      border-radius: 1.33vw;
      padding: 2.67vw 4vw;
      .s-time {
        color: #8d5d22;
        font-size: 2.93vw;
        font-weight: bold;
        line-height: 4vw;
        text-align: left;
      }
      .s-info {
        margin-top: 1.07vw;
        color: $c-info;
        font-weight: normal;
        font-size: $font_267;
      }
    }

    .l-panel {
      position: relative;
      padding: $padding_3;

      &.nop {
        padding: 0;
      }
      h3 {
        font-size: $font_400;
        line-height: $font_560;
      }
      .line {
        margin: $padding1 0 $margin;
      }
      .info {
        color: $c-info;
        font-size: 2.93vw;
      }
      .more {
        position: relative;
        height: 14vw;

        .more--name {
          position: absolute;
          left: 5.33vw;
          top: 3vw;
          @include font($c-text, $font_347, bold);
          line-height: 8vw;
        }
        .more--desc {
          position: absolute;
          left: 5.33vw;
          bottom: 7vw;
          @include font($c-info, $font_293, normal);
        }
        .checked-item {
          position: absolute;
          top: 50%;
          right: 5vw;
          width: 4vw;
          height: 4vw;
          box-shadow: inset 0 0 0 2px $c-info;
          background-color: white;
          transform: translateY(-50%);
          box-sizing: border-box;
          border-radius: 50%;

          &.checked {
            background: url(/static/icon/success.png) no-repeat center center;
            background-size: cover;
            box-shadow: none;
            border: none;
          }
        }
      }
      .user-comment {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comment-left {
          color: $c-text;
          font-size: $font_400;
          font-weight: bold;
          text-align: left;
        }
        .comment-right {
          display: flex;
          align-items: center;
          cursor: pointer;
          .comment-label {
            color: $c-text;
            font-size: $font_320;
            font-weight: normal;
            line-height: $font_400;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 55vw;
          }
        }
        .comment-icon {
          width: 3.2vw;
          height: 3.2vw;
          margin-left: 1vw;
        }
      }
      .course-pannel {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        img {
          width: 47%;
          height: 24.6vw;
          display: block;
          border-radius: 1vw;
        }
        .course-name {
          font-size: 4vw;
          color: #1f2435;
          font-weight: $weight_600;
          // @include limitLine(2);
          margin-left: 3vw;
          line-height: 5.5vw;
          margin-top: 3px;
        }
      }
    }
    .subscribe-list {
      // display: flex;
      // align-items: center;
      // flex-flow: row nowrap;
      position: relative;
      padding-top: 0;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      .list {
        display: inline-block;
        width: 92px;
        overflow: hidden;
        // flex: 1;
        // background: rgba(255, 241, 225, 0.6);
        border-radius: 1.3vw;
        border: 1px solid rgba(142, 144, 153, 0.4);
        padding-top: 4vw;
        padding-bottom: 4.8vw;
        margin-right: 2.1vw;
        // &:nth-child(4n){
        //     margin-right: 0;
        // }
        &.active {
          background: rgba(255, 241, 225, 0.6);
          border: 1px solid #8d5d22;
        }
        p {
          text-align: center;
          &.name {
            font-size: 3.7vw;
            font-weight: 600;
            color: #1d2233;
            line-height: 5.3vw;
            // font-weight: bold;
          }
          &.price {
            font-size: 5.3vw;
            font-weight: 600;
            color: #8d5d22;
            line-height: 5.3vw;
            margin: 1.4vw auto 1.5vw;
            em {
              font-style: normal;
              font-size: 3.5vw;
            }
          }
          &.txt {
            font-size: 2.9vw;
            font-weight: 400;
            color: #676c84;
            line-height: 4vw;
            &.lineStr {
              text-decoration: line-through;
            }
          }
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  #{&}--packages {
    padding-bottom: $padding_3;

    .van-swipe-item {
      padding-left: $padding_3;
      box-sizing: border-box;

      &:last-child {
        padding-right: $padding_3;
      }
    }
  }

  #{&}--tips {
    width: 87.2vw;
    padding: $padding;
    background-color: white;
    text-align: center;

    .icon {
      width: $iconSize;
      height: $iconSize;
      margin-top: 4.3vw;
    }
    h3 {
      font-size: $font_373;
      line-height: $font_533;
      padding-top: 7.47vw;
    }
    p {
      font-size: $font_293;
      color: $c-info;
      padding-top: 0.8vw;
    }
    .van-button--info {
      width: 59.2vw;
      margin-top: 10.93vw;
    }
    .link {
      font-size: $font_400;
      margin-top: 4.8vw;
    }
  }

  #{&}--coupon {
    h3 {
      font-size: $font_480;
      line-height: $font_667;
    }
    .coupons {
      height: 70vw;
      overflow: auto;
      margin: $margin1 0;
    }
  }

  #{&}--coupon-view {
    @include flexColumn;
    padding: 0 $padding * 0.75 0 $padding;
    height: 12vw;
    background-color: #fdfdfd;
    box-shadow: inset 0 -1px 0 0 rgba(#edf1fb, 0.5);
  }
  .coupon__detail {
    width: 100%;
    height: 5.33vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @include font($c-text, $font_320, normal);

    &.on {
      background: url(/static/icon/arrow_right_gray.png) no-repeat right center;
      background-size: 5.33vw auto;
      padding-right: 5.33vw;
    }
    &.disabled {
      padding-right: 0;
      background: none;

      .coupon__name {
        display: none;
      }
      .s-subtitle,
      .coupon__num,
      .coupon__unit {
        color: #cccccc;
      }
    }
    .s-subtitle {
      padding: 0;
    }
    .coupon__detail_left {
      display: flex;
      align-items: center;
    }
    .coupon__name {
      @include flexColumn;
      height: 4vw;
      max-width: 32vw;
      margin-left: 2.2vw;
      padding: 0 1vw;
      line-height: normal;
      font-size: 2.93vw;
      border-radius: 2px;
      color: $c-notice;
      box-shadow: 0 0 0 1px $c-notice;
      box-sizing: border-box;
      em {
        display: block;
        width: 100%;
        font-style: normal;
        @include singleLine;
      }
    }
    .coupon__w {
      text-align: right;
      display: flex;
      align-items: center;
      .on {
        color: $c-main;
      }
      .dis {
        color: #cccccc;
      }
    }
    .coupon__num {
      font-size: 3.4vw;
      font-weight: 600;
      color: $c-notice;
    }
    .coupon__unit {
      font-size: 3vw;
      color: $c-notice;
    }
  }

  #{&}--footerBar {
    .view {
      box-sizing: border-box;
      padding: $headerMargin $padding;
      width: 100%;
      height: $footerHeight;
      ::v-deep .van-col {
        height: 100%;
      }
    }
    .label {
      color: $c-info;
      font-size: $font_267;
      left: $font_373;
      padding-top: 0.5vw;
    }
    .price {
      font-size: $font_480;
      line-height: $font_653;
      font-weight: bold;
      color: $c-notice;
    }
    ::v-deep sub {
      font-size: $font_320;
      vertical-align: bottom;
    }
    ::v-deep .van-button {
      height: 100%;
      font-size: $font_400;
      line-height: normal;
      background: linear-gradient(to right, #ff8000, #ff5900);
    }
  }

  .s-tips {
    margin-top: 5.87vw;
    font-size: $font_320;
    color: #bdbdbd;
    &.s-tips_inline {
      margin-top: 0;
      padding: 0 $padding_3 $padding_3;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: max-height 0.5s;
    max-height: 100vh;
  }
  .slide-enter,
  .slide-leave-to {
    max-height: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.25s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .bottom-wrap {
    bottom: 0;
  }
  .comment-wrap {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    padding: 4vw 3vw;
    box-sizing: border-box;
    .comment-header {
      position: relative;
      color: $c-text;
      font-size: $font_400;
      font-weight: $weight_600;
      line-height: $font_467;
      text-align: center;
    }
    .comment-body {
      position: relative;
      width: 100%;
      margin-top: 4vw;
      // border-radius: 1.3vw;
      .van-cell {
        background-color: #f8f8f9;
        border-radius: 1.3vw;
      }
    }
    .comment-bottom {
      position: relative;
      border-radius: 1.3vw;
      padding: 3.2vw 0;
      background-color: #518cdb;
      margin-top: 4vw;
      color: white;
      font-size: $font_373;
      font-weight: $weight_600;
      line-height: $font_440;
      text-align: center;
    }
  }
}

::v-deep .van-dialog__content {
  background: url(/static/img/coupon_rule.jpg) no-repeat center top;
  background-size: contain;
  padding: $padding;

  h1 {
    font-size: 6.4vw;
    text-align: center;
    padding: 8vw 0;
  }
  .content {
    margin-top: $padding;
    margin-bottom: $padding;

    p {
      color: $c-info;
      font-size: $font_347;
      line-height: $font_587;
      margin-top: $margin;
      text-align: center;
    }
  }
}
</style>
