<template>
  <div class="search-filter-box">
    <div class="search-filter-container">
      <div class="search-filter-item" v-for="(item,index) in filterOptions" @click="selectFilterTab(index)">
        <span class="filter-name" :class="{'filter-active-name': handleActive(index)}">{{ item.name }}</span>
        <img v-if="tabIndex===index" src="/static/cellScreen/selected.png" />
        <img v-else-if="handleActive(index)" src="/static/cellScreen/selected2.png" />
        <img v-else src="/static/cellScreen/un-selected.png" />
      </div>
    </div>
    <div class="filter-warp" v-if="tabIndex > -1" :style="{height: contentHeight + 'px'}" @click="closeWarp">
      <div @click.stop>
        <!--    条件筛选区域-->
        <template v-if="tabIndex !== 3">
          <div class="filter-box">
            <!--        区域-->
            <div class="city-filter-box" v-if="tabIndex == 0">
              <div class="city-filter-header">
                <div class="city-name">{{ cityObj.cityName }}</div>
                <div class="city-btn" @click="selectCity">修改城市</div>
              </div>
              <div class="city-filter-container">
                <div class="region-filter-box" ref="regionRef">
                  <div class="filter-box-item" :class="{'filter-box-active-item': cityObj.curRegionIndex === index}" v-for="(region,index) in cityFilterData" @click="selectRegion(index)">{{ region.name }}</div>
                </div>
                <div class="area-filter-box" ref="areaRef">
<!--                  <div class="filter-box-item" :class="{'filter-box-active-item': cityObj.curAreaIndex === 0}" @click="selectArea(0)">-->
<!--                    不限-->
<!--                    <img v-show="cityObj.curAreaIndex === 0" class="selected-icon" src="/static/cellScreen/gougou.png" alt="" />-->
<!--                  </div>-->
                  <div class="filter-box-item" :class="{'filter-box-active-item': cityObj.curAreaIndex === index}" v-for="(area, index) in areaList" @click="selectArea(index)">
                    {{ area.name }}
                    <img v-show="cityObj.curAreaIndex === index" class="selected-icon" src="/static/cellScreen/gougou.png" alt="" />
                  </div>
                </div>

              </div>
            </div>
            <!--        户数-->
            <div class="filter-box-container" v-if="tabIndex==1">
              <div class="filter-box-item" :class="{'filter-box-active-item': item.value == form2['communityPeopleNum']}"
                   v-for="item in households" @click="selectFilter('communityPeopleNum', item.value)">{{ item.name }}</div>
            </div>
            <!--        房价-->
            <div class="filter-price-box" v-if="tabIndex==2">
              <div class="" v-for="(item, index) in housePrice">
                <div class="filter-name">{{ item.title }}</div>
                <div class="filter-des" v-if="!(form.regionCode || form.circleCode)">不可选择，仅选择行政区或片区后生效</div>
                <div class="filter-box-container" :class="{'filter-box-container2': index === 1}">
                  <div class="filter-box-item" :class="{
                    'filter-box-item2': index ===1 && i > 0,
                    'filter-box-active-item': (index === 0 && option.value == form2[item.type]) ||
                    (index===1 && ( (form.regionCode || form.circleCode) && option.value == form2[item.type] )),
                    'filter-box-item-disable': index===1 && !(form.regionCode || form.circleCode)
                  }"
                       v-for="(option, i) in item.selectOptionList" @click="selectFilter(item.type, option.value)"
                  >{{ option.name }}</div>
                </div>
              </div>
            </div>
            <!--        排序-->
            <div class="filter-sort-box" v-if="tabIndex==4">
              <div class="filter-name">排序</div>
              <div class="filter-box-container filter-box-container3">
                <div class="filter-box-item filter-box-item3" v-for="item in curSortList"
                     @click="selectFilter('sortKey', item.value)"
                     :class="{'filter-box-active-item': item.value == form2['sortKey']}"
                >
                  {{ item.name }}
                  <img v-if="item.value == form2['sortKey']" class="sort-icon" src="/static/cellScreen/sort-active.png" />
                  <img v-else class="sort-icon" src="/static/cellScreen/sort.png" />
                </div>
              </div>
            </div>
            <div class="filter-other">
              <div class="commit-btn" @click="confirmSelect">确定</div>
            </div>
          </div>
        </template>
        <!--    偏好-->
        <template v-if="tabIndex===3">
          <div class="filter-preference-box" :style="{height: contentHeight - 69 + 'px'}">
            <div class="filter-preference-header">筛选<span>500米</span>范围内满足品牌/业态/配套偏好的小区</div>
            <div class="filter-preference-container">
              <template v-if="!isMini">
                <div class="filter-preference-container-item" v-for="pItem in preferenceFilterList">
                  <div class="preference-item-name">{{ pItem.name }}</div>
                  <template v-if="pItem.type=='brand'">
                    <div class="special-filter-container">
                      <div class="filter-tab">
                        <span class="filter-tab-item">偏好</span>
                        <span class="filter-tab-item2" @click="selectBrand(pItem, 'preference')">选品牌<img src="/static/icon/list_icon_go.png" alt="" /></span>
                      </div>
                      <div class="brand-selected-box" v-if="pItem.preference && pItem.preference.length">
                        <div class="brand-selected-box-item" v-for="(cItem, i) in pItem.preference">
                          <span>{{ cItem.name }}</span>
                          <img src="/static/cellScreen/close.png" alt="" @click="deleteBrandItem(pItem,i, 'preference')" />
                        </div>
                      </div>
                      <div class="filter-tab">
                        <span class="filter-tab-item">避开</span>
                        <span class="filter-tab-item2" @click="selectBrand(pItem, 'avoid')">选品牌<img src="/static/icon/list_icon_go.png" alt="" /></span>
                      </div>
                      <div class="brand-selected-box" v-if="pItem.avoid && pItem.avoid.length">
                        <div class="brand-selected-box-item" v-for="(cItem, i) in pItem.avoid">
                          <span>{{ cItem.name }}</span>
                          <img src="/static/cellScreen/close.png" alt="" @click="deleteBrandItem(pItem,i, 'avoid')" />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="pItem.type=='format'">
                    <div class="special-filter-container">
                      <div class="filter-tab">
                        <span class="filter-tab-item">偏好</span>
                        <span class="filter-tab-item2" @click="selectFormat(pItem, 'preference')">请选择偏好行业<img src="/static/icon/list_icon_go.png" alt="" /></span>
                      </div>
                      <div class="brand-selected-box" v-if="pItem.preference && pItem.preference.length">
                        <div class="brand-selected-box-item" v-for="(cItem, i) in pItem.preference">
                          <span>{{ cItem.name }}</span>
                          <img src="/static/cellScreen/close.png" alt="" @click="deleteFormatItem(pItem,i, 'preference')" />
                        </div>
                      </div>
                      <div class="filter-tab">
                        <span class="filter-tab-item">避开</span>
                        <span class="filter-tab-item2" @click="selectFormat(pItem, 'avoid')">请选择避开行业<img src="/static/icon/list_icon_go.png" alt="" /></span>
                      </div>
                      <div class="brand-selected-box" v-if="pItem.avoid && pItem.avoid.length">
                        <div class="brand-selected-box-item" v-for="(cItem, i) in pItem.avoid">
                          <span>{{ cItem.name }}</span>
                          <img src="/static/cellScreen/close.png" alt="" @click="deleteFormatItem(pItem,i, 'avoid')" />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
              <div class="filter-preference-container-item" v-for="pItem in preferenceItems2">
                <div class="preference-item-name">{{ pItem.name }}</div>
                <template>
                  <div class="filter-value-tab">
                    <span class="filter-value-tab-item" :class="{'filter-tab-active-item': value.selected}"
                          v-for="value in pItem.selectList" @click="selectCategory(value)">{{ value.name }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="btn-group">
            <div class="reset-btn" @click="resetOption">重置</div>
            <div class="commit-btn" @click="submit2">确定</div>
          </div>
        </template>
      </div>
    </div>
    <van-popup v-model="cityVisible" round position="bottom" :style="{ height: '60%' }">
      <van-area
        title="选择城市"
        :area-list="cityList"
        :columns-num="2"
        @confirm="handleCityCheck"
        @cancel="cityVisible=false"
      />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
const defaultCity = {
  cityName: '深圳市',
  cityCode: '440300'
}
export const defaultConfig = {
    cityCode: '', // 城市code
    regionCode: null, // 行政区code
    circleCode: null, // 片区编码
    communityPeopleNum: 'house', // 户数
    price: 'price', // 房价
    avoidBrands: [], // 避免品牌
    preferenceBrands: [], // 偏好品牌
    avoidCategorys: [], // 避免业态
    preferenceCategorys: [], // 偏好业态
    sortKey: 'sort', // 排序字段key
    houseSpecial: 'house_special',
    searchType: 0, // 搜索类型 0 消耗次数 1 不消耗次数
}
const cityParam = {
  curRegionIndex: 0,
  curAreaIndex: 0,
  cityName: '', // 城市名称
  cityCode: '', // 城市code
  regionCode: null, // 当前所选行政区code
}
import LoadMap from '@/components/baseMap/loadMap';
export default {
  name: 'searchFilterBox',
  props: {
    contentHeight: {
      default: 0,
      type: Number
    },
    isVip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    areaList() {
      return this.cityFilterData[this.cityObj.curRegionIndex].children
    },
    curSortList() {
      let list = []
      if (!this.form.preferenceCategorys.length && !this.form.avoidCategorys.length) {
        list = this.sortList.slice(0, this.sortList.length - 2)
      } else {
        list = this.sortList
      }
      return list
    }
  },
  watch: {
    isVip(nv) {
      if (!nv) {
        this.tabIndex = -1
      }
    }
  },
  data() {
    return {
      filterOptions: [
        { name: '区域', btnId: 3060301 },
        { name: '户数', btnId: 3060302  },
        { name: '房价', btnId: 3060303  },
        { name: '偏好', btnId: 3060304  },
        { name: '排序', btnId: 3060305  },
      ],
      households: [],
      sortList: [],
      preferenceFilterList: [
        { name: '品牌', preference: [], avoid: [], type: 'brand' },
        { name: '业态', preference: [], avoid: [], type: 'format' },
      ],
      preferenceFilterList2: [
        { name: '品牌', preference: [], avoid: [], type: 'brand' },
        { name: '业态', preference: [], avoid: [], type: 'format' },
      ],
      preferenceItems: [],
      preferenceItems2: [],
      cityFilterData: [],
      tabIndex: -1,
      housePrice: [],
      form: {...defaultConfig},
      form2: {...defaultConfig},
      // isVip: false,
      cityObj: { ...cityParam },
      cityObj2: { ...cityParam },
      cityVisible: false,
      cityList: areaList,
      isMini: false
    }
  },
  async mounted () {
    this.isMini = window.isMini
    console.log('this.isMini',this.isMini)
    // 判断用户是否有权益
    // const res = await this.HTTP.checkBenefit()
    // console.log('vip', res)
    // this.isVip = res
    // this.$emit('setVip', this.isVip)
    this.getPosition()
    this.queryCondition()
  },
  methods: {
    handleActive(index) {
      if (index===0) {
        return this.form.circleCode || this.form.regionCode
      }
      if (index === 1) {
        return this.form.communityPeopleNum !== 'house'
      }
      if (index === 2) {
        return this.form.price !== 'price' || this.form.houseSpecial !== 'house_special'
      }
      if (index === 3) {
        return this.form.avoidBrands.length || this.form.preferenceBrands.length || this.form.avoidCategorys.length || this.form.preferenceCategorys.length
      }
      if (index === 4) {
        return this.form.sortKey !== 'sort'
      }
      return false
    },
    /**
     * 根据经纬度，调用高德地图获取所属城市,并保存至store
     */
    getCurrentCity ( lnglat, callbackFn ) {
      let geocoder = new AMap.Geocoder( { city: '全国' } );
      geocoder.getAddress( lnglat, ( status, result ) => {
        if ( status === 'complete' && result.info === 'OK' ) {
          // result为对应的地理位置详细信息
          console.log(result.regeocode)
          const { addressComponent:{ province, city, adcode } } = result.regeocode;
          const currentAddr = { province, city: city || province, adcode };
          callbackFn && callbackFn( currentAddr );
        } else {
          console.log( '地址解析失败，重新选择' );
          callbackFn && callbackFn( { city: defaultCity.cityName, adcode: defaultCity.cityCode } );
        }
      } );
      geocoder = null;
    },
    // 获取当前位置
    getPosition() {
      if (window.isClient) {
        this.JSBridge.callHandler( 'getLocation', null, ( data ) => {
          if (data == '00') {
            // 默认城市
            this.handleGetLocation()
            return
          }
          if ( typeof data === 'string' ) data = JSON.parse( data );
          console.log('position', data)
          if ( data ) {
            // 处理拿不到当前位置
            if (data.city && data.adCode) {
              defaultCity.cityCode = data.adCode.substr( 0, 4 ) + '00';
              defaultCity.cityName =data.city;
              this.handleGetLocation()
            } else if (data.latitude && data.longitude) {
              LoadMap().then(() => {
                this.getCurrentCity([data.longitude, data.latitude], (res) => {
                  // console.log('res', res)
                  const { city, adcode } = res
                  defaultCity.cityCode = adcode.substr( 0, 4 ) + '00';
                  defaultCity.cityName = city;
                  this.handleGetLocation()
                })
              })
            } else {
              defaultCity.cityCode = '440300'
              defaultCity.cityName = '深圳市'
              this.handleGetLocation()
            }
          } else {
            // 默认城市
            this.handleGetLocation()
          }
        } );
      } else {
        // 默认城市
        if (window.mn) {
          defaultCity.cityName = window.mn
        }
        if (window.mc) {
          defaultCity.cityCode = window.mc
        }
        this.handleGetLocation()
      }
    },
    handleGetLocation() {
      this.cityObj.cityCode = defaultCity.cityCode
      this.cityObj.cityName = defaultCity.cityName
      this.form2.cityCode = this.cityObj.cityCode
      this.queryRegionByCity()
      this.submit('first')
    },
    // 选中商业等业态
    selectCategory(value) {
      this.$set(value, 'selected', !value.selected)
    },
    // 选中条件
    selectFilter(type, val) {
      // 房价区间与房价特点互斥
      if (this.tabIndex === 2) {
        if (type === 'price') {
          this.form2['houseSpecial'] = 'house_special'
        } else if (type === 'houseSpecial') {
          if (!(this.form.regionCode || this.form.circleCode)) {
            return
          }
          this.form2['price'] = 'price'
        }
      }
      if (this.tabIndex == 4 && val == this.form2[type]) {
        this.form2[type] = 'sort'
      } else {
        this.form2[type] = val
      }
      // console.log('form2', this.form2)
    },
    // 根据城市获取行政区及片区
    queryRegionByCity() {
      this.HTTP.queryRegionByCity(this.cityObj.cityName).then(res => {
        console.log(res)
        let cityFilterData = []
        if (res.children && res.children.length) {
          let area = []
          res.children.forEach(el => {
            if (el.children && el.children.length) {
              area = area.concat(el.children)
            }
          })
          cityFilterData = res.children
          // cityFilterData.unshift({
          //   name: '不限',
          //   children: area,
          //   code: null
          // })
        }
        this.cityFilterData = cityFilterData

      })
    },
    // 获取查询条件
    queryCondition() {
      this.HTTP.queryCondition().then(res => {
        console.log(res)
        const { houseHolds, housePrice, preferenceItems, sort } = res
        this.households = houseHolds.selectOptionList || []
        this.sortList = sort.selectOptionList.slice(1)
        housePrice.forEach(e => {
          if (e.title === '房价区间') {
            e.type = 'price'
          }
          if (e.title === '房价特点') {
            e.type = 'houseSpecial'
          }
        })
        this.housePrice = housePrice || []
        this.preferenceItems = JSON.parse(JSON.stringify(preferenceItems)) || []
        this.preferenceItems2 = JSON.parse(JSON.stringify(preferenceItems)) || []
      })
    },
    handleCityCheck (data) {
      this.cityObj.cityName = data[1].name
      this.cityObj.cityCode = data[1].code
      this.form2.cityCode = this.cityObj.cityCode
      this.form2.regionCode = null
      this.form2.circleCode = null
      this.cityObj.regionCode = null
      this.cityObj.curRegionIndex = 0
      this.cityObj.curAreaIndex = 0
      this.$refs.regionRef.scrollTop = 0
      this.queryRegionByCity()
      this.cityVisible = false
    },
    selectCity() {
      if (window.isMini) {
        this.cityVisible = true
      } else {
        this.JSBridge.callHandler( 'selectCity', null, ( res ) => {
          if ( res == null ) {
            this.cityObj.cityName = defaultCity.cityName
            this.cityObj.cityCode = defaultCity.cityCode
          } else {
            let cityObj = res;
            if ( typeof cityObj === 'string' ) {
              cityObj = JSON.parse( cityObj );
            }
            this.cityObj.cityName = cityObj.name
            this.cityObj.cityCode = cityObj.code
          }
          this.form2.cityCode = this.cityObj.cityCode
          this.form2.regionCode = null
          this.form2.circleCode = null
          this.cityObj.regionCode = null
          this.cityObj.curRegionIndex = 0
          this.cityObj.curAreaIndex = 0
          this.$refs.regionRef.scrollTop = 0
          this.queryRegionByCity()
        } );
      }
    },
    selectArea(index) {
      this.cityObj.curAreaIndex = index
      if (index > 0) {
        this.form2.circleCode = this.areaList[index].code || null
      } else {
        this.form2.circleCode = null
      }
    },
    selectRegion(index) {
      this.cityObj.curRegionIndex = index
      this.$refs.areaRef.scrollTop = 0
      this.selectArea(0)
    },
    closeWarp() {
      this.tabIndex = -1
    },
    // 重置数据
    resetOption() {
      this.preferenceItems2.forEach(el => {
        el.selectList.forEach(e => e.selected = false)
      })
      this.preferenceFilterList.forEach(el => {
        el.preference = []
        el.avoid = []
      })
      // this.preferenceItems = JSON.parse(JSON.stringify(this.preferenceItems2))
      this.form2.preferenceCategorys = []
      this.form2.avoidCategorys = []
      this.form2.avoidBrands = []
      this.form2.preferenceBrands = []
      // this.submit()
    },
    confirmSelect() {
      if (this.tabIndex == 0) {
        this.cityObj.regionCode = this.cityFilterData[this.cityObj.curRegionIndex].code || null
        this.form2.regionCode = this.cityObj.regionCode

        if (!(this.form2.regionCode || this.form2.circleCode)) {
          this.form2.houseSpecial = 'house_special'
        }
      }
      this.submit()
    },
    // 偏好确认
    submit2() {
      this.preferenceItems = JSON.parse(JSON.stringify(this.preferenceItems2))
      let arr = []
      this.preferenceItems.forEach(el => {
        const res = el.selectList.filter(e => e.selected)
        res.forEach(item => {
          arr = arr.concat(item.code)
        })
      })

      // 偏好
      this.preferenceFilterList2 = JSON.parse(JSON.stringify(this.preferenceFilterList))
      this.form2.preferenceBrands = this.preferenceFilterList2[0].preference.map(el => el.code)
      this.form2.avoidBrands = this.preferenceFilterList2[0].avoid.map(el => el.code)

      this.form2.avoidCategorys = this.preferenceFilterList2[1].avoid.map(el => el.code)
      const arr2 = this.preferenceFilterList2[1].preference.map(el => el.code)

      this.form2.preferenceCategorys = arr.concat(arr2)

      // 如果取消所有业态，则重置已选择的业态排序
      if (!this.form2.avoidCategorys.length && !this.form2.preferenceCategorys.length && (this.form2.sortKey === 'sort_6' || this.form2.sortKey === 'sort_5')) {
        this.form2.sortKey = 'sort'
      }

      this.submit()
    },
    submit(type) {
      this.tabIndex = -1
      this.form = JSON.parse(JSON.stringify(this.form2))
      this.cityObj2 = {...this.cityObj}
      this.$emit('submit', this.form, type)
    },
    deleteBrandItem(item,i, type) {
      item[type].splice(i, 1)
    },
    // 选择品牌
    selectBrand(item, type) {
      const param = JSON.stringify({
        title: `选择品牌${type === 'preference' ? '偏好' : '避开'}`,
        "maxSize": 5,
        "type": type === 'preference' ? 1 : 2,
        prefer: this.preferenceFilterList[0].preference || [],
        avoid: this.preferenceFilterList[0].avoid || []
      })
      console.log('param', param)
      this.JSBridge.callHandler('selectBrand', param, (res) => {
        console.log('选择品牌', res)
        let data = null
        if (typeof res === 'string') {
          const _data = JSON.parse(res)
          if (typeof _data === 'string') {
            data = JSON.parse(_data)
          } else {
            data = JSON.parse(res)
          }
        }
        item[type] = JSON.parse(JSON.stringify(data)) || []
      } )
    },
    deleteFormatItem(item,i, type) {
      item[type].splice(i, 1)
    },
    // 选择业态
    selectFormat(item, type) {
      // item[type].push('数位')
      const param = JSON.stringify({
        title: `选择业态${type === 'preference' ? '偏好' : '避开'}`,
        "maxSize": 5,
        "type": type === 'preference' ? 1 : 2,
        prefer: this.preferenceFilterList[1].preference || [],
        avoid: this.preferenceFilterList[1].avoid || [],
        pageType: 2
      })
      console.log('param', param)
      this.JSBridge.callHandler('selectIndustry', param, (res) => {
        console.log('选择业态', res)
        let data = null
        if (typeof res === 'string') {
          data = JSON.parse(res)
        }
        item[type] = JSON.parse(JSON.stringify(data)) || []
      } )
    },
    selectFilterTab(index) {
      if (!this.isVip) {
        this.$emit('lockVip')
        return
      }
      if (this.tabIndex == index) {
        this.tabIndex = -1
      } else {
        this.JSBridge.BTNCLICK(this.$route.meta.id, null, 3060300, this.filterOptions[index].btnId)
        this.tabIndex = index
      }
      // 根据已选择的条件，重置参数
      this.form2 = JSON.parse(JSON.stringify(this.form))
      this.preferenceItems2 = JSON.parse(JSON.stringify(this.preferenceItems))
      this.preferenceFilterList = JSON.parse(JSON.stringify(this.preferenceFilterList2))

      const regionIndex = this.cityFilterData.findIndex(el => el.code == this.cityObj.regionCode)
      this.cityObj.curRegionIndex = regionIndex > -1 ? regionIndex : 0
      const curAreaIndex = this.areaList.findIndex(el => el.code == this.form2.circleCode)
      this.cityObj.curAreaIndex = curAreaIndex > -1 ? curAreaIndex : 0

      if (this.cityObj2.cityCode !== this.cityObj.cityCode) {
        this.cityObj = { ...this.cityObj2 }
        this.queryRegionByCity()
      } else {
        this.cityObj = { ...this.cityObj2 }
      }


      // console.log('form2', this.form2)
      // console.log('defaultConfig', defaultConfig)
    },
    resetForm() {
      this.form2 = { ...defaultConfig }
      this.form2.cityCode = this.cityObj.cityCode
      this.form = JSON.parse(JSON.stringify(this.form2))

      this.cityObj.curRegionIndex = 0
      this.cityObj.curAreaIndex = 0
      this.cityObj.regionCode = null
      this.cityObj2.curRegionIndex = 0
      this.cityObj2.curAreaIndex = 0
      this.cityObj2.regionCode = null

      this.preferenceItems2.forEach(el => {
        el.selectList.forEach(e => e.selected = false)
      })
      this.preferenceFilterList.forEach(el => {
        el.preference = []
        el.avoid = []
      })
      this.tabIndex = -1
      this.$emit('resetSearch', this.form)
    }
  }
};
</script>

<style lang="scss" scoped>
  .search-filter-box {
    padding: 0 4vw;
    margin-top: 1.333vw;
    position: relative;
    .btn-group {
      position: fixed;
      width: 100%;
      bottom: 0;
      border-top: 1px solid #E7E7E7;
      height: 70px;
      padding: 0 4vw;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: #fff;
      .reset-btn {
        width: 25.33vw;
        text-align: center;
        line-height: 40px;
        border-radius: 1.333vw;
        border: 2px solid #347FFF;
        font-weight: 500;
        color: #347FFF;
        font-size: 3.733vw;
        margin-right: 2.67vw;
      }
      .commit-btn {
        flex: 1;
        text-align: center;
        line-height: 40px;
        border-radius: 1.333vw;
        background: #347FFF;
        font-weight: 500;
        color: #fff;
        font-size: 3.733vw;
      }
    }
    .filter-warp {
      position: absolute;
      width: 100%;
      //top: 0;
      left: 0;
      z-index: 1;
      background: rgba(0,0,0,0.5);
    }
    .filter-preference-box {
      //position: absolute;
      //width: 100%;
      //z-index: 1;
      //left: 0;
      background: #fff;
      padding: 4vw;
      box-sizing: border-box;
      height: 100%;
      overflow-y: scroll;
      .filter-tab {
        font-weight: 400;
        color: #333333;
        font-size: 3.47vw;
        margin-top: 3.2vw;
        border-radius: 1.067vw;
        .filter-tab-item {
          //display: table-cell;
          width: 18vw;
          height: 8.533vw;
          text-align: center;
          border: 1px solid #999999;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(1) {
            border-radius: 1.067vw 0 0 1.067vw;
          }
          &:nth-child(2) {
            border-radius: 0 1.067vw 1.067vw 0;
          }
          &.filter-tab-active-item {
            color: #347FFF;
            background: #F1F3FF;
            border: 1px solid #347FFF;
          }
        }
      }
      .filter-preference-container {
        .filter-preference-container-item {
          .filter-value-tab {
            display: flex;
            flex-wrap: wrap;
            .filter-value-tab-item {
              margin-top: 2.67vw;
              width: 18vw;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 8.533vw;
              text-align: center;
              background: #F8F8F8;
              margin-right: 3.2vw;
              font-weight: 400;
              color: #333333;
              font-size: 3.47vw;
              border-radius: 1.067vw;
              box-sizing: border-box;
              &.filter-tab-active-item {
                color: #347FFF;
                background: #F1F3FF;
                border: 1px solid #347FFF;
              }
            }
          }
          .preference-item-name {
            font-size: 4.267vw;
            font-weight: bold;
            color: #333333;
            margin-top: 7.47vw;
          }
        }
      }
      .filter-preference-header {
        font-weight: 400;
        color: #333333;
        font-size: 4.8vw;
        line-height: 7.2vw;
        span {
          color: #347FFF;
          font-weight: bold;
        }
      }
      .special-filter-container {
        .brand-selected-box {
          margin-top: 2.1333vw;
          border: 1px dashed #347FFF;
          padding: 2.4vw 2.67vw;
          display: flex;
          flex-wrap: wrap;
          .brand-selected-box-item {
            background: #DBE8FF;
            border-radius: 1.067vw;
            width: calc(100% / 4 - 7.5px);
            height: 7.2vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            color: #333333;
            font-size: 3.2vw;
            padding: 1.333vw 2vw;
            box-sizing: border-box;
            margin-right: 10px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(n + 5) {
              margin-top: 10px;
            }
            span {
              flex: 1;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            img {
              width: 2.4vw;
              height: 2.4vw;
              margin-left: 1.3333vw;
            }
          }
        }
        .filter-tab {
          display: flex;
          //font-weight: bold;
          .filter-tab-item {
            border-radius: 1.067vw;
            border: 0;
            background: #F8F8F8;
          }
          .filter-tab-item2 {
            width: 42.67vw;
            height: 8.533vw;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2vw;
            box-sizing: border-box;
            margin-left: 3.2vw;
            border-radius: 1.067vw;
            background: #F1F3FF;
            border: 1px solid #347FFF;
            font-weight: bold;
            color: #347FFF;
            font-size: 3.47vw;
            img {
              width: 4vw;
            }
          }
        }

      }
    }
    .filter-box {
      background: #fff;
      .city-filter-box {
        .city-filter-container {
          display: flex;
          height: 70.67vw;
          border-top: 1px solid #E7E7E7;
          .region-filter-box {
            width: 32vw;
            overflow-y: scroll;
            background: #F0F0F0;
            .filter-box-item {
              &.filter-box-active-item {
                background: #F8F8F8;
              }
            }
          }
          .filter-box-item {
            height: 11.2vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 400;
            color: #333333;
            font-size: 3.733vw;
            padding: 0 4vw 0 6.267vw;
            box-sizing: border-box;
            &.filter-box-active-item {
              font-weight: bold;
              color: #347FFF;
              background: #F1F3FF;
            }
            .selected-icon {
              width: 3.2vw;
              height: 2.267vw;
            }
          }
          .area-filter-box {
            flex: 1;
            overflow-y: scroll;
            background: #F8F8F8;
            .filter-box-item {
              &.filter-box-active-item {
                background: #fff;
              }
            }
          }
        }
        .city-filter-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #F0F0F0;
          height: 13.33vw;
          padding: 0 4vw 0 7.067vw;
          .city-name {
            font-weight: bold;
            color: #333333;
            font-size: 4vw;
          }
          .city-btn {
            font-weight: bold;
            color: #347FFF;
            font-size: 3.2vw;
            width: 19.733vw;
            text-align: center;
            line-height: 7.47vw;
            border-radius: 3.733vw;
            border: .267vw solid #347FFF;
          }
        }
      }
      .filter-name {
        font-size: 4.267vw;
        font-weight: bold;
        color: #333333;
        line-height: 6.4vw;
        padding-left: 4vw;
      }
      .filter-des {
        font-weight: 400;
        color: #808080;
        font-size: 3.2vw;
        line-height: 4.8vw;
        margin-top: 1.333vw;
        padding-left: 4vw;
      }
      .commit-btn {
        text-align: center;
        line-height: 10.67vw;
        background: #347FFF;
        border-radius: 1.333vw;
        margin-top: 2.67vw;
        font-weight: bold;
        color: #FFFFFF;
        font-size: 3.73vw;
      }
      .filter-other {
        padding: 4vw;
        .tip-box {
          font-weight: 400;
          color: #999999;
          font-size: 2.4vw;
          display: flex;
          align-items: center;
          img {
            width: 3.733vw;
            height: 3.733vw;
            margin-right: 1.333vw;
          }
        }
      }

      .filter-box-container {
        display: flex;
        flex-wrap: wrap;
        padding: 4vw;

        &.filter-box-container2 {
          justify-content: space-between;
          .filter-box-item {
            margin-right: 0;
          }
        }

        &.filter-box-container3 {
          justify-content: space-between;
          .filter-box-item3 {
            width: 44.67vw;
            &:nth-child(odd) {
              .sort-icon {
                transform: rotate(180deg);
              }
            }
            .sort-icon {
              width: 2.4vw;
              height: 2.4vw;
              margin-left: 1.067vw;
            }
          }
          .filter-box-item {
            margin-right: 0;
          }
        }



        .filter-box-item {
          min-width: 21.07vw;
          height: 9.333vw;
          text-align: center;
          background: #F7F7F7;
          border-radius: 1.067vw;
          font-weight: 400;
          color: #333333;
          font-size: 3.47vw;
          margin-right: 2.5vw;
          margin-bottom: 2.5vw;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &.filter-box-active-item {
            border: .267vw solid #347FFF;
            color: #347FFF;
            font-weight: bold;
            background: #F1F3FF;
          }
          &.filter-box-item2 {
            width: 32vw;
          }
          &.filter-box-item-disable {
            color: #808080;
          }
        }
      }

    }
    .search-filter-container {
      height: 12vw;
      display: flex;
      align-items: center;
      .search-filter-item {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        color: #1D2233;
        font-size: 3.47vw;
        .filter-active-name {
          color: #347FFF;
        }
        img {
          width: 2.4vw;
          height: 2.4vw;
          margin-left: 1.333vw;
        }
      }
    }
  }
</style>
