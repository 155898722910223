<template>
    <div class="wrapper">
        <StateBar bgcolor="white" />
        <!-- <AutoView :header="false"> -->
            <img class="reportImg" src="/static/img/report_img.png" alt="">
            <div class="positionWrapper">
                <div class="positionTitle">
                    <div class="left">输入位置</div>
                </div>
                <div class="positionContent">
                    <div class="left">
                        <div class="icon"></div>
                        <div class="city" @click="clickCityPicker">{{ city }}</div>
                        <div class="moreIcon"></div>
                    </div>
                    <div class="right">
                        <van-field style="background-color: #f6f7f9;" v-model="address" type="text" @input="getAddress" placeholder="请输入您要评估的位置"/>
                    </div>
                </div>
                 <div class="positionTitle">
                    <div class="left">确定分析范围</div>
                    <div class="right">建议分析范围为1公里，您可按需选择</div>
                </div>
                <div class="distanceLimit">
                    <van-slider v-model="distanceLimit" bar-height="6px" @change="sliderMove" step="33" block-size="16" activeColor="#4544FC" max="99" style="margin: 2vw 0" >
                        <template #button>
                            <img class="custom-button" src="/static/icon/slider_control.png" alt="">
                        </template>
                    </van-slider>
                    <div class="scale">
                        <span @click="distanceClick(0)">0.5km</span>
                        <span @click="distanceClick(33)">1km</span>
                        <span @click="distanceClick(66)">2km</span>
                        <span @click="distanceClick(99)">3km</span>
                    </div>
                </div>
                <div class="industryPicker">
                    <div class="positionTitle">
                        <div class="left">意向行业</div>
                        <div class="right">根据意向行业深入分析数据</div>
                    </div>
                    <div class="currentIndustry" @click="selectIndustry">
                        <div class="industryName">{{industryName}}</div>
                        <div class="pickerIcon"></div>
                    </div>
                </div>
                <van-field class="telField" v-model="tel" maxlength="11" type="tel" @input="checkTel" placeholder="输入号码接收数据报告短信（已加密）"/>
                <div v-if="errMsg" class="errMsg">{{errMsg}}</div>
            </div>
            <div class="submitBtn">
                <button class="subBtn" :class="{ 'btn-disabled': disabled}" @click="submit">
                立即提交获取报告
                </button>
                <van-checkbox class="checkBox" v-model="checked">
                    <div style="color: #999999">
                        我已阅读并同意<span class="tip" @click="clickPrivacyPolicy">《隐私政策》</span><span class="tip" @click="clickUserAgreement">《服务条例》</span>且授权，上上参谋通过电话和短信对我的开店选址提供服务
                    </div> 
                </van-checkbox>
                <img v-if="!disabled" class="submitIcon" src="/static/icon/free_icon.png" alt="">
            </div>
            <div class="imgWrapper" >
                <img class="reportImg" v-for="item in imgList" :key="item.url" :src="item.url" alt="">
            </div>
            <van-popup v-model="showPicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    :default-index="0"
                    @cancel="showPicker = false"
                    @confirm="onConfirm"
                />
            </van-popup>
            <van-popup v-model="showCityPicker" round position="bottom">
                <van-area 
                    title="" 
                    :area-list="areaList" 
                    :columns-num="2"
                    value="110101" 
                    @cancel="showCityPicker = false" 
                    @confirm="onCityConfirm" />
            </van-popup>
        <!-- </AutoView> -->
    </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import LoadMap from '@/components/baseMap/loadMap'
import { isPhoneNo } from '@/utils/index'
// import tubatuUpload from '@/request/http'
export default {
    data() {
        return {
            title: '上上参谋，生意人的数据军师',
            loading: false,
            city: "",
            cityCode: "",
            address: "",
            distanceLimit: 33,
            showPicker: false,
            showCityPicker: false,
            errMsg: "",
            tel: "",
            latitude: "",
            longitude: "",
            checked: false,
            industryName: "餐饮",
            columns: ["餐饮", "零售", "教育", "休闲娱乐", "医疗健康", "丽人", "运动健身"],
            areaList: [],
            imgList: [
                {url: "/static/img/report_img_A.png"},
                {url: "/static/img/report_img_B.png"}
            ],
            radius: 1000
        }
    },
    computed: {
        disabled: function() {
            return this.errMsg || this.tel === '' || this.address == '' || this.city == '' || !this.checked
        }
    },
    mounted() {
        this.areaList = areaList;
        LoadMap().then(() => {
            this.getLocation()
        })
        this.HTTP.getMessageUpload({
            deviceType: 3,
            refId: "",
            platform: "sscm",
            version: "2.1.7",
            track: JSON.stringify([{
                pageId: "10166",
                timestamp: new Date().getTime()
            }])
        }).then(res => {
            console.log(res)
        }).catch(e => {
            console.log(e)
        })
    },
    methods: {
        clickUserAgreement(e) {
            e.stopPropagation()
            location.href = '/static/page/SSCM/useragreement.html'
        },
        clickPrivacyPolicy(e) {
            e.stopPropagation()
            location.href = '/static/page/SSCM/privacypolicy.html'
        },
        getLocation() {
            let that = this;
             AMap.plugin('AMap.CitySearch', function () {
                var citySearch = new AMap.CitySearch();
                citySearch.getLocalCity(function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        // 查询成功，result即为当前所在城市信息
                        that.city = result.city;
                        that.cityCode = result.adcode
                    }
                })
            })
            AMap.plugin('AMap.Geolocation', function() {
                var geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 10000,
                })

                geolocation.getCurrentPosition()
                AMap.event.addListener(geolocation, 'complete', onComplete)
                AMap.event.addListener(geolocation, 'error', onError)

                function onComplete (data) {
                    // data是具体的定位信息
                    that.address = data.formattedAddress;
                    that.longitude = data.position.lng;
                    that.latitude = data.position.lat;
                }

                function onError (data) {
                    // 定位出错
                    this.$toast(`定位出错: ${data.message}`)
                }
            })
        },
        clickCityPicker() {
            this.showCityPicker = true;
        },
        onCityConfirm(e) {
            this.showCityPicker = false;
            this.city = e[1].name;
            this.cityCode = e[1].code;
        },
        getAddress(value) {
            this.address = value;
        },
        getRidius(value) {
            switch(value) {
                case 0 :
                    this.radius = 500
                    break
                case 33:
                    this.radius = 1000
                    break
                case 66:
                    this.radius = 2000
                    break
                default:
                    this.radius = 3000
            }   
        },
        distanceClick(value) {
            this.distanceLimit = value;
            this.getRidius(this.distanceLimit)
        },
        sliderMove(e) {
            this.getRidius(e)
        },
        onConfirm(value) {
            this.industryName = value;
            this.showPicker = false;
        },
        selectIndustry() {
            this.showPicker = true;
        },
        checkTel(value) {
            this.tel = value;
            if (!isPhoneNo(value)) {
                this.errMsg = "请填写正确的手机号码";
            } else {
                this.errMsg = "";
            }
        },
        submit() {
            if(this.disabled) {
                if(this.address == '') {
                    this.$toast('请输入详细地址');
                    return
                }
                if(this.tel === '') {
                    this.$toast('请输入手机号');
                    return
                }
                if(this.errMsg) {
                    this.$toast('请输入正确的手机号');
                    return
                }
                if(!this.checked) {
                    this.$toast('请勾选下方协议');
                    return
                }
            } else {
                const params = {
                    range: this.radius,
                    phone: this.tel,
                    city: this.city,
                    cityCode: this.cityCode,
                    location: this.address,
                    categoryName: this.industryName,
                    latitude: this.latitude,
                    longitude: this.longitude
                }
                console.log(params);
                this.HTTP.tubatuUpload(params).then(res => {
                    console.log(res)
                })
                this.$router.push('/channel/smsJumpPage')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    height: 100vh;
    overflow-x: hidden;
    background-color: #fff;
}
.reportImg {
    width: 100vw;
    display: block;
}
.positionWrapper {
    padding: 4vw;
}
.positionTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vw;
    .left {
      height: 5.6vw;
      font-size: 4vw;
      font-weight: bold;
      color: #333333;
      line-height: 5.6vw;
    }
    .right {
      height: 4.4vw;
      font-size: 3.2vw;
      color: #999999;
      line-height: 4.4vw;
    }
  }
.positionContent {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10.667vw;
    background: #f7f6f9;
    border-radius: 1.333vw;
    border: 0.133vw solid #ebeaee;
    padding: 2vw;
    margin-bottom: 4vw;
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.467vw;
      padding-right: 2.8vw;
      border-right: 0.267vw solid #dadada;
      .icon {
        width: 4.533vw;
        height: 4.533vw;
        background-image: url("/static/icon/location_icon.png");
        background-size: 100% 100%;
        margin-right: 1.067vw;
      }
      .city {
        font-size: 3.467vw;
        color: #666666;
        margin-right: 2.133vw;
      }
      .moreIcon {
        width: 2.267vw;
        height: 1.733vw;
        background-image: url("/static/icon/choice_icon.png");
        background-size: 100% 100%;
      }
    }
    .right {
      flex: 1;
      font-size: 3.467vw;
      font-weight: bold;
      color: #333333;
      padding-left: 2.8vw;
    }
  }
  .distanceLimit {
    margin-bottom: 4vw;
    padding: 0 2vw;
    .scale {
        margin-bottom: 4vw;
    }
    span {
      font-size: 3.5vw;
      color: #333333;
      &:nth-child(1) {
        margin-right: 15vw;
      }
      &:nth-child(2) {
        margin-right: 19vw;
      }
      &:nth-child(3) {
        margin-right: 17vw;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .custom-button {
        width: 7vw;
        height: 7vw;
    }
  }
  .industryPicker {
      margin-bottom: 4vw;
  }
  .currentIndustry {
    height: 10.667vw;
    background: #f7f6f9;
    border-radius: 1.333vw;
    border: 0.133vw solid #ebeaee;
    padding: 0 2vw;    
    display: flex;
    justify-content: space-between;
    align-items: center;
    .industryName {
      font-size: 3.467vw;
      color: #999999;
    }
    .pickerIcon {
      width: 2.4vw;
      height: 1.733vw;
      background-image: url("/static/icon/input_icon-choice.png");
      background-size: 100% 100%;
    }
  }
  .telField {
      height: 10.66vw;
      background: #f7f6f9;
      border-radius: 1.333vw;
      border: 0.266vw solid #ebeaee;
  }
  .errMsg {
      color: red;
      font-size: 2.5vw;
      margin-left: 4vw;
      margin-top: 4vw;
  }
  
  .submitBtn {
    height: 21.867vw;
    padding: 4vw;
    position: relative;
    background: #ffffff;
    .subBtn {
        border: none;
      &.btn-disabled {
        background: #999;
      }
      width: 92vw;
      height: 10.667vw;
      background: #3040B0;
      box-shadow: 0px -2px 6px 0px rgba(34, 34, 34, 0.12);
      border-radius: 1.333vw;
      font-size: 4vw;
      font-weight: bold;
      color: #ffffff;
      line-height: 10.667vw;
      text-shadow: 0px -2px 6px rgba(34, 34, 34, 0.12);
    }
    .submitIcon {
        position: absolute;
        width: 12.267vw;
        right: 4vw;
        top: 0;
    }
    .checkBox {
        font-size: 3.2vw;
        margin-top: 2vw;
        .tip {
            color: #347FFF;
        }
    }
  }
</style>