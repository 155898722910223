<template>
    <div>
      <img src="/static/img/default_cover.jpg" />
      <a :href="linkUrl">点击跳转app</a>
    </div>
</template>

<script>
export default {
  name: 'openApp',
  data () {
      return {
        linkUrl: ''
      }
  },
  created () {
    this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
    const id = this.$route.query.id;
    const type = this.$route.query.type;
    const url = this.$route.query.url;
    let openUrl = '';
    if ( window.isIOS ) {
      openUrl = 'https://webclient.sscanmou.com/SSCM/type=' + type + '&url=' + encodeURIComponent(url);
    } else if ( window.isAndroid ) {
      openUrl = 'androidsscm://link/params?type=' + type + '&url=' + encodeURIComponent(url);
    } else {
      alert('暂不支持浏览器打开');
    }
    if (openUrl !== '') {
      if (id != null && id !== undefined && id !== '') {
        openUrl += '&id=' + id;
      }
      this.linkUrl = openUrl;
      window.location.href = openUrl;
    }
  }
}
</script>

<style scoped>
img {
  width: 100vw;
  position: absolute;
  z-index: -10;
}
  span {
    z-index: 100;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 98%;
    color: dodgerblue;
  }
</style>
