<template>
    <div class="page">
        <div class="banner">
            <img src="static/img/channel/img-main-banner.png">
        </div>
        <div class="rules-icon" @click="showRuleDialog">
            <img src="static/img/channel/icon-rules.png">
        </div>
        <section class="welfare-one">
            <!-- 上上参谋-->
            <div class="module">
                <img class="title-img" src="static/img/channel/img-head-kdxz.png" @click="toView('sscm')">
                <div class="content">
                    <div class="title-img-wrap">
                        <img src="/static/img/channel/img-sscm-title.png">
                    </div>
                    <ul>
                        <li><img src="static/img/channel/sscm-item-1.png"></li>
                        <li><img src="static/img/channel/sscm-item-2.png"></li>
                        <li><img src="static/img/channel/sscm-item-3.png"></li>
                        <li><img src="static/img/channel/sscm-item-4.png"></li>
                    </ul>
                    <div class="btn-wrap" @click="toBranch(urls['sscm'][getEnv()], {m:'Module_wdfs_10000',b:'Button_wdfs_10001'})">
                        <img src="static/img/channel/img-btn.png">
                    </div>
                </div>
            </div>
            <!--数位观察-->
            <div class="module">
                <img class="title-img" v-lazy="'static/img/channel/img-head-xxsj.png'" @click="toView('swgc')">
                <div class="content">
                    <div class="title-img-wrap">
                        <img v-lazy="'/static/img/channel/img-swgc-title.png'">
                    </div>
                    <ul>
                        <li><img v-lazy="'static/img/channel/img-item-1.png'"></li>
                        <li><img v-lazy="'static/img/channel/img-item-2.png'"></li>
                        <li><img v-lazy="'static/img/channel/img-item-3.png'"></li>
                        <li><img v-lazy="'static/img/channel/img-item-4.png'"></li>
                    </ul>
                    <div class="btn-wrap" @click="toBranch(urls['swgc'][getEnv()], {m:'Module_wdfs_10002',b:'Button_wdfs_10002'})">
                        <img v-lazy="'static/img/channel/img-btn.png'">
                    </div>
                </div>
            </div>
            <!--数位拓店云-->
            <div class="module">
                <img class="title-img" v-lazy="'static/img/channel/img-head-szh.png'" @click="toView('tdy')">
                <div class="content">
                    <div class="title-img-wrap">
                        <img v-lazy="'/static/img/channel/img-tdy-title.png'">
                    </div>
                    <ul>
                        <li><img v-lazy="'static/img/channel/tdy-item-1.png'"></li>
                        <li><img v-lazy="'static/img/channel/tdy-item-2.png'"></li>
                        <li><img v-lazy="'static/img/channel/tdy-item-3.png'"></li>
                        <li><img v-lazy="'static/img/channel/tdy-item-4.png'"></li>
                    </ul>
                    <div class="btn-wrap" @click="toBranch(urls['swtdy'][getEnv()], {m:'Module_wdfs_10003',b:'Button_wdfs_10003'})">
                        <img v-lazy="'static/img/channel/img-btn.png'">
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="welfare-two">
            <img v-lazy="'/static/img/channel/img-welfare-two.png'">
            <img v-lazy="'/static/img/channel/img-welfare-three.png'">
        </section> -->

        <section class="apps">
          <div class="app">
            <div class="full-img-wrap">
              <img v-lazy="'/static/img/channel/stlm.png'">
            </div>
          </div>
            <!--上上参谋-->
            <div class="app" id="sscm">
                <div class="img-wrap">
                    <img v-lazy="'/static/img/channel/img-sscm-app.png'">
                </div>
                <p class="sub-title">300万商家在用的开店选址工具</p>
                <div class="video-wrap">
                    <div class="mse" id="video1"></div>
                    <!--<video-->
                    <!--    class="art-video"-->
                    <!--    preload="metadata"-->
                    <!--    crossorigin="anonymous"-->
                    <!--    poster="/static/img/channel/img-video-face.png'"-->
                    <!--    controls-->
                    <!--    v-lazy="'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_sscm.mp4"></video>-->
                </div>
                <ul class="nav">
                    <li class="w-27" :class="taba == 'tab_1' ? 'active' : ''" @click="tabChange('a', 1),track({m:'Module_wdfs_10007',b:'Button_wdfs_10007'}, 'btn')">开店位置推荐</li>
                    <li class="w-27" :class="taba == 'tab_2' ? 'active' : ''" @click="tabChange('a', 2),track({m:'Module_wdfs_10007',b:'Button_wdfs_10008'}, 'btn')">开店选址评估</li>
                    <li class="w-27" :class="taba == 'tab_3' ? 'active' : ''" @click="tabChange('a', 3),track({m:'Module_wdfs_10007',b:'Button_wdfs_10009'}, 'btn')">外卖选址评估</li>
                    <li class="w-18" :class="taba == 'tab_4' ? 'active' : ''" @click="tabChange('a', 4),track({m:'Module_wdfs_10007',b:'Button_wdfs_100010'}, 'btn')">数据查询</li>
                </ul>
                <div class="content">
                    <div class="tab-item" v-show="taba == 'tab_1' ">
                        <img v-lazy="'/static/img/channel/branch/img-kdtj.png'"/>
                    </div>
                    <div class="tab-item" v-show="taba == 'tab_2' ">
                        <img v-lazy="'/static/img/channel/branch/img-kdxzpg.png'"/>
                    </div>
                    <div class="tab-item" v-show="taba == 'tab_3' ">
                        <img v-lazy="'/static/img/channel/branch/img-wmxzpg.png'"/>
                    </div>
                    <div class="tab-item" v-show="taba == 'tab_4' ">
                        <img v-lazy="'/static/img/channel/branch/img-sjcx.png'"/>
                    </div>
                </div>
                <div class="btn-wrap" @click="toBranch(urls['sscm'][getEnv()], {m:'Module_wdfs_10007',b:'Button_wdfs_10011'})">
                    <img v-lazy="'/static/img/channel/img-sscm-app-btn.png'">
                </div>
            </div>
            <!--数位观察-->
            <div class="app" id="swgc">
                <div class="img-wrap">
                    <img v-lazy="'/static/img/channel/img-swgc-app.png'">
                </div>
                <p class="sub-title">肯德基、万科、复旦大学都在用的数据查询平台</p>
                <div class="video-wrap">
                    <div class="mse" id="video2"></div>
                    <!--<video-->
                    <!--    class="art-video"-->
                    <!--    preload="metadata"-->
                    <!--    crossorigin="anonymous"-->
                    <!--    poster="/static/img/channel/img-video-face.png'"-->
                    <!--    controls-->
                    <!--    v-lazy="'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_swgc.mp4"></video>-->
                </div>
                <ul class="nav nav-5">
                    <li :class="tabb == 'tab_1' ? 'active' : ''" @click="tabChange('b', 1),track({m:'Module_wdfs_10008',b:'Button_wdfs_10012'}, 'btn')">查城市</li>
                    <li :class="tabb == 'tab_2' ? 'active' : ''" @click="tabChange('b', 2),track({m:'Module_wdfs_10008',b:'Button_wdfs_10013'}, 'btn')">查区域</li>
                    <li :class="tabb == 'tab_3' ? 'active' : ''" @click="tabChange('b', 3),track({m:'Module_wdfs_10008',b:'Button_wdfs_10014'}, 'btn')">查商场</li>
                    <li :class="tabb == 'tab_4' ? 'active' : ''" @click="tabChange('b', 4),track({m:'Module_wdfs_10008',b:'Button_wdfs_10015'}, 'btn')">查品牌</li>
                    <li :class="tabb == 'tab_5' ? 'active' : ''" @click="tabChange('b', 5),track({m:'Module_wdfs_10008',b:'Button_wdfs_10016'}, 'btn')">查客群</li>
                </ul>
                <div class="content">
                    <div class="tab-item" v-show="tabb == 'tab_1' ">
                        <img v-lazy="'/static/img/channel/shuweiguancha/1.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabb == 'tab_2' ">
                        <img v-lazy="'/static/img/channel/shuweiguancha/2.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabb == 'tab_3' ">
                        <img v-lazy="'/static/img/channel/shuweiguancha/3.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabb == 'tab_4' ">
                        <img v-lazy="'/static/img/channel/shuweiguancha/4.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabb == 'tab_5' ">
                        <img v-lazy="'/static/img/channel/shuweiguancha/5.png'"/>
                    </div>
                </div>
                <div class="btn-wrap" @click="toBranch(urls['swgc'][getEnv()], {m:'Module_wdfs_10008',b:'Button_wdfs_10017'})">
                    <img v-lazy="'/static/img/channel/img-swgc-app-btn.png'">
                </div>
            </div>
            <!--拓店云-->
            <div class="app" id="tdy">
                <div class="img-wrap">
                    <img v-lazy="'/static/img/channel/img-swtdy-app.png'">
                </div>
                <p class="sub-title">盒马、夸父炸串、卤有有、良品铺子等连锁品牌在用</p>
                <div class="video-wrap">
                    <div class="mse" id="video3"></div>
                </div>
                <ul class="nav  nav-5">
                    <li :class="tabc == 'tab_1' ? 'active' : ''" @click="tabChange('c', 1),track({m:'Module_wdfs_10009',b:'Button_wdfs_100018'}, 'btn')">智能网规</li>
                    <li :class="tabc == 'tab_2' ? 'active' : ''" @click="tabChange('c', 2),track({m:'Module_wdfs_10009',b:'Button_wdfs_100019'}, 'btn')">战略地图</li>
                    <li :class="tabc == 'tab_3' ? 'active' : ''" @click="tabChange('c', 3),track({m:'Module_wdfs_10009',b:'Button_wdfs_100020'}, 'btn')">位置推荐</li>
                    <li :class="tabc == 'tab_4' ? 'active' : ''" @click="tabChange('c', 4),track({m:'Module_wdfs_10009',b:'Button_wdfs_100021'}, 'btn')">铺位评估</li>
                    <li :class="tabc == 'tab_5' ? 'active' : ''" @click="tabChange('c', 5),track({m:'Module_wdfs_10009',b:'Button_wdfs_100022'}, 'btn')">勘店APP</li>
                </ul>
                <div class="content">
                    <div class="tab-item" v-show="tabc == 'tab_1' ">
                        <img v-lazy="'/static/img/channel/tuodianyun/2.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabc == 'tab_2' ">
                        <img v-lazy="'/static/img/channel/tuodianyun/1.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabc == 'tab_3' ">
                        <img v-lazy="'/static/img/channel/tuodianyun/5.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabc == 'tab_4' ">
                        <img v-lazy="'/static/img/channel/tuodianyun/3.png'"/>
                    </div>
                    <div class="tab-item" v-show="tabc == 'tab_5' ">
                        <img v-lazy="'/static/img/channel/tuodianyun/4.png'"/>
                    </div>
                </div>
                <div class="btn-wrap" @click="toBranch(urls['swtdy'][getEnv()], {m:'Module_wdfs_10009',b:'Button_wdfs_100123'})">
                    <img v-lazy="'/static/img/channel/img-tdy-app-btn.png'">
                </div>
            </div>
        </section>
        <div class="dialog-wrap" v-if="dialogVisible">
            <div class="dialog-content">
                <van-icon class="close-icon" name="cross" @click="closeDialog"/>
                <p class="title">活动规则</p>
                <div class="rules">
                    1、活动时间：2022年4月24日10:00至2022年6月15日23点59分59秒。<br />
                    2、会员试用权益领取后，账号注册成功即开始生效，到期则自动失效，请领取后尽快试用体验相关会员服务，过期未使用不予补发。试用期结束后用户可选择购买正式VIP会员，如购买时尚在试用期内，则正式VIP会员生效时间自试用期结束之日起。<br />
                    3、本活动链接中VIP会员试用服务仅适用于上上参谋/数位拓店云/数位观察未注册新用户及上上参谋已注册用户。<br />
                    4、会员试用权益每个手机号仅限领取1次，同手机号、终端设备相同等与用户身份相关的信息存在相同、相似或非真实有效等情况的，均可能被认定为同用户，按照营销活动规则中的同一用户处理。<br />
                    5、成为试用期会员后，可享受的会员权益以网站或APP内公布的信息为准。为更好的向会员提供服务，上上参谋/数位观察/数位拓店云有权基于自身业务发展需要调整全部或部分会员权益。前述权益调整将在相应服务页面进行通知或公告。<br />
                    6、如出现不可抗力或情势变更的情况（包括但不限于重大灾害事件、活动受政府机关指令停办或调整，活动遭受严重网络攻击或因系统故障需要暂停举办的），活动方可依据相关法律法规的规定主张免责。<br />
                    7、用户参与本活动过程中不得将所获得的优惠转让予他人，不得将该优惠用于出售等目的，也不可将该优惠进行任何形式的变现或赢利。<br />
                    8、如有任何关于此活动的咨询和帮助需要，请咨询上上参谋APP在线客服/数位观察数据咨询。<br />
                    <div class="mask"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/request/http';
import Player from "xgplayer";
import Search from "@/views/home/search";

export default {
  name: 'index',
  components: { Search },
  data () {
    return {
        dialogVisible: false,
        // 临时代码
        taba: 'tab_1',
        tabb: 'tab_1',
        tabc: 'tab_1',
        active: '',
        query:{
            c: '',
            m: '',
            b: '',
            p: '',
            d: '',
        },
        urls: {
            sscm: {
                development: 'https://huawei-test-webclient.sscanmou.com/#/channel/wdfs/branch',
                test: 'https://huawei-test-webclient.sscanmou.com/#/channel/wdfs/branch',
                production: 'https://webclient.sscanmou.com/#/channel/wdfs/branch',
            },
            // 数位观察
            swgc: {
                development: 'http://139.9.101.146/pages/resuscitate/resuscitate',
                test: 'http://139.9.101.146/pages/resuscitate/resuscitate',
                production: 'https://m.swguancha.com/pages/resuscitate/resuscitate',
            },
            // 拓店云
            swtdy: {
                development: 'https://saas-test.sscanmou.com/wdfs_activity_saas',
                test: 'https://saas-test.sscanmou.com/wdfs_activity_saas',
                production: 'https://saas.sscanmou.com/wdfs_activity_saas',
            },
        }
    };
  },
    created() {
      http.setAuth('');
    },
    methods: {
      getUserId () {
          return window.localStorage.getItem('sscm-wdfs-user-id') || '';
      },
      closeDialog() {
          let body = document.getElementsByTagName('body')[0];
          body.style.overflow = 'auto'
          this.dialogVisible = false;
      },
      showRuleDialog(){
          this.dialogVisible = true;
          let body = document.getElementsByTagName('body')[0];
          body.style.overflow = 'hidden'
      },
      toBranch(url, data) {
          let query = Object.assign(this.query, data)
          window.location.href =`${url}?c=${query.c}&m=${query.m}&p=${query.p}&d=${query.d}&b=${query.b}`;
      },
      getEnv () {
          console.log(process.env.NODE_ENV)
          return process.env.NODE_ENV;
      },
      track (_data, type) {
          let data = {
              c: '',
              m: '',
              b: '',
              p: '',
              d: '', // 1图片 2链接
          };
          data = Object.assign(data, this.query, _data);
          let trackRequestApi = http.sendTrack;

          if (type === 'btn') {
              trackRequestApi = http.sendBtnTrack;
          }
          trackRequestApi({
              deviceType: 4,
              platform: 'sscm',
              version: '0.0.1',
              userId: this.getUserId(),
              // parentPageId: data.p,
              track:  `[{\"pageId\":\"wdfs_10000\",\"refId\":\"wdfs\",\"extra\":\"${data.c}\",\"source\":{\"btnId\":\"${data.b}\",\"moduleId\":\"${data.m}\"},\"timestamp\":${new Date().getTime()}}]`
          })
      },
      // 临时代码不要模仿
      tabChange(type,index){
          this[`tab${type}`] = `tab_${index}`
          console.log(this[`tab${type}`])
      },
      toView (id) {
          var dom =document.getElementById(id);
          dom.scrollIntoView();
      },
  },
  mounted () {
      this.query = Object.assign(this.query, this.$route.query);
      this.query.p = 'wdfs_10000';
      this.$nextTick(() => {
          let sscmPlayer = new Player({
              id: 'video1',
              // poster: '', // 封面图
              url: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_sscm.mp4',
              playsinline: true,
              'x5-video-player-type': 'h5',
              'x5-video-player-fullscreen': 'false',
              closeVideoClick: true,
              closeVideoTouch: true,
              autoplay: false, // 自动播放
              videoInit: true, // 显示首帧
              fluid: true, // 流式布局
              // fitVideoSize: 'fixWidth',
              ignores: ['volume'],
              // options: {
              //     actions: {
              //         onPlay: () => { console.log(123) },
              //     },
              // }
          });

          let swgc = new Player({
              id: 'video2',
              // poster: '', // 封面图
              url: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_swgc.mp4',
              playsinline: true,
              'x5-video-player-type': 'h5',
              'x5-video-player-fullscreen': 'false',
              closeVideoClick: true,
              closeVideoTouch: true,
              autoplay: false, // 自动播放
              videoInit: true, // 显示首帧
              fluid: true, // 流式布局
              // fitVideoSize: 'fixWidth',
              ignores: ['volume'],
          });

          let tdy = new Player({
              id: 'video3',
              // poster: '', // 封面图
              url: 'https://sscm-prod.oss-cn-hangzhou.aliyuncs.com/lego/prod/wdfs/wdfs_saas.mp4',
              playsinline: true,
              'x5-video-player-type': 'h5',
              'x5-video-player-fullscreen': 'false',
              closeVideoClick: true,
              closeVideoTouch: true,
              autoplay: false, // 自动播放
              videoInit: true, // 显示首帧
              fluid: true, // 流式布局
              // fitVideoSize: 'fixWidth',
              ignores: ['volume'],
          });

          sscmPlayer.once('play', () => {
              this.track({m:'Module_wdfs_10004',b:'Button_wdfs_10004'}, 'btn')
          })
          swgc.once('play', () => {
              this.track({m:'Module_wdfs_10005',b:'Button_wdfs_10005'}, 'btn')
          })
          tdy.once('play', () => {
              this.track({m:'Module_wdfs_10006',b:'Button_wdfs_10006'}, 'btn')
          })
      })
      this.track({});
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/px2vw.scss';

*{
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}
.page{
    background: #FFDEC2;
    font-size: 0;
    .banner{
        img{
            width: 100%;
        }
    }
    .rules-icon{
        position: absolute;
        right: 0px;
        top: vw(23);
        width: vw(98);
        img{
            width: 100%;
        }
    }
    .rules{
        font-size: vw(26);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8893A7;
        line-height: vw(37);
        height: vw(714);
        overflow: auto;
        padding: 0 vw(30) vw(120) vw(30);
        .mask{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0 0 vw(20) vw(20);
            height: vw(120);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        }
    }
    .welfare-one{
        position: relative;
        padding: vw(20);
        background: url("/static/img/channel/img-sub.png") no-repeat;
        background-size: 100% auto;
        padding-top: vw(30) ;
        .module{
            margin-bottom: vw(26);
            .title-img{
                width: 100%;
            }
            .content{
                background: #fff;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px 0px rgba(255, 180, 142, 0.6);
                border-radius: 0px 0px 10px 10px;
                .title-img-wrap{
                    text-align: center;
                    padding: vw(24) 0 vw(27) 0;
                    img{
                        height: vw(40);
                        display: inline-block;
                    }
                }
                ul{
                    padding: vw(0) vw(30);
                    background-size: vw(358) auto;
                    background-position: center vw(24);
                    background-color: #fff;
                    li{
                        width: vw(307);
                        display: inline-block;
                        margin: vw(9);
                        img{
                            width: 100%;
                        }
                    }
                }
            }
            .btn-wrap{
                padding: 0 vw(20) vw(12) vw(20);
                display: inline-block;
                img{
                    width: 100%;
                }
            }
        }
    }
    .welfare-two{
        img{
            width: 100%;
            margin-bottom: vw(27);
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .sub-img{
        img{
            width: 100%;
        }
    }
    .apps{
        font-size: vw(28);
        .app{
            background: linear-gradient(360deg, #FFCBA1 0%, #FFDEC2 100%);
            padding: 0 vw(20);
            .img-wrap{
                text-align: center;
                padding-top: vw(38);
                font-size: 0;
                img{
                    display: inline-block;
                    height: vw(43);
                }
            }
            .full-img-wrap{
              img{
                width: 100%;
              }
            }
            .video-wrap{
                video{
                    width: vw(710);
                    height: vw(400);
                }
            }
            .content{
                background: #fff;
                padding: vw(20);
                border-radius: 0 0 10px 10px;
                .tab-item{
                    img{
                        width: 100%;
                    }
                }
            }
            .btn-wrap{
                padding: vw(20) 0;
                display: inline-block;
                img{
                    width: 100%;
                }
            }
        }
        .sub-title{
            font-size: vw(24);
            font-weight: bold;
            color: #A84627;
            line-height: 36px;
            text-align: center;
        }
    }
}
.nav{
    background: linear-gradient(360deg, #FFE9D5 0%, #FFF7EC 100%);
    border-radius: vw(10) vw(10) 0px 0px;
    border: vw(2) solid rgba(255, 255, 255, 0.7);
    font-size: 0;
    margin-top: vw(26);
    padding: 0 vw(20);
    &.nav-5{
        li{
            width: 20%;
        }
    }
    .w-27{
        width: 27.27%;
    }
    .w-18{
        width: 18.18%;
    }
    li{
        width: 25%;
        height: vw(80);
        font-size: vw(24);
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #A84627;
        line-height: vw(80);
        display: inline-block;
        text-align: center;
        &.active{
            background: url("/static/img/channel/tab-bottom-line.png") no-repeat;
            background-size: vw(150);
            background-position: center bottom;
            font-size: vw(26);
            font-weight: bold;
            color: #FF783A;
        }
    }
}
.dialog-wrap{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0,0,0,0.3);
    *{
        font-size: vw(28);
    }
    .dialog-content{
        width: vw(638);
        background: #fff;
        border-radius: vw(20);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 vw(30);
        .close-icon{
            font-size: vw(40);
            position: absolute;
            top: vw(20);
            right: vw(20);
            font-weight: bold;
        }
        .title{
            padding: vw(30);
            font-size: vw(34);
            font-weight: 600;
            color: #333333;
            line-height: vw(48);
            text-align: center;
        }
        .input-wrap{
            position: relative;
            height: vw(100);
            background: #F7F7F7;
            border-radius: vw(10);
            border: 1px solid #EBEAEE;
            margin-bottom: vw(20);
            input{
                height: 100%;
                width: 100%;
                background: none;
                padding: vw(30) vw(200) vw(30) vw(30);
            }
            .vcode{
                font-size: vw(28);
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF5900;
                line-height: vw(100);
                position: absolute;
                right: vw(30);
                background: none;
                height: vw(100);
            }
        }
        .btn-wrap{
            padding: vw(14) 0 vw(34) 0;
            button{
                width: 100%;
                height: vw(90);
                background: linear-gradient(90deg, #FF5900 0%, #FF8000 100%);
                border-radius: vw(45);
                font-size: vw(30);
                font-weight: 500;
                color: #FFFFFF;
                line-height: 42px;
                text-align: center;
            }
        }
    }
}
</style>
