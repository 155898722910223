<template>
    <div class="brand">
        <StateBar />
        <TitleHeader title="品牌查询" bgcolor="transparent" color="black" :back="true"></TitleHeader>

        <!-- 搜索框 -->
        <van-search v-model="form.name" placeholder="请输入品牌名称" @search="loadData('clear')" />
        <div class="brand--option">
            <div class="filtrate" v-for="(item, index) of types" :key="index" :class="{ filtrOn: newOpend === index }" @click="tabClick(item, index)">
                <span>{{ item }}</span>
            </div>
        </div>
        <div ref="content" style="height: 0"></div>

        <AutoView ref="view" id="view" :header="true" :footer="false" class="brand--view" :class="{ lock: opened }">
            <!-- 展示列表 -->
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__xs">
                <EmptyCard v-if="list.length === 0" type="search"></EmptyCard>
                <template v-else>
                    <div class="brand-recommend-box">
                      <img class="brand-recommend" src="/static/report/brand-recommend-bg.png" @click="goBrandHome">
                    </div>
                    <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="loadData">
                        <div class="content-wrap">
                            <BrandItem v-for="val in list" :key="val.key" :item="val" @reportCharts="reportCharts" />
                        </div>
                        <LockVip v-if="isLock" />
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>

        <!-- 选项视图 -->
        <transition name="slide">
            <div v-if="opened" :style="{ top: offsetTop + 'px' }" class="mask" @click.self="menuShow">
                <div class="option-view">
                    <!-- Index 0 选择品类-->
                    <div class="left" v-if="newOpend === 0">
                      <div class="category-tree" @click.stop="">
                        <div class="category-tree-nav">
                          <div class="category-tree-nav-item" :class="{'category-tree-nav-active-item': firstActiveIndex===index}" v-for="(item,index) in categoryList" :key="index" @click="selectFirstLevel(item, index)">{{ item.name }}</div>
                        </div>
                        <div class="category-tree-nav" ref="secondCategoryRef">
                          <div class="category-tree-nav-item" :class="{'category-tree-nav-active-item': secondActiveIndex===index}" v-for="(item,index) in categoryList[firstActiveIndex].list" :key="index" @click="selectSecondLevel(item, index)">{{ item.name }}</div>
                        </div>
                        <div class="category-tree-nav" ref="thirdCategoryRef">
                          <div v-if="categoryList[firstActiveIndex] && categoryList[firstActiveIndex].list">
                            <div class="category-tree-nav-item" :class="{'category-tree-nav-active-item': thirdActiveIndex===index}" v-for="(item,index) in categoryList[firstActiveIndex].list[secondActiveIndex].list" :key="index" @click="selectThirdLevel(item,index)">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Index 1 排序规则-->
                    <div class="mid" v-if="newOpend === 1">
                        <div v-for="tag in filtrateOpt.rules" :key="tag.key">
                            <h5 class="h5">{{ tag.title }}</h5>
                            <span class="custom-btn isSecected">{{ tag.val }}</span>
                        </div>
                    </div>
                    <!-- Index 2 筛选品牌-->
                    <div class="right" v-if="newOpend === 2">
                        <h5 class="h5">人均消费</h5>
                        <div class="flex-pre">
                            <span
                                v-for="(per, idx) in filtrateOpt.perCost"
                                :key="per.key"
                                class="custom-tag"
                                :class="{ isSecected: form.preIdx == idx }"
                                @click="costSort(per, idx)"
                            >
                                {{ per.text }}
                            </span>
                        </div>
                    </div>
                    <div class="btnBar">
                        <van-button type="info" plain class="sort" @click="onReset">重置</van-button>
                        <van-button type="info" class="long" @click="onSubmit">确认</van-button>
                    </div>
                </div>
            </div>
        </transition>

        <!-- 开通会员弹框 -->
        <OpenVip :showDialog="showDialog" @cancel="showDialog = false" />
    </div>
</template>

<script>
import BrandItem from "./components/brandItem.vue";
import SelectTabs from "@/components/widget/selectTabs";
import LockVip from "./components/lockVip.vue";
import OpenVip from "@/components/openVip/index.vue";

// const categoryList = [
//     {
//         name: "餐饮",
//         code: "SW01",
//         children: [
//             {
//                 code: "SW0101",
//                 name: "中式餐饮",
//             },
//             {
//                 code: "SW0102",
//                 name: "异国风情餐饮",
//             },
//             {
//                 code: "SW0103",
//                 name: "休闲餐饮",
//             },
//             {
//                 code: "SW0104",
//                 name: "烧烤/铁板烧",
//             },
//             {
//                 code: "SW0105",
//                 name: "轻餐",
//             },
//             {
//                 code: "SW0106",
//                 name: "焖锅/干锅",
//             },
//             {
//                 code: "SW0107",
//                 name: "火锅",
//             },
//             {
//                 code: "SW0108",
//                 name: "复合餐饮",
//             },
//         ],
//     },
//     {
//         name: "零售",
//         code: "SW02",
//         children: [
//             {
//                 code: "SW0201",
//                 name: "百货",
//             },
//             {
//                 code: "SW0202",
//                 name: "超市",
//             },
//             {
//                 code: "SW0203",
//                 name: "服装",
//             },
//             {
//                 code: "SW0204",
//                 name: "其他零售",
//             },
//             {
//                 code: "SW0205",
//                 name: "时尚精品",
//             },
//             {
//                 code: "SW0206",
//                 name: "专业卖场",
//             },
//         ],
//     },
//     {
//         name: "儿童亲子",
//         code: "SW03",
//         children: [
//             {
//                 code: "SW0301",
//                 name: "儿童游乐",
//             },
//             {
//                 code: "SW0302",
//                 name: "儿童零售",
//             },
//             {
//                 code: "SW0303",
//                 name: "儿童教育",
//             },
//             {
//                 code: "SW0304",
//                 name: "儿童服务",
//             },
//         ],
//     },
//     {
//         name: "生活服务",
//         code: "SW04",
//         children: [
//             {
//                 code: "SW0401",
//                 name: "丽人养生",
//             },
//             {
//                 code: "SW0402",
//                 name: "其他生活服务",
//             },
//             {
//                 code: "SW0403",
//                 name: "医疗健康",
//             },
//         ],
//     },
//     {
//         name: "文体娱",
//         code: "SW05",
//         children: [
//             {
//                 code: "SW0501",
//                 name: "娱乐休闲",
//             },
//             {
//                 code: "SW0502",
//                 name: "文化艺术",
//             },
//             {
//                 code: "SW0503",
//                 name: "体育运动",
//             },
//         ],
//     },
//     {
//         name: "跨界集合店",
//         code: "SW06",
//         children: [
//             {
//                 code: "SW0601",
//                 name: "生活方式集合",
//             },
//             {
//                 code: "SW0602",
//                 name: "跨界体验店",
//             },
//             {
//                 code: "SW0701",
//                 name: "汽车销售",
//             },
//         ],
//     },
//     {
//         name: "其他类型",
//         code: "SW08",
//         children: [
//             {
//                 code: "SW0802",
//                 name: "酒店",
//             },
//             {
//                 code: "SW0803",
//                 name: "主题街区",
//             },
//         ],
//     },
// ];

export default {
    components: { BrandItem, SelectTabs, LockVip, OpenVip },
    data() {
        return {
            firstLoading: true,
            loading: true,
            types: ["选择品类", "排序规则", "筛选品牌"],
            offsetTop: 0,
            opened: null, // 展开选项
            newOpend: null,
            filtrateOpt: {
                category: this.categoryList,
                rules: [
                    {
                        title: "门店数",
                        val: "门店数优先",
                    },
                ],
                perCost: [
                    { text: "不限", min: null, max: null },
                    { text: "10-30元", min: 10, max: 30 },
                    { text: "30-50元", min: 30, max: 50 },
                    { text: "50-100元", min: 50, max: 100 },
                    { text: "100-150元", min: 100, max: 150 },
                    { text: "150-200元", min: 150, max: 200 },
                    { text: "200元以上", min: 200, max: null },
                ],
            },
            form: {
                name: null,
                categoryTag1Code: null,
                categoryTag2Code: null,
                categoryTag3Code: null,
                consumeAvgRangeMIn: null,
                consumeAvgRangeMax: null,
                sortField: "open_shop_number",
                preIdx: 0,
                current: 1,
                size: 10,
            },
            list: [],
            finishedText: "没有更多了",
            finished: false,
            isLock: false,
            showDialog: false,
            categoryList: [],
            firstActiveIndex: 0,
            secondActiveIndex: 0,
            thirdActiveIndex: 0
        };
    },
    computed: {
        currentCategory() {
            if (!this.form.categoryTag1Code) return null;
            const category = this.filtrateOpt.category.find((item) => item.code === this.form.categoryTag1Code);
            return category ? category.children : [];
        },
        category() {
            if (!this.currentCategory) return null;
            return this.currentCategory.map((item) => {
                return item.name;
            });
        },
    },
    mounted() {
        this.getAllIndustry()
        this.loadData();
        this.$nextTick(() => {
            this.offsetTop = this.$refs.content.getBoundingClientRect().top;
        });
    },
    methods: {
        async getAllIndustry() {
          const industrys = await this.HTTP.industry()
          industrys.forEach((item, index) => {
            if (item.list && item.list.length) {
              item.list.unshift({
                name: '全部',
                code: '',
              })
              item.list.forEach((el, index) => {
                if (el.code === this.form.categoryTag2Code) {
                  this.secondActiveIndex = index
                }
                if (el.list && el.list.length) {
                  el.list.unshift({
                    name: '全部',
                    code: '',
                  })
                }
              })
            }
            // children
            if (item.code === this.form.categoryTag1Code) {
              this.firstActiveIndex = index + 1
            }
          })
          this.categoryList = industrys || [];

          this.categoryList.unshift({
            name: '不限',
            code: '',
          })
        },
        selectThirdLevel(val,index) {
          this.thirdActiveIndex = index
          this.form.categoryTag3Code = val.code
        },
        selectSecondLevel(val,index) {
          this.secondActiveIndex = index
          this.thirdActiveIndex = 0
          this.$refs.thirdCategoryRef.scrollTop = 0

          this.form.categoryTag2Code = val.code;
          this.form.categoryTag3Code = ''
        },
        selectFirstLevel(val,index) {
          this.firstActiveIndex = index
          this.secondActiveIndex = 0
          this.thirdActiveIndex = 0
          this.$refs.secondCategoryRef.scrollTop = 0
          this.$refs.thirdCategoryRef.scrollTop = 0
          this.form.categoryTag1Code = val.code;
          this.form.categoryTag2Code = '';
          this.form.categoryTag3Code = '';
        },
        goBrandHome() {
          this.swRouter({
            path: "/h5/#/brand/home"
          });
        },
        costSort(data, idx) {
            this.form.preIdx = idx;
            this.form.consumeAvgRangeMIn = data.min;
            this.form.consumeAvgRangeMax = data.max;
        },
        reportCharts(data) {
          this.swRouter('/h5/#/brand/detail/' + data)
            // this.HTTP.checkBrandStatus(data)
            //     .then((res) => {
            //         this.swRouter({
            //             path: "/report/brandLoader",
            //             query: {
            //                 reportId: data,
            //             },
            //         });
            //     })
            //     .catch((e) => {
            //         if (e.code == 201022) {
            //             this.showDialog = true;
            //         } else {
            //             this.$toast("网络不稳定，请稍候重试！");
            //         }
            //     });
        },
        selectCategory(res) {
            const value = res.index;
            const index = this.form.category.indexOf(value);
            if (index > -1) {
                this.form.category.splice(index, 1);
            } else {
                this.form.category.push(value);
            }
        },
        loadData(clear) {
            this.opened = null;
            if (clear) {
                this.form.current = 1;
                this.finished = false;
            }
            this.loading = true;
            let params = Object.assign({}, this.form);
            for (let key in params) {
                if (!params[key]) {
                    delete params[key];
                }
            }
            this.HTTP.searchBrand(params)
                .then((res) => {
                    console.log(res);
                    if (clear) this.list = [];
                    this.firstLoading = false;
                    this.loading = false;
                    this.form.current++;
                    if (this.list.length) {
                        this.list = this.list.concat(res.data || []);
                    } else {
                        this.list = res.data;
                    }
                    if (res.data.length < res.size || res.data.length === 0) {
                        this.finished = true; // 完成加载
                    }
                })
                .catch((e) => {
                    if (e.code == 201022) {
                        this.isLock = true;
                        this.finished = true; // 完成加载
                        this.finishedText = "";
                        this.loading = false;
                    } else {
                        this.$toast("网络不稳定，请稍候重试！");
                        this.loading = false;
                    }
                });
        },
        // tab切换
        tabClick(item, index) {
            this.opened = this.opened === item ? null : item;
            this.newOpend = index;
        },
        menuShow() {
            this.opened = null;
        },
        onReset() {
            this.form = {
                name: null,
                categoryTag1Code: null,
                categoryTag2Code: null,
                categoryTag3Code: null,
                consumeAvgRangeMIn: null,
                consumeAvgRangeMax: null,
                sortField: "open_shop_number",
                preIdx: 0,
                current: 1,
                size: 10,
            };
            this.firstActiveIndex = 0
            this.secondActiveIndex = 0
            this.thirdActiveIndex = 0
        },
        onSubmit() {
            if (this.loading) return;
            this.loadData("clear");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
.category-tree {
  display: flex;
  background: #f7f8fa;
  font-size: vw2(14);
  height: vw2(300);
  position: relative;
  .category-tree-nav {
    flex: 1;
    overflow-y: auto;
    border-right: 1px solid #D3D3D3;
    &:first-child {
      background: #fff
    }
    .category-tree-nav-item {
      padding: vw2(15) vw2(10);
      &.category-tree-nav-active-item {
        color: #347FFF;
      }
    }
  }
}
.brand {
    height: 100vh;
    overflow: hidden;
    .brand-recommend-box {
      padding: 0 3.2vw;
      margin-bottom: 3.2vw;
      font-size: 0;
      .brand-recommend {
        width: 100%;
      }
    }


    #{&}--view {
        scroll-behavior: smooth;
        position: relative;
        background: #f7f7f7;
        padding: 3.2vw 0 43vw 0;

        .content-wrap {
            padding: 0 3.2vw;
        }

        &.lock {
            overflow: hidden;
        }
    }
    .mask {
        position: absolute;
        // top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        background-color: rgba(black, 0.7);

        .option-view {
            background-color: white;

            .left {
                height: 100%;
                .on {
                    color: $c-main;
                }
                .side {
                    width: 30%;
                    min-height: 55vw;
                    background-color: #f7f7f7;
                }
                .main {
                    width: 70%;
                    padding: 1.5vw 0 0 3vw;
                    box-sizing: border-box;
                }
                .radio {
                    @include ani;
                    display: block;
                    width: 100%;
                    height: 12vw;
                    line-height: 12vw;
                    font-size: $font_373;
                    text-align: center;
                    overflow: hidden;
                }
            }
            .mid {
                padding: 3.2vw 5.33vw;
                .h5 {
                    font-size: 3.47vw;
                    color: #333;
                    font-weight: bold;
                }
                .custom-btn {
                    display: inline-block;
                    width: 37.87vw;
                    font-size: 3.47vw;
                    color: #666;
                    padding: 2.13vw 0;
                    border-radius: 0.8vw;
                    background: #f8f8f8;
                    margin: 2.67vw 0 4.8vw 0;
                    border: 1px solid #f1f1f1;
                    text-align: center;
                }
            }
            .right {
                padding: 3.2vw 5.33vw;
                .h5 {
                    font-size: 3.47vw;
                    color: #333;
                    font-weight: bold;
                }
                .flex-pre {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    .custom-tag {
                        flex: 0 0 24%;
                        font-size: 3.47vw;
                        color: #666;
                        padding: 2.13vw 2.67vw;
                        border-radius: 0.8vw;
                        background: #f8f8f8;
                        margin-top: 3.2vw;
                        border: 1px solid #f1f1f1;
                        text-align: center;
                    }
                }
            }
            .isSecected {
                color: #347fff !important;
                background: #dfedff !important;
            }

            .btnBar {
                @include flex;
                padding: $padding_3 $padding_4;
                width: 100%;
                box-sizing: border-box;

                .sort {
                    width: 28vw;
                    color: #347fff;
                }
                .long {
                    width: 61vw;
                }
            }
        }
    }
    #{&}--option {
        position: relative;
        @include flex;
        height: 10vw;
        padding: 0 $padding_4;
        background-color: white;
        color: $c-text;
        line-height: normal;
        .filtrate {
            @include flexColumn;
            height: 100%;
            font-style: normal;
            font-size: $font_373;
            padding: 0 4vw;
            background: url(/static/icon/icon_down@2x.png) no-repeat right 3.73vw center;
            background-size: 2.5vw auto;
            flex: 1;
            box-sizing: border-box;
            overflow: hidden;
            text-align: center;
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                width: 100%;
                white-space: nowrap;
            }
            &.filtrOn {
                color: $c-main;
                background: url(/static/icon/icon_up@2x.png) no-repeat right 3.73vw center;
                background-size: 2.5vw auto;
            }
        }
    }
}
</style>
