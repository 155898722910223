<template>
    <div class="brand_table">
        <van-grid :column-num="2" border :center="false">
            <template v-for="( item, ix ) of dataList">
                <van-grid-item :key="'c1' + ix" :text="item.name" />
                <van-grid-item :key="'c2' + ix" :text="item.value || '暂未披露'" />
            </template>
        </van-grid>
    </div>
</template>


<script>
    export default {
        name: "brandTable",
        props: {
            dataList: {
                type: Array
            },
        },
        data() {
            return {

            }
        },
        methods: {

        },
    }

</script>

<style lang="scss" scoped>
    @import "@/styles/index.scss";
    .brand_table{
        margin-top: 2.67vw;
        margin-bottom: 5.06vw;
        width: 100%;
        height: auto;
        ::v-deep .van-grid-item:nth-child(odd) {
            flex-basis: 28vw !important;
            box-sizing: border-box;
        }
        ::v-deep .van-grid-item:nth-child(even) {
            flex-basis: calc(100% - 28vw) !important;
            box-sizing: border-box;
        }
        ::v-deep .van-grid-item__content{
            padding: 2.67vw 2.67vw 2.67vw 3.73vw;
        }
        ::v-deep .van-grid-item:nth-child(odd) .van-grid-item__content{
            background: #F0F7FF;
        }
        ::v-deep [class*=van-hairline]::after{
            border: 0 solid #DEDEE7;
            border-width: 0 1px 1px 0;
        }
        ::v-deep .van-hairline--top::after{
            border-top-width: 1px;
            border-left-width: 1px;
            border-bottom-width: 0;
        }
    }

</style>
