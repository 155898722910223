/**
 * 社区栏目
 */
const hotVideo = () => import(/* webpackChunkName: "module-community" */ "@/views/community/hotVideo");
const articleDetail = () => import(/* webpackChunkName: "module-community" */ "@/views/community/articleDetail");
const articleEmpty = () => import(/* webpackChunkName: "module-community" */ "@/views/community/empty");
const drgGoods = () => import(/* webpackChunkName: "module-community" */ "@/views/community/drgGoods");
const goodsList = () => import(/* webpackChunkName: "module-community" */ "@/views/community/goodsList");
const latest = () => import(/* webpackChunkName: "module-community" */ "@/views/community/latest");
const collection = () => import(/* webpackChunkName: "module-community" */ "@/views/community/collection");
const storeMap = () => import(/* webpackChunkName: "module-community" */ "@/views/community/storeMap");
const discuss = () => import(/* webpackChunkName: "module-community" */ "@/views/community/discuss");
const store = () => import(/* webpackChunkName: "module-community" */ "@/views/shops/store")

export default [
  {
    path: "/community/hotVideo",
    name: "hotVideo",
    component: hotVideo,
    meta: { id: 10125 }
  },
  {
    path: "/community/article/:id",
    name: "articleDetail",
    component: articleDetail,
    meta: { id: 10126 }
  },
  {
    path: "/community/empty",
    name: "articleEmpty",
    component: articleEmpty
  },
  //
  {
    path: "/community/drgGoods",
    name: "drgGoods",
    component: drgGoods,
    meta: { id: 10118 }
  },
  {
    path: "/community/goodsList",
    name: "goodsList",
    component: goodsList,
    meta: { id: 10119 }
  },
  // 最新资讯
  {
    path: "/community/latest",
    name: "latest",
    component: latest,
    meta: { id: 10120 }
  },
  // 收藏
  {
    path: "/community/collection",
    name: "collection",
    component: collection,
    meta: { id: 10121 }
  },
  // 开店地图
  {
    path: "/community/storeMap",
    name: "storeMap",
    component: storeMap,
    meta: { id: 10164 }
  },
  {
    path: "/community/discuss",
    name: "discuss",
    component: discuss,
    meta: { id: 10173 }
  },
  {
    path: "/community/store",
    name: "store",
    component: store,
    meta: { id: 10333 }
  }
];
