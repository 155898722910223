<template>
    <div class="card-list" @click="onTap">
        <div class="brand-wrap">
            <ul class="flex-ul">
                <li class="left">
                <img :src="item.logoUrl" alt="" />
                </li>
                <li class="right">
                <p class="top">{{ item.brandName }}</p>
                <p class="mid">{{ item.formedStr }}</p>
                <p class="bottom" v-if="item.joinTotalCost"><span>预估总投资额：</span>{{ item.joinTotalCost }}</p>
                </li>
            </ul>
            <div class="bottom-wrap">
                <ul class="flex-bottom">
                <li class="l-li" v-if="item.newCategoryNameL1">
                    <van-tag style="border:1px solid #FF9F6B; border-radius: 3px" color="#FFF1E9" text-color="#FF5900">{{ item.newCategoryNameL1 }}</van-tag>
                </li>
                <li class="r-li">
                    <i>{{ item.joinNumTotal }}</i> 人申请 &nbsp;&nbsp;<i>{{ item.shopNum }}</i> 家门店
                </li>
                </ul>
            </div>
        </div>
        <div class="float-cite" v-if="item.isRecord">
          <img v-if="!recommendIcon" src="/static/icon/forTheRecord.png" alt="" />
          <img v-else src="/static/discovery/tj2.png" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name : 'card',
    props: {
        item: Object,
        top : {
            type   : Number,
            default: 0,
        },
        analyse: {
            type: Boolean,
            default: false
        },
        recommendIcon: {
          default: false,
          type: Boolean
        }
    },
    methods: {
        onTap () {
            this.$emit( 'click', this.item );
        },
        onTap2 () {
            this.$emit( 'click2', this.item );
        }
    },
};
</script>

<style lang="scss" scoped>
.card-list {
  position: relative;
  height: 35.73vw;

  .brand-wrap {
    background: #fff;
    box-shadow: 0 0 2.13vw 0 rgba(0, 0, 0, 0.09);
    border-radius: 1.33vw;

    .flex-ul {
      display: flex;
      padding: 3.2vw 4vw;
      .left {
        flex: 0 0 22.67vw;
        img {
          width: 20vw;
          height: 20vw;
          border-radius: 1.07vw;
          object-fit: cover;
        }
      }
      .right {
        flex: 1;
        .top {
          line-height: 5.87vw;
          font-size: 4vw;
          color: #1d2233;
          font-weight: bold;
        }
        .mid {
          line-height: 4.8vw;
          font-size: 3.47vw;
          color: #8e9099;
          margin-top: 1.87vw;
        }
        .bottom {
          line-height: 4.8vw;
          font-size: 3.47vw;
          color: #8e9099;
          margin-top: 1.07vw;
        }
      }
    }
    .bottom-wrap {
      height: 9.07vw;
      line-height: 9.07vw;
      background: #fbfbfb;
      border-bottom-left-radius: 1.33vw;
      border-bottom-right-radius: 1.33vw;
      .flex-bottom {
        display: flex;
        padding: 0 4vw;
        justify-content: center;
        align-items: center;
        .l-li {
          // flex: 1;
          line-height: 0;
        }
        .r-li {
          flex: 1;
          font-size: 3.47vw;
          color: #666;
          text-align: right;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          margin-left: 1.33vw;
          i {
            color: #ff5900;
            font-style: normal;
          }
        }
      }
    }
  }
  .float-cite {
    position: absolute;
    right: 1.6vw;
    top: -0.8vw;
    img {
      width: 11.07vw;
      height: 5.73vw;
    }
  }
}
</style>
