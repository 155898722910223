var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-container"},[_c('div',{staticClass:"hot-map-container"},[_c('baseMap',{ref:"baseMapRef",attrs:{"id":_vm.mapId,"mapOpts":{
                     mapStyle: 'amap://styles/whitesmoke',
                     zoom: 14,
                     zooms: [3, 19]
                   }},on:{"map-complete":_vm.complete}}),_c('div',{staticClass:"hot-map-container-mask"})],1),(_vm.showHotText)?_c('div',{staticClass:"left-explain"},[_c('span',[_vm._v("少")]),_c('div',{staticClass:"legend-color"},_vm._l((_vm.colors),function(color){return _c('span',{style:({'background': color})})}),0),_c('span',[_vm._v("多")])]):_vm._e(),_c('div',{staticClass:"right-zoom",on:{"click":_vm.popupChange}},[_c('p',{staticClass:"tips"},[_vm._v("全屏缩放")]),_c('img',{attrs:{"src":"/static/shopEcology/report/icon_zoom.png","alt":""}})]),(_vm.legendList.length)?_c('div',{staticClass:"legend-box"},_vm._l((_vm.legendList),function(item,index){return _c('div',{key:index,staticClass:"legend-box-item"},[_c('div',{staticClass:"legend-box-item-grid",style:({'background': item.color})}),_c('div',{staticClass:"legend-box-item-name"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e(),_c('van-popup',{staticClass:"grid-hot-map-popup",style:({ width: '100%', height: '100%', border: 0 }),attrs:{"get-container":"#app","position":"center"},model:{value:(_vm.showChartReportPopup),callback:function ($$v) {_vm.showChartReportPopup=$$v},expression:"showChartReportPopup"}},[_c('div',{staticClass:"popup-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupLoading),expression:"popupLoading"}],staticClass:"center-loading"},[_c('van-loading')],1),_c('baseMap',{ref:"baseMapPopupRef",attrs:{"id":`popup_${_vm.mapId}`,"mapOpts":{
               mapStyle: 'amap://styles/whitesmoke',
               zoom: 15,
               zooms: [3, 19]
            }},on:{"map-complete":_vm.popupMapComplete}}),(_vm.legendList.length)?_c('div',{staticClass:"legend-box"},_vm._l((_vm.legendList),function(item,index){return _c('div',{key:index,staticClass:"legend-box-item"},[_c('div',{staticClass:"legend-box-item-grid",style:({'background': item.color})}),_c('div',{staticClass:"legend-box-item-name"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e(),_c('div',{staticClass:"top-back"},[[_c('img',{attrs:{"src":"/static/shopEcology/report/icon_back_entity.png","alt":""},on:{"click":_vm.hidePopup}}),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"back-tips"},[_vm._v("颜色越红，热力越旺越集中")]),_c('p',{staticClass:"back-tips"},[_vm._v("地图支持单指滑动，双指缩放")])])]],2),_c('div',{staticClass:"bottom-location"},[_c('div',{staticClass:"new-icon-location"},[_c('img',{attrs:{"src":"/static/shopEcology/report/icon_location_hot.png","alt":""},on:{"click":_vm.panToCenter}})]),_c('p',{staticClass:"tips skewing"},[_vm._v("点击按钮回到原点")]),_c('p',{staticClass:"tips float-r"},[_vm._v(_vm._s(_vm.hotText))])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }