<template>
    <div class="news" @click="onTap">
        <van-image class="news--img s-radius__s"
            fit="cover"
            lazy-load
            :src="item.coverImage">
            <template v-slot:loading>
                <van-loading type="spinner" size="20" />
            </template>
        </van-image>

        <div class="news--content">
            <h1>{{ item.title || '---' }}</h1>
            <div v-if="item.labels" class="label">
                <span v-for="( label, index ) in item.labels" :key="index">{{ label }}</span>
            </div>
            <p class="info">{{ item.showClickCount || 1 }}浏览·{{ item.gmtCreate | timeFormat }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name : 'news',
    props: {
        item: Object,
    },
    methods: {
        onTap () {
            this.$emit( 'click', this.item );
        },
    },
    filters: {
        timeFormat ( val ) {
            return val ? val.split( ' ' )[ 0 ] : '刚刚';
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.news {
    position: relative;
    height: $cardHeight;
    padding-left: calc( 5vw + #{ $cardHeight } );
    // padding-right: calc( 5vw + #{ $cardHeight } );
    box-sizing: border-box;

    #{&}--img {
        position: absolute;
        left: 0;
        // right: 0;
        width: $cardHeight;
        height: $cardHeight;
    }

    #{&}--content {
        position: relative;
        height: 100%;
        h1 {
            color: $c-text;
            width: 100%;
            min-height: $font_533;
            max-height: $font_533 * 2;
            // font-size: $font_400;
            // line-height: $font_533;
            font-size: $font_427;
            line-height: $font_560;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .label {
            overflow: hidden;
            margin-top: 2.13vw;
            height: $font_453;
            span {
                float: left;
                font-size: $font_240;
                line-height: $font_453;
                color: $c-main;
                border-radius: $radius-xs;
                background-color: #F3F7FC;
                padding: 0 1.87vw;
                margin-right: 1.33vw;
            }
        }
        .info {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin: 3.2vw 0 0;
            color: $c-info;
            font-size: 2.66vw;
        }
    }
}
</style>
