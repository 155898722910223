<template>
  <div class="feedback" :class="{'move-feedback': !flags}">
    <img class="feedback-icon" src="/static/report/feedback-icon.png" alt="" />
    <div class="name">数据反馈</div>
  </div>
</template>

<script>
let timer = null
export default {
  components: {},
  data () {
    return {
      flags: false, //是否开启拖拽
      nx: "", //鼠标移动X
      ny: "", //鼠标移动Y
      maxW: 0, //可视窗口宽
      maxH: 0, //可是窗口高
    };
  },
  mounted () {
    this.maxW = document.documentElement.clientWidth; //获取可视窗口宽
    this.maxH = document.documentElement.clientHeight; //获取可视窗口高
    this.initEventDrag()
  },
  methods: {
    initEventDrag() {
      const box = document.querySelector('.feedback')
      console.log(box.getBoundingClientRect())
      // console.log('obj',box,box.style.width, box.style.height)
      const elWidth = box.getBoundingClientRect().width
      const elHeight = box.getBoundingClientRect().height
      console.log(elWidth,elHeight)
      box.addEventListener('touchstart', (event) => {
        console.log('拖动开始');
        this.flags = true; //鼠标按下开启拖拽
      })
      box.addEventListener('touchmove', (event) => {
        console.log('拖动中');
        event.preventDefault(); //方法阻止元素发生默认的行为。
        if (this.flags) {
          //判断用户是否在拖拽  如果拖拽
          this.nx = event.touches[0].clientX;
          this.ny = event.touches[0].clientY;
          //左右范围
          //console.log(this.nx,this.ny)
          if (this.nx < 0) {
            //设置拖动范围   不可以拖动到可是窗口外 当拖动X轴小于0时说明已经拖出去了  就将X轴赋值为0
            this.nx = 0;
          } else if (this.nx > this.maxW - elWidth) {
            //如果大于可视窗口宽时将可视窗口宽赋值给X轴
            this.nx = this.maxW - elWidth;
          }
          //上下范围
          if (this.ny < 0) {
            //设置拖动范围   不可以拖动到可是窗口外 当拖动X轴小于0时说明已经拖出去了  就将Y轴赋值为0
            this.ny = 0;
          } else if (this.ny >= this.maxH - elHeight) {
            //如果大于可视窗口高时将可视窗口宽赋值给X轴
            this.ny = this.maxH - elHeight;
          }
          console.log(this.nx,this.ny)
          box.style.left = this.nx + "px"; //将拖拽的X轴赋值给div
          box.style.top = this.ny + "px"; //将拖拽的Y轴赋值给div
          //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
          // document.addEventListener(
          //   "touchmove",
          //   function () {
          //     // event.preventDefault();
          //   },
          //   false
          // );
        }
      })
      box.addEventListener('touchend', (event) => {
        console.log('拖动结束');
        this.flags = false; //鼠标按下开启拖拽
        clearTimeout(timer)
        if (this.nx < this.maxW - elWidth && this.nx > 10) {
          timer = setTimeout(() => {
            this.nx = this.maxW - elWidth
            box.style.left = this.nx + "px"; //将拖拽的X轴赋值给div
          }, 1000)
        }
      })
      // box.addEventListener('dragstart', (e) => {
      //   console.log('拖动开始');
      // })
      // box.addEventListener('drag', (e) => {
      //   console.log('拖动中');
      // })
      // box.addEventListener('dragend', (e) => {
      //   console.log('拖动结束');
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  position: fixed;
  right: 5px;
  bottom: 28vh;
  width: 11.73vw;
  height: 12.8vw;
  background: #FFFFFF;
  box-shadow: 0 1.333vw 4vw 0 rgba(29,34,51,0.14);
  border-radius: 1.333vw;
  backdrop-filter: blur(0.533vw);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.move-feedback {
    transition: all 0.4s ease-in-out
  }
  .feedback-icon {
    width: 10.67vw;
    height: 8vw;
  }
  .name {
    font-weight: 500;
    color: #325AA7;
    font-size: 2.4vw;
    margin-top: 4px;
  }
}
</style>
