<template>
    <div class="partner">
        <StateBar />
        <TitleHeader title="提现记录" bgcolor="transparent" :back="true" />

        <AutoView :header="true" :footer="false" class="partner--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="partner"></EmptyCard>
                <template v-else>
                    <van-pull-refresh v-model="dataLoading" @refresh="loadNewData">
                        <van-list v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="loadData" >
                            <Card v-for="( item ) in list" :id="'r-' + item.id" :key="item.id" @tap="onTap"
                                :item="item" />
                        </van-list>
                    </van-pull-refresh>
                </template>
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import Card from '@/components/card/cashout';

export default {
    name      : 'partnerHistory',
    components: {
        Card,
    },
    data () {
        return {
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            recent      : null,
            remote      : null,
            list        : [],
        };
    },
    methods: {
        loadData () {
            this.loading = true;
            this.HTTP.cashoutList( this.remote, 'before' ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                if ( res.list.length ) {
                    this.list = this.list.concat( res.list );
                }
                if ( this.list.length ) {
                    this.recent = this.list[ 0 ].applyTime;
                    this.remote = this.list[ this.list.length - 1 ].applyTime;
                }
                this.finished = ! res.more;
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading  = false;
                this.finished = true;
            } );
        },
        loadNewData () {
            this.dataLoading = true;
            // 重新获取
            this.remote = null;
            this.HTTP.cashoutList( this.remote, 'before' ).then( ( res ) => {
                this.list = res.list;
                if ( this.list.length ) {
                    this.recent = this.list[ 0 ].applyTime;
                    this.remote = this.list[ this.list.length - 1 ].applyTime;
                }
                setTimeout( () => { this.dataLoading = false; }, 1000 );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                setTimeout( () => { this.dataLoading = false; }, 1000 );
            } );
        },
        onTap () {},
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.partner {
    height: 100vh;
    background-color: $c-pageBg;

    #{&}--view {
        .l-panel {
            padding: $padding2;
        }
    }
}
</style>
