<!--
位置选择器

Props:
  position: 初始位置, 默认为当前位置
Events:
  change: 位置改变时触发
  confirm: 确定位置时触发
  cancel: 返回时触发
-->

<template>
    <div class="poi-picker">
      <div class="poi-picker--header">
        <StateBar />
        <div class="header-body">
          <van-button class="icon-btn s-radius__s" @click="$emit('cancel')">
            <Icon name="left" :size="6.13"></Icon>
          </van-button>
          <van-button v-show="isAddressReady" type="info" class="confirm-btn s-radius__s" @click="getAddresName">确定</van-button>
        </div>
      </div>
      <!-- 地图 -->
      <BaseMap ref="mapVm" @complete="mapComplete"
      :center="center"
      :zoom="zoom"
      :mapType="2"
      :radius="rangeRadius"
      @mapMoveend="mapMoveendHandler"
      class="map-cont"/>
      <SlidePopup v-model="isShowPopup" @change="popupChange" :bottomDis="32">
        <template slot="sticky">
          <div class="search-box">
            <SearchBar v-model="searchStr"
                      placeholder="搜索地点"
                      ref="searchBar"
                      :clearable="isShowPopup"
                      :showCancelBtn="isShowPopup"
                      :showFakeBtn="showFakeBtn"
                      @input="completeByInput"
                      @search="searchAmapPOI"
                      @clear="searchBarClear"
                      @cancel="searchBarCancel"
                      @focus="searchBarFocus"
                      @clickFakeBtn="clickFakeBtn"
                      @blur="searchBarblur"></SearchBar>
          </div>
        </template>
        <div class="search-result">
          <van-list
            :immediate-check="false"
            offset="100"
            v-model="isLoadingPoi"
            :finished="poiFinished"
            finished-text="没有更多了"
            @load="loadMorePoi"
            class="poi-list"
          >
            <POIBar v-for="(poi,index) in POIList"
                      :key="poi.id+index"
                      :formatName="poi.formatName"
                      :distance="poi.distance"
                      :address="poi.address"
                      :checked="!!selectedPOI && selectedPOI.id === poi.id"
                      @click="selectPOI(poi)"
                      class="poi-item"></POIBar>
          </van-list>
          <div class="create-poi-btn" v-if="false">
            <Icon name="plus-poi" :size="4"></Icon>
            <p class="btn-text">
              <span>没有找到您想找的位置?</span>
              <span class="highlight">去新增</span>
            </p>
          </div>
          <ResEmpty v-show="!POIList.length&&searchStr" :isCard="false" type="address" />
        </div>
      </SlidePopup>
      <div class="btn-wrap">
        <van-button class="relocate-btn icon-btn s-radius__s" @click="relocate">
          <Icon name="aim" :size="5.07"></Icon>
        </van-button>
        <div class="radius" @click="showPicker=true">
          <span>{{rangeRadius}}米</span>
          <span class="pull-down"><Icon name="map-icon-pd" :size="2"></Icon></span>
        </div>
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          duration="0.2"
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </div>
</template>

<script>
import { formatNameToSegments } from '@/utils';
import Icon from '@/components/Icon/Icon';
import SlidePopup from '@/components/SlidePopup';
import SearchBar from '@/components/SearchBar';
import ResEmpty from '@/components/card/empty';
import POIBar from '@/components/POIBar';
import BaseMap from '@/components/baseMap';
// import { debounce } from '@/utils/index';
// const Icon = () => import( '@/components/Icon/Icon' );
const mockLocation = [116.407387, 39.904179];// [114.129794374736, 22.6242752908589];
const searchRadius  = 1000;
const bigRadius = 10000;
const columns = ['500米', '1000米', '2000米'];
let _init = false;
export default {
    name      : 'POIPicker',
    components: {
        Icon,
        SlidePopup,
        SearchBar,
        ResEmpty,
        POIBar,
        BaseMap,
    },
    props: {
        position: {
            type: Array
        }
    },
    data () {
        return {
            zoom              : 14, // 17,15
            center            : this.position || mockLocation,
            basePos           : [], // 基础位置设置为空
            poi               : this.position || mockLocation, // 选择的poi
            // PIO查询结果
            isShowPopup       : false,
            realSearchVal     : '', // 用来搜索的输入值
            searchStr         : '', // 输入框的显示值
            POIList           : [],
            selectedPOI       : null,
            showPicker        : false,
            rangeRadius       : Number( columns[0].slice( 0, - 1 ) ),
            columns           : columns,
            showFakeBtn       : true,
            isLoadingPoi      : false,  // 加载更多
            poiFinished       : false,  // 是否加载完成
            pageSize          : 20,     // 搜索附近的
            pageIndex         : 1,      // 当前加载页数
            isCompleteMode    : false,  // true输入提示模式,false搜索附近模式
            currentCompletePoi: {},
            completePoiList   : [], // 输入提示返回的列表
            isAddressReady    : false, // 获取地址完成
        };
    },
    methods: {
        clickFakeBtn () {
            this.showFakeBtn = false;
            this.isShowPopup = true;
        },
        mapComplete ( map ) {
            //  地图加载完成
            if ( ! _init ) {
                this.getCurrentCity( this.poi );
            }
        },
        mapMoveendHandler ( mapCenter ) {
            if ( this.isPickerPoi ) {
                this.isPickerPoi = false;
                return;
            }
            this.poi = mapCenter;
            // 清除当前选择的poi
            this.selectedPOI = null;
            console.error( 'cancel' );
            this.$emit( 'change', this.poi );
        },
        onConfirm ( str, index ) {
            this.rangeRadius = str.slice( 0, - 1 );
            this.showPicker = false;
        },
        getAddresName () {
            // 如果是地图选点，地址逆解析
            // 如果搜索数据，则不需要地址逆解析
            if ( this.selectedPOI ) {
                const address = [...this.poi, this.selectedPOI.name];
                this.$emit( 'confirm', address, Number( this.rangeRadius ) );
                return;
            }
            const geocoder = new AMap.Geocoder( { city: '全国' } );
            geocoder.getAddress( this.poi, ( status, result ) => {
                const address = [...this.poi];
                if ( status === 'complete' && result.info === 'OK' ) {
                    // result为对应的地理位置详细信息
                    const { formattedAddress, addressComponent:{
                        province, city, district, township, street, streetNumber
                    } } = result.regeocode;
                    let len = province.length + city.length;
                    let aliaStr = '';
                    if ( formattedAddress.indexOf( district ) > - 1 ) {
                        len  +=  district.length;
                        aliaStr += district;
                    } else {
                        aliaStr += city;
                    }
                    if ( formattedAddress.indexOf( township ) > - 1 ) {
                        len  +=  township.length;
                        aliaStr += township;
                    }
                    if ( formattedAddress.indexOf( street ) > - 1 ) {
                        len  +=  street.length;
                        aliaStr += street;
                    }
                    if ( formattedAddress.indexOf( streetNumber ) > - 1 ) {
                        len  +=  streetNumber.length;
                        aliaStr += streetNumber;
                    }
                    let resName = formattedAddress.slice( len );
                    if ( ! resName ) {
                        resName = aliaStr;
                    }
                    address.push( resName );
                } else {
                    address.push( '地址解析失败，重新选择' );
                }
                this.$emit( 'confirm', address, Number( this.rangeRadius ) );
            } );
        },
        /**
         * 搜索规则：
         * 1.使用自动提示api，获取较精确的10个匹配结果，
         * 2.如果选择了10个匹配结果中的某个poi，以该poi为中心，搜索10km范围
         * 3.如果未手动选择任何匹配结果，以第一步查询结果的第一个poi为中心，搜索10km范围
         * */
        // 搜索关键词,自动补全
        completeByInput ( val ) {
            this.realSearchVal = this.searchStr = val;
            this.isCompleteMode = true;
            this.poiFinished = false;
            this.pageIndex = 1;
            const { city } = this.$store.getters.currentAddr;
            // 首先清空,重新初始化list组件，防止无故触发load事件
            this.POIList = [];
            this.$nextTick( () => {
                this.$refs.mapVm.searchAutoComplete( { city,
                    keyword: val,
                    success: ( isOk, res, status ) => {
                    // console.log( 'completeByInput', isOk, res, status );
                        if ( ! isOk ) {
                            this.POIList = [];
                            return;
                        }
                        const { tips } = res;
                        const list = this.handlePoiData( tips );
                        this.completePoiList = this.POIList = list;
                        // 取第一个;
                        this.currentCompletePoi = list[0];
                    } } );
            } );
        },
        searchAmapPOI ( pageIndex ) {
        },
        // 初次搜索，默认搜索,搜索附近一千米
        defaultSearchNearBy () {
            if ( ! window.AMap || ! this.$refs.mapVm || this.basePos.length === 0 ) {
                return;
            }
            _init = true;
            const { city } = this.$store.getters.currentAddr;
            this.pageIndex = 1; // 初始化
            this.isLoadingPoi = false;
            this.poiFinished = false;
            this.isCompleteMode = false;
            this.POIList = [];
            // @@@
            this.isPickerPoi = true;
            this.$refs.mapVm.searchNearBy( {
                keyword  : '',
                center   : this.basePos,
                city     : city || '全国',
                radius   : searchRadius,
                pageSize : this.pageSize,
                pageIndex: this.pageIndex,
                success  : ( isOk, res, status ) => {
                    this.$nextTick( () => {
                        this.isAddressReady = true; // 获取当前地址完成
                    } );
                    let list;
                    if ( ! isOk ) {
                        list = [];
                    } else {
                        const { poiList:{ count, pois } } = res;
                        if ( count <= this.pageSize ) {
                            // 总数少于一页的数量
                            this.poiFinished = true;
                        }
                        list = this.handlePoiData( pois );
                    }
                    if ( list[0] ) {
                        // 默认选中第一个poi
                        this.selectedPOI = list[0];
                    }
                    this.POIList = list;
                }
            } );
        },
        // 搜索附近的poi
        searchNearBy ( {
            keyword = this.realSearchVal,
            center = this.center,
            radius = searchRadius,
            isNoRepeat = false } ) {
            // console.log( 'keyword:', keyword, this.pageIndex );
            this.$refs.mapVm.searchNearBy( {
                keyword,
                center,
                city     : '全国',
                radius,
                pageSize : this.pageSize,
                pageIndex: this.pageIndex,
                success  : ( isOk, res, status ) => {
                    // console.log( 'searchNearBy', isOk, res, status );
                    if ( ! isOk ) {
                        if ( this.isLoadingPoi ) {
                            this.isLoadingPoi = false;
                        }
                        this.POIList = [];
                        return;
                    }
                    const { poiList:{ count, pois } } = res;

                    if ( count <= this.pageSize ) {
                        // 总数少于一页的数量
                        this.poiFinished = true;
                    }
                    let list = this.handlePoiData( pois );
                    if ( this.isLoadingPoi ) { // 加载更多
                        if ( isNoRepeat ) { // 第一次去重
                            list = list.filter( ( item ) => {
                                return this.POIList.findIndex( ( oldItem ) => {
                                    return oldItem.name === item.name;
                                } );
                            } );
                        }
                        this.POIList = [...this.POIList, ...list];
                        if ( this.POIList.length >= count ) {
                            this.poiFinished = true;
                        }
                    } else {
                        this.POIList = list;
                    }
                    this.$nextTick( () => {
                        this.isLoadingPoi  = false;
                    } );
                }
            } );
        },
        handlePoiData ( pois ) {
            const list = pois.map( ( item ) => {
                const { id, address, distance, name, location:{ lat, lng } } = item;
                const obj = {
                    id,
                    address   : typeof address === 'string' && address ? address : name,
                    name,
                    longitude : lng,
                    latitude  : lat,
                    formatName: formatNameToSegments( item.name, this.realSearchVal )
                };
                if ( distance ) {
                    obj.distance = `${distance}米`;
                }
                return obj;
            } );
            return list;
        },
        searchBarClear () {
            this.realSearchVal = this.searchStr = '';
            this.relocate();
        },
        searchBarCancel () {
            this.isShowPopup = false;
            this.realSearchVal = this.searchStr = '';
            this.relocate();
        },
        searchBarFocus () {
            this.isShowPopup = true;
        },
        searchBarblur () {
        },
        selectPOI ( poi ) {
            this.isPickerPoi = true; // 手动选择poi
            this.isShowPopup = false;
            this.currentCompletePoi = poi;
            this.selectedPOI = poi;
            this.searchStr = poi.name;
            this.center = this.poi = [poi.longitude, poi.latitude];
            // 改变当前指针
            this.$refs.mapVm.currNeedleSetCenter( poi.longitude, poi.latitude );
            if (window.isMini) {
              this.$emit('selectPOI', poi)
            }
        },
        popupChange ( isOpen ) {
            ! isOpen && this.$refs.searchBar.blur();
        },
        relocate () {
            this.$refs.mapVm.setMapCenter( this.basePos );
            this.realSearchVal = this.searchStr = '';
            this.center = this.basePos;
            this.defaultSearchNearBy();
        },
        loadMorePoi () {
            if ( ! window.AMap ) {
                // 偶现地图未加载完成，就触发了事件
                this.isLoadingPoi = false;
                return;
            }
            // console.log( 111, this.isCompleteMode, this.pageIndex );
            if ( this.isCompleteMode ) {
                const { longitude, latitude } = this.currentCompletePoi;
                if ( ! longitude || ! latitude ) {
                    this.isLoadingPoi = false;
                    return;
                }
                // 输入模式下的加载更多
                this.searchNearBy( {
                    center    : [longitude, latitude],
                    radius    : bigRadius,
                    isNoRepeat: this.pageIndex === 1 // 提示完后，第一次搜索时候要去重一次
                } );
                this.pageIndex ++;
                return;
            }
            this.pageIndex ++;
            this.searchNearBy( { } );
        },
        /**
         * 根据经纬度，调用高德地图获取所属城市,并保存至store
         */
        getCurrentCity ( lnglat ) {
            this.$refs.mapVm.currDotSetCenter( lnglat[0], lnglat[1] );
            this.$refs.mapVm.getCurrentCity( lnglat, ( curr ) => {
                this.$store.commit( 'setCurrentAddr', curr );
                this.defaultSearchNearBy();
            } );
        },
    },
    watch: {
        isShowPopup ( nv ) {
            if ( ! nv ) {
                this.showFakeBtn = true;
            }
        }
    },
    mounted () {
        if ( this.center === mockLocation ) {
            // 获取当前的经纬度
            this.JSBridge.callHandler( 'geolocation', null, ( loca ) => {
            // '经度,纬度'
                let arr = this.center;// 测试数据
                if ( loca ) {
                    arr = loca.split( ',' ).map( Number );
                }
                this.basePos = this.poi = this.center = arr;
                if ( window.AMap && ! _init && this.$refs.mapVm ) {
                    this.getCurrentCity( arr );
                }
                console.log( '初始位置信息：', arr );
            } );
        }
    },
    beforeDestroy () {
        _init = false;
    },
};
</script>

<style lang="scss" scoped>
  // @import '@/styles/index.scss';
.map-cont{
  width: 100vw;
  height: 68vh;
}
.poi-picker {
  width: 100vw;
  height: 100vh;
  #{&}--header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100vw;
    height: $headerHeight;
    background: linear-gradient(180deg, rgba(247, 246, 249, 1) 0%, rgba(247, 246, 249, 0.59) 56%, rgba(247, 246, 249, 0) 100%);
    padding: $headerMargin + 1 $padding - 2; // @@@这里需要重新计算
    .header-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .confirm-btn {
        height: 9.33vw;
        line-height: unset;
        @include  font($c_FFFFFF, $font_400, $weight_500);
        box-shadow: 0 2.67vw 8vw 0 rgba(98,153,226,0.43);
      }
    }
  }
  .map {
    width: 100%;
    height: 100%;
    ::v-deep .amap-logo, ::v-deep .amap-copyright {
      display: none !important;
    }
  }
  .search-box {
    // height: 15.47vw;
    padding: 0 6.4vw;
    padding-bottom: 5vw;
  }
  .search-result {
    height: 100%;
    display: flex;
    flex-direction: column;
    .poi-list {
      flex-grow: 1;
      padding: 0 6.4vw;
      .poi-item {
        &:last-child {
          border-bottom: none;
        }
        border-bottom: 0.13vw solid $c-line;
      }
    }
    .create-poi-btn {
      flex-shrink: 0;
      height: 13.33vw;
      background-color: $c-pageBg;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-text {
        margin: 0 0 0 2.67vw;
        @include font($c-info, $font_320, $weight_500);
        .highlight {
          color: $c-main;
        }
      }
    }
  }
  .btn-wrap{
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 42.5vh;
    bottom:calc(34vh + 18.2vw);
    width:88vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4vw 6vw;
  }
  .relocate-btn{
    width: 9.33vw;
    height: 9.33vw;
    background: rgba(255,255,255,1);
    box-shadow: 0 2.67vw 4vw 0 rgba(136,141,152,0.27);
    border-radius: 2.67vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .radius{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9vw;
    padding: 0 4vw;
    background:rgba(255,255,255,1);
    box-shadow:0vw 3vw 4vw 0vw rgba(136,141,152,0.27);
    border-radius:3vw;
    font-size:4vw;
    font-weight:500;
    color:rgba(29,34,51,1);
    .pull-down{
        padding-left: 3vw;
    }
  }
  .s-radius__s{
    border: none;
    &::before{
      border: none;
    }
  }
}
</style>
