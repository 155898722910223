<!-- 市调分享活动页 -->
<template>
    <div class="inviteSP">
        <StateBar bgcolor="white" />
        <TitleHeader title="市场调研报告" :back="true" bgcolor="white" />

        <AutoView :footer="false" class="inviteSP--view l-view">
            <div class="l-panel panel">
                <h2>邀请好友注册成功</h2>
                <p><em>立即增加1次市场调研报告查询机会</em>，同时你的好友也将获得一次免费查询机会</p>
                <img src="/static/img/decorate_inviteSP_2.png" class="pic" />
                <van-button type="info" block class="s-radius__s" @click="onTap">立即邀请</van-button>

                <div class="l-panel">
                    <h5>邀请记录</h5>
                    <ul v-if="list && list.length" class="inviteSP--list">
                        <li v-for="( item, index ) of list" :key="index"><van-image class="photo"
                                fit="cover"
                                lazy-load
                                round
                                :src="item.avatar || ''"
                                error-icon="/static/icon/default.png" />
                            {{ item.phone }}
                        </li>
                    </ul>
                    <div v-else class="empty">暂无记录</div>
                </div>
            </div>
        </AutoView>
    </div>
</template>


<script>
export default {
    name: 'inviteSP',
    data () {
        return {
            list: [],
        };
    },
    methods: {
        onTap () {
            if ( ! window.isClient ) {
                this.$toast( '非客户端环境！' );
                return;
            }
            this.JSBridge.callHandler( 'callShare', {
                type: 'normal',
                url : '/#/share/inviteSP',
                data: {},
            }, ( st ) => {
                // console.log( st );
            } );
        },
    },
    created () {
        // 获取邀请列表
        this.HTTP.inviteList( null, 'before', 2 ).then( ( res ) => {
            this.list = res.list;
        } );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.inviteSP {
    height: 100vh;
    background-color: $c-pageBg2;

    #{&}--view {
        .panel {
            padding: 4.8vw;
            background: url( /static/img/decorate_inviteSP.png ) no-repeat right top, linear-gradient( 105deg, #FBFBFD, #E7EAF4 );
            background-size: 50%, 100%;
            box-shadow: inset 0 0 0 1px #EDEDED;

            h2 {
                font-size: 5.07vw;
                line-height: 7vw;
            }
            p {
                margin: 1.33vw 0 8.4vw;
                font-size: $font_320;
                line-height: $font_440;
                color: #676C84;
                em {
                    font-style: normal;
                    color: $c-main;
                }
            }

            .pic {
                display: block;
                width: 60.8vw;
                height: 24vw;
                margin: 0 auto;
            }

            ::v-deep .van-button {
                margin-top: 6.67vw;
                font-size: $font_400;
                font-weight: bold;
            }

            .l-panel {
                margin-top: 4.13vw;
                padding: $padding2;

                h5 {
                    font-size: $font_320;
                    line-height: $font_440;
                    color: $c-info;
                }
                .empty {
                    font-size: $font_320;
                    line-height: $font_440;
                    text-align: center;
                    margin-top: 3.2vw;
                }
            }
        }
    }

    #{&}--list {
        margin-top: 4vw;
        font-size: $font_320;

        .photo {
            position: absolute;
            width: $padding;
            height: $padding;
            left: 0;
            top: 0;
        }
        li {
            position: relative;
            height: $padding;
            line-height: $padding;
            padding-left: 8vw;
            margin-top: 3vw;
            &:after {
                content: '邀请成功';
                position: absolute;
                right: 0;
                top: 0;
                color: $c-notice;
            }
        }
    }
}
</style>
