<!--
Name:
    标签栏
Props:
    option: 标签列表
    value : 默认选项 index|key|text
Events:
    change: 选择触发
Tips:
    最多6项不支持滑动
-->
<template>
    <div class="tabbar" :class="{'tabbar2': option.length > 5}">
        <span v-for="( item ) in option" :key="item.key" class="item" :class="{ on: value == item.key || value == item.text }" @click="onTap( item.key, item.text )">
          {{ item.text }}
          <div class="tab-underline" v-if="value == item.key || value == item.text"></div>
        </span>
    </div>
</template>

<script>
export default {
    name : 'tabbar',
    props: {
        option: {
            type   : [] | {},
            default: [],
        },
        value: Number | String,
    },
    methods: {
        onTap ( key, value ) {
            if ( this.value === key || this.value === value ) return;
            this.$emit( 'change', { key, value } );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

$compHeight: 12vw;

.tabbar {
    @include flex;
    flex-flow: row wrap;
    width: 100%;
    height: $compHeight;
    background-color: white;
    //box-shadow: inset 0 0.5px #EBEAEE;

    &.tabbar2 {
      display: block;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      .item {
        display: inline-block;
        line-height: $compHeight;
        padding: 0 10px;
      }
    }
    .item {
        position: relative;
        @include flexColumn;
        flex: 1;
        height: 100%;
        color: #8E9099;
        font-size: $font_373;
        font-weight: bold;
        @include ani;

        &.on {
            color: $c-main;
            // box-shadow: inset 0 -2px $c-main;
        }
        .tab-underline {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 90%;
          height: 2px;
          border-radius: 1px;
          background-color: $c-main;
          margin-left: 5%;
        }
    }
}
</style>
