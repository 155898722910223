<template>
    <div class="record">
        <StateBar></StateBar>
        <TitleHeader title="地图标注记录" bgcolor="transparent" :back="true"></TitleHeader>
        <AutoView :header="true" :footer="false" class="record--view">
            <van-skeleton :loading="firstLoading" animate :row="4" class="s-skeleton s-radius__s"></van-skeleton>
            <div v-show="!firstLoading">
                <template v-if="list && list.length">
                    <div class="content" v-for="(item, index) in list" :key="index">
                        <div class="title">
                            <div class="left">
                                <img src="/static/icon/map_icon.png" alt="" />
                                地图标注
                            </div>
                            <div class="right" :class="explainMap[item.status].class" >
                                <img v-if="explainMap[item.status].icon" :src="explainMap[item.status].icon" alt="" />
                                {{explainMap[item.status].desc}}
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-item">
                                <div class="label">名称：</div>
                                <div class="name">{{item.placeName}}</div>
                            </div>
                            <div class="card-item">
                                <div class="label">地图平台：</div>
                                <div class="name">{{item.goodsName}}</div>
                            </div>
                            <div class="card-item">
                                <div class="label">提交时间：</div>
                                <div class="name">{{item.gmtCreate}}</div>
                            </div>
                        </div>
                        <van-steps :active="item.status === 3 ? 0 : item.status" active-color="#347FFF">
                            <van-step>待处理</van-step>
                            <van-step>处理中</van-step>
                            <van-step>处理完成</van-step>
                        </van-steps>
                    </div>
                </template>
                <div class="empty" v-else>
                    <img src="/static/img/state_img_nothing.png" alt="">
                    <span>暂无记录</span>
                    <div class="btn" @click="jumpDetail">了解详情</div>
                </div>
            </div>
            <div class="service" @click="serviceHandle">
                <img src="/static/icon/service.png" alt="">
            </div>
        </AutoView>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            firstLoading: true,
            explainMap: {
                0: {
                    icon: "",
                    desc: "",
                    class: ""
                },
                1: {
                    icon: "/static/icon/icon_wait.png",
                    desc: "预计3-7个工作日完成",
                    class: ""
                },
                2: {
                    icon: "/static/icon/icon_ok.png",
                    desc: "可在地图平台搜索查看",
                    class: "success"
                },
                3: {
                    icon: "/static/icon/icon_wrong.png",
                    desc: "无法处理，请联系客服",
                    class: "wrong"
                }
            }
        }
    },
    mounted() {
        this.getMyList()
    },
    beforeMount() {
      // this.JSBridge.callHandler("clearHistory", "my");
    },
    methods: {
        getMyList() {
            this.HTTP.getMyList().then(res => {
                this.list = res.data
                this.firstLoading = false
            })
        },
        serviceHandle() {
            this.JSBridge.callHandler("onNormalizingCall", {
                type: 5,
                url: "ONLINE_SERVICE",
                params: null,
            });
        },
        jumpDetail() {
            this.swRouter("/mapMark/detail")
        }
    }
}
</script>

<style  lang="scss" scoped>
@import "@/styles/index.scss";
.record {
    width: 100vw;
    height: 100vh;
}
.record--view {
    padding: 2.667vw;
    color: $c-info;
    background: #F7F6F9;
    font-size: $font_320;
    position: relative;
}
.content {
    padding: 4vw;
    background: #fff;
    border-radius: $radius-xs;
    margin-bottom: 2.667vw;
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 4.4vw;
        .left {
            color: $c-text;
            font-weight: bold;
            font-size: $font_373;
            display: flex;
            align-items: center;
        }
        .right {
            display: flex;
            align-items: center;
            img {
                margin-right: 1vw;
            }
        }
        .success {
            color: $c-main;
        }
        .wrong {
            color: #FF504E;
        }
        img {
            width: 4.267vw;
            height: 4.267vw;
            margin: 0 1vw 0.5vw 0;
        }
    }
    .card {
        background: #F8F8F9;
        padding: 4vw;
        margin-top: 2vw;
        .card-item {
            line-height: 4.4vw;
            display: flex;
            .label {
                color: $c-text;
            }
        }
    }
}
.empty {
    width: 94.6vw;
    height: 75vw;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font_373;
    font-weight: bold;
    color: $c-text;
    img {
        width: 23.2vw;
        height: 23.2vw;
        margin-top: 8.667vw;
    }
    span {
        margin: 5.733vw 0 9.33vw;
    }
    .btn {
        width: 26.66vw;
        height: 10vw;
        background-color: $c-main;
        color: #fff;
        font-weight: normal;
        font-size: $font_320;
        border-radius: $radius-xs;
        text-align: center;
        line-height: 10vw;
    }
}
.service {
    position: fixed;
    right: 1.33vw;
    bottom: 21.33vw;
    width: 11.733vw;
    height: 11.733vw;
    z-index: 100;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
