<template>
    <div class="data">
        <StateBar></StateBar>
        <TitleHeader title="数据说明" bgcolor="transparent" :back="true" style="border-bottom: 1px solid #E7E7E7;box-sizing: border-box;"></TitleHeader>

        <AutoView :header="true" :footer="false" class="bills--view l-view">
            <van-skeleton :loading="firstLoading"  title :animate="false" :row="3" class="s-skeleton s-radius__s">
                <!-- <EmptyCard v-if="list.length === 0" type="bills"></EmptyCard> -->
                <!-- <template v-else> -->
                    <p class="time">数据更新周期：{{ reportUpdateDate || '2023年5月' }}</p>
                    <div class="data-box" v-for="(val, index) in list" :key="index">
                        <h3 class="title">{{val.title}}</h3>
                        <p class="text" v-html="val.subTitle"></p>
                        <div class="text" v-for="(citem, cIndex) in val.childrent" :key="cIndex">
                            <span></span>
                            <p>{{citem}}</p>
                        </div>
                    </div>
                <!-- </template> -->
            </van-skeleton>
        </AutoView>
    </div>
</template>

<script>
import { formatDate2 } from '@/utils/filters';

export default {
    name: 'dataState',
    data () {
        return {
            firstLoading: false,
            list: [
                {
                    title: "外卖概况",
                    // subTitle: "数据来源于公开的本地生活平台",
                    childrent: [
                        "外卖门店数：查询范围内的外卖店铺总数",
                        // "外卖门店数/餐饮门店数：餐饮门店与外卖门店数量的比值（将外卖门店比例基准设定为1）",
                        "月订单指数：正常营业的外卖门店月售数量",
                        "预估月销售额：门店月售*门店客单价的总和",
                        "单店客单价=所有门店人均总和/门店数",
                        "店均订单量=所有月订单量/门店数",
                        "订单中位数是所有门店的订单数按顺序排列，居于中间位置的数据，用来衡量综合水平",
                        "店均销售额=所有门店月销售额/门店数"
                    ]
                },
                {
                    title: "品类数据",
                    childrent: [
                        "前5品类占比：门店数量前5品类的某品类门店数量 / 排名前5的品类门店数量总和",
                        "各品类数据：门店数、店均订单量、单店客单价",
                    ]
                },
                {
                    title: "门店排行",
                    subTitle: "根据月订单指数降序排列",
                },
                {
                    title: "外卖热力图",
                    subTitle: "根据门店数、月订单指数、客单价绘制热力图<br />为保证使用体验，热力图默认分析3km",
                }
            ],
            reportUpdateDate: ''
        };
    },
    methods: {},

    created () {
      if (this.$route.query.date) {
        this.HTTP.queryReportUpdateDate({
          stdId: 10037,
          gmtCreate: this.$route.query.date
        }).then(res => {
          if (res.data.data && res.data.data.length) {
            const date1 = res.data.data[0].dataTime.replace(/-/g, '/')
            this.reportUpdateDate = formatDate2(new Date(date1), 'yyyy年MM月dd日')
          }
        })
      }
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.data {
    width: 100%;
    height: 100vh;
    background: #fff;
    .time{
        font-size: 2.93vw;
        color: #8E9099;
        line-height: 4vw;
    }
    .data-box{
        width: 100%;
        height: auto;
        .title{
            margin: 2.67vw 0 1.33vw;
            font-size: 4.27vw;
            font-weight: bold;
            color: #333333;
            line-height: 5.87vw;
        }
        .text{
            font-size: 3.47vw;
            color: #666666;
            line-height: 5.87vw;
            margin-bottom: 0.8vw;
            display: flex;
            // align-items: center;
            span {
                width: 2.13vw;
                height: 2.13vw;
                margin-right: 2.13vw;
                border-radius: 50%;
                background: #347FFF;
                position: relative;
                top: 1.9vw;
            }
            p{
                flex: 1;
            }
            &:last-child{
                margin-bottom: 2.93vw;
            }
        }
        &:last-child{
            .text{
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
