<template>
    <div class="partner">
        <StateBar />
        <TitleHeader title="申请提现" bgcolor="transparent" color="white" :back="true">
            <div slot="right" class="partner--rule" @click="swRouter( '/partner/history' )">提现记录</div>
        </TitleHeader>

        <AutoView v-if="!loading" :header="true" :footer="true" class="partner--view">
            <div class="partner--panel">
                <label class="label">提现到：<span>(支持提现至银行卡)</span></label>
                <div class="mode f-vmt" @click="showForm = true">
                    <template v-if="getAccount">
                        <!-- <i class="icon-alipay">银行卡账户</i>
                        <span class="c-info">（{{ getAccount }}）</span> -->
                        {{ getAccount }}
                    </template>
                    <span v-else>请填写收款账户信息</span>
                    <i class="icon-arrow"></i>
                </div>

                <label class="label f-vmt">提现金额：</label>
                <div class="field f-vmt">
                    <input type="number"
                        v-model="form.amount"
                        placeholder="请输入提现金额"
                        maxlength="8"
                        oninput="if(value.length>maxLength)value=value.slice(0,maxLength)"
                        class="input amount" :class="{ on: form.amount || form.amount === 0 }" />
                    <i class="symbol">￥</i>
                    <button class="btn all" @click="setAll">全部</button>
                </div>
                <label class="label gray f-vmt" :class="{ red: isMax }">{{ getBalance }}</label>
                <label class="label gray" style="margin-top: .8vw" v-show="getDiscountBool">实际到账为{{ discount }}元，已自动为您扣缴6%相关税费</label>
            </div>

            <div class="partner--panel f-vmt">
                <label class="label">手机号码：</label>
                <div class="field f-vmt">
                    <input type="tel" v-model="form.phone" readonly placeholder="请输入手机号" maxlength="11" class="input" />
                </div>

                <label class="label f-vmt2">短信验证码：</label>
                <div class="field f-vmt f-vmb">
                    <input type="tel" v-model="form.code" placeholder="请输入验证码" maxlength="6" oninput="if(value.length>maxLength)value=value.slice(0,maxLength)" class="input noLine" />
                    <button :disabled="isSendCode" class="btn code"  @click="onGetCode">{{ isSendCode ? waitingTime + '秒' : '获取验证码' }}</button>
                </div>
            </div>
            <!-- :loading="submitting" -->
            <van-button type="info" block class="s-radius__s f-vmt2" :disabled="disabled"  @click="onTap">提交申请</van-button>
        </AutoView>

        <SoltFooter bgcolor="transparent" class="s-line__t">
            <div class="partner--customBot"><p>提现遇到问题？<router-link to="/feedback">提交反馈</router-link></p></div>
        </SoltFooter>

        <!-- 收款信息 -->
        <van-popup v-model="showForm" position="bottom" round :closeable="!saving" :close-on-click-overlay="false" @click-overlay="onClose">
            <div class="partner--popup">
                <h3>填写收款账户信息</h3>
                <div class="l-panel">
                    <!-- <label class="label">支付宝账号：</label>
                    <div class="field f-vmt">
                        <input type="text" v-model="form.account" placeholder="用于佣金提现收款" maxlength="32" class="input" />
                    </div>
                    <label class="label f-vmt2">真实姓名：</label>
                    <div class="field f-vmt">
                        <input type="text" v-model="form.realname" placeholder="请填写支付宝账户姓名" maxlength="6" class="input noLine" />
                    </div> -->
                    <label class="label">真实姓名：</label>
                    <div class="field f-vmt">
                        <input type="text" v-model="form.realname" placeholder="请填写真实姓名" maxlength="6" class="input noLine" />
                    </div>
                    <label class="label f-vmt2">银行：</label>
                    <div class="field f-vmt">
                        <input type="text" v-model="form.bankName" placeholder="请填写您的收款银行" class="input noLine" />
                    </div>
                    <label class="label f-vmt2">银行支行：</label>
                    <div class="field f-vmt">
                        <input type="text" v-model="form.bankBranchName" placeholder="(选填)请填写您的收款银行支行" class="input noLine" />
                    </div>
                    <label class="label f-vmt2">卡号：</label>
                    <div class="field f-vmt">
                        <input type="tel" v-model="form.bankAccount" placeholder="请填写您的银行卡号" maxlength="19" class="input noLine" />
                    </div>


                </div>
                <SoltFooter bgcolor="transparent" class="btnView">
                    <van-button type="info" block class="s-radius__s" :loading="saving" @click="onSubmit">保存</van-button>
                </SoltFooter>
            </div>
        </van-popup>

        <!-- 全局加载遮罩 :show="submitting"-->
        <LoadingOverlay :showView="false" />
    </div>
</template>

<script>
const BASE_NUM = 60; // 默认等待时间
export default {
    name: 'partner',
    data () {
        return {
            loading   : true,
            submitting: false,
            saving    : false,
            disabled  : true,

            isSendCode : false,
            timer      : 0,
            waitingTime: BASE_NUM,

            showForm: false,

            already: false, // 已是合伙人
            entity : null, // 原始数据

            form: {
                amount  : null,
                phone   : null,
                code    : null,
                account : null,
                bankName: null,
                bankBranchName: null,
                bankAccount: null,
                realname: null,
            },
        };
    },
    methods: {
        onTap () {
            // if ( this.submitting ) return;
            // this.submitting = true;
            this.HTTP.createCashout( this.form.amount, this.form.code ).then( ( res ) => {
                this.$toast( '已收到申请，我们会尽快审核！' );
                // 清理缓存
                this.$store.commit( 'setPartnerInfo', null );
                setTimeout( () => {
                    this.swRouter( '/partner/history' );
                }, 2000 );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'msg' ) ) {
                    this.$toast( e.msg || '未知错误' );
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
        onSubmit () {
            // 校验
            // if ( ! /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{1,5}$/.test( this.form.account ) && ! /^1\d{10}$/.test( this.form.account ) ) {
            //     this.$toast( '请输入正确的收款账号！' );
            //     return;
            // }
            // if ( ! /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/.test( this.form.account ) ) {
            //     this.$toast( '请输入正确的收款账号！' );
            //     return;
            // } else
            if ( ! this.form.realname || this.form.realname.trim().length < 2 ) {
                this.$toast( '请输入真实姓名！' );
                return;
            } else if(!this.form.bankName) {
                this.$toast( '请输入收款银行' );
                return;
            } else if(!this.form.bankAccount || ! /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/.test(this.form.bankAccount) ) {
                this.$toast( '请输入正确的银行卡号' );
                return;
            } else if (this.form.bankBranchName && this.form.bankBranchName.length >= 32) {
              this.$toast( '请输入正确的银行支行名称，如：某某银行支行' );
              return;
            }
            // 提交
            if ( this.saving ) return;
            this.saving = true;
            let params = {
                alipayAccount: null,
                alipayName   : this.form.realname,
                bankName     : this.form.bankName,
                bankBranchName: this.form.bankBranchName,
                bankAccount: this.form.bankAccount
            }
            this.HTTP.savePartnerInfo( params ).then( ( res ) => {
                this.saving = false;
                this.showForm = false;
                this.entity.bankName = this.form.bankName;
                this.entity.bankAccount = this.form.bankAccount;
                this.validate();
                this.$toast( '保存成功！' );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'msg' ) ) {
                    this.$toast( e.msg || '未知错误' );
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
                setTimeout( () => {
                    this.saving = false;
                }, 2000 );
            } );
        },
        onClose () {
            if ( this.saving ) return;
            this.showForm = false;
        },
        onGetCode () {
            if ( this.isSendCode ) return;
            this.HTTP.authCode().then( ( res ) => {
                this.isSendCode = true;
                this.$toast( '验证码发送成功' );
                clearInterval( this.timer );
                this.timer = setInterval( () => {
                    if ( this.waitingTime === 1 ) {
                        clearInterval( this.timer );
                        this.isSendCode  = false;
                        this.waitingTime = BASE_NUM;
                    } else {
                        this.waitingTime --;
                    }
                }, 1000 );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    this.$toast( e.msg );
                } else {
                    this.$toast( '网络不稳定，请稍候重试！' );
                }
                setTimeout( () => { this.isSendCode = false; }, 2000 );
            } );
        },
        setAll () {
            if ( ! this.entity.commissionDetail || ! this.entity.commissionDetail.availableCommission || this.entity.commissionDetail.availableCommission === '0.00' ) this.$toast( '无可提现金额！' );
            this.$set( this.form, 'amount', this.entity ? this.entity.commissionDetail.availableCommission : 0 );
        },
        validate () {
            // console.log( 'enter validate' );
            this.disabled = true;
            if ( ! this.getAccount ) {
                // console.log( 'validate 1' );
                console.log( 'getAccount:', this.getAccount );
                return false;
            }
            if ( ! this.form.code || this.form.code.length !== 6 ) {
                // console.log( 'validate 2' );
                return false;
            }
            if ( ! this.form.amount ) {
                // console.log( 'validate 3' );
                return false;
            } else if ( ! /^\d+(.\d{1,2})?$/.test( this.form.amount ) ) {
                this.$toast( '请输入正确的金额！' );
                // console.log( 'validate 4' );
                return false;
            } else if ( /^0{1,6}(.0{1,6})?$/.test( this.form.amount ) ) {
                // console.log( 'validate 5' );
                return false;
            } else if ( this.form.amount < this.entity.minWithdrawalAmount ) {
                // console.log( 'validate 6' );
                return false;
            } else if ( this.isMax ) {
                // console.log( 'validate 7' );
                return false;
            }
            // console.log( 'enter validate success' );
            this.disabled = false;
            return true;
        },
        formatAccount ( str ) {
            const len = str.length;
            // if ( len >= 11 ) {
                str = `${str.substr( 0, 4 )}****${str.substr( - 4 )}`;
            // } else {
            //     const size = Math.floor( len / 3 );
            //     str = `${str.substr( 0, size )}****${str.substr( - size )}`;
            // }
            return str;
        },
    },
    computed: {
        discount() {
            if(!this.form.amount) {
                return null;
            } else {
                if(this.form.amount >= this.entity.minWithdrawalAmount) {
                    if(this.form.amount <= this.entity.commissionDetail.availableCommission) {
                        const finalPrice = (this.form.amount) / (1 + 0.06);
                        return Math.round(finalPrice).toFixed(2);
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        },
        getDiscountBool() {
            if(!this.form.amount) {
                return false;
            } else {
                if(this.form.amount >= this.entity.minWithdrawalAmount) {
                    if(this.form.amount <= this.entity.commissionDetail.availableCommission) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },
        getAccount () {
            // return this.entity.alipayName ? this.entity.alipayName  : false;
            var bankAccount = this.entity.bankAccount ? this.formatAccount( this.entity.bankAccount ) : false;
            var bankName = this.entity.bankName ? this.entity.bankName : false;
            if(bankAccount && bankName) {
                return bankName+"("+bankAccount+")"
            } else {
                return false;
            }
        },
        getBalance () {
            if ( this.isMax ) {
                return '超出可提现金额';
            }
            const availableCommission = this.entity.commissionDetail.availableCommission || 0;
            // if ( availableCommission < 500 && availableCommission > 0 ) {
            //     return '未达到最低提现金额标准（500元）';
            // }
            return `余额 ${this.entity.commissionDetail.availableCommission || 0}元`;
        },
        isMax () {
            return this.entity && ~ ~ this.form.amount > ~ ~ this.entity.commissionDetail.availableCommission;
        },
    },
    watch: {
        entity ( res ) {
            this.loading = false;
            this.form.phone    = res.phone;
            this.form.account  = res.alipayAccount || null;
            this.form.realname = res.alipayName || null;
            this.form.bankName = res.bankName || null;
            this.form.bankBranchName = res.bankBranchName || null;
            this.form.bankAccount = res.bankAccount || null;
            if ( res.commissionDetail ) {
                if ( typeof ( res.commissionDetail.availableCommission ) == 'string' ) {
                    res.commissionDetail.availableCommission = parseFloat( res.commissionDetail.availableCommission );
                }
            }

        },
        form: {
            deep: true,
            handler ( form ) {
                this.validate();
            },
        },
    },
    created () {
        // 查询合伙人信息
        const res = this.$store.getters.partnerInfo;
        if ( res ) {
            this.entity = res;
        } else {
            this.HTTP.getPartnerInfo().then( ( res ) => {
                this.entity = res;
                console.log( 'entity:', res );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
            } );
        }
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'white' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.partner {
    height: 100vh;
    // background: linear-gradient(to bottom, #FD8752 35%, $c-pageBg 55%);
    background: $c-pageBg url(/static/img/bg_partner.jpg) no-repeat left top;
    background-size: 100% auto;

    #{&}--rule {
        color: white;
    }

    #{&}--view {
        padding: $padding;
        // background: url( /static/img/partner.png ) no-repeat left $padding;
        background-size: contain;

        ::v-deep .van-button {
            font-size: $font_400;
            line-height: normal;
        }
    }

    #{&}--panel {
        padding: $margin $padding;
        background-color: white;
        border-radius: $radius_s;
    }

    #{&}--customBot {
        font-weight: bold;
        font-size: $font_320;
        color: $c-info;

        p {
            display: inline-block;
            line-height: $font_440;
        }
        a {
            color: $c-main;
        }
    }

    #{&}--popup {
        background-color: $c-pageBg2;

        h3 {
            padding: $padding2 $padding;
            font-size: $font_400;
            line-height: $font_560;
            text-align: center;
        }
        .l-panel {
            margin: 1.33vw $padding 4.3vw;
            // margin: $margin $padding;
            padding: $padding2 $font_533;
        }
        .btnView {
            position: static;
            box-sizing: border-box;
            padding: $headerMargin $padding;
            ::v-deep .soltFooter--view {
                height: 10.67vw;
            }
            ::v-deep .van-col {
                height: 100%;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            font-weight: bold;
            line-height: normal;
        }
    }

    .label {
        @include flex;
        font-size: $font_320;
        line-height: $font_440;
        height: $font_440;

        span {
            color: $c-info;
        }
        &.gray {
            color: $c-info;
        }
        &.red {
            color: $c-price;
        }
    }
    .mode {
        @include flex;
        font-size: $font_400;
        height: 13.34vw;
        background-color: #FFF6F4;
        box-shadow: $padding 0 0 #FFF6F4, -$padding 0 0 #FFF6F4;
        color: #676C84;
        .c-info {
            color: $c-info;
            // padding-right: $padding;
        }
        .icon-alipay {
            @include flexColumn;
            width: auto;
            height: 6.67vw;
            padding-left: 8.27vw;
            font-style: normal;
            color: $c-text;
            background: url(/static/icon/alipay.png) no-repeat left center;
            background-size: 6.67vw 6.67vw;
        }
        .icon-arrow {
            width: 4vw;
            height: 4vw;
            background: url(/static/icon/arrow_right_gray.png) no-repeat;
            background-size: cover;
        }
    }
    .field {
        position: relative;
        height: 10vw;

        .input {
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: inset 0 -1px 0 0 $c-line;
            font-size: $font_400;
            font-weight: bold;
            padding: 0;
            margin: 0;
            box-sizing: border-box;

            &::-webkit-input-placeholder {
                font-weight: normal;
            }
            &.noLine {
                box-shadow: none;
            }
            &.amount {
                padding-left: 8vw;
                &.on {
                    font-size: 8.81vw;
                }
            }
        }
        .btn {
            width: 25vw;
            height: 100%;
            color: $c-main;
            border: none;
            padding: 0;
            background-color: transparent;
            box-shadow: inset 0 0 0 1px $c-main;
            border-radius: $radius_s;
            font-size: 3.34vw;
            font-weight: bold;

            &:disabled {
                background-color: $c-gray;
                box-shadow: none;
                color: white;
            }

            &.code, &.all {
                position: absolute;
                top: 0;
                right: 0;
            }
            &.all {
                box-shadow: none;
                font-weight: normal;
                text-align: right;
                padding: 0;
                font-size: $font_347;
            }
        }
        .symbol {
            @include flexColumn;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            font-size: 8.81vw;
            font-style: normal;
            text-indent: -1.5vw;
        }
    }
}
</style>
