<template>
    <div class="bills">
        <StateBar></StateBar>
        <TitleHeader title="选择开票订单" bgcolor="transparent" :back="true">
            <div slot="right" @click="swRouter( '/billHistory' )">开票记录</div>
        </TitleHeader>

        <AutoView :header="true" :footer="true" class="bills--view l-view">
            <van-skeleton :loading="firstLoading" title :animate="false" :row="2" class="s-skeleton s-radius__s">
                <EmptyCard v-if="list.length === 0" type="bills"></EmptyCard>
                <template v-else>
                    <van-list v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="loadData" >
                        <div v-for="( data, key ) in showList" :key="key">
                            <h3 class="s-subtitle">{{ key }}</h3>
                            <div class="bills--panel l-panel">
                                <div v-for="( item, index ) of data" :key="index" class="item" :class="{ noSpace: index === data.length - 1 }" @click="onSelected( item )">
                                    <h3 class="f-tof">{{ item | title }}</h3>
                                    <p class="f-tof">{{ item.placeTime | convDate }}</p>
                                    <span class="f-tof">￥{{ item.payAmount }}</span>
                                    <i :class="{ checked: select.hasOwnProperty( item.orderNo ) }"></i>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </template>
            </van-skeleton>
        </AutoView>

        <SoltFooter v-show="list.length" class="bills--footerBar">
            <van-row class="view">
                <van-col span="6" class="left" @click="selectAllOrder">
                    <p class="label">全选</p>
                    <i :class="{ checked: selectAll }"></i>
                </van-col>
                <van-col span="7" class="order">
                    <em>{{ Object.keys( select ).length }}</em>个订单
                </van-col>
                <van-col span="11">
                    <van-button type="info" block @click="onTap" class="s-radius__s">共{{ getAmount | toFixed }}元</van-button>
                </van-col>
            </van-row>
        </SoltFooter>
    </div>
</template>

<script>
export default {
    name: 'bills',
    data () {
        return {
            firstLoading: true,
            loading     : true,
            dataLoading : false,
            finished    : false,
            selectAll   : false,
            page        : 1,
            total       : 0,
            list        : [],
            showList    : {},
            select      : {},
        };
    },
    filters: {
        title ( item ) {
            // 11 周边客群报告 9 品牌报告
            if ( item.type === 10 ) {
                return '购买数据查询服务';
            } else if ( item.type === 8 ) {
                return '购买店铺监测服务';
            } else if ( item.type === 7 ) {
                return '购买套餐包';
            } else if ( item.type === 6 ) {
                return '数据导出';
            } else if ( item.type === 5 ) {
                return '工具计算';
            } else if ( item.type === 4 ) {
                return '购买尊享包';
            } else if ( item.type === 3 ) {
                return '购买会员';
            } else if ( item.type === 2 ) {
                return '购买上上贝';
            }else if ( item.type === 13 ) {
                return '购买课程';
            }else if ( item.type === 14 ) {
                return '加盟入驻';
            } else {
                const payType = {
                    ALIPAY  : '支付宝',
                    WX      : '微信',
                    UNIONPAY: '银联',
                    COUPON  : '优惠券',
                    APPLE   : '苹果',
                    SSCM    : '上上贝',
                };
                return '解锁报告（' + ( item.payType ? payType[ item.payType ] : '未知' ) + '支付）';
            }
        },
        convDate ( value ) {
            const sp1 = value.split( ' ' );
            return sp1[ 0 ].split( '-' ).join( '.' ) + ' ' + sp1[ 1 ].substr( 0, 5 );
        },
    },
    methods: {
        loadData () {
            this.loading = true;
            this.HTTP.billList( this.page ).then( ( res ) => {
                this.firstLoading = false;
                this.loading = false;
                this.page ++;
                this.total = res.total;
                if ( this.list.length ) {
                    this.list = this.list.concat( res.data );
                } else {
                    this.list = res.data;
                }
                if ( res.data.length < res.size || this.list.length >= this.total ) {
                    this.finished = true; // 完成加载
                }
                // 数据分组
                for ( const item of res.data ) {
                    const yms = item.placeTime.split( '-' );
                    const key = `${yms[0]}年${yms[1]}月`;
                    if ( ! this.showList.hasOwnProperty( key ) ) {
                        this.showList[ key ] = [];
                    }
                    this.showList[ key ].push( item );
                }
                // console.log( this.list );
            } ).catch( ( e ) => {
                this.$toast( '网络连接不稳定，请稍候重试！' );
                this.loading = false;
            } );
        },
        onSelected ( item ) {
            if ( this.select.hasOwnProperty( item.orderNo ) ) {
                this.$delete( this.select, item.orderNo );
            } else {
                this.$set( this.select, item.orderNo, item.payAmount );
            }
            this.selectAll = Object.keys(this.select).length === this.list.length
        },
        onTap () {
            const ids = [];
            let sum   = 0;
            for ( const key in this.select ) {
                ids.push( key );
                sum += parseFloat( this.select[ key ] );
            }
            if ( ! ids.length ) {
                this.$toast( '请选择需要开票的订单！' );
                return;
            }
            this.swRouter({
                path : '/invoice',
                query: {
                    orderNo: ids.join( '-' ),
                    amount : sum,
                },
            } )
            // this.$router.push( {
            //     path : '/invoice',
            //     query: {
            //         orderNo: ids.join( '-' ),
            //         amount : sum,
            //     },
            // } );
        },
        selectAllOrder() {
          this.selectAll = !this.selectAll
          if ( ! this.selectAll ) {
            this.select = {};
          } else {
            const select = {};
            for ( const item of this.list ) {
              select[ item.orderNo ] = item.payAmount;
            }
            this.select = select;
          }
        }
    },
    watch: {},
    computed: {
        getAmount () {
            let sum = 0;
            for ( const key in this.select ) {
                sum += parseFloat( this.select[ key ] );
            }
            return sum;
        },
    },
    created () {
        this.loadData();
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.bills {
    height: 100vh;
    background-color: $c-pageBg;

    #{&}--view {
        .s-subtitle {
            color: $c-info;
            font-size: $font_347;
        }
        .l-panel {
            padding: 5.33vw;
        }
    }

    #{&}--panel {
        margin-bottom: $padding2;

        .item {
            position: relative;
            padding-left: 10vw;
            padding-bottom: 4.53vw;
            margin-bottom: 5.2vw;
            box-shadow: inset 10vw 0 white, inset 0 -1px $c-line;

            &.noSpace {
                padding-bottom: 0;
                margin-bottom: 0;
                box-shadow: none;
            }

            h3 {
                font-size: $font_400;
                line-height: $font_560;
            }
            p {
                color: $c-info;
                font-size: $font_293;
                line-height: $font_400;
                margin-top: 1.6vw;
            }
            span {
                position: absolute;
                top: $margin;
                right: 0;
                color: $c-price;
                font-size: $font_400;
                font-weight: bold;
            }
            i {
                @extend .s-ani;
                position: absolute;
                top: $margin;
                left: 0;
                width: 5.33vw;
                height: 5.33vw;
                background-color: $c-pageBg;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 50%;
                background-size: 3.73vw;
                box-shadow: 0 0 0 1px $c-line;

                &.checked {
                    background-image: url(/static/icon/selected.png);
                    background-color: $c-main;
                    box-shadow: none;
                }
            }
        }
    }

    #{&}--footerBar {
        .view {
            box-sizing: border-box;
            padding: $headerMargin $padding;
            width: 100%;
            height: $footerHeight;
            ::v-deep .van-col {
                height: 100%;
            }
        }
        .left {
            position: relative;
            .label {
                position: absolute;
                top: 3.75vw;
                left: 10vw;
                color: $c-info;
                font-size: $font_373;
            }
            i {
                @extend .s-ani;
                position: absolute;
                top: 2.75vw;
                left: 0;
                width: 5.33vw;
                height: 5.33vw;
                background-color: $c-pageBg;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 3.73vw;
                border-radius: 50%;
                box-shadow: 0 0 0 1px $c-line;

                &.checked {
                    background-image: url(/static/icon/selected.png);
                    background-color: $c-main;
                    box-shadow: none;
                }
            }
        }
        .order {
            margin-top: 3.5vw;
            font-size: $font_373;
            text-align: right;
            padding-right: $padding2;
            em {
                color: $c-main;
                font-style: normal;
            }
        }
        ::v-deep .van-button {
            height: 100%;
            font-size: $font_400;
            line-height: normal;
            padding-right: 9.33vw;
            background-image: url(/static/icon/arrow_right.png);
            background-repeat: no-repeat;
            background-position: right 3.47vw center;
            background-size: 4vw;
        }
    }
}
</style>
