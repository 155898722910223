<template>
    <div class="vip">
        <StateBar></StateBar>
        <TitleHeader title="VIP会员" bgcolor="transparent" :back="true">
            <!-- <div slot="right" class="detail" @click="$router.push( '/vipHistory' )">明细</div> -->
        </TitleHeader>

        <!-- 骨架屏占位图 -->
        <img v-if="loading" src="/static/skt/vip.png" width="100%" />

        <AutoView v-show="!loading" :header="true" :footer="false" class="vip--view l-view">
            <div class="vip--card s-radius" :class="{ vip: isVip }">
                <van-image class="photo"
                    fit="cover"
                    lazy-load
                    round
                    :src="info.photo"
                    error-icon="/static/icon/default.png">
                    <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                    </template>
                </van-image>
                <h4>{{ info.nickname }}</h4>
                <p>{{ isVip && info.member ? `${ info.member.endTime }到期` : '尚未开通' }}</p>
                <a href="javascript:;" class="btn" @click="onTapRenew">立即续费</a>

                <template v-if="info.member">
                    <template v-if="! isAndroid">
                        <p class="info">会员福利（上上贝）</p>
                        <h3>{{ info.member.waitTakeMemberCoins && info.member.waitTakeMemberCoins > 0 ? info.member.waitTakeMemberCoins : info.member.takeMemberCoins }}</h3>
                        <hr />
                        <a href="javascript:;" class="btnGet" :class="{ on: isVip && info.member.waitTakeMemberCoins > 0 }" @click="onTap">{{ btnText }}</a>
                    </template>
                    <p class="info right" :class="{ sp: isAndroid }">累积节省（元）</p>
                    <h3 class="right" :class="{ sp: isAndroid }">{{ info.member.totalDiscountAmount }}</h3>
                </template>
            </div>

            <div v-if="info.member" class="vip--feature">
                <h3 class="s-subtitle">VIP专属特权</h3>
                <van-divider />
                <van-image
                    fit="cover"
                    lazy-load
                    :src="info.member.descLink">
                    <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                    </template>
                </van-image>
            </div>
        </AutoView>
    </div>
</template>

<script>
export default {
    name: 'vip',
    data () {
        return {
            loading   : true,
            isAndroid : false,
            submitting: false,
            res       : null, // 原始数据
            isVip     : false, // 是否会员
            isExpired : false, // 是否过期
            info      : {
                nickname: '',
                photo   : '',
                member  : null, // 会员信息
            },
        };
    },
    methods: {
        onTap () {
            if ( this.submitting || ! this.isVip || ! this.info.member || ! ( this.info.member.waitTakeMemberCoins > 0 ) ) return;
            this.submitting = true;
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.VIP.TAP_TAKE );
            this.HTTP.takeCoins().then( ( res ) => {
                this.submitting = false;
                this.info.member.takeMemberCoins = ( parseFloat( this.info.member.takeMemberCoins ) + parseFloat( this.info.member.waitTakeMemberCoins ) ).toFixed( 2 );
                this.info.member.waitTakeMemberCoins = null;
                this.$toast( '领取成功！' );
            } ).catch( ( e ) => {
                if ( typeof e === 'object' && e.hasOwnProperty( 'code' ) ) {
                    if ( e.code === 111001 ) {
                        this.$toast( '您的会员已过期！' );
                        this.isVip     = false;
                        this.isExpired = true;
                    }
                } else {
                    this.$toast( '网络连接不稳定，请稍候重试！' );
                }
                setTimeout( () => {
                    this.submitting = false;
                }, 2000 );
            } );
        },
        onTapRenew () {
            // 埋点上报
            this.JSBridge.report( this.JSBridge.REPORT_EVENT.VIP.TAP_RENEW );
            this.$router.push( { name: 'vipBuy', params: this.res } );
        },
    },
    computed: {
        btnText () {
            if ( this.isExpired ) {
                return '已过期';
            } else if ( this.isVip && this.info.member.waitTakeMemberCoins > 0 ) {
                return '去领取 ›';
            } else {
                return '已领取';
            }
        },
    },
    created () {
        // 查询会员信息
        this.HTTP.getVipInfo().then( ( res ) => {
            const isVip = res.userDetail ? res.userDetail.member : false;
            if ( ! isVip ) {
                this.$router.replace( { name: 'vipBuy', params: res } );
            }
            this.loading       = false;
            this.res           = res;
            this.isVip         = isVip;
            this.info.nickname = res.userDetail ? res.userDetail.username : null;
            this.info.photo    = res.userDetail ? res.userDetail.avatar : '';
            this.info.member   = res.userDetail ? res.userDetail.memberInfo : null;
        } ).catch( ( e ) => {
            this.$toast( '网络连接不稳定，请稍候重试！' );
            this.loading = false;
        } );
    },
    beforeMount () {
        this.JSBridge.callHandler( 'switchNavbar', false );
        this.JSBridge.callHandler( 'setStatebarTheme', 'black' );

        this.isAndroid = window.isAndroid || false;
    },
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';

.vip {
    height: 100vh;
    background-color: $c-pageBg2;

    .detail {
        color: $c-main;
    }

    #{&}--card {
        position: relative;;
        background: url(/static/img/vip_card.jpg) no-repeat left top;
        background-size: 100%;
        width: 87.2vw;
        height: 49.6vw;
        color: white;
        text-align: center;

        .photo {
            position: absolute;
            top: 9.2vw;
            left: 7.47vw;
            width: 8vw;
            height: 8vw;
            box-shadow: 0 0 0 2px white;

            ::v-deep .van-icon__image {
                width: 100%;
                height: 100%;
            }
        }

        h3 {
            position: absolute;
            bottom: 3.2vw;
            left: 6.26vw;
            font-size: 6.13vw;
            line-height: 8.66vw;
            &.right {
                left: 52.8vw;
            }
            &.sp {
                left: 0vw;
                width: 100%;
                text-align: center;
            }
        }
        h4 {
            position: absolute;
            top: 8.26vw;
            left: 18.53vw;
            font-size: $font_400;
            line-height: $font_560;
            font-weight: bold;
        }
        p {
            position: absolute;
            top: 14vw;
            left: 18.53vw;
            font-size: $font_320;
            line-height: $font_440;
            color: $c-gray2;
        }
        hr {
            position: absolute;
            bottom: 4.13vw;
            left: 48.26vw;
            width: 1px;
            height: 11.73vw;
            background-color: rgba(white, .11);
            border: none;
            margin: 0;
        }

        .info {
            position: absolute;
            top: 33.33vw;
            left: 6.26vw;
            &.right {
                left: 52.8vw;
            }
            &.sp {
                left: 0vw;
                width: 100%;
                text-align: center;
            }
        }
        .btn {
            @include flexColumn;
            position: absolute;
            top: 9.19vw;
            right: 0;
            width: 23.6vw;
            height: 8vw;
            background-color: #555F6C;
            color: #F9C27F;
            font-size: $font_320;
            border-radius: 5vw 0 0 5vw;
            text-indent: 3vw;
        }
        .btnGet {
            @include flexColumn;
            position: absolute;
            top: 40vw;
            left: 30.35vw;
            width: 15.2vw;
            height: 5.06vw;
            font-size: $font_293;
            // line-height: 5.06vw;
            font-weight: bold;
            border-radius: $radius-xs;
            color: $c-info;
            text-align: center;
            background-color: rgba(black, .2);

            &.on {
                color: #8D5D22;
                background: linear-gradient( to bottom,rgba(244,182,106,1),rgba(252,202,141,1) );
            }
        }

        &.vip {
            .photo {
                box-shadow: 0 0 0 2px $c-vip2;
            }
            &:after {
                content: '';
                position: absolute;
                width: 3.73vw;
                height: 3.73vw;
                top: 14.4vw;
                left: 12.13vw;
                background: url(/static/icon/vip.png) no-repeat;
                background-size: 100%;
            }
        }
    }

    #{&}--feature {
        position: absolute;
        width: 100%;
        left: 0;
        background-color: white;
        padding: $padding;
        padding-bottom: $padding * 2;
        box-sizing: border-box;
        margin-top: $margin;

        .s-subtitle {
            margin: 0;
        }

        .van-divider {
            margin: 5.2vw 0;
        }
    }
}
</style>
