<template>
    <div class="bar-wrap" v-if="showBar">
        <div class="icon-wrap" @click="hideBar">
            <img class="bar-icon" src="/static/icon/icon_close@2x.png" />
        </div>
        <span class="bar-label" @click="jumpTo">{{title}}</span>
        <span class="bar-jump" @click="jumpTo">去开启</span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type   : String,
            default: '开启通知，第一时间获取店铺最新动态'
        },
        jumpTitle: {
            type   : String,
            default: '去开启'
        },
        jumpUrl: {
            type    : String,
            required: true
        },
        show: {
            type   : Boolean,
            default: false
        }
    },
    watch: {
        show ( nv, ov ) {
            this.showBar = nv;
        }
    },
    data () {
        return {
            showBar: false
        };
    },
    mounted () {
        // console.log( 'show:', this.show, ', showBar:', this.showBar );
        this.showBar = this.show;
    },
    methods: {
        jumpTo () {
            // console.log( 'jump to url:', this.jumpUrl );
            this.JSBridge.callHandler( 'enableNotification', undefined );
        },
        hideBar () {
            this.showBar = false;
        }
    }
};
</script>

<style lang="scss" scoped>
// @import '@/styles/index.scss';
.bar-wrap {
    position: relative;
    background-color: rgba(245, 137, 88, 0.1);
    display: flex;
    align-items: center;
    z-index: 5;
    padding: 2vw 4vw;
    .icon-wrap {
        cursor: pointer;
        padding: 1.5vw;
        display: flex;
        align-items: center;
        .bar-icon {
            width: 2.1vw;
            height: 2.1vw;
        }
    }
    .bar-label {
        position: relative;
        color: #F58958;
        font-size: 3.2vw;
        line-height: 4.5vw;
        text-align: left;
        margin-left: 2.7vw;
    }
    .bar-jump {
        position: absolute;
        cursor: pointer;
        right: 4vw;
        // background-color: #F58958;
        background: linear-gradient(90deg, #FF8000 0%, #FF5900 100%);
        border-radius: $radius-s;
        color: #ffffff;
        font-size: 2.9vw;
        line-height: 4vw;
        padding: 0.8vw 2.1vw;
    }
}
</style>
