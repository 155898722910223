<template>
  <div class="search">
    <StateBar />
    <SearchBar ref="compSearchBar" 
      :back="true" 
      :placeholder="placeholder" 
      :on="false" 
      @change="change" 
      @entry="search"  
      :inputContent="keyword"
      @clear="clear">
      <span slot="right" class="right-title" @click="search">搜索</span>
    </SearchBar>

    <!-- 骨架屏占位图 -->
    <van-skeleton :loading="loading" title :animate="false" :row="5" class="s-skeleton" />

    <AutoView ref="view" :header="true" :footer="false" class="search--view" :style="{ opacity: loading ? 0 : 1 }">
      <div class="search--main">
        <!-- v-if="!this.keyword" -->
        <template v-if="!searckFlag">
          <div class="data_list">
            <EmptyCard v-if="list.length == 0 && !isFlag" type="search"></EmptyCard>
            <template v-else>
              <van-list 
                v-model="cardLoading" 
                class="brand-wrap" 
                :finished="finished" 
                :immediate-check="false"
                finished-text="没有更多了" @load="searchLoading">
                <BrandCard
                  v-for="(item, index) of list"
                  :key="index"
                  :item="item"
                  @click="onTap"
                  class="itemClass"
                  ref="brandItemRef"/>
                </van-list>
                
            </template>
          </div>
        </template>

        <template v-else>
          <div style="padding: 0 4vw">
            <template v-if="historyList && historyList.length > 0">
              <ul class="history-flex">
                <li class="left">历史记录</li>
                <li class="right" @click="deleteHistory">
                  <img src="/static/icon/delete_icon.png" alt="" />
                </li>
              </ul>
              <div class="tag-wrap" ref="tagContent">
                <van-tag
                  class="tag"
                  round
                  type="primary"
                  size="large"
                  color="#f7f7f7"
                  text-color="#333"
                  v-for="item in historyList"
                  :key="item.key"
                  @click="searchBy(item)"
                >
                  {{ item }}
                </van-tag>
              </div>
            </template>
            <div class="hot-ranking" v-if="hotSearchBrandList && hotSearchBrandList.length">
              <h4>热度排名</h4>
              <div class="ranking-wrap">
                <HotRanking v-for="(item, index) in hotSearchBrandList" :key="item.key" :item="item" :idx="index" :showLine="index !== 0" />
              </div>
            </div>
          </div>
        </template>
        <!-- <template v-else>
          <template v-if="listData && listData.length">
            <ul class="search-list" v-for="item in listData" :key="item.key" @click="searchByList(item.brandName)">
              <li class="left">
                <img src="/static/icon/search_icon.png" alt="" />
              </li>
              <li class="right" v-html="highlight(item.brandName)"></li>
            </ul>
          </template>
          <ul class="search-list" @click="search" v-else>
            <li class="left">
              <img src="/static/icon/search_icon.png" alt="" />
            </li>
            <li class="right">
              查找<span style="color: #347fff">"{{ keyword }}"</span>
            </li>
          </ul>
        </template> -->
      </div>
    </AutoView>
  </div>
</template>

<script>
import HotRanking from "./components/hotRanking.vue";
import SearchBar from "@/components/header/search";
import { setLocalStorage, getLocalStorage, removeLocalStorage, debounce } from "@/utils/index";
import BrandCard from "./components/card";
export default {
  components: { HotRanking, SearchBar,BrandCard },
  data() {
    return {
      placeholder: "请输入品牌名称",
      loading: false,
      keyword: "",
      historyList: [], // 搜索记录
      listData: [], // 联想列表
      runSearch: null,
      hotSearchBrandList: [],
      form: {
        brandName: null,
        recommend: 1,
        size: 6,
        current: 1,
      },
      list: [],
      finished: false,
      cardLoading: true,
      total: 0,
      searckFlag: true,
      isFlag: false,
    };
  },
  created() {
    if (getLocalStorage("searchHistory")) {
      this.historyList = getLocalStorage("searchHistory");
    }
    this.loadDtat();
  },
  methods: {
    searchBy(val) {
      this.keyword = val;
      this.search();
    },
    onTap(item) {
      setLocalStorage("versionCode", window._appCode);
      this.brandCode = item.brandCode;
      this.swRouter("/brand/details/" + item.brandCode);
    },
    highlight(val) {
      let keyword = this.keyword;
      if (val.indexOf(keyword) !== -1) {
        return val.replace(keyword, `<font color='#347FFF'>${keyword}</font>`);
      } else {
        return val;
      }
    },
    deleteHistory() {
      this.historyList = [];
      removeLocalStorage("searchHistory");
    },
    change(data) {
      this.keyword = data.keyword;
      this.form.brandName = data.keyword;
      // if (!this.runSearch) {
      //   this.runSearch = debounce(() => {
      //     if (!this.keyword) {
      //       this.listData = [];
      //       return;
      //     }
      //     this.HTTP.searchBrandByName(this.keyword).then((res) => {
      //       console.log(res);
      //       let result = res.data || [];
      //       this.listData = result.length > 10 ? result.slice(0, 10) : result;
      //     });
      //   }, 500);
      // }
      // this.runSearch();
    },
    search() {
      this.list = [];
      this.form.current = 1;
      const keyword = this.keyword;
      if (keyword.trim() == "") { return; }
      this.isFlag = true;
      this.HTTP.brandList2(this.form).then(res => {
        this.isFlag = false;
        this.searckFlag = false;
        if (res.data === null) this.list = [];
        else {
          this.cardLoading = false;
          this.form.current++;
          this.total = res.total;
          if (this.list.length) {
            this.list = this.list.concat(res.data);
          } else {
            this.list = res.data;
          }
          if (res.data.length < res.size || this.list.length >= this.total) {
            this.finished = true; // 完成加载
          }
        }
      }).catch((e) => {
        this.$toast("网络不稳定，请稍候重试！");
        setTimeout(() => {
          this.cardLoading = false;
        }, 2000);
      });
      setTimeout(() => {
        this.searchHistory(keyword);
      },1000)
      // this.swRouter("/brand/discovery?brandName=" + keyword);
    },
    searchLoading() {
      const keyword = this.keyword;
      if (keyword.trim() == "") { return; }
      this.isFlag = true;
      this.HTTP.brandList2(this.form).then(res => {
        this.isFlag = false;
        this.searckFlag = false;
        if (res.data === null) this.list = [];
        else {
          this.cardLoading = false;
          this.form.current++;
          this.total = res.total;
          if (this.list.length) {
            this.list = this.list.concat(res.data);
          } else {
            this.list = res.data;
          }
          if (res.data.length < res.size || this.list.length >= this.total) {
            this.finished = true; // 完成加载
          }
        }
      }).catch((e) => {
        this.$toast("网络不稳定，请稍候重试！");
        setTimeout(() => {
          this.cardLoading = false;
        }, 2000);
      });
    },
    // searchByList(val) {
    //   this.searchHistory(val);
    //   this.swRouter("/brand/discovery?brandName=" + val);
    // },
    searchHistory(keyword) {
      let idx = this.historyList.indexOf(keyword);
      if (this.historyList.length == 0 || idx === -1) {
        this.historyList.unshift(keyword);
        setLocalStorage("searchHistory", this.historyList);
      } else {
        this.historyList.splice(idx, 1);
        this.historyList.unshift(keyword);
        setLocalStorage("searchHistory", this.historyList);
      }
      // 保持前10个搜索关键词
      let len = this.historyList.length;
      this.historyList = len > 10 ? this.historyList.slice(0, 10) : this.historyList;
      setLocalStorage("searchHistory", this.historyList);
    },
    loadDtat() {
      this.HTTP.listHotRank()
        .then((res) => {
          let result = res.data || [];
          this.hotSearchBrandList = result.length > 5 ? result.slice(0, 5) : result;
        })
        .catch((err) => {
          this.$toast("网络不稳定，请稍后再试！");
        });
    },
    clear() {
      this.searckFlag = true;
    },
    cancel() {
      // 埋点上报
      this.JSBridge.report(this.JSBridge.REPORT_EVENT.SEARCH.TAP_CANCEL);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  .right-title {
    font-size: 3.73vw;
    color: #347fff;
  }
  #{&}--view {
    scroll-behavior: smooth;
    position: relative;
    background: #fff;
  }

  #{&}--main {
    position: relative;
    height: 100%;
    .data_list{
      min-height: 100%;
      background: #F7F7F7;
      padding: 4vw;
      box-sizing: border-box;
      .itemClass {
        margin-bottom: 3vw;
        border-radius: 1.3vw;
        box-shadow: 0 0.8vw 0.8vw 0 rgba(#5fa9e6, 0.08);
      }
    }
    .history-flex {
      display: flex;
      padding: 3.2vw 0 2.13vw 0;
      .left {
        flex: 1;
        font-size: 4.27vw;
        color: #333;
        font-weight: bold;
      }
      .right {
        flex: 1;
        text-align: right;
        img {
          width: 4vw;
          height: 4vw;
        }
      }
    }
    .tag-wrap {
      position: relative;
      .tag {
        margin: 0 2.13vw 1.6vw 0;
      }
    }
    .hot-ranking {
      padding-top: 5.33vw;
      h4 {
        font-size: 4.27vw;
        color: #333;
        font-weight: bold;
        margin-bottom: 3.2vw;
      }
      .ranking-wrap {
        background: #fff;
        box-shadow: 0 0 2.13vw 0 rgba(0, 0, 0, 0.06);
        border-radius: 1.33vw;
        border: 0.27vw solid #e7e7e7;
        padding: 0 2.67vw;
      }
    }
    .search-list {
      display: flex;
      align-items: center;
      height: 12.8vw;
      line-height: 12.8vw;
      padding: 0 3.2vw;
      border-bottom: 0.27vw solid #f1f1f1;
      .left {
        flex: 0 0 5.87vw;
        img {
          width: 3.47vw;
          height: 3.47vw;
          margin-bottom: 0.53vw;
        }
      }
      .right {
        flex: 1;
        font-size: 3.73vw;
        color: #333;
      }
    }
  }
}
</style>
