<template>
  <div>
    <StateBar :bgcolor="'#fff'" />
    <TitleHeader :title="'会员中心'" :bgcolor="'#fff'" color="black" :back="true"></TitleHeader>
    <AutoView
      class="page-view"
      ref="view"
      :header="true"
      :footer="true"
    >
<!--      {{imageUrl}}-->
      <div class="success">
        <p class="title">提交成功</p>
        <p class="subtitle">审核通过后一个工作日内自动开通连锁品牌VIP！</p>
      </div>
      <div class="module no-border">
        <div class="cell">
          <div class="cell-hd">商户名称：</div>
          <div class="cell-ft">连锁品牌招商版 一年</div>
        </div>
        <div class="cell">
          <div class="cell-hd">应付金额：</div>
          <div class="cell-ft price">¥19998</div>
        </div>
      </div>
      <div class="module">
        <div class="cell title-wrap">
          <div class="cell-hd module-title">对公转账汇款账号如：</div>
          <div class="cell-ft copy" @click="copyText">复制转账信息</div>
        </div>
        <div class="copy-text">
          <div class="cell">
            <div class="cell-hd">开户名称：</div>
            <div class="cell-bd">深圳数位大数据科技有限公司</div>
          </div>
          <div class="cell">
            <div class="cell-hd">开户银行：</div>
            <div class="cell-bd">招商银行深圳云城支行</div>
          </div>
          <div class="cell">
            <div class="cell-hd">汇款账号：</div>
            <div class="cell-bd">755925539310801</div>
          </div>
        </div>
        <p class="tip">汇款完成并上传转账凭证，审核通过后一个工作日内自动开通连锁品牌VIP，转账即默认您已同意<b @click.stop="agreementClick('enterprise')">《上上参谋企业版-数位拓店云服务协议》</b></p>
        <p class="module-title title-wrap transfer">转账凭证</p>
        <div class="img-wrap">
          <van-image :src="imageUrl" @click="preview">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
      </div>
    </AutoView>
    <div class="footer-wrap">
      <van-button type="info" @click="toService">
        您已提交转账凭证，有问题可联系客服
      </van-button>
    </div>
  </div>
</template>

<script>
import { copyText } from "@/utils";
import UploadImg from "@/components/uploadImg";
import { ImagePreview } from "vant";

export default {
  name: "submitted",
  props: ['imageUrl'],
  components: {
    UploadImg
  },
  data(){
    return {
      urlArr: null,
    }
  },
  methods: {
    agreementClick(type) {
      let query = {}
      switch(type) {
        case 'subscription':
          query.queryPath = '/subscription.html'
          query.title = '连续订阅服务协议'
          break
        case 'memberservice':
          query.queryPath = '/memberservice.html'
          query.title = '上上参谋会员服务协议'
          break
        case 'paymentagreement':
          query.queryPath = '/paymentagreement.html'
          query.title = '上上参谋支付协议'
          break
        case 'enterprise':
          query.queryPath = '/enterprise.html'
          query.title = '上上参谋企业版-数位拓店云服务协议'
          break
      }
      this.swRouter({
        path: '/iframeAgreement',
        query,
      })
    },
    preview(){
      ImagePreview([this.$props.imageUrl]);
    },
    copyText(){
      let str ='开户名称：深圳数位大数据科技有限公司\n' +
        '开户银行：招商银行深圳云城支行\n' +
        '汇款账号：755925539310801';
      copyText(str);
    },
    toService(){
      this.JSBridge.callHandler("onNormalizingCall", {
        type: 5,
        url: "ONLINE_SERVICE",
        params: null,
      });
    },
    urlChange(urlArr){
      this.urlArr = urlArr;
    },
    submit(){
      if (Array.isArray(this.urlArr) && this.urlArr.length > 0) {
        const url = this.urlArr[0];
        this.HTTP.submitTransfer({
          image: url,
        }).then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$toast('提交成功！');
          } else {
            this.$toast('网络连接不稳定，请稍候重试！');
          }
        })
      }
    },
  }
};
</script>

<style lang="scss" scoped>
//@import '@/styles/px2vw.scss';
//@import '@/styles/_cell.scss';
//@import "@/styles/index.scss";
.titleHeader{
  border-bottom: 1px solid #E7E7E7;
}
.page-view{
  background: url("/static/vip/enterprise/duigou.png") no-repeat 100% 0;
  background-size: vw(199) auto;
}
.success{
  padding-top: vw(76);
  padding-left: vw(30);
  padding-bottom: vw(40);
  .title{
    font-size: vw(36);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF8700;
    line-height: vw(60);
  }
  .subtitle{
    font-size: vw(28);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: vw(40);
    margin-top: vw(20);
  }
}
.module{
  padding: vw(30) vw(28);
  border-top: vw(20) #F8F8F9 solid;
  .title-wrap{
    margin-bottom: vw(32);
  }
  .module-title, .cell .module-title{
    font-size: vw(32);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    height: vw(31);
    padding-left: vw(14);
    position: relative;
    &::before{
      content: ' ';
      width: vw(6);
      height: vw(31);
      background: #347FFF;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: vw(3);
    }
  }
  .cell-hd{
    font-size: vw(28);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .cell{
    padding: vw(15) 0;
  }
  .cell-bd, .cell-ft{
    font-size: vw(28);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .copy-text{
    background: #F7F7F7;
    border-radius: vw(10);
    padding: vw(20) vw(28);
    margin-bottom: vw(20);
    .cell{
      padding: vw(20) 0;
    }
  }
  .tip{
    font-size: vw(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: vw(36);
    b{
      font-weight: 400;
      color: #347FFF;
      line-height: 100%;
    }
  }
  .price{
    font-size: vw(36);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FE392C;
  }
  .copy{
    font-size: vw(28);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #347FFF;
  }
  .transfer{
    margin-top: vw(35);
  }
}
.footer-wrap{
  padding: 0 vw(20);
  .van-button{
    border: none;
    background: linear-gradient(90deg, #FFAC42 0%, #FFCE8F 100%);
    width: 100%;
  }
}
.no-border{
  border: none !important;
}
.img-wrap{
  width: vw(160);
  overflow: hidden;
  img{
    width: 100%;
  }
}


</style>

